import { connect } from "react-redux";

import { getSeasonRosterPlayersMergeToolSeasonId } from "@/redux/seasonRoster/selectors";

import { seasonRosterActions } from "@/redux/seasonRoster/actions";
import { loadSeasonPlayersRosterRoutine } from "@/redux/seasonRoster/routines";

import PlayersListMergeToolFailure from "./Failure";

const mapStateToProps = state => ({
  seasonId: getSeasonRosterPlayersMergeToolSeasonId(state)
});

const mapDispatchToProps = dispatch => ({
  exitMergeMode: seasonId => {
    dispatch(loadSeasonPlayersRosterRoutine({ seasonId }));
    dispatch(seasonRosterActions.players.mergeTool.disable());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayersListMergeToolFailure);
