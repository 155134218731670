import { useContext, useMemo } from "react";

import CurrentLeagueContext from "@/contexts/CurrentLeagueContext";

export default function useCurrentLeagueContext() {
  const currentLeague = useContext(CurrentLeagueContext);
  const currentLeagueMemo = useMemo(() => ({ ...currentLeague }), [currentLeague]);

  return currentLeagueMemo;
}
