import React from "react";

import FilterSelect from "@/components/FilterSelect";

import useYearSelect from "./hooks/useYearSelect";

function YearSelect() {
  const { options, selectedYear, setSelectedYear } = useYearSelect();

  return (
    <FilterSelect
      placeholder="All years"
      value={selectedYear}
      isLoaded={true}
      isLoading={false}
      onChange={setSelectedYear}
    >
      {options.map(year => (
        <option key={year} value={year}>
          {year}
        </option>
      ))}
    </FilterSelect>
  );
}

export default YearSelect;
