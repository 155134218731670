import _uniqBy from "lodash/uniqBy";
import _isEqual from "lodash/isEqual";
import _trimEnd from "lodash/trimEnd";

import getInvitationStatus from "@/lib/core/getInvitationStatus";

const selectRoot = state => state.invitationForm;
const selectRoles = state => selectRoot(state).roles;
const selectFields = state => selectRoot(state).fields;
const selectFieldValue = (state, name) => selectRoot(state).fields[name].value;
const selectMeta = state => selectRoot(state).meta;

export const getRoles = state => {
  const roles = selectRoles(state).map(({ title, level, resourceTitle, ...rest }) => ({
    ...rest,
    id: `${title}-${level.type}-${level.id}`,
    title,
    level,
    resourceTitle
  }));

  return _uniqBy(roles, "id");
};

export const getField = (state, name) => {
  const { value, errors } = selectFields(state)[name];

  return { value, errors, isInvalid: errors.length > 0 };
};

export const getAttributes = state => {
  const code = selectFieldValue(state, "code");
  const description = selectFieldValue(state, "description");
  const roles = selectRoles(state).map(({ title, level }) => ({
    title,
    level
  }));

  return { code, description, roles };
};

export const getIsValid = state => {
  const fields = selectFields(state);

  const fieldsAreValid = Object.values(fields).filter(({ errors }) => errors.length > 0).length === 0;

  const roles = selectRoles(state);

  return fieldsAreValid && roles.length > 0;
};

export const getIsDirty = state => {
  const fields = selectFields(state);

  const fieldsAreDirty = Object.values(fields).filter(({ isDirty }) => isDirty).length > 0;

  const roles = selectRoles(state).map(({ title, level }) => ({
    title,
    level
  }));

  const initRoles = selectRoot(state).initRoles;
  const rolesAreDirty = !_isEqual(roles, initRoles);

  return fieldsAreDirty || rolesAreDirty;
};

export const getIsNotFilled = state => {
  const fields = selectFields(state);

  return Object.entries(fields).filter(([name, { value }]) => _trimEnd(value) === "").length > 0;
};

export const getIsInvalid = state => getIsValid(state) === false;

export const getIsPristine = state => getIsDirty(state) === false;

export const getMeta = state => selectMeta(state);

export const getRolesAreLoading = state => {
  const roles = selectRoles(state);

  return roles.length > 0 && roles.filter(({ isLoading }) => isLoading).length > 0;
};

export const getRolesAreLoaded = state => {
  const roles = selectRoles(state);

  return roles.length === 0 || roles.filter(({ isLoaded }) => isLoaded).length > 0;
};

export const getStatus = state => getInvitationStatus(getMeta(state).expiresAt);
