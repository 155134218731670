import { useEffect } from "react";
import { useDispatch } from "react-redux";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import { loadingRoutine } from "@/redux/divisionsList";

import useDivisionsList from "./useDivisionsList";

import actions from "@/redux/divisionsList/actions";

export default function useDivisionsListLoading({ pageSize = 25 }) {
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(location.search);
  const pageNumber = searchParams.get("page") || 1;
  const sort = searchParams.get("sort") || "none";

  const isTeamsList = location.pathname.endsWith("teams");

  const { seasonIsLoaded, seasonId } = useCurrentSeasonContext();

  const { isListLoaded, isListLoading, isListFailed } = useDivisionsList();

  useEffect(
    () => {
      dispatch(actions.clear());
    },
    [pageNumber]
  );

  useEffect(
    () => {
      dispatch(actions.clear());
    },
    [sort]
  );

  useEffect(
    () => {
      if (seasonIsLoaded && !isListLoaded && !isListLoading && !isListFailed && !isTeamsList) {
        dispatch(loadingRoutine({ seasonId, pageSize, pageNumber, sort }));
      }
    },
    [
      dispatch,
      seasonIsLoaded,
      isListLoaded,
      isListLoading,
      isListFailed,
      seasonId,
      pageSize,
      pageNumber,
      isTeamsList,
      sort
    ]
  );

  return {
    pageNumber,
    sort
  };
}
