import { connect } from "react-redux";

import {
  getSeasonRosterPlayersListIsLoading,
  getSeasonRosterPlayersListIsLoaded,
  getSeasonRosterPlayersList,
  getSeasonRosterPlayersListHasMore,
  getSeasonRosterPlayersListIsAppending,
  getSeasonRosterPlayersMergeToolIsEnabled,
  getSeasonRosterPlayersMergeToolStatus,
  getSeasonRosterPlayersListSearchCurrentQuery,
  getSeasonRosterPlayersListTotalCount,
  getSeasonRosterPlayersListFilteredCount
} from "@/redux/seasonRoster/selectors";

import { loadSeasonPlayersRosterRoutine } from "@/redux/seasonRoster/routines";
import { seasonRosterActions } from "@/redux/seasonRoster/actions";

import PlayersList from "./List";

const mapStateToProps = state => ({
  isLoading: getSeasonRosterPlayersListIsLoading(state),
  isLoaded: getSeasonRosterPlayersListIsLoaded(state),
  players: getSeasonRosterPlayersList(state),
  totalCount: getSeasonRosterPlayersListTotalCount(state),
  filteredCount: getSeasonRosterPlayersListFilteredCount(state),
  mergeToolStatus: getSeasonRosterPlayersMergeToolStatus(state),
  hasMore: getSeasonRosterPlayersListHasMore(state),
  isAppending: getSeasonRosterPlayersListIsAppending(state),
  isMergeToolEnabled: getSeasonRosterPlayersMergeToolIsEnabled(state),
  currentQuery: getSeasonRosterPlayersListSearchCurrentQuery(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadMorePlayers: () =>
    dispatch(
      loadSeasonPlayersRosterRoutine({
        seasonId: ownProps.seasonId,
        append: true
      })
    ),
  enableMergeTool: () =>
    dispatch(
      seasonRosterActions.players.mergeTool.enable({
        seasonId: ownProps.seasonId
      })
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayersList);
