import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import { Row, Col, FormGroup, Label, Input, Button, Alert, FormFeedback } from "reactstrap";

import Layout from "@/layouts/Layout";
import Head from "@/components/Head";
import TitleBar from "@/components/TitleBar";
import DashboardForm from "@/components/DashboardForm";
import DashboardFormFooter from "@/components/DashboardFormFooter";

class NewPassword extends PureComponent {
  static propTypes = {
    queryParams: PropTypes.shape({
      token: PropTypes.string
    }),
    fields: PropTypes.shape({
      password: PropTypes.shape({
        value: PropTypes.string,
        error: PropTypes.string
      }),
      passwordConfirmation: PropTypes.shape({
        value: PropTypes.string,
        error: PropTypes.string
      })
    }),
    isFormDirty: PropTypes.bool,
    isWorking: PropTypes.bool,
    isCompleted: PropTypes.bool,
    match: PropTypes.shape({}),
    error: PropTypes.string,
    changeField: PropTypes.func,
    initField: PropTypes.func,
    createPassword: PropTypes.func,
    clear: PropTypes.func
  };

  static defaultProps = {
    queryParams: { token: null },
    isFormDirty: true,
    isWorking: false,
    isCompleted: false,
    fields: {
      password: { value: "", error: null },
      passwordConfirmation: { value: "", error: null }
    },
    initField() {},
    changeField() {},
    createPassword() {},
    clear() {}
  };

  componentDidMount() {
    const {
      queryParams: { token }
    } = this.props;

    this.props.initField({ field: "token", value: token });
  }

  componentWillUnmount() {
    this.props.clear();
  }

  handleChange = ({ target }) => {
    const { name: field, value } = target;

    this.props.changeField({ field, value });
  };

  handleSubmit = () => {
    const {
      fields: { password, passwordConfirmation, token }
    } = this.props;

    this.props.createPassword({
      token: token.value,
      password: password.value,
      passwordConfirmation: passwordConfirmation.value
    });
  };

  render() {
    const {
      error,
      fields: { password, passwordConfirmation, token },
      isFormDirty,
      isWorking,
      isCompleted
    } = this.props;

    return (
      <Layout className="page-align-center">
        <Row className="justify-content-center">
          <Col md="10" lg="6">
            <Head title="New Password" />

            {token ? (
              isCompleted ? (
                <Fragment>
                  <Alert color="success">Password has been successfully created</Alert>
                  <Button color="primary" tag={Link} to="/" outline block>
                    Login now
                  </Button>
                </Fragment>
              ) : (
                <Fragment>
                  <TitleBar title="Enter New Password" />
                  {error ? <Alert color="danger">{error}</Alert> : null}
                  {token.error ? (
                    <Fragment>
                      <Alert color="danger">{token.error}</Alert>
                      <Button color="primary" tag={Link} to="/password/reset" outline block>
                        Request new token
                      </Button>
                    </Fragment>
                  ) : null}

                  <DashboardForm isOperating={isWorking}>
                    <FormGroup>
                      <Label for="password">New Password</Label>
                      <Input
                        type="password"
                        id="password"
                        name="password"
                        onChange={this.handleChange}
                        value={password.value}
                        invalid={password.error !== null}
                      />
                      <FormFeedback>{password.error}</FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label for="passwordConfirmation">Confirm Password</Label>
                      <Input
                        type="password"
                        id="passwordConfirmation"
                        name="passwordConfirmation"
                        onChange={this.handleChange}
                        value={passwordConfirmation.value}
                        invalid={passwordConfirmation.error !== null}
                      />
                      <FormFeedback>{passwordConfirmation.error}</FormFeedback>
                    </FormGroup>

                    <DashboardFormFooter>
                      <div className="dashboard-form-footer__secondary">
                        <Button color="secondary" tag={Link} to="/" outline>
                          Cancel
                        </Button>
                      </div>

                      <div className="dashboard-form-footer__primary">
                        <Button
                          type="button"
                          color="success"
                          outline={isFormDirty === false}
                          disabled={isFormDirty === false}
                          onClick={this.handleSubmit}
                        >
                          {isWorking ? "Processing…" : "Reset Password"}
                        </Button>
                      </div>
                    </DashboardFormFooter>
                  </DashboardForm>
                </Fragment>
              )
            ) : (
              <Fragment>
                <Alert color="danger">Password Reset Token is required</Alert>
                <Button outline block color="primary" tag={Link} to="/password/reset">
                  Request Token
                </Button>
              </Fragment>
            )}
          </Col>
        </Row>
      </Layout>
    );
  }
}

export default NewPassword;
