import { connect } from "react-redux";

import {
  isCurrentTeamOperating,
  isCurrentTeamReady,
  getCurrentTeam,
  getCurrentTeamPlayersRoster
} from "@/redux/teams/selectors";

import {
  isCurrentPlayerOperating,
  isCurrentPlayerReady,
  isCurrentPlayerForbidden,
  isCurrentPlayerArchived,
  getCurrentPlayer,
  getCurrentTeamPlayer,
  getCurrentTeamPlayerBreadcrumbs,
  getCurrentPlayerFullName
} from "@/redux/players/selectors";

import TeamRosterPlayersEdit from "./TeamRosterPlayersEdit";

import { CurrentPlayerLoadingRoutine } from "@/redux/players/routines";
import { clearCurrentPlayer } from "@/redux/players/actions";
import { CurrentTeamLoadingRoutine, CurrentTeamUpdatingRoutine } from "@/redux/teams/routines";
import { getSeason } from "@/redux/currentSeason/selectors";

const mapStateToProps = state => ({
  season: getSeason(state),
  isTeamReady: isCurrentTeamReady(state),
  isTeamOperating: isCurrentTeamOperating(state),
  isPlayerReady: isCurrentPlayerReady(state),
  isPlayerOperating: isCurrentPlayerOperating(state),
  isPlayerForbidden: isCurrentPlayerForbidden(state),
  isPlayerArchived: isCurrentPlayerArchived(state),
  team: getCurrentTeam(state),
  playersRoster: getCurrentTeamPlayersRoster(state),
  player: getCurrentPlayer(state),
  teamPlayer: getCurrentTeamPlayer(state),
  playerFullName: getCurrentPlayerFullName(state),
  breadcrumbs: getCurrentTeamPlayerBreadcrumbs(state),
  isPlayerCreating: false
});

const mapDispatchToProps = dispatch => ({
  loadTeam: id => dispatch(CurrentTeamLoadingRoutine({ id })),
  loadPlayer: ({ id, seasonId }) => dispatch(CurrentPlayerLoadingRoutine({ id, seasonId })),
  updateTeam: ({ id, seasonId, divisionId, attributes, tab, redirect, logo }) =>
    dispatch(CurrentTeamUpdatingRoutine({ id, seasonId, divisionId, attributes, logo, tab, redirect })),
  clearPlayer: () => dispatch(clearCurrentPlayer())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamRosterPlayersEdit);
