import axios from 'axios';

export const divisionsService = (path, config) => {
  path = `${path}/divisions`;

  return {
    get: async ({ include=[] }={}) => {
      path = include.length ? `${path}?include=${include}` : path;
      return axios.get(path, config).then(response => {
        return response.data;
      }).catch(e => {
        console.log("Get Season Divisions Error", e);
        return {};
      });
    }
  }
}