import { handleActions } from "redux-actions";

import { refereesActions } from "../../actions";
import { mergeRefereesRoutine } from "../../routines";

const { mergeTool: mergeToolActions } = refereesActions.list;

const initialState = {
  seasonId: null,
  status: null,
  ids: [],
  winnerId: null
};

function updateStatus(state, status, rest) {
  return { ...state, status, ...rest };
}

function reduceEnabled(state, { payload: { seasonId } }) {
  return updateStatus(state, "selecting-referees", { seasonId });
}

function reduceDisabled(state) {
  return updateStatus(state, null, { ids: [] });
}

function reduceRefereesSelected(state, { payload: ids }) {
  return {
    ...state,
    ids,
    winnerId: null
  };
}

function reduceWinnerChosen(state, { payload: winnerId }) {
  return { ...state, winnerId };
}

function reduceSettingStatus(state, { payload: status }) {
  return updateStatus(state, status);
}

function reduceMergingRequest(state) {
  return updateStatus(state, "merging");
}

function reduceMergingFailure(state) {
  return updateStatus(state, "failure");
}

function reduceMergingSuccess(state) {
  return updateStatus(state, null, { ids: [] });
}

export default handleActions(
  {
    [refereesActions.list.clear]: () => ({ ...initialState }),
    [mergeToolActions.enable]: reduceEnabled,
    [mergeToolActions.disable]: reduceDisabled,
    [mergeToolActions.chooseWinner]: reduceWinnerChosen,
    [mergeToolActions.setStatus]: reduceSettingStatus,
    [mergeRefereesRoutine.REQUEST]: reduceMergingRequest,
    [mergeRefereesRoutine.FAILURE]: reduceMergingFailure,
    [mergeRefereesRoutine.SUCCESS]: reduceMergingSuccess,
    [mergeToolActions.selectReferees]: reduceRefereesSelected
  },
  initialState
);
