import nanoid from "nanoid";
import * as Cloudflare from '@/lib/vendors/cloudflare';
import { config } from "@/config";

export function createFile({ file, token }) {
  const fileName = file.name ? `${nanoid()}-${file.name}` : nanoid();

  return Cloudflare.send( file, token ).then(url => {
    return {
      data: {
        data: {
          attributes: {
            url
          }
        }
      }
    }
  });
  
  // const headers = {
  //   Authorization: `Bearer ${token}`,
  //   "Content-Disposition": `attachment; filename="${fileName}"`,
  //   "Content-Type": file.type
  // };

  // return axios.request({
  //   baseURL: config.API_BASE_URL,
  //   method: "POST",
  //   url: "/files",
  //   data: file,
  //   headers
  // });
}
