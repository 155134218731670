import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import StickyFooter from "@/components/StickyFooter";

class RefereesListMergeToolFooter extends PureComponent {
  static propTypes = {
    countRefereesSelected: PropTypes.number,
    isEnabled: PropTypes.bool,
    status: PropTypes.string,
    winnerId: PropTypes.string,
    disable: PropTypes.func,
    setStatus: PropTypes.func,
    mergeReferees: PropTypes.func
  };

  static defaultProps = {
    isEnabled: false,
    status: null,
    winnerId: null,
    disable() {},
    setStatus() {},
    mergeReferees() {}
  };

  renderSelectedRefereesCount = () => {
    const { countRefereesSelected } = this.props;

    if (countRefereesSelected === 0) {
      return "No referees selected to merge";
    }

    switch (countRefereesSelected) {
      case 0:
        return "No referees selected to merge.";
      case 1:
        return "Only one referee selected to merge. Select at least one more.";
      default:
        return `${countRefereesSelected} referees selected to merge.`;
    }
  };

  proceedToChoosingWinner = () => {
    this.props.setStatus("choosing-winner");
  };

  renderPrimaryButton = () => {
    const { status, countRefereesSelected, winnerId, mergeReferees } = this.props;

    switch (status) {
      case "selecting-referees":
        return (
          <Button
            outline={countRefereesSelected < 2}
            type="button"
            color="success"
            disabled={countRefereesSelected < 2}
            onClick={this.proceedToChoosingWinner}
          >
            Continue Merge
          </Button>
        );
      case "choosing-winner":
        return (
          <Button
            outline={winnerId === null}
            type="button"
            color="success"
            disabled={winnerId === null}
            onClick={mergeReferees}
          >
            Merge Referees
          </Button>
        );
      default:
        return null;
    }
  };

  render() {
    const { isEnabled, status, disable } = this.props;

    if (!isEnabled || !["selecting-referees", "choosing-winner"].includes(status)) {
      return null;
    }

    return (
      <StickyFooter>
        <div className="footerText">
          {status === "selecting-referees" && (
            <p className="sticky-footer__text">{this.renderSelectedRefereesCount()}</p>
          )}
        </div>

        <div className="footerButtons">
          <div className="sticky-footer__secondary">
            <Button outline type="button" color="secondary" onClick={disable}>
              Cancel merge
            </Button>
          </div>

          <div className="sticky-footer__primary">{this.renderPrimaryButton()}</div>
        </div>
      </StickyFooter>
    );
  }
}

export default RefereesListMergeToolFooter;
