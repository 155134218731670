import { call, all, put, takeLatest } from "redux-saga/effects";

import { gamesheetAPIRequest } from "@/redux/api/sagas";
import { loadPenaltyAccumulationReport } from "@/lib/api/reports";
import { loadSeason } from "@/lib/api/seasons";
import { fetchOne } from "@/redux/api/sagas";

import { penaltyAccumulationReportLoadingRoutine } from "./routines";

function* loadReportSaga({ payload: { seasonId, penaltyCodes } }) {
  yield put(penaltyAccumulationReportLoadingRoutine.request());

  try {
    const { data } = yield call(gamesheetAPIRequest, loadPenaltyAccumulationReport, {
      seasonId,
      penaltyCodes
    });

    const [season] = yield fetchOne({ type: "seasons", id: seasonId }, loadSeason, {});

    yield put(penaltyAccumulationReportLoadingRoutine.success({ ...data, season }));
  } catch (error) {
    const responseStatus = error.response && error.response.status;

    yield put(penaltyAccumulationReportLoadingRoutine.failure({ error, responseStatus }));

    if (!responseStatus) {
      throw error;
    }
  } finally {
    yield put(penaltyAccumulationReportLoadingRoutine.fulfill());
  }
}

export function* penaltyAccumulationReportFlow() {
  yield all([takeLatest(penaltyAccumulationReportLoadingRoutine.TRIGGER, loadReportSaga)]);
}
