import { useEffect } from "react";
import { useDispatch } from "react-redux";

import actions from "@/redux/teams/actions";

export default function useCurrentTeamClearing() {
  const dispatch = useDispatch();

  useEffect(() => () => dispatch(actions.current.clear()), [dispatch]);
}
