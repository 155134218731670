import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";

import { Input } from "reactstrap";

class GamePeriodTimeInput extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func
  };

  static defaultProps = {
    onChange() {}
  };

  render() {
    const { id, name, value, onChange } = this.props;

    return (
      <InputMask id={id} name={name} mask="99:99" maskChar="-" value={value} onChange={onChange}>
        {props => <Input {...props} />}
      </InputMask>
    );
  }
}

export default GamePeriodTimeInput;
