import React from "react";
import PropTypes from "prop-types";
import DateInput from "react-datetime";

import { Input, FormGroup, Label, Row, Col } from "reactstrap";

import { list as gameTypes } from "@/constants/gameTypes";
import useScrollTo from "@/hooks/useScrollTo";
import Flags from "@/components/Gamesheet/components/Properties/components/Flags";
import ContentSection from "@/components/ContentSection";
import DashboardForm from "@/components/DashboardForm";
import DashboardFormFooter from "@/components/DashboardFormFooter";
import FormItem from "@/components/FormItem";
import PageAlert from "@/components/PageAlert";
import TitleBar from "@/components/TitleBar";
import { Can } from "@/components/Ability";
import ConfirmDeletionModal from "@/components/ConfirmDeletionModal";
import TimeInput from "@/components/TimeInput";
import PenaltiesFieldset from "./components/PenaltiesFieldset";
import ScoringFieldset from "./components/ScoringFieldset";
import GoalieShiftsFieldset from "./components/GoalieShiftsFieldset";
import ShotsFieldset from "./components/ShotsFieldset";
import ShootoutsFieldset from "./components/ShootoutsFieldset";
import FairPlayPoints from "./components/FairPlayPoints";
import MaxFairPlayInfo from "./components/MaxFairPlayInfo";
import Lineup from "./components/Lineup";
import SubmitButton from "./components/SubmitButton";
import TimeZoneInput from "./components/TimeZoneInput";
import ShowIf from "@/components-16.8/ShowIf";

import "./GameForm.scss";

class GameForm extends React.PureComponent {
  static propTypes = {
    flags: PropTypes.arrayOf(
      PropTypes.shape({
        event: PropTypes.string.isRequired
      })
    ),
    gameId: PropTypes.string.isRequired,
    seasonId: PropTypes.string.isRequired,
    fields: PropTypes.shape({}),
    categories: PropTypes.arrayOf(PropTypes.string),
    error: PropTypes.string,
    isWorking: PropTypes.bool,
    isDeleting: PropTypes.bool.isRequired,
    changeField: PropTypes.func,
    submit: PropTypes.func,
    deleteGame: PropTypes.func,
    fppEnabled: PropTypes.bool,
    homeRoster: PropTypes.array,
    visitorRoster: PropTypes.array,
    suspendedPlayers: PropTypes.shape({}),
    isPristine: PropTypes.bool
  };

  static defaultProps = {
    flags: [],
    fields: {},
    homeTitle: "",
    visitorTitle: "",
    error: null,
    categories: [],
    isWorking: false,
    fppEnabled: false,
    changeField() {},
    submit() {},
    deleteGame() {},
    isPristine: false
  };

  handleFieldChange = ({ target: { name, value } }) => {
    this.props.changeField({ name, value });
  };

  handleDateChange = date => {
    if (!date || typeof date === "string") {
      return;
    }

    this.props.changeField({
      name: "date",
      value: date.format("YYYY-MM-DD")
    });
  };

  handleTimeChange = (time, name) => {
    this.props.changeField({
      name: name,
      value: time.format("HH:mm")
    });
  };

  createTimeChangeHandler = fieldName => time => {
    if (!time || typeof time === "string") {
      return;
    }

    this.props.changeField({
      name: fieldName,
      value: time.format("HH:mm")
    });
  };

  handleSubmit = () => {
    const { gameId, seasonId, submit } = this.props;

    submit({ id: gameId, seasonId });
  };

  handleDelete = () => {
    const { gameId, seasonId, deleteGame } = this.props;

    deleteGame({ id: gameId, seasonId });
  };

  render() {
    const { flags, fields, categories, isWorking, isDeleting, error, gameId, fppEnabled, isPristine } = this.props;

    const { Element: PenaltyScrollTarget } = useScrollTo("penalties");

    return (
      <DashboardForm className="game-form" onSubmit={this.handleSubmit} isOperating={isWorking || isDeleting}>
        {error && <PageAlert type="danger" message={error} />}

        <Flags flags={flags} />
        <ContentSection className="top-meta-info">
          <FormItem>
            <FormGroup className="col-md-3">
              <Label for="gameDate">Game Date</Label>

              <DateInput
                name="date"
                id="gameDate"
                value={fields.date.value}
                dateFormat="YYYY-MM-DD"
                timeFormat={false}
                inputProps={{ onChange: () => {}, placeholder: "YYYY-MM-DD" }}
                closeOnSelect={true}
                onChange={this.handleDateChange}
              />
            </FormGroup>

            <FormGroup className="col-md-2">
              <Label for="gameStartTime">Start Time</Label>

              <TimeInput
                name="startTime"
                id="gameStartTime"
                value={fields.startTime.value}
                onChange={this.handleTimeChange}
              />
            </FormGroup>

            <FormGroup className="col-md-2">
              <Label for="gameEndTime">End Time</Label>

              <TimeInput
                name="endTime"
                id="gameEndTime"
                value={fields.endTime.value}
                onChange={this.handleTimeChange}
              />
            </FormGroup>

            <FormGroup className="col-md-2">
              <Label for="gameCurfewTime">Curfew Time</Label>

              <TimeInput
                name="curfewTime"
                id="gameCurfewTime"
                value={fields.curfewTime.value}
                onChange={this.handleTimeChange}
              />
            </FormGroup>

            <FormGroup className="col-md-3">
              <Label for="gameTimeZone">Time Zone</Label>

              <TimeZoneInput
                name="timeZone"
                id="gameTimeZone"
                value={fields.timeZone.value}
                onChange={this.handleFieldChange}
                date={fields.date}
                time={fields.startTime}
                setOffset={val => this.handleFieldChange({ target: { name: "offset", value: val }})}
              />
            </FormGroup>
          </FormItem>

          <FormItem>
            <FormGroup className="col-md-3">
              <Label for="gameLocation">Location</Label>
              <Input
                id="gameLocation"
                name="location"
                value={fields.location.value}
                onChange={this.handleFieldChange}
              />
            </FormGroup>
          </FormItem>

          <FormItem>
            <FormGroup className="col-md-3">
              <Label for="gameCategory">Category</Label>
              <Input
                type="select"
                id="gameCategory"
                name="category"
                value={fields.category.value}
                onChange={this.handleFieldChange}
              >
                <option value="" />
                {categories.map(category => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </Input>
            </FormGroup>

            <FormGroup className="col-md-3">
              <Label for="gameNumber">Game Number</Label>
              <Input id="gameNumber" name="number" value={fields.number.value} onChange={this.handleFieldChange} />
            </FormGroup>

            <FormGroup className="col-md-2">
              <Label for="gameType">Game type</Label>
              <Input
                type="select"
                id="gameType"
                name="gameType"
                value={fields.gameType.value}
                onChange={this.handleFieldChange}
              >
                {gameTypes.map(({ code, title }) => (
                  <option key={code} value={code}>
                    {title}
                  </option>
                ))}
              </Input>
            </FormGroup>

            <FormGroup className="col-md-2">
              <Label for="gamesPlayed">Games Played</Label>
              <Input
                type="number"
                min="0"
                id="gamesPlayed"
                name="gamesPlayed"
                value={fields.gamesPlayed.value}
                onChange={this.handleFieldChange}
              />
            </FormGroup>

            <FormGroup className="col-md-2">
              <Label for="gamesTotal">Games Total</Label>
              <Input
                type="number"
                min="0"
                id="gamesTotal"
                name="gamesTotal"
                value={fields.gamesTotal.value}
                onChange={this.handleFieldChange}
              />
            </FormGroup>
          </FormItem>
        </ContentSection>

        <ContentSection className="top-lineups">
          <TitleBar title="Lineups" titleTag="h3" />
          <Lineup team="visitor" />
          <Lineup team="home" />
        </ContentSection>

        <ContentSection className="top-goalie-shifts">
          <TitleBar title="Goalie shifts" titleTag="h3" />
          <GoalieShiftsFieldset team="visitor" />
          <GoalieShiftsFieldset team="home" />
        </ContentSection>

        <ContentSection className="top-shots">
          <TitleBar title="Shots" titleTag="h3" />
          <ShotsFieldset />
        </ContentSection>

        <ContentSection className="top-scoring">
          <TitleBar title="Scoring" titleTag="h3" />
          <ScoringFieldset team="visitor" />
          <ScoringFieldset team="home" />
        </ContentSection>

        <ContentSection className="top-penalties">
          <PenaltyScrollTarget>
            <TitleBar title="Penalties" titleTag="h3" />
          </PenaltyScrollTarget>
          <PenaltiesFieldset type="visitor" />
          <PenaltiesFieldset type="home" />
        </ContentSection>

        <ContentSection className="top-shootouts">
          <TitleBar title="Shootouts" titleTag="h3" />
          <Row>
            <Col md="6">
              <ShootoutsFieldset team="visitor" />
            </Col>

            <Col md="6">
              <ShootoutsFieldset team="home" />
            </Col>
          </Row>
        </ContentSection>

        <ShowIf cond={fppEnabled} value={true}>
          <ContentSection className="top-fair-play">
            <TitleBar title="Fair Play" titleTag="h3" />
            <Row>
              <Col md="6">
                <FairPlayPoints team="visitor" />
              </Col>
              <Col md="6">
                <FairPlayPoints team="home" />
              </Col>
              <Col md="12">
                <MaxFairPlayInfo />
              </Col>
            </Row>
          </ContentSection>
        </ShowIf>


        <DashboardFormFooter>
          <Can I="delete" this={{ type: "games", id: gameId }}>
            <div className="dashboard-form-footer__secondary">
              <ConfirmDeletionModal
                resourceTitle={
                  fields.number.value && fields.number.value.trimEnd() !== "" ? fields.number.value : `#${gameId}`
                }
                resourceType="game"
                isDeleting={isDeleting}
                isOperating={isWorking}
                onDelete={this.handleDelete}
              />
            </div>
          </Can>
          <div className="dashboard-form-footer__primary">
            <SubmitButton gameId={gameId} canSubmit={this.props.canSubmit} disabled={isPristine} />
          </div>
        </DashboardFormFooter>
      </DashboardForm>
    );
  }
}

export default GameForm;
