import { handleActions } from "redux-actions";

import { invitationFormActions } from "../../actions";

const defaultState = {
  isOpen: false,
  association: null,
  league: null,
  season: null,
  divisions: []
};

const open = () => Object.assign({}, defaultState, { isOpen: true });
const close = () => Object.assign({}, defaultState, { isOpen: false });

const toggleOpened = state => Object.assign({}, defaultState, { isOpen: !state.isOpen });

const setAssociation = (state, { payload }) =>
  Object.assign({}, state, {
    association: payload,
    league: null,
    season: null
  });

const setLeague = (state, { payload }) =>
  Object.assign({}, state, {
    league: payload,
    season: null
  });

const setSeason = (state, { payload }) =>
  Object.assign({}, state, {
    season: payload
  });

const selectDivision = (state, { payload }) =>
  Object.assign({}, state, {
    divisions: [...state.divisions, payload]
  });

const deselectDivision = (state, { payload }) =>
  Object.assign({}, state, {
    divisions: state.divisions.filter(id => id !== payload)
  });

export default handleActions(
  {
    [invitationFormActions.divisionsList.open]: open,
    [invitationFormActions.divisionsList.close]: close,
    [invitationFormActions.divisionsList.toggleOpened]: toggleOpened,
    [invitationFormActions.divisionsList.selectAssociation]: setAssociation,
    [invitationFormActions.divisionsList.selectLeague]: setLeague,
    [invitationFormActions.divisionsList.selectSeason]: setSeason,
    [invitationFormActions.divisionsList.selectDivision]: selectDivision,
    [invitationFormActions.divisionsList.deselectDivision]: deselectDivision
  },
  defaultState
);
