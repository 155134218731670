import { useSelector } from "react-redux";

import { formatFullDateTime } from "@/utils/date";

import { getStatus, getMeta } from "@/redux/invitationForm/selectors";

export default function useExpirationDate() {
  const status = useSelector(getStatus);
  const { expiresAt } = useSelector(getMeta);

  const permanent = expiresAt === null;

  return {
    dateTime: permanent ? null : formatFullDateTime(expiresAt),
    permanent,
    status
  };
}
