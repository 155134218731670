import React, { useCallback, useEffect, useMemo, useState } from "react";

import Breadcrumbs from "@/components/Breadcrumbs";
import Head from "@/components/Head";
import TitleBar from "@/components/TitleBar";
import ProtectedLayout from "@/layouts/ProtectedLayout";
import SearchBar from "@/components/SearchBar";
import { config } from "../../config";
import axios from "axios";
import TotalCount from "@/components/TotalCount";

import "./SearchBar.scss"
import PageLoader from "@/components/PageLoader";
import EmptyListMessage from "@/components/EmptyListMessage";
import { useRecoilState } from "recoil";
import { currentQueryState, searchResultsState } from "./state";
import { Table } from "reactstrap";
import Coach from "./Coach";
import InfiniteScroll from "react-infinite-scroll-component";
import InfiniteScrollEndMessage from "@/components/InfiniteScroll/EndMessage/EndMessage";
import InfiniteScrollSpinner from "@/components/InfiniteScroll/Spinner/Spinner";
import { getFreshToken } from "@/lib/api/getFreshToken";

export function CoachPenaltyReportSearch() {

    const PAGE_SIZE = 15;

    // persistant state
    const [currentQuery, setCurrentQuery] = useRecoilState(currentQueryState)
    const [coaches, setCoaches] = useRecoilState(searchResultsState)

    // rendered state
    const [nextQuery, setQuery] = useState(currentQuery)
    const [offset, setOffset] = useState(0);
    const [isLoading, setIsLoading] = useState(false)

    // derived values
    const isDirty = useMemo(() => nextQuery != currentQuery, [currentQuery, nextQuery])
    const totalCoaches = useMemo(() => coaches.data.length == 0 ? 0 : coaches.data[0].total, [JSON.stringify(coaches.data)])
    const noCoachesFound = useMemo(() => !coaches.data.length && !isLoading && currentQuery != "", [currentQuery, coaches.data.length, isLoading])
    const showTable = useMemo(() => !!coaches.data.length && !isLoading, [coaches.data.length, isLoading])
    const hasMore = useMemo(() => !currentQuery || totalCoaches > coaches.data.length, [coaches.data.length, totalCoaches, currentQuery])

    // callbacks
    const getCoaches = useCallback(async () => {
        const token = await getFreshToken();
        return axios.get(`${config.BFF_API}/people/v4?filter[query]=${currentQuery}&filter[persontype]=coach&filter[limit]=${PAGE_SIZE}&filter[offset]=${offset}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        }).then(response => response.data.data)
    }, [currentQuery, PAGE_SIZE, offset])

    // actions
    const resetdata = () => {
        setCoaches({ query: "", data: [], offset: 0 })
        setOffset(0)
    }

    const commit = () => {
        setIsLoading(true)
        resetdata()
        setCurrentQuery(nextQuery)
        !nextQuery && setIsLoading(false)
    }

    const clear = () => {
        resetdata()
        setQuery("")
    }

    const next = () => {
        setOffset(prev => prev + PAGE_SIZE)
    }

    useEffect(() => {
        console.log("offset changed to: ", offset)
    }, [offset])

    // side effects
    useEffect(() => {
        let mounted = true;
        // console.log("useEffect",currentQuery,offset,coaches)

        if (currentQuery && (currentQuery != coaches.query || offset > coaches.offset )) {

            getCoaches().then(_coaches => {
                if (mounted) {
                    setCoaches((prev) => ({ query: currentQuery, data: [ ...prev.data, ..._coaches ], offset }));
                    setIsLoading(false)
                }
            })

        }

        return () => {
            mounted = false
        }
    }, [coaches, getCoaches])

    return <ProtectedLayout subject={{ type: "reports" }} action="read">
        <Head title="Coach Penalty Reports" />
        <Breadcrumbs items={[{ title: "Reports" }, { title: "Coach Penalty Reports", url: "/reports/coach-penalty" }]} />
        <TitleBar title="Search Coaches" />
        <SearchBar
            className="coach-penalty-report__search-bar"
            placeholder="Search coaches…"
            nextQuery={nextQuery}
            currentQuery={currentQuery}
            isDirty={isDirty}
            disabled={isLoading}
            isLoading={isLoading}
            onChange={setQuery}
            commit={commit}
            clear={clear}
        />

        <PageLoader isLoading={isLoading} />

        {noCoachesFound && <EmptyListMessage>No Coaches Found</EmptyListMessage>}

        {!!showTable && <>
            <TotalCount plural="coaches" singular="coach" totalCount={totalCoaches} filteredCount={coaches.data.length} />

            <InfiniteScroll
                dataLength={coaches.data.length}
                next={next}
                hasMore={hasMore}
                loader={<InfiniteScrollSpinner enabled/>}
                endMessage={<InfiniteScrollEndMessage text="You've reached the end of the coaches list."/>}
            >
                <Table striped borderless>
                    <thead>
                        <tr>
                            <th scope="col">Coach</th>
                            <th scope="col">External ID</th>
                            <th scope="col">League</th>
                        </tr>
                    </thead>
                    <tbody>

                        {coaches.data.map(({ externalId, ...rest }) => (
                            <Coach key={externalId} id={externalId} externalId={externalId} {...rest} />
                        ))}

                    </tbody>
                </Table>
            </InfiniteScroll>

        </>}

    </ProtectedLayout>

}