import { connect } from "react-redux";

import {
  getListSearchNextQuery,
  getSearchCurrentQuery,
  getListSearchQueryIsDirty,
  getListIsLoading
} from "@/redux/teamRosterExtender/selectors";

import actions from "@/redux/teamRosterExtender/actions";

import SearchBar from "./SearchBar";

const mapStateToProps = state => ({
  nextQuery: getListSearchNextQuery(state),
  currentQuery: getSearchCurrentQuery(state),
  isDirty: getListSearchQueryIsDirty(state),
  isLoading: getListIsLoading(state)
});

const mapDispatchToProps = (dispatch, { action }) => ({
  setQuery: query => dispatch(actions.search.setNextQuery(query)),
  commit: () => dispatch(actions.search.commit({ action })),
  clear: () => dispatch(actions.search.clear())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBar);
