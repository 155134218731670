import { useCallback } from "react";
import { config } from "@/config";
import { getEncodedAccessToken } from "@/redux/token/selectors";
import { useSelector } from "react-redux";

export function useDivisionActions(seasonId, divisionId) {

    const accessToken = useSelector(getEncodedAccessToken);

    const sendAction = useCallback((action) => {
        return fetch(`${config.BFF_API}/seasons/${seasonId}/divisions/${divisionId}/${action}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({}),
        }).then((response) => {
            if (!response.ok) {
                throw new Error(response.statusText);
            }
        }).catch((error) => {
            console.error("Error:", error);
        });

    }, [accessToken]);

    return {
        lock: useCallback(() => sendAction("lock-teams"), [sendAction]),
        unlock: useCallback(() => sendAction("unlock-teams"), [sendAction]),
    }

}