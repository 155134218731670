import { handleActions, combineActions } from "redux-actions";

// The original component this was used for has been replaced by a 16.8 component.
// Everything here is unused except for seasonId and urlSearchParams

import actions from "./actions";

const initialState = {
  scheduledDateFrom: "",
  scheduledDateTo: "",
  divisionIds: [],
  query: "",
  seasonId: "",
  urlSearchParams: ""
};

function reduceFieldChanged(fieldName) {
  return (state, { payload: { value } }) => ({ ...state, [fieldName]: value });
}

function reduceUrlSearchParams(state, { payload: { urlSearchParams, seasonId } }) {
  return { ...state, urlSearchParams: urlSearchParams, seasonId: seasonId };
}

export default handleActions(
  {
    [actions.setScheduledDateFrom]: reduceFieldChanged("scheduledDateFrom"),
    [actions.setScheduledDateTo]: reduceFieldChanged("scheduledDateTo"),
    [actions.setDivisionIds]: reduceFieldChanged("divisionIds"),
    [actions.setQuery]: reduceFieldChanged("query"),
    [actions.setUrlSearchParams]: reduceUrlSearchParams,
    [combineActions(actions.clear, actions.reset)]: () => ({ ...initialState })
  },
  initialState
);
