import { connect } from "react-redux";

import { getRefereesMergeToolStatus } from "@/redux/referees/selectors";
import { refereesActions } from "@/redux/referees/actions";

import RefereesListMergeTool from "./MergeTool";

const mapStateToProps = state => ({
  status: getRefereesMergeToolStatus(state)
});

const mapDispatchToProps = dispatch => ({
  setStatus: status => dispatch(refereesActions.list.mergeTool.setStatus(status))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RefereesListMergeTool);
