import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Input } from "reactstrap";

class ShotsInput extends PureComponent {
  static propTypes = {
    goalieId: PropTypes.string.isRequired,
    period: PropTypes.string.isRequired,
    shots: PropTypes.number.isRequired,
    onChange: PropTypes.func
  };

  static defaultProps = {
    onChange() {}
  };

  handleChange = ({ target: { value } }) => {
    const { goalieId, period, onChange } = this.props;

    onChange({ goalieId, period, shots: value });
  };

  render() {
    const { shots } = this.props;

    return <Input type="number" value={shots} onChange={this.handleChange} min="0" />;
  }
}

export default ShotsInput;
