import _capitalize from "lodash/capitalize";
import _isEqual from "lodash/isEqual";

const selectRoot = state => state.roleForm;
const selectAssociationList = state => selectRoot(state).associationList;
const selectLeagueList = state => selectRoot(state).leagueList;
const selectSeasonList = state => selectRoot(state).seasonList;
const selectDivisionList = state => selectRoot(state).divisionList;
const selectTeamList = state => selectRoot(state).teamList;

// Global User
export const getRoleIsGlobal = state => selectRoot(state).roleIsGlobal;

// Associations
export const getSelectedAssociations = state => selectRoot(state).selectedAssociations || []; // New selector for selected associations

export const getAssociationList = (state) => {
  const { ids, data } = selectAssociationList(state) || {};
  const selectedAssociations = getSelectedAssociations(state);

  const associationGroups = ids.map(id => {
    const associationData = data[id] || {};
    const title = associationData.title || `Association ${id}`;

    const items = [{ 
      ...associationData, 
      id, 
      title, 
      isSelected: selectedAssociations.includes(id) 
    }];

    return {
      groupTitle: title,
      items,
    };
  });

  return associationGroups;
};

export const getAllAssociationList = state => {
  const { ids } = selectAssociationList(state);
  return ids || []; // Return all association IDs
};

export const getAssociationListIsLoaded = state => selectAssociationList(state).isLoaded;

export const getAssociationListIsLoading = state => selectAssociationList(state).isLoading;

export const getAssociationIdsNotSelected = (state) => {
  const associations = getAssociationList(state); // Retrieve all associations, possibly grouped
  const selectedAssociations = getSelectedAssociations(state); // Array of selected association IDs

  const notSelectedIds = associations
    .flatMap(group => group.items) // Flatten items from all groups
    .filter(item => !selectedAssociations.includes(item.id)) // Filter out selected associations
    .map(item => item.id); // Map to get only `id`s

  return notSelectedIds;
};

// Leagues
export const getSelectedLeagues = state => selectRoot(state).selectedLeagues || []; // New selector for selected leagues

export const getLeagueList = (state, associationIds) => {
  if (!associationIds || associationIds.length === 0) return [];
  const selectedLeagues = getSelectedLeagues(state);

  const leagueGroups = associationIds.map(associationId => {
    const associationData = selectLeagueList(state)[associationId] || {};
    const ids = associationData.ids || [];
    const data = associationData.data || {};
    const associationTitle = getAssociationTitle(state, associationId) || `Association ${associationId}`; // Get a unique fallback title for each association

    const items = ids.map(id => {
      const leagueData = data[id] || {};
      const leagueTitle = leagueData.title || `League ${id}`;  // Ensure each league has a unique title

      return {
        ...leagueData,
        id: id,
        title: leagueTitle,
        isSelected: selectedLeagues.includes(id),
      };
    }).filter(item => item.title);

    return {
      groupTitle: associationTitle,
      items,
    };
  }).filter(group => group.items.length > 0 || group.groupTitle);

  return leagueGroups;
};

export const getLeagueListIsLoaded = (state, associationIds) => {
  const associationsArray = Array.isArray(associationIds) ? associationIds : [associationIds];
  if (!associationsArray || associationsArray.length === 0) return false; // If no associations selected, return true
  return associationsArray.every(associationId => !!(selectLeagueList(state)[associationId] || {}).isLoaded);
};

export const getLeagueListIsLoading = (state, associationIds) => {
  const associationsArray = Array.isArray(associationIds) ? associationIds : [associationIds];
  if (!associationsArray || associationsArray.length === 0) return false; // If no associations selected, return false
  return associationsArray.some(associationId => !!(selectLeagueList(state)[associationId] || {}).isLoading);
};

export const getLeagueIdsNotSelected = (state) => {
  const selectedAssociationIds = getSelectedAssociations(state);
  if (!selectedAssociationIds || selectedAssociationIds.length === 0) {
    return [];
  }

  const selectedLeagues = getSelectedLeagues(state) || [];
  const leagues = getLeagueList(state, selectedAssociationIds); // Retrieve all leagues for the selected associations

  const notSelectedIds = leagues
    .flatMap(group => group.items) // Flatten all items in all groups
    .filter(item => !selectedLeagues.includes(item.id)) // Filter items not selected
    .map(item => item.id); // Map to get only `id`s

  return notSelectedIds;
};

// Seasons
export const getSelectedSeasons = state => selectRoot(state).selectedSeasons || []; 

export const getSeasonList = (state, leagueIds) => {
  if (!leagueIds || leagueIds.length === 0) return [];
  const selectedSeasons = getSelectedSeasons(state);

  const seasonGroups = leagueIds.map(leagueId => {
    const leagueData = selectSeasonList(state)[leagueId] || {};
    const ids = leagueData.ids || [];
    const data = leagueData.data || {};
    const leagueTitle = getLeagueTitle(state, leagueId) || `League ${leagueId}`; // Use getLeagueTitle to retrieve league title

    const items = ids.map(id => {
      const seasonData = data[id] || {};
      return {
        ...seasonData,
        id: id,
        title: seasonData.title || '',
        isSelected: selectedSeasons.includes(id),
      };
    }).filter(item => item.title);

    return {
      groupTitle: leagueTitle,
      items,
    };
  }).filter(group => group.items.length > 0 || group.groupTitle);

  return seasonGroups;
};

export const getSeasonListIsLoaded = (state, leagueIds) => {
  const leaguesArray = Array.isArray(leagueIds) ? leagueIds : [leagueIds];
  if (!leaguesArray || leaguesArray.length === 0) return false; // If no leagues selected, return true
  return leaguesArray.every(leagueId => !!(selectSeasonList(state)[leagueId] || {}).isLoaded);
};

export const getSeasonListIsLoading = (state, leagueIds) => {
  const leaguesArray = Array.isArray(leagueIds) ? leagueIds : [leagueIds];
  if (!leaguesArray || leaguesArray.length === 0) return false; // If no leagues selected, return false
  return leaguesArray.some(leagueId => !!(selectSeasonList(state)[leagueId] || {}).isLoading);
};

export const getSeasonIdsNotSelected = (state) => {
  const selectedLeagueIds = getSelectedLeagues(state);
  if (!selectedLeagueIds || selectedLeagueIds.length === 0) {
    return [];
  }

  const selectedSeasons = getSelectedSeasons(state) || [];
  const seasons = getSeasonList(state, selectedLeagueIds); // Retrieve all seasons for the selected leagues

  const notSelectedIds = seasons
    .flatMap(group => group.items) // Flatten all items from all groups
    .filter(item => !selectedSeasons.includes(item.id)) // Filter items not selected
    .map(item => item.id); // Map to get only `id`s

  return notSelectedIds;
};

// Divisions
export const getSelectedDivisions = state => selectRoot(state).selectedDivisions || []; 

export const getDivisionList = (state, seasonIds) => {
  if (!seasonIds || seasonIds.length === 0) return [];
  const selectedDivisions = getSelectedDivisions(state);

  const divisionGroups = seasonIds.map(seasonId => {
    const seasonData = selectDivisionList(state)[seasonId] || {};
    const ids = seasonData.ids || [];
    const data = seasonData.data || {};
    const seasonTitle = getSeasonTitle(state, seasonId) || `Season ${seasonId}`; // Use getSeasonTitle to retrieve season title

    const items = ids.map(id => {
      const divisionData = data[id] || {};
      return {
        ...divisionData,
        id: id,
        title: divisionData.title || '',
        isSelected: selectedDivisions.includes(id),
      };
    }).filter(item => item.title);

    return {
      groupTitle: seasonTitle,
      items,
    };
  }).filter(group => group.items.length > 0 || group.groupTitle);

  return divisionGroups;
};

export const getDivisionListIsLoaded = (state, seasonIds) => {
  const seasonsArray = Array.isArray(seasonIds) ? seasonIds : [seasonIds];
  if (!seasonsArray || seasonsArray.length === 0) return false; // If no seasons selected, return false
  return seasonsArray.every(seasonId => !!(selectDivisionList(state)[seasonId] || {}).isLoaded);
};

export const getDivisionListIsLoading = (state, seasonIds) => {
  const seasonsArray = Array.isArray(seasonIds) ? seasonIds : [seasonIds];
  if (!seasonsArray || seasonsArray.length === 0) return false; // If no seasons selected, return false
  return seasonsArray.some(seasonId => !!(selectDivisionList(state)[seasonId] || {}).isLoading);
};

export const getDivisionIdsNotSelected = (state) => {
  const selectedSeasonIds = getSelectedSeasons(state);
  if (!selectedSeasonIds || selectedSeasonIds.length === 0) {
    return [];
  }

  const selectedDivisions = getSelectedDivisions(state) || [];
  const divisions = getDivisionList(state, selectedSeasonIds); // Retrieve all divisions for the selected seasons

  const notSelectedIds = divisions
    .flatMap(group => group.items) // Flatten all items from all groups
    .filter(item => !selectedDivisions.includes(item.id)) // Filter items not selected
    .map(item => item.id); // Map to get only `id`s

  return notSelectedIds;
};

// Teams
export const getSelectedTeams = state => selectRoot(state).selectedTeams || []; // New selector for selected teams

export const getTeamList = (state, divisionIds) => {
  if (!divisionIds || divisionIds.length === 0) return [];
  const selectedTeams = getSelectedTeams(state);

  const teamGroups = divisionIds.map(divisionId => {
    const divisionData = selectTeamList(state)[divisionId] || {};
    const ids = divisionData.ids || [];
    const data = divisionData.data || {};
    const divisionTitle = getDivisionTitle(state, divisionId) || `Division ${divisionId}`; // Use getDivisionTitle to retrieve division title

    const items = ids.map(id => {
      const teamData = data[id] || {};
      return {
        ...teamData,
        id: id,
        title: teamData.title || '',
        isSelected: selectedTeams.includes(id),
      };
    }).filter(item => item.title);

    return {
      groupTitle: divisionTitle,
      items,
    };
  }).filter(group => group.items.length > 0 || group.groupTitle);

  return teamGroups;
};

export const getTeamListIsLoaded = (state, divisionIds) => {
  const divisionsArray = Array.isArray(divisionIds) ? divisionIds : [divisionIds];
  if (!divisionsArray || divisionsArray.length === 0) return false; // If no divisions selected, return true
  return divisionsArray.every(divisionId => !!(selectTeamList(state)[divisionId] || {}).isLoaded);
};

export const getTeamListIsLoading = (state, divisionIds) => {
  const divisionsArray = Array.isArray(divisionIds) ? divisionIds : [divisionIds];
  if (!divisionsArray || divisionsArray.length === 0) return false; // If no divisions selected, return false
  return divisionsArray.some(divisionId => !!(selectTeamList(state)[divisionId] || {}).isLoading);
};

export const getTeamIdsNotSelected = (state) => {
  const selectedDivisionIds = getSelectedDivisions(state);
  if (!selectedDivisionIds || selectedDivisionIds.length === 0) {
    return [];
  }

  const selectedTeams = getSelectedTeams(state) || [];
  const teams = getTeamList(state, selectedDivisionIds); // Retrieve all teams for the selected divisions

  const notSelectedIds = teams
    .flatMap(group => group.items) // Flatten all items from all groups
    .filter(item => !selectedTeams.includes(item.id)) // Filter out selected teams
    .map(item => item.id); // Map to get only `id`s

  return notSelectedIds;
};

// Title

export const getTitle = state => selectRoot(state).title;

// Misc

export const getLastLevel = state => {
  const levels = selectRoot(state).levels;
  return levels.length > 0 ? levels[levels.length - 1] : null;
};

export const getLevel = state => {
  const isGlobal = getRoleIsGlobal(state);
  
  if (isGlobal) {
    return {
      id: "*",
      type: ""
    }
  } else {
    return getLastLevel(state);
  }
};

export const getInitRole = state => selectRoot(state).initRole;

export const getRole = state => {
  const title = getTitle(state);
  const level = getLevel(state);
  const isGlobal = getRoleIsGlobal(state);

  return { title, level, isGlobal };
};

export const getIsValid = state => {
  const { title, level } = getRole(state);

  return title !== "" && level !== null;
};

export const getIsInvalid = state => !getIsValid(state);

export const getFullRoleName = (state, action) => {
  const isValid = getIsValid(state);

  if (!isValid) {
    return "";
  }

  const { title, level, isGlobal } = getRole(state);

  if (isGlobal) {
    return `Global ${_capitalize(title)}`;
  } else {
    return `${_capitalize(level.type.replace(/s$/, ""))} ${_capitalize(title)}`;
  }
};

export const getIsVisible = state => selectRoot(state).isVisible;

export const getIsInitializing = state => selectRoot(state).isInitializing;

export const getAssociationTitle = (state, id) => (selectAssociationList(state).data[id] || {}).title || "";

export const getLeagueTitle = (state, id) => {
  const leagues = Object.values(selectLeagueList(state)).reduce((result, { data }) => ({ ...result, ...data }), {});

  return (leagues[id] || {}).title || "";
};

export const getSeasonTitle = (state, id) => {
  const seasons = Object.values(selectSeasonList(state)).reduce((result, { data }) => ({ ...result, ...data }), {});

  return (seasons[id] || {}).title || "";
};

export const getDivisionTitle = (state, id) => {
  const divisions = Object.values(selectDivisionList(state)).reduce((result, { data }) => ({ ...result, ...data }), {});

  return (divisions[id] || {}).title || "";
};

export const getTeamTitle = (state, id) => {
  const teams = Object.values(selectTeamList(state)).reduce((result, { data }) => ({ ...result, ...data }), {});

  return (teams[id] || {}).title || "";
};

export const getResourceTitle = state => {
  const { level, isGlobal } = getRole(state);

  if (!level || isGlobal) {
    return null;
  }

  const { type, id } = level;

  switch (type) {
    case "associations":
      return getAssociationTitle(state, id);
    case "leagues":
      return getLeagueTitle(state, id);
    case "seasons":
      return getSeasonTitle(state, id);
    case "divisions":
      return getDivisionTitle(state, id);
    case "teams":
      return getTeamTitle(state, id);
    default:
      throw new Error(`Unable to obtain title for unknown resource type ${type}`);
  }
};

export const getIsPristine = state => _isEqual(getRole(state), getInitRole(state));
