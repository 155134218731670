
import React from "react";
import { FormGroup, Button, CustomInput } from "reactstrap";

import AbilityButton from "@/components/AbilityButton";
import ContentSection from "@/components/ContentSection";
import { Can } from "@/components/Ability";
import { useCloneSeasonForm } from "./hooks";
import DashboardForm from "@/components/DashboardForm";
import DashboardFormFooter from "@/components/DashboardFormFooter";

export const CloneSeasonForm = () => {
    const {
        season,
        seasonLeagueId,
        isSubmitting,
        newSeasonTitle,
        cloneIpadKeys,
        cloneDivisions,
        cloneTeams,
        cloneTeamRosters,
        cloneSeasonRoster,
        cloneReferees,
        clonePrototeams,
        setIpadKeysValue,
        setDivisionsValue,
        setTeamsValue,
        setTeamRostersValue,
        setSeasonRosterValue,
        setRefereesValue,
        setPrototeamsValue,
        submit,
        cancel
    } = useCloneSeasonForm();

    return (
        <DashboardForm
            namespace="season"
            className="season-form"
            isOperating={isSubmitting}
            onSubmit={submit}
        >
            <ContentSection>
                <p>Clone "{season.title}" season settings into new "{newSeasonTitle}" season?</p>
                <FormGroup style={{ marginBottom: "1rem" }}>
                    <CustomInput
                        id={`clone-season__ipad-keys__toggle`}
                        type="switch"
                        label={<span>Scoring Access Keys</span>}
                        checked={cloneIpadKeys}
                        onChange={(e) => setIpadKeysValue(e.target.checked)}
                    />
                    <CustomInput
                        id={`clone-season__referees__toggle`}
                        type="switch"
                        label={<span>Referees</span>}
                        checked={cloneReferees}
                        onChange={(e) => setRefereesValue(e.target.checked)}
                    />
                    <CustomInput
                        id={`clone-season__seasonRoster__toggle`}
                        type="switch"
                        label={<span>Season Roster</span>}
                        checked={cloneSeasonRoster}
                        onChange={(e) => setSeasonRosterValue(e.target.checked)}
                    />
                </FormGroup>
                <FormGroup>
                    <CustomInput
                        id={`clone-season__divisions__toggle`}
                        type="switch"
                        label={<span>Divisions</span>}
                        checked={cloneDivisions}
                        onChange={(e) => setDivisionsValue(e.target.checked)}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ marginRight: "20px" }}>
                        <CustomInput
                            id={`clone-season__teams__toggle`}
                            type="switch"
                            label={<span>Teams</span>}
                            checked={cloneTeams}
                            onChange={(e) => setTeamsValue(e.target.checked)}
                        />
                        </div>
                        <div>
                        <CustomInput
                            id={`clone-season__prototeams__toggle`}
                            type="checkbox"
                            label={
                            <span style={{ color: cloneTeams ? "inherit" : "#999999" }}>
                                Keep Old Prototeams
                            </span>
                            }
                            checked={clonePrototeams}
                            disabled={!cloneTeams}
                            onChange={(e) => setPrototeamsValue(e.target.checked)}
                        />
                        </div>
                    </div>
                    <div style={{ marginLeft: "20px" }}>
                        <CustomInput
                        id={`clone-season__teamRosters__toggle`}
                        type="switch"
                        label={<span>Team Rosters</span>}
                        checked={cloneTeamRosters}
                        onChange={(e) => setTeamRostersValue(e.target.checked)}
                        />
                    </div>
                </FormGroup>
                <DashboardFormFooter>
                    <div className="dashboard-form-footer__secondary">
                        <Button
                            color={"secondary"}
                            size="sm"
                            onClick={cancel}
                        >
                            Cancel
                        </Button>
                    </div>
                    <div className="dashboard-form-footer__primary">
                        <AbilityButton
                            subject={{ type: "seasons", seasonLeagueId }}
                            action="create"
                            type="submit"
                            color="success"
                            outline={false}
                            disabled={false}
                        >
                            Clone
                        </AbilityButton>
                    </div>
                </DashboardFormFooter>
            </ContentSection>
        </DashboardForm>
    )
}