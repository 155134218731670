import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

export function Coach({ id, firstName, lastName, externalId, leagues }) {
  const fullName = `${firstName} ${lastName}`;

  return (
    <tr>
      <td>
        <Link
          subject={{ type: "penaltyReports", coachId: id }}
          action="read"
          to={`/reports/bench-staff-penalty/${externalId}`}
        >
          {fullName}
        </Link>
      </td>
      <td>{externalId}</td>
      <td>
        {leagues.map((league, index) => (
          <div key={league}>
            {league}
            {index + 1 < leagues.length && ","}
          </div>
        ))}
      </td>
    </tr>
  );
}

Coach.propTypes = {
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  externalId: PropTypes.string.isRequired,
  leagues: PropTypes.arrayOf(PropTypes.string)
};

Coach.defaultProps = {
  leagues: []
};

export default Coach;
