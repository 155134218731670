import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getIsVisible as getRoleFormIsVisible } from "@/redux/roleForm/selectors";

import formActions from "@/redux/invitationForm/actions";
import roleFormActions from "@/redux/roleForm/actions";

export default function useRoleForm() {
  const dispatch = useDispatch();
  const roleFormIsVisible = useSelector(getRoleFormIsVisible);

  const toggleRole = useCallback(
    ({ initRole, role, resourceTitle }) => {
      dispatch(
        initRole
          ? formActions.changeRole({ initRole, role, resourceTitle })
          : formActions.addRole({ role, resourceTitle })
      );
    },
    [dispatch]
  );

  const removeRole = useCallback(role => dispatch(formActions.removeRole(role)), [dispatch]);

  useEffect(
    () => {
      return () => dispatch(roleFormActions.reset());
    },
    [dispatch]
  );

  return {
    roleFormIsVisible,
    toggleRole,
    removeRole
  };
}
