export default function buildSeasonMember(member = {}, props) {
  const { firstName, lastName, externalId } = member;

  return {
    firstName,
    lastName,
    externalId,
    ...props
  };
}
