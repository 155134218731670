import _orderBy from "lodash/orderBy";

const selectRoot = state => state.trainerVisitsReport;

export const getTrainerVisitsReportIsLoaded = state => selectRoot(state).isLoaded;

export const getTrainerVisitsReportIsLoading = state => selectRoot(state).isLoading;

export const getTrainerVisitsReportIsForbidden = state => selectRoot(state).isForbidden;

export const getTrainerVisitsReportSummaryStats = state => {
  const { visits, summaryStats } = selectRoot(state);

  return {
    ...summaryStats,
    visits: visits.reduce((count, { visits }) => count + visits, 0)
  };
};

export const getTrainerVisitsReportVisitList = state => {
  const divisions = selectRoot(state).divisions;
  const seasons = selectRoot(state).seasons;

  const visits = _orderBy(
    selectRoot(state).visits.map(({ divisionId: id, ...rest }) => ({
      ...divisions[id],
      id,
      ...rest
    })),
    "visits",
    "desc"
  );

  const groupedVisits = Object.entries(seasons).reduce(
    (result, [id, { title }]) => [
      ...result,
      {
        season: { id, title },
        divisions: visits.filter(({ seasonId }) => seasonId === id)
      }
    ],
    []
  );

  return _orderBy(groupedVisits, ({ divisions }) => divisions.reduce((count, { visits }) => count + visits, 0), "desc");
};
