import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import actions from "@/redux/accountVerificationForm/actions";
import { getField } from "@/redux/accountVerificationForm/selectors";

export default function useField(name) {
  const dispatch = useDispatch();
  const { value, isInvalid, errors } = useSelector(state => getField(state, name));

  const setValue = useCallback(value => dispatch(actions.changeField({ name, value })), [dispatch, name]);

  return {
    value,
    isInvalid,
    errors,
    setValue
  };
}
