import { handleActions } from "redux-actions";

import penaltyIsMajor from "@/utils/penaltyIsMajor";

import { refereeReportLoadingRoutine } from "../routines";
import actions from "../actions";

const initialState = {
  isLoaded: false,
  isLoading: false,
  isForbidden: false,
  careerStats: {
    gamesRefereed: 0,
    avgPenaltyMinutesPerGame: 0,
    mostFreqAssessedPenaltyCode: undefined,
    mostFreqAssessedPenaltyCount: 0
  },
  referee: {
    firstName: undefined,
    lastName: undefined,
    externalId: undefined
  },
  majorPenalties: [],
  games: {},
  seasons: {}
};

function reduceCareerStats(report) {
  const {
    gamesRefereed,
    averagePenaltyMinutesPerGameRefereed,
    mostFrequentPenaltyCode,
    mostFrequentPenaltiesNumber
  } = report;

  return {
    gamesRefereed,
    avgPenaltyMinutesPerGame: averagePenaltyMinutesPerGameRefereed,
    mostFreqAssessedPenaltyCode: mostFrequentPenaltyCode,
    mostFreqAssessedPenaltyCount: mostFrequentPenaltiesNumber
  };
}

function reduceMajorPenalties(games) {
  return games
    .reduce(
      (result, { id, penalties }) => [
        ...result,
        ...((penalties || []).map(penalty => ({
          gameId: id,
          ...penalty
        })) || [])
      ],
      []
    )
    .filter(penalty => penaltyIsMajor(penalty))
    .map((penalty, index) => {
      const { code, gameId, length, offTime, period, penalized } = penalty;

      const id = [gameId, period, offTime, code, penalized.type, penalized.id, index].join("-");

      return {
        id,
        code,
        gameId,
        length,
        period
      };
    });
}

function reduceGames(games) {
  return games.reduce(
    (result, { id, startTime, home, visitor, season }) => ({
      ...result,
      [id]: {
        startTime,
        homeId: home.id,
        visitorId: visitor.id,
        seasonId: season.id
      }
    }),
    {}
  );
}

function reduceTeam({ title }) {
  return { title };
}

function reduceTeams(games) {
  return games.reduce(
    (result, { home, visitor }) => ({
      ...result,
      [home.id]: reduceTeam(home),
      [visitor.id]: reduceTeam(visitor)
    }),
    {}
  );
}

function reduceSeasons(games) {
  return games.reduce(
    (result, { season: { id, title, type } }) => ({
      ...result,
      [id]: { title, isArchived: type === "archivedSeasons" }
    }),
    {}
  );
}

function reduceReferee(referees) {
  const { firstName, lastName, externalId } = referees[0];

  return { firstName, lastName, externalId };
}

function reduceLoadingRequest(state) {
  return { ...state, isLoading: true };
}

function reduceLoadingSuccess(state, { payload: { report } }) {
  const careerStats = reduceCareerStats(report);
  const majorPenalties = reduceMajorPenalties(report.games);
  const games = reduceGames(report.games);
  const teams = reduceTeams(report.games);
  const seasons = reduceSeasons(report.games);
  const referee = reduceReferee(report.referees);

  return {
    ...state,
    careerStats,
    majorPenalties,
    games,
    teams,
    seasons,
    referee,
    isLoaded: true
  };
}

function reduceLoadingFailure(state, { payload: { responseStatus } }) {
  return { ...state, isLoaded: false, isForbidden: responseStatus === 401 };
}

function reduceLoadingFulfill(state) {
  return { ...state, isLoading: false };
}

function reduceClearing() {
  return { ...initialState };
}

export default handleActions(
  {
    [refereeReportLoadingRoutine.REQUEST]: reduceLoadingRequest,
    [refereeReportLoadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [refereeReportLoadingRoutine.FAILURE]: reduceLoadingFailure,
    [refereeReportLoadingRoutine.FULFILL]: reduceLoadingFulfill,
    [actions.report.clear]: reduceClearing
  },
  initialState
);
