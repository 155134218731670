import { createActions } from "redux-actions";

const { completedGamesFilter: actions } = createActions({
  COMPLETED_GAMES_FILTER: {
    SET_DATE_FROM: undefined,
    SET_DATE_TO: undefined,
    SET_VIEWED_STATUS: undefined,
    SET_DIVISION_IDS: undefined,
    SET_FLAGGED_STATUS: undefined,
    SET_QUERY: undefined,
    SET_GAMETYPE: undefined,
    CLEAR: undefined,
    RESET: undefined
  }
});

export default actions;
