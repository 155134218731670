import { handleActions } from "redux-actions";

import { invitationFormActions } from "../../actions";

const defaultState = {
  isOpen: false,
  association: null,
  league: null,
  season: null,
  division: null,
  teams: []
};

const open = () => Object.assign({}, defaultState, { isOpen: true });
const close = () => Object.assign({}, defaultState, { isOpen: false });

const toggleOpened = state => Object.assign({}, defaultState, { isOpen: !state.isOpen });

const setAssociation = (state, { payload }) =>
  Object.assign({}, state, {
    association: payload,
    league: null,
    season: null,
    division: null
  });

const setLeague = (state, { payload }) =>
  Object.assign({}, state, {
    league: payload,
    season: null,
    division: null
  });

const setSeason = (state, { payload }) =>
  Object.assign({}, state, {
    season: payload,
    division: null
  });

const setDivision = (state, { payload }) =>
  Object.assign({}, state, {
    division: payload
  });

const selectTeam = (state, { payload }) =>
  Object.assign({}, state, {
    teams: [...state.teams, payload]
  });

const deselectTeam = (state, { payload }) =>
  Object.assign({}, state, {
    teams: state.teams.filter(id => id !== payload)
  });

export default handleActions(
  {
    [invitationFormActions.teamsList.open]: open,
    [invitationFormActions.teamsList.close]: close,
    [invitationFormActions.teamsList.toggleOpened]: toggleOpened,
    [invitationFormActions.teamsList.selectAssociation]: setAssociation,
    [invitationFormActions.teamsList.selectLeague]: setLeague,
    [invitationFormActions.teamsList.selectSeason]: setSeason,
    [invitationFormActions.teamsList.selectDivision]: setDivision,
    [invitationFormActions.teamsList.selectTeam]: selectTeam,
    [invitationFormActions.teamsList.deselectTeam]: deselectTeam
  },
  defaultState
);
