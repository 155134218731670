import createOffsetPaginationReducer from "@/redux/common/pagination/createOffsetPaginationReducer";

import { listLoadingRoutine } from "../routines";
import actions from "../actions";

import invitations from "./invitations";

const pagination = createOffsetPaginationReducer(listLoadingRoutine, actions.clear);

export { invitations, pagination };
