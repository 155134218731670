import { connect } from "react-redux";

import { getMeta } from "@/redux/teamRoster/selectors";

import { lockingRoutine } from "@/redux/teamRoster/routines";

import TeamRosterShow from "./TeamRosterShow";

const mapStateToProps = state => ({
  isRosterLocking: getMeta(state).isRosterLocking
});

const mapDispatchToProps = dispatch => ({
  lockRosters: payload => dispatch(lockingRoutine.trigger(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamRosterShow);
