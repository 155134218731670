import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import CoachesListMergeToolTable from "./Table";
import CoachesListMergeToolProgress from "./Progress";
import CoachesListMergeToolFailure from "./Failure";

class CoachesListMergeTool extends PureComponent {
  static propTypes = {
    status: PropTypes.string,
    setStatus: PropTypes.func
  };

  static defaultProps = {
    status: null,
    setStatus() {}
  };

  returnToCoachSelection = () => {
    this.props.setStatus("selecting-coaches");
  };

  render() {
    const { status } = this.props;

    switch (status) {
      case "choosing-winner":
        return (
          <Fragment>
            <Button color="link" onClick={this.returnToCoachSelection}>
              ← Back to coach selection
            </Button>

            <h1>Which coach would you like to keep?</h1>
            <p className="text-secondary">
              Select the coach you want to keep and we’ll merge the other coaches into this coach.
            </p>

            <CoachesListMergeToolTable />
          </Fragment>
        );
      case "merging":
        return <CoachesListMergeToolProgress />;
      case "failure":
        return <CoachesListMergeToolFailure />;
      default:
        return null;
    }
  }
}

export default CoachesListMergeTool;
