import React, { useState } from "react";
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { useSelector } from "react-redux";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";
import { Can } from "@/components/Ability";
import { getEncodedAccessToken } from "@/redux/token/selectors";
import { config } from "@/config";

export const ExternalImportButton = ({ startOpen = false, divisionId }) => {
  const [isOpen, setIsOpen] = useState(startOpen);
  const toggleIsOpen = () => setIsOpen(!isOpen);

  const tokenValue = useSelector(getEncodedAccessToken);

  const { seasonId } = useCurrentSeasonContext();

  const getRegistryURL = registry => {
    const coreAddr = config.SSO_REGISTRY_URL || "gs-dashboard-dev.web.app";

    let baseURL = "";
    switch (registry.toUpperCase()) {
      case "HCR":
        baseURL = `https://${coreAddr}/registry/hcr`;
        break;
      case "USAH":
        baseURL = `https://${coreAddr}/registry/usah`;
        break;
      default:
        return "";
    }

    const params = `?SEASON=${seasonId}&${!!divisionId ? `DIVISION=${divisionId}&` : ""}ACCESS_TOKEN=${tokenValue}`;

    return baseURL + params;
  };

  return (
    <Can I="update" this={{ type: "all" }}>
      <ButtonDropdown isOpen={isOpen} toggle={toggleIsOpen}>
        <DropdownToggle caret size="sm" color="success" outline>
          Import
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={() => {
              window.location.href = getRegistryURL("hcr");
            }}
            size="sm"
            color="primary"
          >
            From HCR
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              window.location.href = getRegistryURL("usah");
            }}
            size="sm"
            color="primary"
          >
            From USAH
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    </Can>
  );
};
