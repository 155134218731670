import { handleActions } from "redux-actions";

import { loadGameFormRoutine } from "../routines";
import actions from "../actions";

const hydratePenalties = list =>
  list.reduce(
    (result, penalty, index) => ({
      ...result,
      [index + 1]: penalty
    }),
    {}
  );

const initialState = {
  home: {},
  visitor: {}
};

const addPenalty = (state, { payload: { team } }) => {
  const ids = Object.keys(state[team]).map(id => Number(id));
  const id = ids.length > 0 ? Math.max(...ids) + 1 : 1;

  return {
    ...state,
    [team]: {
      ...state[team],
      [id]: {
        period: "1",
        penalized: null,
        servedById: "",
        length: "",
        code: "",
        offTime: "",
        startTime: "",
        onTime: ""
      }
    }
  };
};

const init = (state, { payload: { game } }) => {
  const { homePenalties, visitorPenalties } = game;

  return {
    home: hydratePenalties(homePenalties || []),
    visitor: hydratePenalties(visitorPenalties || [])
  };
};

const changePenalty = (state, { payload: { id, team, field, value } }) => ({
  ...state,
  [team]: {
    ...state[team],
    [id]: {
      ...state[team][id],
      [field]: value
    }
  }
});

const removePenalty = (state, { payload: { team, id } }) => ({
  ...state,
  [team]: Object.entries(state[team])
    .filter(([penaltyId]) => penaltyId !== id)
    .reduce((result, [id, data]) => ({ ...result, [id]: data }), {})
});

export default handleActions(
  {
    [loadGameFormRoutine.SUCCESS]: init,
    [actions.changePenalty]: changePenalty,
    [actions.addPenalty]: addPenalty,
    [actions.removePenalty]: removePenalty,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
