import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getGameFormTeamTitle, getGameFormTeamShootouts } from "@/redux/gameForm/selectors";

import actions from "@/redux/gameForm/actions";

export default function useShootoutFieldset(team) {
  const dispatch = useDispatch();

  const teamTitle = useSelector(state => getGameFormTeamTitle(state, team));
  const shootouts = useSelector(state => getGameFormTeamShootouts(state, team));

  const addShootout = useCallback(() => dispatch(actions.addShootout({ team })), [dispatch, team]);

  return {
    teamTitle,
    shootouts,
    addShootout
  };
}
