import React, { useCallback } from "react";

import { Input } from "reactstrap";

import useQueryInput from "./hooks/useQueryInput";

function QueryInput() {
  const { value, setValue } = useQueryInput();
  const onChange = useCallback(({ target: { value } }) => setValue(value), [setValue]);

  return <Input placeholder="Search users by name or email" type="text" value={value} onChange={onChange} />;
}

export default QueryInput;
