import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormGroup, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function StatusInput(props) {
  const { player, onChange } = props;
  const mustBePlaying = !player.canRemove && player.status === "playing";
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const handleChange = e => {
    const val = e.target.value;
    if (mustBePlaying && val !== "starting" && val !== "playing") {
      setModal(true);
    } else {
      onChange(player.id, e);
    }
  };

  return (
    <FormGroup className="col-md-2">
      <Input
        id={`status-${player.id}`}
        type="select"
        name="status"
        value={player.starting ? "starting" : player.status}
        onChange={handleChange}
      >
        <option value={"starting"}>Starting</option>
        <option value={"playing"}>Playing</option>
        <option value={"sitting_out"} className={mustBePlaying ? "disabled" : ""}>
          Sitting out
        </option>
        <option value={"suspended"} className={mustBePlaying ? "disabled" : ""}>
          Suspended
        </option>
        <option value={"injured"} className={mustBePlaying ? "disabled" : ""}>
          Injured
        </option>
      </Input>
      <Modal isOpen={modal} toggle={toggle} centered={true} backdrop="static">
        <ModalHeader toggle={toggle}>Invalid Status</ModalHeader>
        <ModalBody>
          {player.firstName} {player.lastName} is involved in a game event (goal, assist, SO, penalty, or goalie shift);
          their status must be Starting or Playing. If you wish to use a different status, please modify their game
          event(s).
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </FormGroup>
  );
}

StatusInput.propTypes = {
  player: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired
};

export default StatusInput;
