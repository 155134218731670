import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Table } from "reactstrap";

import EmptyListMessage from "@/components/EmptyListMessage";

import TrainerVisitsReportListSeason from "./Season";

import "./List.scss";

class List extends PureComponent {
  static propTypes = {
    visits: PropTypes.arrayOf(
      PropTypes.shape({
        season: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired
      })
    ).isRequired,
    visitsCount: PropTypes.number.isRequired
  };

  render() {
    const { visits, visitsCount } = this.props;

    return (
      <div className="trainer-visits-report__section">
        <h3 className="trainer-visits-report__section-title">Trainer Visits by Division</h3>

        {visits.length > 0 && visitsCount > 0 ? (
          <Table striped borderless className="trainer-visits-report__visits-table">
            <thead>
              <tr>
                <th className="trainer-visits-report__visits-table__season">Season</th>
                <th className="trainer-visits-report__visits-table__division">Division</th>
                <th className="trainer-visits-report__visits-table__visits">Trainer Visits</th>
              </tr>
            </thead>
            <tbody>
              {visits.map(({ season, ...rest }) => (
                <TrainerVisitsReportListSeason key={season.id} {...season} {...rest} />
              ))}
            </tbody>
          </Table>
        ) : (
          <EmptyListMessage>There were no trainer visits.</EmptyListMessage>
        )}
      </div>
    );
  }
}

export default List;
