import reduceTeamGoaliesIceTime from "./reduceTeamGoaliesIceTime";

export default function reduceGameGoaliesIceTime(state, { payload: { game } }) {
  const goals = [...(game.homeGoals || []), ...(game.visitorGoals || [])];
  const penalties = [...(game.homePenalties || []), ...(game.visitorPenalties || [])];
  const shots = [...(game.homeShots || []), ...(game.visitorShots || [])];
  const hasShootouts = [...(game.homeShootoutAttempts || []), ...(game.visitorShootoutAttempts || [])].length > 0;

  const homeGoaliesIceTime = reduceTeamGoaliesIceTime({
    players: game.homeRoster.players || [],
    shifts: game.homeGoalieShifts || [],
    periods: game.periods || {},
    goals,
    penalties,
    shots,
    oppositeShifts: game.visitorGoalieShifts || [],
    hasShootouts
  });

  const visitorGoaliesIceTime = reduceTeamGoaliesIceTime({
    players: game.visitorRoster.players || [],
    shifts: game.visitorGoalieShifts || [],
    periods: game.periods || {},
    goals,
    penalties,
    shots,
    oppositeShifts: game.homeGoalieShifts || [],
    hasShootouts
  });

  return {
    ...homeGoaliesIceTime,
    ...visitorGoaliesIceTime
  };
}
