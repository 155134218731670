import { all, put, takeLatest } from "redux-saga/effects";

import {
  loadDivisionPenaltyTypeReport,
  loadTeamPenaltyTypeReport,
  loadSeasonPenaltyTypeReport
} from "@/lib/api/reports";

import { fetchList } from "@/redux/api/sagas";

import { penaltyTypeReportLoadingRoutine } from "./routines";

function* loadReports(type, service, payload) {
  const { [type]: penaltyTypeReports, meta } = yield fetchList(type, service, payload);

  return { penaltyTypeReports, meta };
}

function* loadReport({ seasonIds, divisionIds, teamIds }) {
  if (teamIds) {
    return yield loadReports("teamsPenaltyTypeReports", loadTeamPenaltyTypeReport, { teamIds });
  } else if (divisionIds) {
    return yield loadReports("divisionsPenaltyTypeReports", loadDivisionPenaltyTypeReport, { divisionIds });
  } else if (seasonIds) {
    return yield loadReports("seasonsPenaltyTypeReports", loadSeasonPenaltyTypeReport, { seasonIds });
  } else {
    throw new Error("Can't determine report type to load");
  }
}

function* loadReportSaga({ payload: { seasonIds, divisionIds, teamIds } }) {
  yield put(penaltyTypeReportLoadingRoutine.request());

  try {
    const { penaltyTypeReports, meta } = yield loadReport({
      seasonIds,
      divisionIds,
      teamIds
    });

    yield put(penaltyTypeReportLoadingRoutine.success({ ...meta, penaltyTypeReports }));
  } catch (error) {
    const responseStatus = error.response && error.response.status;

    yield put(penaltyTypeReportLoadingRoutine.failure({ error, responseStatus }));

    if (!responseStatus) {
      throw error;
    }
  } finally {
    yield put(penaltyTypeReportLoadingRoutine.fulfill());
  }
}

export function* penaltyTypeReportFlow() {
  yield all([takeLatest(penaltyTypeReportLoadingRoutine.TRIGGER, loadReportSaga)]);
}
