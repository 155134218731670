import { createRoutine } from "redux-saga-routines";

export const loadGamesheetRoutine = createRoutine("GAMESHEET/LOADING");
export const exportGamesheetToPDF = createRoutine("GAMESHEET/EXPORT_TO_PDF");

export const loadGamesheetHistoryRoutine = createRoutine("GAMESHEET/HISTORY/LOADING");

export const loadGamesheetHistoryRecordRoutine = createRoutine("GAMESHEET/HISTORY/RECORD/LOADING");

export const exportGamesheetHistoryRecordToPDFRoutine = createRoutine("GAMESHEET/HISTORY/RECORD/EXPORTING_TO_PDF");
