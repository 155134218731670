import { createRoutine } from "redux-saga-routines";

export const TeamListLoadingRoutine = createRoutine("LOAD_TEAM_LIST");
export const BigTeamListLoadingRoutine = createRoutine("BIG_LOAD_TEAM_LIST");
export const BigTeamInvitationListLoadingRoutine = createRoutine("BIG_LOAD_TEAM_INVITATION_LIST");
export const TeamCreatingRoutine = createRoutine("CREATE_TEAM");
export const CurrentTeamLoadingRoutine = createRoutine("LOAD_CURRENT_TEAM");
export const CurrentTeamUpdatingRoutine = createRoutine("UPDATE_CURRENT_TEAM");
export const CurrentTeamDeletingRoutine = createRoutine("DELETE_CURRENT_TEAM");
export const TeamLockingRoutine = createRoutine("LOCK_TEAM");
