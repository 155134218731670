import _orderBy from "lodash/orderBy";
import _trimEnd from "lodash/trimEnd";

import { formatDate } from "@/utils/date";

const selectRoot = state => state.refereeReport;

const selectList = state => selectRoot(state).list;
const selectListSearch = state => selectList(state).search;
const selectListPagination = state => selectList(state).pagination;

const selectReport = state => selectRoot(state).report;
const selectMajorPenalties = state => selectReport(state).majorPenalties;
const selectSeasons = state => selectReport(state).seasons;
const selectTeams = state => selectReport(state).teams;

export const getRefereeReportListSearchNextQuery = state => selectListSearch(state).nextQuery;

export const getRefereeReportListSearchCurrentQuery = state => selectListSearch(state).currentQuery;

export const getRefereeReportListSearchQueryIsDirty = state => {
  const { nextQuery, currentQuery } = selectListSearch(state);

  return _trimEnd(nextQuery) !== _trimEnd(currentQuery);
};

export const getRefereeReportListIsLoading = state => selectListPagination(state).isLoading;

export const getRefereeReportListIsLoaded = state => selectListPagination(state).isLoaded;

export const getRefereeReportList = state => {
  const { ids } = selectListPagination(state);
  const { data } = selectList(state);

  const { leagues } = selectList(state);

  return ids.map(id => ({
    ...data[id],
    leagues: Object.entries(leagues)
      .filter(([leagueId]) => data[id].leagueIds.includes(leagueId))
      .map(([leagueId, { title }]) => title)
  }));
};

export const getRefereeReportListTotalCount = state => selectListPagination(state).totalCount;

export const getRefereeReportListFilteredCount = state => selectListPagination(state).filteredCount;

export const getRefereeReportListOffset = state => selectListPagination(state).ids.length;

export const getRefereeReportListLimit = state => selectListPagination(state).limit;

export const getRefereeReportListHasMore = state => {
  const filteredCount = selectListPagination(state).filteredCount;
  const offset = selectListPagination(state).ids.length;

  return offset < filteredCount;
};

export const getRefereeReportListIsAppending = state => selectListPagination(state).append;

const selectGames = state => {
  const seasons = selectSeasons(state);
  const teams = selectTeams(state);

  return Object.entries(selectReport(state).games).reduce((result, [id, game]) => {
    const season = { ...seasons[game.seasonId], id: game.seasonId };
    const home = teams[game.homeId].title;
    const visitor = teams[game.visitorId].title;
    const title = `${home} vs ${visitor}`;
    const date = formatDate(game.startTime, "UTC");

    return {
      ...result,
      [id]: {
        id,
        season,
        title,
        date,
        startTime: game.startTime
      }
    };
  }, {});
};

export const getRefereeReportIsLoaded = state => selectReport(state).isLoaded;
export const getRefereeReportIsLoading = state => selectReport(state).isLoading;
export const getRefereeReportIsForbidden = state => selectReport(state).isForbidden;

export const getRefereeReportReferee = state => selectReport(state).referee;

export const getRefereeReportCareerStats = state => {
  const majorPenaltiesAssessedCount = selectMajorPenalties(state).length;

  return {
    ...selectReport(state).careerStats,
    majorPenaltiesAssessedCount
  };
};

export const getRefereeReportMajorPenalties = state => {
  const games = selectGames(state);

  const penalties = selectMajorPenalties(state).map(({ gameId, ...rest }) => ({
    ...rest,
    game: games[gameId]
  }));

  return _orderBy(penalties, [({ game }) => game.startTime, "game.id"], ["desc", "desc"]);
};

export const getRefereeReportGamesOfficiated = state => {
  const games = Object.values(selectGames(state));

  return _orderBy(games, ["startTime", "id"], ["desc", "desc"]);
};
