import { handleActions } from "redux-actions";

import { seasonListLoadingRoutine as loadingRoutine } from "../routines";
import actions from "../actions";

const initialState = {
  isLoaded: false,
  isLoading: false,
  ids: [],
  data: {},
  selectedIds: [] 
};

function updateLeagueSeasons(state, leagueId, data) {
  return {
    ...state,
    [leagueId]: {
      ...state[leagueId],
      ...data
    }
  };
}

function reduceLeagueSelected(state, { payload: { id: leagueId } }) {
  return {
    ...state,
    [leagueId]: {
      isLoaded: false,
      isLoading: false,
      ids: [],
      data: {},
      ...state[leagueId]
    }
  };
}

function reduceLoadingRequest(state, { payload }) {
  const { leagueId } = payload;

  return updateLeagueSeasons(state, leagueId, { isLoading: true });
}

function reduceLoadingSuccess(state, { payload }) {
  const { leagueId, ids, seasons } = payload;

  const data = seasons.reduce((result, { id, title }) => ({ ...result, [id]: { title } }), {});

  return updateLeagueSeasons(state, leagueId, {
    ids,
    data,
    isLoaded: true
  });
}

function reduceLoadingFailure(state, { payload }) {
  const { leagueId } = payload;

  return updateLeagueSeasons(state, leagueId, { isLoaded: false });
}

function reduceLoadingFulfill(state, { payload }) {
  const { leagueId } = payload;

  return updateLeagueSeasons(state, leagueId, { isLoading: false });
}

function reduceSelectSeason(state, { payload: { id } }) {
  return {
    ...state,
    selectedIds: [...state.selectedIds, id] // Add league ID to selectedIds array
  };
}

function reduceDeselectSeason(state, { payload: { id } }) {
  return {
    ...state,
    selectedIds: state.selectedIds.filter(seasonId => seasonId !== id) // Remove league ID from selectedIds array
  };
}

function reduceSelectAllSeasons(state) {
  const allSeasonIds = Object.keys(state.data).flatMap(
    leagueId => (state[leagueId] && state[leagueId].ids) || []
  );
  return {
    ...state,
    selectedIds: allSeasonIds // Set selectedIds to all league IDs
  };
}

function reduceClearSeasons(state) {
  return {
    ...state,
    selectedIds: [] // Clear selectedIds array
  };
}

export default handleActions(
  {
    [actions.leagueList.select]: reduceLeagueSelected,
    [loadingRoutine.REQUEST]: reduceLoadingRequest,
    [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [loadingRoutine.FAILURE]: reduceLoadingFailure,
    [loadingRoutine.FULFILL]: reduceLoadingFulfill,
    [actions.seasonList.select]: reduceSelectSeason, // Handle individual league selection
    [actions.seasonList.deselect]: reduceDeselectSeason, // Handle individual league deselection
    [actions.seasonList.selectAll]: reduceSelectAllSeasons, // Handle selecting all leagues
    [actions.seasonList.clear]: reduceClearSeasons // Handle clearing all selected leagues
  },
  initialState
);
