import { all, put, takeLatest, select, debounce } from "redux-saga/effects";
import _snakeCase from "lodash/snakeCase";

import { searchTeams } from "@/lib/api/teams";
import { fetchList } from "@/redux/api/sagas";
import { getFilterParams, getIsDirty } from "@/redux/teamFilter/selectors";
import filterActions from "@/redux/teamFilter/actions";

import { listLoadingRoutine } from "./routines";

import { getPageSize, getPageNumber } from "./selectors";

function* listLoadingSaga() {
  const filterIsDirty = yield select(getIsDirty);

  if (!filterIsDirty) {
    yield put(filterActions.reset());
    return;
  }

  const size = yield select(getPageSize);
  const page = yield select(getPageNumber);
  const filterParams = yield select(getFilterParams);
  const filter = Object.entries(filterParams)
    .map(([key, value]) => [_snakeCase(key), value])
    .reduce((result, [key, value]) => ({ ...result, [key]: value }), {});

  yield put(listLoadingRoutine.request());

  try {
    const { teams, ids, filteredCount, totalCount, totalPages } = yield fetchList("teams", searchTeams, {
      page,
      size,
      filter,
      include: "division,season,league,association"
    });

    yield put(
      listLoadingRoutine.success({
        ids,
        filteredCount,
        totalCount,
        totalPages,
        teams
      })
    );
  } catch (error) {
    yield put(listLoadingRoutine.failure({ error }));
  } finally {
    yield put(listLoadingRoutine.fulfill());
  }
}

export function* teamListFlow() {
  yield all([
    takeLatest(listLoadingRoutine, listLoadingSaga),
    takeLatest(
      ({ type }) =>
        [
          filterActions.setAssociationId,
          filterActions.setLeagueId,
          filterActions.setSeasonId,
          filterActions.setDivisionId,
          filterActions.setYear
        ]
          .map(a => a.toString())
          .includes(type),
      listLoadingSaga
    ),
    debounce(300, filterActions.setQuery, listLoadingSaga)
  ]);
}
