import React from "react";
import PropTypes from "prop-types";

import { Table } from "reactstrap";
import EmptyListMessage from "../EmptyListMessage";

function StatsBySeason({ stats }) {
  return (
    <div className="team-member-penalty-report__section">
      <h3 className="team-member-penalty-report__section-title">Penalty Statistics by Season</h3>

      { stats.length > 0 ? (
        <Table striped borderless>
          <thead>
            <tr>
              <th className="team-member-penalty-report__season-stats-table__season">Season</th>
              <th className="team-member-penalty-report__season-stats-table__games-played">GP</th>
              <th className="team-member-penalty-report__season-stats-table__penalties">P</th>
              <th className="team-member-penalty-report__season-stats-table__penalty-minutes">PIM</th>
              <th className="team-member-penalty-report__season-stats-table__avg-pm-gm">Avg. PIM/GP</th>
            </tr>
          </thead>
          <tbody>
            {stats.map(({ season, gamesPlayed, penalties, penaltyMinutes, avgPmGp }, i) => (
              <tr key={i}>
                <td>{season.title}</td>
                <td>{gamesPlayed}</td>
                <td>{penalties}</td>
                <td>{penaltyMinutes}</td>
                <td>{avgPmGp}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyListMessage>No seasons with games played.</EmptyListMessage>
      )}
    </div>
  );
}

StatsBySeason.propTypes = {
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      season: PropTypes.shape({
        id: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number
        ]).isRequired,
        title: PropTypes.string.isRequired
      }).isRequired,
      gamesPlayed: PropTypes.number.isRequired,
      penalties: PropTypes.number.isRequired,
      penaltyMinutes: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
      avgPmGp: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired
    })
  )
};

StatsBySeason.defaultProps = {
  stats: []
};

export default StatsBySeason;
