import React, { useCallback } from "react";
import PropTypes from "prop-types";
import _capitalize from "lodash/capitalize";

import { Button } from "reactstrap";
import Spinner from "@/components/Spinner";

import Failure from "./Failure";

import { canCheck } from "@/components/Ability";

function Role(props) {
  const { title, level, resourceTitle, isLoading, isLoaded, isFailed, failureCode, editRole, removeRole } = props;

  const { type, id } = level;

  const onEdit = useCallback(() => editRole({ title, level }), [editRole, title, level]);

  const onRemove = useCallback(() => removeRole({ title, level }), [removeRole, title, level]);

  const resourceType = _capitalize(type).replace(/s$/, "");

  const amSuperAdmin = canCheck({ I: "update", this: { type: "all" } });
  const roleIsGlobal = type === "" && id === "*";
  const canEdit = roleIsGlobal ? amSuperAdmin : true;

  const description = (() => {
    if (id === "*" && type === "") {
      return "Global"
    } else {
      return `${resourceType}: ${resourceTitle}`;
    }
  })();

  return (
    <tr className={`dashboard-table-item level-type-${type}-id-${id}`}>
      <td>{_capitalize(title)}</td>
      <td>
        {isLoaded && canEdit && (
            <Button
              size="sm"
              color="primary"
              outline
              className="dashboard-table-item__action dashboard-table-item__action--hoverable"
              onClick={onEdit}
            >
              Edit
            </Button>
        )}

        {isFailed &&
          (failureCode !== "unauthorized") && (
            <Button
              size="sm"
              color="danger"
              outline
              className="dashboard-table-item__action dashboard-table-item__action--hoverable"
              onClick={onRemove}
            >
              Remove
            </Button>
          )}
      </td>
      <td>
        {isFailed && <Failure code={failureCode || ''} resourceType={resourceType} hint={`(${id})`} />}

        {isLoaded && (
          <div>{description}</div>
        )}

        {isLoading && <Spinner theme="scale" color="yellow" size="s" />}
      </td>
    </tr>
  );
}

Role.propTypes = {
  title: PropTypes.string.isRequired,
  level: PropTypes.shape({
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }).isRequired,
  isLoaded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isFailed: PropTypes.bool.isRequired,
  resourceTitle: PropTypes.string,
  failureCode: PropTypes.string,
  editRole: PropTypes.func.isRequired,
  removeRole: PropTypes.func.isRequired
};

export default Role;
