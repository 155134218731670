import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Button, CustomInput } from "reactstrap";

import Spinner from "@/components/Spinner";

import ReportDataSourcesSelectSeasonGroup from "./SeasonGroup";

class SeasonList extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    isLoaded: PropTypes.bool,
    isOptionSelected: PropTypes.bool,
    isAllOptionsSelected: PropTypes.bool,
    isArchivedIncluded: PropTypes.bool,
    hasArchived: PropTypes.bool,
    seasonGroups: PropTypes.arrayOf(
      PropTypes.shape({
        leagueId: PropTypes.string.isRequired
      })
    ),
    singleSelect: PropTypes.bool,
    clear: PropTypes.func,
    selectAll: PropTypes.func,
    toggleArchivedIncluded: PropTypes.func,
    extended: PropTypes.bool,
    penalties: PropTypes.bool
  };

  static defaultProps = {
    isLoaded: false,
    isLoading: false,
    isOptionSelected: false,
    isAllOptionsSelected: false,
    isArchivedIncluded: false,
    hasArchived: false,
    singleSelect: false,
    extended: false,
    penalties: false,
    seasonGroups: [],
    clear() {},
    selectAll() {},
    toggleArchivedIncluded() {}
  };

  render() {
    const {
      isLoaded,
      isLoading,
      isOptionSelected,
      isAllOptionsSelected,
      isArchivedIncluded,
      hasArchived,
      seasonGroups,
      singleSelect,
      clear,
      selectAll,
      toggleArchivedIncluded,
      extended,
      penalties
    } = this.props;

    return (
      <div className="report-data-sources-select-list">
        <div className="report-data-sources-select-list__label">
          <span className="report-data-sources-select-list__label__text">
            Select season
            {singleSelect ? "" : "(s)"}
          </span>

          {hasArchived && (
            <span className="report-data-sources-select-list__label__control">
              <CustomInput
                type="checkbox"
                id="report-data-sources-select-list__archived-seasons-control"
                label="Show archived"
                checked={isArchivedIncluded}
                onChange={toggleArchivedIncluded}
              />
            </span>
          )}
        </div>

        <div className="report-data-sources-select-list__input list-group list-group-flush">
          {isLoading ? (
            <Spinner className="report-data-sources-select-list__spinner" theme="loader" color="yellow" size="xxl" />
          ) : isLoaded ? (
            seasonGroups.map(({ leagueId, ...rest }) => (
              <ReportDataSourcesSelectSeasonGroup
                extended={extended}
                singleSelect={singleSelect}
                penalties={penalties}
                key={leagueId}
                {...rest}
              />
            ))
          ) : (
            <p className="report-data-sources-select-list__empty-list-message">No league(s) selected</p>
          )}
        </div>

        <div className="report-data-sources-select-list__controls">
          {isOptionSelected && (
            <Button
              className="report-data-sources-select-list__controls__left-control"
              color="link"
              size="sm"
              onClick={clear}
            >
              Clear selection
            </Button>
          )}

          {isLoaded &&
            !singleSelect &&
            isAllOptionsSelected === false && (
              <Button
                className="report-data-sources-select-list__controls__right-control"
                color="link"
                size="sm"
                onClick={selectAll}
              >
                Select All
              </Button>
            )}
        </div>
      </div>
    );
  }
}

export default SeasonList;
