import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Switch } from "react-router-dom";

import LoggedInRoute from "@/containers/LoggedInRoute";

import BenchStaffReportPage from "./ReportPage";
import { CoachPenaltyReportSearch } from "@/components-16.8/CoachPenaltyReportSearch";

class ReportsRoute extends PureComponent {
  static propTypes = {
    match: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired
  };

  render() {
    const { match } = this.props;

    return (
      <Switch>
        <LoggedInRoute path={`${match.url}/:externalId`} component={BenchStaffReportPage} />

        <LoggedInRoute path="" component={CoachPenaltyReportSearch} />
      </Switch>
    );
  }
}

export default ReportsRoute;
