import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { submittingRoutine, getMeta } from "@/redux/associationForm";

export default function useAssociationFormSubmitting({ associationId, values }) {
  const dispatch = useDispatch();
  const { isSubmitting } = useSelector(getMeta);

  const submit = useCallback(
    () => {
      dispatch(submittingRoutine({ associationId, values }));
    },
    [dispatch, associationId, values]
  );

  return {
    isSubmitting,
    submit
  };
}
