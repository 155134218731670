import { createGetService } from "./serviceMaker";

export const loadResource = createGetService(({ type, id }) => {
  if (!["associations", "leagues", "seasons", "divisions", "teams"].includes(type)) {
    throw new Error(`Unknown resource type ${type}`);
  }

  return {
    url: `/${type}/${id}`
  };
});
