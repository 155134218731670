import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import GamesheetNote from "@/components/GamesheetNote";
import PenaltyLabel from "@/components/Gamesheet/components/TeamsLayout/components/PenaltiesSheet/components/PenaltyLabel";
import Referee from "./Referee";

class PenaltyNote extends PureComponent {
  static propTypes = {
    penaltyId: PropTypes.string.isRequired,
    team: PropTypes.string,
    number: PropTypes.string,
    player: PropTypes.string,
    period: PropTypes.string,
    length: PropTypes.string,
    code: PropTypes.string,
    label: PropTypes.string,
    note: PropTypes.shape({}),
    referee: PropTypes.shape({})
  };

  static defaultProps = {
    team: "N/A",
    number: "N/A",
    player: "N/A",
    servedBy: "N/A",
    period: "N/A",
    length: "N/A",
    code: "N/A",
    label: "N/A",
    note: {},
    referee: {}
  };

  render() {
    const { penaltyId, team, number, player, period, length, code, label, note, referee } = this.props;

    return (
      <tr>
        <td>{team}</td>
        <td className="text-uppercase">{number}</td>
        <td className="text-uppercase">{player}</td>
        <td>{period}</td>
        <td>{length}</td>
        <td className="text-uppercase">
          <PenaltyLabel id={penaltyId} scope="penaltyNote" label={label} code={code} />
        </td>
        <td>
          <GamesheetNote {...note} />
        </td>
        <td>
          <Referee {...referee} />
        </td>
      </tr>
    );
  }
}

export default PenaltyNote;
