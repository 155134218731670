import { all, put, takeLatest, actionChannel, take, takeEvery, call } from "redux-saga/effects";

import { buffers } from "redux-saga";

import { loadSeasons } from "@/lib/api/seasons";
import { loadDivisions } from "@/lib/api/divisions";
import { loadTeams } from "@/lib/api/teams";

import { fetchList } from "@/redux/api/sagas";

import {
  seasonListLoadingRoutine,
  divisionListLoadingRoutine,
  teamListLoadingRoutine,
  seasonDivisionListLoadingRoutine,
  divisionTeamListLoadingRoutine
} from "./routines";

function* countDivisions(seasonIds) {
  const countDivisionsFulfill = yield actionChannel(
    ({ type, payload }) =>
      type === seasonDivisionListLoadingRoutine.FULFILL && payload && seasonIds.includes(payload.seasonId),
    buffers.dropping(seasonIds.length)
  );

  yield all(seasonIds.map(seasonId => put(seasonDivisionListLoadingRoutine({ seasonId }))));

  yield all(seasonIds.map(() => take(countDivisionsFulfill)));
}

function* countTeams(divisionIds) {
  const countTeamsFulfill = yield actionChannel(
    ({ type, payload }) =>
      type === divisionTeamListLoadingRoutine.FULFILL && payload && divisionIds.includes(payload.divisionId),
    buffers.dropping(divisionIds.length)
  );

  yield all(divisionIds.map(divisionId => put(divisionTeamListLoadingRoutine({ divisionId }))));

  yield all(divisionIds.map(() => take(countTeamsFulfill)));
}

function* seasonListLoadingSaga({ payload: { ids: seasonIds } }) {
  yield put(seasonListLoadingRoutine.request());

  try {
    const { seasons, archivedSeasons } = yield fetchList(["seasons", "archivedSeasons"], loadSeasons, {
      filter: {
        id: seasonIds.join(",")
      },
      include: "leagues,associations"
    });

    yield put(
      seasonListLoadingRoutine.success({
        seasons: [...seasons, ...archivedSeasons]
      })
    );
  } catch (e) {
    yield put(seasonListLoadingRoutine.failure({ error: e }));
  } finally {
    yield put(seasonListLoadingRoutine.fulfill());
  }
}

function* divisionListLoadingSaga({ payload: { ids } }) {
  yield put(divisionListLoadingRoutine.request());

  try {
    const { divisions } = yield fetchList("divisions", loadDivisions, {
      ids,
      include: "seasons,leagues,associations"
    });

    const seasonIds = divisions.reduce(
      (result, { season: { id } }) => (result.includes(id) ? result : [...result, id]),
      []
    );

    yield put(divisionListLoadingRoutine.success({ divisions }));

    yield all([call(countDivisions, seasonIds), call(countTeams, ids)]);
  } catch (e) {
    yield put(divisionListLoadingRoutine.failure({ error: e }));
  } finally {
    yield put(divisionListLoadingRoutine.fulfill());
  }
}

function* teamListLoadingSaga({ payload: { ids } }) {
  yield put(teamListLoadingRoutine.request());

  try {
    const { teams } = yield fetchList("teams", loadTeams, {
      ids,
      include: "divisions,seasons,leagues,associations"
    });

    const seasonIds = teams.reduce(
      (result, { season: { id } }) => (result.includes(id) ? result : [...result, id]),
      []
    );

    const divisionIds = teams.reduce(
      (result, { division: { id } }) => (result.includes(id) ? result : [...result, id]),
      []
    );

    yield put(teamListLoadingRoutine.success({ teams }));

    yield all([call(countDivisions, seasonIds), call(countTeams, divisionIds)]);
  } catch (e) {
    yield put(teamListLoadingRoutine.failure({ error: e }));
  } finally {
    yield put(teamListLoadingRoutine.fulfill());
  }
}

function* seasonDivisionListLoadingSaga({ payload: { seasonId } }) {
  yield put(seasonDivisionListLoadingRoutine.request({ seasonId }));

  try {
    const { totalCount } = yield fetchList("divisions", loadDivisions, {
      seasonId
    });

    yield put(
      seasonDivisionListLoadingRoutine.success({
        seasonId,
        totalDivisions: totalCount
      })
    );
  } catch (e) {
    yield put(seasonDivisionListLoadingRoutine.failure({ seasonId, error: e }));
  } finally {
    yield put(seasonDivisionListLoadingRoutine.fulfill({ seasonId }));
  }
}

function* divisionTeamListLoadingSaga({ payload: { divisionId } }) {
  yield put(divisionTeamListLoadingRoutine.request({ divisionId }));

  try {
    const { totalCount } = yield fetchList("teams", loadTeams, {
      divisionId
    });

    yield put(
      divisionTeamListLoadingRoutine.success({
        divisionId,
        totalTeams: totalCount
      })
    );
  } catch (e) {
    yield put(divisionTeamListLoadingRoutine.failure({ divisionId, error: e }));
  } finally {
    yield put(divisionTeamListLoadingRoutine.fulfill({ divisionId }));
  }
}

export function* reportDataSourcesListFlow() {
  yield all([
    takeLatest(seasonListLoadingRoutine.TRIGGER, seasonListLoadingSaga),
    takeLatest(divisionListLoadingRoutine.TRIGGER, divisionListLoadingSaga),
    takeLatest(teamListLoadingRoutine.TRIGGER, teamListLoadingSaga),
    takeEvery(seasonDivisionListLoadingRoutine.TRIGGER, seasonDivisionListLoadingSaga),
    takeEvery(divisionTeamListLoadingRoutine.TRIGGER, divisionTeamListLoadingSaga)
  ]);
}
