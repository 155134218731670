import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import SignatureImage from "@/components/SignatureImage";

class Coach extends PureComponent {
  static propTypes = {
    position: PropTypes.string,
    name: PropTypes.string,
    signature: PropTypes.string
  };

  static defaultProps = {
    position: "N/A",
    name: "N/A"
  };

  render() {
    const { position, name, signature } = this.props;

    return (
      <tr>
        <td>{position}</td>
        <td className="text-uppercase">{name}</td>
        <td>
          <SignatureImage src={signature} />
        </td>
      </tr>
    );
  }
}

export default Coach;
