import React from "react";

import List from "../List";

import { usePenaltyList } from "./hooks";

function PenaltyList() {
  const {
    isLoaded,
    penaltyList,
    isOptionSelected,
    isAllOptionsSelected,
    flagged,
    select,
    deselect,
    selectAll,
    clear,
    updateFlagged
  } = usePenaltyList();

  const options = penaltyList
    .map(({ code, label, isSelected }) => ({ title: `${code} - ${label}`, id: code, isSelected }))
    .sort((a, b) => a.title.localeCompare(b.title));

  return (
    <List
      isLoaded={isLoaded}
      isOptionSelected={isOptionSelected}
      isAllOptionsSelected={isAllOptionsSelected}
      singleSelect={false}
      options={options}
      select={select}
      deselect={deselect}
      clear={clear}
      emptyListMessage="No season selected"
      label="Select penalties"
      selectAll={selectAll}
      checkboxLabel="Flagged"
      checkboxCallback={updateFlagged}
      checkboxChecked={flagged}
    />
  );
}

export default PenaltyList;
