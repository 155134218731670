import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import Head from "@/components/Head";
import Breadcrumbs, { BreadcrumbsProps } from "@/components/Breadcrumbs";
import PageLoader from "@/components/PageLoader";
import TitleBar from "@/components/TitleBar";

import RefereesList from "@/components/RefereesList";

import ProtectedLayout from "@/layouts/ProtectedLayout";

class RefereesIndexPage extends PureComponent {
  static propTypes = {
    season: PropTypes.shape({}),
    seasonId: PropTypes.string.isRequired,
    seasonIsLoaded: PropTypes.bool.isRequired,
    seasonIsLoading: PropTypes.bool.isRequired,
    seasonIsForbidden: PropTypes.bool.isRequired,
    seasonBreadcrumbs: BreadcrumbsProps,
    isMergingMode: PropTypes.bool,
    loadRefereesList: PropTypes.func,
    loadSeason: PropTypes.func,
    clear: PropTypes.func
  };

  static defaultProps = {
    loadRefereesList() {},
    clear() {}
  };

  componentDidMount() {
    const { loadRefereesList } = this.props;

    loadRefereesList();
  }

  componentWillUnmount() {
    this.props.clear();
  }

  render() {
    const {
      isMergingMode,
      seasonId,
      seasonIsLoaded,
      seasonIsForbidden,
      seasonIsLoading,
      seasonBreadcrumbs,
      season
    } = this.props;

    return (
      <ProtectedLayout
        subject={{ type: "referees", seasonId }}
        action="read"
        skip={!seasonIsLoaded && !seasonIsForbidden}
      >
        {seasonIsLoaded ? (
          <Fragment>
            {!isMergingMode && (
              <Fragment>
                <Breadcrumbs items={seasonBreadcrumbs} />
                <Head title={`${season.title} Referees`} />
                <TitleBar title="Referees" />
              </Fragment>
            )}

            <RefereesList seasonId={seasonId} />
          </Fragment>
        ) : (
          <PageLoader isLoading={seasonIsLoading} />
        )}
      </ProtectedLayout>
    );
  }
}

export default RefereesIndexPage;
