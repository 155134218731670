import { useCallback } from "react";

import { useDashboardFormNamespaceContext } from "@/components/DashboardForm";

import { PERIOD_NAME_REVERSE_ALIASES } from "../constants";

export default function usePenaltyLengthsFields(options) {
  const { value: periodValues, onChange: changePeriodValues } = options;
  const namespace = useDashboardFormNamespaceContext();

  const changePeriod = useCallback(
    ({ name, value }) => {
      const parsedValue = parseInt(value);

      changePeriodValues({
        ...periodValues,
        [name]: parsedValue || 0
      });
    },
    [changePeriodValues, periodValues]
  );

  const createField = alias => {
    const name = PERIOD_NAME_REVERSE_ALIASES[alias];
    const value = periodValues[name];

    return {
      id: `${namespace}__period_lengths__${alias}`,
      value: value,
      onChange: ({ target: { value } }) => changePeriod({ name, value })
    };
  };

  return Object.keys(PERIOD_NAME_REVERSE_ALIASES).reduce(
    (result, alias) => ({ ...result, [alias]: createField(alias) }),
    {}
  );
}
