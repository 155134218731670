import { combineActions } from "redux-actions";

import createPaginationReducer from "@/redux/common/createPaginationReducer";

import filterActions from "@/redux/teamFilter/actions";

import { listLoadingRoutine } from "../routines";
import actions from "../actions";

import associations from "./associations";
import leagues from "./leagues";
import seasons from "./seasons";
import divisions from "./divisions";
import teams from "./teams";

const pagination = createPaginationReducer(
  listLoadingRoutine,
  combineActions(
    actions.clear,
    filterActions.reset,
    filterActions.setAssociationId,
    filterActions.setLeagueId,
    filterActions.setSeasonId,
    filterActions.setDivisionId,
    filterActions.setQuery
  ),
  10
);

export { associations, leagues, seasons, divisions, teams, pagination };
