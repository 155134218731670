import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Table } from "reactstrap";
import { formatPeriodName } from "../../formatPeriodName";
import { formatSoccerMinutes } from "@/lib/core/formatSoccerTime";

class GoalieStats extends PureComponent {
  static propTypes = {
    caption: PropTypes.string,
    stats: PropTypes.shape({
      periods: PropTypes.arrayOf(PropTypes.string),
      stats: PropTypes.arrayOf(
        PropTypes.shape({
          jersey: PropTypes.string.isRequired,
          toi: PropTypes.number.isRequired,
          shots: PropTypes.shape({}),
        })
      ),
    }),
    sport: PropTypes.string,
  };

  static defaultProps = {
    caption: "Goalie Stats",
    stats: []
  };

  render() {
    const { caption, stats: { stats, periods }, sport } = this.props;

    return (
      <Table striped borderless size="sm" className="layout-auto">
        <thead>
          <tr>
            <th scope="colgroup" colSpan="6">
              {caption}
            </th>
          </tr>
          <tr>
            <th scope="col">NO.</th>
            {periods.map(period => (
              <th scope="col" key={period}>{formatPeriodName(period)}</th>
            ))}
            <th scope="col">{sport !== "hockey" ? "MINS" : "TOI"}</th>
          </tr>
        </thead>
        <tbody>
          {stats.map(stat => (
            <GoalieStatRow key={stat.jersey} {...stat} periods={periods} sport={sport} />
          ))}
        </tbody>
      </Table>
    );
  }
}

export default GoalieStats;

const GoalieStatRow = ({ jersey, toi, shots, periods, sport }) => {
  let timeStamp = `${Math.floor(toi / 60).toString().padStart(2, '0')}:${(toi % 60).toString().padStart(2, '0')}`;
  if (sport.toLowerCase() === "soccer") {
    timeStamp = formatSoccerMinutes(timeStamp);
  }

  return (
    <tr>
      <td>{jersey}</td>
      {periods.map(period => (
        <td key={period}>{shots[period] || 0}</td>
      ))}
      <td>{timeStamp}</td>
    </tr>
  )
}
