import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { otpRoutine } from "@/redux/otp/routines";
import { getOTP } from "@/redux/otp/selectors";



export const useOTP = () => {
    const dispatch = useDispatch();
    const otp = useSelector(getOTP);

    useEffect(() => {
        dispatch(otpRoutine());
    }, [dispatch]);

    return {
        otp
    }
}

export const getStatsHref = (path, otp) => {
    const otpValid = !!otp;
    const url = new URL(window.location.href);

    let subDomain = ""
    if (url.hostname === "localhost" || url.hostname.startsWith("dev.")) {
        subDomain = "dev.";
    } else if (url.hostname.startsWith("qa.")) {
        subDomain = "qa.";
    } else {
        subDomain = "";
    }

    if (otpValid) {
        return `https://${subDomain}gamesheetstats.com${path}?otp=${otp}`;
    } else {
        return `https://${subDomain}gamesheetstats.com${path}`;
    }
}

