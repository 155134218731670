import { connect } from "react-redux";

import { getGameFormTeamTitle, getGameFormTeamGoals } from "@/redux/gameForm/selectors";

import actions from "@/redux/gameForm/actions";

import ScoringFieldset from "./ScoringFieldset";

const mapStateToProps = state => (state, { team }) => ({
  teamTitle: getGameFormTeamTitle(state, team),
  goals: getGameFormTeamGoals(state, team)
});

const mapDispatchToProps = dispatch => ({
  changeGoal: payload => dispatch(actions.changeGoal(payload)),
  addGoal: team => dispatch(actions.addGoal({ team })),
  removeGoal: payload => dispatch(actions.removeGoal(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScoringFieldset);
