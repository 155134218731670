import { useContext, useMemo } from "react";

import CurrentAssociationContext from "@/contexts/CurrentAssociationContext";

export default function useCurrentAssociationContext() {
  const currentAssociation = useContext(CurrentAssociationContext);
  const currentAssociationMemo = useMemo(() => ({ ...currentAssociation }), [currentAssociation]);

  return currentAssociationMemo;
}
