import React from "react";
import ScheduledGames from "./ScheduledGames";
import { RecoilRoot } from "recoil";
import PageLoader from "@/components/PageLoader";

function ScheduledGamesContainer(props) {
  return (
    <RecoilRoot>
      <React.Suspense fallback={<PageLoader isLoading />}>
        <ScheduledGames {...props} />
      </React.Suspense>
    </RecoilRoot>
  )
}

export default ScheduledGamesContainer;
