import React, { useContext } from "react";

import CurrentGameContext from "@/contexts/CurrentGameContext";
import ProtectedLayout from "@/layouts/ProtectedLayout";
import Head from "@/components/Head";
import PageLoader from "@/components/PageLoader";
import NotFound from "@/components/NotFound";

import PlayerOfTheGameForm, { usePlayerOfTheGameFormClearing } from "@/components/PlayerOfTheGameForm";

function PlayerOfTheGame() {
  const { gameId, gameIsLoaded, gameIsLoading, gameIsNotFound, gameIsForbidden } = useContext(CurrentGameContext);

  usePlayerOfTheGameFormClearing();

  return (
    <ProtectedLayout
      className="pog-page"
      subject={{ type: "player-of-the-game", gameId }}
      action="create"
      skip={!gameIsLoaded && !gameIsForbidden}
      condition={gameIsForbidden}
    >
      <Head title="Player of the Game" />

      {gameIsLoading ? (
        <PageLoader isLoading={true} />
      ) : (
        <React.Fragment>
          {gameIsNotFound && <NotFound />}
          {gameIsLoaded && <PlayerOfTheGameForm />}
        </React.Fragment>
      )}
    </ProtectedLayout>
  );
}

export default PlayerOfTheGame;
