import { takeLatest, all, put, call, select, debounce } from "redux-saga/effects";
import { push } from "connected-react-router";
import build from "redux-object";

import { gamesheetAPIRequest } from "@/redux/api/sagas";
import { loadGame, updateGame, deleteGame } from "@/lib/api/games";
import { loadPlayers } from "@/lib/api/players";
import createMembersLoadingSaga from "./helpers/createMembersLoadingSaga";

import {
  loadGameFormRoutine,
  seasonPlayersLoadingRoutine,
  submitGameFormRoutine,
  deleteGameFormRoutine
} from "./routines";

import { getGameFormNextAttributes, getGameFormDataTeams, getCanSubmitForm } from "./selectors";

const loadSeasonPlayersSaga = createMembersLoadingSaga({
  type: "players",
  routine: seasonPlayersLoadingRoutine,
  apiService: loadPlayers
});

function* loadGameFormSaga({ payload }) {
  yield put(loadGameFormRoutine.request());

  try {
    const { data } = yield call(gamesheetAPIRequest, loadGame, {
      ...payload,
      include: "players,coaches,referees,teams,league,season"
    });

    const game = build(data, "games", payload.id, {
      eager: true
    });

    yield put(
      loadGameFormRoutine.success({
        game,
        attributes: data.games[payload.id].attributes
      })
    );
  } catch (error) {
    const responseStatus = error.response && error.response.status;

    if (error.response) {
      yield put(loadGameFormRoutine.failure({ responseStatus }));
    } else {
      throw error;
    }
  } finally {
    yield put(loadGameFormRoutine.fulfill());
  }
}

function* submitGameFormSaga({ payload: { id, seasonId } }) {
  const canSubmit = yield select(getCanSubmitForm);
  if (!canSubmit) {
    return;
  }

  const attributes = yield select(getGameFormNextAttributes);
  const { visitorId, homeId } = yield select(getGameFormDataTeams);

  yield put(submitGameFormRoutine.request());

  try {
    yield call(
      gamesheetAPIRequest,
      updateGame,
      {
        attributes,
        seasonId,
        id,
        visitorId,
        homeId
      },
      true
    );

    yield put(submitGameFormRoutine.success());
    yield put(push(`/seasons/${seasonId}/games/completed/${id}`));
  } catch (error) {
    if (error.response) {
      yield put(submitGameFormRoutine.failure("Failed to update game due to invalid data"));
    } else {
      yield put(submitGameFormRoutine.failure("Failed to update game due to unknown error"));

      throw error;
    }
  } finally {
    yield put(submitGameFormRoutine.fulfill());
  }
}

function* deleteGameFormSaga({ payload: { id, seasonId } }) {
  yield put(deleteGameFormRoutine.request());

  yield gamesheetAPIRequest(deleteGame, { id, seasonId });

  yield put(deleteGameFormRoutine.success());
  yield put(push(`/seasons/${seasonId}/games/completed`));
  yield put(deleteGameFormRoutine.fulfill());
}

export function* gameFormFlow() {
  yield all([
    takeLatest(loadGameFormRoutine.TRIGGER, loadGameFormSaga),
    debounce(300, seasonPlayersLoadingRoutine.TRIGGER, loadSeasonPlayersSaga),
    takeLatest(submitGameFormRoutine.TRIGGER, submitGameFormSaga),
    takeLatest(deleteGameFormRoutine.TRIGGER, deleteGameFormSaga)
  ]);
}
