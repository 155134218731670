import React from "react";
import PropTypes from "prop-types";
import { ListGroup } from "reactstrap";

import ContentSection from "@/components/ContentSection";
import Role from "./Role";

import { useRolesFieldset } from "./hooks";

function RolesFieldset({ value, setValue }) {
  const roles = useRolesFieldset({ value, setValue });

  return (
    <ContentSection title="Seasons">
      <ListGroup flush>
        {roles.map(role => (
          <Role key={role.seasonId} {...role} />
        ))}
      </ListGroup>
    </ContentSection>
  );
}

RolesFieldset.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      level: PropTypes.shape({ id: PropTypes.string.isRequired, type: PropTypes.string.isRequired }).isRequired
    })
  ).isRequired,
  setValue: PropTypes.func.isRequired
};

export default RolesFieldset;
