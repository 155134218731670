import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import ArchivedSeasonContent from "@/layouts/ArchivedSeasonContent";
import ProtectedLayout from "@/layouts/ProtectedLayout";
import Head from "@/components/Head";
import PageLoader from "@/components/PageLoader";
import Breadcrumbs, { BreadcrumbsProps } from "@/components/Breadcrumbs";
import CSVRosterImportWizard from "@/components-16.8/RosterImportWizard";
import { RecoilRoot } from "recoil";

class TeamRosterCSVImport extends PureComponent {
  static propTypes = {
    isTeamOperating: PropTypes.bool.isRequired,
    isTeamReady: PropTypes.bool.isRequired,
    isTeamForbidden: PropTypes.bool.isRequired,
    isTeamArchived: PropTypes.bool.isRequired,
    breadcrumbs: BreadcrumbsProps,
    seasonId: PropTypes.string.isRequired,
    teamId: PropTypes.string.isRequired,
    loadTeam: PropTypes.func,
    reset: PropTypes.func
  };

  static defaultProps = {
    isTeamOperating: false,
    isTeamReady: false,
    breadcrumbs: null,
    loadTeam() {},
    reset() {}
  };

  componentDidMount() {
    this.props.loadTeam();
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const { isTeamOperating, isTeamReady, isTeamForbidden, isTeamArchived, breadcrumbs, seasonId, teamId } = this.props;

    return (
      <ArchivedSeasonContent condition={isTeamArchived}>
        <ProtectedLayout
          className="roster-page"
          subject={{ type: "roster", teamId }}
          action="import"
          skip={!isTeamReady && !isTeamForbidden}
        >
          <Head title="Import from CSV" />

          <PageLoader isLoading={isTeamOperating} isLoaded={isTeamReady}>
            <Breadcrumbs items={breadcrumbs} lastItem="Roster" />
            <CSVRosterImportWizard seasonId={seasonId} teamId={teamId} />
          </PageLoader>
        </ProtectedLayout>
      </ArchivedSeasonContent>
    );
  }
}

export default TeamRosterCSVImport;
