import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { ModalBody } from "reactstrap";
import PageLoader from "@/components/PageLoader";
import EmptyListMessage from "@/components/EmptyListMessage";

import GameNotesListItem from "./Item";

class GameNotesList extends PureComponent {
  static propTypes = {
    gameNotes: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })),
    isLoaded: PropTypes.bool,
    isLoading: PropTypes.bool
  };

  static defaultProps = {
    gameNotes: [],
    isLoaded: false,
    isLoading: false
  };

  render() {
    const { isLoading, isLoaded, gameNotes } = this.props;

    return (
      <ModalBody>
        <PageLoader isLoading={isLoading} isLoaded={isLoaded}>
          <div className="games-notes__list list-group list-group-flush">
            {isLoaded && gameNotes.length < 1 ? (
              <EmptyListMessage>
                There are no notes.
                <br /> Create one.
              </EmptyListMessage>
            ) : (
              gameNotes.map(note => <GameNotesListItem key={note.id} {...note} />)
            )}
          </div>
        </PageLoader>
      </ModalBody>
    );
  }
}

export default GameNotesList;
