import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { CurrentCoachUpdatingRoutine, CurrentCoachDeletingRoutine } from "@/redux/coaches/routines";

export default function useCurrentCoach({ seasonId, coachId }) {
  const dispatch = useDispatch();

  const updateCoach = useCallback(
    ({ attributes }) => dispatch(CurrentCoachUpdatingRoutine({ id: coachId, seasonId, attributes })),
    [dispatch, coachId, seasonId]
  );

  const deleteCoach = useCallback(() => dispatch(CurrentCoachDeletingRoutine({ id: coachId, seasonId })), [
    dispatch,
    coachId,
    seasonId
  ]);

  return {
    updateCoach,
    deleteCoach
  };
}
