import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Table, Row, Col } from "reactstrap";

import ContentSection from "@/components/ContentSection";

import Referee from "./components/Referee";

class Referees extends PureComponent {
  static propTypes = {
    referees: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired
      })
    )
  };

  static defaultProps = {
    referees: []
  };

  render() {
    const { referees } = this.props;

    return (
      <ContentSection title="Referees" hSize="h3">
        <Row>
          <Col>
            <Table striped borderless size="sm" className="layout-fixed">
              <caption className="visuallyhidden">Referees</caption>
              <thead>
                <tr>
                  <th scope="colgroup" colSpan="4">
                    Referees
                  </th>
                </tr>
                <tr>
                  <th scope="col">Position</th>
                  <th scope="col">Name</th>
                  <th scope="col">External ID</th>
                  <th scope="col">Signature</th>
                </tr>
              </thead>
              <tbody>
                {referees.map(({ id, ...referee }) => (
                  <Referee key={id} {...referee} />
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </ContentSection>
    );
  }
}

export default Referees;
