import { combineReducers } from "redux";

import players from "./players";
import coaches from "./coaches";
import properties from "./properties";
import referees from "./referees";
import refereeNotes from "./refereeNotes";
import scorekeeperNotes from "./scorekeeperNotes";
import goaliesIceTime from "./goaliesIceTime";
import penaltyTypes from "./penaltyTypes";
import shootout from "./shootout";
import shots from "./shots";

import { createTeamReducer } from "./team";

const visitor = createTeamReducer("visitor");
const home = createTeamReducer("home");

export default combineReducers({
  visitor,
  home,
  players,
  coaches,
  properties,
  referees,
  refereeNotes,
  scorekeeperNotes,
  goaliesIceTime,
  penaltyTypes,
  shootout,
  shots
});
