import { connect } from "react-redux";
import { push } from "connected-react-router";

import Modal from "./Modal";

import { getGameNotesModalIsWorking, getGameNoteFormIsEnabled } from "@/redux/gameNotes/selectors";

import { enableGameNoteForm, clearGameNotes } from "@/redux/gameNotes/actions";

import { gameNotesListLoadingRoutine } from "@/redux/gameNotes/routines";

const mapStateToProps = state => ({
  isWorking: getGameNotesModalIsWorking(state),
  isFormEnabled: getGameNoteFormIsEnabled(state)
});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { seasonId, gameId }
    }
  }
) => ({
  enableForm: () => dispatch(enableGameNoteForm()),
  clear: () => dispatch(clearGameNotes({ seasonId, gameId })),
  close: () => dispatch(push(`/seasons/${seasonId}/games/completed/${gameId}`)),
  loadNotesList: () => dispatch(gameNotesListLoadingRoutine({ seasonId, gameId }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Modal);
