import { createActions } from "redux-actions";

const { divisions: actions } = createActions({
  DIVISIONS: {
    LIST: {
      CLEAR: undefined
    }
  }
});

export default actions;
