import { combineReducers } from "redux";

import { listReducer, creatingReducer, currentReducer, bigListReducer, invitationListReducer } from "./reducers";

export default combineReducers({
  list: listReducer,
  new: creatingReducer,
  current: currentReducer,
  bigList: bigListReducer,
  invitationList: invitationListReducer
});
