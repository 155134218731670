import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import useActionsDropdown from "./hooks/useActionsDropdown";
import { Can, canCheck } from "@/components/Ability";
import { useSeasonArchiving } from "./hooks";
import ConfirmSeasonArchivingModal from "../ConfirmSeasonArchivingModal";
import useSeasonDeleting from "./hooks/useSeasonDeleting"
import ConfirmDeleteSeasonModal from "./ConfirmDeleteSeasonModal";
import { toast } from "react-toastify";
import { loadingRoutine } from "@/redux/seasonsList";
import { useDispatch } from "react-redux";
import useCurrentAssociation from "@/hooks/useCurrentAssociation";
import useCurrentLeague from "@/hooks/useCurrentLeague";
import useCurrentLeagueContext from "@/hooks/useCurrentLeagueContext";



export default function ActionsDropdown(props) {
  
  
  const { seasonId, id, title } = props;
  const { leagueId } = useCurrentLeagueContext()
  const { leagueAssociationId: associationId } = useCurrentLeague(leagueId)
  const dispatch = useDispatch();
  const { isOpen, toggleIsOpened, } = useActionsDropdown({ seasonId, id, title });


  const showButton =
    canCheck({ I: "update", this: { type: "seasons", id } }) ||
    canCheck({ I: "delete", this: { type: "seasons", id } }) ||
    canCheck({ I: "update", this: { type: "all" } });

  const _archiveSeason = useSeasonArchiving(id);
  const archiveSeason = useCallback(async () => {
    
    _archiveSeason()
    .then(async () => {
      await new Promise(r => setTimeout(r, 500)); // give the archive event a chance to process
      toast.success("Season archived")
      setModalIsOpen(false)
      console.log("Season archived", associationId, leagueId)
      dispatch(loadingRoutine({ associationId, leagueId }))
    })

  }, [_archiveSeason])

  const deleteSeason = useSeasonDeleting(id)



  const [isModalOpen, setModalIsOpen] = useState(false);
  const [isArchiving, setIsArchiving] = useState(false)

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting]= useState(false)

  const toggleModal = useCallback(() => !isArchiving && setModalIsOpen(!isModalOpen), [
    setModalIsOpen,
    isArchiving,
    isModalOpen
  ]);


  const toggleDeleteModal = useCallback(() => !isDeleting &&  setIsDeleteModalOpen(!isDeleteModalOpen), [
    setModalIsOpen,
    isModalOpen,
    isDeleting
  ]);

  return (
    <>
      {(showButton && (
        <ButtonDropdown isOpen={isOpen} toggle={toggleIsOpened}>
          <DropdownToggle caret size="sm" color="secondary" outline>
            Actions
          </DropdownToggle>

          <DropdownMenu>
            <Can I="update" this={{ type: "seasons", id }}>
              <DropdownItem
                onClick={() => {}}
                action="update"
                size="sm"
                color="primary"
                tag={Link}
                to={`/seasons/${id}/edit`}
              >
                Edit
              </DropdownItem>
            </Can>
            <Can I="update" this={{ type: "all" }}>
              <DropdownItem
                onClick={() => {}}
                action="update"
                size="sm"
                color="primary"
                tag={Link}
                to={`/seasons/${id}/clone`}
              >
                Clone
              </DropdownItem>
            </Can>
            <Can I="update" this={{ type: "seasons", id }}>
              <DropdownItem onClick={toggleModal}>
                Archive
              </DropdownItem>
            </Can>

            <Can I="delete" this={{ type: "seasons", id }}>
              <DropdownItem divider />
              <DropdownItem className="text-danger" onClick={toggleDeleteModal}>
                Delete
              </DropdownItem>
            </Can>
          </DropdownMenu>
          <ConfirmSeasonArchivingModal
            seasonTitle={title}
            isArchiving={isArchiving}
            onConfirm={archiveSeason}
            isModalOpen={isModalOpen}
            setModalIsOpen={setModalIsOpen}
            toggleModal={toggleModal}
            setIsArchiving={setIsArchiving}
          />
          <ConfirmDeleteSeasonModal
            resourceTitle={title}
            resourceType="Season"
            isDeleting={isDeleting}
            onDelete={deleteSeason}
            toggleDeleteModal={toggleDeleteModal}
            isDeleteModalOpen={isDeleteModalOpen}
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            setIsDeleting={setIsDeleting}
          />
  
        </ButtonDropdown>
      )) || <span />}
    </>
  );
}

ActionsDropdown.propTypes = {
  seasonId: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
};

ActionsDropdown.defaultProps = {
  numTeams: 0,
  hasUnlockedTeams: true,
  triggerTeamsLocking: () => {}
};
