import { combineReducers } from "redux";
import { handleActions, combineActions } from "redux-actions";

import { loadingRoutine } from "./routines";
import actions from "./actions";

import { teams, divisions } from "./reducers";

const isLoaded = handleActions(
  {
    [loadingRoutine.SUCCESS]: () => true,
    [actions.clear]: () => false
  },
  false
);

const isLoading = handleActions(
  {
    [loadingRoutine.REQUEST]: () => true,
    [combineActions(loadingRoutine.FULFILL, actions.clear)]: () => false
  },
  false
);

export default combineReducers({ isLoaded, isLoading, teams, divisions });
