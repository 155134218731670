import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Table, FormGroup, Label, Input } from "reactstrap";

class Flood extends PureComponent {
  static propTypes = {
    firstFlood: PropTypes.bool,
    secondFlood: PropTypes.bool
  };

  static defaultProps = {
    firstFlood: false,
    secondFlood: false
  };

  render() {
    const { firstFlood, secondFlood } = this.props;

    return (
      <Table striped borderless size="sm">
        <thead>
          <tr>
            <th scope="colgroup" colSpan="2">
              Flood Between
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <FormGroup check inline disabled>
                <Label check>
                  <Input type="checkbox" disabled checked={firstFlood} /> 1st &amp; 2nd
                </Label>
              </FormGroup>
            </td>
            <td>
              <FormGroup check inline disabled>
                <Label check>
                  <Input type="checkbox" disabled checked={secondFlood} /> 2nd &amp; 3rd
                </Label>
              </FormGroup>
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }
}

export default Flood;
