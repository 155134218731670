import React, { useCallback, useEffect, useMemo, useState } from "react";

import Breadcrumbs from "@/components/Breadcrumbs";
import Head from "@/components/Head";
import TitleBar from "@/components/TitleBar";
import ProtectedLayout from "@/layouts/ProtectedLayout";
import SearchBar from "@/components/SearchBar";
import { config } from "../../config";
import axios from "axios";
import TotalCount from "@/components/TotalCount";

import "./SearchBar.scss"
import PageLoader from "@/components/PageLoader";
import EmptyListMessage from "@/components/EmptyListMessage";
import { useRecoilState } from "recoil";
import { currentQueryState, searchResultsState } from "./state";
import { Table } from "reactstrap";
import Player from "./Player";
import InfiniteScroll from "react-infinite-scroll-component";
import InfiniteScrollEndMessage from "@/components/InfiniteScroll/EndMessage/EndMessage";
import InfiniteScrollSpinner from "@/components/InfiniteScroll/Spinner/Spinner";
import { getFreshToken } from "@/lib/api/getFreshToken";

export function PlayerPenaltyReportSearch() {

    const PAGE_SIZE = 15;

    // persistant state
    const [currentQuery, setCurrentQuery] = useRecoilState(currentQueryState)
    const [players, setPlayers] = useRecoilState(searchResultsState)

    // rendered state
    const [nextQuery, setQuery] = useState(currentQuery)
    const [offset, setOffset] = useState(0);
    const [isLoading, setIsLoading] = useState(false)

    // derived values
    const isDirty = useMemo(() => nextQuery != currentQuery, [currentQuery, nextQuery])
    const totalPlayers = useMemo(() => players.data.length == 0 ? 0 : players.data[0].total, [JSON.stringify(players.data)])
    const noPlayersFound = useMemo(() => !players.data.length && !isLoading && currentQuery != "", [currentQuery, players.data.length, isLoading])
    const showTable = useMemo(() => !!players.data.length && !isLoading, [players.data.length, isLoading])
    const hasMore = useMemo(() => !currentQuery || totalPlayers > players.data.length, [players.data.length, totalPlayers, currentQuery])

    // callbacks
    const getPlayers = useCallback(async () => {
        console.log("getPlayers", currentQuery, PAGE_SIZE, offset)
        const token = await getFreshToken();
        return axios.get(`${config.BFF_API}/people/v4?filter[query]=${currentQuery}&filter[persontype]=player&filter[limit]=${PAGE_SIZE}&filter[offset]=${offset}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        }).then(response => response.data.data)
    }, [currentQuery, PAGE_SIZE, offset])

    // actions
    const resetdata = () => {
        setPlayers({ query: "", data: [], offset: 0 })
        setOffset(0)
    }

    const commit = () => {
        setIsLoading(true)
        resetdata()
        setCurrentQuery(nextQuery)
        !nextQuery && setIsLoading(false)
    }

    const clear = () => {
        resetdata()
        setQuery("")
    }

    const next = () => {
        setOffset(prev => prev + PAGE_SIZE)
    }

    useEffect(() => {
        console.log("offset changed to: ", offset)
    }, [offset])

    // side effects
    useEffect(() => {
        let mounted = true;
        // console.log("useEffect",currentQuery,offset,players)

        if (currentQuery && (currentQuery != players.query || offset > players.offset )) {

            getPlayers().then(_players => {
                if (mounted) {
                    setPlayers((prev) => ({ query: currentQuery, data: [ ...prev.data, ..._players ], offset }));
                    setIsLoading(false)
                }
            })

        }

        return () => {
            mounted = false
        }
    }, [players, getPlayers])

    return <ProtectedLayout subject={{ type: "reports" }} action="read">
        <Head title="Player Penalty Reports" />
        <Breadcrumbs items={[{ title: "Reports" }, { title: "Player Penalty Reports", url: "/reports/player-penalty" }]} />
        <TitleBar title="Search Players" />
        <SearchBar
            className="player-penalty-report__search-bar"
            placeholder="Search players…"
            nextQuery={nextQuery}
            currentQuery={currentQuery}
            isDirty={isDirty}
            disabled={isLoading}
            isLoading={isLoading}
            onChange={setQuery}
            commit={commit}
            clear={clear}
        />

        <PageLoader isLoading={isLoading} />

        {noPlayersFound && <EmptyListMessage>No Players Found</EmptyListMessage>}

        {!!showTable && <>
            <TotalCount plural="players" singular="player" totalCount={totalPlayers} filteredCount={players.data.length} />

            <InfiniteScroll
                dataLength={players.data.length}
                next={next}
                hasMore={hasMore}
                loader={<InfiniteScrollSpinner enabled/>}
                endMessage={<InfiniteScrollEndMessage text="You've reached the end of the players list."/>}
            >
                <Table striped borderless>
                    <thead>
                        <tr>
                            <th scope="col">Player</th>
                            <th scope="col">External ID</th>
                            <th scope="col">League</th>
                        </tr>
                    </thead>
                    <tbody>

                        {players.data.map(({ externalId, ...rest }) => (
                            <Player key={externalId} id={externalId} externalId={externalId} {...rest} />
                        ))}

                    </tbody>
                </Table>
            </InfiniteScroll>

        </>}

    </ProtectedLayout>

}