import React from "react";
import PropTypes from "prop-types";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import ArchivedSeasonContent from "./ArchivedSeasonContent";

function EnsureCurrentSeasonActive({ children }) {
  const { seasonIsArchived } = useCurrentSeasonContext();

  return <ArchivedSeasonContent condition={seasonIsArchived}>{children}</ArchivedSeasonContent>;
}

EnsureCurrentSeasonActive.propTypes = {
  children: PropTypes.node
};

EnsureCurrentSeasonActive.defaultProps = {
  children: null
};

export default EnsureCurrentSeasonActive;
