import { connect } from "react-redux";

import { getMembers, getTeamIsUpdating } from "@/redux/teamRosterExtender/selectors";

import Table from "./Table";
import { addingMemberToTeamRoutine } from "@/redux/teamRosterExtender/routines";

const mapStateToProps = state => ({
  members: getMembers(state),
  teamIsUpdating: getTeamIsUpdating(state)
});

const mapDispatchToProps = (dispatch, { teamId, seasonId }) => ({
  addMemberToRoster: ({ id, type }) => dispatch(addingMemberToTeamRoutine({ memberId: id, type, teamId, seasonId }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Table);
