import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getMeta, getSeason, loadingRoutine } from "@/redux/currentSeason";

export default function useCurrentSeason(seasonId) {
  const dispatch = useDispatch();

  const {
    isLoaded: seasonIsLoaded,
    isLoading: seasonIsLoading,
    isForbidden: seasonIsForbidden,
    isFailed: seasonIsFailed,
    isArchived: seasonIsArchived
  } = useSelector(getMeta);

  const season = useSelector(getSeason);
  const association = season.association;
  const league = season.league;
  const associationId = association.id;
  const leagueId = league.id;

  const {
    title,
    externalId,
    generalSettings,
    statsSettings,
    penaltySettings,
    flaggingSettings,
    playerOfTheGame,
    startDate,
    endDate,
    liveScoringMode, 
    leagueAppMode
  } = season;

  const gameSettings = {
    generalSettings,
    statsSettings,
    penaltySettings,
    flaggingSettings
  };

  const seasonFormFields = {
    ...gameSettings,
    title,
    externalId,
    playerOfTheGame,
    startDate,
    endDate,
    liveScoringMode,
    leagueAppMode
  };

  const seasonBreadcrumbs = seasonIsLoaded
    ? [
        { title: "Associations", url: "/associations" },
        {
          title: association.title,
          url: `/associations/${associationId}/leagues`
        },
        {
          title: league.title,
          url: `/leagues/${leagueId}/seasons`
        },
        {
          title: season.title,
          url: `/seasons/${seasonId}`
        }
      ]
    : [];

  useEffect(
    () => {
      dispatch(loadingRoutine({ seasonId }));
    },
    [dispatch, seasonId]
  );

  return {
    season,
    seasonId,
    seasonIsLoading,
    seasonIsLoaded,
    seasonIsForbidden,
    seasonIsArchived,
    seasonIsFailed,
    seasonBreadcrumbs,
    seasonFormFields,
    seasonGameSettings: gameSettings,
    seasonAssociationId: associationId,
    seasonLeagueId: leagueId
  };
}
