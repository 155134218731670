import { useContext, useMemo } from "react";

import CurrentIpadKeyContext from "@/contexts/CurrentIpadKeyContext";

export default function useCurrentIpadKeyContext() {
  const currentIpadKeyContext = useContext(CurrentIpadKeyContext);
  const currentIpadKeyContextMemo = useMemo(() => ({ ...currentIpadKeyContext }), [currentIpadKeyContext]);

  return currentIpadKeyContextMemo;
}
