import { handleActions } from "redux-actions";

import { loadGamesheetRoutine } from "../../routines";
import { clearGamesheet } from "../../actions";
import reduceRefereeNotes from "../../utils/reduceRefereeNotes";

const initialState = [];

export default handleActions(
  {
    [loadGamesheetRoutine.SUCCESS]: reduceRefereeNotes,
    [clearGamesheet]: () => initialState
  },
  initialState
);
