import React, { Fragment } from "react";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";
import Head from "@/components/Head";
import PageLoader from "@/components/PageLoader";

import ScheduledGameForm, { useScheduledGameFormClearing } from "@/components/ScheduledGameForm";

import { useTeamPickerFieldLoading, useTeamPickerFieldClearing } from "@/components/TeamPickerField";

function NewScheduledGame() {
  const { seasonIsLoading, seasonIsLoaded, seasonIsForbidden, seasonBreadcrumbs, seasonId } = useCurrentSeasonContext();

  useTeamPickerFieldLoading();
  useTeamPickerFieldClearing();
  useScheduledGameFormClearing();

  return (
    <ProtectedLayout condition={!seasonIsForbidden}>
      <PageLoader isLoading={seasonIsLoading} isLoaded={seasonIsLoaded}>
        {seasonIsLoaded && (
          <Fragment>
            <Head title="New Scheduled Game" />
            <Breadcrumbs
              items={
                seasonBreadcrumbs && [
                  ...seasonBreadcrumbs,
                  {
                    title: "Scheduled Games",
                    url: `/seasons/${seasonId}/games/scheduled`
                  }
                ]
              }
            />
            <TitleBar title="New Scheduled Game" />
          </Fragment>
        )}
      </PageLoader>

      {seasonIsLoaded && <ScheduledGameForm />}
    </ProtectedLayout>
  );
}

export default NewScheduledGame;
