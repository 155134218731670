import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import _isEqual from "lodash/isEqual";

import { getGameFormTeamTitle, getGameFormTeamLineup, getTeamNormJerseysPlaying } from "@/redux/gameForm/selectors";

import actions from "@/redux/gameForm/actions";
import usePrevious from "@/hooks/usePrevious";

export default function useLineup({ team }) {
  const teamTitle = useSelector(state => getGameFormTeamTitle(state, team));
  const lineup = useSelector(state => getGameFormTeamLineup(state, team));

  const normJerseysPlaying = useSelector(state => getTeamNormJerseysPlaying(state, team));
  const prevNormJerseysPlaying = usePrevious(normJerseysPlaying);
  const refreshJerseyValidity = !_isEqual(normJerseysPlaying, prevNormJerseysPlaying);

  const dispatch = useDispatch();
  const updatePlayer = useCallback(
    (playerId, { target: { name, value } }) =>
      dispatch(
        actions.updatePlayer({
          team,
          playerId,
          field: name,
          value: value
        })
      ),
    [dispatch, team]
  );

  return {
    teamTitle,
    lineup,
    refreshJerseyValidity,
    updatePlayer
  };
}
