import { handleActions } from "redux-actions";

import { seasonRosterActions } from "../../actions";
import { mergeSeasonCoachesRoutine } from "../../routines";

const { mergeTool: mergeToolActions } = seasonRosterActions.coaches;

const initialState = {
  seasonId: null,
  status: null,
  ids: [],
  winnerId: null
};

function updateStatus(state, status, rest) {
  return { ...state, status, ...rest };
}

function reduceEnabled(state, { payload: { seasonId } }) {
  return updateStatus(state, "selecting-coaches", { seasonId });
}

function reduceDisabled(state) {
  return updateStatus(state, null, { ids: [] });
}

function reduceCoachSelected(state, { payload: id }) {
  return {
    ...state,
    ids: Array.from(new Set(state.ids).add(id)),
    winnerId: null
  };
}

function reduceCoachDeselected(state, { payload: id }) {
  return { ...state, winnerId: null, ids: state.ids.filter(pid => pid !== id) };
}

function reduceWinnerChosen(state, { payload: winnerId }) {
  return { ...state, winnerId };
}

function reduceSettingStatus(state, { payload: status }) {
  return updateStatus(state, status);
}

function reduceMergingRequest(state) {
  return updateStatus(state, "merging");
}

function reduceMergingFailure(state) {
  return updateStatus(state, "failure");
}

function reduceMergingSuccess(state) {
  return updateStatus(state, null, { ids: [] });
}

export default handleActions(
  {
    [seasonRosterActions.clear]: () => ({ ...initialState }),
    [mergeToolActions.enable]: reduceEnabled,
    [mergeToolActions.disable]: reduceDisabled,
    [mergeToolActions.selectCoach]: reduceCoachSelected,
    [mergeToolActions.deselectCoach]: reduceCoachDeselected,
    [mergeToolActions.chooseWinner]: reduceWinnerChosen,
    [mergeToolActions.setStatus]: reduceSettingStatus,
    [mergeSeasonCoachesRoutine.REQUEST]: reduceMergingRequest,
    [mergeSeasonCoachesRoutine.FAILURE]: reduceMergingFailure,
    [mergeSeasonCoachesRoutine.SUCCESS]: reduceMergingSuccess
  },
  initialState
);
