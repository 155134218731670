import { selectorFamily } from "recoil";
import Service from "@/services";
import { selectedIdsState } from '../../List/state';
import { isDeletingState, selectAllGamesState } from "./atoms";

export const bulkDeleteState = selectorFamily({
  key: 'bulkDelete',
  get: ({ seasonId }) => ({ get }) => {
    const selectedIds = get(selectedIdsState);
    const isDeleting = get(isDeletingState);
    const allGamesSelected = get(selectAllGamesState);

    if (!seasonId || !isDeleting) {
      return 0;
    }

    if (selectedIds.length === 0) {
      return 0;
    }

    //check for all selected flag
    if(allGamesSelected){
      // run delete all
      return Service.api().seasons.season( seasonId ).scheduledGames.deleteAll().then(response => {
        return response;
      });
    } else {
      // run normal delete
      return Promise.all(selectedIds.map(id => 
        Service.api().seasons.season( seasonId ).scheduledGames.delete({
          gameId: id
        }))).then(responses => {
          return responses.length;
        });
    }
  }
});
