import React from "react";

import { Link } from "react-router-dom";
import { Table, Button } from "reactstrap";

import EmptyListMessage from "@/components/EmptyListMessage";
import TotalCount from "@/components/TotalCount";

import useCurrentLeagueContext from "@/hooks/useCurrentLeagueContext";

import Season from "./Season";

import { useSeasonsList } from "./hooks";

function ArchivedSeasonsList({ otp }) {
  const { seasons, totalCount, activeTotalCount } = useSeasonsList();
  const { leagueId } = useCurrentLeagueContext();

  if (seasons.length < 1) {
    return (
      <EmptyListMessage>
        <p>There are no archived seasons.</p>

        <Button color="primary" outline tag={Link} to={`/leagues/${leagueId}/seasons`}>
          View Active Seasons ({activeTotalCount})
        </Button>
      </EmptyListMessage>
    );
  }

  return (
    <div className="seasons-index">
      <TotalCount plural="archived seasons" singular="archived season" totalCount={totalCount}>
        <Link to={`/leagues/${leagueId}/seasons`}>View Active Seasons ({activeTotalCount})</Link>
      </TotalCount>

      <Table striped borderless>
        <thead>
          <tr>
            <th scope="col">Title</th>
            <th scope="col">
              <span className="visuallyhidden">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {seasons.map(({ id, ...rest }) => (
            <Season key={id} id={id} otp={otp} {...rest} />
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default ArchivedSeasonsList;
