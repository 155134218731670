import React from "react";
import PropTypes from "prop-types";

import SelectInput from "./SelectInput";

import useTeamPickerFieldDivisionInput from "./hooks/useTeamPickerFieldDivisionInput";

function DivisionInput({ invalid, value, onChange }) {
  const { divisions, isLoaded, handleChange } = useTeamPickerFieldDivisionInput(onChange);

  const { division: divisionId } = value;

  if (!isLoaded) {
    return null;
  }

  return (
    <SelectInput
      invalid={invalid}
      onChange={handleChange}
      value={isLoaded ? divisionId : ""}
      placeholder="No division selected"
      allowBlank={isLoaded}
      collection={divisions}
    />
  );
}

DivisionInput.propTypes = {
  invalid: PropTypes.bool,
  value: PropTypes.shape({ division: PropTypes.string.isRequired }).isRequired,
  onChange: PropTypes.func
};

DivisionInput.defaultProps = {
  invalid: false,
  onChange() {}
};

export default DivisionInput;
