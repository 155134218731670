import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { verificationEmailResendingRoutine } from "@/redux/userForm/routines";

import {
  getUserEmail,
  getUserStatus,
  getIsResendingVerificationEmail,
  getIsResendingVerificationEmailFailed,
  getIsOperating
} from "@/redux/userForm/selectors";

export default function useVerificationEmailResending(userId) {
  const dispatch = useDispatch();

  const userEmail = useSelector(getUserEmail);
  const userStatus = useSelector(getUserStatus);
  const isResending = useSelector(getIsResendingVerificationEmail);
  const isResendingFailed = useSelector(getIsResendingVerificationEmailFailed);
  const isOperating = useSelector(getIsOperating);

  const triggerResending = useCallback(() => dispatch(verificationEmailResendingRoutine({ userId, userEmail })), [
    dispatch,
    userId,
    userEmail
  ]);

  return {
    userStatus,
    userEmail,
    isResending,
    isResendingFailed,
    isOperating,
    triggerResending
  };
}
