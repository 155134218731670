import { handleActions } from "redux-actions";

import { listLoadingRoutine } from "../routines";
import actions from "../actions";

const initialState = {};

function reduceLoadingSuccess(state, { payload: { teams } }) {
  return teams.reduce((result, team) => {
    const {
      league: { id, title }
    } = team;

    return {
      ...result,
      [id]: {
        title
      }
    };
  }, state);
}

export default handleActions(
  {
    [listLoadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
