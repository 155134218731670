import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Button, CustomInput } from "reactstrap";

import Spinner from "@/components/Spinner";

import ReportDataSourcesSelectListItem from "./Item";

import "./List.scss";

class SelectList extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    isLoaded: PropTypes.bool,
    isOptionSelected: PropTypes.bool,
    singleSelect: PropTypes.bool,
    isAllOptionsSelected: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired
      })
    ),
    label: PropTypes.string,
    emptyListMessage: PropTypes.string,
    select: PropTypes.func,
    deselect: PropTypes.func,
    clear: PropTypes.func,
    selectAll: PropTypes.func,
    checkboxLabel: PropTypes.string,
    checkboxCallback: PropTypes.func,
    checkboxChecked: PropTypes.bool
  };

  static defaultProps = {
    isLoading: false,
    isLoaded: false,
    isOptionSelected: false,
    singleSelect: false,
    isAllOptionsSelected: false,
    options: [],
    label: "Select options",
    emptyListMessage: "No items selected",
    checkboxCallback: () => {},
    select() {},
    deselect() {},
    clear() {},
    selectAll() {}
  };

  render() {
    const {
      isLoading,
      isLoaded,
      isOptionSelected,
      singleSelect,
      options,
      label,
      emptyListMessage,
      select,
      deselect,
      clear,
      selectAll,
      isAllOptionsSelected,
      checkboxLabel,
      checkboxCallback,
      checkboxChecked
    } = this.props;

    return (
      <div className="report-data-sources-select-list">
        <div className="d-flex flex-direction-row justify-content-between">
          <p className="report-data-sources-select-list__label">{label}</p>

          {isLoaded &&
            checkboxLabel && (
              <span className="report-data-sources-select-list__label__control">
                <CustomInput
                  type="checkbox"
                  id={`report-data-sources-select-list__${checkboxLabel}-control`}
                  label={checkboxLabel}
                  checked={checkboxChecked}
                  onChange={checkboxCallback}
                />
              </span>
            )}
        </div>

        <div className="report-data-sources-select-list__input list-group list-group-flush">
          {isLoading ? (
            <Spinner className="report-data-sources-select-list__spinner" theme="loader" color="yellow" size="xxl" />
          ) : isLoaded ? (
            options.map(({ id, ...rest }) => (
              <ReportDataSourcesSelectListItem key={id} id={id} select={select} deselect={deselect} {...rest} />
            ))
          ) : (
            <p className="report-data-sources-select-list__empty-list-message">{emptyListMessage}</p>
          )}
        </div>

        <div className="report-data-sources-select-list__controls">
          {isOptionSelected && (
            <Button
              className="report-data-sources-select-list__controls__left-control"
              color="link"
              size="sm"
              onClick={clear}
            >
              Clear Selection
            </Button>
          )}

          {isLoaded &&
            !singleSelect &&
            isAllOptionsSelected === false && (
              <Button
                className="report-data-sources-select-list__controls__right-control"
                color="link"
                size="sm"
                onClick={selectAll}
              >
                Select All
              </Button>
            )}
        </div>
      </div>
    );
  }
}

export default SelectList;
