import React, { useCallback, useEffect, useState } from "react";
import { config } from "../../config";
import PageLoader from "../PageLoader";
import TitleBar from "../TitleBar";
import { Button } from "reactstrap";
import { getFreshToken } from "@/lib/api/getFreshToken";
import CareerStats from "../TeamMemberPenaltyReport/CareerStats";
import StatsBySeason from "../TeamMemberPenaltyReport/StatsBySeason";
import MajorPenalties from "../TeamMemberPenaltyReport/MajorPenalties";
import InfractionHistory from "../TeamMemberPenaltyReport/InfractionHistory";
import { useDispatch, useSelector } from "react-redux";
import buildAbilityRules from "@/lib/core/buildAbilityRules";
import { getTokenRoles } from "@/redux/token/selectors";
import { addAbilityRules } from "@/redux/ability/actions";
import { CoachPenaltyReportData } from "./class.report";

import "./Report.scss";

export default function CoachPenaltyReport({ externalId }) {

    const handlePrint = useCallback(() => window.print(), []);
    const [ report, setReport ] = useState(new CoachPenaltyReportData());
    const [ isSuspended, setIsSuspended ] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const roles = useSelector(getTokenRoles);
    const dispatch = useDispatch();

    useEffect(() => {
        getCoachPenaltyReport().then(data => {
            const report = new CoachPenaltyReportData();
            report.externalId = externalId;
            report.data = data;

            setIsSuspended(report.isSuspended);

            setReport(report);
            setIsLoading(false);
            setIsLoaded(true);
        })
    }, [externalId]);

    const handleAbilityRules = useCallback((data) => {
        const games = (data.coach_games || []).map(game => ({
            id: game.id,
            home: { id: game.home.id, division: { id: game.home.division } },
            visitor: { id: game.visitor.id, division: { id: game.visitor.division } },
            association: { id: game.association_id },
            league: { id: game.league_id },
            season: { id: game.season_id },
        }));

        const resources = { games };

        const rules = buildAbilityRules({ roles, resources });

        dispatch(addAbilityRules(rules));
    }, []);

    async function getCoachPenaltyReport() {
        const accessToken = await getFreshToken();
        return fetch(`${config.BFF_API}/reports/coach-penalty-report/${externalId}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(res => res.json())
            .then(response => {
                handleAbilityRules(response.data);
                return response.data
            })
    }

    const coachIds = report.ids;
    const coachId = coachIds.length > 0 && coachIds[0] || null;
    const handleSuspend = useCallback(async () => {
        if (!coachId) return;

        const accessToken = await getFreshToken();
        
        return fetch(`${config.BFF_API}/coaches/${coachId}/${isSuspended && 'unsuspend' || 'suspend'}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({}),
        }).then((response) => {
            if (!response.ok) {
                throw new Error(response.statusText);
            } else {
                setIsSuspended(!isSuspended);
            }
        }).catch((error) => {
            console.error("Error:", error);
        });
    }, [coachId, isSuspended]);

    return <>

        <PageLoader isLoading={isLoading} isLoaded={isLoaded}>
            <TitleBar
                title={`${report.firstName} ${report.lastName} — Coach Penalty Report`}
                subtitle={`External ID: ${externalId}`}
            >
                {isLoaded && <Button outline color="primary" size="sm" onClick={handleSuspend}>
                    {isSuspended && "Unsuspend" || "Suspend"}
                </Button>}
                <Button outline color="primary" size="sm" onClick={handlePrint}>
                    Print
                </Button>
            </TitleBar>

            <CareerStats {...report.careerStats} />
            <StatsBySeason stats={report.statsBySeason} />
            <MajorPenalties penalties={report.majorPenalties} />
            <InfractionHistory history={report.infractionHistory} />
        </PageLoader>

    </>
}