import React from "react";

import ResourceSelect from "./ResourceSelect";

import useTeamSelect from "./hooks/useTeamSelect";

function TeamSelect() {
  const { isLoaded, isLoading, options, isEnabled, toggleSelected, resourceType, isOptionSelected, isAllOptionsSelected } = useTeamSelect();

  return (
    <ResourceSelect
      label="Select team(s)"
      isLoaded={isLoaded}
      isLoading={isLoading}
      options={options}
      isEnabled={isEnabled}
      toggleSelected={toggleSelected}
      isOptionSelected={isOptionSelected} 
      isAllOptionsSelected={isAllOptionsSelected}
      resourceType="teamList"
    />
  );
}

export default TeamSelect;
