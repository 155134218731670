import { useEffect } from "react";
import { useDispatch } from "react-redux";

import useCurrentLeagueContext from "@/hooks/useCurrentLeagueContext";

import { loadingRoutine } from "@/redux/seasonsList";

import useSeasonsList from "./useSeasonsList";

export default function useSeasonsListLoading() {
  const dispatch = useDispatch();

  const { leagueIsLoaded, leagueId, leagueAssociationId } = useCurrentLeagueContext();

  const { isListLoaded, isListLoading, isListFailed } = useSeasonsList();

  useEffect(
    () => {
      if (leagueIsLoaded && !isListLoaded && !isListLoading && !isListFailed) {
        dispatch(loadingRoutine({ leagueId, associationId: leagueAssociationId }));
      }
    },
    [dispatch, leagueIsLoaded, isListLoaded, isListLoading, isListFailed, leagueId, leagueAssociationId]
  );
}
