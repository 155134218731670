import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Input } from "reactstrap";

class SeasonIpadKeysManagerKeyFilter extends PureComponent {
  static propTypes = {
    filter: PropTypes.string,
    setFilter: PropTypes.func,
    totalKeysAmount: PropTypes.number
  };

  static defaultProps = {
    filter: "",
    setFilter() {},
    totalKeysAmount: 0
  };

  handleChange = ({ target: { value } }) => {
    this.props.setFilter(value);
  };

  render() {
    const { filter, totalKeysAmount } = this.props;

    return (
      <div className="pb-4">
        <Input
          className="dashboard-form__input--monospace"
          placeholder="Search keys"
          value={filter}
          onChange={this.handleChange}
          disabled={totalKeysAmount === 0}
        />
      </div>
    );
  }
}

export default SeasonIpadKeysManagerKeyFilter;
