import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import Head from "@/components/Head";
import Breadcrumbs, { BreadcrumbsProps } from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";
import PageLoader from "@/components/PageLoader";
import RefereeForm from "@/components/RefereeForm";

class RefereesNew extends PureComponent {
  static propTypes = {
    seasonId: PropTypes.string.isRequired,
    seasonIsLoading: PropTypes.bool,
    seasonIsLoaded: PropTypes.bool,
    seasonIsForbidden: PropTypes.bool.isRequired,
    seasonBreadcrumbs: BreadcrumbsProps,
    clearForm: PropTypes.func
  };

  static defaultProps = {
    seasonIsLoading: false,
    seasonIsLoaded: false,
    seasonBreadcrumbs: null,
    clearForm() {}
  };

  componentWillUnmount() {
    this.props.clearForm();
  }

  render() {
    const { seasonIsLoading, seasonIsLoaded, seasonIsForbidden, seasonBreadcrumbs, seasonId } = this.props;

    return (
      <ProtectedLayout
        subject={{ type: "referees", seasonId }}
        action="create"
        skip={!seasonIsLoaded && !seasonIsForbidden}
      >
        {seasonIsLoaded ? (
          <Fragment>
            <Head title="New Referee" />
            <Breadcrumbs
              items={seasonBreadcrumbs}
              lastItem={{
                title: "Referees",
                url: `/seasons/${seasonId}/referees`
              }}
            />
            <TitleBar title="New Referee" />
            <RefereeForm seasonId={seasonId} />
          </Fragment>
        ) : (
          <PageLoader isLoading={seasonIsLoading} />
        )}
      </ProtectedLayout>
    );
  }
}

export default RefereesNew;
