import { handleActions } from "redux-actions";

import { teamListLoadingRoutine as loadingRoutine } from "../routines";
import actions from "../actions";

const initialState = {
  isLoaded: false,
  isLoading: false,
  ids: [],
  data: {},
  selectedIds: [] 
};

function updateDivisionTeams(state, divisionId, data) {
  return {
    ...state,
    [divisionId]: {
      ...state[divisionId],
      ...data
    }
  };
}

function reduceDivisionSelected(state, { payload: { id: divisionId } }) {
  return {
    ...state,
    [divisionId]: {
      isLoaded: false,
      isLoading: false,
      ids: [],
      data: {},
      ...state[divisionId]
    }
  };
}

function reduceLoadingRequest(state, { payload }) {
  const { divisionId } = payload;

  return updateDivisionTeams(state, divisionId, { isLoading: true });
}

function reduceLoadingSuccess(state, { payload }) {
  const { divisionId, ids, teams } = payload;

  const data = teams.reduce((result, { id, title }) => ({ ...result, [id]: { title } }), {});

  return updateDivisionTeams(state, divisionId, {
    ids,
    data,
    isLoaded: true
  });
}

function reduceLoadingFailure(state, { payload }) {
  const { divisionId } = payload;

  return updateDivisionTeams(state, divisionId, { isLoaded: false });
}

function reduceLoadingFulfill(state, { payload }) {
  const { divisionId } = payload;

  return updateDivisionTeams(state, divisionId, { isLoading: false });
}

function reduceSelectTeam(state, { payload: { id } }) {
  return {
    ...state,
    selectedIds: [...state.selectedIds, id] // Add league ID to selectedIds array
  };
}

function reduceDeselectTeam(state, { payload: { id } }) {
  return {
    ...state,
    selectedIds: state.selectedIds.filter(teamId => teamId !== id) // Remove league ID from selectedIds array
  };
}

function reduceSelectAllTeams(state) {
  const allTeamIds = Object.keys(state.data).flatMap(
    divisionId => (state[divisionId] && state[divisionId].ids) || []
  );
  return {
    ...state,
    selectedIds: allTeamIds // Set selectedIds to all league IDs
  };
}

function reduceClearTeams(state) {
  return {
    ...state,
    selectedIds: [] // Clear selectedIds array
  };
}

export default handleActions(
  {
    [actions.divisionList.select]: reduceDivisionSelected,
    [loadingRoutine.REQUEST]: reduceLoadingRequest,
    [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [loadingRoutine.FAILURE]: reduceLoadingFailure,
    [loadingRoutine.FULFILL]: reduceLoadingFulfill,
    [actions.teamList.select]: reduceSelectTeam, // Handle individual league selection
    [actions.teamList.deselect]: reduceDeselectTeam, // Handle individual league deselection
    [actions.teamList.selectAll]: reduceSelectAllTeams, // Handle selecting all leagues
    [actions.teamList.clear]: reduceClearTeams // Handle clearing all selected leagues
  },
  initialState
);
