import React, { Fragment } from "react";

import { Label } from "reactstrap";

import { actions } from "@/redux/scheduledGamesFilter";

import DivisionDropdownField from "@/components/DivisionDropdownField";

import useFilter from "./hooks/useFilter";

function DivisionInput() {
  const name = "divisionIds";
  const { value, setValue, inputId } = useFilter({
    name,
    action: actions.setDivisionIds
  });

  return (
    <Fragment>
      <Label for={inputId}>Divisions</Label>
      <DivisionDropdownField
        size="sm"
        id={inputId}
        name={name}
        onChange={setValue}
        value={value}
        readyMsg="All divisions"
      />
    </Fragment>
  );
}

export default DivisionInput;
