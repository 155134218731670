import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import StickyFooter from "@/components/StickyFooter";

class CoachesListMergeToolFooter extends PureComponent {
  static propTypes = {
    countCoachesSelected: PropTypes.number,
    isEnabled: PropTypes.bool,
    status: PropTypes.string,
    winnerId: PropTypes.string,
    disable: PropTypes.func,
    setStatus: PropTypes.func,
    mergeCoaches: PropTypes.func
  };

  static defaultProps = {
    coaches: [],
    isEnabled: false,
    status: null,
    winnerId: null,
    disable() {},
    setStatus() {},
    mergeCoaches() {}
  };

  renderSelectedCoachesCount = () => {
    const { countCoachesSelected } = this.props;

    if (countCoachesSelected === 0) {
      return "No coaches selected to merge";
    }

    switch (countCoachesSelected) {
      case 0:
        return "No coaches selected to merge.";
      case 1:
        return "Only one coach selected to merge. Select at least one more.";
      default:
        return `${countCoachesSelected} coaches selected to merge.`;
    }
  };

  proceedToChoosingWinner = () => {
    this.props.setStatus("choosing-winner");
  };

  renderPrimaryButton = () => {
    const { status, countCoachesSelected, winnerId, mergeCoaches } = this.props;

    switch (status) {
      case "selecting-coaches":
        return (
          <Button
            outline={countCoachesSelected < 2}
            type="button"
            color="success"
            disabled={countCoachesSelected < 2}
            onClick={this.proceedToChoosingWinner}
          >
            Continue
          </Button>
        );
      case "choosing-winner":
        return (
          <Button
            outline={winnerId === null}
            type="button"
            color="success"
            disabled={winnerId === null}
            onClick={mergeCoaches}
          >
            Merge Coaches
          </Button>
        );
      default:
        return null;
    }
  };

  render() {
    const { isEnabled, status, disable } = this.props;

    if (!isEnabled || !["selecting-coaches", "choosing-winner"].includes(status)) {
      return null;
    }

    return (
      <StickyFooter>
        <div className="sticky-footer__secondary">
          <Button outline type="button" color="secondary" onClick={disable}>
            Cancel merge
          </Button>
        </div>

        {status === "selecting-coaches" && <p className="sticky-footer__text">{this.renderSelectedCoachesCount()}</p>}

        <div className="sticky-footer__primary">{this.renderPrimaryButton()}</div>
      </StickyFooter>
    );
  }
}

export default CoachesListMergeToolFooter;
