import React from "react";
import PropTypes from "prop-types";

import { Switch } from "react-router-dom";

import LoggedInRoute from "@/containers/LoggedInRoute";

import LeagueScope from "./LeagueScope";

function LeaguesRoute({ match: { url } }) {
  return (
    <Switch>
      <LoggedInRoute path={`${url}/:leagueId`} component={LeagueScope} />
    </Switch>
  );
}

LeaguesRoute.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired
};

export default LeaguesRoute;
