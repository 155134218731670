import { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getIsSubmitting, submittingRoutine } from "@/redux/scheduledGameForm";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";
import { useHistory } from "react-router";

export default function useScheduledGameFormSubmitting({ values, gameId }) {
  const dispatch = useDispatch();
  const { seasonId } = useCurrentSeasonContext();
  const history = useHistory()
  const [clear, setClear] = useState(0)

  const { broadcaster, timeZone, offset, ...remaining } = values;

  const submitValues = {
    ...remaining,
    timeZoneOffset: offset,
    timeZoneName: timeZone,
    data: {
      broadcaster
    }
  };

  const isSubmitting = useSelector(getIsSubmitting);


  const submit = useCallback(
    ({ goBack = true }) => {
      dispatch(submittingRoutine({ gameId, seasonId, values: submitValues, goBack: goBack, history}));
    },
    [dispatch, submitValues, gameId, seasonId]
  );

  return {
    isSubmitting,
    submit,
    clear,
    setClear
  };
}
