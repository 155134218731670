import { createActions } from "redux-actions";

const { copyTeams: copyTeamsActions } = createActions({
  COPY_TEAMS: {
    MODAL: {
      OPEN: undefined,
      CLOSE: undefined
    },
    CLEAR: undefined,
    INIT: undefined,
    BEGIN: undefined,
    IMPORTING: undefined,
    COMPLETE: undefined,
    SELECT_TEAM: undefined,
    DESELECT_TEAM: undefined
  }
});

export default copyTeamsActions;
