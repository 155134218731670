import React from "react";
import { Link } from "react-router-dom";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";
import AbilityButton from "@/components/AbilityButton";
import UserList from "@/components/UserList";
import UserFilter from "@/components/UserFilter";
import UserVerificationNotification from "@/components/UserVerificationNotification";

function UsersIndex() {
  return (
    <ProtectedLayout subject="users" action="read">
      <Head title="Users" />

      <Breadcrumbs items={[{ title: "Users", url: "/users" }]} />

      <TitleBar title="Users">
        <AbilityButton subject="users" action="create" outline color="success" size="sm" tag={Link} to="/users/new">
          + New User
        </AbilityButton>
      </TitleBar>

      <UserFilter className="mb-3" />
      <UserList />
      <UserVerificationNotification />
    </ProtectedLayout>
  );
}

export default UsersIndex;
