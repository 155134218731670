import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class InjuredPlayer extends PureComponent {
  static propTypes = {
    number: PropTypes.string,
    name: PropTypes.string,
    note: PropTypes.string
  };

  static defaultProps = {
    number: "",
    name: "",
    note: ""
  };

  render() {
    const { number, name, note } = this.props;

    return (
      <tr>
        <td className="injured">{number}</td>
        <td>
          <span className="injured">{name}</span>
          &nbsp;(INJ)
        </td>
        <td>{note}</td>
      </tr>
    );
  }
}

export default InjuredPlayer;
