import { useCallback } from "react";
import { useSelector } from "react-redux";

import { getDivisionTeams } from "@/redux/teamPickerField";

import useTeamPickerFieldMeta from "./useTeamPickerFieldMeta";

export default function useTeamPickerFieldTeamInput(options) {
  const {
    value: { team: teamId, division: divisionId },
    onChange
  } = options;

  const { isLoaded, isLoading } = useTeamPickerFieldMeta();
  const teams = useSelector(state => getDivisionTeams(state, divisionId));

  const handleChange = useCallback(({ target: { value } }) => onChange({ division: divisionId, team: value }), [
    onChange,
    divisionId
  ]);

  return {
    isLoaded,
    isLoading,
    teams,
    teamId,
    divisionId,
    handleChange
  };
}
