import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

class Member extends PureComponent {
  static propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    externalId: PropTypes.string.isRequired,
    leagues: PropTypes.arrayOf(PropTypes.string)
  };

  static defaultProps = {
    leagues: []
  };

  render() {
    const { firstName, lastName, externalId, leagues } = this.props;

    return (
      <tr>
        <td>
          <Link to={`/reports/referee/${externalId}`}>{`${firstName} ${lastName}`}</Link>
        </td>
        <td>{externalId}</td>
        <td>
          {leagues.map((league, index) => (
            <div key={league}>
              {league}
              {index + 1 < leagues.length && ","}
            </div>
          ))}
        </td>
      </tr>
    );
  }
}

export default Member;
