import { handleActions } from "redux-actions";

import { loadGameFormRoutine } from "../routines";
import actions from "../actions";

function hydrateLineup(rosterPlayers = [], players = []) {
  return rosterPlayers.map(({ id, ...rest }) => {
    const { firstName, lastName } = players.find(player => player.id === id) || {};

    return { id, firstName, lastName, ...rest };
  });
}

function hydrateCoaches(rosterCoaches = [], coaches = []) {
  return rosterCoaches.map(({ id, ...rest }) => {
    const { firstName, lastName } = coaches.find(coach => coach.id === id) || {};

    return { id, firstName, lastName, ...rest };
  });
}

function hydratePenaltyLengths({ season, home: { division } }) {
  if (division.settings.penaltyLengths && Object.keys(division.settings.penaltyLengths)) {
    return division.settings.penaltyLengths;
  }

  return season.settings.penaltyLengths;
}

function hydrateFppEnabled({ home }) {
  let fppEnabled = false;
  if (Object.keys(home.division.settings).length == 0) {
    // Division uses season settings
    if (home.season.settings.fairPlayPoints && home.season.settings.fairPlayPoints.isEnabled) {
      fppEnabled = true;
    }
  } else {
    // Division overrides season settings
    if (home.division.settings.fairPlayPoints && home.division.settings.fairPlayPoints.isEnabled) {
      fppEnabled = true;
    }
  }
  return fppEnabled;
}

function hydrateMaxFppPenaltyMinutes({ home }) {
  let fppMinutes = 16;
  if (Object.keys(home.division.settings).length == 0) {
    // Division uses season settings
    if (home.season.settings.fairPlayPoints) {
      fppMinutes = home.season.settings.fairPlayPoints.maxPenaltyMinutes;
    }
  } else {
    // Division overrides season settings
    if (home.division.settings.fairPlayPoints) {
      fppMinutes = home.division.settings.fairPlayPoints.maxPenaltyMinutes;
    }
  }
  return fppMinutes;
}

function combineSuspensions(home, visitor) {
  const homeSuspensions = home.players.reduce((result, { id, ...player }) => ({ ...result, [id]: player }), {});
  const visitorSuspensions = visitor.players.reduce((result, { id, ...player }) => ({ ...result, [id]: player }), {});
  return { ...homeSuspensions, ...visitorSuspensions };
}

const changeFairPlayEarned = (state, { payload: { team, earned } }) => {
  return {
    ...state,
    [team + "FppEarned"]: earned
  };
};

const removePlayerSuspension = (state, { payload: { playerId } }) => {
  const suspendedPlayers = { ...state.suspendedPlayers };
  delete suspendedPlayers[playerId];
  return {
    ...state,
    suspendedPlayers: suspendedPlayers
  };
};

const updatePlayerSuspension = (state, { payload: { playerId, number, length } }) => ({
  ...state,
  suspendedPlayers: {
    ...state.suspendedPlayers,
    [playerId]: {
      number: number,
      length: length
    }
  }
});

const updatePlayer = (state, { payload: { team, playerId, field, value } }) => {
  let suspendedPlayers = { ...state.suspendedPlayers };
  const lineup = [...state[`${team}Lineup`]];
  const i = lineup.findIndex(e => e.id == playerId);

  lineup[i] = { ...lineup[i] };

  switch (field) {
    case "number":
    case "position":
    case "duty":
      lineup[i][field] = value;
      break;
    case "status":
      lineup[i]["starting"] = value == "starting";
      if (lineup[i][field] === "suspended" && value !== "suspended") {
        suspendedPlayers = removePlayerSuspension(state, {
          payload: { playerId }
        }).suspendedPlayers;
      }
      switch (value) {
        case "suspended":
          if (lineup[i][field] !== "suspended") {
            suspendedPlayers = updatePlayerSuspension(state, {
              payload: { playerId, number: 0, length: 0 }
            }).suspendedPlayers;
          }
          lineup[i][field] = value;
          break;
        case "starting":
          lineup[i][field] = "playing";
          break;
        default:
          lineup[i][field] = value;
      }
      break;
    default:
      console.log("unknown Lineup update");
  }

  return {
    ...state,
    [team + "Lineup"]: lineup,
    suspendedPlayers: suspendedPlayers
  };
};

const addPlayer = (state, { payload: { team, player } }) => {
  const newPlayer = {
    addedAtGameTime: false,
    affiliated: false,
    duty: "",
    id: player.id,
    firstName: player.firstName,
    lastName: player.lastName,
    number: "",
    position: "",
    starting: false,
    status: "playing"
  };

  return {
    ...state,
    [team + "Lineup"]: [...state[`${team}Lineup`], newPlayer]
  };
};

const removePlayer = (state, { payload: { team, playerId } }) => {
  const newLineup = [...state[`${team}Lineup`]].filter(player => player.id !== playerId);
  return {
    ...state,
    [team + "Lineup"]: newLineup
  };
};

const initialState = {
  attributes: {},
  visitorId: null,
  homeId: null,
  categories: [],
  homePlayers: [],
  visitorPlayers: [],
  homeCoaches: [],
  visitorCoaches: [],
  homeTitle: null,
  visitorTitle: null,
  flags: [],
  fppEnabled: false,
  homeFppEarned: false,
  visitorFppEarned: false,
  maxFppPenaltyMinutes: 16,
  homeLineup: [],
  visitorLineup: [],
  suspendedPlayers: {},
  seasonId: ""
};

export default handleActions(
  {
    [loadGameFormRoutine.SUCCESS]: (state, { payload: { game, attributes } }) => ({
      attributes,
      visitorId: game.visitor.id,
      homeId: game.home.id,
      categories: game.home.division.settings.gameCategories || game.season.settings.gameCategories,
      homeLineup: hydrateLineup(game.homeRoster.players, game.players),
      visitorLineup: hydrateLineup(game.visitorRoster.players, game.players),
      suspendedPlayers: combineSuspensions(game.homeSuspensions, game.visitorSuspensions),
      seasonId: game.season.id,
      homeCoaches: hydrateCoaches(game.homeRoster.coaches, game.coaches),
      visitorCoaches: hydrateCoaches(game.visitorRoster.coaches, game.coaches),
      homeTitle: game.home.title,
      homeId: game.home.id,
      visitorTitle: game.visitor.title,
      visitorId: game.visitor.id,
      penaltyLengths: hydratePenaltyLengths(game),
      flags: (game.flags || []).map(({ event, code, member, team }) => {
        if (member !== undefined) {
          const { type: memberType, id: memberId } = member;

          switch (memberType) {
            case "coaches":
              member = game.coaches.find(({ id }) => id === memberId);
              break;
            case "players":
              member = game.players.find(({ id }) => id === memberId);
              break;
            default:
              break;
          }

          member = [member.firstName, member.lastName].join(" ");
        }

        if (team !== undefined) {
          const { id: teamId } = team;

          team = [game.home, game.visitor].find(({ id }) => id === teamId).title;
        }

        return {
          event,
          code,
          member,
          team
        };
      }),
      penaltyTypes: (game.season.settings.penaltyCodes || []).reduce(
        (result, { code, label }) => ({ ...result, [code]: label }),
        {}
      ),
      homeDivisionTitle: game.home.division.title,
      visitorDivisionTitle: game.visitor.division.title,
      fppEnabled: hydrateFppEnabled(game),
      homeFppEarned: game.homeFppEarned,
      visitorFppEarned: game.visitorFppEarned,
      maxFppPenaltyMinutes: hydrateMaxFppPenaltyMinutes(game)
    }),
    [actions.clear]: () => ({ ...initialState }),
    [actions.changeFairPlayEarned]: changeFairPlayEarned,
    [actions.updatePlayer]: updatePlayer,
    [actions.addPlayer]: addPlayer,
    [actions.removePlayer]: removePlayer
  },
  initialState
);
