import { toStartEndDates } from "@/components/HockeyYearField/DateUtilities";

const selectRoot = state => state.teamFilter;
const selectAssociationList = state => selectRoot(state).associationList;
const selectLeagueList = state => selectRoot(state).leagueList;
const selectSeasonList = state => selectRoot(state).seasonList;
const selectDivisionList = state => selectRoot(state).divisionList;

// Associations
export const getAssociationId = state => selectRoot(state).associationId;

export const getAssociationList = state => {
  const { ids, data } = selectAssociationList(state);

  return ids.map(id => ({
    ...data[id],
    id
  }));
};

export const getAssociationListIsLoaded = state => selectAssociationList(state).isLoaded;

export const getAssociationListIsLoading = state => selectAssociationList(state).isLoading;

// Leagues
export const getLeagueId = state => selectRoot(state).leagueId;

export const getLeagueList = (state, associationId) => {
  const { ids, data } = selectLeagueList(state)[associationId] || {
    ids: [],
    data: {}
  };

  return ids.map(id => ({ ...data[id], id }));
};

export const getLeagueListIsLoaded = (state, associationId) =>
  !!(selectLeagueList(state)[associationId] || {}).isLoaded;

export const getLeagueListIsLoading = (state, associationId) =>
  !!(selectLeagueList(state)[associationId] || {}).isLoading;

// Seasons
export const getSeasonId = state => selectRoot(state).seasonId;

export const getSeasonList = (state, leagueId) => {
  const { ids, data } = selectSeasonList(state)[leagueId] || {
    ids: [],
    data: {}
  };

  return ids.map(id => ({ ...data[id], id }));
};

export const getSeasonListIsLoaded = (state, leagueId) => !!(selectSeasonList(state)[leagueId] || {}).isLoaded;

export const getSeasonListIsLoading = (state, leagueId) => !!(selectSeasonList(state)[leagueId] || {}).isLoading;

// Divisions
export const getDivisionId = state => selectRoot(state).divisionId;

export const getDivisionList = (state, seasonId) => {
  const { ids, data } = selectDivisionList(state)[seasonId] || {
    ids: [],
    data: {}
  };

  return ids.map(id => ({ ...data[id], id }));
};

export const getDivisionListIsLoaded = (state, seasonId) => !!(selectDivisionList(state)[seasonId] || {}).isLoaded;

export const getDivisionListIsLoading = (state, seasonId) => !!(selectDivisionList(state)[seasonId] || {}).isLoading;

// Query
export const getQuery = state => selectRoot(state).query;

// Hockey Year
export const getYear = state => selectRoot(state).year;

// Filter
export const getFilterParams = state => {
  const associationId = getAssociationId(state);
  const leagueId = getLeagueId(state);
  const seasonId = getSeasonId(state);
  const divisionId = getDivisionId(state);

  const year = getYear(state);
  const startDate = year ? toStartEndDates(year).startDate : year;

  const params = {
    query: getQuery(state)
  };

  if (startDate !== "") {
    params.startDate = startDate;
  }

  if (divisionId !== "") {
    params.divisionId = divisionId;
  } else if (seasonId !== "") {
    params.seasonId = seasonId;
  } else if (leagueId !== "") {
    params.leagueId = leagueId;
  } else if (associationId !== "") {
    params.associationId = associationId;
  }

  return Object.entries(params).reduce((result, [key, value]) => {
    if (value === "") {
      return result;
    }

    return { ...result, [key]: value };
  }, {});
};

export const getSeasonFilterParams = state => {
  const year = getYear(state);
  const startDate = year ? toStartEndDates(year).startDate : year;

  const params = {};

  if (startDate !== "") {
    params.startDate = startDate;
  }

  return Object.entries(params).reduce((result, [key, value]) => {
    if (value === "") {
      return result;
    }

    return { ...result, [key]: value };
  }, {});
};

export const getIsDirty = state => Object.values(getFilterParams(state)).filter(value => value !== "").length > 0;
