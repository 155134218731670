import React from "react";
import PropTypes from "prop-types";
import { FormGroup, Label, Input, FormText } from "reactstrap";

function Message({ value, onChange }) {
  return (
    <FormGroup className="player-of-the-game__template">
      <Label for="player-of-the-game__template__input">Template Text</Label>
      <div className="player-of-the-game__template__body">
        <p>Hi {"<Player Name>"}</p>
        <p>
          You’ve been selected as the player of the game:
          <br />
          {"<Visitor Team> vs <Home Team>"}
          <br />
          played on {"<Game Date> at <Venue>"}.
        </p>
        <Input
          type="textarea"
          className="player-of-the-game__template__input"
          id="player-of-the-game__template__input"
          placeholder="Enter a message about the reward here."
          value={value}
          onChange={onChange}
        />
        <FormText className="player-of-the-game__template__hint">
          E.g. “As a reward we’re giving you a coupon for a free kids meal at Shoeless Joe’s. See the attached file
          below.”
        </FormText>
        <p>Keep up the great work!</p>
      </div>
    </FormGroup>
  );
}

Message.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
};

Message.defaultProps = {
  value: "",
  onChange() {}
};

export default Message;
