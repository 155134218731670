import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";

import { FormGroup, Label, Input, Button, FormFeedback } from "reactstrap";

import ContentSection from "@/components/ContentSection";
import DashboardForm from "@/components/DashboardForm";
import DashboardFormFooter from "@/components/DashboardFormFooter";
import FormItem from "@/components/FormItem";
import ConfirmDeletionModal from "@/components/ConfirmDeletionModal";
import { Can } from "@/components/Ability";
import AbilityButton from "@/components/AbilityButton";
import LogoPickerField from "./LogoPickerField";

import useLeagueForm from "./hooks/useLeagueForm";
import useLeagueFormSubmitting from "./hooks/useLeagueFormSubmitting";
import useLeagueFormDeleting from "./hooks/useLeagueFormDeleting";

function LeagueForm({ leagueId, associationId, initialValues }) {
  const { fields, isPristine, isInvalid, values } = useLeagueForm(initialValues);

  const { isSubmitting, submit } = useLeagueFormSubmitting({
    associationId,
    leagueId,
    values
  });

  const { isDeleting, deleteLeague } = useLeagueFormDeleting({
    associationId,
    leagueId
  });

  return (
    <DashboardForm className="league-form" isOperating={isSubmitting || isDeleting} onSubmit={submit}>
      <ContentSection>
        <FormItem>
          <FormGroup className="col-md-6 required">
            <Label for="leagueTitle">League Name</Label>
            <Input
              id="leagueTitle"
              name="leagueTitle"
              {...fields.title.input}
              placeholder="e.g. Minor Midget AAA Showcase"
            />

            {fields.title.input.invalid &&
              fields.title.errors.map(error => <FormFeedback key={error}>{error}</FormFeedback>)}
          </FormGroup>
        </FormItem>
      </ContentSection>
      <ContentSection title="League Logo">
        <LogoPickerField {...fields.logo} />
        {fields.logo.isDirty &&
          fields.logo.errors.map(error => (
            <p key={error} className="text-danger">
              {error}
            </p>
          ))}
      </ContentSection>

      <DashboardFormFooter>
        {leagueId ? (
          <Fragment>
            <Can I="delete" this={{ type: "leagues", id: leagueId }}>
              <div className="dashboard-form-footer__secondary">
                <ConfirmDeletionModal
                  resourceTitle={fields.title.initialValue}
                  resourceType="League"
                  isOperating={isSubmitting}
                  isDeleting={isDeleting}
                  onDelete={deleteLeague}
                />
              </div>
            </Can>

            <Can I="update" this={{ type: "leagues", id: leagueId }}>
              <div className="dashboard-form-footer__primary">
                <Button
                  type="submit"
                  color="success"
                  outline={isPristine || isInvalid || isDeleting}
                  disabled={isPristine || isInvalid || isDeleting}
                >
                  {isSubmitting ? "Saving Changes…" : "Save Changes"}
                </Button>
              </div>
            </Can>
          </Fragment>
        ) : (
          <div className="dashboard-form-footer__primary">
            <AbilityButton
              subject={{ type: "leagues", associationId }}
              action="create"
              type="submit"
              color="success"
              outline={isPristine || isInvalid || isDeleting}
              disabled={isPristine || isInvalid || isDeleting}
            >
              {isSubmitting ? "Creating League…" : "Create League"}
            </AbilityButton>
          </div>
        )}
      </DashboardFormFooter>
    </DashboardForm>
  );
}

LeagueForm.propTypes = {
  associationId: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({}),
  leagueId: PropTypes.string
};

export default LeagueForm;
