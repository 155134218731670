export const CSVRefereeImportDisplayPage = {
    LANDING: "landing",
    LOADING: "loading",
    PARSE_SUCCESS: "parsingSuccess",
    PARSE_FAILURE: "parsingFailure",
    IMPORT_PROGRESS: "importProgress",
    IMPORT_SUCCESS: "importSuccess",
    IMPORT_FAILURE: "importFailure",
  };
  
  export const CSVRefereeImportDisplayPageValues = Object.values(CSVRefereeImportDisplayPage);