import { createActions } from "redux-actions";

const { userVerificationNotification: actions } = createActions({
  USER_VERIFICATION_NOTIFICATION: {
    SHOW: undefined,
    HIDE: undefined
  }
});

export default actions;
