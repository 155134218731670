import { handleActions } from "redux-actions";

import { loadGamesheetRoutine } from "../../routines";
import { clearGamesheet } from "../../actions";
import reduceCoaches from "../../utils/reduceCoaches";

const initialState = [];

export default handleActions(
  {
    [loadGamesheetRoutine.SUCCESS]: reduceCoaches,
    [clearGamesheet]: () => initialState
  },
  initialState
);
