import { connect } from "react-redux";

import SeasonTeamsSelect from "./SeasonTeamsSelect";

import { isTeamListLoaded, isTeamListLoading, getTeamList } from "@/redux/teams/selectors";

import { TeamListLoadingRoutine } from "@/redux/teams/routines";

const mapStateToProps = state => ({
  isTeamsLoaded: isTeamListLoaded(state),
  isTeamsLoading: isTeamListLoading(state),
  teams: getTeamList(state)
});

const mapDispatchToProps = dispatch => ({
  loadTeams: seasonId => dispatch(TeamListLoadingRoutine.trigger({ seasonId }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SeasonTeamsSelect);
