import { connect } from "react-redux";

import { getSeasonRosterPlayersMergeToolIsEnabled } from "@/redux/seasonRoster/selectors";
import { seasonRosterActions } from "@/redux/seasonRoster/actions";

import PlayersListPlayer from "./Player";

const mapStateToProps = (state, ownProps) => ({
  isMergeToolEnabled: getSeasonRosterPlayersMergeToolIsEnabled(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  selectForMerge: () => dispatch(seasonRosterActions.players.mergeTool.selectPlayer(ownProps.id)),
  deselectForMerge: () => dispatch(seasonRosterActions.players.mergeTool.deselectPlayer(ownProps.id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayersListPlayer);
