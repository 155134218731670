import React from "react";
import PropTypes from "prop-types";

import { Switch } from "react-router-dom";

import LoggedInRoute from "@/containers/LoggedInRoute";

import PenaltyTypeReportDataSourcesPage from "./DataSourcesPage";
import PenaltyTypeReportPage from "./ReportPage";

import useDataSourcesClearing from "./hooks/useDataSourcesClearing";

function PenaltyTypeReportRoute({ match }) {
  useDataSourcesClearing();

  return (
    <Switch>
      <LoggedInRoute path={`${match.url}/sources`} component={PenaltyTypeReportDataSourcesPage} />

      <LoggedInRoute path="" component={PenaltyTypeReportPage} />
    </Switch>
  );
}

PenaltyTypeReportRoute.propTypes = {
  match: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired
};

export default PenaltyTypeReportRoute;
