import { useSelector, useDispatch } from "react-redux";

import { getYear } from "@/redux/teamFilter/selectors";
import actions from "@/redux/teamFilter/actions";
import { getHockeyYearOptions } from "@/components/HockeyYearField/DateUtilities";

export default function useYearSelect() {
  const selectedYear = useSelector(getYear);
  const options = getHockeyYearOptions();

  const dispatch = useDispatch();
  const setSelectedYear = year => {
    dispatch(actions.setYear(year));
  };

  return {
    options,
    selectedYear,
    setSelectedYear
  };
}
