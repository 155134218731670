import React, { useCallback, useRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import { Input, FormFeedback } from "reactstrap";

import useField from "../hooks/useField";

import "./Field.scss";

function Field(props) {
  const inputRef = useRef(null);
  const { type, children, name, transform, member, placeholder } = props;
  const { value, setValue, isInvalid, errors, ref, triggerReset } = useField(name, member);
  const [cursor, setCursor] = useState({ start: 0, end: 0 });

  const handleChange = useCallback(
    ({ target }) => {
      setCursor({ start: target.selectionStart, end: target.selectionEnd });
      setValue(transform(target.value));
    },
    [setValue, transform]
  );

  useEffect(
    () => {
      if (inputRef.current != null && type == "text") {
        let elem = ReactDOM.findDOMNode(inputRef.current);
        elem.setSelectionRange(cursor.start, cursor.end);
      }
    },
    [value]
  );

  const handleKeyDown = useCallback(
    ({ key }) => {
      if (key === "Escape") {
        triggerReset();
      }
    },
    [triggerReset]
  );

  return (
    <div className="team-roster__form-field">
      <Input
        ref={inputRef}
        type={type}
        bsSize="sm"
        onChange={handleChange}
        value={value}
        invalid={isInvalid}
        innerRef={ref}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
      >
        {children}
      </Input>
      {isInvalid && (
        <FormFeedback valid={false} tooltip>
          {errors.map(error => (
            <div key={error}>{error}</div>
          ))}
        </FormFeedback>
      )}
    </div>
  );
}

Field.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  member: PropTypes.shape({}).isRequired,
  transform: PropTypes.func
};

Field.defaultProps = {
  type: "text",
  children: null,
  placeholder: "",
  transform: value => value
};

export default Field;
