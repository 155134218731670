import { handleActions } from "redux-actions";

import { invitationFormActions } from "../../../actions";

const defaultState = {
  actions: {
    create: false,
    read: false,
    update: false,
    delete: false
  },
  global: true
};

const changeActions = (state, { payload }) => Object.assign({}, state, { actions: { ...state.actions, ...payload } });

export default handleActions(
  {
    [invitationFormActions.scopes.invitations.changeActions]: changeActions
  },
  defaultState
);
