import React from "react";
import PropTypes from "prop-types";

import LoggedInRoute from "@/containers/LoggedInRoute";
import { Switch } from "react-router-dom";

import useCurrentDivision from "@/hooks/useCurrentDivision";
import useCurrentDivisionClearing from "@/hooks/useCurrentDivisionClearing";
import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";
import CurrentDivisionContext from "@/contexts/CurrentDivisionContext";

import EditDivision from "./pages/EditDivision";
import { Teams } from "./modules";

function DivisionScope({ match: { url, params } }) {
  const { divisionId } = params;

  const { seasonId } = useCurrentSeasonContext();
  const currentDivision = useCurrentDivision({ seasonId, divisionId });

  useCurrentDivisionClearing();

  return (
    <CurrentDivisionContext.Provider value={{ ...currentDivision, divisionId }}>
      <Switch>
        <LoggedInRoute path={`${url}/edit`} component={EditDivision} />
        <LoggedInRoute path={`${url}/teams`} component={Teams} />
      </Switch>
    </CurrentDivisionContext.Provider>
  );
}

DivisionScope.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      divisionId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default DivisionScope;
