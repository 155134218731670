import { all, put, call, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";

import { createLeague, updateLeague, deleteLeague } from "@/lib/api/leagues";
import { responseErrorsFullMessages } from "@/lib/api/utils";
import { gamesheetAPIRequest } from "@/redux/api/sagas";

import { FIELDS_MAPPING } from "@/components/LeagueForm";

import { submittingRoutine, deletingRoutine } from "./routines";
import { createFile } from "@/lib/api/file";

function* uploadLogoSaga(file) {
  const {
    data: { data }
  } = yield call(gamesheetAPIRequest, createFile, {
    file
  });

  return data.attributes.url;
}

function* submittingSaga({ payload: { associationId, leagueId, values } }) {
  yield put(submittingRoutine.request());

  const { title, logo } = values;

  // TODO: what happens if logo is removed? What happens when an association logo is removed?

  try {
    const service = leagueId ? updateLeague : createLeague;

    const attributes = {
      title,
      logo: logo instanceof File ? yield call(uploadLogoSaga, logo) : logo
    };

    yield call(
      gamesheetAPIRequest,
      service,
      {
        attributes,
        identity: { type: "leagues", id: leagueId },
        associationId
      },
      true
    );

    yield put(submittingRoutine.success());
    yield put(push(`/associations/${associationId}/leagues`));
  } catch (error) {
    const { response } = error;
    const validationErrors = response ? responseErrorsFullMessages(response, FIELDS_MAPPING) : {};

    yield put(submittingRoutine.failure({ error, validationErrors }));
  } finally {
    yield put(submittingRoutine.fulfill());
  }
}

function* deletingSaga({ payload: { associationId, leagueId } }) {
  yield put(deletingRoutine.request());

  try {
    yield gamesheetAPIRequest(deleteLeague, {
      identity: { type: "leagues", id: leagueId },
      associationId
    });

    yield put(deletingRoutine.success());
    yield put(push(`/associations/${associationId}/leagues`));
  } catch (e) {
    yield put(deletingRoutine.failure({ error: e }));
  } finally {
    yield put(deletingRoutine.fulfill());
  }
}

export function* leagueFormFlow() {
  yield all([takeLatest(submittingRoutine, submittingSaga), takeLatest(deletingRoutine, deletingSaga)]);
}
