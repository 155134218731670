import { useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { suspensionReportLoadingRoutine } from "@/redux/suspensionReport/routines";
import actions from "@/redux/suspensionReport/actions";

import { getSuspensionReportIsLoaded, getSuspensionReportIsForbidden } from "@/redux/suspensionReport/selectors";

export default function useSuspensionReport(search) {
  const dispatch = useDispatch();
  const isLoaded = useSelector(getSuspensionReportIsLoaded);
  const isForbidden = useSelector(getSuspensionReportIsForbidden);

  const seasonId = useMemo(() => new URLSearchParams(search).get("season_id"), [search]);

  useEffect(
    () => {
      dispatch(suspensionReportLoadingRoutine.trigger({ seasonId }));

      return () => dispatch(actions.report.clear());
    },
    [dispatch, seasonId]
  );

  return { isLoaded, isForbidden, seasonId };
}
