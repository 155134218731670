import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getRoles, getRolesAreLoading } from "@/redux/userForm/selectors";
import actions from "@/redux/userForm/actions";

import { initRoutine as roleFormInitRoutine } from "@/redux/roleForm/routines";

export default function useRoles() {
  const dispatch = useDispatch();
  const roles = useSelector(getRoles);
  const initRoleForm = useCallback(role => dispatch(roleFormInitRoutine(role)), [dispatch]);
  const areLoading = useSelector(getRolesAreLoading);

  const removeRole = useCallback(role => dispatch(actions.removeRole(role)), [dispatch]);

  return {
    roles,
    initRoleForm,
    areLoading,
    removeRole
  };
}
