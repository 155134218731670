import React, { Fragment } from "react";
import PropTypes from "prop-types";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";

import CoachPenaltyReport from "@/components/CoachPenaltyReport";

import usePenaltyReport from "./hooks/usePenaltyReport";

function PenaltyReport({ match: { params } }) {
  const { externalId } = params;

  const { isLoaded, isForbidden, coach } = usePenaltyReport(externalId);

  return (
    <ProtectedLayout condition={!isForbidden}>
      <Head
        title={
          isLoaded ? `${coach.firstName} ${coach.lastName} — Bench Staff Penalty Report` : "Bench Staff Penalty Report"
        }
      />

      {isLoaded && (
        <Fragment>
          <Breadcrumbs
            items={[
              { title: "Reports" },
              {
                title: "Bench Staff Penalty Reports",
                url: "/reports/bench-staff-penalty"
              },
              { title: `${coach.firstName} ${coach.lastName}` }
            ]}
          />
        </Fragment>
      )}

      <CoachPenaltyReport externalId={externalId} />
    </ProtectedLayout>
  );
}

PenaltyReport.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      externalId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default PenaltyReport;
