import resourcesArrayToMap from "./resourcesArrayToMap";
import buildSeasonMember from "./buildSeasonMember";

export default function reduceReferees(state, { payload: { game } }) {
  const seasonReferees = resourcesArrayToMap(game.referees || []);
  const gameReferees = game.refereesRoster || [];

  return gameReferees.reduce(
    (referees, { id, ...gameReferee }) => ({
      ...referees,
      [id]: buildSeasonMember(seasonReferees[id], gameReferee)
    }),
    {}
  );
}
