import { handleActions } from "redux-actions";

import { LoadInvitationsListRoutine as routine } from "../../routines";
import { invitationsListActions } from "../../actions";

const initialState = {};

function reduceLoadingSuccess(state, { payload: { invitations } }) {
  return invitations.reduce(
    (result, { id, code, description }) => ({
      ...result,
      [id]: { code, description }
    }),
    state
  );
}

export default handleActions(
  {
    [routine.SUCCESS]: reduceLoadingSuccess,
    [invitationsListActions.clear]: () => ({ ...initialState })
  },
  initialState
);
