import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import { Label, Input } from "reactstrap";

class SeasonTeamsSelect extends PureComponent {
  static propTypes = {
    seasonId: PropTypes.string.isRequired,
    isTeamsLoaded: PropTypes.bool,
    isTeamsLoading: PropTypes.bool,
    defaultValue: PropTypes.string,
    teams: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
      })
    ),
    disallowIds: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    loadTeams: PropTypes.func
  };

  static defaultProps = {
    isTeamsLoaded: false,
    isTeamsLoading: false,
    defaultValue: "",
    teams: [],
    disallowIds: [],
    onChange() {},
    loadTeams() {}
  };

  componentDidMount() {
    const { isTeamsLoading, loadTeams, seasonId } = this.props;

    if (!isTeamsLoading) {
      loadTeams(seasonId);
    }
  }

  handleChange = ({ target }) => {
    const { isTeamsLoaded, onChange } = this.props;

    if (isTeamsLoaded) {
      onChange(target.value);
    }
  };

  optionIsDisabled(id, data) {
    const { disallowIds } = this.props;
    return disallowIds.indexOf(id) > -1 || !!data.rosterLocked;
  }

  render() {
    const { isTeamsLoaded, defaultValue, isTeamsLoading, teams } = this.props;

    return (
      <Fragment>
        <Label for="teamId">Select Team</Label>
        <Input
          id="teamPlayerTeamId"
          type="select"
          name="teamId"
          bsSize="default"
          defaultValue={isTeamsLoaded ? defaultValue : ""}
          disabled={isTeamsLoading}
          onChange={this.handleChange}
        >
          <option value="" disabled>
            {isTeamsLoaded
              ? teams.length > 0
                ? "Select Team"
                : "No teams available"
              : isTeamsLoading
                ? "Loading teams…"
                : "Pending teams…"}
          </option>
          {teams.map(({ id, title, data }) => (
            <option key={id} value={id} disabled={this.optionIsDisabled(id, data)}>
              {title}
              {!!data.rosterLocked && " (locked)"}
            </option>
          ))}
        </Input>
      </Fragment>
    );
  }
}

export default SeasonTeamsSelect;
