export const FIELDS_MAPPING = {
  title: "League Name",
  logo: "Logo"
};

export const DEFAULT_VALUES = {
  title: "",
  logo: ""
};

export const CONSTRAINTS = {
  title: {
    presence: { allowEmpty: false, message: "^League Name can’t be blank" }
  }
};
