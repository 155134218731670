import React, { Fragment } from "react";
import PropTypes from "prop-types";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import PageLoader from "@/components/PageLoader";

import PlayerPenaltyReport from "@/components/PlayerPenaltyReport";

import useCurrentPlayer from "../hooks/useCurrentPlayer";
import usePlayerPenaltyReport from "../hooks/usePlayerPenaltyReport";
import useCurrentPlayerClearing from "../hooks/useCurrentPlayerClearing";

function PlayerPenaltyReportPage({ seasonId, match: { params } }) {
  const { playerId } = params;

  const { seasonIsLoaded, seasonIsLoading, seasonBreadcrumbs } = useCurrentSeasonContext();

  const { isPlayerReady, isPlayerOperating, isPlayerForbidden, player, externalId } = useCurrentPlayer({
    seasonId,
    playerId
  });

  usePlayerPenaltyReport(externalId);
  useCurrentPlayerClearing();

  return (
    <ProtectedLayout
      subject={{ type: "penaltyReports", playerId }}
      action="read"
      skip={!isPlayerReady && !isPlayerForbidden}
    >
      {isPlayerReady && seasonIsLoaded ? (
        <Fragment>
          <Head title={`${player.firstName} ${player.lastName} Penalty Report`} />

          <Breadcrumbs
            items={seasonBreadcrumbs}
            lastItem={{
              title: `${player.firstName} ${player.lastName}`,
              url: `/seasons/${seasonId}/roster/players/${playerId}`
            }}
          />

          {externalId !== "" && <PlayerPenaltyReport externalId={externalId} />}
        </Fragment>
      ) : (
        <PageLoader isLoading={isPlayerOperating || seasonIsLoading} />
      )}
    </ProtectedLayout>
  );
}

PlayerPenaltyReportPage.propTypes = {
  seasonId: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      playerId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default PlayerPenaltyReportPage;
