import { connect } from "react-redux";

import { getGameFormTeamPlayers } from "@/redux/gameForm/selectors";

import Penalty from "./Penalty";

const mapStateToProps = (state, { type, ...rest }) => ({
  players: getGameFormTeamPlayers(state, type),
  type,
  ...rest
});

export default connect(mapStateToProps)(Penalty);
