import React, { Fragment, useEffect, useState, useMemo } from "react";
import { Progress as ProgressBar } from "reactstrap";

import TitleBar from "@/components/TitleBar";

import { useScheduledGamesCSVImportWizardProgress } from "./hooks";

import { progressHints } from "@/components-16.8/ProgressBar";

function Progress() {
  const { total, success, fail, chunkSize } = useScheduledGamesCSVImportWizardProgress();

  const [percentPending, setPercentPending] = useState(0); // purely aesthetic
  const percentSuccess = success / total * 100;
  const percentFail = fail / total * 100;

  useEffect(() => {
    if (percentPending > 0) {
      setPercentPending(0);
    }

    if (success + fail < total) {
      const actualPercentPending = Math.min(total - (success + fail), chunkSize) / total * 100;
      setTimeout(() => setPercentPending(actualPercentPending), 200);
    }
  }, [success, fail]);

  const progressStatus = useMemo(
    () => {
      switch (true) {
        case success + fail >= total && total > 0:
          return "C'est la Butt!";
        default:
          return `${progressHints[Math.floor(Math.random() * progressHints.length)]}...`;
      }
    },
    [success, fail]
  );

  return (
    <Fragment>
      <TitleBar title="Importing…" />

      <ProgressBar multi className="scheduled-games-csv-import-progress-bar">
        {percentFail > 0 && (
          <ProgressBar bar color="danger" value={percentFail}>{percentFail.toFixed(0)}%</ProgressBar>
        )}
        <ProgressBar bar color="success" value={percentSuccess}>{percentSuccess.toFixed(0)}%</ProgressBar>
        <ProgressBar bar animated color="success" value={percentPending}/>
      </ProgressBar>
      <p>{progressStatus}</p>
    </Fragment>
  );
}

export default Progress;
