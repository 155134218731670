import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { firebase as app, _firebase as firebase } from "@/firebase";
import GameSheetProgressBar from "@/components-16.8/ProgressBar";
import { config } from "@/config";

const importHCRIdState = atom({
  key: "importHCRIdState",
  default: ""
});

export const useHCRRegistrySync = ({ teamId, seasonId }) => {
  // encapsulate the state
  const [importId, setImportId] = useRecoilState(importHCRIdState);
  const [isImporting, setIsImporting] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [failureMessage, setFailureMessage] = useState("");
  const [inputIsInvalid, setInputIsInvalid] = useState(false)
  const [progress, setProgress] = useState({
    total: 0,
    started: 0,
    failed: 0,
    pending: 0,
    success: 0
  });

  useEffect(() => {
    setImportId("");
  }, []);

  useEffect(
    () => {
      if (importId) {
        const flattenGroup = (flattened, group) => [...group, ...flattened];

        return firebase
          .firestore()
          .doc(`services/registrySync/importProcedures/${importId}`)
          .onSnapshot(doc => {
            const importProcedure = doc.data();

            const total = Object.values(importProcedure.import).reduce(flattenGroup, []).length;
            const started = Object.values(importProcedure.started).reduce(flattenGroup, []).length;
            const completed = Object.values(importProcedure.completed).reduce(flattenGroup, []).length;
            const failed = Object.values(importProcedure.failed).reduce(flattenGroup, []).length;

            setProgress({
              total,
              requested: started - completed - failed,
              failed,
              pending: total - started,
              success: completed
            });

            const resolved = completed + failed;
            const pct = Math.floor((resolved / total) * 100);

            if (pct >= 100) {
              setIsImporting(false);
              if (!!failed) {
                setIsFailed(true);
                setInputIsInvalid(true);
                setFailureMessage(
                  "We were unable to locate an approved Hockey Canada roster matching that Team ID. Double check that your Team ID is correct and your roster is approved before trying again."
                );
              } else {
                setIsComplete(true);
              }
            }
          });
      }
    },
    [importId]
  );

  const ProgressBar = ({ title }) => {
    return (
      <GameSheetProgressBar
        total={progress.total}
        requested={progress.requested}
        failed={progress.failed}
        pending={progress.pending}
        success={progress.success}
        title={title}
      />
    );
  };

  // create class instance
  return {
    isImporting,

    isComplete,

    isFailed,

    setIsFailed,

    failureMessage,

    progress,

    ProgressBar,

    inputIsInvalid,

    setInputIsInvalid,

    reset(){
        setImportId("");
        setIsFailed(false);
        setIsImporting(false);
        setIsComplete(false);
        setProgress({
            total: 0,
            started: 0,
            failed: 0,
            pending: 0,
            success: 0
        });
    },

    async startImport(registryId, organisationId) {

      if (!registryId || cleanRegistryId(registryId).length === 0) {
        setIsFailed(true);
        setInputIsInvalid(true);
        setFailureMessage("Enter your team’s Hockey Canada ID");
        return;
      }

      if (!organisationId) {
          setIsFailed(true);
          setFailureMessage("Select your organization");
          return;
      }

      setIsFailed(false);
      setInputIsInvalid(false);
      setFailureMessage("");
      setIsComplete(false);
      setIsImporting(true);

      return axios
        .post(
          `https://${config.REGISTRY_SYNC_URL}/service.registrysync/v4/rpc/teamImportProcedure`,
          {
            registryId: cleanRegistryId(registryId),
            code: "hcr",
            scope: {
              teamId,
              seasonId
            },
            registryScope: {
                organisationId
            }
          },
          {
            headers: {
              authorization: `Bearer ${window.localStorage.getItem("accessToken")}`
            }
          }
        )
        .then(response => {
          console.log(response);
          setImportId(response.data.id);
        })
        .catch(err => {
          console.error(err);
          setIsFailed(true);
          setInputIsInvalid(true);
          setIsImporting(false);
          const errMsg = err.response.data.message || err.message;
          setFailureMessage(maskErrorMessage(errMsg));
        });
    }
  };
};

// trim, remove leading '#' if present
function cleanRegistryId(registryId) {
  registryId = registryId.trim();

  if (registryId.length > 0 && registryId.charAt(0) === "#") {
    return registryId.slice(1);
  }

  return registryId
}

// convert verbose messages into user-friendly ones
function maskErrorMessage(errMsg) {
  const key = !!errMsg ? errMsg.split(":")[0] : "";
  switch (key) {
    case "ExternalTeamIdMismatch":
      return "A team with this HCR ID already exists within this season. If you are certain that this is the correct ID for your team, please contact your league administrator to inform them of the issue.";
    case "NoRegistryTeamFound":
      return "We were unable to locate an approved Hockey Canada roster matching that Team ID. Double check that your Team ID is correct and your roster is approved before trying again."
    case "TeamIsLocked":
      return "This team has been locked. Please refresh the page."
    default:
      return errMsg;
  }
}
