import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { FormGroup, Label, Button } from "reactstrap";

import FormItem from "@/components/FormItem";
import ContentSection from "@/components/ContentSection";
import Penalty from "@/components/GameForm/components/Penalty";

class PenaltiesFieldset extends PureComponent {
  static propTypes = {
    type: PropTypes.string.isRequired,
    teamTitle: PropTypes.string.isRequired,
    penalties: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })),
    changePenalty: PropTypes.func,
    addPenalty: PropTypes.func,
    removePenalty: PropTypes.func
  };

  static defaultProps = {
    penalties: [],
    changePenalty() {},
    addPenalty() {},
    removePenalty() {}
  };

  addPenalty = () => {
    const { type, addPenalty } = this.props;

    addPenalty(type);
  };

  render() {
    const { teamTitle, penalties, changePenalty, type, removePenalty } = this.props;

    return (
      <ContentSection title={`${type.toUpperCase()} – ${teamTitle}`}>
        {penalties.length > 0 && (
          <FormItem>
            <FormGroup className="col-md-1">
              <Label>Period</Label>
            </FormGroup>

            <FormGroup className="col-md-2">
              <Label>Offender</Label>
            </FormGroup>

            <FormGroup className="col-md-2">
              <Label>Served By</Label>
            </FormGroup>

            <FormGroup className="col-md-1">
              <Label>Length</Label>
            </FormGroup>

            <FormGroup className="col-md-2">
              <Label>Code</Label>
            </FormGroup>

            <FormGroup className="col-md-1">
              <Label>Time off</Label>
            </FormGroup>

            <FormGroup className="col-md-1">
              <Label>Time start</Label>
            </FormGroup>

            <FormGroup className="col-md-1">
              <Label>Time on</Label>
            </FormGroup>
          </FormItem>
        )}

        {penalties.map(({ id, ...data }) => (
          <Penalty key={id} type={type} id={id} {...data} onChange={changePenalty} onRemove={removePenalty} />
        ))}

        <FormItem>
          <Button block outline color="success" onClick={this.addPenalty}>
            Add penalty
          </Button>
        </FormItem>
      </ContentSection>
    );
  }
}

export default PenaltiesFieldset;
