import React from "react";

import { Modal, ModalHeader, ModalBody } from "reactstrap";

import useUserVerificationNotification from "./hooks/useUserVerificationNotification";

function Notification() {
  const { display, userEmail, title, hide } = useUserVerificationNotification();

  if (!display) {
    return null;
  }

  return (
    <Modal centered isOpen={true} toggle={hide}>
      <ModalHeader toggle={hide}>{title}</ModalHeader>

      <ModalBody>
        <p>
          A verification email has been sent to <a href={`mailto:${userEmail}`}>{userEmail}</a>. The account will be
          activated once the user has clicked on the verification link and setup their password.
        </p>
      </ModalBody>
    </Modal>
  );
}

export default Notification;
