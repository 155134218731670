
export const usePenaltyAccumulationReport = ({ penaltyCodes, data }) => {
  const rowData = Object.values(data)
    .filter(({ penaltyCode }) => penaltyCodes.includes(penaltyCode))
    .map(elem => {
      elem.lastInfractionDate = elem.lastInfractionDate.split('T')[0];
      return elem;
    });

  return {
    rowData
  };
}