import { handleActions } from "redux-actions";

import { passwordActions } from "../../actions";
import { resetPasswordRoutine } from "../../routines";

const initialState = {
  isWorking: false,
  isCompleted: false,
  error: null
};

const resetRequested = state => ({ ...state, isWorking: true });
const resetCompleted = state => ({ ...state, isCompleted: true });
const resetFailed = (state, { payload }) => ({
  ...state,
  error: payload
});
const resetFulfilled = state => ({ ...state, isWorking: false });
const clear = state => ({ ...initialState });
const clearError = state => ({ ...state, error: null });

export default handleActions(
  {
    [resetPasswordRoutine.TRIGGER]: clearError,
    [resetPasswordRoutine.REQUEST]: resetRequested,
    [resetPasswordRoutine.SUCCESS]: resetCompleted,
    [resetPasswordRoutine.FAILURE]: resetFailed,
    [resetPasswordRoutine.FULFILL]: resetFulfilled,
    [passwordActions.reset.clear]: clear
  },
  initialState
);
