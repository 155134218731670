import { handleActions } from "redux-actions";

import { loadingRoutine } from "../routines";
import actions from "../actions";

const initialState = {
  title: "",
  logo: ""
};

function reduceLoadingSuccess(state, { payload: { association } }) {
  const { logo, title } = association;

  return { logo, title };
}

export default handleActions(
  {
    [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
