import { connect } from "react-redux";

import { getGameFormPeriodNames, getGameGoalieShots } from "@/redux/gameForm/selectors";

import actions from "@/redux/gameForm/actions";

import ShotsFieldset from "./ShotsFieldset";

const mapStateToProps = state => ({
  periods: getGameFormPeriodNames(state),
  shots: getGameGoalieShots(state)
});

const mapDispatchToProps = dispatch => ({
  setShots: payload => dispatch(actions.setShots(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShotsFieldset);
