import React, { Fragment } from "react";
import PropTypes from "prop-types";
import withQueryParams from "react-router-query-params";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import Layout from "@/layouts/Layout";
import Head from "@/components/Head";

import UnsubscribeByToken, { useUnsubscribeByToken } from "@/components/UnsubscribeByToken";

import "./Unsubscribe.scss";

function Unsubscribe({ queryParams: { token } }) {
  const { teamId, teamTitle, tokenIsValid } = useUnsubscribeByToken(token);

  return (
    <Layout className="unsubscribe">
      <Head title="Email Notification Settings" />

      <div className="container">
        <Row>
          <Col md="8">
            {tokenIsValid ? (
              <UnsubscribeByToken token={token} teamId={teamId} teamTitle={teamTitle} />
            ) : (
              <Fragment>
                <h1 className="unsubscribe__title">Subscription Not Found</h1>
                <Link to="/account/email-notification-settings">Edit email notification settings</Link>
              </Fragment>
            )}
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

Unsubscribe.propTypes = {
  queryParams: PropTypes.shape({
    token: PropTypes.string.isRequired
  })
};

export default withQueryParams({
  keys: {
    token: { default: "", validate: value => value && value.length > 0 }
  }
})(Unsubscribe);
