import { createGetService, createPostService, createPatchService, createDeleteService } from "./serviceMaker";

const leagueUrlByIdentity = ({ identity }) => ({
  url: `/leagues/${identity.id}`
});

export const loadLeagues = createGetService(({ associationId }) => ({
  url: `/associations/${associationId}/leagues`
}));

export const createLeague = createPostService(({ attributes, associationId }) => ({
  url: `/associations/${associationId}/leagues`,
  attributes,
  relationships: {
    association: { data: { id: associationId, type: "associations" } }
  }
}));

export const loadLeague = createGetService(({ identity, include }) => ({
  ...leagueUrlByIdentity({ identity }),
  params: { include }
}));

export const updateLeague = createPatchService(({ identity, associationId, attributes }) => ({
  url: `/associations/${associationId}/leagues/${identity.id}`,
  attributes,
  relationships: {
    association: { data: { id: associationId, type: "associations" } }
  }
}));

export const deleteLeague = createDeleteService(({ identity, associationId }) => ({
  url: `/associations/${associationId}/leagues/${identity.id}`
}));
