import { connect } from "react-redux";

import CopyTeamsModal from "./CopyTeamsModal";

import { getCopyTeamsIsModalOpen, getCopyTeamsStage, getCopyTeamsSelectedCount } from "@/redux/copyTeams/selectors";

import teamFilterActions from "@/redux/teamFilter/actions";
import teamListActions from "@/redux/teamList/actions";
import copyTeamsActions from "@/redux/copyTeams/actions";
import { copyTeamsRoutine } from "@/redux/copyTeams/routines";

const mapStateToProps = state => ({
  isOpen: getCopyTeamsIsModalOpen(state),
  stage: getCopyTeamsStage(state),
  countSelectedTeams: getCopyTeamsSelectedCount(state)
});

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(copyTeamsActions.modal.close()),
  clearTeamFilter: () => dispatch(teamFilterActions.clear()),
  clearTeamList: () => dispatch(teamListActions.clear()),
  clearCopyTeams: () => dispatch(copyTeamsActions.clear()),
  beginCopyTeams: () => dispatch(copyTeamsRoutine())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CopyTeamsModal);
