import { GAME_TYPE_VALUES } from "@/constants/gameTypes";
import { getUserTimeZone } from "@/utils/timezones";

export const FIELDS_MAPPING = {
  visitorTeam: "Visitor team",
  homeTeam: "Home team",
  visitorDivision: "Visitor division",
  homeDivision: "Home division",
  scheduledStartTime: "Game date",
  startTime: "Scheduled start time",
  endTime: "Scheduled end time",
  timeZone: "Time zone",
  offset: "Offset",
  number: "Game number",
  gameType: "Game type",
  location: "Location",
  scorekeeper: "Scorekeeper",
  scorekeeperName: "Scorekeeper name",
  scorekeeperPhone: "Scorekeeper phone",
  broadcaster: "Broadcast Provider"
};

export const DEFAULT_VALUES = {
  visitor: {
    division: "",
    team: ""
  },
  home: {
    division: "",
    team: ""
  },
  scheduledStartTime: "",
  startTime: "",
  endTime: "",
  timeZone: getUserTimeZone(),
  offset: 0,
  number: "",
  gameType: "regular_season",
  location: "",
  scorekeeper: {
    name: "",
    phone: ""
  },
  broadcaster: ""
};

export const CONSTRAINTS = {
  home: {
    validateDivisionPresence: { message: "^Home division can’t be blank" }
  },
  scheduledStartTime: {
    presence: { allowEmpty: false, message: "^Game date can’t be blank" }
  },
  startTime: {
    presence: { allowEmpty: false }
  },
  number: {
    presence: { allowEmpty: false, message: "^Game number can’t be blank" }
  },
  gameType: {
    inclusion: GAME_TYPE_VALUES
  },
  broadcaster: {
    presence: { allowEmpty: true }
  },
  timeZone: {
    presence: { allowEmpty: false, message: "^Must specify a time zone" },
  },
  endTime: {
    presence: { allowEmpty: false }
  },
};
