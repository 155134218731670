import { connect } from "react-redux";

import List from "./List";

import {
  getRefereeReportListIsLoading,
  getRefereeReportListIsLoaded,
  getRefereeReportList,
  getRefereeReportListTotalCount,
  getRefereeReportListFilteredCount,
  getRefereeReportListSearchCurrentQuery,
  getRefereeReportListHasMore,
  getRefereeReportListIsAppending
} from "@/redux/refereeReport/selectors";

import { refereeReportListLoadingRoutine } from "@/redux/refereeReport/routines";

const mapStateToProps = state => ({
  isLoading: getRefereeReportListIsLoading(state),
  isLoaded: getRefereeReportListIsLoaded(state),
  referees: getRefereeReportList(state),
  totalCount: getRefereeReportListTotalCount(state),
  filteredCount: getRefereeReportListFilteredCount(state),
  currentQuery: getRefereeReportListSearchCurrentQuery(state),
  hasMore: getRefereeReportListHasMore(state),
  isAppending: getRefereeReportListIsAppending(state)
});

const mapDispatchToProps = dispatch => ({
  loadMoreReferees: () =>
    dispatch(
      refereeReportListLoadingRoutine({
        append: true
      })
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List);
