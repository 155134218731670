import { handleActions } from "redux-actions";

import { leagueListLoadingRoutine as loadingRoutine } from "../routines";
import actions from "../actions";

const initialState = {
  isLoaded: false,
  isLoading: false,
  ids: [],
  data: {},
  selectedIds: [] 
};

function updateAssociationLeagues(state, associationId, data) {
  return {
    ...state,
    [associationId]: {
      ...state[associationId],
      ...data
    }
  };
}

function reduceAssociationSelected(state, { payload: { id: associationId } }) {
  return {
    ...state,
    [associationId]: {
      isLoaded: false,
      isLoading: false,
      ids: [],
      data: {},
      ...state[associationId]
    }
  };
}

function reduceLoadingRequest(state, { payload }) {
  const { associationId } = payload;

  return updateAssociationLeagues(state, associationId, { isLoading: true });
}

function reduceLoadingSuccess(state, { payload }) {
  const { associationId, ids, leagues } = payload;

  const data = leagues.reduce((result, { id, title }) => ({ ...result, [id]: { title } }), {});

  return updateAssociationLeagues(state, associationId, {
    ids,
    data,
    isLoaded: true
  });
}

function reduceLoadingFailure(state, { payload }) {
  const { associationId } = payload;

  return updateAssociationLeagues(state, associationId, { isLoaded: false });
}

function reduceLoadingFulfill(state, { payload }) {
  const { associationId } = payload;

  return updateAssociationLeagues(state, associationId, { isLoading: false });
}

function reduceSelectLeague(state, { payload: { id } }) {
  return {
    ...state,
    selectedIds: [...state.selectedIds, id] // Add league ID to selectedIds array
  };
}

function reduceDeselectLeague(state, { payload: { id } }) {
  return {
    ...state,
    selectedIds: state.selectedIds.filter(leagueId => leagueId !== id) // Remove league ID from selectedIds array
  };
}

function reduceSelectAllLeagues(state) {
  const allLeagueIds = Object.keys(state.data).flatMap(
    associationId => (state[associationId] && state[associationId].ids) || []
  );
  return {
    ...state,
    selectedIds: allLeagueIds // Set selectedIds to all league IDs
  };
}

function reduceClearLeagues(state) {
  return {
    ...state,
    selectedIds: [] // Clear selectedIds array
  };
}

export default handleActions(
  {
    [actions.associationList.select]: reduceAssociationSelected,
    [loadingRoutine.REQUEST]: reduceLoadingRequest,
    [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [loadingRoutine.FAILURE]: reduceLoadingFailure,
    [loadingRoutine.FULFILL]: reduceLoadingFulfill,
    [actions.leagueList.select]: reduceSelectLeague, // Handle individual league selection
    [actions.leagueList.deselect]: reduceDeselectLeague, // Handle individual league deselection
    [actions.leagueList.selectAll]: reduceSelectAllLeagues, // Handle selecting all leagues
    [actions.leagueList.clear]: reduceClearLeagues // Handle clearing all selected leagues
  },
  initialState
);
