import React, { useState, useEffect, useRef } from "react";
import { FilePicker } from "react-file-picker";

export const useImagePicker = ({ initialLogo, updateParent, clear, placeholder }) => {
  
  const [imgFile, setImgFile] = useState(initialLogo.file);
  const [imgSrc, setImgSrc] = useState(initialLogo.url);
  const [hasImg, setHasImg] = useState(!!initialLogo.url && initialLogo.url != placeholder);
  const [error, setError] = useState("");

  const reset = () => setImgSrc(initialLogo.url);

  const destroy = () => {
    updateImg(null);
  }

  useEffect(() => {
    setImgFile(initialLogo.file);
    setImgSrc(initialLogo.url);
    setHasImg(!!initialLogo.url && initialLogo.url != placeholder);
    setError("");
  }, [ clear ])

  const updateImg = file => {
    
    updateParent({
      target: {
        name: "logo",
        value: {
          file: file,
          url: ""
        }
      }
    });

    setError("");
    setImgFile(file);
    
    if (file) {
      setImgSrc(URL.createObjectURL(file)); 
      setHasImg(true);
    } else {
      setImgSrc(""); 
      setHasImg(false);
    }
  };

  return {
    imgSrc,
    imgFile,
    hasImg,
    error,
    reset,
    setError,
    destroy,
    updateImg
  };
};
