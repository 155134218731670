import React from "react";

import FilterSelect from "@/components/FilterSelect";

import useLeagueSelect from "./hooks/useLeagueSelect";

function LeagueSelect() {
  const { options, selectedId, isLoaded, isLoading, setSelectedId } = useLeagueSelect();

  return (
    <FilterSelect
      placeholder="All leagues"
      value={selectedId}
      isLoaded={isLoaded}
      isLoading={isLoading}
      onChange={setSelectedId}
    >
      {options.map(({ id, title }) => (
        <option key={id} value={id}>
          {title}
        </option>
      ))}
    </FilterSelect>
  );
}

export default LeagueSelect;
