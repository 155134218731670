import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Alert, Button } from "reactstrap";
import { Link } from "react-router-dom";

import PageLoader from "@/components/PageLoader";

import { useUnsubscribeByTokenMeta, useUnsubscribeByTokenResubscribeAction } from "./hooks";

function UnsubscribeByToken({ token, teamId, teamTitle }) {
  const { isPending, isUnsubscribed, isResubscribed, isNotFound } = useUnsubscribeByTokenMeta();

  const resubscribe = useUnsubscribeByTokenResubscribeAction({ token, teamId });

  return isPending ? (
    <PageLoader isLoading={true} />
  ) : isNotFound ? (
    <Fragment>
      <h1 className="unsubscribe__title">Subscription Not Found</h1>
      <Link to="/account/email-notification-settings">Edit email notification settings</Link>
    </Fragment>
  ) : (
    <Fragment>
      <h1
        className={classNames("unsubscribe__title", {
          "unsubscribe__title--resubscribed": isResubscribed
        })}
      >
        You are no longer subscribed to post game emails for {teamTitle}
      </h1>
      {isUnsubscribed && isResubscribed ? (
        <Alert color="success">Subscribed again!</Alert>
      ) : (
        <p>
          Unsubscribed by mistake?{" "}
          <Button className="unsubscribe__link" color="link" tabIndex="-1" onClick={resubscribe}>
            Resubscribe
          </Button>
          .
        </p>
      )}
      <Link to="/account/email-notification-settings">Edit email subscription settings</Link>
    </Fragment>
  );
}

UnsubscribeByToken.propTypes = {
  teamId: PropTypes.string,
  teamTitle: PropTypes.string,
  token: PropTypes.string.isRequired
};

export default UnsubscribeByToken;
