import React from "react";
import { Link } from "react-router-dom"; 

function getTeamNameRenderer(seasonId) {
  return ({ value, data: { id } }) => {
    if (value === "") {
      value = <span>------ TBD ------</span>;
    }

    return (
      <Link to={`/seasons/${seasonId}/games/scheduled/${id}/edit`} >
        {value}
      </Link>
    );
  }
};

export default getTeamNameRenderer;
