import { atom } from "recoil";

export const currentPageState = atom({
  key: 'currentPage',
  default: 1
});

export const totalPagesState = atom({
  key: 'totalPage',
  default: 1
});

export const filteredGamesState = atom({
  key: 'filteredGames',
  default: 0
});