import { handleActions } from "redux-actions";

import { loadingRoutine, updatingRoutine } from "../routines";

import actions from "../actions";

const initialState = {
  id: "",
  title: "",
  externalId: "",
  logoUrl: "",
  association: {},
  league: {},
  season: {},
  division: {},
  roster: {
    players: [],
    coaches: []
  },
  data: {}
};

function reduceLoadingSuccess(state, { payload: { team } }) {
  const { id, title, externalId, logoUrl, association, league, season, division, roster, data } = team;

  return {
    id,
    title,
    externalId,
    logoUrl,
    roster: { players: roster.players || [], coaches: roster.coaches || [] },
    association: { id: association.id, title: association.title },
    league: { id: league.id, title: league.title },
    season: { id: season.id, title: season.title },
    division: { id: division.id, title: division.title },
    data: data
  };
}

function reduceRosterUpdatingSuccess(state, { payload: { team } }) {
  const { roster } = team;

  return {
    ...state,
    roster: { players: roster.players || [], coaches: roster.coaches || [] }
  };
}

export default handleActions(
  {
    [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [updatingRoutine.SUCCESS]: reduceRosterUpdatingSuccess,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
