import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { CustomInput, FormGroup } from "reactstrap";

import FormItem from "@/components/FormItem";
import { useDashboardFormNamespaceContext } from "@/components/DashboardForm";

function ManagerSchedulingSwitch({ enabled, onChange }) {
  const namespace = useDashboardFormNamespaceContext();

  const toggle = useCallback(({ target: { checked } }) => onChange(checked), [onChange]);

  return (
    <FormItem>
      <FormGroup className="col-md-12">
        <CustomInput
          id={`${namespace}__manager-scheduling__toggle`}
          type="switch"
          label={<span>Allow designated team personnel to schedule games for their team</span>}
          checked={enabled}
          onChange={toggle}
        />
      </FormGroup>
    </FormItem>
  );
}

ManagerSchedulingSwitch.propTypes = {
  enabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ManagerSchedulingSwitch;
