import { connect } from "react-redux";

import {
  getReportDataSourcesSelectAssociationList,
  getReportDataSourcesSelectAssociationListIsLoaded,
  getReportDataSourcesSelectAssociationListIsLoading,
  getReportDataSourcesSelectAssociationIsSelected
} from "@/redux/reportDataSourcesSelect/selectors";

import { reportDataSourcesSelectAssociationListLoadingRoutine } from "@/redux/reportDataSourcesSelect/routines";

import actions from "@/redux/reportDataSourcesSelect/actions";

import List from "./List";

const mapStateToProps = state => ({
  isLoading: getReportDataSourcesSelectAssociationListIsLoading(state),
  isLoaded: getReportDataSourcesSelectAssociationListIsLoaded(state),
  associations: getReportDataSourcesSelectAssociationList(state),
  isOptionSelected: getReportDataSourcesSelectAssociationIsSelected(state)
});

const mapDispatchToProps = dispatch => ({
  loadList: () => dispatch(reportDataSourcesSelectAssociationListLoadingRoutine.trigger()),
  select: id => dispatch(actions.associationList.select({ id })),
  deselect: id => dispatch(actions.associationList.deselect({ id })),
  clear: () => dispatch(actions.associationList.clear())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List);
