import validateType from "./validateType";
import validateFirstName from "./validateFirstName";
import validateLastName from "./validateLastName";
import validateExternalId from "./validateExternalId";
import validateTeamId from "./validateTeamId";
import validateJerseyNumber from "./validateJerseyNumber";
import validateStatusAffiliated from "./validateStatusAffiliated";
import validatePosition from "./validatePosition";
import validateDesignation from "./validateDesignation";
import validateBirthDate from "./validateBirthDate";
import validateShotHand from "./validateShotHand";
import validateCountry from "./validateCountry";

export default [
  validateType,
  validateFirstName,
  validateLastName,
  validateExternalId,
  validateTeamId,
  validateJerseyNumber,
  validateStatusAffiliated,
  validatePosition,
  validateDesignation,
  validateBirthDate,
  validateShotHand,
  validateCountry
];
