import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { account } from "@/redux/account/actions";
import { acceptInvitationValidationRoutine } from "@/redux/account/routines";

export const useUrlInvitationCode = () => {
    const dispatch = useDispatch();
    const urlParams = new URLSearchParams(window.location.search);
    const invitation = urlParams.get("invitation");

    useEffect(() => {
        if (!invitation) return;

        dispatch(account.invitation.fieldChange({
            code: invitation,
        }));
        
        dispatch(acceptInvitationValidationRoutine());
    }, [invitation]);
};