import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import Spinner from "@/components/Spinner";

import "./Spinner.scss";

class InfiniteScrollSpinner extends PureComponent {
  static propTypes = {
    enabled: PropTypes.bool
  };

  static defaultProps = {
    enabled: false
  };

  render() {
    const { enabled } = this.props;

    if (!enabled) {
      return null;
    }

    return (
      <div className="infinite-scroll__spinner-wrapper">
        <Spinner className="infinite-scroll__spinner" theme="loader" color="yellow" size="xxl" />
      </div>
    );
  }
}

export default InfiniteScrollSpinner;
