import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import PaginationItem from "./components/PaginationItem";

class PaginationNav extends PureComponent {
  static propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    disabled: PropTypes.bool,
    changePage: PropTypes.func
  };

  static defaultProps = {
    disabled: false,
    changePage() {}
  };

  handlePageClick = pageNumber => {
    this.props.changePage(pageNumber);
  };

  render() {
    const { currentPage, totalPages, disabled, totalCount } = this.props;
    let currentHigh = 25;
    let currentLow = 1;

    if (totalPages <= 1) {
      return null;
    }

    const sliceStart = Math.floor(currentPage / 5) * 5 || 1;
    const sliceEnd = (Math.floor(currentPage / 5) + 1) * 5;

    const pages = [...Array(totalPages).keys()].slice(sliceStart - 1, sliceEnd);

    const onClick = direction => {
      switch (direction) {
        case "first":
          this.handlePageClick(1);
          break;
        case "last":
          this.handlePageClick(totalPages);
          break;
        case 1:
        case -1:
          if (direction + currentPage <= 0 || direction + currentPage > totalPages) {
            return;
          }
          this.handlePageClick(direction + currentPage);
          break;
        default:
          console.error(direction, " is not a valid page selector");
      }
    };
    return (
      <Fragment>
        <div className="pagination-footer">
          {/* <p className="pagination-results"><strong>{currentLow}</strong> to <strong>{currentHigh}</strong> of <strong>{totalCount}</strong></p> */}

          <ul className="pagination">
            <PaginationItem
              text={<span className="material-icons md-18">first_page</span>}
              onClick={() => onClick("first")}
            />
            <PaginationItem
              text={<span className="material-icons">navigate_before</span>}
              onClick={() => onClick(-1)}
            />

            <li>
              <p>
                {" "}
                Page <strong>{currentPage} </strong> of <strong>{totalPages}</strong>{" "}
              </p>
            </li>

            <PaginationItem text={<span className="material-icons">navigate_next</span>} onClick={() => onClick(1)} />
            <PaginationItem
              text={<span className="material-icons md-18">last_page</span>}
              onClick={() => onClick("last")}
            />
          </ul>
        </div>
        <hr className="pagination-hr" />
      </Fragment>
    );
  }
}

export default PaginationNav;
