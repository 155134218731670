import React, { useCallback } from "react";
import PropTypes from "prop-types";

import TeamPickerField from "@/components/TeamPickerField";

function TeamInput(props) {
  const { id, name, value, setValue, opponentId, isInvalid, isDirty, errors } = props;

  // console.log("TeamInputProps", props);

  const onChange = useCallback(value => setValue(value), [setValue]);

  return (
    <TeamPickerField
      id={id}
      name={name}
      onChange={onChange}
      value={value}
      disallowTeamValue={opponentId}
      invalid={isDirty && isInvalid}
      errors={errors}
    />
  );
}

TeamInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.shape({}).isRequired,
  opponentId: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  isInvalid: PropTypes.bool.isRequired,
  isDirty: PropTypes.bool.isRequired
};

export default TeamInput;
