import { handleActions } from "redux-actions";

import { loadGamesheetRoutine } from "@/redux/gamesheet/routines";

import { loadGamesRoutine, toggleGameViewedRoutine } from "../routines";
import { gamesActions } from "../actions";

const initialState = {};

const applyGameViewedStatus = (state, game) => ({
  ...state,
  [game.id]: {
    isViewed: game.meta.viewed === true,
    isChanging: false
  }
});

const loadViewsStatus = (state, { payload }) => {
  const games = payload.games || [];

  const views = games.reduce((result, game) => applyGameViewedStatus(result, game), {});

  return { ...state, ...views };
};

const loadGameViewedStatus = (state, { payload: { game } }) => applyGameViewedStatus(state, game);

const toggleViewedRequest = (state, { payload: { id } }) => ({
  ...state,
  [id]: { ...state[id], isChanging: true }
});

const toggleViewedSuccess = (state, { payload: { id, isViewed } }) => ({
  ...state,
  [id]: { ...state[id], isViewed }
});

const toggleViewedFulfill = (state, { payload: { id } }) => ({
  ...state,
  [id]: { ...state[id], isChanging: false }
});

export default handleActions(
  {
    [loadGamesRoutine.SUCCESS]: loadViewsStatus,
    [loadGamesheetRoutine.SUCCESS]: loadGameViewedStatus,
    [toggleGameViewedRoutine.REQUEST]: toggleViewedRequest,
    [toggleGameViewedRoutine.SUCCESS]: toggleViewedSuccess,
    [toggleGameViewedRoutine.FULFILL]: toggleViewedFulfill,
    [gamesActions.list.clear]: () => ({ ...initialState })
  },
  initialState
);
