import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Input } from "reactstrap";

class PeriodInput extends PureComponent {
  static propTypes = {
    periods: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func
  };

  static defaultProps = {
    value: "",
    periods: [],
    onChange() {}
  };

  render() {
    const { id, name, onChange, periods, value } = this.props;

    return (
      <Input type="select" id={id} name={name} onChange={onChange} value={value}>
        {periods.map(period => (
          <option key={period} value={period}>
            {period.toUpperCase()}
          </option>
        ))}
      </Input>
    );
  }
}

export default PeriodInput;
