import { combineReducers } from "redux";

import createOffsetPaginationReducer from "@/redux/common/pagination/createOffsetPaginationReducer";

import { playersLoadingRoutine, coachesLoadingRoutine } from "../../routines";

import actions from "../../actions";

import search from "./search";
import data from "./data";
import teams from "./teams";

const pagination = createOffsetPaginationReducer([playersLoadingRoutine, coachesLoadingRoutine], actions.clear);

export default combineReducers({
  search,
  pagination,
  data,
  teams
});
