import { put, all, takeLatest, call } from "redux-saga/effects";
import build from "redux-object";

import { gamesheetAPIRequest } from "@/redux/api/sagas";
import { loadBillingReport } from "@/lib/api/reports";

import { billingReportLoadingRoutine } from "./routines";

function* reportLoadingSaga({ payload: { seasonId } }) {
  yield put(billingReportLoadingRoutine.request());

  try {
    const { data } = yield call(gamesheetAPIRequest, loadBillingReport, {
      seasonId
    });

    const teamBillingReports = build(data, "teamBillingReports") || [];

    yield put(billingReportLoadingRoutine.success({ teamBillingReports }));
  } catch (error) {
    const { status: responseStatus } = error.response || {};

    yield put(billingReportLoadingRoutine.failure({ error, responseStatus }));
  } finally {
    yield put(billingReportLoadingRoutine.fulfill());
  }
}

export function* billingReportFlow() {
  yield all([takeLatest(billingReportLoadingRoutine.TRIGGER, reportLoadingSaga)]);
}
