export const CHANGE_FIELD = "CHANGE_FIELD";
export const SET_ERRORS = "SET_ERRORS";
export const VISIT_FIELD = "VISIT_FIELD";
export const RESET_FIELD = "RESET_FIELD"

export function changeField({ name, value, errors }) {
  return { type: CHANGE_FIELD, payload: { name, value, errors } };
}

export function setErrors(errors) {
  return { type: SET_ERRORS, payload: errors };
}

export function visitField(name) {
  return { type: VISIT_FIELD, payload: { name } };
}

export function resetVisitField(name){
  return { type: RESET_FIELD, payload: { name } }
}

