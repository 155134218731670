import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getQuery } from "@/redux/invitationFilter/selectors";
import actions from "@/redux/invitationFilter/actions";

export default function useQueryInput() {
  const dispatch = useDispatch();
  const value = useSelector(getQuery);
  const setValue = useCallback(value => dispatch(actions.setQuery(value)), [dispatch]);

  return {
    value,
    setValue
  };
}
