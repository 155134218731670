import { takeLatest, all, put } from "redux-saga/effects";

import { fetchOne } from "@/redux/api/sagas";
import { loadGame } from "@/lib/api/games";

import { loadingRoutine } from "./routines";

function* loadingSaga({ payload: { seasonId, gameId } }) {
  yield put(loadingRoutine.request());

  try {
    const [game] = yield fetchOne({ type: "games", id: gameId }, loadGame, {
      seasonId,
      include: "teams"
    });

    yield put(loadingRoutine.success({ game }));
  } catch (error) {
    const responseStatus = error.response && error.response.status;

    if (error.response) {
      yield put(loadingRoutine.failure({ responseStatus }));
    } else {
      throw error;
    }
  } finally {
    yield put(loadingRoutine.fulfill());
  }
}

export function* currentGameFlow() {
  yield all([takeLatest(loadingRoutine.TRIGGER, loadingSaga)]);
}
