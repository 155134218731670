import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Input } from "reactstrap";

class TeamPlayerInput extends PureComponent {
  static propTypes = {
    team: PropTypes.oneOf(["home", "visitor"]).isRequired,
    players: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        firstName: PropTypes.string,
        lastName: PropTypes.string
      })
    ),
    value: PropTypes.string,
    id: PropTypes.string,
    optional: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func
  };

  static defaultProps = {
    optional: false,
    players: [],
    value: "",
    onChange() {}
  };

  render() {
    const { id, name, value, players, optional, onChange } = this.props;

    return (
      <Input type="select" id={id} name={name} value={value} onChange={onChange}>
        <option disabled={optional === false} />
        {players.map(({ id, firstName, lastName, number }) => (
          <option key={id} value={id}>{`#${number} – ${firstName} ${lastName}`}</option>
        ))}
      </Input>
    );
  }
}

export default TeamPlayerInput;
