import _orderBy from "lodash/orderBy";

const selectRoot = state => state.seasonIpadKeysManager;
const selectKeys = state => selectRoot(state).keys;

export const getSeasonIpadKeysManagerIsOpen = state => selectRoot(state).isOpen;

export const getSeasonIpadKeysManagerIsLoading = state => selectRoot(state).isLoading;

export const getSeasonIpadKeysManagerIsLoaded = state => selectRoot(state).isLoaded;

export const getSeasonIpadKeysManagerSeasonId = state => selectRoot(state).seasonId;

export const getSeasonIpadKeysManagerSeasonTitle = state => selectRoot(state).seasonTitle;

export const getSeasonIpadKeysManagerKeyValue = (state, keyId) => selectKeys(state).data[keyId].value;

export const getSeasonIpadKeysManagerKeyDescription = (state, keyId) => selectKeys(state).data[keyId].description;

export const getSeasonIpadKeysManagerKeyScopes = (state, keyId) => selectKeys(state).data[keyId].scopes;

export const getSeasonIpadKeysManagerKeyRoles = (state, keyId) => selectKeys(state).data[keyId].roles;

export const getSeasonIpadKeysManagerKeys = state => {
  const { ids, data } = selectKeys(state);
  const { filter } = selectRoot(state);

  const seasonId = getSeasonIpadKeysManagerSeasonId(state);

  const keys = ids.map(id => {
    const { value, seasonIds, roles, isSelected, liveScoringScopes } = data[id];
    const isIncludesSeason = seasonIds.includes(seasonId);

    const isAvailable = roles.length === 0 || roles.filter(({ title }) => title !== "app").length === 0;

    return {
      id,
      value,
      isSelected,
      isIncludesSeason,
      isAvailable,
      description: data[id].description,
      roles,
      seasonIds,
      liveScoringScopes
    };
  });

  function escapeRegExp(str) {
    return str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

  const filteredKeys = filter
    ? keys.filter(
        ({ value, description }) =>
          value.toString().match(escapeRegExp(filter.toString())) ||
          description.toLowerCase().match(escapeRegExp(filter.toString()))
      )
    : keys;

  return _orderBy(filteredKeys, [({ value }) => value.toLowerCase()], ["asc"]);
};

export const getSeasonIpadKeysManagerIsWorking = state => selectRoot(state).isWorking;

export const getSeasonIpadKeysManagerFilter = state => selectRoot(state).filter;

export const getSeasonIpadKeysManagerTotalKeysAmount = state => selectRoot(state).keys.ids.length;

export const getSeasonIpadKeysManagerSelectedKeys = state =>
  getSeasonIpadKeysManagerKeys(state).filter(({ isSelected }) => isSelected);
