import { handleActions } from "redux-actions";
import { combineReducers } from "redux";

import { loadRefereesListRoutine } from "../../routines";
import { refereesActions } from "../../actions";

import data from "./data";
import search from "./search";
import mergeTool from "./mergeTool";

const isForbidden = handleActions(
  {
    [loadRefereesListRoutine.FAILURE]: (state, { payload: { responseStatus } }) => responseStatus === 401,
    [refereesActions.list.clear]: () => false
  },
  false
);

export default combineReducers({
  data,
  search,
  mergeTool,
  isForbidden
});
