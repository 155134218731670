import { all, put, call, takeLatest, takeEvery, select } from "redux-saga/effects";

import build from "redux-object";

import { loadAssociations } from "@/lib/api/associations";
import { loadLeagues } from "@/lib/api/leagues";
import { loadSeasons } from "@/lib/api/seasons";
import { loadDivisions } from "@/lib/api/divisions";
import { loadTeams } from "@/lib/api/teams";

import { gamesheetAPIRequest, fetchList } from "@/redux/api/sagas";

import {
  reportDataSourcesSelectAssociationListLoadingRoutine,
  reportDataSourcesSelectLeagueListLoadingRoutine,
  reportDataSourcesSelectSeasonListLoadingRoutine,
  divisionsLoadingRoutine,
  teamsLoadingRoutine
} from "./routines";

import actions from "./actions";

import {
  getReportDataSourcesSelectLeagueListIsLoadedForAssociation,
  getReportDataSourcesSelectSelectedAssociationId,
  getReportDataSourcesSelectSeasonListIsLoadedForAssociation,
  getReportDataSourcesSelectLeagueIdsNotSelected,
  getReportDataSourcesSelectSeasonIdsNotSelected,
  getDivisionListIsLoadedForSeason,
  getTeamListIsLoadedForDivision,
  getDivisionIdsNotSelected,
  getPenaltiesNotSelected,
  getTeamIdsNotSelected
} from "./selectors";

function* loadAssociationListSaga() {
  yield put(reportDataSourcesSelectAssociationListLoadingRoutine.request());

  try {
    const { data } = yield call(gamesheetAPIRequest, loadAssociations);

    const associations = build(data, "associations");

    yield put(
      reportDataSourcesSelectAssociationListLoadingRoutine.success({
        associations
      })
    );
  } catch (e) {
    yield put(reportDataSourcesSelectAssociationListLoadingRoutine.failure());
  } finally {
    yield put(reportDataSourcesSelectAssociationListLoadingRoutine.fulfill());
  }
}

function* loadLeagueListSaga({ payload: { id: associationId } }) {
  const isLeagueListLoaded = yield select(getReportDataSourcesSelectLeagueListIsLoadedForAssociation, associationId);

  if (isLeagueListLoaded) {
    return;
  }

  yield put(reportDataSourcesSelectLeagueListLoadingRoutine.request({ associationId }));

  try {
    const { data } = yield call(gamesheetAPIRequest, loadLeagues, {
      associationId
    });

    const leagues = build(data, "leagues");

    yield put(
      reportDataSourcesSelectLeagueListLoadingRoutine.success({
        leagues,
        associationId
      })
    );
  } catch (e) {
    yield put(reportDataSourcesSelectLeagueListLoadingRoutine.failure({ associationId }));
  } finally {
    yield put(reportDataSourcesSelectLeagueListLoadingRoutine.fulfill({ associationId }));
  }
}

function* loadSeasonListSaga({ payload: { id: leagueId } }) {
  const associationId = yield select(getReportDataSourcesSelectSelectedAssociationId);

  const isSeasonListLoaded = yield select(getReportDataSourcesSelectSeasonListIsLoadedForAssociation, leagueId);

  if (isSeasonListLoaded) {
    return;
  }

  yield put(
    reportDataSourcesSelectSeasonListLoadingRoutine.request({
      leagueId
    })
  );

  try {
    const { seasons, archivedSeasons } = yield fetchList(["seasons", "archivedSeasons"], loadSeasons, {
      leagueId,
      associationId
    });

    yield put(
      reportDataSourcesSelectSeasonListLoadingRoutine.success({
        seasons: [...seasons, ...archivedSeasons],
        leagueId
      })
    );
  } catch (e) {
    yield put(reportDataSourcesSelectSeasonListLoadingRoutine.failure({ leagueId }));
  } finally {
    yield put(reportDataSourcesSelectSeasonListLoadingRoutine.fulfill({ leagueId }));
  }
}

function* loadDivisionListSaga({ payload: { id: seasonId } }) {
  const isDivisionListLoaded = yield select(getDivisionListIsLoadedForSeason, seasonId);

  if (isDivisionListLoaded) {
    return;
  }

  yield put(
    divisionsLoadingRoutine.request({
      seasonId
    })
  );

  try {
    const { divisions } = yield fetchList("divisions", loadDivisions, {
      seasonId
    });

    yield put(
      divisionsLoadingRoutine.success({
        divisions,
        seasonId
      })
    );
  } catch (e) {
    yield put(divisionsLoadingRoutine.failure({ seasonId }));
  } finally {
    yield put(divisionsLoadingRoutine.fulfill({ seasonId }));
  }
}

function* loadTeamListSaga({ payload: { id: divisionId } }) {
  const isTeamListLoaded = yield select(getTeamListIsLoadedForDivision, divisionId);

  if (isTeamListLoaded) {
    return;
  }

  yield put(
    teamsLoadingRoutine.request({
      divisionId
    })
  );

  try {
    const { teams } = yield fetchList("teams", loadTeams, {
      divisionId
    });

    yield put(
      teamsLoadingRoutine.success({
        teams,
        divisionId
      })
    );
  } catch (e) {
    yield put(teamsLoadingRoutine.failure({ divisionId }));
  } finally {
    yield put(teamsLoadingRoutine.fulfill({ divisionId }));
  }
}

function* selectAllLeaguesSaga() {
  const ids = yield select(getReportDataSourcesSelectLeagueIdsNotSelected);

  yield all(ids.map(id => put(actions.leagueList.select({ id }))));
}

function* selectAllSeasonsSaga({ payload: { loadDivisions } }) {
  const ids = yield select(getReportDataSourcesSelectSeasonIdsNotSelected);

  yield all(ids.map(id => put(actions.seasonList.select({ id, loadDivisions }))));
}

function* selectAllPenaltiesSaga({ payload: { sid } }) {
  const missingPenalties = yield select(getPenaltiesNotSelected);
  const missingCodes = missingPenalties.map(({ code }) => code);

  yield put(actions.penaltyList.select({ sid, codes: missingCodes }));
}

function* selectAllDivisionsSaga() {
  const ids = yield select(getDivisionIdsNotSelected);

  yield all(ids.map(id => put(actions.divisionList.select({ id }))));
}

function* selectAllTeamsSaga() {
  const ids = yield select(getTeamIdsNotSelected);

  yield all(ids.map(id => put(actions.teamList.select({ id }))));
}

export function* reportDataSourcesSelectFlow() {
  yield all([
    takeLatest(reportDataSourcesSelectAssociationListLoadingRoutine.TRIGGER, loadAssociationListSaga),
    takeLatest(actions.associationList.select, loadLeagueListSaga),
    takeEvery(actions.leagueList.select, loadSeasonListSaga),
    takeEvery(
      ({ type, payload }) => type === actions.seasonList.select.toString() && payload && payload.loadDivisions,
      loadDivisionListSaga
    ),
    takeEvery(actions.divisionList.select, loadTeamListSaga),
    takeLatest(actions.leagueList.selectAll, selectAllLeaguesSaga),
    takeLatest(actions.seasonList.selectAll, selectAllSeasonsSaga),
    takeLatest(actions.penaltyList.selectAll, selectAllPenaltiesSaga),
    takeLatest(actions.divisionList.selectAll, selectAllDivisionsSaga),
    takeLatest(actions.teamList.selectAll, selectAllTeamsSaga)
  ]);
}
