const selectRoot = state => state.exportSeasonGames;

export const getCount = state => selectRoot(state).count;
export const getIsLoading = state => selectRoot(state).isLoading;
export const getIsLoaded = state => selectRoot(state).isLoaded;

export const getMeta = state => {
  const { isLoading, isLoaded, isSubmitting, isSubmitted } = selectRoot(state);

  return {
    isLoading,
    isLoaded,
    isSubmitting,
    isSubmitted
  };
};
