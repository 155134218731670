import { handleActions } from "redux-actions";

import { teamLoadingRoutine } from "../routines";
import actions from "../actions";

const initialState = {};

function reduceLoadingSuccess(state, { payload: { team } }) {
  const { season } = team;

  return {
    ...state,
    [season.id]: {
      title: season.title
    }
  };
}

export default handleActions(
  {
    [teamLoadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
