import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Table } from "reactstrap";

class CareerStats extends PureComponent {
  static propTypes = {
    gamesRefereed: PropTypes.number,
    avgPenaltyMinutesPerGame: PropTypes.number,
    mostFreqAssessedPenaltyCode: PropTypes.string,
    mostFreqAssessedPenaltyCount: PropTypes.number,
    majorPenaltiesAssessedCount: PropTypes.number
  };

  static defaultProps = {
    gamesRefereed: 0,
    avgPenaltyMinutesPerGame: 0,
    mostFreqAssessedPenaltyCode: null,
    mostFreqAssessedPenaltyCount: 0,
    majorPenaltiesAssessedCount: 0
  };

  render() {
    const {
      gamesRefereed,
      avgPenaltyMinutesPerGame,
      mostFreqAssessedPenaltyCode,
      mostFreqAssessedPenaltyCount,
      majorPenaltiesAssessedCount
    } = this.props;

    return (
      <div className="referee-report__section">
        <h3 className="referee-report__section-title">Career Statistics</h3>

        <Table striped borderless>
          <thead>
            <tr>
              <th>Games Refereed</th>
              <th>Average PIM/GP</th>
              <th>Most Frequently Assessed Penalty</th>
              <th>Number of Major Penalties Assessed</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{gamesRefereed}</td>
              <td>{avgPenaltyMinutesPerGame}</td>
              <td>
                {mostFreqAssessedPenaltyCount > 0
                  ? `${mostFreqAssessedPenaltyCode} [${mostFreqAssessedPenaltyCount}]`
                  : "N/A"}
              </td>
              <td>{majorPenaltiesAssessedCount}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}

export default CareerStats;
