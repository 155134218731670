import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { FormGroup } from "reactstrap";

import FormItem from "@/components/FormItem";
import ShotsInput from "../ShotsInput";

class GoalieShots extends PureComponent {
  static propTypes = {
    goalieNumber: PropTypes.string.isRequired,
    goalieName: PropTypes.string.isRequired,
    goalieId: PropTypes.string.isRequired,
    shots: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onChange: PropTypes.func
  };

  static defaultProps = {
    shots: [],
    onChange() {}
  };

  render() {
    const { goalieNumber, goalieName, goalieId, shots, onChange } = this.props;

    return (
      <FormItem>
        <FormGroup className="col-md-3">
          #{goalieNumber} {goalieName}
        </FormGroup>

        {shots.map(({ period, shots }) => (
          <FormGroup key={period} className="col-md-1">
            <ShotsInput period={period} shots={shots} goalieId={goalieId} onChange={onChange} />
          </FormGroup>
        ))}
      </FormItem>
    );
  }
}

export default GoalieShots;
