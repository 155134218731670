import React from "react";

import ProgressBar from "@/components-16.8/ProgressBar";
import { useImport } from "@/components-16.8/RefereeImportWizard/hooks/useImport";

export function ImportProgress({ seasonId }) {

    const { refereeCount, progress } = useImport({ seasonId });

    return (
        <>
            <ProgressBar
                title={`Referees ( ${progress.success} imported of ${refereeCount} )`}
                total={refereeCount}
                pending={progress.pending}
                requested={progress.requested}
                success={progress.success}
                failed={progress.failed}
            />
        </>

    )

}