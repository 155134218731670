import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import { GAME_TYPES } from "@/constants";

import AbilityButton from "@/components/AbilityButton";

import TextPlaceholder from "./TextPlaceholder";

function ScheduledGame(props) {
  const { visitor, home, divisions, date, startTime, number, gameType, location, seasonId, id } = props;

  return (
    <tr className="dashboard-table-item">
      <td>
        <TextPlaceholder placeholder="--------- TBD ----------">{visitor}</TextPlaceholder>
      </td>
      <td>
        <TextPlaceholder placeholder="--------- TBD ----------">{home}</TextPlaceholder>
      </td>
      <td>
        <AbilityButton
          subject={{ type: "scheduledGames", id }}
          action="update"
          size="sm"
          color="primary"
          outline
          className="dashboard-table-item__action dashboard-table-item__action--hoverable"
          tag={Link}
          to={`/seasons/${seasonId}/games/scheduled/${id}/edit`}
        >
          Edit
        </AbilityButton>
      </td>
      <td>
        <TextPlaceholder placeholder="No divisions selected">{divisions}</TextPlaceholder>
      </td>
      <td>{date}</td>
      <td>{startTime}</td>
      <td>{number}</td>
      <td>{GAME_TYPES[gameType]}</td>
      <td>{location}</td>
    </tr>
  );
}

ScheduledGame.propTypes = {
  id: PropTypes.string.isRequired,
  seasonId: PropTypes.string.isRequired,
  visitor: PropTypes.string.isRequired,
  home: PropTypes.string.isRequired,
  divisions: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  gameType: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired
};

export default ScheduledGame;
