import { handleActions } from "redux-actions";
import _camelCase from "lodash/camelCase";

import { passwordActions } from "../../actions";
import { validateNewPasswordRoutine, createNewPasswordRoutine } from "../../routines";

const initialState = {
  token: {
    value: "",
    isDirty: false,
    error: null
  },
  password: {
    value: "",
    isDirty: false,
    error: null
  },
  passwordConfirmation: {
    value: "",
    isDirty: false,
    error: null
  }
};

const initField = (state, { payload: { field, value } }) => {
  return { ...state, [field]: { error: null, isDirty: false, value } };
};

const changeFieldValue = (state, { payload: { field, value } }) => {
  return { ...state, [field]: { error: null, isDirty: true, value } };
};

const failValidation = (state, { payload: { errors } }) => {
  return {
    ...state,
    ...Object.entries(errors).reduce(
      (fields, [field, error]) => ({
        ...fields,
        [field]: { ...state[field], isDirty: false, error }
      }),
      {}
    )
  };
};

const failRequest = (state, { payload: { errors = [] } }) => ({
  ...state,
  ...errors.reduce((fields, { title, source: { pointer } }) => {
    const field = _camelCase(pointer.match(/\/data\/attributes\/(\w+)/)[1]);

    return {
      ...fields,
      [field]: { ...state[field], error: `${field} ${title}`, isDirty: false }
    };
  }, {})
});

const clear = () => ({ ...initialState });

export default handleActions(
  {
    [passwordActions.new.initField]: initField,
    [passwordActions.new.changeField]: changeFieldValue,
    [validateNewPasswordRoutine.FAILURE]: failValidation,
    [createNewPasswordRoutine.FAILURE]: failRequest,
    [passwordActions.new.clear]: clear
  },
  initialState
);
