import calculateGoalieIceTime from "@/utils/calculateGoalieIceTime";

export default function reduceTeamGoaliesIceTime(options) {
  const { players, shifts, goals, periods, penalties, shots, oppositeShifts, hasShootouts } = options;

  const goalies = players.filter(({ position }) => position === "goalie").map(({ id }) => id);

  return {
    ...goalies.reduce(
      (result, id) => ({
        ...result,
        [id]: 0
      }),
      {}
    ),
    ...calculateGoalieIceTime({
      periods,
      shifts,
      goals,
      penalties,
      shots,
      oppositeShifts,
      hasShootouts
    })
  };
}
