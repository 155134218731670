import React from 'react';
import { Button } from "reactstrap";
import { Link } from 'react-router-dom';

import { useImportSuccess } from './useImportSuccess';
import DashboardFormFooter from "@/components/DashboardFormFooter";

export function ImportSuccess({ seasonId }) {

  const { refereeCount } = useImportSuccess();

  return (
    <>
      <div className="csv-import__confirmation__text">
        <p>
          {refereeCount} {refereeCount === 1 ? "referee was" : "referees were"} imported.
        </p>
      </div>
      <DashboardFormFooter>
        <div className="dashboard-form-footer__primary">
          <Link to={`/seasons/${seasonId}/referees`}><Button type="button" color="primary">Go To Season Referees</Button></Link>
        </div>
      </DashboardFormFooter>
    </>
  );
}