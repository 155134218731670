import { connect } from "react-redux";

import { loadGamesheetHistoryRoutine, exportGamesheetHistoryRecordToPDFRoutine } from "@/redux/gamesheet/routines";

import {
  getGamesheetHistoryRecords,
  getGamesheetHistoryIsLoaded,
  getGamesheetHistoryIsWorking
} from "@/redux/gamesheet/selectors";

import RecordsList from "./RecordsList";

const mapStateToProps = state => ({
  records: getGamesheetHistoryRecords(state),
  isLoaded: getGamesheetHistoryIsLoaded(state),
  isWorking: getGamesheetHistoryIsWorking(state)
});

const mapDispatchToProps = (dispatch, { gameId, seasonId }) => ({
  loadRecords: payload => dispatch(loadGamesheetHistoryRoutine(payload)),
  downloadPDF: id =>
    dispatch(
      exportGamesheetHistoryRecordToPDFRoutine({
        id,
        gameId,
        seasonId,
        download: true
      })
    ),
  viewPDF: id =>
    dispatch(
      exportGamesheetHistoryRecordToPDFRoutine({
        id,
        gameId,
        seasonId
      })
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecordsList);
