import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Table } from "reactstrap";

class SummaryStats extends PureComponent {
  static propTypes = {
    gamesPlayed: PropTypes.number,
    visits: PropTypes.number,
    avgVisitsPerGp: PropTypes.number
  };

  static defaultProps = {
    gamesPlayed: 0,
    visits: 0,
    avgVisitsPerGp: 0
  };

  render() {
    const { gamesPlayed, visits, avgVisitsPerGp } = this.props;

    return (
      <div className="trainer-visits-report__section">
        <h3 className="trainer-visits-report__section-title">Summary Statistics</h3>

        <Table striped borderless>
          <thead>
            <tr>
              <th>Games Played</th>
              <th>Trainer Visits</th>
              <th>Average Visits Per GP</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{gamesPlayed}</td>
              <td>{visits}</td>
              <td>{avgVisitsPerGp}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}

export default SummaryStats;
