import _camelCase from "lodash/camelCase";
import _trimEnd from "lodash/trimEnd";
import { handleActions } from "redux-actions";

import { changeGameNoteFormField, disableGameNoteForm, clearGameNotes } from "../../actions";

import {
  gameNoteFormFieldValidationRoutine,
  gameNoteFormValidationRoutine,
  gameNoteFormSubmittingRoutine
} from "../../routines";

const FIELDS_MAPPING = {
  text: "Note"
};

const initialState = {
  text: {
    initialValue: "",
    value: "",
    isDirty: false,
    errors: []
  }
};

function reduceFieldChange(state, { payload: { field, value } }) {
  return {
    ...state,
    [field]: {
      ...state[field],
      isDirty: _trimEnd(value) !== _trimEnd(state[field].initialValue),
      value
    }
  };
}

function reduceFieldValidationSuccess(state, { payload: { field } }) {
  return { ...state, [field]: { ...state[field], errors: [] } };
}

function reduceFieldValidationFailure(state, { payload: { field, errors } }) {
  return { ...state, [field]: { ...state[field], errors } };
}

function reduceFormValidationSuccess(state) {
  return Object.keys(state).reduce((result, field) => {
    return { ...result, [field]: { ...state[field], errors: [] } };
  }, {});
}

function reduceFormValidationFailure(state, { payload: { errors } }) {
  return {
    ...state,
    ...Object.keys(errors).reduce(
      (result, field) => ({
        ...result,
        [field]: { ...state[field], errors: errors[field] }
      }),
      {}
    )
  };
}

function reduceSubmitFailure(state, { payload }) {
  if (payload && payload.response) {
    const {
      status,
      data: { errors }
    } = payload.response;

    if (status === 400 && errors) {
      return {
        ...state,
        ...errors.reduce((result, { title, source: { pointer } }) => {
          const field = _camelCase(pointer.match(/\/data\/attributes\/(\w+)/)[1]);

          if (!field) {
            return { ...result };
          }

          const fieldName = FIELDS_MAPPING[field];

          return {
            ...result,
            [field]: {
              ...state[field],
              isDirty: false,
              errors: [...state[field].errors, `${fieldName} ${title}`]
            }
          };
        }, {})
      };
    }
  }

  return { ...state };
}

function reduceSubmitSuccess(state, { payload }) {
  return { ...initialState };
}

export default handleActions(
  {
    [changeGameNoteFormField]: reduceFieldChange,
    [gameNoteFormFieldValidationRoutine.SUCCESS]: reduceFieldValidationSuccess,
    [gameNoteFormFieldValidationRoutine.FAILURE]: reduceFieldValidationFailure,
    [gameNoteFormValidationRoutine.SUCCESS]: reduceFormValidationSuccess,
    [gameNoteFormValidationRoutine.FAILURE]: reduceFormValidationFailure,
    [gameNoteFormSubmittingRoutine.SUCCESS]: reduceSubmitSuccess,
    [gameNoteFormSubmittingRoutine.FAILURE]: reduceSubmitFailure,
    [disableGameNoteForm]: () => ({ ...initialState }),
    [clearGameNotes]: () => ({ ...initialState })
  },
  initialState
);
