import buildSeasonMember from "./buildSeasonMember";

export default function reducePlayers(state, { payload: { game } }) {
  const seasonPlayers = (game.players || []).reduce((players, { id, ...player }) => ({ ...players, [id]: player }), {});
  const gameRoster = [...(game.visitorRoster.players || []), ...(game.homeRoster.players || [])];

  return gameRoster.reduce(
    (players, { id, ...rosterPlayer }) => ({
      ...players,
      [id]: buildSeasonMember(seasonPlayers[id], rosterPlayer)
    }),
    {}
  );
}
