import _isEmpty from "lodash/isEmpty";

export function validateCode(value) {
  const errors = [];

  if (_isEmpty(value)) {
    errors.push("Code can not be empty");
  }

  return errors;
}

export function validateDescription(value) {
  const errors = [];

  if (_isEmpty(value)) {
    errors.push("Description can not be empty");
  }

  return errors;
}

export function validate({ code, description }) {
  let errors = null;

  const codeErrors = validateCode(code);
  const descriptionErrors = validateDescription(description);

  if (codeErrors.length > 0) {
    errors = { ...errors, code: codeErrors };
  }

  if (descriptionErrors.length > 0) {
    errors = { ...errors, description: descriptionErrors };
  }

  return errors;
}
