import _orderBy from "lodash/orderBy";

import {
  getTokenCanReadTeam,
  getTokenCanReadGames,
  getTokenCanReadDivision,
  getTokenCanReadCoaches,
  getTokenCanReadPlayers
} from "@/redux/token/selectors";
import { formatDate } from "@/utils/date";

function makePerson({ id, type, coaches, players }) {
  const { seasonId, ...person } = { coaches, players }[type][id];

  return {
    ...person,
    id,
    seasonId,
    name: `${person.firstName} ${person.lastName}`,
    type: { coaches: "coach", players: "player" }[type],
    typePlural: type,
    url: seasonId && `/seasons/${seasonId}/roster/${type}/${id}`
  };
}

function getTokenCanReadPerson(state, { type }) {
  return { coaches: getTokenCanReadCoaches, players: getTokenCanReadPlayers }[type](state);
}

const selectRoot = state => state.suspensionReport;
const selectReport = state => selectRoot(state).report;

const selectGames = state =>
  Object.entries(selectReport(state).games).reduce(
    (result, [id, game]) => ({
      ...result,
      [id]: {
        ...game,
        date: formatDate(game.startTime, "UTC")
      }
    }),
    {}
  );

export const getSuspensionReportIsLoaded = state => selectReport(state).isLoaded;

export const getSuspensionReportIsLoading = state => selectReport(state).isLoading;

export const getSuspensionReportIsForbidden = state => selectReport(state).isForbidden;

export const getSuspensionReportSuspensionsBySeasons = state => {
  const seasons = selectReport(state).seasons;
  const suspensions = selectReport(state).suspensions;
  const players = selectReport(state).players;
  const coaches = selectReport(state).coaches;
  const games = selectGames(state);
  const teams = selectReport(state).teams;
  const divisions = selectReport(state).divisions;
  const primarySorting = selectReport(state).sorting;
  const sorting = {
    columns: [primarySorting.column],
    order: [primarySorting.order]
  };

  if (primarySorting.column !== "game.startTime") {
    sorting.columns.push("game.startTime");
    sorting.order.push("desc");
  }

  const groupedTeamGamesStats = Object.entries(seasons).reduce(
    (result, [id, { title, isArchived }]) => [
      ...result,
      {
        season: { id, title, isArchived },
        suspensions: _orderBy(
          suspensions
            .filter(({ seasonId }) => seasonId === id)
            .map(({ person, gameId, teamId, divisionId, ...rest }) => ({
              ...rest,
              suspendedPerson: makePerson({ ...person, coaches, players }),
              game: { id: gameId, ...games[gameId] },
              team: { id: teamId, ...teams[teamId] },
              division: { id: divisionId, ...divisions[divisionId] },
              tokenCanReadTeam: getTokenCanReadTeam(state, teamId),
              tokenCanReadGame: getTokenCanReadGames(state),
              tokenCanReadDivision: getTokenCanReadDivision(state, divisionId),
              tokenCanReadSuspendedPerson: getTokenCanReadPerson(state, person)
            })),
          sorting.columns,
          sorting.order
        )
      }
    ],
    []
  );

  return _orderBy(groupedTeamGamesStats, ["season.title"], ["asc"]);
};

export const getSuspensionReportSorting = state => selectReport(state).sorting;
