import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import { unsubscribingRoutine, actions } from "@/redux/unsubscribeByToken";

import { decodeToken } from "../utils";

export default function useUnsubscribeByToken(token) {
  const dispatch = useDispatch();

  const { subscriptionId, teamTitle, teamId, tokenIsValid } = useMemo(
    () => {
      return decodeToken(token);
    },
    [token]
  );

  useEffect(
    () => {
      tokenIsValid && dispatch(unsubscribingRoutine({ token, subscriptionId }));

      return () => dispatch(actions.clear());
    },
    [dispatch, token, subscriptionId, tokenIsValid]
  );

  return {
    teamId,
    teamTitle,
    tokenIsValid
  };
}
