import { connect } from "react-redux";

import List from "./List";

import actions from "@/redux/reportDataSourcesSelect/actions";

import {
  getReportDataSourcesSelectSeasonGroupList,
  getReportDataSourcesSelectSeasonListIsLoaded,
  getReportDataSourcesSelectSeasonListIsLoading,
  getReportDataSourcesSeasonIsSelected,
  getReportDataSourcesHasAllSeasonsSelected,
  getReportDataSourcesSeasonIsArchivedIncluded,
  getReportDataSourcesSeasonHasArchived
} from "@/redux/reportDataSourcesSelect/selectors";

const mapStateToProps = state => ({
  seasonGroups: getReportDataSourcesSelectSeasonGroupList(state),
  isLoaded: getReportDataSourcesSelectSeasonListIsLoaded(state),
  isLoading: getReportDataSourcesSelectSeasonListIsLoading(state),
  isOptionSelected: getReportDataSourcesSeasonIsSelected(state),
  isAllOptionsSelected: getReportDataSourcesHasAllSeasonsSelected(state),
  isArchivedIncluded: getReportDataSourcesSeasonIsArchivedIncluded(state),
  hasArchived: getReportDataSourcesSeasonHasArchived(state)
});

const mapDispatchToProps = (dispatch, { extended, penalties }) => {
  let selectAll = () => dispatch(actions.seasonList.selectAll({ loadDivisions: extended }));
  if (penalties) {
    selectAll = () => dispatch(actions.seasonList.selectAll({ loadPenalties: extended }));
  }

  return {
    clear: () => dispatch(actions.seasonList.clear()),
    selectAll,
    toggleArchivedIncluded: () => dispatch(actions.seasonList.toggleArchivedIncluded())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List);
