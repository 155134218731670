import { createActions } from "redux-actions";

export const { invitationForm: invitationFormActions, invitationsList: invitationsListActions } = createActions({
  INVITATIONS_LIST: {
    CLEAR: undefined
  },
  INVITATION_FORM: {
    ASSOCIATIONS_LIST: {
      OPEN: undefined,
      CLOSE: undefined,
      TOGGLE_OPENED: undefined,
      SELECT_ASSOCIATION: undefined,
      DESELECT_ASSOCIATION: undefined
    },
    LEAGUES_LIST: {
      OPEN: undefined,
      CLOSE: undefined,
      TOGGLE_OPENED: undefined,
      SELECT_ASSOCIATION: undefined,
      SELECT_LEAGUE: undefined,
      DESELECT_LEAGUE: undefined
    },
    SEASONS_LIST: {
      OPEN: undefined,
      CLOSE: undefined,
      TOGGLE_OPENED: undefined,
      SELECT_ASSOCIATION: undefined,
      SELECT_LEAGUE: undefined,
      SELECT_SEASON: undefined,
      DESELECT_SEASON: undefined
    },
    DIVISIONS_LIST: {
      OPEN: undefined,
      CLOSE: undefined,
      TOGGLE_OPENED: undefined,
      SELECT_ASSOCIATION: undefined,
      SELECT_LEAGUE: undefined,
      SELECT_SEASON: undefined,
      SELECT_DIVISION: undefined,
      DESELECT_DIVISION: undefined
    },
    TEAMS_LIST: {
      OPEN: undefined,
      CLOSE: undefined,
      TOGGLE_OPENED: undefined,
      SELECT_ASSOCIATION: undefined,
      SELECT_LEAGUE: undefined,
      SELECT_SEASON: undefined,
      SELECT_DIVISION: undefined,
      SELECT_TEAM: undefined,
      DESELECT_TEAM: undefined
    },
    ATTRIBUTES: {
      CHANGE_ATTRIBUTES: undefined
    },
    SCOPES: {
      ASSOCIATIONS: {
        CHANGE_ACTIONS: undefined,
        CHANGE_GLOBAL_MODE: undefined,
        ADD_ASSOCIATIONS: undefined,
        REMOVE_ASSOCIATION: undefined
      },
      LEAGUES: {
        CHANGE_ACTIONS: undefined,
        CHANGE_GLOBAL_MODE: undefined,
        ADD_LEAGUES: undefined,
        REMOVE_LEAGUE: undefined
      },
      SEASONS: {
        CHANGE_ACTIONS: undefined,
        CHANGE_GLOBAL_MODE: undefined,
        ADD_SEASONS: undefined,
        REMOVE_SEASON: undefined
      },
      DIVISIONS: {
        CHANGE_ACTIONS: undefined,
        CHANGE_GLOBAL_MODE: undefined,
        ADD_DIVISIONS: undefined,
        REMOVE_DIVISION: undefined
      },
      TEAMS: {
        CHANGE_ACTIONS: undefined,
        CHANGE_GLOBAL_MODE: undefined,
        ADD_TEAMS: undefined,
        REMOVE_TEAM: undefined
      },
      PLAYERS: {
        CHANGE_ACTIONS: undefined
      },
      COACHES: {
        CHANGE_ACTIONS: undefined
      },
      REFEREES: {
        CHANGE_ACTIONS: undefined
      },
      GAMES: {
        CHANGE_ACTIONS: undefined
      },
      NOTES: {
        CHANGE_ACTIONS: undefined
      },
      REPORTS: {
        CHANGE_ACTIONS: undefined
      },
      API_KEYS: {
        CHANGE_ACTIONS: undefined
      },
      INVITATIONS: {
        CHANGE_ACTIONS: undefined
      },
      USERS: {
        CHANGE_ACTIONS: undefined
      }
    }
  }
});
