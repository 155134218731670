import React from "react";
import PropTypes from "prop-types";
import { Switch } from "react-router-dom";

import LoggedOutRoute from "@/containers/LoggedOutRoute";
import LoggedInRoute from "@/containers/LoggedInRoute";

import New from "./pages/New";
import Edit from "./pages/Edit";
import Password from "./pages/Password";
import Invitation from "./pages/Invitation";
import Verify from "./pages/AccountVerify";
import EmailNotificationSettings from "./pages/EmailNotificationSettings";
import HandleFirebase from "./pages/HandleFirebase";

const AccountRoute = ({ match: { path } }) => (
  <Switch>
    <LoggedOutRoute path={`${path}/manage`} component={HandleFirebase} />
    <LoggedOutRoute path={`${path}/new`} component={New} />
    <LoggedOutRoute path={`${path}/verify`} component={Verify} />
    <LoggedInRoute path={`${path}/password`} component={Password} />
    <LoggedInRoute path={`${path}/edit`} component={Edit} />
    <LoggedInRoute path={`${path}/invitation`} component={Invitation} />
    <LoggedInRoute path={`${path}/email-notification-settings`} component={EmailNotificationSettings} />
  </Switch>
);

AccountRoute.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired
  }).isRequired
};

export default AccountRoute;
