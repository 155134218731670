import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import { FormGroup, Label } from "reactstrap";

import FormItem from "@/components/FormItem";

import GoalieShots from "../GoalieShots";

class ShotsFieldset extends PureComponent {
  static propTypes = {
    periods: PropTypes.arrayOf(PropTypes.string),
    shots: PropTypes.arrayOf(PropTypes.shape({ goalieId: PropTypes.string.isRequired })),
    setShots: PropTypes.func
  };

  static defaultProps = {
    periods: [],
    shots: [],
    setShots() {}
  };

  render() {
    const { periods, shots, setShots } = this.props;

    return (
      <Fragment>
        <FormItem>
          <FormGroup className="col-md-3">
            <Label>Goalie</Label>
          </FormGroup>

          {periods.map(period => (
            <FormGroup key={period} className="col-md-1">
              <Label>{period.toUpperCase()}</Label>
            </FormGroup>
          ))}
        </FormItem>

        {shots.map(({ goalieId, ...data }) => (
          <GoalieShots key={goalieId} goalieId={goalieId} {...data} onChange={setShots} />
        ))}
      </Fragment>
    );
  }
}

export default ShotsFieldset;
