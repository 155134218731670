import React, { PureComponent } from "react";
// import PropTypes from "prop-types";

import ProtectedLayout from "@/layouts/ProtectedLayout";

import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";

import RefereeReportRefereeList from "@/components/RefereeReportRefereeList";

class SearchPage extends PureComponent {
  render() {
    return (
      <ProtectedLayout subject="reports" action="read">
        <Head title="Referee Reports" />

        <Breadcrumbs items={[{ title: "Reports" }, { title: "Referee Reports", url: "/reports/referee" }]} />

        <TitleBar title="Search Referees" />

        <RefereeReportRefereeList />
      </ProtectedLayout>
    );
  }
}

export default SearchPage;
