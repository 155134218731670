import { createPostService, createGetService, createPatchService, createDeleteService } from "./serviceMaker";

export const createApiKey = createPostService(({ attributes }) => ({
  url: "/api-keys",
  identity: {
    type: "api-keys"
  },
  attributes
}));

export const loadSeasonApiKeys = createGetService(({ seasonId }) => ({
  url: "/api-keys",
  params: {
    filter: {
      season: seasonId
    }
  }
}));

export const loadApiKeys = createGetService(({ associationId }) => ({
  url: "/api-keys",
  params: {
    filter: {
      association: associationId
    }
  }
}));

export const updateApiKey = createPatchService(({ identity: { id }, attributes }) => ({
  url: `/api-keys/${id}`,
  attributes
}));

export const loadApiKey = createGetService(({ id }) => ({
  url: `/api-keys/${id}`,
  params: {
    include: "seasons"
  }
}));

export const deleteApiKey = createDeleteService(({ id }) => ({
  url: `/api-keys/${id}`
}));
