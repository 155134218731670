export default function fixRefereesToRefereesRoster({ games, ...rest }) {
  return {
    ...rest,
    games: Object.entries(games).reduce(
      (
        games,
        [
          id,
          {
            attributes: { referees, ...restAttrs },
            ...rest
          }
        ]
      ) => ({
        ...games,
        [id]: {
          ...rest,
          attributes: { ...restAttrs, refereesRoster: referees }
        }
      }),
      {}
    )
  };
}
