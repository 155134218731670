import { all, takeLatest, put, call } from "redux-saga/effects";
import { push } from "connected-react-router";

import { createApiKey, updateApiKey, deleteApiKey } from "@/lib/api/apiKeys";
import { responseErrorsFullMessages } from "@/lib/api/utils";

import { FIELDS_MAPPING } from "@/components/IpadKeyForm";

import { submittingRoutine, deletingRoutine } from "./routines";

import { gamesheetAPIRequest } from "../api/sagas";

function* submittingSaga({ payload }) {
  const { seasonId, keyId, values } = payload;

  const { value, description, liveScoringScopes, roles } = values;

  yield put(submittingRoutine.request());

  const attributes = {
    value,
    description,
    liveScoringScopes,
    roles
  };

  try {
    const service = keyId ? updateApiKey : createApiKey;

    yield call(
      gamesheetAPIRequest,
      service,
      {
        attributes,
        identity: { type: "api-keys", id: keyId }
      },
      true
    );

    yield put(submittingRoutine.success());
    yield put(push(`/seasons/${seasonId}/ipad-keys`));
  } catch (error) {
    const { response } = error;
    const validationErrors = response ? responseErrorsFullMessages(response, FIELDS_MAPPING) : {};

    yield put(submittingRoutine.failure({ error, validationErrors }));
  } finally {
    yield put(submittingRoutine.fulfill());
  }
}

function* deletingSaga({ payload: { keyId, seasonId } }) {
  yield put(deletingRoutine.request());

  try {
    yield call(gamesheetAPIRequest, deleteApiKey, { id: keyId });

    yield put(deletingRoutine.success());
    yield put(push(`/seasons/${seasonId}/ipad-keys`));
  } catch (e) {
    yield put(deletingRoutine.failure());
  } finally {
    yield put(deletingRoutine.fulfill());
  }
}

export function* ipadKeyFormFlow() {
  yield all([takeLatest(submittingRoutine, submittingSaga), takeLatest(deletingRoutine, deletingSaga)]);
}
