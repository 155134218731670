import { createActions } from "redux-actions";

const { currentSeason: actions } = createActions({
  CURRENT_SEASON: {
    CLEAR: undefined,
    LOCK_ROSTERS: undefined
  }
});

export default actions;
