import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import AbilityLink from "@/components/AbilityLink";

class GameNumber extends PureComponent {
  static propTypes = {
    game: PropTypes.shape({
      id: PropTypes.string.isRequired,
      number: PropTypes.string.isRequired,
      seasonId: PropTypes.string.isRequired
    }).isRequired,
    noLink: PropTypes.bool.isRequired
  };

  render() {
    const { game, noLink } = this.props;
    const { number, id, seasonId } = game;
    const text = number || "None";
    const url = `/seasons/${seasonId}/games/completed/${id}`;

    if (noLink) {
      return text;
    }

    return (
      <AbilityLink subject={{ type: "games", id }} action="read" fallback={text} to={url}>
        {text}
      </AbilityLink>
    );
  }
}

export default GameNumber;
