import { handleActions } from "redux-actions";

import { listLoadingRoutine } from "../routines";
import actions from "../actions";

const initialState = {};

function reduceLoadingSuccess(state, { payload: { teams } }) {
  return teams.reduce((result, team) => {
    const { id, title, association, league, season, division } = team;

    return {
      ...result,
      [id]: {
        title,
        associationId: association.id,
        leagueId: league.id,
        seasonId: season.id,
        divisionId: division.id
      }
    };
  }, state);
}

export default handleActions(
  {
    [listLoadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
