import { handleActions, combineActions } from "redux-actions";

import {
  CurrentDivisionLoadingRoutine as loadingRoutine,
  CurrentDivisionUpdatingRoutine as updatingRoutine,
  CurrentDivisionDeletingRoutine as deletingRoutine
} from "../routines";

const defaultState = {
  isLoaded: false,
  isUpdated: false,
  isDeleted: false,
  isForbidden: false,
  operation: null,
  errors: null,
  id: null
};

const triggerLoading = (state, { payload }) => Object.assign({}, defaultState, { id: payload.id });

const reduce = props => state => Object.assign({}, state, props);

const failure = props => (state, { payload }) =>
  Object.assign({}, state, props, {
    isForbidden: payload.responseStatus === 401,
    errors: payload.errors
  });

const operate = operation => state => Object.assign({}, state, { operation });

const fulfill = state => Object.assign({}, state, { operation: null });

export default handleActions(
  {
    [loadingRoutine.TRIGGER]: triggerLoading,
    [loadingRoutine.REQUEST]: operate("loading"),
    [loadingRoutine.SUCCESS]: reduce({ isLoaded: true }),
    [loadingRoutine.FAILURE]: failure({ isLoaded: false }),
    [updatingRoutine.TRIGGER]: reduce({ isUpdated: false }),
    [updatingRoutine.REQUEST]: operate("updating"),
    [updatingRoutine.SUCCESS]: reduce({ isUpdated: true }),
    [updatingRoutine.FAILURE]: failure({ isUpdated: false }),
    [deletingRoutine.TRIGGER]: reduce({ isDeleted: false }),
    [deletingRoutine.REQUEST]: operate("deleting"),
    [deletingRoutine.SUCCESS]: reduce({ isDeleted: true }),
    [deletingRoutine.FAILURE]: failure({ isDeleted: false }),
    [combineActions(loadingRoutine.FULFILL, updatingRoutine.FULFILL, deletingRoutine.FULFILL)]: fulfill
  },
  defaultState
);
