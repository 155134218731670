import { handleActions } from "redux-actions";

import { loadGamesRoutine } from "../../routines";
import { gamesActions } from "../../actions";

const initialState = {};

function calculateShootoutGoals(attempts) {
  return (attempts || []).filter(({ result }) => result === "goal").length;
}

function calculateTeamShootoutScore(teamAttempts, oppositeTeamAttempts) {
  const teamShootoutGoals = calculateShootoutGoals(teamAttempts);
  const oppositeTeamGoals = calculateShootoutGoals(oppositeTeamAttempts);

  return teamShootoutGoals > oppositeTeamGoals ? 1 : 0;
}

const reduceGame = ({
  visitor,
  home,
  startTime,
  number,
  location,
  visitorGoals,
  homeGoals,
  season,
  flags,
  coaches,
  players,
  notes,
  visitorShootoutAttempts,
  homeShootoutAttempts
}) => ({
  visitor: {
    title: visitor.title,
    division: (visitor.division || {}).title,
    score: (visitorGoals || []).length + calculateTeamShootoutScore(visitorShootoutAttempts, homeShootoutAttempts)
  },
  home: {
    title: home.title,
    division: (home.division || {}).title,
    score: (homeGoals || []).length + calculateTeamShootoutScore(homeShootoutAttempts, visitorShootoutAttempts)
  },
  seasonId: season.id,
  startTime,
  number,
  location,
  countNotes: (notes || []).length,
  flags:
    (flags &&
      flags.map(({ event, code, member, team }) => {
        if (member !== undefined) {
          const { type: memberType, id: memberId } = member;
          switch (memberType) {
            case "coaches":
              member = coaches.find(({ id }) => id === memberId );
              break;
            case "players":
              member = players.find(({ id }) => id === memberId );
              break;
            default:
              break;
          }

          member = member ? [member.firstName, member.lastName].join(" ") : "N/A";
          member = member === " " ? (memberType === "players" ? "Player" : "Coach") : member;

        }

        if (team !== undefined) {
          const { id: teamId } = team;

          team = ([home, visitor].find(({ id }) => id === teamId) || { title: "N/A" }).title;
        }

        return {
          event,
          code,
          member,
          team
        };
      })) ||
    []
});

function reduceLoadingSuccess(state, { payload }) {
  const games = payload.games || [];

  const data = games.reduce((result, game) => Object.assign({}, result, { [game.id]: reduceGame(game) }), {});

  return Object.assign({}, state, data);
}

const clearData = () => ({ ...initialState });

export default handleActions(
  {
    [loadGamesRoutine.SUCCESS]: reduceLoadingSuccess,
    [gamesActions.list.clear]: clearData
  },
  initialState
);
