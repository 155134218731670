import { handleActions, combineActions } from "redux-actions";

import { submittingRoutine, deletingRoutine } from "@/redux/associationForm";

import { loadingRoutine } from "./routines";
import actions from "./actions";

const initialState = {
  isLoading: false,
  isLoaded: false,
  isForbidden: false,
  isFailed: false,
  ids: [],
  data: {},
  totalCount: 0
};

function reduceLoadingRequest(state) {
  return { ...state, isLoading: true };
}

function reduceLoadingSuccess(state, { payload }) {
  const { associations, ids, totalCount } = payload;

  const data = associations.reduce((result, { id, title }) => ({ ...result, [id]: { title } }), {});

  return { ...state, isLoaded: true, data, ids, totalCount };
}

function reduceLoadingFailure(state, { payload: { responseStatus } }) {
  return { ...state, isFailed: true, isForbidden: responseStatus === 401 };
}

function reduceLoadingFulfill(state) {
  return { ...state, isLoading: false };
}

export default handleActions(
  {
    [loadingRoutine.REQUEST]: reduceLoadingRequest,
    [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [loadingRoutine.FAILURE]: reduceLoadingFailure,
    [loadingRoutine.FULFILL]: reduceLoadingFulfill,
    [combineActions(actions.clear, submittingRoutine.SUCCESS, deletingRoutine.SUCCESS)]: () => ({ ...initialState })
  },
  initialState
);
