import { handleActions } from "redux-actions";

import { loadingRoutine } from "../routines";
import actions from "../actions";

function reduceLoadingSuccess(state, { payload: { teams } }) {
  return teams.reduce(
    (result, { division: { id, title } }) => (result.find(d => d.id === id) ? result : [...result, { id, title }]),
    []
  );
}

export default handleActions(
  {
    [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [actions.clear]: () => []
  },
  []
);
