import React from "react";
import PropTypes from "prop-types";

import MultiSelectField from "@/components/MultiSelectField";

import useDivisionsList from "./hooks/useDivisionsList";

function Field(props) {
  const {
    size,
    id,
    name,
    onChange,
    value,
    readyMsg,
    emptyMsg,
    loadingMsg,
    pendingMsg,
    multipleMsg,
    disabledMsg,
    disabled
  } = props;

  const { isListLoaded, isListLoading, divisions } = useDivisionsList();

  return (
    <MultiSelectField
      size={size}
      id={id}
      name={name}
      isListLoaded={isListLoaded}
      isListLoading={isListLoading}
      options={divisions.map(({ id, title }) => ({
        value: id,
        label: title
      }))}
      onChange={onChange}
      readyMsg={readyMsg}
      emptyMsg={emptyMsg}
      loadingMsg={loadingMsg}
      pendingMsg={pendingMsg}
      multipleMsg={multipleMsg}
      disabledMsg={disabledMsg}
      disabled={disabled}
      value={value}
    />
  );
}

Field.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  size: PropTypes.string,
  readyMsg: PropTypes.string,
  emptyMsg: PropTypes.string,
  loadingMsg: PropTypes.string,
  pendingMsg: PropTypes.string,
  multipleMsg: PropTypes.string,
  disabledMsg: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

Field.defaultProps = {
  value: [],
  size: "md",
  readyMsg: "Select divisions",
  emptyMsg: "No divisions available",
  loadingMsg: "Loading divisions…",
  pendingMsg: "Pending divisions…",
  multipleMsg: "Multiple divisions",
  disabledMsg: "Divisions",
  disabled: false
};

export default Field;
