import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import GamesheetHistoryRecordsList from "../RecordsList";

class GamesheetHistoryModal extends PureComponent {
  static propTypes = {
    gameId: PropTypes.string.isRequired,
    seasonId: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    isWorking: PropTypes.bool,
    close: PropTypes.func
  };

  static defaultProps = {
    isOpen: false,
    isWorking: false,
    close() {}
  };

  handleClose = () => {
    const { isWorking, close } = this.props;

    if (isWorking) {
      return;
    }

    close();
  };

  render() {
    const { isOpen, gameId, seasonId } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={this.handleClose} size="lg" centered>
        <ModalHeader toggle={this.handleClose}>History</ModalHeader>

        <ModalBody>
          <GamesheetHistoryRecordsList seasonId={seasonId} gameId={gameId} />
        </ModalBody>
      </Modal>
    );
  }
}

export default GamesheetHistoryModal;
