// https://www.ag-grid.com/javascript-data-grid/component-header/

export const getAscName = baseId => {
  return baseId;
};

export const getDescName = baseId => {
  return "-" + baseId;
};

export const getNoneName = () => {
  return "none";
};

export const getCustomHeader = (setSort, currentSort, colId) => {
  return class CustomHeader {
    init(agParams) {
      this.agParams = agParams;
      this.eGui = document.createElement("div");
      this.eGui.className = "ag-cell-label-container";

      let inner = `
                    <div class="ag-header-cell-label" role="presentation" unselectable="on">
                        <span class="ag-header-cell-text" unselectable="on">${this.agParams.displayName}</span>
                `;
      if (currentSort === getAscName(colId)) {
        inner += `
                        <span class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"><span class="ag-icon ag-icon-asc" unselectable="on" role="presentation"></span></span>
                    </div>
                `;
      } else if (currentSort === getDescName(colId)) {
        inner += `
                        <span class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"><span class="ag-icon ag-icon-desc" unselectable="on" role="presentation"></span></span>
                    </div>
                `;
      } else {
        inner += `
                    </div>
                `;
      }

      this.eGui.innerHTML = inner;

      this.onClickEventListener = () => {
        switch (currentSort) {
          case getAscName(colId):
            setSort(getNoneName());
            return;
          case getDescName(colId):
            setSort(getAscName(colId));
            return;
          case getNoneName():
          default:
            setSort(getDescName(colId));
            return;
        }
      };

      if (this.agParams.enableSorting) {
        this.eGui.addEventListener("click", this.onClickEventListener);
      }
    }

    onSortChanged() {}

    getGui() {
      return this.eGui;
    }

    onSortRequested() {}

    destroy() {
      this.eGui.removeEventListener("click", this.onClickEventListener);
    }
  };
};
