import React from "react";
import PropTypes from "prop-types";

import { FormGroup, CustomInput } from "reactstrap";

import FormSection from "@/components/FormSection";
import FormItem from "@/components/FormItem";

import { useLiveScoringScopesInput } from "./hooks";

function LiveScoringScopesInput({ value, setValue }) {
  const { read, write } = useLiveScoringScopesInput({ value, setValue });

  return (
    <FormSection title="Live Scoring">
      <FormItem>
        <FormGroup className="col">
          <CustomInput
            id={`$live_scoring_scopes__read__toggle__input`}
            type="switch"
            name="read"
            size="l"
            label={
              <span>
                Allow this key to <strong>read</strong> the Live Scoring data
              </span>
            }
            {...read}
          />
        </FormGroup>

        <FormGroup className="col">
          <CustomInput
            id={`$live_scoring_scopes__write__toggle__input`}
            type="switch"
            name="write"
            size="l"
            label={
              <span>
                Allow this key to <strong>write</strong> the Live Scoring data
              </span>
            }
            {...write}
          />
        </FormGroup>
      </FormItem>
    </FormSection>
  );
}

LiveScoringScopesInput.propTypes = {
  value: PropTypes.arrayOf(PropTypes.oneOf(["read", "write"]).isRequired).isRequired,
  setValue: PropTypes.func.isRequired
};

export default LiveScoringScopesInput;
