import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./FormItem.scss";

const FormItem = ({ className, children, ...rest }) => {
  return (
    <div className={classNames("form-item", "form-row", className)} {...rest}>
      {children}
    </div>
  );
};

FormItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

FormItem.defaultProps = {
  className: ""
};

FormItem.formRole = "item";

export default FormItem;
