import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { actions } from "@/redux/teamRoster";

export default function useAddingEmptyCoach() {
  const dispatch = useDispatch();
  const addEmptyCoach = useCallback(() => dispatch(actions.appendEmptyCoach()), [dispatch]);

  return addEmptyCoach;
}
