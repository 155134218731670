import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { Button } from "reactstrap";
import { FilePicker } from "react-file-picker";

import { usePhotoFile, usePhotoFileLoader } from "../hooks";

import Editor from "./Editor";

import "./Field.scss";

const DIMS = {
  minWidth: 1,
  minHeight: 1,
  maxWidth: 10000,
  maxHeight: 10000,
};

function Field({ initialValue, value, setValue, isDirty }) {
  const onChange = useCallback((file) => setValue(file || ""), [setValue]);

  const { file, changeFile, clearFile, fileError, setFileError } =
    usePhotoFile(onChange);

  const loadFile = usePhotoFileLoader({
    changeFile,
    onError: setFileError,
    dims: DIMS,
  });

  let url = isDirty ? "" : initialValue;
  url = url.includes("imagedelivery.net") ? `${url}/256` : url;

  return (
    <div className="player-photo-field">
      {file ? (
        <div>
          <Editor file={file} onChange={onChange} />
        </div>
      ) : (
        <div>
          {url !== "" ? (
            <div className="player-photo-field__preview">
              <img src={url} alt="Player" />
            </div>
          ) : (
            <div className="player-photo-field__placeholder" />
          )}
        </div>
      )}
      <div>
        <div className="d-flex flex-row player-photo-field__buttons mt-3">
          <FilePicker
            onChange={loadFile}
            onError={setFileError}
            dims={DIMS}
            extensions={["jpg", "jpeg", "png", "webp", "svg"]}
            maxSize={10}
          >
            <Button
              className="player-photo-field__button"
              type="button"
              size="sm"
              color="primary"
              outline
            >
              {url === "" && !file ? "Select Photo" : "Change Photo"}
            </Button>
          </FilePicker>
          {(file || (url && url !== "")) && (
            <Button
              className="player-photo-field__button ml-2"
              type="button"
              size="sm"
              color="danger"
              outline
              onClick={clearFile}
            >
              Remove Photo
            </Button>
          )}
        </div>
        <p className="player-photo-field__text mt-3">
          Supported formats are PNG, JPEG, WebP, and SVG. The maximum file
          size is 10 MB.
        </p>
        {fileError && <p className="text-danger mb-0">{fileError}</p>}
      </div>
    </div>
  );
}

Field.propTypes = {
  initialValue: PropTypes.any,
  value: PropTypes.any,
  isDirty: PropTypes.bool,
  setValue: PropTypes.func,
};

export default Field;
