import React from "react";
import PropTypes from "prop-types";

import TeamMemberPenaltyReportGameLink from "../GameLink";

function Penalty({ game, code, count, label }) {
  const infraction = label
    ? `${label} (${code})`
    : code;

  return (
    <tr>
      <td>{game.date}</td>
      <td>
        <TeamMemberPenaltyReportGameLink {...game} />
      </td>
      <td>{infraction}</td>
      <td>{count}</td>
    </tr>
  );
}

Penalty.propTypes = {
  game: PropTypes.shape({ date: PropTypes.string.isRequired }).isRequired,
  code: PropTypes.string,
  count: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

Penalty.defaultProps = {
  code: ""
};

export default Penalty;
