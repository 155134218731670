import { handleActions, combineActions } from "redux-actions";

import { teamsLoadingRoutine } from "../routines";
import actions from "../actions";

function updateDivisionTeams(state, divisionId, data) {
  return {
    ...state,
    data: {
      ...state.data,
      [divisionId]: {
        ...state.data[divisionId],
        ...data
      }
    }
  };
}

const initialState = {
  data: {},
  selectedIds: []
};

function reduceLoadingRequest(state, { payload: { divisionId } }) {
  return updateDivisionTeams(state, divisionId, { isLoading: true });
}

function reduceLoadingSuccess(state, { payload: { divisionId, teams } }) {
  return updateDivisionTeams(state, divisionId, {
    teams: teams.map(({ id, title }) => ({ id, title })),
    isLoaded: true
  });
}

function reduceLoadingFailure(state, { payload: { divisionId } }) {
  return updateDivisionTeams(state, divisionId, { isLoaded: false });
}

function reduceLoadingFulfill(state, { payload: { divisionId } }) {
  return updateDivisionTeams(state, divisionId, { isLoading: false });
}

function reduceSelected(state, { payload: { id } }) {
  return {
    ...state,
    selectedIds: [...state.selectedIds, id]
  };
}

function reduceDeselected(state, { payload: { id } }) {
  return {
    ...state,
    selectedIds: state.selectedIds.filter(selectedId => selectedId !== id)
  };
}

function reduceTeamsClearing(state) {
  return { ...state, selectedIds: [] };
}

function reduceDivisionSelected(state, { payload: { id: divisionId } }) {
  return {
    ...state,
    data: {
      ...state.data,
      [divisionId]: {
        isLoaded: false,
        isLoading: false,
        teams: [],
        ...state.data[divisionId]
      }
    }
  };
}

function reduceDivisionDeselected(state, { payload: { id: divisionId } }) {
  const divisionTeamIds = state.data[divisionId].teams.map(({ id }) => id);

  return {
    ...state,
    selectedIds: state.selectedIds.filter(teamId => !divisionTeamIds.includes(teamId))
  };
}

function reduceClearing(state) {
  return { ...initialState };
}

export default handleActions(
  {
    [teamsLoadingRoutine.REQUEST]: reduceLoadingRequest,
    [teamsLoadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [teamsLoadingRoutine.FAILURE]: reduceLoadingFailure,
    [teamsLoadingRoutine.FULFILL]: reduceLoadingFulfill,
    [combineActions(
      actions.associationList.select,
      actions.associationList.deselect,
      actions.associationList.clear,
      actions.leagueList.select,
      actions.leagueList.deselect,
      actions.leagueList.clear,
      actions.seasonList.select,
      actions.seasonList.deselect,
      actions.seasonList.clear,
      actions.divisionList.clear
    )]: reduceTeamsClearing,
    [actions.divisionList.select]: reduceDivisionSelected,
    [actions.divisionList.deselect]: reduceDivisionDeselected,
    [actions.teamList.select]: reduceSelected,
    [actions.teamList.deselect]: reduceDeselected,
    [actions.teamList.clear]: reduceTeamsClearing,
    [actions.clear]: reduceClearing
  },
  initialState
);
