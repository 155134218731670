import { useEffect } from "react";
import { useDispatch } from "react-redux";

import actions from "@/redux/userFilter/actions";

export default function useUserFilterClearing() {
  const dispatch = useDispatch();

  useEffect(
    () => {
      return () => dispatch(actions.clear());
    },
    [dispatch]
  );
}
