import { handleActions } from "redux-actions";

import { loadGamesheetRoutine } from "../../routines";
import { clearGamesheet } from "../../actions";
import teamReducer from "../../utils/teamReducer";

const initialState = {
  id: "",
  title: "",
  playersIds: [],
  coachesIds: [],
  goals: [],
  penalties: [],
  division: "",
  suspensions: {},
  fairPlay: false
};

export function createTeamReducer(teamType) {
  return handleActions(
    {
      [loadGamesheetRoutine.SUCCESS]: teamReducer(teamType),
      [clearGamesheet]: () => initialState
    },
    initialState
  );
}
