import { all, put, select, take, actionChannel } from "redux-saga/effects";
import { buffers } from "redux-saga";

export default function createListImportingSaga(options) {
  const { listRoutine, rowRoutine, selector } = options;

  return function*({ payload: { seasonId } }) {
    yield put(listRoutine.request());

    const rows = yield select(selector);

    const fulfillChannel = yield actionChannel(rowRoutine.FULFILL, buffers.dropping(rows.length));

    yield all(rows.map(row => put(rowRoutine.trigger({ ...row, seasonId }))));
    yield all(rows.map(() => take(fulfillChannel)));

    yield put(listRoutine.success());
    yield put(listRoutine.fulfill());
  };
}
