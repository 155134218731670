import _isEqual from "lodash/isEqual";
import _get from "lodash/get";
import { handleActions } from "redux-actions";

import actions from "../actions";
import { loadingRoutine, roleLevelLoadingRoutine } from "../routines";
import _capitalize from "lodash/capitalize";

function getFailureCode(error) {
  const responseStatus = _get(error, "response.status");
  const errorObject = _get(error, "response.data.errors[0]", {
    source: { pointer: null },
    code: null
  });

  if (responseStatus === 403 && _get(errorObject, "source.pointer") === "/" && errorObject.code === "archived") {
    return "archived";
  } else if (responseStatus === 401) {
    return "unauthorized";
  } else if (responseStatus === 404) {
    return "notFound";
  }
}

function reduceAdding(state, { payload: { role, resourceTitle, additionalRoles = [] } }) {
  let newRoles; // Determine new roles based on conditions
  const existingRoles = state;
  const roleExistsInExistingRoles = existingRoles.some(existingRole => existingRole.title === role.title && _isEqual(existingRole.level, role.level)); // Check if existingRoles already contains role

  // existingRoles has role -> do not add role
  if (roleExistsInExistingRoles) { 
    alert(`The role "${_capitalize(role.title)}, ${_capitalize(role.level.type.replace(/s$/, ""))}: ${resourceTitle}" already exists.`);
    newRoles = state;
  } else {
    newRoles = [
      ...state,
      {
        ...role,
        resourceTitle,
        isLoading: false,
        isLoaded: true,
        isFailed: false,
        failureCode: ""
      }
    ];
  }
  return newRoles;
}

function reduceChanging(state, { payload: { initRole, role, resourceTitle, additionalRoles = [] } }) {
  let newRoles; // Determine new roles based on conditions
  const existingRoles = state;
  const isRoleEqualToInitRole = role.title === initRole.title && _isEqual(role.level, initRole.level); // Check if role is equal to initRole based only on title and level
  const roleExistsInExistingRoles = existingRoles.some(existingRole => existingRole.title === role.title && _isEqual(existingRole.level, role.level)); // Check if existingRoles already contains role
  const initRoleExistsInAdditionalRoles = additionalRoles.some(additionalRole => additionalRole.title === initRole.title && _isEqual(additionalRole.level, initRole.level)); // Check if additionalRoles contains initRole
  
  // role == initRole & additionalRoles has initRole -> do not add role
  if (isRoleEqualToInitRole && initRoleExistsInAdditionalRoles) { 
    alert(`The role "${_capitalize(role.title)}, ${_capitalize(role.level.type.replace(/s$/, ""))}: ${resourceTitle}" already exists.`);
    newRoles = state;
  } 
  // role != initRole & existingRoles has role -> do not add role
  else if (!isRoleEqualToInitRole && roleExistsInExistingRoles) { 
    alert(`The role "${_capitalize(role.title)}, ${_capitalize(role.level.type.replace(/s$/, ""))}: ${resourceTitle}" already exists.`);
    newRoles = state;
  } 
  // role != initRole & additionalRoles does not have initRole -> replace initRole with role
  else if (!isRoleEqualToInitRole && !initRoleExistsInAdditionalRoles) { 
    newRoles = [
      ...state.filter(({ title, level }) => !(title === initRole.title && _isEqual(level, initRole.level))),
      {
        ...role,
        resourceTitle,
        isLoading: false,
        isLoaded: true,
        isFailed: false,
        failureCode: ""
      }
    ];
  } 
  // role != initRole & additionalRoles has initRole & existingRoles does not have role -> add role
  else if (!isRoleEqualToInitRole && initRoleExistsInAdditionalRoles && !roleExistsInExistingRoles) { 
    newRoles = [
      ...state,
      {
        ...role,
        resourceTitle,
        isLoading: false,
        isLoaded: true,
        isFailed: false,
        failureCode: ""
      }
    ];
  } 
  // if none of the above conditions are met
  else { 
    newRoles = state;
  }
  return newRoles;
}

function reduceRemoving(state, { payload: role }) {
  return state.filter(({ title, level }) => title !== role.title || !_isEqual(level, role.level));
}

function reduceFormLoading(
  state,
  {
    payload: {
      user: { roles }
    }
  }
) {
  return [
    ...(roles || []).map(role => ({
      ...role,
      resourceTitle: "",
      isLoaded: false,
      isLoading: false,
      isFailed: false,
      failureCode: ""
    }))
  ];
}

function reduceLoadingRequest(state, { payload: { type, id } }) {
  return state.map(({ level, ...rest }) => {
    if (level.type === type && level.id === id) {
      return { ...rest, level, isLoading: true };
    }

    return { level, ...rest };
  });
}

function reduceLoadingSuccess(state, { payload: { type, id, title } }) {
  return state.map(({ level, ...rest }) => {
    if (level.type === type && level.id === id) {
      return { ...rest, level, isLoaded: true, resourceTitle: title };
    }

    return { level, ...rest };
  });
}

function reduceLoadingFailure(state, { payload: { type, id, status, error, failureCode = "" } }) {
  return state.map(({ level, ...rest }) => {
    if (level.type === type && level.id === id) {
      return {
        ...rest,
        level,
        failureCode: failureCode || getFailureCode(error),
        isFailed: true
      };
    }

    return { level, ...rest };
  });
}

function reduceLoadingFulfill(state, { payload: { type, id } }) {
  return state.map(({ level, ...rest }) => {
    if (level.type === type && level.id === id) {
      return { ...rest, level, isLoading: false };
    }

    return { level, ...rest };
  });
}

export default handleActions(
  {
    [actions.addRole]: reduceAdding,
    [actions.changeRole]: reduceChanging,
    [actions.removeRole]: reduceRemoving,
    [loadingRoutine.SUCCESS]: reduceFormLoading,
    [roleLevelLoadingRoutine.REQUEST]: reduceLoadingRequest,
    [roleLevelLoadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [roleLevelLoadingRoutine.FAILURE]: reduceLoadingFailure,
    [roleLevelLoadingRoutine.FULFILL]: reduceLoadingFulfill,
    [actions.reset]: () => []
  },
  []
);
