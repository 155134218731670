import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Table } from "reactstrap";

import PageLoader from "@/components/PageLoader";
import GamesheetHistoryRecord from "@/components/Gamesheet/History/Record";

class RecordsList extends PureComponent {
  static propTypes = {
    gameId: PropTypes.string.isRequired,
    seasonId: PropTypes.string.isRequired,
    records: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })),
    isLoaded: PropTypes.bool,
    isWorking: PropTypes.bool,
    viewPDF: PropTypes.func,
    downloadPDF: PropTypes.func,
    loadRecords: PropTypes.func
  };

  static defaultProps = {
    records: [],
    isLoaded: false,
    isWorking: false,
    viewPDF() {},
    downloadPDF() {},
    loadRecords() {}
  };

  componentDidMount() {
    const { seasonId, gameId } = this.props;

    this.props.loadRecords({ seasonId, gameId });
  }

  render() {
    const { isLoaded, isWorking, records, viewPDF, downloadPDF } = this.props;

    return (
      <PageLoader isLoading={isWorking} isLoaded={isLoaded}>
        <Table striped borderless>
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Modified by</th>
              <th scope="col">PDF</th>
            </tr>
          </thead>
          <tbody>
            {records.map(({ id, ...rest }) => (
              <GamesheetHistoryRecord
                key={id}
                id={id}
                {...rest}
                viewPDF={viewPDF}
                downloadPDF={downloadPDF}
                disabled={isWorking}
              />
            ))}
          </tbody>
        </Table>
      </PageLoader>
    );
  }
}

export default RecordsList;
