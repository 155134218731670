import React, { useContext } from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

import "./Breadcrumbs.scss";
import CurrentDisplaySettings from "@/contexts/CurrentDisplaySettingsContext/Context";

const Breadcrumbs = ({ items, lastItem, lastItemNoUrl }) => {
  
  const { breadcrumbs } = useContext(CurrentDisplaySettings);
  
  if (!items || !breadcrumbs ) {
    return (
      <div className="breadcrumbs">
        <span>&nbsp;</span>
      </div>
    );
  }

  const crumbs = [...items];

  if (lastItem) {
    crumbs.push(lastItem);
  }

  return (
    <div className="breadcrumbs">
      <Breadcrumb>
        {crumbs.map((item, index) => {
          const { url, title } = typeof item === "object" ? item : { title: item };
          const isLastItem = index + 1 === crumbs.length;

          return (
            <BreadcrumbItem key={url ? `${url}-${title}` : title} active={isLastItem}>
              {(url && !isLastItem) || (url && isLastItem && !lastItemNoUrl) ? <Link to={url}>{title}</Link> : title}
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    </div>
  );
};

const itemProp = PropTypes.shape({
  title: PropTypes.string.isRequired,
  url: PropTypes.string
});

const BreadcrumbsProps = PropTypes.arrayOf(itemProp);

Breadcrumbs.propTypes = {
  items: BreadcrumbsProps,
  lastItem: PropTypes.oneOfType([itemProp, PropTypes.string]),
  lastItemNoUrl: PropTypes.bool
};

Breadcrumbs.defaultProps = {
  items: null,
  lastItem: null,
  lastItemNoUrl: false
};

export default Breadcrumbs;
export { BreadcrumbsProps };
