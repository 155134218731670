import {
  createGetService,
  createPostService,
  createPatchService,
  createDeleteService,
  createPutService
} from "./serviceMaker";

export const loadSeasons = createGetService(({ associationId, leagueId, filter, include }) => ({
  url: associationId && leagueId ? `/associations/${associationId}/leagues/${leagueId}/seasons` : "/seasons",
  params: {
    filter,
    include
  }
}));

export const createSeason = createPostService(({ associationId, leagueId, attributes }) => ({
  url: `/associations/${associationId}/leagues/${leagueId}/seasons`,
  relationships: {
    association: { data: { type: "associations", id: associationId } },
    league: { data: { type: "leagues", id: leagueId } }
  },
  attributes
}));

export const loadSeason = createGetService(({ identity, include }) => ({
  url: `/seasons/${identity.id}`,
  params: { include }
}));

export const updateSeason = createPatchService(({ identity, associationId, leagueId, attributes }) => ({
  url: `/associations/${associationId}/leagues/${leagueId}/seasons/${identity.id}`,
  relationships: {
    association: { data: { type: "associations", id: associationId } },
    league: { data: { type: "leagues", id: leagueId } }
  },
  attributes
}));

export const updateSeasonRosterLock = createPatchService(({ seasonId, attributes }) => ({
  url: `/seasons/${seasonId}/roster-lock`,
  relationships: {},
  attributes
}));

export const deleteSeason = createDeleteService(({ identity, associationId, leagueId }) => ({
  url: `/associations/${associationId}/leagues/${leagueId}/seasons/${identity.id}`
}));

export const archiveSeason = createPutService(({ seasonId }) => ({
  url: `/archived/seasons/${seasonId}`
}));

export const unarchiveSeason = createPutService(({ seasonId }) => ({
  url: `/activate/seasons/${seasonId}`
}));
