import { handleActions, combineActions } from "redux-actions";

import { divisionsLoadingRoutine } from "../routines";
import actions from "../actions";

function updateSeasonDivisions(state, seasonId, data) {
  return {
    ...state,
    data: {
      ...state.data,
      [seasonId]: {
        ...state.data[seasonId],
        ...data
      }
    }
  };
}

const initialState = {
  data: {},
  selectedIds: []
};

function reduceLoadingRequest(state, { payload: { seasonId } }) {
  return updateSeasonDivisions(state, seasonId, { isLoading: true });
}

function reduceLoadingSuccess(state, { payload: { seasonId, divisions } }) {
  return updateSeasonDivisions(state, seasonId, {
    divisions: divisions.map(({ id, title }) => ({ id, title })),
    isLoaded: true
  });
}

function reduceLoadingFailure(state, { payload: { seasonId } }) {
  return updateSeasonDivisions(state, seasonId, { isLoaded: false });
}

function reduceLoadingFulfill(state, { payload: { seasonId } }) {
  return updateSeasonDivisions(state, seasonId, { isLoading: false });
}

function reduceSelected(state, { payload: { id, singleSelect = false } }) {
  return {
    ...state,
    selectedIds: [...(singleSelect ? [] : state.selectedIds), id]
  };
}

function reduceDeselected(state, { payload: { id } }) {
  return {
    ...state,
    selectedIds: state.selectedIds.filter(selectedId => selectedId !== id)
  };
}

function reduceDivisionsClearing(state) {
  return { ...state, selectedIds: [] };
}

function reduceSeasonSelected(state, { payload: { id: seasonId } }) {
  return {
    ...state,
    data: {
      ...state.data,
      [seasonId]: {
        isLoaded: false,
        isLoading: false,
        divisions: [],
        ...state.data[seasonId]
      }
    }
  };
}

function reduceClearing(state) {
  return { ...initialState };
}

export default handleActions(
  {
    [divisionsLoadingRoutine.REQUEST]: reduceLoadingRequest,
    [divisionsLoadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [divisionsLoadingRoutine.FAILURE]: reduceLoadingFailure,
    [divisionsLoadingRoutine.FULFILL]: reduceLoadingFulfill,
    [combineActions(
      actions.associationList.select,
      actions.associationList.deselect,
      actions.associationList.clear,
      actions.leagueList.select,
      actions.leagueList.deselect,
      actions.leagueList.clear,
      actions.seasonList.deselect,
      actions.seasonList.clear,
      actions.divisionList.clear
    )]: reduceDivisionsClearing,
    [actions.divisionList.select]: reduceSelected,
    [actions.divisionList.deselect]: reduceDeselected,
    [actions.seasonList.select]: reduceSeasonSelected,
    [actions.clear]: reduceClearing
  },
  initialState
);
