import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import { actions, getFilterIsDirty } from "@/redux/completedGamesFilter";

export default function useReset() {
  const { seasonId } = useCurrentSeasonContext();
  const dispatch = useDispatch();

  const reset = useCallback(() => dispatch(actions.reset({ seasonId })), [dispatch, seasonId]);

  const isFilterDirty = useSelector(getFilterIsDirty);

  return {
    reset,
    isFilterDirty
  };
}
