import React from "react";

import { Link } from "react-router-dom";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";
import AbilityButton from "@/components/AbilityButton";
import InvitationList from "@/components/InvitationList";
import InvitationFilter from "@/components/InvitationFilter";

function InvitationsIndex() {
  return (
    <ProtectedLayout className="invitations-page" subject={{ type: "invitations" }} action="read">
      <Head title="Invitations" />

      <Breadcrumbs items={[{ title: "Invitations", url: "/invitations" }]} />

      <TitleBar title="Invitations">
        <AbilityButton
          subject="invitations"
          action="create"
          outline
          color="success"
          size="sm"
          tag={Link}
          to={`/invitations/new`}
        >
          + New Invitation
        </AbilityButton>
      </TitleBar>

      <InvitationFilter className="mb-3" />
      <InvitationList />
    </ProtectedLayout>
  );
}

export default InvitationsIndex;
