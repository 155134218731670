import { useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { penaltyAccumulationReportLoadingRoutine } from "@/redux/penaltyAccumulationReport/routines";
import actions from "@/redux/penaltyAccumulationReport/actions";

import {
  getPenaltyAccumulationReportIsLoaded,
  getPenaltyAccumulationReportIsForbidden,
  getPenaltyAccumulationReportData,
  getPenaltyAccumulationReportSeason
} from "@/redux/penaltyAccumulationReport/selectors";

export default function usePenaltyAccumulationReport(search) {
  const dispatch = useDispatch();
  const isLoaded = useSelector(getPenaltyAccumulationReportIsLoaded);
  const isForbidden = useSelector(getPenaltyAccumulationReportIsForbidden);
  const data = useSelector(getPenaltyAccumulationReportData);
  const season = useSelector(getPenaltyAccumulationReportSeason);

  const searchParams = new URLSearchParams(search);
  const seasonIdParam = searchParams.get("season_id");
  const penaltyCodesParam = searchParams.get("penalty_codes");

  const seasonId = useMemo(() => seasonIdParam, [seasonIdParam]);
  const penaltyCodes = useMemo(() => (penaltyCodesParam && penaltyCodesParam.split(",")) || [], [penaltyCodesParam]);

  useEffect(
    () => {
      dispatch(penaltyAccumulationReportLoadingRoutine.trigger({ seasonId, penaltyCodes }));
      return () => dispatch(actions.report.clear());
    },
    [dispatch, seasonId]
  );

  return { isLoaded, isForbidden, seasonId, penaltyCodes, data, season };
}
