import React from "react";
import PropTypes from "prop-types";

import { Switch } from "react-router-dom";

import useCurrentAssociation from "@/hooks/useCurrentAssociation";
import useCurrentAssociationClearing from "@/hooks/useCurrentAssociationClearing";
import CurrentAssociationContext from "@/contexts/CurrentAssociationContext";
import LoggedInRoute from "@/containers/LoggedInRoute";

import LeaguesIndex from "./pages/LeaguesIndex";
import NewLeague from "./pages/NewLeague";
import EditAssociation from "./pages/EditAssociation";

import { useLeaguesListClearing } from "@/components/LeaguesList";

function AssociationScope({ match: { url, params } }) {
  const { associationId } = params;

  const currentAssociation = useCurrentAssociation(associationId);

  useCurrentAssociationClearing();
  useLeaguesListClearing();

  return (
    <CurrentAssociationContext.Provider value={{ ...currentAssociation, associationId }}>
      <Switch>
        <LoggedInRoute path={`${url}/leagues/new`} component={NewLeague} />
        <LoggedInRoute path={`${url}/leagues`} component={LeaguesIndex} />
        <LoggedInRoute path={`${url}/edit`} component={EditAssociation} />
      </Switch>
    </CurrentAssociationContext.Provider>
  );
}

AssociationScope.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      associationId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default AssociationScope;
