import { useSelector } from "react-redux";

import {
  getIsLoaded,
  getIsLoading,
  getIsForbidden,
  getIsFailed,
  getActiveList,
  getActiveTotalCount,
  getArchivedTotalCount
} from "@/redux/seasonsList";

export default function useSeasonsList() {
  const isListLoaded = useSelector(getIsLoaded);
  const isListLoading = useSelector(getIsLoading);
  const isListForbidden = useSelector(getIsForbidden);
  const isListFailed = useSelector(getIsFailed);
  const seasons = useSelector(getActiveList);
  const totalCount = useSelector(getActiveTotalCount);
  const archivedTotalCount = useSelector(getArchivedTotalCount);

  return {
    isListLoaded,
    isListLoading,
    isListForbidden,
    isListFailed,
    seasons,
    totalCount,
    archivedTotalCount
  };
}
