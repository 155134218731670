import React from "react";
import PropTypes from "prop-types";

import { Input, Button } from "reactstrap";

function CategoryField({ value, onChange, removeCategory }) {
  return (
    <tr>
      <td>
        <Input type="text" value={value} bsSize="sm" onChange={onChange} />
      </td>
      <td>
        <Button className="delete-button" size="sm" color="danger" onClick={removeCategory} outline>
          Delete
        </Button>
      </td>
    </tr>
  );
}

CategoryField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  removeCategory: PropTypes.func.isRequired
};

export default CategoryField;
