import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import padlock from './padlock.png';

import './style.scss';

export const LockImg = ({ height = "36", className = "", subject = "teams" }) => {
    const [modalOpen, setModalOpen] = useState(false);

    const toggleModal = () => {
        setModalOpen(oldVal => !oldVal);
    }

    const getHeaderText = () => {
        switch(subject) {
            case "divisions": return "All Teams in this Division are Locked";
            default: return "Team is Locked";
        }
    }

    const getBodyText = () => {
        switch(subject) {
            case "divisions": return "All teams in this division are locked. If you need to make a change to one of them, please contact your league / tournament administrator.";
            default: return "This team has been locked. If you need to make a change, please contact your league / tournament administrator.";
        }
    }

    return (
        <div className={`lock-img ${className}`}>
            <img src={padlock} height={height} onClick={toggleModal} />
            <Modal
                fade={false}
                isOpen={modalOpen}
                toggle={toggleModal}
                centered={true}
            >
                <ModalHeader toggle={toggleModal}>
                    {getHeaderText()}
                </ModalHeader>
                <ModalBody>
                    <p>{getBodyText()}</p>
                    <p><a href="https://help.gamesheet.app/article/82-team-roster-locking-unlocking" target="_blank">More Information</a></p>
                </ModalBody>
            </Modal>
        </div>
    )
}