import React, { Fragment, useEffect, useState } from "react";

import { Link } from "react-router-dom";

import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";
import SeasonFull from "@/components/SeasonFull";
import ProtectedLayout from "@/layouts/ProtectedLayout";
import AbilityButton from "@/components/AbilityButton";
import PageLoader from "@/components/PageLoader";
import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";
import { useOTP } from "@/lib/core/useOTP";
import Axios from "axios";
import { config } from "@/config";

function ShowSeason() {
  const {
    seasonId,
    season,
    seasonBreadcrumbs,
    seasonIsLoaded,
    seasonIsLoading,
    seasonIsForbidden
  } = useCurrentSeasonContext();

  const { otp } = useOTP()

  return (
    <ProtectedLayout condition={!seasonIsForbidden} className="seasons-page">
      {seasonIsLoaded ? (
        <Fragment>
          <Head title={season.title} />

          <Breadcrumbs items={seasonBreadcrumbs} lastItemNoUrl />

          <TitleBar title={season.title}>
            <AbilityButton
              subject={{ type: "seasons", id: seasonId }}
              action="update"
              outline
              color="primary"
              size="sm"
              tag={Link}
              to={`/seasons/${seasonId}/edit`}
            >
              Edit Season
            </AbilityButton>

            <AbilityButton
              subject={{ type: "ipadKeys", seasonId }}
              action="read"
              outline
              color="primary"
              size="sm"
              tag={Link}
              to={`/seasons/${seasonId}/ipad-keys`}
            >
             Scoring Access Keys
            </AbilityButton>
          </TitleBar>

          <SeasonFull id={seasonId} otp={otp} />
        </Fragment>
      ) : (
        <PageLoader isLoading={seasonIsLoading} isLoaded={seasonIsLoaded} />
      )}
    </ProtectedLayout>
  );
}

export default ShowSeason;
