import { put, all, takeLatest, select, call } from "redux-saga/effects";
import build from "redux-object";
import _trimEnd from "lodash/trimEnd";

import { loadReferees } from "@/lib/api/referees";

import { gamesheetAPIRequest } from "@/redux/api/sagas";
import { loadRefereeReport } from "@/lib/api/reports";

import { refereeReportListLoadingRoutine, refereeReportLoadingRoutine } from "./routines";

import {
  getRefereeReportListSearchCurrentQuery,
  getRefereeReportListOffset,
  getRefereeReportListLimit
} from "./selectors";

import actions from "./actions";

function* refereesLoadingSaga({ payload: { append = false } = {} }) {
  const query = yield select(getRefereeReportListSearchCurrentQuery);
  const limit = yield select(getRefereeReportListLimit);
  const offset = append ? yield select(getRefereeReportListOffset) : 0;

  yield put(refereeReportListLoadingRoutine.request({ query, append }));

  const { data, rawData, meta } = yield call(gamesheetAPIRequest, loadReferees, {
    include: "leagues",
    limit,
    offset,
    query
  });

  const referees = build(data, "searchPersons") || [];

  yield put(
    refereeReportListLoadingRoutine.success({
      ids: rawData.map(({ id }) => id),
      totalCount: meta ? meta["total-count"] || 0 : 0,
      filteredCount: meta ? meta["filtered-count"] || 0 : 0,
      referees,
      query,
      append
    })
  );

  yield put(refereeReportListLoadingRoutine.fulfill({ query, append }));
}

function* reportLoadingSaga({ payload: { externalId } }) {
  yield put(refereeReportLoadingRoutine.request());

  try {
    const { data } = yield call(gamesheetAPIRequest, loadRefereeReport, {
      externalId
    });

    const report = build(data, "refereePenaltyReports", externalId, {
      includeType: true
    });

    yield put(refereeReportLoadingRoutine.success({ report }));
  } catch (error) {
    const responseStatus = error.response && error.response.status;

    yield put(refereeReportLoadingRoutine.failure({ error, responseStatus }));
  } finally {
    yield put(refereeReportLoadingRoutine.fulfill());
  }
}

function* commitSearchQuerySaga() {
  const query = yield select(getRefereeReportListSearchCurrentQuery);

  if (_trimEnd(query)) {
    yield put(refereeReportListLoadingRoutine());
  } else {
    yield put(actions.list.clear());
  }
}

export function* refereeReportFlow() {
  yield all([
    takeLatest(actions.list.search.commit, commitSearchQuerySaga),
    takeLatest(refereeReportListLoadingRoutine.TRIGGER, refereesLoadingSaga),
    takeLatest(refereeReportLoadingRoutine.TRIGGER, reportLoadingSaga)
  ]);
}
