/*
"parse..." is used to convert incoming data from the API to data usable by this application
"standardize..." is used to convert data from this application to data usable by the API for storing in the databases
There are four penalty settings:
- penaltyCodes
- penaltyClasses
- penaltyDurations
- penaltyLengths
But the bottom three can be derived from penaltyCodes

** API **
penaltyCodes = [
    {
        code: "AGG",
        label: "Aggressor",
        penaltyClass: "Minor", // optional
        duration: "2"          // optional
    }
];
penaltyClasses = ["Minor"];
penaltyDurations = [
    {
        length: "2",
        penaltyClasses: ["Minor"],
    }
];
penaltyLengths = ["1.5", "2"];

** Application ** 
penaltyCodes = [
    {
        code: "AGG",
        label: "Aggressor",
        penaltyClass: "Minor", // required
        duration: "2"          // required
    }
];
penaltyClasses = ["Minor"];
penaltyDurations = [
    {
        length: "2",
        penaltyClass: "Minor",
    }
];
penaltyLengths = ["1.5", "2"];
*/

// useful for season settings, and uses penaltyCodes to derive penaltyDurations
export const parsePenaltySettings = ({ penaltyCodes }) => {
    // ensure every penalty code has a "penaltyClass" and "duration" field
    const updatedPenaltyCodes = (penaltyCodes || []).map((code) => {
        const { penaltyClass, duration } = code;

        return {
            ...code,
            penaltyClass: penaltyClass || "",
            duration: duration || "",
        };
    });

    const uniquePenaltyDurations = {}; // will convert to list later, needs to be object for now to ensure no duplicates   
    updatedPenaltyCodes.forEach((code) => {
        // durations can be a string of semicolon-separated numbers
        code.duration.split(";").forEach((length) => {
            if (length.trim() !== "") {
                // get all unique penalty duration + penaltyClass combinations
                const coercedTime = coerceTimeToDecimal(length);
                const key = `${coercedTime}-${code.penaltyClass}`;
                uniquePenaltyDurations[key] = {
                    length: coercedTime,
                    penaltyClass: code.penaltyClass,
                };
            }
        })
    });

    // convert penalty durations to an array, coerce to decimal if applicable, and sort
    const penaltyDurations = Object.values(uniquePenaltyDurations)
        .sort((a,b) => a.penaltyClass.localeCompare(b.penaltyClass)) // yes sort by class first
        .sort((a,b) => a.length.localeCompare(b.length));

    return {
        penaltyCodes: updatedPenaltyCodes,
        penaltyDurations,
    }
}

// useful for division settings
export const parsePenaltyDurations = ({ penaltyDurations }) => {
    const updatedPenaltyDurations = [];

    (penaltyDurations || []).forEach((duration) => {
        duration.penaltyClasses.forEach((penaltyClass) => {
            updatedPenaltyDurations.push({
                length: duration.length,
                penaltyClass,
            });
        });
    });

    updatedPenaltyDurations
        .sort((a,b) => a.penaltyClass.localeCompare(b.penaltyClass)) // yes sort by class first
        .sort((a,b) => a.length.localeCompare(b.length));

    return {
        penaltyDurations: updatedPenaltyDurations,
    }
}

// for backwards compatibility:
// existing seasons have no penaltyClasses or length, and so they won't have penaltyDurations either
// in this case, use penaltyLengths to derive penaltyDurations
export const parsePenaltyDurationsFromLengths = ({ penaltyLengths }) => {
    return penaltyLengths
        .filter(length => length.trim() !== "")
        .map(penaltyLength => ({
            length: coerceTimeToDecimal(penaltyLength),
            penaltyClass: "",
        }))
        .sort((a,b) => a.penaltyClass.localeCompare(b.penaltyClass)) // yes sort by class first
        .sort((a,b) => a.length.localeCompare(b.length));
}

// useful for season settings, and uses penaltyCodes to derive all other penalty settings
export const standardizePenaltySettings = ({ penaltyCodes }) => {
    // populate these using penaltyCodes as the source of truth
    const penaltyClasses = [];
    const uniquePenaltyDurations = {}; // will convert to list later, needs to be object for now for easy lookup

    penaltyCodes.forEach((code) => {
        // get all unique penalty classes, excluding empty strings
        if (code.penaltyClass.trim() != "" && !penaltyClasses.includes(code.penaltyClass)) {
            penaltyClasses.push(code.penaltyClass);
        }

        // durations can be a string of semicolon-separated numbers
        code.duration.split(";").forEach((length) => {
            if (length.trim() !== "") {
                // update uniquePenaltyDurations
                const coercedTime = coerceTimeToDecimal(length);
                if (coercedTime in uniquePenaltyDurations) {
                    if (!uniquePenaltyDurations[coercedTime].penaltyClasses.includes(code.penaltyClass)) {
                        uniquePenaltyDurations[coercedTime].penaltyClasses.push(code.penaltyClass);
                    }
                } else {
                    uniquePenaltyDurations[coercedTime] = {
                        length: coercedTime,
                        penaltyClasses: [code.penaltyClass],
                    };
                }
            }
        })
    });

    return {
        penaltyCodes,
        penaltyClasses,
        penaltyDurations: Object.values(uniquePenaltyDurations),
        penaltyLengths: Object.keys(uniquePenaltyDurations),
    }
}

// useful for division settings, and uses penaltyDurations to derive penaltyLengths
export const standardizePenaltyDurations = ({ penaltyDurations }) => {
    const uniqueDurations = {}; // will convert to list later, needs to be object for now for easy lookup

    penaltyDurations.forEach((duration) => {
        if (duration.length.trim() !== "") {
            // update uniqueDurations
            const coercedTime = coerceTimeToDecimal(duration.length);
            if (coercedTime in uniqueDurations) {
                if (!uniqueDurations[coercedTime].penaltyClasses.includes(duration.penaltyClass)) {
                    uniqueDurations[coercedTime].penaltyClasses.push(duration.penaltyClass);
                }
            } else {
                uniqueDurations[coercedTime] = {
                    length: coercedTime,
                    penaltyClasses: [duration.penaltyClass],
                };
            }
        }
    });

    return {
        penaltyDurations: Object.values(uniqueDurations),
        penaltyLengths: Object.keys(uniqueDurations),
    }
}

// helper function to convert 2:59 -> 2.983
// otherwise returns input
export const coerceTimeToDecimal = (time) => {
    const split = time.split(":");
    if (split.length !== 2) {
        return time.trim();
    }

    const minutesPart = Number(split[0]);
    const secondsPart = Number(split[1]);
    if (!Number.isInteger(minutesPart) || !Number.isInteger(secondsPart)) {
        return time.trim();
    }
    if (minutesPart < 0 || minutesPart > 59 || secondsPart < 0 || secondsPart > 59) {
        return time.trim();
    }

    const minutesDecimal = minutesPart + (secondsPart / 60);
    const roundedDecimal = Math.round((minutesDecimal + Number.EPSILON) * 1000) / 1000;

    return roundedDecimal.toString();
}