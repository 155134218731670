import { useCallback } from "react";

import useFilter from "./useFilter";

export default function useDateInput({ name, action }) {
  const { value, setValue, inputId } = useFilter({ name, action });

  const setDateValue = useCallback(
    date => {
      if (!date || typeof date === "string") {
        return;
      }

      setValue(date.format("YYYY-MM-DD"));
    },
    [setValue]
  );

  const clearDateValue = () => {
    setValue("");
  }

  return {
    inputId,
    value,
    setValue: setDateValue,
    clearDateValue
  };
}
