import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { Input } from "reactstrap";

import Option from "./Option";

function FilterSelect(props) {
  const { placeholder, value, options, isLoaded, isLoading, onChange } = props;

  const onChangeCallback = useCallback(({ target: { value } }) => onChange(value), [onChange]);

  return (
    <Input type="select" value={value} disabled={isLoading || !isLoaded} onChange={onChangeCallback}>
      <Option value={""} text={isLoading ? "Loading…" : placeholder} />
      {options.map(({ id, title }) => (
        <Option key={id} value={id} text={title} />
      ))}
    </Input>
  );
}

FilterSelect.propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  ),
  isLoaded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default FilterSelect;
