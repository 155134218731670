// input: string, output: boolean
export const golangDateTimeIsZero = (time) => {
  return time === "0001-01-01T00:00:00Z";
};

export const getUserTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getUserOffsetNow = () => {
  return -1 * (new Date().getTimezoneOffset());
}

export const getAllSupportedTimezones = () => {
  return Intl.supportedValuesOf("timeZone");
};

export const getTimezoneOptions = () => {
  const fullList = Intl.supportedValuesOf("timeZone");

  const userTz = getUserTimeZone().split("/");
  if (userTz.length < 2) {
    return fullList;
  }

  const userContinent = userTz[0];
  return fullList.filter((tz) => {
    return tz.startsWith(userContinent);
  });
};

// assumes offset is minutes (and an integer)
export const formatOffset = (offset) => {
  if (offset === null) return "invalid";
  const hours = Math.floor(Math.abs(offset) / 60);
  const minutes = Math.abs(offset) % 60;
  const sign = offset < 0 ? "-" : "+";
  return `GMT${sign}${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
};

// params:
// tzName: a valid time zone name like "America/Montreal", taken from getTimezoneOptions()
// date: a string in the format "YYYY-MM-DD"
// time: a string in the format "HH:MM"
//
// returns: the offset in minutes (an integer), or null if unable to determine
export const getOffset = (tzName, date, time) => {
  if (!tzName) return null;
  if (!date) return null;
  if (!time) return null;
  
  try {
    const dateParts = date.split("-");
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];
    const timeParts = time.split(":");
    const hours = timeParts[0];
    const minutes = timeParts[1];
    const target = `${day}/${month}/${year}, ${hours}:${minutes}:00`;

    const format = (offset) => {
      const hours = Math.floor(Math.abs(offset) / 60);
      const minutes = Math.abs(offset) % 60;
      const sign = offset < 0 ? "-" : "+";
      return `${sign}${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
    };

    let offset = 0;
    let guess = new Date();

    let count = 0;
    while (true) {
      count += 1;
      if (count > 10) break;

      guess = new Date(date + "T" + time + ":00" + format(offset));
      const tzDate = guess.toLocaleString("en-GB", {
        timeZone: tzName || "UTC",
      });
      if (tzDate === target) {
        break;
      }

      const tzParts = tzDate.split(", ");
      const tzDateParts = tzParts[0].split("/");
      const tzDay = tzDateParts[0];
      const tzTimeParts = tzParts[1].split(":");
      const tzHours = tzTimeParts[0];
      const tzMinutes = tzTimeParts[1];

      if (parseInt(tzDay) != parseInt(day)) {
        const sign = tzDay < day ? "-1" : "+1";
        offset += sign * 4 * 60; // move by 4 hours
        continue;
      }

      if (parseInt(tzHours) != parseInt(hours)) {
        offset += (parseInt(tzHours) - parseInt(hours)) * 60;
        continue;
      }

      if (parseInt(tzMinutes) != parseInt(minutes)) {
        offset += parseInt(tzMinutes) - parseInt(minutes);
        continue;
      }
    }

    if (count > 10) {
      return null;
    } else {
      return offset;
    }
  } catch (e) {
    return null;
  }
};
