import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Table } from "reactstrap";

import Signature from "./components/Signature";

class LineupSignature extends PureComponent {
  static propTypes = {
    caption: PropTypes.string,
    signature: PropTypes.shape({
        signedBy: PropTypes.string,
        signature: PropTypes.string
      })
  };

  static defaultProps = {
    caption: "Lineup Published By:",
    signature: {
      signedBy: "N/A",
      signature: ""
    }
  };

  render() {
    const { caption, signature } = this.props;

    return (
      <Table striped borderless size="sm">
        <thead>
          <tr>
            <th scope="colgroup" colSpan="2">
              {caption}
            </th>
          </tr>
        </thead>
        <tbody>
          <Signature signedBy={signature.signedBy} signature={signature.signature} />
        </tbody>
      </Table>
    );
  }
}

export default LineupSignature;
