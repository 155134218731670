import { connect } from "react-redux";

import { getAcceptInvitation, getPrototeamToLink } from "@/redux/account/selectors";
import { screens } from "@/redux/account/reducers/invitation";
import { account } from "@/redux/account/actions";
import {
  acceptInvitationValidationRoutine,
  acceptInvitationRoutine,
  loadMergedTeamPreviewRoutine,
} from "@/redux/account/routines";

import Invitation from "./Invitation";

const mapStateToProps = state => ({
  ...getAcceptInvitation(state),
  prototeamToLink: getPrototeamToLink(state),
});

const mapDispatchToProps = dispatch => {
  return {
    onChange: attributes => dispatch(account.invitation.fieldChange(attributes)),
    onSubmit: () => dispatch(acceptInvitationValidationRoutine()),
    onWrongInvitation: () => {
      dispatch(account.invitation.setCodeInfo({}));
      dispatch(account.invitation.showScreen(screens.ENTER_CODE)); 
    },
    onCorrectInvitation: () => dispatch(account.invitation.showScreen(screens.HOW_TO_ADD)),
    onBackToVerify: () => dispatch(account.invitation.showScreen(screens.VERIFY_TEAM_INVITATION)),
    onVerifyMerge: () => dispatch(loadMergedTeamPreviewRoutine()),
    onRejectMerge: () => dispatch(account.invitation.showScreen(screens.HOW_TO_ADD)),
    onAccept: () => dispatch(acceptInvitationRoutine()),
    clear: () => dispatch(account.invitation.clear())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Invitation);
