import React, { Fragment } from "react";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";
import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";

import DivisionForm, { useDivisionFormClearing } from "@/components/DivisionForm";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import PageLoader from "@/components/PageLoader";

function NewDivision() {
  const {
    seasonId,
    seasonIsLoaded,
    seasonIsLoading,
    seasonIsForbidden,
    seasonBreadcrumbs,
    seasonGameSettings
  } = useCurrentSeasonContext();

  useDivisionFormClearing();

  return (
    <ProtectedLayout
      subject={{ type: "divisions", seasonId }}
      action="create"
      skip={!seasonIsLoaded && !seasonIsForbidden}
      className="divisions-page"
    >
      <Head title="New Division" />

      {seasonIsLoaded ? (
        <Fragment>
          <Breadcrumbs items={seasonBreadcrumbs} />
          <TitleBar title="New Division" />
          <DivisionForm seasonId={seasonId} seasonGameSettings={seasonGameSettings} />
        </Fragment>
      ) : (
        <PageLoader isLoading={seasonIsLoading} />
      )}
    </ProtectedLayout>
  );
}

export default NewDivision;
