import { useCallback } from "react";
import { useDispatch } from "react-redux";

import actions from "@/redux/reportDataSourcesSelect/actions";

export default function useTeamGroup() {
  const dispatch = useDispatch();

  const select = useCallback(id => dispatch(actions.teamList.select({ id })), [dispatch]);

  const deselect = useCallback(id => dispatch(actions.teamList.deselect({ id })), [dispatch]);

  return { select, deselect };
}
