import { handleActions } from "redux-actions";

import { loadGameFormRoutine } from "../routines";
import actions from "../actions";

const reduceShots = (state, { payload: { game } }) => {
  const { homeShots, visitorShots } = game;

  const shots = [...(homeShots || []), ...(visitorShots || [])];

  return shots.reduce(
    (result, { goalieId, period }) => ({
      ...result,
      [goalieId]: {
        ...result[goalieId],
        [period]: ((result[goalieId] || {})[period] || 0) + 1
      }
    }),
    {}
  );
};

const setShots = (state, { payload: { goalieId, period, shots } }) => ({
  ...state,
  [goalieId]: {
    ...state[goalieId],
    [period]: parseInt(shots)
  }
});

export default handleActions(
  {
    [loadGameFormRoutine.SUCCESS]: reduceShots,
    [actions.setShots]: setShots,
    [actions.clear]: () => ({})
  },
  {}
);
