import React from "react";

import { Button } from "reactstrap";

import useFilterReset from "./hooks/useFilterReset";

function ResetButton() {
  const { isFilterDirty, reset } = useFilterReset();

  if (isFilterDirty) {
    return (
      <div className="user-filter__reset">
        <Button color="link" onClick={reset}>
          Reset filters
        </Button>
      </div>
    );
  }

  return null;
}

export default ResetButton;
