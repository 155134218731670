import axios from "axios";
import { createGetService, createPostService, createDeleteService, createPatchService } from "./serviceMaker";

export const loadUsers = createGetService(({ limit, offset, filter }) => ({
  url: "/users",
  params: {
    limit,
    offset,
    filter
  }
}));

export const createUser = createPostService(({ attributes }) => ({
  url: "/users",
  attributes
}));

export const loadUser = createGetService(({ id }) => ({ url: `/users/${id}` }));

export const updateUser = createPatchService(({ id, attributes }) => ({
  url: `/users/${id}`,
  attributes
}));

export const deleteUser = createDeleteService(({ id }) => ({
  url: `/users/${id}`
}));

export const resendUserVerificationCode = createPostService(({ userId }) => ({
  url: `/users/${userId}/verification-code`
}));

export const userExistsInPostgres = async (email) => {
  try {
    await axios.post(
      "/api/users/exist",
      {
        data: {
          attributes: {
            email
          }
        }
      },
      {
        headers: {
          "Content-Type": "application/vnd.api+json",
          Accept: "application/vnd.api+json"
        }
      }
    );

    return true;
  } catch (error) {
    return false;
  }
}

export const setUsersFirebaseUid = async (email, uid) => {
  try {
    await axios.post(
      "/api/users/firebase-uid",
      {
        data: {
          attributes: {
            email,
            uid
          }
        }
      },
      {
        headers: {
          "Content-Type": "application/vnd.api+json",
          Accept: "application/vnd.api+json"
        }
      }
    );

    return true;
  } catch (error) {
    return false;
  }
}
