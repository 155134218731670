import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  isCurrentTeamLoading,
  isCurrentTeamLoaded,
  getCurrentTeam,
  isCurrentTeamForbidden
} from "@/redux/teams/selectors";

import { CurrentTeamLoadingRoutine } from "@/redux/teams/routines";

export default function useCurrentTeamLoading({ seasonId, teamId }) {
  const dispatch = useDispatch();

  const teamIsLoaded = useSelector(isCurrentTeamLoaded);
  const teamIsLoading = useSelector(isCurrentTeamLoading);
  const teamIsForbidden = useSelector(isCurrentTeamForbidden);
  const team = useSelector(getCurrentTeam);

  useEffect(
    () => {
      if (!teamIsLoaded && !teamIsLoading && !teamIsForbidden) {
        dispatch(CurrentTeamLoadingRoutine({ seasonId, id: teamId }));
      }
    },
    [teamIsLoaded, teamIsLoading, teamIsForbidden, dispatch, seasonId, teamId]
  );

  return {
    team,
    teamId,
    teamIsLoaded,
    teamIsLoading,
    teamIsForbidden
  };
}
