import { handleActions } from "redux-actions";

import { currentRefereeLoadingRoutine } from "../routines";
import { refereesActions } from "../actions";

const initialState = {
  firstName: null,
  lastName: null,
  externalId: null,
  isLoading: false,
  isLoaded: false,
  isForbidden: false
};

function reduceLoadingRequest(state) {
  return { ...state, isLoading: true };
}

function reduceLoadingSuccess(state, { payload: { referee } }) {
  const { firstName, lastName, externalId } = referee;

  return { ...state, firstName, lastName, externalId, isLoaded: true };
}

function reduceLoadingFailure(state, { payload: { responseStatus } }) {
  return { ...state, isLoaded: false, isForbidden: responseStatus === 401 };
}

function reduceLoadingFulfill(state) {
  return { ...state, isLoading: false };
}

function reduceClearing(state) {
  return { ...initialState };
}

export default handleActions(
  {
    [currentRefereeLoadingRoutine.REQUEST]: reduceLoadingRequest,
    [currentRefereeLoadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [currentRefereeLoadingRoutine.FAILURE]: reduceLoadingFailure,
    [currentRefereeLoadingRoutine.FULFILL]: reduceLoadingFulfill,
    [refereesActions.current.clear]: reduceClearing
  },
  initialState
);
