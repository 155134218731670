import { selector } from "recoil";
import { recordsState, seasonIdState } from "@/components-16.8/RosterImportWizard/state/atoms";
import Service from "@/services";
import * as mutators from "../parser/mutators";
import { mergeByExternalId } from "../functions/mergeByExternalId";


export const coachRecordsState = selector({
  key: 'coachRecords',
  get: ({ get }) => {
    const coaches = get(recordsState).filter(record => record.type === "coach").map(coach => ({ ...coach, team_ids:[ coach.teamId ] }));
    return mergeByExternalId( coaches );
  }
});

export const coachImportsState = selector({
  key: 'coachImports',
  get: ({ get }) => {
    const seasonId = get(seasonIdState);
    return get(coachRecordsState).map( record => () => {
      
      const coach = Object.keys(record).reduce(( mutatedRecord, key ) => {
        return {
          ...mutatedRecord,
          [key]: mutators[key] ? mutators[key](record[key]) : record[key]
        }
      }, {});

      const attributes = {
        birthdate: coach.birthdate,
        external_id: coach.externalId,
        first_name: coach.firstName,
        last_name: coach.lastName
      }

      !!record.birthdate || (delete attributes.birthdate);
      
      return Service.api().seasons.season(seasonId).coaches.import.post({
        data: { attributes }}).then( record => {
        return { record, coach };
      });
    });
  }
});
