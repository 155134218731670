import { REFEREE_POSITIONS } from "@/constants";

const DEFAULT_LANG = "en";

export const getCodeTitle = (data, lang = "en", code) => {
  const defaultLang = data[DEFAULT_LANG];
  const isLangExist = !!data[lang];
  const currentLang = isLangExist ? data[lang] : defaultLang;
  const codeExists = !!currentLang[code];

  return codeExists ? currentLang[code] : code;
};


export const playerDuty = (code, lang = "en") => {
  const data = {
    en: {
      captain: "Captain",
      alternate_captain: "Alternate Captain"
    }
  };

  return getCodeTitle(data, lang, code);
};

export const playerAffiliated = (code, lang = "en") => {
  const data = {
    en: {
      true: "Affiliated",
      false: "Regular"
    }
  };

  return getCodeTitle(data, lang, code);
};

export const refereePosition = code => REFEREE_POSITIONS[code];
