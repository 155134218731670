import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import AbilityButton from "@/components/AbilityButton";
import FullName from "@/components/FullName";
import Status from "@/components/UserStatus";

function User({ id, firstName, lastName, email, roles, status }) {
  return (
    <tr className="dashboard-table-item">
      <td>
        <FullName firstName={firstName} lastName={lastName} />
      </td>
      <td>
        <AbilityButton
          subject={{ type: "users", id }}
          action="update"
          size="sm"
          color="primary"
          outline
          className="dashboard-table-item__action dashboard-table-item__action--hoverable"
          tag={Link}
          to={`/users/${id}/edit`}
        >
          Edit
        </AbilityButton>
      </td>
      <td>{email}</td>
      <td>{roles.join(", ")}</td>
      <td>
        <Status status={status} />
      </td>
    </tr>
  );
}

User.propTypes = {
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  status: PropTypes.string.isRequired
};

export default User;
