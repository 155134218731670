import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getSelectedLeagues,
  getSeasonList,
  getSeasonListIsLoaded,
  getSeasonListIsLoading,
  getRoleIsGlobal,
  getSelectedSeasons // New selector to get selected seasons
} from "@/redux/roleForm/selectors";

import actions from "@/redux/roleForm/actions";

export default function useSeasonSelect() {
  const selectedLeagues = useSelector(getSelectedLeagues);
  const options = useSelector(state => getSeasonList(state, selectedLeagues));
  const selectedSeasons = useSelector(getSelectedSeasons); // Fetch selected seasons
  const roleIsGlobal = useSelector(getRoleIsGlobal);
  const isLoaded = useSelector(state => getSeasonListIsLoaded(state, selectedLeagues));
  const isLoading = useSelector(state => getSeasonListIsLoading(state, selectedLeagues));
  const isOptionSelected = selectedSeasons.length > 0; // Check if any option is selected
  const totalItemsCount = options.reduce((count, group) => count + group.items.length, 0); // Flatten options to get a count of all items across groups
  const isAllOptionsSelected = totalItemsCount > 0 && selectedSeasons.length === totalItemsCount;
  const dispatch = useDispatch();

  const toggleSelected = useCallback(
    ({ id, ...rest }) => {
      const isSelected = selectedSeasons.includes(id); // Check if season is selected
      const action = isSelected ? actions.seasonList.deselect : actions.seasonList.select; 

      dispatch(action({ id, ...rest }));
    },
    [dispatch, selectedSeasons] // Include selectedSeasons in dependencies
  );

  return {
    options,
    isLoaded,
    isLoading,
    isEnabled: !roleIsGlobal,
    toggleSelected,
    selectedSeasons, // Return selected seasons for UI rendering if needed
    isOptionSelected, // Pass this value to indicate if any items are selected
    isAllOptionsSelected
  };
}
