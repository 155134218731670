import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getDivisionId,
  getTeamId,
  getTeamList,
  getTeamListIsLoaded,
  getTeamListIsLoading
} from "@/redux/invitationFilter/selectors";

import actions from "@/redux/invitationFilter/actions";

export default function useLeagueSelect() {
  const divisionId = useSelector(getDivisionId);
  const selectedId = useSelector(getTeamId);
  const options = useSelector(state => getTeamList(state, divisionId));

  const isLoaded = useSelector(state => getTeamListIsLoaded(state, divisionId));

  const isLoading = useSelector(state => getTeamListIsLoading(state, divisionId));

  const dispatch = useDispatch();

  const setSelectedId = useCallback(id => dispatch(actions.setTeamId(id)), [dispatch]);

  return {
    selectedId,
    options,
    isLoaded,
    isLoading,
    setSelectedId
  };
}
