import { connect } from "react-redux";

import {
  getRefereeReportIsLoaded,
  getRefereeReportIsLoading,
  getRefereeReportReferee,
  getRefereeReportCareerStats,
  getRefereeReportMajorPenalties,
  getRefereeReportGamesOfficiated
} from "@/redux/refereeReport/selectors";

import Report from "./Report";

const mapStateToProps = state => ({
  isLoaded: getRefereeReportIsLoaded(state),
  isLoading: getRefereeReportIsLoading(state),
  referee: getRefereeReportReferee(state),
  careerStats: getRefereeReportCareerStats(state),
  majorPenalties: getRefereeReportMajorPenalties(state),
  gamesOfficiated: getRefereeReportGamesOfficiated(state)
});

export default connect(mapStateToProps)(Report);
