import { handleActions } from "redux-actions";

import { trainerVisitsReportLoadingRoutine } from "./routines";
import actions from "./actions";

const initialState = {
  isLoaded: false,
  isLoading: false,
  isForbidden: false,
  summaryStats: {
    gamesPlayed: 0,
    avgVisitsPerGp: 0
  },
  visits: [],
  divisions: [],
  seasons: []
};

function reduceSummaryStats(payload) {
  return {
    gamesPlayed: payload["games_played"],
    avgVisitsPerGp: payload["average_visits_per_games_played"]
  };
}

function reduceVisits({ divisionTrainerVisitsReports }) {
  return divisionTrainerVisitsReports.map(({ visitsNumber: visits, division }) => ({
    divisionId: division.id,
    seasonId: division.season.id,
    visits
  }));
}

function reduceDivisions({ divisionTrainerVisitsReports }) {
  return divisionTrainerVisitsReports.reduce(
    (result, { division: { id, title, season } }) => ({
      ...result,
      [id]: {
        title,
        seasonId: season.id
      }
    }),
    {}
  );
}

function reduceSeasons({ divisionTrainerVisitsReports }) {
  return divisionTrainerVisitsReports.reduce(
    (result, { season: { id, title } }) => ({
      ...result,
      [id]: {
        title
      }
    }),
    {}
  );
}

function reduceLoadingRequest(state) {
  return { ...state, isLoading: true };
}

function reduceLoadingSuccess(state, { payload }) {
  const summaryStats = reduceSummaryStats(payload);
  const visits = reduceVisits(payload);
  const divisions = reduceDivisions(payload);
  const seasons = reduceSeasons(payload);

  return {
    ...state,
    summaryStats,
    visits,
    divisions,
    seasons,
    isLoaded: true
  };
}

function reduceLoadingFailure(state, { payload: { responseStatus } }) {
  return { ...state, isLoaded: false, isForbidden: responseStatus === 401 };
}

function reduceLoadingFulfill(state) {
  return { ...state, isLoading: false };
}

function reduceClearing(state) {
  return { ...initialState };
}

export default handleActions(
  {
    [trainerVisitsReportLoadingRoutine.REQUEST]: reduceLoadingRequest,
    [trainerVisitsReportLoadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [trainerVisitsReportLoadingRoutine.FAILURE]: reduceLoadingFailure,
    [trainerVisitsReportLoadingRoutine.FULFILL]: reduceLoadingFulfill,
    [actions.report.clear]: reduceClearing
  },
  initialState
);
