import PropTypes from "prop-types";
import React from "react";
import { Button } from "reactstrap";

import useFile from "./hooks/useFile";

import ImagePreview from "./ImagePreview";
import ImagePicker from "./ImagePicker";
import ClearFileButton from "./ClearFileButton";

import "./Field.scss";

function Field({ url, maxSize, onChange, alt }) {
  const { file, changeFile, clearFile, fileError, setFileError } =
    useFile(onChange);

  return (
    <div className="image-picker-field form-row">
      <div className="col-6 col-md-3">
        <div className="image-picker-field__image-container">
          <ImagePreview url={url} file={file} alt={alt} />
        </div>
      </div>
      <div className="col-6 col-md-3">
        <div className="d-flex flex-row image-picker-field__buttons">
          <ImagePicker
            onChange={changeFile}
            onError={setFileError}
            extensions={["png", "jpg", "jpeg", "webp", "svg"]}
            maxSize={maxSize}
            dims={{
              minWidth: 70,
              minHeight: 70,
              maxWidth: 7000,
              maxHeight: 7000,
            }}
          >
            <Button
              className="image-picker-field__button"
              type="button"
              size="sm"
              color="primary"
              outline
            >
              Select Image
            </Button>
          </ImagePicker>
          <ClearFileButton file={file} url={url} clearFile={clearFile} />
        </div>
        <p className="image-picker-field__text">
          Supported formats are PNG, JPEG, WebP, and SVG. For best results,
          the image dimensions should be 230✕70px.<br />The maximum file size is{" "}
          {maxSize} MB.
        </p>
        {fileError && <p className="text-danger">{fileError}</p>}
      </div>
    </div>
  );
}

Field.propTypes = {
  url: PropTypes.string,
  maxSize: PropTypes.number,
  onChange: PropTypes.func,
  alt: PropTypes.string,
};

Field.defaultProps = {
  url: "",
  maxSize: 10,
  alt: "Image",
  onChange() {},
};

export default Field;
