import { FIELDS_MAPPING } from "./constants";

export default {
  name: {
    presence: {
      allowEmpty: false,
      message: `^${FIELDS_MAPPING.name} can’t be blank`
    }
  },
  email: {
    presence: {
      allowEmpty: false,
      message: `^${FIELDS_MAPPING.email} can’t be blank`
    },
    email: true
  }
};
