import { connect } from "react-redux";

import SearchPage from "./SearchPage";

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchPage);
