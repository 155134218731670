import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Table } from "reactstrap";

class Venue extends PureComponent {
  static propTypes = {
    gameNumber: PropTypes.string,
    date: PropTypes.string,
    timeStart: PropTypes.string,
    timeEnd: PropTypes.string,
    location: PropTypes.string
  };

  static defaultProps = {
    gameNumber: "N/A",
    date: "N/A",
    timeStart: "N/A",
    timeEnd: "N/A",
    location: "N/A"
  };

  render() {
    const { gameNumber, date, timeStart, timeEnd, location } = this.props;

    return (
      <Table striped borderless size="sm">
        <thead>
          <tr>
            <th scope="col">Game No.</th>
            <th scope="col">Date</th>
            <th scope="col">Started</th>
            <th scope="col">Ended</th>
            <th scope="col">Location</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-uppercase">{gameNumber}</td>
            <td>{date}</td>
            <td>{timeStart}</td>
            <td>{timeEnd}</td>
            <td>{location}</td>
          </tr>
        </tbody>
      </Table>
    );
  }
}

export default Venue;
