import React from "react";
import PropTypes from "prop-types";

import { Table } from "reactstrap";

function Team(props) {
  const { caption, totalPenaltyMinutes, fairPlayEarned } = props;

  return (
    <Table striped borderless size="sm" className="layout-fixed">
      <thead>
        <tr>
          <th scope="colgroup" colSpan="2">
            {caption}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Total Penalty Minutes</td>
          <td>{totalPenaltyMinutes}</td>
        </tr>
        <tr>
          <td className="bold">Fair Play Point Earned</td>
          <td className="text-uppercase bold">{fairPlayEarned ? `Yes` : `No`}</td>
        </tr>
      </tbody>
    </Table>
  );
}

Team.propTypes = {
  caption: PropTypes.string.isRequired,
  totalPenaltyMinutes: PropTypes.number.isRequired,
  fairPlayEarned: PropTypes.bool.isRequired
};

export default Team;
