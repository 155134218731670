import React from "react";

import useCurrentAssociationContext from "@/hooks/useCurrentAssociationContext";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import TitleBar from "@/components/TitleBar";
import Breadcrumbs from "@/components/Breadcrumbs";
import Head from "@/components/Head";

import AssociationForm, { useAssociationFormClearing } from "@/components/AssociationForm";

import PageLoader from "@/components/PageLoader";

function EditAssociation() {
  const {
    association,
    associationIsLoaded,
    associationIsLoading,
    associationId,
    associationBreadcrumbs
  } = useCurrentAssociationContext();

  useAssociationFormClearing();

  return (
    <ProtectedLayout
      subject={{ type: "associations", id: associationId }}
      action="update"
      skip={!associationIsLoaded}
      className="associations-page"
    >
      {associationIsLoaded && <Head title={association.title} />}

      <Breadcrumbs items={associationBreadcrumbs} />

      <TitleBar title="Edit Association" />

      {associationIsLoaded ? (
        <AssociationForm associationId={associationId} initialValues={association} />
      ) : (
        <PageLoader isLoading={associationIsLoading} />
      )}
    </ProtectedLayout>
  );
}

export default EditAssociation;
