import React from "react";
import PropTypes from 'prop-types';

function ShowIf({ cond, value, children, component }){
  return (cond === value) && ( component || children );
}

ShowIf.propTypes = {
  cond: PropTypes.any.isRequired,
  value: PropTypes.any,
  component: PropTypes.node
}

ShowIf.defaultProps = {
  value: true
}

export default ShowIf;
