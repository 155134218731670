import React, { useCallback } from "react";
import PropTypes from "prop-types";

import ImagePickerField from "@/components/ImagePickerField";

function LogoPickerField({ initialValue, value, setValue, isDirty }) {
  
  const onChange = useCallback(file => setValue(file || ""), [setValue]);
  const url = initialValue.includes("imagedelivery.net") ? `${initialValue}/256` : initialValue;

  return <ImagePickerField alt="Association Logo" url={isDirty ? "" : url} onChange={onChange} />;
}

LogoPickerField.propTypes = {
  initialValue: PropTypes.any,
  value: PropTypes.any,
  isDirty: PropTypes.bool,
  setValue: PropTypes.func
};

export default LogoPickerField;
