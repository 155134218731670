import { connect } from "react-redux";

import { closeSeasonIpadKeysManager } from "@/redux/seasonIpadKeysManager/actions";

import {
  ipadKeysManagerAddingSeasonToKeysListRoutine,
  seasonIpadKeysManagerKeysLoadingRoutine
} from "@/redux/seasonIpadKeysManager/routines";

import {
  getSeasonIpadKeysManagerSeasonTitle,
  getSeasonIpadKeysManagerIsOpen,
  getSeasonIpadKeysManagerIsWorking,
  getSeasonIpadKeysManagerSelectedKeys
} from "@/redux/seasonIpadKeysManager/selectors";

import Modal from "./Modal";

const mapStateToProps = state => ({
  isOpen: getSeasonIpadKeysManagerIsOpen(state),
  seasonTitle: getSeasonIpadKeysManagerSeasonTitle(state),
  isWorking: getSeasonIpadKeysManagerIsWorking(state),
  selectedKeys: getSeasonIpadKeysManagerSelectedKeys(state)
});

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(closeSeasonIpadKeysManager()),
  addSeasonToKeysList: () => dispatch(ipadKeysManagerAddingSeasonToKeysListRoutine()),
  loadKeysList: associationId => dispatch(seasonIpadKeysManagerKeysLoadingRoutine({ associationId }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Modal);
