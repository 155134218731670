import React, { Fragment } from "react";
import { Label } from 'reactstrap';
import PropTypes from "prop-types";

import MultiSelectField from "@/components/MultiSelectField";
import { useDivisionsInput } from "./useDivisionsInput";

function DivisionsInput({ inputId, value, setValue, seasonId }) {
  const name = "divisionIds";

  const { options, isLoaded } = useDivisionsInput({ seasonId });

  return (
    <Fragment>
      <Label for={inputId}>Divisions</Label>
      <MultiSelectField
        size="sm"
        id={inputId}
        name={name}
        isListLoaded={isLoaded}
        isListLoading={!isLoaded}
        options={options.map(({ id, title }) => ({
          value: id,
          label: title
        }))}
        onChange={setValue}
        readyMsg={"All divisions"}
        value={value}
      />
    </Fragment>
  );
}

DivisionsInput.propTypes = {
  inputId: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  setValue: PropTypes.func.isRequired,
  seasonId: PropTypes.string.isRequired
};

export default DivisionsInput;
