import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { locationInputFocusState } from './components/QueryInput/state';

export default function useScheduledGameLocationInput({ ref }) {
  const setlocationInputFocus = useSetRecoilState(locationInputFocusState);

  useEffect(() => {
    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            setlocationInputFocus(false);
        }
    }

    document.addEventListener("mousedown", handleClickOutside);
    
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

}