import { useCallback } from "react";
import { useDispatch } from "react-redux";

import actions from "@/redux/gameForm/actions";

export default function useShootoutChange({ team, index }) {
  const dispatch = useDispatch();

  const onChange = useCallback(
    ({ target: { name, value, type } }) =>
      dispatch(
        actions.changeShootout({
          team,
          index,
          field: name,
          value: type === "number" ? parseInt(value) : value
        })
      ),
    [dispatch, team, index]
  );

  return onChange;
}
