import axios from 'axios';
import { seasonTeamService } from "@/services/api/seasons/season/teams/team";

export const seasonTeamsService = (path, config) => {
  let v2_path = `${path}/teams-v2`;
  path = `${path}/teams`;

  return {

    get: async ({ include=[] }={}) => {
      path = include.length ? `${path}?include=${include}` : path;
      return axios.get(path, config).then(response => {
        // console.log(`service.api.get.seasons/season/teams/`, response.data);
        return response.data;
      }).catch(e => {
        console.log("Get Season Teams Error", e);
      });
    },

    v2_team: ( id ) => seasonTeamService(`${v2_path}/${id}`, config)
  }
}
