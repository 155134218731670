import { all, call, put, takeLatest } from "redux-saga/effects";
import build from "redux-object";

import { beginSaga } from "@/redux/common/GenericSaga";

import { CreateInvitationRoutine, LoadInvitationsListRoutine } from "./routines";

import { createInvitation, loadInvitations } from "@/lib/api/invitations";
import { createResource } from "@/redux/resources/sagas";
import { gamesheetAPIRequest } from "../api/sagas";

export const createInvitationSaga = beginSaga(CreateInvitationRoutine)
  .perform(function*(payload) {
    const { code, description } = payload;
    const scopes = Object.keys(payload.scopes).reduce((result, scopeName) => {
      const actions = Object.keys(payload.scopes[scopeName].actions).filter(
        action => payload.scopes[scopeName].actions[action] === true
      );

      const scope = { actions };

      if (payload.scopes[scopeName].global === false) {
        scope.ids = payload.scopes[scopeName].ids;
      }

      return Object.assign({}, result, { [scopeName]: scope });
    }, {});

    scopes.account = { actions: ["create", "read", "update", "delete"] };
    scopes.tokens = { actions: ["create", "read", "update", "delete"] };
    scopes.password = { actions: ["create", "read", "update", "delete"] };

    const identity = { type: "invitations" };
    const attributes = { code, description, scopes };

    const response = yield call(createResource, createInvitation, {
      identity,
      attributes
    });

    return response;
  })
  .andRedirectTo("/invitations")
  .endSaga();

function* loadInvitationsListSaga({ payload: { append = false } }) {
  yield put(LoadInvitationsListRoutine.request({ append }));

  try {
    const { data, rawData, meta } = yield call(gamesheetAPIRequest, loadInvitations);

    const invitations = build(data, "invitations");

    yield put(
      LoadInvitationsListRoutine.success({
        ids: rawData.map(({ id }) => id),
        totalCount: meta ? meta["total-count"] || 0 : 0,
        filteredCount: meta ? meta["total-count"] || 0 : 0, // @FIXME
        invitations,
        append
      })
    );
  } catch (e) {
    yield put(LoadInvitationsListRoutine.failure({ append }));

    throw e;
  } finally {
    yield put(LoadInvitationsListRoutine.fulfill({ append }));
  }
}

export function* invitationsFlow() {
  yield all([
    createInvitationSaga.takeLatest(),
    takeLatest(LoadInvitationsListRoutine.TRIGGER, loadInvitationsListSaga)
  ]);
}
