import { handleActions } from "redux-actions";

import { loadingRoutine } from "../routines";
import actions from "../actions";

const initialState = {
  title: "",
  association: { id: "", title: "" },
  logo: ""
};

function reduceLoadingSuccess(state, { payload: { league } }) {
  const { title, association, logo="" } = league;

  return {
    title,
    association: { id: association.id, title: association.title },
    logo
  };
}

export default handleActions(
  {
    [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
