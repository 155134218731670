import { all, put, call, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";

import { createAssociation, updateAssociation, deleteAssociation } from "@/lib/api/associations";

import { responseErrorsFullMessages } from "@/lib/api/utils";
import { gamesheetAPIRequest } from "@/redux/api/sagas";

import { FIELDS_MAPPING } from "@/components/AssociationForm";

import { submittingRoutine, deletingRoutine } from "./routines";
import { createFile } from "@/lib/api/file";

function* uploadLogoSaga(file) {
  const {
    data: { data }
  } = yield call(gamesheetAPIRequest, createFile, {
    file
  });

  return data.attributes.url;
}

function* submittingSaga({ payload: { associationId, values } }) {
  const { title, logo } = values;

  yield put(submittingRoutine.request());

  try {
    const attributes = {
      title,
      logo: logo instanceof File ? yield call(uploadLogoSaga, logo) : logo
    };

    const service = associationId ? updateAssociation : createAssociation;

    yield call(
      gamesheetAPIRequest,
      service,
      {
        attributes,
        identity: { type: "associations", id: associationId }
      },
      true
    );

    yield put(submittingRoutine.success());
    yield put(push("/associations"));
  } catch (error) {
    const { response } = error;
    const validationErrors = response ? responseErrorsFullMessages(response, FIELDS_MAPPING) : {};

    yield put(submittingRoutine.failure({ error, validationErrors }));
  } finally {
    yield put(submittingRoutine.fulfill());
  }
}

function* deletingSaga({ payload: { associationId } }) {
  yield put(deletingRoutine.request());

  try {
    yield gamesheetAPIRequest(deleteAssociation, {
      identity: { type: "associations", id: associationId }
    });

    yield put(deletingRoutine.success());
    yield put(push("/associations"));
  } catch (e) {
    yield put(deletingRoutine.failure({ error: e }));
  } finally {
    yield put(deletingRoutine.fulfill());
  }
}

export function* associationFormFlow() {
  yield all([takeLatest(submittingRoutine, submittingSaga), takeLatest(deletingRoutine, deletingSaga)]);
}
