import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import AbilityLink from "@/components/AbilityLink";
import AbilityButton from "@/components/AbilityButton";

class PlayerTeam extends PureComponent {
  static propTypes = {
    playerId: PropTypes.string.isRequired,
    seasonId: PropTypes.string.isRequired,
    divisionId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    jersey: PropTypes.string,
    status: PropTypes.string,
    position: PropTypes.string,
    duty: PropTypes.string
  };

  static defaultProps = {
    jersey: "",
    status: "",
    position: "",
    duty: "",
    canEdit: false,
    canRead: false
  };

  render() {
    const { playerId, seasonId, id, title, jersey, status, position, duty } = this.props;

    return (
      <tr>
        <td>
          <AbilityLink
            subject={{ type: "teams", id }}
            action="read"
            fallback={title}
            to={`/seasons/${seasonId}/teams/${id}/roster`}
          >
            {title}
          </AbilityLink>
        </td>
        <td>{jersey}</td>
        <td>{duty}</td>
        <td>{position}</td>
        <td>{status}</td>
        <td>
          <AbilityButton
            subject={{ type: "teams", id }}
            action="update"
            outline
            color="primary"
            size="sm"
            tag={Link}
            to={`/seasons/${seasonId}/teams/${id}/roster/players/${playerId}/edit`}
          >
            Edit
          </AbilityButton>
        </td>
      </tr>
    );
  }
}

export default PlayerTeam;
