import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Alert } from "reactstrap";

import "./PageAlert.scss";

class PageAlert extends PureComponent {
  static propTypes = {
    type: PropTypes.oneOf(["success", "warning", "info", "danger"]),
    message: PropTypes.string
  };

  static defaultProps = {
    message: null
  };

  constructor(props) {
    super(props);

    this.state = {
      alertIsVisible: true
    };

    this.onDismiss = this.onDismiss.bind(this);
  }

  onDismiss() {
    this.setState({ alertIsVisible: false });
  }

  render() {
    const { type, message } = this.props;

    return (
      <Alert className="page-alert" color={type} isOpen={this.state.alertIsVisible} toggle={this.onDismiss}>
        {message}
      </Alert>
    );
  }
}

export default PageAlert;
