import { handleActions, combineActions } from "redux-actions";

import actions from "../actions";

import { submittingRoutine, loadingRoutine, deletingRoutine } from "../routines";

const initialState = {
  isCreating: false,
  isDeleting: false,
  isLoading: false,
  isLoaded: false,
  code: "",
  expiresAt: null
};

function reduceCreatingRequest(state) {
  return { ...state, isCreating: true };
}

function reduceCreatingFulfill(state) {
  return { ...state, isCreating: false };
}

function reduceDeletingRequest(state) {
  return { ...state, isDeleting: true };
}

function reduceDeletingFulfill(state) {
  return { ...state, isDeleting: false };
}

function reduceLoadingRequest(state) {
  return { ...state, isLoading: true };
}

function reduceLoadingSuccess(state, { payload: { invitation } }) {
  const { id, expiresAt } = invitation;

  return {
    ...state,
    isLoaded: true,
    id,
    expiresAt: expiresAt ? new Date(expiresAt) : null
  };
}

function reduceLoadingFailure(state) {
  return { ...state, isLoaded: false };
}

function reduceLoadingFulfill(state) {
  return { ...state, isLoading: false };
}

export default handleActions(
  {
    [submittingRoutine.REQUEST]: reduceCreatingRequest,
    [submittingRoutine.FULFILL]: reduceCreatingFulfill,
    [deletingRoutine.REQUEST]: reduceDeletingRequest,
    [deletingRoutine.FULFILL]: reduceDeletingFulfill,
    [loadingRoutine.REQUEST]: reduceLoadingRequest,
    [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [loadingRoutine.FAILURE]: reduceLoadingFailure,
    [loadingRoutine.FULFILL]: reduceLoadingFulfill,
    [combineActions(actions.clear, actions.reset)]: () => ({ ...initialState })
  },
  initialState
);
