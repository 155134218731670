import axios from 'axios';

export const importService = ( path, config ) => {
  path = `${path}/import`;

  return {

    // POST /seasons/:id/players/import
    post: async ({ data }) => {
      return axios.post( path, { data }, config);
    }

  }
}
