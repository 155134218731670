import React from "react";
import PropTypes from "prop-types";

import Spinner from "@/components/Spinner";

import "./PageLoader.scss";

class PageLoader extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    isLoaded: PropTypes.bool,
    isLoading: PropTypes.bool
  };

  static defaultProps = {
    children: null,
    isLoaded: false,
    isLoading: false
  };

  render() {
    const { children, isLoaded, isLoading } = this.props;

    if (isLoading) {
      if (isLoaded) {
        return (
          <div className="page-loader__with-content">
            <div className="page-loader__wrapper" />
            <Spinner
              className="page-loader__spinner page-loader__spinner__absolute"
              theme="loader"
              color="yellow"
              size="xxl"
            />
            <div className="page-loader__content">{children}</div>
          </div>
        );
      }

      return (
        <div className="page-loader">
          <Spinner className="page-loader__spinner" theme="loader" color="yellow" size="xxl" />
        </div>
      );
    }

    return isLoaded ? children : null;
  }
}

export default PageLoader;
