import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import actions from "@/redux/gameForm/actions";
import { loadGameFormRoutine } from "@/redux/gameForm/routines";

import { getGameFormIsLoaded, getGameFormIsLoading, getGameFormIsForbidden } from "@/redux/gameForm/selectors";

import GamesEdit from "./GamesEdit";

function GamesEditContainer({ match: { params } }) {
  const { seasonId, gameId } = params;

  const dispatch = useDispatch();

  const { seasonIsLoaded, seasonIsLoading, seasonBreadcrumbs } = useCurrentSeasonContext();

  const loadGameForm = useCallback(() => dispatch(loadGameFormRoutine({ seasonId, id: gameId })), [
    dispatch,
    seasonId,
    gameId
  ]);

  const clearForm = useCallback(() => dispatch(actions.clear()), [dispatch]);

  const formIsLoaded = useSelector(getGameFormIsLoaded);
  const formIsLoading = useSelector(getGameFormIsLoading);
  const formIsForbidden = useSelector(getGameFormIsForbidden);

  return (
    <GamesEdit
      gameId={gameId}
      seasonId={seasonId}
      seasonIsLoaded={seasonIsLoaded}
      seasonIsLoading={seasonIsLoading}
      breadcrumbs={seasonBreadcrumbs}
      loadGameForm={loadGameForm}
      clearForm={clearForm}
      formIsLoading={formIsLoading}
      formIsLoaded={formIsLoaded}
      formIsForbidden={formIsForbidden}
    />
  );
}

GamesEditContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      seasonId: PropTypes.string.isRequired,
      gameId: PropTypes.string.isRequired
    }).isRequired,
    path: PropTypes.string.isRequired
  }).isRequired
};

export default GamesEditContainer;
