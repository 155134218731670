import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";
import { useEffect, useState, useCallback } from "react";

export const usePenaltyClasses = () => {
  const [options, setOptions] = useState([]);
  const { season } = useCurrentSeasonContext();
  const sport = season && season.sport ? season.sport : "";

  useEffect(() => {
    if (!sport) return;
    let mounted = true;

    (async () => {
      const penaltyOptions = await fetch(`https://lookups.gamesheet.io/api/penalties?sport=${sport.toLowerCase()}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch penalties");
          }

          return response.json();
        })
        .catch(() => ([]));

      mounted && setOptions(penaltyOptions);
    })();

    return () => {
      mounted = false;
    };
  }, [sport]);

  const isValid = useCallback((penaltyClass) => {
    if (options.length <= 0) return true;
    return options.some(({ key, title }) => {
      return key === penaltyClass.toLowerCase() || title === penaltyClass;
    });
  }, [options]);

  const getKey = useCallback((penaltyClass) => {
    const option = options.find(({ key, title }) => {
      return key === penaltyClass.toLowerCase() || title === penaltyClass;
    });

    return option ? option.key : penaltyClass;
  }, [options]);

  const getLabel = useCallback((penaltyClass) => {
    const option = options.find(({ key, title }) => {
      return key === penaltyClass.toLowerCase() || title === penaltyClass;
    });

    return option ? option.title : penaltyClass;
  }, [options]);

  return {
    options,
    getKey,
    getLabel,
    isValid
  };
}