import React from "react";
import PropTypes from "prop-types";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";

import PenaltyAccumulationReport from "@/components-16.8/PenaltyAccumulationReport";
import usePenaltyAccumulationReport from "./hooks/usePenaltyAccumulationReport";

function ReportPage({ location: { search } }) {
  const { isLoaded, isForbidden, seasonId, penaltyCodes, data, season } = usePenaltyAccumulationReport(search);

  return (
    <ProtectedLayout condition={!isForbidden}>
      <Head title="Penalty Accumulation Report" />
      <div>
        <Breadcrumbs
          items={[
            { title: "Reports" },
            {
              title: "Penalty Accumulation Reports",
              url: "/reports/penalty-accumulation/sources"
            }
          ]}
        />
      </div>

      <PenaltyAccumulationReport
        isLoaded={isLoaded}
        seasonId={seasonId}
        penaltyCodes={penaltyCodes}
        data={data}
        season={season}
      />
    </ProtectedLayout>
  );
}

ReportPage.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string.isRequired }).isRequired
};

export default ReportPage;
