import React from "react";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import TitleBar from "@/components/TitleBar";
import Breadcrumbs from "@/components/Breadcrumbs";
import Head from "@/components/Head";
import LeagueForm, { useLeagueFormClearing } from "@/components/LeagueForm";
import PageLoader from "@/components/PageLoader";
import useCurrentLeagueContext from "@/hooks/useCurrentLeagueContext";

function EditLeague() {
  const {
    leagueId,
    league,
    leagueBreadcrumbs,
    leagueIsLoaded,
    leagueIsLoading,
    leagueIsForbidden,
    leagueFormFields,
    leagueAssociationId
  } = useCurrentLeagueContext();

  useLeagueFormClearing();

  return (
    <ProtectedLayout
      subject={{ type: "leagues", id: leagueId }}
      action="update"
      className="leagues-page"
      skip={!leagueIsLoaded && !leagueIsForbidden}
    >
      {leagueIsLoaded && <Head title={league.title} />}

      <Breadcrumbs items={leagueBreadcrumbs} noLastItemUrl />

      <TitleBar title="Edit League" />

      {leagueIsLoaded ? (
        <LeagueForm initialValues={leagueFormFields} leagueId={leagueId} associationId={leagueAssociationId} />
      ) : (
        <PageLoader isLoading={leagueIsLoading} />
      )}
    </ProtectedLayout>
  );
}

export default EditLeague;
