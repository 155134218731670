import { connect } from "react-redux";

import { getSuspensionReportSorting } from "@/redux/suspensionReport/selectors";
import actions from "@/redux/suspensionReport/actions";

import Column from "./Column";

const mapStateToProps = state => ({
  sorting: getSuspensionReportSorting(state)
});

const mapDispatchToProps = (dispatch, { name }) => ({
  toggleSorting: () => dispatch(actions.report.toggleSorting(name))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Column);
