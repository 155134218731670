import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Table } from "reactstrap";

import SuspensionReportSuspension from "./Suspension";
import SuspensionReportColumn from "./Column";
import TotalCount from "../TotalCount";

class SeasonSuspensions extends PureComponent {
  static propTypes = {
    season: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      isArchived: PropTypes.bool.isRequired
    }),
    suspensions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired
      })
    ).isRequired
  };

  render() {
    const { season, suspensions } = this.props;

    const title = season.isArchived ? `${season.title} (archived)` : season.title;

    return (
      <div className="suspension-report__subsection">
        <h5 className="suspension-report__subsection-title">{title} Suspensions</h5>

        <TotalCount plural="suspensions" singular="suspension" totalCount={suspensions.length} />

        <Table striped borderless className="suspension-report__table">
          <thead>
            <tr>
              <SuspensionReportColumn title="Date" name="game.startTime" />
              <SuspensionReportColumn title="Game #" name="game.number" />
              <SuspensionReportColumn title="Type" name="suspendedPerson.type" />
              <SuspensionReportColumn title="Name" name="suspendedPerson.name" />
              <SuspensionReportColumn title="Team" name="team.title" />
              <SuspensionReportColumn title="Division" name="division.title" />
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            {suspensions.map(suspension => (
              <SuspensionReportSuspension disableLinks={season.isArchived} key={suspension.id} {...suspension} />
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default SeasonSuspensions;
