import React from "react";
import PropTypes from "prop-types";
import _toUpper from "lodash/toUpper";

import { Button } from "reactstrap";

import Field from "./Field";

import usePlayerForm from "../hooks/usePlayerForm";
import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

function PlayerForm({ playerId }) {
  const { triggerReset, isInvalid, isNotFilled, isPristine, isSaving, triggerSave } = usePlayerForm(playerId);
  const { season } = useCurrentSeasonContext();

  return (
    <tr>
      <td>
        <Field member={{ type: "players", id: playerId }} name="number" />
      </td>
      <td>
        <Field member={{ type: "players", id: playerId }} name="firstName" transform={_toUpper} />
      </td>
      <td>
        <Field member={{ type: "players", id: playerId }} name="lastName" transform={_toUpper} />
      </td>
      <td>
        <Field
          member={{ type: "players", id: playerId }}
          name="externalId"
          placeholder="Optional"
          transform={_toUpper}
        />
      </td>
      <td>
        <Field
          member={{ type: "players", id: playerId }}
          name="affiliated"
          type="select"
          transform={value => value === "true"}
        >
          <option value={false}>Regular</option>
          <option value={true}>Affiliated</option>
        </Field>
      </td>
      <td>
        <Field member={{ type: "players", id: playerId }} name="position" type="select">
          <option value="">None</option>
          {season.playerPositions.map(position => (
            <option key={position.key} value={position.key}>
              {position.value}
            </option>
          ))}
        </Field>
      </td>
      <td>
        <Field member={{ type: "players", id: playerId }} name="duty" type="select">
          <option value="">None</option>
          <option value="captain">Captain</option>
          <option value="alternate_captain">Alternate Captain</option>
        </Field>
      </td>
      <td className="text-right">
        <Button size="sm" color="secondary" outline onClick={triggerReset}>
          Cancel
        </Button>
        <Button
          type="submit"
          size="sm"
          color="success"
          outline={isInvalid || isNotFilled || isPristine}
          disabled={isInvalid || isNotFilled || isPristine}
          onClick={triggerSave}
          className="ml-1"
        >
          {isSaving ? "Saving…" : "Save"}
        </Button>
      </td>
    </tr>
  );
}

PlayerForm.propTypes = {
  playerId: PropTypes.string.isRequired
};

export default PlayerForm;
