import { handleActions } from "redux-actions";

import { invitationFormActions } from "../../actions";

const defaultState = {
  code: "",
  description: ""
};

const change = (state, { payload }) => Object.assign({}, state, payload);

export default handleActions(
  {
    [invitationFormActions.attributes.changeAttributes]: change
  },
  defaultState
);
