import { combineReducers } from "redux";

import { associations, leagues, seasons, divisions, teams, pagination } from "./reducers";

export default combineReducers({
  associations,
  leagues,
  seasons,
  divisions,
  teams,
  pagination
});
