import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Row, Col, Table } from "reactstrap";

import Report from "./components/Report";

class RefereeReports extends PureComponent {
  static propTypes = {
    reports: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired
      })
    )
  };

  static defaultProps = {
    reports: []
  };

  render() {
    const { reports } = this.props;

    if (reports.length === 0) {
      return null;
    }

    return (
      <Row>
        <Col>
          <Table striped borderless size="sm" className="notes-table">
            <caption className="visuallyhidden">Referee Report</caption>
            <thead>
              <tr>
                <th scope="colgroup" colSpan="6">
                  Referee Report
                </th>
                <th scope="colgroup" colSpan="2" />
              </tr>
              <tr>
                <th scope="col">Team</th>
                <th scope="col">No.</th>
                <th scope="col">Player</th>
                <th scope="col">Per.</th>
                <th scope="col">Min</th>
                <th scope="col">Code</th>
                <th scope="col">Note</th>
                <th scope="col">By</th>
              </tr>
            </thead>
            <tbody>
              {reports.map(({ id, ...report }) => (
                <Report key={id} {...report} />
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  }
}

export default RefereeReports;
