import _camelCase from "lodash/camelCase";
import React, { Fragment } from "react";
import PropTypes from "prop-types";

import ReactDateInput from "react-datetime";
import { Label } from "reactstrap";

import { actions } from "@/redux/scheduledGamesFilter";

import useDateInput from "./hooks/useDateInput";

function DateInput({ label, name }) {
  const action = actions[_camelCase(`set_${name}`)];
  const { inputId, setValue, value } = useDateInput({ name, action });

  return (
    <Fragment>
      <Label for={inputId}>{label}</Label>
      <ReactDateInput
        name={name}
        id={inputId}
        dateFormat="YYYY-MM-DD"
        timeFormat={false}
        inputProps={{
          className: "form-control form-control-sm",
          onChange: () => {},
          placeholder: "YYYY-MM-DD"
        }}
        closeOnSelect={true}
        onChange={setValue}
        value={value}
      />
    </Fragment>
  );
}

DateInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.oneOf(["scheduledDateFrom", "scheduledDateTo"]).isRequired
};

export default DateInput;
