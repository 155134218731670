import React, { Fragment } from "react";

import { Label, Input } from "reactstrap";

import { actions } from "@/redux/scheduledGamesFilter";

import useFilter from "./hooks/useFilter";

function QueryInput() {
  const name = "query";
  const { value, inputId, handleInputChange } = useFilter({
    name,
    action: actions.setQuery
  });

  return (
    <Fragment>
      <Label for={inputId}>Search games</Label>
      <Input
        type="text"
        id={inputId}
        name={name}
        bsSize="sm"
        onChange={handleInputChange}
        placeholder="Enter game #, team name, or location"
        value={value}
      />
    </Fragment>
  );
}

export default QueryInput;
