import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import PlayerCode from "@/components/Gamesheet/components/TeamsLayout/components/PlayersLineup/components/PlayerCode";
import { formatPeriodName } from "../../../formatPeriodName";
import { formatSoccerTime } from "@/lib/core/formatSoccerTime";

class Goal extends PureComponent {
  static propTypes = {
    period: PropTypes.string,
    time: PropTypes.string,
    scorer: PropTypes.string,
    assistA: PropTypes.string,
    assistB: PropTypes.string,
    goalie: PropTypes.string,
    scorerName: PropTypes.string,
    assistAName: PropTypes.string,
    assistBName: PropTypes.string,
    goalieName: PropTypes.string,
    sport: PropTypes.string,
    rawPeriods: PropTypes.arrayOf(PropTypes.shape({}))
  };

  static defaultProps = {
    period: "N/A",
    time: "N/A",
    scorer: "N/A",
    assistA: "N/A",
    assistB: "N/A",
    goalie: "",
    scorerName: "",
    assistAName: "",
    assistBName: "",
    goalieName: "",
    sport: "hockey",
    rawPeriods: []
  };

  render() {
    const {
      period,
      time,
      scorer,
      assistA,
      assistB,
      goalie,
      scorerName,
      assistAName,
      assistBName,
      goalieName,
      sport,
      rawPeriods
    } = this.props;

    const id = (time + scorerName + goalie).replace(/[\W_]+/g, "_");

    const timeStamp = sport.toLowerCase() === "soccer" ? formatSoccerTime(time, period, rawPeriods) : time;

    return (
      <tr>
        <td>{formatPeriodName(period)}</td>
        <td>{timeStamp}</td>
        <td>
          <PlayerCode scope="scorer" id={id} code={scorer} name={scorerName} />
        </td>
        <td>
          <PlayerCode scope="assist-a" id={id} code={assistA} name={assistAName} />
        </td>
        <td>
          <PlayerCode scope="assist-b" id={id} code={assistB} name={assistBName} />
        </td>
        <td>
          <PlayerCode scope="goalie" id={id} code={goalie} name={goalieName} />
        </td>
      </tr>
    );
  }
}

export default Goal;
