import React, { useState } from "react";
import PropTypes from "prop-types";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export function SingleActionModal(props) {
    const { buttonText, buttonColor, headerText, bodyText, cancelText, confirmText, confirmColor, confirmCallback, disabled, className } = props;

    const [isModalOpen, setModalIsOpen] = useState(false);

    const toggleModal = () => setModalIsOpen(oldVal => !oldVal);

    const onConfirm = () => {
        confirmCallback();
        toggleModal();
    }

    return (
        <>
            <Button color={buttonColor} size="sm" outline onClick={toggleModal} disabled={disabled} className={className}>
                {buttonText}
            </Button>

            <Modal className="lock-rosters-modal" isOpen={isModalOpen} toggle={toggleModal} fade={false} centered>
                <ModalHeader toggle={toggleModal}>{headerText}</ModalHeader>
                <ModalBody>
                    {bodyText}
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-between">
                    <Button type="button" color="secondary" onClick={toggleModal}>
                        {cancelText}
                    </Button>
                    <Button outline type="button" color={confirmColor} onClick={onConfirm}>
                        {confirmText}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

SingleActionModal.propTypes = {
    buttonText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
      ]).isRequired,
    buttonColor: PropTypes.string,
    headerText: PropTypes.string.isRequired,
    bodyText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
      ]).isRequired,
    confirmText: PropTypes.string,
    confirmText: PropTypes.string.isRequired,
    confirmColor: PropTypes.string,
    confirmCallback: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string
};

SingleActionModal.defaultProps = {
    buttonText: "Open",
    buttonColor: "success",
    headerText: "Open",
    bodyText: "Open?",
    cancelText: "Cancel",
    confirmText: "Yes",
    confirmColor: "danger",
    confirmCallback: () => { },
    disabled: false,
    className: ""
};
