import React from "react";

import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";

import Layout from "@/layouts/Layout";
import Head from "@/components/Head";
import Spinner from "@/components/Spinner";
import DashboardForm from "@/components/DashboardForm";
import DashboardFormFooter from "@/components/DashboardFormFooter";
import "../invitation.scss";

export const OptionallySyncTeamGUI = (props) => {
    const {
        codeInfo,
        userTeams,
        merge,
        prototeamId,
        setMergeWithExisting,
        onOptionsSelectChange,
        onBackToVerify,
        onAccept,
        nextScreenIsLoading,
    } = props;

    return (
        <Layout className="page-align-center optionally-merge-teams">
            <Head title="Accept Invitation" />

            <Row className="justify-content-center">
                <Col md="10" lg="6">
                    <DashboardForm>
                        <div className="invitation-container">
                            <p className="header">How would you like to add {codeInfo.team.title} to your account?</p>

                            <FormGroup tag="fieldset">
                                <FormGroup className="top-option" check>
                                    <Input
                                        type="radio"
                                        name="radio"
                                        id="link-to-existing"
                                        onChange={() => setMergeWithExisting(true)}
                                        defaultChecked
                                    />
                                    <Label for="link-to-existing" className="radio-label" check>Link {codeInfo.team.title} to an existing team:</Label>
                                    <Input
                                        type="select"
                                        id="select-team-id"
                                        onChange={onOptionsSelectChange}
                                        disabled={!merge.value}
                                    >
                                        <option value="">Select Team</option>
                                        {userTeams.teams.map(team => (
                                            <option key={team.id} value={team.prototeam.id}>{team.prototeam.title} - Plays in {team.division.title}, {team.season.title}</option>
                                        ))}

                                    </Input>
                                    <div className="warning-container">
                                        <div>Select this option to link {codeInfo.team.title} to an existing team and sync the rosters.</div>
                                    </div>
                                </FormGroup>
                                <FormGroup className="bottom-option" check>
                                    <Input
                                        type="radio"
                                        name="radio"
                                        id="add-new-team"
                                        onChange={() => setMergeWithExisting(false)}
                                    />
                                    <Label className="no-margin radio-label" for="add-new-team">Add {codeInfo.team.title} without linking to an existing team.</Label>
                                    <div className="warning-container">
                                        <div>Select this option to add {codeInfo.team.title} as a new team. They will appear as a new team in your Dashboard with the logo and roster shown on the previous page. <a target="_blank" href="https://help.gamesheet.app/article/50-accepting-an-invitation-code-as-an-existing-user">More Info</a></div>
                                    </div>
                                </FormGroup>
                            </FormGroup>

                            <DashboardFormFooter>
                                <div className="dashboard-form-footer__secondary">
                                    <Button type="button" color="secondary" onClick={onBackToVerify}>
                                        Back
                                    </Button>
                                </div>
                                <div className="dashboard-form-footer__primary">
                                    {nextScreenIsLoading && (
                                        <div className="next-screen-loading-spinner">
                                            <Spinner theme="loader" color="yellow" size="m" />
                                        </div>
                                    )}
                                    <Button type="button" color="success" onClick={onAccept} disabled={(merge.value && prototeamId.value == "") || nextScreenIsLoading}>
                                        {merge.value ? "Continue" : "Accept Invitation"}
                                    </Button>
                                </div>
                            </DashboardFormFooter>
                        </div>
                    </DashboardForm>
                </Col>
            </Row >
        </Layout >
    );
}