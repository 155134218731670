import Papa from "papaparse";

import _camelCase from "lodash/camelCase";
import _trim from "lodash/trim";
import _startCase from "lodash/startCase";

export const ALL_COLUMNS = [
  "id",
  "date",
  "time",
  "endTime",
  "timeZone",
  "visitorDivision",
  "visitorTeam",
  "homeDivision",
  "homeTeam",
  "location",
  "gameNumber",
  "gameType",
  "scorekeeperName",
  "scorekeeperPhone",
  "broadcastProvider"
];

export const REQUIRED_COLUMNS = [
  "date",
  "time",
  "endTime",
  "timeZone",
  "homeDivision",
  "gameNumber",
  "gameType"
];

export class ReadingError extends Error {
  constructor(message) {
    super(message);
    this.name = "ReadingError";
    if ("captureStackTrace" in Error) {
      Error.captureStackTrace(this, ReadingError);
    }
  }
}

function makeHumanReadableColumns(cols) {
  return cols.map(col => `"${_startCase(col)}"`);
}

function reader(file) {
  return new Promise(resolve => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: "greedy",
      transformHeader: hd => _camelCase(hd),
      transform: (value, col) => {
        const trimmedValue = _trim(value);

        if (col === "gameType") {
          return trimmedValue.toLowerCase();
        }

        return trimmedValue;
      },
      complete: res => resolve(res)
    });
  });
}

export default async function read(file) {
  const { data: records, errors } = await reader(file);

  if (errors.length > 0) {
    console.log("ERRORS:", errors);
    throw new ReadingError("CSV file is invalid");
  } else if (records.length === 0) {
    throw new ReadingError("CSV file is empty");
  }

  const columns = Object.keys(records[0]);

  const missingColumns = REQUIRED_COLUMNS.filter(validCol => !columns.includes(validCol));

  if (missingColumns.length > 0) {
    const humanReadableColumns = makeHumanReadableColumns(missingColumns);

    let message;

    if (missingColumns.length === 1) {
      message = `CSV file is missing column ${humanReadableColumns[0]}`;
    } else {
      message = `CSV file is missing the following columns: ${humanReadableColumns.join(", ")}`;
    }

    throw new ReadingError(message);
  }

  return records;
}
