import React from "react";
import PropTypes from "prop-types";

import Division from "../List/Item";

import { useDivisionGroup } from "./hooks";

function DivisionGroup({ seasonTitle, divisions }) {
  const { select, deselect } = useDivisionGroup();

  const seasonsList = divisions.map(({ id, title, ...rest }) => (
    <Division key={id} id={id} title={title} {...rest} select={select} deselect={deselect} />
  ));

  return (
    <div className="report-data-sources-select-list__group">
      <p className="report-data-sources-select-list__group__title list-group-item list-group-item-light">
        {seasonTitle}
      </p>

      {seasonsList}
    </div>
  );
}

DivisionGroup.propTypes = {
  seasonTitle: PropTypes.string.isRequired,
  divisions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  )
};

export default DivisionGroup;
