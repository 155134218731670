import { useCallback, useState } from "react";

export default function useFileField({ value, onChange }) {
  const [error, setError] = useState(null);

  const changeFile = useCallback(
    file => {
      setError(null);
      onChange(file);
    },
    [onChange, setError]
  );

  const clearFile = useCallback(() => changeFile(""), [changeFile]);

  const changeError = useCallback(
    error => {
      clearFile();
      setError(error);
    },
    [clearFile, setError]
  );

  return {
    file: {
      value,
      onChange: changeFile,
      onClear: clearFile
    },
    error: {
      value: error,
      onChange: changeError
    }
  };
}
