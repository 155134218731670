import { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getAssociationList,
  getAssociationListIsLoaded,
  getAssociationListIsLoading,
  getRoleIsGlobal,
  getSelectedAssociations // New selector to get selected associations
} from "@/redux/roleForm/selectors";

import { associationListLoadingRoutine } from "@/redux/roleForm/routines";
import actions from "@/redux/roleForm/actions";

export default function useAssociationSelect() {
  const options = useSelector(getAssociationList);
  const isLoaded = useSelector(getAssociationListIsLoaded);
  const isLoading = useSelector(getAssociationListIsLoading);
  const selectedAssociations = useSelector(getSelectedAssociations); // Fetch selected associations
  const roleIsGlobal = useSelector(getRoleIsGlobal);
  const isOptionSelected = selectedAssociations.length > 0; // Check if any option is selected
  const totalItemsCount = options.reduce((count, group) => count + group.items.length, 0); // Flatten options to get a count of all items across groups
  const isAllOptionsSelected = totalItemsCount > 0 && selectedAssociations.length === totalItemsCount;
  const dispatch = useDispatch();
  
  const toggleSelected = useCallback(
    ({ id, ...rest }) => {

      const isSelected = selectedAssociations.includes(id);
      const action = isSelected ? actions.associationList.deselect: actions.associationList.select;
      
      dispatch(action({ id, ...rest }));
    },
    [dispatch, selectedAssociations]
  );

  useEffect(
    () => {
      if (!isLoaded) {
        dispatch(associationListLoadingRoutine());
      }
    },
    [dispatch, isLoaded]
  );

  return {
    options,
    isLoaded,
    isLoading,
    isEnabled: !roleIsGlobal,
    toggleSelected,
    selectedAssociations, // Return selected associations for UI rendering if needed
    isOptionSelected, // Pass this value to indicate if any items are selected
    isAllOptionsSelected
  };
}
