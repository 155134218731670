import React, { Fragment } from "react";
import PropTypes from "prop-types";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import PageLoader from "@/components/PageLoader";

import CoachPenaltyReport from "@/components/CoachPenaltyReport";

import useCurrentCoach from "../hooks/useCurrentCoach";
import useCoachPenaltyReport from "../hooks/useCoachPenaltyReport";

function CoachPenaltyReportPage({ seasonId, match: { params } }) {
  const { coachId } = params;

  const { seasonIsLoaded, seasonIsLoading, seasonBreadcrumbs } = useCurrentSeasonContext();

  const { isCoachReady, isCoachOperating, isCoachForbidden, coach, externalId } = useCurrentCoach({
    seasonId,
    coachId
  });

  useCoachPenaltyReport(externalId);

  return (
    <ProtectedLayout
      subject={{ type: "penaltyReports", coachId }}
      action="read"
      skip={!isCoachReady && !isCoachForbidden}
    >
      {isCoachReady && seasonIsLoaded ? (
        <Fragment>
          <Head title={`${coach.firstName} ${coach.lastName} Penalty Report`} />

          <Breadcrumbs
            items={seasonBreadcrumbs}
            lastItem={{
              title: `${coach.firstName} ${coach.lastName}`,
              url: `/seasons/${seasonId}/roster/coaches/${coachId}`
            }}
          />

          {externalId !== "" && <CoachPenaltyReport externalId={externalId} />}
        </Fragment>
      ) : (
        <PageLoader isLoading={isCoachOperating || seasonIsLoading} />
      )}
    </ProtectedLayout>
  );
}

CoachPenaltyReportPage.propTypes = {
  seasonId: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      coachId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default CoachPenaltyReportPage;
