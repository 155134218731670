import React from "react";
import PropTypes from "prop-types";

import CoachesList from "@/components/CoachesList";

function CoachesTab({ match: { params } }) {
  const { seasonId } = params;

  return <CoachesList seasonId={seasonId} />;
}

CoachesTab.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      seasonId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default CoachesTab;
