export function designation( value ){

    value = value.toLowerCase();

    switch( value ){
        case "c": return "captain";
        case "a": return "alternate_captain";
    }

    return value;

}