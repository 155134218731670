import { useEffect } from "react";
import { useDispatch } from "react-redux";

import useCurrentAssociationContext from "@/hooks/useCurrentAssociationContext";

import { loadingRoutine } from "@/redux/leaguesList";

import useLeaguesList from "./useLeaguesList";

export default function useLeaguesListLoading() {
  const dispatch = useDispatch();

  const { associationId } = useCurrentAssociationContext();
  const { isListLoaded, isListLoading, isListFailed } = useLeaguesList();

  useEffect(
    () => {
      if (!isListLoaded && !isListLoading && !isListFailed) {
        dispatch(loadingRoutine({ associationId }));
      }
    },
    [dispatch, isListLoaded, isListLoading, isListFailed, associationId]
  );
}
