import { createGetService } from "./serviceMaker";

export const loadPlayerPenaltyReport = createGetService(({ externalId }) => ({
  url: `/reports/players/${externalId}`
}));

export const loadCoachPenaltyReport = createGetService(({ externalId }) => ({
  url: `/reports/coaches/${externalId}`
}));

export const loadRefereeReport = createGetService(({ externalId }) => ({
  url: `/reports/referees/${externalId}`
}));

export const loadTrainerVisitsReport = createGetService(({ seasonIds }) => ({
  url: `/reports/trainer-visits`,
  params: {
    filter: { seasons: seasonIds.join(",") }
  }
}));

export const loadBillingReport = createGetService(({ seasonId }) => ({
  url: "/reports/billing",
  params: {
    filter: { season: seasonId }
  }
}));

export const loadSuspensionReport = createGetService(({ seasonId }) => ({
  url: "/reports/suspensions",
  params: {
    filter: { season: seasonId }
  }
}));

export const loadDivisionPenaltyTypeReport = createGetService(({ divisionIds }) => ({
  url: "/reports/divisions-penalty-type",
  params: {
    filter: { divisions: divisionIds.join(",") }
  }
}));

export const loadTeamPenaltyTypeReport = createGetService(({ teamIds }) => ({
  url: "/reports/teams-penalty-type",
  params: {
    filter: { teams: teamIds.join(",") }
  }
}));

export const loadSeasonPenaltyTypeReport = createGetService(({ seasonIds }) => ({
  url: "/reports/seasons-penalty-type",
  params: {
    filter: { seasons: seasonIds.join(",") }
  }
}));

export const loadPenaltyAccumulationReport = createGetService(({ seasonId, penaltyCodes }) => ({
  url: "/reports/penalty-accumulation",
  params: {
    filter: { season: seasonId, codes: penaltyCodes && penaltyCodes.join(",") }
  }
}));
