import React, { useCallback, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { isCoachCreating } from "@/redux/coaches/selectors";
import { CoachCreatingRoutine } from "@/redux/coaches/routines";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";
import CoachForm from "@/components/CoachForm";
import PageLoader from "@/components/PageLoader";

function NewCoach() {
  const dispatch = useDispatch();

  const isCreating = useSelector(isCoachCreating);

  const { seasonId, seasonIsLoaded, seasonIsLoading, seasonIsForbidden, seasonBreadcrumbs } = useCurrentSeasonContext();

  const onSubmit = useCallback(
    ({ attributes }) => {
      dispatch(CoachCreatingRoutine({ seasonId, attributes }));
    },
    [dispatch, seasonId]
  );

  return (
    <ProtectedLayout
      subject={{ type: "coaches", seasonId }}
      action="create"
      skip={!seasonIsLoaded && !seasonIsForbidden}
    >
      {seasonIsLoaded ? (
        <Fragment>
          <Head title="New Coach" />
          <Breadcrumbs
            items={seasonBreadcrumbs}
            lastItem={{
              title: "Coaches",
              url: `/seasons/${seasonId}/roster/coaches`
            }}
          />

          <TitleBar title="New Coach" />

          <CoachForm onSubmit={onSubmit} isOperating={isCreating} seasonId={seasonId} />
        </Fragment>
      ) : (
        <PageLoader isLoading={seasonIsLoading} />
      )}
    </ProtectedLayout>
  );
}

export default NewCoach;
