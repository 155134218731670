import { connect } from "react-redux";

import { getSeasonRosterCoachesMergeToolIsEnabled } from "@/redux/seasonRoster/selectors";
import { seasonRosterActions } from "@/redux/seasonRoster/actions";

import CoachesListCoach from "./Coach";

const mapStateToProps = (state, ownProps) => ({
  isMergeToolEnabled: getSeasonRosterCoachesMergeToolIsEnabled(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  selectForMerge: () => dispatch(seasonRosterActions.coaches.mergeTool.selectCoach(ownProps.id)),
  deselectForMerge: () => dispatch(seasonRosterActions.coaches.mergeTool.deselectCoach(ownProps.id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoachesListCoach);
