import { useCallback } from "react";
import _isArray from "lodash/isArray";

export default function usePlayerOfTheGameFieldset({ value, onChange, errors }) {
  const { coupon, message } = value || {};

  const enabled = value !== null;

  const toggle = useCallback(
    ({ target: { checked } }) => {
      onChange(checked ? { coupon: "", message: "" } : null);
    },
    [onChange]
  );

  const changeCoupon = useCallback(
    file => {
      onChange({ ...value, coupon: file });
    },
    [onChange, value]
  );

  const changeMessage = useCallback(({ target: { value: message } }) => onChange({ ...value, message }), [
    onChange,
    value
  ]);

  return {
    enabled: {
      checked: enabled,
      onChange: toggle
    },
    coupon: {
      value: coupon,
      onChange: changeCoupon,
      errors: (errors.find(error => _isArray(error) && error[0] === "coupon") || [])[1] || []
    },
    message: {
      value: message,
      onChange: changeMessage
    }
  };
}
