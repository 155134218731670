import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { actions } from "@/redux/completedGamesFilter";

export default function useFilterClearing() {
  const dispatch = useDispatch();

  useEffect(
    () => {
      return () => dispatch(actions.clear());
    },
    [dispatch]
  );
}
