const selectRoot = state => state.registration;

export const getRegistration = state => selectRoot(state);
export const getRegistrationFields = state => selectRoot(state).fields;

export const getRegistrationAttributes = state => {
  const fields = getRegistrationFields(state);

  let attributes = {};

  for (let [field, { value }] of Object.entries(fields)) {
    attributes[field] = value;
  }

  return attributes;
};

export const getRegistrationIsLoading = state => selectRoot(state).isLoading;
