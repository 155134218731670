import { handleActions } from "redux-actions";

import { teamListLoadingRoutine as loadingRoutine } from "../routines";
import actions from "../actions";

const initialState = {};

function updateDivisionTeams(state, divisionId, data) {
  return {
    ...state,
    [divisionId]: {
      ...state[divisionId],
      ...data
    }
  };
}

function reduceDivisionSelected(state, { payload: divisionId }) {
  return {
    ...state,
    [divisionId]: {
      isLoaded: false,
      isLoading: false,
      ids: [],
      data: {},
      ...state[divisionId]
    }
  };
}

function reduceLoadingRequest(state, { payload }) {
  const { divisionId } = payload;

  return updateDivisionTeams(state, divisionId, { isLoading: true });
}

function reduceLoadingSuccess(state, { payload }) {
  const { divisionId, ids, teams } = payload;

  const data = teams.reduce((result, { id, title }) => ({ ...result, [id]: { title } }), {});

  return updateDivisionTeams(state, divisionId, {
    ids,
    data,
    isLoaded: true
  });
}

function reduceLoadingFailure(state, { payload }) {
  const { divisionId } = payload;

  return updateDivisionTeams(state, divisionId, { isLoaded: false });
}

function reduceLoadingFulfill(state, { payload }) {
  const { divisionId } = payload;

  return updateDivisionTeams(state, divisionId, { isLoading: false });
}

export default handleActions(
  {
    [actions.setDivisionId]: reduceDivisionSelected,
    [loadingRoutine.REQUEST]: reduceLoadingRequest,
    [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [loadingRoutine.FAILURE]: reduceLoadingFailure,
    [loadingRoutine.FULFILL]: reduceLoadingFulfill,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
