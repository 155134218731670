const overwriteOnFalsey = ['affiliated'];

export function mergeTeamRoster( existing, incoming ){

    // clone
    existing = [...(existing||[])];
    
    // update existing
    existing = existing.map( entity => {
        const match = incoming.find( i => i.id === entity.id);

        if(!!match){
            Object.keys(match).reduce(( record, key ) => {
                if(!match[key] && !overwriteOnFalsey.includes(key)){
                    delete match[key];
                }
                return match;
            }, match);
        }

        return !!match && { ...entity, ...match } || entity;
    });
    
    // add new
    incoming.filter( i => !existing.find(e => e.id === i.id)).forEach( record => existing.push( record ));

    
    // // merge records
    // incoming.forEach( entity => {
        
    //     let match;
    //     const index = existing.map(e => e.id);
    //     for ( let x of existing ){
    //         match = x.id === entity.id;
    //         const i = index.indexOf(x.id);

    //         if(match){
    //             delete existing[i];
    //             existing.push(Object.keys(entity).reduce(( record, key ) => {
    //                 return (!!incoming[key] || overwriteOnFalsey.includes(key)) && { ...record, [key]: incoming[key] } || record;
    //             }, {}));
    //         }

    //     }
        
    //     if(!match){
    //         existing.push(entity);
    //     }

        
    // })

    return existing;

}