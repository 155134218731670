import React, { useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { Link, useHistory } from "react-router-dom";

import { Can, canCheck } from "@/components/Ability";
import PageLoader from "@/components/PageLoader";
import EmptyListMessage from "@/components/EmptyListMessage";
import TotalCount from "@/components/TotalCount";
import Spinner from "@/components/Spinner";
import { LockImg } from "@/components-16.8/LockImg";
import ActionsDropdown from "./ActionsDropdown";
import { useDivisionsList } from "./hooks";
import { PaginationBar } from "@/components-16.8/PaginationBar";
import { getCustomHeader } from "@/components/TeamsList/getCustomSortHeader";

export const PAGE_SIZE = 25;

function DivisionsList({ pageNumber, sort }) {
  const {
    isListLoading,
    isListLoaded,
    divisions,
    totalCount
  } = useDivisionsList();
  const isMobile = window.innerWidth < 780 ? true : false;
  const [gridApi, setGridApi] = useState(null);
  const history = useHistory();
  const [ locking, setLocking ] = useState(false);

  const onGridReady = params => {
    setGridApi(params.api);
  };

  const canDelete = canCheck({ I: "delete", this: "divisions" });
  const canUpdate = canCheck({ I: "update", this: "divisions" });

  const paginationRows = {
    currentLow: (pageNumber - 1) * PAGE_SIZE + 1,
    currentHigh: Math.min(pageNumber * PAGE_SIZE, totalCount),
    total: totalCount
  };

  const paginationPages = {
    current: pageNumber,
    total: Math.ceil(totalCount / PAGE_SIZE)
  };

  const setUrlParam = (label, val) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(label, val);
    history.push({
      pathname: location.pathname,
      search: searchParams.toString()
    });
  };

  const setUrlPageParam = page => {
    setUrlParam("page", page);
  };

  const setSort = sort => {
    setUrlParam("sort", sort);
  };

  if (isListLoaded && divisions.length < 1) {
    return (
      <EmptyListMessage>
        There are no divisions.
        <br />
        Create a new one.
      </EmptyListMessage>
    );
  }
  return (
    <PageLoader isLoading={isListLoading} isLoaded={isListLoaded}>
      <div className="d-flex flex-row justify-content-between">
        <TotalCount plural="divisions" singular="division" totalCount={totalCount} />
        {locking && <Spinner theme="loader" color="yellow" size="m" />}
      </div>
      <div className="ag-theme-alpine ag-gs-theme">
        <AgGridReact
          defaultColDef={{
            resizable: true,
            wrapText: true,
            autoHeight: true
          }}
          pagination={false}
          rowData={divisions}
          onGridReady={onGridReady}
          domLayout={"autoHeight"}
          suppressCellSelection={true}
          rowHeight={44}
          enableCellTextSelection={true}
          getRowNodeId={data => data.id}
        >
          <AgGridColumn
            headerName="Title"
            field="title"
            lockPosition={isMobile}
            minWidth="200"
            flex="2"
            sortable={true}
            headerComponent={getCustomHeader(setSort, sort, "title")}
            cellRendererFramework={params => {
              const { seasonId, id } = params.data;
              return (
                <Link to={`/seasons/${seasonId}/divisions/${id}/teams`}>{params.value}</Link>
              ) 
            }}
          />
          <AgGridColumn
            headerName="Teams"
            field="numTeams"
            lockPosition={isMobile}
            minWidth="100"
            width="100"
            sortable={true}
            headerComponent={getCustomHeader(setSort, sort, "numTeams")}
            cellRendererFramework={params => {
              const { numTeams, hasUnlockedTeams } = params.data;
              return (
                <>
                  <span>{numTeams}</span>
                  {numTeams > 0 && !hasUnlockedTeams ? (
                    <LockImg height="12" className="d-inline ml-1" subject="divisions" />
                  ) : (
                    <></>
                  )}
                </>
              );
            }}
          />

          <AgGridColumn
            headerName=""
            field="id"
            lockPosition={isMobile}
            minWidth="120"
            flex="1"
            type="rightAligned"
            sortable={false}
            hide={!canDelete && !canUpdate}
            cellRendererFramework={params => {
              const { seasonId, id, title, numTeams, hasUnlockedTeams } = params.data;
              return (
                <ActionsDropdown
                  seasonId={seasonId}
                  id={id}
                  title={title}
                  numTeams={numTeams}
                  hasUnlockedTeams={hasUnlockedTeams}
                  setLocking={setLocking}
                />
              );
            }}
          />
        </AgGridReact>
        <PaginationBar
          rows={paginationRows}
          pages={paginationPages}
          nav={{
            onFirst: () => setUrlPageParam(1),
            onBack: () => setUrlPageParam(Math.max(pageNumber - 1, 1)),
            onForward: () => setUrlPageParam(Math.min(Number(pageNumber) + 1, paginationPages.total)),
            onLast: () => setUrlPageParam(paginationPages.total)
          }}
        />
      </div>
    </PageLoader>
  );
}

export default DivisionsList;
