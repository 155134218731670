import { createRoutine } from "redux-saga-routines";

export const associationListLoadingRoutine = createRoutine("ROLE_FORM/ASSOCIATION_LIST/LOADING");

export const leagueListLoadingRoutine = createRoutine("ROLE_FORM/LEAGUE_LIST/LOADING");

export const seasonListLoadingRoutine = createRoutine("ROLE_FORM/SEASON_LIST/LOADING");

export const divisionListLoadingRoutine = createRoutine("ROLE_FORM/DIVISION_LIST/LOADING");

export const teamListLoadingRoutine = createRoutine("ROLE_FORM/TEAM_LIST/LOADING");

export const initRoutine = createRoutine("ROLE_FORM/INITIALIZING");
