import React, { PureComponent } from "react";

import PageLoader from "@/components/PageLoader";

import "./Progress.scss";

class RefereesListMergeToolProgress extends PureComponent {
  render() {
    return (
      <div className="referees-list__merge-tool__progress">
        <h1>Merging referees. Please wait…</h1>
        <PageLoader isLoading={true} />
      </div>
    );
  }
}

export default RefereesListMergeToolProgress;
