import React, { useCallback } from "react";
import PropTypes from "prop-types";

import PointsInput from "./PointsInput";

function GameValues({ value, onChange }) {
  const { regulation, overtime } = value;
  const { shutout } = regulation;

  const changeRegulationValue = useCallback(
    ({ name, value: points }) => onChange({ ...value, regulation: { ...regulation, [name]: points } }),
    [value, regulation, onChange]
  );

  const changeOvertimeValue = useCallback(
    ({ name, value: points }) => onChange({ ...value, overtime: { ...overtime, [name]: points } }),
    [value, overtime, onChange]
  );

  const changeShutoutValue = useCallback(
    ({ value: shutout }) =>
      onChange({
        ...value,
        regulation: { ...regulation, shutout },
        overtime: { ...overtime, shutout }
      }),
    [onChange, value, regulation, overtime]
  );

  return (
    <table className="point-system__table">
      <thead>
        <tr>
          <th />
          <th>Win</th>
          <th>Tie</th>
          <th>Loss</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Regulation Game:</th>
          <td>
            <PointsInput name="win" value={regulation.win} onChange={changeRegulationValue} />
          </td>
          <td>
            <PointsInput name="tie" value={regulation.tie} onChange={changeRegulationValue} />
          </td>
          <td>
            <PointsInput name="loss" value={regulation.loss} onChange={changeRegulationValue} />
          </td>
        </tr>

        <tr>
          <th>Game with OT / Shootout:</th>
          <td>
            <PointsInput name="win" value={overtime.win} onChange={changeOvertimeValue} />
          </td>
          <td>
            <PointsInput name="tie" value={overtime.tie} onChange={changeOvertimeValue} />
          </td>
          <td>
            <PointsInput name="loss" value={overtime.loss} onChange={changeOvertimeValue} />
          </td>
        </tr>

        <tr>
          <th>Shutout:</th>
          <td>
            <PointsInput value={shutout} onChange={changeShutoutValue} />
          </td>
          <td colSpan="2" />
        </tr>
      </tbody>
    </table>
  );
}

GameValues.propTypes = {
  value: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired
};

export default GameValues;
