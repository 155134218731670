import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Popover, PopoverBody } from "reactstrap";

class PlayerCode extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    scope: PropTypes.string.isRequired,
    code: PropTypes.string,
    name: PropTypes.string
  };

  static defaultProps = {
    code: "N/A",
    name: "N/A"
  };

  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }

  openPopover = () => {
    this.setState({
      open: true
    });
  };

  closePopover = () => {
    this.setState({
      open: false
    });
  };

  render() {
    const { id, scope, code, name } = this.props;

    if (!code) {
      return <span />;
    }

    const elementId = `playerCode-${scope}-${id}`;

    const { open } = this.state;

    return (
      <div
        id={elementId}
        onFocus={this.openPopover}
        onMouseOver={this.openPopover}
        onBlur={this.closePopover}
        onMouseOut={this.closePopover}
      >
        <Popover className="playerCode__popover" placement="left" isOpen={open} target={elementId}>
          <PopoverBody>{name}</PopoverBody>
        </Popover>
        {code}
      </div>
    );
  }
}

export default PlayerCode;
