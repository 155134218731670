import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from "reactstrap";

import { Can } from "@/components/Ability";

import { getStatsHref } from "@/lib/core/useOTP";

import useActionsDropdown from "./hooks/useActionsDropdown";

function ActionsDropdown(props) {
  const { member, teamId, seasonId, isDeleting, disabled, rosterLocked, otp = "" } = props;

  const { isOpen, toggleIsOpened, triggerEdit, triggerDelete, ignoreLock } = useActionsDropdown(member);

  const redirectHandler = (url) => {
    window.location.href = url;
  };

  return (
    <Can I="read" this={{ type: "rosterMemberActions", teamId }}>
      {isDeleting ? (
        <Button color="danger" size="sm" outline disabled>
          Removing…
        </Button>
      ) : (
        <ButtonDropdown isOpen={isOpen} toggle={toggleIsOpened}>
          <DropdownToggle caret size="sm" color="secondary" outline disabled={disabled}>
            Actions
          </DropdownToggle>
          <DropdownMenu right>
            {(!rosterLocked || ignoreLock) && (
              <Can I="update" this={{ type: "roster", teamId }}>
                <DropdownItem onClick={triggerEdit}>Edit</DropdownItem>
              </Can>
            )}
            {member.type === "players" && (
              <Can I="update" this={member}>
                <DropdownItem
                  tag={Link}
                  to={{
                    pathname: `/seasons/${seasonId}/roster/${member.type}/${member.id}/edit`,
                    state: { from: { type: "teamRoster", seasonId, teamId } }
                  }}
                >
                  Edit Player Profile
                </DropdownItem>
              </Can>
            )}
            <Can I="update" this={member}>
              <DropdownItem tag={Link} to={`/seasons/${seasonId}/roster/${member.type}/${member.id}`}>
                Manage {member.title} Teams
              </DropdownItem>
            </Can>
            {member.type === "players" && (
              <Fragment>
                <DropdownItem onClick={() => redirectHandler(getStatsHref(`/seasons/${seasonId}/players/${member.id}`, otp))}>
                  View Player Statistics
                </DropdownItem>
                <DropdownItem onClick={() => redirectHandler(getStatsHref(`/seasons/${seasonId}/goalies/${member.id}`, otp))}>
                  View Goalie Statistics
                </DropdownItem>
              </Fragment>
            )}
            <Can I="read" a="reports">
              <DropdownItem tag={Link} to={`/seasons/${seasonId}/roster/${member.type}/${member.id}/penalty-report`}>
                View Penalty Report
              </DropdownItem>
            </Can>
            {(!rosterLocked || ignoreLock) && (
              <Can I="update" this={{ type: "roster", teamId }}>
                <DropdownItem divider />
                <DropdownItem className="text-danger" onClick={triggerDelete}>
                  Remove
                </DropdownItem>
              </Can>
            )}
          </DropdownMenu>
        </ButtonDropdown>
      )}
    </Can>
  );
}

ActionsDropdown.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  seasonId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  rosterLocked: PropTypes.bool
};

export default ActionsDropdown;
