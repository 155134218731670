import React from "react";
import { Table } from "reactstrap";

import TotalCount from "@/components/TotalCount";
import EmptyListMessage from "@/components/EmptyListMessage";
import ListControls from "@/components/ListControls";
import DashboardForm from "@/components/DashboardForm";

import AddCoachButton from "./AddCoachButton";
import CreateCoachButton from "./CreateCoachButton";
import Coach from "./Coach";
import { CoachForm } from "./Form";

import useRoster from "./hooks/useRoster";

import "./CoachesRoster.scss";

function CoachesRoster() {
  const {
    team,
    coaches,
    totalCoachesCount,
    filteredCoachesCount,
    isEditing: isRosterEditing,
    ignoreLock,
    isOperating
  } = useRoster();

  if (coaches.length < 1) {
    return (
      <EmptyListMessage>
        <p>There are no coaches</p>
        {(!team.data.rosterLocked || ignoreLock) && (
          <p>
            <AddCoachButton team={team}>+ Add Coach from Season</AddCoachButton>{" "}
            <CreateCoachButton team={team}>Create New Coach</CreateCoachButton>
          </p>
        )}
      </EmptyListMessage>
    );
  }

  return (
    <DashboardForm disabled={isOperating}>
      <div className="coaches-roster">
        {(!team.data.rosterLocked || ignoreLock) && (
          <ListControls>
            <AddCoachButton team={team} outline disabled={isRosterEditing || isOperating}>
              Add Coach from Season
            </AddCoachButton>
            <CreateCoachButton team={team} disabled={isRosterEditing}>
              + New Coach
            </CreateCoachButton>
          </ListControls>
        )}

        <TotalCount
          plural="coaches"
          singular="coach"
          totalCount={totalCoachesCount}
          filteredCount={filteredCoachesCount}
        />

        <Table striped borderless>
          <thead>
            <tr>
              <th scope="col" className="coaches-roster__first-name">
                First name
              </th>
              <th scope="col" className="coaches-roster__last-name">
                Last name
              </th>
              <th scope="col" className="coaches-roster__external-id">
                External Id
              </th>
              <th scope="col" className="coaches-roster__position">
                Position
              </th>
              <th scope="col" className="coaches-roster__actions">
                &nbsp;
              </th>
            </tr>
          </thead>
          <tbody>
            {coaches.map(
              ({ id, isEditing, ...rest }) =>
                isEditing ? (
                  <CoachForm key={id} coachId={id} />
                ) : (
                  <Coach muted={isRosterEditing} key={id} id={id} team={team} {...rest} />
                )
            )}
          </tbody>
        </Table>
      </div>
    </DashboardForm>
  );
}

export default CoachesRoster;
