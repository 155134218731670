import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import "./Item.scss";

class GameNotesListItem extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    dateCreated: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  };

  render() {
    const { author, dateCreated, text } = this.props;

    return (
      <div className="list-group-item game-notes__list__item">
        <div className="game-notes__list__item__header">
          <span className="game-notes__list__item__author">{author}</span>
          <span className="game-notes__list__item__date">{dateCreated}</span>
        </div>
        <p className="game-notes__list__item__text">{text}</p>
      </div>
    );
  }
}

export default GameNotesListItem;
