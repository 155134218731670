import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { loadingRoutine } from "@/redux/teamPickerField";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";
import useTeamPickerFieldMeta from "./useTeamPickerFieldMeta";

export default function useTeamPickerFieldLoading() {
  const dispatch = useDispatch();
  const { seasonId } = useCurrentSeasonContext();
  const { isLoaded, isLoading } = useTeamPickerFieldMeta();

  useEffect(
    () => {
      if (!isLoading && !isLoaded) {
        dispatch(loadingRoutine({ seasonId }));
      }
    },
    [dispatch, seasonId, isLoading, isLoaded]
  );

  return {
    isLoaded,
    isLoading
  };
}
