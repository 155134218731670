import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getRefereeReportIsLoaded,
  getRefereeReportIsForbidden,
  getRefereeReportReferee
} from "@/redux/refereeReport/selectors";

import { refereeReportLoadingRoutine } from "@/redux/refereeReport/routines";
import actions from "@/redux/refereeReport/actions";

export default function useRefereeReportPage(externalId) {
  const dispatch = useDispatch();
  const isLoaded = useSelector(getRefereeReportIsLoaded);
  const isForbidden = useSelector(getRefereeReportIsForbidden);
  const referee = useSelector(getRefereeReportReferee);

  useEffect(
    () => {
      dispatch(refereeReportLoadingRoutine({ externalId }));

      return () => {
        dispatch(actions.report.clear());
      };
    },
    [dispatch, externalId]
  );

  return {
    isLoaded,
    isForbidden,
    referee
  };
}
