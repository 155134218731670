import { put, all, takeLatest, call } from "redux-saga/effects";
import build from "redux-object";

import { gamesheetAPIRequest } from "@/redux/api/sagas";
import { loadTrainerVisitsReport } from "@/lib/api/reports";

import { trainerVisitsReportLoadingRoutine } from "./routines";

function* reportLoadingSaga({ payload: { seasonIds } }) {
  yield put(trainerVisitsReportLoadingRoutine.request());

  try {
    const { data, meta } = yield call(gamesheetAPIRequest, loadTrainerVisitsReport, {
      seasonIds
    });

    const divisionTrainerVisitsReports = build(data, "divisionTrainerVisitsReports") || [];

    yield put(
      trainerVisitsReportLoadingRoutine.success({
        ...meta,
        divisionTrainerVisitsReports
      })
    );
  } catch (error) {
    const responseStatus = error.response && error.response.status;

    yield put(trainerVisitsReportLoadingRoutine.failure({ error, responseStatus }));
  } finally {
    yield put(trainerVisitsReportLoadingRoutine.fulfill());
  }
}

export function* trainerVisitsReportFlow() {
  yield all([takeLatest(trainerVisitsReportLoadingRoutine.TRIGGER, reportLoadingSaga)]);
}
