import React from "react";
import { Link } from "react-router-dom";

import useCurrentAssociationContext from "@/hooks/useCurrentAssociationContext";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import Head from "@/components/Head";
import TitleBar from "@/components/TitleBar";
import Breadcrumbs from "@/components/Breadcrumbs";
import PageLoader from "@/components/PageLoader";
import AbilityButton from "@/components/AbilityButton";

import LeaguesList, { useLeaguesListLoading, useLeaguesList } from "@/components/LeaguesList";

function LeaguesIndex() {
  const {
    associationId,
    associationIsLoading,
    associationIsLoaded,
    associationBreadcrumbs
  } = useCurrentAssociationContext();

  useLeaguesListLoading();

  const { isListLoading, isListLoaded, isListForbidden } = useLeaguesList();

  return (
    <ProtectedLayout condition={!isListForbidden} className="leagues-page">
      <PageLoader isLoading={associationIsLoading} isLoaded={associationIsLoaded}>
        <Head title="Leagues" />

        <Breadcrumbs items={associationBreadcrumbs} lastItemNoUrl />

        <TitleBar title="Leagues">
          <AbilityButton
            subject={{ type: "leagues", associationId }}
            action="create"
            outline
            color="success"
            size="sm"
            tag={Link}
            to={`/associations/${associationId}/leagues/new`}
          >
            + New League
          </AbilityButton>
        </TitleBar>

        <PageLoader isLoading={isListLoading} isLoaded={isListLoaded}>
          <LeaguesList />
        </PageLoader>
      </PageLoader>
    </ProtectedLayout>
  );
}

export default LeaguesIndex;
