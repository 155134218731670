import React from 'react';
import { Button } from "reactstrap";
import { Link } from 'react-router-dom';

import DashboardFormFooter from "@/components/DashboardFormFooter";

export function ImportFailure({ seasonId }) {

  // render the wizard
  return (
    <>
      <div className="csv-import__error__text">
        <p>Import failed</p>
      </div>
      <DashboardFormFooter>
        <div className="dashboard-form-footer__primary">
          <Link to={`/seasons/${seasonId}/referees`}><Button type="button" color="primary">Go To Season Referees</Button></Link>
        </div>
      </DashboardFormFooter>
    </>
  );
}