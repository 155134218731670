import React from "react";

import { Row, Col, Button } from "reactstrap";

import Layout from "@/layouts/Layout";
import Head from "@/components/Head";
import Spinner from "@/components/Spinner";
import DashboardForm from "@/components/DashboardForm";
import DashboardFormFooter from "@/components/DashboardFormFooter";
import { TeamReview } from "./TeamReview";
import "../invitation.scss";

export const MergeConfirmationGUI = (props) => {
    const {
        mergedTeam,
        prototeamToLink,
        nextScreenIsLoading,
        onRejectMerge,
        onMergeIsCorrect,
    } = props;

    return (
        <Layout className="page-align-center merge-confirmation">
            <Head title="Accept Invitation" />

            <Row className="justify-content-center">
                <Col md="10" lg="6">
                    <DashboardForm>
                        <div className="invitation-container">
                            <p className="header">You are linking {mergedTeam.title} with {prototeamToLink.title}</p>
                            <p className="sub-header">These rosters will be combined into one and any future roster changes will affect both rosters.</p>
                            <TeamReview teamInfo={mergedTeam} />
                        </div>
                        <DashboardFormFooter>
                            <div className="dashboard-form-footer__secondary">
                                <Button type="button" color="secondary" onClick={onRejectMerge}>
                                    Back
                                </Button>
                            </div>
                            <div className="dashboard-form-footer__primary">
                                {nextScreenIsLoading && (
                                    <div className="next-screen-loading-spinner">
                                        <Spinner theme="loader" color="yellow" size="m" />
                                    </div>
                                )}
                                <Button
                                    type="button"
                                    color="success"
                                    onClick={onMergeIsCorrect}
                                    disabled={nextScreenIsLoading}
                                >
                                    Accept Invitation
                                </Button>
                            </div>
                        </DashboardFormFooter>
                    </DashboardForm>
                </Col>
            </Row >
        </Layout >
    );
}
