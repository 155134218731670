import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { loadingRoutine, getMeta, getTeam, actions, getIsEditing } from "@/redux/teamRoster";

export default function useTeamRoster({ id, seasonId }) {
  const dispatch = useDispatch();

  const { isLoading, isLoaded, isForbidden, isOperating, isArchived } = useSelector(getMeta);

  const isEditing = useSelector(getIsEditing);
  const team = useSelector(getTeam);

  useEffect(
    () => {
      dispatch(loadingRoutine({ id, seasonId }));

      return () => dispatch(actions.clear());
    },
    [dispatch, id, seasonId]
  );

  const { association, league, season, division } = team;

  const breadcrumbs = isLoaded
    ? [
        {
          url: `/associations`,
          title: "Associations"
        },
        {
          url: `/associations/${association.id}/leagues`,
          title: association.title
        },
        {
          url: `/leagues/${league.id}/seasons`,
          title: league.title
        },
        {
          url: `/seasons/${season.id}`,
          title: season.title
        },
        {
          url: `/seasons/${season.id}/divisions/${division.id}/teams`,
          title: division.title
        }
      ]
    : [];

  return {
    isLoading,
    isLoaded,
    isForbidden,
    isOperating,
    isEditing,
    isArchived,
    team,
    breadcrumbs
  };
}
