import React, { Fragment, useState, useEffect } from "react";
import { Label, Input } from "reactstrap";
import PropTypes from "prop-types";
import { debounce } from 'lodash';

function QueryInput({ inputId, value, setValue }) {
  const updateParent = useState(() => debounce((v) => setValue(v), 300))[0];
  const [query, setQuery] = useState(value);
  const name = "query";

  useEffect(() => {
    setQuery(value);
  }, [value])

  useEffect(() => {
    if (value !== query) {
      updateParent(query);
    }
  }, [query]);

  return (
    <Fragment>
      <Label for={inputId}>Search games</Label>
      <Input
        type="text"
        id={inputId}
        name={name}
        bsSize="sm"
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Enter game #, team name, or location"
        value={query}
      />
    </Fragment>
  );
}

QueryInput.propTypes = {
  inputId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired
};

export default QueryInput;
