import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import {
  getGamesListCurrentPage,
  getGamesListTotalPages,
  getGamesListIsLoaded,
  getGamesListIsLoading,
  getGamesListTotalCount,
  getGamesListFilteredCount
} from "@/redux/games/selectors";

import { getFilterIsDirty } from "@/redux/completedGamesFilter";

import { loadGamesRoutine } from "@/redux/games/routines";

export default function useCompletedGamesListPagination() {
  const { seasonId } = useCurrentSeasonContext();
  const dispatch = useDispatch();

  const totalPages = useSelector(getGamesListTotalPages);
  const currentPage = useSelector(getGamesListCurrentPage);
  const isListLoaded = useSelector(getGamesListIsLoaded);
  const isListLoading = useSelector(getGamesListIsLoading);
  const totalCount = useSelector(getGamesListTotalCount);
  const filteredCount = useSelector(getGamesListFilteredCount);
  const isFilterDirty = useSelector(getFilterIsDirty);

  const loadPage = useCallback(pageNumber => dispatch(loadGamesRoutine({ pageNumber, seasonId })), [
    dispatch,
    seasonId
  ]);

  return {
    loadPage,
    totalPages,
    currentPage,
    isListLoaded,
    isListLoading,
    totalCount,
    filteredCount,
    isFilterDirty
  };
}
