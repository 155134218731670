import { createPostService, createGetService, createDeleteService } from "./serviceMaker";

export const createInvitation = createPostService(({ attributes }) => ({
  url: "/invitations",
  attributes
}));

export const loadInvitations = createGetService(({ limit, offset, filter }) => ({
  url: "/invitations",
  params: { limit, offset, filter }
}));

export const loadInvitation = createGetService(({ code }) => ({
  url: `/invitations/${code}`
}));

export const deleteInvitation = createDeleteService(({ id }) => ({
  url: `/invitations/${id}`
}));
