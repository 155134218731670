const VALID_PLAYER_DESIGNATIONS = ["captain", "alternate_captain"];

export default function validateDesignation({ designation }) {
  if (
    designation !== "" &&
    !VALID_PLAYER_DESIGNATIONS.includes(designation.toLowerCase())
  ) {
    return [{ field: "designation", error: "invalid" }];
  }

  return [];
}
