import { handleActions } from "redux-actions";
import moment from "moment";

import { loadingRoutine } from "../routines";
import actions from "../actions";

const initialState = {
  id: "",
  firstName: "",
  lastName: "",
  externalId: "",
  photoUrl: "",
  biography: "",
  height: "",
  weight: "",
  shotHand: "",
  birthdate: null,
  hometown: "",
  country: "",
  province: "",
  draftedBy: "",
  committedTo: "",
  season: { id: "", title: "" },
  league: { id: "", title: "" },
  association: { id: "", title: "" },
  teams: []
};

function reduceLoadingSuccess(state, { payload: { player } }) {
  const {
    id,
    firstName,
    lastName,
    externalId,
    photoUrl,
    biography,
    height,
    weight,
    shotHand,
    birthdate,
    hometown,
    country,
    province,
    draftedBy,
    committedTo,
    season,
    league,
    association,
    teams
  } = player;

  return {
    id,
    firstName,
    lastName,
    externalId,
    photoUrl,
    biography,
    height,
    weight,
    shotHand,
    birthdate: birthdate ? moment(birthdate).format("YYYY-MM-DD") : null,
    hometown,
    country,
    province,
    draftedBy,
    committedTo,
    season: { id: season.id, title: season.title },
    league: { id: league.id, title: league.title },
    association: { id: association.id, title: association.title },
    teams: teams.map(({ id, title, season }) => ({
      id,
      title,
      seasonId: season.id
    }))
  };
}

export default handleActions(
  {
    [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
