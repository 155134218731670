import { createRoutine } from "redux-saga-routines";

export const loadingRoutine = createRoutine("TEAM_ROSTER/LOADING");
export const updatingRoutine = createRoutine("TEAM_ROSTER/UPDATING");
export const lockingRoutine = createRoutine("TEAM_ROSTER/LOCKING");

// Players
export const playerFieldValidatingRoutine = createRoutine("TEAM_ROSTER/PLAYERS_FORM/FIELD_VALIDATING");

export const playerValidatingRoutine = createRoutine("TEAM_ROSTER/PLAYERS_FORM/VALIDATING");

export const playerSubmittingRoutine = createRoutine("TEAM_ROSTER/PLAYERS_FORM/SUBMITTING");

export const playerSavingRoutine = createRoutine("TEAM_ROSTER/PLAYERS/SAVING");

export const playerRemovingRoutine = createRoutine("TEAM_ROSTER/PLAYERS/REMOVING");

// Coaches
export const coachFieldValidatingRoutine = createRoutine("TEAM_ROSTER/COACHES_FORM/FIELD_VALIDATING");

export const coachValidatingRoutine = createRoutine("TEAM_ROSTER/COACHES_FORM/VALIDATING");

export const coachSubmittingRoutine = createRoutine("TEAM_ROSTER/COACHES_FORM/SUBMITTING");

export const coachSavingRoutine = createRoutine("TEAM_ROSTER/COACHES/SAVING");

export const coachRemovingRoutine = createRoutine("TEAM_ROSTER/COACHES/REMOVING");
