import { createPostService, createGetService, createPatchService, createDeleteService } from "./serviceMaker";

const makeCoachURL = ({ seasonId, identity }) => ({
  url: `/seasons/${seasonId}/coaches/${identity.id}`
});

const makeSeasonCoachesURL = ({ seasonId }) => ({
  url: `/seasons/${seasonId}/coaches`
});

export const createCoach = createPostService(({ seasonId, attributes }) => ({
  ...makeSeasonCoachesURL({ seasonId }),
  attributes
}));

export const loadCoach = createGetService(({ seasonId, identity }) => ({
  ...makeCoachURL({ seasonId, identity }),
  params: { include: "teams" }
}));

export const loadCoaches = createGetService(({ seasonId, limit, offset, query, include = "teams" }) => ({
  ...(seasonId ? makeSeasonCoachesURL({ seasonId }) : { url: "/coaches" }),
  params: {
    include,
    limit,
    offset,
    filter: { query: query || undefined }
  }
}));

export const updateCoach = createPatchService(({ seasonId, identity, attributes }) => ({
  ...makeCoachURL({ seasonId, identity }),
  attributes
}));

export const deleteCoach = createDeleteService(makeCoachURL);

export const importCoach = createPostService(({ seasonId, firstName, lastName, externalId, attributes }) => ({
  url: `/seasons/${seasonId}/coaches/import`,
  attributes: attributes || { firstName, lastName, externalId }
}));

export const mergeCoaches = createPatchService(({ seasonId, winnerId, firstName, lastName, externalId, coachIds }) => ({
  url: `/seasons/${seasonId}/coaches/${winnerId}/merge`,
  attributes: {
    firstName,
    lastName,
    externalId
  },
  identity: { type: "coaches", id: winnerId },
  relationships: {
    duplicates: {
      data: coachIds.filter(pid => pid !== winnerId).map(id => ({ type: "coaches", id }))
    }
  }
}));
