import React from "react";
import PropTypes from "prop-types";
import { Table } from "reactstrap";

import EmptyListMessage from "@/components/EmptyListMessage";

import TeamMemberPenaltyReportMajorPenaltiesPenalty from "./Penalty";

import "./MajorPenalties.scss";

function MajorPenalties({ penalties }) {
  // console.log("MajorPenalties", penalties)
  return (
    <div className="team-member-penalty-report__section">
      <h3 className="team-member-penalty-report__section-title">Incidents &amp; Suspensions</h3>

      {penalties.length > 0 ? (
        <Table striped borderless className="team-member-penalty-report__major-penalties-table">
          <thead>
            <tr>
              <th className="team-member-penalty-report__major-penalties-table__game-date">Game Date</th>
              <th className="team-member-penalty-report__major-penalties-table__game-title">Game</th>
              <th className="team-member-penalty-report__major-penalties-table__penalty-code">Infraction</th>
              <th className="team-member-penalty-report__major-penalties-table__penalty-code">Count</th>
            </tr>
          </thead>
          <tbody>
            {penalties.map(({ id, ...rest }) => (
              <TeamMemberPenaltyReportMajorPenaltiesPenalty key={id} {...rest} />
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyListMessage>No major or misconduct penalties.</EmptyListMessage>
      )}
    </div>
  );
}

MajorPenalties.propTypes = {
  penalties: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired }))
};

MajorPenalties.defaultProps = {
  penalties: []
};

export default MajorPenalties;
