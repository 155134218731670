import React from "react";
import PropTypes from "prop-types";

import MemberEditTrigger from "./MemberEditTrigger";
import { Can } from "@/components/Ability";

function FieldValue(props) {
  const { teamId, member, name, value, muted, fallbackValue, transform, children } = props;

  const transformedValue = value !== "" ? transform(value) : fallbackValue;
  const finalValue = children(transformedValue);

  return (
    <Can I="update" this={{ type: "roster", teamId }} fallback={finalValue}>
      <MemberEditTrigger disabled={muted} member={member} fieldName={name}>
        {finalValue}
      </MemberEditTrigger>
    </Can>
  );
}

FieldValue.propTypes = {
  teamId: PropTypes.string.isRequired,
  member: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.func,
  muted: PropTypes.bool,
  transform: PropTypes.func,
  fallbackValue: PropTypes.string
};

FieldValue.defaultProps = {
  muted: false,
  transform: value => value,
  fallbackValue: "---",
  children: value => value
};

export default FieldValue;
