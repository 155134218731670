import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { Input } from "reactstrap";

function FilterSelect(props) {
  const { placeholder, value, children, isLoaded, isLoading, onChange } = props;

  const onChangeCallback = useCallback(({ target: { value } }) => onChange(value), [onChange]);

  return (
    <Input type="select" value={value} disabled={isLoading || !isLoaded} onChange={onChangeCallback}>
      <option value={""}>{isLoading ? "Loading…" : placeholder}</option>
      {children}
    </Input>
  );
}

FilterSelect.propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  children: PropTypes.node,
  isLoaded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default FilterSelect;
