import _camelCase from "lodash/camelCase";
import { handleActions } from "redux-actions";

import { penaltyTypeReportLoadingRoutine } from "./routines";

import { penaltyTypeReportActions } from "./actions";

const initialState = {
  isLoading: false,
  isLoaded: false,
  isForbidden: false,
  summaryStats: {},
  assessedPenalties: [],
  penaltyTypes: {}
};

function reduceSummaryStats(payload) {
  return {
    gamesPlayed: payload["games_played"],
    penaltiesAssessed: payload["penalties_number"],
    majorPenaltiesAssessed: payload["major_penalties_number"],
    avgPenaltiesPerGamesPlayed: payload["average_penalties_per_games_played"],
    mostFreqAssessedPenaltyCode: payload["most_frequent_penalty_code"],
    mostFreqAssessedPenaltyCount: payload["most_frequent_penalties_number"],
    mostFreqAssessedMajorPenaltyCode: payload["most_frequent_major_penalty_code"],
    mostFreqAssessedMajorPenaltyCount: payload["most_frequent_major_penalties_number"]
  };
}

function reduceAssessedPenalties({ penaltyTypeReports }) {
  return Object.entries(
    penaltyTypeReports.reduce((acc, { penalties, season: { settings: { penaltyCodes } } }) => {
      Object.entries(penalties || {}).forEach(([key, count]) => {
        if (acc[key] === undefined) {
          // Penalty codes are inconsistent because json-api-normalizer transforms all attribute keys
          // to camelCase. To work that around we find corresponding penalty types in the season settings
          // and use codes and labels from there.
          const penalty = penaltyCodes.find(({ code }) => _camelCase(code) === key);

          if (penalty === undefined) {
            acc[key] = { code: key, label: "", count };
          } else {
            acc[key] = { ...penalty, count };
          }
        } else {
          acc[key].count += count;
        }
      });

      return acc;
    }, {})
  ).map(([_, penalty]) => penalty);
}

function reduceLoadingRequest(state) {
  return { ...state, isLoading: true };
}

function reduceLoadingSuccess(state, { payload }) {
  const summaryStats = reduceSummaryStats(payload);
  const assessedPenalties = reduceAssessedPenalties(payload);

  return {
    ...state,
    summaryStats,
    assessedPenalties,
    isLoaded: true
  };
}

function reduceLoadingFailure(state, { payload: { responseStatus } }) {
  return { ...state, isLoaded: false, isForbidden: responseStatus === 401 };
}

function reduceLoadingFulfill(state) {
  return { ...state, isLoading: false };
}

export default handleActions(
  {
    [penaltyTypeReportLoadingRoutine.REQUEST]: reduceLoadingRequest,
    [penaltyTypeReportLoadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [penaltyTypeReportLoadingRoutine.FAILURE]: reduceLoadingFailure,
    [penaltyTypeReportLoadingRoutine.FULFILL]: reduceLoadingFulfill,
    [penaltyTypeReportActions.report.clear]: () => ({ ...initialState })
  },
  initialState
);
