import { handleActions } from "redux-actions";

const initialState = {};

function reduceLeagues(result, { leagues }) {
  return leagues.reduce(
    (acc, { id, title }) => ({
      ...acc,
      [id]: { title }
    }),
    result
  );
}

function createLoadingSuccessReducer(memberType) {
  return (state, { payload }) => payload[memberType].reduce(reduceLeagues, state);
}

export default function createDataReducer({ loadingRoutine, actions, memberType }) {
  return handleActions(
    {
      [loadingRoutine.SUCCESS]: createLoadingSuccessReducer(memberType),
      [actions.list.clear]: () => ({ ...initialState })
    },
    initialState
  );
}
