import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Table } from "reactstrap";

import Coach from "./components/Coach";

class CoachesList extends PureComponent {
  static propTypes = {
    caption: PropTypes.string.isRequired,
    coaches: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired }))
  };

  render() {
    const { caption, coaches } = this.props;

    return (
      <Table striped borderless size="sm">
        <thead>
          <tr>
            <th scope="colgroup" colSpan="3">
              {caption}
            </th>
          </tr>
          <tr>
            <th scope="col">Position</th>
            <th scope="col">Name</th>
            <th scope="col">Signature</th>
          </tr>
        </thead>
        <tbody>
          {coaches.map(({ id, ...rest }) => (
            <Coach key={id} {...rest} />
          ))}
        </tbody>
      </Table>
    );
  }
}

export default CoachesList;
