import _get from "lodash/get";
import { handleActions, combineActions } from "redux-actions";

import {
  CurrentPlayerLoadingRoutine as loadingRoutine,
  CurrentPlayerUpdatingRoutine as updatingRoutine,
  CurrentPlayerDeletingRoutine as deletingRoutine
} from "../routines";

import { clearCurrentPlayer } from "../actions";

const initialState = {
  isLoaded: false,
  isUpdated: false,
  isDeleted: false,
  isForbidden: false,
  isArchived: false,
  operation: null,
  errors: null,
  id: null
};

const triggerLoading = (state, { payload }) => {
  if (state.id === payload.id) {
    return Object.assign({}, state, { errors: null });
  }

  return Object.assign({}, initialState, { id: payload.id });
};

const reduce = props => state => Object.assign({}, state, props);

const loaded = (state, { payload }) => Object.assign({}, state, { isLoaded: true, id: payload.id });

const failure = props => (state, { payload: { error, errors } }) => {
  const status = _get(error, "response.status");
  const code = _get(error, "response.data.errors[0].code");
  const pointer = _get(error, "response.data.errors[0].source.pointer");

  return {
    ...state,
    ...props,
    errors: errors,
    isForbidden: status === 401,
    isArchived: status === 403 && code === "archived" && pointer === "/"
  };
};

const operate = operation => state => Object.assign({}, state, { operation });

const fulfill = state => Object.assign({}, state, { operation: null });

export default handleActions(
  {
    [loadingRoutine.TRIGGER]: triggerLoading,
    [loadingRoutine.REQUEST]: operate("loading"),
    [loadingRoutine.SUCCESS]: loaded,
    [loadingRoutine.FAILURE]: failure({ isLoaded: false }),
    [updatingRoutine.TRIGGER]: reduce({ isUpdated: false }),
    [updatingRoutine.REQUEST]: operate("updating"),
    [updatingRoutine.SUCCESS]: reduce({ isUpdated: true }),
    [updatingRoutine.FAILURE]: failure({ isUpdated: false }),
    [deletingRoutine.TRIGGER]: reduce({ isDeleted: false }),
    [deletingRoutine.REQUEST]: operate("deleting"),
    [deletingRoutine.SUCCESS]: reduce({ isDeleted: true }),
    [deletingRoutine.FAILURE]: failure({ isDeleted: false }),
    [combineActions(loadingRoutine.FULFILL, updatingRoutine.FULFILL, deletingRoutine.FULFILL)]: fulfill,
    [clearCurrentPlayer]: () => ({ ...initialState })
  },
  initialState
);
