import { selectorFamily } from "recoil";
import Service from "@/services";

export const divisionOptionsState = selectorFamily({
  key: 'divisionOptions',
  get: ({ seasonId }) => async () => {

    if (!seasonId) {
      return [];
    }

    return Service.api().seasons.season( seasonId ).divisions.get().then(response => {
      return response.data.map(division => ({
        id: division.id,
        title: division.attributes.title
      }));
    });
  }
});
