import React from "react";
import PropTypes from "prop-types";

import { Switch } from "react-router-dom";

import EnsureCurrentSeasonActive from "@/layouts/EnsureCurrentSeasonActive";
import LoggedInRoute from "@/containers/LoggedInRoute";

import useCurrentSeason from "@/hooks/useCurrentSeason";
import useCurrentSeasonClearing from "@/hooks/useCurrentSeasonClearing";
import CurrentSeasonContext from "@/contexts/CurrentSeasonContext";
import { useDivisionsListClearing } from "@/components/DivisionsList";

import EditSeason from "./pages/EditSeason";
import ShowSeason from "./pages/ShowSeason";
import CloneSeason from "./pages/CloneSeason";

import { IpadKeys, Divisions, Teams } from "./modules";

function SeasonScope({ match: { url, params } }) {
  const { seasonId } = params;

  const currentSeason = useCurrentSeason(seasonId);

  useCurrentSeasonClearing();
  useDivisionsListClearing();

  return (
    <CurrentSeasonContext.Provider value={{ ...currentSeason, seasonId }}>
      <EnsureCurrentSeasonActive>
        <Switch>
          <LoggedInRoute path={`${url}/ipad-keys`} component={IpadKeys} />
          <LoggedInRoute path={`${url}/divisions`} component={Divisions} />
          <LoggedInRoute path={`${url}/teams`} component={Teams} />

          <LoggedInRoute path={`${url}/edit`} component={EditSeason} />
          <LoggedInRoute path={`${url}/clone`} component={CloneSeason} />
          <LoggedInRoute path="" component={ShowSeason} />
        </Switch>
      </EnsureCurrentSeasonActive>
    </CurrentSeasonContext.Provider>
  );
}

SeasonScope.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      seasonId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default SeasonScope;
