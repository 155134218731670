import { put, call } from "redux-saga/effects";

export default function createRowImportingSaga(options) {
  const { routine, service } = options;

  return function*({ payload }) {
    const { lineNumber } = payload;

    yield put(routine.request({ lineNumber }));

    try {
      const id = yield call(service, payload);

      yield put(routine.success({ lineNumber, id }));
    } catch (e) {
      yield put(routine.failure({ lineNumber, e }));
    } finally {
      yield put(routine.fulfill({ lineNumber }));
    }
  };
}
