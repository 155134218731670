import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { isDeletingState, selectAllGamesState, bulkDeleteState } from './state';
import { selectedIdsState, forceListUpdateState, scheduledGamesMetaState } from '../List/state';

export default function useFooter({ seasonId }) {
  const setIsDeleting = useSetRecoilState(isDeletingState);
  const deleted = useRecoilValue(bulkDeleteState({ seasonId }));
  const [selectedIds, setSelectedIds] = useRecoilState(selectedIdsState);
  const setForceListUpdate = useSetRecoilState(forceListUpdateState);
  const [selectAllGames, setSelectAllGames] = useRecoilState(selectAllGamesState);
  const meta = useRecoilValue(scheduledGamesMetaState);

  useEffect(() => {
    (async function () {
      if (deleted) {
        const numDeleted = await deleted;
        if (numDeleted > 0) {
          setSelectedIds([]);
          setIsDeleting(false);
          setForceListUpdate(Math.random());
        }
      }
    })();
  }, [deleted]);

  const clearSelections = () => {
    setSelectedIds([]);
  };

  return {
    clearSelections,
    startDelete: () => setIsDeleting(true),
    toDeleteLength: selectedIds.length,
    selectAllGames, 
    selected: selectedIds,
    meta,
    setSelectAllGames
  }
}