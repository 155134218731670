import { put, all, takeLatest, select, call } from "redux-saga/effects";
import build from "redux-object";
import _trimEnd from "lodash/trimEnd";

import { loadCoaches } from "@/lib/api/coaches";

import { gamesheetAPIRequest } from "@/redux/api/sagas";

import { coachPenaltyReportListLoadingRoutine } from "./routines";

import selectors from "./selectors";
import actions from "./actions";

const {
  getCoachPenaltyReportListSearchCurrentQuery,
  getCoachPenaltyReportListLimit,
  getCoachPenaltyReportListOffset
} = selectors;

function* coachesLoadingSaga({ payload: { append = false } = {} }) {
  const query = yield select(getCoachPenaltyReportListSearchCurrentQuery);

  const limit = yield select(getCoachPenaltyReportListLimit);
  const offset = append ? yield select(getCoachPenaltyReportListOffset) : 0;

  yield put(coachPenaltyReportListLoadingRoutine.request({ query, append }));

  const { data, rawData, meta } = yield call(gamesheetAPIRequest, loadCoaches, {
    include: "leagues",
    limit,
    offset,
    query
  });

  const coaches = build(data, "searchPersons") || [];

  yield put(
    coachPenaltyReportListLoadingRoutine.success({
      ids: rawData.map(({ id }) => id),
      totalCount: meta ? meta["total-count"] || 0 : 0,
      filteredCount: meta ? meta["filtered-count"] || 0 : 0,
      coaches,
      query,
      append
    })
  );

  yield put(coachPenaltyReportListLoadingRoutine.fulfill({ query, append }));
}

function* commitSearchQuerySaga() {
  const query = yield select(getCoachPenaltyReportListSearchCurrentQuery);

  if (_trimEnd(query)) {
    yield put(coachPenaltyReportListLoadingRoutine());
  } else {
    yield put(actions.list.clear());
  }
}

export function* coachPenaltyReportFlow() {
  yield all([
    takeLatest(actions.list.search.commit, commitSearchQuerySaga),
    takeLatest(coachPenaltyReportListLoadingRoutine.TRIGGER, coachesLoadingSaga),
  ]);
}
