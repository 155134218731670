import { handleActions } from "redux-actions";

import { reportDataSourcesSelectAssociationListLoadingRoutine } from "../routines";
import actions from "../actions";

const initialState = {
  data: [],
  selectedId: null,
  isLoading: false,
  isLoaded: false
};

function reduceLoadingRequest(state) {
  return { ...state, isLoading: true };
}

function reduceLoadingSuccess(state, { payload: { associations } }) {
  return {
    ...state,
    isLoaded: true,
    data: associations.map(({ id, title }) => ({ id, title }))
  };
}

function reduceLoadingFailure(state) {
  return { ...state, isLoaded: false };
}

function reduceLoadingFulfill(state) {
  return { ...state, isLoading: false };
}

function reduceSelected(state, { payload: { id } }) {
  return { ...state, selectedId: id };
}

function reduceDeselected(state) {
  return { ...state, selectedId: null };
}

function reduceAssociationClearing(state) {
  return { ...state, selectedId: null };
}

function reduceClearing(state) {
  return { ...initialState };
}

export default handleActions(
  {
    [reportDataSourcesSelectAssociationListLoadingRoutine.REQUEST]: reduceLoadingRequest,
    [reportDataSourcesSelectAssociationListLoadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [reportDataSourcesSelectAssociationListLoadingRoutine.FAILURE]: reduceLoadingFailure,
    [reportDataSourcesSelectAssociationListLoadingRoutine.FULFILL]: reduceLoadingFulfill,
    [actions.associationList.select]: reduceSelected,
    [actions.associationList.deselect]: reduceDeselected,
    [actions.associationList.clear]: reduceAssociationClearing,
    [actions.clear]: reduceClearing
  },
  initialState
);
