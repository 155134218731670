import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { FormGroup, Label, Input, Col, Row, FormFeedback } from "reactstrap";

import useCurrentPlayerContext from "@/hooks/useCurrentPlayerContext";

import DashboardForm from "@/components/DashboardForm";
import DashboardFormFooter from "@/components/DashboardFormFooter";
import FormItem from "@/components/FormItem";
import BirthdateField from "@/components/BirthdateField";

import AbilityButton from "@/components/AbilityButton";
import SeasonRosterMemberDeleteConfirmation from "@/components/SeasonRosterMemberDeleteConfirmation";
import { Can } from "@/components/Ability";

import CountryField from "./CountryField";
import ProvinceField from "./ProvinceField";
import PhotoField from "./PhotoField";

import TitleBar from "@/components/TitleBar";

import { usePlayerForm, usePlayerFormSubmitting, usePlayerFormDeleting } from "./hooks";

function PlayerForm({ seasonId, playerId, initialValues }) {
  const { playerFullName, playerTeams } = useCurrentPlayerContext();

  const { fields, isPristine, isInvalid, values, resetProvince } = usePlayerForm(initialValues);

  const { isSubmitting, submit } = usePlayerFormSubmitting({
    seasonId,
    values,
    playerId
  });

  const { isDeleting, deletePlayer } = usePlayerFormDeleting({
    seasonId,
    playerId
  });

  return (
    <DashboardForm
      namespace="player"
      className="player-form"
      isOperating={isSubmitting || isDeleting}
      onSubmit={submit}
    >
      <Row form>
        <Col md="8">
          <FormItem>
            <FormGroup className="col-md-6 required">
              <Label for="playerFirstName">First Name</Label>
              <Input id="playerFirstName" name="firstName" {...fields.firstName.input} />

              {fields.firstName.input.invalid &&
                fields.firstName.errors.map(error => <FormFeedback key={error}>{error}</FormFeedback>)}
            </FormGroup>

            <FormGroup className="col-md-6 required">
              <Label for="playerLastName">Last Name</Label>
              <Input id="playerLastName" name="lastName" {...fields.lastName.input} />

              {fields.lastName.input.invalid &&
                fields.lastName.errors.map(error => <FormFeedback key={error}>{error}</FormFeedback>)}
            </FormGroup>

            <FormGroup className="col-md-12">
              <Label for="playerExternalId">External ID</Label>
              <Input id="playerExternalId" name="externalId" placeholder="Optional" {...fields.externalId.input} />
            </FormGroup>

            <FormGroup className="col-md-5">
              <BirthdateField {...fields.birthdate} />
            </FormGroup>

            <FormGroup className="col-md-2">
              <Label for="playerWeight">Weight</Label>
              <Input id="playerWeight" name="weight" {...fields.weight.input} />
            </FormGroup>

            <FormGroup className="col-md-2">
              <Label for="playerHeight">Height</Label>
              <Input id="playerHeight" name="height" {...fields.height.input} />
            </FormGroup>

            <FormGroup className="col-md-3">
              <Label for="playerShotHand">Shot-hand / Catch hand</Label>
              <Input type="select" id="playerShotHand" name="shotHand" {...fields.shotHand.input}>
                <option value="" />
                <option value="right">Right</option>
                <option value="left">Left</option>
              </Input>
            </FormGroup>

            <div className="col-12">
              <TitleBar slim title="Birthplace" titleTag="h4" />
            </div>
            <FormGroup className="col-md-4">
              <CountryField {...fields.country} resetProvince={resetProvince} />
            </FormGroup>

            <FormGroup className="col-md-4">
              <ProvinceField {...fields.province} country={fields.country.value} />
            </FormGroup>

            <FormGroup className="col-md-4">
              <Label for="playerHometown">City / Town</Label>
              <Input id="playerHometown" name="hometown" {...fields.hometown.input} />
            </FormGroup>

            <FormGroup className="col-md-6">
              <Label for="playerDraftedBy">Drafted by</Label>
              <Input id="playerDraftedBy" name="draftedBy" {...fields.draftedBy.input} />
            </FormGroup>

            <FormGroup className="col-md-6">
              <Label for="playerCommittedTo">Committed to</Label>
              <Input id="playerCommittedTo" name="committedTo" {...fields.committedTo.input} />
            </FormGroup>
          </FormItem>
        </Col>

        <Col md="4">
          <PhotoField {...fields.photo} />
        </Col>
      </Row>

      <FormItem>
        <FormGroup className="col-md-12">
          <Label for="playerBio">Bio</Label>
          <Input type="textarea" id="playerBio" name="biography" {...fields.biography.input} />
        </FormGroup>
      </FormItem>

      <DashboardFormFooter>
        {playerId ? (
          <Fragment>
            <Can I="delete" this={{ type: "players", id: playerId }}>
              <div className="dashboard-form-footer__secondary">
                <SeasonRosterMemberDeleteConfirmation
                  member={{
                    id: playerId,
                    fullName: playerFullName,
                    type: "player"
                  }}
                  teams={playerTeams}
                  isOperating={isSubmitting}
                  isDeleting={isDeleting}
                  onDelete={deletePlayer}
                />
              </div>
            </Can>

            <div className="dashboard-form-footer__primary">
              <AbilityButton
                subject={{ type: "players", id: playerId }}
                action="update"
                type="submit"
                color="success"
                outline={isPristine || isInvalid}
                disabled={isPristine || isInvalid}
              >
                {isSubmitting ? "Saving Changes…" : "Save Changes"}
              </AbilityButton>
            </div>
          </Fragment>
        ) : (
          <div className="dashboard-form-footer__primary">
            <AbilityButton
              subject={{ type: "players", seasonId }}
              action="create"
              type="submit"
              color="success"
              outline={isPristine || isInvalid}
              disabled={isPristine || isInvalid}
            >
              {isSubmitting ? "Creating Player…" : "Create Player"}
            </AbilityButton>
          </div>
        )}
      </DashboardFormFooter>
    </DashboardForm>
  );
}

PlayerForm.propTypes = {
  seasonId: PropTypes.string.isRequired,
  playerId: PropTypes.string,
  initialValues: PropTypes.shape({})
};

export default PlayerForm;
