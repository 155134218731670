import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

class PaginationItem extends PureComponent {
  static propTypes = {
    pageNumber: PropTypes.number,
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    onClick: PropTypes.func
  };

  static defaultProps = {
    disabled: false,
    active: false,
    onClick() {}
  };

  handleClick = () => {
    const { pageNumber, onClick, disabled } = this.props;

    if (disabled === false) {
      onClick(pageNumber);
    }
  };

  render() {
    const { disabled, text, pageNumber, active } = this.props;

    return (
      <li className={classNames({ "page-item": true, disabled, active })}>
        <button className="page-link" onClick={this.handleClick}>
          {text || pageNumber}
        </button>
      </li>
    );
  }
}

export default PaginationItem;
