import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import SearchBar from "@/components/SearchBar";

import "./SearchBar.scss";

class RefereesListSearchBar extends PureComponent {
  static propTypes = {
    seasonId: PropTypes.string.isRequired,
    nextQuery: PropTypes.string,
    currentQuery: PropTypes.string,
    isDirty: PropTypes.bool,
    isLoading: PropTypes.bool,
    setQuery: PropTypes.func,
    commit: PropTypes.func,
    clear: PropTypes.func
  };

  static defaultProps = {
    nextQuery: "",
    currentQuery: "",
    isDirty: true,
    isLoading: false,
    setQuery() {},
    commit() {},
    clear() {}
  };

  render() {
    const { nextQuery, currentQuery, isDirty, isLoading, setQuery, commit, clear } = this.props;

    return (
      <SearchBar
        className="referees-list__search-bar"
        placeholder="Search referees…"
        nextQuery={nextQuery}
        currentQuery={currentQuery}
        isDirty={isDirty}
        disabled={isLoading}
        isLoading={isLoading}
        onChange={setQuery}
        commit={commit}
        clear={clear}
      />
    );
  }
}

export default RefereesListSearchBar;
