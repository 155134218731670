import { handleActions } from "redux-actions";

import { invitationFormActions } from "../../../actions";

const defaultState = {
  actions: {
    create: false,
    read: false,
    update: false,
    delete: false
  },
  global: true,
  ids: []
};

const changeActions = (state, { payload }) => Object.assign({}, state, { actions: { ...state.actions, ...payload } });

const changeGlobalMode = (state, { payload }) => Object.assign({}, state, { global: payload });

const addDivisions = (state, { payload }) => Object.assign({}, state, { ids: [...state.ids, ...payload] });

const removeDivision = (state, { payload }) =>
  Object.assign({}, state, { ids: state.ids.filter(id => id !== payload) });

export default handleActions(
  {
    [invitationFormActions.scopes.divisions.changeActions]: changeActions,
    [invitationFormActions.scopes.divisions.changeGlobalMode]: changeGlobalMode,
    [invitationFormActions.scopes.divisions.addDivisions]: addDivisions,
    [invitationFormActions.scopes.divisions.removeDivision]: removeDivision
  },
  defaultState
);
