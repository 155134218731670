import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { actions } from "@/redux/teamRoster";

export default function useAddingEmptyPlayer() {
  const dispatch = useDispatch();
  const addEmptyPlayer = useCallback(() => dispatch(actions.appendEmptyPlayer()), [dispatch]);

  return addEmptyPlayer;
}
