import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { isTeamListLoaded, isTeamListLoading, isTeamListForbidden } from "@/redux/teams/selectors";

import { TeamListLoadingRoutine } from "@/redux/teams/routines";

import actions from "@/redux/teams/actions";

export default function useTeamsListLoading({ seasonId, divisionId, pageSize = 25 }) {
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(location.search);
  const pageNumber = searchParams.get("page") || 1;
  const sort = searchParams.get("sort") || "none";

  const isListLoaded = useSelector(isTeamListLoaded);
  const isListLoading = useSelector(isTeamListLoading);
  const isListForbidden = useSelector(isTeamListForbidden);

  useEffect(
    () => {
      dispatch(actions.list.clear());
    },
    [pageNumber]
  );

  useEffect(
    () => {
      dispatch(actions.list.clear());
    },
    [sort]
  );

  useEffect(
    () => {
      if (!isListLoaded && !isListLoading && !isListForbidden) {
        dispatch(TeamListLoadingRoutine({ seasonId, divisionId, pageNumber, pageSize, sort }));
      }
    },
    [isListLoaded, isListLoading, isListForbidden, seasonId, divisionId, pageNumber, pageSize, sort, dispatch]
  );

  return {
    pageNumber,
    sort
  };
}
