export default function makeCoachesRoster(coaches) {
  return coaches.reduce((coaches, { coach }) => {
    const { id, position } = coach;

    return [
      ...coaches,
      {
        id,
        position: position.toLowerCase()
      }
    ];
  }, []);
}
