import React from "react";
import PropTypes from "prop-types";
import FormItem from "@/components/FormItem";
import { Label, FormGroup } from "reactstrap";

import ContentSection from "@/components/ContentSection";

import useLineup from "./hooks/useLineup";

import LineupInput from "./LineupInput";
import AddPlayerButton from "./components/AddPlayer/AddPlayerButton";

import "./Lineup.scss";

function Lineup({ team }) {
  const { teamTitle, lineup, refreshJerseyValidity, updatePlayer } = useLineup({
    team
  });

  return (
    <ContentSection title={`${team.toUpperCase()} – ${teamTitle}`} className={"lineup-inputs"}>
      <FormItem>
        <FormGroup className="col-md-5">
          <Label>Name</Label>
        </FormGroup>

        <FormGroup className="col-md-1">
          <Label>Jersey</Label>
        </FormGroup>

        <FormGroup className="col-md-2">
          <Label>Position</Label>
        </FormGroup>

        <FormGroup className="col-md-2">
          <Label>Designation</Label>
        </FormGroup>

        <FormGroup className="col-md-2">
          <Label>Status</Label>
        </FormGroup>
      </FormItem>

      {lineup.map((player, index) => (
        <LineupInput
          key={player.id}
          team={team}
          player={player}
          stripe={index % 2 == 0}
          refreshJerseyValidity={refreshJerseyValidity}
          onChange={updatePlayer}
        />
      ))}

      <AddPlayerButton teamType={team} />
    </ContentSection>
  );
}

Lineup.propTypes = {
  team: PropTypes.oneOf(["home", "visitor"]).isRequired
};

export default Lineup;
