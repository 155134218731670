import { useCallback } from "react";

import { POINT_SYSTEMS } from "../constants";

export default function usePointSystems({ value, onChange }) {
  const { title: currentTitle } = value;

  const changeTitle = useCallback(({ target: { name } }) => onChange({ ...value, title: name }), [onChange, value]);

  return POINT_SYSTEMS.filter(({ enabled }) => enabled).map(({ title, label, description }) => ({
    title,
    label,
    description,
    onChange: changeTitle,
    checked: title === currentTitle
  }));
}
