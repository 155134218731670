import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { FormGroup, FormFeedback, Label, Input, Button } from "reactstrap";

import DashboardForm from "@/components/DashboardForm";
import DashboardFormFooter from "@/components/DashboardFormFooter";
import ContentSection from "@/components/ContentSection";
import FormItem from "@/components/FormItem";
import ConfirmDeletionModal from "@/components/ConfirmDeletionModal";

import LiveScoringScopesInput from "./LiveScoringScopesInput";
import RolesFieldset from "./RolesFieldset";

import { useIpadKeyForm } from "./hooks";

function IpadKeyForm({ initialValues, seasonId, keyId }) {
  const { fields, submit, deleteKey, isPristine, isInvalid, isSubmitting, isDeleting } = useIpadKeyForm({
    initialValues,
    seasonId,
    keyId
  });

  return (
    <DashboardForm namespace="ipad-key" isOperating={isSubmitting || isDeleting} onSubmit={submit}>
      <ContentSection>
        <FormItem>
          <FormGroup className="col-md-6 required">
            <Label for="keyValue">Key</Label>
            <Input id="keyValue" name="value" className="dashboard-form__input--monospace" {...fields.value.input} />

            {fields.value.input.invalid &&
              fields.value.errors.map(error => <FormFeedback key={error}>{error}</FormFeedback>)}
          </FormGroup>

          <FormGroup className="col-md-6 required">
            <Label for="keyDescription">Description</Label>
            <Input id="keyDescription" name="description" {...fields.description.input} />

            {fields.description.input.invalid &&
              fields.description.errors.map(error => <FormFeedback key={error}>{error}</FormFeedback>)}
          </FormGroup>
        </FormItem>
      </ContentSection>

      <ContentSection>
        <LiveScoringScopesInput {...fields.liveScoringScopes} />
      </ContentSection>

      {keyId && <RolesFieldset {...fields.roles} />}

      <DashboardFormFooter>
        {keyId ? (
          <Fragment>
            <div className="dashboard-form-footer__secondary">
              <ConfirmDeletionModal
                resourceTitle={fields.value.initialValue}
                resourceType="iPad Key"
                isOperating={isSubmitting}
                isDeleting={isDeleting}
                onDelete={deleteKey}
              />
            </div>

            <div className="dashboard-form-footer__primary">
              <Button
                type="submit"
                color="success"
                outline={isPristine || isInvalid}
                disabled={isPristine || isInvalid}
              >
                {isSubmitting ? "Saving Changes…" : "Save changes"}
              </Button>
            </div>
          </Fragment>
        ) : (
          <div className="dashboard-form-footer__primary">
            <Button type="submit" color="success" outline={isPristine || isInvalid} disabled={isPristine || isInvalid}>
              {isSubmitting ? "Creating Key…" : "Create Key"}
            </Button>
          </div>
        )}
      </DashboardFormFooter>
    </DashboardForm>
  );
}

IpadKeyForm.propTypes = {
  seasonId: PropTypes.string.isRequired,
  keyId: PropTypes.string,
  initialValues: PropTypes.shape({})
};

export default IpadKeyForm;
