import React from "react";
import PropTypes from "prop-types";

import { Switch } from "react-router-dom";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";
import useCurrentGame from "@/hooks/useCurrentGame";
import useCurrentGameClearing from "@/hooks/useCurrentGameClearing";
import CurrentGameContext from "@/contexts/CurrentGameContext";
import LoggedInRoute from "@/containers/LoggedInRoute";

import PlayerOfTheGame from "./pages/PlayerOfTheGame";
import GamesShow from "./pages/GamesShow";
import GamesEdit from "./pages/GamesEdit";

function AssociationScope({ match: { url, path, params } }) {
  const { gameId } = params;

  const { seasonId } = useCurrentSeasonContext();
  const currentGame = useCurrentGame({ seasonId, gameId });

  useCurrentGameClearing();

  return (
    <CurrentGameContext.Provider value={currentGame}>
      <Switch>
        <LoggedInRoute path={`${path}/player-of-the-game`} component={PlayerOfTheGame} />

        <LoggedInRoute path={`${path}/edit`} component={GamesEdit} />

        <LoggedInRoute path={path} component={GamesShow} />
      </Switch>
    </CurrentGameContext.Provider>
  );
}

AssociationScope.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      gameId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default AssociationScope;
