import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { actions } from "@/redux/seasonForm";

export default function useSeasonFormClearing() {
  const dispatch = useDispatch();

  useEffect(() => () => dispatch(actions.clear()), [dispatch]);
}
