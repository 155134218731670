import { useSelector } from "react-redux";

import { getGameFormTeamDivisionTitle, getMaxFairPlayPenaltyMinutes } from "@/redux/gameForm/selectors";

export default function useMaxFairPlayInfo() {
  const divisionTitle = useSelector(state => getGameFormTeamDivisionTitle(state, "home"));

  const maxFairPlayPenaltyMinutes = useSelector(state => getMaxFairPlayPenaltyMinutes(state));

  return {
    divisionTitle,
    maxFairPlayPenaltyMinutes
  };
}
