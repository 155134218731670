import { createGetService, createPostService, createPatchService, createPutService } from "./serviceMaker";

export const loadAccount = createGetService(({ verificationCode }) => ({
  url: "/account",
  headers: { "x-verification-code": verificationCode }
}));

export const createAccount = createPostService(({ attributes }) => ({
  url: "/account",
  identity: {
    type: "users"
  },
  attributes
}));

export const updateAccount = createPatchService(({ attributes }) => ({
  url: "/account",
  identity: {
    type: "users"
  },
  attributes
}));

export const acceptInvitation = createPutService(({ attributes }) => ({
  url: "/account/invitations",
  identity: {
    type: "users"
  },
  attributes
}));
