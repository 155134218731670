export default function makePlayersRoster(players) {
  return players.reduce((players, { player }) => {
    const { id, jerseyNumber, position, designation, affiliated } = player;

    return [
      ...players,
      {
        id,
        number: jerseyNumber,
        position: position.toLowerCase(),
        duty: designation.toLowerCase(),
        affiliated: affiliated.toLowerCase() === "yes"
      }
    ];
  }, []);
}
