import { config } from "@/config";
import axios from "axios";

export const send = async ( imagefile, token=false ) => {

  // don't upload a blank imagefile
  if(!imagefile) return "";
  
  // get temporary upload url
  const uploadURL = await axios({
    method: 'get',
    url: `https://${config.V4_URL}/service.assetsapi/v4/assets/cloudflare`,
    headers: {
      Authorization: `Bearer ${token || window.localStorage.getItem('accessToken')}`
    }
  }).then(response => response.data.data.uploadURL);

  // create formdata
  const formData = new FormData();
  formData.append("file", imagefile);
  
  // upload file
  return axios({
    method: "post",
    url: uploadURL,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then(result => {
    
    // "variants" is an array of images with different endings for sizes
    // example: https://imagedelivery.net/ErrQpIaCOWR-Tz51PhN1zA/dc458675-4dd4-4858-1187-67f113d76300/16
    // we want the url without the "16" on the end
    const parts = result.data.result.variants[0].split("/");
    parts.pop();
    return parts.join("/");

  });

}