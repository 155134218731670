import { createActions } from "redux-actions";

import { NAMESPACE, NAMESPACE_PARAM } from "./constants";

const { [NAMESPACE_PARAM]: actions } = createActions({
  [NAMESPACE]: {
    clear: undefined
  }
});

export default actions;
