import React from "react";
import PropTypes from "prop-types";

import { Switch } from "react-router-dom";

import LoggedInRoute from "@/containers/LoggedInRoute";

import { useAssociationsListLoading, useAssociationsListClearing } from "@/components/AssociationsList";

import AssociationScope from "./AssociationScope";
import NewAssociation from "./pages/NewAssociation";
import AssociationsIndex from "./pages/AssociationsIndex";

function AssociationsRoute({ match: { url } }) {
  useAssociationsListLoading();
  useAssociationsListClearing();

  return (
    <Switch>
      <LoggedInRoute path={`${url}/new`} component={NewAssociation} />

      <LoggedInRoute path={`${url}/:associationId`} component={AssociationScope} />

      <LoggedInRoute path="" component={AssociationsIndex} />
    </Switch>
  );
}

AssociationsRoute.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired
};

export default AssociationsRoute;
