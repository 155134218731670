import { handleActions } from "redux-actions";

import { clearGamesheet, showGamesheetHistory, hideGamesheetHistory } from "../actions";

import {
  loadGamesheetHistoryRoutine,
  loadGamesheetHistoryRecordRoutine,
  exportGamesheetHistoryRecordToPDFRoutine
} from "../routines";

import teamReducer from "../utils/teamReducer";
import reducePlayers from "../utils/reducePlayers";
import reduceCoaches from "../utils/reduceCoaches";
import reduceProperties from "../utils/reduceProperties";
import reduceReferees from "../utils/reduceReferees";
import reduceRefereeNotes from "../utils/reduceRefereeNotes";
import reduceScorekeeperNotes from "../utils/reduceScorekeeperNotes";
import reduceGameGoaliesIceTime from "../utils/reduceGameGoaliesIceTime";
import reduceShootout from "../utils/reduceShootout";
import reduceShots from "../utils/reduceShots";

const reduceVisitorTeam = teamReducer("visitor");
const reduceHomeTeam = teamReducer("home");

const initialState = {
  isOpen: false,
  isLoaded: false,
  isLoading: false,
  records: []
};

/**
 * Transforms gamesheet author (createdBy) into a representation of
 * user data. If gamesheet is created using ApiKey returns null.
 */
function buildGamesheetAuthor({ type, firstName, lastName, email }) {
  return type ? null : { name: `${firstName} ${lastName}`, email };
}

function reduceGamesheetHistoryRecordsList(state, { payload: { gamesheets } }) {
  return {
    ...state,
    isLoaded: true,
    records: gamesheets.reduce(
      (result, { id, createdBy, createdAt }) => ({
        ...result,
        [id]: {
          author: createdBy ? buildGamesheetAuthor(createdBy) : null,
          date: createdAt,
          gamesheet: {
            data: {},
            meta: { isLoaded: false, isLoading: false, isExporting: false }
          }
        }
      }),
      {}
    )
  };
}

function reduceGamesheetHistoryRecord(state, { payload }) {
  const { id } = payload;

  return {
    ...state,
    records: {
      ...state.records,
      [id]: {
        ...state.records[id],
        gamesheet: {
          ...state.records[id].gamesheet,
          meta: {
            ...state.records[id].gamesheet.meta,
            isLoaded: true
          },
          data: {
            visitor: reduceVisitorTeam({}, { payload }),
            home: reduceHomeTeam({}, { payload }),
            players: reducePlayers({}, { payload }),
            coaches: reduceCoaches({}, { payload }),
            properties: reduceProperties({}, { payload }),
            referees: reduceReferees({}, { payload }),
            refereeNotes: reduceRefereeNotes({}, { payload }),
            scorekeeperNotes: reduceScorekeeperNotes({}, { payload }),
            goaliesIceTime: reduceGameGoaliesIceTime({}, { payload }),
            shootout: reduceShootout({}, { payload }),
            shots: reduceShots({}, { payload }),
          }
        }
      }
    }
  };
}

export default handleActions(
  {
    [clearGamesheet]: () => initialState,
    [showGamesheetHistory]: state => ({ ...state, isOpen: true }),
    [hideGamesheetHistory]: state => ({
      ...state,
      isOpen: false
    }),
    [loadGamesheetHistoryRoutine.REQUEST]: state => ({
      ...state,
      isLoading: true
    }),
    [loadGamesheetHistoryRoutine.SUCCESS]: reduceGamesheetHistoryRecordsList,
    [loadGamesheetHistoryRoutine.FULFILL]: state => ({
      ...state,
      isLoading: false
    }),
    [loadGamesheetHistoryRecordRoutine.REQUEST]: (state, { payload: { id } }) => ({
      ...state,
      records: {
        ...state.records,
        [id]: {
          ...state.records[id],
          gamesheet: {
            ...state.records[id].gamesheet,
            meta: {
              ...state.records[id].gamesheet.meta,
              isLoading: true
            }
          }
        }
      }
    }),
    [loadGamesheetHistoryRecordRoutine.SUCCESS]: reduceGamesheetHistoryRecord,
    [loadGamesheetHistoryRecordRoutine.FAILURE]: (state, { payload: { id } }) => ({
      ...state,
      records: {
        ...state.records,
        [id]: {
          ...state.records[id],
          gamesheet: {
            ...state.records[id].gamesheet,
            meta: {
              ...state.records[id].gamesheet.meta,
              isLoaded: false
            }
          }
        }
      }
    }),
    [loadGamesheetHistoryRecordRoutine.FULFILL]: (state, { payload: { id } }) => ({
      ...state,
      records: {
        ...state.records,
        [id]: {
          ...state.records[id],
          gamesheet: {
            ...state.records[id].gamesheet,
            meta: {
              ...state.records[id].gamesheet.meta,
              isLoading: false
            }
          }
        }
      }
    }),
    [exportGamesheetHistoryRecordToPDFRoutine.REQUEST]: (state, { payload: { id } }) => ({
      ...state,
      records: {
        ...state.records,
        [id]: {
          ...state.records[id],
          gamesheet: {
            ...state.records[id].gamesheet,
            meta: {
              ...state.records[id].gamesheet.meta,
              isExporting: true
            }
          }
        }
      }
    }),
    [exportGamesheetHistoryRecordToPDFRoutine.FULFILL]: (state, { payload: { id } }) => ({
      ...state,
      records: {
        ...state.records,
        [id]: {
          ...state.records[id],
          gamesheet: {
            ...state.records[id].gamesheet,
            meta: {
              ...state.records[id].gamesheet.meta,
              isExporting: false
            }
          }
        }
      }
    })
  },
  initialState
);
