import { useCallback } from "react";
import { useSelector } from "react-redux";

import { getDivisions } from "@/redux/teamPickerField";

import useTeamPickerFieldMeta from "./useTeamPickerFieldMeta";

export default function useTeamPickerFieldDivisionInput(onChange) {
  const { isLoaded } = useTeamPickerFieldMeta();
  const divisions = useSelector(getDivisions);

  const handleChange = useCallback(({ target: { value } }) => onChange({ division: value, team: "" }), [onChange]);

  return {
    isLoaded,
    divisions,
    handleChange
  };
}
