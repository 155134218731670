import moment from "moment";

export default function formatIceTime(iceTime) {
  const duration = moment.duration(iceTime, "s");

  const minutes = Math.floor(duration.asMinutes());
  const seconds = duration.seconds();

  return `${minutes >= 10 ? minutes : `0${minutes}`}:${seconds >= 10 ? seconds : `0${seconds}`}`;
}
