import React, { useRef } from 'react';
import { RecoilRoot } from 'recoil';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-dom';

import QueryInput from './components/QueryInput';
import SearchResults from './components/SearchResults';
import useScheduledGameLocationInput from './useScheduledGameLocationInput';

const searchClient = algoliasearch('13DJIXLJVR', '1fcb9fdc5f04ff7825b431fa559e3066');

const ScheduledGameLocationInput = ({ location, setLocation, clear }) => {
  const wrapperRef = useRef(null);
  useScheduledGameLocationInput({ ref: wrapperRef });

  return (
    <div ref={wrapperRef}>
      <InstantSearch searchClient={searchClient} indexName="LIVEBARN_SURFACES">
        <QueryInput value={location} setValue={setLocation} clear={clear} />
        <SearchResults />
      </InstantSearch>
    </div>
  );
};

export default ScheduledGameLocationInput;