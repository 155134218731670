import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Input } from "reactstrap";

class TeamMemberInput extends PureComponent {
  static propTypes = {
    team: PropTypes.oneOf(["home", "visitor"]).isRequired,
    players: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        firstName: PropTypes.string,
        lastName: PropTypes.string
      })
    ),
    coaches: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        firstName: PropTypes.string,
        lastName: PropTypes.string
      })
    ),
    value: PropTypes.shape({
      type: PropTypes.oneOf(["players", "coaches"]).isRequired,
      id: PropTypes.string.isRequired
    }),
    id: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func
  };

  static defaultProps = {
    players: [],
    coaches: [],
    onChange() {}
  };

  handleChange = e => {
    e.preventDefault();

    const {
      target: { name, value }
    } = e;
    const [type, id] = value.split(":");

    this.props.onChange({ name, value: { type, id } });
  };

  render() {
    const { id, name, value, players, coaches } = this.props;

    return (
      <Input
        type="select"
        id={id}
        name={name}
        value={value ? `${value.type}:${value.id}` : ""}
        onChange={this.handleChange}
      >
        <option disabled />
        <optgroup label="Players">
          {players.map(({ id, firstName, lastName, number }) => (
            <option key={`players:${id}`} value={`players:${id}`}>{`#${number} – ${firstName} ${lastName}`}</option>
          ))}
        </optgroup>

        <optgroup label="Coaches">
          {coaches.map(({ id, firstName, lastName }) => (
            <option key={`coaches:${id}`} value={`coaches:${id}`}>{`${firstName} ${lastName}`}</option>
          ))}
        </optgroup>
      </Input>
    );
  }
}

export default TeamMemberInput;
