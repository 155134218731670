import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FilePicker } from "react-file-picker";

import { Button, ModalBody, ModalFooter, Table } from "reactstrap";

import InvalidPenaltyCode from "./InvalidPenaltyCode";

function ValidationFailure({ invalidRows, onReSelect, penaltyClasses }) {
  return (
    <Fragment>
      <ModalBody>
        <p className="text-danger">
          We&rsquo;ve found some issues with your file and highlighted them below. Please correct the issues and try
          again.
        </p>
        <Table borderless striped size="sm" className="invalid-csv-codes-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Penalty Label</th>
              <th>Code</th>
              <th>Class</th>
              <th>Duration</th>
            </tr>
          </thead>
          <tbody>
            {invalidRows.map(row => (
              <tr key={`row-${row.lineNumber}`}>
                <InvalidPenaltyCode {...row} />
              </tr>
            ))}
          </tbody>
        </Table>
        {invalidRows.filter(r => r.invalidClass).length > 0 && (
          <p className="text-danger">
            Valid penalty classes are: {penaltyClasses.map(({ title }) => title).join(", ")}
          </p>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-center">
        <FilePicker onChange={onReSelect}>
          <Button className="btn btn-success" type="button">
            Re-Select File
          </Button>
        </FilePicker>
      </ModalFooter>
    </Fragment>
  );
}

ValidationFailure.propTypes = {
  invalidRows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onReSelect: PropTypes.func.isRequired
};

export default ValidationFailure;
