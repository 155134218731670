import React from "react";

import { Input } from "reactstrap";

import useSearchBar from "./hooks/useSearchBar";

function PlayerSearchBar() {
  const { value, onChange } = useSearchBar();

  return <Input placeholder="Search players..." type="text" value={value} onChange={onChange} />;
}

export default PlayerSearchBar;
