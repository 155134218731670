import React, { Fragment } from "react";
import PropTypes from "prop-types";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
  Table,
  CustomInput,
  Label,
  Alert
} from "reactstrap";

import { useDashboardFormNamespaceContext } from "@/components/DashboardForm";
import EmptyListMessage from "@/components/EmptyListMessage";

import CountPenalties from "../CountPenalties";

import "./PenaltyPicker.scss";

function PenaltyPicker(props) {
  const {
    isOpen,
    listIsNotBlank,
    close,
    query,
    penaltyCodes,
    sport,
    countSelected,
    totalCountSelected,
    isAllSelected,
    toggleAllEnabled,
    countMissing,
    totalCountMissing,
    clearSelectedPenalties
  } = props;

  const namespace = useDashboardFormNamespaceContext();

  if (!isOpen) {
    return null;
  }

  return (
    <Modal size="lg " fade={false} isOpen={true} toggle={close} centered>
      <ModalHeader toggle={close}>Change Flagged {sport === 'soccer' ? "Infractions" : "Penalties"}</ModalHeader>
      <ModalBody>
        {listIsNotBlank ? (
          <Fragment>
            <InputGroup size="sm" className="mb-2">
              <Input placeholder="Search by code or label…" type="text" {...query.input} />
              {query.isDirty && (
                <InputGroupAddon addonType="append">
                  <Button type="button" onClick={query.onReset}>
                    x
                  </Button>
                </InputGroupAddon>
              )}
            </InputGroup>

            {countMissing > 0 && (
              <Alert color="warning">
                In order to resolve warnings you need too specify the missing codes in the season {sport === 'soccer' ? "infraction" : "penalty"} types section
                or disable (uncheck) the corresponding codes in the list below.
              </Alert>
            )}

            <div
              className={`game-flagging-settings__penalty_picker__table-wrapper${
                countMissing > 0 ? "--slim" : ""
              } d-flex w-100`}
            >
              {penaltyCodes.length > 0 ? (
                <Table striped borderless>
                  <thead>
                    <tr>
                      <th className="narrow">
                        <CustomInput
                          id={`${namespace}__game-flagging-settings__penalty-picker__toggle-all`}
                          type="checkbox"
                          label={<span>&nbsp;</span>}
                          checked={isAllSelected}
                          onChange={toggleAllEnabled}
                          innerRef={el => el && (el.indeterminate = countSelected > 0 && !isAllSelected)}
                        />
                      </th>
                      <th>Code</th>
                      <th>Label</th>
                    </tr>
                  </thead>
                  <tbody>
                    {penaltyCodes.map(({ id, code, label, input, isMissing }) => (
                      <tr key={id}>
                        <td>
                          <CustomInput type="checkbox" {...input} />
                        </td>
                        <td>{code}</td>
                        <td>
                          <Label for={id}>
                            {isMissing ? (
                              <span className="text-warning">
                                Code is not specified in the list of the season {sport === 'soccer' ? "infraction" : "penalty"} types.
                              </span>
                            ) : (
                              label
                            )}
                          </Label>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <EmptyListMessage className="align-self-center">
                  We couldn’t find any {sport === 'soccer' ? "infraction" : "penalty"} types matching your criteria. Try adjusting your search terms.
                </EmptyListMessage>
              )}
            </div>
          </Fragment>
        ) : (
          <EmptyListMessage>
            No {sport === 'soccer' ? "infraction" : "penalty"} types defined.
            <br />
            Use {sport === 'soccer' ? "Infraction" : "Penalty"} Settings section to specify {sport === 'soccer' ? "infraction" : "penalty"} types.
          </EmptyListMessage>
        )}
      </ModalBody>
      <ModalFooter>
        <div className="d-flex w-100">
          {listIsNotBlank && (
            <div className="align-self-center">
              <CountPenalties
                totalCountMissing={totalCountMissing}
                totalCountSelected={totalCountSelected}
                clearSelectedPenalties={clearSelectedPenalties}
                sport={sport}
              />
            </div>
          )}
          <div className="ml-auto">
            <Button color="secondary" size="sm" onClick={close}>
              Close
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
}

PenaltyPicker.propTypes = {
  query: PropTypes.shape({}).isRequired,
  penaltyCodes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  listIsNotBlank: PropTypes.bool.isRequired,
  countSelected: PropTypes.number.isRequired,
  totalCountSelected: PropTypes.number.isRequired,
  totalCountMissing: PropTypes.number.isRequired,
  countMissing: PropTypes.number.isRequired,
  isAllSelected: PropTypes.bool.isRequired,
  isNoneSelected: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  toggleAllEnabled: PropTypes.func.isRequired,
  clearSelectedPenalties: PropTypes.func.isRequired
};

export default PenaltyPicker;
