import { createRoutine } from "redux-saga-routines";

export const loadSeasonPlayersRosterRoutine = createRoutine("SEASON_ROSTER/PLAYERS/LOADING");

export const loadSeasonCoachesRosterRoutine = createRoutine("SEASON_ROSTER/COACHES/LOADING");

export const loadSeasonPlayersRosterMergeToolPlayerStats = createRoutine(
  "SEASON_ROSTER/PLAYERS/MERGE_TOOL/LOADING_PLAYER_STATS"
);

export const loadSeasonPlayersRosterMergeToolGoalieStats = createRoutine(
  "SEASON_ROSTER/PLAYERS/MERGE_TOOL/LOADING_GOALIE_STATS"
);

export const mergeSeasonPlayersRoutine = createRoutine("SEASON_ROSTER/PLAYERS/MERGE_TOOL/MERGING");

export const mergeSeasonCoachesRoutine = createRoutine("SEASON_ROSTER/COACHES/MERGE_TOOL/MERGING");
