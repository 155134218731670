import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useDispatch } from "react-redux";
import useActionsDropdown from "./hooks/useActionsDropdown";
import { isCurrentTeamDeleting } from "@/redux/teams/selectors";
import { CurrentTeamDeletingRoutine } from "@/redux/teams/routines";
import useConfirmDeleteModal from "@/components-16.8/ConfirmDeleteModal/index";
import { InviteModal } from "./inviteModal/InviteModal";
import { Can, canCheck } from "@/components/Ability";
import useTeamLocking from "./hooks/useTeamsLocking";
import useTeamUnlocking from "./hooks/useTeamsUnlocking";
import { TeamListLoadingRoutine } from "@/redux/teams/routines";

export default function ActionsDropdown(props) {
  const {
    title,
    seasonId,
    id,
    displayDivision,
    locked,
    ignoreLock,
    setLocking,
    invitations,
  } = props;
  let { divisionId } = props;
  const dispatch = useDispatch();

  const deleteTeam = useCallback(() => {
    {
      displayDivision ? (divisionId = null) : divisionId;
    }

    return (
      dispatch(CurrentTeamDeletingRoutine({ seasonId, id, divisionId })),
      [dispatch, seasonId, id, divisionId]
    );
  });

  const teamsLocking = useTeamLocking(seasonId, id);
  const teamsUnlocking = useTeamUnlocking(seasonId, id);

  const _triggerTeamsLocking = useCallback(
    async ({ lock, seasonId, teamId }) => {
      setLocking(true);
      if (lock) {
        teamsLocking(seasonId, teamId);
      } else {
        teamsUnlocking(seasonId, teamId);
      }
      const searchParams = new URLSearchParams(location.search);
      const pageNumber = searchParams.get("page") || 1;
      const sort = searchParams.get("sort") || "none";

      await new Promise((r) => setTimeout(r, 3000));
      dispatch(
        TeamListLoadingRoutine({
          seasonId,
          divisionId,
          pageNumber,
          pageSize: 25,
          sort,
        })
      );
      setLocking(false);
    },
    []
  );

  const showButton =
    (!locked && canCheck({ I: "update", this: { type: "teams", id } })) ||
    (!locked && canCheck({ I: "delete", this: { type: "teams", id } })) ||
    (canCheck({ I: "update", this: { type: "teams", id } }) &&
      canCheck({ I: "update", this: { type: "teamLocks" } }));

  const { toggle: toggleDelete, DeleteModal } = useConfirmDeleteModal({
    onDelete: deleteTeam,
  });

  const { isOpen, toggleIsOpened, triggerEdit, isDeleting } =
    useActionsDropdown({ title, seasonId, divisionId, id });
  const [inviteIsOpen, setInviteIsOpen] = useState(false);

  return (
    <>
      {(showButton && (
        <ButtonDropdown isOpen={isOpen} toggle={toggleIsOpened}>
          <Can I="update" this={{ type: "teams", id }}>
            <Can I="update" this={{ type: "teamLocks" }}>
              <DropdownToggle caret size="sm" color="secondary" outline>
                Actions
              </DropdownToggle>
            </Can>
          </Can>

          <DropdownMenu>
            <Can I="read" an="invitations">
              <DropdownItem onClick={() => setInviteIsOpen(true)} size="sm">
                Invite to Team
              </DropdownItem>
              <InviteModal
                teamTitle={title}
                teamId={id}
                toggle={() => setInviteIsOpen((prev) => !prev)}
                isOpen={inviteIsOpen}
                invitations={invitations}
              />
            </Can>

            {(!locked || ignoreLock) && (
              <Can I="update" this={{ type: "teams", id }}>
                <DropdownItem
                  onClick={triggerEdit}
                  action="update"
                  size="sm"
                  color="primary"
                  tag={Link}
                  to={`/seasons/${seasonId}/divisions/${divisionId}/teams/${id}/edit`}
                >
                  Edit
                </DropdownItem>
              </Can>
            )}

            {(locked && (
              <Can I="update" this={{ type: "teams", id }}>
                <Can I="update" this={{ type: "teamLocks" }}>
                  <DropdownItem
                    onClick={() =>
                      _triggerTeamsLocking({
                        seasonId: seasonId,
                        divisionId: id,
                        lock: false,
                      })
                    }
                    size="sm"
                    color="primary"
                  >
                    Unlock
                  </DropdownItem>
                </Can>
              </Can>
            )) || (
              <Can I="update" this={{ type: "teams", id }}>
                <Can I="update" this={{ type: "teamLocks" }}>
                  <DropdownItem
                    onClick={() =>
                      _triggerTeamsLocking({
                        seasonId: seasonId,
                        divisionId: id,
                        lock: true,
                      })
                    }
                    size="sm"
                    color="primary"
                  >
                    Lock
                  </DropdownItem>
                </Can>
              </Can>
            )}

            {(!locked || ignoreLock) && (
              <Can I="delete" this={{ type: "teams", id }}>
                <DropdownItem divider />
                <DropdownItem className="text-danger" onClick={toggleDelete}>
                  Delete
                </DropdownItem>
                <DeleteModal
                  areActionsEnabled={isDeleting}
                  resourceTitle={title}
                  resourceType="teams"
                />
              </Can>
            )}
          </DropdownMenu>
        </ButtonDropdown>
      )) || <span />}
    </>
  );
}

ActionsDropdown.propTypes = {
  seasonId: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  locked: PropTypes.bool,
};
