import moment from "moment";

export default function validateBirthDate({ birthdate }) {
  if (!birthdate) {
    return [];
  }

  const date = moment(
    birthdate, "YYYY-MM-DD", true
  );

  if (!date.isValid()) {
    return [{ field: "birthdate", error: "invalid" }];
  }

  return [];
}
