import saveAs from "file-saver";
import { useRecoilValue } from "recoil";

import {
    readingErrorState,
} from "@/components-16.8/RefereeImportWizard/state";

export function useLanding() {

    const readingError = useRecoilValue(readingErrorState);

    const IMPORT_CSV_TEMPLATE = [
        "First Name,Last Name,Email Address,External ID\n",
        ",,,"
    ];

    function handleDownloadTemplate(){
        const blob = new Blob(IMPORT_CSV_TEMPLATE, { type: "text/csv;charset=utf-8" });
        saveAs(blob, `gamesheet-referee-import-template.csv`);
      }

    return {
        readingError,
        handleDownloadTemplate
    }
}