import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { isBigTeamInvitationListLoaded, getBigInvitationListTeams } from "@/redux/teams/selectors";

import { BigTeamInvitationListLoadingRoutine } from "@/redux/teams/routines";

export default function useTeamsListLoading({ seasonId, divisionId, title }) {
  const dispatch = useDispatch();

  const isBigListLoaded = useSelector(isBigTeamInvitationListLoaded);
  const bigList = useSelector(getBigInvitationListTeams);

  const processCells = params => {
    if (params.column.colId === "invitations") {
      const inviteCodes = params.value.map(item => {
        return item.code;
      });
      return inviteCodes;
    }
    return params.value;
  };

  const csvExportParams = {
    fileName: `Invitation codes ${title}.csv`,
    customHeader: "Title,Division,Invitation Code",
    columnKeys: ["title", "divisionTitle", "invitations"],
    processCellCallback: processCells
  };

  useEffect(
    () => {
      if (isBigListLoaded) {
        const rows = bigList.map(team => {
          team.divisionTitle = team.division.title;
          return csvExportParams.columnKeys
            .map(key => {
              const value = csvExportParams.processCellCallback({ column: { colId: key }, value: team[key] });
              return `"${value}"`;
            })
            .join(",");
        });

        rows.splice(0, 0, csvExportParams.customHeader);

        const content = rows.reduce((collector, row) => {
          return collector + row + "\r\n";
        }, "");

        const hiddenLink = document.createElement("a");
        hiddenLink.href = "data:text/csv;charset=utf-8," + encodeURIComponent(content);
        hiddenLink.download = csvExportParams.fileName;
        hiddenLink.click();
      }
    },
    [isBigListLoaded]
  );

  return {
    triggerInvitationExport: () => {
      dispatch(BigTeamInvitationListLoadingRoutine({ seasonId, divisionId }));
    }
  };
}
