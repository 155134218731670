import { connect } from "react-redux";

import { seasonRosterActions } from "@/redux/seasonRoster/actions";

import CoachesListMergeToolCoach from "./Coach";

const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  chooseAsWinner: () => dispatch(seasonRosterActions.coaches.mergeTool.chooseWinner(ownProps.id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoachesListMergeToolCoach);
