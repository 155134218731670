import { all, put, takeLatest } from "redux-saga/effects";

import { loadAssociation } from "@/lib/api/associations";

import { fetchOne } from "@/redux/api/sagas";

import { loadingRoutine } from "./routines";

function* loadingSaga({ payload: { associationId } }) {
  yield put(loadingRoutine.request());

  try {
    const [association] = yield fetchOne({ type: "associations", id: associationId }, loadAssociation);

    yield put(loadingRoutine.success({ association }));
  } catch (error) {
    const { response } = error;

    yield put(
      loadingRoutine.failure({
        error,
        responseStatus: response && response.responseStatus
      })
    );
  } finally {
    yield put(loadingRoutine.fulfill());
  }
}

export function* currentAssociationFlow() {
  yield all([takeLatest(loadingRoutine.TRIGGER, loadingSaga)]);
}
