import { combineReducers } from "redux";

import reset from "./reducers/reset";
import newReducer from "./reducers/new";
import editReducer from "./reducers/edit";

export default combineReducers({
  reset,
  new: newReducer,
  edit: editReducer
});
