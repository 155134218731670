import { combineReducers } from "redux";
import { handleActions, combineActions } from "redux-actions";

import actions from "./actions";

import { associationList, leagueList, seasonList, divisionList, teamList } from "./reducers";
import { initRoutine } from "./routines";

const roleIsGlobal = handleActions(
  {
    [actions.setGlobal]: (state, { payload: { isChecked } }) => isChecked,
    [initRoutine.TRIGGER]: (state, { payload: initRole }) => {
      if (initRole && initRole.level && initRole.level.id === "*" && initRole.level.type === "") {
        return true;
      } else {
        return false;
      }
    },
    [actions.reset]: () => false,
  },
  false
);

const selectedAssociations = handleActions(
  {
    [initRoutine.TRIGGER]: (state, { payload }) => {
      if (payload && payload.level && payload.level.type === "associations") {
        const associationId = payload.level.id;
        return state.includes(associationId) ? state : [associationId]; // Add only if not already present
      }
      return state;
    },
    [actions.associationList.select]: (state, { payload: { id } }) => [...new Set([...state, id])], // Ensure no duplicates on select
    [actions.associationList.deselect]: (state, { payload: { id } }) => state.filter((associationId) => associationId !== id),
    [actions.associationList.clear]: () => [], // Clear selection
    [combineActions(
      actions.reset
    )]: () => []
  },
  [] // Initial state is an empty array
);

const selectedLeagues = handleActions(
  {
    [actions.leagueList.select]: (state, { payload: { id } }) => [...state, id], // Add league to selected array
    [actions.leagueList.deselect]: (state, { payload: { id } }) => state.filter(leagueId => leagueId !== id), // Remove league from selected array
    [actions.leagueList.clear]: () => [], // Clear selection
    [combineActions(
      actions.associationList.select,
      actions.associationList.deselect,
      actions.reset
    )]: () => [] // Reset selection on association deselect or reset
  },
  [] // Initial state is an empty array
);

const selectedSeasons = handleActions(
  {
    [actions.seasonList.select]: (state, { payload: { id } }) => [...state, id], // Add season to selected array
    [actions.seasonList.deselect]: (state, { payload: { id } }) => state.filter(seasonId => seasonId !== id), // Remove season from selected array
    [actions.seasonList.clear]: () => [], // Clear selection
    [combineActions(
      actions.associationList.select,
      actions.associationList.deselect,
      actions.leagueList.select,
      actions.leagueList.deselect,
      actions.reset
    )]: () => [] // Reset selection on association deselect or reset
  },
  [] // Initial state is an empty array
);

const selectedDivisions = handleActions(
  {
    [actions.divisionList.select]: (state, { payload: { id } }) => [...state, id], // Add division to selected array
    [actions.divisionList.deselect]: (state, { payload: { id } }) => state.filter(divisionId => divisionId !== id), // Remove division from selected array
    [actions.divisionList.clear]: () => [], // Clear selection
    [combineActions(
      actions.associationList.select,
      actions.associationList.deselect,
      actions.leagueList.select,
      actions.leagueList.deselect,
      actions.seasonList.select,
      actions.seasonList.deselect,
      actions.reset
    )]: () => [] // Reset selection on association deselect or reset
  },
  [] // Initial state is an empty array
);

const selectedTeams = handleActions(
  {
    [actions.teamList.select]: (state, { payload: { id } }) => [...state, id], // Add team to selected array
    [actions.teamList.deselect]: (state, { payload: { id } }) => state.filter(teamId => teamId !== id), // Remove team from selected array
    [actions.teamList.clear]: () => [], // Clear selection
    [combineActions(
      actions.associationList.select,
      actions.associationList.deselect,
      actions.leagueList.select,
      actions.leagueList.deselect,
      actions.seasonList.select,
      actions.seasonList.deselect,
      actions.divisionList.select,
      actions.divisionList.deselect,
      actions.reset
    )]: () => [] // Reset selection on association deselect or reset
  },
  [] // Initial state is an empty array
);

const title = handleActions(
  {
    [actions.setTitle]: (state, { payload: title }) => title,
    [initRoutine.TRIGGER]: (state, { payload: initRole = { title: "" } }) => initRole.title,
    [actions.reset]: () => ""
  },
  ""
);

const levels = handleActions(
  {
    [actions.associationList.select]: (state, { payload: { id } }) => [...state, { type: "associations", id }],
    [actions.associationList.deselect]: (state, { payload: { id } }) => state.filter(level => level.id !== id),

    [actions.leagueList.select]: (state, { payload: { id } }) => [...state, { type: "leagues", id }],
    [actions.leagueList.deselect]: (state, { payload: { id } }) => state.filter(level => level.id !== id),

    [actions.seasonList.select]: (state, { payload: { id } }) => [...state, { type: "seasons", id }],
    [actions.seasonList.deselect]: (state, { payload: { id } }) => state.filter(level => level.id !== id),

    [actions.divisionList.select]: (state, { payload: { id } }) => [...state, { type: "divisions", id }],
    [actions.divisionList.deselect]: (state, { payload: { id } }) => state.filter(level => level.id !== id),

    [actions.teamList.select]: (state, { payload: { id } }) => [...state, { type: "teams", id }],
    [actions.teamList.deselect]: (state, { payload: { id } }) => state.filter(level => level.id !== id),

    [actions.teamList.clear]: (state) => {
      // Remove all teams and fallback to the last selected division
      const newLevels = state.filter(level => level.type !== "teams");
      const lastSelectedDivision = [...newLevels].reverse().find(level => level.type === "divisions");
      return lastSelectedDivision ? [...newLevels, lastSelectedDivision] : newLevels;
    },
    
    [actions.divisionList.clear]: (state) => {
      // Remove all divisions and fallback to the last selected season
      const newLevels = state.filter(level => level.type !== "divisions");
      const lastSelectedSeason = [...newLevels].reverse().find(level => level.type === "seasons");
      return lastSelectedSeason ? [...newLevels, lastSelectedSeason] : newLevels;
    },
    
    [actions.seasonList.clear]: (state) => {
      // Remove all seasons and fallback to the last selected league
      const newLevels = state.filter(level => level.type !== "seasons");
      const lastSelectedLeague = [...newLevels].reverse().find(level => level.type === "leagues");
      return lastSelectedLeague ? [...newLevels, lastSelectedLeague] : newLevels;
    },
    
    [actions.leagueList.clear]: (state) => {
      // Remove all leagues and fallback to the last selected association
      const newLevels = state.filter(level => level.type !== "leagues");
      const lastSelectedAssociation = [...newLevels].reverse().find(level => level.type === "associations");
      return lastSelectedAssociation ? [...newLevels, lastSelectedAssociation] : newLevels;
    },

    [actions.associationList.clear]: () => [], // Clear everything for associations

    [actions.reset]: () => []
  },
  [] // Initial state is an empty array
);

const isVisible = handleActions({ [initRoutine.TRIGGER]: () => true, [actions.reset]: () => false }, false);

const isInitializing = handleActions(
  {
    [initRoutine.REQUEST]: () => true,
    [initRoutine.FULFILL]: () => false
  },
  false
);

const initRole = handleActions(
  {
    [initRoutine.TRIGGER]: (state, { payload = null }) => payload,
    [actions.reset]: () => null
  },
  null
);

export default combineReducers({
  roleIsGlobal,
  selectedAssociations, // Use selectedAssociations instead of associationId
  selectedLeagues, // Use selectedLeagues instead of leagueId
  selectedSeasons, // Use selectedSeasons instead of seasonId
  selectedDivisions, // Use selectedDivisions instead of divisionId
  selectedTeams, // Use selectedTeams instead of teamId
  title,
  levels,
  isVisible,
  initRole,
  isInitializing,
  associationList,
  leagueList,
  seasonList,
  divisionList,
  teamList
});
