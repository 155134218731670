export default function validateTeamId({ teamId }, validationOptions) {
  const { validTeamIds, lockedTeamIds, skipTeamIdValidation } = validationOptions;

  if(skipTeamIdValidation){
    return [];
  }

  switch (true) {
    case teamId === "": {
      return [];
    }
    case !validTeamIds.includes(teamId): {
      return [{ field: "teamId", error: "invalid" }];
    }
    case lockedTeamIds.includes(teamId): {
      return [{ field: "teamId", error: "locked" }];
    }
    default: {
      return [];
    }
  }
}
