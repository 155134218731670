import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap";
import React, { useState, useCallback } from "react";
import { getEncodedAccessToken } from "@/redux/token/selectors";
import { useSelector } from "react-redux";
import Spinner from "@/components/Spinner";
import { getAccountEmail } from "@/redux/account";
import { config } from "@/config";

import "./inviteModal.scss";
import { EyeIcon, WhistleIcon, CheckmarkIcon, CloseIcon } from "./icons";

export const InviteModal = ({ isOpen, toggle, teamTitle, teamId, invitations }) => {
  const [role, setRole] = useState("direct");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const accessToken = useSelector(getEncodedAccessToken);
  const inviter = useSelector(getAccountEmail);
  const [loading, setLoading] = useState(false);

  // Regular expression for email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Validate email string (comma-separated)
  const validateEmails = (emails) => {
    const emailArray = emails.split(',').map(email => email.trim());
    for (const email of emailArray) {
      if (!emailRegex.test(email)) {
        return false;
      }
    }
    return true;
  };

  const handleSendEmails = useCallback(
    async (emails) => {
      // Validate comma-separated emails string
      if (!validateEmails(emails)) {
        setError("Please enter valid email addresses.");
        return;
      }
      
      setError("");  // Reset error
      setLoading(true);

      try {
        const response = await fetch(`${config.EVENTLOG_GATEWAY}/v5/teams/${teamId}/dashboard-invite-users`, {
          method: "POST",
          body: JSON.stringify({
            attributes: {
              event: "invite-users",
              schema: "invitation-emails",
              version: "v3",
            },
            data: {
              emails: emails.toLowerCase().replace(/\s/g, ''),  
              team_id: parseInt(teamId),
              invited_by: inviter,
              invite_code: invitations,
            },
          }),
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(response.statusText);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
        toggle();
      }
    },
    [role, accessToken, config, teamTitle, invitations, teamId, toggle]
  );

  const content = (() => {
    if (loading) {
      return <Spinner className="page-loader__spinner page-loader__spinner__absolute" theme="loader" color="yellow" size="xxl" />;
    } else if (role === "direct") {
      return (
        <>
          <ModalHeader toggle={toggle}>Invite Team Staff</ModalHeader>
          <ModalBody>
            <Input
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              invalid={!!error}
            />
            {error && <p className="text-danger">{error}</p>}
            <p>To send multiple invitations, separate additional emails by commas.</p>
          </ModalBody>
          <ModalFooter>
            <div className="d-flex w-100">
              <div className="mr-auto">
                <Button type="button" color="secondary" onClick={toggle}>
                  Close
                </Button>
              </div>
              <div>
                <Button outline type="button" color="success" onClick={() => handleSendEmails(email)} disabled={!email}>
                  Send Invitation
                </Button>
              </div>
            </div>
          </ModalFooter>
        </>
      );
    } else if (role === "indirect") {
      return (
        <>
          <ModalHeader toggle={toggle}>Invite Non Staff</ModalHeader>
          <ModalBody>
            <Input
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              invalid={!!error}
            />
            {error && <p className="text-danger">{error}</p>}
            <p>To send multiple invitations, separate additional emails by commas.</p>
          </ModalBody>
          <ModalFooter>
            <div className="d-flex w-100">
              <div className="mr-auto">
                <Button type="button" color="secondary" onClick={() => setRole("")}>
                  Back
                </Button>
              </div>
              <div>
                <Button outline type="button" color="success" onClick={() => handleSendEmails(email)} disabled={!email}>
                  Send Invitation
                </Button>
              </div>
            </div>
          </ModalFooter>
        </>
      );
    } else {
      return (
        <>
          <ModalHeader toggle={toggle}>Select a Role</ModalHeader>
          <ModalBody>
            <p>Select the type of user you'd like to invite to {teamTitle}</p>
            <div className="option" onClick={() => setRole("direct")}>
              <div className="title-bar">
                <div className="left">
                  <div className="icon">
                    <WhistleIcon />
                  </div>
                  <p className="title">Team Staff</p>
                </div>
                <div className="right">
                  <span className="arrow">{">"}</span>
                </div>
              </div>
              <p>Have a direct relationship with the team, serving in roles such as Manager, Coach, Helper, Trainer, and so on.</p>
              <div className="bullets">
                <div className="bullet">
                  <div className="icon">
                    <CheckmarkIcon color="green" />
                  </div>
                  <p>Access to Team App</p>
                </div>
                <div className="bullet">
                  <div className="icon">
                    <CheckmarkIcon color="green" />
                  </div>
                  <p>Set Game Lineups</p>
                </div>
                <div className="bullet">
                  <div className="icon">
                    <CheckmarkIcon color="green" />
                  </div>
                  <p>Scheduled Practices</p>
                </div>
                <div className="bullet">
                  <div className="icon">
                    <CheckmarkIcon color="green" />
                  </div>
                  <p>Team Chat & Messaging</p>
                </div>
              </div>
            </div>
            <div className="option" onClick={() => setRole("indirect")}>
              <div className="title-bar">
                <div className="left">
                  <div className="icon">
                    <EyeIcon />
                  </div>
                  <p className="title">Non-Staff</p>
                </div>
                <div className="right">
                  <span className="arrow">{">"}</span>
                </div>
              </div>
              <p>Need access to the team's scoresheets but do not manage the team directly and are not involved in game-day workflows like setting game lineups.</p>
              <div className="bullets">
                <div className="bullet">
                  <div className="icon">
                    <CheckmarkIcon color="green" />
                  </div>
                  <p>Access to Admin Dashboard</p>
                </div>
                <div className="bullet">
                  <div className="icon">
                    <CheckmarkIcon color="green" />
                  </div>
                  <p>Access to Scoresheets</p>
                </div>
                <div className="bullet">
                  <div className="icon">
                    <CloseIcon color="red" />
                  </div>
                  <p>Set Game Lineups</p>
                </div>
                <div className="bullet">
                  <div className="icon">
                    <CloseIcon color="red" />
                  </div>
                  <p>Team Chat & Messaging</p>
                </div>
              </div>
            </div>
          </ModalBody>
        </>
      );
    }
  })();

  return (
    <div>
      <Modal className="confirm-invite-modal" isOpen={isOpen} toggle={toggle} fade={false} centered>
        {content}
      </Modal>
    </div>
  );
};
