import { connect } from "react-redux";

import CoachesListSearchBar from "./SearchBar";

import {
  getSeasonRosterCoachesListSearchNextQuery,
  getSeasonRosterCoachesListSearchCurrentQuery,
  getSeasonRosterCoachesListSearchQueryIsDirty,
  getSeasonRosterCoachesListIsLoading
} from "@/redux/seasonRoster/selectors";

import { seasonRosterActions } from "@/redux/seasonRoster/actions";

const mapStateToProps = state => ({
  nextQuery: getSeasonRosterCoachesListSearchNextQuery(state),
  currentQuery: getSeasonRosterCoachesListSearchCurrentQuery(state),
  isDirty: getSeasonRosterCoachesListSearchQueryIsDirty(state),
  isLoading: getSeasonRosterCoachesListIsLoading(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setQuery: query => dispatch(seasonRosterActions.coaches.search.setNextQuery(query)),
  commit: () => dispatch(seasonRosterActions.coaches.search.commit({ seasonId: ownProps.seasonId })),
  clear: () => dispatch(seasonRosterActions.coaches.search.clear({ seasonId: ownProps.seasonId }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoachesListSearchBar);
