import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import AbilityLink from "@/components/AbilityLink";

class GameLink extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    season: PropTypes.shape({
      id: PropTypes.string.isRequired,
      isArchived: PropTypes.bool.isRequired
    }).isRequired
  };

  render() {
    const { season, id, title } = this.props;

    if (season.isArchived) {
      return title;
    }

    return (
      <AbilityLink
        subject={{ type: "games", id }}
        action="read"
        to={`/seasons/${season.id}/games/completed/${id}`}
        fallback={title}
      >
        {title}
      </AbilityLink>
    );
  }
}

export default GameLink;
