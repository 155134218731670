import { connect } from "react-redux";

import {
  getEditPasswordFormFields,
  getEditPasswordFormIsDirty,
  getEditPasswordIsWorking,
  getEditPasswordIsCompleted,
  getEditPasswordError
} from "@/redux/password/selectors";

import { passwordActions } from "@/redux/password/actions";
import { validatePasswordRoutine } from "@/redux/password/routines";

import Password from "./Password";

const mapStateToProps = (state, ownProps) => ({
  fields: getEditPasswordFormFields(state),
  isFormDirty: getEditPasswordFormIsDirty(state),
  isWorking: getEditPasswordIsWorking(state),
  isCompleted: getEditPasswordIsCompleted(state),
  error: getEditPasswordError(state)
});

const mapDispatchToProps = dispatch => ({
  changeField: payload => dispatch(passwordActions.edit.changeField(payload)),
  updatePassword: attributes => dispatch(validatePasswordRoutine(attributes)),
  clear: () => dispatch(passwordActions.edit.clear())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Password);
