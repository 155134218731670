import React, { Fragment } from "react";

import { Table } from "reactstrap";

import TotalCount from "@/components/TotalCount";
import EmptyListMessage from "@/components/EmptyListMessage";
import PageLoader from "@/components/PageLoader";
import Pagination from "@/components/Pagination";

import useTeamListSelector from "./hooks/useTeamListSelector";
import useTeamListLoader from "./hooks/useTeamListLoader";

import Team from "./Team";

import "./List.scss";

function List() {
  const {
    teams,
    isLoading,
    isLoaded,
    totalCount,
    filteredCount,
    pageNumber,
    totalPages,
    filterIsDirty
  } = useTeamListSelector();

  const { loadPage } = useTeamListLoader();

  if (!isLoading && !filterIsDirty && teams.length === 0) {
    return <EmptyListMessage>Search or use filters to see the list of available teams.</EmptyListMessage>;
  } else if (!isLoading && isLoaded && filterIsDirty && teams.length === 0) {
    return (
      <EmptyListMessage>
        We couldn’t find any teams matching your criteria. Try adjusting your search terms or reducing your filter
        selections.
      </EmptyListMessage>
    );
  }

  return (
    <Fragment>
      {isLoaded &&
        teams.length > 0 && (
          <TotalCount plural="teams" singular="team" totalCount={totalCount} filteredCount={filteredCount} />
        )}

      <PageLoader isLoaded={isLoaded} isLoading={isLoading}>
        {teams.length > 0 && (
          <Table striped borderless>
            <thead>
              <tr>
                <th scope="col" className="copy-teams__list__team__title">
                  Team
                </th>
                <th scope="col">Division</th>
                <th className="narrow">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {teams.map(({ id, ...rest }) => (
                <Team key={id} id={id} {...rest} />
              ))}
            </tbody>
          </Table>
        )}
      </PageLoader>

      <Pagination currentPage={pageNumber} totalPages={totalPages} changePage={loadPage} />
    </Fragment>
  );
}

export default List;
