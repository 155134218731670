import React, { useCallback } from "react";
import PropTypes from "prop-types";

import useMemberEditTrigger from "./hooks/useMemberEditTrigger";

import "./MemberEditTrigger.scss";

function MemberEditTrigger({ member, fieldName, children, disabled }) {
  const { triggerEdit, isLocked } = useMemberEditTrigger({ member, autoFocus: fieldName });

  const handleKeyPress = useCallback(
    ({ key }) => {
      if (key === "Enter") {
        triggerEdit();
      }
    },
    [triggerEdit]
  );

  if (disabled) {
    return children;
  }

  return (
    <span
      className={isLocked ? "" : "team-roster__member-edit-trigger"}
      onClick={triggerEdit}
      onKeyPress={handleKeyPress}
      role="button"
      tabIndex={0}
    >
      {children}
    </span>
  );
}

MemberEditTrigger.propTypes = {
  member: PropTypes.shape({}).isRequired,
  fieldName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool
};

MemberEditTrigger.defaultProps = {
  disabled: false
};

export default MemberEditTrigger;
