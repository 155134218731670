import React from "react";
import DropdownInput from "./DropdownInput";

export default function GameTypeInput() {
  return (
    <DropdownInput name="gameType" actionName="setGametype" label="Game type">
      <option value="">All</option>
      <option value="regular_season">Regular Season</option>
      <option value="exhibition">Exhibition</option>
      <option value="tournament">Tournament</option>
      <option value="playoff">Playoff</option>
    </DropdownInput>
  );
}
