import normalize from "json-api-normalizer";
import _snakeCase from "lodash/snakeCase";
import _camelCase from "lodash/camelCase";
import _kebabCase from "lodash/kebabCase";
import _isDate from "lodash/isDate";
import qs from "qs";

function normalizeIdentity({ id, type, ...rest }) {
  const result = {};

  if (id) {
    result.id = id;
  }

  if (type) {
    result.type = _kebabCase(type);
  }

  return { ...result, ...rest };
}

export function deepNormalizeObject(object) {
  if (object === undefined || object === null || _isDate(object) || typeof object !== "object") {
    return object;
  }

  if (Array.isArray(object)) {
    return object.map(item => deepNormalizeObject(item));
  }

  return Object.keys(object).reduce(
    (result, key) => ({
      ...result,
      [_snakeCase(key)]: deepNormalizeObject(object[key])
    }),
    {}
  );
}

function normalizeAttributes(attributes) {
  return deepNormalizeObject(attributes);
}

function normalizeRelationshipData(data) {
  if (data === undefined || data === null || typeof data !== "object") {
    return data;
  }

  if (Array.isArray(data)) {
    return data.map(item => normalizeIdentity(item));
  }

  return normalizeIdentity(data);
}

function normalizeRelationships(relationships) {
  if (
    relationships === undefined ||
    relationships === null ||
    typeof relationships !== "object" ||
    Array.isArray(relationships)
  ) {
    return relationships;
  }

  return Object.keys(relationships).reduce((result, key) => {
    const { data, ...rest } = relationships[key] || {};

    if (data) {
      return {
        ...result,
        [_snakeCase(key)]: { data: normalizeRelationshipData(data), ...rest }
      };
    }

    return result;
  }, {});
}

function normalizeRequestData(data) {
  if (data === undefined || data === null || typeof data !== "object") {
    return data;
  }

  if (Array.isArray(data)) {
    return data.map(item => normalizeRequestData(item));
  }

  let result = {};

  const identity = normalizeIdentity(data);
  const attributes = normalizeAttributes(data.attributes);
  const relationships = normalizeRelationships(data.relationships);

  if (identity) {
    result = { ...identity };
  }

  if (attributes) {
    result = { ...result, attributes };
  }

  if (relationships) {
    result = { ...result, relationships };
  }

  return result;
}

export function parseResponse(body) {
  let data;

  if (typeof body === "string") {
    try {
      data = JSON.parse(body);
    } catch (e) {
      /* Ignore */
    }
  } else if (typeof body === "object") {
    return body;
  }

  return data;
}

export function normalizeResponse(body) {
  if (typeof body !== "object") {
    return {};
  }

  const { data, ...rest } = body;
  const result = { ...rest };

  if (data) {
    result.data = normalize(body);
    result._rawData = data;
  }

  return result;
}

export function normalizeRequest(data) {
  if (data && typeof data === "object") {
    return { ...data, data: normalizeRequestData(data.data) };
  }

  return data;
}

export function stringifyRequest(data) {
  if (data && typeof data === "object") {
    return JSON.stringify(data);
  }

  return data;
}

export function serializeParams(params) {
  return qs.stringify(params, { arrayFormat: "brackets" });
}

export function responseErrorsFullMessages(response, fieldsMapping) {
  const {
    data: { errors },
    status
  } = response;

  if (status === 400 && errors) {
    return errors.reduce((result, { title: error, source: { pointer } }) => {
      const name = pointer
        .split("/")
        .slice(3)
        .map(_camelCase)
        .join("/");

      if (!name) {
        return result;
      }

      const title = fieldsMapping[name];
      const errors = [...(result[name] || []), `${title} ${error}`];

      return {
        ...result,
        [name]: errors
      };
    }, {});
  }

  return {};
}
