import React from "react";
import PropTypes from "prop-types";

import classNames from "classnames";

const colorByStatus = {
  active: "text-success",
  pending: "text-warning"
};

function Status({ status, className }) {
  const color = colorByStatus[status];

  return <span className={classNames(color, "text-capitalize", className)}>{status}</span>;
}

Status.propTypes = {
  status: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default Status;
