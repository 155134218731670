import React from "react";
import PropTypes from "prop-types";

import FormSection from "@/components/FormSection";

import { useDivisionPenaltySettingsFields } from "./hooks/useDivisionPenaltySettingsFields";

import PenaltyDurations from "./PenaltyDurations";

export function DivisionPenaltySettingsFieldset({ value, onChange, sport }) {
  const { penaltyDurations } = useDivisionPenaltySettingsFields({
    value,
    onChange
  });

  penaltyDurations.sport = sport;

  return (
    <FormSection title={`${sport === 'soccer' ? "Infraction" : "Penalty"} Settings`}>
      <PenaltyDurations {...penaltyDurations} />
    </FormSection>
  );
}

DivisionPenaltySettingsFieldset.propTypes = {
  value: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func
};

DivisionPenaltySettingsFieldset.defaultProps = {
  onChange() {}
};
