import { handleActions } from "redux-actions";

import { clearGamesheet } from "../../actions";
import { loadGamesheetRoutine } from "../../routines";
import reduceProperties from "../../utils/reduceProperties";

const initialState = {
  number: "",
  startTime: "",
  endTime: "",
  location: "",
  category: "",
  periods: [],
  league: "",
  firstFlood: false,
  secondFlood: false,
  curfew: {
    time: "",
    visitorCoachSignature: null,
    homeCoachSignature: null
  },
  fppEnabled: false,
  gameType: {
    code: "",
    gamesPlayed: 0,
    gamesTotal: 0
  },
  scorekeeper: {
    name: "",
    phone: ""
  },
  flags: []
};

export default handleActions(
  {
    [loadGamesheetRoutine.SUCCESS]: reduceProperties,
    [clearGamesheet]: () => initialState
  },
  initialState
);
