import React from "react";
import PropTypes from "prop-types";
import { Row, Col, FormGroup, CustomInput } from "reactstrap";

import { usePlayerOfTheGameFieldset } from "./hooks";

import FormSection from "@/components/FormSection";
import FormItem from "@/components/FormItem";
import Message from "./components/Message";
import Coupon from "./components/Coupon";

import "./PlayerOfTheGameFieldset.scss";

function PlayerOfTheGameFieldset({ value, onChange, errors }) {
  const { enabled, message, coupon } = usePlayerOfTheGameFieldset({
    value,
    onChange,
    errors
  });

  return (
    <FormSection title="Player of the Game">
      <FormItem className="player-of-the-game__toggle">
        <FormGroup className="col">
          <CustomInput
            id={`$player-of-the-game__toggle__input`}
            type="switch"
            label="Allow Team Managers to select Player of the Game"
            {...enabled}
          />
        </FormGroup>
      </FormItem>

      {enabled.checked && (
        <Row>
          <Col md="6">
            <Message {...message} />
          </Col>

          <Col md="6">
            <Coupon {...coupon} />
          </Col>
        </Row>
      )}
    </FormSection>
  );
}

PlayerOfTheGameFieldset.propTypes = {
  value: PropTypes.shape({
    coupon: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)]),
    message: PropTypes.string
  }),
  onChange: PropTypes.func,
  errors: PropTypes.arrayOf(PropTypes.any)
};

PlayerOfTheGameFieldset.defaultProps = {
  onChange() {}
};

export default PlayerOfTheGameFieldset;
