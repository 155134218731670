import _isEmpty from "lodash/isEmpty";

export function validateFirstName(value) {
  const errors = [];

  if (_isEmpty(value)) {
    errors.push("First name can not be empty");
  }

  return errors;
}

export function validateLastName(value) {
  const errors = [];

  if (_isEmpty(value)) {
    errors.push("Last name can not be empty");
  }

  return errors;
}

export function validateEmailAddress(value) {
  if (value !== "" && !value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
    return ["Invalid address"];
  }
  return [];
}

export function validateExternalId(value) {
  return [];
}

export function validate({ firstName, lastName, externalId }) {
  let errors = null;

  const firstNameErrors = validateFirstName(firstName);
  const lastNameErrors = validateLastName(lastName);
  const externalIdErrors = validateExternalId(externalId);

  if (firstNameErrors.length > 0) {
    errors = { ...errors, firstName: firstNameErrors };
  }

  if (lastNameErrors.length > 0) {
    errors = { ...errors, lastName: lastNameErrors };
  }

  if (externalIdErrors.length > 0) {
    errors = { ...errors, externalId: externalIdErrors };
  }

  return errors;
}
