import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { GAME_TYPES } from "@/constants";

import { Table } from "reactstrap";

class Category extends PureComponent {
  static propTypes = {
    league: PropTypes.string,
    divisions: PropTypes.string,
    category: PropTypes.string,
    gameType: PropTypes.shape({
      code: PropTypes.string,
      progress: PropTypes.string
    })
  };

  static defaultProps = {
    league: "N/A",
    divisions: "N/A",
    category: "N/A",
    gameType: {
      code: "",
      progress: null
    }
  };

  render() {
    const { league, divisions, category, gameType } = this.props;

    return (
      <Table striped borderless size="sm">
        <thead>
          <tr>
            <th scope="col">League</th>
            <th scope="col">Divisions</th>
            <th scope="col">Category</th>
            <th scope="col">{gameType.progress ? `${GAME_TYPES[gameType.code]} Game` : " "}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{league}</td>
            <td>{divisions}</td>
            <td className="text-uppercase">{category}</td>
            <td>{gameType.progress}</td>
          </tr>
        </tbody>
      </Table>
    );
  }
}

export default Category;
