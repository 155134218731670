import { handleActions } from "redux-actions";

import { loadingRoutine, lockRostersRoutine } from "../routines";
import actions from "../actions";

const initialState = {
  isLoading: false,
  isLoaded: false,
  isFailed: false,
  isForbidden: false,
  isRosterLocking: false
};

function reduceLoadingRequest(state) {
  return { ...state, isLoading: true, isRosterLocking: false };
}

function reduceLoadingSuccess(state) {
  return { ...state, isLoaded: true };
}

function reduceLoadingFailure(state, { payload: { responseStatus } }) {
  return { ...state, isFailed: true, isForbidden: responseStatus === 401 };
}

function reduceLoadingFulfill(state) {
  return { ...state, isLoading: false };
}

function reduceRosterLockRequest(state) {
  return { ...state, isRosterLocking: true };
}

function reduceRosterLockFulfill(state) {
  return { ...state, isRosterLocking: false };
}

export default handleActions(
  {
    [loadingRoutine.REQUEST]: reduceLoadingRequest,
    [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [loadingRoutine.FAILURE]: reduceLoadingFailure,
    [loadingRoutine.FULFILL]: reduceLoadingFulfill,
    [lockRostersRoutine.REQUEST]: reduceRosterLockRequest,
    [lockRostersRoutine.FULFILL]: reduceRosterLockFulfill,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
