import React from "react";

import FilterSelect from "@/components/FilterSelect";

import useSeasonSelect from "./hooks/useSeasonSelect";

function SeasonSelect() {
  const { options, selectedId, isLoaded, isLoading, setSelectedId } = useSeasonSelect();

  return (
    <FilterSelect
      placeholder="All seasons"
      value={selectedId}
      isLoaded={isLoaded}
      isLoading={isLoading}
      onChange={setSelectedId}
    >
      {options.map(({ id, title }) => (
        <option key={id} value={id}>
          {title}
        </option>
      ))}
    </FilterSelect>
  );
}

export default SeasonSelect;
