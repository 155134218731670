import React from "react";
import PropTypes from "prop-types";
import _toUpper from "lodash/toUpper";

import { Button } from "reactstrap";

import Field from "./Field";

import useCoachForm from "../hooks/useCoachForm";
import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

function CoachForm({ coachId }) {
  const { triggerReset, isInvalid, isNotFilled, isPristine, isSaving, triggerSave } = useCoachForm(coachId);
  const { season } = useCurrentSeasonContext();

  return (
    <tr>
      <td>
        <Field member={{ type: "coaches", id: coachId }} name="firstName" transform={_toUpper} />
      </td>
      <td>
        <Field member={{ type: "coaches", id: coachId }} name="lastName" transform={_toUpper} />
      </td>
      <td>
        <Field
          member={{ type: "coaches", id: coachId }}
          name="externalId"
          transform={_toUpper}
          placeholder="Optional"
        />
      </td>
      <td>
        <Field member={{ type: "coaches", id: coachId }} name="position" type="select">
          <option value="">None</option>
          {season.coachPositions.map(position => (
            <option key={position.key} value={position.key}>
              {position.value}
            </option>
          ))}
        </Field>
      </td>
      <td className="text-right">
        <Button size="sm" color="secondary" outline onClick={triggerReset}>
          Cancel
        </Button>
        <Button
          type="submit"
          size="sm"
          color="success"
          outline={isInvalid || isNotFilled || isPristine}
          disabled={isInvalid || isNotFilled || isPristine}
          onClick={triggerSave}
          className="ml-1"
        >
          {isSaving ? "Saving…" : "Save"}
        </Button>
      </td>
    </tr>
  );
}

CoachForm.propTypes = {
  coachId: PropTypes.string.isRequired
};

export default CoachForm;
