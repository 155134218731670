import React, { Fragment } from "react";
import PropTypes from "prop-types";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import PageLoader from "@/components/PageLoader";
import CoachForm from "@/components/CoachForm";

import useCurrentCoach from "../hooks/useCurrentCoach";
import useCoachManipulation from "../hooks/useCoachManipulation";

function EditCoach({ seasonId, match: { params } }) {
  const { coachId } = params;

  const { seasonIsLoaded, seasonIsLoading, seasonBreadcrumbs } = useCurrentSeasonContext();

  const { isCoachOperating, isCoachReady, isCoachForbidden, isCoachDeleting, coach, teams } = useCurrentCoach({
    seasonId,
    coachId
  });

  const { updateCoach, deleteCoach } = useCoachManipulation({
    seasonId,
    coachId
  });

  return (
    <ProtectedLayout condition={!isCoachForbidden}>
      {isCoachReady && seasonIsLoaded ? (
        <Fragment>
          <Head title="Edit Coach" />
          <Breadcrumbs
            items={seasonBreadcrumbs}
            lastItem={{
              title: `${coach.firstName} ${coach.lastName}`,
              url: `/seasons/${seasonId}/roster/coaches/${coachId}`
            }}
          />

          <TitleBar title="Edit Coach" />

          <CoachForm
            {...coach}
            teams={teams}
            seasonId={seasonId}
            isOperating={isCoachOperating}
            isDeleting={isCoachDeleting}
            onSubmit={updateCoach}
            onDelete={deleteCoach}
          />
        </Fragment>
      ) : (
        <PageLoader isLoading={isCoachOperating || seasonIsLoading} />
      )}
    </ProtectedLayout>
  );
}

EditCoach.propTypes = {
  seasonId: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      coachId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default EditCoach;
