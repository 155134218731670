import { handleActions } from "redux-actions";

import { loadGamesheetRoutine } from "../../routines";
import { clearGamesheet } from "../../actions";
import reduceGameGoaliesIceTime from "../../utils/reduceGameGoaliesIceTime";

const initialState = {};

export default handleActions(
  {
    [loadGamesheetRoutine.SUCCESS]: reduceGameGoaliesIceTime,
    [clearGamesheet]: () => initialState
  },
  initialState
);
