import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

class Player extends PureComponent {
  static propTypes = {
    number: PropTypes.string,
    name: PropTypes.string,
    note: PropTypes.string,
    starting: PropTypes.bool
  };

  static defaultProps = {
    number: "",
    name: "",
    note: "",
    starting: false
  };

  render() {
    const { number, name, note, starting } = this.props;

    return (
      <tr>
        <td>{number}</td>
        <td className={classNames({ starting })}>{name}</td>
        <td>{note}</td>
      </tr>
    );
  }
}

export default Player;
