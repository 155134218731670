import React from "react";
import PropTypes from "prop-types";
import { Switch } from "react-router-dom";

import useCurrentSeason from "@/hooks/useCurrentSeason";
import useCurrentSeasonClearing from "@/hooks/useCurrentSeasonClearing";

import CurrentSeasonContext from "@/contexts/CurrentSeasonContext";

import EnsureCurrentSeasonActive from "@/layouts/EnsureCurrentSeasonActive";
import LoggedInRoute from "@/containers/LoggedInRoute";

import RefereesIndex from "./pages/RefereesIndex";
import RefereesNew from "./pages/RefereesNew";
import RefereesEdit from "./pages/RefereesEdit";
import RefereesReport from "./pages/Report";
import RefereesImport from "./pages/RefereesImport";

const RefereesRoute = ({ match }) => {
  const {
    params: { seasonId },
    path
  } = match;

  const currentSeason = useCurrentSeason(seasonId);
  useCurrentSeasonClearing();

  return (
    <CurrentSeasonContext.Provider value={{ ...currentSeason, seasonId }}>
      <EnsureCurrentSeasonActive>
        <Switch>
          <LoggedInRoute path={`${path}/:refereeId/report`} component={RefereesReport} seasonId={seasonId} />

          <LoggedInRoute path={`${path}/:refereeId/edit`} component={RefereesEdit} seasonId={seasonId} />

          <LoggedInRoute path={`${path}/new`} component={RefereesNew} seasonId={seasonId} />

          <LoggedInRoute path={`${path}/csv-import`} component={RefereesImport} />

          <LoggedInRoute path="" component={RefereesIndex} seasonId={seasonId} />
        </Switch>
      </EnsureCurrentSeasonActive>
    </CurrentSeasonContext.Provider>
  );
};

RefereesRoute.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    params: PropTypes.shape({
      seasonId: PropTypes.string.isRequired
    })
  }).isRequired
};

export default RefereesRoute;
