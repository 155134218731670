const selectRoot = state => state.invitationFilter;
const selectAssociationList = state => selectRoot(state).associationList;
const selectLeagueList = state => selectRoot(state).leagueList;
const selectSeasonList = state => selectRoot(state).seasonList;
const selectDivisionList = state => selectRoot(state).divisionList;
const selectTeamList = state => selectRoot(state).teamList;

// Associations
export const getAssociationId = state => selectRoot(state).associationId;

export const getAssociationList = state => {
  const { ids, data } = selectAssociationList(state);

  return ids.map(id => ({
    ...data[id],
    id
  })).filter(x => ('title' in x));
};

export const getAssociationListIsLoaded = state => selectAssociationList(state).isLoaded;

export const getAssociationListIsLoading = state => selectAssociationList(state).isLoading;

// Leagues
export const getLeagueId = state => selectRoot(state).leagueId;

export const getLeagueList = (state, associationId) => {
  const { ids, data } = selectLeagueList(state)[associationId] || {
    ids: [],
    data: {}
  };

  return ids.map(id => ({ ...data[id], id })).filter(x => ('title' in x));
};

export const getLeagueListIsLoaded = (state, associationId) =>
  !!(selectLeagueList(state)[associationId] || {}).isLoaded;

export const getLeagueListIsLoading = (state, associationId) =>
  !!(selectLeagueList(state)[associationId] || {}).isLoading;

// Seasons
export const getSeasonId = state => selectRoot(state).seasonId;

export const getSeasonList = (state, leagueId) => {
  const { ids, data } = selectSeasonList(state)[leagueId] || {
    ids: [],
    data: {}
  };

  return ids.map(id => ({ ...data[id], id })).filter(x => ('title' in x));
};

export const getSeasonListIsLoaded = (state, leagueId) => !!(selectSeasonList(state)[leagueId] || {}).isLoaded;

export const getSeasonListIsLoading = (state, leagueId) => !!(selectSeasonList(state)[leagueId] || {}).isLoading;

// Divisions
export const getDivisionId = state => selectRoot(state).divisionId;

export const getDivisionList = (state, seasonId) => {
  const { ids, data } = selectDivisionList(state)[seasonId] || {
    ids: [],
    data: {}
  };

  return ids.map(id => ({ ...data[id], id })).filter(x => ('title' in x));
};

export const getDivisionListIsLoaded = (state, seasonId) => !!(selectDivisionList(state)[seasonId] || {}).isLoaded;

export const getDivisionListIsLoading = (state, seasonId) => !!(selectDivisionList(state)[seasonId] || {}).isLoading;

// Teams
export const getTeamId = state => selectRoot(state).teamId;

export const getTeamList = (state, divisionId) => {
  const { ids, data } = selectTeamList(state)[divisionId] || {
    ids: [],
    data: {}
  };

  return ids.map(id => ({ ...data[id], id })).filter(x => ('title' in x));
};

export const getTeamListIsLoaded = (state, divisionId) => !!(selectTeamList(state)[divisionId] || {}).isLoaded;

export const getTeamListIsLoading = (state, divisionId) => !!(selectTeamList(state)[divisionId] || {}).isLoading;

// Role
export const getRole = state => selectRoot(state).role;

// Status
export const getStatus = state => selectRoot(state).status;

// Query
export const getQuery = state => selectRoot(state).query;

// Filter
export const getFilterParams = state => {
  const associationId = getAssociationId(state);
  const leagueId = getLeagueId(state);
  const seasonId = getSeasonId(state);
  const divisionId = getDivisionId(state);
  const teamId = getTeamId(state);

  const params = {
    role: getRole(state),
    status: getStatus(state),
    query: getQuery(state)
  };

  if (teamId !== "") {
    params.teamId = teamId;
  } else if (divisionId !== "") {
    params.divisionId = divisionId;
  } else if (seasonId !== "") {
    params.seasonId = seasonId;
  } else if (leagueId !== "") {
    params.leagueId = leagueId;
  } else if (associationId !== "") {
    params.associationId = associationId;
  }

  return Object.entries(params).reduce((result, [key, value]) => {
    if (value === "") {
      return result;
    }

    return { ...result, [key]: value };
  }, {});
};

export const getIsDirty = state => Object.values(getFilterParams(state)).filter(value => value !== "").length > 0;
