import React from "react";

import { Row, Col, FormGroup, Label, Input, Button, Alert, FormFeedback } from "reactstrap";

import Layout from "@/layouts/Layout";
import Head from "@/components/Head";
import TitleBar from "@/components/TitleBar";
import Spinner from "@/components/Spinner";
import DashboardForm from "@/components/DashboardForm";
import DashboardFormFooter from "@/components/DashboardFormFooter";
import "../invitation.scss";

export const InvitationFormGUI = (props) => {
    const {
        code,
        error,
        nextScreenIsLoading,
        onChange,
        onSubmit,
    } = props;

    return (
        <Layout className="page-align-center">
            <Head title="Accept Invitation" />

            <Row className="justify-content-center">
                <Col md="10" lg="6">
                    <TitleBar title="Accept Invitation" />
                    {error ? <Alert color="danger">{error}</Alert> : null}

                    <DashboardForm>
                        <FormGroup>
                            <Label for="code">Invitation Code</Label>
                            <Input
                                type="code"
                                id="code"
                                name="code"
                                defaultValue={code.value}
                                onChange={onChange}
                                invalid={code.error !== null}
                            />
                            <FormFeedback>{code.error}</FormFeedback>
                        </FormGroup>

                        <DashboardFormFooter>
                            <div className="dashboard-form-footer__primary">
                                {nextScreenIsLoading && (
                                    <div className="next-screen-loading-spinner">
                                        <Spinner theme="loader" color="yellow" size="m" />
                                    </div>
                                )}
                                <Button type="button" color="success" onClick={onSubmit} disabled={nextScreenIsLoading}>
                                    Continue
                                </Button>
                            </div>
                        </DashboardFormFooter>
                    </DashboardForm>
                </Col>
            </Row>
        </Layout>
    );
}