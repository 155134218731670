import { handleActions } from "redux-actions";

import { loadRefereesListRoutine } from "../../routines";
import { refereesActions } from "../../actions";

const initialState = {
  isLoading: false,
  isLoaded: true,
  referees: {},
  totalCount: 0
};

function reduceReferee(referee) {
  const { id, firstName, lastName, externalId, season, emailAddress } = referee;

  return {
    firstName,
    lastName,
    externalId,
    seasonId: season.id,
    emailAddress,
    id
  };
}

function reduceLoadingSuccess(state, { payload: { referees, totalCount } }) {
  return {
    isLoading: false,
    isLoaded: true,
    totalCount,
    referees: referees.reduce(
      (result, referee) => ({
        ...result,
        [referee.id]: reduceReferee(referee)
      }),
      { ...state.referees }
    )
  };
}

function reduceLoadingTriggered(state) {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
    referees: {},
    totalCount: 0
  };
}

function reduceRemoveReferee(state, { payload: id }) {
  const keepIds = Object.keys(state.referees).filter(rid => rid !== id);
  const newReferees = keepIds.reduce(
    (collector, elem) => ({
      ...collector,
      [elem]: state.referees[elem]
    }),
    {}
  );
  return {
    ...state,
    referees: newReferees,
    totalCount: state.totalCount - 1
  };
}

export default handleActions(
  {
    [loadRefereesListRoutine.TRIGGER]: reduceLoadingTriggered,
    [loadRefereesListRoutine.SUCCESS]: reduceLoadingSuccess,
    [refereesActions.list.remove]: reduceRemoveReferee,
    [refereesActions.list.clear]: () => ({ ...initialState })
  },
  initialState
);
