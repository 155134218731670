import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import PenaltyLabel from "./PenaltyLabel";
import PlayerCode from "@/components/Gamesheet/components/TeamsLayout/components/PlayersLineup/components/PlayerCode";
import { formatPeriodName } from "../../../formatPeriodName";
import AbilityLink from "@/components/AbilityLink";
import { canCheck } from "@/components/Ability";
import { formatSoccerTime } from "@/lib/core/formatSoccerTime";


function Penalty({
  id,
  period,
  type,
  member,
  memberName,
  length,
  code,
  timeOff,
  timeStart,
  timeOn,
  servedBy,
  servedByName,
  label,
  externalId,
  penalizedId,
  sport,
  rawPeriods
}) {
  const link = (() => {
    let out = null;

    switch (type) {
      case "players":
        out = <PlayerCode scope="memberno" id={id} code={member} name={memberName} />;
        break;
      case "coaches":
        out = <PlayerCode scope="memberno" id={id} code={member} name={memberName} />;
        break;
      case "teams":
        out = <PlayerCode scope="memberno" id={id} code={member} name={memberName} />;
        break;
    }

    if (canCheck({ I: "read", a: "reports" })) {
      switch (type) {
        case "players":
          out = (
            <AbilityLink action="read" subject={{type: "players", id: penalizedId}} fallback={out} to={`/reports/player-penalty/${externalId}`} target="player-penalty-report">
              {out}
            </AbilityLink>
          );
          break;
        case "coaches":
          out = (
            <AbilityLink action="read" subject={{type: "coaches", id: penalizedId}} fallback={out} to={`/reports/bench-staff-penalty/${externalId}`} target="bench-staff-penalty-report">
              {out}
            </AbilityLink>
          );
          break;
      }
    } 

    return out;
  })();

  const timeStampOff = sport.toLowerCase() === "soccer" ? formatSoccerTime(timeOff, period, rawPeriods) : timeOff;
  const timeStampStart = sport.toLowerCase() === "soccer" ? formatSoccerTime(timeStart, period, rawPeriods) : timeStart;
  const timeStampOn = sport.toLowerCase() === "soccer" ? formatSoccerTime(timeOn, period, rawPeriods) : timeOn;

  return (
    <tr>
      <td>{formatPeriodName(period)}</td>
      <td>{link}</td>
      <td>{length}</td>
      <td className="text-uppercase">
        <PenaltyLabel id={id} scope="penalty" label={label} code={code} />
      </td>
      <td>{timeStampOff}</td>
      <td>{timeStampStart}</td>
      <td>{timeStampOn}</td>
      <td>
        <PlayerCode scope="servedBy" id={id} code={servedBy} name={servedByName} />
      </td>
    </tr>
  );
}

Penalty.propTypes = {
  id: PropTypes.string.isRequired,
  period: PropTypes.string,
  member: PropTypes.string,
  memberName: PropTypes.string,
  length: PropTypes.string,
  code: PropTypes.string,
  timeOff: PropTypes.string,
  timeStart: PropTypes.string,
  timeOn: PropTypes.string,
  servedBy: PropTypes.string,
  label: PropTypes.string,
  servedByName: PropTypes.string,
  externalId: PropTypes.string,
  playerId: PropTypes.string
};

Penalty.defaultProps = {
  period: "N/A",
  member: "N/A",
  memberName: "N/A",
  length: "N/A",
  code: "N/A",
  timeOff: "N/A",
  timeStart: "N/A",
  timeOn: "N/A",
  servedBy: "N/A",
  servedByName: "N/A",
  label: "N/A"
};

export default Penalty;
