import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

import FullName from "@/components/FullName";

import usePlayerRow from "./hooks/usePlayerRow";

function PlayerRow(props) {
  const { teamType, id, firstName, lastName, externalId, teams } = props;

  const { alreadyOnLineup, addToLineup } = usePlayerRow({
    team: teamType,
    playerId: id
  });

  return (
    <tr>
      <td>
        <FullName firstName={firstName} lastName={lastName} />
      </td>
      <td>{externalId}</td>
      <td>
        {teams.map(({ id, title }) => (
          <div key={id}>{title}</div>
        ))}
      </td>
      <td>
        <Button
          outline
          onClick={() => addToLineup({ ...props })}
          color={alreadyOnLineup ? "secondary" : "success"}
          disabled={alreadyOnLineup}
          size="sm"
        >
          {alreadyOnLineup ? "On Lineup" : "Add to Lineup"}
        </Button>
      </td>
    </tr>
  );
}

PlayerRow.propTypes = {
  teamType: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  externalId: PropTypes.string.isRequired,
  teams: PropTypes.array.isRequired
};

export default PlayerRow;
