import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import useFormWithRemoteValidationErrors from "@/hooks/useFormWithRemoteValidationErrors";

import { getRemoteValidationErrors, getMeta, submittingRoutine, deletingRoutine } from "@/redux/ipadKeyForm";

import { DEFAULT_VALUES, CONSTRAINTS } from "../constants";
import buildIpadKeyRoles from "@/lib/core/buildIpadKeyRoles";

export default function useSeasonForm(options) {
  const { initialValues = DEFAULT_VALUES, keyId, seasonId } = options;

  const dispatch = useDispatch();

  const { values, ...form } = useFormWithRemoteValidationErrors({
    initialValues: keyId
      ? initialValues
      : {
          ...initialValues,
          roles: buildIpadKeyRoles([seasonId])
        },
    constraints: CONSTRAINTS,
    errorsSelector: getRemoteValidationErrors
  });

  const { isSubmitting, isDeleting } = useSelector(getMeta);

  const submit = useCallback(() => dispatch(submittingRoutine({ values, seasonId, keyId })), [
    dispatch,
    values,
    seasonId,
    keyId
  ]);

  const deleteKey = useCallback(() => dispatch(deletingRoutine({ keyId, seasonId })), [dispatch, keyId, seasonId]);

  return {
    ...form,
    values,
    isSubmitting,
    isDeleting,
    submit,
    deleteKey
  };
}
