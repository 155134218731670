import { createActions } from "redux-actions";

const { teamRosterExtender: actions } = createActions({
  TEAM_ROSTER_EXTENDER: {
    CLEAR: undefined,
    SEARCH: {
      SET_NEXT_QUERY: undefined,
      COMMIT: undefined,
      CLEAR: undefined
    }
  }
});

export default actions;
