import { useState, useCallback } from "react";

export function useNewPenaltyCodeForm(options) {
  const { value: penaltyCodes, onChange: changePenaltyCodes } = options;

  const [isActive, setIsActive] = useState(false);
  const [code, setCode] = useState("");
  const [label, setLabel] = useState("");

  const display = useCallback(() => setIsActive(true), [setIsActive]);
  const hide = useCallback(() => setIsActive(false), [setIsActive]);

  const onCodeChange = useCallback(({ target: { value } }) => setCode(value), [setCode]);

  const onLabelChange = useCallback(({ target: { value } }) => setLabel(value), [setLabel]);

  const reset = useCallback(
    () => {
      setCode("");
      setLabel("");
    },
    [setCode, setLabel]
  );

  const onSubmit = useCallback(
    () => {
      changePenaltyCodes([...penaltyCodes, { code, label }]);
      reset();
      hide();
    },
    [changePenaltyCodes, penaltyCodes, code, label, reset, hide]
  );

  const onCancel = useCallback(
    () => {
      reset();
      hide();
    },
    [reset, hide]
  );

  return {
    code: {
      value: code,
      onChange: onCodeChange
    },
    label: {
      value: label,
      onChange: onLabelChange
    },
    isActive,
    display,
    hide,
    onSubmit,
    onCancel
  };
}
