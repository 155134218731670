import React from "react";
import PropTypes from "prop-types";

import { Badge } from "reactstrap";

import UnauthorizedResource from "./UnauthorizedResource";

function Failure({ code, resourceType, hint }) {
  switch (code) {
    case "notFound":
      return <Badge color="warning">{resourceType} {hint || '(unknown)'} not found</Badge>;
    case "archived":
      return <Badge color="info">{resourceType} {hint || '(unknown)'} is archived</Badge>;
    case "unauthorized":
      return <UnauthorizedResource resourceType={resourceType} />;
    default:
      return <Badge color="danger">Unable to load {resourceType} {hint || '(unknown)'}</Badge>;
  }
}

Failure.propTypes = {
  code: PropTypes.string.isRequired,
  resourceType: PropTypes.string.isRequired
};

export default Failure;
