import { takeLatest, all, put, call, select, takeEvery, actionChannel, race, take } from "redux-saga/effects";
import build from "redux-object";
import normalize from "json-api-normalizer";

import renderGameSheetToPDF from "@/utils/renderGameSheetToPDF";
import saveGameSheetPDF from "@/utils/saveGameSheetPDF";

import { gamesheetAPIRequest } from "@/redux/api/sagas";

import { loadGame, loadGameGamesheets, loadGameGamesheet } from "@/lib/api/games";

import { toggleGameViewedRoutine } from "@/redux/games/routines";

import { ensureTokenIsFreshSaga } from "@/redux/token/sagas";

import axios from "axios";

import {
  loadGamesheetRoutine,
  exportGamesheetToPDF,
  loadGamesheetHistoryRoutine,
  loadGamesheetHistoryRecordRoutine,
  exportGamesheetHistoryRecordToPDFRoutine
} from "./routines";

import {
  getGamesheetForPDF,
  getGamesheetHistoryRecordForPDF,
  getGamesheetHistoryRecordIsLoaded,
  getGamesheetHistoryRecordDateTime
} from "./selectors";

import fixRefereesToRefereesRoster from "./utils/fixRefereesToRefereesRoster";
import { config } from "@/config";

function* loadGamesheetSaga({ payload }) {
  yield put(loadGamesheetRoutine.request());

  try {
    const { data } = yield call(gamesheetAPIRequest, loadGame, {
      ...payload,
      include: "players,coaches,referees,teams,season,association,league"
    });

    const game = build(fixRefereesToRefereesRoster(data), "games", payload.id, {
      eager: true
    });

    yield put(loadGamesheetRoutine.success({ game }));

    if (!game.meta.viewed) {
      yield put(toggleGameViewedRoutine({ id: game.id, isViewed: true, delayed: true }));
    }
  } catch (error) {
    const responseStatus = error.response && error.response.status;

    if (error.response) {
      yield put(loadGamesheetRoutine.failure({ responseStatus }));
    } else {
      throw error;
    }
  } finally {
    yield put(loadGamesheetRoutine.fulfill());
  }
}

function* exportGamesheetToPDFSaga({payload}) {
  yield put(exportGamesheetToPDF.request());

  try {
    const gamesheet = yield select(getGamesheetForPDF);

    const accessToken = yield call(ensureTokenIsFreshSaga);
    const response = yield call(
      () => axios.get(
        `${config.SCORESHEET_EXPORT_SERVICE_URL}/service.scoresheets/v4/get-game/${payload.gameId}`,
        {
          responseType: 'blob',
          headers: {
            "Authorization": `Bearer ${accessToken}`,
          },
        }
      )
    );

    saveGameSheetPDF({ blob: response.data, gamesheet });

    yield put(exportGamesheetToPDF.success());
  } catch (error) {
    yield put(exportGamesheetToPDF.failure({ error }));
  } finally {
    yield put(exportGamesheetToPDF.fulfill());
  }
}

function* loadGamesheetHistorySaga({ payload }) {
  yield put(loadGamesheetHistoryRoutine.request());

  try {
    const { data } = yield call(gamesheetAPIRequest, loadGameGamesheets, payload);

    const gamesheets = build(data, "gamesheets");

    yield put(loadGamesheetHistoryRoutine.success({ gamesheets }));
  } catch (error) {
    yield put(loadGamesheetHistoryRoutine.failure({ error }));
  } finally {
    yield put(loadGamesheetHistoryRoutine.fulfill());
  }
}

function* loadGamesheetHistoryRecordSaga({ payload }) {
  yield put(loadGamesheetHistoryRecordRoutine.request(payload));

  try {
    const { data } = yield call(gamesheetAPIRequest, loadGameGamesheet, payload);

    const gamesheet = build(data, "gamesheets", payload.id);
    const gameData = normalize(JSON.parse(gamesheet.gameJson));

    const game = build(fixRefereesToRefereesRoster(gameData), "games", payload.gameId, {
      eager: true
    });

    yield put(loadGamesheetHistoryRecordRoutine.success({ game, id: payload.id }));
  } catch (error) {
    yield put(loadGamesheetHistoryRecordRoutine.failure(payload));
  } finally {
    yield put(loadGamesheetHistoryRecordRoutine.fulfill(payload));
  }
}

function* exportGamesheetHistoryRecordToPDFSaga({ payload }) {
  
  yield put(exportGamesheetHistoryRecordToPDFRoutine.request(payload));

  try{

    const gamesheet = yield select(getGamesheetForPDF);

    const accessToken = yield call(ensureTokenIsFreshSaga);
    const response = yield call(
      () => axios.get(
        `${config.SCORESHEET_EXPORT_SERVICE_URL}/service.scoresheets/v4/get-old-game/${payload.id}`,
        {
          responseType: 'blob',
          headers: {
            "Authorization": `Bearer ${accessToken}`,
          },
        }
      )
    );

    if(payload.download){
    saveGameSheetPDF({ blob: response.data, gamesheet });
    } else {
      const url = URL.createObjectURL(response.data);
      window.open(url);
    }
    
    yield put(exportGamesheetHistoryRecordToPDFRoutine.success(payload));
  } catch (error) {
    yield put(exportGamesheetHistoryRecordToPDFRoutine.failure(payload));

    throw error;
  } finally {
    yield put(exportGamesheetHistoryRecordToPDFRoutine.fulfill(payload));
  }
}

export function* gamesheetFlow() {
  yield all([
    takeLatest(loadGamesheetRoutine.TRIGGER, loadGamesheetSaga),
    takeLatest(exportGamesheetToPDF.TRIGGER, exportGamesheetToPDFSaga),
    takeLatest(loadGamesheetHistoryRoutine.TRIGGER, loadGamesheetHistorySaga),
    takeEvery(loadGamesheetHistoryRecordRoutine.TRIGGER, loadGamesheetHistoryRecordSaga),
    takeLatest(exportGamesheetHistoryRecordToPDFRoutine.TRIGGER, exportGamesheetHistoryRecordToPDFSaga)
  ]);
}
