import { handleActions } from "redux-actions";

import {
  rosterParsingRoutine,
  rosterImportingRoutine,
  playerImportingRoutine,
  coachImportingRoutine,
  teamLoadingRoutine,
  playersListImportingRoutine,
  coachesListImportingRoutine,
  teamUpdatingRoutine,
  teamsListUpdatingRoutine
} from "./routines";

import actions from "./actions";

const initialState = {
  records: {},
  status: "pending",
  playersImportingStatus: "pending",
  coachesImportingStatus: "pending",
  teamsUpdatingStatus: "pending",
  error: null,
  teams: {}
};

function reduceRecord(records, record, index) {
  return {
    ...records,
    [index + 2]: {
      ...record,
      importStatus: "pending",
      id: null
    }
  };
}

function updateRecord(state, lineNumber, changes) {
  return {
    ...state,
    records: {
      ...state.records,
      [lineNumber]: {
        ...state.records[lineNumber],
        ...changes
      }
    }
  };
}

function updateTeam(state, teamId, changes) {
  return {
    ...state,
    teams: {
      ...state.teams,
      [teamId]: {
        ...state.teams[teamId],
        ...changes
      }
    }
  };
}

function reduceRosterParsingRequest(state) {
  return { ...state, status: "parsing" };
}

function reduceRosterParsingSuccess(state, { payload: { records } }) {
  return {
    ...state,
    status: "parsed",
    records: records.reduce(reduceRecord, {})
  };
}

function reduceRosterParsingFailure(state, { payload: { error } }) {
  return { ...state, status: "parsing_failed", error };
}

function reduceRosterImportingRequest(state) {
  return { ...state, status: "importing" };
}

function reduceTeamLoadingSuccess(state, { payload }) {
  const { teamId, team } = payload;

  const {
    roster,
    externalId,
    title,
    division: { id: divisionId },
    data
  } = team;

  const { coaches = [], players = [] } = roster;

  return {
    ...state,
    teams: {
      ...state.teams,
      [teamId]: {
        externalId,
        title,
        divisionId,
        roster: { coaches, players },
        updatingStatus: "pending",
        data
      }
    }
  };
}

function reduceRosterImportingSuccess(state) {
  return { ...state, status: "completed" };
}

function reduceRosterImportingFailure(state, { payload: { error } }) {
  return { ...state, status: "importing_failed", error };
}

function reduceRowImportingRequest(state, { payload: { lineNumber } }) {
  return updateRecord(state, lineNumber, { importStatus: "requested" });
}

function reduceRowImportingSuccess(state, { payload: { lineNumber, id } }) {
  return updateRecord(state, lineNumber, { importStatus: "success", id });
}

function reduceRowImportingFailure(state, { payload: { lineNumber } }) {
  return updateRecord(state, lineNumber, { importStatus: "failed" });
}

function reduceTeamUpdatingRequest(state, { payload: { teamId } }) {
  return updateTeam(state, teamId, { updatingStatus: "requested" });
}

function reduceTeamUpdatingSuccess(state, { payload: { teamId } }) {
  return updateTeam(state, teamId, { updatingStatus: "success" });
}

function reduceTeamUpdatingFailure(state, { payload: { teamId } }) {
  return updateTeam(state, teamId, { updatingStatus: "failed" });
}

function reducePlayersListImportingRequest(state) {
  return { ...state, playersImportingStatus: "requested" };
}

function reducePlayersListImportingFulfill(state) {
  return { ...state, playersImportingStatus: "completed" };
}

function reduceCoachesListImportingRequest(state) {
  return { ...state, coachesImportingStatus: "requested" };
}

function reduceCoachesListImportingFulfill(state) {
  return { ...state, coachesImportingStatus: "completed" };
}

function reduceTeamsListUpdatingRequest(state) {
  return { ...state, teamsUpdatingStatus: "requested" };
}

function reduceTeamsListUpdatingFulfill(state) {
  return { ...state, teamsUpdatingStatus: "completed" };
}

export default handleActions(
  {
    [rosterParsingRoutine.REQUEST]: reduceRosterParsingRequest,
    [rosterParsingRoutine.SUCCESS]: reduceRosterParsingSuccess,
    [rosterParsingRoutine.FAILURE]: reduceRosterParsingFailure,
    [teamLoadingRoutine.SUCCESS]: reduceTeamLoadingSuccess,
    [rosterImportingRoutine.REQUEST]: reduceRosterImportingRequest,
    [rosterImportingRoutine.SUCCESS]: reduceRosterImportingSuccess,
    [rosterImportingRoutine.FAILURE]: reduceRosterImportingFailure,
    [playerImportingRoutine.REQUEST]: reduceRowImportingRequest,
    [playerImportingRoutine.SUCCESS]: reduceRowImportingSuccess,
    [playerImportingRoutine.FAILURE]: reduceRowImportingFailure,
    [coachImportingRoutine.REQUEST]: reduceRowImportingRequest,
    [coachImportingRoutine.SUCCESS]: reduceRowImportingSuccess,
    [coachImportingRoutine.FAILURE]: reduceRowImportingFailure,
    [teamUpdatingRoutine.REQUEST]: reduceTeamUpdatingRequest,
    [teamUpdatingRoutine.SUCCESS]: reduceTeamUpdatingSuccess,
    [teamUpdatingRoutine.FAILURE]: reduceTeamUpdatingFailure,
    [playersListImportingRoutine.REQUEST]: reducePlayersListImportingRequest,
    [playersListImportingRoutine.FULFILL]: reducePlayersListImportingFulfill,
    [coachesListImportingRoutine.REQUEST]: reduceCoachesListImportingRequest,
    [coachesListImportingRoutine.FULFILL]: reduceCoachesListImportingFulfill,
    [teamsListUpdatingRoutine.REQUEST]: reduceTeamsListUpdatingRequest,
    [teamsListUpdatingRoutine.FULFILL]: reduceTeamsListUpdatingFulfill,
    [actions.reset]: () => ({ ...initialState })
  },
  initialState
);
