import { handleActions } from "redux-actions";

const initialState = {};

function reduceMember(member) {
  const { id, firstName, lastName, leagues } = member;

  return {
    firstName,
    lastName,
    externalId: id,
    leagueIds: leagues.map(({ id }) => id)
  };
}

function createLoadingSuccessReducer(memberType) {
  return (state, { payload }) =>
    payload[memberType].reduce(
      (result, member) => ({
        ...result,
        [member.id]: reduceMember(member)
      }),
      { ...state }
    );
}

export default function createDataReducer({ loadingRoutine, actions, memberType }) {
  return handleActions(
    {
      [loadingRoutine.SUCCESS]: createLoadingSuccessReducer(memberType),
      [actions.list.clear]: () => ({ ...initialState })
    },
    initialState
  );
}
