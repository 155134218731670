import React, { PureComponent, Fragment } from "react";
import { RecoilRoot } from "recoil";
import PropTypes from "prop-types";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import Head from "@/components/Head";
import PageLoader from "@/components/PageLoader";
import Breadcrumbs, { BreadcrumbsProps } from "@/components/Breadcrumbs";
import CSVRosterImportWizard from "@/components-16.8/RosterImportWizard";

class SeasonRosterCSVImport extends PureComponent {
  static propTypes = {
    seasonIsLoading: PropTypes.bool,
    seasonIsLoaded: PropTypes.bool,
    seasonIsForbidden: PropTypes.bool.isRequired,
    breadcrumbs: BreadcrumbsProps,
    seasonId: PropTypes.string,
    loadSeason: PropTypes.func,
    reset: PropTypes.func
  };

  static defaultProps = {
    seasonIsLoading: false,
    seasonIsLoaded: false,
    seasonIsForbidden: false,
    breadcrumbs: null,
    seasonId: "",
    reset() {}
  };

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const { seasonIsLoading, seasonIsLoaded, seasonIsForbidden, breadcrumbs, seasonId } = this.props;

    return (
      <ProtectedLayout
        subject={{ type: "roster", seasonId }}
        action="import"
        skip={!seasonIsLoaded && !seasonIsForbidden}
        className="roster-page"
      >
        {seasonIsLoaded ? (
          <Fragment>
            <Head title="Import from CSV" />
            <Breadcrumbs items={breadcrumbs} lastItem={{ title: "Roster", url: `/seasons/${seasonId}/roster` }} />
            <CSVRosterImportWizard seasonId={seasonId} />
          </Fragment>
        ) : (
          <PageLoader isLoading={seasonIsLoading} />
        )}
      </ProtectedLayout>
    );
  }
}

export default SeasonRosterCSVImport;
