import { handleActions } from "redux-actions";

import { seasonRosterActions } from "../../actions";

import {
  loadSeasonPlayersRosterMergeToolPlayerStats,
  loadSeasonPlayersRosterMergeToolGoalieStats,
  mergeSeasonPlayersRoutine
} from "../../routines";

const { mergeTool: mergeToolActions } = seasonRosterActions.players;

const initialState = {
  seasonId: null,
  status: null,
  ids: [],
  winnerId: null,
  playerStats: {},
  goalieStats: {}
};

const initialPlayerStats = {
  isLoading: false,
  isLoaded: false,
  error: false,
  gamesPlayed: 0
};

const initialGoalieStats = {
  isLoading: false,
  isLoaded: false,
  error: false,
  gamesPlayed: 0
};

function updateStatus(state, status, rest) {
  return { ...state, status, ...rest };
}

function updatePlayerStats(state, playerId, nextStats) {
  return {
    ...state,
    playerStats: {
      ...state.playerStats,
      [playerId]: {
        ...state.playerStats[playerId],
        ...nextStats
      }
    }
  };
}

function updateGoalieStats(state, goalieId, nextStats) {
  return {
    ...state,
    goalieStats: {
      ...state.goalieStats,
      [goalieId]: {
        ...state.goalieStats[goalieId],
        ...nextStats
      }
    }
  };
}

function reduceEnabled(state, { payload: { seasonId } }) {
  return updateStatus(state, "selecting-players", { seasonId });
}

function reduceDisabled(state) {
  return updateStatus(state, null, { ids: [] });
}

function reducePlayerSelected(state, { payload: id }) {
  const playerStats = { ...initialPlayerStats, ...state.playerStats[id] };
  const goalieStats = { ...initialGoalieStats, ...state.goalieStats[id] };

  return {
    ...state,
    ids: Array.from(new Set(state.ids).add(id)),
    playerStats: { ...state.playerStats, [id]: playerStats },
    goalieStats: { ...state.goalieStats, [id]: goalieStats },
    winnerId: null
  };
}

function reducePlayerDeselected(state, { payload: id }) {
  return { ...state, winnerId: null, ids: state.ids.filter(pid => pid !== id) };
}

function reduceWinnerChosen(state, { payload: winnerId }) {
  return { ...state, winnerId };
}

function reduceSettingStatus(state, { payload: status }) {
  return updateStatus(state, status);
}

function reducePlayerStatsLoadingRequest(state, { payload: { playerId } }) {
  return updatePlayerStats(state, playerId, { isLoading: true });
}

function reducePlayerStatsLoadingSuccess(state, { payload }) {
  const { playerId, gamesPlayed } = payload;

  return updatePlayerStats(state, playerId, { gamesPlayed, isLoaded: true });
}

function reducePlayerStatsLoadingFailure(state, { payload: { playerId } }) {
  return updatePlayerStats(state, playerId, {
    isLoaded: false,
    isFailed: true
  });
}

function reducePlayerStatsLoadingFulfill(state, { payload: { playerId } }) {
  return updatePlayerStats(state, playerId, { isLoading: false });
}

function reduceGoalieStatsLoadingRequest(state, { payload: { goalieId } }) {
  return updateGoalieStats(state, goalieId, { isLoading: true });
}

function reduceGoalieStatsLoadingSuccess(state, { payload }) {
  const { goalieId, gamesPlayed } = payload;

  return updateGoalieStats(state, goalieId, { gamesPlayed, isLoaded: true });
}

function reduceGoalieStatsLoadingFailure(state, { payload: { goalieId } }) {
  return updateGoalieStats(state, goalieId, {
    isLoaded: false,
    isFailed: true
  });
}

function reduceGoalieStatsLoadingFulfill(state, { payload: { goalieId } }) {
  return updateGoalieStats(state, goalieId, { isLoading: false });
}

function reduceMergingRequest(state) {
  return updateStatus(state, "merging");
}

function reduceMergingFailure(state) {
  return updateStatus(state, "failure", {
    goalieStats: {},
    playerStats: {}
  });
}

function reduceMergingSuccess(state) {
  return updateStatus(state, null, {
    ids: [],
    goalieStats: {},
    playerStats: {}
  });
}

export default handleActions(
  {
    [seasonRosterActions.clear]: () => ({ ...initialState }),
    [mergeToolActions.enable]: reduceEnabled,
    [mergeToolActions.disable]: reduceDisabled,
    [mergeToolActions.selectPlayer]: reducePlayerSelected,
    [mergeToolActions.deselectPlayer]: reducePlayerDeselected,
    [mergeToolActions.chooseWinner]: reduceWinnerChosen,
    [mergeToolActions.setStatus]: reduceSettingStatus,
    [loadSeasonPlayersRosterMergeToolPlayerStats.REQUEST]: reducePlayerStatsLoadingRequest,
    [loadSeasonPlayersRosterMergeToolPlayerStats.SUCCESS]: reducePlayerStatsLoadingSuccess,
    [loadSeasonPlayersRosterMergeToolPlayerStats.FAILURE]: reducePlayerStatsLoadingFailure,
    [loadSeasonPlayersRosterMergeToolPlayerStats.FULFILL]: reducePlayerStatsLoadingFulfill,
    [loadSeasonPlayersRosterMergeToolGoalieStats.REQUEST]: reduceGoalieStatsLoadingRequest,
    [loadSeasonPlayersRosterMergeToolGoalieStats.SUCCESS]: reduceGoalieStatsLoadingSuccess,
    [loadSeasonPlayersRosterMergeToolGoalieStats.FAILURE]: reduceGoalieStatsLoadingFailure,
    [loadSeasonPlayersRosterMergeToolGoalieStats.FULFILL]: reduceGoalieStatsLoadingFulfill,
    [mergeSeasonPlayersRoutine.REQUEST]: reduceMergingRequest,
    [mergeSeasonPlayersRoutine.FAILURE]: reduceMergingFailure,
    [mergeSeasonPlayersRoutine.SUCCESS]: reduceMergingSuccess
  },
  initialState
);
