import { connect } from "react-redux";

import {
  getSourcesListIsLoaded,
  getSourcesListIsLoading,
  getSourcesList,
  getSourcesListIsError
} from "@/redux/reportDataSourcesList/selectors";

import List from "./List";

const mapStateToProps = state => ({
  isLoaded: getSourcesListIsLoaded(state),
  isLoading: getSourcesListIsLoading(state),
  isError: getSourcesListIsError(state),
  sources: getSourcesList(state)
});

export default connect(mapStateToProps)(List);
