import { useCallback } from "react";

export default function useCustomPointSystem({ value, onChange }) {
  const { gameValues, periodValues } = value;

  const changeGameValues = useCallback(gameValues => onChange({ ...value, gameValues }), [value, onChange]);

  const changePeriodValues = useCallback(periodValues => onChange({ ...value, periodValues }), [value, onChange]);

  return {
    isCustom: value.title === "custom",
    gameValues: {
      value: gameValues,
      onChange: changeGameValues
    },
    periodValues: {
      value: periodValues,
      onChange: changePeriodValues
    }
  };
}
