const re = new RegExp(/\w+.\w+$/);

export default function usePreview({ value }) {
  if (value instanceof File) {
    return { name: value.name, url: null };
  }

  try {
    const { pathname } = new URL(value);
    return { name: pathname.match(re)[0], url: value };
  } catch {
    return { name: null, url: null };
  }
}
