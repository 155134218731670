import validate from "validate.js";
import _isEqual from "lodash/isEqual";

import { CHANGE_FIELD, SET_ERRORS, VISIT_FIELD, RESET_FIELD } from "./actions";

function fillFieldValue({ initialValue, value, errors, isTouched, isVisited }) {
  return {
    value,
    errors,
    initialValue,
    isTouched,
    isVisited,
    isDirty: !_isEqual(initialValue, value),
    isInvalid: errors.length > 0
  };
}

function reduceFieldChange(state, { name, value, errors }) {
  return {
    ...state,
    fields: {
      ...state.fields,
      [name]: fillFieldValue({
        ...state.fields[name],
        isTouched: true,
        value,
        errors
      })
    }
  };
}

function reduceSettingErrors(state, errors) {
  return {
    ...state,
    fields: Object.entries(errors).reduce(
      (fields, [field, errors]) => ({
        ...fields,
        [field]: fillFieldValue({
          ...fields[field],
          isTouched: errors.length > 0,
          errors
        })
      }),
      state.fields
    )
  };
}

function reduceFieldVisit(state, { name }) {
  return {
    ...state,
    fields: {
      ...state.fields,
      [name]: {
        ...state.fields[name],
        isVisited: true
      }
    }
  };
}

function reduceFieldVisitReset(state, { name }) {
  return {
    ...state,
    fields: {
      ...state.fields,
      [name]: {
        ...state.fields[name],
        isVisited: false,
        isTouched: false,
        isDirty: false,
        isValid: false,
      }
    }
  };
}

export const initialState = {
  fields: {}
};

export function init({ initialValues, constraints }) {
  const errors = validate(initialValues, constraints) || {};

  return {
    fields: Object.entries(initialValues).reduce(
      (result, [name, value]) => ({
        ...result,
        [name]: fillFieldValue({
          value,
          initialValue: value,
          errors: errors[name] || [],
          isTouched: false,
          isVisited: false
        })
      }),
      {}
    )
  };
}

export default function reducer(state, { type, payload }) {
  switch (type) {
    case CHANGE_FIELD:
      return reduceFieldChange(state, payload);
    case SET_ERRORS:
      return reduceSettingErrors(state, payload);
    case VISIT_FIELD:
      return reduceFieldVisit(state, payload);
    case RESET_FIELD:
      return reduceFieldVisitReset(state, payload)
    default:
      throw new Error("Unknown action type", type);
  }
}
