import React from "react";
import PropTypes from "prop-types";
import GameFlag from "./components/GameFlag";
import { groupFlags } from "@/components/GameFlagsList/helpers";

import "./Flags.scss";

function Flags({ id, flags, seasonId }) {
  if (!flags || flags.length <= 0) {
    return <span>&nbsp;</span>;
  }
  
  const groupedFlags = groupFlags({ flags });
  return (<div className="games-page__flags">
    {groupedFlags.map(({ color, flags }) => {
      const safeTextColor = color.replace("#", "_");
      return (<GameFlag key={safeTextColor} id={id + safeTextColor} flags={flags} color={color} seasonId={seasonId} />);
    })}
  </div>);
}

Flags.propTypes = {
  id: PropTypes.string.isRequired,
  flags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  seasonId: PropTypes.string.isRequired
};

export default Flags;
