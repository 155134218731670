import React from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import { Button } from 'reactstrap';

import useSearchResults from './useSearchResults';

import './style.scss';

const SearchResults = ({ hits, hasPrevious, refinePrevious, hasMore, refineNext }) => {
  const { venues, setLocation } = useSearchResults({ hits });

  return (
    <div className="position-relative">
      {venues.length > 0 && (
      <div className="location-query-results-box">
        {hasPrevious && (
          <button disabled={!hasPrevious} onClick={refinePrevious}>
            Show previous
          </button>)}
        {venues.map(venue => {
          const surfaces = (
            <ul>
              {venue.surfaces.map(surface => (
                <li
                  className="surface"
                  onClick={() => setLocation(surface.compositeKey)}
                  key={surface.id}
                >
                  {surface.name}
                </li>
              ))}
            </ul>
          );

          return (
            <div
              className="venue"
              key={`${venue.name}-${venue.postalCode}`}
            >
              <span style={{ fontWeight: 500 }}>
                {venue.name}, {venue.city}, {venue.province}, {venue.postalCode}
              </span>
              {surfaces}
            </div>
          );
        })}
        {hasMore && (
          <Button
            color="success"
            disabled={!hasMore}
            onClick={refineNext}
            size="sm"
            className="mb-2"
            outline
          >
            Show more
          </Button>)}
      </div>)}
    </div>
  );
}

export default connectInfiniteHits(SearchResults);