import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";
import { routerMiddleware } from "connected-react-router";
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import reduxCatch from "redux-catch";
import * as Sentry from "@sentry/react";

import handleRuntimeError from "@/utils/handleRuntimeError";
import { init } from "@/redux/app/actions";

import createRootReducer from "./createRootReducer";
import rootSaga from "./rootSaga";
import { config } from "@/config";

export default ({ initialState = {}, history }) => {
  const rootReducer = createRootReducer(history);
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  if (config.NODE_ENV === "development" && process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === "true") {
    middlewares.push(
      // reduxImmutableStateInvariant(),
      createLogger({
        collapsed: true
      })
    );
  }

  const sentryEnhancer = Sentry.createReduxEnhancer({
    stateTransformer: ({ account, token, ...rest }) => ({
      ...rest,
      account: {
        ...account,
        account: {
          ...account.account,
          fields: {
            ...account.account.fields,
            email: {
              ...account.account.fields.email,
              value: "<HIDDEN>"
            },
            firstName: {
              ...account.account.fields.firstName,
              value: "<HIDDEN>"
            },
            lastName: {
              ...account.account.fields.lastName,
              value: "<HIDDEN>"
            },
            password: {
              ...account.account.fields.password,
              value: "<HIDDEN>"
            },
            passwordConfirmation: {
              ...account.account.fields.passwordConfirmation,
              value: "<HIDDEN>"
            }
          },
          user: {
            ...account.account.user,
            email: "<HIDDEN>",
            firstName: "<HIDDEN>",
            lastName: "<HIDDEN>"
          }
        }
      },
      token: {
        ...token,
        value: "<HIDDEN>"
      }
    })
  });

  middlewares.push(reduxCatch(handleRuntimeError));

  const enhancers = [applyMiddleware(...middlewares)];

  const store = createStore(rootReducer, initialState, composeEnhancers(...enhancers, sentryEnhancer));

  sagaMiddleware.run(rootSaga);

  store.dispatch(init());

  return store;
};

const composeEnhancers = (...args) =>
  typeof window !== "undefined" ? composeWithDevTools({})(...args) : compose(...args);
