import React from "react";
import PropTypes from "prop-types";

import "./FormSection.scss";

function FormSection({ title, children }) {
  return (
    <section className="form-section">
      <h2 className="form-section__title">{title}</h2>
      {children}
    </section>
  );
}

FormSection.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node
};

export default FormSection;
