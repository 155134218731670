import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import _toPath from "lodash/toPath";

import useForm from "./useForm";

export default function useFormWithRemoteValidationErrors({ initialValues, constraints, errorsSelector }) {
  const remoteValidationErrors = useSelector(errorsSelector);
  const errors = useMemo(
    () =>
      Object.entries(remoteValidationErrors).reduce((result, [key, value]) => {
        const [name, ...rest] = _toPath(key.replace("/", "."));

        return {
          ...result,
          [name]:
            rest.length > 0 ? [...(result[name] || []), [rest.join("."), value]] : [...(result[name] || []), ...value]
        };
      }, {}),
    [remoteValidationErrors]
  );

  const { setErrors, reset, ...form } = useForm(initialValues, constraints);

  useEffect(
    () => {
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
      }
    },
    [setErrors, errors]
  );

  return {
    ...form,
    setErrors,
    reset
  };
}
