import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import AbilityLink from "@/components/AbilityLink";

class Team extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    seasonId: PropTypes.string.isRequired,
    noLink: PropTypes.bool.isRequired
  };

  render() {
    const { id, title, seasonId, noLink } = this.props;
    const url = `/seasons/${seasonId}/teams/${id}/roster`;

    if (noLink) {
      return title;
    }

    return (
      <AbilityLink subject={{ type: "teams", id }} action="read" to={url} fallback={title}>
        {title}
      </AbilityLink>
    );
  }
}

export default Team;
