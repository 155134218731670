import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import { loadingRoutine } from "./routines";
import actions from "./actions";

const initialMeta = {
  isLoading: false,
  isLoaded: false,
  isForbidden: false,
  isNotFound: false
};

const meta = handleActions(
  {
    [loadingRoutine.REQUEST]: state => ({ ...state, isLoading: true }),
    [loadingRoutine.SUCCESS]: state => ({ ...state, isLoaded: true }),
    [loadingRoutine.FAILURE]: (state, { payload: { responseStatus } }) => ({
      ...state,
      isForbidden: responseStatus === 401,
      isNotFound: responseStatus === 404
    }),
    [loadingRoutine.FULFILL]: state => ({ ...state, isLoading: false }),
    [actions.clear]: () => ({ ...initialMeta })
  },
  initialMeta
);

const game = handleActions(
  {
    [loadingRoutine.SUCCESS]: (state, { payload: { game } }) => ({ ...game }),
    [actions.clear]: () => null
  },
  null
);

export default combineReducers({ meta, game });
