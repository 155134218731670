import React from "react";
import PropTypes from "prop-types";
import { Table } from "reactstrap";

function CareerStats(props) {
  const {
    avgPmGp,
    mostFreqInfractionCode,
    mostFreqInfractionCount,
    majorPenaltiesCount,
    gamesSuspensionsCount
  } = props;

  return (
    <div className="team-member-penalty-report__section">
      <h3 className="team-member-penalty-report__section-title">Career Penalty Statistics</h3>

      <Table striped borderless>
        <thead>
          <tr>
            <th>Average PIM/GP</th>
            <th>Most Frequent Infraction</th>
            <th>Major / Misconduct Penalties</th>
            <th>Games Sat for Suspensions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{avgPmGp || "0"}</td>
            <td>{mostFreqInfractionCount > 0 ? `${mostFreqInfractionCode} [${mostFreqInfractionCount}]` : "N/A"}</td>
            <td>{majorPenaltiesCount}</td>
            <td>{gamesSuspensionsCount !== null ? gamesSuspensionsCount : "N/A"}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

CareerStats.propTypes = {
  avgPmGp: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  mostFreqInfractionCode: PropTypes.string,
  mostFreqInfractionCount: PropTypes.number,
  majorPenaltiesCount: PropTypes.number,
  gamesSuspensionsCount: PropTypes.number
};

CareerStats.defaultProps = {
  avgPmGp: 0,
  mostFreqInfractionCode: null,
  mostFreqInfractionCount: 0,
  majorPenaltiesCount: 0,
  gamesSuspensionsCount: 0
};

export default CareerStats;
