import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

class FullName extends PureComponent {
  static propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    url: PropTypes.string
  };

  static defaultProps = {
    url: null
  };

  render() {
    const { firstName, lastName, url } = this.props;
    const Tag = url ? Link : "span";

    return (
      <Tag to={url} className="full-name">
        {firstName} <span className="last-name">{lastName}</span>
      </Tag>
    );
  }
}

export default FullName;
