import React, { useState } from "react";

import { Link } from "react-router-dom";
import { Button } from "reactstrap";

import EmptyListMessage from "@/components/EmptyListMessage";
import TotalCount from "@/components/TotalCount";
import AbilityButton from "@/components/AbilityButton";
import useCurrentLeagueContext from "@/hooks/useCurrentLeagueContext";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import seasonTitleRenderer from "./seasonTitleRenderer";
import { useSeasonsList } from "./hooks";
import ActionsDropdown from "./ActionsDropdown";

import "./_seasonsList.scss"

function SeasonsList() {
  const { seasons, totalCount, archivedTotalCount } = useSeasonsList();
  const { leagueId } = useCurrentLeagueContext();

  const [gridApi, setGridApi] = useState(null);
  
  const onGridReady = params => {
    setGridApi(params.api);
  };

  const isMobile = window.innerWidth < 780 ? true : false;


  if (seasons.length < 1) {
    return (
      <EmptyListMessage>
        <p>There are no active seasons.</p>

        <Button color="primary" outline tag={Link} to={`/leagues/${leagueId}/seasons/archived`}>
          View Archived Seasons ({archivedTotalCount})
        </Button>

        <AbilityButton
          subject={{ type: "seasons", leagueId }}
          action="create"
          outline
          color="success"
          tag={Link}
          to={`/leagues/${leagueId}/seasons/new`}
        >
          + New Season
        </AbilityButton>
      </EmptyListMessage>
    );
  }

  return (
    <div className="seasons-index">
      <TotalCount plural="seasons" singular="season" totalCount={totalCount}>
        {archivedTotalCount > 0 && (
          <Link to={`/leagues/${leagueId}/seasons/archived`}>View Archived Seasons ({archivedTotalCount})</Link>
        )}
      </TotalCount>

      <div className="ag-theme-alpine ag-gs-theme">
      <AgGridReact
          defaultColDef={{
            resizable: true,
            wrapText: true,
            autoHeight: true,
          }}
          frameworkComponents={{
            seasonTitleRenderer: seasonTitleRenderer
          }}
          pagination={false}
          rowData={seasons}
          onGridReady={onGridReady}
          domLayout={"autoHeight"}
          suppressCellSelection={true}
          rowHeight={44}
          enableCellTextSelection={true}
          getRowNodeId={data => data.id}
        >
          <AgGridColumn
            headerName="Title"
            field="title"
            lockPosition={isMobile}
            minWidth="200"
            flex="2"
            sortable={true}
            cellRenderer="seasonTitleRenderer"
          />
          <AgGridColumn
            headerName=""
            field="id"
            lockPosition={isMobile}
            minWidth="120"
            flex="1"
            type="rightAligned"
            sortable={false}
            cellRendererFramework={params => {
              const { id, title } = params.data;
              return (
                <ActionsDropdown
                  seasonId={id}
                  id={id}
                  title={title}
                />
              );
            }}
          />
        </AgGridReact>
      </div>
    </div>
  );
}

export default SeasonsList;
