import React, { Fragment } from "react";
import PropTypes from "prop-types";
import TimeInput from "@/components/TimeInput";

import { FormFeedback } from "reactstrap";

import useSetScheduledTimeValue from "./hooks/useSetScheduledTimeValue";

function ScheduledEndTimeInput(props) {
  const { id, value, name, setValue, isInvalid, isDirty, errors, ref, startTime } = props;

  const onChange = useSetScheduledTimeValue(setValue);

  if (startTime != "" && value == "") {
    const timeParts = startTime.split(":");
    let hours = parseInt(timeParts[0]);
    let minutes = parseInt(timeParts[1]);

    hours = (hours + Math.floor((minutes + 15) / 60) + 1) % 24;
    minutes = (minutes + 15) % 60;

    setValue(`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`);
  }

  return (
    <Fragment>
      <TimeInput name={name} id={id} value={value} isDirty={isDirty} isInvalid={isInvalid} onChange={onChange} ref={ref}/>

      {isDirty &&
        errors.map(error => (
          <FormFeedback style={{ display: "block" }} key={error}>
            {error}
          </FormFeedback>
        ))}
    </Fragment>
  );
}

ScheduledEndTimeInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  isDirty: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default ScheduledEndTimeInput;
