import { combineReducers } from "redux";

import { listReducer, creatingReducer, currentReducer, teamFormReducer } from "./reducers";

export default combineReducers({
  list: listReducer,
  new: creatingReducer,
  current: currentReducer,
  teamForm: teamFormReducer
});
