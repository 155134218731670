import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

import FullName from "@/components/FullName";

class Member extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    externalId: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    teams: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
      })
    ),
    onRoster: PropTypes.bool.isRequired,
    isProcessing: PropTypes.bool.isRequired,
    addMemberToRoster: PropTypes.func,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    teams: [],
    disabled: false,
    addMemberToRoster() {}
  };

  triggerAddingMemberToRoster = () => {
    const { id, type, addMemberToRoster, isProcessing } = this.props;

    if (isProcessing === false) {
      addMemberToRoster({ id, type });
    }
  };

  render() {
    const { firstName, lastName, externalId, teams, onRoster, isProcessing, disabled } = this.props;

    return (
      <tr>
        <td>
          <FullName firstName={firstName} lastName={lastName} />
        </td>
        <td>{externalId}</td>
        <td>
          {teams.map(({ id, title }) => (
            <div key={id}>{title}</div>
          ))}
        </td>
        <td>
          <Button
            outline
            onClick={this.triggerAddingMemberToRoster}
            color={onRoster || isProcessing ? "secondary" : "success"}
            disabled={disabled || onRoster || isProcessing}
            size="sm"
          >
            {onRoster ? "On Roster" : isProcessing ? "Processing…" : "Add to Roster"}
          </Button>
        </td>
      </tr>
    );
  }
}

export default Member;
