import { Ability } from "@casl/ability";

function subjectName(item) {
  if (!item || typeof item === "string") {
    return item;
  }

  return item.type;
}

export default function createAbility(rules) {
  return new Ability(rules, {
    subjectName
  });
}
