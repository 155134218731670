import React from "react";
import PropTypes from "prop-types";

import PenaltyReport from "./PenaltyReport";
import GeneralReport from "./GeneralReport";

function Report({ type, ...rest }) {
  switch (type) {
    case "penalty":
      return <PenaltyReport {...rest} />;
    case "general":
      return <GeneralReport {...rest} />;
    default:
      return null;
  }
}

Report.propTypes = {
  type: PropTypes.oneOf(["penalty", "general"]).isRequired
};

export default Report;
