import { put, all, takeLatest, call } from "redux-saga/effects";
import build from "redux-object";

import { suspensionReportLoadingRoutine } from "./routines";
import { loadSuspensionReport } from "@/lib/api/reports";
import { gamesheetAPIRequest } from "../api/sagas";

function* reportLoadingSaga({ payload: { seasonId } }) {
  yield put(suspensionReportLoadingRoutine.request());

  try {
    const { data } = yield call(gamesheetAPIRequest, loadSuspensionReport, {
      seasonId
    });

    const suspensionReports =
      build(data, "suspensionReports", undefined, {
        includeType: true
      }) || [];

    yield put(suspensionReportLoadingRoutine.success({ suspensionReports }));
  } catch (error) {
    const { status: responseStatus } = error.response || {};

    yield put(suspensionReportLoadingRoutine.failure({ error, responseStatus }));
  } finally {
    yield put(suspensionReportLoadingRoutine.fulfill());
  }
}

export function* suspensionReportFlow() {
  yield all([takeLatest(suspensionReportLoadingRoutine.TRIGGER, reportLoadingSaga)]);
}
