import { Table } from "reactstrap";
import React, { Fragment, useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";
import "../style.scss";
import classNames from "classnames";
import { useRosterImport } from "@/components-16.8/RosterImportWizard/hooks/useRosterImport";

const makeShapeFromFields = fieldType => {
  return PropTypes.shape(
    Object.keys(FIELDS).reduce((result, field) => {
      return { ...result, [field]: fieldType };
    }, {})
  );
};

const FIELDS = {
  type: "Type",
  firstName: "First Name",
  lastName: "Last Name",
  externalId: "External ID",
  teamId: "Team Id",
  jerseyNumber: "Jersey Number",
  position: "Position",
  designation: "Designation",
  affiliated: "Affiliated",
  birthdate: "Birthdate",
  weight: "Weight",
  height: "Height",
  shotHand: "Shot Hand",
  country: "Birth Country",
  state: "Birth State",
  hometown: "Birth City",
  draftedBy: "Drafted By",
  committedTo: "Committed To",
  bio: "Bio"
};

const fieldsReducer = (state, action) => {
  switch (action.type) {
    case "changeSkippedRecords": {
      const skipped = action.payload.skipped;
      const fields = Object.keys(FIELDS).filter(_field => !skipped.includes(_field));
      const columns = Math.min(6, Math.ceil(fields.length / 2));
      const matrix = [];
      while (fields.length) matrix.push(fields.splice(0, columns));
      return {
        lineNumberSpan: matrix.length + 1,
        columns,
        fieldMatrix: matrix,
        fields
      };
    }
    default: {
      // do nothing
    }
  }
};

function ValidationFailed() {

  const { records } = useRosterImport();
  const [ recordsWithErrors, setRecordsWithErrors ] = useState([]);

  const [state, dispatch] = useReducer(fieldsReducer, {
    lineNumberSpan: 0,
    columns: 0,
    fieldMatrix: [],
    fields: []
  });

  useEffect(() => {
    dispatch({ type: "changeSkippedRecords", payload: { skipped: ["bio"] } });
  }, []);

  useEffect(() => {
    setRecordsWithErrors(records
      .map((record, recordIndex) => ({ ...record, line:recordIndex+2 }))
      .filter( record => {
        return !!record.validationErrors.length
      })
    );
  },[ records.length ]);

  return (
    <Fragment>
      <div className="csv-import__error__text">
        <p>{recordsWithErrors.length} records with validation errors were found in the imported .csv file. Please fix and try again.</p>
      </div>
      <Table borderless className="csv-import-table">
        {recordsWithErrors.map( record => (

          // every record get it's own tbody which provides the alternating color and thick black bar
          <tbody key={record.line} className="record">
            {state.fieldMatrix.map((_fields, index) => (

              // each row of fields
              <tr key={index} className="field-row">

                {/* This shows the record number on the left which rowspans all the field rows */}
                {!index && ( <td className="field" rowSpan={state.lineNumberSpan}>{record.line}</td> )}

                {/* Horizontally places the cell for this row of fields */}
                {_fields.map(field => {

                  // check to see if this field has an import validation error
                  const error = record.validationErrors.find( error => error.field === field );
                  !!error && console.log(error);

                  // a single value in a row
                  return (
                    <td key={field} className={classNames({ field: true, error: !!error })}>
                      <span className="label">{FIELDS[field] === "" ? "\u00A0" : FIELDS[field]}</span>
                      <span className="value">{record[field] === "" ? "\u00A0" : record[field]}</span>
                      { !!error && <span className="error">{error.error}</span> }
                    </td>
                  );
                })}
              </tr>

            ))}
            <tr className="field-row">
              <td colSpan={state.columns} className="field">
                <span className="label">Biography</span>
                <span className="value">{record["bio"]}</span>
              </td>
            </tr>
          </tbody>

        ))}
      </Table>
    </Fragment>
  );
}

export default ValidationFailed;
