import { useSelector } from "react-redux";
import { getPenaltyClassOptions } from "@/redux/currentSeason/selectors";

export function usePenaltyClassOptions() {
    const penaltyClassOptions = useSelector(state => getPenaltyClassOptions(state));
    
    return {
      penaltyClassOptions
    };
}
