import { handleActions } from "redux-actions";

import { passwordActions } from "../../actions";
import { resetPasswordRoutine } from "../../routines";

const initialState = {
  email: "",
  isDirty: false
};

const setEmail = (state, { payload }) => ({
  ...state,
  isDirty: true,
  email: payload
});

const clear = () => ({ ...initialState });

const makePristine = state => ({ ...state, isDirty: false });

export default handleActions(
  {
    [passwordActions.reset.setEmail]: setEmail,
    [passwordActions.reset.clear]: clear,
    [resetPasswordRoutine.TRIGGER]: makePristine
  },
  initialState
);
