import { handleActions } from "redux-actions";

import { loadGameFormRoutine } from "../routines";
import actions from "../actions";

const addError = (state, { payload: { key, msg } }) => ({
  ...state,
  [key]: msg
});

const removeError = (state, { payload: { key } }) => {
  const out = { ...state };
  delete out[key];
  return out;
};

export default handleActions(
  {
    [loadGameFormRoutine.SUCCESS]: () => ({}),
    [actions.addError]: addError,
    [actions.removeError]: removeError,
    [actions.clear]: () => ({})
  },
  {}
);
