import { createRoutine } from "redux-saga-routines";

export const DivisionListLoadingRoutine = createRoutine("LOAD_DIVISION_LIST");

export const DivisionCreatingRoutine = createRoutine("CREATE_DIVISION");

export const CurrentDivisionLoadingRoutine = createRoutine("LOAD_CURRENT_DIVISION");

export const CurrentDivisionUpdatingRoutine = createRoutine("UPDATE_CURRENT_DIVISION");

export const CurrentDivisionDeletingRoutine = createRoutine("DELETE_CURRENT_DIVISION");
