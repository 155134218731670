import { handleActions } from "redux-actions";

import { CoachListLoadingRoutine as loadingRoutine, CurrentCoachDeletingRoutine as deletingRoutine } from "../routines";

const defaultState = {
  isLoaded: false,
  isLoading: false,
  errors: null,
  ids: []
};

const trigger = () => Object.assign({}, defaultState);

const request = state => Object.assign({}, state, { isLoading: true });

const success = (state, { payload }) => Object.assign({}, state, { isLoaded: true, ids: payload.ids });

const failure = (state, { payload }) => Object.assign({}, state, { isLoaded: false, errors: payload.errors });

const fulfill = state => Object.assign({}, state, { isLoading: false });

const remove = (state, { payload }) => Object.assign({}, state, { ids: state.ids.filter(id => id !== payload.id) });

export default handleActions(
  {
    [loadingRoutine.TRIGGER]: trigger,
    [loadingRoutine.REQUEST]: request,
    [loadingRoutine.SUCCESS]: success,
    [loadingRoutine.FAILURE]: failure,
    [loadingRoutine.FULFILL]: fulfill,
    [deletingRoutine.SUCCESS]: remove
  },
  defaultState
);
