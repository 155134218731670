import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Table } from "reactstrap";
import SignatureImage from "@/components/SignatureImage";

class Curfew extends PureComponent {
  static propTypes = {
    time: PropTypes.string,
    homeCoachSignature: PropTypes.string,
    visitorCoachSignature: PropTypes.string
  };

  static defaultProps = {
    time: "None",
    homeCoachSignature: null,
    visitorCoachSignature: null
  };

  render() {
    const { time, homeCoachSignature, visitorCoachSignature } = this.props;

    return (
      <Table striped borderless size="sm">
        <thead>
          <tr>
            <th scope="col">Curfew</th>
            <th scope="col">V Init</th>
            <th scope="col">H Init</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{time}</td>
            <td>
              <SignatureImage src={homeCoachSignature} />
            </td>
            <td>
              <SignatureImage src={visitorCoachSignature} />
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }
}

export default Curfew;
