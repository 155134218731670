import React from "react";
import PropTypes from "prop-types";

import "./ListControls.scss";

const ListControls = ({ children }) => {
  return <div className="list-controls">{children}</div>;
};

ListControls.propTypes = {
  children: PropTypes.node
};

ListControls.defaultProps = {};

export default ListControls;
