import { createGetService, createDeleteService, createPostService } from "./serviceMaker";

export const loadSubscriptions = createGetService("/subscriptions");

export const deleteSubscription = createDeleteService(({ subscriptionId, unsubscribeToken }) => ({
  url: `/subscriptions/${subscriptionId}`,
  headers: {
    "X-UNSUBSCRIBE-TOKEN": unsubscribeToken
  }
}));

export const createSubscription = createPostService(({ subscribeToken, teamId }) => ({
  url: "/subscriptions",
  identity: { type: "post-game-email-subscriptions" },
  relationships: {
    team: {
      data: { type: "teams", id: teamId }
    }
  },
  headers: {
    "X-SUBSCRIBE-TOKEN": subscribeToken
  }
}));
