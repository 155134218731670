import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getMeta, getGame, loadingRoutine } from "@/redux/currentGame";

export default function useCurrentGame({ gameId, seasonId }) {
  const dispatch = useDispatch();

  const {
    isLoaded: gameIsLoaded,
    isLoading: gameIsLoading,
    isForbidden: gameIsForbidden,
    isNotFound: gameIsNotFound
  } = useSelector(getMeta);

  const game = useSelector(getGame);

  useEffect(
    () => {
      dispatch(loadingRoutine({ seasonId, gameId }));
    },
    [dispatch, seasonId, gameId]
  );

  return {
    game,
    gameId,
    gameIsLoading,
    gameIsLoaded,
    gameIsForbidden,
    gameIsNotFound
  };
}
