import { handleActions, combineActions } from "redux-actions";

import { submittingRoutine, deletingRoutine } from "@/redux/divisionForm";

import { lockTeamsRoutine } from "./routines";

import {
  TeamCreatingRoutine,
  CurrentTeamDeletingRoutine,
  CurrentTeamUpdatingRoutine,
  TeamListLoadingRoutine
} from "@/redux/teams/routines";

import { loadingRoutine } from "./routines";
import actions from "./actions";

const initialState = {
  isLoading: false,
  isLoaded: false,
  isForbidden: false,
  isFailed: false,
  isLockingTeams: [],
  ids: [],
  data: {},
  totalCount: 0
};

function reduceLoadingRequest(state) {
  return { ...state, isLoading: true };
}

function reduceLoadingSuccess(state, { payload }) {
  const { divisions, ids, totalCount } = payload;

  const data = divisions.reduce((result, { id, title, season, numTeams, numLocked }) => {
    const hasUnlockedTeams = numTeams > numLocked;
    return {
      ...result,
      [id]: { title, seasonId: season.id, numTeams, hasUnlockedTeams }
    };
  }, {});

  return { ...state, isLoaded: true, data, ids, totalCount };
}

function reduceLoadingFailure(state, { payload: { responseStatus } }) {
  return { ...state, isFailed: true, isForbidden: responseStatus === 401 };
}

function reduceLoadingFulfill(state) {
  return { ...state, isLoading: false };
}

function reduceTeamsLockRequest(state, { payload: { divisionId } }) {
  return { ...state, isLockingTeams: [...state.isLockingTeams, divisionId] };
}

function reduceTeamsLockSuccess(state, { payload: { lock, divisionId } }) {
  if (!!state.data && divisionId in state.data) {
    const out = {
      ...state,
      data: {
        ...state.data,
        [divisionId]: {
          ...state.data[divisionId],
          hasUnlockedTeams: !lock
        }
      }
    };
    console.log("in reduceTeamsLockSuccess", {
      lock,
      divisionId,
      in: state.data[divisionId],
      out: out.data[divisionId]
    });
    return out;
  } else {
    return { ...state };
  }
}

function reduceTeamsLockFulfill(state, { payload: { divisionId } }) {
  return { ...state, isLockingTeams: state.isLockingTeams.filter(did => did !== divisionId) };
}

export default handleActions(
  {
    [loadingRoutine.REQUEST]: reduceLoadingRequest,
    [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [loadingRoutine.FAILURE]: reduceLoadingFailure,
    [loadingRoutine.FULFILL]: reduceLoadingFulfill,
    [lockTeamsRoutine.REQUEST]: reduceTeamsLockRequest,
    [lockTeamsRoutine.SUCCESS]: reduceTeamsLockSuccess,
    [lockTeamsRoutine.FULFILL]: reduceTeamsLockFulfill,
    [combineActions(
      actions.clear,
      submittingRoutine.SUCCESS,
      deletingRoutine.SUCCESS,
      TeamCreatingRoutine.SUCCESS,
      CurrentTeamDeletingRoutine.SUCCESS,
      CurrentTeamUpdatingRoutine.SUCCESS,
      TeamListLoadingRoutine.SUCCESS
    )]: () => ({ ...initialState })
  },
  initialState
);
