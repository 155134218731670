import React, { useCallback, useEffect, useState } from "react";
import { config } from "../../config";
import PageLoader from "../PageLoader";
import TitleBar from "../TitleBar";
import { Button } from "reactstrap";
import { getFreshToken } from "@/lib/api/getFreshToken";
import CareerStats from "../TeamMemberPenaltyReport/CareerStats";
import StatsBySeason from "../TeamMemberPenaltyReport/StatsBySeason";
import MajorPenalties from "../TeamMemberPenaltyReport/MajorPenalties";
import InfractionHistory from "../TeamMemberPenaltyReport/InfractionHistory";
import { PlayerPenaltyReportData } from "./class.report";
import { useDispatch, useSelector } from "react-redux";
import buildAbilityRules from "@/lib/core/buildAbilityRules";
import { getTokenRoles } from "@/redux/token/selectors";
import { addAbilityRules } from "@/redux/ability/actions";

// copy this
export default function PlayerPenaltyReport({ externalId }) {

    const handlePrint = useCallback(() => window.print(), []);
    const [ report, setReport ] = useState(new PlayerPenaltyReportData());
    const [ isSuspended, setIsSuspended ] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const roles = useSelector(getTokenRoles);
    const dispatch = useDispatch();

    useEffect(() => {
        getPlayerPenaltyReport().then(data => {
            
            const report = new PlayerPenaltyReportData()
            report.player = data.player
            report.careerStats = data.stats
            report.statsBySeason = data.season_stats
            report.infractionHistory = data.history
            report.majorPenalties = data.incidents
            setReport(report)

            setIsSuspended(() => (data.rostered || []).some(team => {
                return team.status === 'suspended'
            }));

            setIsLoading(false);
            setIsLoaded(true);
        })
    }, [])

    const handleAbilityRules = useCallback((data) => {
        const history = (data.history || []).map(({ game }) => ({
            id: game.id,
            home: { id: 0, division: { id: game.division_id } },
            visitor: { id: 0, division: { id: 0 }},
            association: { id: game.association_id },
            league: { id: game.league_id },
            season: { id: game.season_id },
        }));
        const incidents = (data.incidents || []).map(({ game }) => ({
            id: game.id,
            home: { division: { id: game.division_id } },
            visitor: { division: { id: 0 }},
            association: { id: game.association_id },
            league: { id: game.league_id },
            season: { id: game.season_id },
        }));

        const resources = {
            games: [...history, ...incidents]
        };

        const rules = buildAbilityRules({ roles, resources });

        dispatch(addAbilityRules(rules));
    }, []);

    const playerId = report.id;
    const handleSuspend = useCallback(async () => {
        if (!playerId) return;

        const accessToken = await getFreshToken();
        
        return fetch(`${config.BFF_API}/players/${playerId}/${isSuspended && 'unsuspend' || 'suspend'}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({}),
        }).then((response) => {
            if (!response.ok) {
                throw new Error(response.statusText);
            } else {
                setIsSuspended(!isSuspended);
            }
        }).catch((error) => {
            console.error("Error:", error);
        });
    }, [playerId, isSuspended]);

    async function getPlayerPenaltyReport() {
        const accessToken = await getFreshToken();
        return fetch(`${config.BFF_API}/reports/player-penalty-report/${externalId}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(res => res.json())
            .then(response => {
                handleAbilityRules(response.data);
                return response.data
            })
    }

    return <>

        <PageLoader isLoading={isLoading} isLoaded={isLoaded}>
            <TitleBar
                title={`${report.firstName} ${report.lastName} — Player Penalty Report`}
                subtitle={`External ID: ${externalId}`}
            >
                {isLoaded && <Button outline color="primary" size="sm" onClick={handleSuspend}>
                    {isSuspended && "Unsuspend" || "Suspend"}
                </Button>}
                <Button outline color="primary" size="sm" onClick={handlePrint}>
                    Print
                </Button>
            </TitleBar>

            <CareerStats {...report.careerStats} />
            <StatsBySeason stats={report.statsBySeason} />
            <MajorPenalties penalties={report.majorPenalties} />
            <InfractionHistory history={report.infractionHistory} />
        </PageLoader>

    </>

}