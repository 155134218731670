import React from "react";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";

import ReportDataSourcesSelect from "@/components/ReportDataSourcesSelect";

function DataSourcesPage() {
  return (
    <ProtectedLayout subject="reports" action="read">
      <Head title="Penalty Type Reports" />

      <Breadcrumbs
        items={[
          { title: "Reports" },
          {
            url: "/reports/penalty-type/sources",
            title: "Penalty Type Reports"
          }
        ]}
      />

      <TitleBar title="Penalty Type Report" />

      <ReportDataSourcesSelect url="/reports/penalty-type" extended />
    </ProtectedLayout>
  );
}

export default DataSourcesPage;
