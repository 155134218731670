import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

class Item extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    className: PropTypes.string,
    select: PropTypes.func,
    deselect: PropTypes.func
  };

  static defaultProps = {
    select() {},
    deselect() {}
  };

  toggleSelected = () => {
    const { isSelected, id, select, deselect } = this.props;

    const action = isSelected ? deselect : select;

    action(id);
  };

  render() {
    const { title, className, isSelected } = this.props;

    return (
      <button
        type="button"
        className={classNames(
          [className, "report-data-sources-select-list__input__item", "list-group-item", "list-group-item-action"],
          { active: isSelected }
        )}
        onClick={this.toggleSelected}
      >
        {title}
      </button>
    );
  }
}

export default Item;
