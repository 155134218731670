import React, { Fragment } from "react";

import { Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";

import TitleBar from "@/components/TitleBar";
import DashboardFormFooter from "@/components/DashboardFormFooter";

import { useScheduledGamesCSVImportWizardResult } from "./hooks";

function Result() {
  const { created, updated, failed, seasonId, reset } = useScheduledGamesCSVImportWizardResult();

  return (
    <Fragment>
      <TitleBar title="Import Completed" />

      {failed <= 0 && (
        <div className="scheduled-games-csv-import__result__text">
          <p>
            <strong>{created}</strong> scheduled {created === 1 ? "game was" : "games were"}{" "} imported and <strong>{updated}</strong> scheduled {updated === 1 ? "game was" : "games were"}{" "} updated.
          </p>
        </div>
      )}

      {failed > 0 && (
        <Row className="justify-content-center">
          <Col md="10" lg="6">
            <div className="scheduled-games-csv-import__result__warning">
              <p>
                Some imports failed, an unknown number succeeded, please check the list.
              </p>

              <Button type="button" color="primary" onClick={reset} size="sm" outline>
                Try again
              </Button>
            </div>
          </Col>
        </Row>
      )}

      <DashboardFormFooter>
        <div className="dashboard-form-footer__primary">
          <Button tag={Link} to={`/seasons/${seasonId}/games/scheduled`} color="primary">
            Go to Scheduled Games List
          </Button>
        </div>
      </DashboardFormFooter>
    </Fragment>
  );
}

export default Result;
