import React from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";


function ResetButton({ reset }) {
  return (
    <div className="text-center w-100 mt-3">
      <Button color="link" onClick={reset} className="p-0">
        Reset filters
      </Button>
    </div>
  );
}

ResetButton.propTypes = {
  reset: PropTypes.func.isRequired
};

export default ResetButton;
