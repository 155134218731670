import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

class Referee extends PureComponent {
  static propTypes = {
    position: PropTypes.string,
    name: PropTypes.string
  };

  static defaultProps = {
    position: "N/A",
    name: "N/A"
  };

  render() {
    const { position, name } = this.props;

    return (
      <Fragment>
        {position}
        <span className="text-uppercase"> ({name})</span>
      </Fragment>
    );
  }
}

export default Referee;
