import { connect } from "react-redux";

import { getGameFormTeamPlayers } from "@/redux/gameForm/selectors";

import TeamPlayerInput from "./TeamPlayerInput";

const mapStateToProps = (state, { team }) => ({
  players: getGameFormTeamPlayers(state, team)
});

export default connect(mapStateToProps)(TeamPlayerInput);
