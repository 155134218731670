import { atom } from "recoil";
import { CSVRosterImportStatus as ImportStatus } from "@/components-16.8/RosterImportWizard/types/importStatus";

export const lastMountState = atom({
  key: 'lastMount',
  default: 0
});

export const componentStatusState = atom({
  key: 'componentStatus',
  default: ImportStatus.PENDING
});

export const recordsState = atom({
  key: 'records',
  default: []
});

export const seasonIdState = atom({
  key: 'seasonId',
  default: null
});

export const teamIdState = atom({
  key: 'teamId',
  default: null
});

export const playerImportProgressState = atom({
  key: 'playerImportProgress',
  default: { pending: 0, sent: 0, resolved:0, rejected:0 }
});

export const coachImportProgressState = atom({
  key: 'coachImportProgress',
  default: { pending: 0, sent: 0, resolved:0, rejected:0 }
});

export const teamUpdateProgressState = atom({
  key: 'teamUpdateProgress',
  default: { pending: 0, sent: 0, resolved:0, rejected:0 }
});

export const playerImportResponsesState = atom({
  key: 'playerImportResponses',
  default: []
});

export const coachesImportResponsesState = atom({
  key: 'coachesImportResponses',
  default: []
});

export const teamsUpdateResponsesState = atom({
  key: 'teamsUpdateResponses',
  default: []
});
