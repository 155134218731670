import { takeLatest, all, put, select, debounce } from "redux-saga/effects";

import { fetchList } from "@/redux/api/sagas";
import { loadScheduledGames } from "@/lib/api/schedule";

import { actions as filterActions, getFilter } from "@/redux/scheduledGamesFilter";

import { loadingRoutine } from "./routines";
import { getPageSize } from "./selectors";

function* loadingSaga({ payload }) {
  const { pageNumber = 1, seasonId } = payload;

  const filter = yield select(getFilter);
  const pageSize = yield select(getPageSize);

  yield put(loadingRoutine.request({ pageNumber }));

  const response = yield fetchList("scheduledGames", loadScheduledGames, {
    pageNumber,
    pageSize,
    seasonId,
    filter,
    include: "teams,divisions"
  });

  yield put(loadingRoutine.success({ ...response, pageNumber }));
  yield put(loadingRoutine.fulfill({ pageNumber }));
}

export function* scheduledGamesListFlow() {
  yield all([
    takeLatest(loadingRoutine.TRIGGER, loadingSaga),
    takeLatest(
      ({ type }) =>
        [
          filterActions.setScheduledDateFrom,
          filterActions.setScheduledDateTo,
          filterActions.setDivisionIds,
          filterActions.reset
        ]
          .map(a => a.toString())
          .includes(type),
      loadingSaga
    ),
    debounce(300, filterActions.setQuery, loadingSaga)
  ]);
}
