import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { Row, Col } from "reactstrap";

import DashboardFormFooter from "@/components/DashboardFormFooter";

import AssociationList from "./AssociationList";
import LeagueList from "./LeagueList";
import SeasonList from "./SeasonList";
import PenaltyList from "./PenaltyList";
import DivisionList from "./DivisionList";
import TeamList from "./TeamList";
import SelectedResourcesCount from "./SelectedResourcesCount";
import SubmitButton from "./SubmitButton";

function Select({ url, singleSelect, penalties, extended }) {
  return (
    <Fragment>
      <Row>
        <Col md={extended ? 2 : penalties ? 3 : 4}>
          <AssociationList singleSelect={extended ? false : penalties || singleSelect} />
        </Col>
        <Col md={extended ? 2 : penalties ? 3 : 4}>
          <LeagueList singleSelect={extended ? false : penalties || singleSelect} />
        </Col>
        <Col md={extended ? 3 : penalties ? 3 : 4}>
          <SeasonList
            singleSelect={extended ? false : penalties || singleSelect}
            extended={extended}
            penalties={penalties}
          />
        </Col>
        {penalties && (
          <Col md="3">
            <PenaltyList />
          </Col>
        )}
        {extended && (
          <Fragment>
            <Col md="2">
              <DivisionList />
            </Col>
            <Col md="3">
              <TeamList />
            </Col>
          </Fragment>
        )}
      </Row>

      <DashboardFormFooter>
        <SelectedResourcesCount singleSelect={singleSelect} />
        <div className="dashboard-form-footer__primary">
          <SubmitButton extended={extended} url={url} singleSelect={singleSelect} penalties={penalties} />
        </div>
      </DashboardFormFooter>
    </Fragment>
  );
}

Select.propTypes = {
  url: PropTypes.string.isRequired,
  singleSelect: PropTypes.bool,
  extended: PropTypes.bool
};

Select.defaultProps = {
  singleSelect: false,
  extended: false
};

export default Select;
