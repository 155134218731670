import _orderBy from "lodash/orderBy";

const selectRoot = state => state.teamPickerField;

function orderByTitle(collection) {
  return _orderBy(collection, ["title"], ["asc"]);
}

export const getIsLoaded = state => selectRoot(state).isLoaded;
export const getIsLoading = state => selectRoot(state).isLoading;

export const getDivisions = state => orderByTitle(selectRoot(state).divisions);

export const getDivisionTeams = (state, divisionId) =>
  orderByTitle(selectRoot(state).teams.filter(team => team.divisionId === divisionId));

export const getDivisionIdByTeamId = (state, teamId) => {
  const team = selectRoot(state).teams.find(team => team.id === teamId);

  return team ? team.divisionId : null;
};
