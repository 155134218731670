import { useCallback } from "react";
import { useDispatch } from "react-redux";

import actions from "@/redux/copyTeams/actions";

export default function useTeamToggling(teamId, isSelected) {
  const dispatch = useDispatch();

  const toggleSelected = useCallback(
    () => {
      const action = isSelected ? actions.deselectTeam : actions.selectTeam;

      dispatch(action(teamId));
    },
    [dispatch, teamId, isSelected]
  );

  return {
    toggleSelected
  };
}
