import { handleActions, combineActions } from "redux-actions";

import { recordParsingRoutine, parsingRoutine, chunkImportingRoutine } from "../routines";

import actions from "../actions";

const initialState = {
  records: [],
  created: 0,
  updated: 0,
  failed: 0,
};

function reduceParsingSuccess(state, { payload }) {
  const {
    record: { date, gameNumber, time, ...rest },
    lineNumber
  } = payload;

  return {
    ...state,
    records: [
      ...state.records,
      {
        ...rest,
        lineNumber,
        number: gameNumber,
        scheduledStartTime: date,
        startTime: time
      }
    ]
  };
}

function reduceChunkSuccess(state, { payload: { created, updated } }) {
  return {
    ...state,
    created: Number(state.created) + Number(created),
    updated: Number(state.updated) + Number(updated)
  };
}

function reduceChunkFailure(state, { payload: { numRecords } }) {
  return { ...state, failed: Number(state.failed) + Number(numRecords) };
}

export default handleActions(
  {
    [recordParsingRoutine.SUCCESS]: reduceParsingSuccess,
    [chunkImportingRoutine.SUCCESS]: reduceChunkSuccess,
    [chunkImportingRoutine.FAILURE]: reduceChunkFailure,
    [combineActions(actions.clear, parsingRoutine.TRIGGER)]: () => ({ ...initialState })
  },
  initialState
);
