import moment from "moment";

export default function validateBirthDate({ birthdate }) {
  if (!birthdate) {
    return [];
  }

  const date = moment(
    birthdate,
    ["MM-DD-YYYY", "MM/DD/YYYY", "YYYY/MM/DD", "DD-MM-YYYY", "DD/MM/YYYY", moment.ISO_8601],
    true
  );

  if (!date.isValid()) {
    return [{ field: "birthdate", error: "invalid" }];
  }

  return [];
}
