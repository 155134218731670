import React from "react";

import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";

import AssociationForm, { useAssociationFormClearing } from "@/components/AssociationForm";

import ProtectedLayout from "@/layouts/ProtectedLayout";

function NewAssociation() {
  useAssociationFormClearing();

  return (
    <ProtectedLayout className="associations-page" subject="associations" action="create">
      <Head title="New Association" />

      <Breadcrumbs items={[{ title: "Associations", url: "/associations" }]} />

      <TitleBar title="New Association" />

      <AssociationForm />
    </ProtectedLayout>
  );
}

export default NewAssociation;
