import { handleActions } from "redux-actions";

import { DivisionCreatingRoutine as routine } from "../routines";

const defaultState = {
  isCreated: false,
  isCreating: false,
  errors: null
};

const trigger = () => Object.assign({}, defaultState, { isCreated: false });

const request = state => Object.assign({}, state, { isCreating: true });

const success = state => Object.assign({}, state, { isCreated: true });

const failure = (state, { payload }) => Object.assign({}, state, { isCreated: false, errors: payload.errors });

const fulfill = state => Object.assign({}, state, { isCreating: false });

export default handleActions(
  {
    [routine.TRIGGER]: trigger,
    [routine.REQUEST]: request,
    [routine.SUCCESS]: success,
    [routine.FAILURE]: failure,
    [routine.FULFILL]: fulfill
  },
  defaultState
);
