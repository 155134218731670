import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { actions } from "@/redux/exportSeasonGames";

export default function useExportSeasonGamesModal() {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const close = useCallback(() => setIsOpen(false), [setIsOpen]);
  const open = useCallback(() => setIsOpen(true), [setIsOpen]);

  const clear = useCallback(() => dispatch(actions.clear()), [dispatch]);

  return {
    isOpen,
    close,
    open,
    clear
  };
}
