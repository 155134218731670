import { useContext, useMemo } from "react";

import CurrentSeasonContext from "@/contexts/CurrentSeasonContext";

export default function useCurrentSeasonContext() {
  const currentSeason = useContext(CurrentSeasonContext);
  const currentSeasonMemo = useMemo(() => ({ ...currentSeason }), [currentSeason]);

  return currentSeasonMemo;
}
