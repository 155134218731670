import { combineActions } from "redux-actions";

import { actions as filterActions } from "@/redux/completedGamesFilter";

import { submitGameFormRoutine, deleteGameFormRoutine } from "@/redux/gameForm/routines";

import { gameNoteFormSubmittingRoutine } from "@/redux/gameNotes/routines";

import { loadGamesRoutine } from "../../routines";
import { gamesActions } from "../../actions";

import createPaginationReducer from "@/redux/common/createPaginationReducer";

export default createPaginationReducer(
  loadGamesRoutine,
  combineActions(
    filterActions.reset,
    gamesActions.list.clear,
    submitGameFormRoutine.SUCCESS,
    deleteGameFormRoutine.SUCCESS,
    gameNoteFormSubmittingRoutine.SUCCESS
  )
);
