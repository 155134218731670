import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import _trim from "lodash/trim";

import { Input, Button } from "reactstrap";

import DashboardForm from "@/components/DashboardForm";

import "./SearchBar.scss";

class SearchBar extends PureComponent {
  static propTypes = {
    placeholder: PropTypes.string,
    className: PropTypes.string,
    nextQuery: PropTypes.string,
    currentQuery: PropTypes.string,
    isDirty: PropTypes.bool,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    commit: PropTypes.func,
    clear: PropTypes.func
  };

  static defaultProps = {
    placeholder: "Search…",
    className: "",
    nextQuery: "",
    currentQuery: "",
    isDirty: false,
    isLoading: false,
    disabled: false,
    onChange() {},
    commit() {},
    clear() {}
  };

  handleQueryChange = ({ target: { value } }) => {
    this.props.onChange(value);
  };

  handleClear = () => {
    const { nextQuery, currentQuery, onChange, clear } = this.props;

    if (_trim(nextQuery).length > 1 && _trim(currentQuery).length === 0) {
      onChange("");
    } else {
      clear();
    }
  };

  render() {
    const { nextQuery, placeholder, className, disabled, isDirty, isLoading, commit } = this.props;

    const isPristine = !isDirty;

    return (
      <DashboardForm className={classNames(["search-bar", className])} onSubmit={commit}>
        <fieldset disabled={disabled}>
          <div className="input-group">
            <Input
              className="form-control-sm"
              type="search"
              name="query"
              placeholder={placeholder}
              onChange={this.handleQueryChange}
              value={nextQuery}
            />
            <div className="input-group-append">
              <Button outline type="submit" size="sm" color="primary" disabled={isPristine}>
                {isLoading && _trim(nextQuery).length > 0 ? "Searching…" : "Search"}
              </Button>
            </div>
          </div>
        </fieldset>
      </DashboardForm>
    );
  }
}

export default SearchBar;
