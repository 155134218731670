import React, { Fragment } from "react";
import PropTypes from "prop-types";

import classNames from "classnames";

function InvalidPenaltyCode(props) {
  const {
    code,
    label,
    penaltyClass,
    duration,
    blankLabel,
    blankCode,
    blankClass,
    blankDuration,
    dupeCode,
    invalidClass,
    lineNumber
  } = props;

  return (
    <Fragment>
      <td>{lineNumber}</td>
      <td className={classNames({ "alert-danger": blankLabel })}>{blankLabel ? "Cannot be blank" : label}</td>
      <td className={classNames({ "alert-danger": blankCode || dupeCode })}>
        {dupeCode ? (
          <Fragment>
            <strong>{code}</strong> (duplicate)
          </Fragment>
        ) : blankCode ? (
          "Cannot be blank"
        ) : (
          code
        )}
      </td>
      <td className={classNames({ "alert-danger": (invalidClass) })}>
        {invalidClass ? 
          penaltyClass.length > 0 ? (
          <Fragment>
            <strong>{penaltyClass}</strong> (invalid)
          </Fragment>
          ) : (
            <>(empty)</>
        ) : (
          penaltyClass
        )}
      </td>
      <td className={classNames({ "alert-danger": (!blankClass && blankDuration) })}>
        {!blankClass && blankDuration && "Duration cannot be blank if class is not"}
        {duration}
      </td>
    </Fragment>
  );
}

InvalidPenaltyCode.propTypes = {
  code: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  blankCode: PropTypes.bool.isRequired,
  blankLabel: PropTypes.bool.isRequired,
  dupeCode: PropTypes.bool.isRequired,
  lineNumber: PropTypes.number.isRequired
};

export default InvalidPenaltyCode;
