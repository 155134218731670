import { connect } from "react-redux";

import { getAccount } from "@/redux/account/selectors";
import { account } from "@/redux/account/actions";
import { accountValidationRoutine } from "@/redux/account/routines";

import Edit from "./Edit";

const mapStateToProps = state => ({
  ...getAccount(state)
});

const mapDispatchToProps = dispatch => {
  return {
    onChange: attributes => dispatch(account.edit.fieldChange(attributes)),
    onSubmit: () => dispatch(accountValidationRoutine())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Edit);
