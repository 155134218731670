import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import "./EndMessage.scss";

class InfiniteScrollEndMessage extends PureComponent {
  static propTypes = {
    text: PropTypes.string
  };

  static defaultProps = {
    text: null
  };

  render() {
    const { text } = this.props;

    return <div className="infinite-scroll__end-message">{text}</div>;
  }
}

export default InfiniteScrollEndMessage;
