import { connect } from "react-redux";

import { getGameFormTeamGoalies } from "@/redux/gameForm/selectors";

import TeamGoalieInput from "./TeamGoalieInput";

const mapStateToProps = (state, { team }) => ({
  goalies: getGameFormTeamGoalies(state, team)
});

export default connect(mapStateToProps)(TeamGoalieInput);
