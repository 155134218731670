import React from "react";
import PropTypes from "prop-types";

import { FormGroup, Input, Row, Col } from "reactstrap";

import { LIMIT } from "./constants";
import { useMaxGoalDifferential } from "./hooks";

import TitleBar from "@/components/TitleBar";

import "./MaxGoalDifferentialFormField.scss";

function MaxGoalDifferentialFormField({ value, onChange }) {
  const { options, changeValue } = useMaxGoalDifferential({
    onChange,
    value
  });

  return (
    <FormGroup className="max-goal-differential">
      <TitleBar slim title="Max Goal Differential" titleTag="h4" />
      <Row>
        <Col md="6">
          <Input type="select" onChange={changeValue} value={value || LIMIT}>
            {options.map(({ value, text }) => (
              <option key={value} value={value}>
                {text}
              </option>
            ))}
          </Input>
        </Col>
      </Row>
    </FormGroup>
  );
}

MaxGoalDifferentialFormField.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func
};

export default MaxGoalDifferentialFormField;
