import React, { useCallback, useMemo, useRef, useState, useEffect } from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

export const TieBreakerTable = ({ data, dataToSubmit, setDataToSubmit, allowSortAllRows }) => {
  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: "100%", height: "450px" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%", textAlign: "center" }), []);
  const [rowData, setRowData] = useState(null);
  const [columnDefs, setColumnDefs] = useState([
    { field: "rank", rowDrag: (params) => rowDragEnabled(params) },
    { field: "reason", resizable: true },
    { field: "teamName", resizable: true },
    { field: "GP", headerName: "GP" },
    { field: "W", headerName: "W" },
    { field: "L", headerName: "L" },
    { field: "T", headerName: "T" },
    { field: "OTSOW", headerName: "OTW/SOW" },
    { field: "OTSOL", headerName: "OTL/SOL" },
    { field: "PTS", headerName: "PTS" },
    { field: "GF", headerName: "GF" },
    { field: "GA", headerName: "GA" },
    { field: "DIFF", headerName: "DIFF" },
    { field: "PPG", headerName: "PPG" },
    { field: "PPGA", headerName: "PPGA" },
    { field: "SHG", headerName: "SHG" },
    { field: "SHGA", headerName: "SHGA" },
    { field: "PIM", headerName: "PIM" },
    { field: "QuickestGoal", headerName: "QA" },
    { field: "SO", headerName: "SO" },
    { field: "PeriodPoints", headerName: "Per. P" },
    { field: "PeriodsWon", headerName: "Per. W" },
    { field: "DIFF", headerName: "GD" },
    { field: "FPP", headerName: "FPP" },
    { field: "PPO", headerName: "PPO" },
    { field: "PPOA", headerName: "PPOA" },
  ]);
  const defaultColDef = useMemo(() => {
    return {
      width: 100,
      sortable: false,
      filter: false,
    };
  }, []);

  const onGridReady = useCallback(
    (params) => {
      data && setRowData(data);
    },
    [data]
  );

  const gridOptions = {
    getRowStyle: params => {
      if(allowSortAllRows || params.data.reason === "Manual"){
        return {background: "#fff"}
      } else {
        // this sets the row color to be gray when not a manually ranked row.
        return {background: '#f8f9fa', color: "rgba(0, 0, 0, 0.5)"}
      }
    }
  }

  const rowDragEnabled = (params) => {
    //add logic for which rows can grabbed
    return allowSortAllRows || params.data.reason === "Manual";
  };

  const onRowDragMove = useCallback(
    (event) => {
      if (!event.overNode) {
        return;
      }

      if (!allowSortAllRows && event.node.data.statHash !== event.overNode.data.statHash) {
        // This guard prevents a row from being dropped on a row that does not have the same statHash - Drew
        return;
      }

      const movingNode = event.node;
      const overNode = event.overNode;
      const rowNeedsToMove = movingNode !== overNode;
      if (rowNeedsToMove) {
        // the list of rows we have is data, not row nodes, so extract the data
        const movingData = movingNode.data;
        const overData = overNode.data;
        const fromIndex = data.indexOf(movingData);
        const toIndex = data.indexOf(overData);
        const newStore = data.slice();
        moveInArray(newStore, fromIndex, toIndex);
        data = newStore;
        setRowData(newStore);
        gridRef.current.api.clearFocusedCell();
      }
      function moveInArray(arr, fromIndex, toIndex) {
        const element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
      }

      let adjustedRanksWithRankId = data.map((rank, index) => {
        return {
          id: rank.id,
          rank: index + 1,
        };
      });
      let copyOfDataToSubmit = [ ...dataToSubmit ];

      let newDataToSubmit = copyOfDataToSubmit.map((rank) => {
        let matchedRank = adjustedRanksWithRankId.find((sortedRank) => sortedRank.id === rank.id);
        if (matchedRank.id) {
          rank.rank = matchedRank.rank;
        }
        return rank;
      });

      newDataToSubmit.sort((a, b) => a.rank - b.rank);
      setDataToSubmit( newDataToSubmit );
    },
    [data]
  );

  const getRowNodeId = useCallback((data) => {
    return data.id;
  }, []);

  const autoSizeAll = useCallback((skipHeader) => {
    const allColumnIds = [];
    gridRef.current.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);

  useEffect(() => {
    autoSizeAll();
  }, []);

  return (
    <div style={containerStyle}>
      <div style={gridStyle} className="ag-theme-alpine">
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          immutableData={true}
          animateRows={true}
          onGridReady={onGridReady}
          getRowNodeId={getRowNodeId}
          onRowDragMove={onRowDragMove}
          gridOptions={gridOptions}
        ></AgGridReact>
      </div>
    </div>
  );
};
