import _isEmpty from "lodash/isEmpty";

export function validateFirstName(value) {
  const errors = [];

  if (_isEmpty(value)) {
    errors.push("First name can not be empty");
  }

  return errors;
}

export function validateLastName(value) {
  const errors = [];

  if (_isEmpty(value)) {
    errors.push("Last name can not be empty");
  }

  return errors;
}

export function validateEmail(value) {
  const errors = [];

  if (_isEmpty(value)) {
    errors.push("Email can not be empty");
  }

  return errors;
}

export function validate({ firstName, lastName, email }) {
  let errors = null;

  const firstNameErrors = validateFirstName(firstName);
  const lastNameErrors = validateLastName(lastName);
  const emailErrors = validateEmail(email);

  if (firstNameErrors.length > 0) {
    errors = { ...errors, firstName: firstNameErrors };
  }

  if (lastNameErrors.length > 0) {
    errors = { ...errors, lastName: lastNameErrors };
  }

  if (emailErrors.length > 0) {
    errors = { ...errors, email: emailErrors };
  }

  return errors;
}
