import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import AbilityButton from "@/components/AbilityButton";
import useSubmitButton from "./useSubmitButton";

function SubmitButton(props) {
  const { gameId, canSubmit, disabled } = props;
  const { errors } = useSubmitButton();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <React.Fragment>
      {canSubmit && (
        <AbilityButton
          subject={{ type: "games", id: gameId }}
          action="update"
          type="submit"
          color="success"
          disabled={disabled}
          outline={disabled}
        >
          Save Changes
        </AbilityButton>
      )}
      {!canSubmit && (
        <React.Fragment>
          <Button color="secondary" onClick={toggle} disabled={disabled} outline={disabled}>
            Save Changes
          </Button>
          <Modal isOpen={modal} toggle={toggle} centered={true} backdrop="static">
            <ModalHeader toggle={toggle}>Cannot Save</ModalHeader>
            <ModalBody>
              <span className="d-block">Please fix the following errors:</span>
              <ul>
                {errors.map(msg => (
                  <li key={msg} className="mt-2">
                    {msg}
                  </li>
                ))}
              </ul>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>
                Ok
              </Button>
            </ModalFooter>
          </Modal>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

SubmitButton.propTypes = {
  gameId: PropTypes.string.isRequired,
  canSubmit: PropTypes.bool.isRequired
};

export default SubmitButton;
