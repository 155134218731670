import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";
import { seasonRosterActions } from "@/redux/seasonRoster/actions";

import {
  getSeasonRosterPlayersMergeToolIsEnabled,
  getSeasonRosterPlayersMergeToolStatus,
  getSeasonRosterCoachesMergeToolStatus,
  getSeasonRosterCoachesMergeToolIsEnabled,
  getSeasonRosterPlayersListIsLoading,
  getSeasonRosterPlayersListIsLoaded,
  getSeasonRosterCoachesListIsLoading,
  getSeasonRosterCoachesListIsLoaded
} from "@/redux/seasonRoster/selectors";

import { loadSeasonPlayersRosterRoutine, loadSeasonCoachesRosterRoutine } from "@/redux/seasonRoster/routines";

import SeasonRosterIndex from "./SeasonRosterIndex";

function SeasonRosterIndexContainer({ match: { params } }) {
  const { tab } = params;
  const dispatch = useDispatch();

  const {
    seasonId,
    seasonIsLoaded,
    seasonIsLoading,
    seasonIsForbidden,
    season,
    seasonBreadcrumbs
  } = useCurrentSeasonContext();

  const isPlayersListLoaded = useSelector(getSeasonRosterPlayersListIsLoaded);
  const isPlayersListLoading = useSelector(getSeasonRosterPlayersListIsLoading);

  const isCoachesListLoaded = useSelector(getSeasonRosterCoachesListIsLoaded);
  const isCoachesListLoading = useSelector(getSeasonRosterCoachesListIsLoading);

  const playersMergeStatus = useSelector(getSeasonRosterPlayersMergeToolStatus);
  const coachesMergeStatus = useSelector(getSeasonRosterCoachesMergeToolStatus);
  const isMergePlayersToolEnabled = useSelector(getSeasonRosterPlayersMergeToolIsEnabled);
  const isMergeCoachesToolEnabled = useSelector(getSeasonRosterCoachesMergeToolIsEnabled);

  const isPlayersMergingMode = ["choosing-winner", "merging", "failure"].includes(playersMergeStatus);

  const isCoachesMergingMode = ["choosing-winner", "merging", "failure"].includes(coachesMergeStatus);

  const clear = useCallback(
    () => {
      dispatch(seasonRosterActions.clear());
    },
    [dispatch]
  );

  const loadPlayersList = useCallback(
    () =>
      dispatch(
        loadSeasonPlayersRosterRoutine({
          seasonId
        })
      ),
    [dispatch, seasonId]
  );

  const loadCoachesList = useCallback(
    () =>
      dispatch(
        loadSeasonCoachesRosterRoutine({
          seasonId
        })
      ),
    [dispatch, seasonId]
  );

  return (
    <SeasonRosterIndex
      seasonId={seasonId}
      isSeasonLoaded={seasonIsLoaded}
      isSeasonLoading={seasonIsLoading}
      isSeasonForbidden={seasonIsForbidden}
      isPlayersListLoaded={isPlayersListLoaded}
      isPlayersListLoading={isPlayersListLoading}
      isCoachesListLoaded={isCoachesListLoaded}
      isCoachesListLoading={isCoachesListLoading}
      season={season}
      breadcrumbs={seasonBreadcrumbs}
      isMergePlayersToolEnabled={isMergePlayersToolEnabled}
      isMergeCoachesToolEnabled={isMergeCoachesToolEnabled}
      isMergingMode={isPlayersMergingMode || isCoachesMergingMode}
      clear={clear}
      loadPlayersList={loadPlayersList}
      loadCoachesList={loadCoachesList}
      tab={tab}
    />
  );
}

SeasonRosterIndexContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      tab: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  seasonId: PropTypes.string.isRequired
};

export default SeasonRosterIndexContainer;
