import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { submittingRoutine, deletingRoutine } from "@/redux/invitationForm/routines";

import { getIsInvalid, getIsPristine, getIsNotFilled, getMeta, getStatus } from "@/redux/invitationForm/selectors";

export default function useForm() {
  const dispatch = useDispatch();
  const submit = useCallback(() => dispatch(submittingRoutine()), [dispatch]);

  const { id, isLoaded, isLoading, isDeleting, isCreating } = useSelector(getMeta);

  const remove = useCallback(() => dispatch(deletingRoutine(id)), [dispatch, id]);

  const isInvalid = useSelector(getIsInvalid);
  const isPristine = useSelector(getIsPristine);
  const isNotFilled = useSelector(getIsNotFilled);
  const status = useSelector(getStatus);

  const isOperating = isDeleting || isCreating;

  return {
    submit,
    isInvalid,
    isPristine,
    isNotFilled,
    isOperating,
    isCreating,
    isDeleting,
    isLoading,
    isLoaded,
    id,
    status,
    remove
  };
}
