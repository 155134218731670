import React from "react";

import { Input } from "reactstrap";

import useRoleSelect from "./hooks/useRoleSelect";

function RoleSelect() {
  const { value, setRole } = useRoleSelect();

  return (
    <Input type="select" value={value} onChange={setRole}>
      <option value="">All roles</option>
      <option value="administrator">Administrator</option>
      <option value="director">Director</option>
      <option value="convenor">Convenor</option>
      <option value="manager">Manager</option>
      <option value="scheduler">Scheduler</option>
      <option value="observer">Observer</option>
      <option value="guest">Guest</option>
    </Input>
  );
}

export default RoleSelect;
