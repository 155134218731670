import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

import PageLoader from "@/components/PageLoader";

import SeasonIpadKeysManagerKeysList from "../KeysList";
import SeasonIpadKeysManagerSelectedKeysList from "../SelectedKeysList";

class SeasonIpadKeysManagerModal extends PureComponent {
  static propTypes = {
    associationId: PropTypes.string.isRequired,
    seasonTitle: PropTypes.string,
    isOpen: PropTypes.bool,
    isWorking: PropTypes.bool,
    selectedKeys: PropTypes.arrayOf(PropTypes.shape({})),
    close: PropTypes.func,
    addSeasonToKeysList: PropTypes.func,
    loadKeysList: PropTypes.func
  };

  static defaultProps = {
    seasonTitle: "",
    isOpen: false,
    isWorking: false,
    selectedKeys: [],
    close() {},
    addSeasonToKeysList() {},
    loadKeysList() {}
  };

  handleClose = () => {
    const { isWorking, close } = this.props;

    if (isWorking) {
      return;
    }

    close();
  };

  handleOpened = () => {
    const { associationId, loadKeysList } = this.props;

    loadKeysList(associationId);
  };

  render() {
    const { isOpen, seasonTitle, selectedKeys, isWorking, addSeasonToKeysList } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={this.handleClose} size="lg" onOpened={this.handleOpened} centered>
        <ModalHeader toggle={this.handleClose}>Add {seasonTitle} to keys</ModalHeader>

        <ModalBody>
          {isWorking ? (
            <PageLoader isLoading={true} />
          ) : (
            <Fragment>
              <SeasonIpadKeysManagerKeysList />
              <SeasonIpadKeysManagerSelectedKeysList />
            </Fragment>
          )}
        </ModalBody>

        <ModalFooter className="d-flex">
          <div>
            <Button type="button" color="secondary" outline onClick={this.handleClose} disabled={isWorking}>
              Cancel
            </Button>
          </div>

          <div className="ml-auto">
            <Button
              type="button"
              color="success"
              disabled={selectedKeys.length < 1 || isWorking}
              onClick={addSeasonToKeysList}
            >
              {isWorking ? "Processing…" : "Add season to selected keys"}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default SeasonIpadKeysManagerModal;
