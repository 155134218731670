import React from "react";

import FilterSelect from "./FilterSelect";

import useSeasonSelect from "./hooks/useSeasonSelect";

function SeasonSelect() {
  const { options, selectedId, isLoaded, isLoading, setSelectedId } = useSeasonSelect();

  return (
    <FilterSelect
      placeholder="All seasons"
      options={options}
      value={selectedId}
      isLoaded={isLoaded}
      isLoading={isLoading}
      onChange={setSelectedId}
    />
  );
}

export default SeasonSelect;
