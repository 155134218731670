import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import token from "./token/reducer";
import login from "./login/reducer";
import otp from "./otp/reducer";
import ability from "./ability/reducer";
import registration from "./registration/reducer";
import gamesheet from "./gamesheet/reducer";
import account from "./account/reducer";
import resources from "./resources/reducer";
import { reducer as associationsList } from "./associationsList";
import { reducer as currentAssociation } from "./currentAssociation";
import { reducer as associationForm } from "./associationForm";
import { reducer as leaguesList } from "./leaguesList";
import { reducer as currentLeague } from "./currentLeague";
import { reducer as leagueForm } from "./leagueForm";
import { reducer as seasonsList } from "./seasonsList";
import { reducer as currentSeason } from "./currentSeason";
import { reducer as seasonForm } from "./seasonForm";
import { reducer as cloneSeasonForm } from "./cloneSeasonForm";
import divisions from "./divisions/reducer";
import { reducer as divisionsList } from "./divisionsList";
import { reducer as currentDivision } from "./currentDivision";
import { reducer as divisionForm } from "./divisionForm";
import teams from "./teams/reducer";
import players from "./players/reducer";
import coaches from "./coaches/reducer";
import invitations from "./invitations/reducer";
import games from "./games/reducer";
import password from "./password/reducer";
import seasonRoster from "./seasonRoster/reducer";
import gameForm from "./gameForm/reducer";
import copyTeams from "./copyTeams/reducer";
import ipadKeyForm from "./ipadKeyForm/reducer";
import ipadKeys from "./ipadKeys/reducer";
import seasonIpadKeysManager from "./seasonIpadKeysManager/reducer";
import referees from "./referees/reducer";
import gameNotes from "./gameNotes/reducer";
import playerPenaltyReport from "./playerPenaltyReport/reducer";
import coachPenaltyReport from "./coachPenaltyReport/reducer";
import penaltyAccumulationReport from "./penaltyAccumulationReport/reducer";
import penaltyTypeReport from "./penaltyTypeReport/reducer";
import refereeReport from "./refereeReport/reducer";
import trainerVisitsReport from "./trainerVisitsReport/reducer";
import billingReport from "./billingReport/reducer";
import suspensionReport from "./suspensionReport/reducer";
import reportDataSourcesSelect from "./reportDataSourcesSelect/reducer";
import csvImport from "./csvImport/reducer";
import teamRosterExtender from "./teamRosterExtender/reducer";
import reportDataSourcesList from "./reportDataSourcesList/reducer";
import userList from "./userList/reducer";
import userFilter from "./userFilter/reducer";
import roleForm from "./roleForm/reducer";
import userForm from "./userForm/reducer";
import userVerificationNotification from "./userVerificationNotification/reducer";
import accountVerificationForm from "./accountVerificationForm/reducer";
import invitationFilter from "./invitationFilter/reducer";
import invitationList from "./invitationList/reducer";
import invitationForm from "./invitationForm/reducer";
import { reducer as teamRoster } from "./teamRoster";
import teamFilter from "./teamFilter/reducer";
import teamList from "./teamList/reducer";
import { reducer as divisionDropdown } from "./divisionDropdown";
import { reducer as completedGamesFilter } from "./completedGamesFilter";
import { reducer as scheduledGamesFilter } from "./scheduledGamesFilter";
import { reducer as scheduledGamesList } from "./scheduledGamesList";
import { reducer as teamPickerField } from "./teamPickerField";
import { reducer as scheduledGameForm } from "./scheduledGameForm";
import { reducer as scheduledGamesCSVImportWizard } from "./scheduledGamesCSVImportWizard";
import { reducer as currentPlayer } from "./currentPlayer";
import { reducer as playerForm } from "./playerForm";
import { reducer as gameReportEmailNotificationSettings } from "./gameReportEmailNotificationSettings";
import { reducer as unsubscribeByToken } from "./unsubscribeByToken";
import { reducer as currentGame } from "./currentGame";
import { reducer as playerOfTheGameForm } from "./playerOfTheGameForm";
import { reducer as exportSeasonGames } from "./exportSeasonGames";
import { reducer as currentIpadKey } from "./currentIpadKey";

export default function createRootReducer(history) {
  return combineReducers({
    router: connectRouter(history),
    token,
    login,
    otp,
    ability,
    registration,
    gamesheet,
    account,
    resources,
    associationsList,
    currentAssociation,
    associationForm,
    leaguesList,
    currentLeague,
    leagueForm,
    seasonsList,
    currentSeason,
    seasonForm,
    cloneSeasonForm,
    divisions,
    divisionsList,
    currentDivision,
    divisionForm,
    teams,
    players,
    coaches,
    invitations,
    games,
    password,
    seasonRoster,
    gameForm,
    copyTeams,
    ipadKeyForm,
    ipadKeys,
    seasonIpadKeysManager,
    referees,
    gameNotes,
    playerPenaltyReport,
    coachPenaltyReport,
    penaltyAccumulationReport,
    penaltyTypeReport,
    refereeReport,
    trainerVisitsReport,
    billingReport,
    suspensionReport,
    reportDataSourcesSelect,
    csvImport,
    teamRosterExtender,
    reportDataSourcesList,
    userList,
    userFilter,
    roleForm,
    userForm,
    userVerificationNotification,
    accountVerificationForm,
    invitationFilter,
    invitationList,
    invitationForm,
    teamRoster,
    teamFilter,
    teamList,
    divisionDropdown,
    completedGamesFilter,
    scheduledGamesFilter,
    scheduledGamesList,
    teamPickerField,
    scheduledGameForm,
    scheduledGamesCSVImportWizard,
    currentPlayer,
    playerForm,
    gameReportEmailNotificationSettings,
    unsubscribeByToken,
    currentGame,
    playerOfTheGameForm,
    exportSeasonGames,
    currentIpadKey
  });
}
