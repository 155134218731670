import React, { Fragment } from "react";
import { Input, Label } from "reactstrap";

export default function GameTypeInput({handleInputChange, value}) {
  return (
    <Fragment>
      <Label>Game Type</Label>
      <Input type="select" name="gameType" bsSize="sm" onChange={(e) => handleInputChange(e.target.value)} value={value}>
        <option value="">All</option>
        <option value="regular_season">Regular Season</option>
        <option value="exhibition">Exhibition</option>
        <option value="tournament">Tournament</option>
        <option value="playoff">Playoff</option>
      </Input>
    </Fragment>
  );
}
