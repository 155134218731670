import moment from "moment";

export function getCurrentHockeyYear() {
  const today = new moment();

  const nextYear = moment(today).add(1, "year");
  let { startDate, endDate } = toStartEndDates(toHockeyYear(today.format("YYYY-MM-DD"), nextYear.format("YYYY-MM-DD")));
  startDate = new moment(startDate);
  endDate = new moment(endDate);

  if (startDate <= today && today <= endDate) {
    return toHockeyYear(today.format("YYYY-MM-DD"), nextYear.format("YYYY-MM-DD"));
  } else {
    const lastYear = moment(today).subtract(1, "year");
    return toHockeyYear(lastYear.format("YYYY-MM-DD"), today.format("YYYY-MM-DD"));
  }
}

export function getHockeyYearOptions() {
  const startYear = 2012;
  const endYear = parseInt(new moment().format("YYYY")) + 1;

  const options = Array.from(Array(endYear - startYear + 1).keys()).map(n => {
    const yearFrom = startYear + n;
    const yearTo = yearFrom + 1;
    return toHockeyYear(yearFrom + "-08-16", yearTo + "-08-15");
  });

  return options;
}

export function toHockeyYear(startDate, endDate) {
  const startYear = moment(startDate).format("YYYY");
  const endYear = moment(endDate).format("YYYY");
  if (parseInt(startYear) + 1 != parseInt(endYear)) {
    console.log("Bad conversion toHockeyYear");
  }
  return `${startYear}-${endYear}`;
}

export function toStartEndDates(hockeyYear) {
  const [startYear, endYear] = hockeyYear.split("-");
  if (parseInt(startYear) + 1 != parseInt(endYear)) {
    console.log("Bad conversion toStartEndDates");
  }
  const startDate = `${startYear}-08-16`;
  const endDate = `${endYear}-08-15`;
  return { startDate, endDate };
}
