import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import Head from "@/components/Head";
import Breadcrumbs, { BreadcrumbsProps } from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";
import PageLoader from "@/components/PageLoader";
import RefereeForm from "@/components/RefereeForm";

import ProtectedLayout from "@/layouts/ProtectedLayout";

class RefereesEdit extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    seasonId: PropTypes.string.isRequired,
    isSeasonLoading: PropTypes.bool,
    isSeasonLoaded: PropTypes.bool,
    isRefereeLoading: PropTypes.bool,
    isRefereeLoaded: PropTypes.bool,
    isRefereeForbidden: PropTypes.bool.isRequired,
    breadcrumbs: BreadcrumbsProps,
    loadSeason: PropTypes.func,
    loadReferee: PropTypes.func,
    clearForm: PropTypes.func
  };

  static defaultProps = {
    isSeasonLoading: false,
    isSeasonLoaded: false,
    isRefereeLoading: false,
    isRefereeLoaded: false,
    breadcrumbs: null,
    loadSeason() {},
    loadReferee() {},
    clearForm() {}
  };

  componentDidMount() {
    this.props.loadReferee();
  }

  componentWillUnmount() {
    this.props.clearForm();
  }

  render() {
    const {
      isSeasonLoading,
      isSeasonLoaded,
      isRefereeLoading,
      isRefereeLoaded,
      isRefereeForbidden,
      breadcrumbs,
      seasonId,
      id
    } = this.props;

    return (
      <ProtectedLayout condition={!isRefereeForbidden}>
        <Head title="Edit Referee" />

        {isSeasonLoaded && isRefereeLoaded ? (
          <Fragment>
            <Breadcrumbs
              items={breadcrumbs}
              lastItem={{
                title: "Referees",
                url: `/seasons/${seasonId}/referees`
              }}
            />
            <TitleBar title="Edit referee" />
            <RefereeForm id={id} seasonId={seasonId} />
          </Fragment>
        ) : (
          <PageLoader isLoading={isSeasonLoading || isRefereeLoading} />
        )}
      </ProtectedLayout>
    );
  }
}

export default RefereesEdit;
