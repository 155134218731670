import { connect } from "react-redux";

import { goBack } from "connected-react-router";

import {
  getPasswordResetFormEmail,
  getPasswordResetIsWorking,
  getPasswordResetIsCompleted,
  getPasswordResetFormIsDirty,
  getPasswordResetError
} from "@/redux/password/selectors";

import { passwordActions } from "@/redux/password/actions";
import { resetPasswordRoutine } from "@/redux/password/routines";

import Reset from "./Reset";

const mapStateToProps = state => ({
  email: getPasswordResetFormEmail(state),
  isWorking: getPasswordResetIsWorking(state),
  isCompleted: getPasswordResetIsCompleted(state),
  isDirty: getPasswordResetFormIsDirty(state),
  error: getPasswordResetError(state)
});

const mapDispatchToProps = dispatch => ({
  setEmail: email => dispatch(passwordActions.reset.setEmail(email)),
  resetPassword: email => dispatch(resetPasswordRoutine.trigger({ email })),
  goBack: () => dispatch(goBack()),
  clear: () => dispatch(passwordActions.reset.clear())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reset);
