import { createPatchService, createGetService, createPostService, createDeleteService } from "./serviceMaker";

const urlByIdentity = ({ identity, seasonId }) => ({
  url: seasonId ? `/seasons/${seasonId}/divisions/${identity.id}` : `/divisions/${identity.id}`
});

const urlBySeason = ({ seasonId }) => ({
  url: `/seasons/${seasonId}/divisions`
});

export const loadDivisions = createGetService(({ seasonId, title, ids, include, pageSize, pageNumber, sort }) => ({
  url: seasonId ? `/seasons/${seasonId}/divisions` : "/divisions",
  params: {
    filter: {
      title,
      id: ids && ids.join(",")
    },
    ...(!!pageNumber && !!pageSize ? { page: { number: pageNumber, size: pageSize } } : {}),
    ...(!!sort ? { sort } : {}),
    include
  }
}));

export const createDivision = createPostService(({ attributes, seasonId }) => ({
  ...urlBySeason({ seasonId }),
  relationships: {
    season: { data: { id: seasonId, type: "seasons" } }
  },
  attributes
}));

export const loadDivision = createGetService(({ identity, seasonId, include }) => ({
  ...urlByIdentity({ identity, seasonId }),
  params: { include }
}));

export const updateDivision = createPatchService(({ identity, seasonId, attributes }) => ({
  ...urlByIdentity({ identity, seasonId }),
  relationships: {
    season: { data: { id: seasonId, type: "seasons" } }
  },
  attributes
}));

export const updateDivisionRosterLock = createPatchService(({ divisionId, attributes }) => ({
  url: `/divisions/${divisionId}/roster-lock`,
  relationships: {},
  attributes
}));

export const deleteDivision = createDeleteService(urlByIdentity);

export const copyTeamsToDivision = createPostService(({ divisionId, teamIds }) => ({
  url: `/divisions/${divisionId}/teams`,
  params: { copy_id: teamIds.join(",") }
}));
