import _get from "lodash/get";
import { handleActions } from "redux-actions";

import { teamLoadingRoutine, addingMemberToTeamRoutine } from "../routines";
import actions from "../actions";

const initialState = {
  title: "",
  externalId: "",
  divisionId: "",
  roster: {
    coaches: [],
    players: []
  },
  association: { id: "", title: "" },
  league: { id: "", title: "" },
  season: { id: "", title: "" },
  division: { id: "", title: "" },
  isLoading: false,
  isLoaded: false,
  isUpdating: false,
  isForbidden: false,
  isArchived: false,
  data: {}
};

function reduceLoadingRequest(state) {
  return { ...state, isLoading: true };
}

function reduceLoadingSuccess(state, { payload }) {
  const {
    title,
    externalId,
    division: { id: divisionId },
    roster: { players = [], coaches = [] } = {},
    association,
    league,
    season,
    division,
    data
  } = payload;

  return {
    ...state,
    title,
    externalId,
    divisionId,
    roster: {
      players,
      coaches
    },
    association: { id: association.id, title: association.title },
    league: { id: league.id, title: league.title },
    season: { id: season.id, title: season.title },
    division: { id: division.id, title: division.title },
    isLoaded: true,
    data
  };
}

function reduceLoadingFailure(state, { payload: { error } }) {
  const status = _get(error, "response.status");
  const errorObject = _get(error, "response.data.errors[0]", {
    code: null,
    source: { pointer: null }
  });

  return {
    ...state,
    isLoaded: false,
    isForbidden: status === 401,
    isArchived: status === 403 && errorObject.code === "archived" && _get(errorObject, "source.pointer") === "/"
  };
}

function reduceLoadingFulfill(state) {
  return { ...state, isLoading: false };
}

function reduceAddingMemberRequest(state) {
  return { ...state, isUpdating: true };
}

function reduceAddingMemberSuccess(state, { payload: { nextRoster } }) {
  const { players = [], coaches = [] } = nextRoster;

  return { ...state, roster: { players, coaches } };
}

function reduceAddingMemberFulfill(state) {
  return { ...state, isUpdating: false };
}

export default handleActions(
  {
    [teamLoadingRoutine.REQUEST]: reduceLoadingRequest,
    [teamLoadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [teamLoadingRoutine.FAILURE]: reduceLoadingFailure,
    [teamLoadingRoutine.FULFILL]: reduceLoadingFulfill,
    [addingMemberToTeamRoutine.REQUEST]: reduceAddingMemberRequest,
    [addingMemberToTeamRoutine.SUCCESS]: reduceAddingMemberSuccess,
    [addingMemberToTeamRoutine.FULFILL]: reduceAddingMemberFulfill,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
