import { createActions } from "redux-actions";

// The original component this was used for has been replaced by a 16.8 component.
// Everything here is unused except for SET_URL_SEARCH_PARAMS, which is by the new component to communicate with our old code.
const { scheduledGamesFilter: actions } = createActions({
  SCHEDULED_GAMES_FILTER: {
    SET_SCHEDULED_DATE_FROM: undefined,
    SET_SCHEDULED_DATE_TO: undefined,
    SET_DIVISION_IDS: undefined,
    SET_QUERY: undefined,
    SET_URL_SEARCH_PARAMS: undefined,
    CLEAR: undefined,
    RESET: undefined
  }
});

export default actions;
