import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import { refereesActions } from "@/redux/referees/actions";

import { getRefereeFormIsLoading, getRefereeFormIsLoaded, getRefereeFormIsForbidden } from "@/redux/referees/selectors";

import { refereeFormLoadingRoutine } from "@/redux/referees/routines";

import RefereesEdit from "./RefereesEdit";

function RefereesEditContainer({ seasonId, match: { params } }) {
  const { refereeId } = params;
  const dispatch = useDispatch();

  const { season, seasonIsLoaded, seasonIsLoading, seasonIsForbidden, seasonBreadcrumbs } = useCurrentSeasonContext();

  const isRefereeLoaded = useSelector(getRefereeFormIsLoaded);
  const isRefereeLoading = useSelector(getRefereeFormIsLoading);
  const isRefereeForbidden = useSelector(getRefereeFormIsForbidden);

  const loadReferee = useCallback(
    () =>
      dispatch(
        refereeFormLoadingRoutine({
          id: refereeId,
          seasonId
        })
      ),
    [dispatch, seasonId, refereeId]
  );

  const clearForm = useCallback(() => dispatch(refereesActions.form.clear()), [dispatch]);

  return (
    <RefereesEdit
      id={refereeId}
      seasonId={seasonId}
      season={season}
      isSeasonLoaded={seasonIsLoaded}
      isSeasonLoading={seasonIsLoading}
      isSeasonForbidden={seasonIsForbidden}
      isRefereeLoaded={isRefereeLoaded}
      isRefereeLoading={isRefereeLoading}
      isRefereeForbidden={isRefereeForbidden}
      breadcrumbs={seasonBreadcrumbs}
      loadReferee={loadReferee}
      clearForm={clearForm}
    />
  );
}

RefereesEditContainer.propTypes = {
  seasonId: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      refereeId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default RefereesEditContainer;
