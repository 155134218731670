import { useCallback } from "react";

export function useSeasonPenaltySettingsFields({ value, onChange }) {
  const { penaltyCodes } = value;

  const changePenaltyCodes = useCallback(penaltyCodes => onChange({ ...value, penaltyCodes }), [onChange, value]);

  return {
    penaltyCodes: {
      value: penaltyCodes,
      onChange: changePenaltyCodes
    },
  };
}
