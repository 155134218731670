import _camelCase from "lodash/camelCase";
import { all, takeLatest, select, put } from "redux-saga/effects";
import { push } from "connected-react-router";

import { createScheduledGame, loadScheduledGame, updateScheduledGame, deleteScheduledGame } from "@/lib/api/schedule";

import { gamesheetAPIRequest, fetchOne } from "@/redux/api/sagas";

import { FIELDS_MAPPING } from "@/components/ScheduledGameForm";

import { getUrlSearchParamsInfo } from "@/redux/scheduledGamesFilter/selectors";
import { getOneGameData } from "./selectors";

import { submittingRoutine, loadingRoutine, deletingRoutine } from "./routines";

function* submittingSaga({ payload: { gameId, seasonId, values, goBack, history } }) {
  yield put(submittingRoutine.request());

  const oneGameData = yield select(getOneGameData);

  let allValues = values;
  if (oneGameData) {
    allValues.data = {
      ...oneGameData.data,
      ...values.data,
    };

    allValues.status = oneGameData.status;
  }

  try {
    const service = gameId ? updateScheduledGame : createScheduledGame;

    yield gamesheetAPIRequest(service, { seasonId, gameId, values: allValues }, true);
    yield put(submittingRoutine.success());

    const urlSearchParamsInfo = yield select(getUrlSearchParamsInfo);
    const append = seasonId === urlSearchParamsInfo.seasonId ? urlSearchParamsInfo.urlSearchParams : "";
    
    yield goBack ?  history.push(`/seasons/${seasonId}/games/scheduled${append}`) : history.push(`/seasons/${seasonId}/games/scheduled/new`);

  } catch (error) {
    const { response } = error;
    if (response) {
      const {
        data: { errors },
        status
      } = response;

      if (status === 400 && errors) {
        const validationErrors = errors.reduce((result, { title: error, source: { pointer } }) => {
          const name = _camelCase(pointer.match(/\/data\/\w+\/(\w+)/)[1]);

          if (!name) {
            return result;
          }

          let alias;

          switch (name) {
            case "homeTeam":
            case "homeDivision":
              alias = "home";
              break;
            case "visitorTeam":
            case "visitorDivision":
              alias = "visitor";
              break;
            case "scorekeeperName":
            case "scorekeeperPhone":
              alias = "scorekeeper";
              break;
            default:
              alias = name;
          }

          const title = FIELDS_MAPPING[name];
          const errors = [...(result[alias] || []), `${title} ${error}`];

          return {
            ...result,
            [alias]: errors
          };
        }, {});
        yield put(submittingRoutine.failure({ validationErrors }));
      }
    } else {
      yield put(submittingRoutine.failure({ error }));
    }
  } finally {
    yield put(submittingRoutine.fulfill());
  }
}

function* loadingSaga({ payload: { seasonId, gameId } }) {
  yield put(loadingRoutine.request());

  try {
    const [game] = yield fetchOne({ type: "scheduledGames", id: gameId }, loadScheduledGame, {
      seasonId,
      gameId,
      skipAbilitiesUpdate: true
    });

    yield put(loadingRoutine.success({ game }));
  } catch (error) {
    yield put(loadingRoutine.failure({ error }));
  } finally {
    yield put(loadingRoutine.fulfill());
  }
}

function* deletingSaga({ payload: { seasonId, gameId } }) {
  yield put(deletingRoutine.request());

  try {
    yield gamesheetAPIRequest(
      deleteScheduledGame,
      {
        seasonId,
        gameId
      },
      true
    );

    yield put(deletingRoutine.success());

    const urlSearchParamsInfo = yield select(getUrlSearchParamsInfo);
    const append = seasonId === urlSearchParamsInfo.seasonId ? urlSearchParamsInfo.urlSearchParams : "";
    yield put(push(`/seasons/${seasonId}/games/scheduled${append}`));
  } catch (error) {
    yield put(deletingRoutine.failure({ error }));
  } finally {
    yield put(deletingRoutine.fulfill());
  }
}

export function* scheduledGameFormFlow() {
  yield all([
    takeLatest(submittingRoutine.TRIGGER, submittingSaga),
    takeLatest(loadingRoutine.TRIGGER, loadingSaga),
    takeLatest(deletingRoutine.TRIGGER, deletingSaga)
  ]);
}
