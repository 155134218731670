export function asyncCommandQueue({ MAX_CONCURRENT=2, SECONDS_BETWEEN_REQUESTS=1, requests=[], onProgress=()=>{}, onResolve=()=>{}, onDone=()=>{} }){

  const queue = [...requests].reduce(( requests, currentImport ) => {
    requests.pending.push( currentImport );
    return requests;
  }, { pending:[], sent:[], resolved:[], rejected:[] });

  const retry = (request) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => request().then(resolve).catch(reject), 1000)
    })
  }

  const refreshQueue = () => {

    if((queue.resolved.length + queue.rejected.length) === requests.length){
      onDone(queue);
    }

    if(!queue.pending.length){
      return;
    }

    // keep the number of requests at MAX_CONCURRENT if we have them
    const add = MAX_CONCURRENT - ( queue.sent.length - (queue.resolved.length + queue.rejected.length));
    const makeActive = queue.pending.splice(0,add);

    // add new active requests
    if( makeActive.length ){
      queue.sent.push( ...makeActive.map( newRequest => {
        // sort responses into resolved and rejected when they're done
        return newRequest()
          .catch(() => retry(newRequest))
          .then(result => {
            queue.resolved.push(result);
            onResolve(queue);
          })
          .catch(e => {
            queue.rejected.push(e);
          })
          .finally(() => {
            onProgress(queue);
            refreshQueue();
          });
      }));
    }

  };

  refreshQueue();

}
