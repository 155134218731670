import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Col, Row, Alert, FormGroup, Label, Input, FormFeedback, Button } from "reactstrap";

import Layout from "@/layouts/Layout";
import Head from "@/components/Head";
import TitleBar from "@/components/TitleBar";
import ContentSection from "@/components/ContentSection";
import DashboardForm from "@/components/DashboardForm";
import DashboardFormFooter from "@/components/DashboardFormFooter";
import FormItem from "@/components/FormItem";
// import PasswordRecoveryModal from "@/components/PasswordRecoveryModal";

class Edit extends PureComponent {
  static propTypes = {
    user: PropTypes.shape({
      email: PropTypes.string
    }),
    fields: PropTypes.shape({
      firstName: PropTypes.shape({
        value: PropTypes.string,
        error: PropTypes.string
      }).isRequired,
      lastName: PropTypes.shape({
        value: PropTypes.string,
        error: PropTypes.string
      }).isRequired,
      email: PropTypes.shape({
        value: PropTypes.string,
        error: PropTypes.string
      }).isRequired,
      password: PropTypes.shape({
        value: PropTypes.string,
        error: PropTypes.string
      }).isRequired,
    }).isRequired,
    error: PropTypes.string,
    isUpdating: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func
  };

  static defaultProps = {
    isUpdating: false,
    onChange() { },
    onSubmit() { }
  };

  handleChange = ({ target }) => {
    const { name, value } = target;

    this.props.onChange({
      [name]: value
    });
  };

  handleSubmit = () => {
    this.props.onSubmit();
  };

  render() {
    const {
      user: { email: currentEmail },
      fields: { firstName, lastName, email, password },
      isUpdating,
      error
    } = this.props;

    const showVerifyPassword = currentEmail !== email.value;

    return (
      <Layout>
        <Head title="My Profile" />

        <Row className="justify-content-center">
          <Col md="10" lg="6">
            <TitleBar title="My Profile" />

            <DashboardForm className="user-profile-form" onSubmit={this.handleSubmit}>
              <ContentSection>
                <FormItem>
                  <FormGroup className="col-lg-6">
                    <Label for="userFirstName">First Name</Label>
                    <Input
                      id="firstName"
                      name="firstName"
                      defaultValue={firstName.value}
                      onChange={this.handleChange}
                      invalid={firstName.error !== null}
                    />
                    <FormFeedback>{firstName.error}</FormFeedback>
                  </FormGroup>

                  <FormGroup className="col-lg-6">
                    <Label for="userLastName">Last Name</Label>
                    <Input
                      id="lastName"
                      name="lastName"
                      defaultValue={lastName.value}
                      onChange={this.handleChange}
                      invalid={lastName.error !== null}
                    />
                    <FormFeedback>{lastName.error}</FormFeedback>
                  </FormGroup>
                </FormItem>

                <FormItem>
                  <FormGroup className="col">
                    <Label for="userEmail">Email</Label>
                    <Input
                      id="email"
                      name="email"
                      defaultValue={email.value}
                      onChange={this.handleChange}
                      invalid={email.error !== null}
                    />
                    <FormFeedback>{email.error}</FormFeedback>
                  </FormGroup>
                </FormItem>

                {showVerifyPassword && (
                  <FormItem>
                    <FormGroup className="col">
                      <Label for="userPassword">Verify current password</Label>
                      <Input
                        id="password"
                        name="password"
                        type="password"
                        defaultValue={password.value}
                        onChange={this.handleChange}
                        invalid={password.error !== null}
                      />
                      <FormFeedback>{password.error}</FormFeedback>
                    </FormGroup>
                  </FormItem>
                )}
              </ContentSection>

              <Link to="/account/password">Change Password</Link>

              <DashboardFormFooter className="small-margin">
                <div className="dashboard-form-footer__primary">
                  <Button type="submit" color="success" disabled={isUpdating}>
                    {isUpdating ? "Saving..." : "Save Changes"}
                  </Button>
                </div>
              </DashboardFormFooter>

              {error ? <Alert color="danger">{error}</Alert> : null}
            </DashboardForm>
          </Col>
        </Row>
      </Layout>
    );
  }
}

export default Edit;
