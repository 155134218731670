const ERROR_MESSAGES = {
  invalid: "invalid",
  blank: "can’t be blank"
};

const selectRoot = state => state.csvImport;
const selectTeams = state =>
  Object.entries(selectRoot(state).teams).map(([id, data]) => ({
    id,
    ...data
  }));

const selectRows = state =>
  Object.entries(selectRoot(state).records).map(([lineNumber, record]) => ({
    lineNumber,
    record
  }));

const selectInvalidRows = state =>
  selectRows(state).filter(({ record: { validationErrors } }) => validationErrors.length > 0);

const createRowsSelector = recordType => state =>
  selectRows(state)
    .filter(({ record: { type } }) => type === recordType)
    .map(({ lineNumber, record: { importStatus, ...rest } }) => ({
      lineNumber,
      importStatus,
      [recordType]: rest
    }));

export const getPlayerRows = createRowsSelector("player");
export const getCoachRows = createRowsSelector("coach");
export const getValidTeamIds = state => selectRoot(state).validTeamIds;
export const getTeamIds = state => selectTeams(state).map(({ id }) => id);

export const getInvalidRows = state =>
  selectInvalidRows(state).map(({ lineNumber, record: { validationErrors, ...rest } }) => ({
    lineNumber,
    record: {
      ...rest,
      validationErrors: validationErrors.reduce(
        (errors, { field, error }) => ({
          ...errors,
          [field]: [...(errors[field] || []), ERROR_MESSAGES[error] || error]
        }),
        {}
      )
    }
  }));

export const getStatus = state => selectRoot(state).status;
export const getError = state => selectRoot(state).error;

export const getPlayersBeingImported = state => getPlayerRows(state);
export const getCoachesBeingImported = state => getCoachRows(state);
export const getTeamsBeingUpdated = state => selectTeams(state);

export const getCountPlayersBeingImported = state => getPlayerRows(state).length;

export const getCountCoachesBeingImported = state => getCoachRows(state).length;
export const getCountTeamsBeingUpdated = state => selectTeams(state).length;

export const getPlayersImported = state =>
  getPlayerRows(state).filter(({ importStatus }) => importStatus === "success");

export const getCoachesImported = state => getCoachRows(state).filter(({ importStatus }) => importStatus === "success");

export const getCountPlayersImported = state => getPlayersImported(state).length;

export const getCountCoachesImported = state => getCoachesImported(state).length;

export const getCountPlayersNotImported = state =>
  getPlayerRows(state).filter(({ importStatus }) => importStatus === "failed").length;

export const getCountCoachesNotImported = state =>
  getCoachRows(state).filter(({ importStatus }) => importStatus === "failed").length;

export const getCountTeamsFailedToUpdate = state =>
  Object.values(selectRoot(state).teams).reduce(
    (sum, { updatingStatus }) => (updatingStatus === "failed" ? sum + 1 : sum),
    0
  );

export const getCountValidationErrors = state =>
  selectInvalidRows(state).reduce((sum, { record: { validationErrors } }) => sum + validationErrors.length, 0);

export const getIsValidationFailed = state => getCountValidationErrors(state) > 0;

export const getPlayersImportingStatus = state => selectRoot(state).playersImportingStatus;

export const getCoachesImportingStatus = state => selectRoot(state).coachesImportingStatus;

export const getTeamsUpdatingStatus = state => selectRoot(state).teamsUpdatingStatus;

export const getTeam = (state, teamId) => selectRoot(state).teams[teamId];

export const getImportedPlayersByTeam = (state, teamId) =>
  getPlayersImported(state).filter(({ player }) => player.teamId === teamId);

export const getImportedCoachesByTeam = (state, teamId) =>
  getCoachesImported(state).filter(({ coach }) => coach.teamId === teamId);
