import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Alert, FormGroup, Label, Input, Button } from "reactstrap";

import DashboardForm from "@/components/DashboardForm";
import DashboardFormFooter from "@/components/DashboardFormFooter";

class ForgotPasswordForm extends PureComponent {
  static propTypes = {
    email: PropTypes.string,
    error: PropTypes.string,
    isWorking: PropTypes.bool,
    isDirty: PropTypes.bool,
    isCompleted: PropTypes.bool,
    setEmail: PropTypes.func,
    onSubmit: PropTypes.func,
    goBack: PropTypes.func
  };

  static defaultProps = {
    email: "",
    error: null,
    isWorking: false,
    isCompleted: false,
    isDirty: false,
    setEmail() {},
    onSubmit() {},
    goBack() {}
  };

  handleSubmit = () => {
    const { email, onSubmit } = this.props;

    onSubmit(email);
  };

  handleEmailChange = ({ target }) => {
    const { value } = target;

    this.props.setEmail(value);
  };

  render() {
    const { email, isWorking, goBack, isDirty, error } = this.props;

    return (
      <DashboardForm onSubmit={this.handleSubmit} isOperating={isWorking}>
        <p>Enter your email below and we&apos;ll send you a link to create a new password.</p>

        <FormGroup>
          <Label for="email">Email</Label>
          <Input type="email" id="email" name="email" value={email} onChange={this.handleEmailChange} />
        </FormGroup>

        {error && isDirty === false && <Alert color="danger">{error}</Alert>}

        <DashboardFormFooter className="small-margin">
          <div className="dashboard-form-footer__secondary">
            <Button type="button" onClick={goBack} disabled={isWorking} outline>
              ← Go back
            </Button>
          </div>

          <div className="dashboard-form-footer__primary">
            <Button type="button" color="success" onClick={this.handleSubmit} disabled={isDirty === false}>
              {isWorking ? "Processing…" : "Submit Email"}
            </Button>
          </div>
        </DashboardFormFooter>
      </DashboardForm>
    );
  }
}

export default ForgotPasswordForm;
