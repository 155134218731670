import React, { Fragment, useCallback } from "react";

import { Table, Button } from "reactstrap";

import Role from "./Role";
import useRoles from "./hooks/useRoles";
import EmptyListMessage from "@/components/EmptyListMessage";

import "./Table.scss";

function RolesTable() {
  const { roles, initRoleForm, areLoading, removeRole } = useRoles();
  const showNewRoleForm = useCallback(() => initRoleForm(), [initRoleForm]);

  {roles.map(({ id, team, division, season, league, association, ...rest }) => (
    <Role
      key={`${id}-${team}-${division}-${season}-${league}-${association}`}
      editRole={initRoleForm}
      removeRole={removeRole}
      {...rest}
    />
  ))}

  return (
    <Fragment>
      {roles.length > 0 ? (
        <Table striped borderless>
          <thead>
            <tr>
              <th className="user-form__roles-table__role" scope="col">
                Role
              </th>
              <th scope="col">
                <span className="visuallyhidden">Actions</span>
              </th>
              <th scope="col" className="user-form__roles-table__level">
                Level
              </th>
            </tr>
          </thead>
          <tbody>
            {roles.map(({ id, ...rest }) => (
              <Role key={id} editRole={initRoleForm} removeRole={removeRole} {...rest} />
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyListMessage>There are no roles assigned to user</EmptyListMessage>
      )}

      <Button block outline color="success" onClick={showNewRoleForm} disabled={areLoading} className="mt-3">
        + Add Role
      </Button>
    </Fragment>
  );
}

export default RolesTable;
