import { connect } from "react-redux";

import { getSeasonIpadKeysManagerSelectedKeys } from "@/redux/seasonIpadKeysManager/selectors";
import { toggleSeasonIpadKeysManagerKeySelected } from "@/redux/seasonIpadKeysManager/actions";

import SeasonIpadKeysManagerSelectedKeysList from "./List";

const mapStateToProps = state => ({
  keys: getSeasonIpadKeysManagerSelectedKeys(state)
});

const mapDispatchToProps = dispatch => ({
  toggleSelected: id => dispatch(toggleSeasonIpadKeysManagerKeySelected({ keyId: id }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SeasonIpadKeysManagerSelectedKeysList);
