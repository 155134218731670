import Papa from "papaparse";

import _camelCase from "lodash/camelCase";
import _trim from "lodash/trim";
import _startCase from "lodash/startCase";

export const COLUMNS = {
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  EMAIL_ADDRESS: "emailAddress",
  EXTERNAL_ID: "externalId"
};

export function makeHumanReadableCol(col) {
  if (col === COLUMNS.EXTERNAL_ID) return `External ID`;
  return `${_startCase(col)}`;
}

function reader(file) {
  return new Promise(resolve => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: "greedy",
      transformHeader: hd => _camelCase(hd),
      transform: (value, col) => _trim(value),
      complete: res => resolve(res)
    });
  });
}

export async function readCsvFile(file) {
  const { data: records, errors } = await reader(file);

  if (errors.length > 0) {
    throw new Error("CSV file is invalid");
  } else if (records.length === 0) {
    throw new Error("CSV file is empty");
  }

  const columns = Object.keys(records[0]);
  const missingColumns = Object.values(COLUMNS).filter(rc => !columns.includes(rc));
  const unknownColumns = columns.filter(c => !Object.values(COLUMNS).includes(c));

  if (!!missingColumns.length) {
    const humanReadableColumns = missingColumns.map(makeHumanReadableCol);
    const message = `CSV file is missing ${missingColumns.length > 1 ? "the following columns:" : "the column:"
      } ${humanReadableColumns.map(c => `"${c}"`).join(", ")}`;
    throw new Error(message);
  }

  if (!!unknownColumns.length) {
    const message = `CSV file includes ${unknownColumns.length > 1 ? "the following unknown columns:" : "unknown column:"
      } ${unknownColumns.map(c => `"${c}"`).join(", ")}`;
    throw new Error(message);
  }

  return records;
}