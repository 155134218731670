import { handleActions } from "redux-actions";

import { CreateInvitationRoutine as routine } from "../routines";

const defaultState = {
  isCreating: false,
  isCreated: false
};

const trigger = () => ({ ...defaultState });
const request = state => ({ ...state, isCreating: true });
const success = state => ({ ...state, isCreated: true });
const failure = state => ({ ...state, isCreated: false });
const fulfill = state => ({ ...state, isCreating: false });

export default handleActions(
  {
    [routine.TRIGGER]: trigger,
    [routine.REQUEST]: request,
    [routine.SUCCESS]: success,
    [routine.FAILURE]: failure,
    [routine.FULFILL]: fulfill
  },
  defaultState
);
