import React from "react";
import PropTypes from "prop-types";

import { Label, Button, FormGroup } from "reactstrap";

import ContentSection from "@/components/ContentSection";
import FormItem from "@/components/FormItem";

import Shootout from "./Shootout";

import { useShootoutFieldset } from "./hooks";

function ShootoutsFieldset({ team }) {
  const { teamTitle, shootouts, addShootout } = useShootoutFieldset(team);

  return (
    <ContentSection title={`${team.toUpperCase()} – ${teamTitle}`} className="last-child-override">
      {shootouts.length > 0 && (
        <FormItem>
          <FormGroup className="col-md-2">
            <Label>Attempt #</Label>
          </FormGroup>

          <FormGroup className="col-md-3">
            <Label>Player</Label>
          </FormGroup>

          <FormGroup className="col-md-3">
            <Label>Goalie</Label>
          </FormGroup>

          <FormGroup className="col-md-2">
            <Label>Result</Label>
          </FormGroup>
        </FormItem>
      )}

      {shootouts.map(shootout => (
        <Shootout key={shootout.index} team={team} {...shootout} />
      ))}

      <FormItem>
        <Button block outline color="success" onClick={addShootout}>
          Add {team} Shootout
        </Button>
      </FormItem>
    </ContentSection>
  );
}

ShootoutsFieldset.propTypes = {
  team: PropTypes.oneOf(["home", "visitor"]).isRequired
};

export default ShootoutsFieldset;
