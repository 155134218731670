import { useCallback } from "react";
import useFormWithRemoteValidationErrors from "@/hooks/useFormWithRemoteValidationErrors";

import { getRemoteValidationErrors } from "@/redux/playerForm";

import { DEFAULT_VALUES, CONSTRAINTS } from "../constants";

export default function useAssociationForm(initialValues = DEFAULT_VALUES) {
  const form = useFormWithRemoteValidationErrors({
    initialValues,
    constraints: CONSTRAINTS,
    errorsSelector: getRemoteValidationErrors
  });

  const resetProvince = useCallback(() => form.fields.province.setValue(""), [form.fields.province]);

  return {
    ...form,
    resetProvince
  };
}
