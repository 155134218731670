import { useRecoilValue, useRecoilState } from 'recoil';

import { ImportStatus } from "@/components-16.8/RefereeImportWizard/types/importStatus";


import {
    importStatusState,
    badRecordsState
} from "@/components-16.8/RefereeImportWizard/state";

export function useParsingFailure() {

    const [importStatus, setImportStatus] = useRecoilState(importStatusState);
    const badRecords = useRecoilValue(badRecordsState);

    const backToSelect = () => {
        setImportStatus(ImportStatus.PENDING);
    }

    return {
        badRecords,
        backToSelect,
    }
    
}