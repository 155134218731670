import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Input } from "reactstrap";

class PenaltyLengthInput extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    lengths: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func
  };

  static defaultProps = {
    onChange() {}
  };

  render() {
    const { id, name, value, lengths, onChange } = this.props;

    return (
      <Input type="select" id={id} name={name} value={value} onChange={onChange}>
        <option disabled />
        {lengths.map(length => (
          <option key={length} value={length}>
            {length}
          </option>
        ))}
      </Input>
    );
  }
}

export default PenaltyLengthInput;
