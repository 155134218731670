import React, { useContext } from "react";

import { Row, Col, Label, Input, FormGroup, Button, FormFeedback, Alert } from "reactstrap";

import CurrentSeasonContext from "@/contexts/CurrentSeasonContext";
import CurrentGameContext from "@/contexts/CurrentGameContext";
import TitleBar from "@/components/TitleBar";
import DashboardForm from "@/components/DashboardForm";

import { usePlayerOfTheGameForm, useGameIsExpired, useSeasonPlayerOfTheGameIsEnabled } from "./hooks";

import ExpiredGameMessage from "./ExpiredGameMessage";

import "./PlayerOfTheGameForm.scss";

function PlayerOfTheGameForm() {
  const { seasonId } = useContext(CurrentSeasonContext);
  const { game, gameId } = useContext(CurrentGameContext);

  const { fields, submit, isPristine, isInvalid, isSubmitting, isUnknownError, isSubmitted } = usePlayerOfTheGameForm({
    seasonId,
    gameId
  });

  const isEnabled = useSeasonPlayerOfTheGameIsEnabled();
  const isGameExpired = useGameIsExpired();

  const { number, visitor, home } = game;

  if (!isEnabled) {
    return <Alert color="danger">Player of the game is disabled in the current season</Alert>;
  }

  if (isGameExpired) {
    return <ExpiredGameMessage />;
  }

  return (
    <Row>
      <Col md={{ size: 6, offset: 3 }}>
        <TitleBar title="Player of the Game" />

        {isSubmitted ? (
          <Alert color="success">The email has been sent</Alert>
        ) : (
          <React.Fragment>
            <p className="pog-page__game">
              Game #{number} | {visitor.title} vs {home.title}
            </p>
            <p className="pog-page__description">
              Enter the recipient player’s name &amp; email and we’ll send them a congratulatory note along with a
              reward from your league’s sponsor.
            </p>

            {isUnknownError && (
              <Alert color="danger">Unable to send email due to unknown error. Please, try again.</Alert>
            )}

            <DashboardForm className="pog-page__form" isOperating={isSubmitting} onSubmit={submit}>
              <FormGroup>
                <Label for="pog-page__form__name">Player Name</Label>
                <Input id="pog-page__form__name" name="name" {...fields.name.input} />

                {fields.name.input.invalid &&
                  fields.name.errors.map(error => <FormFeedback key={error}>{error}</FormFeedback>)}
              </FormGroup>
              <FormGroup>
                <Label for="pog-page__form__email">Email</Label>
                <Input id="pog-page__form__email" type="email" name="email" {...fields.email.input} />

                {fields.email.input.invalid &&
                  fields.email.errors.map(error => <FormFeedback key={error}>{error}</FormFeedback>)}
              </FormGroup>
              <div className="pog-page__form__footer">
                <Button
                  color="success"
                  type="submit"
                  outline={isPristine || isInvalid}
                  disabled={isPristine || isInvalid}
                >
                  {isSubmitting ? "Sending Email…" : "Send Email"}
                </Button>
              </div>
            </DashboardForm>
          </React.Fragment>
        )}
      </Col>
    </Row>
  );
}

export default PlayerOfTheGameForm;
