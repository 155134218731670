import React, { Fragment } from "react";
import { useRosterImport } from "@/components-16.8/RosterImportWizard/hooks/useRosterImport";
import ShowIf from "@/components-16.8/ShowIf";

function ValidationSucceeded() {

  const { players, coaches, season, team, isSeasonImport, isTeamImport } = useRosterImport();

  return (
    <Fragment>
      <div className="csv-import__confirmation__text">
        <p>
          <ShowIf cond={isSeasonImport}>{players.length} players and {coaches.length} coaches will be imported into the <span className="season">{season.title}</span> season.</ShowIf>
          <ShowIf cond={isTeamImport}>{players.length} players and {coaches.length} coaches will be imported into the <span className="season">{team && team.attributes.title}</span> team.</ShowIf>
        </p>
        <p>Would you like to continue?</p>
      </div>
    </Fragment>
  );
}

export default ValidationSucceeded;
