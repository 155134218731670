import { all, put, takeLatest, select, debounce } from "redux-saga/effects";
import _snakeCase from "lodash/snakeCase";

import { loadInvitations } from "@/lib/api/invitations";
import { fetchList } from "@/redux/api/sagas";
import { getFilterParams } from "@/redux/invitationFilter/selectors";
import filterActions from "@/redux/invitationFilter/actions";

import { listLoadingRoutine } from "./routines";

import { getLimit, getOffset } from "./selectors";

function* listLoadingSaga({ payload: { append = false } = {} }) {
  const limit = yield select(getLimit);
  const offset = append ? yield select(getOffset) : 0;
  const filterParams = yield select(getFilterParams);
  const filter = Object.entries(filterParams)
    .map(([key, value]) => [_snakeCase(key), value])
    .reduce((result, [key, value]) => ({ ...result, [key]: value }), {});

  yield put(listLoadingRoutine.request({ append }));

  try {
    const { invitations, ids, filteredCount, totalCount } = yield fetchList("invitations", loadInvitations, {
      limit,
      offset,
      filter
    });

    yield put(
      listLoadingRoutine.success({
        append,
        ids,
        filteredCount,
        totalCount,
        invitations
      })
    );
  } catch (error) {
    yield put(listLoadingRoutine.failure({ error }));
  } finally {
    yield put(listLoadingRoutine.fulfill());
  }
}

export function* invitationListFlow() {
  yield all([
    takeLatest(listLoadingRoutine, listLoadingSaga),
    takeLatest(
      ({ type }) =>
        [
          filterActions.setAssociationId,
          filterActions.setLeagueId,
          filterActions.setSeasonId,
          filterActions.setDivisionId,
          filterActions.setTeamId,
          filterActions.setRole,
          filterActions.setStatus,
          filterActions.reset
        ]
          .map(a => a.toString())
          .includes(type),
      listLoadingSaga
    ),
    debounce(300, filterActions.setQuery, listLoadingSaga)
  ]);
}
