import {currentPageState, totalPagesState, filteredGamesState } from './state';
import { useRecoilValue, useRecoilState } from 'recoil';
import { scheduledGamesMetaState } from '../List/state';

export default function usePagination() {

    const [currentPage, setCurrentPage] = useRecoilState(currentPageState);
    const [totalPages, setTotalPages] = useRecoilState(totalPagesState);
    const [filteredGames, setFilteredGames] = useRecoilState(filteredGamesState);
    const [meta, setMeta] = useRecoilState(scheduledGamesMetaState);

    return {
        meta,
        currentPage, 
        totalPages,
        filteredGames, 
        setMeta,
        setFilteredGames,
        setCurrentPage,
        setTotalPages
    }
}
