import { selector } from "recoil";
import { recordsState, seasonIdState } from "@/components-16.8/RosterImportWizard/state/atoms";
import Service from "@/services";
import moment from 'moment';
import * as mutators from "../parser/mutators";
import { mergeByExternalId } from "../functions/mergeByExternalId";

export const playerRecordsState = selector({
  key: 'playerRecords',
  get: ({ get }) => {
    
    const players = get(recordsState).filter(record => record.type === "player").map(player => ({ ...player, team_ids:[ player.teamId ] }));
    return mergeByExternalId( players );

  }
});

export const playerImportsState = selector({
  key: 'playerImports',
  get: ({ get }) => {
    const seasonId = get(seasonIdState);
    const players = get(playerRecordsState);

    // players is an array of merged players, so it's expected to have less than the number of rows in the csv if there are more than one record with the same externalId
    return players.map( record => () => {
      
      const player = Object.keys(record).reduce(( mutatedRecord, key ) => {
        return {
          ...mutatedRecord,
          [key]: mutators[key] ? mutators[key](record[key]) : record[key]
        }
      }, {});

      const attributes = {
        biography: player.bio,
        affiliated: player.affiliated,
        committed_to: player.committedTo,
        birthdate: player.birthdate,
        external_id: player.externalId,
        first_name: player.firstName,
        last_name: player.lastName,
        country: player.country,
        drafted_by: player.draftedBy,
        height: player.height,
        hometown: player.hometown,
        province: player.state,
        shot_hand: player.shotHand,
        weight: player.weight
      };

      !!record.birthdate || (delete attributes.birthdate);
      delete player.relationships;
      
      return Service.api().seasons.season(seasonId).players.import.post({ data: { attributes }}).then( record => {
        return { record, player };
      });
    });
  }
});
