import { useSelector } from "react-redux";

import {
  getInvitations,
  getIsLoading,
  getIsLoaded,
  getIsAppending,
  getTotalCount,
  getFilteredCount
} from "@/redux/invitationList/selectors";

export default function useInvitationListSelector() {
  const invitations = useSelector(getInvitations);
  const isLoading = useSelector(getIsLoading);
  const isLoaded = useSelector(getIsLoaded);
  const isAppending = useSelector(getIsAppending);
  const totalCount = useSelector(getTotalCount);
  const filteredCount = useSelector(getFilteredCount);

  return {
    invitations,
    isLoading,
    isLoaded,
    isAppending,
    totalCount,
    filteredCount
  };
}
