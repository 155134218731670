import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { submittingRoutine, deletingRoutine } from "@/redux/userForm/routines";

import {
  getIsInvalid,
  getIsPristine,
  getIsNotFilled,
  getIsOperating,
  getIsLoading,
  getIsLoaded,
  getUserName,
  getUserEmail,
  getUserStatus
} from "@/redux/userForm/selectors";

export default function useForm(userId) {
  const dispatch = useDispatch();
  const submit = useCallback(() => dispatch(submittingRoutine(userId)), [dispatch, userId]);

  const remove = useCallback(() => dispatch(deletingRoutine(userId)), [dispatch, userId]);

  const isInvalid = useSelector(getIsInvalid);
  const isPristine = useSelector(getIsPristine);
  const isNotFilled = useSelector(getIsNotFilled);
  const isOperating = useSelector(getIsOperating);
  const isLoading = useSelector(getIsLoading);
  const isLoaded = useSelector(getIsLoaded);
  const userName = useSelector(getUserName);
  const userEmail = useSelector(getUserEmail);
  const userStatus = useSelector(getUserStatus);

  return {
    submit,
    isInvalid,
    isPristine,
    isNotFilled,
    isOperating,
    isLoading,
    isLoaded,
    userName,
    userEmail,
    userStatus,
    remove
  };
}
