import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import _trimEnd from "lodash/trimEnd";

import {
  isCurrentCoachReady,
  isCurrentCoachOperating,
  getCurrentCoach,
  getSeasonCoachTeams,
  isCurrentCoachForbidden,
  isCurrentCoachDeleting
} from "@/redux/coaches/selectors";

import { CurrentCoachLoadingRoutine } from "@/redux/coaches/routines";
import { clearCurrentCoach } from "@/redux/coaches/actions";

export default function useCurrentCoach({ seasonId, coachId }) {
  const dispatch = useDispatch();
  const isCoachReady = useSelector(isCurrentCoachReady);
  const isCoachOperating = useSelector(isCurrentCoachOperating);
  const isCoachForbidden = useSelector(isCurrentCoachForbidden);
  const isCoachDeleting = useSelector(isCurrentCoachDeleting);
  const coach = useSelector(getCurrentCoach);
  const teams = useSelector(getSeasonCoachTeams);

  const externalId = useMemo(() => (coach && _trimEnd(coach.externalId)) || "", [coach]);

  useEffect(
    () => {
      dispatch(CurrentCoachLoadingRoutine.trigger({ seasonId, id: coachId }));

      return () => dispatch(clearCurrentCoach());
    },
    [dispatch, seasonId, coachId]
  );

  return {
    isCoachReady,
    isCoachOperating,
    isCoachForbidden,
    isCoachDeleting,
    coach,
    teams,
    externalId
  };
}
