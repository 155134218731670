import React from "react";

import { GAME_EXPIRATION_DAYS } from "./constants";

import expirationIcon from "./assets/expiration-icon.svg";

function ExpiredGameMessage() {
  return (
    <div className="pog-page__expiration">
      <img className="pog-page__expiration__icon" src={expirationIcon} alt="Expiration Icon" />
      <h1 className="pog-page__expiration__title">The game has expired</h1>
      <p>
        You cannot select the Player of the Game because the game was played more than {GAME_EXPIRATION_DAYS} days ago.
      </p>
      <p>
        If you think it’s a mistake, please contact us at{" "}
        <a href="mailto:support@gameheetinc.com">support@gameheetinc.com</a>.
      </p>
    </div>
  );
}

export default ExpiredGameMessage;
