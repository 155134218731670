import { useState, useCallback } from "react";

import { formatDate, correctMonthDay } from "../utils";

export default function useBirthdateField({ setValue, value }) {
  const [date, setDate] = useState(value && value !== "" ? new Date(`${value}T00:00:00`) : null);

  const [month, setMonth] = useState(date ? date.getMonth() : null);
  const [day, setDay] = useState(date ? date.getDate() : null);
  const [year, setYear] = useState(date ? date.getFullYear() : null);

  const onDateChange = useCallback(
    ({ month, day, year }) => {
      const correctedDay = correctMonthDay({ month, day, year });

      const date =
        month !== null && correctedDay !== null && year !== null ? new Date(year, month, correctedDay) : null;

      setDate(date);
      setMonth(date ? date.getMonth() : month);
      setDay(date ? date.getDate() : correctedDay);
      setYear(date ? date.getFullYear() : year);
      setValue(date ? formatDate(date) : null);
    },
    [setDate, setMonth, setDay, setYear, setValue]
  );

  const onMonthChange = useCallback(
    ({ target: { value } }) => {
      const month = parseInt(value);

      onDateChange({ month, day, year });
    },
    [day, year, onDateChange]
  );

  const onDayChange = useCallback(
    ({ target: { value } }) => {
      const day = parseInt(value);

      onDateChange({ day, month, year });
    },
    [month, year, onDateChange]
  );

  const onYearChange = useCallback(
    ({ target: { value } }) => {
      const year = parseInt(value);

      onDateChange({ month, day, year });
    },
    [month, day, onDateChange]
  );

  const clearDate = useCallback(() => onDateChange({ month: null, day: null, year: null }), [onDateChange]);

  return {
    month: {
      value: month,
      onChange: onMonthChange
    },
    day: {
      value: day,
      onChange: onDayChange
    },
    year: {
      value: year,
      onChange: onYearChange
    },
    date,
    clearDate
  };
}
