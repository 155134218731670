import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import { loadingRoutine } from "./routines";
import actions from "./actions";
import { teams, divisions, seasons, settings } from "./reducers";

export default combineReducers({
  isLoading: handleActions(
    {
      [loadingRoutine.REQUEST]: () => true,
      [loadingRoutine.FULFILL]: () => false,
      [actions.clear]: () => false
    },
    false
  ),
  isLoaded: handleActions(
    {
      [loadingRoutine.SUCCESS]: () => true,
      [actions.clear]: () => false
    },
    false
  ),
  teams,
  divisions,
  seasons,
  settings
});
