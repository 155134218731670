import React, { Fragment } from "react";
import PropTypes from "prop-types";

import ReactDateInput from "react-datetime";
import { Label } from "reactstrap";
import "./style.scss"

function DateInput({ inputId, label, value, setValue }) {

  return (
    <Fragment>
      <Label for={inputId}>{label}</Label>
        <div className="input-container">
          <ReactDateInput
            id={inputId}
            dateFormat="YYYY-MM-DD"
            timeFormat={false}
            inputProps={{
              className: "form-control form-control-sm no-caret",
              onChange: () => {},
              placeholder: "YYYY-MM-DD"
            }}
            closeOnSelect={true}
            onChange={newDate => setValue(newDate && newDate.format("YYYY-MM-DD") || "")}
            value={value}
          />
          {value && <div className="clear-button" onClick={() => setValue("")}>X</div> }
        </div>
    </Fragment>
  );
}

DateInput.propTypes = {
  inputId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired
};

export default DateInput;
