import { put, takeLatest, all, select, takeEvery } from "redux-saga/effects";

import { loadAssociations } from "@/lib/api/associations";
import { loadLeagues } from "@/lib/api/leagues";
import { loadSeasons } from "@/lib/api/seasons";
import { loadDivisions } from "@/lib/api/divisions";
import { loadTeams } from "@/lib/api/teams";

import { fetchList } from "@/redux/api/sagas";

import {
  associationListLoadingRoutine,
  leagueListLoadingRoutine,
  seasonListLoadingRoutine,
  divisionListLoadingRoutine,
  teamListLoadingRoutine
} from "./routines";

import actions from "./actions";

import {
  getLeagueListIsLoaded,
  getSeasonListIsLoaded,
  getDivisionListIsLoaded,
  getAssociationId,
  getLeagueListIsLoading,
  getSeasonListIsLoading,
  getDivisionListIsLoading,
  getTeamListIsLoading,
  getTeamListIsLoaded
} from "./selectors";

function* associationListLoadingSaga() {
  yield put(associationListLoadingRoutine.request());

  try {
    const { ids, associations } = yield fetchList("associations", loadAssociations);

    yield put(associationListLoadingRoutine.success({ ids, associations }));
  } catch (error) {
    yield put(associationListLoadingRoutine.failure({ error }));
  } finally {
    yield put(associationListLoadingRoutine.fulfill());
  }
}

function* leagueListLoadingSaga({ payload: associationId }) {
  const isLoaded = yield select(getLeagueListIsLoaded, associationId);
  const isLoading = yield select(getLeagueListIsLoading, associationId);

  if (isLoaded || isLoading || associationId === "") {
    return;
  }

  yield put(leagueListLoadingRoutine.request({ associationId }));

  try {
    const { ids, leagues } = yield fetchList("leagues", loadLeagues, {
      associationId
    });

    yield put(leagueListLoadingRoutine.success({ associationId, ids, leagues }));
  } catch (error) {
    yield put(leagueListLoadingRoutine.failure({ error, associationId }));
  } finally {
    yield put(leagueListLoadingRoutine.fulfill({ associationId }));
  }
}

function* seasonListLoadingSaga({ payload: leagueId }) {
  const associationId = yield select(getAssociationId);
  const isLoaded = yield select(getSeasonListIsLoaded, leagueId);
  const isLoading = yield select(getSeasonListIsLoading, leagueId);

  if (isLoaded || isLoading || leagueId === "") {
    return;
  }

  yield put(seasonListLoadingRoutine.request({ leagueId }));

  try {
    const { ids, seasons } = yield fetchList("seasons", loadSeasons, {
      associationId,
      leagueId
    });

    yield put(seasonListLoadingRoutine.success({ leagueId, ids, seasons }));
  } catch (error) {
    yield put(seasonListLoadingRoutine.failure({ error, leagueId }));
  } finally {
    yield put(seasonListLoadingRoutine.fulfill({ leagueId }));
  }
}

function* divisionListLoadingSaga({ payload: seasonId }) {
  const isLoaded = yield select(getDivisionListIsLoaded, seasonId);
  const isLoading = yield select(getDivisionListIsLoading, seasonId);

  if (isLoaded || isLoading || seasonId === "") {
    return;
  }

  yield put(divisionListLoadingRoutine.request({ seasonId }));

  try {
    const { ids, divisions } = yield fetchList("divisions", loadDivisions, {
      seasonId
    });

    yield put(divisionListLoadingRoutine.success({ seasonId, ids, divisions }));
  } catch (error) {
    yield put(divisionListLoadingRoutine.failure({ error, seasonId }));
  } finally {
    yield put(divisionListLoadingRoutine.fulfill({ seasonId }));
  }
}

function* teamListLoadingSaga({ payload: divisionId }) {
  const isLoaded = yield select(getTeamListIsLoaded, divisionId);
  const isLoading = yield select(getTeamListIsLoading, divisionId);

  if (isLoaded || isLoading || divisionId === "") {
    return;
  }

  yield put(teamListLoadingRoutine.request({ divisionId }));

  try {
    const { ids, teams } = yield fetchList("teams", loadTeams, {
      divisionId
    });

    yield put(teamListLoadingRoutine.success({ divisionId, ids, teams }));
  } catch (error) {
    yield put(teamListLoadingRoutine.failure({ error, divisionId }));
  } finally {
    yield put(teamListLoadingRoutine.fulfill({ divisionId }));
  }
}

export function* userFilterFlow() {
  yield all([
    takeLatest(associationListLoadingRoutine, associationListLoadingSaga),
    takeEvery(actions.setAssociationId, leagueListLoadingSaga),
    takeEvery(actions.setLeagueId, seasonListLoadingSaga),
    takeEvery(actions.setSeasonId, divisionListLoadingSaga),
    takeEvery(actions.setDivisionId, teamListLoadingSaga)
  ]);
}
