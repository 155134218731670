import { firebase } from "@/firebase";
import { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";

import { account } from "@/redux/account/actions";

export const useFirebaseUser = () => {
    const dispatch = useDispatch();
    const [user, setUser] = useState(null);

    useEffect(() => {
        let mounted = true;

        const cancelListener = firebase.auth().onAuthStateChanged(user => {
            if (user) {
                mounted && setUser(user);
            } else {
                mounted && setUser(null)
            }
        });

        return () => {
            cancelListener();
            mounted = false;
        }
    }, [])

    useEffect(() => {
        dispatch(account.fUser.set(user));

        return () => dispatch(account.fUser.clear());
    }, [dispatch, user]);

    return {
        user
    }
}