import React from "react";

import useCurrentAssociationContext from "@/hooks/useCurrentAssociationContext";

import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";
import LeagueForm, { useLeagueFormClearing } from "@/components/LeagueForm";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import PageLoader from "@/components/PageLoader";

function NewLeague() {
  const {
    associationId,
    associationIsLoading,
    associationIsLoaded,
    associationIsForbidden,
    associationBreadcrumbs
  } = useCurrentAssociationContext();

  useLeagueFormClearing();

  return (
    <ProtectedLayout
      subject={{ type: "leagues", associationId }}
      action="create"
      className="leagues-page"
      skip={!associationIsLoaded && !associationIsForbidden}
    >
      <Head title="New League" />

      <PageLoader isLoading={associationIsLoading} isLoaded={associationIsLoaded}>
        <Breadcrumbs items={associationBreadcrumbs} lastItem={{ title: "New League" }} />

        <TitleBar title="New League" />

        <LeagueForm associationId={associationId} />
      </PageLoader>
    </ProtectedLayout>
  );
}

export default NewLeague;
