import React, { PureComponent, Fragment } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Switch, Link, Route } from "react-router-dom";
import { Nav, NavItem, NavLink } from "reactstrap";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import PageLoader from "@/components/PageLoader";
import Breadcrumbs, { BreadcrumbsProps } from "@/components/Breadcrumbs";
import Head from "@/components/Head";
import TitleBar from "@/components/TitleBar";
import AbilityButton from "@/components/AbilityButton";

import PlayersTab from "./tabs/PlayersTab";
import CoachesTab from "./tabs/CoachesTab";

class SeasonRosterIndex extends PureComponent {
  static propTypes = {
    tab: PropTypes.string.isRequired,
    seasonId: PropTypes.string.isRequired,
    isSeasonLoaded: PropTypes.bool.isRequired,
    isSeasonLoading: PropTypes.bool.isRequired,
    isSeasonForbidden: PropTypes.bool.isRequired,
    isPlayersListLoaded: PropTypes.bool.isRequired,
    isPlayersListLoading: PropTypes.bool.isRequired,
    isCoachesListLoaded: PropTypes.bool.isRequired,
    isCoachesListLoading: PropTypes.bool.isRequired,
    isMergePlayersToolEnabled: PropTypes.bool,
    isMergeCoachesToolEnabled: PropTypes.bool,
    isMergingMode: PropTypes.bool,
    season: PropTypes.shape({}),
    breadcrumbs: BreadcrumbsProps,
    loadPlayersList: PropTypes.func,
    loadCoachesList: PropTypes.func,
    clear: PropTypes.func
  };

  static defaultProps = {
    season: {},
    breadcrumbs: null,
    isMergePlayersToolEnabled: false,
    isMergeCoachesToolEnabled: false,
    isMergingMode: false,
    loadPlayersList() {},
    loadCoachesList() {},
    clear() {}
  };

  componentDidMount() {
    const {
      isPlayersListLoaded,
      isPlayersListLoading,
      isCoachesListLoaded,
      isCoachesListLoading,
      loadPlayersList,
      loadCoachesList
    } = this.props;

    if (!isPlayersListLoaded && !isPlayersListLoading) {
      loadPlayersList();
    }

    if (!isCoachesListLoaded && !isCoachesListLoading) {
      loadCoachesList();
    }
  }

  componentWillUnmount() {
    this.props.clear();
  }

  render() {
    const {
      seasonId,
      isSeasonLoaded,
      isSeasonLoading,
      isSeasonForbidden,
      season,
      breadcrumbs,
      tab,
      isMergingMode,
      isMergePlayersToolEnabled,
      isMergeCoachesToolEnabled
    } = this.props;

    return (
      <ProtectedLayout
        className="roster-page"
        subject={{ type: "roster", seasonId }}
        action="read"
        skip={!isSeasonLoaded && !isSeasonForbidden}
      >
        {isSeasonLoaded ? (
          <Fragment>
            {!isMergingMode && (
              <Fragment>
                <Head title={`${season.title} Roster`} />
                <Breadcrumbs items={breadcrumbs} />
                <TitleBar title={`${season.title} Roster`}>
                  <AbilityButton
                    subject={{ type: "roster", seasonId }}
                    action="import"
                    color="success"
                    tag={Link}
                    to={`/seasons/${seasonId}/roster/import-from-csv`}
                    size="sm"
                    outline
                  >
                    Import from CSV
                  </AbilityButton>
                </TitleBar>

                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: tab === "players"
                      })}
                      tag={Link}
                      to={`/seasons/${seasonId}/roster/players`}
                      disabled={isMergeCoachesToolEnabled}
                    >
                      Players
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: tab === "coaches"
                      })}
                      tag={Link}
                      to={`/seasons/${seasonId}/roster/coaches`}
                      disabled={isMergePlayersToolEnabled}
                    >
                      Coaches
                    </NavLink>
                  </NavItem>
                </Nav>
              </Fragment>
            )}

            <Switch>
              <Route path={`/seasons/:seasonId/roster/players`} component={PlayersTab} />

              <Route path={`/seasons/:seasonId/roster/coaches`} component={CoachesTab} />
            </Switch>
          </Fragment>
        ) : (
          <PageLoader isLoading={isSeasonLoading} />
        )}
      </ProtectedLayout>
    );
  }
}

export default SeasonRosterIndex;
