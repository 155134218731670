import React from "react";
import PropTypes from "prop-types";

import { Switch, Redirect } from "react-router-dom";

import useCurrentSeason from "@/hooks/useCurrentSeason";
import CurrentSeasonContext from "@/contexts/CurrentSeasonContext";
import useCurrentSeasonClearing from "@/hooks/useCurrentSeasonClearing";
import { useDivisionsListClearing } from "@/components/DivisionDropdownField";
import { useCompletedGamesFilterClearing } from "@/components/CompletedGamesFilter";
import { useCompletedGamesListClearing } from "@/components/CompletedGamesList";
import { useScheduledGamesFilterClearing } from "@/components/ScheduledGamesFilter";
import { useScheduledGamesListClearing } from "@/components/ScheduledGamesList";

import EnsureCurrentSeasonActive from "@/layouts/EnsureCurrentSeasonActive";
import LoggedInRoute from "@/containers/LoggedInRoute";

import GamesIndex from "./pages/GamesIndex";
import NewScheduledGame from "./pages/NewScheduledGame";
import EditScheduledGame from "./pages/EditScheduledGame";
import ImportScheduledGames from "./pages/ImportScheduledGames";
import GameScope from "./GameScope";

function GamesRoute({ match: { path, params, url } }) {
  const { seasonId } = params;
  const currentSeason = useCurrentSeason(seasonId);

  useCurrentSeasonClearing();
  useDivisionsListClearing();
  useCompletedGamesFilterClearing();
  useCompletedGamesListClearing();
  useScheduledGamesFilterClearing();
  useScheduledGamesListClearing();

  return (
    <CurrentSeasonContext.Provider value={{ ...currentSeason, seasonId }}>
      <EnsureCurrentSeasonActive>
        <Switch>
          <LoggedInRoute path={`${path}/completed/:gameId`} component={GameScope} />

          <LoggedInRoute path={`${path}/scheduled/new`} component={NewScheduledGame} />

          <LoggedInRoute path={`${path}/scheduled/csv-import`} component={ImportScheduledGames} />

          <LoggedInRoute path={`${path}/scheduled/:gameId/edit`} component={EditScheduledGame} />

          <LoggedInRoute path={`${url}/:status`} component={GamesIndex} />

          <Redirect to={`${url}/completed`} />
        </Switch>
      </EnsureCurrentSeasonActive>
    </CurrentSeasonContext.Provider>
  );
}

GamesRoute.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      seasonId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  clear: PropTypes.func
};

export default GamesRoute;
