import React, { Fragment, useCallback } from "react";
import PropTypes from "prop-types";

import { Table, Button } from "reactstrap";

import Role from "./Role";
import useRoles from "./hooks/useRoles";
import EmptyListMessage from "@/components/EmptyListMessage";

import "./Table.scss";

function RolesTable({ editable }) {
  const { roles, initRoleForm, areLoading } = useRoles();
  const showNewRoleForm = useCallback(() => initRoleForm(), [initRoleForm]);

  return (
    <Fragment>
      {roles.length > 0 ? (
        <Table striped borderless>
          <thead>
            <tr>
              <th className="invitation-form__roles-table__role" scope="col">
                Role
              </th>
              <th scope="col">
                <span className="visuallyhidden">Actions</span>
              </th>
              <th scope="col" className="invitation-form__roles-table__level">
                Level
              </th>
            </tr>
          </thead>
          <tbody>
            {roles.map(({ id, ...rest }) => (
              <Role editable={editable} key={id} editRole={initRoleForm} {...rest} />
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyListMessage>There are no roles assigned to invitation</EmptyListMessage>
      )}

      {editable && (
        <Button block outline color="success" onClick={showNewRoleForm} disabled={areLoading} className="mt-3">
          + Add Role
        </Button>
      )}
    </Fragment>
  );
}

RolesTable.propTypes = {
  editable: PropTypes.bool
};

RolesTable.defaultProps = {
  editable: false
};

export default RolesTable;
