import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./EmptyListMessage.scss";

const EmptyListMessage = ({ children, className }) => {
  return <div className={classNames("empty-list-message", className)}>{children}</div>;
};

EmptyListMessage.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

EmptyListMessage.defaultProps = {};

export default EmptyListMessage;
