import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import actions from "./actions";
import { copyTeamsRoutine } from "./routines";

import { selectedTeams } from "./reducers";

const openModal = () => true;
const closeModal = () => false;

export default combineReducers({
  isModalOpen: handleActions({ [actions.modal.open]: openModal, [actions.modal.close]: closeModal }, false),
  seasonId: handleActions(
    {
      [actions.modal.open]: (state, { payload: { seasonId } }) => seasonId,
      [actions.clear]: () => null
    },
    null
  ),
  divisionId: handleActions(
    {
      [actions.modal.open]: (state, { payload: { divisionId } }) => divisionId,
      [actions.clear]: () => null
    },
    null
  ),
  stage: handleActions(
    {
      [copyTeamsRoutine.TRIGGER]: () => "copying",
      [copyTeamsRoutine.SUCCESS]: () => "completed",
      [copyTeamsRoutine.FAILURE]: () => "failed",
      [actions.clear]: () => "selectTeams"
    },
    "selectTeams"
  ),
  selectedTeams
});
