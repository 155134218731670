import React from "react";
import PropTypes from "prop-types";

function TextPlaceholder({ children, placeholder }) {
  if (children === "") {
    return <span className="text-secondary">{placeholder}</span>;
  }

  return children;
}

TextPlaceholder.propTypes = {
  children: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired
};

export default TextPlaceholder;
