import { all, put, call, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";

import { createDivision, updateDivision, deleteDivision } from "@/lib/api/divisions";

import { responseErrorsFullMessages } from "@/lib/api/utils";
import { gamesheetAPIRequest } from "@/redux/api/sagas";

import { FIELDS_MAPPING } from "@/components/DivisionForm";

import { submittingRoutine, deletingRoutine } from "./routines";

import { standardizePenaltyDurations } from "@/utils/standardizePenaltySettings";

function* submittingSaga({ payload }) {
  const { seasonId, divisionId, values, goBack, history } = payload;

  const { title, externalId, generalSettings, statsSettings, penaltySettings, usingSeasonSettings } = values;

  // remove duplicate penalty durations, and generate penalty lengths from them
  const { penaltyDurations, penaltyLengths } = standardizePenaltyDurations(penaltySettings);

  const attributes = {
    title,
    externalId,
    settings: usingSeasonSettings
      ? {}
      : {
          ...statsSettings,
          ...generalSettings,
          penaltyDurations: penaltyDurations,
          // use updated penaltyLengths only if we have penaltyDurations, to maintain existing data for backwards compatibility
          penaltyLengths: penaltyDurations.length > 0 ? penaltyLengths : penaltySettings.penaltyLengths,
        }
  };

  yield put(submittingRoutine.request());

  try {
    const service = divisionId ? updateDivision : createDivision;

    yield call(
      gamesheetAPIRequest,
      service,
      {
        attributes,
        identity: { type: "divisions", id: divisionId },
        seasonId
      },
      true
    );

    yield put(submittingRoutine.success());
    yield goBack ?  history.push(`/seasons/${seasonId}/divisions`) : history.push(`/seasons/${seasonId}/divisions/new`);
  } catch (error) {
    const { response } = error;
    const validationErrors = response ? responseErrorsFullMessages(response, FIELDS_MAPPING) : {};

    yield put(submittingRoutine.failure({ error, validationErrors }));
  } finally {
    yield put(submittingRoutine.fulfill());
  }
}

function* deletingSaga({ payload: { seasonId, divisionId } }) {
  yield put(deletingRoutine.request());

  try {
    yield gamesheetAPIRequest(deleteDivision, {
      identity: { type: "divisions", id: divisionId },
      seasonId
    });

    yield put(deletingRoutine.success());
    yield put(push(`/seasons/${seasonId}/divisions`));
  } catch (e) {
    yield put(deletingRoutine.failure({ error: e }));
  } finally {
    yield put(deletingRoutine.fulfill());
  }
}

export function* divisionFormFlow() {
  yield all([takeLatest(submittingRoutine, submittingSaga), takeLatest(deletingRoutine, deletingSaga)]);
}
