import { createActions } from "redux-actions";

const { teams: actions } = createActions({
  TEAMS: {
    LIST: {
      CLEAR: undefined
    },
    CURRENT: {
      CLEAR: undefined
    },
    CLEAR_CREATED: undefined
  }
});

export default actions;
