import { useSelector } from "react-redux";

import { getAbilityResourcesListIsPrefetched, getAbilityResourcesListIsPrefetching } from "@/redux/ability/selectors";

export default function useAbilityResourcesPrefetching() {
  const isListPrefetched = useSelector(getAbilityResourcesListIsPrefetched);
  const isListPrefetching = useSelector(getAbilityResourcesListIsPrefetching);

  return {
    isListPrefetched,
    isListPrefetching
  };
}
