import { connect } from "react-redux";

import {
  getSeasonIpadKeysManagerTotalKeysAmount,
  getSeasonIpadKeysManagerFilter
} from "@/redux/seasonIpadKeysManager/selectors";

import { setSeasonIpadKeysManagerFilter } from "@/redux/seasonIpadKeysManager/actions";

import SeasonIpadKeysManagerKeyFilter from "./KeyFilter";

const mapStateToProps = state => ({
  totalKeysAmount: getSeasonIpadKeysManagerTotalKeysAmount(state),
  filter: getSeasonIpadKeysManagerFilter(state)
});

const mapDispatchToProps = dispatch => ({
  setFilter: filter => dispatch(setSeasonIpadKeysManagerFilter(filter))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SeasonIpadKeysManagerKeyFilter);
