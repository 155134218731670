import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import React, { useState } from "react";


export default function useConfirmDeleteModal({ onDelete }){

  // const  = props;
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const handleDelete = () => {
      onDelete();
      toggle();
  };

  function DeleteModal({ areActionsEnabled, resourceTitle, resourceType }){
    return (
    <div >
      <Modal className="confirm-deletion-modal" isOpen={isOpen} toggle={toggle} centered> 
        <ModalHeader>Confirmation</ModalHeader>
        <ModalBody>
            <p>
              Are you sure you want to delete <span className="text-lowercase">{resourceType}</span>{" "}
              <strong>{resourceTitle}</strong>?
            </p>
        </ModalBody>

        <ModalFooter>
          <div className="d-flex w-100">
            <div className="mr-auto">
              <Button type="button" color="secondary" onClick={toggle} disabled={areActionsEnabled}>
                Cancel
              </Button>
            </div>

            <div>
              <Button outline type="button" color="danger" onClick={handleDelete} disabled={areActionsEnabled}>
                Delete
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
    
    )
  }
  return {
    isOpen, 
    toggle, 
    DeleteModal
  };
}