import { handleActions } from "redux-actions";

import { togglePlayerTeamFormDisplay, changePlayerTeamFormValues, changePlayerTeamFormTeamId } from "../actions";

import { AddPlayerToTeamRoutine } from "../routines";

const defaultState = {
  display: false,
  isOperating: false,
  values: {
    teamId: "",
    jersey: "",
    duty: "",
    position: "",
    affiliated: false
  }
};

const triggerDisplay = state => ({ ...defaultState, display: !state.display });

const changeValues = (state, { payload }) => ({
  ...state,
  values: { ...state.values, ...payload }
});

const changeTeamId = (state, { payload }) => ({
  ...state,
  values: payload.id !== "" ? { ...state.values, teamId: payload.id } : defaultState.values
});

const addingRequest = state => Object.assign({}, state, { isOperating: true });

const addingSuccess = state => Object.assign({}, defaultState);

const addingFulfill = state => Object.assign({}, state, { isOperating: false });

export default handleActions(
  {
    [togglePlayerTeamFormDisplay]: triggerDisplay,
    [changePlayerTeamFormValues]: changeValues,
    [changePlayerTeamFormTeamId]: changeTeamId,
    [AddPlayerToTeamRoutine.REQUEST]: addingRequest,
    [AddPlayerToTeamRoutine.SUCCESS]: addingSuccess,
    [AddPlayerToTeamRoutine.FULFILL]: addingFulfill
  },
  defaultState
);
