import { connect } from "react-redux";

import { getRefereesMergeToolSeasonId } from "@/redux/referees/selectors";

import { refereesActions } from "@/redux/referees/actions";
import { loadRefereesListRoutine } from "@/redux/referees/routines";

import RefereesListMergeToolFailure from "./Failure";

const mapStateToProps = state => ({
  seasonId: getRefereesMergeToolSeasonId(state)
});

const mapDispatchToProps = dispatch => ({
  exitMergeMode: seasonId => {
    dispatch(loadRefereesListRoutine({ seasonId }));
    dispatch(refereesActions.list.mergeTool.disable());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RefereesListMergeToolFailure);
