import { connect } from "react-redux";

import {
  getGamesheetHistoryIsModalOpen,
  getGamesheetHistoryIsWorking,
  getGamesheetHistoryPreviewURL
} from "@/redux/gamesheet/selectors";

import { hideGamesheetHistory } from "@/redux/gamesheet/actions";

import Modal from "./Modal";

const mapStateToProps = state => ({
  isOpen: getGamesheetHistoryIsModalOpen(state),
  isWorking: getGamesheetHistoryIsWorking(state),
  previewUrl: getGamesheetHistoryPreviewURL(state)
});

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(hideGamesheetHistory())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Modal);
