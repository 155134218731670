import React, {useEffect} from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";

import Login from "@/containers/Login";

import useLoggedInRoute from "@/hooks/useLoggedIn";
import useAbilityResourcesPrefetching from "@/hooks/useAbilityResourcesPrefetching";

import SplashScreen from "@/components/SplashScreen";
function LoggedInRoute(props) {
  
  const { isLoggedIn } = useLoggedInRoute();
  const { isListPrefetched } = useAbilityResourcesPrefetching();
  const { component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? isListPrefetched ? <Component {...rest} {...props} /> : <SplashScreen /> : <Login />
      }
    />
  );
}

LoggedInRoute.propTypes = {
  component: PropTypes.elementType
};

export default LoggedInRoute;
