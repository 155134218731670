import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Table } from "reactstrap";

import BillingReportTeamGamesStatsTeam from "./Team";

class Season extends PureComponent {
  static propTypes = {
    seasonTitle: PropTypes.string.isRequired,
    teams: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired
      })
    )
  };

  static defaultProps = {
    teams: []
  };

  render() {
    const { seasonTitle, teams } = this.props;

    return (
      <div className="billing-report__subsection">
        <h3 className="billing-report__section-title">{seasonTitle} Home Games Played Per Team</h3>

        <Table striped borderless className="billing-report__table">
          <thead>
            <tr>
              <th>Team</th>
              <th>Division</th>
              <th>Regular Season</th>
              <th>Playoff</th>
              <th>Exhibition</th>
              <th>Tournament</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {teams.map((team, index) => (
              <BillingReportTeamGamesStatsTeam key={team.id} {...team} />
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default Season;
