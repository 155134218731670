import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import actions from "@/redux/userVerificationNotification/actions";

import { getNotification } from "@/redux/userVerificationNotification/selectors";

export default function useUserVerificationNotification() {
  const dispatch = useDispatch();
  const { display, userEmail, title } = useSelector(getNotification);
  const hide = useCallback(() => dispatch(actions.hide()), [dispatch]);

  useEffect(
    () => {
      return () => {
        if (display) {
          hide();
        }
      };
    },
    [hide, display]
  );

  return { display, userEmail, title, hide };
}
