import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { coachPenaltyReportLoadingRoutine } from "@/redux/coachPenaltyReport/routines";
import selectors from "@/redux/coachPenaltyReport/selectors";
import actions from "@/redux/coachPenaltyReport/actions";

const { getCoachPenaltyReportIsLoaded, getCoachPenaltyReportMember, getCoachPenaltyReportIsForbidden } = selectors;

export default function usePenaltyReport(externalId) {
  const dispatch = useDispatch();
  const coach = useSelector(getCoachPenaltyReportMember);
  const isLoaded = useSelector(getCoachPenaltyReportIsLoaded);
  const isForbidden = useSelector(getCoachPenaltyReportIsForbidden);

  useEffect(
    () => {
      dispatch(coachPenaltyReportLoadingRoutine({ externalId }));

      return () => dispatch(actions.report.clear());
    },
    [dispatch, externalId]
  );

  return {
    coach,
    isLoaded,
    isForbidden
  };
}
