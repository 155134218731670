export const GAME_FLAGGING_CRITERIONS = {
  scorekeeperTimeoutNote: "Timeout",
  scorekeeperInjuryNote: "Injury timeout",
  scorekeeperGeneralNote: "General note by scorekeeper",
  refereePenaltyNote: "Penalty note by referee",
  refereeGameNote: "Game note by referee",
  addedAtGameTime: "Player added at game time",
  penalty: "Penalty",
  suspensionServed: "Suspension served",
  suspensionLifted: "Player or coach returns from suspension",
  unlocked: "Game was unlocked"
};

export const SOCCER_GAME_FLAGGING_CRITERIONS = {
  scorekeeperTimeoutNote: "Timeout",
  scorekeeperInjuryNote: "Injury timeout",
  scorekeeperGeneralNote: "General note by scorekeeper",
  refereePenaltyNote: "Infraction note by referee",
  refereeGameNote: "Game note by referee",
  addedAtGameTime: "Player added at game time",
  penalty: "Infraction",
  suspensionServed: "Suspension served",
  suspensionLifted: "Player or coach returns from suspension",
  unlocked: "Game was unlocked"
};

export const STALE_FLAGGING_CRITERIONS = {
  nonAffiliatedPlayer: "Non-affiliated player"
};

export const ALL_FLAGGING_CRITERIONS = {
  ...STALE_FLAGGING_CRITERIONS,
  ...GAME_FLAGGING_CRITERIONS
};

export const DEFAULT_GAME_FLAGGING_CRITERIA = Object.keys(GAME_FLAGGING_CRITERIONS).reduce(
  (result, flag) => ({ ...result, [flag]: false }),
  {}
);
