import buildSeasonMember from "./buildSeasonMember";

export default function reduceCoaches(state, { payload: { game } }) {
  const seasonCoaches = (game.coaches || []).reduce((coaches, { id, ...player }) => ({ ...coaches, [id]: player }), {});
  const gameRoster = [...(game.visitorRoster.coaches || []), ...(game.homeRoster.coaches || [])];

  return gameRoster.reduce(
    (coaches, { id, ...rosterCoach }) => ({
      ...coaches,
      [id]: buildSeasonMember(seasonCoaches[id], rosterCoach)
    }),
    {}
  );
}
