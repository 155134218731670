import { useSelector } from "react-redux";

import { getIsLoaded, getIsLoading } from "@/redux/teamPickerField";

export default function useTeamPickerFieldMeta() {
  const isLoaded = useSelector(getIsLoaded);
  const isLoading = useSelector(getIsLoading);

  return {
    isLoaded,
    isLoading
  };
}
