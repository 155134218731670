import React from "react";

import { Button } from "reactstrap";

import TitleBar from "@/components/TitleBar";
import PageLoader from "@/components/PageLoader";
// import PenaltyTypeReportDataSources from "@/components/ReportDataSourcesList";

import PenaltyTypeReportSummaryStats from "./SummaryStats";
import PenaltyTypeReportAssessedPenalties from "./AssessedPenalties";

import useReport from "./hooks/useReport";

import "./Report.scss";

function PenaltyTypeReport() {
  const { isLoading, isLoaded, summaryStats, assessedPenalties, triggerPrint } = useReport();

  return (
    <PageLoader isLoading={isLoading} isLoaded={isLoaded}>
      <TitleBar title="Penalty Type Report">
        <Button outline color="primary" size="sm" onClick={triggerPrint}>
          Print
        </Button>
      </TitleBar>

      <PenaltyTypeReportSummaryStats {...summaryStats} />
      <PenaltyTypeReportAssessedPenalties assessedPenalties={assessedPenalties} />
      {/* <PenaltyTypeReportDataSources /> */}

      {/* <p className="text-secondary">
        * Data presented in this report is filtered by your user permissions.
        Be aware that a user viewing this report with different permissions
        may see different results.
      </p> */}
    </PageLoader>
  );
}

export default PenaltyTypeReport;
