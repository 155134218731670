import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Input } from "reactstrap";

class PenaltyCodeInput extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    codes: PropTypes.arrayOf(PropTypes.shape({ code: PropTypes.string.isRequired })),
    onChange: PropTypes.func
  };

  static defaultProps = {
    codes: [],
    onChange() {}
  };

  render() {
    const { id, name, value, codes, onChange } = this.props;

    return (
      <Input type="select" id={id} name={name} value={value} onChange={onChange}>
        <option disabled />
        {codes.map(({ code, label }) => (
          <option key={code} value={code}>
            {code} - {label}
          </option>
        ))}
      </Input>
    );
  }
}

export default PenaltyCodeInput;
