import { handleActions } from "redux-actions";

import { accountLoadingRoutine } from "../routines";
import actions from "../actions";

const initialState = {
  userName: "",
  userEmail: ""
};

function reduceLoadingSuccess(state, { payload: { account } }) {
  const { firstName, lastName, email } = account;

  return { userName: [firstName, lastName].join(" "), userEmail: email };
}

export default handleActions(
  {
    [accountLoadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
