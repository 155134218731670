import React, { useCallback, Fragment } from "react";
import PropTypes from "prop-types";

import { Label, Input } from "reactstrap";
import { RegionDropdown } from "react-country-region-selector";

function ProvinceField({ value, setValue, country }) {
  const onChange = useCallback(({ target: { value } }) => setValue(value), [setValue]);

  return (
    <Fragment>
      <Label for="playerProvince">State / Province</Label>
      {country === "US" || country === "CA" ? (
        <RegionDropdown
          countryValueType="short"
          id="playerProvince"
          name="province"
          value={value}
          country={country}
          className="form-control"
          defaultOptionLabel=""
          onChange={setValue}
        />
      ) : (
        <Input type="text" name="province" id="playerProvince" value={value} onChange={onChange} />
      )}
    </Fragment>
  );
}

ProvinceField.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  country: PropTypes.string.isRequired
};

export default ProvinceField;
