import { useSelector } from "react-redux";

import {
  getUsers,
  getIsLoading,
  getIsLoaded,
  getIsAppending,
  getTotalCount,
  getFilteredCount
} from "@/redux/userList/selectors";

export default function useUserListSelector() {
  const users = useSelector(getUsers);
  const isLoading = useSelector(getIsLoading);
  const isLoaded = useSelector(getIsLoaded);
  const isAppending = useSelector(getIsAppending);
  const totalCount = useSelector(getTotalCount);
  const filteredCount = useSelector(getFilteredCount);

  return {
    users,
    isLoading,
    isLoaded,
    isAppending,
    totalCount,
    filteredCount
  };
}
