import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

import ProtectedLayout from "@/layouts/ProtectedLayout";

import PageLoader from "@/components/PageLoader";
import Head from "@/components/Head";
import Breadcrumbs, { BreadcrumbsProps } from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";
import GameForm from "@/components/GameForm";
import NotFound from "@/components/NotFound";

class GamesEdit extends PureComponent {
  static propTypes = {
    seasonId: PropTypes.string.isRequired,
    gameId: PropTypes.string.isRequired,
    seasonIsLoaded: PropTypes.bool,
    seasonIsLoading: PropTypes.bool,
    formIsLoaded: PropTypes.bool,
    formIsLoading: PropTypes.bool,
    formIsForbidden: PropTypes.bool,
    breadcrumbs: BreadcrumbsProps,
    loadGameForm: PropTypes.func,
    clearForm: PropTypes.func
  };

  static defaultProps = {
    seasonIsLoaded: false,
    seasonIsLoading: false,
    formIsLoaded: false,
    formIsLoading: false,
    formIsForbidden: false,
    breadcrumbs: null,
    loadGameForm() {},
    clearForm() {}
  };

  componentDidMount() {
    const { seasonId, gameId, loadGameForm } = this.props;

    loadGameForm({ seasonId, id: gameId });
  }

  componentWillUnmount() {
    this.props.clearForm();
  }

  render() {
    const {
      seasonIsLoading,
      seasonIsLoaded,
      formIsLoaded,
      formIsLoading,
      formIsForbidden,
      breadcrumbs,
      seasonId,
      gameId
    } = this.props;

    return (
      <ProtectedLayout subject={{ type: "games", id: gameId }} action="update" skip={!formIsLoaded && !formIsForbidden}>
        <PageLoader isLoading={seasonIsLoading} isLoaded={seasonIsLoaded}>
          {seasonIsLoaded ? (
            <Fragment>
              <Head title="Edit Game" />

              <Breadcrumbs items={breadcrumbs} lastItem={"Edit Game"} />

              <TitleBar title="Edit Game">
                <Button
                  outline
                  color="success"
                  size="sm"
                  tag={Link}
                  to={`/seasons/${seasonId}/games/completed/${gameId}`}
                >
                  View Score Sheet
                </Button>
              </TitleBar>

              {formIsLoaded ? (
                <GameForm seasonId={seasonId} gameId={gameId} />
              ) : (
                <PageLoader isLoading={formIsLoading} />
              )}
            </Fragment>
          ) : (
            <NotFound />
          )}
        </PageLoader>
      </ProtectedLayout>
    );
  }
}

export default GamesEdit;
