import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getSelectedAssociations,
  getLeagueList,
  getLeagueListIsLoaded,
  getLeagueListIsLoading,
  getRoleIsGlobal,
  getSelectedLeagues // New selector to get selected leagues
} from "@/redux/roleForm/selectors";

import actions from "@/redux/roleForm/actions";

export default function useLeagueSelect() {
  const selectedAssociations = useSelector(getSelectedAssociations);
  const options = useSelector(state => getLeagueList(state, selectedAssociations));
  const selectedLeagues = useSelector(getSelectedLeagues); // Fetch selected leagues
  const roleIsGlobal = useSelector(getRoleIsGlobal);
  const isLoaded = useSelector(state => getLeagueListIsLoaded(state, selectedAssociations));
  const isLoading = useSelector(state => getLeagueListIsLoading(state, selectedAssociations));
  const isOptionSelected = selectedLeagues.length > 0; // Check if any option is selected
  const totalItemsCount = options.reduce((count, group) => count + group.items.length, 0); // Flatten options to get a count of all items across groups
  const isAllOptionsSelected = totalItemsCount > 0 && selectedLeagues.length === totalItemsCount;
  const dispatch = useDispatch();

  const toggleSelected = useCallback(
    ({ id, ...rest }) => {
      const isSelected = selectedLeagues.includes(id); // Check if league is selected
      const action = isSelected ? actions.leagueList.deselect : actions.leagueList.select;

      dispatch(action({ id, ...rest }));
    },
    [dispatch, selectedLeagues] // Include selectedLeagues in dependencies
  );

  return {
    options,
    isLoaded,
    isLoading,
    isEnabled: !roleIsGlobal,
    toggleSelected,
    selectedLeagues, // Return selected leagues for UI rendering if needed
    isOptionSelected, // Pass this value to indicate if any items are selected
    isAllOptionsSelected
  };
}
