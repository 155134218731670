import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormGroup, Input, FormFeedback } from "reactstrap";

import useJerseyInput from "./useJerseyInput";
import normalizeJersey from "./normalizeJersey";

function JerseyInput(props) {
  const { player, team, refreshJerseyValidity, onChange } = props;
  const { normJerseysPlaying, addError, removeError } = useJerseyInput({
    team
  });
  const key = `${team}-player-jersey-${player.id}`;

  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const getError = newNumber => {
    if (player.status === "playing") {
      if (!newNumber || newNumber.length < 1 || 2 < newNumber.length || !/^\d+$/.test(newNumber)) {
        return {
          hasError: true,
          shortMsg: "",
          longMsg: `${player.firstName} ${player.lastName} on the ${team} team has an invalid jersey number`
        };
      } else {
        const newNorm = normalizeJersey(newNumber);
        const oldNorm = normalizeJersey(player.number);
        const existing = normJerseysPlaying.filter(num => num === newNorm).length;
        if ((newNorm !== oldNorm && existing >= 1) || existing >= 2) {
          return {
            hasError: true,
            shortMsg: "Duplicate",
            longMsg: `${player.firstName} ${
              player.lastName
            } on the ${team} team has the same jersey number as another player`
          };
        }
      }
    }
    return { hasError: false, shortMsg: "", longMsg: "" };
  };

  useEffect(() => {
    if (refreshJerseyValidity) {
      const error = getError(player.number);
      if (error.hasError) {
        setIsValid(false);
        setErrorMessage(error.shortMsg);
        addError({ key, msg: error.longMsg });
      } else {
        setIsValid(true);
        removeError({ key });
      }
    }
  });

  return (
    <FormGroup className="col-md-1">
      <Input
        type="jersey"
        value={player.number}
        name="number"
        invalid={!isValid}
        onChange={e => onChange(player.id, e)}
      />
      {!isValid &&
        errorMessage !== "" && (
          <FormFeedback valid={false} tooltip>
            {errorMessage}
          </FormFeedback>
        )}
    </FormGroup>
  );
}

JerseyInput.propTypes = {
  player: PropTypes.shape({}).isRequired,
  team: PropTypes.oneOf(["home", "visitor"]).isRequired,
  refreshJerseyValidity: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default JerseyInput;
