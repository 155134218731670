import { connect } from "react-redux";

import PlayersListSearchBar from "./SearchBar";

import {
  getSeasonRosterPlayersListSearchNextQuery,
  getSeasonRosterPlayersListSearchCurrentQuery,
  getSeasonRosterPlayersListSearchQueryIsDirty,
  getSeasonRosterPlayersListIsLoading
} from "@/redux/seasonRoster/selectors";

import { seasonRosterActions } from "@/redux/seasonRoster/actions";

const mapStateToProps = state => ({
  nextQuery: getSeasonRosterPlayersListSearchNextQuery(state),
  currentQuery: getSeasonRosterPlayersListSearchCurrentQuery(state),
  isDirty: getSeasonRosterPlayersListSearchQueryIsDirty(state),
  isLoading: getSeasonRosterPlayersListIsLoading(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setQuery: query => dispatch(seasonRosterActions.players.search.setNextQuery(query)),
  commit: () => dispatch(seasonRosterActions.players.search.commit({ seasonId: ownProps.seasonId })),
  clear: () => dispatch(seasonRosterActions.players.search.clear({ seasonId: ownProps.seasonId }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayersListSearchBar);
