import React, { Fragment } from "react";
import PropTypes from "prop-types";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";
import UserForm from "@/components/UserForm";
import RoleForm from "@/components/RoleForm";

import PageLoader from "@/components/PageLoader";
import UserVerificationNotification from "@/components/UserVerificationNotification";

import useUserForm from "../hooks/useUserForm";
import useRoleForm from "../hooks/useRoleForm";

function UsersEdit({ match: { params } }) {
  const { userId } = params;

  const { userName, isLoading, isLoaded } = useUserForm(userId);
  const { roleFormIsVisible, toggleRole, removeRole } = useRoleForm();

  return (
    <ProtectedLayout subject="users" action="update">
      <Head title="Edit User" />

      <PageLoader isLoading={isLoading} isLoaded={isLoaded}>
        {isLoaded && <Breadcrumbs items={[{ title: "Users", url: "/users" }, { title: userName }]} />}

        {roleFormIsVisible ? (
          <RoleForm onSubmit={toggleRole} onRemove={removeRole} />
        ) : (
          <Fragment>
            <TitleBar title="Edit User" />
            <UserForm userId={userId} />
          </Fragment>
        )}
      </PageLoader>

      <UserVerificationNotification />
    </ProtectedLayout>
  );
}

UsersEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default UsersEdit;
