import _orderBy from "lodash/orderBy";
import moment from "moment";
import { getOffset } from "@/utils/timezones";

const selectRoot = state => state.scheduledGamesCSVImportWizard;

export const getMeta = state => selectRoot(state).meta;

export const getInvalidRows = state => {
  const { failedRecords } = selectRoot(state);

  return _orderBy(
    failedRecords.map(({ lineNumber, validationErrors, ...record }) => ({
      record,
      lineNumber,
      validationErrors
    })),
    "lineNumber",
    "asc"
  );
};

export const getRecords = state => selectRoot(state).records;

export const getNumRecords = state => getRecords(state).records.length;

export const getNumRecordsToInsert = state => {
  return getRecords(state).records.filter(({ id }) => !id || id === "" || id === 0).length;
}

export const getNumRecordsToUpdate = state => {
  return getRecords(state).records.filter(({ id }) => !!id && id !== "" && id !== 0).length;
}

export const getCreated = state => getRecords(state).created;

export const getUpdated = state => getRecords(state).updated;

export const getImportedSuccess = state => getCreated(state) + getUpdated(state);

export const getImportedFailure = state => getRecords(state).failed;

export const getFormattedGames = state => {
  const records = getRecords(state).records;
  return records.map(record => {
    const scheduledStartTime =  `${record.scheduledStartTime}T${record.startTime}:00Z`;
    const scheduledEndTime = (() => {
      const start = new Date(scheduledStartTime);
      const end = new Date(`${record.scheduledStartTime}T${record.endTime}:00Z`);
      if (end < start) {
        end.setDate(end.getDate() + 1);
      }
      return moment(end).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
    })();

    return {
      id: Number(record.id),
      gameType: record.gameType,
      homeDivisionId: Number(record.home.division),
      visitorDivisionId: Number(record.visitor.division),
      homeId: Number(record.home.team),
      visitorId: Number(record.visitor.team),
      number: record.number,
      location: record.location,
      scorekeeper: record.scorekeeper,
      timeZoneOffset: getOffset(record.timeZone, record.scheduledStartTime, record.startTime) || 0,
      timeZoneName: record.timeZone,
      scheduledStartTime,
      scheduledEndTime,
      data: {
        broadcaster: record.broadcastProvider,
      },
      status: record.status
    }
  });
}
