import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./DashboardFormFooter.scss";

const DashboardFormFooter = ({ className, children }) => {
  return <div className={classNames("dashboard-form-footer form-footer", className)}>{children}</div>;
};

DashboardFormFooter.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

DashboardFormFooter.defaultProps = {
  className: "",
  children: null
};

export default DashboardFormFooter;
