import { handleActions } from "redux-actions";

import actions from "./actions";
import { loadingRoutine } from "./routines";

const initialState = {
  isLoading: false,
  isLoaded: false,
  divisions: []
};

function reduceLoadingRequest(state) {
  return { ...state, isLoading: true };
}

function reduceLoadingSuccess(state, { payload: divisions }) {
  return {
    ...state,
    divisions: divisions.map(({ id, title }) => ({ id, title })),
    isLoaded: true
  };
}

function reduceLoadingFulfill(state) {
  return { ...state, isLoading: false };
}

export default handleActions(
  {
    [loadingRoutine.REQUEST]: reduceLoadingRequest,
    [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [loadingRoutine.FULFILL]: reduceLoadingFulfill,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
