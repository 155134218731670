import React, { Fragment } from "react";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";
import InvitationForm from "@/components/InvitationForm";
import RoleForm from "@/components/RoleForm";

import useInvitationForm from "../hooks/useInvitationForm";
import useRoleForm from "../hooks/useRoleForm";

function InvitationsNew() {
  useInvitationForm();

  const { roleFormIsVisible, toggleRole, removeRole } = useRoleForm();

  return (
    <ProtectedLayout className="invitations-page" subject={{ type: "invitations" }} action="create">
      <Head title="New Invitation" />

      <Breadcrumbs items={[{ title: "Invitations", url: "/invitations" }]} />

      {roleFormIsVisible ? (
        <RoleForm onSubmit={toggleRole} onRemove={removeRole} />
      ) : (
        <Fragment>
          <TitleBar title="New Invitation" />
          <InvitationForm />
        </Fragment>
      )}
    </ProtectedLayout>
  );
}

export default InvitationsNew;
