import _camelCase from "lodash/camelCase";
import React, { Fragment } from "react";
import PropTypes from "prop-types";

import ReactDateInput from "react-datetime";
import { Label } from "reactstrap";

import { actions } from "@/redux/completedGamesFilter";

import useDateInput from "./hooks/useDateInput";
import "./CompletedGamesFilter.scss"

function DateInput({ label, name }) {
  const action = actions[_camelCase(`set_${name}`)];
  const { inputId, setValue, value, clearDateValue } = useDateInput({ name, action });

  return (
    <Fragment>
      <Label for={inputId}>{label}</Label>
        <div className="input-container">
          <ReactDateInput
            name={name}
            id={inputId}
            dateFormat="YYYY-MM-DD"
            timeFormat={false}
            inputProps={{
              className: "form-control form-control-sm no-caret",
              onChange: () => {},
              placeholder: "YYYY-MM-DD"
            }}
            closeOnSelect={true}
            onChange={setValue}
            value={value}
          />
          {value && <div className="clear-button" onClick={() => clearDateValue()}>X</div> }
        </div>
    </Fragment>
  );
}

DateInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.oneOf(["dateFrom", "dateTo"]).isRequired
};

export default DateInput;
