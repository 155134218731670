import React from "react";
import PropTypes from "prop-types";

import { ALL_COLUMNS, REQUIRED_COLUMNS } from "@/lib/core/readScheduledGamesCSV";

import InvalidField from "./InvalidField";

function InvalidRow({ lineNumber, record, errors }) {
  return (
    <tr>
      <td>{lineNumber}</td>
      {ALL_COLUMNS.map(field => (
        <InvalidField key={field} value={record[field] || ""} error={errors[field] || ""} />
      ))}
    </tr>
  );
}

InvalidRow.propTypes = {
  lineNumber: PropTypes.number.isRequired,
  record: PropTypes.shape(
    REQUIRED_COLUMNS.reduce(
      (result, field) => ({
        ...result,
        [field]: PropTypes.string.isRequired
      }),
      {}
    )
  ).isRequired,
  errors: PropTypes.shape(
    REQUIRED_COLUMNS.reduce(
      (result, field) => ({
        ...result,
        [field]: PropTypes.string
      }),
      {}
    )
  ).isRequired
};

export default InvalidRow;
