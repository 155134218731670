import { createRoutine } from "redux-saga-routines";

export const CoachListLoadingRoutine = createRoutine("LOAD_COACH_LIST");

export const CoachCreatingRoutine = createRoutine("CREATE_COACH");

export const CurrentCoachLoadingRoutine = createRoutine("LOAD_CURRENT_COACH");

export const CurrentCoachUpdatingRoutine = createRoutine("UPDATE_CURRENT_COACH");

export const CurrentCoachDeletingRoutine = createRoutine("DELETE_CURRENT_COACH");

export const AddCoachToTeamRoutine = createRoutine("ADD_COACH_TO_TEAM");
