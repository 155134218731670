import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class Division extends PureComponent {
  static propTypes = {
    season: PropTypes.string,
    title: PropTypes.string.isRequired,
    visits: PropTypes.number.isRequired
  };

  static defaultProps = {
    season: null
  };

  render() {
    const { season, title, visits } = this.props;

    return (
      <tr>
        <td>{season || <span>&nbsp;</span>}</td>
        <td>{title}</td>
        <td>{visits}</td>
      </tr>
    );
  }
}

export default Division;
