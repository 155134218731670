import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { isDivisionListLoaded, isDivisionListLoading, isDivisionListForbidden } from "@/redux/divisions/selectors";

import { DivisionListLoadingRoutine } from "@/redux/divisions/routines";

export default function useTeamFormDivisionsListLoading(seasonId) {
  const dispatch = useDispatch();

  const isListLoaded = useSelector(isDivisionListLoaded);
  const isListLoading = useSelector(isDivisionListLoading);
  const isListForbidden = useSelector(isDivisionListForbidden);

  useEffect(
    () => {
      if (!isListLoaded && !isListLoading && !isListForbidden) {
        dispatch(DivisionListLoadingRoutine({ seasonId }));
      }
    },
    [isListLoaded, isListLoading, isListForbidden, seasonId, dispatch]
  );
}
