import React from "react";
import PropTypes from "prop-types";

import { Switch } from "react-router-dom";

import LoggedInRoute from "@/containers/LoggedInRoute";

import SuspensionReportDataSourcesPage from "./DataSourcesPage";
import SuspensionReportPage from "./ReportPage";

import useDataSourcesClearing from "./hooks/useDataSourcesClearing";

function ReportsRoute({ match }) {
  useDataSourcesClearing();

  return (
    <Switch>
      <LoggedInRoute path={`${match.url}/sources`} component={SuspensionReportDataSourcesPage} />

      <LoggedInRoute path="" component={SuspensionReportPage} />
    </Switch>
  );
}

ReportsRoute.propTypes = {
  match: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired
};

export default ReportsRoute;
