import PropTypes from "prop-types";
import _startCase from "lodash/startCase";
import { useRecoilValue } from "recoil";
import { playerPositionsState, coachPositionsState } from "@/components-16.8/RosterImportWizard/state/season";
import ContentSection from "@/components/ContentSection";
import { Table } from "reactstrap";
import React from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";

function Parser({ error, teamId }) {
  const playerPositions = useRecoilValue(playerPositionsState);
  const coachPositions = useRecoilValue(coachPositionsState);

  return (
    <>
      {error && <p className="text-danger">{error}</p>}

      <ContentSection title="Expected CSV File Layout">
        <Table borderless>
          <thead>
            <tr>
              <th>
                Type <span className="text-danger">*</span>
              </th>
              <th>
                First Name <span className="text-danger">*</span>
              </th>
              <th>
                Last Name <span className="text-danger">*</span>
              </th>
              <th>External ID</th>
              {teamId === null && <th>Team ID</th>}
              <th>Jersey Number</th>
              <th>Position</th>
              <th>Designation</th>
              <th>Affiliated</th>
              <th>Birthdate</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Player</td>
              <td>Aidan</td>
              <td>Gurev</td>
              <td>28465930</td>
              {teamId === null && <td>121</td>}
              <td>11</td>
              <td>forward</td>
              <td>alternate_captain</td>
              <td>Yes</td>
              <td>2001-02-27</td>
            </tr>
            <tr>
              <td>Coach</td>
              <td>Allan</td>
              <td>Doyle</td>
              <td>45435576</td>
              {teamId === null && <td>345</td>}
              <td />
              <td>assistant_trainer</td>
              <td />
              <td />
              <td />
            </tr>
          </tbody>
        </Table>

        <div className="mt-5">
          <h5>Extended Fields</h5>
          <p>
            Extended fields can now be imported! You can add any of the extended field values below to your CSV file to
            import into them into GameSheet.
          </p>
          <Table borderless>
            <thead>
              <tr>
                <th>Weight</th>
                <th>Height</th>
                <th>Shot Hand</th>
                <th>Birth Country</th>
                <th>Birth State</th>
                <th>Birth City</th>
                <th>Drafted By</th>
                <th>Committed To</th>
                <th>Bio</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>198 LBS</td>
                <td>6&apos; 1&quot;</td>
                <td>left</td>
                <td>USA</td>
                <td>Michigan</td>
                <td>Waterford Township</td>
                <td>Detroit Red Wings</td>
                <td>University of Michigan</td>
                <td>Aidan Gurev is an American professional ice hockey player...</td>
              </tr>
            </tbody>
          </Table>
        </div>

        <Row className="mt-5">
          <Col lg={12}>
            <h5>Requirements and limitations</h5>

            <ul>
              <li>
                Fields marked with <span className="text-danger">*</span> are required. Others are optional.
              </li>
              <li>
                Expected date format is <code>YYYY-MM-DD</code>
              </li>
            </ul>
          </Col>
          <Col lg={6}>
            <ul>
              <li>
                Valid types:
                <ul>
                  <li><code>P, Player</code></li>
                  <li><code>C, Coach</code></li>
                </ul>
              </li>
            </ul>
          </Col> 
          <Col lg={6}>
            <ul>
              <li>
                Valid Shot hands: 
                <ul>
                  <li><code>L, Left</code></li>
                  <li><code>R, Right</code></li>
                </ul>
              </li>
            </ul>
          </Col>
          <Col lg={6}>
            <ul>
              <li>
                Valid player positions: 
                <ul>
                  {playerPositions.map((position) => (
                    <li key={position.key}>
                      <code>{position.abbr.toUpperCase()}, {position.value}</code>
                    </li>
                  ))}
                </ul>
              </li>
              <li className="mt-3">
                Valid player designations:
                <ul>
                  <li><code>C, Captain</code></li>
                  <li><code>A, Alternate_captain</code></li>
                </ul>
              </li>
            </ul>
          </Col>
          <Col lg={6}>
          <ul>
              <li>
                Valid coach positions:
                <ul>
                  {coachPositions.map((position) => (
                    <li key={position.key}>
                      <code>{position.abbr.toUpperCase()}, {position.value}</code>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </Col>
        </Row>
      </ContentSection>
    </>
  );
}

Parser.propTypes = {
  error: PropTypes.string,
  teamId: PropTypes.string
};

Parser.defaultProps = {
  error: null,
  teamId: null
};

export default Parser;
