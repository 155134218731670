import Papa from "papaparse";
import _camelCase from "lodash/camelCase";
import _trim from "lodash/trim";

// these columns must always be present
const NECESSARY_COLUMNS = ["label", "code", "penaltyClass"];

export function validate(rows, isValidPenaltyClass) {
  const validatedRows = rows.reduce((result, { code, label, penaltyClass, duration }, index) => {
    const blankLabel = label.trim() === "";
    const blankCode = code.trim() === "";
    const blankClass = penaltyClass.trim() === "";
    const blankDuration = duration.replace(";","").trim() === "";
    const dupeCode = !!result.find(rec => rec.code === code);
    const invalidClass = !isValidPenaltyClass(penaltyClass);
    let invalid = blankCode || blankLabel || (!blankClass && blankDuration) || dupeCode || invalidClass;

    return [
      ...result,
      {
        code,
        label,
        penaltyClass,
        duration,
        blankLabel,
        blankCode,
        blankClass,
        blankDuration,
        dupeCode,
        invalidClass,
        lineNumber: index + 2,
        invalid
      }
    ];
  }, []);

  return validatedRows.filter(({ invalid }) => invalid);
}

function reader(file) {
  return new Promise(resolve => {
    Papa.parse(file, {
      delimiter: "",
      newline: "",
      skipEmptyLines: "greedy",
      header: true,
      transformHeader: h => {
        const header = _camelCase(_trim(h).toLowerCase());

        if (header === "penaltyLabel" || header === "infractionLabel") return "label";
        if (header === "class") return "penaltyClass";

        return header;
      },
      transform: (value, column) => {
        if (column === "code") {
          return value
            .trimEnd()
            .trimStart()
            .toUpperCase();
        } else if (column === "penaltyClass") {
          return value.trimEnd().trimStart();
        }

        return value.trimEnd().trimStart();
      },
      delimitersToGuess: [",", "\t", "|", ";", Papa.RECORD_SEP, Papa.UNIT_SEP],
      complete: res => resolve(res)
    });
  });
}

export async function read(file) {
  const { data: rows, meta, errors } = await reader(file);

  if (errors.length > 0) {
    return { error: "CSV file is invalid" };
  } else if (rows.length === 0) {
    return { error: "CSV file is empty" };
  }

  const columns = meta.fields;
  const missingColumns = NECESSARY_COLUMNS.filter(column => !columns.includes(column));

  if (missingColumns.length > 0) {
    return { error: "CSV file has incorrect headers" };
  }

  rows.forEach(row => {
    if (!("duration" in row) || row.duration == "") {
      row.duration = "0";
    }
  });

  return { rows };
}
