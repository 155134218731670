import { getResources, getResource } from "@/redux/resources/selectors";

const rootSelector = state => state.associations;
const listSelector = state => rootSelector(state).list;
const newSelector = state => rootSelector(state).new;
const currentSelector = state => rootSelector(state).current;

export const isAssociationListLoaded = state => listSelector(state).isLoaded;

export const isAssociationListLoading = state => listSelector(state).isLoading;

export const getAssociationList = state => {
  const { ids } = listSelector(state);

  return ids && ids.length > 0 ? getResources(state, "associations", ids) : [];
};

export const isAssociationCreating = state => newSelector(state).isCreating;

export const isCurrentAssociationLoaded = state => currentSelector(state).isLoaded === true;

export const isCurrentAssociationReady = state =>
  isCurrentAssociationLoaded(state) && !currentSelector(state).isDeleted;

export const isCurrentAssociationOperating = state => typeof currentSelector(state).operation === "string";

export const isCurrentAssociationLoading = state => currentSelector(state).operation === "loading";

export const isCurrentAssociationForbidden = state => currentSelector(state).isForbidden;

export const getCurrentAssociation = state =>
  isCurrentAssociationLoaded(state) ? getResource(state, "associations", currentSelector(state).id) : undefined;

export const getCurrentAssociationId = state => currentSelector(state).id;

export const getCurrentAssociationBreadcrumbs = state => {
  if (isCurrentAssociationReady(state)) {
    const { id, title } = getCurrentAssociation(state);

    return [{ url: "/associations", title: "Associations" }, { url: `/associations/${id}/leagues`, title }];
  }
};

export const getAssociationListTotalCount = state => listSelector(state).totalCount;
