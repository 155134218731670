import _get from "lodash/get";
import { handleActions, combineActions } from "redux-actions";

import {
  CurrentTeamLoadingRoutine as loadingRoutine,
  CurrentTeamUpdatingRoutine as updatingRoutine,
  CurrentTeamDeletingRoutine as deletingRoutine
} from "../routines";

import actions from "../actions";

const initialState = {
  isLoaded: false,
  isUpdated: false,
  isForbidden: false,
  isArchived: false,
  operation: null,
  errors: null,
  id: null,
  logoUrl: "",
  externalId: "",
  title: "",
  roster: {
    coaches: [],
    players: []
  },
  data: {},
  association: { id: "", title: "" },
  league: { id: "", title: "" },
  season: { id: "", title: "" },
  division: { id: "", title: "" }
};

const triggerLoading = (state, { payload }) => Object.assign({}, initialState, { id: payload.id });

const reduce = props => state => Object.assign({}, state, props);

const loaded = (
  state,
  { payload: { id, title, roster, externalId, association, league, season, division, data, logoUrl } }
) => {
  return Object.assign({}, state, {
    id,
    title,
    roster,
    logoUrl,
    externalId,
    association: { id: association.id, title: association.title },
    league: { id: league.id, title: league.title },
    season: { id: season.id, title: season.title },
    division: { id: division.id, title: division.title },
    data: data,
    isLoaded: true
  });
};

const failure = props => (state, { payload: { error } }) => {
  const status = _get(error, "response.status");
  const code = _get(error, "response.data.errors[0].code");
  const pointer = _get(error, "response.data.errors[0].source.pointer");

  return {
    ...state,
    ...props,
    isForbidden: status === 401,
    isArchived: status === 403 && code === "archived" && pointer === "/",
    error
  };
};

const operate = operation => state => Object.assign({}, state, { operation });

const fulfill = state => Object.assign({}, state, { operation: null });

export default handleActions(
  {
    [loadingRoutine.TRIGGER]: triggerLoading,
    [loadingRoutine.REQUEST]: operate("loading"),
    [loadingRoutine.SUCCESS]: loaded,
    [loadingRoutine.FAILURE]: failure({ isLoaded: false }),
    [updatingRoutine.TRIGGER]: reduce({ isUpdated: false }),
    [updatingRoutine.REQUEST]: operate("updating"),
    [updatingRoutine.SUCCESS]: reduce({ isUpdated: true }),
    [updatingRoutine.FAILURE]: failure({ isUpdated: false }),
    [deletingRoutine.REQUEST]: operate("deleting"),
    [combineActions(loadingRoutine.FULFILL, updatingRoutine.FULFILL, deletingRoutine.FULFILL)]: fulfill,
    [actions.current.clear]: () => ({ ...initialState })
  },
  initialState
);
