import { createActions } from "redux-actions";

export const { account } = createActions({
  ACCOUNT: {
    EDIT: {
      FIELD_CHANGE: undefined,
      CLEAR: undefined
    },
    INVITATION: {
      SET_CODE_INFO: undefined,
      SHOW_SCREEN: undefined,
      FIELD_CHANGE: undefined,
      CLEAR: undefined
    },
    F_USER: {
      SET: undefined,
      CLEAR: undefined
    }
  }
});
