import { combineReducers } from "redux";
import { handleActions, combineActions } from "redux-actions";

import actions from "./actions";

import { associationList, leagueList, seasonList, divisionList } from "./reducers";

const associationId = handleActions(
  {
    [actions.setAssociationId]: (state, { payload: id }) => id,
    [combineActions(actions.reset, actions.clear)]: () => ""
  },
  ""
);

const leagueId = handleActions(
  {
    [actions.setLeagueId]: (state, { payload: id }) => id,
    [combineActions(actions.setAssociationId, actions.reset, actions.clear)]: () => ""
  },
  ""
);

const seasonId = handleActions(
  {
    [actions.setSeasonId]: (state, { payload: id }) => id,
    [combineActions(actions.setAssociationId, actions.setLeagueId, actions.reset, actions.clear)]: () => ""
  },
  ""
);

const divisionId = handleActions(
  {
    [actions.setDivisionId]: (state, { payload: id }) => id,
    [combineActions(
      actions.setAssociationId,
      actions.setLeagueId,
      actions.setSeasonId,
      actions.reset,
      actions.clear
    )]: () => ""
  },
  ""
);

const query = handleActions(
  {
    [actions.setQuery]: (state, { payload: query }) => query,
    [combineActions(actions.reset, actions.clear)]: () => ""
  },
  ""
);

const year = handleActions(
  {
    [actions.setYear]: (state, { payload: year }) => year,
    [combineActions(actions.reset, actions.clear)]: () => ""
  },
  ""
);

export default combineReducers({
  associationId,
  leagueId,
  seasonId,
  divisionId,
  query,
  year,
  associationList,
  leagueList,
  seasonList,
  divisionList
});
