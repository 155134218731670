import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import Status from "@/components/InvitationStatus";

function User({ code, description, roles, status }) {
  return (
    <tr className="dashboard-table-item">
      <td>
        <Link to={`/invitations/${code}`}>{code}</Link>
      </td>
      <td>{description}</td>
      <td>{roles.join(", ")}</td>
      <td>
        <Status status={status} />
      </td>
    </tr>
  );
}

User.propTypes = {
  code: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  status: PropTypes.string.isRequired
};

export default User;
