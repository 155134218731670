import { all, put, takeLatest } from "redux-saga/effects";

import { loadLeague } from "@/lib/api/leagues";

import { fetchOne } from "@/redux/api/sagas";

import { loadingRoutine } from "./routines";

function* loadingSaga({ payload: { leagueId } }) {
  yield put(loadingRoutine.request());

  try {
    const [league] = yield fetchOne({ type: "leagues", id: leagueId }, loadLeague, { include: "associations" });

    yield put(loadingRoutine.success({ league }));
  } catch (error) {
    const { response } = error;

    yield put(
      loadingRoutine.failure({
        error,
        responseStatus: response && response.responseStatus
      })
    );
  } finally {
    yield put(loadingRoutine.fulfill());
  }
}

export function* currentLeagueFlow() {
  yield all([takeLatest(loadingRoutine.TRIGGER, loadingSaga)]);
}
