import { handleActions } from "redux-actions";
import _uniq from "lodash/uniq";

import { listLoadingRoutine } from "../routines";

const initialState = {};

function reduceLoadingSuccess(state, { payload: { invitations } }) {
  return invitations.reduce((result, invitation) => {
    const { id, code, description, roles, expiresAt } = invitation;

    return {
      ...result,
      [id]: {
        code,
        description,
        roles: _uniq((roles || []).map(({ title }) => title)),
        expiresAt: expiresAt && new Date(expiresAt)
      }
    };
  }, state);
}

export default handleActions(
  {
    [listLoadingRoutine.SUCCESS]: reduceLoadingSuccess
  },
  initialState
);
