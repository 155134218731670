import { useCallback } from "react";

import { loadFile, loadImage } from "@/components/ImagePickerField";

export default function usePhotoFileLoader({ changeFile, onError, dims }) {
  return useCallback(
    async file => {
      try {
        const dataUrl = await loadFile(file);
        await loadImage(dataUrl, dims);

        changeFile(file);
      } catch (err) {
        onError(err.message);
      }
    },
    [changeFile, onError, dims]
  );
}
