import _omitBy from "lodash/omitBy";

import api from "./api";

function applyBuilder(builder, payload) {
  const type = typeof builder;

  switch (type) {
    case "function":
      return { ...builder(payload) };
    case "string":
      return { url: builder };
    case "object":
      return builder;
    default:
      throw new Error(`Unexpected builder of type [${type}]. Expected [function], [string] or [object]`);
  }
}

const buildTokenAuthHeader = token => (token ? { Authorization: `Bearer ${token}` } : undefined);

export function createService(method, builder) {
  const service = function(payload = {}) {
    const request = Object.assign({}, payload, applyBuilder(builder, payload));
    const { attributes, relationships, basicAuth, headers, ...rest } = request;
    const token = request.token || payload.token;

    const identity = request.identity || payload.identity;

    const allHeaders = {
      ...headers,
      ...buildTokenAuthHeader(token)
    };

    const data = attributes || relationships ? { ...identity, attributes } : undefined;

    if (relationships) {
      data.relationships = relationships;
    }

    return api({
      auth: basicAuth,
      headers: _omitBy(allHeaders, value => value === undefined),
      data: { data },
      method,
      ...rest
    });
  };

  return service;
}

export function createGetService(builder) {
  return createService("GET", builder);
}

export function createPutService(builder) {
  return createService("PUT", builder);
}

export function createPostService(builder) {
  return createService("POST", builder);
}

export function createPatchService(builder) {
  return createService("PATCH", builder);
}

export function createDeleteService(builder) {
  return createService("DELETE", builder);
}
