import createTeamMemberPenaltyReportSelectors from "@/redux/common/reports/createTeamMemberPenaltyReportSelectors";

const selectRoot = state => state.playerPenaltyReport;

const selectors = createTeamMemberPenaltyReportSelectors({
  selectRoot,
  context: "player"
});

export default selectors;
