import { handleActions } from "redux-actions";

import { DivisionListLoadingRoutine as routine, CurrentDivisionDeletingRoutine as deletingRoutine } from "../routines";

import actions from "../actions";

const initialState = {
  isLoaded: false,
  isLoading: false,
  isForbidden: false,
  errors: null,
  ids: [],
  totalCount: 0
};

const trigger = () => Object.assign({}, initialState, { isLoaded: false });

const request = state => Object.assign({}, state, { isLoading: true });

const success = (state, { payload }) =>
  Object.assign({}, state, {
    isLoaded: true,
    ids: payload.ids,
    totalCount: payload.totalCount
  });

const failure = (state, { payload }) =>
  Object.assign({}, state, {
    isForbidden: payload.responseStatus === 401,
    isLoaded: false,
    errors: payload.errors
  });

const fulfill = state => Object.assign({}, state, { isLoading: false });

const remove = (state, { payload }) => Object.assign({}, state, { ids: state.ids.filter(id => id !== payload.id) });

export default handleActions(
  {
    [routine.TRIGGER]: trigger,
    [routine.REQUEST]: request,
    [routine.SUCCESS]: success,
    [routine.FAILURE]: failure,
    [routine.FULFILL]: fulfill,
    [deletingRoutine.SUCCESS]: remove,
    [actions.list.clear]: () => ({ ...initialState })
  },
  initialState
);
