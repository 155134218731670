export default function validateTeamId({ teamId }, validationOptions) {
  const { validTeamIds, skipTeamIdValidation } = validationOptions;

  switch (true) {
    case teamId === "": {
      return [{ field: "teamId", error: "blank" }];
    }
    case !skipTeamIdValidation && !validTeamIds.includes(teamId): {
      return [{ field: "teamId", error: "invalid" }];
    }
    default: {
      return [];
    }
  }
}
