import { playerAffiliated, playerDuty } from "@/utils/codeToTitle";
import { getPlayerPositionTranslator } from "../currentSeason";

import { getResources, getResource } from "@/redux/resources/selectors";
import { getCurrentTeam, getCurrentTeamBreadcrumbs } from "@/redux/teams/selectors";

import { getCurrentSeason } from "@/redux/seasons/selectors";

import { getSeasonRosterBreadcrumbs } from "@/redux/seasonRoster/selectors";

const rootSelector = state => state.players;
const listSelector = state => rootSelector(state).list;
const newSelector = state => rootSelector(state).new;
const currentSelector = state => rootSelector(state).current;
const teamFormSelector = state => rootSelector(state).teamForm;

export const isPlayersListLoaded = state => listSelector(state).isLoaded;
export const isPlayersListLoading = state => listSelector(state).isLoading;
export const getPlayerList = state => {
  if (isPlayersListLoaded(state)) {
    return getResources(state, "players", listSelector(state).ids);
  }
};

export const isPlayerCreating = state => newSelector(state).isCreating;

export const isCurrentPlayerLoaded = state => currentSelector(state).isLoaded;
export const isCurrentPlayerForbidden = state => currentSelector(state).isForbidden;

export const isCurrentPlayerArchived = state => currentSelector(state).isArchived;

export const isCurrentPlayerOperating = state => {
  const operation = currentSelector(state).operation;

  return typeof operation === "string" && operation.length > 0;
};

export const isCurrentPlayerReady = state => isCurrentPlayerLoaded(state) && !currentSelector(state).isDeleted;

export const getCurrentPlayer = state =>
  isCurrentPlayerReady(state) ? getResource(state, "players", currentSelector(state).id) : undefined;

export const getCurrentPlayerFullName = state => {
  if (isCurrentPlayerLoaded(state)) {
    const { firstName, lastName } = getCurrentPlayer(state);

    return `${firstName} ${lastName}`;
  }
};

export const isCurrentPlayerDeleting = state => currentSelector(state).operation === "deleting";

export const getCurrentTeamPlayer = state => {
  const team = getCurrentTeam(state);
  const player = getCurrentPlayer(state);
  const playerInRoster = team && player ? (team.roster.players || []).find(item => item.id === player.id) : undefined;

  if (playerInRoster) {
    const { id, firstName, lastName, externalId } = player;

    return {
      id,
      firstName,
      lastName,
      externalId,
      jersey: playerInRoster.number,
      duty: playerInRoster.duty,
      position: playerInRoster.position,
      affiliated: playerInRoster.affiliated
    };
  }
};

export const getSeasonPlayerTeams = state => {
  if (!isCurrentPlayerReady(state)) {
    return [];
  }

  const player = getCurrentPlayer(state);

  const teams = player.teams || [];

  const playerPosition = getPlayerPositionTranslator(state);

  return teams.filter(({ roster }) => "players" in roster).map(({ id, title, roster, season, division }) => {
    const rosterPlayer = (roster ? roster.players : []).find(item => item.id === player.id) || {};

    return {
      id,
      title,
      seasonId: season.id,
      divisionId: division.id,
      jersey: rosterPlayer.number,
      duty: playerDuty(rosterPlayer.duty),
      position: playerPosition(rosterPlayer.position),
      status: playerAffiliated(rosterPlayer.affiliated)
    };
  });
};

export const getCurrentTeamPlayerBreadcrumbs = state => {
  const team = getCurrentTeam(state);
  const player = getCurrentPlayer(state);
  const teamBreadcrumbs = getCurrentTeamBreadcrumbs(state);

  if (team && player && teamBreadcrumbs) {
    return [
      ...teamBreadcrumbs,
      {
        url: `/teams/${team.id}/roster/players/${player.id}`,
        title: getCurrentPlayerFullName(state)
      }
    ];
  }
};

export const getCurrentSeasonPlayerBreadcrumbs = state => {
  const season = getCurrentSeason(state);
  const player = getCurrentPlayer(state);
  const seasonRosterBreadcrumbs = getSeasonRosterBreadcrumbs(state, "players");

  if (season && player && seasonRosterBreadcrumbs) {
    return [
      ...seasonRosterBreadcrumbs,
      {
        url: `/seasons/${season.id}/roster/players/${player.id}`,
        title: getCurrentPlayerFullName(state)
      }
    ];
  }
};

export const isCurrentPlayerTeamFormDisplaying = state => teamFormSelector(state).display;

export const getCurrentPlayerTeamFormValues = state => teamFormSelector(state).values;

export const isCurrentPlayerTeamFormOperating = state => teamFormSelector(state).isOperating;

export const getCurrentPlayerTeamIds = state => {
  if (isCurrentPlayerLoaded(state)) {
    return (getCurrentPlayer(state).teams || []).map(({ id }) => id);
  }

  return [];
};
