import React from "react";
import PropTypes from "prop-types";

import withQueryParams from "react-router-query-params";

import Layout from "@/layouts/Layout";
import Head from "@/components/Head";
import AccountVerificationForm from "@/components/AccountVerificationForm";

function AccountVerify({ queryParams: { code } }) {
  return (
    <Layout>
      <Head title="Verify account" />

      <AccountVerificationForm code={code} />
    </Layout>
  );
}

AccountVerify.propTypes = {
  queryParams: PropTypes.shape({
    code: PropTypes.string.isRequired
  })
};

export default withQueryParams({
  keys: {
    code: {
      default: "",
      validate: value => !!value
    }
  }
})(AccountVerify);
