import { sortByPeriodAndTime } from "@/utils/sortByPeriodAndTime";
import _snakeCase from "lodash/snakeCase";

function selectByOvertimePeriod({ period }) {
  return period.isOvertimePeriod;
}

function sortByPeriodStartTime({ period: { startTimeA } }, { period: { startTimeB } }) {
  return startTimeA - startTimeB;
}

function scoreboardPeriodTimeToSeconds({ scoreboardTime, duration }) {
  const [minutes, seconds] = scoreboardTime.split(":").map(value => parseInt(value));

  return duration - (minutes * 60 + seconds);
}

function transformPeriodTimeToAbsoluteGameTime({ period, scoreboardTime, periods }) {
  const secondsFromStart = scoreboardPeriodTimeToSeconds({
    scoreboardTime,
    duration: period.duration
  });

  return period.startTime + secondsFromStart;
}

function reducePeriods(periodsConfig) {
  const periodNumbers = Object.keys(periodsConfig).sort();
  const lastPeriodIndex = periodNumbers.length - 1;

  let gameTime = 0;

  return periodNumbers.reduce((result, periodNumber, index) => {
    const duration = periodsConfig[periodNumber] * 60;

    const startTime = gameTime;
    const endTime = gameTime + duration;
    const isOvertimePeriod = periodNumber.match(/^ot/gi) !== null;

    gameTime = endTime;

    return [
      ...result,
      {
        number: periodNumber,
        duration,
        startTime,
        endTime,
        isLastPeriod: index === lastPeriodIndex,
        isMainTimePeriod: !isOvertimePeriod,
        isOvertimePeriod
      }
    ];
  }, []);
}

function findPeriodByNumber(periods, periodNumber) {
  return periods.find(({ number }) => _snakeCase(number) === _snakeCase(periodNumber));
}

function reduceShifts({ shiftsConfig, periods }) {
  return shiftsConfig
    .filter(({ period: periodNumber }) => findPeriodByNumber(periods, periodNumber) !== undefined)
    .reduce((result, { goalieId, time: scoreboardTime, period: periodNumber }) => {
      const period = findPeriodByNumber(periods, periodNumber);

      const startTime = transformPeriodTimeToAbsoluteGameTime({
        period,
        scoreboardTime
      });

      return [...result, { goalieId, scoreboardTime, period, startTime }];
    }, [])
    .sort((shiftA, shiftB) => shiftA.startTime - shiftB.startTime);
}

function getNextGoalieShift({ current, shifts }) {
  const nextIndex = shifts.indexOf(current) + 1;

  return shifts[nextIndex];
}

function reduceOvertimeGoals({ goalsConfig, periods }) {
  return goalsConfig
    .reduce((result, goal) => {
      const period = findPeriodByNumber(periods, goal.period);
      const time = transformPeriodTimeToAbsoluteGameTime({
        period,
        scoreboardTime: goal.time
      });

      return [...result, { period, time }];
    }, [])
    .filter(selectByOvertimePeriod);
}

function reduceOvertimeShots({ shotsConfig, periods }) {
  return shotsConfig
    .reduce((result, shot) => {
      const period = findPeriodByNumber(periods, shot.period);

      return [...result, { period }];
    }, [])
    .filter(selectByOvertimePeriod)
    .sort(sortByPeriodStartTime);
}

function reduceOvertimePenalties({ penaltiesConfig, periods }) {
  return penaltiesConfig
    .reduce((result, penalty) => {
      const period = findPeriodByNumber(periods, penalty.period);

      return [...result, { period }];
    }, [])
    .filter(selectByOvertimePeriod)
    .sort(sortByPeriodStartTime);
}

function getLatestGoalTime(goals) {
  return goals.sort(sortByPeriodAndTime)[0].time;
}

function calculateGameMainTimeDuration(periods) {
  return periods.filter(({ isMainTimePeriod }) => isMainTimePeriod).reduce((sum, { duration }) => sum + duration, 0);
}

function calculateGameOvertimeDuration(options) {
  const {
    periods,
    shifts,
    oppositeShifts,
    overtimeGoals,
    overtimeShots,
    overtimePenalties,
    gameMainTimeDuration,
    hasShootouts
  } = options;

  if (hasShootouts) {
    return Math.max(...periods.map(({ endTime }) => endTime)) - gameMainTimeDuration;
  }

  if (overtimeGoals.length > 0) {
    return getLatestGoalTime(overtimeGoals) - gameMainTimeDuration;
  }

  const overtimeShifts = shifts.filter(selectByOvertimePeriod);
  const overtimeOppositeShifts = oppositeShifts.filter(selectByOvertimePeriod);

  const latestShiftPeriod = overtimeShifts.length > 0 ? overtimeShifts.reverse()[0].period : null;

  const latestOppositeShiftPeriod =
    overtimeOppositeShifts.length > 0 ? overtimeOppositeShifts.reverse()[0].period : null;

  const latestShotPeriod = overtimeShots.length > 0 ? overtimeShots.reverse()[0].period : null;
  const latestPenaltyPeriod = overtimePenalties.length > 0 ? overtimePenalties.reverse()[0].period : null;

  const latestOvertimeActivityPeriod = [
    latestShiftPeriod,
    latestOppositeShiftPeriod,
    latestShotPeriod,
    latestPenaltyPeriod
  ]
    .filter(period => period)
    .sort((periodA, periodB) => periodB.endTime - periodA.endTime)[0];

  return latestOvertimeActivityPeriod ? latestOvertimeActivityPeriod.endTime - gameMainTimeDuration : 0;
}

export default function calculateGoaliesTimeOnIce({
  shifts: shiftsConfig,
  periods: periodsConfig,
  goals: goalsConfig,
  shots: shotsConfig,
  penalties: penaltiesConfig,
  oppositeShifts: oppositeShiftsConfig,
  hasShootouts
}) {
  const periods = reducePeriods(periodsConfig);

  const shifts = reduceShifts({ shiftsConfig, periods });
  const oppositeShifts = reduceShifts({
    shiftsConfig: oppositeShiftsConfig,
    periods
  });

  const overtimeGoals = reduceOvertimeGoals({ goalsConfig, periods });
  const overtimeShots = reduceOvertimeShots({ shotsConfig, periods });
  const overtimePenalties = reduceOvertimePenalties({
    penaltiesConfig,
    periods
  });

  const gameMainTimeDuration = calculateGameMainTimeDuration(periods);
  const gameOvertimeDuration = calculateGameOvertimeDuration({
    periods,
    shifts,
    oppositeShifts,
    overtimeGoals,
    overtimeShots,
    overtimePenalties,
    gameMainTimeDuration,
    hasShootouts
  });

  return shifts.reduce((result, shift) => {
    const { goalieId } = shift;
    const startTime = shift.startTime;
    const nextShift = getNextGoalieShift({ current: shift, shifts });

    const endTime = nextShift ? nextShift.startTime : gameMainTimeDuration + gameOvertimeDuration;

    return {
      ...result,
      [goalieId]: (result[goalieId] || 0) + (endTime - startTime)
    };
  }, {});
}
