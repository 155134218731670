import { handleActions } from "redux-actions";

import { createNewPasswordRoutine } from "../../routines";
import { passwordActions } from "../../actions";

const initialState = {
  isWorking: false,
  isCompleted: false,
  error: null
};

const beginRequest = state => ({ ...state, isWorking: true });
const succeedRequest = state => ({ ...state, isCompleted: true });
const fulfillRequest = state => ({ ...state, isWorking: false });
const clear = state => ({ ...initialState });

export default handleActions(
  {
    [createNewPasswordRoutine.REQUEST]: beginRequest,
    [createNewPasswordRoutine.SUCCESS]: succeedRequest,
    [createNewPasswordRoutine.FULFILL]: fulfillRequest,
    [passwordActions.new.clear]: clear
  },
  initialState
);
