import React from "react";
import PropTypes from "prop-types";
import { ModalHeader } from "reactstrap";

const TITLE_BY_STEP = {
  "select-file": "Select CSV File to Import",
  "validation-failure": "Invalid CSV Data Found",
  confirmation: "Confirm Import"
};

function Header({ step, close }) {
  return <ModalHeader toggle={close}>{TITLE_BY_STEP[step]}</ModalHeader>;
}

Header.propTypes = {
  step: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired
};

export default Header;
