import { connect } from "react-redux";

import { seasonRosterActions } from "@/redux/seasonRoster/actions";

import PlayersListMergeToolPlayer from "./Player";

const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  chooseAsWinner: () => dispatch(seasonRosterActions.players.mergeTool.chooseWinner(ownProps.id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayersListMergeToolPlayer);
