import { handleActions } from "redux-actions";

import { gameNotesListLoadingRoutine, gameNoteFormSubmittingRoutine } from "../../routines";
import { clearGameNotes } from "../../actions";

const initialState = {
  ids: [],
  data: {}
};

function reduceNote({ text, createdAt, author }) {
  return {
    text,
    dateCreated: createdAt,
    author: `${author.firstName} ${author.lastName}`
  };
}

function reduceLoadingSuccess(state, { payload: { gameNotes } }) {
  const ids = gameNotes.map(({ id }) => id);
  const data = gameNotes.reduce((result, { id, ...rest }) => ({ ...result, [id]: reduceNote(rest) }), {});

  return { ids, data };
}

function reduceCreatingSuccess(state, { payload: { gameNote } }) {
  const { id, ...attrs } = gameNote;

  return {
    ...state,
    ids: [...state.ids, id],
    data: { ...state.data, [id]: reduceNote(attrs) }
  };
}

export default handleActions(
  {
    [gameNotesListLoadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [gameNoteFormSubmittingRoutine.SUCCESS]: reduceCreatingSuccess,
    [clearGameNotes]: () => ({ ...initialState })
  },
  initialState
);
