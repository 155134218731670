import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { Input, FormFeedback } from "reactstrap";

import { GAME_TYPES_LIST } from "@/constants/gameTypes";

function GameTypeInput(props) {
  const { id, name, input, isDirty, errors } = props;

  return (
    <Fragment>
      <Input type="select" id={id} name={name} {...input}>
        {GAME_TYPES_LIST.map(({ code, title }) => (
          <option key={code} value={code}>
            {title}
          </option>
        ))}
      </Input>

      {isDirty && errors.map(error => <FormFeedback key={error}>{error}</FormFeedback>)}
    </Fragment>
  );
}

GameTypeInput.propTypes = {
  id: PropTypes.string,
  input: PropTypes.shape({}).isRequired,
  name: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  isDirty: PropTypes.bool
};

GameTypeInput.defaultProps = {
  isDirty: false,
  errors: []
};

export default GameTypeInput;
