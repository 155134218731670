import { connect } from "react-redux";

import {
  getBillingReportIsLoading,
  getBillingReportIsLoaded,
  getBillingReportTeamGamesStatsBySeasons
} from "@/redux/billingReport/selectors";

import Report from "./Report";

const mapStateToProps = state => ({
  isLoading: getBillingReportIsLoading(state),
  isLoaded: getBillingReportIsLoaded(state),
  teamGamesStats: getBillingReportTeamGamesStatsBySeasons(state)
});

export default connect(mapStateToProps)(Report);
