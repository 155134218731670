import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import useVerificationEmailResending from "./hooks/useVerificationEmailResending";

function ResendVerificationEmailButton({ userId }) {
  const { isResending, isResendingFailed, isOperating, triggerResending, userStatus } = useVerificationEmailResending(
    userId
  );

  if (userStatus !== "pending") {
    return null;
  }

  return (
    <Fragment>
      {isResendingFailed && <span className="text-danger mr-1">Failed to resend the verification email</span>}

      <Button outline color="primary" disabled={isResending || isOperating} onClick={triggerResending}>
        {isResending
          ? "Resending verification email…"
          : isResendingFailed
            ? "Retry verification email resending"
            : "Resend verification email"}
      </Button>
    </Fragment>
  );
}

ResendVerificationEmailButton.propTypes = {
  userId: PropTypes.string.isRequired
};

export default ResendVerificationEmailButton;
