import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getTeamSettingsGroupedBySeason,
  getDisabledTeamIds,
  getEnabledTeamIds,
  actions
} from "@/redux/gameReportEmailNotificationSettings";

export default function useReportEmailNotificationSettings() {
  const dispatch = useDispatch();

  const settings = useSelector(getTeamSettingsGroupedBySeason);
  const canDisableAll = useSelector(getEnabledTeamIds).length > 0;
  const canEnableAll = useSelector(getDisabledTeamIds).length > 0;

  const disableAll = useCallback(() => dispatch(actions.disableAll()), [dispatch]);

  const enableAll = useCallback(() => dispatch(actions.enableAll()), [dispatch]);

  return { settings, canDisableAll, canEnableAll, enableAll, disableAll };
}
