const selectRoot = state => state.seasonsList;

export const getIsLoaded = state => selectRoot(state).isLoaded;
export const getIsLoading = state => selectRoot(state).isLoading;
export const getIsFailed = state => selectRoot(state).isFailed;
export const getIsForbidden = state => selectRoot(state).isForbidden;
export const getTotalCount = state => selectRoot(state).totalCount;

export const getList = (state, data) => {
  const { ids } = selectRoot(state);

  return ids.reduce((result, id) => (data[id] ? [...result, { id, ...data[id] }] : result), []);
};

export const getActiveList = state => getList(state, selectRoot(state).data.active);

export const getArchivedList = state => getList(state, selectRoot(state).data.archived);

export const getActiveTotalCount = state => getActiveList(state).length;
export const getArchivedTotalCount = state => getArchivedList(state).length;
