import React from "react";
import PropTypes from "prop-types";

import { FormGroup } from "reactstrap";

import TitleBar from "@/components/TitleBar";

import { usePointSystems, useCustomPointSystem } from "./hooks";

import PointSystemField from "./PointSystemField";
import GameValues from "./GameValues";
import PeriodValues from "./PeriodValues";

import "./PointSystemFormFields.scss";

function PointSystemFormFields({ value, onChange }) {
  const pointSystems = usePointSystems({ value, onChange });
  const { isCustom, gameValues, periodValues } = useCustomPointSystem({
    value,
    onChange
  });

  return (
    <div className="point-system">
      <TitleBar titleClassName="point-system__title" title="Point System" titleTag="h4" />
      <FormGroup>
        {pointSystems.map(({ title, ...rest }) => (
          <PointSystemField key={title} title={title} {...rest} />
        ))}
      </FormGroup>

      {isCustom && (
        <FormGroup>
          <GameValues {...gameValues} />
          <PeriodValues {...periodValues} />
        </FormGroup>
      )}
    </div>
  );
}

PointSystemFormFields.propTypes = {
  value: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired
};

export default PointSystemFormFields;
