import useFormWithRemoteValidationErrors from "@/hooks/useFormWithRemoteValidationErrors";

import { getRemoteValidationErrors } from "@/redux/leagueForm";

import { DEFAULT_VALUES, CONSTRAINTS } from "../constants";

export default function useAssociationForm(initialValues = DEFAULT_VALUES) {
  return useFormWithRemoteValidationErrors({
    initialValues,
    constraints: CONSTRAINTS,
    errorsSelector: getRemoteValidationErrors
  });
}
