import { put, select } from "redux-saga/effects";
import build from "redux-object";

import { updateTeam } from "@/lib/api/teams";
import { gamesheetAPIRequest } from "@/redux/api/sagas";

import { updatingRoutine } from "../routines";
import { getTeam } from "../selectors";

export default function* updatingSaga({ roster }) {
  yield put(updatingRoutine.request());

  const {
    id: teamId,
    season: { id: seasonId },
    division: { id: divisionId },
    title,
    externalId,
    data: teamData
  } = yield select(getTeam);

  const { data } = yield gamesheetAPIRequest(updateTeam, {
    identity: { type: "teams", id: teamId },
    seasonId,
    divisionId,
    attributes: {
      title,
      externalId,
      roster,
      data: teamData
    }
  });

  const team = build(data, "teams", teamId);

  yield put(updatingRoutine.success({ team }));
  yield put(updatingRoutine.fulfill());
}
