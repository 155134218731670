import React, { useRef, Fragment } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";
import ProtectedLayout from "@/layouts/ProtectedLayout";
import PageLoader from "@/components/PageLoader";
import TitleBar from "@/components/TitleBar";
import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import AbilityButton from "@/components/AbilityButton";
import { Can } from "@/components/Ability";
import TeamList, { useSeasonTeamsList, useTeamsListLoading, useTeamsListClearing } from "@/components/TeamsList";
import Spinner from "@/components/Spinner";
import { SingleActionModal } from "@/components-16.8/SingleActionModal";
import padlock from "@/components-16.8/LockImg/padlock.png";
import openPadlock from "@/components-16.8/LockImg/open-padlock.png";

import "./style.scss";

const PAGE_SIZE = 25;

function TeamsIndex({ isRosterLocking, lockRosters }) {
  const {
    seasonId,
    seasonIsForbidden,
    seasonIsLoaded,
    seasonIsLoading,
    season,
    seasonBreadcrumbs
  } = useCurrentSeasonContext();

  const { isLoaded, isLoading, isForbidden, teams, lockingTeams, triggerLockTeam } = useSeasonTeamsList();
  const hasUnlockedTeams = teams.filter(t => !t.data.rosterLocked).length > 0;

  const myCsvRef = useRef();

  const lockRostersCallback = () => {
    lockRosters({ lock: true });
  };

  const unlockRostersCallback = () => {
    lockRosters({ lock: false });
  };

  const handleOnClick = ({invitationsOnly}) => {
    if (teams.length === 0) {
      console.error("Must have Teams to Export");
      return;
    }
    if(myCsvRef.current && invitationsOnly){
      myCsvRef.current.ExportInvitationsToCSV()
    }

    if (myCsvRef.current && !invitationsOnly) {
      myCsvRef.current.ExportToCSV();
    }
  };

  const { pageNumber, sort } = useTeamsListLoading({ seasonId, pageSize: PAGE_SIZE });
  useTeamsListClearing();

  return (
    <ProtectedLayout className="teams-page" condition={!seasonIsForbidden && !isForbidden}>
      {seasonIsLoaded ? (
        <Fragment>
          <Head title={`${season.title} Teams`} />

          <Breadcrumbs items={seasonBreadcrumbs} />

          <TitleBar title="Teams">
            {(teams.length && false) > 0 && (
              <Can I="update" this={{ type: "seasons", id: seasonId }}>
                <Can I="update" this={{ type: "teamLocks" }}>
                  {isRosterLocking && (
                    <div className="roster-lock-spinner">
                      <Spinner theme="loader" color="yellow" size="m" />
                    </div>
                  )}
                  {(hasUnlockedTeams && (
                    <SingleActionModal
                      buttonText={
                        <>
                          <img src={padlock} height={12} className="lock-teams-button" />
                          <span className="lock-teams-button">Lock Teams</span>
                        </>
                      }
                      headerText={`Lock Teams in ${season.title}`}
                      bodyText={
                        <>
                          <p>
                            Locking prevents teams and their rosters from being modified. Would you like to proceed with
                            locking all teams in {season.title}?
                          </p>
                          <p>
                            <a
                              href="https://help.gamesheet.app/article/82-team-roster-locking-unlocking"
                              target="_blank"
                            >
                              More Information
                            </a>
                          </p>
                        </>
                      }
                      confirmText="Lock"
                      buttonColor="secondary"
                      confirmColor="success"
                      confirmCallback={lockRostersCallback}
                      disabled={isRosterLocking}
                    />
                  )) || (
                    <SingleActionModal
                      buttonText={
                        <>
                          <img src={openPadlock} height={12} className="lock-teams-button" />
                          <span className="lock-teams-button">Unlock Teams</span>
                        </>
                      }
                      headerText={`Unlock Teams in ${season.title}`}
                      bodyText={
                        <>
                          <p>
                            Unlocking allows teams and their rosters to be modified. Would you like to proceed with
                            unlocking all teams in {season.title}?
                          </p>
                          <p>
                            <a
                              href="https://help.gamesheet.app/article/82-team-roster-locking-unlocking"
                              target="_blank"
                            >
                              More Information
                            </a>
                          </p>
                        </>
                      }
                      confirmText="Unlock"
                      buttonColor="secondary"
                      confirmColor="success"
                      confirmCallback={unlockRostersCallback}
                      disabled={isRosterLocking}
                    />
                  )}
                </Can>
              </Can>
            )}

            <Button onClick={() => handleOnClick({invitationsOnly:true})} size="sm" outline color="primary">
              DOWNLOAD INVITATION CODES
            </Button>

            <Button onClick={() => handleOnClick({invitationsOnly:false})} size="sm" outline color="primary">
              Export to CSV
            </Button>

            <AbilityButton
              subject={{ type: "teams", seasonId }}
              action="create"
              outline
              color="success"
              size="sm"
              tag={Link}
              to={`/seasons/${seasonId}/teams/new`}
            >
              + New Team
            </AbilityButton>
          </TitleBar>

          {isLoaded ? (
            <TeamList
              csvRef={myCsvRef}
              title={season.title}
              seasonId={seasonId}
              teams={teams}
              displayDivision={true}
              lockingTeams={lockingTeams}
              triggerLockTeam={triggerLockTeam}
              pageSize={PAGE_SIZE}
              pageNumber={pageNumber}
              sort={sort}
            />
          ) : (
            <PageLoader isLoading={isLoading} />
          )}
        </Fragment>
      ) : (
        <PageLoader isLoading={seasonIsLoading} />
      )}
    </ProtectedLayout>
  );
}

export default TeamsIndex;
