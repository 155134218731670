import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import DivisionSelectField from "@/components/DivisionSelectField";
import { isDivisionListLoading, isDivisionListLoaded, getDivisionList } from "@/redux/divisions/selectors";

class DivisionSelectFieldContainer extends PureComponent {
  static propTypes = {
    key: PropTypes.string,
    name: PropTypes.string.isRequired,
    isListLoading: PropTypes.bool.isRequired,
    isListLoaded: PropTypes.bool.isRequired,
    divisions: PropTypes.arrayOf(PropTypes.shape({})),
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    valid: PropTypes.bool
  };

  static defaultProps = {
    divisions: [],
    defaultValue: null,
    valid: true,
    onChange() {}
  };

  render() {
    const { key, name, isListLoading, isListLoaded, divisions, defaultValue, onChange, valid } = this.props;

    return (
      <DivisionSelectField
        key={key}
        name={name}
        divisions={divisions}
        defaultValue={defaultValue}
        isBusy={isListLoading}
        isReady={isListLoaded}
        onChange={onChange}
        valid={valid}
      />
    );
  }
}

const mapStateToProps = state => ({
  isListLoading: isDivisionListLoading(state),
  isListLoaded: isDivisionListLoaded(state),
  divisions: getDivisionList(state)
});

export default connect(mapStateToProps)(DivisionSelectFieldContainer);
