import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { HandlePasswordReset } from "./HandlePasswordReset";
import { HandleRecoverEmail } from "./HandleRecoverEmail";
import { HandleBadMode } from "./HandleBadMode";

class HandleFirebase extends PureComponent {
  static propTypes = {
    login: PropTypes.func.isRequired,
  };

  render() {
    const searchParams = new URLSearchParams(location.search);
    const mode = searchParams.get('mode') || "MISSING";
    const actionCode = searchParams.get('oobCode');

    switch (mode) {
      case 'resetPassword': return (<HandlePasswordReset actionCode={actionCode} login={this.props.login} />);
      case 'recoverEmail': return (<HandleRecoverEmail />);
      case 'verifyEmail': // this should never happen
      default: return (<HandleBadMode mode={mode} />);
    }
  }
}

export default HandleFirebase;
