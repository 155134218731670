import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getLeagueId,
  getSeasonId,
  getSeasonList,
  getSeasonListIsLoaded,
  getSeasonListIsLoading
} from "@/redux/teamFilter/selectors";

import actions from "@/redux/teamFilter/actions";

export default function useLeagueSelect() {
  const leagueId = useSelector(getLeagueId);
  const selectedId = useSelector(getSeasonId);
  const options = useSelector(state => getSeasonList(state, leagueId));

  const isLoaded = useSelector(state => getSeasonListIsLoaded(state, leagueId));

  const isLoading = useSelector(state => getSeasonListIsLoading(state, leagueId));

  const dispatch = useDispatch();

  const setSelectedId = useCallback(id => dispatch(actions.setSeasonId(id)), [dispatch]);

  return {
    selectedId,
    options,
    isLoaded,
    isLoading,
    setSelectedId
  };
}
