import { takeLatest, call, put } from "redux-saga/effects";

import { otpRoutine } from "./routines";
import { getOTP } from "@/lib/api/getFreshToken";

export function* otpSaga() {
  yield put(otpRoutine.request());

  const otp = yield call(() => getOTP());
  if (!otp) {
    yield put(otpRoutine.failure());
  } else {
    yield put(otpRoutine.success({ otp }))
  }

  yield put(otpRoutine.fulfill());
}

export function* otpRefreshSaga() {
  // wait almost two minutes
  yield call(() => new Promise(resolve => setTimeout(resolve, 118000)));

  // refresh otp
  yield call(otpSaga);
}

export function* otpFlow() {
  yield takeLatest(otpRoutine.TRIGGER, otpSaga);
  yield takeLatest(otpRoutine.SUCCESS, otpRefreshSaga); // automatically cancels previous saga if still running (so expiry is effectively reset)
}
