import createMembersFlow from "./createMembersFlow";

import {
  validateFirstName,
  validateLastName,
  validateExternalId,
  validateNumber,
  validateAffiliated,
  validatePlayerPosition,
  validateDuty,
  validatePlayer
} from "../validations";

import {
  playerFieldValidatingRoutine,
  playerValidatingRoutine,
  playerSubmittingRoutine,
  playerSavingRoutine,
  playerRemovingRoutine
} from "../routines";

import actions from "../actions";

import { getAllPlayerAttributes, getRosterPlayerAttributes, getSeasonPlayerAttributes } from "../selectors";

import { createPlayer, updatePlayer } from "@/lib/api/players";

export default createMembersFlow("players", {
  validators: {
    firstName: validateFirstName,
    lastName: validateLastName,
    externalId: validateExternalId,
    number: validateNumber,
    affiliated: validateAffiliated,
    position: validatePlayerPosition,
    duty: validateDuty
  },
  validate: validatePlayer,
  routines: {
    fieldValidating: playerFieldValidatingRoutine,
    validating: playerValidatingRoutine,
    submitting: playerSubmittingRoutine,
    saving: playerSavingRoutine,
    removing: playerRemovingRoutine
  },
  actions: {
    changeField: actions.playersForm.changeField
  },
  selectors: {
    getAllMemberAttributes: getAllPlayerAttributes,
    getRosterMemberAttributes: getRosterPlayerAttributes,
    getSeasonMemberAttributes: getSeasonPlayerAttributes
  },
  services: {
    createMember: createPlayer,
    updateMember: updatePlayer
  }
});
