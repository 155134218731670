import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import _trim from "lodash/trim";

import InfiniteScroll from "@/components/InfiniteScroll";
import TotalCount from "@/components/TotalCount";
import AbilityButton from "@/components/AbilityButton";

import PlayersListSearchBar from "./SearchBar";
import PlayersListPlayer from "./Player";
import PlayersListMergeTool from "./MergeTool";
import PlayersListMergeToolFooter from "./MergeTool/Footer";

class PlayersList extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    isLoaded: PropTypes.bool,
    players: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired
      })
    ),
    totalCount: PropTypes.number,
    filteredCount: PropTypes.number,
    mergeToolStatus: PropTypes.string,
    currentQuery: PropTypes.string,
    isAppending: PropTypes.bool,
    seasonId: PropTypes.string.isRequired,
    hasMore: PropTypes.bool,
    isMergeToolEnabled: PropTypes.bool,
    loadMorePlayers: PropTypes.func,
    enableMergeTool: PropTypes.func
  };

  static defaultProps = {
    isLoading: false,
    isLoaded: false,
    hasMore: false,
    players: [],
    totalCount: 0,
    filteredCount: 0,
    mergeToolStatus: null,
    currentQuery: "",
    isAppending: false,
    isMergeToolEnabled: false,
    loadMorePlayers() {},
    enableMergeTool() {}
  };

  render() {
    const {
      players,
      seasonId,
      loadMorePlayers,
      hasMore,
      isMergeToolEnabled,
      isLoaded,
      isLoading,
      isAppending,
      enableMergeTool,
      mergeToolStatus,
      currentQuery,
      totalCount,
      filteredCount
    } = this.props;

    return (
      <Fragment>
        <PlayersListMergeTool />

        {(!isMergeToolEnabled || mergeToolStatus === "selecting-players") && (
          <Fragment>
            <div className="d-flex mb-5">
              <PlayersListSearchBar seasonId={seasonId} />

              <div className="ml-auto">
                <AbilityButton
                  subject={{ type: "players", seasonId }}
                  action="merge"
                  outline
                  color={isMergeToolEnabled ? "secondary" : "primary"}
                  size="sm"
                  onClick={enableMergeTool}
                  disabled={isMergeToolEnabled}
                >
                  Merge Players
                </AbilityButton>

                <AbilityButton
                  subject={{ type: "players", seasonId }}
                  action="create"
                  outline
                  className="ml-2"
                  color={isMergeToolEnabled ? "secondary" : "success"}
                  size="sm"
                  tag={Link}
                  to={`/seasons/${seasonId}/roster/players/new`}
                  disabled={isMergeToolEnabled}
                >
                  Create Player
                </AbilityButton>
              </div>
            </div>

            <InfiniteScroll
              dataLength={players.length}
              loadList={loadMorePlayers}
              hasMore={hasMore}
              isLoaded={isLoaded}
              isLoading={isLoading}
              isAppending={isAppending}
              emptyMessage={_trim(currentQuery) === "" ? "There are no players" : "No players found"}
              endMessage="You’ve reached the end of the players list."
            >
              <TotalCount plural="players" singular="player" totalCount={totalCount} filteredCount={filteredCount} />

              <div className="dashboard-table">
                <Table striped borderless className="players-list__table">
                  <thead>
                    <tr>
                      <th scope="col">Full name</th>
                      {!isMergeToolEnabled && (
                        <th scope="col">
                          <span className="visuallyhidden">Actions</span>
                        </th>
                      )}
                      <th scope="col">External ID</th>
                      <th scope="col">Division</th>
                      <th scope="col">Team</th>
                      {isMergeToolEnabled && (
                        <th scope="col">
                          <span className="visuallyhidden">Merge</span>
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {players.map(({ id, ...rest }) => (
                      <PlayersListPlayer key={id} id={id} {...rest} />
                    ))}
                  </tbody>
                </Table>
              </div>
            </InfiniteScroll>
          </Fragment>
        )}

        <PlayersListMergeToolFooter />
      </Fragment>
    );
  }
}

export default PlayersList;
