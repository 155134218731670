import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Button, FormText } from "reactstrap";
import { FilePicker } from "react-file-picker";
import { Preview } from "./components";

import { useFileField } from "./hooks";

import "./FileField.scss";

function FileField({ value, onChange }) {
  const { file, error } = useFileField({ value, onChange });

  return (
    <Fragment>
      <Preview value={file.value} />
      <FilePicker onChange={file.onChange} onError={error.onChange}>
        <Button className="file-field__select-button" type="button" size="sm" color="primary" outline>
          Select File
        </Button>
      </FilePicker>
      {file.value && (
        <Button type="button" size="sm" color="danger" outline onClick={file.onClear}>
          Remove File
        </Button>
      )}
      <FormText color="danger">{error.value}</FormText>
    </Fragment>
  );
}

FileField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)]),
  onChange: PropTypes.func
};

FileField.defaultProps = {
  onChange() {}
};

export default FileField;
