import { api } from "./api";
import { asyncCommandQueue } from "@/services/asyncQueue";

export default {
  preConfig: ( alias, service, config={} ) => {
    this[alias] = this[service](config);
  },

  api,

  asyncCommandQueue
}
