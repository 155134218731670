import React from "react";
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import { Can } from "@/components/Ability";

function AbilityButton({ subject, action, children, ...buttonProps }) {
  return (
    <Can do={action} on={subject}>
      <Button {...buttonProps}>{children}</Button>
    </Can>
  );
}

AbilityButton.propTypes = {
  subject: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
  action: PropTypes.string.isRequired
};

export default AbilityButton;
