import React, { PureComponent } from "react";

import { Col, Row } from "reactstrap";

import Layout from "@/layouts/Layout";
import Head from "@/components/Head";
import TitleBar from "@/components/TitleBar";


export class HandleRecoverEmail extends PureComponent {
    render() {
        return (
            <Layout>
                <Head title="Recover Email" />

                <Row className="justify-content-center">
                    <Col md="10" lg="6">
                        <TitleBar title="Recover Email" />
                        <p>
                            If your email address was changed without your knowledge, or to an address that is unrecoverable, please contact us at
                            {" "}<a href="mailto:support@gamesheetinc.com">support@gamesheetinc.com</a>.
                        </p>
                        <p>
                            Please include "Email Recovery" in the subject line and, in the main message, a phone number where we can reach you.
                        </p>
                    </Col>
                </Row>
            </Layout>
        )
    }
}
