import { createContext } from "react";

const CurrentDisplaySettings = createContext({
    breadcrumbs: window.self == window.top,
    nav: window.self == window.top
});

export default CurrentDisplaySettings;

export const Provider = CurrentDisplaySettings.Provider;
export const Consumer = CurrentDisplaySettings.Consumer;
