import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import EmptyListMessage from "@/components/EmptyListMessage";

import SuspensionReportSeasonSuspensions from "./SeasonSuspensions";

class List extends PureComponent {
  static propTypes = {
    suspensionsBySeasons: PropTypes.arrayOf(
      PropTypes.shape({
        season: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired
      })
    ).isRequired
  };

  render() {
    const { suspensionsBySeasons } = this.props;

    return (
      <div className="suspension-report__section">
        {suspensionsBySeasons.length > 0 ? (
          suspensionsBySeasons.map(({ season, suspensions }) => (
            <SuspensionReportSeasonSuspensions key={season.id} season={season} suspensions={suspensions} />
          ))
        ) : (
          <EmptyListMessage>There were no suspensions.</EmptyListMessage>
        )}
      </div>
    );
  }
}

export default List;
