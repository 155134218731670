import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

import AbilityButton from "@/components/AbilityButton";

import nl2br from "@/utils/nl2br";

class CoachesListCoach extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    seasonId: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    externalId: PropTypes.string,
    teams: PropTypes.string,
    divisions: PropTypes.string,
    isSelectedForMerge: PropTypes.bool,
    isMergeToolEnabled: PropTypes.bool,
    selectForMerge: PropTypes.func,
    deselectForMerge: PropTypes.func
  };

  static defaultProps = {
    externalId: "",
    teams: "",
    divisions: "",
    isMergeToolEnabled: false,
    isSelectedForMerge: false,
    selectForMerge() {},
    deselectForMerge() {}
  };

  toggleSelected = () => {
    const { isSelectedForMerge, selectForMerge, deselectForMerge } = this.props;

    const action = isSelectedForMerge ? deselectForMerge : selectForMerge;

    action();
  };

  render() {
    const {
      id,
      seasonId,
      firstName,
      lastName,
      externalId,
      teams,
      divisions,
      isMergeToolEnabled,
      isSelectedForMerge
    } = this.props;

    return (
      <tr className="dashboard-table-item" key={id}>
        <td>
          <Link className="full-name" to={`/seasons/${seasonId}/roster/coaches/${id}`}>
            {firstName} <span className="last-name">{lastName}</span>
          </Link>
        </td>
        {!isMergeToolEnabled && (
          <td>
            <AbilityButton
              subject={{ type: "coaches", id }}
              action="update"
              tag={Link}
              size="sm"
              color="primary"
              outline
              className="dashboard-table-item__action dashboard-table-item__action--hoverable"
              to={`/seasons/${seasonId}/roster/coaches/${id}/edit`}
            >
              Edit
            </AbilityButton>
          </td>
        )}
        <td>{externalId}</td>
        <td>{nl2br(teams)}</td>
        <td>{nl2br(divisions)}</td>
        {isMergeToolEnabled && (
          <td>
            <Button outline color={isSelectedForMerge ? "danger" : "success"} size="sm" onClick={this.toggleSelected}>
              {isSelectedForMerge ? "Deselect" : "Select"}
            </Button>
          </td>
        )}
      </tr>
    );
  }
}

export default CoachesListCoach;
