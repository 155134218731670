import { createActions } from "redux-actions";

const { teamFilter: actions } = createActions({
  TEAM_FILTER: {
    SET_ASSOCIATION_ID: undefined,
    SET_LEAGUE_ID: undefined,
    SET_SEASON_ID: undefined,
    SET_DIVISION_ID: undefined,
    SET_TEAM_ID: undefined,
    SET_ROLE: undefined,
    SET_STATUS: undefined,
    SET_QUERY: undefined,
    SET_YEAR: undefined,
    RESET: undefined,
    CLEAR: undefined
  }
});

export default actions;
