import React from "react";
import PropTypes from "prop-types";

import LoggedInRoute from "@/containers/LoggedInRoute";
import { Switch } from "react-router-dom";

import useCurrentLeague from "@/hooks/useCurrentLeague";
import useCurrentLeagueClearing from "@/hooks/useCurrentLeagueClearing";
import CurrentLeagueContext from "@/contexts/CurrentLeagueContext";
import { useSeasonsListClearing } from "@/components/SeasonsList";

import EditLeague from "./pages/EditLeague";
import SeasonsIndex from "./pages/SeasonsIndex";
import NewSeason from "./pages/NewSeason";
import ArchivedSeasonsIndex from "./pages/ArchivedSeasonsIndex";

function LeagueScope({ match: { url, params } }) {
  const { leagueId } = params;

  const currentLeague = useCurrentLeague(leagueId);

  useCurrentLeagueClearing();
  useSeasonsListClearing();

  return (
    <CurrentLeagueContext.Provider value={{ ...currentLeague, leagueId }}>
      <Switch>
        <LoggedInRoute path={`${url}/seasons/archived`} component={ArchivedSeasonsIndex} />
        <LoggedInRoute path={`${url}/seasons/new`} component={NewSeason} />
        <LoggedInRoute path={`${url}/seasons`} component={SeasonsIndex} />
        <LoggedInRoute path={`${url}/edit`} component={EditLeague} />
      </Switch>
    </CurrentLeagueContext.Provider>
  );
}

LeagueScope.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      leagueId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default LeagueScope;
