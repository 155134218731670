const GAME_TYPES = {
  exhibition: "Exhibition",
  playoff: "Playoff",
  regular_season: "Regular Season",
  tournament: "Tournament"
};

export default GAME_TYPES;

export const list = Object.entries(GAME_TYPES).map(([key, value]) => ({
  code: key,
  title: value
}));

export const GAME_TYPES_LIST = list;

export const GAME_TYPE_VALUES = Object.keys(GAME_TYPES);
