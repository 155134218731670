import { all, put, call, takeLatest, takeEvery } from "redux-saga/effects";

import { loadDivisions } from "@/lib/api/divisions";
import { fetchList, gamesheetAPIRequest } from "@/redux/api/sagas";

import { loadingRoutine, lockTeamsRoutine } from "./routines";

import { updateDivisionRosterLock } from "@/lib/api/divisions";

function* loadingSaga({ payload: { seasonId, pageSize, pageNumber, sort } }) {
  yield put(loadingRoutine.request());

  try {
    const { divisions, ids, totalCount, data } = yield call(fetchList, "divisions", loadDivisions, {
      seasonId,
      pageSize,
      pageNumber,
      sort
    });

    yield put(
      loadingRoutine.success({
        ids,
        teams: (data && data.teams) || {},
        divisions,
        totalCount
      })
    );
  } catch (error) {
    const responseStatus = error.response ? error.response.status : null;

    yield put(loadingRoutine.failure({ error, responseStatus }));

    throw error;
  } finally {
    yield put(loadingRoutine.fulfill());
  }
}

function* lockTeamsSaga({ payload: { lock, divisionId, successCb } }) {
  yield put(lockTeamsRoutine.request({ lock, divisionId }));

  try {
    yield call(
      gamesheetAPIRequest,
      updateDivisionRosterLock,
      {
        divisionId,
        attributes: { lock }
      },
      true
    );

    yield put(lockTeamsRoutine.success({ lock, divisionId }));

    if (successCb && typeof successCb === "function") {
      successCb();
    }
  } catch (error) {
    const { response } = error;

    yield put(
      lockTeamsRoutine.failure({
        error,
        response,
        divisionId
      })
    );
  } finally {
    yield put(lockTeamsRoutine.fulfill({ lock, divisionId }));
  }
}

export function* divisionsListFlow() {
  yield all([takeLatest(loadingRoutine, loadingSaga), takeEvery(lockTeamsRoutine, lockTeamsSaga)]);
}
