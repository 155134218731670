import { connect } from "react-redux";

import {
  getRefereeReportListSearchNextQuery,
  getRefereeReportListSearchCurrentQuery,
  getRefereeReportListSearchQueryIsDirty,
  getRefereeReportListIsLoading
} from "@/redux/refereeReport/selectors";

import actions from "@/redux/refereeReport/actions";

import SearchBar from "./SearchBar";

const mapStateToProps = state => ({
  nextQuery: getRefereeReportListSearchNextQuery(state),
  currentQuery: getRefereeReportListSearchCurrentQuery(state),
  isDirty: getRefereeReportListSearchQueryIsDirty(state),
  isLoading: getRefereeReportListIsLoading(state)
});

const mapDispatchToProps = dispatch => ({
  setQuery: query => dispatch(actions.list.search.setNextQuery(query)),
  commit: () => dispatch(actions.list.search.commit()),
  clear: () => dispatch(actions.list.search.clear())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBar);
