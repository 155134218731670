import React from "react";
import useScrollTo from "@/hooks/useScrollTo";
import { Link } from "react-router-dom";

export const getColors = () => ({
  red: "#FF6926",
  supernova: "#FEC307",
  blue: "#097AFF",
  green: "#34B632",
  live_blue: "#88d5ff",
});

export const groupFlags = ({ flags }) => {
  const colors = getColors();

  // colour the flags based on their event type
  const groupedFlags = flags.reduce((acc, flag) => {
    switch (flag.event) {
      case "referee_penalty_note":
      case "referee_game_note":
      case "penalty":
        acc["red"] = acc["red"] || [];
        acc["red"].push(flag);
        break;
      case "unlocked":
        acc["red"] = acc["red"] || [];
        acc["red"].push(flag);
        break;
      case "suspension_served":
        acc["supernova"] = acc["supernova"] || [];
        acc["supernova"].push(flag);
        break;
      case "suspension_lifted":
          acc["live_blue"] = acc["live_blue"] || [];
          acc["live_blue"].push(flag);
          break;
      case "added_at_game_time":
        acc["blue"] = acc["blue"] || [];
        acc["blue"].push(flag);
        break;
      default:
        acc["green"] = acc["green"] || [];
        acc["green"].push(flag);
    }

    return acc;
  }, {});

  // order matters here: we want red, supernova, blue, then green
  const out = [];

  if ("red" in groupedFlags) {
    out.push({
      color: colors["red"],
      flags: groupedFlags["red"],
    });
  }

  if ("supernova" in groupedFlags) {
    out.push({
      color: colors["supernova"],
      flags: groupedFlags["supernova"],
    });
  }

  if ("live_blue" in groupedFlags) {
    out.push({
      color: colors["live_blue"],
      flags: groupedFlags["live_blue"],
    });
  } 

  if ("blue" in groupedFlags) {
    out.push({
      color: colors["blue"],
      flags: groupedFlags["blue"],
    });
  }

  if ("green" in groupedFlags) {
    out.push({
      color: colors["green"],
      flags: groupedFlags["green"],
    });
  }

  return out;
};

// summarize a flag into a short human-readable text
export const makeFlagMessage = ({ flag, noLink = false, prLink = false }) => {
  let msg = "Unexpected flag";
  const { event, code, label, member, team } = flag;

  const { scrollTo: scrollToPenalties } = useScrollTo("penalties");

  switch (event) {
    case "scorekeeper_timeout_note":
      msg = `Timeout by ${team}`;
      break;
    case "scorekeeper_injury_note":
      if (!member || !member.trim()) {
        msg = `Injury on ${team}`;
      } else {
        msg = `Injury to ${member} on ${team}`;
      }
      break;
    case "scorekeeper_general_note":
      msg = `General note by scorekeeper`;
      break;
    case "referee_penalty_note":
      msg = `Penalty note by referee on ${code}`;
      break;
    case "referee_game_note":
      msg = "Game note by referee";
      break;
    case "non_affiliated_player":
      msg = `Non-affiliated Player ${member} on ${team}`;
      break;
    case "added_at_game_time":
      if (!member || !member.trim()) {
        msg = `Player on ${team} added at game time `;
      } else {
        msg = `Player ${member} on ${team} added at game time`;
      }
      break;
    case "penalty":
      let text = code === label ? code + " penalty" : code + " - " + label;
      msg = noLink ? (
        <span>{text}</span>
      ) : (
        <a role="button" onClick={scrollToPenalties}>
          {text}
        </a>
      );
      break;
    case "suspension_served":
      msg = `Suspension served by ${member} on ${team}`;
      break;
    case "suspension_lifted":
      if (member.name === 'Player' || member.name === 'Coach') {
        msg = `A ${member.name} on ${team} has been activated from suspension`;
      } else if (member === 'Player' || member === 'Coach') {
        msg = `A ${member} on ${team} has been activated from susension`;
      } else if (member.name) {
        msg = prLink && member.seasonId !== "" && member.id !== "" && member.type !== ""
          ? (
            <span>
              <Link target={`${member.name} - penalty-report`} to={`/seasons/${member.seasonId}/roster/${member.type}/${member.id}/penalty-report`}>{member.name}</Link> has been activated from suspension
            </span>
          )
          : `${member.name} has been activated from suspension`;
      } else {
        msg = `${member} has been activated from suspension`;
      }
      break;
    case "unlocked":
      msg = "Game was unlocked";
      break;
  }

  return msg;
};
