import build from "redux-object";

export const getResources = (state, type, ids = null) => build(state.resources, type, ids) || [];

export const getResource = (state, type, id) => {
  if (id === undefined || id === null) {
    throw new Error("`id` required");
  }

  return build(state.resources, type, id);
};
