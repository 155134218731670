import { connect } from "react-redux";

import { getSeasonRosterCoachesMergeToolStatus } from "@/redux/seasonRoster/selectors";
import { seasonRosterActions } from "@/redux/seasonRoster/actions";

import CoachesListMergeTool from "./MergeTool";

const mapStateToProps = state => ({
  status: getSeasonRosterCoachesMergeToolStatus(state)
});

const mapDispatchToProps = dispatch => ({
  setStatus: status => dispatch(seasonRosterActions.coaches.mergeTool.setStatus(status))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoachesListMergeTool);
