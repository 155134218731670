import { connect } from "react-redux";

import { getSeasonRosterCoachesMergeToolSeasonId } from "@/redux/seasonRoster/selectors";

import { seasonRosterActions } from "@/redux/seasonRoster/actions";
import { loadSeasonCoachesRosterRoutine } from "@/redux/seasonRoster/routines";

import CoachesListMergeToolFailure from "./Failure";

const mapStateToProps = state => ({
  seasonId: getSeasonRosterCoachesMergeToolSeasonId(state)
});

const mapDispatchToProps = dispatch => ({
  exitMergeMode: seasonId => {
    dispatch(loadSeasonCoachesRosterRoutine({ seasonId }));
    dispatch(seasonRosterActions.coaches.mergeTool.disable());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoachesListMergeToolFailure);
