import React from "react";

import FilterSelect from "./FilterSelect";

import useLeagueSelect from "./hooks/useLeagueSelect";

function LeagueSelect() {
  const { options, selectedId, isLoaded, isLoading, setSelectedId } = useLeagueSelect();

  return (
    <FilterSelect
      placeholder="All leagues"
      options={options}
      value={selectedId}
      isLoaded={isLoaded}
      isLoading={isLoading}
      onChange={setSelectedId}
    />
  );
}

export default LeagueSelect;
