import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { Table, Label, CustomInput, Button } from "reactstrap";

import TitleBar from "@/components/TitleBar";
import { useDashboardFormNamespaceContext } from "@/components/DashboardForm";

import { useFlaggingCriteriaFields } from "../hooks";

import CountPenalties from "../CountPenalties";

function FlaggingCriteria(props) {
  const { value, onChange, openPenaltyPicker, countSelectedPenalties, countMissingPenalties, sport } = props;

  const namespace = useDashboardFormNamespaceContext();

  const { criterions, toggleAllSelected, countSelected, isAllSelected } = useFlaggingCriteriaFields({
    value,
    onChange,
    sport
  });

  return (
    <Fragment>
      <TitleBar title="Flagging Criteria" titleTag="h4" slim />

      <Table striped borderless>
        <thead>
          <tr>
            <th className="narrow">
              <CustomInput
                id={`${namespace}__game-flagging-settings__flagging-criteria__toggle-all`}
                type="checkbox"
                label={<span>&nbsp;</span>}
                checked={isAllSelected}
                onChange={toggleAllSelected}
                innerRef={el => el && (el.indeterminate = countSelected > 0 && !isAllSelected)}
              />
            </th>
            <th colSpan="2">Flagging Criteria</th>
          </tr>
        </thead>
        <tbody>
          {criterions.map(({ id, name, input, label, enabled }) => (
            <tr key={id}>
              <td>
                <CustomInput type="checkbox" {...input} />
              </td>
              <td colSpan={name !== "penalty" || !enabled ? "2" : undefined}>
                <Label for={id}>{label}</Label>
              </td>
              {name === "penalty" &&
                enabled && (
                  <td>
                    <div className="d-flex">
                      <div>
                        <Button type="button" size="sm" color="primary" onClick={openPenaltyPicker} outline>
                          Select {sport === 'soccer' ? "Infractions" : "Penalties"}
                        </Button>
                      </div>
                      <div className="align-self-center ml-2">
                        <span className="text-secondary">
                          {countSelectedPenalties === 0 ? (
                            <span className="text-danger">At least one {sport === 'soccer' ? "infraction" : "penalty"} type must be selected</span>
                          ) : (
                            <CountPenalties
                              totalCountSelected={countSelectedPenalties}
                              totalCountMissing={countMissingPenalties}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  </td>
                )}
            </tr>
          ))}
        </tbody>
      </Table>
    </Fragment>
  );
}

FlaggingCriteria.propTypes = {
  value: PropTypes.shape({}).isRequired,
  countSelectedPenalties: PropTypes.number,
  countMissingPenalties: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  openPenaltyPicker: PropTypes.func.isRequired
};

export default FlaggingCriteria;
