import { handleActions } from "redux-actions";

import { teamLoadingRoutine } from "../routines";
import actions from "../actions";

const initialState = {};

function reduceLoadingRequest(state, { payload: { teamId } }) {
  return {
    ...state,
    [teamId]: {
      error: null,
      isLoading: false,
      isLoaded: false,
      isFailed: true
    }
  };
}

function reduceLoadingSuccess(state, { payload: { team } }) {
  return {
    ...state,
    [team.id]: {
      ...state[team.id],
      isLoaded: true,
      title: team.title,
      divisionId: team.division.id,
      seasonId: team.season.id
    }
  };
}

function reduceLoadingFailure(state, { payload: { teamId, error } }) {
  return {
    ...state,
    [teamId]: {
      ...state[teamId],
      isFailed: true,
      error
    }
  };
}

function reduceLoadingFulfill(state, { payload: { teamId } }) {
  return {
    ...state,
    [teamId]: {
      ...state[teamId],
      isLoading: false
    }
  };
}

export default handleActions(
  {
    [teamLoadingRoutine.REQUEST]: reduceLoadingRequest,
    [teamLoadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [teamLoadingRoutine.FAILURE]: reduceLoadingFailure,
    [teamLoadingRoutine.FULFILL]: reduceLoadingFulfill,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
