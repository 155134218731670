import React from "react";
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import AbilityLink from "@/components/AbilityLink";

import useTeamToggling from "./hooks/useTeamToggling";

function Team(props) {
  const { id, title, association, league, season, division, isSelected, isAvailable, allowDeselect } = props;

  const { toggleSelected } = useTeamToggling(id, isSelected);

  return (
    <tr className="dashboard-table-item">
      <td className="align-bottom">
        <AbilityLink
          target="_blank"
          className="text-dark"
          to={`/seasons/${season.id}/teams/${id}/roster`}
          subject={{ type: "teams", id }}
          action="read"
          fallback={title}
        >
          {title}
        </AbilityLink>
      </td>
      <td>
        <small>
          {association.title} » {league.title} » {season.title}
        </small>
        <br />
        {division.title}
      </td>
      <td>
        {isAvailable ? (
          <Button
            outline
            type="button"
            color={isSelected ? (allowDeselect ? "danger" : "secondary") : "success"}
            size="sm"
            onClick={toggleSelected}
            disabled={!allowDeselect && isSelected}
          >
            {isSelected ? (allowDeselect ? "Deselect" : "Selected") : "Select"}
          </Button>
        ) : (
          <Button outline type="button" color="secondary" size="sm" disabled>
            Unavailable
          </Button>
        )}
      </td>
    </tr>
  );
}

Team.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  association: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }),
  league: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }),
  season: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }),
  division: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }),
  isSelected: PropTypes.bool.isRequired,
  isAvailable: PropTypes.bool.isRequired,
  allowDeselect: PropTypes.bool
};

Team.defaultProps = {
  allowDeselect: false
};

export default Team;
