import _isEmpty from "lodash/isEmpty";

// The original component this was used for has been replaced by a 16.8 component.

const selectRoot = state => state.scheduledGamesFilter;

export const getFilter = state => selectRoot(state);

export const getFilterIsDirty = state => Object.values(selectRoot(state)).find(value => !_isEmpty(value)) !== undefined;

export const getFilterValue = (state, fieldName) => selectRoot(state)[fieldName];

// This is the only one in use
export const getUrlSearchParamsInfo = state => ({
  seasonId: getFilter(state).seasonId,
  urlSearchParams: getFilter(state).urlSearchParams
});
