import moment from "moment";
import { all, takeLatest, put } from "redux-saga/effects";

import { loadGames, exportSeasonGames } from "@/lib/api/games";

import { fetchList, gamesheetAPIRequest } from "@/redux/api/sagas";
import { gamesCountLoadingRoutine, submittingRoutine } from "./routines";

function* gamesCountLoadingSaga({ payload: { seasonId } }) {
  yield put(gamesCountLoadingRoutine.request());

  const { totalCount } = yield fetchList("games", loadGames, {
    pageNumber: 1,
    pageSize: 1,
    seasonId
  });

  yield put(gamesCountLoadingRoutine.success({ count: totalCount }));
  yield put(gamesCountLoadingRoutine.fulfill());
}

function* submittingSaga({ payload }) {
  const {
    values: { email },
    seasonId
  } = payload;

  yield put(submittingRoutine.request());

  try {
    yield gamesheetAPIRequest(exportSeasonGames, {
      seasonId,
      email,
      timezone: Number(moment().format("ZZ") / 100).toString()
    });
    yield put(submittingRoutine.success());
  } catch (error) {
    throw new Error("Failed to send export games request");
  } finally {
    yield put(submittingRoutine.fulfill());
  }
}

export function* exportSeasonGamesFlow() {
  yield all([
    takeLatest(gamesCountLoadingRoutine.TRIGGER, gamesCountLoadingSaga),
    takeLatest(submittingRoutine.TRIGGER, submittingSaga)
  ]);
}
