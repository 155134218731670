const selectRoot = state => state.divisionsList;

export const getIsLoaded = state => selectRoot(state).isLoaded;
export const getIsLoading = state => selectRoot(state).isLoading;
export const getIsFailed = state => selectRoot(state).isFailed;
export const getIsForbidden = state => selectRoot(state).isForbidden;
export const getTotalCount = state => selectRoot(state).totalCount;

export const getDivisionsLocking = state => selectRoot(state).isLockingTeams;

export const getList = state => {
  const { ids, data } = selectRoot(state);

  return ids.map(id => ({ ...data[id], id }));
};
