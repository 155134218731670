import React, { Fragment } from "react";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import Breadcrumbs from "@/components/Breadcrumbs";
import Head from "@/components/Head";
import PageLoader from "@/components/PageLoader";

import ScheduledGamesCSVImportWizard, {
  useScheduledGamesCSVImportWizardClearing
} from "@/components/ScheduledGamesCSVImportWizard";

function ImportScheduledGames() {
  const { seasonIsLoading, seasonIsLoaded, seasonIsForbidden, seasonBreadcrumbs, seasonId } = useCurrentSeasonContext();

  useScheduledGamesCSVImportWizardClearing();

  return (
    <ProtectedLayout condition={!seasonIsForbidden}>
      {seasonIsLoaded ? (
        <Fragment>
          <Head title="Import Scheduled Games" />
          <Breadcrumbs
            items={
              seasonBreadcrumbs && [
                ...seasonBreadcrumbs,
                {
                  title: "Scheduled Games",
                  url: `/seasons/${seasonId}/games/scheduled`
                }
              ]
            }
          />

          <ScheduledGamesCSVImportWizard />
        </Fragment>
      ) : (
        <PageLoader isLoading={seasonIsLoading} />
      )}
    </ProtectedLayout>
  );
}

export default ImportScheduledGames;
