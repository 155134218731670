import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getMeta, getPlayer, loadingRoutine } from "@/redux/currentPlayer";

export default function useCurrentPlayer({ seasonId, playerId }) {
  const dispatch = useDispatch();

  const {
    isLoaded: playerIsLoaded,
    isLoading: playerIsLoading,
    isForbidden: playerIsForbidden,
    isFailed: playerIsFailed
  } = useSelector(getMeta);

  const player = useSelector(getPlayer);
  const { firstName, lastName, association, league, season, teams } = player;
  const fullName = `${firstName} ${lastName}`;

  const playerFormFields = {
    ...[
      "firstName",
      "lastName",
      "externalId",
      "biography",
      "height",
      "weight",
      "shotHand",
      "birthdate",
      "hometown",
      "country",
      "province",
      "draftedBy",
      "committedTo"
    ].reduce((result, attribute) => ({ ...result, [attribute]: player[attribute] }), {}),
    photo: player.photoUrl
  };

  const playerBreadcrumbs = playerIsLoaded
    ? [
        { title: "Associations", url: "/associations" },
        {
          title: association.title,
          url: `/associations/${association.id}/leagues`
        },
        {
          title: league.title,
          url: `/leagues/${league.id}/seasons`
        },
        {
          title: season.title,
          url: `/seasons/${season.id}`
        },
        {
          title: "Players",
          url: `/seasons/${season.id}/roster/players`
        },
        {
          title: fullName,
          url: `/seasons/${season.id}/roster/players/${playerId}`
        }
      ]
    : [];

  useEffect(
    () => {
      dispatch(loadingRoutine({ seasonId, playerId }));
    },
    [dispatch, seasonId, playerId]
  );

  return {
    player,
    playerId,
    playerIsLoading,
    playerIsLoaded,
    playerIsForbidden,
    playerIsFailed,
    playerBreadcrumbs,
    playerFormFields,
    playerFullName: fullName,
    playerTeams: teams
  };
}
