import React, { Fragment } from "react";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";
import UserForm from "@/components/UserForm";
import RoleForm from "@/components/RoleForm";

import useUserForm from "../hooks/useUserForm";
import useRoleForm from "../hooks/useRoleForm";

function UsersNew() {
  useUserForm();

  const { roleFormIsVisible, toggleRole, removeRole } = useRoleForm();

  return (
    <ProtectedLayout subject="users" action="create">
      <Head title="New User" />

      <Breadcrumbs items={[{ title: "Users", url: "/users" }]} />

      {roleFormIsVisible ? (
        <RoleForm onSubmit={toggleRole} onRemove={removeRole} />
      ) : (
        <Fragment>
          <TitleBar title="New User" />
          <UserForm />
        </Fragment>
      )}
    </ProtectedLayout>
  );
}

export default UsersNew;
