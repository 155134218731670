import { useCallback } from "react";

export default function useGameStatsSettingsFields({ value, onChange }) {
  const {
    playerStatsDisplay,
    goalValue,
    assistValue,
    pointSystem,
    tieBreakers,
    fairPlayPoints,
    maxGoalDifferential
  } = value;

  const changePointsValue = useCallback(
    ({ target: { name, value: pointsValue } }) => {
      const parsedValue = parseInt(pointsValue) || 0;

      onChange({ ...value, [name]: parsedValue });
    },
    [onChange, value]
  );

  const changePlayerStatsDisplay = useCallback(
    ({ target: { value: playerStatsDisplay } }) => onChange({ ...value, playerStatsDisplay }),
    [onChange, value]
  );

  const changePointSystemValue = useCallback(pointSystem => onChange({ ...value, pointSystem }), [onChange, value]);

  const changeTieBreakersValue = useCallback(
    tieBreakers => {
      onChange({ ...value, tieBreakers });
    },
    [onChange, value]
  );

  const changeFairPlayPointsValue = useCallback(
    fairPlayPoints => {
      onChange({ ...value, fairPlayPoints });
    },
    [onChange, fairPlayPoints]
  );

  const changeMaxGoalDifferentialValue = useCallback(
    maxGoalDifferential => {
      onChange({ ...value, maxGoalDifferential });
    },
    [onChange, value]
  );

  return {
    playerStatsDisplay: {
      value: playerStatsDisplay,
      onChange: changePlayerStatsDisplay
    },
    goalValue: {
      value: goalValue,
      onChange: changePointsValue
    },
    assistValue: {
      value: assistValue,
      onChange: changePointsValue
    },
    pointSystem: {
      value: pointSystem,
      onChange: changePointSystemValue
    },
    tieBreakers: {
      value: tieBreakers,
      onChange: changeTieBreakersValue
    },
    fairPlayPoints: {
      value: fairPlayPoints,
      onChange: changeFairPlayPointsValue
    },
    maxGoalDifferential: {
      value: maxGoalDifferential,
      onChange: changeMaxGoalDifferentialValue
    }
  };
}
