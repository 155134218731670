import { all, put, call, select, takeLatest } from "redux-saga/effects";
import axios from 'axios';
import { push } from "connected-react-router";

import { submittingRoutine } from "./routines";
import { actions as currentSeasonActions } from "@/redux/currentSeason";
import { config } from "../../config";
import { deepNormalizeObject } from "@/lib/api/utils";
import { getFreshToken } from "@/lib/api/getFreshToken";

function* submittingSaga({ payload: { data, leagueId } }) {
  yield put(submittingRoutine.request());

  const event = {
    event: "season-clone",
    attributes: {
      schema: "clone-season-event"
    },
    data: {
      new: deepNormalizeObject(data.new),
      old: deepNormalizeObject(data.old),
      options: data.options
    },
  }

  const token = yield call(getFreshToken);

  try {
    if (!token) {
      throw "No token"
    }

    const url = `${config.BFF_API}/seasons`;
    const response = yield call(
      () => axios.post(
        url,
        event,
        {
          headers: {
            "Authorization": `Bearer ${token}`,
          },
        }
      )
    );

    if (response.status === 200 && response.data.status === "success") {
      yield put(submittingRoutine.success());
      yield put(currentSeasonActions.clear()); // otherwise form data on the edit page is not fresh
      yield put(push(`/seasons/${response.data.data["season-created"].id}`));
    } else {
      throw response
    }
  } catch (error) {
    console.error(error);
    yield put(submittingRoutine.failure({ error }));
    yield put(push(`/leagues/${leagueId}/seasons`));
  } finally {
    yield put(submittingRoutine.fulfill());
  }
}

export function* cloneSeasonFormFlow() {
  yield all([
    takeLatest(submittingRoutine, submittingSaga),
  ]);
}
