import { connect } from "react-redux";

import { getGameFormTeamPlayers, getGameFormTeamCoaches } from "@/redux/gameForm/selectors";

import TeamMemberInput from "./TeamMemberInput";

const mapStateToProps = (state, { team }) => ({
  players: getGameFormTeamPlayers(state, team),
  coaches: getGameFormTeamCoaches(state, team)
});

export default connect(mapStateToProps)(TeamMemberInput);
