import { all, put, call, takeLatest, select } from "redux-saga/effects";
import { push, getLocation } from "connected-react-router";

import { createPlayer, updatePlayer, deletePlayer } from "@/lib/api/players";
import { createFile } from "@/lib/api/file";
import { responseErrorsFullMessages } from "@/lib/api/utils";
import { gamesheetAPIRequest } from "@/redux/api/sagas";

import { FIELDS_MAPPING } from "@/components/PlayerForm";

import { submittingRoutine, deletingRoutine } from "./routines";

function canvasToFile(canvas) {
  return new Promise(resolve => {
    canvas.toBlob(
      blob => {
        const file = new File([blob], canvas.fileName, {
          type: canvas.fileType
        });

        resolve(file);
      },
      canvas.fileType,
      0.85
    );
  });
}

function* getRedirectUrl({ seasonId, playerId }) {
  const location = yield select(getLocation);
  const { from } = (location || {}).state || {};

  return from && from.type === "teamRoster"
    ? `/seasons/${from.seasonId}/teams/${from.teamId}/roster/players`
    : playerId
      ? `/seasons/${seasonId}/roster/players/${playerId}`
      : `/seasons/${seasonId}/roster/players`;
}

function* uploadPhotoSaga(fileOrCanvas) {
  const file = fileOrCanvas instanceof HTMLCanvasElement ? yield call(canvasToFile, fileOrCanvas) : fileOrCanvas;

  const {
    data: { data }
  } = yield call(gamesheetAPIRequest, createFile, {
    file
  });

  return data.attributes.url;
}

function* submittingSaga({ payload }) {
  const { seasonId, playerId, values } = payload;
  const { photo, ...attributes } = values;

  yield put(submittingRoutine.request());

  try {
    const service = playerId ? updatePlayer : createPlayer;

    const resp = yield call(
      gamesheetAPIRequest,
      service,
      {
        attributes: {
          ...attributes,
          photoUrl: photo instanceof Object ? yield call(uploadPhotoSaga, photo) : photo
        },
        identity: { type: "players", id: playerId },
        seasonId
      },
      true
    );

    yield put(submittingRoutine.success());

    const redirectUrl = yield getRedirectUrl({
      seasonId,
      playerId: resp.rawData.id
    });

    yield put(push(redirectUrl));
  } catch (error) {
    const { response } = error;
    const validationErrors = response ? responseErrorsFullMessages(response, FIELDS_MAPPING) : {};

    yield put(submittingRoutine.failure({ error, validationErrors }));
  } finally {
    yield put(submittingRoutine.fulfill());
  }
}

function* deletingSaga({ payload: { seasonId, playerId } }) {
  yield put(deletingRoutine.request());

  try {
    yield gamesheetAPIRequest(deletePlayer, {
      identity: { type: "players", id: playerId },
      seasonId
    });

    yield put(deletingRoutine.success());

    const redirectUrl = yield getRedirectUrl({ seasonId, playerId: null });

    yield put(push(redirectUrl));
  } catch (e) {
    yield put(deletingRoutine.failure({ error: e }));
  } finally {
    yield put(deletingRoutine.fulfill());
  }
}

export function* playerFormFlow() {
  yield all([takeLatest(submittingRoutine, submittingSaga), takeLatest(deletingRoutine, deletingSaga)]);
}
