import React from "react";
import PropTypes from "prop-types";

import { Switch } from "react-router-dom";

import LoggedInRoute from "@/containers/LoggedInRoute";

import BillingReportDataSourcesPage from "./DataSourcesPage";
import BillingReportPage from "./ReportPage";
import useDataSourcesClearing from "./hooks/useDataSourcesClearing";

function ReportsRoute({ match }) {
  useDataSourcesClearing();

  return (
    <Switch>
      <LoggedInRoute path={`${match.url}/sources`} component={BillingReportDataSourcesPage} />

      <LoggedInRoute path="" component={BillingReportPage} />
    </Switch>
  );
}

ReportsRoute.propTypes = {
  match: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired
};

export default ReportsRoute;
