import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import AbilityButton from "@/components/AbilityButton";

function AddPlayerButton({ children, outline, teamId, disabled, action, url }) {
  return (
    <AbilityButton
      subject={{ type: "roster", teamId }}
      action={action}
      outline={outline}
      color="success"
      className="mb-3"
      size="sm"
      tag={Link}
      to={url}
      disabled={disabled}
    >
      {children}
    </AbilityButton>
  );
}

AddPlayerButton.propTypes = {
  children: PropTypes.node.isRequired,
  teamId: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  outline: PropTypes.bool,
  disabled: PropTypes.bool
};

AddPlayerButton.defaultProps = {
  outline: false,
  disabled: false
};

export default AddPlayerButton;
