import { useContext } from "react";

import CurrentGameContext from "@/contexts/CurrentGameContext";

import { GAME_EXPIRATION_DAYS } from "../constants";

export default function useGameIsExpired() {
  const { game } = useContext(CurrentGameContext);
  const { startTime } = game;

  const gameDate = new Date(startTime).getTime();
  const now = new Date().getTime();

  return Math.round(now - gameDate) > GAME_EXPIRATION_DAYS * 24 * 60 * 60 * 1000;
}
