import _get from "lodash/get";
import { handleActions, combineActions } from "redux-actions";

import {
  loadingRoutine,
  playerSubmittingRoutine,
  playerRemovingRoutine,
  coachSubmittingRoutine,
  coachRemovingRoutine,
  lockingRoutine
} from "../routines";

import actions from "../actions";

const initialState = {
  isLoading: false,
  isLoaded: false,
  isForbidden: false,
  isOperating: false,
  isArchived: false,
  isRosterLocking: false,
  error: null
};

function reduceLoadingRequest(state) {
  return { ...state, isLoading: true, isRosterLocking: false };
}

function reduceLoadingSuccess(state) {
  return { ...state, isLoaded: true };
}

function reduceLoadingFailure(state, { payload: { error } }) {
  const status = _get(error, "response.status");
  const code = _get(error, "response.data.errors[0].code");
  const pointer = _get(error, "response.data.errors[0].source.pointer");

  return {
    ...state,
    isForbidden: status === 401,
    isArchived: status === 403 && code === "archived" && pointer === "/",
    error
  };
}

function reduceLoadingFulfill(state) {
  return { ...state, isLoading: false };
}

function reduceOperatingRequest(state) {
  return { ...state, isOperating: true };
}

function reduceOperatingFulfill(state) {
  return { ...state, isOperating: false };
}

function reduceRosterLockRequest(state) {
  return { ...state, isRosterLocking: true };
}

function reduceRosterLockFulfill(state) {
  return { ...state, isRosterLocking: false };
}

export default handleActions(
  {
    [loadingRoutine.REQUEST]: reduceLoadingRequest,
    [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [loadingRoutine.FAILURE]: reduceLoadingFailure,
    [loadingRoutine.FULFILL]: reduceLoadingFulfill,
    [lockingRoutine.REQUEST]: reduceRosterLockRequest,
    [lockingRoutine.FULFILL]: reduceRosterLockFulfill,
    [combineActions(
      playerSubmittingRoutine.REQUEST,
      playerRemovingRoutine.REQUEST,
      coachSubmittingRoutine.REQUEST,
      coachRemovingRoutine.REQUEST
    )]: reduceOperatingRequest,
    [combineActions(
      playerSubmittingRoutine.FULFILL,
      playerRemovingRoutine.FULFILL,
      coachSubmittingRoutine.FULFILL,
      coachRemovingRoutine.FULFILL
    )]: reduceOperatingFulfill,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
