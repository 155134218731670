import { useCallback } from "react";

export function useDivisionPenaltySettingsFields({ value, onChange }) {
  const { penaltyDurations } = value;

  const changePenaltyDurations = useCallback(penaltyDurations => onChange({ ...value, penaltyDurations }), [onChange, value]);

  return {
    penaltyDurations: {
      value: penaltyDurations,
      onChange: changePenaltyDurations
    }
  };
}
