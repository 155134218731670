import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { FormFeedback } from "reactstrap";
import ReactDateInput from "react-datetime";

import useSetScheduledDateValue from "./hooks/useSetScheduledDateValue";

function ScheduledDateInput(props) {
  const { id, value, setValue, errors, isInvalid, isDirty } = props;

  const onChange = useSetScheduledDateValue(setValue);

  return (
    <Fragment>
      <ReactDateInput
        id={id}
        dateFormat="YYYY-MM-DD"
        timeFormat={false}
        inputProps={{
          className: classNames("form-control", {
            "is-invalid": isDirty && isInvalid
          }),
          onChange: () => {},
          placeholder: "YYYY-MM-DD"
        }}
        closeOnSelect={true}
        onChange={onChange}
        value={value}
      />

      {isDirty &&
        errors.map(error => (
          <FormFeedback style={{ display: "block" }} key={error}>
            {error}
          </FormFeedback>
        ))}
    </Fragment>
  );
}

ScheduledDateInput.propTypes = {
  id: PropTypes.string.isRequired,
  minDate: PropTypes.string,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  isDirty: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired
};

ScheduledDateInput.defaultProps = {
  minDate: ""
};

export default ScheduledDateInput;
