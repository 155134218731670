import { selector } from 'recoil';

import { locationQueryState, locationInputFocusState } from '../../QueryInput/state';

export const hideLocationResultsState = selector({
  key: 'hideLocationResults',
  get: ({ get }) => {
    const query = get(locationQueryState);
    const focus = get(locationInputFocusState);
    
    return (!focus || query.length < 3);
  }
})