import { createActions } from "redux-actions";

const { gameReportEmailNotificationSettings: actions } = createActions({
  GAME_REPORT_EMAIL_NOTIFICATION_SETTINGS: {
    ENABLE_ALL: undefined,
    DISABLE_ALL: undefined,
    CLEAR: undefined
  }
});

export default actions;
