import React, { Fragment, useCallback } from "react";
import PropTypes from "prop-types";

import { Input, FormFeedback } from "reactstrap";

import useField from "./hooks/useField";

function Field({ id, name }) {
  const { value, setValue, isInvalid, errors } = useField(name);

  const handleChange = useCallback(({ target: { value } }) => setValue(value), [setValue]);

  return (
    <Fragment>
      <Input id={id} value={value} onChange={handleChange} invalid={isInvalid} type="password" />

      {errors.map(error => (
        <FormFeedback key={error}>{error}</FormFeedback>
      ))}
    </Fragment>
  );
}

Field.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default Field;
