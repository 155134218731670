import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { locationQueryState, locationInputFocusState } from '../QueryInput/state';

import { hideLocationResultsState } from './state';

export default function useSearchResults({ hits }) { 
  const setLocationQuery = useSetRecoilState(locationQueryState);
  const setlocationInputFocus = useSetRecoilState(locationInputFocusState);
  const hideLocationResults = useRecoilValue(hideLocationResultsState);
  const [venues, setVenues] = useState([]);

  const setLocation = (compositeKey) => {
    setLocationQuery(compositeKey);
    setlocationInputFocus(false);
  }
  
  useEffect(() => {
    if (!hideLocationResults && hits) {
      const newVenues = hits.map(hit => ({
        name: hit.name,
        city: hit.city,
        province: hit.province.name,
        postalCode: hit.postalCode,
        surfaces: hit.surfaces.map(surface => ({
          name: surface.name,
          id: surface.id,
          compositeKey: surface.compositeKey
        }))
      }));
      setVenues(newVenues);
    } else {
      if (venues.length > 0) {
        setVenues([]);
      }
    }
  }, [hits, hideLocationResults]);
  
  return {
    venues,
    setLocation
  };
};