import { useCallback } from "react";
import { useDispatch } from "react-redux";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import { toggleGameViewedRoutine } from "@/redux/games/routines";

export default function useToggleViewedStatus({ id, isViewed }) {
  const { seasonId } = useCurrentSeasonContext();
  const dispatch = useDispatch();

  return useCallback(() => dispatch(toggleGameViewedRoutine.trigger({ id, seasonId, isViewed: !isViewed })), [
    dispatch,
    id,
    seasonId,
    isViewed
  ]);
}
