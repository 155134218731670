import _trim from "lodash/trim";

const selectRoot = state => state.teamRosterExtender;

const selectList = state => selectRoot(state).members;
const selectSearch = state => selectList(state).search;
const selectPagination = state => selectList(state).pagination;
const selectData = state => selectList(state).data;
const selectTeams = state => selectList(state).teams;
const selectTeam = state => selectRoot(state).team;

function buildMemberUrl({ seasonId, type, id }) {
  return `/seasons/${seasonId}/roster/${type}/${id}`;
}

export const getListSearchCurrentQuery = state => selectSearch(state).currentQuery;

export const getListSearchNextQuery = state => selectSearch(state).nextQuery;

export const getListSearchQueryIsDirty = state => {
  const { nextQuery, currentQuery } = selectSearch(state);

  return _trim(nextQuery) !== _trim(currentQuery);
};

export const getSearchCurrentQuery = state => selectSearch(state).currentQuery;

export const getPaginationLimit = state => selectPagination(state).limit;
export const getPaginationOffset = state => selectPagination(state).ids.length;
export const getListIsAppending = state => selectPagination(state).append;
export const getListTotalCount = state => selectPagination(state).totalCount;
export const getListFilteredCount = state => selectPagination(state).filteredCount;
export const getListOffset = state => selectPagination(state).ids.length;
export const getListLimit = state => selectPagination(state).limit;
export const getListIsLoaded = state => selectPagination(state).isLoaded;
export const getListIsLoading = state => selectPagination(state).isLoading;

export const getListHasMore = state => {
  const filteredCount = getListFilteredCount(state);
  const offset = getListOffset(state);

  return offset < filteredCount;
};

export const getTeamIsLoading = state => selectTeam(state).isLoading;
export const getTeamIsLoaded = state => selectTeam(state).isLoaded;
export const getTeamIsUpdating = state => selectTeam(state).isUpdating;
export const getTeamIsForbidden = state => selectTeam(state).isForbidden;
export const getTeamIsArchived = state => selectTeam(state).isArchived;
export const getTeamTitle = state => selectTeam(state).title;

export const getBreadcrumbs = (state, teamId) => {
  const team = selectTeam(state);
  const { association, league, season, division } = team;

  if (team.isLoaded) {
    return [
      {
        title: "Associations",
        url: "/associations"
      },
      {
        title: association.title,
        url: `/associations/${association.id}`
      },
      {
        title: league.title,
        url: `/leagues/${league.id}`
      },
      {
        title: season.title,
        url: `/seasons/${season.id}`
      },
      {
        title: division.title,
        url: `/seasons/${season.id}/divisions/${division.id}/teams`
      },
      {
        url: `/seasons/${season.id}/teams/${teamId}/roster`,
        title: team.title
      }
    ];
  }
};

export const getMembersCount = state => selectPagination(state).ids.length;

export const getMembers = state => {
  const ids = selectPagination(state).ids;
  const data = selectData(state);
  const teams = selectTeams(state);
  const { roster } = selectTeam(state);

  return ids.map(memberId => {
    const { firstName, lastName, externalId, seasonId, teamIds, type, isProcessing } = data[memberId];

    const onRoster = (roster[type] || []).find(({ id }) => id === memberId) !== undefined;

    const memberTeams = teamIds.map(id => ({ id, ...teams[id] }));
    const url = buildMemberUrl({ seasonId, type, id: memberId });

    return {
      id: memberId,
      firstName,
      lastName,
      externalId,
      onRoster,
      isProcessing,
      url,
      type,
      teams: memberTeams
    };
  });
};

export const getTeam = selectTeam;
