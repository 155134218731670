import { getData } from "country-list";
import _orderBy from "lodash/orderBy";

export const FIELDS_MAPPING = {
  firstName: "First name",
  lastName: "Last name",
  externalId: "External ID",
  photo: "Photo",
  biography: "Bio",
  height: "Height",
  weight: "Weight",
  shotHand: "Shot-hand / Catch hand",
  birthdate: "Birth date",
  hometown: "City / Town",
  country: "Country",
  province: "State / Province",
  draftedBy: "Drafted by",
  committedTo: "Committed to"
};

export const DEFAULT_VALUES = {
  firstName: "",
  lastName: "",
  externalId: "",
  photo: "",
  biography: "",
  height: "",
  weight: "",
  shotHand: "",
  birthdate: null,
  hometown: "",
  country: "",
  province: "",
  draftedBy: "",
  committedTo: ""
};

export const CONSTRAINTS = {
  firstName: {
    presence: { allowEmpty: false, message: "^First name can’t be blank" }
  },
  lastName: {
    presence: { allowEmpty: false, message: "^Last name can’t be blank" }
  }
};

const ALL_COUNTRIES_DATA = getData();
const FAVORITE_COUNTRY_CODES = ["CA", "US"];

export const FAVORITE_COUNTRIES = ALL_COUNTRIES_DATA.filter(({ code }) => FAVORITE_COUNTRY_CODES.includes(code));

export const OTHER_COUNTRIES = _orderBy(
  ALL_COUNTRIES_DATA.filter(({ code }) => !FAVORITE_COUNTRY_CODES.includes(code)),
  ["name"],
  ["asc"]
);
