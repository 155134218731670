import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getStatus } from "@/redux/userFilter/selectors";
import actions from "@/redux/userFilter/actions";

export default function useStatusSelect() {
  const value = useSelector(getStatus);
  const dispatch = useDispatch();
  const setStatus = useCallback(({ target: { value } }) => dispatch(actions.setStatus(value)), [dispatch]);

  return {
    value,
    setStatus
  };
}
