import { playersService } from "@/services/api/seasons/season/players";
import { coachesService } from "@/services/api/seasons/season/coaches";
import { seasonTeamsService } from "@/services/api/seasons/season/teams";
import { scheduledGamesService } from "@/services/api/seasons/season/scheduledGames";
import { divisionsService } from "@/services/api/seasons/season/divisions";
import { refereesService } from "@/services/api/seasons/season/referees";
import axios from "axios";

export const seasonService = (path, config) => {
  return {

    get: async ({ include=[] }={}) => {
      path = include.length ? `${path}?include=${include}` : path;

      return axios.get(path, config).then(response => {
        return response.data;
      }).catch(e => {
        console.log("Get Season Teams Error", e);
      });
    },

    teams: seasonTeamsService(path, config),
    players: playersService(path, config),
    coaches: coachesService(path, config),
    scheduledGames: scheduledGamesService(path, config),
    divisions: divisionsService(path, config),
    referees: refereesService(path, config)
  }
}
