import "regenerator-runtime/runtime";

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";

import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { toast } from "react-toastify";
import { RecoilRoot } from "recoil";
import { config } from "@/config";

import App from "@/containers/App";
import "@/styles/App.scss";

import createStore from "@/redux/createStore";

import history from "./history";
import "./customValidators";

Sentry.init({
  release: config.SENTRY_RELEASE,
  environment: config.SENTRY_ENVIRONMENT,
  dsn: config.SENTRY_DSN,
  normalizeDepth: 3,
  integrations: [
    new Sentry.Integrations.Breadcrumbs({
      fetch: false,
      console: false
    })
  ],
  beforeSend(event, hint) {
    const error = hint.originalException;

    // Ignore Network Errors
    if (error.isAxiosError) {
      return null;
    }

    return event;
  }
});

toast.configure({
  autoClose: 8000,
  draggable: false,
  pauseOnHover: true,
  pauseOnFocusLoss: true
});

const initialState = {};
const store = createStore({ initialState, history });

document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <ConnectedRouter history={history}>
          <RecoilRoot >
            <App />
          </RecoilRoot>
        </ConnectedRouter>
      </Router>
    </Provider>,
    document.getElementById("react-root")
  );
});
