import _orderBy from "lodash/orderBy";
import _capitalize from "lodash/capitalize";
import _trimEnd from "lodash/trimEnd";

import { formatDate } from "@/utils/date";

function createPenaltyReducer({ state, selectReport }) {
  const teams = selectReport(state).teams;

  const games = Object.entries(selectReport(state).games).reduce(
    (result, [id, { seasonId, homeId, visitorId, startTime }]) => {
      const home = teams[homeId].title;
      const visitor = teams[visitorId].title;

      return {
        ...result,
        [id]: {
          seasonId,
          startTime,
          title: `${home} vs ${visitor}`,
          date: formatDate(startTime, "UTC")
        }
      };
    },
    {}
  );

  return ({ gameId, teamId, ...rest }) => {
    const game = { id: gameId, ...games[gameId] };
    const team = teams[teamId].title;

    return {
      ...rest,
      game,
      team
    };
  };
}

function orderPenalties(penalties) {
  return _orderBy(penalties, [({ game }) => game.startTime, "gameId"], ["desc", "desc"]);
}

export default function createTeamMemberPenaltyReportSelectors(options) {
  const { selectRoot, context } = options;
  const prefix = _capitalize(context);

  const selectList = state => selectRoot(state).list;
  const selectListSearch = state => selectList(state).search;
  const selectListPagination = state => selectList(state).pagination;
  const selectReport = state => selectRoot(state).report;
  const selectPenalties = state => selectReport(state).penalties;
  const selectMajorPenalties = state => selectPenalties(state).filter(({ isMajor }) => isMajor);

  const res = {
    [`get${prefix}PenaltyReportListSearchNextQuery`]: state => selectListSearch(state).nextQuery,

    [`get${prefix}PenaltyReportListSearchCurrentQuery`]: state => selectListSearch(state).currentQuery,

    [`get${prefix}PenaltyReportListSearchQueryIsDirty`]: state => {
      const { nextQuery, currentQuery } = selectListSearch(state);

      return _trimEnd(nextQuery) !== _trimEnd(currentQuery);
    },

    [`get${prefix}PenaltyReportListIsLoading`]: state => selectListPagination(state).isLoading,

    [`get${prefix}PenaltyReportListIsLoaded`]: state => selectListPagination(state).isLoaded,

    [`get${prefix}PenaltyReportList`]: state => {
      const { ids } = selectListPagination(state);
      const { data } = selectList(state);
      const { leagues } = selectList(state);

      return ids.map(id => ({
        ...data[id],
        id,
        leagues: Object.entries(leagues)
          .filter(([leagueId]) => data[id].leagueIds.includes(leagueId))
          .map(([leagueId, { title }]) => title)
      }));
    },

    [`get${prefix}PenaltyReportListTotalCount`]: state => selectListPagination(state).totalCount,

    [`get${prefix}PenaltyReportListFilteredCount`]: state => selectListPagination(state).filteredCount,

    [`get${prefix}PenaltyReportListOffset`]: state => selectListPagination(state).ids.length,

    [`get${prefix}PenaltyReportListLimit`]: state => selectListPagination(state).limit,

    [`get${prefix}PenaltyReportListHasMore`]: state => {
      const filteredCount = selectListPagination(state).filteredCount;
      const offset = selectListPagination(state).ids.length;

      return offset < filteredCount;
    },

    [`get${prefix}PenaltyReportListIsAppending`]: state => selectListPagination(state).append,

    [`get${prefix}PenaltyReportIsLoading`]: state => selectReport(state).isLoading,

    [`get${prefix}PenaltyReportIsLoaded`]: state => selectReport(state).isLoaded,

    [`get${prefix}PenaltyReportIsForbidden`]: state => selectReport(state).isForbidden,

    [`get${prefix}PenaltyReportMember`]: state => selectReport(state).member,

    [`get${prefix}PenaltyReportCareerStats`]: state => ({
      ...selectReport(state).careerStats,
      majorPenaltiesCount: selectMajorPenalties(state).length
    }),

    [`get${prefix}PenaltyReportStatsBySeason`]: state =>
      Object.entries(selectReport(state).seasons).map(([id, { title, ...data }]) => ({
        ...data,
        season: {
          id,
          title
        }
      })),

    [`get${prefix}PenaltyReportMajorPenalties`]: state => {
      const reducer = createPenaltyReducer({ state, selectReport });

      const penalties = selectMajorPenalties(state).map(reducer);

      return orderPenalties(penalties);
    },

    [`get${prefix}PenaltyReportInfractionHistory`]: state => {
      const reducer = createPenaltyReducer({ state, selectReport });

      const penalties = selectPenalties(state).map(reducer);

      return orderPenalties(penalties);
    }
  };

  return res;
}
