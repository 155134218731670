import { handleActions } from "redux-actions";

import { loadingRoutine } from "../routines";
import actions from "../actions";

const initialState = {
  value: "",
  description: "",
  roles: [],
  liveScoringScopes: [],
  seasons: {}
};

function reduceLoadingSuccess(state, { payload: { ipadKey } }) {
  const { value, description, roles, liveScoringScopes, seasons } = ipadKey;

  return {
    value,
    description,
    roles,
    liveScoringScopes: liveScoringScopes || [],
    seasons: seasons.reduce((result, { id, title }) => ({ ...result, [id]: { title } }), {})
  };
}

export default handleActions(
  {
    [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
