import React, { useState } from 'react';
import PropTypes from "prop-types";
import { Button } from 'reactstrap';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import Spinner from "@/components/Spinner";
import { usePenaltyAccumulationReport } from './usePenaltyAccumulationReport';

import './style.scss';

function PenaltyAccumulationReport({ isLoaded, penaltyCodes, data, season }) {
  const { rowData } = usePenaltyAccumulationReport({ penaltyCodes, data });
  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const formatSeason = () => {
    return season.title.replace(" ", "-");
  }

  const formatDate = (date) => {
    const timeZone = date.toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
    const formattedDate = `${date.getFullYear()}-${String(date.getMonth()).padStart(2,'0')}-${String(date.getDate()).padStart(2,'0')}-${timeZone}-${String(date.getHours()).padStart(2,'0')}-${String(date.getMinutes()).padStart(2,'0')}-${String(date.getSeconds()).padStart(2,'0')}`;
    return formattedDate;
  }
  
  const handleExport = () => {
    if (gridApi) {
      const fileName = `${formatSeason()}_Penalty-Accumulation-Report_${formatDate(new Date())}`;
      gridApi.exportDataAsCsv({ fileName });
    }
  };

  return (
    <div>
      <div className="d-flex flex-direction-row justify-content-between">
        <h1>Penalty Accumulation Report</h1>
        {isLoaded && (<Button outline color="primary" size="sm" onClick={handleExport}>
          Export to CSV
        </Button>)}
      </div>
      {isLoaded && (
        <div className="ag-theme-alpine ag-gs-theme mb-3 mt-4">
          <AgGridReact
            defaultColDef={{
              resizable: true,
              sortable: true,
              wrapText: true,
              filter: true,
              filterParams: {
                suppressAndOrCondition: true,
              }
            }}
            pagination={false}
            suppressRowClickSelection={true}
            rowData={rowData}
            onGridReady={onGridReady}
            domLayout={'autoHeight'}
            suppressCellSelection={true}
            minrowHeight={44}
            enableCellTextSelection={true}
            columnDefs={[
              { headerName: "First Name",
                field: 'firstName',
                flex: 1,
                cellClass: 'link',
                minWidth: 100,
                cellRenderer: nameCellRenderer },
              { headerName: "Last Name",
                field: 'lastName',
                flex: 1,
                cellClass: 'link',
                minWidth: 100,
                cellRenderer: nameCellRenderer },
              { headerName: "Team(s)", field: 'teams', flex: 1, minWidth: 160 },
              { headerName: "Code", field: 'penaltyCode', width: 75 },
              { headerName: "Label", field: 'penaltyLabel', width: 120 },
              { headerName: "Duration", field: 'duration', width: 105 },
              { headerName: "Category", field: 'category', width: 110 },
              { headerName: "Assessed", field: 'timeAssessed', width: 105 },
              { headerName: "Last Infraction",
                field: 'lastInfractionDate',
                width: 150,
                comparator: (a, b) => {
                  const aDate = new Date(a);
                  const bDate = new Date(b);
                  return (aDate.getDate() === bDate.getDate()) ? 0 : (aDate > bDate) ? 1 : -1;
                },
                filter: 'agDateColumnFilter',
                filterParams: {
                  inRangeInclusive: true, 
                  comparator: (filterDate, cellValue) => {
                    const cellDate = new Date(cellValue + 'T00:00:00');
                    return (cellDate.getDate() === filterDate.getDate()) ? 0 : (cellDate > filterDate) ? 1 : -1;
                  }
                }
              },
            ]}
          >
          </AgGridReact>
        </div>
      ) || (
        <div className="penalty-accumulation-spinner-container">
          <Spinner theme="loader" color="yellow" size="xxl" />
        </div>
      )}
    </div>
  );
}

const nameCellRenderer = params => {
  return `<a href="/reports/player-penalty/${params.data.extId}">${params.value}</a>`;
}


PenaltyAccumulationReport.propTypes = {
  isLoaded: PropTypes.bool,
  seasonId: PropTypes.string,
  penaltyCodes: PropTypes.arrayOf( PropTypes.string ), 
  data: PropTypes.shape({}).isRequired,
  triggerExport: PropTypes.number
}

PenaltyAccumulationReport.defaultProps = {
  isLoaded: false,
  penaltyCodes: [], 
  data: {},
  triggerExport: 0
}

export default PenaltyAccumulationReport;