import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { isCurrentTeamDeleting } from "@/redux/teams/selectors";
import { CurrentTeamDeletingRoutine } from "@/redux/teams/routines";

export default function useTeamFormDeleting({ seasonId, divisionId, teamId }) {
  const dispatch = useDispatch();

  const isDeleting = useSelector(isCurrentTeamDeleting);

  const onDelete = useCallback(() => dispatch(CurrentTeamDeletingRoutine({ seasonId, divisionId, id: teamId })), [
    dispatch,
    seasonId,
    divisionId,
    teamId
  ]);

  return {
    isDeleting,
    onDelete
  };
}
