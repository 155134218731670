import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getGameSeasonId,
  getGameSeasonPlayers,
  getSearchListOffset,
  getSearchListHasMore,
  getSearchListIsLoaded,
  getSearchListIsLoading,
  getSearchListIsAppending,
  getSearchListTotalCount
} from "@/redux/gameForm/selectors";

import { seasonPlayersLoadingRoutine } from "@/redux/gameForm/routines";

import actions from "@/redux/gameForm/actions";

export default function useRoster() {
  const seasonPlayers = useSelector(state => getGameSeasonPlayers(state));
  const seasonId = useSelector(state => getGameSeasonId(state));

  const countPlayers = useSelector(state => getSearchListOffset(state));
  const hasMore = useSelector(state => getSearchListHasMore(state));
  const isLoaded = useSelector(state => getSearchListIsLoaded(state));
  const isLoading = useSelector(state => getSearchListIsLoading(state));
  const isAppending = useSelector(state => getSearchListIsAppending(state));
  const totalCount = useSelector(state => getSearchListTotalCount(state));

  const dispatch = useDispatch();
  const load = useCallback(() => dispatch(seasonPlayersLoadingRoutine.trigger({ seasonId, append: false })), [
    dispatch,
    seasonId
  ]);
  const loadMore = useCallback(() => dispatch(seasonPlayersLoadingRoutine.trigger({ seasonId, append: true })), [
    dispatch,
    seasonId
  ]);
  const clearQuery = useCallback(() => dispatch(actions.clearSearchPlayer()), [dispatch]);

  return {
    seasonPlayers,
    countPlayers,
    hasMore,
    isLoaded,
    isLoading,
    isAppending,
    totalCount,
    load,
    loadMore,
    clearQuery
  };
}
