import { all, takeLatest, put, call } from "redux-saga/effects";

import { firebase } from "@/firebase";
import { tokenResetRoutine } from "@/redux/token/routines";
import { logoutAction } from "./actions";
import { loadAccountRoutine } from "@/redux/account/routines";

export function* logoutSaga() {
  yield put(tokenResetRoutine.trigger());

  yield call(() =>
    firebase
      .auth()
      .signOut()
      .catch(function(error) {
        console.log("failed to sign out of firebase", error);
      })
  );
}

export function* logoutFlow() {
  yield all([
    takeLatest([logoutAction, loadAccountRoutine.FAILURE], logoutSaga)
  ]);
}
