import React from "react";

import { Table } from "reactstrap";

import Team from "./Team";

import useSelectedTeamList from "./hooks/useSelectedTeamList";

import "./List.scss";

function SelectedList() {
  const { teams } = useSelectedTeamList();

  if (teams.length === 0) {
    return null;
  }

  return (
    <div className="mt-5">
      <h4>Selected teams ({teams.length})</h4>
      <Table borderless striped>
        <thead>
          <tr>
            <th scope="col" className="copy-teams__list__team__title">
              Team
            </th>
            <th scope="col">Division</th>
            <th scope="col" className="narrow">
              <span className="visuallyhidden">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {teams.map(team => (
            <Team key={team.id} allowDeselect {...team} />
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default SelectedList;
