import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Button } from "reactstrap";

class SeasonIpadKeysManagerKeyActionBar extends PureComponent {
  static propTypes = {
    isAvailable: PropTypes.bool.isRequired,
    isIncludesSeason: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    toggleSelected: PropTypes.func.isRequired
  };

  render() {
    const { isAvailable, isIncludesSeason, isSelected, toggleSelected } = this.props;

    if (!isAvailable) {
      return (
        <Button outline type="button" color="secondary" size="sm" disabled>
          Unavailable
        </Button>
      );
    } else if (isIncludesSeason) {
      return (
        <Button outline type="button" color="success" size="sm" disabled>
          Included
        </Button>
      );
    } else if (isSelected) {
      return (
        <Button outline type="button" color="secondary" size="sm" disabled>
          Selected
        </Button>
      );
    }

    return (
      <Button outline type="button" color="success" size="sm" onClick={toggleSelected}>
        Select
      </Button>
    );
  }
}

export default SeasonIpadKeysManagerKeyActionBar;
