import { connect } from "react-redux";

import { screens } from "@/redux/registration/reducer";
import { getRegistration } from "@/redux/registration/selectors";
import { registrationSetFieldAction, setInvitationCodeInfoAction, showRegistrationScreenAction } from "@/redux/registration/actions";
import { registrationValidationRoutine, registrationRoutine } from "@/redux/registration/routines";

import New from "./New";

const mapStateToProps = state => ({
  ...getRegistration(state)
});

const mapDispatchToProps = dispatch => {
  return {
    onChange: attributes => dispatch(registrationSetFieldAction(attributes)),
    onSubmit: () => dispatch(registrationValidationRoutine()),
    onWrongInvitation: () => {
      dispatch(setInvitationCodeInfoAction({}));
      dispatch(showRegistrationScreenAction(screens.ENTER_INFO)); 
    },
    onCorrectInvitation: () => dispatch(registrationRoutine()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(New);
