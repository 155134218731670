import { connect } from "react-redux";

import IpadKeysIndex from "./IpadKeysIndex";

import { ipadKeysListLoadingRoutine } from "@/redux/ipadKeys/routines";

import {
  getIpadKeysListIsLoaded,
  getIpadKeysListIsLoading,
  getIpadKeysListIsForbidden,
  getIpadKeysList
} from "@/redux/ipadKeys/selectors";

import { openSeasonIpadKeysManager } from "@/redux/seasonIpadKeysManager/actions";

const mapStateToProps = state => ({
  isLoading: getIpadKeysListIsLoading(state),
  isLoaded: getIpadKeysListIsLoaded(state),
  isForbidden: getIpadKeysListIsForbidden(state),
  ipadKeys: getIpadKeysList(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadKeys: () => dispatch(ipadKeysListLoadingRoutine({ seasonId: ownProps.seasonId })),
  openKeysManager: payload => dispatch(openSeasonIpadKeysManager(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IpadKeysIndex);
