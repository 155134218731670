import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Table } from "reactstrap";

class FinalScore extends PureComponent {
  static propTypes = {
    visitor: PropTypes.number,
    home: PropTypes.number
  };

  static defaultProps = {
    visitor: 0,
    home: 0
  };

  render() {
    const { visitor, home } = this.props;

    return (
      <Table striped borderless size="sm" className="layout-fixed">
        <thead>
          <tr>
            <th scope="colgroup" colSpan="2">
              Final Score
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Visitor - {visitor}</td>
            <td>Home - {home}</td>
          </tr>
        </tbody>
      </Table>
    );
  }
}

export default FinalScore;
