import { all, select, put, call, takeLatest } from "redux-saga/effects";

import { loadSeason } from "@/lib/api/seasons";
import { fetchOne, gamesheetAPIRequest } from "@/redux/api/sagas";

import { loadingRoutine, lockRostersRoutine, loadPlayerPositionsRoutine, loadCoachPositionsRoutine } from "./routines";

import { updateSeasonRosterLock } from "@/lib/api/seasons";

import { getMeta, getSeason } from "./selectors";

function* loadingSaga({ payload: { seasonId } }) {
  yield put(loadingRoutine.request());

  try {
    const [season] = yield fetchOne({ type: "seasons", id: seasonId }, loadSeason, { include: "associations,leagues" });

    yield put(loadingRoutine.success({ season }));

    if (season) {
      yield put(loadPlayerPositionsRoutine({ sport: season.sport }));
      yield put(loadCoachPositionsRoutine({ sport: season.sport }));
    }
  } catch (error) {
    const { response } = error;

    yield put(
      loadingRoutine.failure({
        error,
        response
      })
    );
  } finally {
    yield put(loadingRoutine.fulfill());
  }
}

export const fetchCoachPositions = async (sport) => {
  if (!sport) {
    return [];
  }

  return fetch (
    `https://lookups.gamesheet.io/api/duties?sport=${sport}&for=coach`, {
      method: 'GET'
  })
  .then(response => response.json())
  .then(data => {
    if (!data || !Array.isArray(data)) {
      return [];
    }

    return data.map(d => ({
      key: d.key || "",
      value: d.title || "",
      abbr: d.abbr || "",
    })).filter(d => d.key && d.value);
  });
}

export const fetchPlayerPositions = async (sport) => {
  if (!sport) {
    return [];
  }

  return fetch (
    `https://lookups.gamesheet.io/api/positions?sport=${sport.toLowerCase()}`, {
      method: 'GET'
  })
  .then(response => response.json())
  .then(data => {
    if (!data || !Array.isArray(data)) {
      return [];
    }

    return data.map(d => ({
      key: d.key || "",
      value: d.title || "",
      abbr: d.abbr || "",
    })).filter(d => d.key && d.value);
  });
}

function* loadPlayerPositionsSaga({ payload: { sport }}) {
  yield put(loadPlayerPositionsRoutine.request());

  try {
    const values = yield call(fetchPlayerPositions, sport);
    yield put(loadPlayerPositionsRoutine.success({ values }));
  } catch (error) {
    yield put(loadPlayerPositionsRoutine.failure());
  } finally {
    yield put(loadPlayerPositionsRoutine.fulfill());
  }
}

function* loadCoachPositionsSaga({ payload: { sport }}) {
  yield put(loadCoachPositionsRoutine.request());

  try {
    const values = yield call(fetchCoachPositions, sport);
    yield put(loadCoachPositionsRoutine.success({ values }));
  } catch (error) {
    yield put(loadCoachPositionsRoutine.failure());
  } finally {
    yield put(loadCoachPositionsRoutine.fulfill());
  }
}

function* lockRostersSaga({ payload: { lock, successCb } }) {
  yield put(lockRostersRoutine.request());

  const meta = yield select(getMeta);
  if (!meta.isLoaded) {
    yield put(lockRostersRoutine.fulfill());
    return;
  }

  const season = yield select(getSeason);

  try {
    yield call(
      gamesheetAPIRequest,
      updateSeasonRosterLock,
      {
        seasonId: season.id,
        attributes: { lock }
      },
      true
    );

    yield put(lockRostersRoutine.success());

    if (successCb && typeof successCb === "function") {
      successCb();
    }
  } catch (error) {
    const { response } = error;

    yield put(
      lockRostersRoutine.failure({
        error,
        response
      })
    );
  } finally {
    yield put(lockRostersRoutine.fulfill());
  }
}

export function* currentSeasonFlow() {
  yield all([
    takeLatest(loadingRoutine.TRIGGER, loadingSaga),
    takeLatest(lockRostersRoutine.TRIGGER, lockRostersSaga),
    takeLatest(loadPlayerPositionsRoutine.TRIGGER, loadPlayerPositionsSaga),
    takeLatest(loadCoachPositionsRoutine.TRIGGER, loadCoachPositionsSaga)
  ]);
}
