import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import TitleBar from "@/components/TitleBar";
import PageLoader from "@/components/PageLoader";

import RefereeReportCareerStats from "./CareerStats";
import RefereeReportMajorPenalties from "./MajorPenalties";
import RefereeReportGamesOfficiated from "./GamesOfficiated";

import "./Report.scss";

class Report extends PureComponent {
  static propTypes = {
    externalId: PropTypes.string.isRequired,
    referee: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      externalId: PropTypes.string
    }),
    careerStats: PropTypes.shape({}),
    majorPenalties: PropTypes.arrayOf(PropTypes.shape({})),
    gamesOfficiated: PropTypes.arrayOf(PropTypes.shape({})),
    isLoaded: PropTypes.bool,
    isLoading: PropTypes.bool
  };

  static defaultProps = {
    referee: {
      firstName: "",
      lastName: "",
      externalId: ""
    },
    careerStats: {},
    majorPenalties: [],
    gamesOfficiated: [],
    isLoaded: false,
    isLoading: false
  };

  print = () => {
    window.print();
  };

  render() {
    const {
      referee: { firstName, lastName, externalId },
      careerStats,
      majorPenalties,
      gamesOfficiated,
      isLoading,
      isLoaded
    } = this.props;

    return (
      <PageLoader isLoading={isLoading} isLoaded={isLoaded}>
        <TitleBar title={`${firstName} ${lastName} — Referee Report`} subtitle={`Referee ID: ${externalId}`}>
          <Button outline color="primary" size="sm" onClick={this.print}>
            Print
          </Button>
        </TitleBar>

        <RefereeReportCareerStats {...careerStats} />
        <RefereeReportMajorPenalties penalties={majorPenalties} />
        <RefereeReportGamesOfficiated games={gamesOfficiated} />
      </PageLoader>
    );
  }
}

export default Report;
