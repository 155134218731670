import { handleActions } from "redux-actions";

import { loadGameFormRoutine } from "../routines";
import actions from "../actions";

const initialState = {
  home: {},
  visitor: {}
};

function reduceShootouts(state, { payload: { game } }) {
  const homeAttempts = game.homeShootoutAttempts || [];
  const visitorAttempts = game.visitorShootoutAttempts || [];

  return {
    home: homeAttempts.reduce(
      (result, attempt, index) => ({
        ...result,
        [index]: attempt
      }),
      {}
    ),
    visitor: visitorAttempts.reduce(
      (result, attempt, index) => ({
        ...result,
        [index]: attempt
      }),
      {}
    )
  };
}

function changeShootout(state, { payload: { team, index, field, value } }) {
  return {
    ...state,
    [team]: {
      ...state[team],
      [index]: {
        ...state[team][index],
        [field]: value
      }
    }
  };
}

function addShootout(state, { payload: { team } }) {
  const extractNumbers = attempts => Object.values(attempts).map(({ number }) => parseInt(number));

  const indexes = Object.keys(state[team]);

  const numbers = [...extractNumbers(state.home), ...extractNumbers(state.visitor)];

  const nextNumber = numbers.length > 0 ? Math.max(...numbers) + 1 : 1;
  const nextIndex = indexes.length > 0 ? Math.max(...indexes) + 1 : 0;

  return {
    ...state,
    [team]: {
      ...state[team],
      [nextIndex]: {
        number: nextNumber,
        goalieId: "",
        shooterId: "",
        result: ""
      }
    }
  };
}

function removeShootout(state, { payload: { team, index } }) {
  return {
    ...state,
    [team]: Object.keys(state[team])
      .filter(i => i !== index)
      .reduce(
        (result, index) => ({
          ...result,
          [index]: state[team][index]
        }),
        {}
      )
  };
}

export default handleActions(
  {
    [loadGameFormRoutine.SUCCESS]: reduceShootouts,
    [actions.changeShootout]: changeShootout,
    [actions.addShootout]: addShootout,
    [actions.removeShootout]: removeShootout,
    [actions.clear]: () => ({ ...initialState })
  },
  {}
);
