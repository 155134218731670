import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Row, Col, FormGroup } from "reactstrap";

import FormSection from "@/components/FormSection";
import LeagueAppModeField from "./LeagueAppModeField";


import "./LeagueAppFieldset.scss";

const LEAGUE_APP_MODES = [
  {
    mode: "disabled",
    label: "Disabled",
    description:
      "This season will not be avaliable within the GameSheet League app."
  },
  {
    mode: "free",
    label: "Free",
    description:
      "This season will be avaliable at no cost within the GameSheet League app."
  },
  {
    mode: "paid",
    label: "Paid",
    description:
      "This season will be avaliable for purchase within the GameSheet League app."
  },
];

function useLeagueAppFieldset({ value: currentMode, onChange }) {
  const changeMode = useCallback(({ target: { name: mode } }) => onChange(mode), [onChange]);

  return LEAGUE_APP_MODES.map(({ mode, label, description }) => ({
    mode,
    label,
    description,
    onChange: changeMode,
    checked: mode === currentMode
  }));
}

function LeagueAppFieldset({ value, onChange }) {
  const leagueAppModes = useLeagueAppFieldset({ value, onChange });

  return (
      <FormSection title="League App">
        <Row>
          <Col md="6">
            <FormGroup>
              {leagueAppModes.map(({ mode, ...rest }) => (
                <LeagueAppModeField key={mode} mode={mode} {...rest} />
              ))}
            </FormGroup>
          </Col>
        </Row>
      </FormSection>
  );
}

LeagueAppFieldset.propTypes = {
  value: PropTypes.oneOf(LEAGUE_APP_MODES.map(({ mode }) => mode)).isRequired,
  onChange: PropTypes.func
};

LeagueAppFieldset.defaultProps = {
  onChange() {}
};

export default LeagueAppFieldset;
