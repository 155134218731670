export const PERIOD_NAME_ALIASES = {
  1: "firstPeriod",
  2: "secondPeriod",
  3: "thirdPeriod",
  4: "fourthPeriod",
  ot1: "overtimePeriod"
};

export const PERIOD_NAME_REVERSE_ALIASES = Object.entries(PERIOD_NAME_ALIASES).reduce(
  (result, [name, alias]) => ({ ...result, [alias]: name }),
  {}
);
