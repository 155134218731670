import { createActions } from "redux-actions";

const { roleForm: actions } = createActions({
  ROLE_FORM: {
    SET_TITLE: undefined,
    SET_GLOBAL: undefined,
    RESET: undefined,
    ASSOCIATION_LIST: {
      SELECT: undefined,
      DESELECT: undefined,
      CLEAR: undefined,
      SELECT_ALL: undefined 
    },
    LEAGUE_LIST: {
      SELECT: undefined,
      DESELECT: undefined,
      CLEAR: undefined,
      SELECT_ALL: undefined 
    },
    SEASON_LIST: {
      SELECT: undefined,
      DESELECT: undefined,
      CLEAR: undefined,
      SELECT_ALL: undefined 
    },
    DIVISION_LIST: {
      SELECT: undefined,
      DESELECT: undefined,
      CLEAR: undefined,
      SELECT_ALL: undefined 
    },
    TEAM_LIST: {
      SELECT: undefined,
      DESELECT: undefined,
      CLEAR: undefined,
      SELECT_ALL: undefined 
    }
  }
});

export default actions;
