import React from "react";

import FilterSelect from "./FilterSelect";

import useTeamSelect from "./hooks/useTeamSelect";

function TeamSelect() {
  const { options, selectedId, isLoaded, isLoading, setSelectedId } = useTeamSelect();

  return (
    <FilterSelect
      placeholder="All teams"
      options={options}
      value={selectedId}
      isLoaded={isLoaded}
      isLoading={isLoading}
      onChange={setSelectedId}
    />
  );
}

export default TeamSelect;
