import { handleActions, combineActions } from "redux-actions";

import { playersLoadingRoutine, coachesLoadingRoutine, addingMemberToTeamRoutine } from "../../routines";

import actions from "../../actions";

const initialState = {};

function reduceLoadingSuccess(state, { payload: { members } }) {
  return members.reduce(
    (result, { id, type, firstName, lastName, externalId, teams = [], season }) => ({
      ...result,
      [id]: {
        type,
        firstName,
        lastName,
        externalId,
        seasonId: season.id,
        teamIds: teams.map(({ id }) => id),
        isProcessing: false
      }
    }),
    state
  );
}

function reduceAddingMemberToTeamRequest(state, { payload: { memberId } }) {
  return {
    ...state,
    [memberId]: {
      ...state[memberId],
      isProcessing: true
    }
  };
}

function reduceAddingMemberToTeamSuccess(state, { payload }) {
  const { memberId, teamId } = payload;

  return {
    ...state,
    [memberId]: {
      ...state[memberId],
      teamIds: [...state[memberId].teamIds, teamId]
    }
  };
}

function reduceAddingMemberToTeamFulfill(state, { payload: { memberId } }) {
  return {
    ...state,
    [memberId]: {
      ...state[memberId],
      isProcessing: false
    }
  };
}

export default handleActions(
  {
    [combineActions(playersLoadingRoutine.SUCCESS, coachesLoadingRoutine.SUCCESS)]: reduceLoadingSuccess,
    [addingMemberToTeamRoutine.REQUEST]: reduceAddingMemberToTeamRequest,
    [addingMemberToTeamRoutine.SUCCESS]: reduceAddingMemberToTeamSuccess,
    [addingMemberToTeamRoutine.FULFILL]: reduceAddingMemberToTeamFulfill,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
