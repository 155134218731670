import { atom } from "recoil";

export const startDateFilterState = atom({
  key: 'startDateFilter',
  default: ''
});

export const endDateFilterState = atom({
  key: 'endDateFilter',
  default: ''
});

export const divisionsFilterState = atom({
  key: 'divisionsFilter',
  default: []
});

export const queryStringFilterState = atom({
  key: 'queryStringFilter',
  default: ''
});

export const gameTypeFilterState = atom({
  key: 'gameTypeFilter',
  default: ''
});