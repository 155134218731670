import React, { Fragment } from "react";
import PropTypes from "prop-types";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";

import TrainerVisitsReport from "@/components/TrainerVisitsReport";

import useTrainerVisitsReport from "./hooks/useTrainerVisitsReport";

function ReportPage({ location: { search } }) {
  const { isLoaded, isForbidden, seasonIds } = useTrainerVisitsReport(search);

  return (
    <ProtectedLayout condition={!isForbidden}>
      <Head title="Trainer Visits Report" />

      {isLoaded && (
        <Fragment>
          <Breadcrumbs
            items={[
              { title: "Reports" },
              {
                title: "Trainer Visits Reports",
                url: "/reports/trainer-visits/sources"
              }
            ]}
          />
        </Fragment>
      )}

      <TrainerVisitsReport seasonIds={seasonIds} />
    </ProtectedLayout>
  );
}

ReportPage.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string.isRequired }).isRequired
};

export default ReportPage;
