import { createActions } from "redux-actions";

const { currentDivision: actions } = createActions({
  CURRENT_DIVISION: {
    CLEAR: undefined,
    LOCK_ROSTERS: undefined
  }
});

export default actions;
