import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getTrainerVisitsReportIsLoaded,
  getTrainerVisitsReportIsForbidden
} from "@/redux/trainerVisitsReport/selectors";

import { trainerVisitsReportLoadingRoutine } from "@/redux/trainerVisitsReport/routines";
import { seasonListLoadingRoutine } from "@/redux/reportDataSourcesList/routines";

import dataSourcesListActions from "@/redux/reportDataSourcesList/actions";
import actions from "@/redux/trainerVisitsReport/actions";

export default function useTrainerVisitsReport(search) {
  const dispatch = useDispatch();
  const isLoaded = useSelector(getTrainerVisitsReportIsLoaded);
  const isForbidden = useSelector(getTrainerVisitsReportIsForbidden);

  const seasonIds = useMemo(() => new URLSearchParams(search).get("season_ids").split(","), [search]);

  useEffect(
    () => {
      dispatch(trainerVisitsReportLoadingRoutine.trigger({ seasonIds }));
      dispatch(seasonListLoadingRoutine.trigger({ ids: seasonIds }));

      return () => {
        dispatch(actions.report.clear());
        dispatch(dataSourcesListActions.clear());
      };
    },
    [dispatch, seasonIds]
  );

  return { isLoaded, isForbidden, seasonIds };
}
