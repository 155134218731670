import { handleActions } from "redux-actions";
import _trim from "lodash/trim";
import _camelCase from "lodash/camelCase";

import { loadingRoutine } from "../routines";

import globalActions from "../actions";

const FIELDS_MAPPING = {
  firstName: "First Name",
  lastName: "Last Name",
  externalId: "External Id"
};

function reduceInitialField(value) {
  return {
    value,
    initialValue: value,
    errors: [],
    isDirty: false
  };
}

function updateMember(state, memberId, { ...rest }) {
  if (!state[memberId]) {
    return state;
  }

  return {
    ...state,
    [memberId]: {
      ...state[memberId],
      ...rest
    }
  };
}

function removeMember(state, memberId) {
  return Object.keys(state)
    .filter(id => id !== memberId)
    .reduce((result, id) => ({ ...result, [id]: state[id] }), {});
}

function updateFields(state, memberId, { ...rest }) {
  return updateMember(state, memberId, {
    fields: {
      ...state[memberId].fields,
      ...rest
    }
  });
}

function reduceFields(state, memberId, fn) {
  return updateFields(
    state,
    memberId,
    Object.entries(state[memberId].fields).reduce(
      (result, [name, field]) => ({
        ...result,
        [name]: {
          ...field,
          ...fn(name, field)
        }
      }),
      {}
    )
  );
}

function updateField(state, memberId, name, { ...rest }) {
  return updateFields(state, memberId, {
    [name]: {
      ...state[memberId].fields[name],
      ...rest
    }
  });
}

export default function createMembersReducer({
  fields,
  actions,
  routines,
  extract,
  autoFocus = "firstName",
  extraMemberAttrs = []
}) {
  const initialState = {};

  const reduceMember = ({ id = "", isEditing = false, ...member } = {}) => {
    const values = { ...fields, ...member };

    return {
      ...values,
      id,
      isEditing,
      isDeleting: false,
      isSaving: false,
      autoFocus,
      fields: Object.keys(fields).reduce(
        (result, name) => ({
          ...result,
          [name]: reduceInitialField(values[name])
        }),
        {}
      )
    };
  };

  const reduceLoadingSuccess = (state, { payload: { team } }) => {
    const [members, roster] = extract(team);

    return roster.reduce((result, { id, ...rosterMemberAttrs }) => {
      const { firstName, lastName, externalId } = members[id];
      const extraAttrs = extraMemberAttrs.reduce((result, attr) => ({ ...result, [attr]: members[id][attr] }), {});
      const member = {
        id,
        firstName,
        lastName,
        externalId,
        ...rosterMemberAttrs,
        ...extraAttrs
      };

      return {
        ...result,
        [id]: reduceMember(member)
      };
    }, {});
  };

  const reduceFieldChanging = (state, { payload }) => {
    const { name, value, memberId } = payload;

    return updateField(state, memberId, name, {
      isDirty: _trim(value) !== _trim(state[memberId].fields[name].initialValue),
      value
    });
  };

  const reduceEmptyMemberAppending = state => ({
    ...state,
    "": reduceMember({ isEditing: true })
  });

  const reduceFormResetting = (state, { payload }) => {
    const { memberId } = payload;

    if (memberId === "") {
      return removeMember(state, memberId);
    }

    return updateMember(state, memberId, {
      isEditing: false,
      autoFocus,
      fields: Object.entries(state[memberId].fields).reduce(
        (result, [name, { initialValue }]) => ({
          ...result,
          [name]: {
            initialValue,
            value: initialValue,
            errors: [],
            isDirty: false
          }
        }),
        {}
      )
    });
  };

  const reduceEditing = (state, { payload: { memberId, autoFocus } }) => {
    return updateMember(state, memberId, {
      isEditing: true,
      autoFocus: autoFocus || state[memberId].autoFocus
    });
  };

  const reduceFieldValidationSuccess = (state, { payload }) =>
    updateField(state, payload.memberId, payload.name, {
      errors: []
    });

  const reduceFieldValidationFailure = (state, { payload }) =>
    updateField(state, payload.memberId, payload.name, {
      errors: payload.errors
    });

  const reduceSubmittingRequest = (state, { payload }) => updateMember(state, payload.memberId, { isSaving: true });

  const reduceSubmittingFulfill = (state, { payload }) => updateMember(state, payload.memberId, { isSaving: false });

  const reduceValidationSuccess = (state, { payload }) =>
    reduceFields(state, payload.memberId, () => ({
      errors: []
    }));

  const reduceValidationFailure = (state, { payload }) =>
    reduceFields(state, payload.memberId, name => ({
      errors: payload.errors[name]
    }));

  const reduceSubmittingSuccess = (state, { payload }) => ({
    ...removeMember(state, payload.memberId),
    [payload.member.id]: reduceMember(payload.member)
  });

  const reduceSavingFailure = (state, { payload: { id, response } }) => {
    if (response) {
      const {
        status,
        data: { errors }
      } = response;

      if (status === 400 && errors) {
        return updateFields(state, id, {
          ...errors.reduce((result, { title: error, source: { pointer } }) => {
            const name = _camelCase(pointer.match(/\/data\/attributes\/(\w+)/)[1]);

            if (!name) {
              return { ...result };
            }

            const title = FIELDS_MAPPING[name];

            return {
              ...result,
              [name]: {
                ...state[id].fields[name],
                isDirty: false,
                errors: [...state[id].fields[name].errors, `${title} ${error}`]
              }
            };
          }, {})
        });
      }
    }

    return state;
  };

  const reduceRemovingRequest = (state, { payload }) => updateMember(state, payload.memberId, { isDeleting: true });

  const reduceRemovingSuccess = (state, { payload }) => removeMember(state, payload.memberId);

  const reduceRemovingFulfill = (state, { payload }) => updateMember(state, payload.memberId, { isDeleting: false });

  return handleActions(
    {
      [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
      [actions.appendMember]: reduceEmptyMemberAppending,
      [actions.changeField]: reduceFieldChanging,
      [actions.reset]: reduceFormResetting,
      [actions.edit]: reduceEditing,
      [routines.fieldValidating.SUCCESS]: reduceFieldValidationSuccess,
      [routines.fieldValidating.FAILURE]: reduceFieldValidationFailure,
      [routines.validating.SUCCESS]: reduceValidationSuccess,
      [routines.validating.FAILURE]: reduceValidationFailure,
      [routines.submitting.REQUEST]: reduceSubmittingRequest,
      [routines.submitting.SUCCESS]: reduceSubmittingSuccess,
      [routines.submitting.FULFILL]: reduceSubmittingFulfill,
      [routines.saving.FAILURE]: reduceSavingFailure,
      [routines.removing.REQUEST]: reduceRemovingRequest,
      [routines.removing.SUCCESS]: reduceRemovingSuccess,
      [routines.removing.FULFILL]: reduceRemovingFulfill,
      [globalActions.clear]: () => ({ ...initialState })
    },
    initialState
  );
}
