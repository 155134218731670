import { handleActions } from "redux-actions";

import { invitationFormActions } from "../../actions";

const defaultState = {
  isOpen: false,
  associations: []
};

const open = () => Object.assign({}, defaultState, { isOpen: true });
const close = () => Object.assign({}, defaultState, { isOpen: false });

const toggleOpened = state => Object.assign({}, defaultState, { isOpen: !state.isOpen });

const selectAssociation = (state, { payload }) =>
  Object.assign({}, state, {
    associations: [...state.associations, payload]
  });

const deselectAssociation = (state, { payload }) =>
  Object.assign({}, state, {
    associations: state.associations.filter(id => id !== payload)
  });

export default handleActions(
  {
    [invitationFormActions.associationsList.open]: open,
    [invitationFormActions.associationsList.close]: close,
    [invitationFormActions.associationsList.toggleOpened]: toggleOpened,
    [invitationFormActions.associationsList.selectAssociation]: selectAssociation,
    [invitationFormActions.associationsList.deselectAssociation]: deselectAssociation
  },
  defaultState
);
