import React, { useCallback } from "react";
import PropTypes from "prop-types";

import AbilityButton from "@/components/AbilityButton";

import useScrollToBottom from "./hooks/useScrollToBottom";

function CreateMemberButton({ children, teamId, disabled, onClick, action }) {
  const triggerScrollToBottom = useScrollToBottom();

  const handleClick = useCallback(
    () => {
      onClick();
      triggerScrollToBottom();
    },
    [onClick, triggerScrollToBottom]
  );

  return (
    <AbilityButton
      subject={{ type: "roster", teamId }}
      action={action}
      outline
      color="success"
      className="mb-3"
      size="sm"
      onClick={handleClick}
      disabled={disabled}
    >
      {children}
    </AbilityButton>
  );
}

CreateMemberButton.propTypes = {
  children: PropTypes.node.isRequired,
  teamId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

CreateMemberButton.defaultProps = {
  disabled: false
};

export default CreateMemberButton;
