import { buffers } from "redux-saga";
import { select, actionChannel, take, all, put, call, takeLatest } from "redux-saga/effects";
import build from "redux-object";

import CSVRoster from "@/lib/core/CSVRoster";
import updateTeamRoster from "@/lib/core/updateTeamRoster";

import { loadTeam, updateTeam } from "@/lib/api/teams";
import { importPlayer } from "@/lib/api/players";
import { importCoach } from "@/lib/api/coaches";

import { gamesheetAPIRequest } from "@/redux/api/sagas";
import takeConcurrently from "@/redux/common/takeConcurrently";

import { CurrentTeamLoadingRoutine } from "@/redux/teams/routines";

import {
  rosterParsingRoutine,
  teamLoadingRoutine,
  rosterImportingRoutine,
  playersListImportingRoutine,
  coachesListImportingRoutine,
  playerImportingRoutine,
  coachImportingRoutine,
  teamsListUpdatingRoutine,
  teamUpdatingRoutine
} from "./routines";

import {
  getPlayerRows,
  getCoachRows,
  getTeamIds,
  getTeam,
  getImportedPlayersByTeam,
  getImportedCoachesByTeam
} from "./selectors";

import createListImportingSaga from "./helpers/createListImportingSaga";
import createRowImportingSaga from "./helpers/createRowImportingSaga";
import createImportingService from "./helpers/createImportingService";
import makeCoachesRoster from "./helpers/makeCoachesRoster";
import makePlayersRoster from "./helpers/makePlayersRoster";

const playerImportingService = createImportingService({
  type: "player",
  apiService: importPlayer
});

const coachImportingService = createImportingService({
  type: "coach",
  apiService: importCoach
});

const playerImportingSaga = createRowImportingSaga({
  routine: playerImportingRoutine,
  service: playerImportingService
});

const coachImportingSaga = createRowImportingSaga({
  routine: coachImportingRoutine,
  service: coachImportingService
});

const playersListImportingSaga = createListImportingSaga({
  listRoutine: playersListImportingRoutine,
  rowRoutine: playerImportingRoutine,
  selector: getPlayerRows
});

const coachesListImportingSaga = createListImportingSaga({
  listRoutine: coachesListImportingRoutine,
  rowRoutine: coachImportingRoutine,
  selector: getCoachRows
});

function* teamsListLoadingSaga({ teamIds, seasonId }) {
  const fulfillChannel = yield actionChannel(
    ({ type, payload }) => type === teamLoadingRoutine.FULFILL && teamIds.includes(payload.teamId),
    buffers.dropping(teamIds.length)
  );

  yield all(teamIds.map(teamId => put(teamLoadingRoutine.trigger({ seasonId, teamId }))));

  yield all(teamIds.map(() => take(fulfillChannel)));
}

function* teamLoadingSaga({ payload: { teamId, seasonId } }) {
  yield put(teamLoadingRoutine.request({ teamId }));

  try {
    const { data } = yield call(gamesheetAPIRequest, loadTeam, {
      seasonId,
      identity: { id: teamId },
      include: null
    });

    const team = build(data, "teams", teamId);

    yield put(teamLoadingRoutine.success({ teamId, team }));
  } catch (e) {
    yield put(teamLoadingRoutine.failure({ teamId }));
  } finally {
    yield put(teamLoadingRoutine.fulfill({ teamId }));
  }
}

function* rosterParsingSaga({ payload: { file, seasonId, teamId } }) {
  yield put(rosterParsingRoutine.request());

  try {
    const { records, teamIds } = yield call(CSVRoster.read, file, { teamId });

    yield call(teamsListLoadingSaga, {
      teamIds,
      seasonId
    });

    const validationOptions = {
      validTeamIds: yield select(getTeamIds)
    };

    const validatedRecords = CSVRoster.validate(records, validationOptions);

    yield put(rosterParsingRoutine.success({ records: validatedRecords }));
  } catch (e) {
    if (e.name === "ReadingError") {
      yield put(rosterParsingRoutine.failure({ error: e.message }));
    } else {
      yield put(
        rosterParsingRoutine.failure({
          error: "CSV parsing failed due to an unexpected error"
        })
      );
      throw e;
    }
  } finally {
    yield put(rosterParsingRoutine.fulfill());
  }
}

function* teamsListUpdatingSaga({ seasonId }) {
  yield put(teamsListUpdatingRoutine.request());

  const teamIds = yield select(getTeamIds);

  const fulfillChannel = yield actionChannel(teamUpdatingRoutine.FULFILL, buffers.dropping(teamIds.length));

  yield all(teamIds.map(teamId => put(teamUpdatingRoutine.trigger({ seasonId, teamId }))));

  yield all(teamIds.map(() => take(fulfillChannel)));

  yield put(teamsListUpdatingRoutine.success());
  yield put(teamsListUpdatingRoutine.fulfill());
}

function* teamUpdatingSaga({ payload: { seasonId, teamId } }) {
  yield put(teamUpdatingRoutine.request({ teamId }));

  try {
    const { roster, divisionId, title, externalId, data } = yield select(getTeam, teamId);

    const players = yield select(getImportedPlayersByTeam, teamId);
    const coaches = yield select(getImportedCoachesByTeam, teamId);

    const nextRoster = updateTeamRoster(roster, {
      players: makePlayersRoster(players),
      coaches: makeCoachesRoster(coaches)
    });

    yield call(gamesheetAPIRequest, updateTeam, {
      seasonId,
      divisionId,
      identity: { type: "teams", id: teamId },
      attributes: {
        title,
        externalId,
        roster: nextRoster,
        data
      }
    });

    yield all([put(teamUpdatingRoutine.success({ teamId })), put(CurrentTeamLoadingRoutine.trigger({ id: teamId }))]);
  } catch (e) {
    yield put(teamUpdatingRoutine.failure({ teamId, error: e }));
  } finally {
    yield put(teamUpdatingRoutine.fulfill({ teamId }));
  }
}

function* rosterImportingSaga({ payload: { seasonId } }) {
  yield put(rosterImportingRoutine.request());

  const playersListImportingFulfill = yield actionChannel(playersListImportingRoutine.FULFILL);

  const coachesListImportingFulfill = yield actionChannel(coachesListImportingRoutine.FULFILL);

  yield all([
    put(coachesListImportingRoutine.trigger({ seasonId })),
    put(playersListImportingRoutine.trigger({ seasonId }))
  ]);

  yield all([take(playersListImportingFulfill), take(coachesListImportingFulfill)]);

  yield call(teamsListUpdatingSaga, { seasonId });

  yield put(rosterImportingRoutine.success());
  yield put(rosterImportingRoutine.fulfill());
}

export function* csvImportFlow() {
  yield all([
    takeLatest(rosterParsingRoutine.TRIGGER, rosterParsingSaga),
    takeLatest(rosterImportingRoutine.TRIGGER, rosterImportingSaga),
    takeConcurrently(teamLoadingRoutine.TRIGGER, teamLoadingSaga, 10),
    takeLatest(playersListImportingRoutine.TRIGGER, playersListImportingSaga),
    takeLatest(coachesListImportingRoutine.TRIGGER, coachesListImportingSaga),
    takeConcurrently(playerImportingRoutine.TRIGGER, playerImportingSaga, 10),
    takeConcurrently(coachImportingRoutine.TRIGGER, coachImportingSaga, 5),
    takeConcurrently(teamUpdatingRoutine.TRIGGER, teamUpdatingSaga, 10)
  ]);
}
