import { createPostService, createGetService, createPatchService, createDeleteService } from "./serviceMaker";

const makePlayerURL = ({ seasonId, identity }) => ({
  url: `/seasons/${seasonId}/players/${identity.id}`
});

const makeSeasonPlayersURL = ({ seasonId }) => ({
  url: `/seasons/${seasonId}/players`
});

export const createPlayer = createPostService(({ seasonId, attributes }) => ({
  ...makeSeasonPlayersURL({ seasonId }),
  attributes
}));

export const loadPlayer = createGetService(({ seasonId, identity, include = "teams" }) => ({
  ...makePlayerURL({ seasonId, identity }),
  params: { include }
}));

export const loadPlayers = createGetService(({ seasonId, limit, offset, query, include = "teams" }) => ({
  ...(seasonId ? makeSeasonPlayersURL({ seasonId }) : { url: "/players" }),
  params: {
    include,
    limit,
    offset,
    filter: { query: query || undefined }
  }
}));

export const updatePlayer = createPatchService(({ seasonId, identity, attributes }) => ({
  ...makePlayerURL({ seasonId, identity }),
  attributes
}));

export const deletePlayer = createDeleteService(makePlayerURL);

export const importPlayer = createPostService(({ seasonId, firstName, lastName, externalId, attributes }) => ({
  url: `/seasons/${seasonId}/players/import`,
  attributes: attributes || { firstName, lastName, externalId }
}));

export const loadPlayerStats = createGetService(({ seasonId, playerId }) => ({
  url: `/stats/v1/seasons/${seasonId}/players`,
  params: {
    filter: { id: playerId }
  }
}));

export const loadGoalieStats = createGetService(({ seasonId, goalieId }) => ({
  url: `/stats/v1/seasons/${seasonId}/goalies`,
  params: {
    filter: { id: goalieId }
  }
}));

export const mergePlayers = createPatchService(
  ({ seasonId, winnerId, firstName, lastName, externalId, playerIds }) => ({
    url: `/seasons/${seasonId}/players/${winnerId}/merge`,
    attributes: {
      firstName,
      lastName,
      externalId
    },
    identity: { type: "players", id: winnerId },
    relationships: {
      duplicates: {
        data: playerIds.filter(pid => pid !== winnerId).map(id => ({ type: "players", id }))
      }
    }
  })
);
