import React from "react";
import PropTypes from "prop-types";

import { CustomInput } from "reactstrap";

import { useToggleTeamSubscription } from "./hooks";

import "./Switch.scss";

function Switch({ teamId, checked, isCommitted }) {
  const toggle = useToggleTeamSubscription();

  return (
    <CustomInput
      className="email-notification-settings__switch"
      id={`game-report-emails-switch-${teamId}`}
      data-testid={`game-report-emails-switch-${teamId}`}
      type="switch"
      label={<span>&nbsp;</span>}
      checked={checked}
      value={teamId}
      onChange={toggle}
      disabled={!isCommitted}
    />
  );
}

Switch.propTypes = {
  teamId: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  isCommitted: PropTypes.bool.isRequired
};

export default Switch;
