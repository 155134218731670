import { handleActions } from "redux-actions";

import actions from "../actions";

function reduceTeamSelected(state, { payload: teamId }) {
  return [...state, teamId];
}

function reduceTeamDeselected(state, { payload: teamId }) {
  return state.filter(id => id !== teamId);
}

export default handleActions(
  {
    [actions.selectTeam]: reduceTeamSelected,
    [actions.deselectTeam]: reduceTeamDeselected,
    [actions.clear]: () => []
  },
  []
);
