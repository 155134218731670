import { all } from "redux-saga/effects";

import { loadDivisions, createDivision, loadDivision, updateDivision, deleteDivision } from "@/lib/api/divisions";

import { beginSaga } from "@/redux/common/GenericSaga";

import { CurrentSeasonLoadingRoutine as seasonLoadingRoutine } from "@/redux/seasons/routines";

import {
  DivisionListLoadingRoutine as listLoadingRoutine,
  DivisionCreatingRoutine as creatingRoutine,
  CurrentDivisionLoadingRoutine as loadingRoutine,
  CurrentDivisionUpdatingRoutine as updatingRoutine,
  CurrentDivisionDeletingRoutine as deletingRoutine
} from "./routines";

const seasonUrl = ({ seasonId }) => `/seasons/${seasonId}`;
const makeDivisionIdentity = ({ id }) => ({ type: "divisions", id });

export const loadDivisionListSaga = beginSaga(listLoadingRoutine)
  .withService(loadDivisions)
  .loadResources({ type: "divisions" })
  .andSucceedWith((data, payload, { rawData, meta }) => ({
    ids: rawData.map(({ id }) => id),
    totalCount: meta["total-count"],
    filteredCount: meta ? meta["filtered-count"] || 0 : 0
  }))
  .endSaga();

export const createDivisionSaga = beginSaga(creatingRoutine)
  .withService(createDivision)
  .createResource({ type: "divisions" })
  .andRedirectTo(seasonUrl)
  .endSaga();

export const loadCurrentDivisionSaga = beginSaga(loadingRoutine)
  .initiallyPut(({ seasonId }) => seasonLoadingRoutine.trigger({ id: seasonId }))
  .withService(loadDivision)
  .loadResource(({ id }) => ({ type: "divisions", id }))
  .endSaga();

export const updateCurrentDivisionSaga = beginSaga(updatingRoutine)
  .withService(updateDivision)
  .updateResource(makeDivisionIdentity)
  .andRedirectTo(seasonUrl)
  .endSaga();

export const deleteCurrentDivisionSaga = beginSaga(deletingRoutine)
  .withService(deleteDivision)
  .deleteResource(makeDivisionIdentity)
  .andRedirectTo(() => {
    const path = seasonUrl();
    return path + location.search;
  })
  .endSaga();

export function* divisionsFlow() {
  yield all([
    loadDivisionListSaga.takeLatest(),
    createDivisionSaga.takeLatest(),
    loadCurrentDivisionSaga.takeLatest(),
    updateCurrentDivisionSaga.takeLatest(),
    deleteCurrentDivisionSaga.takeLatest()
  ]);
}
