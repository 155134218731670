import { call, take, fork, put, actionChannel } from "redux-saga/effects";
import { channel, buffers } from "redux-saga";

export default function takeConcurrently(actionName, saga, concurrency = 5) {
  const worker = function*(inbox) {
    while (true) {
      const action = yield take(inbox);

      yield saga(action);
    }
  };

  const master = function*() {
    const inbox = yield actionChannel(actionName, buffers.expanding(concurrency));
    const outbox = yield call(channel, buffers.expanding(concurrency));

    for (var i = 0; i < concurrency; i++) {
      yield fork(worker, outbox);
    }

    while (true) {
      const action = yield take(inbox);

      yield put(outbox, action);
    }
  };

  return call(master);
}
