import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";

import Header from "@/components/Header";
import Footer from "@/components/Footer";
import WarningAlerts from "@/components/WarningAlerts";

import "react-toastify/dist/ReactToastify.css";
import bg from "./assets/home-title-box.jpg";

class Layout extends PureComponent {
  
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    showNav: PropTypes.bool,
    showBreadcrumbs: PropTypes.bool
  };

  static defaultProps = {
    children: "",
    className: null,
    showNav: true,
    showBreadcrumbs: true
  };

  render() {
    
    const urlParams = new URLSearchParams(window.location.search);
    const nav = urlParams.get("config.nav");
    const { children, className, showNav, showBreadcrumbs } = this.props;

    return (
      <>
        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            left: 0,
            top: 0,
            zIndex: 0,
            opacity: 0.6
          }}
        />
        <main
          className={className}
          style={{
            background: "none",
            position: "relative"
          }}
        >
          <Header />
          <WarningAlerts />

          <div className="container" style={{ backgroundColor: "#FFF", maxWidth: 1280 }}>
            <div className="row">
              <div className="col">{children}</div>
            </div>
          </div>

          <Footer />
          <ToastContainer pauseOnFocusLoss={true} />
        </main>
      </>
    );
  }
}

export default Layout;
