import { put, all, select, takeLatest, call } from "redux-saga/effects";

import { loadTeam, lockTeam } from "@/lib/api/teams";
import { fetchOne, gamesheetAPIRequest } from "@/redux/api/sagas";

import { loadingRoutine, lockingRoutine } from "./routines";

import { getMeta, getTeam } from "./selectors";

import playersFlow from "./sagas/playersFlow";
import coachesFlow from "./sagas/coachesFlow";

function* loadingSaga({ payload: { seasonId, id } }) {
  yield put(loadingRoutine.request());

  try {
    const [team] = yield fetchOne({ type: "teams", id }, loadTeam, {
      seasonId,
      include: "association,league,season,division,players,coaches"
    });

    yield put(loadingRoutine.success({ team }));
  } catch (error) {
    yield put(loadingRoutine.failure({ error }));
  } finally {
    yield put(loadingRoutine.fulfill());
  }
}

function* lockingSaga({ payload: { lock, successCb } }) {
  yield put(lockingRoutine.request());

  const meta = yield select(getMeta);

  if (!meta.isLoaded) {
    yield put(lockingRoutine.fulfill());
    return;
  }

  const team = yield select(getTeam);

  try {
    yield call(
      gamesheetAPIRequest,
      lockTeam,
      {
        teamId: team.id,
        seasonId: team.season.id,
        attributes: { lock }
      },
      true
    );

    yield put(lockingRoutine.success());

    if (successCb && typeof successCb === "function") {
      successCb();
    }
  } catch (error) {
    const { response } = error;

    yield put(
      lockingRoutine.failure({
        error,
        response
      })
    );
  } finally {
    yield put(lockingRoutine.fulfill());
  }
}

export function* teamRosterFlow() {
  yield all([
    takeLatest(loadingRoutine.TRIGGER, loadingSaga),
    call(playersFlow),
    call(coachesFlow),
    takeLatest(lockingRoutine.TRIGGER, lockingSaga)
  ]);
}
