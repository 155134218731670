import { useCallback } from "react";

export default function useFairPlayPoints({ value, onChange }) {
  const { isEnabled, maxPenaltyMinutes, pointValue } = value;

  const changeIsEnabled = useCallback(
    ({ target: { checked: isEnabled } }) => {
      onChange({ ...value, isEnabled });
    },
    [onChange, value]
  );

  const changeMaxPenaltyMinutes = useCallback(
    ({ target: { value: mins } }) => {
      let maxPenaltyMinutes = parseInt(mins) || 0;
      onChange({ ...value, maxPenaltyMinutes });
    },
    [onChange, value]
  );

  const changePointValue = useCallback(
    ({ target: { value: points } }) => {
      let pointValue = parseInt(points) || 0;
      onChange({ ...value, pointValue });
    },
    [onChange, value]
  );

  return {
    isEnabled: {
      value: isEnabled,
      onChange: changeIsEnabled
    },
    maxPenaltyMinutes: {
      value: maxPenaltyMinutes >= 0 ? String(maxPenaltyMinutes) : "",
      onChange: changeMaxPenaltyMinutes
    },
    pointValue: {
      value: pointValue >= 0 ? String(pointValue) : "",
      onChange: changePointValue
    }
  };
}
