import { useContext, useMemo } from "react";

import CurrentDivisionContext from "@/contexts/CurrentDivisionContext";

export default function useCurrentDivisionContext() {
  const currentDivision = useContext(CurrentDivisionContext);
  const currentDivisionMemo = useMemo(() => ({ ...currentDivision }), [currentDivision]);

  return currentDivisionMemo;
}
