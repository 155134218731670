import React from "react";
import PropTypes from "prop-types";

import { FormGroup, Label, FormText } from "reactstrap";
import FileField from "@/components/FileField";

function Coupon({ value, onChange, errors }) {
  return (
    <FormGroup className="player-of-the-game__coupon">
      <Label>Coupon File</Label>
      <div className="player-of-the-game__coupon__wrapper">
        <FileField value={value} onChange={onChange} />
      </div>
      <FormText className="player-of-the-game__coupon__hint">Supported formats are JPG and PNG.</FormText>
      <FormText className="player-of-the-game__coupon__hint">Maximum file size is 2 MB.</FormText>
      {errors.map(error => (
        <FormText key={error} color="danger">
          {error}
        </FormText>
      ))}
    </FormGroup>
  );
}

Coupon.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)]),
  onChange: PropTypes.func,
  errors: PropTypes.arrayOf(PropTypes.string)
};

Coupon.defaultProps = {
  value: "",
  onChange() {},
  errors: []
};

export default Coupon;
