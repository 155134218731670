import { createPostService, createGetService, createPatchService, createDeleteService } from "./serviceMaker";

const makeRefereeURL = ({ seasonId, identity }) => ({
  url: `/seasons/${seasonId}/referees/${identity.id}`
});

const makeRefereesURL = ({ seasonId }) => ({
  url: `/seasons/${seasonId}/referees`
});

export const createReferee = createPostService(({ seasonId, attributes }) => ({
  ...makeRefereesURL({ seasonId }),
  attributes
}));

export const loadReferee = createGetService(({ seasonId, identity }) => ({
  ...makeRefereeURL({ seasonId, identity })
}));

export const loadReferees = createGetService(({ seasonId, limit, offset, query, include }) => ({
  ...(seasonId ? makeRefereesURL({ seasonId }) : { url: "/referees" }),
  params: {
    include,
    limit,
    offset,
    filter: { query: query || undefined }
  }
}));

export const updateReferee = createPatchService(({ seasonId, identity, attributes }) => ({
  ...makeRefereeURL({ seasonId, identity }),
  attributes
}));

export const deleteReferee = createDeleteService(makeRefereeURL);

export const mergeReferees = createPatchService(
  ({ seasonId, winnerId, firstName, lastName, externalId, refereeIds }) => ({
    url: `/seasons/${seasonId}/referees/${winnerId}/merge`,
    attributes: {
      firstName,
      lastName,
      externalId
    },
    identity: { type: "referees", id: winnerId },
    relationships: {
      duplicates: {
        data: refereeIds.filter(pid => pid !== winnerId).map(id => ({ type: "referees", id }))
      }
    }
  })
);
