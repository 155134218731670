import { connect } from "react-redux";

import {
  getRefereesMergeToolIsEnabled,
  getRefereesMergeToolStatus,
  getRefereesMergeToolCountRefereesSelected,
  getRefereesMergeToolWinnerId
} from "@/redux/referees/selectors";

import { refereesActions } from "@/redux/referees/actions";

import RefereesListMergeToolFooter from "./Footer";
import { mergeRefereesRoutine } from "@/redux/referees/routines";

const mapStateToProps = state => ({
  isEnabled: getRefereesMergeToolIsEnabled(state),
  status: getRefereesMergeToolStatus(state),
  countRefereesSelected: getRefereesMergeToolCountRefereesSelected(state),
  winnerId: getRefereesMergeToolWinnerId(state)
});

const mapDispatchToProps = dispatch => ({
  disable: () => dispatch(refereesActions.list.mergeTool.disable()),
  setStatus: status => dispatch(refereesActions.list.mergeTool.setStatus(status)),
  mergeReferees: () => dispatch(mergeRefereesRoutine())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RefereesListMergeToolFooter);
