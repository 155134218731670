import React from "react";
import PropTypes from "prop-types";

import LoggedInRoute from "@/containers/LoggedInRoute";
import { Switch, Redirect } from "react-router-dom";

import useCurrentSeason from "@/hooks/useCurrentSeason";
import useCurrentSeasonClearing from "@/hooks/useCurrentSeasonClearing";

import CurrentSeasonContext from "@/contexts/CurrentSeasonContext";

import EnsureCurrentSeasonActive from "@/layouts/EnsureCurrentSeasonActive";
import SeasonRosterIndex from "./pages/SeasonRosterIndex";
import SeasonRosterPlayersNew from "./pages/NewPlayer";
import SeasonRosterCoachesNew from "./pages/NewCoach";
import SeasonRosterPlayersEdit from "./pages/EditPlayer";
import SeasonRosterCoachesEdit from "./pages/EditCoach";
import SeasonRosterPlayersShow from "./pages/Player";
import SeasonRosterCoachesShow from "./pages/Coach";
import SeasonRosterPlayersPenaltyReport from "./pages/PlayerPenaltyReport";
import SeasonRosterCoachesPenaltyReport from "./pages/CoachPenaltyReport";
import SeasonRosterCsvImport from "./pages/SeasonRosterCsvImport";

const SeasonRosterRoute = ({ match }) => {
  const {
    params: { seasonId }
  } = match;

  const currentSeason = useCurrentSeason(seasonId);
  useCurrentSeasonClearing();

  return (
    <CurrentSeasonContext.Provider value={{ ...currentSeason, seasonId }}>
      <EnsureCurrentSeasonActive>
        <Switch>
          <LoggedInRoute
            path={`${match.url}/players/:playerId/penalty-report`}
            seasonId={seasonId}
            component={SeasonRosterPlayersPenaltyReport}
          />
          <LoggedInRoute
            path={`${match.url}/coaches/:coachId/penalty-report`}
            seasonId={seasonId}
            component={SeasonRosterCoachesPenaltyReport}
          />
          <LoggedInRoute
            path={`${match.url}/players/:playerId/edit`}
            seasonId={seasonId}
            component={SeasonRosterPlayersEdit}
          />
          <LoggedInRoute
            path={`${match.url}/coaches/:coachId/edit`}
            seasonId={seasonId}
            component={SeasonRosterCoachesEdit}
          />
          <LoggedInRoute path={`${match.url}/players/new`} seasonId={seasonId} component={SeasonRosterPlayersNew} />
          <LoggedInRoute path={`${match.url}/coaches/new`} seasonId={seasonId} component={SeasonRosterCoachesNew} />
          <LoggedInRoute
            path={`${match.url}/players/:playerId`}
            seasonId={seasonId}
            component={SeasonRosterPlayersShow}
          />
          <LoggedInRoute
            path={`${match.url}/coaches/:coachId`}
            seasonId={seasonId}
            component={SeasonRosterCoachesShow}
          />
          <LoggedInRoute path={`${match.url}/import-from-csv`} seasonId={seasonId} component={SeasonRosterCsvImport} />
          <LoggedInRoute path={`${match.url}/:tab`} seasonId={seasonId} component={SeasonRosterIndex} />

          <Redirect to={`${match.url}/players`} />
        </Switch>
      </EnsureCurrentSeasonActive>
    </CurrentSeasonContext.Provider>
  );
};

SeasonRosterRoute.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      seasonId: PropTypes.string.isRequired
    })
  }).isRequired
};

export default SeasonRosterRoute;
