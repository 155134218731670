import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  actions,
  playerSubmittingRoutine,
  getPlayerFormIsInvalid,
  getPlayerFormIsNotFilled,
  getPlayerFormIsPristine,
  getPlayerFormIsSaving,
  getPlayerFormIsDeleting
} from "@/redux/teamRoster";

export default function usePlayerForm(playerId) {
  const dispatch = useDispatch();

  const triggerReset = useCallback(() => dispatch(actions.playersForm.reset({ memberId: playerId })), [
    dispatch,
    playerId
  ]);

  const triggerEdit = useCallback(() => dispatch(actions.playersForm.edit({ memberId: playerId })), [
    dispatch,
    playerId
  ]);

  const triggerSave = useCallback(() => dispatch(playerSubmittingRoutine.trigger({ memberId: playerId })), [
    dispatch,
    playerId
  ]);

  const isInvalid = useSelector(state => getPlayerFormIsInvalid(state, playerId), [playerId]);

  const isNotFilled = useSelector(state => getPlayerFormIsNotFilled(state, playerId), [playerId]);

  const isPristine = useSelector(state => getPlayerFormIsPristine(state, playerId), [playerId]);

  const isSaving = useSelector(state => getPlayerFormIsSaving(state, playerId), [playerId]);

  const isDeleting = useSelector(state => getPlayerFormIsDeleting(state, playerId), [playerId]);

  return {
    triggerReset,
    triggerEdit,
    triggerSave,
    isInvalid,
    isNotFilled,
    isPristine,
    isSaving,
    isDeleting
  };
}
