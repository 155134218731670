import React from "react";

import DropdownInput from "./DropdownInput";

function FlaggedStatusInput() {
  return (
    <DropdownInput name="flagged" actionName="setFlaggedStatus" label="Flagged">
      <option value="">All</option>
      <option value="true">Flagged</option>
      <option value="false">Not Flagged</option>
    </DropdownInput>
  );
}

export default FlaggedStatusInput;
