import { createRoutine } from "redux-saga-routines";

export const seasonListLoadingRoutine = createRoutine("REPORT_DATA_SOURCES_LIST/SEASON_LIST/LOADING");

export const divisionListLoadingRoutine = createRoutine("REPORT_DATA_SOURCES_LIST/DIVISION_LIST/LOADING");

export const teamListLoadingRoutine = createRoutine("REPORT_DATA_SOURCES_LIST/TEAM_LIST/LOADING");

export const seasonDivisionListLoadingRoutine = createRoutine("REPORT_DATA_SOURCES_LIST/SEASON_DIVISION_LIST/LOADING");

export const divisionTeamListLoadingRoutine = createRoutine("REPORT_DATA_SOURCES_LIST/DIVISION_TEAM_LIST/LOADING");
