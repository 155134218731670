import { combineReducers } from "redux";
import { handleActions, combineActions } from "redux-actions";

import invitation from "./reducers/invitation";

import { loginAction } from "@/redux/login/actions";
import { logoutAction } from "@/redux/logout/actions";

import {
  loadAccountRoutine,
  accountValidationRoutine,
  accountUpdateRoutine,
  newPasswordValidationRoutine
} from "./routines";

import { account } from "./actions";

const defaultState = {
  user: {},
  fields: {
    email: {
      value: "",
      error: null
    },
    firstName: {
      value: "",
      error: null
    },
    lastName: {
      value: "",
      error: null
    },
    password: {
      value: "",
      error: null
    },
    passwordConfirmation: {
      value: "",
      error: null
    }
  },
  fUser: null,
  error: null,
  isLoading: false,
  isLoaded: false,
  isUpdating: false
};

const loadAccountRoutineRequestActionHandler = state => {
  return {
    ...state,
    isLoading: true,
  };
};

const loadAccountRoutineSuccessActionHandler = (state, { payload }) => {
  const [[, { attributes }]] = Object.entries(payload.users);

  let { fields } = state;

  for (let [field, value] of Object.entries(attributes)) {
    fields = Object.assign({}, fields, { [field]: { value, error: null } });
  }

  return {
    ...state,
    isLoaded: true,
    user: attributes,
    fields,
  };
};

const loadAccountRoutineFailureActionHandler = (state, { payload }) => {
  return {
    ...state,
    isLoaded: false,
    error: payload,
  };
} 

const loadAccountRoutineFulfillActionHandler = state => {
  return {
    ...state,
    fields: {
      ...state.fields,
      password: {
        value: "",
        error: null
      }
    },
    isLoading: false,
  };
};

const accountEditFieldChangeActionHandler = (state, { payload }) => {
  const [[field, value]] = Object.entries(payload);

  const fields = Object.assign({}, state.fields, {
    [field]: {
      value,
      error: null
    }
  });

  return {
    ...state,
    fields,
    error: null,
  };
};

const accountValidationRoutineFailureActionHandler = (state, { payload }) => {
  let { errors } = payload;
  let { fields } = state;

  for (let error of errors) {
    const [[field, title]] = Object.entries(error);
    const { value } = fields[field];
    fields = Object.assign({}, fields, { [field]: { value, error: title } });
  }

  return {
    ...state,
    fields,
  };
};

const newPasswordValidationRoutineFailureActionHandler = (state, { payload }) => {
  let { errors } = payload;
  let { fields } = state;

  for (let error of errors) {
    const [[field, title]] = Object.entries(error);
    const { value } = fields[field];
    fields = Object.assign({}, fields, { [field]: { value, error: title } });
  }

  return {
    ...state,
    fields,
  };
};

const accountUpdateRoutineTriggerActionHandler = state => {
  let { fields } = state;

  for (let [field, { value }] of Object.entries(fields)) {
    fields = Object.assign({}, fields, { [field]: { value, error: null } });
  }

  return {
    ...state,
    fields,
  };
};

const accountUpdateRoutineRequestActionHandler = state => {
  return {
    ...state,
    isUpdating: true,
  };
}

const accountUpdateRoutineFailureActionHandler = (state, { payload }) => {
  let { error = null } = payload;

  return {
    ...state,
    error,
  };
};

const accountUpdateRoutineSuccessActionHandler = (state, { payload }) => {
  const out = {
    ...state,
    isLoaded: true,
    error: defaultState.error,
    user: {
      ...state.user,
      firstName: state.fields.firstName.value,
      lastName: state.fields.lastName.value,
      email: state.fields.email.value
    }
  };
  return out;
};

const accountUpdateRoutineFulfillActionHandler = state => {
  return {
    ...state,
    fields: {
      ...state.fields,
      password: {
        value: "",
        error: null
      }
    },
    isUpdating: false,
  };
}

const setFUserHandler = (state, { payload: fUser }) => {
  return {
    ...state,
    fUser,
  };
};

const clearFUserHandler = (state) => {
  return {
    ...state,
    fUser: defaultState.fUser,
  };
};

const defaultHandler = (state) => {
  return {
    ...defaultState,
    fUser: state.fUser,
  };
}

export default combineReducers({
  account: handleActions(
    {
      [combineActions(loadAccountRoutine.TRIGGER, loginAction, logoutAction)]: defaultHandler,
      [loadAccountRoutine.REQUEST]: loadAccountRoutineRequestActionHandler,
      [loadAccountRoutine.SUCCESS]: loadAccountRoutineSuccessActionHandler,
      [loadAccountRoutine.FAILURE]: loadAccountRoutineFailureActionHandler,
      [loadAccountRoutine.FULFILL]: loadAccountRoutineFulfillActionHandler,
      [account.edit.fieldChange]: accountEditFieldChangeActionHandler,
      [accountValidationRoutine.FAILURE]: accountValidationRoutineFailureActionHandler,
      [newPasswordValidationRoutine.FAILURE]: newPasswordValidationRoutineFailureActionHandler,
      [accountUpdateRoutine.TRIGGER]: accountUpdateRoutineTriggerActionHandler,
      [accountUpdateRoutine.REQUEST]: accountUpdateRoutineRequestActionHandler,
      [accountUpdateRoutine.SUCCESS]: accountUpdateRoutineSuccessActionHandler,
      [accountUpdateRoutine.FAILURE]: accountUpdateRoutineFailureActionHandler,
      [accountUpdateRoutine.FULFILL]: accountUpdateRoutineFulfillActionHandler,
      [account.fUser.set]: setFUserHandler,
      [account.fUser.clear]: clearFUserHandler
    },
    defaultState
  ),
  invitation
});
