import "./style.scss";
import React, { useMemo } from "react";
import PropTypes from "prop-types";

export const progressHints = [
  "Stickhandling",
  "Driving the Net",
  "Taping Sticks",
  "Picking Corners",
  "Cleaning the Ice",
  "Drop Pass",
  "Top Cheddar",
  "Warming the Bench",
  "Dropping the Gloves",
  "Stacking the Pads",
  "Bar Down",
  "Cherrypicking",
  "Going Coast to Coast",
  "Dirty Danglin'",
  "Watching Highlights",
  "Dipsy Doodlin'",
  "Putting the Little Brother in Net"
];

function ProgressBar({ title, total, state, pending, requested, success, failed }) {
  const progressStatus = useMemo(
    () => {
      switch (true) {
        // eslint-disable-next-line no-fallthrough
        default:
        case !!pending || total > 0:
          return `${progressHints[Math.floor(Math.random() * progressHints.length)]}...`;

        case total === success + failed && total > 0:
          return "C'est la Butt!";
      }
    },
    [success, failed, total, pending]
  );

  return (
    <div className="csv-roster-import-progress-bar">
      <h5 className="title">{title}</h5>
      <p className="status">{progressStatus}</p>
      <div className={`graph ${state}`}>
        {pending > 0 &&
          Array(pending)
            .fill(0)
            .map((x, i) => <div className={`item pending`} key={i} />)}
        {requested > 0 &&
          Array(requested)
            .fill(0)
            .map((x, i) => <div className={`item requested`} key={i} />)}
        {success > 0 &&
          Array(success)
            .fill(0)
            .map((x, i) => <div className={`item success`} key={i} />)}
        {failed > 0 &&
          Array(failed)
            .fill(0)
            .map((x, i) => <div className={`item failed`} key={i} />)}
      </div>
    </div>
  );
}

ProgressBar.propTypes = {
  // title text shown on the left of the progress bar
  title: PropTypes.string.isRequired,

  // this sets the entire progress state, but is overridden in segments by the progress failures
  state: PropTypes.oneOf(["pending", "requested", "success", "failed"]),

  // progress numbers
  total: PropTypes.number,
  pending: PropTypes.number,
  requested: PropTypes.number,
  success: PropTypes.number,
  failed: PropTypes.number
};

ProgressBar.defaultProps = {
  state: "pending",
  pending: 0,
  requested: 0,
  success: 0,
  failed: 0
};

export default ProgressBar;
