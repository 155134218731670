import { handleActions } from "redux-actions";

import { toggleGameViewedRoutine } from "@/redux/games/routines";
import { gameNoteFormSubmittingRoutine } from "@/redux/gameNotes/routines";
import { loadGamesheetRoutine, exportGamesheetToPDF } from "../routines";
import { clearGamesheet } from "../actions";

const initialState = {
  isLoaded: false,
  isLoading: false,
  isViewed: false,
  isForbidden: false,
  isExporting: false,
  countNotes: 0,
  associationLogo: "",
  leagueLogo: ""
};

export default handleActions(
  {
    [loadGamesheetRoutine.REQUEST]: state => ({ ...state, isLoading: true }),
    [loadGamesheetRoutine.SUCCESS]: (state, { payload: { game } }) => ({
      ...state,
      isLoaded: true,
      associationLogo: game.association.logo,
      leagueLogo: game.league.logo,
      countNotes: (game.notes || []).length
    }),
    [loadGamesheetRoutine.FAILURE]: (state, { payload: { responseStatus } }) => ({
      ...state,
      isForbidden: responseStatus === 401
    }),
    [loadGamesheetRoutine.FULFILL]: state => ({ ...state, isLoading: false }),
    [clearGamesheet]: () => initialState,
    [toggleGameViewedRoutine.SUCCESS]: (state, { payload: { viewed } }) => ({
      ...state,
      isViewed: state.isLoaded ? viewed : false
    }),
    [exportGamesheetToPDF.REQUEST]: state => ({ ...state, isExporting: true }),
    [exportGamesheetToPDF.FULFILL]: state => ({ ...state, isExporting: false }),
    [gameNoteFormSubmittingRoutine.SUCCESS]: state => ({
      ...state,
      countNotes: state.countNotes + 1
    })
  },
  initialState
);
