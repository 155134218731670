export const FIELDS_MAPPING = {
  value: "Key",
  description: "Description",
  liveScoringScopes: "Live Scoring",
  roles: "Seasons"
};

export const DEFAULT_VALUES = {
  value: "",
  description: "",
  liveScoringScopes: ["read", "write"],
  roles: []
};

export const CONSTRAINTS = {
  value: {
    presence: { allowEmpty: false, message: "^Key can’t be blank" }
  },
  description: {
    presence: { allowEmpty: false, message: "^Description can’t be blank" }
  }
};
