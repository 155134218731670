import React from "react";

import Layout from "@/layouts/Layout";
import Head from "@/components/Head";
import TitleBar from "@/components/TitleBar";

function InsufficientPrivileges() {
  return (
    <Layout>
      <Head title="Insufficient Privileges" />
      <TitleBar title="Insufficient Privileges" />

      <p>Oops, it looks like your account has insufficient privileges to access this resource.</p>

      <p>
        If you have an invitation code, <a href="/account/invitation">click here</a> to accept it.
      </p>
      <p>
        If you do not have an invitation code and you believe this is an error, please contact us at{" "}
        <a href="mailto:support@gamesheetinc.com">support@gamesheetinc.com</a>.<br />
        In the email provide your:
      </p>

      <ul>
        <li>username,</li>
        <li>the resource (league, season, team) you were attempting to access.</li>
      </ul>

      <p>We’ll get you back up and running in no time!</p>
    </Layout>
  );
}

export default InsufficientPrivileges;
