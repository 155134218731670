import { handleActions } from "redux-actions";

import { loadGamesheetRoutine } from "../../routines";
import { clearGamesheet } from "../../actions";
import reduceShots from "../../utils/reduceShots";

const initialState = {
    home: [],
    visitor: []
};

export default handleActions(
    {
        [loadGamesheetRoutine.SUCCESS]: reduceShots,
        [clearGamesheet]: () => initialState
    },
    initialState
);
