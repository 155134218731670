import React from "react";
import PropTypes from "prop-types";

import { Switch } from "react-router-dom";

import LoggedInRoute from "@/containers/LoggedInRoute";

import PenaltyAccumulationReportDataSourcesPage from "./DataSourcesPage";
import PenaltyAccumulationReportPage from "./ReportPage";
import useDataSourcesClearing from "./hooks/useDataSourcesClearing";

function PenaltyAccumulationReportRoute({ match }) {
  useDataSourcesClearing();

  return (
    <Switch>
      <LoggedInRoute path={`${match.url}/sources`} component={PenaltyAccumulationReportDataSourcesPage} />

      <LoggedInRoute path="" component={PenaltyAccumulationReportPage} />
    </Switch>
  );
}

PenaltyAccumulationReportRoute.propTypes = {
  match: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired
};

export default PenaltyAccumulationReportRoute;
