import React from "react";
import PropTypes from "prop-types";

import useImagePreview from "./hooks/useImagePreview";

function ImagePreview({ url, file, alt }) {
  const src = useImagePreview({ url, file });

  if (!src) {
    return null;
  }

  return <img src={src} alt={alt} />;
}

ImagePreview.propTypes = {
  url: PropTypes.string,
  file: PropTypes.shape({}),
  alt: PropTypes.string
};

ImagePreview.defaultProps = {
  alt: "Image"
};

export default ImagePreview;
