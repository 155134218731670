import { useCallback } from "react";

export default function useScheduledGameFormScorekeeperInput(options) {
  const { value: scorekeeperValue, setValue: setScorekeeperValue, setVisited } = options;

  const { name, phone } = scorekeeperValue;

  const onNameChange = useCallback(
    ({ target: { value } }) => setScorekeeperValue({ ...scorekeeperValue, name: value }),
    [setScorekeeperValue, scorekeeperValue]
  );

  const onPhoneChange = useCallback(
    ({ target: { value } }) => setScorekeeperValue({ ...scorekeeperValue, phone: value }),
    [setScorekeeperValue, scorekeeperValue]
  );

  const onBlur = useCallback(() => setVisited(), [setVisited]);

  return {
    name: {
      onBlur,
      value: name,
      onChange: onNameChange
    },
    phone: {
      onBlur,
      value: phone,
      onChange: onPhoneChange
    }
  };
}
