import { handleActions } from "redux-actions";

import { suspensionReportLoadingRoutine } from "../routines";
import actions from "../actions";

const initialState = {
  isLoaded: false,
  isLoading: false,
  isForbidden: false,
  players: {},
  coaches: {},
  teams: {},
  divisions: {},
  seasons: {},
  leagues: {},
  associations: {},
  games: {},
  suspensions: [],
  sorting: {
    column: "game.startTime",
    order: "desc"
  }
};

const defaultSortingOrder = {
  "game.startTime": "desc",
  "game.number": "asc",
  "suspendedPerson.type": "asc",
  "suspendedPerson.name": "asc",
  "team.title": "asc",
  "division.title": "asc"
};

function reducePerson(result, { person: { id, firstName, lastName, season } }) {
  return {
    ...result,
    [id]: {
      firstName,
      lastName,
      seasonId: season && season.id
    }
  };
}

function reduceCoaches({ suspensionReports }) {
  return suspensionReports.filter(({ person: { type } }) => type === "coaches").reduce(reducePerson, {});
}

function reducePlayers({ suspensionReports }) {
  return suspensionReports.filter(({ person: { type } }) => type === "players").reduce(reducePerson, {});
}

function reduceTeams({ suspensionReports }) {
  return suspensionReports.reduce(
    (result, { suspensionReportTeam: { id, title, season } }) => ({
      ...result,
      [id]: {
        title: title,
        seasonId: season.id
      }
    }),
    {}
  );
}

function reduceDivisions({ suspensionReports }) {
  return suspensionReports.reduce(
    (
      result,
      {
        suspensionReportTeam: {
          division: { id, title, season }
        }
      }
    ) => ({
      ...result,
      [id]: {
        title,
        seasonId: season.id
      }
    }),
    {}
  );
}

function reduceSeasons({ suspensionReports }) {
  return suspensionReports.reduce(
    (result, { season: { id, title, league, association, type } }) => ({
      ...result,
      [id]: {
        title,
        leagueId: league.id,
        associationId: association.id,
        isArchived: type === "archivedSeasons"
      }
    }),
    {}
  );
}

function reduceLeagues({ suspensionReports }) {
  return suspensionReports.reduce(
    (result, { season: { league } }) => ({
      ...result,
      [league.id]: {
        title: league.title
      }
    }),
    {}
  );
}

function reduceAssociations({ suspensionReports }) {
  return suspensionReports.reduce(
    (result, { season: { association } }) => ({
      ...result,
      [association.id]: {
        title: association.title
      }
    }),
    {}
  );
}

function reduceGames({ suspensionReports }) {
  return suspensionReports.reduce(
    (result, { suspensionReportGame: { id, number, startTime, season } }) => ({
      ...result,
      [id]: {
        number,
        startTime,
        seasonId: season.id
      }
    }),
    {}
  );
}

function reduceSuspensions({ suspensionReports }) {
  return suspensionReports.map(
    ({
      id,
      suspensionReportTeam: team,
      suspensionReportGame: game,
      season,
      person,
      suspension: { number, length }
    }) => ({
      id,
      teamId: team.id,
      gameId: game.id,
      divisionId: team.division.id,
      seasonId: season.id,
      person: { id: person.id, type: person.type },
      number,
      length
    })
  );
}

function reduceLoadingRequest(state) {
  return { ...state, isLoading: true };
}

function reduceLoadingSuccess(state, { payload }) {
  const players = reducePlayers(payload);
  const coaches = reduceCoaches(payload);
  const teams = reduceTeams(payload);
  const divisions = reduceDivisions(payload);
  const seasons = reduceSeasons(payload);
  const leagues = reduceLeagues(payload);
  const associations = reduceAssociations(payload);
  const games = reduceGames(payload);
  const suspensions = reduceSuspensions(payload);

  return {
    ...state,
    players,
    coaches,
    teams,
    divisions,
    seasons,
    leagues,
    associations,
    games,
    suspensions,
    isLoaded: true
  };
}

function reduceLoadingFailure(state, { payload: { responseStatus } }) {
  return { ...state, isLoaded: false, isForbidden: responseStatus === 401 };
}

function reduceLoadingFulfill(state) {
  return { ...state, isLoading: false };
}

function reduceClearing(state) {
  return { ...initialState };
}

function reduceToggleSorting(state, { payload: column }) {
  let order = state.sorting.order;

  if (state.sorting.column === column) {
    order = order === "desc" ? "asc" : "desc";
  } else {
    order = defaultSortingOrder[column];
  }

  return {
    ...state,
    sorting: {
      column,
      order
    }
  };
}

export default handleActions(
  {
    [suspensionReportLoadingRoutine.REQUEST]: reduceLoadingRequest,
    [suspensionReportLoadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [suspensionReportLoadingRoutine.FAILURE]: reduceLoadingFailure,
    [suspensionReportLoadingRoutine.FULFILL]: reduceLoadingFulfill,
    [actions.report.clear]: reduceClearing,
    [actions.report.toggleSorting]: reduceToggleSorting
  },
  initialState
);
