import React, { Fragment, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import StickyFooter from "@/components/StickyFooter";
import useFooter from './useFooter';

const Footer = ({ seasonId }) => {

  const { clearSelections, startDelete, setSelectAllGames, toDeleteLength, selectAllGames, selected, meta } = useFooter({ seasonId });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const totalCount = (meta && meta.filteredCount) || 0; 

  const triggerDelete = () => {
    toggleModal();
    startDelete();
  };

  const cancelSelect = () => {
    clearSelections();
    setSelectAllGames(false);
   }

  const renderSelectedCount = () => {
    if (toDeleteLength === 0) {
      return "No scheduled games selected";
    } else {
      return `${selectAllGames & selected.length >= 20 ? totalCount : toDeleteLength } game${toDeleteLength === 1 ? '' : 's'} selected`;
    }
  };

  return (
    <Fragment>
      {toDeleteLength > 0 &&
      (<StickyFooter>
        <p className="sticky-footer__text">{renderSelectedCount()}</p>

          <div className="sticky-footer__secondary">
            <Button
              outline
              type="button"
              color="secondary"
              onClick={cancelSelect}
              disabled={toDeleteLength === 0}
            >
              Cancel
            </Button>
          </div>

          <div className="sticky-footer__primary">
            <Button
              type="button"
              color="danger"
              onClick={toggleModal}
              disabled={toDeleteLength === 0}
            >
              Delete
            </Button>
          </div>
      </StickyFooter>)}
      <Modal
        className="confirm-deletion-modal"
        isOpen={isModalOpen}
        toggle={toggleModal}
        centered
        fade={false}
      >
        <ModalHeader>Confirmation</ModalHeader>
        <ModalBody>
          <p>
            Are you sure you want to delete {!selectAllGames ? toDeleteLength : totalCount} scheduled {toDeleteLength === 1 ? 'game' : 'games'}?
          </p>
        </ModalBody>

        <ModalFooter>
          <div className="d-flex w-100 justify-content-between">
            <Button
              type="button"
              color="secondary"
              onClick={toggleModal}
            >
              Cancel
            </Button>

            <Button
              outline
              type="button"
              color="danger"
              onClick={triggerDelete}
              disabled={toDeleteLength === 0 || !isModalOpen}
            >
              Delete
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default Footer;