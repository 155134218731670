import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

import nl2br from "@/utils/nl2br";

class CoachesListMergeToolCoach extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    seasonId: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    externalId: PropTypes.string,
    teams: PropTypes.string,
    divisions: PropTypes.string,
    isWinner: PropTypes.bool,
    chooseAsWinner: PropTypes.func
  };

  static defaultProps = {
    externalId: "",
    teams: "",
    divisions: "",
    isWinner: false,
    chooseAsWinner() {}
  };

  render() {
    const { id, seasonId, firstName, lastName, externalId, teams, divisions, isWinner, chooseAsWinner } = this.props;

    return (
      <tr>
        <td>
          <Link className="full-name" to={`/seasons/${seasonId}/roster/coaches/${id}`}>
            {firstName} <span className="last-name">{lastName}</span>
          </Link>
        </td>
        <td>{externalId}</td>
        <td>{nl2br(teams)}</td>
        <td>{nl2br(divisions)}</td>
        <td>
          <Button outline={!isWinner} color="success" size="sm" onClick={chooseAsWinner}>
            Keep
          </Button>
        </td>
      </tr>
    );
  }
}

export default CoachesListMergeToolCoach;
