import React from "react";

import ResourceSelect from "./ResourceSelect";

import useSeasonSelect from "./hooks/useSeasonSelect";

function SeasonSelect() {
  const { isLoaded, isLoading, options, isEnabled, toggleSelected, resourceType, isOptionSelected, isAllOptionsSelected } = useSeasonSelect();

  return (
    <ResourceSelect
      label="Select season(s)"
      isLoaded={isLoaded}
      isLoading={isLoading}
      options={options}
      isEnabled={isEnabled}
      toggleSelected={toggleSelected}
      isOptionSelected={isOptionSelected} 
      isAllOptionsSelected={isAllOptionsSelected}
      resourceType="seasonList"
    />
  );
}

export default SeasonSelect;
