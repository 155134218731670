import { connect } from "react-redux";

import Form from "./Form";

import {
  getGameNoteFormFields,
  getGameNoteFormIsOperating,
  getGameNoteFormIsPristine,
  getGameNoteFormIsNotFilled
} from "@/redux/gameNotes/selectors";

import { changeGameNoteFormField, disableGameNoteForm } from "@/redux/gameNotes/actions";

import { gameNoteFormSubmittingRoutine } from "@/redux/gameNotes/routines";

const mapStateToProps = state => ({
  fields: getGameNoteFormFields(state),
  isOperating: getGameNoteFormIsOperating(state),
  isPristine: getGameNoteFormIsPristine(state),
  isNotFilled: getGameNoteFormIsNotFilled(state)
});

const mapDispatchToProps = (dispatch, { gameId, seasonId }) => ({
  changeField: payload => dispatch(changeGameNoteFormField(payload)),
  submitForm: () => dispatch(gameNoteFormSubmittingRoutine({ gameId, seasonId })),
  disableForm: () => dispatch(disableGameNoteForm())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form);
