import { handleActions, combineActions } from "redux-actions";

import actions from "../actions";

import { submittingRoutine, loadingRoutine, deletingRoutine, verificationEmailResendingRoutine } from "../routines";

const initialState = {
  isOperating: false,
  isLoading: false,
  isLoaded: false,
  userName: null,
  userEmail: null,
  userStatus: "",
  resendingVerificationEmail: false,
  resendingVerificationEmailFailed: false
};

function reduceOperationRequest(state) {
  return { ...state, isOperating: true };
}

function reduceOperationFulfill(state) {
  return { ...state, isOperating: false };
}

function reduceLoadingRequest(state) {
  return { ...state, isLoading: true };
}

function reduceLoadingSuccess(state, { payload: { user } }) {
  const { firstName, lastName, email, status } = user;

  return {
    ...state,
    isLoaded: true,
    userName: `${firstName} ${lastName}`,
    userEmail: email,
    userStatus: status
  };
}

function reduceLoadingFailure(state) {
  return { ...state, isLoaded: false };
}

function reduceLoadingFulfill(state) {
  return { ...state, isLoading: false };
}

function reduceResendingVerificationEmailRequest(state) {
  return {
    ...state,
    resendingVerificationEmail: true,
    resendingVerificationEmailFailed: false
  };
}

function reduceResendingVerificationEmailFailure(state) {
  return { ...state, resendingVerificationEmailFailed: true };
}

function reduceResendingVerificationEmailFulfill(state) {
  return { ...state, resendingVerificationEmail: false };
}

export default handleActions(
  {
    [submittingRoutine.REQUEST]: reduceOperationRequest,
    [submittingRoutine.FULFILL]: reduceOperationFulfill,
    [deletingRoutine.REQUEST]: reduceOperationRequest,
    [deletingRoutine.FULFILL]: reduceOperationFulfill,
    [loadingRoutine.REQUEST]: reduceLoadingRequest,
    [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [loadingRoutine.FAILURE]: reduceLoadingFailure,
    [loadingRoutine.FULFILL]: reduceLoadingFulfill,
    [verificationEmailResendingRoutine.REQUEST]: reduceResendingVerificationEmailRequest,
    [verificationEmailResendingRoutine.FAILURE]: reduceResendingVerificationEmailFailure,
    [verificationEmailResendingRoutine.FULFILL]: reduceResendingVerificationEmailFulfill,
    [combineActions(actions.clear, actions.reset)]: () => ({ ...initialState })
  },
  initialState
);
