import { combineReducers } from "redux";

import createOffsetPaginationReducer from "@/redux/common/pagination/createOffsetPaginationReducer";

import { LoadInvitationsListRoutine } from "../../routines";
import { invitationsListActions } from "../../actions";

import data from "./data";

const pagination = createOffsetPaginationReducer(LoadInvitationsListRoutine, invitationsListActions.clear);

export default combineReducers({
  data,
  pagination
});
