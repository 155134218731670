import Papa from "papaparse";

import _camelCase from "lodash/camelCase";
import _trim from "lodash/trim";
import _startCase from "lodash/startCase";

import ReadingError from "./ReadingError";

const REQUIRED_COLUMNS = [
  "type",
  "firstName",
  "lastName",
  "externalId",
  "jerseyNumber",
  "teamId",
  "position",
  "designation",
  "affiliated"
];

const OPTIONAL_COLUMNS = [
  "birthdate",
  "weight",
  "height",
  "shotHand",
  "country",
  "state",
  "hometown",
  "draftedBy",
  "committedTo",
  "bio"
];

const COLUMNS = [...REQUIRED_COLUMNS, ...OPTIONAL_COLUMNS];

function makeHumanReadableColumns(cols) {
  return cols.map(col => `"${_startCase(col)}"`);
}

function reader(file) {
  return new Promise(resolve => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: "greedy",
      transformHeader: hd => _camelCase(hd),
      transform: (value, col) => {
        const trimmedValue = _trim(value);

        if (col === "type") {
          return trimmedValue.toLowerCase();
        }

        return trimmedValue;
      },
      complete: res => resolve(res)
    });
  });
}

export default async function read(file, { teamId }) {
  const { data: records, errors } = await reader(file);

  if (errors.length > 0) {
    throw new ReadingError("CSV file is invalid");
  } else if (records.length === 0) {
    throw new ReadingError("CSV file is empty");
  }

  const columns = Object.keys(records[0]);
  const validColumns = teamId ? COLUMNS.filter(col => col !== "teamId") : COLUMNS;
  const missingColumns = validColumns.filter(
    validCol => !OPTIONAL_COLUMNS.includes(validCol) && !columns.includes(validCol)
  );

  if (!!missingColumns.length) {
    const humanReadableColumns = makeHumanReadableColumns(missingColumns);
    const message = `CSV file is missing ${
      missingColumns.length > 1 ? "the following columns:" : "the column:"
    } ${humanReadableColumns.join(", ")}`;
    throw new ReadingError(message);
  }

  const unknownColumns = columns.filter(col => !OPTIONAL_COLUMNS.includes(col) && !validColumns.includes(col));

  if (unknownColumns.length > 0) {
    const humanReadableColumns = makeHumanReadableColumns(unknownColumns);

    let message;

    if (unknownColumns.length === 1) {
      message = `CSV file includes unknown column ${humanReadableColumns[0]}`;
    } else {
      message = `CSV file includes the following unknown columns: ${humanReadableColumns.join(", ")}`;
    }

    throw new ReadingError(message);
  }

  if (teamId) {
    for (var index in records) {
      records[index].teamId = teamId;
    }
  }

  const teamIds = records
    .map(({ teamId }) => teamId)
    .reduce((result, teamId) => (result.includes(teamId) || teamId === "" ? result : [...result, teamId]), []);

  return { records, teamIds };
}
