import React from "react";
import PropTypes from "prop-types";

import { FormGroup } from "reactstrap";

import FormItem from "@/components/FormItem";

function TotalPenaltyMinutesText({ totalPenaltyMinutes }) {
  return (
    <FormItem>
      <FormGroup className="col-md-6">Total Penalty Minutes</FormGroup>
      <FormGroup className="col-md-6">{`${totalPenaltyMinutes} min`}</FormGroup>
    </FormItem>
  );
}

TotalPenaltyMinutesText.propTypes = {
  totalPenaltyMinutes: PropTypes.number.isRequired
};

export default TotalPenaltyMinutesText;
