import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class Author extends PureComponent {
  static propTypes = {
    data: PropTypes.shape({
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired
    })
  };

  static defaultProps = {
    data: null
  };

  render() {
    const { data } = this.props;

    if (data === null) {
      return null;
    }

    const { name, email } = data;

    return (
      <div>
        <h5>{name}</h5>
        <a href={`mailto:${email}`}>{email}</a>
      </div>
    );
  }
}

export default Author;
