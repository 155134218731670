import React from "react";

import FilterSelect from "./FilterSelect";

import useAssociationSelect from "./hooks/useAssociationSelect";

function AssociationSelect() {
  const { options, selectedId, isLoaded, isLoading, setSelectedId } = useAssociationSelect();

  return (
    <FilterSelect
      placeholder="All associations"
      options={options}
      value={selectedId}
      isLoaded={isLoaded}
      isLoading={isLoading}
      onChange={setSelectedId}
    />
  );
}

export default AssociationSelect;
