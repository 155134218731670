import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";

import { InvitationFormGUI } from "./components/InvitationFormGUI";
import { InvitationReviewGUI } from "./components/InvitationReviewGUI";
import { OptionallySyncTeamGUI } from "./components/OptionallySyncTeamGUI";
import { MergeConfirmationGUI } from "./components/MergeConfirmationGUI";
import { screens } from "@/redux/account/reducers/invitation";
import { useUrlInvitationCode } from "./hooks/useUrlInvitationCode";

export const Invitation = (props) => {
  const {
    fields: { code, merge, prototeamId },
    codeInfo,
    userTeams,
    mergedTeam,
    onWrongInvitation,
    onBackToVerify,
    onAccept,
    onVerifyMerge,
    onRejectMerge,
    showScreen,
    error,
    codeIsLoading,
    acceptIsLoading,
    mergedTeamIsLoading,
    prototeamToLink,
  } = props;

  useUrlInvitationCode();

  const handleChange = ({ target }) => {
    const { name, value } = target;

    props.onChange({
      [name]: value
    });
  };

  const setMergeWithExisting = (val) => {
    props.onChange({
      merge: val,
    });
  };

  const handleOptionsSelectChange = ({ target }) => {
    props.onChange({
      prototeamId: target.value,
    });
  };

  const handleConfirmInvitationCorrect = () => {
    if (props.userTeams.teams.length > 0) {
      props.onCorrectInvitation();
    } else {
      props.onAccept();
    }
  }

  useEffect(() => {
    return () => {
      props.clear();
    }
  }, []);

  switch (showScreen) {
    case screens.ENTER_CODE:
      return (<InvitationFormGUI
        code={code}
        onChange={handleChange}
        onSubmit={props.onSubmit}
        error={error}
        nextScreenIsLoading={codeIsLoading || acceptIsLoading}
      />);
    case screens.VERIFY_TEAM_INVITATION:
      return (<InvitationReviewGUI
        codeInfo={codeInfo}
        onWrongInvitation={onWrongInvitation}
        onInvitationIsCorrect={handleConfirmInvitationCorrect}
        nextScreenIsLoading={userTeams.isLoading}
        confirmButtonText={userTeams.teams.length > 0 ? "Continue" : "Accept Invitation"}
      />);
    case screens.HOW_TO_ADD:
      return (<OptionallySyncTeamGUI
        codeInfo={codeInfo}
        userTeams={userTeams}
        merge={merge}
        prototeamId={prototeamId}
        setMergeWithExisting={setMergeWithExisting}
        onOptionsSelectChange={handleOptionsSelectChange}
        onBackToVerify={onBackToVerify}
        onAccept={merge.value ? onVerifyMerge : onAccept}
        nextScreenIsLoading={merge.value ? mergedTeamIsLoading : acceptIsLoading}
      />);
    case screens.MERGE_CONFIRMATION:
      return (<MergeConfirmationGUI
        prototeamToLink={prototeamToLink}
        mergedTeam={mergedTeam}
        nextScreenIsLoading={acceptIsLoading}
        onRejectMerge={onRejectMerge}
        onMergeIsCorrect={onAccept}
      />);
    default:
      return (<Fragment />);
  }
}

Invitation.propTypes = {
  showScreen: PropTypes.string.isRequired,
  fields: PropTypes.shape({
    code: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.string,
    }).isRequired,
    merge: PropTypes.shape({
      value: PropTypes.bool,
    }).isRequired,
    prototeamId: PropTypes.shape({
      value: PropTypes.string,
    }).isRequired,
  }),
  codeInfo: PropTypes.shape({
    team: PropTypes.shape({
      logo: PropTypes.string,
      title: PropTypes.string,
      players: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        photo: PropTypes.string,
        number: PropTypes.string,
        duty: PropTypes.string,
      })),
      season: PropTypes.shape({
        title: PropTypes.string,
      }),
      division: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
  }),
  userTeams: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    teams: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      prototeam: PropTypes.shape({
        id: PropTypes.string.isRequired,
        logo: PropTypes.string,
        title: PropTypes.string.isRequired,
      }),
      title: PropTypes.string,
      season: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
      division: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    })),
  }),
  mergedTeam: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    logo: PropTypes.string,
    season: PropTypes.shape({
      title: PropTypes.string,
    }),
    division: PropTypes.shape({
      title: PropTypes.string,
    }),
    players: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      photo: PropTypes.string,
      number: PropTypes.string,
      duty: PropTypes.string,
    })),
  }),
  prototeamToLink: PropTypes.shape({
    id: PropTypes.string,
    logo: PropTypes.string,
    title: PropTypes.string,
  }),
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCorrectInvitation: PropTypes.func,
  onWrongInvitation: PropTypes.func,
  onBackToVerify: PropTypes.func,
  onAccept: PropTypes.func,
  onVerifyMerge: PropTypes.func,
  onRejectMerge: PropTypes.func,
  clear: PropTypes.func,
  mergedTeamIsLoading: PropTypes.bool.isRequired,
  codeIsLoading: PropTypes.bool.isRequired,
  acceptIsLoading: PropTypes.bool.isRequired,
};

export default Invitation;
