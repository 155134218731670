import React, { useState, Fragment} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import classNames from "classnames";
import { Input, Button } from "reactstrap";
import { Dialog, DialogActions } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { TimePicker } from "material-ui-time-picker";

const gameSheetTheme = createMuiTheme({
  typography: {
    fontFamily: "inherit"
  },
  palette: {
    primary: {
      main: "#36383d"
    },
    common: {
      white: "#fec307"
    }
  }
});

const TimeInput = ({ id, name, value, isDirty, isInvalid, onChange}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [time, setTime] = useState(value);

  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);


  const handleSave = () => {
    onChange(moment(time), name);
    closeDialog();
  };


  return (
    <Fragment>
      <Input
        className={classNames({
          "is-invalid": isDirty && isInvalid
        })}
        id={id}
        name={name}
        value={value !== "" ? moment(value, "HH:mm").format("hh:mm A") : ""}
        placeholder="--:--"
        onClick={openDialog}
        onChange={() => {}}
        readOnly
        style={{ backgroundColor: "#ffffff" }}
      />

      <ThemeProvider theme={gameSheetTheme}>
        <Dialog open={isOpen}>
          <TimePicker
            defaultValue={moment("00:00", "HH:mm").toDate()}
            value={value !== "" ? moment(value, "HH:mm").toDate() : undefined}
            onChange={newTime => { setTime(newTime)}}
          />
          <DialogActions>
            <Button outline color="secondary" size="sm" onClick={closeDialog}>
              Cancel
            </Button>
            <Button color="success" size="sm" onClick={handleSave} disabled={value === time}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </Fragment>
  );
};

TimeInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  mode: PropTypes.string,
  isDirty: PropTypes.bool,
  isInvalid: PropTypes.bool,
  onChange: PropTypes.func,
};

TimeInput.defaultProps = {
  value: "",
  isDirty: false,
  isInvalid: false,
  onChange() {}
};

export default TimeInput;
