import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

function GameLink({ seasonId, id, children, ...extras }) {
  return (
    <Link to={`/seasons/${seasonId}/games/completed/${id}`} {...extras}>
      {children}
    </Link>
  );
}

GameLink.propTypes = {
  seasonId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default GameLink;
