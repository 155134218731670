import { useCallback } from "react";
import { useSelector } from "react-redux";

import {
  getSelectedTeams,
  getSelectedDivisions,
  getSelectedSeasons,
  getSelectedLeagues,
  getSelectedAssociations
} from "@/redux/roleForm/selectors";

function countResources(singular, plural, count) {
  if (count === 1) {
    return { text: `${count} ${singular}`, count };
  }

  return { text: `${count} ${plural}`, count };
}

export default function useSelectedResourcesCount() {
  const teamIds = useSelector(getSelectedTeams);
  const divisionIds = useSelector(getSelectedDivisions);
  const seasonIds = useSelector(getSelectedSeasons);
  const leagueIds = useSelector(getSelectedLeagues);
  const associationIds = useSelector(getSelectedAssociations);
  
  const countTeams = teamIds.length;
  const countDivisions = divisionIds.length;
  const countSeasons = seasonIds.length;
  const countLeagues = leagueIds.length;
  const countAssociations = associationIds.length;

  if (countTeams > 0) {
    return countResources("team", "teams", countTeams);
  } else if (countDivisions > 0) {
    return countResources("division", "divisions", countDivisions);
  } else if (countSeasons > 0) {
    return countResources("season", "seasons", countSeasons);
  } else if (countLeagues > 0) {
    return countResources("league", "leagues", countLeagues);
  } else if (countAssociations > 0) {
    return countResources("association", "associations", countAssociations);
  }

  return { count: 0 };
}
