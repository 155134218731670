import { connect } from "react-redux";

import {
  getTrainerVisitsReportIsLoaded,
  getTrainerVisitsReportIsLoading,
  getTrainerVisitsReportSummaryStats,
  getTrainerVisitsReportVisitList
} from "@/redux/trainerVisitsReport/selectors";

import Report from "./Report";

const mapStateToProps = state => ({
  isLoading: getTrainerVisitsReportIsLoading(state),
  isLoaded: getTrainerVisitsReportIsLoaded(state),
  summaryStats: getTrainerVisitsReportSummaryStats(state),
  visits: getTrainerVisitsReportVisitList(state)
});

export default connect(mapStateToProps)(Report);
