import { createGetService } from "./serviceMaker";

export const createToken = createGetService(({ email, password }) => ({
  url: "/token",
  basicAuth: { username: email, password }
}));

export const refreshToken = createGetService(token => ({
  url: "/token/refresh",
  token
}));
