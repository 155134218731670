import React from "react";

import useMaxFairPlayInfo from "./hooks/useMaxFairPlayInfo";

import "./MaxFairPlayInfo.scss";

function MaxFairPlayInfo() {
  const { divisionTitle, maxFairPlayPenaltyMinutes } = useMaxFairPlayInfo();
  return (
    <div className="alert alert-secondary text-secondary fair-play-info" role="alert">
      <span className="color-black">{`*Maximum Fair Play Minutes for ${divisionTitle} is `}</span>
      <span className="color-black weight-bold">{`${maxFairPlayPenaltyMinutes} min`}</span>
    </div>
  );
}

export default MaxFairPlayInfo;
