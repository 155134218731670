import React from "react";
import PropTypes from "prop-types";

import { Button } from "reactstrap";

function ClearFileButton({ file, url, clearFile }) {
  if (!file && (!url || url === "")) {
    return null;
  }

  return (
    <Button
      className="image-picker-field__button ml-3"
      type="button"
      size="sm"
      color="danger"
      outline
      onClick={clearFile}
    >
      Remove image
    </Button>
  );
}

ClearFileButton.propTypes = {
  file: PropTypes.shape({}),
  url: PropTypes.string,
  clearFile: PropTypes.func.isRequired
};

export default ClearFileButton;
