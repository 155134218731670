import { _isString } from 'lodash';

import { COLUMNS } from "./read";

export function validateCSVRecords ( records ) {
    const badRecords = [];

    for (let i = 0; i < records.length; i++) {
        const errors = {};

        const record = records[i];
        const firstName = record[COLUMNS.FIRST_NAME];
        const lastName = record[COLUMNS.LAST_NAME];
        const email = record[COLUMNS.EMAIL_ADDRESS];
        const externalId = record[COLUMNS.EXTERNAL_ID];
;
        if (!firstName || firstName.length <= 0) {
            errors[COLUMNS.FIRST_NAME] = "blank";
        }

        if (!lastName || lastName.length <= 0) {
            errors[COLUMNS.LAST_NAME] = "blank";
        }

        if (email.length > 0 && !email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            errors[COLUMNS.EMAIL_ADDRESS] = "invalid";
        }

        // externalId is optional and can be any string: no need to verify

        if (Object.keys(errors).length) {
            badRecords.push({
                line: i+2,
                record,
                errors
            });
        }
    }

    return badRecords;
}