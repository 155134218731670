import { combineReducers } from "redux";

import list from "./reducers/list";
import form from "./reducers/form";
import current from "./reducers/current";

export default combineReducers({
  list,
  form,
  current
});
