import { connect } from "react-redux";

import { getGameFormTeamTitle, getGameFormTeamGoalieShifts } from "@/redux/gameForm/selectors";

import actions from "@/redux/gameForm/actions";

import GoalieShiftsFieldset from "./GoalieShiftsFieldset";

const mapStateToProps = state => (state, { team }) => ({
  teamTitle: getGameFormTeamTitle(state, team),
  shifts: getGameFormTeamGoalieShifts(state, team)
});

const mapDispatchToProps = dispatch => ({
  changeShift: payload => dispatch(actions.changeGoalieShift(payload)),
  addShift: team => dispatch(actions.addGoalieShift({ team })),
  removeShift: payload => dispatch(actions.removeGoalieShift(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoalieShiftsFieldset);
