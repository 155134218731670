import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Table } from "reactstrap";

import EmptyListMessage from "@/components/EmptyListMessage";

import SeasonIpadKeyListItem from "./components/ListItem";

import "./styles/SeasonIpadKeysList.scss";

class SeasonIpadKeysList extends PureComponent {
  static propTypes = {
    seasonId: PropTypes.string.isRequired,
    ipadKeys: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired }))
  };

  static defaultProps = {
    ipadKeys: []
  };

  render() {
    const { ipadKeys, seasonId } = this.props;

    return (
      <div className="season-ipad-keys-list">
        {ipadKeys.length > 0 ? (
          <Table striped borderless className="season-ipad-keys-list__table">
            <thead>
              <tr>
                <th className="season-ipad-keys-list__table__key">Key</th>
                <th>&nbsp;</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {ipadKeys.map(ipadKey => (
                <SeasonIpadKeyListItem seasonId={seasonId} key={ipadKey.id} {...ipadKey} />
              ))}
            </tbody>
          </Table>
        ) : (
          <EmptyListMessage>
            There are no keys. <br />
            Create new one.
          </EmptyListMessage>
        )}
      </div>
    );
  }
}

export default SeasonIpadKeysList;
