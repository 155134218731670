import { handleActions, combineActions } from "redux-actions";

import { playersLoadingRoutine, coachesLoadingRoutine, addingMemberToTeamRoutine } from "../../routines";

import actions from "../../actions";

const initialState = {};

function reduceMembersListLoadingSuccess(state, { payload: { members } }) {
  return members.reduce(
    (result, { teams }) => teams.reduce((result, { id, title }) => ({ ...result, [id]: { title } }), result),
    state
  );
}

function reduceAddingMemberToTeamSuccess(state, { payload }) {
  const {
    team: { id, title }
  } = payload;

  return {
    ...state,
    [id]: {
      title: title
    }
  };
}

export default handleActions(
  {
    [combineActions(playersLoadingRoutine.SUCCESS, coachesLoadingRoutine.SUCCESS)]: reduceMembersListLoadingSuccess,
    [addingMemberToTeamRoutine.SUCCESS]: reduceAddingMemberToTeamSuccess,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
