import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "./ContentPanel.scss";

const ContentPanel = ({ title, children, to, toExternalLink }) => {
  return (
    <div className="content-panel">
      {title && (
        <h3 className="content-panel__title">
          {to ? toExternalLink ? <a href={to}>{title}</a> : <Link to={to}>{title}</Link> : title}
        </h3>
      )}
      <div className="content-panel__controls">{children}</div>
    </div>
  );
};

ContentPanel.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  toExternalLink: PropTypes.bool
};

ContentPanel.defaultProps = {
  children: null,
  to: null,
  toExternalLink: false
};

export default ContentPanel;
