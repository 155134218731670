import { handleActions, combineActions } from "redux-actions";
import { combineReducers } from "redux";
import moment from "moment";

import { submittingRoutine, loadingRoutine, deletingRoutine } from "./routines";
import actions from "./actions";

import { fields, oneGameData, remoteValidationErrors } from "./reducers";

const isSubmitting = handleActions(
  {
    [submittingRoutine.REQUEST]: () => true,
    [combineActions(submittingRoutine.FULFILL, actions.clear)]: () => false
  },
  false
);

const isLoading = handleActions(
  {
    [loadingRoutine.REQUEST]: () => true,
    [combineActions(loadingRoutine.FULFILL, actions.clear)]: () => false
  },
  false
);

const isLoaded = handleActions(
  {
    [loadingRoutine.SUCCESS]: () => true,
    [actions.clear]: () => false
  },
  false
);

const isDeleting = handleActions(
  {
    [deletingRoutine.REQUEST]: () => true,
    [combineActions(deletingRoutine.FULFILL, actions.clear)]: () => false
  },
  false
);

const dateCreated = handleActions(
  {
    [loadingRoutine.success]: (
      state,
      {
        payload: {
          game: { createdAt }
        }
      }
    ) =>
      moment(createdAt)
        .utc()
        .format("YYYY-MM-DD"),
    [actions.clear]: () => ""
  },
  ""
);

export default combineReducers({
  isSubmitting,
  isLoaded,
  isLoading,
  isDeleting,
  dateCreated,
  remoteValidationErrors,
  fields,
  oneGameData
});
