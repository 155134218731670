import { all, put, call, takeLatest } from "redux-saga/effects";

import { loadLeagues } from "@/lib/api/leagues";
import { fetchList } from "@/redux/api/sagas";

import { loadingRoutine } from "./routines";

function* loadingSaga({ payload: { associationId } }) {
  yield put(loadingRoutine.request());

  try {
    const { leagues, ids, totalCount } = yield call(fetchList, "leagues", loadLeagues, {
      associationId
    });

    yield put(
      loadingRoutine.success({
        ids,
        leagues,
        totalCount
      })
    );
  } catch (error) {
    const responseStatus = error.response ? error.response.status : null;

    yield put(loadingRoutine.failure({ error, responseStatus }));

    throw error;
  } finally {
    yield put(loadingRoutine.fulfill());
  }
}

export function* leaguesListFlow() {
  yield all([takeLatest(loadingRoutine, loadingSaga)]);
}
