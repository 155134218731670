import React from "react";

import { Table } from "reactstrap";

import InfiniteScroll from "@/components/InfiniteScroll";
import TotalCount from "@/components/TotalCount";

import useInvitationListSelector from "./hooks/useInvitationListSelector";
import useInvitationListLoader from "./hooks/useInvitationListLoader";

import Invitation from "./Invitation";

import "./List.scss";

function List() {
  const { invitations, isLoading, isLoaded, isAppending, totalCount, filteredCount } = useInvitationListSelector();

  const { loadMoreInvitations } = useInvitationListLoader();

  return (
    <InfiniteScroll
      dataLength={invitations.length}
      loadList={loadMoreInvitations}
      hasMore={invitations.length < filteredCount}
      isLoaded={isLoaded}
      isLoading={isLoading}
      isAppending={isAppending}
      emptyMessage="No invitations found"
      endMessage="You’ve reached the end of the invitations list."
    >
      <TotalCount plural="invitations" singular="invitation" totalCount={totalCount} filteredCount={filteredCount} />

      <Table striped borderless>
        <thead>
          <tr>
            <th scope="col" className="invitation-list__table__code">
              Code
            </th>
            <th scope="col" className="invitation-list__table__description">
              Description
            </th>
            <th scope="col" className="invitation-list__table__role">
              Role
            </th>
            <th scope="col" className="invitation-list__table__status">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {invitations.map(({ id, ...rest }) => (
            <Invitation key={id} id={id} {...rest} />
          ))}
        </tbody>
      </Table>
    </InfiniteScroll>
  );
}

export default List;
