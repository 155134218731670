import _uniq from "lodash/uniq";

import { formatDate } from "@/utils/date";

import { getCurrentSeasonBreadcrumbs } from "@/redux/seasons/selectors";

import { getSeason as getCurrentSeason } from "@/redux/currentSeason/selectors";

const selectRoot = state => state.games;
const selectList = state => selectRoot(state).list;
const selectViews = state => selectRoot(state).views;

export const getGameViewedStatus = (state, id) => {
  const viewedStatus = selectViews(state)[id] || {
    isViewed: false,
    isChanging: false
  };

  return viewedStatus;
};

const selectListPagination = state => selectList(state).pagination;

export const getGamesList = state => {
  const ids = selectListPagination(state).ids || [];
  const data = selectList(state).data;

  return ids.map(id => {
    const { visitor, home, startTime, number, location, seasonId, flags, countNotes } = data[id];
    return {
      visitor: visitor.title,
      home: home.title,
      divisions: _uniq([visitor.division, home.division]).join(", "),
      score: [visitor.score, home.score].join("-"),
      date: formatDate(startTime, "UTC"),
      viewedStatus: getGameViewedStatus(state, id),
      flags: addFlaggedPenaltyLabels(state, flags),
      number,
      location,
      countNotes,
      seasonId,
      id
    };
  });
};

// similar to function in gamesheet and gameForm selectors, but no game
// object to get season from, so have to use currentSeason
const getPenaltyLabel = (state, bareCode) => {
  const currentSeason = getCurrentSeason(state);
  if ("penaltySettings" in currentSeason && "penaltyCodes" in currentSeason.penaltySettings) {
    let found = currentSeason.penaltySettings.penaltyCodes.find(({ code }) => code === bareCode);
    if (found != undefined) {
      return found.label;
    }
  }
  return bareCode;
};

const addFlaggedPenaltyLabels = (state, flags) => {
  return flags.map(flag => {
    if (flag.event === "penalty") {
      flag.label = getPenaltyLabel(state, flag.code);
    }
    return flag;
  });
};

export const getGamesListIsLoaded = state => selectListPagination(state).isLoaded;

export const getGamesListIsLoading = state => selectListPagination(state).isLoading;

export const getGamesListIsForbidden = state => selectList(state).isForbidden;

export const getGamesListPageSize = state => selectListPagination(state).pageSize;

export const getGamesListCurrentPage = state => selectListPagination(state).currentPage;

export const getGamesListTotalPages = state => selectListPagination(state).totalPages;

export const getGamesListBreadcrumbs = state => {
  const season = getCurrentSeason(state);
  const seasonBreadcrumbs = getCurrentSeasonBreadcrumbs(state);

  if (season && seasonBreadcrumbs) {
    return [
      ...seasonBreadcrumbs,
      {
        url: `/seasons/${season.id}/games`,
        title: "Games"
      }
    ];
  }
};

export const getGamesListTotalCount = state => selectListPagination(state).totalCount;

export const getGamesListFilteredCount = state => selectListPagination(state).filteredCount;
