import { handleActions } from "redux-actions";

import { loginSetCredentialsAction } from "./actions";
import { loginRoutine } from "./routines";

const defaultState = {
  isLoading: false,
  error: null,
  email: null,
  password: null,
  pendingUpdatePassword: false,
};

const loginSetCredentialsActionHandler = (state, { payload }) => Object.assign({}, state, payload);

const loginTriggerActionHandler = state => Object.assign({}, state, { error: null });

const loginRequestActionHandler = state => Object.assign({}, state, { isLoading: true });

const loginSuccessActionHandler = (state, { payload }) => {
  if (payload.thenUpdatePassword) {
    return Object.assign({}, state, { email: null, password: payload.password, pendingUpdatePassword: true });
  } else {
    return Object.assign({}, state, { email: null, password: null });
  }
}

const loginFailureActionHandler = (state, { payload }) => Object.assign({}, state, { error: payload });

const loginFulfillActionHandler = state => Object.assign({}, state, { isLoading: false });

export default handleActions(
  {
    [loginSetCredentialsAction]: loginSetCredentialsActionHandler,
    [loginRoutine.trigger]: loginTriggerActionHandler,
    [loginRoutine.request]: loginRequestActionHandler,
    [loginRoutine.success]: loginSuccessActionHandler,
    [loginRoutine.failure]: loginFailureActionHandler,
    [loginRoutine.fulfill]: loginFulfillActionHandler,
  },
  defaultState
);
