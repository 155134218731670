import _isEmpty from "lodash/isEmpty";
import { handleActions } from "redux-actions";

import { loadingRoutine } from "../routines";
import actions from "../actions";
import { parsePenaltyDurations, parsePenaltyDurationsFromLengths } from "@/utils/standardizePenaltySettings";

const initialState = {
  id: "",
  title: "",
  externalId: "",
  association: { id: "", title: "" },
  league: { id: "", title: "" },
  season: { id: "", title: "" },
  usingSeasonSettings: true,
  settings: {}
};

function reduceLoadingSuccess(state, { payload: { division } }) {
  const { id, title, externalId, association, league, season, settings } = division;
  const usingSeasonSettings = _isEmpty(settings);

  let divisionSettings = {};

  if (!usingSeasonSettings) {
    let { penaltyDurations } = parsePenaltyDurations(settings);
    let penaltyLengths = settings.penaltyLengths || [];
    // fallback to penaltyLengths if we have lengths but not durations
    if (penaltyDurations.length <= 0 && penaltyLengths.length > 0) {
      penaltyDurations = parsePenaltyDurationsFromLengths(settings);
    }

    divisionSettings = {
      generalSettings: {
        periodLengths: settings.periodLengths,
        gameCategories: settings.gameCategories || [],
        signatures_required: settings.signaturesRequired || "head_coach",
        default_player_status: settings.defaultPlayerStatus || "",
        shootout: settings.shootout || false,
        manager_scheduling: settings.managerScheduling || false
      },
      statsSettings: {
        assistValue: settings.assistValue,
        goalValue: settings.goalValue,
        playerStatsDisplay: settings.playerStatsDisplay,
        pointSystem: settings.pointSystem,
        fairPlayPoints:
          "fairPlayPoints" in settings
            ? settings.fairPlayPoints
            : {
              isEnabled: false,
              maxPenaltyMinutes: 16,
              pointValue: 1
            },
        tieBreakers: settings.tieBreakers,
        maxGoalDifferential: settings.maxGoalDifferential
      },
      penaltySettings: {
        penaltyDurations,
        penaltyLengths,
      }
    }
  }

  return {
    id,
    title,
    externalId,
    usingSeasonSettings,
    association: { id: association.id, title: association.title },
    league: { id: league.id, title: league.title },
    season: { id: season.id, title: season.title },
    settings: divisionSettings
  };
}

export default handleActions(
  {
    [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
