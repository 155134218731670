import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { Label, Input } from "reactstrap";

import { useCountryFieldChange } from "./hooks";
import { FAVORITE_COUNTRIES, OTHER_COUNTRIES } from "./constants";

function CountryField({ value, setValue, resetProvince }) {
  const onChange = useCountryFieldChange({ setValue, resetProvince });

  return (
    <Fragment>
      <Label for="playerCountry">Country</Label>

      <Input type="select" id="playerCountry" name="country" value={value} className="form-control" onChange={onChange}>
        <option value="">&nbsp;</option>
        {FAVORITE_COUNTRIES.map(({ code, name }) => (
          <option key={code} value={code}>
            {name}
          </option>
        ))}
        <option value="---separator---" disabled>
          ------------
        </option>
        {OTHER_COUNTRIES.map(({ code, name }) => (
          <option key={code} value={code}>
            {name}
          </option>
        ))}
      </Input>
    </Fragment>
  );
}

CountryField.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  resetProvince: PropTypes.func.isRequired
};

export default CountryField;
