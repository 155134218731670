import React from "react";
import PropTypes from "prop-types";
import { ButtonDropdown, DropdownToggle, DropdownMenu, CustomInput, Button } from "reactstrap";

import useListLoading from "./hooks/useListLoading";
import useDropdown from "./hooks/useDropdown";
import useSelection from "./hooks/useSelection";

import "./MultiSelectField.scss";

function MultiSelectField(props) {
  const {
    size,
    isListLoaded,
    isListLoading,
    disabled,
    value,
    options,
    readyMsg,
    emptyMsg,
    loadingMsg,
    pendingMsg,
    multipleMsg,
    disabledMsg,
    onChange,
    loadList
  } = props;

  useListLoading({ disabled, loadList });

  const { isOpen, toggleOpened, close } = useDropdown();
  const { selected, toggleSelected, apply, clear, isDirty } = useSelection({
    close,
    preselected: value,
    onChange
  });

  const columnsCount = Math.ceil(options.length / 8);

  return (
    <ButtonDropdown className="multiselect" isOpen={isOpen} toggle={toggleOpened} disabled={disabled || isListLoading}>
      <DropdownToggle
        className="multiselect__button"
        color="primary"
        size={size}
        caret
        disabled={disabled || isListLoading}
      >
        {disabled
          ? disabledMsg
          : isListLoaded
            ? options.length > 0
              ? value.length > 0
                ? value.length === 1
                  ? options.find(o => o.value === value[0]).label
                  : multipleMsg
                : readyMsg
              : emptyMsg
            : isListLoading
              ? loadingMsg
              : pendingMsg}
      </DropdownToggle>
      <DropdownMenu className="multiselect__menu">
        <div className="multiselect__menu__items" style={{ gridTemplateColumns: "repeat(" + columnsCount + ", 1fr)" }}>
          {options.map(({ value, label }) => (
            <CustomInput
              className="multiselect__checkbox"
              type="checkbox"
              id={`divisionCheck${value}`}
              value={value}
              checked={selected.includes(value)}
              label={label}
              key={value}
              onChange={toggleSelected}
            />
          ))}
        </div>

        <Button className="multiselect__apply-button" color="primary" size="sm" onClick={apply} disabled={!isDirty}>
          Apply
        </Button>

        <Button
          className="multiselect__clear-button"
          color="secondary"
          size="sm"
          outline
          onClick={clear}
          disabled={selected.length === 0}
        >
          Clear Selection
        </Button>
      </DropdownMenu>
    </ButtonDropdown>
  );
}

MultiSelectField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  size: PropTypes.string,
  isListLoaded: PropTypes.bool,
  isListLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.array,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  readyMsg: PropTypes.string,
  emptyMsg: PropTypes.string,
  loadingMsg: PropTypes.string,
  pendingMsg: PropTypes.string,
  multipleMsg: PropTypes.string,
  disabledMsg: PropTypes.string,
  onChange: PropTypes.func,
  loadList: PropTypes.func
};

MultiSelectField.defaultProps = {
  readyMsg: "Select option",
  emptyMsg: "No options available",
  loadingMsg: "Loading options…",
  pendingMsg: "Pending options…",
  multipleMsg: "Multiple options",
  disabledMsg: null,
  name: "",
  size: "sm",
  isListLoaded: false,
  isListLoading: false,
  disabled: false,
  value: [],
  options: [],
  onChange() {},
  loadList() {}
};

export default MultiSelectField;
