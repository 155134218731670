import React from "react";

import { Row, Col, Button } from "reactstrap";

import Layout from "@/layouts/Layout";
import Head from "@/components/Head";
import Spinner from "@/components/Spinner";
import DashboardForm from "@/components/DashboardForm";
import DashboardFormFooter from "@/components/DashboardFormFooter";
import { TeamReview } from "./TeamReview";
import "../invitation.scss";

export const InvitationReviewGUI = (props) => {
    const {
        codeInfo,
        nextScreenIsLoading,
        onWrongInvitation,
        onInvitationIsCorrect,
        confirmButtonText,
    } = props;

    return (
        <Layout className="page-align-center team-review">
            <Head title="Accept Invitation" />

            <Row className="justify-content-center">
                <Col md="10" lg="6">
                    <DashboardForm>
                        <div className="invitation-container">
                            <p className="header">This Invitation Code is for ...</p>
                            <TeamReview teamInfo={codeInfo.team} />
                        </div>
                        <div className="not-the-right-team">
                            <h4>If this is not the right team ...</h4>
                            <div>Click back and double check that you have the correct Invitation Code. If the code is correct but the team is not, contact your league or tournament coordinator to request a new code.</div>
                        </div>
                        <DashboardFormFooter>
                            <div className="dashboard-form-footer__secondary">
                                <Button type="button" color="secondary" onClick={onWrongInvitation}>
                                    Back
                                </Button>
                            </div>
                            <div className="dashboard-form-footer__primary">
                                {nextScreenIsLoading && (
                                    <div className="next-screen-loading-spinner">
                                        <Spinner theme="loader" color="yellow" size="m" />
                                    </div>
                                )}
                                <Button
                                    type="button"
                                    color="success"
                                    onClick={onInvitationIsCorrect}
                                    disabled={nextScreenIsLoading}
                                >
                                    {confirmButtonText}
                                </Button>
                            </div>
                        </DashboardFormFooter>
                    </DashboardForm>
                </Col>
            </Row >
        </Layout >
    );
}

