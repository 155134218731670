import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Draggable } from "react-beautiful-dnd";

import { ManualTieBreaker } from "./ManualTieBreaker";

const TieBreaker = ({ title, label, description, enabled, index, modalIsOpen, setModalIsOpen }) => {
  return (
    <Draggable draggableId={title} index={index} isDragDisabled={!enabled || modalIsOpen}>
      {(provided, snapshot) => (
        <div
          className={classNames(
            "tie-breakers__dnd__item",
            {
              "tie-breakers__dnd__item--dragging": snapshot.isDragging,
            },
            {
              "tie-breakers__dnd__item--draggable": enabled,
            }
          )}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <h4 className="tie-breakers__dnd__item__title">{label}</h4>
          <p className="tie-breakers__dnd__item__description">{description}</p>
          {title === "manual" && (
            <ManualTieBreaker isFirstCriteria={index === 0} modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
          )}
        </div>
      )}
    </Draggable>
  );
};

TieBreaker.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  index: PropTypes.number,
};

export default TieBreaker;
