import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import "./ConfirmSeasonArchivingModal.scss";

function ConfirmSeasonArchivingModal(props) {
  const { seasonTitle, isArchiving, onConfirm, isModalOpen, toggleModal, setIsArchiving } = props;

  const confirmButtonText = isArchiving ? `Archiving Season…` : `Yes, Archive Season`;

  const submitArchive = () => {
    setIsArchiving(true)
    onConfirm();
  }

  return (
    <Fragment>
      <Modal className="season-archiving-modal" isOpen={isModalOpen} toggle={toggleModal} centered>
        <ModalHeader>Confirm Season Archiving</ModalHeader>
        <ModalBody>
          <p>
            The season <strong>{seasonTitle}</strong> will be archived and it’s content will be hidden from view. The
            season can be unarchived <span className="text-nowrap">at any time</span>.
          </p>
          <p>Are you sure you want to continue?</p>
        </ModalBody>

        <ModalFooter>
          <div className="d-flex w-100">
            <div className="mr-auto">
              <Button type="button" color="secondary" outline onClick={toggleModal} disabled={isArchiving}>
                Cancel
              </Button>
            </div>
            
            <div>
              <Button type="button" color="success" onClick={submitArchive} disabled={isArchiving}>
                {confirmButtonText}
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}

ConfirmSeasonArchivingModal.propTypes = {
  seasonTitle: PropTypes.string.isRequired,
  isArchiving: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func,
  isModalOpen: PropTypes.bool,
};

export default ConfirmSeasonArchivingModal;
