import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { submittingRoutine, getMeta } from "@/redux/leagueForm";

export default function useLeagueFormSubmitting(options) {
  const { associationId, leagueId, values } = options;

  const dispatch = useDispatch();
  const { isSubmitting } = useSelector(getMeta);

  const submit = useCallback(
    () => {
      dispatch(submittingRoutine({ associationId, leagueId, values }));
    },
    [dispatch, associationId, leagueId, values]
  );

  return {
    isSubmitting,
    submit
  };
}
