import _orderBy from "lodash/orderBy";
import { useCallback, useState } from "react";

const COLLAPSED_ROWS_COUNT = 8;

export function usePenaltyCodesList(options) {
  const { onChange: changePenaltyCodes } = options;
  const allPenaltyCodes = options.value;

  const totalCount = allPenaltyCodes.length;
  const isNotBlank = totalCount > 0;
  const isCollapsible = totalCount > COLLAPSED_ROWS_COUNT;

  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapsed = useCallback(() => setIsCollapsed(!isCollapsed), [setIsCollapsed, isCollapsed]);

  const deleteAll = useCallback(() => changePenaltyCodes([]), [changePenaltyCodes]);

  const penaltyCodes = (isCollapsed ? allPenaltyCodes.slice(0, COLLAPSED_ROWS_COUNT) : allPenaltyCodes).map(
    ({ code, label, penaltyClass, duration }, index) => ({
      id: index,
      code,
      label,
      penaltyClass,
      duration,
    })
  );

  return {
    penaltyCodes,
    isNotBlank,
    isCollapsible,
    isCollapsed,
    totalCount,
    toggleCollapsed,
    deleteAll,
  };
}
