import { useCallback } from "react";

import { GAME_FLAGGING_CRITERIONS, SOCCER_GAME_FLAGGING_CRITERIONS } from "@/constants";

import { useDashboardFormNamespaceContext } from "@/components/DashboardForm";

export default function useFlaggingCriteriaFields(options) {
  const { value: criteria, onChange: changeCriteria, sport } = options;

  const namespace = useDashboardFormNamespaceContext();
  const CRITERION_OPTIONS = sport === "soccer" ? SOCCER_GAME_FLAGGING_CRITERIONS : GAME_FLAGGING_CRITERIONS;

  const changeCriteriaEnabled = useCallback(
    ({ target: { name, checked } }) => changeCriteria({ ...criteria, [name]: checked }),
    [changeCriteria, criteria]
  );

  const toggleAllSelected = useCallback(
    ({ target: { checked } }) =>
      changeCriteria(Object.keys(criteria).reduce((result, name) => ({ ...result, [name]: checked }), {})),
    [changeCriteria, criteria]
  );

  const criterions = Object.entries(CRITERION_OPTIONS).map(([name, label]) => {
    const id = `${namespace}__flagging-criteria__${name}`;
    const enabled = criteria[name];

    return {
      id,
      label,
      name,
      enabled,
      input: {
        id,
        name,
        checked: enabled,
        onChange: changeCriteriaEnabled
      }
    };
  });

  const countSelected = criterions.filter(({ enabled }) => enabled).length;
  const isAllSelected = countSelected === criterions.length;

  return {
    criterions,
    countSelected,
    isAllSelected,
    toggleAllSelected
  };
}
