import { connect } from "react-redux";

import actions from "@/redux/reportDataSourcesSelect/actions";

import {
  getReportDataSourcesSelectLeagueListIsLoaded,
  getReportDataSourcesSelectLeagueList,
  getReportDataSourcesSelectLeagueListIsLoading,
  getReportDataSourcesSelectLeagueIsSelected,
  getReportDataSourcesSelectHasAllLeaguesSelected
} from "@/redux/reportDataSourcesSelect/selectors";

import List from "../List";

const mapStateToProps = (state, { singleSelect }) => ({
  label: `Select league${singleSelect ? "" : "(s)"}`,
  emptyListMessage: "No association selected",
  options: getReportDataSourcesSelectLeagueList(state),
  isLoaded: getReportDataSourcesSelectLeagueListIsLoaded(state),
  isLoading: getReportDataSourcesSelectLeagueListIsLoading(state),
  isOptionSelected: getReportDataSourcesSelectLeagueIsSelected(state),
  isAllOptionsSelected: getReportDataSourcesSelectHasAllLeaguesSelected(state)
});

const mapDispatchToProps = (dispatch, { singleSelect }) => ({
  select: id => dispatch(actions.leagueList.select({ id, singleSelect })),
  selectAll: () => dispatch(actions.leagueList.selectAll()),
  deselect: id => dispatch(actions.leagueList.deselect({ id, singleSelect })),
  clear: () => dispatch(actions.leagueList.clear())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List);
