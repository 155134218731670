import React from "react";
import PropTypes from "prop-types";

import SelectInput from "./SelectInput";

import useTeamPickerFieldTeamInput from "./hooks/useTeamPickerFieldTeamInput";

function TeamInput(props) {
  const { id, name, value, onChange, disallowValue, invalid } = props;

  const { isLoaded, isLoading, teams, teamId, divisionId, handleChange } = useTeamPickerFieldTeamInput({
    value,
    onChange
  });

  return (
    <SelectInput
      invalid={invalid}
      id={id}
      name={name}
      onChange={handleChange}
      value={isLoaded ? teamId : ""}
      disallowValue={disallowValue}
      placeholder={isLoading ? "Loading teams…" : "No team selected"}
      allowBlank={isLoaded}
      collection={teams}
      disabled={divisionId === "" || isLoading}
    />
  );
}

TeamInput.propTypes = {
  value: PropTypes.shape({
    team: PropTypes.string.isRequired,
    division: PropTypes.string.isRequired
  }).isRequired,
  disallowValue: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  invalid: PropTypes.bool,
  onChange: PropTypes.func
};

TeamInput.defaultProps = {
  disallowValue: "",
  disabled: false,
  invalid: false,
  onChange() {}
};

export default TeamInput;
