import { createActions } from "redux-actions";

const { accountVerificationForm: actions } = createActions({
  ACCOUNT_VERIFICATION_FORM: {
    CHANGE_FIELD: undefined,
    CLEAR: undefined
  }
});

export default actions;
