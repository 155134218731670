import React from "react";

import { Input } from "reactstrap";

import useStatusSelect from "./hooks/useStatusSelect";

function StatusSelect() {
  const { value, setStatus } = useStatusSelect();

  return (
    <Input type="select" value={value} onChange={setStatus}>
      <option value="">All users</option>
      <option value="active">Active users</option>
      <option value="pending">Pending users</option>
    </Input>
  );
}

export default StatusSelect;
