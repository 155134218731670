import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { actions, getIsLocked } from "@/redux/teamRoster";
import { getIgnoreTeamLock } from "@/redux/ability/selectors";

export default function useMemberEditTrigger({ member, autoFocus }) {
  const dispatch = useDispatch();
  const isLocked = useSelector(getIsLocked);
  const ignoreLock = useSelector(getIgnoreTeamLock);

  const triggerEdit = useCallback(
    () =>
      (!isLocked || ignoreLock) &&
      dispatch(
        actions[`${member.type}Form`].edit({
          memberId: member.id,
          autoFocus
        })
      ),
    [dispatch, member, autoFocus, isLocked, ignoreLock]
  );

  return {
    triggerEdit,
    isLocked
  };
}
