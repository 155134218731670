import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Table } from "reactstrap";

class SummaryStats extends PureComponent {
  static propTypes = {
    gamesPlayed: PropTypes.number,
    penaltiesAssessed: PropTypes.number,
    avgPenaltiesPerGamesPlayed: PropTypes.number,
    mostFreqAssessedPenaltyCode: PropTypes.string,
    mostFreqAssessedPenaltyCount: PropTypes.number,
    majorPenaltiesAssessed: PropTypes.number,
    mostFreqAssessedMajorPenaltyCode: PropTypes.string,
    mostFreqAssessedMajorPenaltyCount: PropTypes.number
  };

  static defaultProps = {
    gamesPlayed: 0,
    penaltiesAssessed: 0,
    avgPenaltiesPerGamesPlayed: 0,
    mostFreqAssessedPenaltyCode: "N/A",
    mostFreqAssessedPenaltyCount: 0,
    majorPenaltiesAssessed: 0,
    mostFreqAssessedMajorPenaltyCode: "N/A",
    mostFreqAssessedMajorPenaltyCount: 0
  };

  render() {
    const {
      gamesPlayed,
      penaltiesAssessed,
      avgPenaltiesPerGamesPlayed,
      mostFreqAssessedPenaltyCode,
      mostFreqAssessedPenaltyCount,
      majorPenaltiesAssessed,
      mostFreqAssessedMajorPenaltyCode,
      mostFreqAssessedMajorPenaltyCount
    } = this.props;

    return (
      <div className="penalty-type-report__section">
        <h3 className="penalty-type-report__section-title">Summary Statistics</h3>

        <Table className="penalty-type-report__table" striped borderless>
          <thead>
            <tr>
              <th>Games Played</th>
              <th>Penalties Assessed</th>
              <th>Average P/GP</th>
              <th>Most Frequently Assessed Penalty</th>
              <th>Major / Misconduct Penalties Assessed</th>
              <th>Most Frequently Assessed Major Penalty</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{gamesPlayed}</td>
              <td>{penaltiesAssessed}</td>
              <td>{avgPenaltiesPerGamesPlayed}</td>
              <td>
                {mostFreqAssessedPenaltyCount > 0 ? (
                  <span>
                    {mostFreqAssessedPenaltyCode}
                    &nbsp;[
                    {mostFreqAssessedPenaltyCount}]
                  </span>
                ) : (
                  "N/A"
                )}
              </td>
              <td>{majorPenaltiesAssessed}</td>
              <td>
                {mostFreqAssessedMajorPenaltyCount > 0 ? (
                  <span>
                    {mostFreqAssessedMajorPenaltyCode}
                    &nbsp;[
                    {mostFreqAssessedMajorPenaltyCount}]
                  </span>
                ) : (
                  "N/A"
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}

export default SummaryStats;
