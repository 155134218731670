import { handleActions } from "redux-actions";

import { ipadKeysManagerAddingSeasonToKeyRoutine } from "@/redux/seasonIpadKeysManager/routines";

import { ipadKeysListLoadingRoutine } from "../routines";

const initialState = {
  isLoaded: false,
  isLoading: false,
  isForbidden: false,
  ids: [],
  data: {}
};

function reduceLoadingRequest(state) {
  return { ...state, isLoading: true };
}

function reduceLoadingSuccess(state, { payload: { apiKeys } }) {
  const ids = apiKeys.map(({ id }) => id);

  const data = apiKeys.reduce(
    (result, { id, value, description }) => ({
      ...result,
      [id]: {
        value,
        description
      }
    }),
    {}
  );

  return { ...state, isLoaded: true, ids, data };
}

function reduceLoadingFailure(state, { payload }) {
  return {
    ...state,
    isForbidden: payload.responseStatus === 401,
    isLoaded: false,
    ids: [],
    data: {}
  };
}

function reduceLoadingFulfill(state) {
  return { ...state, isLoading: false };
}

function reduceKeyAdded(state, { payload }) {
  const {
    keyId,
    apiKey: { value, description }
  } = payload;

  const ids = [...state.ids, keyId];

  const data = {
    ...state.data,
    [keyId]: { value, description }
  };

  return {
    ...state,
    ids,
    data
  };
}

export default handleActions(
  {
    [ipadKeysListLoadingRoutine.REQUEST]: reduceLoadingRequest,
    [ipadKeysListLoadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [ipadKeysListLoadingRoutine.FAILURE]: reduceLoadingFailure,
    [ipadKeysListLoadingRoutine.FULFILL]: reduceLoadingFulfill,
    [ipadKeysManagerAddingSeasonToKeyRoutine.SUCCESS]: reduceKeyAdded
  },
  initialState
);
