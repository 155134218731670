import { all, put, select, takeLatest, actionChannel, take, race, call } from "redux-saga/effects";
import { push } from "connected-react-router";
import { buffers } from "redux-saga";
import axios from "axios";

import { config } from "../../config";

import { createUser, loadUser, updateUser, deleteUser, resendUserVerificationCode } from "@/lib/api/users";

import { loadAssociation } from "@/lib/api/associations";
import { loadLeague } from "@/lib/api/leagues";
import { loadSeason } from "@/lib/api/seasons";
import { loadDivision } from "@/lib/api/divisions";
import { loadTeam } from "@/lib/api/teams";
import { gamesheetAPIRequest, fetchOne } from "@/redux/api/sagas";
import { ensureTokenIsFreshSaga } from "@/redux/token/sagas";
import userVerificationNotificationActions from "@/redux/userVerificationNotification/actions";
import takeConcurrently from "../common/takeConcurrently";

import {
  fieldValidatingRoutine,
  validatingRoutine,
  submittingRoutine,
  loadingRoutine,
  deletingRoutine,
  roleLevelLoadingRoutine,
  verificationEmailResendingRoutine
} from "./routines";

import { validate, validateFirstName, validateLastName, validateEmail } from "./validations";

import actions from "./actions";

import { getAttributes, getUserEmail } from "./selectors";

const SERVICES_BY_RESOURCE_TYPE = {
  associations: loadAssociation,
  leagues: loadLeague,
  seasons: loadSeason,
  divisions: loadDivision,
  teams: loadTeam
};

function* fieldValidatingSaga({ payload: { name, value } }) {
  yield put(fieldValidatingRoutine.request({ name, value }));

  let errors;

  switch (name) {
    case "firstName":
      errors = validateFirstName(value);
      break;

    case "lastName":
      errors = validateLastName(value);
      break;

    case "email":
      errors = validateEmail(value);
      break;

    default:
      throw new Error(`Unknown form field ${name}`);
  }

  if (errors.length === 0) {
    yield put(fieldValidatingRoutine.success({ name, value }));
  } else {
    yield put(fieldValidatingRoutine.failure({ name, value, errors }));
  }

  yield put(fieldValidatingRoutine.fulfill({ name, value }));
}

function* validatingSaga() {
  const attributes = yield select(getAttributes);

  yield put(validatingRoutine.request());

  const errors = validate(attributes);

  if (errors) {
    yield put(validatingRoutine.failure({ errors }));
  } else {
    yield put(validatingRoutine.success());
  }

  yield put(validatingRoutine.fulfill());
}

function* userCreatingSaga({ attributes }) {
  yield call(gamesheetAPIRequest, createUser, { attributes });

  yield put(
    userVerificationNotificationActions.show({
      userEmail: attributes.email,
      title: "User successfully created"
    })
  );
}

function* userUpdatingSaga({ id, attributes }) {

  const oldEmail = yield select(getUserEmail);
  const { email: newEmail } = yield select(getAttributes);
  const accessToken = yield call(ensureTokenIsFreshSaga);

  try {
    yield call(
      () => axios.post(
        `${config.AUTH_GATEWAY}/auth/v4/some-user`,
        {
          oldUser: {
            email: oldEmail,
          },
          newUser: {
            email: newEmail,
            firstName: attributes.firstName,
            lastName: attributes.lastName,
            claims: attributes.roles,
          },
        },
        {
          headers: {
            "Authorization": `Bearer ${accessToken}`,
          },
        }
      )
    );
  } catch ({ response }) {
    if (response.status === 500 &&
      'errors' in response.data &&
      response.data.errors.length === 1 &&
      'message' in response.data.errors[0] &&
      response.data.errors[0].message.startsWith("cannot find user from email:")) {
      console.log("Updated user not in Firebase");
    } else {
      throw response;
    }
  }
}

function* submittingSaga({ payload: id }) {
  yield put(submittingRoutine.request());

  try {
    const validationSuccess = yield actionChannel(validatingRoutine.SUCCESS, buffers.dropping(1));

    const validationFulfill = yield actionChannel(validatingRoutine.FULFILL, buffers.dropping(1));

    yield put(validatingRoutine());

    const { success } = yield race({
      success: take(validationSuccess),
      fulfill: take(validationFulfill)
    });

    if (!success) {
      yield put(submittingRoutine.failure());

      return;
    }

    const attributes = yield select(getAttributes);

    if (attributes.roles.length === 0) {
      yield put(submittingRoutine.failure());

      return;
    }

    yield call(id ? userUpdatingSaga : userCreatingSaga, {
      id,
      attributes
    });

    yield put(submittingRoutine.success());
    yield put(push("/users"));
  } catch (error) {
    if (error.response) {
      yield put(submittingRoutine.failure({ response: error.response }));
    } else {
      yield put(submittingRoutine.failure({ error }));
    }
  } finally {
    yield put(submittingRoutine.fulfill());
  }
}

function* loadingSaga({ payload: userId }) {
  yield put(loadingRoutine.request());

  try {
    const [user] = yield fetchOne({ type: "users", id: userId }, loadUser);

    yield put(loadingRoutine.success({ user }));

    yield loadRoleLevels(user.roles || []);
  } catch (error) {
    yield put(loadingRoutine.failure({ error }));
  } finally {
    yield put(loadingRoutine.fulfill());
  }
}

function* deletingSaga({ payload: userId }) {
  yield put(deletingRoutine.request());

  try {
    const userEmail = yield select(getUserEmail);
    const accessToken = yield call(ensureTokenIsFreshSaga);

    try {
      yield call(
        () => axios.delete(
          `${config.AUTH_GATEWAY}/auth/v4/some-user`,
          {
            data: {
              email: userEmail,
            },
            headers: {
              "Authorization": `Bearer ${accessToken}`,
            },
          }
        )
      );
    } catch ({ response }) {
      if (response.status === 500 &&
        'errors' in response.data &&
        response.data.errors.length === 1 &&
        'message' in response.data.errors[0] &&
        response.data.errors[0].message.startsWith("cannot find user from email:")) {
        console.log("Deleted user not in Firebase");
      } else {
        throw response;
      }
    }

    yield put(deletingRoutine.success());
    yield put(push(`/users`));
  } catch (e) {
    yield put(deletingRoutine.failure());
  } finally {
    yield put(deletingRoutine.fulfill());
  }
}

function* loadRoleLevels(roles) {
  yield all(roles.map(({ level }) => put(roleLevelLoadingRoutine(level))));
}

function* roleLevelLoadingSaga({ payload: level }) {
  const { type, id } = level;

  yield put(roleLevelLoadingRoutine.request(level));

  if (id === "*" && type === "") {
    // global, nothing to load
    yield put(roleLevelLoadingRoutine.success(level));
    yield put(roleLevelLoadingRoutine.fulfill(level));
    return;
  }

  try {
    const service = SERVICES_BY_RESOURCE_TYPE[type];

    if (!service) {
      throw new Error(`Unable to load role level with unknown type ${type}`);
    }

    const include = (() => {
      let out = [];

      switch (type) {
        case "teams": out.push("divisions");
        case "divisions": out.push("seasons");
        case "seasons": out.push("leagues");
        case "leagues": out.push("associations");
      }

      return out.join(",");
    })();

    const [resource] = yield fetchOne({ type, id }, service, { skipAbilitiesUpdate: true, include });

    let errorMessages = [];
    switch (type) {
      case 'teams':
        if (!('title' in resource.division)) {
          errorMessages.push("Missing division");
        }
      case 'divisions':
        if (!('title' in resource.season)) {
          errorMessages.push("Missing season");
        }
      case 'seasons':
        if (!('title' in resource.league)) {
          errorMessages.push("Missing league");
        }
      case 'leagues':
        if (!('title' in resource.association)) {
          errorMessages.push("Missing association");
        }
      case 'associations': {
        // ?
      }
    }

    if (errorMessages.length > 0) {
      yield put(roleLevelLoadingRoutine.failure({ ...level, failureCode: "archived" }));
      return;
    }

    yield put(roleLevelLoadingRoutine.success({ ...level, title: resource.title }));
  } catch (error) {
    if (error.response) {
      const { status } = error.response;

      yield put(roleLevelLoadingRoutine.failure({ ...level, status, error }));
    } else {
      yield put(roleLevelLoadingRoutine.failure({ ...level, error }));
    }
  } finally {
    yield put(roleLevelLoadingRoutine.fulfill(level));
  }
}

function* verificationEmailResendingSaga({ payload: { userId, userEmail } }) {
  yield put(verificationEmailResendingRoutine.request());

  try {
    yield call(gamesheetAPIRequest, resendUserVerificationCode, { userId });

    yield put(verificationEmailResendingRoutine.success());

    yield put(
      userVerificationNotificationActions.show({
        userEmail,
        title: "Email successfully sent"
      })
    );
  } catch (error) {
    yield put(verificationEmailResendingRoutine.failure({ error }));
  } finally {
    yield put(verificationEmailResendingRoutine.fulfill());
  }
}

export function* userFormFlow() {
  yield all([
    takeLatest(actions.changeField, fieldValidatingSaga),
    takeLatest(validatingRoutine.TRIGGER, validatingSaga),
    takeLatest(submittingRoutine.TRIGGER, submittingSaga),
    takeLatest(loadingRoutine.TRIGGER, loadingSaga),
    takeLatest(deletingRoutine.TRIGGER, deletingSaga),
    takeConcurrently(roleLevelLoadingRoutine, roleLevelLoadingSaga, 10),
    takeLatest(verificationEmailResendingRoutine, verificationEmailResendingSaga)
  ]);
}
