import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Row, FormGroup } from "reactstrap";

import DashboardForm from "@/components/DashboardForm";

import AssociationSelect from "./AssociationSelect";
import LeagueSelect from "./LeagueSelect";
import SeasonSelect from "./SeasonSelect";
import DivisionSelect from "./DivisionSelect";
import TeamSelect from "./TeamSelect";
import RoleSelect from "./RoleSelect";
import StatusSelect from "./StatusSelect";
import QueryInput from "./QueryInput";
import ResetButton from "./ResetButton";

import "./Filter.scss";

function Filter({ className }) {
  return (
    <DashboardForm className={classNames("invitation__filter", className)}>
      <Row form>
        <FormGroup className="col-md-2">
          <AssociationSelect />
        </FormGroup>

        <FormGroup className="col-md-2">
          <LeagueSelect />
        </FormGroup>

        <FormGroup className="col-md-2">
          <SeasonSelect />
        </FormGroup>

        <FormGroup className="col-md-2">
          <DivisionSelect />
        </FormGroup>

        <FormGroup className="col-md-4">
          <TeamSelect />
        </FormGroup>
      </Row>

      <Row form>
        <FormGroup className="col-md-3">
          <RoleSelect />
        </FormGroup>

        <FormGroup className="col-md-3">
          <StatusSelect />
        </FormGroup>

        <FormGroup className="col-md-6">
          <QueryInput />
        </FormGroup>
      </Row>

      <ResetButton />
    </DashboardForm>
  );
}

Filter.propTypes = {
  className: PropTypes.string
};

export default Filter;
