import { all, takeLatest } from "redux-saga/effects";
import smartlookClient from "smartlook-client";

import { config } from "@/config";

import { loadAccountRoutine } from "@/redux/account/routines";
import { tokenResetRoutine } from "@/redux/token/routines";

function identifyUserSaga({ payload }) {
  const [
    [
      id,
      {
        attributes: { email, firstName, lastName }
      }
    ]
  ] = Object.entries(payload.users);

  const fullName = `${firstName} ${lastName}`;

  if (!window.smartlook) {
    smartlookClient.init(config.SMARTLOOK_KEY);
  }

  smartlookClient.identify(id, { email, name: fullName });
  smartlookClient.record({ forms: true, emails: true, ips: true, numbers: true });
}

function anonymizeUserSaga() {
  smartlookClient.anonymize();
}

export function* smartlookFlow() {
  if (config.NODE_ENV === "production") {
    yield all([
      takeLatest(loadAccountRoutine.SUCCESS, identifyUserSaga),
      takeLatest(tokenResetRoutine.SUCCESS, anonymizeUserSaga)
    ]);
  }
}
