import _orderBy from "lodash/orderBy";

const selectRoot = state => state.penaltyAccumulationReport;
const selectReport = state => selectRoot(state).report;

export const getPenaltyAccumulationReportIsLoaded = state => selectReport(state).isLoaded;

export const getPenaltyAccumulationReportIsLoading = state => selectReport(state).isLoading;

export const getPenaltyAccumulationReportIsForbidden = state => selectReport(state).isForbidden;

export const getPenaltyAccumulationReportData = state => selectReport(state).data;

export const getPenaltyAccumulationReportSeason = state => selectReport(state).season;
