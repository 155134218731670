import validate from "validate.js";

validate.validators.gameFlaggingSettings = (value, options) => {
  const { criteria, penalties } = value;

  if (options && criteria.penalty && penalties.length === 0) {
    return "^Flagged penalties can’t be blank";
  }
};

validate.validators.validateDivisionPresence = (value, options) => {
  const { division } = value;
  const { message, isEnabled } = validate.isHash(options)
    ? { ...options, isEnabled: true }
    : { message: "can’t be blank", isEnabled: !!options };

  if (isEnabled && validate.isEmpty(division)) {
    return message;
  }
};
