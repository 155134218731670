import { combineReducers } from "redux";

import { seasonPlayersLoadingRoutine } from "./routines";
import actions from "./actions";

import createOffsetPaginationReducer from "@/redux/common/pagination/createOffsetPaginationReducer";

import data from "./reducers/data";
import meta from "./reducers/meta";
import fields from "./reducers/fields";
import penalties from "./reducers/penalties";
import goals from "./reducers/goals";
import goalieShifts from "./reducers/goalieShifts";
import shots from "./reducers/shots";
import penaltyCodes from "./reducers/penaltyCodes";
import shootouts from "./reducers/shootouts";
import seasonPlayers from "./reducers/seasonPlayers";
import formErrors from "./reducers/formErrors";

const seasonPlayerPagination = createOffsetPaginationReducer([seasonPlayersLoadingRoutine], actions.clear);

export default combineReducers({
  data,
  meta,
  fields,
  penalties,
  goals,
  goalieShifts,
  shots,
  penaltyCodes,
  shootouts,
  seasonPlayers,
  seasonPlayerPagination,
  formErrors
});
