import { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";

import { listLoadingRoutine } from "@/redux/invitationList/routines";

export default function useInvitationListLoader() {
  const dispatch = useDispatch();

  const loadMoreInvitations = useCallback(() => dispatch(listLoadingRoutine({ append: true })), [dispatch]);

  const loadInvitations = useCallback(() => dispatch(listLoadingRoutine()), [dispatch]);

  useEffect(
    () => {
      loadInvitations();
    },
    [loadInvitations]
  );

  return { loadMoreInvitations };
}
