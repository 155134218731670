import { combineReducers } from "redux";

import createOffsetPaginationReducer from "@/redux/common/pagination/createOffsetPaginationReducer";

import { loadSeasonPlayersRosterRoutine } from "../../routines";
import { seasonRosterActions } from "../../actions";

import data from "./data";
import search from "./search";
import mergeTool from "./mergeTool";

const pagination = createOffsetPaginationReducer(loadSeasonPlayersRosterRoutine, seasonRosterActions.clear);

export default combineReducers({
  data,
  search,
  pagination,
  mergeTool
});
