import { handleActions } from "redux-actions";

import { billingReportLoadingRoutine } from "../routines";
import actions from "../actions";

const initialState = {
  isLoaded: false,
  isLoading: false,
  isForbidden: false,
  teamGamesStats: [],
  seasons: {},
  leagues: {},
  associations: {},
  divisions: {}
};

function reduceTeams({ teamBillingReports }) {
  return teamBillingReports.map(
    ({
      id,
      title,
      regularSeasonGp,
      playoffGp,
      exhibitionGp,
      tournamentGp,
      season: { id: seasonId },
      division: { id: divisionId }
    }) => ({
      id,
      teamTitle: title,
      regularSeasonGamesCount: regularSeasonGp,
      playoffGamesCount: playoffGp,
      exhibitionGamesCount: exhibitionGp,
      tournamentGamesCount: tournamentGp,
      totalGamesCount: regularSeasonGp + playoffGp + exhibitionGp + tournamentGp,
      seasonId,
      divisionId
    })
  );
}

function reduceSeasons({ teamBillingReports }) {
  return teamBillingReports.reduce(
    (result, { season: { id, title, league, association } }) => ({
      ...result,
      [id]: {
        title,
        leagueId: league.id,
        associationId: association.id
      }
    }),
    {}
  );
}

function reduceDivisions({ teamBillingReports }) {
  return teamBillingReports.reduce(
    (result, { division: { id, title } }) => ({
      ...result,
      [id]: {
        title
      }
    }),
    {}
  );
}

function reduceLeagues({ teamBillingReports }) {
  return teamBillingReports.reduce(
    (result, { league: { id, title } }) => ({
      ...result,
      [id]: {
        title
      }
    }),
    {}
  );
}

function reduceAssociations({ teamBillingReports }) {
  return teamBillingReports.reduce(
    (result, { association: { id, title } }) => ({
      ...result,
      [id]: {
        title
      }
    }),
    {}
  );
}

function reduceLoadingRequest(state) {
  return { ...state, isLoading: true };
}

function reduceLoadingSuccess(state, { payload }) {
  const seasons = reduceSeasons(payload);
  const divisions = reduceDivisions(payload);
  const leagues = reduceLeagues(payload);
  const associations = reduceAssociations(payload);
  const teamGamesStats = reduceTeams(payload);

  return {
    ...state,
    seasons,
    divisions,
    leagues,
    associations,
    teamGamesStats,
    isLoaded: true
  };
}

function reduceLoadingFailure(state, { payload: { responseStatus } }) {
  return { ...state, isLoaded: false, isForbidden: responseStatus === 401 };
}

function reduceLoadingFulfill(state) {
  return { ...state, isLoading: false };
}

function reduceClearing(state) {
  return { ...initialState };
}

export default handleActions(
  {
    [billingReportLoadingRoutine.REQUEST]: reduceLoadingRequest,
    [billingReportLoadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [billingReportLoadingRoutine.FAILURE]: reduceLoadingFailure,
    [billingReportLoadingRoutine.FULFILL]: reduceLoadingFulfill,
    [actions.report.clear]: reduceClearing
  },
  initialState
);
