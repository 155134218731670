import { all, takeLatest, put, call, select } from "redux-saga/effects";
import { push } from "connected-react-router";
import { firebase } from "@/firebase";
import axios from "axios";
import { config } from "../../config";

import { createAccount } from "@/lib/api/account";
import { setUsersFirebaseUid } from "@/lib/api/users";

import { loginAction } from "@/redux/login/actions";

import { registrationValidationRoutine, registrationRoutine } from "./routines";
import { setInvitationCodeInfoAction, showRegistrationScreenAction } from "./actions";
import { screens as registrationScreens } from "./reducer";
import { getRegistrationAttributes } from "./selectors";

export function* registrationValidationSaga() {
  yield put(registrationValidationRoutine.request());

  let errors = [];

  const { code, email, firstName, lastName, password, passwordConfirmation } = yield select(getRegistrationAttributes);

  if (code.length === 0) {
    errors.push({ code: "is required" });
  }

  if (email.length === 0) {
    errors.push({ email: "is required" });
  }

  if (firstName.length === 0) {
    errors.push({ firstName: "is required" });
  }

  if (lastName.length === 0) {
    errors.push({ lastName: "is required" });
  }

  if (password.length === 0) {
    errors.push({ password: "is required" });
  }

  if (password.length < 8) {
    errors.push({ password: "should be at least 8 characters long" });
  }

  if (password !== passwordConfirmation) {
    errors.push({ passwordConfirmation: "should match password" });
  }

  let codeInfo = null;
  try {
    const response = yield call(
      () => axios.get(
        `${config.AUTH_GATEWAY}/auth/v4/invitation-code/${code}`,
        {},
      )
    );

    codeInfo = response.data;
    yield put(setInvitationCodeInfoAction({ codeInfo }));
  } catch (error) {
    errors.push({ code: "invalid code" });
  }

  if (errors.length === 0) {
    yield put(registrationValidationRoutine.success());

    if (codeInfo.teams.length === 1 && codeInfo.invitation.roles.length == 1) {
      // is a team invitation, so we need to figure out what to do with it
      yield put(showRegistrationScreenAction(registrationScreens.VERIFY_TEAM_INVITATION));
    } else {
      // accept invitation code as normal, no more verification steps required
      yield put(registrationRoutine());
    }
  } else {
    yield put(registrationValidationRoutine.failure({ errors }));
  }

  yield put(registrationValidationRoutine.fulfill());
}

export function* registrationSaga() {
  yield put(registrationRoutine.request());

  try {
    const attributes = yield select(getRegistrationAttributes);

    yield call(createAccount, { attributes });

    const { email, password } = attributes;

    const userCredential = yield call(() => firebase.auth().createUserWithEmailAndPassword(email, password));
    yield call(() => setUsersFirebaseUid(email, userCredential.user.uid));

    yield put(registrationRoutine.success());

    yield put(loginAction({ email, password }));
    yield put(push("/"));
  } catch (error) {
    const { response } = error;

    if (!response && !error.isAxiosError) {
      yield put(registrationRoutine.failure({ error: "Unexpected Error" }));

      throw error;
    } else if (!response) {
      yield put(
        registrationRoutine.failure({
          error: "Network Error. Please try again."
        })
      );
    } else {
      switch (response.status) {
        case 400:
          yield put(registrationRoutine.failure({ errors: response.data.errors }));
          break;
        default:
          yield put(registrationRoutine.failure({ error: response.statusText }));
      }
    }
  } finally {
    yield put(registrationRoutine.fulfill());
  }
}

export function* registrationFlow() {
  yield all([
    takeLatest(registrationValidationRoutine.TRIGGER, registrationValidationSaga),
    takeLatest(registrationRoutine.TRIGGER, registrationSaga)
  ]);
}
