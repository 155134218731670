import * as moment from "moment";

export class PlayerPenaltyReportData {

    constructor(data, externalId) {
        this._firstName = "";
        this._lastName = "";
        this._id = 0;
        this._careerStats = {
            "pim/gp": "00:00",
            top: {
                code: null,
                total: 0
            },
            mmp: 0,
            sat: 0
        };
        this._statsBySeason = [];
        this._majorPenalties = [];
        this._infractionHistory = [];
        this._codeLookup = {};
    }

    set player(data){
        this._firstName = data.first_name;
        this._lastName = data.last_name;
        this._id = data.id;
    }

    get id() {
        return this._id;
    }

    get firstName() {
        return this._firstName;
    }

    get lastName() {
        return this._lastName;
    }

    get careerStats() {
        
        const top = this._careerStats.top || { code:"", total:0 };

        return {
            avgPmGp: this.pim(this._careerStats["pim/gp"]),
            mostFreqInfractionCode: top.code,
            mostFreqInfractionCount: top.total,
            majorPenaltiesCount: this._careerStats.mmp,
            gamesSuspensionsCount: this._careerStats.sat
        }

    }

    set careerStats(value) {
        if (!value) return;
        this._careerStats = value;
    }

    get statsBySeason() {
        
        if (!Array.isArray(this._statsBySeason)) return [];
        
        return this._statsBySeason.map(stat => {
            return {
                season: {
                    id: 0,
                    title: stat.title
                },
                gamesPlayed: stat.gp,
                penalties: stat.penalties,
                penaltyMinutes: this.pim(stat.pim),
                avgPmGp: this.pim(stat["pim/gp"])
            }
        });
    }

    set statsBySeason(value) {
        if (!value) return;

        this._statsBySeason = value;

        this._codeLookup = value.reduce((acc, season) => {
            if (!season.penalty_codes) return;
            acc[season.id] = season.penalty_codes.reduce((acc, penalty) => {
                acc[penalty.code] = penalty;
                return acc;
            }, {});
            return acc;
        }, {});
    }

    get majorPenalties() {
        
        if(!Array.isArray(this._majorPenalties)) return [];
        
        return this._majorPenalties.map((incident, i) => {
            
            if(incident.type == 'penalty'){
                const label = this._codeLookup[incident.game.season_id] && this._codeLookup[incident.game.season_id][incident.penalty.code] && this._codeLookup[incident.game.season_id][incident.penalty.code].label || "";

                return {
                    id: i,
                    game: {
                        id: incident.game.id,
                        seasonId: incident.game.season_id,
                        date: moment(incident.game.start_time).format("MMM DD, YYYY"),
                        title: `${incident.home.details.title} vs ${incident.visitor.details.title}`
                    },
                    code: incident.penalty.code,
                    label,
                    count: incident.count
                }
            }

            else {
                return {
                    id: i,
                    game: {
                        id: incident.game.id,
                        seasonId: incident.game.season_id,
                        date: moment(incident.game.start_time).format("MMM DD, YYYY"),
                        title: `${incident.home.details.title} vs ${incident.visitor.details.title}`
                    },
                    code: 'Suspension Served',
                    count: `${incident.number} of ${incident["length"]}`
                }
            }
            
        });
    }

    set majorPenalties(value) {
        if (!value) return;
        this._majorPenalties = value;
    }

    get infractionHistory() {
        
        if (!Array.isArray(this._infractionHistory)) return [];
        
        return this._infractionHistory.map((incident, i) => {
            return {
                id: i,
                game: {
                    id: incident.game.id,
                    seasonId: incident.game.season_id,
                    date: moment(incident.game.start_time).format("MMM DD, YYYY"),
                    start_time: moment(incident.game.start_time).unix(),
                    title: `${incident.home.details.title} vs ${incident.visitor.details.title}`
                },
                team: incident.team.details.title,
                length: this.pim(incident.penalty.penalty_minutes),
                code: incident.penalty.code
            }
        });
    }

    set infractionHistory(value) {
        if (!value) return;
        this._infractionHistory = value;
    }

    pim(pim){
        return (pim||"").split(":").filter((v, i) => i > 0).join(":").split(".")[0];
    }

}