import { handleActions } from "redux-actions";

import { loadGamesheetRoutine } from "../../routines";
import { clearGamesheet } from "../../actions";
import reducePlayers from "../../utils/reducePlayers";

const initialState = [];

export default handleActions(
  {
    [loadGamesheetRoutine.SUCCESS]: reducePlayers,
    [clearGamesheet]: () => initialState
  },
  initialState
);
