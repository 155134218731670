import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import ReportDataSourcesSelectListItem from "../../List/Item";

class SeasonGroup extends PureComponent {
  static propTypes = {
    leagueTitle: PropTypes.string.isRequired,
    seasons: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })),
    singleSelect: PropTypes.bool,
    select: PropTypes.func,
    deselect: PropTypes.func
  };

  static defaultProps = {
    seasons: [],
    singleSelect: false,
    select() {},
    deselect() {}
  };

  render() {
    const { leagueTitle, seasons, select, deselect, singleSelect } = this.props;

    const seasonsList = seasons.map(({ id, type, title, ...rest }) => (
      <ReportDataSourcesSelectListItem
        key={id}
        id={id}
        className={type === "archivedSeasons" ? "report-data-sources-select-list__input__item--narrow" : null}
        title={type === "archivedSeasons" ? `${title} (archived)` : title}
        {...rest}
        select={select}
        deselect={deselect}
      />
    ));

    if (singleSelect) {
      return seasonsList;
    }

    return (
      <div className="report-data-sources-select-list__group">
        <p className="report-data-sources-select-list__group__title list-group-item list-group-item-light">
          {leagueTitle}
        </p>

        {seasonsList}
      </div>
    );
  }
}

export default SeasonGroup;
