import { all, takeLatest, select, put } from "redux-saga/effects";

import { copyTeamsToDivision } from "@/lib/api/divisions";

import { gamesheetAPIRequest } from "@/redux/api/sagas";
import { TeamListLoadingRoutine } from "@/redux/teams/routines";

import { copyTeamsRoutine } from "./routines";

import { getCopyTeamsDivisionId, getCopyTeamsSeasonId, getCopyTeamsSelectedIds } from "./selectors";

function* copyTeamsSaga() {
  yield put(copyTeamsRoutine.request());

  try {
    const copyTeamIds = yield select(getCopyTeamsSelectedIds);

    const divisionId = yield select(getCopyTeamsDivisionId);
    const seasonId = yield select(getCopyTeamsSeasonId);

    yield gamesheetAPIRequest(copyTeamsToDivision, {
      divisionId,
      teamIds: copyTeamIds
    });

    yield put(TeamListLoadingRoutine.trigger({ divisionId, seasonId }));

    yield put(copyTeamsRoutine.success());
  } catch (error) {
    yield put(copyTeamsRoutine.failure({ error }));
  } finally {
    yield put(copyTeamsRoutine.fulfill());
  }
}

export function* copyTeamsFlow() {
  yield all([takeLatest(copyTeamsRoutine.TRIGGER, copyTeamsSaga)]);
}
