import React, { Fragment } from "react";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";
import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";
import TeamForm, { useTeamFormSubmitting } from "@/components/TeamForm";
import PageLoader from "@/components/PageLoader";

import ProtectedLayout from "@/layouts/ProtectedLayout";

function NewTeam() {
  const { seasonId, seasonIsLoaded, seasonIsLoading, seasonIsForbidden, seasonBreadcrumbs } = useCurrentSeasonContext();

  const { isCreating, onSubmit, errorsState, errorsDispatch } = useTeamFormSubmitting({ seasonId });

  return (
    <ProtectedLayout subject={{ type: "teams", seasonId }} action="create" skip={!seasonIsLoaded && !seasonIsForbidden}>
      <Head title="New Team" />
      {seasonIsLoaded ? (
        <Fragment>
          <Breadcrumbs items={[...seasonBreadcrumbs, { title: "Teams", url: `/seasons/${seasonId}/teams` }]} />
          <TitleBar title="New Team" />
          <TeamForm
            onSubmit={onSubmit}
            isOperating={isCreating}
            seasonId={seasonId}
            allowChangeDivision
            submittingErrors={errorsState}
            updateSubmittingErrors={errorsDispatch}
          />
        </Fragment>
      ) : (
        <PageLoader isLoading={seasonIsLoading} isLoaded={seasonIsLoaded} />
      )}
    </ProtectedLayout>
  );
}

export default NewTeam;
