import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getMeta, deletingRoutine } from "@/redux/playerForm";

export default function usePlayerFormDeleting({ seasonId, playerId }) {
  const dispatch = useDispatch();

  const { isDeleting } = useSelector(getMeta);

  const deletePlayer = useCallback(
    () => {
      dispatch(deletingRoutine({ playerId, seasonId }));
    },
    [dispatch, playerId, seasonId]
  );

  return {
    isDeleting,
    deletePlayer
  };
}
