export default function useDayInputOptions({ date, month }) {
  const daysInMonths = date
    ? new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
    : month !== null
      ? new Date(new Date().getFullYear(), month + 1, 0).getDate()
      : 31;

  return Array.from(Array(daysInMonths).keys()).reduce(
    (result, value) => [...result, { value: value + 1, label: value + 1 }],
    []
  );
}
