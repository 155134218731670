import _orderBy from "lodash/orderBy";

const selectRoot = state => state.penaltyTypeReport;

export const getPenaltyTypeReportIsLoading = state => selectRoot(state).isLoading;

export const getPenaltyTypeReportIsLoaded = state => selectRoot(state).isLoaded;

export const getPenaltyTypeReportIsForbidden = state => selectRoot(state).isForbidden;

export const getPenaltyTypeReportSummaryStats = state => selectRoot(state).summaryStats;

export const getPenaltyTypeReportAssessedPenalties = state =>
  _orderBy(selectRoot(state).assessedPenalties, [({ count }) => count], ["desc"]);
