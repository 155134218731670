import React from "react";
import PropTypes from "prop-types";

import { Input } from "reactstrap";

import { useDayInputOptions } from "./hooks";

function DayInput({ value, onChange, date, month }) {
  const options = useDayInputOptions({ date, month });

  return (
    <Input type="select" value={value === null ? "" : value} className="custom-select" onChange={onChange}>
      {value === null && (
        <option disabled value="">
          Day
        </option>
      )}
      {options.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </Input>
  );
}

DayInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  date: PropTypes.shape({}),
  month: PropTypes.number
};

export default DayInput;
