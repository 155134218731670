import { createActions } from "redux-actions";

const { refereeReport } = createActions({
  REFEREE_REPORT: {
    LIST: {
      CLEAR: undefined,
      SEARCH: {
        SET_NEXT_QUERY: undefined,
        COMMIT: undefined,
        CLEAR: undefined
      }
    },
    REPORT: {
      CLEAR: undefined
    }
  }
});

export default refereeReport;
