import _trimEnd from "lodash/trimEnd";
import { formatDateTime } from "@/utils/date";

const selectRoot = state => state.gameNotes;

const selectForm = state => selectRoot(state).form;
const selectFormFields = state => selectForm(state).fields;
const selectFormMeta = state => selectForm(state).meta;

const selectList = state => selectRoot(state).list;
const selectListMeta = state => selectList(state).meta;
const selectListItems = state => selectList(state).items;

export const getGameNoteFormAttributes = state =>
  Object.entries(selectFormFields(state)).reduce((result, [field, { value }]) => ({ ...result, [field]: value }), {});

export const getGameNoteFormFields = state => selectFormFields(state);

export const getGameNoteFormIsOperating = state => selectFormMeta(state).isOperating;

export const getGameNoteFormIsEnabled = state => selectFormMeta(state).isEnabled;

export const getGameNoteFormIsPristine = state =>
  Object.values(selectFormFields(state)).filter(({ isDirty }) => isDirty === false).length > 0;

export const getGameNoteFormIsNotFilled = state =>
  Object.values(selectFormFields(state)).filter(({ value }) => _trimEnd(value) === "").length > 0;

export const getGameNotesListIsLoading = state => selectListMeta(state).isLoading;

export const getGameNotesListIsLoaded = state => selectListMeta(state).isLoaded;

export const getGameNotesList = state => {
  const { ids, data } = selectListItems(state);

  return ids.map(id => ({
    ...data[id],
    id,
    dateCreated: formatDateTime(data[id].dateCreated)
  }));
};

export const getGameNotesModalIsWorking = state =>
  getGameNotesListIsLoading(state) || getGameNoteFormIsOperating(state);
