const { atom } = require("recoil");

export const searchResultsState = atom({
    key: 'searchResultsState',
    default: {
        query: '',
        offset: 0,
        data: []
    }
});

export const currentQueryState = atom({
    key: 'currentQueryState',
    default: ''
})