import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { Row, FormGroup, Label, Button } from "reactstrap";

import DashboardForm from "@/components/DashboardForm";
import DashboardFormFooter from "@/components/DashboardFormFooter";
import ContentSection from "@/components/ContentSection";
import ConfirmDeletionModal from "@/components/ConfirmDeletionModal";
import InvitationStatus from "@/components/InvitationStatus";

import RolesTable from "./RolesTable";
import Field from "./Field";
import ExpirationDate from "./ExpirationDate";

import useForm from "./hooks/useForm";

function InvitationForm({ code }) {
  const { submit, isInvalid, isPristine, isNotFilled, isOperating, isCreating, isDeleting, status, remove } = useForm();

  return (
    <DashboardForm onSubmit={submit} isOperating={isOperating}>
      <Row form className="mb-3">
        <FormGroup className="col-md-6">
          <Label for="invitationCode">Code</Label>

          <Field id="invitationCode" name="code" disabled={!!code} />
        </FormGroup>

        <FormGroup className="col-md-6">
          <Label for="invitationDescription">Description</Label>

          <Field id="invitationDescription" name="description" disabled={!!code} />
        </FormGroup>
      </Row>

      <Row form>
        <FormGroup className="col-md-12">
          <ContentSection title="Permissions">
            <RolesTable editable={!code} />
          </ContentSection>
        </FormGroup>
      </Row>

      {code && (
        <Row form className="mt-3">
          <FormGroup className="col-md-12">
            Status:&nbsp;
            <InvitationStatus status={status} />
            <br />
            <ExpirationDate />
          </FormGroup>
        </Row>
      )}

      <DashboardFormFooter>
        {code ? (
          <Fragment>
            <div className="dashboard-form-footer__secondary">
              <ConfirmDeletionModal
                resourceTitle={code}
                resourceType="Invitation"
                isOperating={isOperating}
                isDeleting={isDeleting}
                onDelete={remove}
              />
            </div>
          </Fragment>
        ) : (
          <div className="dashboard-form-footer__primary">
            <Button
              type="submit"
              color="success"
              disabled={isPristine || isInvalid || isNotFilled}
              outline={isPristine || isInvalid || isNotFilled}
            >
              {isCreating ? "Creating Invitation…" : "Create Invitation"}
            </Button>
          </div>
        )}
      </DashboardFormFooter>
    </DashboardForm>
  );
}

InvitationForm.propTypes = {
  code: PropTypes.string
};

export default InvitationForm;
