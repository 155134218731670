import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import {
  getMeta,
  getInvalidRows,
  parsingRoutine,
  actions,
  importingRoutine,
  getNumRecordsToInsert,
  getNumRecordsToUpdate,
} from "@/redux/scheduledGamesCSVImportWizard";

export default function useScheduledGamesCSVImportWizardParser() {
  const dispatch = useDispatch();

  const { seasonId } = useCurrentSeasonContext();

  const { isParsingInProgress, isParsingFailed, parsingError, isParsingSuccess } = useSelector(getMeta);

  const invalidRows = useSelector(getInvalidRows);

  const countInsert = useSelector(getNumRecordsToInsert);

  const countUpdate = useSelector(getNumRecordsToUpdate);

  const countValidationErrors = invalidRows.reduce(
    (count, { validationErrors }) => count + Object.keys(validationErrors).length,
    0
  );

  const isValidationFailed = countValidationErrors > 0;

  const parseFile = useCallback(file => dispatch(parsingRoutine({ file, seasonId })), [dispatch, seasonId]);

  const reset = useCallback(() => dispatch(actions.clear()), [dispatch]);
  const beginImport = useCallback(() => dispatch(importingRoutine({ seasonId })), [dispatch, seasonId]);

  return {
    error: parsingError,
    isFailed: isParsingFailed,
    isParsing: isParsingInProgress,
    isSuccess: isParsingSuccess,
    isValidationFailed,
    parseFile,
    countValidationErrors,
    invalidRows,
    countInsert,
    countUpdate,
    reset,
    beginImport
  };
}
