import { useState, useEffect } from "react";
import { config } from "@/config";

export function useScheduledGameBroadcasters() {
  const [BROADCAST_PROVIDERS_LIST, setBroadcastProvidersList] = useState([]);
  const [BROADCAST_PROVIDER_VALUES, setBroadcastProviderValues] = useState([]);

  useEffect(() => {
    fetchScheduledGameBroadcasters().then(({ BROADCAST_PROVIDERS_LIST, BROADCAST_PROVIDER_VALUES }) => {
      setBroadcastProvidersList(BROADCAST_PROVIDERS_LIST);
      setBroadcastProviderValues(BROADCAST_PROVIDER_VALUES);
    });
  }, []);

  return {
    BROADCAST_PROVIDERS_LIST,
    BROADCAST_PROVIDER_VALUES,
  };
}

export async function fetchScheduledGameBroadcasters() {
  return fetch(`${config.BFF_API}/get-broadcasters`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      const broadcastersData = data.data;

      const list = broadcastersData.map(({ key, title }) => ({
        code: key,
        title,
      }));

      const broadcastProvidersList = [{ code: "", title: "" }, ...list];
      return {
        BROADCAST_PROVIDERS_LIST: broadcastProvidersList,
        BROADCAST_PROVIDER_VALUES: broadcastProvidersList.map(
          ({ code }) => code
        ),
      };
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}
