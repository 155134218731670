import { useCallback } from "react";

import { changeField } from "./actions";

import useValidateField from "./useValidateField";

export default function useSetFieldValue({ dispatch, constraints, values }) {
  const validateField = useValidateField({ constraints, values });

  const setFieldValue = useCallback(
    ({ name, value }) => {
      const errors = validateField({ name, value });

      dispatch(changeField({ name, value, errors }));
    },
    [dispatch, validateField]
  );

  return setFieldValue;
}
