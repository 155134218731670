import { takeLatest, call, put } from "redux-saga/effects";
import axios from 'axios';

import { loginAction } from "./actions";
import { loginRoutine } from "@/redux/login/routines";
import { firebase, PERSISTENCE_TYPE } from "@/firebase";
import { config } from "../../config";
import { createToken as createTokenRequest } from "@/lib/api/token";
import { setUsersFirebaseUid } from "@/lib/api/users";

export function* firebaseLoginSaga({ payload: { email, password, thenUpdatePassword = false, otp, type="usernameAndPassword" } }) {
  yield put(loginRoutine.request());

  try {
    let userCredential = null;
    try {

      
      switch(type){
        
        case "otp": {
          userCredential = yield call(async () => {
            return fetch(
              `${config.AUTH_GATEWAY}/auth/v4/otp/loginWithFirebase`, {
                method: 'POST',
                body: otp
            })
            .then(response => response.json())
            
            // get the firebase token
            // sign in with the firebase token
            .then(async data => {
              
              await firebase.auth().setPersistence(PERSISTENCE_TYPE);
              return firebase.auth().signInWithCustomToken(data.firebase);
      
            })
            .catch(error => console.log('Error:', error));

          });
          break;
        }
        
        default:
        case "usernameAndPassword": {
          userCredential = yield call(async () => {
            await firebase.auth().setPersistence(PERSISTENCE_TYPE);
            return firebase.auth().signInWithEmailAndPassword(email, password);
          });
          break;
        }

      }
      

    } catch (error) {
      if (error.code === "auth/user-not-found") {
        yield call(createTokenRequest, { email, password }); // call the old api
        userCredential = yield call(() => firebase.auth().createUserWithEmailAndPassword(email, password));
        yield call(() => setUsersFirebaseUid(email, userCredential.user.uid));
      } else {
        throw error;
      }
    }
    
    if (!userCredential) {
      throw new Error("Bad userCredential");
    }

    const idToken = yield call(() => userCredential.user.getIdToken())

    const url = `${config.AUTH_GATEWAY}/auth/v4/tokens`;
    const response = yield call(
      () => axios.get(
        url,
        {
          headers: {
            "Authorization": `Bearer ${idToken}`,
          },
        }
      )
    );

    if (response.status !== 200) {
      throw response
    }

    yield put(loginRoutine.success({ ...response.data, password, thenUpdatePassword }))
  } catch (error) {
    let message = "An unknown error occurred. Please check your credentials and try again";
    if ('code' in error) {
      switch (error.code) {
        case "auth/invalid-email":
          message = "Email address is invalid";
          break;
        case "auth/user-disabled":
        case "auth/weak-password":
          message = "Please contact GameSheet admin for help with your account";
          break;
        case "auth/user-not-found":
        case "auth/wrong-password":
        case "auth/email-already-in-use":
          message = "Email or password is incorrect";
          break;
      }
    } else if ('response' in error && 'status' in error.response) {
      switch (error.response.status) {
        case 401:
          message = "Your email or password is wrong. Please try again.";
          break;
        default:
          message = "Something went wrong. Please try again";
      }
    }

    yield put(loginRoutine.failure(message));
  } finally {
    yield put(loginRoutine.fulfill());
  }
}

export function* loginFlow() {
  yield takeLatest(loginAction, firebaseLoginSaga);
}
