import { useCallback, useState } from "react";

export default function useFile(onChange) {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);

  const setFileError = useCallback(
    error => {
      setFile(null);
      setError(error);
    },
    [setFile, setError]
  );

  const changeFile = useCallback(
    file => {
      setError(null);
      setFile(file);
      onChange(file);
    },
    [onChange]
  );

  const clearFile = useCallback(
    () => {
      changeFile(null);
    },
    [changeFile]
  );

  return {
    file,
    fileError: error,
    changeFile,
    clearFile,
    setFileError
  };
}
