import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import _camelCase from "lodash/camelCase";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import { getFilterValue } from "@/redux/scheduledGamesFilter";

export default function useFilter({ name, action }) {
  const { seasonId } = useCurrentSeasonContext();
  const dispatch = useDispatch();

  const value = useSelector(state => getFilterValue(state, name), [name]);
  const setValue = useCallback(value => dispatch(action({ seasonId, value })), [dispatch, action, seasonId]);

  const handleInputChange = useCallback(({ target: { value } }) => setValue(value), [setValue]);

  const inputId = _camelCase(`scheduledGamesFilter${name}`);

  return {
    value,
    setValue,
    inputId,
    handleInputChange
  };
}
