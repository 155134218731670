import React from "react";

import { Button } from "reactstrap";

import useReset from "./hooks/useReset";

function ResetButton() {
  const { isFilterDirty, reset } = useReset();

  if (!isFilterDirty) {
    return null;
  }

  return (
    <div className="completed-games-page__filter__reset">
      <Button color="link" onClick={reset}>
        Reset filters
      </Button>
    </div>
  );
}

export default ResetButton;
