import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import { Can } from "@/components/Ability";

function AbilityLink({ subject, action, fallback, children, ...linkProps }) {
  return (
    <Can do={action} on={subject} fallback={fallback}>
      <Link {...linkProps}>{children}</Link>
    </Can>
  );
}

AbilityLink.propTypes = {
  subject: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
  action: PropTypes.string.isRequired,
  fallback: PropTypes.node
};

AbilityLink.defaultProps = {
  fallback: null
};

export default AbilityLink;
