import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Input } from "reactstrap";

class DivisionSelectField extends PureComponent {
  static propTypes = {
    key: PropTypes.string,
    name: PropTypes.string.isRequired,
    isBusy: PropTypes.bool,
    isReady: PropTypes.bool,
    busyPlaceholder: PropTypes.string,
    readyPlaceholder: PropTypes.string,
    defaultValue: PropTypes.string,
    divisions: PropTypes.arrayOf(PropTypes.shape({})),
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    valid: PropTypes.bool
  };

  static defaultProps = {
    isBusy: false,
    isReady: true,
    busyPlaceholder: "Loading…",
    readyPlaceholder: "Select division…",
    defaultValue: "",
    divisions: [],
    disabled: false,
    valid: true,
    onChange() {}
  };

  constructor(props) {
    super(props);

    this.state = { value: props.defaultValue };
  }

  triggerChange = () => {
    const { name, onChange } = this.props;

    onChange({ target: { name, value: this.state.value } });
  };

  handleChange = ({ target }) => {
    this.setState({ value: target.value }, this.triggerChange);
  };

  render() {
    const {
      key,
      name,
      disabled,
      isBusy,
      isReady,
      busyPlaceholder,
      readyPlaceholder,
      divisions,
      defaultValue,
      valid
    } = this.props;

    return (
      <Input
        key={key}
        className={`custom-select ${valid ? "" : "is-invalid"}`}
        type="select"
        id="teamDivision"
        name={name}
        onChange={this.handleChange}
        defaultValue={defaultValue}
        disabled={disabled || isBusy || !isReady}
      >
        <option value="" disabled>
          {isBusy ? busyPlaceholder : readyPlaceholder}
        </option>

        {isReady &&
          divisions.map(({ id, title }) => (
            <option value={id} key={id}>
              {title}
            </option>
          ))}
      </Input>
    );
  }
}

export default DivisionSelectField;
