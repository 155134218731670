import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { useDispatch } from "react-redux";
import useActionsDropdown from "./hooks/useActionsDropdown";
import { deletingRoutine } from "@/redux/divisionForm";
import useConfirmDeleteModal from "@/components-16.8/ConfirmDeleteModal/index";
import { Can, canCheck } from "@/components/Ability";
import { useDeleteProtection } from "./hooks/useDeleteProtection";
import useDivisionLocking from "./hooks/useDivisionsLocking"
import useDivisionUnlocking from "./hooks/useDivisionsUnlocking"
import { loadingRoutine } from "@/redux/divisionsList";

export default function ActionsDropdown(props) {
  const { seasonId, id, title, numTeams, hasUnlockedTeams, setLocking } = props;

  const dispatch = useDispatch();
  const deleteDivision = useCallback(() => dispatch(deletingRoutine({ seasonId, divisionId: id })), [
    dispatch,
    seasonId,
    id
  ]);

  const divisionsLocking = useDivisionLocking(seasonId, id);
  const divisionsUnlocking = useDivisionUnlocking(seasonId, id);

  const _triggerTeamsLocking = useCallback(
    async ( {lock, seasonId, divisionId} ) => {
      setLocking(true)
      if (lock) {
        divisionsLocking(seasonId, divisionId)
      } else {
        divisionsUnlocking(seasonId, divisionId)
      }
      const searchParams = new URLSearchParams(location.search);
      const pageNumber = searchParams.get("page") || 1;
      const sort = searchParams.get("sort") || "none";

      await new Promise(r => setTimeout(r, 3000));
      dispatch(loadingRoutine({ seasonId, pageSize: 25, pageNumber, sort }));
      setLocking(false)
    },[] );

  const { toggle: toggleDeleteModal, DeleteModal } = useConfirmDeleteModal({ onDelete: deleteDivision });
  const { toggle: toggleDeleteProtection, DeleteProtectionModal } = useDeleteProtection();

  const toggle = () => ((!!numTeams && toggleDeleteProtection) || toggleDeleteModal)();

  const { isOpen, toggleIsOpened, triggerEdit, isDeleting } = useActionsDropdown({ seasonId, id, title });

  const showButton =
    canCheck({ I: "update", this: { type: "divisions", id } }) ||
    canCheck({ I: "delete", this: { type: "divisions", id } });

  return (
    <>
      {(showButton && (
        <ButtonDropdown isOpen={isOpen} toggle={toggleIsOpened}>
          <DropdownToggle caret size="sm" color="secondary" outline>
            Actions
          </DropdownToggle>

          <DropdownMenu>
            <Can I="update" this={{ type: "divisions", id }}>
              <DropdownItem
                onClick={triggerEdit}
                action="update"
                size="sm"
                color="primary"
                tag={Link}
                to={`/seasons/${seasonId}/divisions/${id}/edit`}
              >
                Edit
              </DropdownItem>
            </Can>
            {numTeams > 0 && (
              <Can I="update" this={{ type: "divisions", id }}>
                <Can I="update" this={{ type: "teamLocks" }}>
                  <DropdownItem
                    onClick={() => _triggerTeamsLocking({ seasonId: seasonId, divisionId: id, lock: hasUnlockedTeams })}
                    size="sm"
                    color="primary"
                  >
                    {hasUnlockedTeams ? "Lock Teams" : "Unlock Teams"}
                  </DropdownItem>
                </Can>
              </Can>
            )}
            <Can I="delete" this={{ type: "divisions", id }}>
              <DropdownItem divider />
              <DropdownItem className="text-danger" onClick={toggle}>
                Delete
              </DropdownItem>
              <DeleteModal areActionsEnabled={isDeleting} resourceTitle={title} resourceType="Divisions" />
              <DeleteProtectionModal />
            </Can>
          </DropdownMenu>
        </ButtonDropdown>
      )) || <span />}
    </>
  );
}

ActionsDropdown.propTypes = {
  seasonId: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  numTeams: PropTypes.number,
  hasUnlockedTeams: PropTypes.bool,
  triggerTeamsLocking: PropTypes.func
};

ActionsDropdown.defaultProps = {
  numTeams: 0,
  hasUnlockedTeams: true,
  triggerTeamsLocking: () => {}
};
