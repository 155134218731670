import { useCallback } from "react";
import { useDispatch } from "react-redux";

import useCurrentLeagueContext from "@/hooks/useCurrentLeagueContext";


import {
    deletingRoutine,
  } from "@/redux/seasonForm";

export default function useSeasonDeleting(seasonId) {
  const dispatch = useDispatch();

  const { leagueId, leagueAssociationId: associationId } = useCurrentLeagueContext();

  const deleteSeason = useCallback(() => dispatch(deletingRoutine({ associationId, leagueId, seasonId })), [
    dispatch,
    associationId,
    leagueId,
    seasonId
  ]);

  return deleteSeason;
}
