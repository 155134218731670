import { createActions } from "redux-actions";

const { billingReport } = createActions({
  BILLING_REPORT: {
    REPORT: {
      CLEAR: undefined
    }
  }
});

export default billingReport;
