import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { playerPenaltyReportLoadingRoutine } from "@/redux/playerPenaltyReport/routines";
import selectors from "@/redux/playerPenaltyReport/selectors";
import actions from "@/redux/playerPenaltyReport/actions";

const { getPlayerPenaltyReportIsLoaded, getPlayerPenaltyReportMember, getPlayerPenaltyReportIsForbidden } = selectors;

export default function usePenaltyReport(externalId) {
  const dispatch = useDispatch();
  const player = useSelector(getPlayerPenaltyReportMember);
  const isLoaded = useSelector(getPlayerPenaltyReportIsLoaded);
  const isForbidden = useSelector(getPlayerPenaltyReportIsForbidden);

  useEffect(
    () => {
      dispatch(playerPenaltyReportLoadingRoutine({ externalId }));

      return () => dispatch(actions.report.clear());
    },
    [dispatch, externalId]
  );

  return {
    player,
    isLoaded,
    isForbidden
  };
}
