import React, { Fragment } from "react";
import PropTypes from "prop-types";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";

import SuspensionReport from "@/components/IncidentSuspensionReport";

import useSuspensionReport from "./hooks/useSuspensionReport";

function ReportPage({ location: { search } }) {
  const { isLoaded, isForbidden, seasonId } = useSuspensionReport(search);

  return (
    <ProtectedLayout condition={!isForbidden}>
      <Head title="Incidents & Suspension Report" />

      {isLoaded && (
        <Fragment>
          <Breadcrumbs
            items={[
              { title: "Reports" },
              {
                title: "Incidents & Suspension Report",
                url: "/reports/incidents/sources"
              }
            ]}
          />
        </Fragment>
      )}

      <SuspensionReport seasonId={seasonId} />
    </ProtectedLayout>
  );
}

ReportPage.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string.isRequired }).isRequired
};

export default ReportPage;
