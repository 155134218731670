const selectRoot = state => state.reportDataSourcesList;

function makeSeasonSource(state, season, extra = []) {
  const { leagues, associations } = selectRoot(state);

  const { title: seasonTitle, leagueId, associationId, type: seasonType } = season;

  const { title: leagueTitle } = leagues[leagueId];
  const { title: associationTitle } = associations[associationId];

  const titleStrings = [
    associationTitle,
    leagueTitle,
    seasonType === "archivedSeasons" ? `${seasonTitle} (archived)` : seasonTitle,
    ...extra
  ];

  return {
    id: season.id,
    type: "seasons",
    title: titleStrings.join(" » ")
  };
}

function makeDivisionSource(state, division, extra = []) {
  const { seasons } = selectRoot(state);

  const { id, title, seasonId } = division;
  const season = { id: seasonId, ...seasons[seasonId] };

  return {
    ...makeSeasonSource(state, season, [title, ...extra]),
    type: "divisions",
    id
  };
}

function makeTeamSource(state, team) {
  const { divisions } = selectRoot(state);

  const { id, title, divisionId } = team;
  const division = { id: divisionId, ...divisions[divisionId] };

  return {
    ...makeDivisionSource(state, division, [title]),
    type: "teams",
    id
  };
}

function getSeasonList(state) {
  const { ids: seasonIds, seasons } = selectRoot(state);

  return seasonIds.filter(seasonId => seasons[seasonId]).map(seasonId => {
    const season = { id: seasonId, ...seasons[seasonId] };

    return makeSeasonSource(state, season);
  });
}

function getDivisionList(state) {
  const { divisions, seasons } = selectRoot(state);

  const seasonsWithAllDivisions = Object.entries(seasons).reduce(
    (result, [id, season]) =>
      Object.values(divisions).filter(d => d.seasonId === id).length === season.totalDivisions
        ? [...result, { ...season, id }]
        : result,
    []
  );

  const singleDivisions = Object.entries(divisions).reduce(
    (result, [id, division]) =>
      !seasonsWithAllDivisions.find(season => season.id === division.seasonId)
        ? [...result, { ...division, id }]
        : result,
    []
  );

  const seasonSources = seasonsWithAllDivisions.map(season => {
    return makeSeasonSource(state, season, ["All Divisions", "All Teams"]);
  });

  const divisionSources = singleDivisions.map(division => {
    return makeDivisionSource(state, division, ["All Teams"]);
  });

  return [...seasonSources, ...divisionSources];
}

function getTeamList(state) {
  const { teams: allTeams, divisions: allDivisions, seasons: allSeasons } = selectRoot(state);

  const fullSeasons = Object.entries(allSeasons).reduce((result, [seasonId, season]) => {
    const divisions = Object.entries(allDivisions).reduce((result, [divisionId, division]) => {
      const teams = Object.entries(allTeams).reduce(
        (result, [teamId, team]) => (team.divisionId === divisionId ? [...result, { id: teamId, ...team }] : result),
        []
      );

      if (teams.length === division.totalTeams) {
        return [...result, { id: divisionId, ...division }];
      }

      return result;
    }, []);

    if (divisions.length === season.totalDivisions) {
      return [...result, { id: seasonId, ...season }];
    }

    return result;
  }, []);

  const fullDivisions = Object.entries(allDivisions).reduce((result, [divisionId, division]) => {
    if (fullSeasons.find(({ id }) => id === division.seasonId)) {
      return result;
    }

    const teams = Object.entries(allTeams).reduce(
      (result, [teamId, team]) => (team.divisionId === divisionId ? [...result, { id: teamId, ...team }] : result),
      []
    );

    if (teams.length === division.totalTeams) {
      return [...result, { id: divisionId, ...division }];
    }

    return result;
  }, []);

  const teams = Object.entries(allTeams).reduce((result, [teamId, team]) => {
    if (fullSeasons.find(({ id }) => id === team.seasonId) || fullDivisions.find(({ id }) => id === team.divisionId)) {
      return result;
    }

    return [...result, { id: teamId, ...team }];
  }, []);

  return [
    ...fullSeasons.map(season => makeSeasonSource(state, season, ["All Divisions", "All Teams"])),
    ...fullDivisions.map(division => makeSeasonSource(state, division, ["All Teams"])),
    ...teams.map(team => makeTeamSource(state, team))
  ];
}

export const getSourcesList = state => {
  const { divisions, teams } = selectRoot(state);

  if (Object.keys(teams).length > 0) {
    return getTeamList(state);
  } else if (Object.keys(divisions).length > 0) {
    return getDivisionList(state);
  }

  return getSeasonList(state);
};

export const getSourcesListIsLoaded = state => selectRoot(state).isLoaded;
export const getSourcesListIsLoading = state => selectRoot(state).isLoading;
export const getSourcesListIsError = state => selectRoot(state).isError;
