import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import TitleBar from "@/components/TitleBar";
import PageLoader from "@/components/PageLoader";

import BillingReportTeamGamesStats from "./TeamGamesStats";

import "./Report.scss";

class Report extends PureComponent {
  static propTypes = {
    isLoaded: PropTypes.bool,
    isLoading: PropTypes.bool,
    teamGamesStats: PropTypes.arrayOf(PropTypes.shape({}))
  };

  static defaultProps = {
    isLoaded: false,
    isLoading: false,
    teamGamesStats: []
  };

  print = () => {
    window.print();
  };

  render() {
    const { isLoading, isLoaded, teamGamesStats } = this.props;

    return (
      <PageLoader isLoading={isLoading} isLoaded={isLoaded}>
        <TitleBar title="Billing Report">
          <Button outline color="primary" size="sm" onClick={this.print}>
            Print
          </Button>
        </TitleBar>

        <BillingReportTeamGamesStats teamGamesStats={teamGamesStats} />
      </PageLoader>
    );
  }
}

export default Report;
