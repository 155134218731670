import { handleActions, combineActions } from "redux-actions";

import {
  TeamListLoadingRoutine as loadingRoutine,
  TeamCreatingRoutine as creatingRoutine,
  CurrentTeamDeletingRoutine as deletingRoutine,
  CurrentTeamUpdatingRoutine as updatingRoutine,
  TeamLockingRoutine as lockingRoutine
} from "../routines";

import actions from "../actions";

const initialState = {
  isLoaded: false,
  isLoading: false,
  isForbidden: false,
  isLocking: [],
  errors: null,
  ids: [],
  totalCount: 0,
  filteredCount: 0
};

const trigger = () => Object.assign({}, initialState);

const request = state => Object.assign({}, state, { isLoading: true });

const success = (state, { payload }) =>
  Object.assign({}, state, {
    isLoaded: true,
    ids: payload.ids,
    totalCount: payload.totalCount,
    filteredCount: payload.filteredCount
  });

const failure = (state, { payload }) =>
  Object.assign({}, state, {
    isForbidden: payload.responseStatus === 401,
    isLoaded: false,
    errors: payload.errors
  });

const fulfill = state => Object.assign({}, state, { isLoading: false });

function reduceTeamLockRequest(state, { payload: { teamId } }) {
  return { ...state, isLocking: [...state.isLocking, teamId] };
}

function reduceTeamLockFulfill(state, { payload: { teamId } }) {
  return { ...state, isLocking: state.isLocking.filter(tid => tid !== teamId) };
}

export default handleActions(
  {
    [loadingRoutine.TRIGGER]: trigger,
    [loadingRoutine.REQUEST]: request,
    [loadingRoutine.SUCCESS]: success,
    [loadingRoutine.FAILURE]: failure,
    [loadingRoutine.FULFILL]: fulfill,
    [lockingRoutine.TRIGGER]: reduceTeamLockRequest,
    [lockingRoutine.FULFILL]: reduceTeamLockFulfill,
    [combineActions(
      actions.list.clear,
      creatingRoutine.SUCCESS,
      updatingRoutine.SUCCESS,
      deletingRoutine.SUCCESS
    )]: () => ({ ...initialState })
  },
  initialState
);
