import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Table } from "reactstrap";

import EmptyListMessage from "@/components/EmptyListMessage";

import RefereeReportGameLink from "./GameLink";

class GamesOfficiated extends PureComponent {
  static propTypes = {
    games: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        game: PropTypes.shape({ date: PropTypes.string.isRequired }),
        season: PropTypes.shape({
          title: PropTypes.string.isRequired,
          isArchived: PropTypes.bool.isRequired
        }).isRequired
      })
    )
  };

  static defaultProps = {
    games: []
  };

  render() {
    const { games } = this.props;

    return (
      <div className="referee-report__section">
        <h3 className="referee-report__section-title">Games Officiated</h3>

        {games.length > 0 ? (
          <Table striped borderless>
            <thead>
              <tr>
                <th className="referee-report_games-officiated-table__game">Game</th>
                <th className="referee-report_games-officiated-table__season">Season</th>
                <th className="referee-report_games-officiated-table__date">Date</th>
              </tr>
            </thead>
            <tbody>
              {games.map(({ id, date, title, season }) => (
                <tr key={id}>
                  <td>
                    <RefereeReportGameLink id={id} season={season} title={title} />
                  </td>
                  <td>
                    {season.title} {season.isArchived && "(archived)"}
                  </td>
                  <td>{date}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <EmptyListMessage>No games officiated.</EmptyListMessage>
        )}
      </div>
    );
  }
}

export default GamesOfficiated;
