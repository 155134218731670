import { handleActions, combineActions } from "redux-actions";

import {
  seasonListLoadingRoutine,
  divisionListLoadingRoutine,
  teamListLoadingRoutine,
  seasonDivisionListLoadingRoutine,
  divisionTeamListLoadingRoutine
} from "./routines";

import actions from "./actions";

function reduceSeason(state, season) {
  const associationId = season.association.id;
  const leagueId = season.league.id;

  return {
    ...state,
    associations: {
      ...state.associations,
      [associationId]: { title: season.association.title }
    },
    leagues: {
      ...state.leagues,
      [leagueId]: {
        title: season.league.title,
        associationId
      }
    },
    seasons: {
      ...state.seasons,
      [season.id]: {
        title: season.title,
        type: season.type,
        totalDivisions: 0,
        leagueId,
        associationId
      }
    }
  };
}

function reduceDivision(state, division) {
  const associationId = division.association.id;
  const leagueId = division.league.id;
  const seasonId = division.season.id;

  return {
    ...state,
    ...reduceSeason(state, division.season),
    divisions: {
      ...state.divisions,
      [division.id]: {
        title: division.title,
        totalTeams: 0,
        seasonId,
        leagueId,
        associationId
      }
    }
  };
}

function reduceTeam(state, team) {
  const associationId = team.association.id;
  const leagueId = team.league.id;
  const seasonId = team.season.id;
  const divisionId = team.division.id;

  return {
    ...state,
    ...reduceDivision(state, team.division),
    teams: {
      ...state.teams,
      [team.id]: {
        title: team.title,
        divisionId,
        seasonId,
        leagueId,
        associationId
      }
    }
  };
}

const initialState = {
  isLoaded: false,
  isLoading: false,
  isError: false,
  ids: [],
  associations: {},
  leagues: {},
  seasons: {},
  divisions: {},
  teams: {}
};

function reduceListLoadingTrigger(state, { payload: { ids } }) {
  return { ...state, ids };
}

function reduceListLoadingRequest(state) {
  return { ...state, isLoading: true };
}

function reduceSeasonListLoadingSuccess(state, { payload: { seasons } }) {
  return {
    ...seasons.reduce(reduceSeason, state),
    isLoaded: true
  };
}

function reduceDivisionListLoadingSuccess(state, { payload: { divisions } }) {
  return {
    ...divisions.reduce(reduceDivision, state),
    isLoaded: true
  };
}

function reduceTeamListLoadingSuccess(state, { payload: { teams } }) {
  return {
    ...teams.reduce(reduceTeam, state),
    isLoaded: true
  };
}

function reduceListLoadingFailure(state) {
  return { ...state, isError: true };
}

function reduceListLoadingFulfill(state) {
  return { ...state, isLoading: false };
}

function reduceSeasonDivisionListLoadingSuccess(state, { payload }) {
  const { seasonId, totalDivisions } = payload;

  return {
    ...state,
    seasons: {
      ...state.seasons,
      [seasonId]: {
        ...state.seasons[seasonId],
        totalDivisions
      }
    }
  };
}

function reduceDivisionTeamListLoadingSuccess(state, { payload }) {
  const { divisionId, totalTeams } = payload;

  return {
    ...state,
    divisions: {
      ...state.divisions,
      [divisionId]: {
        ...state.divisions[divisionId],
        totalTeams
      }
    }
  };
}

export default handleActions(
  {
    [combineActions(
      seasonListLoadingRoutine.TRIGGER,
      divisionListLoadingRoutine.TRIGGER,
      teamListLoadingRoutine.TRIGGER
    )]: reduceListLoadingTrigger,
    [combineActions(
      seasonListLoadingRoutine.REQUEST,
      divisionListLoadingRoutine.REQUEST,
      teamListLoadingRoutine.REQUEST
    )]: reduceListLoadingRequest,
    [seasonListLoadingRoutine.SUCCESS]: reduceSeasonListLoadingSuccess,
    [divisionListLoadingRoutine.SUCCESS]: reduceDivisionListLoadingSuccess,
    [teamListLoadingRoutine.SUCCESS]: reduceTeamListLoadingSuccess,
    [combineActions(
      seasonListLoadingRoutine.FAILURE,
      divisionListLoadingRoutine.FAILURE,
      teamListLoadingRoutine.FAILURE
    )]: reduceListLoadingFailure,
    [combineActions(
      seasonListLoadingRoutine.FULFILL,
      divisionListLoadingRoutine.FULFILL,
      teamListLoadingRoutine.FULFILL
    )]: reduceListLoadingFulfill,
    [seasonDivisionListLoadingRoutine.SUCCESS]: reduceSeasonDivisionListLoadingSuccess,
    [divisionTeamListLoadingRoutine.SUCCESS]: reduceDivisionTeamListLoadingSuccess,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
