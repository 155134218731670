import { takeLeading, put, all } from "redux-saga/effects";

import { loadTeams } from "@/lib/api/teams";
import { fetchList } from "@/redux/api/sagas";

import { loadingRoutine } from "./routines";

function* loadingSaga({ payload: { seasonId } }) {
  yield put(loadingRoutine.request());

  try {
    const { teams } = yield fetchList("teams", loadTeams, { seasonId });

    yield put(loadingRoutine.success({ teams }));
  } catch (error) {
    yield put(loadingRoutine.failure({ error }));
  } finally {
    yield put(loadingRoutine.fulfill());
  }
}

export function* teamPickerFieldFlow() {
  yield all([takeLeading(loadingRoutine.TRIGGER, loadingSaga)]);
}
