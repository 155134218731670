import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { FormGroup, Label, Input, Button } from "reactstrap";

import DashboardForm from "@/components/DashboardForm";
import ContentSection from "@/components/ContentSection";
import FormItem from "@/components/FormItem";
import TitleBar from "@/components/TitleBar";
import SeasonTeamsSelect from "@/components/SeasonTeamsSelect";
import DashboardFormFooter from "@/components/DashboardFormFooter";

class NewTeamForm extends PureComponent {
  static propTypes = {
    seasonId: PropTypes.string.isRequired,
    isOperating: PropTypes.bool,
    values: PropTypes.shape({
      teamId: PropTypes.string,
      position: PropTypes.string
    }),
    disallowTeamIds: PropTypes.arrayOf(PropTypes.string),
    changeValues: PropTypes.func,
    changeTeamId: PropTypes.func,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    coachPositions: PropTypes.arrayOf(PropTypes.shape({})).isRequired
  };

  static defaultProps = {
    isOperating: false,
    values: {
      teamId: "",
      position: ""
    },
    disallowTeamIds: [],
    coachPositions: [],
    changeValues() {},
    changeTeamId() {},
    onCancel() {},
    onSubmit() {}
  };

  handleInputChange = ({ target }) => {
    const { changeValues } = this.props;

    changeValues({ [target.name]: target.value });
  };

  handleTeamChange = teamId => {
    const { changeTeamId } = this.props;

    changeTeamId({ id: teamId });
  };

  handleSubmit = () => {
    const { values, onSubmit } = this.props;

    onSubmit(values);
  };

  render() {
    const {
      seasonId,
      values: { teamId, position },
      isOperating,
      onCancel,
      disallowTeamIds,
      coachPositions
    } = this.props;

    return (
      <DashboardForm className="coach-form mt-5" isOperating={isOperating}>
        <TitleBar title="Add coach to team" titleTag="h3" />
        <ContentSection>
          <FormItem>
            <FormGroup className="col-md-6">
              <SeasonTeamsSelect
                disallowIds={disallowTeamIds}
                seasonId={seasonId}
                defaultValue={teamId}
                onChange={this.handleTeamChange}
              />
            </FormGroup>

            <FormGroup className="col-md-6">
              <Label for="position">Position</Label>
              <Input
                type="select"
                id="teamPlayerPosition"
                name="position"
                disabled={teamId === ""}
                defaultValue={position}
                onChange={this.handleInputChange}
              >
                <option value="" disabled>
                  Select position
                </option>
                {coachPositions.map(position => (
                  <option key={position.key} value={position.key}>
                    {position.value}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </FormItem>

          <DashboardFormFooter className="small-margin">
            <div className="dashboard-form-footer__primary">
              <Button outline color="secondary" onClick={onCancel}>
                Cancel
              </Button>
              <Button color="success" disabled={teamId === ""} onClick={this.handleSubmit}>
                Add
              </Button>
            </div>
          </DashboardFormFooter>
        </ContentSection>
      </DashboardForm>
    );
  }
}

export default NewTeamForm;
