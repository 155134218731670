import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import _trimEnd from "lodash/trimEnd";

import {
  getCurrentRefereeIsLoaded,
  getCurrentRefereeIsLoading,
  getCurrentRefereeIsForbidden,
  getCurrentReferee
} from "@/redux/referees/selectors";

import { currentRefereeLoadingRoutine } from "@/redux/referees/routines";
import { refereesActions } from "@/redux/referees/actions";

import { refereeReportLoadingRoutine } from "@/redux/refereeReport/routines";
import actions from "@/redux/refereeReport/actions";

export default function useRefereeReport({ seasonId, refereeId }) {
  const dispatch = useDispatch();
  const isRefereeLoaded = useSelector(getCurrentRefereeIsLoaded);
  const isRefereeLoading = useSelector(getCurrentRefereeIsLoading);
  const isRefereeForbidden = useSelector(getCurrentRefereeIsForbidden);
  const referee = useSelector(getCurrentReferee);
  const externalId = useMemo(() => (referee && referee.externalId && _trimEnd(referee.externalId)) || "", [referee]);

  useEffect(
    () => {
      dispatch(currentRefereeLoadingRoutine.trigger({ seasonId, id: refereeId }));

      return () => dispatch(refereesActions.current.clear());
    },
    [dispatch, seasonId, refereeId]
  );

  useEffect(
    () => {
      if (externalId !== "") {
        dispatch(refereeReportLoadingRoutine({ externalId }));
      }

      return () => {
        dispatch(actions.report.clear());
      };
    },
    [dispatch, seasonId, externalId]
  );

  return {
    isRefereeLoaded,
    isRefereeLoading,
    isRefereeForbidden,
    referee,
    externalId
  };
}
