import React, { Fragment, useEffect } from "react";

import {
  getUserTimeZone,
  getTimezoneOptions,
  formatOffset,
  getOffset,
} from "@/utils/timezones";

function TimeZoneInput(props) {
  const { id, name, value, isDirty, isInvalid, onChange, date, time, setOffset } = props;

  const [options, setOptions] = React.useState([]);

  useEffect(() => {
    const options = getTimezoneOptions();
    setOptions(options.map(tz => ({
      label: tz,
      value: tz,
      offset: null,
    })));

    if (value === "") {
      // "" means it hasn't been set yet, but null means null
      const userTimeZone = getUserTimeZone();
      onChange({ target: { value: userTimeZone, name }});
    }
  }, []);

  useEffect(() => {
    if (!value) return;
    if (!date.value) return;
    if (!time.value) return;
    
    const offset = getOffset(value, date.value, time.value);

    if (offset === null) {
      if (date.isDirty || time.isDirty) {
        value !== "" && onChange({ target: { value: "", name }});
      } else {
        return;
      }
    }

    setOffset(offset);

    setOptions((prev) => {
      return prev.map((option) => {
        if (option.value === value) {
          if (option.offset !== offset) {
            return {
              ...option,
              label: `${value} (${formatOffset(offset)})`,
              offset: offset,
            };
          }
        }
        return option;
      });
    });
  }, [value, date.value, time.value]);

  return (
    <Fragment>
        <select
          id="timezone"
          name="timeZone"
          className="form-control"
          value={value || ""}
          onChange={(e) => onChange(e)}
        >
          <option value="">Select a timezone</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
    </Fragment>
  );
}

export default TimeZoneInput;
