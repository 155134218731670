import { createRoutine } from "redux-saga-routines";

export const reportDataSourcesSelectAssociationListLoadingRoutine = createRoutine(
  "REPORT_DATA_SOURCES_SELECT/ASSOCIATION_LIST/LOADING"
);

export const reportDataSourcesSelectLeagueListLoadingRoutine = createRoutine(
  "REPORT_DATA_SOURCES_SELECT/LEAGUE_LIST/LOADING"
);

export const reportDataSourcesSelectSeasonListLoadingRoutine = createRoutine(
  "REPORT_DATA_SOURCES_SELECT/SEASON_LIST/LOADING"
);

export const divisionsLoadingRoutine = createRoutine("REPORT_DATA_SOURCES_SELECT/DIVISION_LIST/LOADING");

export const teamsLoadingRoutine = createRoutine("REPORT_DATA_SOURCES_SELECT/TEAM_LIST/LOADING");
