import React from "react";
import PropTypes from "prop-types";

import { Switch } from "react-router-dom";

import LoggedInRoute from "@/containers/LoggedInRoute";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import { useTeamFormDivisionsListLoading, useTeamFormDivisionsListClearing } from "@/components/TeamForm";

import NewTeam from "./pages/NewTeam";
import EditTeam from "./pages/EditTeam";
import TeamsIndex from "./pages/TeamsIndex";

function Teams({ match: { url } }) {
  const { seasonId } = useCurrentSeasonContext();

  useTeamFormDivisionsListLoading(seasonId);
  useTeamFormDivisionsListClearing();

  return (
    <Switch>
      <LoggedInRoute path={`${url}/new`} component={NewTeam} />
      <LoggedInRoute path={`${url}/:teamId/edit`} component={EditTeam} />
      <LoggedInRoute path="" component={TeamsIndex} />
    </Switch>
  );
}

Teams.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired
};

export default Teams;
