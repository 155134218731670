import { handleActions } from "redux-actions";
import { combineReducers } from "redux";

import { loadGamesRoutine } from "../../routines";

import data from "./data";
import pagination from "./pagination";

const isForbidden = handleActions(
  {
    [loadGamesRoutine.FAILURE]: (state, { payload: { responseStatus } }) => responseStatus === 401
  },
  false
);

export default combineReducers({
  data,
  pagination,
  isForbidden
});
