import { handleActions } from "redux-actions";
import { decode } from "jsonwebtoken";

import { tokenRefreshRoutine, tokenSetRoutine, tokenResetRoutine } from "./routines";

const defaultState = {
  encodedAccess: "",
  encodedRefresh: "",
  error: null,
  exp: null,
  refreshExp: null,
  roles: [],
};

const tokenSetActionHandler = (state, { payload }) => {
  const decodedRefresh = decode(payload.refresh, { complete: true }).payload;
  const decodedAccess = decode(payload.access, { complete: true }).payload;
  const decodedRoles = decode(payload.roles, { complete: true }).payload;

  return Object.assign({}, state, {
    encodedAccess: payload.access,
    encodedRefresh: payload.refresh,
    exp: new Date(decodedAccess.exp * 1000),
    refreshExp: new Date(decodedRefresh.exp * 1000),
    roles: decodedRoles.roles || [],
  });
};

const refreshTokenSuccessActionHandler = (state, { payload }) => {
  const decodedRefresh = decode(payload.refresh, { complete: true }).payload;
  const decodedAccess = decode(payload.access, { complete: true }).payload;
  const decodedRoles = decode(payload.roles, { complete: true }).payload;

  return Object.assign({}, state, {
    encodedAccess: payload.access,
    encodedRefresh: payload.refresh,
    exp: new Date(decodedAccess.exp * 1000),
    refreshExp: new Date(decodedRefresh.exp * 1000),
    roles: decodedRoles.roles || [],
    error: null,
  });
}

const refreshTokenFailureActionHandler = (state, { payload }) => {
 return Object.assign({}, state, { error: payload });
}

const tokenResetActionHandler = () => defaultState;

export default handleActions(
  {
    [tokenSetRoutine.SUCCESS]: tokenSetActionHandler,
    [tokenRefreshRoutine.SUCCESS]: refreshTokenSuccessActionHandler,
    [tokenRefreshRoutine.FAILURE]: refreshTokenFailureActionHandler,
    [tokenResetRoutine.SUCCESS]: tokenResetActionHandler,
  },
  defaultState
);
