import { all, put, takeLatest } from "redux-saga/effects";

import { loadApiKey } from "@/lib/api/apiKeys";
import { fetchOne } from "@/redux/api/sagas";

import { loadingRoutine } from "./routines";

function* loadingSaga({ payload: { keyId } }) {
  yield put(loadingRoutine.request());

  try {
    const [ipadKey] = yield fetchOne({ type: "apiKeys", id: keyId }, loadApiKey, {
      include: "seasons"
    });

    yield put(loadingRoutine.success({ ipadKey }));
  } catch (error) {
    const { response } = error;

    yield put(
      loadingRoutine.failure({
        error,
        responseStatus: response && response.responseStatus
      })
    );
  } finally {
    yield put(loadingRoutine.fulfill());
  }
}

export function* currentIpadKeyFlow() {
  yield all([takeLatest(loadingRoutine.TRIGGER, loadingSaga)]);
}
