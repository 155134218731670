import { atom } from "recoil";

export const selectedIdsState = atom({
  key: 'selectedIds',
  default: []
});

export const forceListUpdateState = atom({
  key: 'forceListUpdate',
  default: 0
});

export const scheduledGamesMetaState = atom({
  key: 'scheduledGamesMeta',
  default: {}
});