import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Form from "@/components/Form";
import NamespaceContext from "./NamespaceContext";

import "./DashboardForm.scss";

const DashboardForm = ({ onSubmit, onChange, className, children, isOperating, disabled, namespace }) => {
  return (
    <NamespaceContext.Provider value={namespace}>
      <div className={classNames("dashboard-form", className)}>
        <Form
          className="dashboard-form__form"
          onSubmit={onSubmit}
          onChange={onChange}
          disabled={disabled || isOperating}
        >
          {children}
        </Form>
      </div>
    </NamespaceContext.Provider>
  );
};

DashboardForm.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  isOperating: PropTypes.bool,
  disabled: PropTypes.bool,
  namespace: PropTypes.string
};

DashboardForm.defaultProps = {
  isOperating: false,
  disabled: false,
  className: "",
  namespace: "",
  onSubmit() {},
  onChange() {}
};

export default DashboardForm;
