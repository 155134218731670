const selectRoot = state => state.teamList;
const selectPagination = state => selectRoot(state).pagination;
const selectAssociations = state => selectRoot(state).associations;
const selectLeagues = state => selectRoot(state).leagues;
const selectSeasons = state => selectRoot(state).seasons;
const selectDivisions = state => selectRoot(state).divisions;
const selectAssociation = (state, id) => ({
  ...selectAssociations(state)[id],
  id
});
const selectLeague = (state, id) => ({ ...selectLeagues(state)[id], id });
const selectSeason = (state, id) => ({ ...selectSeasons(state)[id], id });
const selectDivision = (state, id) => ({ ...selectDivisions(state)[id], id });

function buildTeam(state, id, team) {
  const { title, associationId, leagueId, seasonId, divisionId } = team;

  return {
    id,
    title,
    association: selectAssociation(state, associationId),
    league: selectLeague(state, leagueId),
    season: selectSeason(state, seasonId),
    division: selectDivision(state, divisionId)
  };
}

export const getTeam = (state, id) => buildTeam(state, id, selectRoot(state).teams[id]);

export const getTeams = state => {
  const { teams } = selectRoot(state);

  return selectPagination(state).ids.map(id => buildTeam(state, id, teams[id]));
};

export const getIsLoaded = state => selectPagination(state).isLoaded;
export const getIsLoading = state => selectPagination(state).isLoading;
export const getIsAppending = state => selectPagination(state).append;
export const getTotalCount = state => selectPagination(state).totalCount;
export const getFilteredCount = state => selectPagination(state).filteredCount;
export const getQuery = state => selectPagination(state).query;
export const getPageNumber = state => selectPagination(state).currentPage;
export const getPageSize = state => selectPagination(state).pageSize;
export const getTotalPages = state => selectPagination(state).totalPages;
