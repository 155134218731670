import React, { Fragment } from "react";

import { Link } from "react-router-dom";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";
import ProtectedLayout from "@/layouts/ProtectedLayout";
import PageLoader from "@/components/PageLoader";
import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";
import DivisionsList, { useDivisionsList } from "@/components/DivisionsList";
import AbilityButton from "@/components/AbilityButton";
import { ExternalImportButton } from "@/components/ExternalImportButton";

function DivisionsIndex({ pageNumber, sort }) {
  const { seasonId, seasonIsLoading, seasonIsLoaded, seasonBreadcrumbs } = useCurrentSeasonContext();

  const { isListForbidden } = useDivisionsList();

  return (
    <ProtectedLayout condition={!isListForbidden} className="divisions-page">
      {seasonIsLoaded ? (
        <Fragment>
          <Head title="Divisions" />

          <Breadcrumbs items={seasonBreadcrumbs} />

          <TitleBar title="Divisions">
            <AbilityButton
              subject={{ type: "divisions", seasonId }}
              action="create"
              outline
              color="success"
              size="sm"
              tag={Link}
              to={`/seasons/${seasonId}/divisions/new`}
            >
              + New Division
            </AbilityButton>
            <ExternalImportButton />
          </TitleBar>

          <DivisionsList pageNumber={pageNumber} sort={sort} />
        </Fragment>
      ) : (
        <PageLoader isLoading={seasonIsLoading} />
      )}
    </ProtectedLayout>
  );
}

export default DivisionsIndex;
