import { all, takeLatest, select, put, actionChannel, take, race, call } from "redux-saga/effects";

import build from "redux-object";

import { loadDashboardNotes, createDashboardNote } from "@/lib/api/dashboardNotes";

import { changeGameNoteFormField } from "./actions";

import {
  gameNoteFormFieldValidationRoutine,
  gameNoteFormValidationRoutine,
  gameNoteFormSubmittingRoutine,
  gameNotesListLoadingRoutine
} from "./routines";

import { validateText, validate } from "./validations";

import { getGameNoteFormAttributes } from "./selectors";

import { gamesheetAPIRequest } from "../api/sagas";

function* validateFieldChangeSaga({ payload: { field, value } }) {
  yield put(gameNoteFormFieldValidationRoutine.request({ field, value }));

  let errors;

  switch (field) {
    case "text":
      errors = validateText(value);
      break;

    default:
      throw new Error(`Unknown form field ${field}`);
  }

  if (errors.length === 0) {
    yield put(gameNoteFormFieldValidationRoutine.success({ field, value }));
  } else {
    yield put(gameNoteFormFieldValidationRoutine.failure({ field, value, errors }));
  }

  yield put(gameNoteFormFieldValidationRoutine.fulfill({ field, value }));
}

function* validateFormSaga() {
  const attributes = yield select(getGameNoteFormAttributes);

  yield put(gameNoteFormValidationRoutine.request());

  const errors = validate(attributes);

  if (errors) {
    yield put(gameNoteFormValidationRoutine.failure({ errors }));
  } else {
    yield put(gameNoteFormValidationRoutine.success());
  }

  yield put(gameNoteFormValidationRoutine.fulfill());
}

function* createGameNoteSaga({ seasonId, gameId }) {
  const attributes = yield select(getGameNoteFormAttributes);

  const { data } = yield call(gamesheetAPIRequest, createDashboardNote, {
    seasonId,
    gameId,
    attributes
  });

  return (build(data, "dashboardNotes") || [])[0];
}

function* submitFormSaga({ payload }) {
  const { seasonId, gameId } = payload;

  yield put(gameNoteFormSubmittingRoutine.request());

  try {
    const validationSuccess = yield actionChannel(gameNoteFormValidationRoutine.SUCCESS);

    const validationFulfill = yield actionChannel(gameNoteFormValidationRoutine.FULFILL);

    yield put(gameNoteFormValidationRoutine());

    const { success } = yield race({
      success: take(validationSuccess),
      fulfill: take(validationFulfill)
    });

    if (!success) {
      yield put(gameNoteFormSubmittingRoutine.failure());

      return;
    }

    const gameNote = yield call(createGameNoteSaga, {
      seasonId,
      gameId
    });

    yield put(gameNoteFormSubmittingRoutine.success({ gameNote }));
  } catch (error) {
    if (error.response) {
      yield put(gameNoteFormSubmittingRoutine.failure({ response: error.response }));
    } else {
      yield put(gameNoteFormSubmittingRoutine.failure());
    }
  } finally {
    yield put(gameNoteFormSubmittingRoutine.fulfill());
  }
}

function* listLoadingSaga({ payload: { seasonId, gameId } }) {
  yield put(gameNotesListLoadingRoutine.request());

  try {
    const { data } = yield call(gamesheetAPIRequest, loadDashboardNotes, {
      seasonId,
      gameId
    });

    const gameNotes = build(data, "dashboardNotes") || [];

    yield put(gameNotesListLoadingRoutine.success({ gameNotes }));
  } catch (e) {
    yield put(gameNotesListLoadingRoutine.failure());
  } finally {
    yield put(gameNotesListLoadingRoutine.fulfill());
  }
}

export function* gameNotesFlow() {
  yield all([
    takeLatest(changeGameNoteFormField, validateFieldChangeSaga),
    takeLatest(gameNoteFormValidationRoutine.TRIGGER, validateFormSaga),
    takeLatest(gameNoteFormSubmittingRoutine.TRIGGER, submitFormSaga),
    takeLatest(gameNotesListLoadingRoutine.TRIGGER, listLoadingSaga)
  ]);
}
