import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Table } from "reactstrap";

class Periods extends PureComponent {
  static propTypes = {
    periods: PropTypes.arrayOf(
      PropTypes.shape({
        caption: PropTypes.string,
        length: PropTypes.number
      })
    )
  };

  static defaultProps = {
    periods: []
  };

  render() {
    const { periods } = this.props;

    return (
      <Table striped borderless size="sm">
        <thead>
          <tr>
            <th scope="col" colSpan="3">
              Length of Game
            </th>
          </tr>
        </thead>
        <tbody>
          {periods.map(({ caption, length }, index) => (
            <tr key={`period-${index + 1}`}>
              <th scope="row">{caption}</th>
              <td>{length}</td>
              <td>&nbsp;</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}

export default Periods;
