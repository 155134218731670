import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router";
import { config } from "../../../../../config";
import { getFreshToken } from "@/lib/api/getFreshToken";

export function useDivision() {
  // this standings url will be replaced and moved to a permanate location before this feature is released.

  const location = useLocation();
  const [divisions, setDivsions] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [divisionStandings, setDivisionStandings] = useState(null);
  const [calculatedStandings, setCalculatedStandings] = useState(null);
  const [dataToSubmit, setDataToSubmit] = useState(null);
  const [gameTypeFilter, setGameTypeFilter] = useState("overall");
  const [isLoading, setIsLoading] = useState(false);

  const gameTypeList = [
    { label: "All Game Types", searchTerm: "overall" },
    { label: "Regular Season", searchTerm: "regular_season" },
    { label: "Playoff", searchTerm: "playoff" },
    { label: "Exhibition", searchTerm: "exhibition" },
    { label: "Tournament", searchTerm: "tournament" },
  ];

  async function loadDivisions(isMounted) {
    let token = await getFreshToken();
    let regex = /([0-9])\w+/g;
    let seasonId = location.pathname.match(regex)[0];

    await axios
      .get(
        `${config.API_BASE_URL}seasons/${seasonId}/divisions?page%5Bnumber%5D=1&page%5Bsize%5D=100&sort=none`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data.data.map((value) => {
          return {
            name: value.attributes.title,
            id: value.id,
            hasOwnSettings: Object.keys(value.attributes.settings).length > 0,
          };
        });
      })
      .then((allDivisions) => {
        isMounted() && setDivsions(allDivisions);
      });
  }

  async function loadLiveStandings(isMounted) {
    let regex = /([0-9])\w+/g;
    let seasonId = location.pathname.match(regex)[0];
    let token = await getFreshToken();

    let url = `${config.BFF_API}/standings/v4?filter[seasons]=${seasonId}&filter[divisions]=${selectedDivision.id}`;
    if (gameTypeFilter.length > 0) {
      url += `&filter[gametype]=${gameTypeFilter}`;
    }

    setIsLoading(true);

    await axios
      .get(url, {
        headers: {
          authorization: `Bearer ${token}`,
          "X-Skip-Request-Cache": "yes",
        },
      })
      .then((res) => {
        if (!res.data || !res.data.data) {
          return [];
        }

        return res.data.data[0].standings.map((rank) => {
          return {
            ...rank.team,
            ...rank.stats,
            id: rank.id,
            rank: rank.rank,
            reason: rank.reason,
            teamName: rank.team.title,
            division: rank.division,
            gameType: rank.gameType,
            statHash: rank.statHash,
            stats: {
              ...rank.stats,
            },
            team: {
              ...rank.team,
            },
          };
        });
      })
      .then((allTeamsData) => {
        let dataToSubmit = allTeamsData.map((newData) => {
          return {
            id: newData.id,
            rank: newData.rank,
            rankReason: newData.reason,
            gameType: newData.gameType,
            divisionId: newData.division.id,
            teamId: newData.team.id,
          };
        });

        isMounted() && setDataToSubmit(dataToSubmit);
        isMounted() && setDivisionStandings(allTeamsData);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    let mounted = true;
    const isMounted = () => mounted;
    loadDivisions(isMounted);

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    const isMounted = () => mounted;

    if (selectedDivision && selectedDivision.id) {
      setDivisionStandings(null);
      loadLiveStandings(isMounted);
    }

    return () => {
      mounted = false;
    };
  }, [selectedDivision, gameTypeFilter]);

  return {
    divisions,
    setDivsions,
    loadDivisions,
    selectedDivision,
    setSelectedDivision,
    divisionStandings,
    setDivisionStandings,
    calculatedStandings,
    setCalculatedStandings,
    dataToSubmit,
    setDataToSubmit,
    gameTypeList,
    gameTypeFilter,
    setGameTypeFilter,
    isLoading,
    setIsLoading,
  };
}
