const VALID_HANDS = ["left", "right"];

export default function validateShotHand({ shotHand }) {
  switch (true) {
    case shotHand && !VALID_HANDS.includes(shotHand.toLowerCase()): {
      return [{ field: "shotHand", error: "invalid" }];
    }
    default: {
      return [];
    }
  }
}
