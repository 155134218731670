import { createRoutine } from "redux-saga-routines";

export const loadAccountRoutine = createRoutine("LOAD_ACCOUNT");

export const accountValidationRoutine = createRoutine("ACCOUNT_VALIDATION");
export const accountUpdateRoutine = createRoutine("ACCOUNT_UPDATE");

export const newPasswordValidationRoutine = createRoutine("NEW_PASSWORD_VALIDATION");

export const acceptInvitationValidationRoutine = createRoutine("ACCEPT_INVITATION_VALIDATION");
export const loadUserRoleTeamsRoutine = createRoutine("LOAD_USER_ROLE_TEAMS");
export const loadMergedTeamPreviewRoutine = createRoutine("LOAD_MERGED_TEAM_PREVIEW");
export const acceptInvitationRoutine = createRoutine("ACCEPT_INVITATION");
