import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Modal, ModalHeader, ModalBody } from "reactstrap";

import { getAccountIsLoading, getAccountIsLoaded } from "@/redux/account";

import PageLoader from "@/components/PageLoader";

import { useExportSeasonGamesCount, useExportSeasonGamesMeta } from "./hooks";

import Form from "./Form";

import "./ExportSeasonGamesModal.scss";

function ExportSeasonGamesModal({ isOpen, close, clear }) {
  const { gamesCount, gamesCountIsLoading, gamesCountIsLoaded } = useExportSeasonGamesCount(isOpen);

  const accountIsLoading = useSelector(getAccountIsLoading);
  const accountIsLoaded = useSelector(getAccountIsLoaded);

  const { isSubmitted } = useExportSeasonGamesMeta();

  return (
    <Modal className="export-season-games-modal" isOpen={isOpen} toggle={close} onClosed={clear} centered>
      <ModalHeader toggle={close}>Export Score Sheets</ModalHeader>
      {isSubmitted ? (
        <ModalBody className="export-season-games-modal">
          <p className="export-season-games-modal__check">
            <span className="export-season-games-modal__check__symbol">&not;</span>
          </p>
          <p>Your score sheets are being exported.</p>
          <p>
            Once finished, you’ll receive an email from <strong>no-reply@gamesheet.app</strong> with the link to
            download the requested score sheets.
          </p>
          <p>It may take a few minutes.</p>
        </ModalBody>
      ) : gamesCountIsLoaded && accountIsLoaded ? (
        <Form gamesCount={gamesCount} close={close} />
      ) : (
        <ModalBody>
          <PageLoader isLoading={gamesCountIsLoading || accountIsLoading} />
        </ModalBody>
      )}
    </Modal>
  );
}

ExportSeasonGamesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired
};

export default ExportSeasonGamesModal;
