import React from "react";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import TitleBar from "@/components/TitleBar";
import Breadcrumbs from "@/components/Breadcrumbs";
import Head from "@/components/Head";
import SeasonForm, { useSeasonFormClearing } from "@/components/SeasonForm";
import PageLoader from "@/components/PageLoader";
import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

function EditSeason() {
  const {
    seasonId,
    season,
    seasonBreadcrumbs,
    seasonIsLoaded,
    seasonIsLoading,
    seasonIsForbidden,
    seasonFormFields,
    seasonAssociationId,
    seasonLeagueId
  } = useCurrentSeasonContext();

  useSeasonFormClearing();


  return (
    <ProtectedLayout
      subject={{ type: "seasons", id: seasonId }}
      action="update"
      className="seasons-page"
      skip={!seasonIsLoaded && !seasonIsForbidden}
    >
      {seasonIsLoaded && <Head title={season.title} />}

      <Breadcrumbs items={seasonBreadcrumbs} noLastItemUrl />

      <TitleBar title="Edit Season" />

      {seasonIsLoaded ? (
        <SeasonForm
          initialValues={seasonFormFields}
          associationId={seasonAssociationId}
          leagueId={seasonLeagueId}
          seasonId={seasonId}
          sport={season.sport}
        />
      ) : (
        <PageLoader isLoading={seasonIsLoading} />
      )}
    </ProtectedLayout>
  );
}

export default EditSeason;
