import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { Input } from "reactstrap";

function PointsInput({ name, value, onChange }) {
  const changeValue = useCallback(
    ({ target: { name, value: pointsValue } }) => {
      const parsedValue = parseInt(pointsValue) || 0;

      onChange({ name, value: parsedValue });
    },
    [onChange]
  );

  return (
    <Input
      name={name}
      type="number"
      min={0}
      onChange={changeValue}
      onKeyUp={changeValue}
      value={value > 0 ? value : ""}
      placeholder={value === 0 ? "0" : undefined}
    />
  );
}

PointsInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

export default PointsInput;
