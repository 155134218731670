import { createGetService, createPostService, createPatchService, createDeleteService } from "./serviceMaker";

const associationUrlByIdentity = ({ identity }) => ({
  url: `/associations/${identity.id}`
});

export const loadAssociations = createGetService("/associations");
export const createAssociation = createPostService("/associations");
export const loadAssociation = createGetService(associationUrlByIdentity);
export const updateAssociation = createPatchService(associationUrlByIdentity);
export const deleteAssociation = createDeleteService(associationUrlByIdentity);

export class Association {
  constructor({ id }) {
    this.id = id;
  }
}
