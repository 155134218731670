import React, { Fragment, useCallback, useState } from "react";
import PropTypes from "prop-types";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import AbilityButton from "@/components/AbilityButton";

import "./ConfirmSeasonUnarchivingModal.scss";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { loadingRoutine } from "@/redux/seasonsList";
import useCurrentLeagueContext from "@/hooks/useCurrentLeagueContext";
import useCurrentLeague from "@/hooks/useCurrentLeague";

function ConfirmSeasonUnarchivingModal(props) {
  
  const dispatch = useDispatch();
  const { leagueId } = useCurrentLeagueContext()
  const { leagueAssociationId: associationId } = useCurrentLeague(leagueId)
  const { seasonId, seasonTitle, disabled, isUnarchiving, onConfirm: _onConfirm, buttonClassname } = props;
  const [isModalOpen, setModalIsOpen] = useState(false);

  const onConfirm = useCallback(async () => {
    
    _onConfirm()
    .then(async () => {
      await new Promise(r => setTimeout(r, 500)); // give the unarchive event a chance to process
      toast.success("Season unarchived")
      setModalIsOpen(false)
      dispatch(loadingRoutine({ associationId, leagueId }))
    })

  }, [_onConfirm])

  const toggleModal = useCallback(() => !isUnarchiving && !disabled && setModalIsOpen(!isModalOpen), [
    setModalIsOpen,
    isUnarchiving,
    disabled,
    isModalOpen
  ]);

  const btnTitle = isUnarchiving ? `Unarchiving Season…` : `Yes, Unarchive Season`;

  return (
    <Fragment>
      <AbilityButton
        subject={{ type: "seasons", id: seasonId }}
        action="update"
        data-testid={`unarchive-season-${seasonId}`}
        className={buttonClassname}
        type="button"
        color="primary"
        size="sm"
        outline
        onClick={toggleModal}
        disabled={disabled || isUnarchiving}
      >
        Unarchive
      </AbilityButton>

      <Modal className="season-unarchiving-modal" isOpen={isModalOpen} toggle={toggleModal} centered>
        <ModalHeader>Confirm Season Unarchiving</ModalHeader>
        <ModalBody>
          <p>
            The season <strong>{seasonTitle}</strong> will be unarchived and it’s content will be available for view.
            The season can be archived again <span className="text-nowrap">at any time</span>.
          </p>
          <p>Are you sure you want to continue?</p>
        </ModalBody>

        <ModalFooter>
          <div className="d-flex w-100">
            <div className="mr-auto">
              <Button type="button" color="secondary" outline onClick={toggleModal} disabled={isUnarchiving}>
                Cancel
              </Button>
            </div>

            <div>
              <Button type="button" color="success" onClick={onConfirm} disabled={isUnarchiving}>
                {btnTitle}
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}

ConfirmSeasonUnarchivingModal.propTypes = {
  seasonId: PropTypes.string.isRequired,
  seasonTitle: PropTypes.string.isRequired,
  isUnarchiving: PropTypes.bool.isRequired,
  buttonClassname: PropTypes.string,
  onConfirm: PropTypes.func,
  disabled: PropTypes.bool
};

ConfirmSeasonUnarchivingModal.defaultProps = {
  buttonClassname: null,
  disabled: false
};

export default ConfirmSeasonUnarchivingModal;
