export const ImportStatus = {
    PENDING: "pending",
    PARSING: "parsing",
    PARSE_ERROR: "parsingError",
    PARSE_SUCCESS: "parsingSuccess",
    IMPORTING: "importing",
    IMPORT_ERROR: "importError",
    IMPORT_SUCCESS: "importSuccess"
}

export const ImportStatusValues = Object.values(ImportStatus);