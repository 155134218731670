import { useCallback } from "react";
import validate from "validate.js";
import _toPath from "lodash/toPath";

export default function useValidateField({ constraints, values }) {
  return useCallback(
    ({ name, value }) => {
      const res = validate({ ...values, [name]: value }, constraints) || {};

      const errors = Object.entries(res).reduce((result, [key, value]) => {
        const [name, ...rest] = _toPath(key);

        return {
          ...result,
          [name]:
            rest.length > 0 ? [...(result[name] || []), [rest.join("."), value]] : [...(result[name] || []), ...value]
        };
      }, {});

      return errors[name] || [];
    },
    [constraints, values]
  );
}
