import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { FormGroup, Label, Button } from "reactstrap";

import FormItem from "@/components/FormItem";
import ContentSection from "@/components/ContentSection";
import Shift from "@/components/GameForm/components/GoalieShift";

class GoalieShiftsFieldset extends PureComponent {
  static propTypes = {
    team: PropTypes.oneOf(["visitor", "home"]).isRequired,
    teamTitle: PropTypes.string.isRequired,
    shifts: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })),
    changeShift: PropTypes.func,
    removeShift: PropTypes.func,
    addShift: PropTypes.func
  };

  static defaultProps = {
    shifts: [],
    changeShift() {},
    removeShift() {},
    addShift() {}
  };

  addShift = () => {
    const { team, addShift } = this.props;

    addShift(team);
  };

  render() {
    const { team, teamTitle, shifts, changeShift, removeShift } = this.props;

    return (
      <ContentSection title={`${team.toUpperCase()} – ${teamTitle}`}>
        {shifts.length > 0 && (
          <FormItem>
            <FormGroup className="col-md-1">
              <Label>Period</Label>
            </FormGroup>

            <FormGroup className="col-md-9">
              <Label>Goalie</Label>
            </FormGroup>

            <FormGroup className="col-md-1">
              <Label>Time on</Label>
            </FormGroup>
          </FormItem>
        )}

        {shifts.map(({ id, ...data }) => (
          <Shift key={id} team={team} id={id} {...data} onChange={changeShift} onRemove={removeShift} />
        ))}

        <FormItem>
          <Button block outline color="success" onClick={this.addShift}>
            Add goalie shift
          </Button>
        </FormItem>
      </ContentSection>
    );
  }
}

export default GoalieShiftsFieldset;
