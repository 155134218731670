import { connect } from "react-redux";

import {
  getListOffset,
  getListHasMore,
  getListIsLoaded,
  getListIsLoading,
  getListIsAppending,
  getListTotalCount,
  getListFilteredCount
} from "@/redux/teamRosterExtender/selectors";

import List from "./List";

const mapStateToProps = state => ({
  countMembers: getListOffset(state),
  hasMore: getListHasMore(state),
  isLoaded: getListIsLoaded(state),
  isLoading: getListIsLoading(state),
  isAppending: getListIsAppending(state),
  totalCount: getListTotalCount(state),
  filteredCount: getListFilteredCount(state)
});

const mapDispatchToProps = (dispatch, { loadingAction, seasonId }) => ({
  loadList: ({ append }) => dispatch(loadingAction({ seasonId, append }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List);
