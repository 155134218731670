import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { deletingRoutine, getMeta } from "@/redux/associationForm";

export default function useAssociationFormDeleting(associationId) {
  const dispatch = useDispatch();
  const { isDeleting } = useSelector(getMeta);

  const deleteAssociation = useCallback(
    () => {
      if (associationId) {
        dispatch(deletingRoutine({ associationId }));
      }
    },
    [dispatch, associationId]
  );

  return {
    isDeleting,
    deleteAssociation
  };
}
