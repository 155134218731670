import React from "react";
import { Button } from "reactstrap";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import TitleBar from "@/components/TitleBar";
import Breadcrumbs from "@/components/Breadcrumbs";
import Head from "@/components/Head";
import SeasonForm, { useSeasonFormClearing } from "@/components/SeasonForm";
import PageLoader from "@/components/PageLoader";
import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";
import { CloneSeasonForm } from "@/components/CloneSeasonForm";

function CloneSeason() {
    const {
        seasonId,
        season,
        seasonBreadcrumbs,
        seasonIsLoaded,
        seasonIsLoading,
        seasonIsForbidden
    } = useCurrentSeasonContext();

    useSeasonFormClearing();

    // TODO: permissions
    return (
        <ProtectedLayout
            subject={{ type: "seasons", id: seasonId }}
            action="update"
            className="seasons-page"
            skip={!seasonIsLoaded && !seasonIsForbidden}
        >
            {seasonIsLoaded && <Head title={`Clone ${season.title}`} />}

            <Breadcrumbs items={seasonBreadcrumbs} noLastItemUrl />

            <TitleBar title="Clone Season" />

            {seasonIsLoaded ? (
                <CloneSeasonForm />
            ) : (
                <PageLoader isLoading={seasonIsLoading} />
            )}
        </ProtectedLayout>
    );
}

export default CloneSeason;
