import React from "react";
import PropTypes from "prop-types";

import ConfirmDeletionModal from "@/components/ConfirmDeletionModal";
import AbilityLink from "@/components/AbilityLink";

function Modal({ member, teams, ...rest }) {
  const { fullName } = member;

  return (
    <ConfirmDeletionModal resourceType={member.type} resourceTitle={fullName} {...rest}>
      <p>
        The <span className="text-capitalize">{member.type}</span> <strong>{fullName}</strong> is currently{" "}
        {teams.length > 0 ? "assigned to the following teams:" : "not assigned to any teams."}
      </p>

      {teams.length > 0 && (
        <ul>
          {teams.map(({ id, title, seasonId }) => (
            <li key={id}>
              <AbilityLink
                to={`/seasons/${seasonId}/teams/${id}`}
                subject={{ type: "teams", id }}
                action="read"
                fallback={title}
                target="_blank"
              >
                {title}
              </AbilityLink>
            </li>
          ))}
        </ul>
      )}

      <p>
        Are you sure you want to delete <strong>{fullName}</strong>
        {teams.length > 0 ? " and remove them from all of the above listed teams?" : "?"}
      </p>
    </ConfirmDeletionModal>
  );
}

Modal.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired
  }).isRequired,
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      seasonId: PropTypes.string.isRequired
    })
  ).isRequired
};

export default Modal;
