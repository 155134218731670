import React from "react";
import PropTypes from "prop-types";

import LoggedInRoute from "@/containers/LoggedInRoute";
import { Switch } from "react-router-dom";

import useCurrentIpadKey from "@/hooks/useCurrentIpadKey";
import useCurrentIpadKeyClearing from "@/hooks/useCurrentIpadKeyClearing";
import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";
import CurrentIpadKeyContext from "@/contexts/CurrentIpadKeyContext";

import EditIpadKey from "./pages/EditIpadKey";

function IpadKeyScope({ match: { url, params } }) {
  const { keyId } = params;

  const { seasonId } = useCurrentSeasonContext();
  const currentIpadKey = useCurrentIpadKey({ seasonId, keyId });

  useCurrentIpadKeyClearing();

  return (
    <CurrentIpadKeyContext.Provider value={{ ...currentIpadKey, keyId }}>
      <Switch>
        <LoggedInRoute path={`${url}/edit`} component={EditIpadKey} />
      </Switch>
    </CurrentIpadKeyContext.Provider>
  );
}

IpadKeyScope.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      keyId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default IpadKeyScope;
