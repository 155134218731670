import { useRecoilValue } from 'recoil';

import {
    recordsState,
} from "@/components-16.8/RefereeImportWizard/state";

export function useImportSuccess() {

    const records = useRecoilValue(recordsState);

    return {
        refereeCount: records.length
    };

}