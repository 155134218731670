import { useSelector } from "react-redux";

import {
  getIsLoading,
  getIsLoaded,
  getIsAppending,
  getTotalCount,
  getFilteredCount,
  getPageNumber,
  getTotalPages
} from "@/redux/teamList/selectors";

import { getCopyTeamsList } from "@/redux/copyTeams/selectors";

import { getIsDirty } from "@/redux/teamFilter/selectors";

export default function useUserListSelector() {
  const teams = useSelector(getCopyTeamsList);
  const isLoading = useSelector(getIsLoading);
  const isLoaded = useSelector(getIsLoaded);
  const isAppending = useSelector(getIsAppending);
  const totalCount = useSelector(getTotalCount);
  const filteredCount = useSelector(getFilteredCount);
  const totalPages = useSelector(getTotalPages);
  const pageNumber = useSelector(getPageNumber);
  const filterIsDirty = useSelector(getIsDirty);

  return {
    teams,
    isLoading,
    isLoaded,
    isAppending,
    totalCount,
    filteredCount,
    pageNumber,
    totalPages,
    filterIsDirty
  };
}
