import { combineReducers } from "redux";

import createOffsetPaginationReducer from "@/redux/common/pagination/createOffsetPaginationReducer";

import createDataReducer from "./list/createDataReducer";
import createSearchReducer from "./list/createSearchReducer";
import createLeaguesReducer from "./list/createLeaguesReducer";

export default function createListReducer({ actions, loadingRoutine, memberType }) {
  const pagination = createOffsetPaginationReducer(loadingRoutine, actions.list.clear);

  return combineReducers({
    data: createDataReducer({ actions, loadingRoutine, memberType }),
    search: createSearchReducer(actions),
    leagues: createLeaguesReducer({
      actions,
      loadingRoutine,
      memberType
    }),
    pagination
  });
}
