import { call } from "redux-saga/effects";
import build from "redux-object";
import _isArray from "lodash/isArray";

import { ensureTokenIsFreshSaga } from "@/redux/token/sagas";
import { addAbilityRulesSaga } from "@/redux/ability/sagas";

export function* gamesheetAPIRequest(service, payload, skipAbilitiesUpdate = false) {
  const token = yield call(ensureTokenIsFreshSaga);

  const result = yield call(service, { ...payload, token });

  if (!skipAbilitiesUpdate) {
    yield call(addAbilityRulesSaga, result.data);
  }

  return result;
}

export const apiRequest = gamesheetAPIRequest;

function buildList(data, resourceTypes) {
  return resourceTypes.reduce(
    (result, objectName) => ({
      ...result,
      [objectName]: build(data, objectName, null, { includeType: true })
    }),
    {}
  );
}

export function* fetchList(resourceType, service, payload) {
  const { data, rawData, meta = {} } = yield call(apiRequest, service, payload);
  const resources = _isArray(resourceType)
    ? buildList(data, resourceType)
    : {
        [resourceType]: build(data, resourceType, null, { includeType: true })
      };
  const ids = rawData.map(({ id }) => id);
  const totalCount = meta["total-count"] || 0;
  const filteredCount = meta["filtered-count"] || 0;
  const totalPages = meta["total-pages"] || 0;
  const result = {
    data,
    ids,
    totalCount,
    totalPages,
    filteredCount,
    meta
  };
  return (_isArray(resourceType) ? resourceType : [resourceType]).reduce(
    (result, objectName) => ({
      ...result,
      [objectName]: resources[objectName] || []
    }),
    result
  );
}

export function* fetchOne(identity, service, { skipAbilitiesUpdate = false, ...payload } = {}) {
  const { type, id } = identity;
  const { data } = yield call(
    apiRequest,
    service,
    {
      identity,
      id,
      ...payload
    },
    skipAbilitiesUpdate
  );

  const resource = id ? build(data, type, id) : (build(data, type) || [])[0];

  return [resource];
}
