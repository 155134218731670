import { createActions } from "redux-actions";

const { invitationForm } = createActions({
  INVITATION_FORM: {
    ADD_ROLE: undefined,
    CHANGE_ROLE: undefined,
    REMOVE_ROLE: undefined,
    CHANGE_FIELD: undefined,
    CLEAR: undefined,
    RESET: undefined
  }
});

export default invitationForm;
