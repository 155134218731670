import createMembersFlow from "./createMembersFlow";

import {
  validateFirstName,
  validateLastName,
  validateExternalId,
  validateCoachPosition,
  validateCoach
} from "../validations";

import {
  coachFieldValidatingRoutine,
  coachValidatingRoutine,
  coachSubmittingRoutine,
  coachSavingRoutine,
  coachRemovingRoutine
} from "../routines";

import actions from "../actions";

import { getAllCoachAttributes, getRosterCoachAttributes, getSeasonCoachAttributes } from "../selectors";

import { createCoach, updateCoach } from "@/lib/api/coaches";

export default createMembersFlow("coaches", {
  validators: {
    firstName: validateFirstName,
    lastName: validateLastName,
    externalId: validateExternalId,
    position: validateCoachPosition
  },
  validate: validateCoach,
  routines: {
    fieldValidating: coachFieldValidatingRoutine,
    validating: coachValidatingRoutine,
    submitting: coachSubmittingRoutine,
    saving: coachSavingRoutine,
    removing: coachRemovingRoutine
  },
  actions: {
    changeField: actions.coachesForm.changeField
  },
  selectors: {
    getAllMemberAttributes: getAllCoachAttributes,
    getRosterMemberAttributes: getRosterCoachAttributes,
    getSeasonMemberAttributes: getSeasonCoachAttributes
  },
  services: {
    createMember: createCoach,
    updateMember: updateCoach
  }
});
