import React, { PureComponent } from "react";

import PageLoader from "@/components/PageLoader";

import "./Progress.scss";

class PlayersListMergeToolProgress extends PureComponent {
  render() {
    return (
      <div className="players-list__merge-tool__progress">
        <h1>Merging players. Please wait…</h1>
        <PageLoader isLoading={true} />
      </div>
    );
  }
}

export default PlayersListMergeToolProgress;
