import React from "react";
import PropTypes from "prop-types";

import { Switch } from "react-router-dom";

import LoggedInRoute from "@/containers/LoggedInRoute";

import { useDivisionsListLoading, PAGE_SIZE } from "@/components/DivisionsList";

import NewDivision from "./pages/NewDivision";
import DivisionsIndex from "./pages/DivisionsIndex";
import DivisionScope from "./DivisionScope";

function Divisions({ match: { url } }) {
  const { pageNumber, sort } = useDivisionsListLoading({ pageSize: PAGE_SIZE });

  return (
    <Switch>
      <LoggedInRoute path={`${url}/new`} component={NewDivision} />
      <LoggedInRoute path={`${url}/:divisionId`} component={DivisionScope} />

      <LoggedInRoute path="" component={DivisionsIndex} pageNumber={pageNumber} sort={sort} />
    </Switch>
  );
}

Divisions.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired
};

export default Divisions;
