import { connect } from "react-redux";

import GameForm from "./GameForm";

import {
  getGameFormFlags,
  getGameFormFields,
  getGameFormIsWorking,
  getGameFormIsDeleting,
  getGameFormGameCategories,
  getGameFormError,
  getFppEnabled,
  getCanSubmitForm,
  getIsPristine
} from "@/redux/gameForm/selectors";

import gameFormActions from "@/redux/gameForm/actions";

import { submitGameFormRoutine, deleteGameFormRoutine } from "@/redux/gameForm/routines";

const mapStateToProps = state => ({
  flags: getGameFormFlags(state),
  fields: getGameFormFields(state),
  categories: getGameFormGameCategories(state),
  error: getGameFormError(state),
  isWorking: getGameFormIsWorking(state),
  isDeleting: getGameFormIsDeleting(state),
  fppEnabled: getFppEnabled(state),
  canSubmit: getCanSubmitForm(state),
  isPristine: getIsPristine(state)
});

const mapDispatchToProps = dispatch => ({
  changeField: payload => dispatch(gameFormActions.changeField(payload)),
  submit: payload => dispatch(submitGameFormRoutine(payload)),
  deleteGame: payload => dispatch(deleteGameFormRoutine(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GameForm);
