import React from "react";

export const WhistleIcon = ({ color = "#36383D" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 38" fill={color}>
    <path
      d="M3.42167 4.86706L6.0757 2.93316L10.373 9.13572L7.719 11.0696L3.42167 4.86706ZM-0.86532 26.5475L3.85991 23.6701C4.01641 19.895 5.8521 16.2826 8.79924 13.9045L14.1042 21.6244L16.8721 18.8937L12.5922 11.8236L34.2654 2.70594L39.3928 10.602L28.1157 21.7472C28.5309 23.6979 28.5124 25.7802 28.0667 27.5676C26.4206 34.1697 19.8279 38.4448 13.383 36.8379C10.6949 36.1677 8.36696 34.3904 6.7333 32.1701L3.77212 33.1254L-0.86532 26.5475ZM13.0273 0.831407L16.1711 1.61526L14.9494 6.51526L11.8056 5.7314L13.0273 0.831407Z"
      fill={color}
    />
  </svg>
);

export const EyeIcon = ({ color = "#36383D" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 24" fill={color}>
    <g clipPath="url(#clip0_1913_15294)">
      <path
        d="M17.3535 12C17.3535 12.9946 16.9395 13.9484 16.2024 14.6517C15.4654 15.3549 14.4657 15.75 13.4233 15.75C12.381 15.75 11.3813 15.3549 10.6443 14.6517C9.90721 13.9484 9.49313 12.9946 9.49313 12C9.49313 11.0054 9.90721 10.0516 10.6443 9.34835C11.3813 8.64509 12.381 8.25 13.4233 8.25C14.4657 8.25 15.4654 8.64509 16.2024 9.34835C16.9395 10.0516 17.3535 11.0054 17.3535 12Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.84668 12C0.84668 12 5.56293 3.75 13.4233 3.75C21.2838 3.75 26 12 26 12C26 12 21.2838 20.25 13.4233 20.25C5.56293 20.25 0.84668 12 0.84668 12ZM13.4233 17.25C14.8826 17.25 16.2822 16.6969 17.314 15.7123C18.3459 14.7277 18.9256 13.3924 18.9256 12C18.9256 10.6076 18.3459 9.27226 17.314 8.28769C16.2822 7.30312 14.8826 6.75 13.4233 6.75C11.964 6.75 10.5645 7.30312 9.53263 8.28769C8.50075 9.27226 7.92105 10.6076 7.92105 12C7.92105 13.3924 8.50075 14.7277 9.53263 15.7123C10.5645 16.6969 11.964 17.25 13.4233 17.25Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_1913_15294">
        <rect
          width="25.1533"
          height="24"
          fill="white"
          transform="translate(0.84668)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const CheckmarkIcon = ({ color = "#36383d" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 20">
      <path
        d="M20.2424 0.639054C20.6539 0.231165 21.2094 0.00160693 21.7888 8.40439e-06C22.3682 -0.00159012 22.9249 0.224899 23.3386 0.630512C23.7523 1.03612 23.9898 1.58827 23.9997 2.16757C24.0096 2.74687 23.791 3.30679 23.3914 3.72626L11.6541 18.3979C11.4524 18.6151 11.2089 18.7895 10.9383 18.9105C10.6676 19.0316 10.3753 19.0968 10.0789 19.1023C9.78246 19.1078 9.48796 19.0534 9.21302 18.9425C8.93807 18.8316 8.68831 18.6663 8.47868 18.4567L0.70185 10.6769C0.485197 10.475 0.311424 10.2316 0.1909 9.96107C0.0703752 9.69058 0.00556728 9.39857 0.00034318 9.10249C-0.00488092 8.8064 0.0495859 8.51229 0.160493 8.23771C0.271401 7.96314 0.436477 7.71371 0.645874 7.50431C0.855272 7.29491 1.1047 7.12984 1.37928 7.01893C1.65386 6.90802 1.94797 6.85355 2.24405 6.85878C2.54014 6.864 2.83214 6.92881 3.10264 7.04934C3.37313 7.16986 3.61658 7.34363 3.81846 7.56029L9.97524 13.7141L20.1866 0.703739C20.2048 0.680963 20.2244 0.659358 20.2454 0.639054H20.2424Z"
        fill={color}
      />
    </svg>
  );
};

export const CloseIcon = ({ color = "#FEC307" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27">
      <path
        d="M5.41422 4.58582L22.4142 21.5858"
        color={color}
        style={{ stroke: `${color}`, strokeWidth: 4 }}
      />
      <path
        d="M22.4142 4.58582L5.41422 21.5858"
        color={color}
        style={{ stroke: `${color}`, strokeWidth: 4 }}
      />
    </svg>
  );
};
