import { all, takeLatest, call, put, select } from "redux-saga/effects";
import axios from 'axios';

import { createPassword, updatePassword } from "@/lib/api/password";
import { userExistsInPostgres, setUsersFirebaseUid } from "@/lib/api/users";

import { firebase } from "@/firebase";
import * as FAuth from "firebase/auth";
import { getFirebaseUser, getAccountAttributes } from "@/redux/account/selectors";
import { randomString } from "@/lib/core/randomString";
import { config } from "../../config";
import { ensureTokenIsFreshSaga } from "@/redux/token/sagas";

import {
  resetPasswordRoutine,
  createNewPasswordRoutine,
  validateNewPasswordRoutine,
  updatePasswordRoutine,
  validatePasswordRoutine
} from "./routines";

const createUserAndReset = async (email) => {
  try {
    const exists = await userExistsInPostgres(email);
    if (!exists) {
      return `No account found with email "${email}"`;
    }

    const userCredential = await firebase.auth().createUserWithEmailAndPassword(
      email,
      randomString(32)
    );

    await setUsersFirebaseUid(email, userCredential.user.uid);

    await firebase.auth().sendPasswordResetEmail(email);
    return "";
  } catch (error) {
    return error.toString();
  }
}

function* resetPasswordSaga({ payload }) {
  yield put(resetPasswordRoutine.request());

  try {
    yield call(() => firebase.auth().sendPasswordResetEmail(payload.email))

    yield put(resetPasswordRoutine.success());
  } catch (error) {
    if ('code' in error && error.code === 'auth/user-not-found') {
      const anotherError = yield call(() => createUserAndReset(payload.email));
      if (anotherError === "") {
        yield put(resetPasswordRoutine.success());
      } else {
        yield put(resetPasswordRoutine.failure(anotherError));
      }
    } else {
      yield put(resetPasswordRoutine.failure("Something went wrong. Please try again"));
    }
  } finally {
    yield put(resetPasswordRoutine.fulfill());
  }
}

function* validateNewPasswordFormSaga({ payload }) {
  const { password, passwordConfirmation } = payload;
  const errors = {};

  if (password.length === 0) {
    errors.password = "Password can't be blank";
  } else if (password.length < 8) {
    errors.password = "Password should be at least 8 characters long.";
  }

  if (password.length > 0 && password !== passwordConfirmation) {
    errors.passwordConfirmation = "Passwords don't match";
  }

  if (Object.keys(errors).length === 0) {
    yield put(validateNewPasswordRoutine.success());
    yield put(createNewPasswordRoutine(payload));
  } else {
    yield put(validateNewPasswordRoutine.failure({ errors }));
  }
}

function* createNewPasswordSaga({ payload }) {
  yield put(createNewPasswordRoutine.request());

  try {
    yield call(createPassword, payload);

    yield put(createNewPasswordRoutine.success());
  } catch ({ response }) {
    switch (response.status) {
      case 400:
        yield put(createNewPasswordRoutine.failure({ errors: response.data.errors }));
        break;
      default:
        yield put(createNewPasswordRoutine.failure({ error: response.statusText }));
    }
  } finally {
    yield put(createNewPasswordRoutine.fulfill());
  }
}

function* validatePasswordFormSaga({ payload }) {
  const { currentPassword, password, passwordConfirmation } = payload;
  const errors = {};

  if (currentPassword.length === 0) {
    errors.currentPassword = "Current password can't be blank";
  }

  if (password.length === 0) {
    errors.password = "Password can't be blank";
  } else if (password.length < 8) {
    errors.password = "Password should be at least 8 characters long.";
  }

  if (password.length > 0 && password !== passwordConfirmation) {
    errors.passwordConfirmation = "Passwords don't match";
  }

  if (Object.keys(errors).length === 0) {
    yield put(validatePasswordRoutine.success());
    yield put(updatePasswordRoutine(payload));
  } else {
    yield put(validatePasswordRoutine.failure({ errors }));
  }
}

function* updatePasswordSaga({ payload }) {
  yield put(updatePasswordRoutine.request());

  try {
    // make sure current password is correct
    const user = yield select(getFirebaseUser);
    if (!user) {
      throw new Error("No user, please logout then back in");
    }

    const credential = FAuth.EmailAuthProvider.credential(
      user.email,
      payload.currentPassword,
    );
    yield call(() => user.reauthenticateWithCredential(credential));

    // update password
    const attributes = yield select(getAccountAttributes);
    const accessToken = yield call(ensureTokenIsFreshSaga);
    const url = `${config.AUTH_GATEWAY}/auth/v4/account`;
    yield call(
      () => axios.post(
        url,
        {
          firstName: attributes.firstName,
          lastName: attributes.lastName,
          email: attributes.email,
          password: payload.password
        },
        {
          headers: {
            "Authorization": `Bearer ${accessToken}`,
          },
        },
      )
    );

    yield put(updatePasswordRoutine.success());
  } catch (error) {
    if ('code' in error) {
      switch (error.code) {
        case "auth/requires-recent-login":
          yield put(updatePasswordRoutine.failure({ error: "Please logout then back in first" }));
          return;
        case "auth/weak-password":
          yield put(updatePasswordRoutine.failure({ error: "Password Too Weak" }));
          return;
        case "auth/wrong-password":
          yield put(updatePasswordRoutine.failure({ error: "Current Password is Incorrect" }));
          return;
      }
    } else if ('response' in error && 'status' in error.response) {
      switch (error.response.status) {
        case 400:
          yield put(updatePasswordRoutine.failure({ errors: error.response.data.errors }));
          return;
      }
    }

    yield put(
      updatePasswordRoutine.failure({
        error: "Unable to update password"
      })
    );
  } finally {
    yield put(updatePasswordRoutine.fulfill());
  }
}

export function* passwordFlow() {
  yield all([
    takeLatest(resetPasswordRoutine.TRIGGER, resetPasswordSaga),
    takeLatest(validateNewPasswordRoutine.TRIGGER, validateNewPasswordFormSaga),
    takeLatest(createNewPasswordRoutine.TRIGGER, createNewPasswordSaga),
    takeLatest(validatePasswordRoutine.TRIGGER, validatePasswordFormSaga),
    takeLatest(updatePasswordRoutine.TRIGGER, updatePasswordSaga)
  ]);
}
