import { handleActions } from "redux-actions";

import { BigTeamInvitationListLoadingRoutine as loadingRoutine } from "../routines";

import actions from "../actions";

const initialState = {
  isLoaded: false,
  isLoading: false,
  errors: null,
  ids: [],
  totalCount: 0,
  filteredCount: 0
};

const trigger = () => Object.assign({}, initialState);

const request = state => Object.assign({}, state, { isLoading: true });

const success = (state, { payload }) =>
  Object.assign({}, state, {
    isLoaded: true,
    ids: payload.ids,
    totalCount: payload.totalCount
  });

const failure = (state, { payload }) =>
  Object.assign({}, state, {
    isLoaded: false,
    errors: payload.errors
  });

const fulfill = state => Object.assign({}, state, { isLoading: false });

export default handleActions(
  {
    [loadingRoutine.TRIGGER]: trigger,
    [loadingRoutine.REQUEST]: request,
    [loadingRoutine.SUCCESS]: success,
    [loadingRoutine.FAILURE]: failure,
    [loadingRoutine.FULFILL]: fulfill,
    [actions.list.clear]: () => ({ ...initialState })
  },
  initialState
);
