export const DEFAULT_VALUES = {
  name: "",
  email: ""
};

export const FIELDS_MAPPING = {
  name: "Player Name",
  email: "Email"
};

export const GAME_EXPIRATION_DAYS = 14;
