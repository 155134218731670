import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Table } from "reactstrap";

import TeamRosterExtenderTableMember from "./Member";

class MembersTable extends PureComponent {
  static propTypes = {
    members: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired
      })
    ),
    teamIsUpdating: PropTypes.bool,
    addMemberToRoster: PropTypes.func
  };

  static defaultProps = {
    members: [],
    teamIsUpdating: false,
    addMemberToRoster() {}
  };

  render() {
    const { members, addMemberToRoster, teamIsUpdating } = this.props;

    return (
      <Table striped borderless>
        <thead>
          <tr>
            <th scope="col">Full name</th>
            <th scope="col">External ID</th>
            <th scope="col">Teams</th>
            <th scope="col">
              <span className="visuallyhidden">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {members.map(({ id, ...rest }) => (
            <TeamRosterExtenderTableMember
              addMemberToRoster={addMemberToRoster}
              key={id}
              id={id}
              disabled={teamIsUpdating}
              {...rest}
            />
          ))}
        </tbody>
      </Table>
    );
  }
}

export default MembersTable;
