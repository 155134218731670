import { connect } from "react-redux";

import {
  getRefereesListIsLoading,
  getRefereesListIsLoaded,
  getRefereesList,
  getRefereesListTotalCount,
  getRefereesListFilteredCount,
  getRefereesListHasMore,
  getRefereesListIsAppending,
  getRefereesListSearchCurrentQuery,
  getRefereesMergeToolStatus,
  getRefereesMergeToolIsEnabled
} from "@/redux/referees/selectors";

import { loadRefereesListRoutine } from "@/redux/referees/routines";
import { refereesActions } from "@/redux/referees/actions";

import RefereesList from "./List";

const mapStateToProps = state => ({
  isLoading: getRefereesListIsLoading(state),
  isLoaded: getRefereesListIsLoaded(state),
  referees: getRefereesList(state),
  totalCount: getRefereesListTotalCount(state),
  filteredCount: getRefereesListFilteredCount(state),
  currentQuery: getRefereesListSearchCurrentQuery(state),
  hasMore: getRefereesListHasMore(state),
  mergeToolStatus: getRefereesMergeToolStatus(state),
  isMergeToolEnabled: getRefereesMergeToolIsEnabled(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadMoreReferees: () =>
    dispatch(
      loadRefereesListRoutine({
        seasonId: ownProps.seasonId,
        append: true
      })
    ),
  enableMergeTool: () =>
    dispatch(
      refereesActions.list.mergeTool.enable({
        seasonId: ownProps.seasonId
      })
    ),
  selectForMerge: ids => dispatch(refereesActions.list.mergeTool.selectReferees(ids)),
  disableMergeTool: () => dispatch(refereesActions.list.mergeTool.disable()),
  removeReferee: id => dispatch(refereesActions.list.remove(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RefereesList);
