import validations from "./validations";

function validateRecord(record, validationOptions) {
  return validations.reduce((allErrors, validation) => {
    const errors = validation(record, validationOptions);
    return errors.length > 0 ? [...allErrors, ...errors] : allErrors;
  }, []);
}

export default function validate(records, validationOptions) {
  return records.map(record => ({
    ...record,
    validationErrors: validateRecord(record, validationOptions)
  }));
}
