import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getMeta, getAssociation, loadingRoutine } from "@/redux/currentAssociation";

export default function useCurrentAssociation(associationId) {
  const dispatch = useDispatch();

  const association = useSelector(getAssociation);

  const {
    isLoaded: associationIsLoaded,
    isLoading: associationIsLoading,
    isForbidden: associationIsForbidden,
    isFailed: associationIsFailed
  } = useSelector(getMeta);

  const associationBreadcrumbs = associationIsLoaded
    ? [
        { title: "Associations", url: "/associations" },
        {
          title: association.title,
          url: `/associations/${associationId}/leagues`
        }
      ]
    : [];

  useEffect(
    () => {
      if (!associationIsLoaded && !associationIsLoading && !associationIsFailed) {
        dispatch(loadingRoutine.trigger({ associationId }));
      }
    },
    [dispatch, associationIsLoaded, associationIsLoading, associationIsFailed, associationId]
  );

  return {
    associationId,
    associationIsLoading,
    associationIsLoaded,
    associationIsForbidden,
    associationIsFailed,
    associationBreadcrumbs,
    association
  };
}
