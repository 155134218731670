import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "reactstrap";

import ContentSection from "@/components/ContentSection";
import Team from "./components/Team";

function FairPlayPoints(props) {
  const { visitor, home } = props;

  return (
    <ContentSection title="Fair Play" hSize="h3">
      <Row>
        <Col md="6">
          <Team
            caption={`Visitor - ${visitor.title}`}
            totalPenaltyMinutes={visitor.totalPenaltyMinutes}
            fairPlayEarned={visitor.fairPlayEarned}
          />
        </Col>
        <Col md="6">
          <Team
            caption={`Home - ${home.title}`}
            totalPenaltyMinutes={home.totalPenaltyMinutes}
            fairPlayEarned={home.fairPlayEarned}
          />
        </Col>
      </Row>
    </ContentSection>
  );
}

FairPlayPoints.propTypes = {
  visitor: PropTypes.shape({
    title: PropTypes.string.isRequired,
    totalPenaltyMinutes: PropTypes.number.isRequired,
    fairPlayEarned: PropTypes.bool.isRequired
  }).isRequired,
  home: PropTypes.shape({
    title: PropTypes.string.isRequired,
    totalPenaltyMinutes: PropTypes.number.isRequired,
    fairPlayEarned: PropTypes.bool.isRequired
  }).isRequired
};

export default FairPlayPoints;
