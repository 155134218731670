// react imports
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Wizard from "@/components-16.8/RosterImportWizard/Wizard";
import { RecoilRoot } from "recoil";
import PageLoader from "@/components/PageLoader";

// component definition
function WizardContainer({ seasonId, teamId }) {

  return (
    <RecoilRoot>
      <React.Suspense fallback={<PageLoader isLoading />}>
        <Wizard seasonId={seasonId} teamId={teamId} />
      </React.Suspense>
    </RecoilRoot>
  )

}

WizardContainer.propTypes = {
  seasonId: PropTypes.string.isRequired,
  teamId: PropTypes.string
};

WizardContainer.defaultProps = {
  teamId: null
};

export default WizardContainer;
