import { call } from "redux-saga/effects";

import { gamesheetAPIRequest } from "@/redux/api/sagas";
import makeSeasonMemberAttributes from "./makeSeasonMemberAttributes";

export default function createImportingService(options) {
  const { type, apiService } = options;

  return function*(payload) {
    const { seasonId } = payload;
    const record = payload[type];

    const attributes = makeSeasonMemberAttributes(record);

    const { rawData } = yield call(gamesheetAPIRequest, apiService, {
      attributes,
      seasonId
    });

    return rawData.id;
  };
}
