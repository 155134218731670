const selectRoot = state => state.associationsList;

export const getIsLoaded = state => selectRoot(state).isLoaded;
export const getIsLoading = state => selectRoot(state).isLoading;
export const getIsFailed = state => selectRoot(state).isFailed;
export const getTotalCount = state => selectRoot(state).totalCount;

export const getList = state => {
  const { ids, data } = selectRoot(state);

  return ids.map(id => ({ ...data[id], type: "associations", id }));
};
