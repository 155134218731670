import { combineReducers } from "redux";

import scopes from "./scopes";
import attributes from "./attributes";
import teamsList from "./teamsList";
import divisionsList from "./divisionsList";
import seasonsList from "./seasonsList";
import leaguesList from "./leaguesList";
import associationsList from "./associationsList";

export default combineReducers({
  attributes,
  scopes,
  teamsList,
  divisionsList,
  seasonsList,
  leaguesList,
  associationsList
});
