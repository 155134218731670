import React from "react";
import PropTypes from "prop-types";

import classNames from "classnames";
import { Link } from "react-router-dom";
import { NavItem, NavLink } from "reactstrap";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

function TabLink({ status, children, active }) {
  const { seasonId } = useCurrentSeasonContext();

  return (
    <NavItem>
      <NavLink className={classNames({ active })} tag={Link} to={`/seasons/${seasonId}/games/${status}`}>
        {children}
      </NavLink>
    </NavItem>
  );
}

TabLink.propTypes = {
  status: PropTypes.oneOf(["completed", "scheduled"]).isRequired,
  children: PropTypes.node.isRequired,
  active: PropTypes.bool.isRequired
};

export default TabLink;
