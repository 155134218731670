import _isEqual from "lodash/isEqual";

const selectRoot = state => state.token;
const selectScopes = state => selectRoot(state).scopes || {};
const selectScope = (state, scopeName) => {
  const { actions, ids } = { ...(selectScopes(state)[scopeName] || {}) };

  return {
    actions: [...(actions || [])],
    ids: Array.isArray(ids) ? [...ids] : undefined
  };
};

const selectCanPerformActionOnResource = (state, action, resource, id) => {
  const scope = selectScope(state, resource);

  if (scope.actions.includes(action) === false) {
    return false;
  }

  if (scope.ids === undefined) {
    return true;
  }

  return typeof Array.isArray(scope.ids) && scope.ids.includes(id);
};

const selectCanManageScope = (state, scope) =>
  _isEqual([...selectScope(state, scope).actions].sort(), ["create", "read", "update", "delete"].sort());

const selectCanEditScope = (state, scope) =>
  selectScope(state, scope).actions.filter(action => ["create", "read", "update"].includes(action)).length === 3;

export const getEncodedAccessToken = state => selectRoot(state).encodedAccess;
export const getEncodedRefreshToken = state => selectRoot(state).encodedRefresh;
export const getTokenExp = state => selectRoot(state).exp;
export const getRefreshExp = state => selectRoot(state).refreshExp;
export const getTokenRoles = state => selectRoot(state).roles;

export const getTokenIsStale = state => {
  const exp = getTokenExp(state);
  if (!exp || typeof exp.getTime === "undefined") {
    return true;
  }

  const expTime = exp.getTime();
  const now = new Date().getTime();

  return Math.round(expTime - now) <= 10000; // ten seconds
};

export const getRefreshTokenIsExpired = state => {
  const exp = getRefreshExp(state)
  if (!exp || typeof exp.getTime === "undefined") {
    return true;
  }
  
  const expTime = exp.getTime();
  const now = new Date().getTime();

  return Math.round(expTime - now) <= 1;
}

export const getTokenCanManageInvitations = state => selectCanEditScope(state, "invitations");

export const getTokenCanReadAPIKeys = state => selectScope(state, "api_keys").actions.includes("read");

export const getTokenCanCreateAPIKeys = state => selectScope(state, "api_keys").actions.includes("create");

export const getTokenCanEditAPIKeys = state => selectScope(state, "api_keys").actions.includes("update");

export const getTokenCanCreateLeagues = state => selectScope(state, "leagues").actions.includes("create");

export const getTokenCanReadLeagues = state => selectScope(state, "leagues").actions.includes("read");

export const getTokenCanReadLeague = (state, id) => selectCanPerformActionOnResource(state, "read", "leagues", id);

export const getTokenCanUpdateLeague = (state, id) => selectCanPerformActionOnResource(state, "update", "leagues", id);

export const getTokenCanDeleteLeague = (state, id) => selectCanPerformActionOnResource(state, "delete", "leagues", id);

export const getTokenCanCreateSeasons = state => selectScope(state, "seasons").actions.includes("create");

export const getTokenCanReadSeasons = state => selectScope(state, "seasons").actions.includes("read");

export const getTokenCanReadSeason = (state, id) => selectCanPerformActionOnResource(state, "read", "seasons", id);

export const getTokenCanUpdateSeason = (state, id) => selectCanPerformActionOnResource(state, "update", "seasons", id);

export const getTokenCanDeleteSeason = (state, id) => selectCanPerformActionOnResource(state, "delete", "seasons", id);

export const getTokenCanManageDivisions = state => selectCanManageScope(state, "divisions");

export const getTokenCanManageTeams = state => selectCanManageScope(state, "teams");

export const getTokenCanManagePlayers = state => selectCanManageScope(state, "players");

export const getTokenCanManageCoaches = state => selectCanManageScope(state, "coaches");

export const getTokenCanEditDivisions = state => selectCanEditScope(state, "divisions");

export const getTokenCanEditTeams = state => selectCanEditScope(state, "teams");

export const getTokenCanEditTeam = (state, id) => selectCanPerformActionOnResource(state, "update", "teams", id);

export const getTokenCanEditPlayers = state => selectCanEditScope(state, "players");

export const getTokenCanEditCoaches = state => selectCanEditScope(state, "coaches");

export const getTokenCanImportSeason = (state, id) => {
  if (!getTokenCanReadSeason(state, id)) {
    return false;
  }

  if (
    getTokenCanEditDivisions(state) &&
    selectScope(state, "divisions").ids === undefined &&
    getTokenCanEditTeams(state) &&
    selectScope(state, "teams").ids === undefined &&
    getTokenCanEditPlayers(state) &&
    selectScope(state, "players").ids === undefined &&
    getTokenCanEditCoaches(state) &&
    selectScope(state, "coaches").ids === undefined
  ) {
    return true;
  }

  return false;
};

export const getTokenCanCreateDivisions = state => selectScope(state, "divisions").actions.includes("create");

export const getTokenCanReadGames = state => selectScope(state, "games").actions.includes("read");

export const getTokenCanUpdateGames = state => selectScope(state, "games").actions.includes("update");

export const getTokenCanReadTeams = state => selectScope(state, "teams").actions.includes("read");

export const getTokenCanCreateTeams = state => selectScope(state, "teams").actions.includes("create");

export const getTokenCanReadPlayers = state => selectScope(state, "players").actions.includes("read");

export const getTokenCanReadCoaches = state => selectScope(state, "coaches").actions.includes("read");

export const getTokenCanCreatePlayers = state => selectScope(state, "players").actions.includes("create");

export const getTokenCanCreateCoaches = state => selectScope(state, "coaches").actions.includes("create");

export const getTokenCanUpdateDivision = (state, id) =>
  selectCanPerformActionOnResource(state, "update", "divisions", id);

export const getTokenCanDeleteDivision = (state, id) =>
  selectCanPerformActionOnResource(state, "delete", "divisions", id);

export const getTokenCanReadDivision = (state, id) => selectCanPerformActionOnResource(state, "read", "divisions", id);

export const getTokenCanUpdateTeam = (state, id) => selectCanPerformActionOnResource(state, "update", "teams", id);

export const getTokenCanUpdateTeams = (state, id) => selectScope(state, "teams").actions.includes("update");

export const getTokenCanReadTeam = (state, id) => selectCanPerformActionOnResource(state, "read", "teams", id);

export const getTokenCanDeleteTeam = (state, id) => selectCanPerformActionOnResource(state, "delete", "teams", id);

export const getTokenCanUpdatePlayer = (state, id) => selectCanPerformActionOnResource(state, "update", "players", id);

export const getTokenCanUpdateCoach = (state, id) => selectCanPerformActionOnResource(state, "update", "coaches", id);

export const getTokenCanDeletePlayer = (state, id) => selectCanPerformActionOnResource(state, "delete", "players", id);

export const getTokenCanDeleteCoach = (state, id) => selectCanPerformActionOnResource(state, "delete", "coaches", id);

export const getTokenCanManageRefereeReports = state => selectScope(state, "games").actions.includes("referee-report");

export const getTokenCanCopyTeams = (state, id) => {
  if (
    selectCanEditScope(state, "associations") &&
    selectScope(state, "associations").ids === undefined &&
    selectCanEditScope(state, "leagues") &&
    selectScope(state, "leagues").ids === undefined &&
    selectCanEditScope(state, "seasons") &&
    selectScope(state, "seasons").ids === undefined &&
    selectCanEditScope(state, "divisions") &&
    selectScope(state, "divisions").ids === undefined &&
    selectCanEditScope(state, "teams") &&
    selectScope(state, "teams").ids === undefined &&
    selectCanEditScope(state, "players") &&
    selectScope(state, "players").ids === undefined &&
    selectCanEditScope(state, "coaches") &&
    selectScope(state, "coaches").ids === undefined
  ) {
    return true;
  }

  return false;
};

export const getTokenCanReadReferees = state => selectScope(state, "referees").actions.includes("read");

export const getTokenCanCreateReferees = state => selectScope(state, "referees").actions.includes("create");

export const getTokenCanUpdateReferee = (state, id) =>
  selectCanPerformActionOnResource(state, "update", "referees", id);

export const getTokenCanManageReferees = state => selectCanManageScope(state, "referees");

export const getTokenCanReadDashboardNotes = state => selectScope(state, "notes").actions.includes("read");

export const getTokenCanCreateDashboardNotes = state => selectScope(state, "notes").actions.includes("create");

export const getTokenCanReadPlayerReports = state =>
  selectScope(state, "reports").actions.includes("player-penalty-report");

export const getTokenCanReadCoachReports = state =>
  selectScope(state, "reports").actions.includes("coach-penalty-report");

export const getTokenCanReadPenaltyTypeReports = state =>
  selectScope(state, "reports").actions.includes("penalty-type-report");

export const getTokenCanReadRefereeReports = state =>
  selectScope(state, "reports").actions.includes("referee-penalty-report");

export const getTokenCanReadTrainerVisitsReports = state =>
  selectScope(state, "reports").actions.includes("trainer-visits-report");

export const getTokenCanReadBillingReports = state => selectScope(state, "reports").actions.includes("billing-report");

export const getTokenCanReadSuspensionReports = state =>
  selectScope(state, "reports").actions.includes("suspension-report");

export const getTokenCanManageSeasons = state => selectCanManageScope(state, "seasons");

export const getTokenCanImportCSVRoster = state =>
  getTokenCanManageSeasons(state) &&
  getTokenCanManageTeams(state) &&
  getTokenCanManagePlayers(state) &&
  getTokenCanManageCoaches(state);

export const getTokenCanReadAllTeams = state =>
  selectScope(state, "teams").actions.includes("read") && selectScope(state, "teams").ids === undefined;
