import _uniqBy from "lodash/uniqBy";
import _isEqual from "lodash/isEqual";
import _trimEnd from "lodash/trimEnd";

const selectRoot = state => state.userForm;
const selectRoles = state => selectRoot(state).roles;
const selectFields = state => selectRoot(state).fields;
const selectFieldValue = (state, name) => selectRoot(state).fields[name].value;
const selectMeta = state => selectRoot(state).meta;

export const getRoles = state => {
  const roles = selectRoles(state).map(({ title, level, resourceTitle, team, division, season, league, association, ...rest }) => ({
    ...rest,
    id: `${title}-${level.type}-${level.id}-${team}-${division}-${season}-${league}-${association}`,
    title,
    level,
    resourceTitle,
    team,
    division,
    season,
    league,
    association
  }));

  return _uniqBy(roles, "id");
};

export const getField = (state, name) => {
  const { value, errors } = selectFields(state)[name];

  return { value, errors, isInvalid: errors.length > 0 };
};

export const getAttributes = state => {
  const firstName = selectFieldValue(state, "firstName");
  const lastName = selectFieldValue(state, "lastName");
  const email = selectFieldValue(state, "email");

  const roles = selectRoles(state).map(({ title, level, association, league, season, division, team, ...rest }) => {
    let id = level.id;  // Default to the original level id
    if (level.type === "associations") id = association || id;
    else if (level.type === "leagues") id = league || id;
    else if (level.type === "seasons") id = season || id;
    else if (level.type === "divisions") id = division || id;
    else if (level.type === "teams") id = team || id;

    return {
      title,
      level: {
        ...level,
        id  // Set the id based on the determined value
      },
      ...rest
    };
  });

  return { firstName, lastName, email, roles };
};

export const getIsValid = state => {
  const fields = selectFields(state);

  const fieldsAreValid = Object.values(fields).filter(({ errors }) => errors.length > 0).length === 0;

  const roles = selectRoles(state);

  return fieldsAreValid && roles.length > 0;
};

export const getIsDirty = state => {
  const fields = selectFields(state);

  const fieldsAreDirty = Object.values(fields).filter(({ isDirty }) => isDirty).length > 0;

  const roles = selectRoles(state).map(({ title, level }) => ({
    title,
    level
  }));

  const initRoles = selectRoot(state).initRoles;
  const rolesAreDirty = !_isEqual(roles, initRoles);

  return fieldsAreDirty || rolesAreDirty;
};

export const getIsNotFilled = state => {
  const fields = selectFields(state);

  return Object.entries(fields).filter(([name, { value }]) => _trimEnd(value) === "").length > 0;
};

export const getIsInvalid = state => getIsValid(state) === false;

export const getIsPristine = state => getIsDirty(state) === false;

export const getIsOperating = state => selectMeta(state).isOperating;
export const getIsLoading = state => selectMeta(state).isLoading;
export const getIsLoaded = state => selectMeta(state).isLoaded;
export const getUserName = state => selectMeta(state).userName;
export const getUserEmail = state => selectMeta(state).userEmail;
export const getUserStatus = state => selectMeta(state).userStatus;

export const getRolesAreLoading = state => {
  const roles = selectRoles(state);

  return roles.length > 0 && roles.filter(({ isLoading }) => isLoading).length > 0;
};

export const getRolesAreLoaded = state => {
  const roles = selectRoles(state);

  return roles.length === 0 || roles.filter(({ isLoaded }) => isLoaded).length > 0;
};

export const getIsResendingVerificationEmail = state => selectMeta(state).resendingVerificationEmail;

export const getIsResendingVerificationEmailFailed = state => selectMeta(state).resendingVerificationEmailFailed;
