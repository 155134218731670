import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import RefereesListMergeToolTable from "./Table";
import RefereesListMergeToolProgress from "./Progress";
import RefereesListMergeToolFailure from "./Failure";

class RefereesListMergeTool extends PureComponent {
  static propTypes = {
    status: PropTypes.string,
    setStatus: PropTypes.func
  };

  static defaultProps = {
    status: null,
    setStatus() {}
  };

  returnToRefereeSelection = () => {
    this.props.setStatus("selecting-referees");
  };

  render() {
    const { status } = this.props;

    switch (status) {
      case "choosing-winner":
        return (
          <Fragment>
            <Button color="link" onClick={this.returnToRefereeSelection}>
              ← Back to referee selection
            </Button>

            <h1>Which referee would you like to keep?</h1>
            <p className="text-secondary">
              Select the referee you want to keep and we’ll merge the other referees into this referee.
            </p>

            <RefereesListMergeToolTable />
          </Fragment>
        );
      case "merging":
        return <RefereesListMergeToolProgress />;
      case "failure":
        return <RefereesListMergeToolFailure />;
      default:
        return null;
    }
  }
}

export default RefereesListMergeTool;
