// react imports
import React from "react";
import PropTypes from "prop-types";

// legacy component imports
import TitleBar from "@/components/TitleBar";
import DashboardForm from "@/components/DashboardForm";

import { CSVRefereeImportDisplayPage as DisplayPage } from "@/components-16.8/RefereeImportWizard/types/displayPage";
import { Landing } from './pages/Landing';
import { Loading } from './pages/Loading';
import { useWizard } from "./useWizard";
import ShowIf from "@/components-16.8/ShowIf";
import { ParsingSuccess } from "./pages/ParsingSuccess";
import { ParsingFailure } from "./pages/ParsingFailure";
import { ImportSuccess } from "./pages/ImportSuccess";
import { ImportFailure } from "./pages/ImportFailure";
import { ImportProgress } from "./pages/ImportProgress";

import "./style.scss";

function Wizard({ seasonId }) {

    const {
        displayPage
    } = useWizard({ seasonId });

  return (
    <>
      <DashboardForm>

        <TitleBar title={"Referee Import Wizard"} />

        <ShowIf cond={displayPage} value={DisplayPage.LANDING} component={<Landing />} />
        <ShowIf cond={displayPage} value={DisplayPage.LOADING} component={<Loading />} />
        <ShowIf cond={displayPage} value={DisplayPage.PARSE_SUCCESS} component={<ParsingSuccess seasonId={seasonId} />} />
        <ShowIf cond={displayPage} value={DisplayPage.PARSE_FAILURE} component={<ParsingFailure />} />
        <ShowIf cond={displayPage} value={DisplayPage.IMPORT_PROGRESS} component={<ImportProgress seasonId={seasonId} />} />
        <ShowIf cond={displayPage} value={DisplayPage.IMPORT_SUCCESS} component={<ImportSuccess seasonId={seasonId} />} />
        <ShowIf cond={displayPage} value={DisplayPage.IMPORT_FAILURE} component={<ImportFailure seasonId={seasonId} />} />

      </DashboardForm>
    </>
  );
}

Wizard.propTypes = {
  seasonId: PropTypes.string.isRequired
};

export default Wizard;
