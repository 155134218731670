import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Row, Col, Alert, FormGroup, Label, Input, FormFeedback, Button } from "reactstrap";

import Layout from "@/layouts/Layout";
import Head from "@/components/Head";
import TitleBar from "@/components/TitleBar";
import DashboardForm from "@/components/DashboardForm";
import DashboardFormFooter from "@/components/DashboardFormFooter";
import Spinner from "@/components/Spinner";
import FormItem from "@/components/FormItem";
import { screens } from "@/redux/registration/reducer";
import { InvitationReviewGUI } from "../Invitation/components/InvitationReviewGUI";
import { useUrlInvitationCode } from "./hooks/useUrlInvitationCode";

import "./New.scss";

class New extends PureComponent {
  static propTypes = {
    showScreen: PropTypes.string.isRequired,
    fields: PropTypes.shape({
      code: PropTypes.shape({
        value: PropTypes.string,
        error: PropTypes.string
      }).isRequired,
      email: PropTypes.shape({
        value: PropTypes.string,
        error: PropTypes.string
      }).isRequired,
      firstName: PropTypes.shape({
        value: PropTypes.string,
        error: PropTypes.string
      }).isRequired,
      lastName: PropTypes.shape({
        value: PropTypes.string,
        error: PropTypes.string
      }).isRequired,
      password: PropTypes.shape({
        value: PropTypes.string,
        error: PropTypes.string
      }).isRequired,
      passwordConfirmation: PropTypes.shape({
        value: PropTypes.string,
        error: PropTypes.string
      }).isRequired
    }).isRequired,
    codeInfo: PropTypes.shape({
      team: PropTypes.shape({
        logo: PropTypes.string,
        title: PropTypes.string,
        players: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.number,
          firstName: PropTypes.string,
          lastName: PropTypes.string,
          photo: PropTypes.string,
          number: PropTypes.string,
          duty: PropTypes.string,
        })),
        season: PropTypes.shape({
          title: PropTypes.string,
        }),
        division: PropTypes.shape({
          title: PropTypes.string,
        }),
      }),
    }),
    error: PropTypes.string,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onWrongInvitation: PropTypes.func,
    onCorrectInvitation: PropTypes.func,
    codeIsLoading: PropTypes.bool,
    newUserIsLoading: PropTypes.bool,
  };

  static defaultProps = {
    onChange() { },
    onSubmit() { }
  };

  handleChange = ({ target }) => {
    const { name, value } = target;

    this.props.onChange({
      [name]: value
    });
  };

  handleSubmit = () => {
    this.props.onSubmit();
  };

  render() {
    const {
      showScreen,
      fields: { code, email, firstName, lastName, password, passwordConfirmation },
      codeInfo,
      error,
      onWrongInvitation,
      onCorrectInvitation,
      codeIsLoading,
      newUserIsLoading,
    } = this.props;

    switch (showScreen) {
      case screens.ENTER_INFO:
        return (<NewUserFormGUI
          code={code}
          email={email}
          firstName={firstName}
          lastName={lastName}
          password={password}
          passwordConfirmation={passwordConfirmation}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          nextScreenIsLoading={codeIsLoading}
          error={error}
        />);
      case screens.VERIFY_TEAM_INVITATION:
        return (<InvitationReviewGUI
          codeInfo={codeInfo}
          onWrongInvitation={onWrongInvitation}
          onInvitationIsCorrect={onCorrectInvitation}
          nextScreenIsLoading={newUserIsLoading}
          confirmButtonText="Create Account"
        />);
      default:
        return (<></>);
    }
  }
}

export const NewUserFormGUI = (props) => {
  const {
    code,
    email,
    firstName,
    lastName,
    password,
    passwordConfirmation,
    onChange,
    onSubmit,
    nextScreenIsLoading,
    error,
  } = props;

  useUrlInvitationCode();

  return (
    <Layout className="account-new-page">
      <Head title="Registration" />

      <Row className="justify-content-center">
        <Col md="10" lg="6">
          <TitleBar title="Registration" />

          {error ? <Alert color="danger">{error}</Alert> : null}

          <DashboardForm>
            <FormGroup>
              <Label for="invitation">Invitation Code</Label>
              <Input
                id="code"
                name="code"
                defaultValue={code.value}
                onChange={onChange}
                invalid={code.error !== null}
              />
              <FormFeedback>{code.error}</FormFeedback>
            </FormGroup>

            <FormItem>
              <FormGroup className="col-md-12">
                <Label for="email">Email</Label>
                <Input
                  id="email"
                  name="email"
                  defaultValue={email.value}
                  onChange={onChange}
                  invalid={email.error !== null}
                />
                <FormFeedback>{email.error}</FormFeedback>
              </FormGroup>

              <FormGroup className="col-md-6">
                <Label for="userFirstName">First Name</Label>
                <Input
                  id="firstName"
                  name="firstName"
                  defaultValue={firstName.value}
                  onChange={onChange}
                  invalid={firstName.error !== null}
                />
                <FormFeedback>{firstName.error}</FormFeedback>
              </FormGroup>

              <FormGroup className="col-md-6">
                <Label for="lastName">Last Name</Label>
                <Input
                  id="lastName"
                  name="lastName"
                  defaultValue={lastName.value}
                  onChange={onChange}
                  invalid={lastName.error !== null}
                />
                <FormFeedback>{lastName.error}</FormFeedback>
              </FormGroup>
            </FormItem>

            <FormItem>
              <FormGroup className="col-md-6">
                <Label for="password">Create Password</Label>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  defaultValue={password.value}
                  onChange={onChange}
                  invalid={password.error !== null}
                />
                <FormFeedback>{password.error}</FormFeedback>
              </FormGroup>

              <FormGroup className="col-md-6">
                <Label for="passwordConfirmation">Confirm Password</Label>
                <Input
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  type="password"
                  defaultValue={passwordConfirmation.value}
                  onChange={onChange}
                  invalid={passwordConfirmation.error !== null}
                />
                <FormFeedback>{passwordConfirmation.error}</FormFeedback>
              </FormGroup>
            </FormItem>

            <DashboardFormFooter className="small-margin">
              <div className="dashboard-form-footer__primary">
                {nextScreenIsLoading && (
                  <div className="next-screen-loading-spinner">
                    <Spinner theme="loader" color="indigo" size="m" />
                  </div>
                )}
                <Button type="submit" color="success" onClick={onSubmit} disabled={nextScreenIsLoading}>
                  Continue
                </Button>
              </div>
            </DashboardFormFooter>
          </DashboardForm>
        </Col>
      </Row>
    </Layout>
  );
}

export default New;
