import { put, select, takeLatest, all, actionChannel, take } from "redux-saga/effects";
import { buffers } from "redux-saga";
import build from "redux-object";

import buildInitialAbilityRules from "@/lib/core/buildInitialAbilityRules";
import buildAbilityRules from "@/lib/core/buildAbilityRules";

import { loadResource } from "@/lib/api/general";

import { getTokenRoles } from "@/redux/token/selectors";
import { tokenSetRoutine } from "@/redux/token/routines";

import { addAbilityRules } from "./actions";
import { resourcePrefetchingRoutine, resourcesListPrefetchingRoutine } from "./routines";
import takeConcurrently from "../common/takeConcurrently";
import { fetchOne } from "../api/sagas";

function* setInitialRulesSaga() {
  const roles = yield select(getTokenRoles);
  const initialRules = buildInitialAbilityRules(roles);

  yield put(addAbilityRules(initialRules));
}

export function* addAbilityRulesSaga(data) {
  const roles = yield select(getTokenRoles);

  const resources = Object.keys(data || {}).reduce((result, resourceType) => {
    const resourceList = build(data, resourceType);

    return {
      ...result,
      [resourceType]: Object.values(resourceList)
    };
  }, {});

  const rules = buildAbilityRules({ roles, resources });

  yield put(addAbilityRules(rules));
}

function* resourcePrefetchingSaga({ payload: { type, id } }) {
  yield put(resourcePrefetchingRoutine.request({ type, id }));

  try {
    const [resource] = yield fetchOne({ type, id }, loadResource, { type });

    yield put(resourcePrefetchingRoutine.success({ type, id, ...resource }));
  } catch (error) {
    yield put(resourcePrefetchingRoutine.failure({ type, id, error }));
  } finally {
    yield put(resourcePrefetchingRoutine.fulfill({ type, id }));
  }
}

function* resourcesListPrefetchingSaga() {
  yield put(resourcesListPrefetchingRoutine.request());

  const roles = yield select(getTokenRoles);
  const prefetchResources = roles
    .filter(({ level: { type, id } }) => type !== "" && id)
    .reduce(
      (result, { level: { type, id } }) =>
        result.find(r => r.type === type && r.id === id) ? result : [...result, { type, id }],
      []
    );

  if (prefetchResources.length > 0) {
    const prefetchingFulfill = yield actionChannel(
      ({ type, payload }) =>
        type === resourcePrefetchingRoutine.FULFILL &&
        payload &&
        prefetchResources.map(({ id }) => id).includes(payload.id),
      buffers.dropping(10)
    );

    yield all(prefetchResources.map(({ type, id }) => put(resourcePrefetchingRoutine({ type, id }))));

    yield all(prefetchResources.map(() => take(prefetchingFulfill)));
  }

  yield put(resourcesListPrefetchingRoutine.success());
  yield put(resourcesListPrefetchingRoutine.fulfill());
}

export function* abilityFlow() {
  yield all([
    takeLatest(tokenSetRoutine.SUCCESS, setInitialRulesSaga),
    takeConcurrently(resourcePrefetchingRoutine.TRIGGER, resourcePrefetchingSaga, 10),
    takeLatest(tokenSetRoutine.SUCCESS, resourcesListPrefetchingSaga)
  ]);
}
