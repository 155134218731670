import React from "react";
import { Button } from "reactstrap";

import Spinner from "@/components/Spinner";

import { useDivisionList } from "./hooks";

import DivisionGroup from "./DivisionGroup";

function DivisionList() {
  const {
    isLoading,
    isLoaded,
    isOptionSelected,
    isAllOptionsSelected,
    divisionGroups,
    clear,
    selectAll
  } = useDivisionList();

  return (
    <div className="report-data-sources-select-list">
      <div className="report-data-sources-select-list__label">
        <span className="report-data-sources-select-list__label__text">Select division(s)</span>
      </div>

      <div className="report-data-sources-select-list__input list-group list-group-flush">
        {isLoading ? (
          <Spinner className="report-data-sources-select-list__spinner" theme="loader" color="yellow" size="xxl" />
        ) : isLoaded ? (
          divisionGroups.map(({ seasonId, ...rest }) => <DivisionGroup key={seasonId} {...rest} />)
        ) : (
          <p className="report-data-sources-select-list__empty-list-message">No seasons selected</p>
        )}
      </div>

      <div className="report-data-sources-select-list__controls">
        {isOptionSelected && (
          <Button
            className="report-data-sources-select-list__controls__left-control"
            color="link"
            size="sm"
            onClick={clear}
          >
            Clear selection
          </Button>
        )}

        {isLoaded &&
          isAllOptionsSelected === false && (
            <Button
              className="report-data-sources-select-list__controls__right-control"
              color="link"
              size="sm"
              onClick={selectAll}
            >
              Select All
            </Button>
          )}
      </div>
    </div>
  );
}

export default DivisionList;
