import { handleActions } from "redux-actions";

import { toggleCoachTeamFormDisplay, changeCoachTeamFormValues, changeCoachTeamFormTeamId } from "../actions";

import { AddCoachToTeamRoutine } from "../routines";

const defaultState = {
  display: false,
  isOperating: false,
  values: {
    teamId: "",
    position: ""
  }
};

const triggerDisplay = state => ({ ...defaultState, display: !state.display });

const changeValues = (state, { payload }) => ({
  ...state,
  values: { ...state.values, ...payload }
});

const changeTeamId = (state, { payload }) => ({
  ...state,
  values: payload.id !== "" ? { ...state.values, teamId: payload.id } : defaultState.values
});

const addingRequest = state => Object.assign({}, state, { isOperating: true });

const addingSuccess = state => Object.assign({}, defaultState);

const addingFulfill = state => Object.assign({}, state, { isOperating: false });

export default handleActions(
  {
    [toggleCoachTeamFormDisplay]: triggerDisplay,
    [changeCoachTeamFormValues]: changeValues,
    [changeCoachTeamFormTeamId]: changeTeamId,
    [AddCoachToTeamRoutine.REQUEST]: addingRequest,
    [AddCoachToTeamRoutine.SUCCESS]: addingSuccess,
    [AddCoachToTeamRoutine.FULFILL]: addingFulfill
  },
  defaultState
);
