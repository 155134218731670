import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Modal } from "reactstrap";

import { usePenaltyCodesImporter } from "../hooks/usePenaltyCodesImporter";

import Header from "./Header";
import FilePicker from "./FilePicker";
import ValidationFailure from "./ValidationFailure";
import Confirmation from "./Confirmation";

import "./PenaltyCodesImporter.scss";

function PenaltyCodesImporter(props) {
  const { isOpen, close, penaltyCodes, changePenaltyCodes, helpers, sport } = props;

  const {
    step,
    parseFile,
    parseError,
    invalidRows,
    reset,
    importablePenaltyCodes,
    importPenaltyCodes,
    penaltyClasses
  } = usePenaltyCodesImporter({ close, penaltyCodes, changePenaltyCodes, helpers });

  return (
    <Fragment>
      <Modal
        size="lg"
        fade={false}
        toggle={close}
        isOpen={isOpen}
        centered
        onClosed={reset}
        className="penalty-codes-importer"
      >
        <Header step={step} close={close} />

        {step === "select-file" && <FilePicker onSelect={parseFile} parseError={parseError} importableClasses={(penaltyClasses || []).map(p => p.title)} />}
        {step === "validation-failure" && <ValidationFailure invalidRows={invalidRows} onReSelect={parseFile} penaltyClasses={penaltyClasses} />}
        {step === "confirmation" && (
          <Confirmation onConfirm={importPenaltyCodes} numDelete={penaltyCodes.length} numAdd={importablePenaltyCodes.length} sport={sport} />
        )}
      </Modal>
    </Fragment>
  );
}

PenaltyCodesImporter.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  penaltyCodes: PropTypes.arrayOf(PropTypes.shape({})),
  changePenaltyCodes: PropTypes.func.isRequired,
  helpers: PropTypes.shape({
    options: PropTypes.arrayOf(PropTypes.shape({})),
    getKey: PropTypes.func,
    getLabel: PropTypes.func,
    isValid: PropTypes.func
  })
};

export default PenaltyCodesImporter;
