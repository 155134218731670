import React from "react";
import PropTypes from "prop-types";

import { Input } from "reactstrap";

function YearInput({ value, onChange }) {
  const currentYear = new Date().getFullYear();
  const options = Array.from(Array(100).keys()).map(number => currentYear - number);

  return (
    <Input type="select" value={value === null ? "" : value} className="custom-select" onChange={onChange}>
      {value === null && (
        <option disabled value="">
          Year
        </option>
      )}
      {options.map(year => (
        <option key={year} value={year}>
          {year}
        </option>
      ))}
    </Input>
  );
}

YearInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func
};

export default YearInput;
