import React, { Fragment, useEffect, useState } from "react";
import { useRosterImport } from "@/components-16.8/RosterImportWizard/hooks/useRosterImport";
import ShowIf from "@/components-16.8/ShowIf";

export function ImportComplete() {

  const { season, team, playerImportProgress, coachImportProgress, isSeasonImport, isTeamImport } = useRosterImport();
  const [ failedMessage, setFailedMesssage ] = useState('');

  useEffect(() => {

    const failedPlayerImports = playerImportProgress.rejected && `${playerImportProgress.rejected} Players`;
    const failedCoachImports = coachImportProgress.rejected && `${coachImportProgress.rejected} Coaches`;
    setFailedMesssage([ failedPlayerImports, failedCoachImports ].filter(x=>!!x).join(" and ")||'');

  }, [ playerImportProgress.rejected, coachImportProgress.rejected ]);

  return (
    <Fragment>
      <div className="csv-import__confirmation__text">
        <p>
          {playerImportProgress.resolved} players and {coachImportProgress.resolved} coaches were imported into the{' '}
          <ShowIf cond={isSeasonImport}><span className="season">{season.title}</span> season.</ShowIf>
          <ShowIf cond={isTeamImport}><span className="season">{team && team.attributes.title}</span> team.</ShowIf>
        </p>
      </div>

      { failedMessage && <div className="csv-import__result__warning"><p>Failed to import {failedMessage}</p></div> }
    </Fragment>
  );
}

export default ImportComplete;
