import { useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { billingReportLoadingRoutine } from "@/redux/billingReport/routines";
import actions from "@/redux/billingReport/actions";

import { getBillingReportIsLoaded, getBillingReportIsForbidden } from "@/redux/billingReport/selectors";

export default function useBillingReport(search) {
  const dispatch = useDispatch();
  const isLoaded = useSelector(getBillingReportIsLoaded);
  const isForbidden = useSelector(getBillingReportIsForbidden);

  const seasonId = useMemo(() => new URLSearchParams(search).get("season_id"), [search]);

  useEffect(
    () => {
      dispatch(billingReportLoadingRoutine.trigger({ seasonId }));

      return () => dispatch(actions.report.clear());
    },
    [dispatch, seasonId]
  );

  return { isLoaded, isForbidden, seasonId };
}
