import { createActions } from "redux-actions";

export const { password: passwordActions } = createActions({
  PASSWORD: {
    RESET: {
      SET_EMAIL: undefined,
      CLEAR: undefined
    },
    NEW: {
      INIT_FIELD: undefined,
      CHANGE_FIELD: undefined,
      CLEAR: undefined
    },
    EDIT: {
      CHANGE_FIELD: undefined,
      CLEAR: undefined
    }
  }
});
