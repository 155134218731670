import { connect } from "react-redux";

import {
  getSeasonRosterCoachesListIsLoading,
  getSeasonRosterCoachesListIsLoaded,
  getSeasonRosterCoachesList,
  getSeasonRosterCoachesListHasMore,
  getSeasonRosterCoachesListIsAppending,
  getSeasonRosterCoachesListSearchCurrentQuery,
  getSeasonRosterCoachesMergeToolStatus,
  getSeasonRosterCoachesMergeToolIsEnabled,
  getSeasonRosterCoachesListTotalCount,
  getSeasonRosterCoachesListFilteredCount
} from "@/redux/seasonRoster/selectors";

import { loadSeasonCoachesRosterRoutine } from "@/redux/seasonRoster/routines";
import { seasonRosterActions } from "@/redux/seasonRoster/actions";

import CoachesList from "./List";

const mapStateToProps = state => ({
  isLoading: getSeasonRosterCoachesListIsLoading(state),
  isLoaded: getSeasonRosterCoachesListIsLoaded(state),
  coaches: getSeasonRosterCoachesList(state),
  totalCount: getSeasonRosterCoachesListTotalCount(state),
  filteredCount: getSeasonRosterCoachesListFilteredCount(state),
  currentQuery: getSeasonRosterCoachesListSearchCurrentQuery(state),
  hasMore: getSeasonRosterCoachesListHasMore(state),
  isAppending: getSeasonRosterCoachesListIsAppending(state),
  mergeToolStatus: getSeasonRosterCoachesMergeToolStatus(state),
  isMergeToolEnabled: getSeasonRosterCoachesMergeToolIsEnabled(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadMoreCoaches: () =>
    dispatch(
      loadSeasonCoachesRosterRoutine({
        seasonId: ownProps.seasonId,
        append: true
      })
    ),
  enableMergeTool: () =>
    dispatch(
      seasonRosterActions.coaches.mergeTool.enable({
        seasonId: ownProps.seasonId
      })
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoachesList);
