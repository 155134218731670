import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import AbilityLink from "@/components/AbilityLink";

class SuspendedPerson extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    typePlural: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    seasonId: PropTypes.string,
    url: PropTypes.string,
    noLink: PropTypes.bool
  };

  static defaultProps = {
    firstName: null,
    lastName: null,
    url: null,
    seasonId: "",
    noLink: false
  };

  render() {
    const { firstName, lastName, url, typePlural, id, noLink } = this.props;
    const name = firstName && lastName ? `${firstName} ${lastName}` : "N/A";

    if (noLink) {
      return name;
    }

    return (
      <AbilityLink subject={{ type: typePlural, id }} action="read" to={url} fallback={name}>
        {name}
      </AbilityLink>
    );
  }
}

export default SuspendedPerson;
