import _isEmpty from "lodash/isEmpty";

export function validatePassword(value) {
  const errors = [];

  if (_isEmpty(value)) {
    errors.push("Password can not be empty");
  } else if (value.length < 8) {
    errors.push("Password should be at least 8 characters long.");
  }

  return errors;
}

export function validatePasswordConfirmation(value) {
  const errors = [];

  if (_isEmpty(value)) {
    errors.push("Password confirmation can not be empty");
  }

  return errors;
}

export function validate({ password, passwordConfirmation }) {
  let errors = null;

  const passwordErrors = validatePassword(password);
  const passwordConfirmationErrors = validatePasswordConfirmation(passwordConfirmation);

  if (passwordErrors.length > 0) {
    errors = { ...errors, password: passwordErrors };
  }

  if (passwordConfirmationErrors.length > 0) {
    errors = { ...errors, passwordConfirmation: passwordConfirmationErrors };
  } else if (password.length > 0 && password !== passwordConfirmation) {
    errors = { ...errors, passwordConfirmation: ["Passwords don’t match"] };
  }

  return errors;
}
