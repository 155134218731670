import { handleActions, combineActions } from "redux-actions";

import actions from "./actions";

const initialState = {
  dateFrom: "",
  dateTo: "",
  divisionIds: [],
  viewed: "",
  flagged: "",
  query: "",
  gameType: ""
};

function reduceFieldChanged(fieldName) {
  return (state, { payload: { value } }) => ({ ...state, [fieldName]: value });
}

export default handleActions(
  {
    [actions.setDateFrom]: reduceFieldChanged("dateFrom"),
    [actions.setDateTo]: reduceFieldChanged("dateTo"),
    [actions.setDivisionIds]: reduceFieldChanged("divisionIds"),
    [actions.setViewedStatus]: reduceFieldChanged("viewed"),
    [actions.setFlaggedStatus]: reduceFieldChanged("flagged"),
    [actions.setQuery]: reduceFieldChanged("query"),
    [actions.setGametype]: reduceFieldChanged("gameType"),
    [combineActions(actions.clear, actions.reset)]: () => ({ ...initialState })
  },
  initialState
);
