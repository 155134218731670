import React, { useState, useCallback } from "react";

export default function useActionsDropdown({ seasonId, id }) {

  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpened = useCallback(() => setIsOpen(!isOpen), [setIsOpen, isOpen]);

  return { isOpen, toggleIsOpened };
}
