import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { playerPenaltyReportLoadingRoutine } from "@/redux/playerPenaltyReport/routines";
import actions from "@/redux/playerPenaltyReport/actions";

export default function usePlayerPenaltyReport(externalId) {
  const dispatch = useDispatch();

  useEffect(
    () => {
      if (externalId !== "") {
        dispatch(playerPenaltyReportLoadingRoutine({ externalId }));
      }

      return () => dispatch(actions.report.clear());
    },
    [dispatch, externalId]
  );
}
