import { connect } from "react-redux";

import {
  getTeamIsLoaded,
  getTeamIsLoading,
  getTeamTitle,
  getBreadcrumbs,
  getTeamIsForbidden,
  getTeamIsArchived
} from "@/redux/teamRosterExtender/selectors";

import actions from "@/redux/teamRosterExtender/actions";

import { teamLoadingRoutine, coachesLoadingRoutine } from "@/redux/teamRosterExtender/routines";

import TeamRosterCoachesAdd from "./TeamRosterCoachesAdd";

const mapStateToProps = (state, { teamId }) => ({
  isTeamLoaded: getTeamIsLoaded(state),
  isTeamLoading: getTeamIsLoading(state),
  isTeamForbidden: getTeamIsForbidden(state),
  isTeamArchived: getTeamIsArchived(state),
  teamTitle: getTeamTitle(state),
  breadcrumbs: getBreadcrumbs(state, teamId),
  loadingAction: coachesLoadingRoutine.trigger
});

const mapDispatchToProps = (dispatch, { seasonId, teamId }) => ({
  loadTeam: () => dispatch(teamLoadingRoutine({ id: teamId, seasonId })),
  loadCoaches: () => dispatch(coachesLoadingRoutine({ seasonId, append: false })),
  clear: () => dispatch(actions.clear())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamRosterCoachesAdd);
