import { connect } from "react-redux";

import {
  isCurrentCoachTeamFormDisplaying,
  getCurrentCoachTeamFormValues,
  isCurrentCoachTeamFormOperating,
  getCurrentCoachTeamIds
} from "@/redux/coaches/selectors";

import { getSeason } from "@/redux/currentSeason/selectors";
import { getTeam } from "@/redux/teams/selectors";

import {
  changeCoachTeamFormValues,
  changeCoachTeamFormTeamId,
  toggleCoachTeamFormDisplay
} from "@/redux/coaches/actions";

import { AddCoachToTeamRoutine } from "@/redux/coaches/routines";

import CoachTeamsList from "./CoachTeamsList";

const mapStateToProps = state => ({
  season: getSeason(state),
  displayTeamForm: isCurrentCoachTeamFormDisplaying(state),
  teamFormValues: getCurrentCoachTeamFormValues(state),
  isTeamFormOperating: isCurrentCoachTeamFormOperating(state),
  getTeam: teamId => getTeam(state, teamId),
  coachTeamIds: getCurrentCoachTeamIds(state)
});

const mapDispatchToProps = dispatch => ({
  changeTeamFormValues: payload => dispatch(changeCoachTeamFormValues(payload)),
  changeTeamFormTeamId: payload => dispatch(changeCoachTeamFormTeamId(payload)),
  toggleTeamFormDisplay: () => dispatch(toggleCoachTeamFormDisplay()),
  addCoachToTeam: payload => dispatch(AddCoachToTeamRoutine.trigger(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoachTeamsList);
