// import React from "react";
import PropTypes from "prop-types";

function NameWithNumber({ number, firstName, lastName }) {
  const name = [firstName, lastName].join(" ");

  return `(${number}) ${name}`;
}

NameWithNumber.propTypes = {
  number: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired
};

export default NameWithNumber;
