import { createActions } from "redux-actions";

export const { games: gamesActions } = createActions({
  GAMES: {
    LIST: {
      CLEAR: undefined,
      SET_FILTER: undefined,
      SET_QUERY: undefined,
      RESET_FILTER: undefined
    }
  }
});
