import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import { FormGroup, Label, Input, Button } from "reactstrap";

import ContentSection from "@/components/ContentSection";
import DashboardForm from "@/components/DashboardForm";
import DashboardFormFooter from "@/components/DashboardFormFooter";
import FormItem from "@/components/FormItem";

class TeamCoachForm extends PureComponent {
  static propTypes = {
    isOperating: PropTypes.bool,
    coachId: PropTypes.string.isRequired,
    coachesRoster: PropTypes.arrayOf(PropTypes.shape({})),
    position: PropTypes.string,
    onSubmit: PropTypes.func,
    onDelete: PropTypes.func,
    rosterLocked: PropTypes.bool,
    coachPositions: PropTypes.arrayOf(PropTypes.shape({})).isRequired
  };

  static defaultProps = {
    isOperating: false,
    position: "",
    coachesRoster: [],
    coachPositions: [],
    onSubmit() {},
    onDelete() {},
    rosterLocked: false
  };

  state = {
    position: this.props.position
  };

  isCoachInRoster = (this.props.coachesRoster || []).findIndex(item => item.id === this.props.coachId) >= 0;

  handleInputChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  handleSubmit = () => {
    const { coachesRoster, onSubmit, coachId: id } = this.props;
    const { position } = this.state;

    // @TODO: allow to change status
    const rosterCoach = {
      id,
      position,
      status: "coaching"
    };

    let nextCoachesRoster = [];

    if (this.isCoachInRoster) {
      nextCoachesRoster = coachesRoster.map(item => (item.id === id ? rosterCoach : item));
    } else {
      nextCoachesRoster = [...coachesRoster, ...[rosterCoach]];
    }

    onSubmit(nextCoachesRoster);
  };

  handleDelete = () => {
    const { coachesRoster, coachId, onDelete } = this.props;

    const nextCoachesRoster = coachesRoster.filter(item => item.id !== coachId);

    onDelete(nextCoachesRoster);
  };

  render() {
    const { isOperating, rosterLocked, coachPositions } = this.props;
    const { position } = this.state;

    return (
      <DashboardForm className="coach-form" isOperating={isOperating}>
        <ContentSection>
          <FormItem>
            <FormGroup className="col-md-6">
              <Label for="teamPlayerPosition">Position</Label>
              <Input
                className="custom-select"
                type="select"
                id="teamPlayerPosition"
                name="position"
                value={position}
                onChange={this.handleInputChange}
              >
                <option value="" disabled>
                  Select position
                </option>
                {coachPositions.map(position => (
                  <option key={position.key} value={position.key}>
                    {position.value}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </FormItem>
        </ContentSection>

        <DashboardFormFooter>
          {this.isCoachInRoster ? (
            <Fragment>
              {!rosterLocked && (
                <div className="dashboard-form-footer__secondary">
                  <Button type="button" color="danger" size="sm" outline onClick={this.handleDelete}>
                    Remove Coach from Team
                  </Button>
                </div>
              )}

              <div className="dashboard-form-footer__primary">
                <Button type="button" color="success" onClick={this.handleSubmit}>
                  Save Changes
                </Button>
              </div>
            </Fragment>
          ) : (
            <div className="dashboard-form-footer__primary">
              <Button type="button" color="success" onClick={this.handleSubmit}>
                Add Coach
              </Button>
            </div>
          )}
        </DashboardFormFooter>
      </DashboardForm>
    );
  }
}

export default TeamCoachForm;
