import React from "react";
import PropTypes from "prop-types";
import { Button, ModalBody, ModalFooter } from "reactstrap";

function pluralized(num, sport) {
  if (num === 1) return sport === 'soccer' ? "infraction" : "penalty";
  return sport === 'soccer' ? "infractions" : "penalties";
}
function Confirmation({ numAdd, numDelete, onConfirm, sport }) {
  return (
    <React.Fragment>
      <ModalBody>
          <p>
            {numDelete} {pluralized(numDelete, sport)} will be removed and {numAdd} {pluralized(numAdd, sport)} will be imported.
          </p>
      </ModalBody>
      <ModalFooter className="justify-content-center">
        <Button className="btn btn-success" type="button" onClick={onConfirm}>
          Import {sport === 'soccer' ? "Infraction" : "Penalty"} Codes
        </Button>
      </ModalFooter>
    </React.Fragment>
  );
}

Confirmation.propTypes = {
  numAdd: PropTypes.number.isRequired,
  numDelete: PropTypes.number.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default Confirmation;
