import React, { Fragment } from "react";
import PropTypes from "prop-types";

import "./CountPenalties.scss";

function CountPenalties(props) {
  const { totalCountSelected, totalCountMissing, clearSelectedPenalties, sport } = props;

  return (
    <Fragment>
      <strong>{totalCountSelected}</strong> {sport === 'soccer' ? "infraction" : "penalty"} {totalCountSelected === 1 ? "type" : "types"} selected
      {totalCountMissing > 0 && (
        <Fragment>
          {" "}
          with{" "}
          <span className="text-warning">
            <strong>{totalCountMissing}</strong> {totalCountMissing === 1 ? "warning" : "warnings"}
          </span>
        </Fragment>
      )}
      {clearSelectedPenalties &&
        totalCountSelected > 0 && (
          <Fragment>
            <span
              className="game-flagging-settings__clear-selected-btn ml-2"
              onClick={clearSelectedPenalties}
              onKeyPress={clearSelectedPenalties}
              role="button"
              tabIndex="-1"
            >
              deselect all
            </span>
          </Fragment>
        )}
    </Fragment>
  );
}

CountPenalties.propTypes = {
  totalCountSelected: PropTypes.number.isRequired,
  totalCountMissing: PropTypes.number.isRequired,
  clearSelectedPenalties: PropTypes.func
};

export default CountPenalties;
