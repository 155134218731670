import createListReducer from "@/redux/common/reports/teamMemberPenaltyReportReducers/createListReducer";

import actions from "../actions";

import { refereeReportListLoadingRoutine } from "../routines";

export default createListReducer({
  actions,
  loadingRoutine: refereeReportListLoadingRoutine,
  memberType: "referees"
});
