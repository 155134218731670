import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Input, Label } from "reactstrap";

import { FormGroup } from "reactstrap";

import FormItem from "@/components/FormItem";

class FairPlayPointInput extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.bool,
    onChange: PropTypes.func
  };

  static defaultProps = {
    onChange() {}
  };

  render() {
    const { id, name, value, onChange } = this.props;

    return (
      <FormItem>
        <FormGroup className="col-md-6">
          <Label for={id}>Fair Play Point Earned</Label>
        </FormGroup>
        <FormGroup className="col-md-6">
          <Input type="select" id={id} name={name} value={value} onChange={onChange}>
            <option disabled />
            <option key={"YES"} value={true}>
              {"YES"}
            </option>
            <option key={"NO"} value={false}>
              {"NO"}
            </option>
          </Input>
        </FormGroup>
      </FormItem>
    );
  }
}

export default FairPlayPointInput;
