const selectRoot = state => state.scheduledGameForm;

export const getIsSubmitting = state => selectRoot(state).isSubmitting;
export const getIsLoaded = state => selectRoot(state).isLoaded;
export const getIsLoading = state => selectRoot(state).isLoading;
export const getIsDeleting = state => selectRoot(state).isDeleting;

export const getRemoteValidationErrors = state => selectRoot(state).remoteValidationErrors;

export const getFields = state => {
  const { data, ...fields } = selectRoot(state).fields;
  return { ...fields, ...data };
};

export const getOneGameData = state => selectRoot(state).oneGameData;
