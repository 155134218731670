// taken from GET https://gamesheet-production.web.app/service.registrysync/v4/rest/registries/hcr/organizations

export const HCR_ORAGNIZATIONS = [
      {
        "id": "1eb5d98e-ac53-658c-b920-06ba8f7ba4e0",
        "title": "BC HOCKEY",
      },
      {
        "id": "1eb5d995-f3f1-6716-9826-06ba8f7ba4e0",
        "title": "HOCKEY ALBERTA",
      },
      {
        "id": "1eb5d9a7-b30a-615a-aa54-06ba8f7ba4e0",
        "title": "HOCKEY NEW BRUNSWICK",
      },
      {
        "id": "1eb5d9a9-df1c-6656-b377-06ba8f7ba4e0",
        "title": "HOCKEY NOVA SCOTIA",
      },
      {
        "id": "1eb5d9ad-2c91-658c-8d16-06ba8f7ba4e0",
        "title": "HOCKEY PEI",
      },
      {
        "id": "1eb5da8d-5868-69c2-8330-06ba8f7ba4e0",
        "title": "NORTHERN ONTARIO HOCKEY ASSOCIATION",
      },
      {
        "id": "1eb5da90-685c-62fe-875d-06ba8f7ba4e0",
        "title": "ALLIANCE HOCKEY",
      },
      {
        "id": "1eb5da90-bb47-6eb4-aca3-06ba8f7ba4e0",
        "title": "ONTARIO MINOR HOCKEY ASSOCIATION",
      },
      {
        "id": "1ebbdbfd-fd88-6782-a818-02ea426c0e36",
        "title": "ONTARIO SLEDGE HOCKEY ASSOCIATION"
      }
    ];