import { createRoutine } from "redux-saga-routines";

export const loadRefereesListRoutine = createRoutine("REFEREES/LIST/LOADING");

export const refereeFormFieldValidationRoutine = createRoutine("REFEREES/FORM/FIELD_VALIDATION");

export const refereeFormValidationRoutine = createRoutine("REFEREES/FORM/VALIDATION");

export const refereeFormSubmittingRoutine = createRoutine("REFEREES/FORM/SUBMITTING");

export const refereeFormLoadingRoutine = createRoutine("REFEREES/FORM/LOADING");

export const refereeFormUpdatingRoutine = createRoutine("REFEREES/FORM/UPDATING");

export const refereeFormDeletingRoutine = createRoutine("REFEREES/FORM/DELETING");

export const mergeRefereesRoutine = createRoutine("REFEREES/LIST/MERGE_TOOL/MERGING");

export const currentRefereeLoadingRoutine = createRoutine("REFEREES/CURRENT/LOADING");
