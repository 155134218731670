import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Col, Row } from "reactstrap";

import Layout from "@/layouts/Layout";
import Head from "@/components/Head";
import TitleBar from "@/components/TitleBar";

export class HandleBadMode extends PureComponent {
    static propTypes = {
        mode: PropTypes.string
    };

    static defaultProps = {
        mode: "MISSING"
    };

    render() {
        return (
            <Layout>
                <Head title="Bad Mode" />

                <Row className="justify-content-center">
                    <Col md="10" lg="6">
                        <TitleBar title="ERROR" />
                        <p>mode: "{this.props.mode}"</p>
                    </Col>
                </Row>
            </Layout>
        )
    }
}