import { all, put } from "redux-saga/effects";

import { beginSaga } from "@/redux/common/GenericSaga";

import { createCoach, loadCoach, loadCoaches, updateCoach, deleteCoach } from "@/lib/api/coaches";

import { updateTeam } from "@/lib/api/teams";

import {
  CoachCreatingRoutine,
  CurrentCoachLoadingRoutine,
  CoachListLoadingRoutine,
  CurrentCoachUpdatingRoutine,
  CurrentCoachDeletingRoutine,
  AddCoachToTeamRoutine
} from "./routines";

const makeCoachIdentity = ({ id }) => ({ type: "coaches", id });

export const loadCoachListSaga = beginSaga(CoachListLoadingRoutine)
  .withService(loadCoaches)
  .loadResources({ type: "coaches" })
  .andSucceedWith(({ coaches }) => ({
    ids: coaches ? Object.keys(coaches).map(id => id) : []
  }))
  .endSaga();

export const createCoachSaga = beginSaga(CoachCreatingRoutine)
  .withService(createCoach)
  .createResource({ type: "coaches" })
  .andRedirectTo(({ seasonId, teamId }, { coaches }) => {
    const coachId = Object.keys(coaches)[0];

    return teamId
      ? `/seasons/${seasonId}/teams/${teamId}/roster/coaches/${coachId}/edit`
      : `/seasons/${seasonId}/roster/coaches/${coachId}`;
  })
  .endSaga();

export const loadCurrentCoachSaga = beginSaga(CurrentCoachLoadingRoutine)
  .withService(loadCoach)
  .loadResource(makeCoachIdentity)
  .endSaga();

export const updateCurrentCoachSaga = beginSaga(CurrentCoachUpdatingRoutine)
  .withService(updateCoach)
  .updateResource(makeCoachIdentity)
  .andRedirectTo(({ seasonId, id }) => `/seasons/${seasonId}/roster/coaches/${id}`)
  .endSaga();

export const deleteCurrentCoachSaga = beginSaga(CurrentCoachDeletingRoutine)
  .withService(deleteCoach)
  .deleteResource(makeCoachIdentity)
  .andRedirectTo(({ seasonId }) => `/seasons/${seasonId}/roster/coaches`)
  .endSaga();

export const addCoachToTeamSaga = beginSaga(AddCoachToTeamRoutine)
  .withService(updateTeam)
  .updateResource(({ teamId }) => ({ type: "teams", id: teamId }))
  .andFinallyPerform(function*({ coachId, seasonId }) {
    yield put(CurrentCoachLoadingRoutine.trigger({ seasonId, id: coachId }));
  })
  .endSaga();

export function* coachesFlow() {
  yield all([
    loadCoachListSaga.takeLatest(),
    createCoachSaga.takeLatest(),
    loadCurrentCoachSaga.takeLatest(),
    updateCurrentCoachSaga.takeLatest(),
    deleteCurrentCoachSaga.takeLatest(),
    addCoachToTeamSaga.takeLatest()
  ]);
}
