export function countResponseGames(response) {
    // look for game-changed and game-scheduled in response.data.data

    const out = {
        created: 0,
        updated: 0
    };

    if (!("data" in response) || !("data" in response.data) || !Array.isArray(response.data.data)) {
        return out
    }

    response.data.data.forEach(event => {
        if (event.name == "game-changed") {
            out.updated += 1;
        } else if (event.name == "game-scheduled") {
            out.created += 1;
        }
    });

    return out;
}