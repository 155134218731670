import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./ContentSection.scss";

const ContentSection = ({ className, children, title, hSize }) => {
  return (
    <div className={classNames("content-section", className)}>
      {title && <h3 className={classNames("content-section__title", hSize)}>{title}</h3>}
      {children}
    </div>
  );
};

ContentSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  hSize: PropTypes.string
};

ContentSection.defaultProps = {
  className: "",
  hSize: "h5"
};

ContentSection.formRole = "section";

export default ContentSection;
