import { put, call } from "redux-saga/effects";
import { push } from "connected-react-router";

export function* GenericGenerator(action) {
  const payload = action ? action.payload : {};

  // console.log(action);

  const identity = typeof this.identityMaker === "function" ? this.identityMaker(payload) : this.identityMaker;

  const payloadWithIdentity = identity ? { identity, ...payload } : payload;

  if (this.initiallyPutFn) {
    yield put(this.initiallyPutFn(payloadWithIdentity));
  }

  try {
    yield put(this.routine.request());

    let response;

    // console.log(payloadWithIdentity);

    if (typeof this.performFn === "function") {
      response = yield this.performFn(payloadWithIdentity);
    } else {
      const requestPayload = this.performFn ? this.performFn(payloadWithIdentity) : payloadWithIdentity;

      // console.log({ saga: this.requestSaga, service: this.serviceFn, payload: requestPayload });

      response = yield call(this.requestSaga, this.serviceFn, requestPayload);
    }

    // console.log(response);

    const { data } = response || {};

    const result = this.succeedFn(data, payloadWithIdentity, response);

    yield put(this.routine.success({ ...result, ...payloadWithIdentity }));

    if (this.finalFn) {
      yield this.finalFn(payloadWithIdentity, data, result);
    } else if (this.redirectFn) {
      const url =
        typeof this.redirectFn === "function" ? this.redirectFn(payloadWithIdentity, data, result) : this.redirectFn;

      yield put(push(url));
    }
  } catch (error) {
    const result = this.failureFn(error, payloadWithIdentity);
    const responseStatus = error.response && error.response.status;

    yield put(
      this.routine.failure({
        ...result,
        error,
        responseStatus,
        errors: [error.message]
      })
    );

    console.log(error);

    // throw error;
  } finally {
    yield put(this.routine.fulfill());
  }
}
