import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Table } from "reactstrap";

import RefereeReportRefereeListMember from "./Member";

class RefereeListTable extends PureComponent {
  static propTypes = {
    referees: PropTypes.arrayOf(
      PropTypes.shape({
        externalId: PropTypes.string.isRequired
      })
    )
  };

  static defaultProps = {
    referees: []
  };

  render() {
    const { referees } = this.props;

    return (
      <Table striped borderless>
        <thead>
          <tr>
            <th scope="col">Referee</th>
            <th scope="col">External ID</th>
            <th scope="col">League</th>
          </tr>
        </thead>
        <tbody>
          {referees.map(({ externalId, ...rest }) => (
            <RefereeReportRefereeListMember key={externalId} externalId={externalId} {...rest} />
          ))}
        </tbody>
      </Table>
    );
  }
}

export default RefereeListTable;
