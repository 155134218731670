import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { loadingRoutine } from "@/redux/associationsList";

import useAssociationsList from "./useAssociationsList";

export default function useAssociationsListLoading() {
  const dispatch = useDispatch();

  const { isLoaded, isLoading, isFailed } = useAssociationsList();

  useEffect(
    () => {
      if (!isLoaded && !isLoading && !isFailed) {
        dispatch(loadingRoutine());
      }
    },
    [dispatch, isLoaded, isLoading, isFailed]
  );
}
