import React from "react";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import TitleBar from "@/components/TitleBar";
import Head from "@/components/Head";
import PageLoader from "@/components/PageLoader";

import GameReportEmailNotificationSettings, {
  useGameReportEmailNotificationSettingsLoading
} from "@/components/GameReportEmailNotificationSettings";

function EmailNotificationSettingsPage() {
  const { isLoading, isLoaded } = useGameReportEmailNotificationSettingsLoading();

  return (
    <ProtectedLayout subject="email-notification-settings" action="manage">
      {isLoaded ? (
        <React.Fragment>
          <Head title="Email Notification Settings" />
          <TitleBar title="Email Notification Settings" />
          <GameReportEmailNotificationSettings />
        </React.Fragment>
      ) : (
        <PageLoader isLoading={isLoading} />
      )}
    </ProtectedLayout>
  );
}

export default EmailNotificationSettingsPage;
