import { firebase } from "@/firebase";
import { useState, useEffect } from 'react';

export function useFirebaseToken() {

    const [token, setTokenState] = useState('');

    useEffect(() => {
        let mounted = true;
        const cancelListener = firebase.auth().onAuthStateChanged(user => {
            if (user) {
                user.getIdToken().then( _token => {
                    mounted && setTokenState(`${_token}`);
                })
            } else {
                mounted && setTokenState('')
            }
        });

        return () => {
        console.log("useFirebaseToken unmounted")

            cancelListener();
            mounted = false;
        }
    }, [])

    return {

        token

    }

}