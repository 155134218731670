export function mergeItemsToTree(state, data) {
  const updates = Object.keys(data).reduce((nextState, collectionType) => {
    const currentCollection = state[collectionType];
    const updatesToCollection = data[collectionType];
    const nextCollection = Object.assign({}, currentCollection, updatesToCollection);

    return Object.assign({}, nextState, { [collectionType]: nextCollection });
  }, {});

  const nextState = Object.assign({}, state, updates);

  return nextState;
}

export function removeItemFromTree(state, resource) {
  const collection = state[resource.type];

  if (!collection) {
    return state;
  }

  const nextCollection = Object.keys(collection)
    .filter(id => id !== resource.id)
    .reduce((obj, id) => Object.assign(obj, { [id]: collection[id] }), {});

  const nextState = Object.assign({}, state, {
    [resource.type]: nextCollection
  });

  return nextState;
}
