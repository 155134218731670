import React from "react";
import PropTypes from "prop-types";

import { Switch } from "react-router-dom";

import LoggedInRoute from "@/containers/LoggedInRoute";
import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import NewIpadKey from "./pages/NewIpadKey";
import IpadKeyScope from "./IpadKeyScope";
import IpadKeysIndex from "./pages/IpadKeysIndex";

function IpadKeys({ match: { url } }) {
  const {
    seasonId,
    seasonIsLoaded,
    seasonIsLoading,
    seasonIsForbidden,
    seasonBreadcrumbs,
    season
  } = useCurrentSeasonContext();

  return (
    <Switch>
      <LoggedInRoute path={`${url}/new`} component={NewIpadKey} />

      <LoggedInRoute path={`${url}/:keyId`} component={IpadKeyScope} />

      <LoggedInRoute
        path=""
        component={IpadKeysIndex}
        seasonId={seasonId}
        seasonIsLoaded={seasonIsLoaded}
        seasonIsLoading={seasonIsLoading}
        breadcrumbs={seasonBreadcrumbs}
        seasonIsForbidden={seasonIsForbidden}
        season={season}
      />
    </Switch>
  );
}

IpadKeys.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired
};

export default IpadKeys;
