export default function penaltyIsMajor({ length }) {
  if (["MP", "GM", "GE", "GRM", "P/PG"].includes(length.toUpperCase())) {
    return true;
  }

  const matches = length.match(/^(\d+)/gi);

  if (matches && parseInt(matches[0]) >= 5) {
    return true;
  }

  return false;
}
