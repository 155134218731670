import { all, call, put, takeLatest, select, actionChannel, take, race } from "redux-saga/effects";

import { buffers } from "redux-saga";
import { push } from "connected-react-router";

import { loadAccount } from "@/lib/api/account";
import { firebase } from "@/firebase";

import { fetchOne, gamesheetAPIRequest } from "@/redux/api/sagas";
import { loginAction } from "@/redux/login/actions";

import { accountLoadingRoutine, passwordCreatingRoutine, fieldValidatingRoutine, validatingRoutine } from "./routines";

import actions from "./actions";

import { getAttributes } from "./selectors";

import { validatePassword, validatePasswordConfirmation, validate } from "./validations";
import { updatePassword } from "@/lib/api/password";
import { setUsersFirebaseUid } from "@/lib/api/users";

function* accountLoadingSaga({ payload: verificationCode }) {
  yield put(accountLoadingRoutine.request());

  try {
    const [account] = yield fetchOne({ type: "users" }, loadAccount, {
      verificationCode
    });

    yield put(accountLoadingRoutine.success({ account }));
  } catch (error) {
    yield put(accountLoadingRoutine.failure({ error }));
  } finally {
    yield put(accountLoadingRoutine.fulfill());
  }
}

function* fieldValidatingSaga({ payload: { name, value } }) {
  yield put(fieldValidatingRoutine.request({ name, value }));

  let errors;

  switch (name) {
    case "password":
      errors = validatePassword(value);
      break;

    case "passwordConfirmation":
      errors = validatePasswordConfirmation(value);
      break;

    default:
      throw new Error(`Unknown form field ${name}`);
  }

  if (errors.length === 0) {
    yield put(fieldValidatingRoutine.success({ name, value }));
  } else {
    yield put(fieldValidatingRoutine.failure({ name, value, errors }));
  }

  yield put(fieldValidatingRoutine.fulfill({ name, value }));
}

function* validatingSaga() {
  const attributes = yield select(getAttributes);

  yield put(validatingRoutine.request());

  const errors = validate(attributes);

  if (errors) {
    yield put(validatingRoutine.failure({ errors }));
  } else {
    yield put(validatingRoutine.success());
  }

  yield put(validatingRoutine.fulfill());
}

function* passwordCreatingSaga({ payload: { verificationCode, userEmail } }) {
  yield put(passwordCreatingRoutine.request());

  try {
    const validationSuccess = yield actionChannel(validatingRoutine.SUCCESS, buffers.dropping(1));

    const validationFulfill = yield actionChannel(validatingRoutine.FULFILL, buffers.dropping(1));

    yield put(validatingRoutine());

    const { success } = yield race({
      success: take(validationSuccess),
      fulfill: take(validationFulfill)
    });

    if (!success) {
      yield put(passwordCreatingRoutine.failure());

      return;
    }

    const { password } = yield select(getAttributes);

    yield put(passwordCreatingRoutine.success());

    const userCredential = yield call(() => firebase.auth().createUserWithEmailAndPassword(userEmail, password));
    yield call(() => setUsersFirebaseUid(userEmail, userCredential.user.uid));

    yield call(gamesheetAPIRequest, updatePassword, {
      password,
      verificationCode
    });

    yield put(loginAction({ email: userEmail, password }));
    yield put(push("/"));
  } catch (error) {
    if (error.response) {
      yield put(passwordCreatingRoutine.failure({ response: error.response }));
    } else {
      yield put(passwordCreatingRoutine.failure());
    }
  } finally {
    yield put(passwordCreatingRoutine.fulfill());
  }
}

export function* accountVerificationFlow() {
  yield all([
    takeLatest(accountLoadingRoutine.TRIGGER, accountLoadingSaga),
    takeLatest(actions.changeField, fieldValidatingSaga),
    takeLatest(validatingRoutine.TRIGGER, validatingSaga),
    takeLatest(passwordCreatingRoutine.TRIGGER, passwordCreatingSaga)
  ]);
}
