import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import actions from "@/redux/reportDataSourcesSelect/actions";

import {
  getDivisionGroupList,
  getDivisionListIsLoaded,
  getDivisionListIsLoading,
  getDivisionIsSelected,
  getAllDivisionsSelected
} from "@/redux/reportDataSourcesSelect/selectors";

export default function useDivisionList() {
  const dispatch = useDispatch();

  const divisionGroups = useSelector(getDivisionGroupList);
  const isLoaded = useSelector(getDivisionListIsLoaded);
  const isLoading = useSelector(getDivisionListIsLoading);
  const isOptionSelected = useSelector(getDivisionIsSelected);
  const isAllOptionsSelected = useSelector(getAllDivisionsSelected);

  const clear = useCallback(() => dispatch(actions.divisionList.clear()), [dispatch]);

  const selectAll = useCallback(() => dispatch(actions.divisionList.selectAll()), [dispatch]);

  return {
    divisionGroups,
    isLoaded,
    isLoading,
    isOptionSelected,
    isAllOptionsSelected,
    clear,
    selectAll
  };
}
