export function throwError(error) {
  throw error;
}

/*
 * Helper function to create URL generator strings with the replaceable params.
 *
 * @example
 *  const url = defineUrl("/categories/:categoryId/products/:productId")
 *  url({ categoryId: 1, productId: 2 }) // => "/categories/1/products/2"
 *
 * @param String pattern - An URL pattern string
 *
 * @returns Function URL generator function
 */
export function defineUrl(pattern) {
  return params => Object.keys(params).reduce((url, key) => url.replace(`:${key}`, params[key]), pattern);
}
