import React from "react";
import PropTypes from "prop-types";
import GameFlagsList from "@/components/GameFlagsList";
import { makeFlagMessage } from "@/components/GameFlagsList/helpers";

const Flags = ({ flags }) => {
	const messages = flags.map((flag) => makeFlagMessage({ flag, prLink: true }));
	return messages.length > 0 ? (
		<section className="gamesheet__flags">
			<h3>Flags</h3>
			<GameFlagsList messages={messages} />
		</section>
	) : (
		""
	);
};

Flags.propTypes = {
	flags: PropTypes.arrayOf(
		PropTypes.shape({
			event: PropTypes.string.isRequired,
		})
	),
};

export default Flags;
