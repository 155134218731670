import { PureComponent } from "react";
import PropTypes from "prop-types";

class Count extends PureComponent {
  static propTypes = {
    number: PropTypes.number.isRequired,
    length: PropTypes.number.isRequired
  };

  render() {
    const { number, length } = this.props;

    return number > 0 && length > 0 ? `${number} of ${length}` : "N/A";
  }
}

export default Count;
