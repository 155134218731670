import React from "react";
import PropTypes from "prop-types";

import { Can } from "@/components/Ability";

import Layout from "../Layout";
import InsufficientPrivileges from "../InsufficientPrivileges";

function ProtectedLayout(props) {
  const { className, subject, action, condition, children, skip } = props;

  if ((subject && skip) || (condition && !subject)) {
    return <Layout className={className}>{children}</Layout>;
  } else if (subject) {
    return (
      <Can I={action} on={subject} fallback={<InsufficientPrivileges />}>
        <Layout className={className}>{children}</Layout>
      </Can>
    );
  }

  return <InsufficientPrivileges />;
}

ProtectedLayout.propTypes = {
  className: PropTypes.string,
  subject: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  action: PropTypes.string,
  condition: PropTypes.bool,
  skip: PropTypes.bool,
  children: PropTypes.node
};

ProtectedLayout.defaultProps = {
  className: null,
  subject: null,
  action: null,
  condition: true,
  skip: false,
  children: null
};

export default ProtectedLayout;
