import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import useFormWithRemoteValidationErrors from "@/hooks/useFormWithRemoteValidationErrors";

import { getMeta, getRemoteValidationErrors, submittingRoutine } from "@/redux/playerOfTheGameForm";

import { DEFAULT_VALUES } from "../constants";
import CONSTRAINTS from "../constraints";

export default function useSeasonForm(options) {
  const { initialValues = DEFAULT_VALUES, seasonId, gameId } = options;

  const dispatch = useDispatch();

  const { values, ...form } = useFormWithRemoteValidationErrors({
    initialValues,
    constraints: CONSTRAINTS,
    errorsSelector: getRemoteValidationErrors
  });

  const { isSubmitting, isSubmitted, isFailed } = useSelector(getMeta);
  const remoteValidationErrors = useSelector(getRemoteValidationErrors);
  const isUnknownError = isFailed && Object.keys(remoteValidationErrors).length === 0;

  const submit = useCallback(() => dispatch(submittingRoutine({ values, seasonId, gameId })), [
    dispatch,
    gameId,
    seasonId,
    values
  ]);

  return {
    ...form,
    values,
    isSubmitting,
    isSubmitted,
    submit,
    isUnknownError
  };
}
