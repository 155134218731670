import createTeamMemberPenaltyReportReducers from "@/redux/common/reports/createTeamMemberPenaltyReportReducer";

import actions from "./actions";

import { coachPenaltyReportListLoadingRoutine, coachPenaltyReportLoadingRoutine } from "./routines";

export default createTeamMemberPenaltyReportReducers({
  actions,
  listLoadingRoutine: coachPenaltyReportListLoadingRoutine,
  loadingRoutine: coachPenaltyReportLoadingRoutine,
  memberType: "coaches"
});
