import { handleActions } from "redux-actions";

import { leagueListLoadingRoutine as loadingRoutine } from "../routines";
import actions from "../actions";

const initialState = {};

function updateAssociationLeagues(state, associationId, data) {
  return {
    ...state,
    [associationId]: {
      ...state[associationId],
      ...data
    }
  };
}

function reduceAssociationSelected(state, { payload: associationId }) {
  return {
    ...state,
    [associationId]: {
      isLoaded: false,
      isLoading: false,
      ids: [],
      data: {},
      ...state[associationId]
    }
  };
}

function reduceLoadingRequest(state, { payload }) {
  const { associationId } = payload;

  return updateAssociationLeagues(state, associationId, { isLoading: true });
}

function reduceLoadingSuccess(state, { payload }) {
  const { associationId, ids, leagues } = payload;

  const data = leagues.reduce((result, { id, title }) => ({ ...result, [id]: { title } }), {});

  return updateAssociationLeagues(state, associationId, {
    ids,
    data,
    isLoaded: true
  });
}

function reduceLoadingFailure(state, { payload }) {
  const { associationId } = payload;

  return updateAssociationLeagues(state, associationId, { isLoaded: false });
}

function reduceLoadingFulfill(state, { payload }) {
  const { associationId } = payload;

  return updateAssociationLeagues(state, associationId, { isLoading: false });
}

export default handleActions(
  {
    [actions.setAssociationId]: reduceAssociationSelected,
    [loadingRoutine.REQUEST]: reduceLoadingRequest,
    [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [loadingRoutine.FAILURE]: reduceLoadingFailure,
    [loadingRoutine.FULFILL]: reduceLoadingFulfill,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
