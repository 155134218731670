import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { loadingRoutine } from "@/redux/userForm/routines";
import actions from "@/redux/userForm/actions";

import useForm from "@/components/UserForm/hooks/useForm";

export default function useUserForm(userId) {
  const dispatch = useDispatch();

  const { userName, isLoading, isLoaded, userStatus } = useForm(userId);

  useEffect(
    () => {
      if (userId) {
        dispatch(loadingRoutine(userId));
      }

      return () => dispatch(actions.reset());
    },
    [dispatch, userId]
  );

  return {
    userName,
    isLoaded,
    isLoading,
    userStatus
  };
}
