import { combineActions } from "redux-actions";

import { submittingRoutine, deletingRoutine } from "@/redux/scheduledGameForm";
import { actions as filterActions } from "@/redux/scheduledGamesFilter";
import { importingRoutine } from "@/redux/scheduledGamesCSVImportWizard";

import { loadingRoutine } from "../routines";
import actions from "../actions";

import createPaginationReducer from "@/redux/common/createPaginationReducer";

export default createPaginationReducer(
  loadingRoutine,
  combineActions(
    filterActions.reset,
    actions.clear,
    submittingRoutine.SUCCESS,
    deletingRoutine.SUCCESS,
    importingRoutine.FULFILL
  )
);
