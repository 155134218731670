import { put, all, select, takeLatest, takeEvery, call } from "redux-saga/effects";

import { beginSaga } from "@/redux/common/GenericSaga";

import { loadTeams, createTeam, loadTeam, updateTeam, deleteTeam, lockTeam, deleteLogo } from "@/lib/api/teams";
import { createFile } from "@/lib/api/file";

import {
  TeamListLoadingRoutine,
  BigTeamListLoadingRoutine,
  BigTeamInvitationListLoadingRoutine,
  TeamCreatingRoutine,
  CurrentTeamLoadingRoutine,
  CurrentTeamUpdatingRoutine,
  CurrentTeamDeletingRoutine,
  TeamLockingRoutine
} from "./routines";

import { ResourceLoadingRoutine } from "@/redux/resources/routines";

import { fetchList, fetchOne, gamesheetAPIRequest } from "@/redux/api/sagas";

import { getSeasonTeamList } from "./selectors";

const makeTeamIdentity = ({ id }) => ({ type: "teams", id });
const maybeRedirect = props => {
  if ("redirect" in props && typeof props.redirect === "function") {
    props.redirect();
  }
};
const callFailureCb = (err, payload) => {
  if ("failureCb" in payload && typeof payload.failureCb === "function") {
    payload.failureCb(err);
  }
};

function* uploadLogoSaga(file) {
  const {
    data: { data }
  } = yield call(gamesheetAPIRequest, createFile, {
    file
  });

  return data.attributes.url;
}

function* loadBigTeamListSaga({ payload: { seasonId, divisionId } }) {
  yield put(BigTeamListLoadingRoutine.request());

  try {
    const res = yield call(fetchList, "associations", loadTeams, {
      seasonId,
      divisionId,
      include: "divisions,invitations"
    });

    const { data, teams, ids, totalCount, filteredCount } = res;

    yield put(
      ResourceLoadingRoutine.success({
        identity: { type: "teams" },
        data
      })
    );

    yield put(
      BigTeamListLoadingRoutine.success({
        ids,
        teams,
        totalCount,
        filteredCount
      })
    );
  } catch (error) {
    const responseStatus = error.response && error.response.status;

    yield put(BigTeamListLoadingRoutine.failure({ responseStatus, error }));
  } finally {
    yield put(BigTeamListLoadingRoutine.fulfill());
  }
}

function* loadBigTeamInvitationListSaga({ payload: { seasonId, divisionId } }) {
  yield put(BigTeamInvitationListLoadingRoutine.request());

  try {
    const res = yield call(fetchList, "associations", loadTeams, {
      seasonId,
      divisionId,
      include: "divisions,invitations"
    });

    const { data, teams, ids, totalCount, filteredCount } = res;

    yield put(
      ResourceLoadingRoutine.success({
        identity: { type: "teams" },
        data
      })
    );

    yield put(
      BigTeamInvitationListLoadingRoutine.success({
        ids,
        teams,
        totalCount,
        filteredCount
      })
    );
  } catch (error) {
    const responseStatus = error.response && error.response.status;

    yield put(BigTeamInvitationListLoadingRoutine.failure({ responseStatus, error }));
  } finally {
    yield put(BigTeamInvitationListLoadingRoutine.fulfill());
  }
}

function* loadTeamListSaga({ payload: { seasonId, divisionId, pageSize, pageNumber, sort } }) {
  yield put(TeamListLoadingRoutine.request());

  try {
    const res = yield call(fetchList, "associations", loadTeams, {
      seasonId,
      divisionId,
      include: "divisions,invitations",
      pageSize,
      pageNumber,
      sort
    });

    const { data, teams, ids, totalCount, filteredCount } = res;

    yield put(
      ResourceLoadingRoutine.success({
        identity: { type: "teams" },
        data
      })
    );

    yield put(
      TeamListLoadingRoutine.success({
        ids,
        teams,
        totalCount,
        filteredCount
      })
    );
  } catch (error) {
    const responseStatus = error.response && error.response.status;

    yield put(TeamListLoadingRoutine.failure({ responseStatus, error }));
  } finally {
    yield put(TeamListLoadingRoutine.fulfill());
  }
}

function* createTeamSaga({ payload }) {
  const { seasonId, divisionId, attributes, logo } = payload;

  yield put(TeamCreatingRoutine.request());

  try {
    const logoUrl = logo.url || (logo.file instanceof File ? yield call(uploadLogoSaga, logo.file) : "");

    yield call(
      gamesheetAPIRequest,
      createTeam,
      {
        seasonId,
        divisionId,
        attributes: {
          ...attributes,
          logoUrl
        }
      },
      true
    );

    yield put(TeamCreatingRoutine.success({ ...payload }));

    maybeRedirect(payload);
  } catch (error) {
    callFailureCb(error, payload);
    yield put(TeamCreatingRoutine.failure({ error }));
  } finally {
    yield put(TeamCreatingRoutine.fulfill());
  }
}

function* loadCurrentTeamSaga({ payload: { id } }) {
  yield put(CurrentTeamLoadingRoutine.request());

  try {
    const [team] = yield fetchOne({ type: "teams", id }, loadTeam, {
      include: "associations,leagues,seasons,divisions"
    });

    yield put(CurrentTeamLoadingRoutine.success({ ...team }));
  } catch (error) {
    yield put(CurrentTeamLoadingRoutine.failure({ error }));
  } finally {
    yield put(CurrentTeamLoadingRoutine.fulfill());
  }
}

function* lockTeamSaga({ payload: { lock, teamId, successCb } }) {
  yield put(TeamLockingRoutine.request({ teamId, lock }));

  const seasonTeamList = yield select(getSeasonTeamList);

  const team = seasonTeamList.find(t => t.id === teamId);

  if (!team) {
    yield put(TeamLockingRoutine.fulfill({ teamId, lock }));
    return;
  }

  try {
    yield call(
      gamesheetAPIRequest,
      lockTeam,
      {
        teamId: team.id,
        seasonId: team.season.id,
        attributes: { lock }
      },
      true
    );

    yield put(TeamLockingRoutine.success({ teamId, lock }));

    if (successCb && typeof successCb === "function") {
      successCb();
    }
  } catch (error) {
    const { response } = error;

    yield put(
      TeamLockingRoutine.failure({
        error,
        response,
        teamId
      })
    );
  } finally {
    yield put(TeamLockingRoutine.fulfill({ teamId, lock }));
  }
}

function* updateCurrentTeamSaga({ payload }) {
  const { id, seasonId, divisionId, attributes, logo } = payload;

  yield put(CurrentTeamUpdatingRoutine.request());

  try {
    const logoUrl = logo.url || (logo.file instanceof File ? yield call(uploadLogoSaga, logo.file) : "");

    yield call(
      gamesheetAPIRequest,
      updateTeam,
      {
        seasonId,
        divisionId,
        identity: { type: "teams", id },
        attributes: {
          ...attributes,
          logoUrl
        }
      },
      true
    );

    if(logoUrl == ""){
      yield call(
        gamesheetAPIRequest,
        deleteLogo,
        {
          seasonId,
          teamId: id
        }
      )
    }

    yield put(CurrentTeamUpdatingRoutine.success());

    maybeRedirect(payload);
  } catch (error) {
    callFailureCb(error, payload);
    yield put(CurrentTeamUpdatingRoutine.failure({ error }));
  } finally {
    yield put(CurrentTeamUpdatingRoutine.fulfill());
  }
}

export const deleteCurrentTeamSaga = beginSaga(CurrentTeamDeletingRoutine)
  .withService(deleteTeam)
  .deleteResource(makeTeamIdentity)
  .andRedirectTo(({ seasonId, divisionId }) => {
    const path =
      divisionId != null ? `/seasons/${seasonId}/divisions/${divisionId}/teams` : `/seasons/${seasonId}/teams`;
    return path + location.search;
  })
  .endSaga();

export function* teamsFlow() {
  yield all([
    takeLatest(TeamListLoadingRoutine, loadTeamListSaga),
    takeLatest(TeamCreatingRoutine, createTeamSaga),
    takeLatest(CurrentTeamLoadingRoutine, loadCurrentTeamSaga),
    takeLatest(CurrentTeamUpdatingRoutine, updateCurrentTeamSaga),
    takeLatest(BigTeamListLoadingRoutine, loadBigTeamListSaga),
    takeLatest(BigTeamInvitationListLoadingRoutine, loadBigTeamInvitationListSaga),
    deleteCurrentTeamSaga.takeLatest(),
    takeEvery(TeamLockingRoutine, lockTeamSaga)
  ]);
}
