import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { Row, Col, Label, FormGroup, Button, Alert } from "reactstrap";

import TitleBar from "@/components/TitleBar";
import DashboardForm from "@/components/DashboardForm";
import DashboardFormFooter from "@/components/DashboardFormFooter";

import InvalidCodeMessage from "./InvalidCodeMessage";
import PasswordField from "./PasswordField";

import useAccountVerification from "./hooks/useAccountVerification";
import PageLoader from "../PageLoader";

function AccountVerificationForm({ code }) {
  const {
    codeIsInvalid,
    accountIsLoading,
    accountIsLoaded,
    accountIsFailed,
    passwordIsCreating,
    passwordIsFailed,
    userName,
    isNotFilled,
    isInvalid,
    isPristine,
    triggerPasswordCreating
  } = useAccountVerification(code);

  if (codeIsInvalid || accountIsFailed) {
    return <InvalidCodeMessage />;
  }

  if (!accountIsLoaded) {
    return <PageLoader isLoading={accountIsLoading} />;
  }

  return (
    <Row className="justify-content-center">
      <Col md="10" lg="6">
        <TitleBar title="Account verification" />

        {passwordIsFailed ? (
          <Alert color="danger">
            We were unable to activate your account due to unexpected error. Please try again or contact{" "}
            <a href="mailto:support@gamesheetinc.com">support@gamesheetinc.com</a>
          </Alert>
        ) : (
          <Fragment>
            <p>
              Welcome aboard, <strong>{userName}</strong>!
            </p>

            <p>Please create a password in order to activate your new account and start using GameSheet.</p>
          </Fragment>
        )}

        <DashboardForm isOperating={passwordIsCreating} onSubmit={triggerPasswordCreating}>
          <FormGroup>
            <Label for="accountVerificationFormPassword">Create password</Label>

            <PasswordField id="accountVerificationFormPassword" name="password" />
          </FormGroup>

          <FormGroup>
            <Label for="accountVerificationFormPasswordConfrmation">Confirm password</Label>

            <PasswordField id="accountVerificationFormPasswordConfrmation" name="passwordConfirmation" />
          </FormGroup>

          <DashboardFormFooter className="small-margin">
            <div className="dashboard-form-footer__primary">
              <Button
                type="submit"
                color="success"
                disabled={isPristine || isInvalid || isNotFilled}
                outline={isPristine || isInvalid || isNotFilled}
              >
                {passwordIsCreating ? "Creating Password…" : "Create Password"}
              </Button>
            </div>
          </DashboardFormFooter>
        </DashboardForm>
      </Col>
    </Row>
  );
}

AccountVerificationForm.propTypes = {
  code: PropTypes.string.isRequired
};

export default AccountVerificationForm;
