import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

import { connect } from "react-redux";

import { getIsLoggedIn } from "@/redux/app/selectors";

class LoggedOutRoute extends PureComponent {
  static propTypes = {
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
    isLoggedIn: PropTypes.bool.isRequired
  };

  render() {
    const { component: Component, isLoggedIn, ...rest } = this.props;

    return (
      <Route {...rest} render={props => (isLoggedIn ? <Redirect to="/" /> : <Component {...rest} {...props} />)} />
    );
  }
}

const mapStateToProps = state => ({
  isLoggedIn: getIsLoggedIn(state)
});

export default connect(mapStateToProps)(LoggedOutRoute);
