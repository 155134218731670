import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import InfiniteScroll from "@/components/InfiniteScroll";
import TotalCount from "@/components/TotalCount";

import TeamRosterExtenderSearchBar from "./SearchBar";
import TeamRosterExtenderTable from "./Table";

class List extends PureComponent {
  static propTypes = {
    totalCount: PropTypes.number.isRequired,
    filteredCount: PropTypes.number.isRequired,
    countPlural: PropTypes.string.isRequired,
    countSingular: PropTypes.string.isRequired,
    seasonId: PropTypes.string.isRequired,
    teamId: PropTypes.string.isRequired,
    countMembers: PropTypes.number.isRequired,
    hasMore: PropTypes.bool.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isAppending: PropTypes.bool.isRequired,
    searchPlaceholder: PropTypes.string.isRequired,
    emptyMessage: PropTypes.string.isRequired,
    endMessage: PropTypes.string.isRequired,
    loadingAction: PropTypes.func.isRequired,
    loadList: PropTypes.func.isRequired
  };

  loadMembers = () => {
    const { seasonId, loadingAction } = this.props;

    return loadingAction({ seasonId, append: false });
  };

  loadMoreMembers = () => {
    this.props.loadList({ append: true });
  };

  render() {
    const {
      countMembers,
      hasMore,
      isLoaded,
      isLoading,
      isAppending,
      searchPlaceholder,
      emptyMessage,
      endMessage,
      seasonId,
      teamId,
      totalCount,
      filteredCount,
      countPlural,
      countSingular
    } = this.props;

    return (
      <Fragment>
        <div className="mb-3">
          <TeamRosterExtenderSearchBar action={this.loadMembers} placeholder={searchPlaceholder} />
        </div>

        {isLoading ? (
          <p className="total-count">
            <span>&nbsp;</span>
          </p>
        ) : (
          <TotalCount
            plural={countPlural}
            singular={countSingular}
            totalCount={totalCount}
            filteredCount={filteredCount}
          />
        )}

        <InfiniteScroll
          dataLength={countMembers}
          loadList={this.loadMoreMembers}
          hasMore={hasMore}
          isLoaded={isLoaded}
          isLoading={isLoading}
          isAppending={isAppending}
          emptyMessage={emptyMessage}
          endMessage={endMessage}
        >
          <TeamRosterExtenderTable seasonId={seasonId} teamId={teamId} />
        </InfiniteScroll>
      </Fragment>
    );
  }
}

export default List;
