import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { listLoadingRoutine } from "@/redux/teamList/routines";

export default function useTeamList() {
  const dispatch = useDispatch();

  const loadPage = useCallback(pageNumber => dispatch(listLoadingRoutine({ pageNumber })), [dispatch]);

  return { loadPage };
}
