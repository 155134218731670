import { handleActions } from "redux-actions";

import { enableGameNoteForm, disableGameNoteForm, clearGameNotes } from "../../actions";

import { gameNoteFormSubmittingRoutine } from "../../routines";

const initialState = {
  isEnabled: false,
  isOperating: false
};

function reduceEnabled(state) {
  return { ...state, isEnabled: true };
}

function reduceDisabled(state) {
  return { ...state, isEnabled: false };
}

function reduceOperationRequest(state) {
  return { ...state, isOperating: true };
}

function reduceOperationFulfill(state) {
  return { ...state, isOperating: false };
}

function reduceOperationSuccess(state) {
  return { ...state, isEnabled: false };
}

export default handleActions(
  {
    [enableGameNoteForm]: reduceEnabled,
    [disableGameNoteForm]: reduceDisabled,
    [clearGameNotes]: reduceDisabled,
    [gameNoteFormSubmittingRoutine.REQUEST]: reduceOperationRequest,
    [gameNoteFormSubmittingRoutine.SUCCESS]: reduceOperationSuccess,
    [gameNoteFormSubmittingRoutine.FULFILL]: reduceOperationFulfill
  },
  initialState
);
