import React from 'react';

const DefaultPlayerIcon = ({ width = 160, height = 160 }) => (
  <svg width={width} height={height} viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M160 0H0V160H160V0Z" className="fill-primary" fill="#FEC304" />
    <path d="M80 112C99.8823 112 116 94.0914 116 72C116 49.9086 99.8823 32 80 32C60.1177 32 44 49.9086 44 72C44 94.0914 60.1177 112 80 112Z" fill="white" />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="160" height="160">
      <path d="M160 0H0V160H160V0Z" className="fill-primary" fill="#FEC304" />
    </mask>
    <g mask="url(#mask0)">
      <path d="M28 160C28 179.882 51.2812 196 80 196C108.719 196 132 179.882 132 160C132 140.118 108.719 124 80 124C51.2812 124 28 140.118 28 160Z" fill="white" />
    </g>
  </svg>
);

export default DefaultPlayerIcon;
