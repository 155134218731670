import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getPenaltyTypeReportIsLoaded, getPenaltyTypeReportIsForbidden } from "@/redux/penaltyTypeReport/selectors";

import { penaltyTypeReportLoadingRoutine } from "@/redux/penaltyTypeReport/routines";

import {
  divisionListLoadingRoutine,
  teamListLoadingRoutine,
  seasonListLoadingRoutine
} from "@/redux/reportDataSourcesList/routines";

import { penaltyTypeReportActions } from "@/redux/penaltyTypeReport/actions";
import dataSourcesListActions from "@/redux/reportDataSourcesList/actions";

function dataSourcesListLoadingRouting({ seasonIds, divisionIds, teamIds }) {
  if (teamIds) {
    return teamListLoadingRoutine({ ids: teamIds });
  } else if (divisionIds) {
    return divisionListLoadingRoutine({ ids: divisionIds });
  } else if (seasonIds) {
    return seasonListLoadingRoutine({ ids: seasonIds });
  } else {
    throw new Error("Can't determine the data sources loading routine.");
  }
}

export default function usePenaltyTypeReport(search) {
  const dispatch = useDispatch();

  const isLoaded = useSelector(getPenaltyTypeReportIsLoaded);
  const isForbidden = useSelector(getPenaltyTypeReportIsForbidden);

  const searchParams = new URLSearchParams(search);
  const seasonIdsParam = searchParams.get("season_ids");
  const divisionIdsParam = searchParams.get("division_ids");
  const teamIdsParam = searchParams.get("team_ids");

  const seasonIds = useMemo(() => seasonIdsParam && seasonIdsParam.split(","), [seasonIdsParam]);

  const divisionIds = useMemo(() => divisionIdsParam && divisionIdsParam.split(","), [divisionIdsParam]);

  const teamIds = useMemo(() => teamIdsParam && teamIdsParam.split(","), [teamIdsParam]);

  useEffect(
    () => {
      dispatch(
        penaltyTypeReportLoadingRoutine({
          seasonIds,
          divisionIds,
          teamIds
        })
      );

      dispatch(dataSourcesListLoadingRouting({ seasonIds, divisionIds, teamIds }));

      return () => {
        dispatch(penaltyTypeReportActions.report.clear());
        dispatch(dataSourcesListActions.clear());
      };
    },
    [dispatch, seasonIds, divisionIds, teamIds]
  );

  return {
    isLoaded,
    isForbidden
  };
}
