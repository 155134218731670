import { handleActions } from "redux-actions";

import { seasonRosterActions } from "../actions";

const initialState = {
  currentQuery: "",
  nextQuery: ""
};

const reduceQueryInput = (state, { payload: query }) => ({
  ...state,
  nextQuery: query
});

const reduceQueryCommit = state => ({
  ...state,
  currentQuery: state.nextQuery
});

export default function createSearchReducer({ setNextQuery, commit, clear }) {
  return handleActions(
    {
      [setNextQuery]: reduceQueryInput,
      [commit]: reduceQueryCommit,
      [clear]: () => ({ ...initialState }),
      [seasonRosterActions.clear]: () => ({ ...initialState })
    },
    initialState
  );
}
