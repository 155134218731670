/**
 * Spinner Component
 */

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import themeClasses from "@/utils/themeClasses";

import "./spinner.css";

const Spinner = ({ className, theme, color, size, ...props }) => {
  const mapTheme = themeClasses("spinner");
  const classes = classNames(className, "spinner", mapTheme(theme), {
    [`spinner_color_${color}`]: color,
    [`spinner_size_${size}`]: size
  });

  return <div className={classes} {...props} />;
};

Spinner.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOfType([PropTypes.string, PropTypes.string]),
  color: PropTypes.string,
  size: PropTypes.string
};

Spinner.defaultProps = {
  className: "",
  theme: "",
  color: "",
  size: ""
};

export default Spinner;
