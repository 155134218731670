import { combineReducers } from "redux";

import meta from "./meta";
import form from "./form";

export { meta, form };

export default combineReducers({
  meta,
  form
});
