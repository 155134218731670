import { handleActions } from "redux-actions";
import _camelCase from "lodash/camelCase";

import { passwordActions } from "../../actions";
import { validatePasswordRoutine, updatePasswordRoutine } from "../../routines";

const initialState = {
  currentPassword: {
    value: "",
    isDirty: false,
    error: null
  },
  password: {
    value: "",
    isDirty: false,
    error: null
  },
  passwordConfirmation: {
    value: "",
    isDirty: false,
    error: null
  }
};

const fieldNames = {
  currentPassword: "Current password",
  newPassword: "Password"
};

const changeFieldValue = (state, { payload: { field, value } }) => {
  return { ...state, [field]: { error: null, isDirty: true, value } };
};

const failValidation = (state, { payload: { errors } }) => {
  return {
    ...state,
    ...Object.entries(errors).reduce(
      (fields, [field, error]) => ({
        ...fields,
        [field]: { ...state[field], isDirty: false, error }
      }),
      {}
    )
  };
};

const failRequest = (state, { payload: { errors = [] } }) => ({
  ...state,
  ...errors.reduce((fields, { title, source: { pointer } }) => {
    const field = _camelCase(pointer.match(/\/data\/attributes\/(\w+)/)[1]);

    return {
      ...fields,
      [field]: {
        ...state[field],
        error: `${fieldNames[field] || field} ${title}`,
        isDirty: false
      }
    };
  }, {})
});

const clear = () => ({ ...initialState });

export default handleActions(
  {
    [passwordActions.edit.changeField]: changeFieldValue,
    [validatePasswordRoutine.FAILURE]: failValidation,
    [updatePasswordRoutine.FAILURE]: failRequest,
    [passwordActions.edit.clear]: clear
  },
  initialState
);
