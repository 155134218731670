export const CSVRosterImportStatus = {
  PENDING: "pending",
  PARSING: "parsing",
  PARSING_FAILED: "parsing_failed",
  PARSED: "parsed",
  IMPORTING: "importing",
  COMPLETE: "completed",
  VALIDATION_FAILED: "validation_failed"
};

export const CSVRosterImportStatusValues = Object.values(CSVRosterImportStatus);
