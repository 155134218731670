import { all, takeLatest, put } from "redux-saga/effects";

import { deleteSubscription, createSubscription } from "@/lib/api/subscriptions";

import { unsubscribingRoutine, resubscribingRoutine } from "./routines";

function* unsubscribingSaga({ payload: { token, subscriptionId } }) {
  yield put(unsubscribingRoutine.request());

  try {
    yield deleteSubscription({ unsubscribeToken: token, subscriptionId });

    yield put(unsubscribingRoutine.success());
  } catch (error) {
    const { response } = error;
    const responseStatus = response && response.status;

    yield put(unsubscribingRoutine.failure({ error, responseStatus }));
  } finally {
    yield put(unsubscribingRoutine.fulfill());
  }
}

function* resubscribingSaga({ payload: { token, teamId } }) {
  yield put(resubscribingRoutine.request());

  try {
    yield createSubscription({ subscribeToken: token, teamId });

    yield put(resubscribingRoutine.success());
  } catch (error) {
    yield put(resubscribingRoutine.failure({ error }));
  } finally {
    yield put(resubscribingRoutine.fulfill());
  }
}

export function* unsubscribeByTokenFlow() {
  yield all([
    takeLatest(unsubscribingRoutine.TRIGGER, unsubscribingSaga),
    takeLatest(resubscribingRoutine.TRIGGER, resubscribingSaga)
  ]);
}
