import { useCallback } from "react";

import useCurrentIpadKeyContext from "@/hooks/useCurrentIpadKeyContext";

export default function useRolesFieldset({ value, setValue }) {
  const test = useCurrentIpadKeyContext();
  const { ipadKeySeasons: seasons } = test;

  const cancelRemoval = useCallback(
    seasonId => {
      setValue([...value, { title: "app", level: { type: "seasons", id: seasonId } }]);
    },
    [value, setValue]
  );

  const remove = useCallback(
    seasonId => {
      setValue(value.filter(({ level }) => level.type === "seasons" && level.id !== seasonId));
    },
    [value, setValue]
  );

  const valueSeasons = value.filter(({ level }) => level.type === "seasons");

  return Object.keys(seasons).map(seasonId => {
    return {
      seasonId,
      title: seasons[seasonId].title,
      isLocked: valueSeasons.length <= 1 && valueSeasons[0].level.id === seasonId,
      isRemoved: !value.find(({ level }) => level.type === "seasons" && level.id === seasonId),
      cancelRemoval: () => cancelRemoval(seasonId),
      remove: () => remove(seasonId)
    };
  });
}
