import { handleActions } from "redux-actions";

import { refereesActions } from "../../actions";

const initialState = {
  currentQuery: "",
  nextQuery: ""
};

const reduceQueryInput = (state, { payload: query }) => ({
  ...state,
  nextQuery: query
});

const reduceQueryCommit = state => ({
  ...state,
  currentQuery: state.nextQuery
});

export default handleActions(
  {
    [refereesActions.list.search.setNextQuery]: reduceQueryInput,
    [refereesActions.list.search.commit]: reduceQueryCommit,
    [refereesActions.list.search.clear]: () => ({ ...initialState }),
    [refereesActions.list.clear]: () => ({ ...initialState })
  },
  initialState
);
