import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { Label, Input } from "reactstrap";

import { actions } from "@/redux/completedGamesFilter";

import useFilter from "./hooks/useFilter";

function DropdownInput({ name, label, children, actionName }) {
  const { value, inputId, handleInputChange } = useFilter({
    name,
    action: actions[actionName]
  });

  return (
    <Fragment>
      <Label for={inputId}>{label}</Label>
      <Input type="select" id={inputId} name={name} bsSize="sm" onChange={handleInputChange} value={value}>
        {children}
      </Input>
    </Fragment>
  );
}

DropdownInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  actionName: PropTypes.string.isRequired
};

export default DropdownInput;
