import { connect } from "react-redux";

import List from "./List";

import { getGameNotesListIsLoading, getGameNotesListIsLoaded, getGameNotesList } from "@/redux/gameNotes/selectors";

const mapStateToProps = state => ({
  isLoading: getGameNotesListIsLoading(state),
  isLoaded: getGameNotesListIsLoaded(state),
  gameNotes: getGameNotesList(state)
});

export default connect(mapStateToProps)(List);
