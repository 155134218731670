import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Table } from "reactstrap";

class Scorekeeper extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    phone: PropTypes.string
  };

  static defaultProps = {
    name: "N/A",
    phone: "N/A"
  };

  render() {
    const { name, phone } = this.props;

    return (
      <Table striped borderless size="sm">
        <thead>
          <tr>
            <th scope="colgroup" colSpan="2">
              Scorekeeper
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Name</th>
            <td className="text-uppercase">{name}</td>
          </tr>
          <tr>
            <th>Phone</th>
            <td>{phone}</td>
          </tr>
        </tbody>
      </Table>
    );
  }
}

export default Scorekeeper;
