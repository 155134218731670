import { handleActions } from "redux-actions";

import { loadSeasonPlayersRosterRoutine } from "../../routines";
import { seasonRosterActions } from "../../actions";

const initialState = {};

function reducePlayer(attributes) {
  const { id, firstName, lastName, externalId, teams, season, divisions } = attributes;

  return {
    seasonId: season.id,
    teams: (teams || []).map(({ title }) => title),
    divisions: divisions.map(({ id, title }) => ({ id, title })),
    firstName,
    lastName,
    externalId,
    id
  };
}

const reduceLoadingSuccess = (state, { payload: { players } }) =>
  players.reduce(
    (result, player) => ({
      ...result,
      [player.id]: reducePlayer(player)
    }),
    { ...state }
  );

export default handleActions(
  {
    [loadSeasonPlayersRosterRoutine.SUCCESS]: reduceLoadingSuccess,
    [seasonRosterActions.clear]: () => ({ ...initialState })
  },
  initialState
);
