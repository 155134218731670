import { useCallback } from "react";
import { useDispatch } from "react-redux";

import copyTeamsActions from "@/redux/copyTeams/actions";

export default function useCopyTeamsModalOpen({ seasonId, divisionId }) {
  const dispatch = useDispatch();

  return useCallback(() => dispatch(copyTeamsActions.modal.open({ seasonId, divisionId })), [
    dispatch,
    seasonId,
    divisionId
  ]);
}
