import React from "react";
import PropTypes from "prop-types";

import Switch from "./Switch";

function TeamSettings({ id, title, divisionTitle, isEnabled, isCommitted }) {
  return (
    <tr>
      <td>{title}</td>
      <td>{divisionTitle}</td>
      <td>
        <Switch teamId={id} checked={isEnabled} isCommitted={isCommitted} />
      </td>
    </tr>
  );
}

TeamSettings.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  divisionTitle: PropTypes.string.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  isCommitted: PropTypes.bool.isRequired
};

export default TeamSettings;
