import React from 'react';

function PaginationItem(props){

    const { text, pageNumber, onClick } = props;

    return(
        <li className="page-item">
            <button onClick={onClick} className="page-link">
                {text || pageNumber}
            </button>
        </li>
    )
}

export default PaginationItem;