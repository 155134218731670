import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { ListGroupItem, Button } from "reactstrap";

class SeasonIpadKeysManagerSelectedKey extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    toggleSelected: PropTypes.func
  };

  static defaultProps = {
    toggleSelected() {}
  };

  handleDeselect = () => {
    const { id, toggleSelected } = this.props;

    toggleSelected(id);
  };

  render() {
    const { value, description } = this.props;

    return (
      <ListGroupItem>
        <div className="d-flex align-items-center ipad-key">
          <div className="flex-fill">
            <span className="d-block weight-bold">{description}</span>
            <code>{value}</code>
          </div>
          <div className="ml-auto">
            <Button outline color="danger" type="button" size="sm" onClick={this.handleDeselect}>
              Deselect
            </Button>
          </div>
        </div>
      </ListGroupItem>
    );
  }
}

export default SeasonIpadKeysManagerSelectedKey;
