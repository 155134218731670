import { takeLatest, takeEvery, all, put, call, select } from "redux-saga/effects";
import axios from "axios";
import build from "redux-object";

import { gamesheetAPIRequest } from "@/redux/api/sagas";
import { loadPlayers, loadPlayerStats, loadGoalieStats, mergePlayers } from "@/lib/api/players";
import { loadCoaches, mergeCoaches } from "@/lib/api/coaches";
import { seasonRosterActions } from "./actions";
import { config } from "../../config";
import { ensureTokenIsFreshSaga } from "@/redux/token/sagas";

import {
  loadSeasonPlayersRosterRoutine,
  loadSeasonCoachesRosterRoutine,
  loadSeasonPlayersRosterMergeToolPlayerStats,
  loadSeasonPlayersRosterMergeToolGoalieStats,
  mergeSeasonPlayersRoutine,
  mergeSeasonCoachesRoutine
} from "./routines";

import {
  getSeasonRosterPlayersListLimit,
  getSeasonRosterPlayersListSearchCurrentQuery,
  getSeasonRosterPlayersMergeToolSeasonId,
  getSeasonRosterPlayersMergeToolPlayerStatsIsLoading,
  getSeasonRosterPlayersMergeToolPlayerStatsIsLoaded,
  getSeasonRosterPlayersMergeToolGoalieStatsIsLoading,
  getSeasonRosterPlayersMergeToolGoalieStatsIsLoaded,
  getSeasonRosterPlayersMergeToolWinner,
  getSeasonRosterPlayersMergeToolPlayerIds,
  getSeasonRosterCoachesListLimit,
  getSeasonRosterCoachesListSearchCurrentQuery,
  getSeasonRosterCoachesMergeToolSeasonId,
  getSeasonRosterCoachesMergeToolWinner,
  getSeasonRosterCoachesMergeToolCoachIds,
  getSeasonRosterCoachesListOffset,
  getSeasonRosterPlayersListOffset
} from "./selectors";

import takeConcurrently from "../common/takeConcurrently";

function* loadPlayersSaga({ payload: { seasonId, append = false } }) {
  const query = yield select(getSeasonRosterPlayersListSearchCurrentQuery);

  const limit = yield select(getSeasonRosterPlayersListLimit);
  const offset = append ? yield select(getSeasonRosterPlayersListOffset) : 0;

  yield put(loadSeasonPlayersRosterRoutine.request({ query, append }));

  const { data, rawData, meta } = yield call(gamesheetAPIRequest, loadPlayers, {
    seasonId,
    limit,
    offset,
    query,
    include: "teams,divisions"
  });

  const players = build(data, "players") || [];

  yield put(
    loadSeasonPlayersRosterRoutine.success({
      ids: rawData.map(({ id }) => id),
      totalCount: meta ? meta["total-count"] || 0 : 0,
      filteredCount: meta ? meta["filtered-count"] || 0 : 0,
      players,
      query,
      append
    })
  );

  yield put(loadSeasonPlayersRosterRoutine.fulfill({ query, append }));
}

function* loadCoachesSaga({ payload: { seasonId, append = false } }) {
  const query = yield select(getSeasonRosterCoachesListSearchCurrentQuery);

  const limit = yield select(getSeasonRosterCoachesListLimit);
  const offset = append ? yield select(getSeasonRosterCoachesListOffset) : 0;

  yield put(loadSeasonCoachesRosterRoutine.request({ query, append }));

  const { data, rawData, meta } = yield call(gamesheetAPIRequest, loadCoaches, {
    seasonId,
    limit,
    offset,
    query,
    include: "teams,divisions"
  });

  const coaches = build(data, "coaches") || [];

  yield put(
    loadSeasonCoachesRosterRoutine.success({
      ids: rawData.map(({ id }) => id),
      totalCount: meta ? meta["total-count"] || 0 : 0,
      filteredCount: meta ? meta["filtered-count"] || 0 : 0,
      coaches,
      query,
      append
    })
  );

  yield put(loadSeasonCoachesRosterRoutine.fulfill({ query, append }));
}

function* commitPlayersSearchQuerySaga({ payload: { seasonId } }) {
  yield put(loadSeasonPlayersRosterRoutine({ seasonId }));
}

function* clearPlayersSearchQuerySaga({ payload: { seasonId } }) {
  yield put(loadSeasonPlayersRosterRoutine({ seasonId }));
}

function* commitCoachesSearchQuerySaga({ payload: { seasonId } }) {
  yield put(loadSeasonCoachesRosterRoutine({ seasonId }));
}

function* clearCoachesSearchQuerySaga({ payload: { seasonId } }) {
  yield put(loadSeasonCoachesRosterRoutine({ seasonId }));
}

function* loadPlayerStatsForMergeTool({ payload: { playerId, seasonId } }) {
  yield put(loadSeasonPlayersRosterMergeToolPlayerStats.request({ playerId }));

  try {
    const accessToken = yield call(ensureTokenIsFreshSaga);
    const response = yield call(
      () => axios.get(
        `${config.BFF_API}/players/${playerId}/stats/v4`,
        {
          headers: {
            "Authorization": `Bearer ${accessToken}`,
          },
        }
      )
    );

    let gamesPlayed = 0;
    if (!!response || !!response.data || !!response.data.status || !!response.data.status == "success") {
      const data = response.data.data;
      if (!!data && !!data.length) {
        data.filter(d => d.season.id == seasonId).forEach(d => {
          gamesPlayed += d.stats.gp;
        })
      }
    }

    yield put(
      loadSeasonPlayersRosterMergeToolPlayerStats.success({
        playerId,
        gamesPlayed
      })
    );
  } catch (e) {
    yield put(loadSeasonPlayersRosterMergeToolPlayerStats.failure({ playerId }));
  } finally {
    yield put(loadSeasonPlayersRosterMergeToolPlayerStats.fulfill({ playerId }));
  }
}

function* loadGoalieStatsForMergeTool({ payload: { goalieId, seasonId } }) {
  yield put(loadSeasonPlayersRosterMergeToolGoalieStats.request({ goalieId }));

  try {
    const accessToken = yield call(ensureTokenIsFreshSaga);
    const response = yield call(
      () => axios.get(
        `${config.BFF_API}/players/${goalieId}/stats/v4`,
        {
          headers: {
            "Authorization": `Bearer ${accessToken}`,
          },
        }
      )
    );

    let gamesPlayed = 0;
    if (!!response || !!response.data || !!response.data.status || !!response.data.status == "success") {
      const data = response.data.data;
      if (!!data && !!data.length) {
        data.filter(d => d.season.id == seasonId).forEach(d => {
          gamesPlayed += d.stats.gp;
        })
      }
    }

    yield put(
      loadSeasonPlayersRosterMergeToolGoalieStats.success({
        goalieId,
        gamesPlayed
      })
    );
  } catch (e) {
    yield put(loadSeasonPlayersRosterMergeToolGoalieStats.failure({ goalieId }));
  } finally {
    yield put(loadSeasonPlayersRosterMergeToolGoalieStats.fulfill({ goalieId }));
  }
}

function* triggerPlayerStatsLoadingSaga({ payload: playerId }) {
  const seasonId = yield select(getSeasonRosterPlayersMergeToolSeasonId);

  const isLoading = yield select(getSeasonRosterPlayersMergeToolPlayerStatsIsLoading, playerId);

  const isLoaded = yield select(getSeasonRosterPlayersMergeToolPlayerStatsIsLoaded, playerId);

  if (!seasonId || isLoading || isLoaded) {
    return;
  }

  yield put(loadSeasonPlayersRosterMergeToolPlayerStats({ playerId, seasonId }));
}

function* triggerGoalieStatsLoadingSaga({ payload: goalieId }) {
  const seasonId = yield select(getSeasonRosterPlayersMergeToolSeasonId);

  const isLoading = yield select(getSeasonRosterPlayersMergeToolGoalieStatsIsLoading, goalieId);

  const isLoaded = yield select(getSeasonRosterPlayersMergeToolGoalieStatsIsLoaded, goalieId);

  if (!seasonId || isLoading || isLoaded) {
    return;
  }

  yield put(loadSeasonPlayersRosterMergeToolGoalieStats({ goalieId, seasonId }));
}

function* mergeSeasonPlayersSaga() {
  yield put(mergeSeasonPlayersRoutine.request());

  const seasonId = yield select(getSeasonRosterPlayersMergeToolSeasonId);
  const { id: winnerId, firstName, lastName, externalId } = yield select(getSeasonRosterPlayersMergeToolWinner);
  const playerIds = yield select(getSeasonRosterPlayersMergeToolPlayerIds);

  try {
    yield call(gamesheetAPIRequest, mergePlayers, {
      seasonId,
      winnerId,
      firstName,
      lastName,
      externalId,
      playerIds
    });

    yield put(mergeSeasonPlayersRoutine.success());
    yield put(loadSeasonPlayersRosterRoutine({ seasonId }));
  } catch (e) {
    yield put(mergeSeasonPlayersRoutine.failure());
  } finally {
    yield put(mergeSeasonPlayersRoutine.fulfill());
  }
}

function* mergeSeasonCoachesSaga() {
  yield put(mergeSeasonCoachesRoutine.request());

  const seasonId = yield select(getSeasonRosterCoachesMergeToolSeasonId);
  const { id: winnerId, firstName, lastName, externalId } = yield select(getSeasonRosterCoachesMergeToolWinner);
  const coachIds = yield select(getSeasonRosterCoachesMergeToolCoachIds);

  try {
    yield call(gamesheetAPIRequest, mergeCoaches, {
      seasonId,
      winnerId,
      firstName,
      lastName,
      externalId,
      coachIds
    });

    yield put(mergeSeasonCoachesRoutine.success());
    yield put(loadSeasonCoachesRosterRoutine({ seasonId }));
  } catch (e) {
    yield put(mergeSeasonCoachesRoutine.failure());
  } finally {
    yield put(mergeSeasonCoachesRoutine.fulfill());
  }
}

export function* seasonRosterFlow() {
  yield all([
    takeLatest(loadSeasonPlayersRosterRoutine.TRIGGER, loadPlayersSaga),
    takeLatest(loadSeasonCoachesRosterRoutine.TRIGGER, loadCoachesSaga),
    takeLatest(seasonRosterActions.players.search.commit, commitPlayersSearchQuerySaga),
    takeLatest(seasonRosterActions.players.search.clear, clearPlayersSearchQuerySaga),
    takeLatest(seasonRosterActions.coaches.search.commit, commitCoachesSearchQuerySaga),
    takeLatest(seasonRosterActions.coaches.search.clear, clearCoachesSearchQuerySaga),
    takeConcurrently(loadSeasonPlayersRosterMergeToolPlayerStats.TRIGGER, loadPlayerStatsForMergeTool),
    takeConcurrently(loadSeasonPlayersRosterMergeToolGoalieStats.TRIGGER, loadGoalieStatsForMergeTool),
    takeEvery(seasonRosterActions.players.mergeTool.selectPlayer, triggerPlayerStatsLoadingSaga),
    takeEvery(seasonRosterActions.players.mergeTool.selectPlayer, triggerGoalieStatsLoadingSaga),
    takeLatest(mergeSeasonPlayersRoutine.TRIGGER, mergeSeasonPlayersSaga),
    takeLatest(mergeSeasonCoachesRoutine.TRIGGER, mergeSeasonCoachesSaga)
  ]);
}
