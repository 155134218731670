import { handleActions } from "redux-actions";

import { loadingRoutine } from "../routines";
import actions from "../actions";

const initialState = {
    data: {},
    status: ""
};

function reduceLoadingSuccess(_state, { payload: { game } }) {
  const { data, status } = game;
  return {
    data,
    status: status || 'scheduled'
  };
}

export default handleActions(
  {
    [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);