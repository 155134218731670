import { getCoachPositionTranslator } from "@/redux/currentSeason/selectors";

import { getResources, getResource } from "@/redux/resources/selectors";
import { getCurrentTeam, getCurrentTeamBreadcrumbs } from "@/redux/teams/selectors";

import { getCurrentSeason } from "@/redux/seasons/selectors";

import { getSeasonRosterBreadcrumbs } from "@/redux/seasonRoster/selectors";

const rootSelector = state => state.coaches;
const listSelector = state => rootSelector(state).list;
const newSelector = state => rootSelector(state).new;
const currentSelector = state => rootSelector(state).current;
const teamFormSelector = state => rootSelector(state).teamForm;

export const isCoachesListLoaded = state => listSelector(state).isLoaded;
export const isCoachesListLoading = state => listSelector(state).isLoading;
export const getCoachesList = state => {
  if (isCoachesListLoaded(state)) {
    return getResources(state, "coaches", listSelector(state).ids);
  }
};

export const isCoachCreating = state => newSelector(state).isCreating;

export const isCurrentCoachLoaded = state => currentSelector(state).isLoaded;
export const isCurrentCoachForbidden = state => currentSelector(state).isForbidden;

export const isCurrentCoachArchived = state => currentSelector(state).isArchived;

export const isCurrentCoachOperating = state => {
  const operation = currentSelector(state).operation;

  return typeof operation === "string" && operation.length > 0;
};

export const isCurrentCoachReady = state => isCurrentCoachLoaded(state) && !currentSelector(state).isDeleted;

export const isCurrentCoachDeleting = state => currentSelector(state).operation === "deleting";

export const getCurrentCoach = state =>
  isCurrentCoachReady(state) ? getResource(state, "coaches", currentSelector(state).id) : undefined;

export const getCurrentCoachFullName = state => {
  if (isCurrentCoachLoaded(state)) {
    const { firstName, lastName } = getCurrentCoach(state);

    return `${firstName} ${lastName}`;
  }
};

export const getCurrentTeamCoach = state => {
  const team = getCurrentTeam(state);
  const coach = getCurrentCoach(state);
  const coachInRoster = team && coach ? (team.roster.coaches || []).find(item => item.id === coach.id) : undefined;

  if (coachInRoster) {
    const { id, firstName, lastName, externalId } = coach;

    return {
      id,
      firstName,
      lastName,
      externalId,
      position: coachInRoster.position
    };
  }
};

export const getSeasonCoachTeams = state => {
  if (!isCurrentCoachReady(state)) {
    return [];
  }

  const coach = getCurrentCoach(state);

  const teams = coach.teams || [];

  const coachPosition = getCoachPositionTranslator(state);

  return teams.filter(({ roster }) => "coaches" in roster).map(({ id, title, roster, season, division }) => {
    const rosterCoach = (roster ? roster.coaches : []).find(item => item.id === coach.id) || {};

    return {
      id,
      title,
      seasonId: season.id,
      divisionId: division.id,
      position: coachPosition(rosterCoach.position)
    };
  });
};

export const getCurrentTeamCoachBreadcrumbs = state => {
  const team = getCurrentTeam(state);
  const coach = getCurrentCoach(state);
  const teamBreadcrumbs = getCurrentTeamBreadcrumbs(state);

  if (team && coach && teamBreadcrumbs) {
    return [
      ...teamBreadcrumbs,
      {
        url: `/teams/${team.id}/roster/coaches/${coach.id}`,
        title: getCurrentCoachFullName(state)
      }
    ];
  }
};

export const getCurrentSeasonCoachBreadcrumbs = state => {
  const season = getCurrentSeason(state);
  const coach = getCurrentCoach(state);
  const seasonRosterBreadcrumbs = getSeasonRosterBreadcrumbs(state, "coaches");

  if (season && coach && seasonRosterBreadcrumbs) {
    return [
      ...seasonRosterBreadcrumbs,
      {
        url: `/seasons/${season.id}/roster/coaches/${coach.id}`,
        title: getCurrentCoachFullName(state)
      }
    ];
  }
};

export const isCurrentCoachTeamFormDisplaying = state => teamFormSelector(state).display;

export const getCurrentCoachTeamFormValues = state => teamFormSelector(state).values;

export const isCurrentCoachTeamFormOperating = state => teamFormSelector(state).isOperating;

export const getCurrentCoachTeamIds = state => {
  if (isCurrentCoachLoaded(state)) {
    return (getCurrentCoach(state).teams || []).map(({ id }) => id);
  }

  return [];
};
