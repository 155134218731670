import { handleActions } from "redux-actions";

import { accountLoadingRoutine, passwordCreatingRoutine } from "../routines";
import actions from "../actions";

const initialState = {
  accountIsLoading: false,
  accountIsLoaded: false,
  accountIsFailed: false,
  passwordIsCreating: false,
  passwordIsFailed: false
};

function reduceAccountLoadingRequest(state) {
  return { ...state, accountIsLoading: true };
}

function reduceAccountLoadingSuccess(state) {
  return { ...state, accountIsLoaded: true };
}

function reduceAccountLoadingFailure(state) {
  return { ...state, accountIsFailed: true };
}

function reduceAccountLoadingFulfill(state) {
  return { ...state, accountIsLoading: false };
}

function reducePasswordCreatingRequest(state) {
  return { ...state, passwordIsCreating: true };
}

function reducePasswordCreatingFailure(state) {
  return { ...state, passwordIsFailed: true };
}

function reducePasswordCreatingFulfill(state) {
  return { ...state, passwordIsCreating: false };
}

export default handleActions(
  {
    [accountLoadingRoutine.REQUEST]: reduceAccountLoadingRequest,
    [accountLoadingRoutine.SUCCESS]: reduceAccountLoadingSuccess,
    [accountLoadingRoutine.FAILURE]: reduceAccountLoadingFailure,
    [accountLoadingRoutine.FULFILL]: reduceAccountLoadingFulfill,
    [passwordCreatingRoutine.REQUEST]: reducePasswordCreatingRequest,
    [passwordCreatingRoutine.FAILURE]: reducePasswordCreatingFailure,
    [passwordCreatingRoutine.FULFILL]: reducePasswordCreatingFulfill,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
