import React from 'react';
import { RecoilRoot } from 'recoil';

import ScheduledGameLocationInput from './ScheduledGameLocationInput';

const Container = (props) => {
  return (
    <RecoilRoot>
      <ScheduledGameLocationInput {...props} />
    </RecoilRoot>
  )
}

export default Container;