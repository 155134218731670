import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Table } from "reactstrap";

import Player from "./components/Player";
import InjuredPlayer from "./components/InjuredPlayer";

class PlayersLineup extends PureComponent {
  static propTypes = {
    caption: PropTypes.string,
    players: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired),
    injuredPlayers: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired)
  };

  static defaultProps = {
    caption: "Lineup",
    players: [],
    injuredPlayers: []
  };

  render() {
    const { caption, players, injuredPlayers } = this.props;

    return (
      <Table striped borderless size="sm">
        <thead>
          <tr>
            <th scope="colgroup" colSpan="3">
              {caption}
            </th>
          </tr>
          <tr>
            <th scope="col">No.</th>
            <th scope="col">Name</th>
            <th scope="col">
              <span className="visuallyhidden">Note</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {players.map(player => (
            <Player key={player.id} {...player} />
          ))}
          {injuredPlayers.map(player => (
            <InjuredPlayer key={player.id} {...player} />
          ))}
        </tbody>
      </Table>
    );
  }
}

export default PlayersLineup;
