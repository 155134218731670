import { createActions } from "redux-actions";

const { suspensionReport } = createActions({
  SUSPENSION_REPORT: {
    REPORT: {
      CLEAR: undefined,
      TOGGLE_SORTING: undefined
    }
  }
});

export default suspensionReport;
