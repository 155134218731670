import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { ListGroup } from "reactstrap";

import PageLoader from "@/components/PageLoader";
import EmptyListMessage from "@/components/EmptyListMessage";

import SeasonIpadKeysManagerKeyFilter from "../KeyFilter";
import SeasonIpadKeysManagerKey from "../Key";

import "./styles/KeysList.scss";

class SeasonIpadKeysManagerKeysList extends PureComponent {
  static propTypes = {
    keys: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })),
    totalKeysAmount: PropTypes.number,
    isLoading: PropTypes.bool,
    isLoaded: PropTypes.bool
  };

  static defaultProps = {
    keys: [],
    totalKeysAmount: 0,
    isLoading: false,
    isLoaded: false
  };

  render() {
    const { isLoading, isLoaded, keys, totalKeysAmount } = this.props;

    return (
      <PageLoader isLoading={isLoading} isLoaded={isLoaded}>
        <SeasonIpadKeysManagerKeyFilter />

        <div className="season-ipad-keys-manager__keys-list">
          {isLoaded &&
            (keys.length > 0 ? (
              <ListGroup>
                {keys.map(key => (
                  <SeasonIpadKeysManagerKey key={key.id} {...key} />
                ))}
              </ListGroup>
            ) : (
              <EmptyListMessage>{totalKeysAmount ? "No matching keys found" : "No keys found"}</EmptyListMessage>
            ))}
        </div>
      </PageLoader>
    );
  }
}

export default SeasonIpadKeysManagerKeysList;
