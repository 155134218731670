import { useSelector } from "react-redux";

import { getList, getTotalCount, getIsLoaded, getIsLoading, getIsFailed } from "@/redux/associationsList";

export default function useAssociationsList() {
  const associations = useSelector(getList);
  const totalCount = useSelector(getTotalCount);
  const isLoaded = useSelector(getIsLoaded);
  const isLoading = useSelector(getIsLoading);
  const isFailed = useSelector(getIsFailed);

  return {
    associations,
    totalCount,
    isLoaded,
    isLoading,
    isFailed
  };
}
