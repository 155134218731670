import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import "./Bubble.scss";

class Bubble extends PureComponent {
  static propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  };

  render() {
    const { text } = this.props;

    return <span className="notes-bubble">{text}</span>;
  }
}

export default Bubble;
