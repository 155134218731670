import { connect } from "react-redux";

import { getSeasonRosterPlayersMergeToolStatus } from "@/redux/seasonRoster/selectors";
import { seasonRosterActions } from "@/redux/seasonRoster/actions";

import PlayersListMergeTool from "./MergeTool";

const mapStateToProps = state => ({
  status: getSeasonRosterPlayersMergeToolStatus(state)
});

const mapDispatchToProps = dispatch => ({
  setStatus: status => dispatch(seasonRosterActions.players.mergeTool.setStatus(status))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayersListMergeTool);
