import { handleActions } from "redux-actions";

import { loadGameFormRoutine, submitGameFormRoutine, deleteGameFormRoutine } from "../routines";

import actions from "../actions";

const initialState = {
  isLoading: false,
  isLoaded: false,
  isWorking: false,
  isForbidden: false,
  isDeleting: false,
  isPristine: true,
  error: null
};

const notPristine = state => ({ ...state, isPristine: false });

export default handleActions(
  {
    [loadGameFormRoutine.REQUEST]: state => ({ ...state, isLoading: true }),
    [loadGameFormRoutine.SUCCESS]: state => ({ ...state, isLoaded: true }),
    [loadGameFormRoutine.FAILURE]: (state, { payload: { responseStatus } }) => ({
      ...state,
      isForbidden: responseStatus === 401
    }),
    [loadGameFormRoutine.FULFILL]: state => ({ ...state, isLoading: false }),
    [submitGameFormRoutine.REQUEST]: state => ({ ...state, isWorking: true }),
    [submitGameFormRoutine.FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [submitGameFormRoutine.FULFILL]: state => ({ ...state, isWorking: false }),
    [deleteGameFormRoutine.REQUEST]: state => ({ ...state, isDeleting: true }),
    [deleteGameFormRoutine.FULFILL]: state => ({ ...state, isDeleting: false }),
    [actions.changeField]: state => ({ ...state, error: null, isPristine: false }),
    [actions.changePenalty]: notPristine,
    [actions.changeFairPlayEarned]: notPristine,
    [actions.addPenalty]: notPristine,
    [actions.removePenalty]: notPristine,
    [actions.changeGoal]: notPristine,
    [actions.addGoal]: notPristine,
    [actions.removeGoal]: notPristine,
    [actions.changeGoalieShift]: notPristine,
    [actions.addGoalieShift]: notPristine,
    [actions.removeGoalieShift]: notPristine,
    [actions.setShots]: notPristine,
    [actions.changeShootout]: notPristine,
    [actions.addShootout]: notPristine,
    [actions.removeShootout]: notPristine,
    [actions.updatePlayer]: notPristine,
    [actions.addPlayer]: notPristine,
    [actions.removePlayer]: notPristine,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
