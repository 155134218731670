import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import AbilityButton from "@/components/AbilityButton";

class ListItem extends PureComponent {
  static propTypes = {
    seasonId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    description: PropTypes.string
  };

  static defaultProps = {
    description: "",
    allowEdit: false
  };

  render() {
    const { id, value, description, seasonId } = this.props;

    return (
      <tr className="dashboard-table-item">
        <td className="season-ipad-keys-list__table__key">
          <code>{value}</code>
        </td>
        <td>
          <div className="dashboard-table-item__actions">
            <AbilityButton
              subject={{ type: "ipadKeys", id }}
              action="update"
              to={`/seasons/${seasonId}/ipad-keys/${id}/edit`}
              tag={Link}
              size="sm"
              color="primary"
              className="dashboard-table-item__action dashboard-table-item__action--hoverable"
              outline
            >
              Edit
            </AbilityButton>
          </div>
        </td>
        <td>{description}</td>
      </tr>
    );
  }
}

export default ListItem;
