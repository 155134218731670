import { connect } from "react-redux";

import { refereesActions } from "@/redux/referees/actions";

import RefereesListMergeToolReferee from "./Referee";

const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  chooseAsWinner: () => dispatch(refereesActions.list.mergeTool.chooseWinner(ownProps.id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RefereesListMergeToolReferee);
