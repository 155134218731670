import React from "react";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import Head from "@/components/Head";
import TitleBar from "@/components/TitleBar";
import Breadcrumbs from "@/components/Breadcrumbs";
import PageLoader from "@/components/PageLoader";

import ArchivedSeasonsList, { useSeasonsList, useSeasonsListLoading } from "@/components/ArchivedSeasonsList";

import useCurrentLeagueContext from "@/hooks/useCurrentLeagueContext";

import { useOTP } from "@/lib/core/useOTP";

function ArchivedSeasonsIndex() {
  useSeasonsListLoading();

  const { leagueIsLoaded, leagueIsLoading, leagueBreadcrumbs, league } = useCurrentLeagueContext();

  const { isListLoaded, isListLoading, isListForbidden } = useSeasonsList();

  const { otp } = useOTP();

  return (
    <ProtectedLayout condition={!isListForbidden} className="seasons-page">
      <PageLoader isLoaded={leagueIsLoaded} isLoading={leagueIsLoading}>
        {league && <Head title={`${league.title} Archived Seasons`} />}

        <Breadcrumbs items={leagueBreadcrumbs} lastItemNoUrl />
        <TitleBar title="Archived Seasons" />

        {isListLoaded ? <ArchivedSeasonsList otp={otp} /> : <PageLoader isLoading={isListLoading} />}
      </PageLoader>
    </ProtectedLayout>
  );
}

export default ArchivedSeasonsIndex;
