import React from "react";
import PropTypes from "prop-types";

import { Switch } from "react-router-dom";

import LoggedInRoute from "@/containers/LoggedInRoute";

import SeasonScope from "./SeasonScope";

function SeasonsRoute({ match: { url } }) {
  return (
    <Switch>
      <LoggedInRoute path={`${url}/:seasonId`} component={SeasonScope} />
    </Switch>
  );
}

SeasonsRoute.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired
};

export default SeasonsRoute;
