import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Row, FormGroup } from "reactstrap";

import actions from "@/redux/teamFilter/actions";
import { getCurrentHockeyYear } from "@/components/HockeyYearField/DateUtilities";

import DashboardForm from "@/components/DashboardForm";
import AssociationSelect from "./AssociationSelect";
import LeagueSelect from "./LeagueSelect";
import SeasonSelect from "./SeasonSelect";
import DivisionSelect from "./DivisionSelect";
import YearSelect from "./YearSelect";
import QueryInput from "./QueryInput";
import ResetButton from "./ResetButton";

import "./Filter.scss";

function Filter({ className, disallowSeasonId }) {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(actions.setYear(getCurrentHockeyYear()));
  }, []);

  return (
    <DashboardForm className={classNames("team-filter", className)}>
      <Row form>
        <FormGroup className="col-md-3">
          <AssociationSelect />
        </FormGroup>

        <FormGroup className="col-md-3">
          <LeagueSelect />
        </FormGroup>

        <FormGroup className="col-md-3">
          <SeasonSelect disallowIds={[disallowSeasonId]} />
        </FormGroup>

        <FormGroup className="col-md-3">
          <DivisionSelect />
        </FormGroup>
      </Row>

      <Row form>
        <FormGroup className="col-md-3">
          <YearSelect />
        </FormGroup>
        <FormGroup className="col-md-9">
          <QueryInput />
        </FormGroup>
      </Row>

      <ResetButton />
    </DashboardForm>
  );
}

Filter.propTypes = {
  className: PropTypes.string,
  disallowSeasonId: PropTypes.string
};

Filter.defaultProps = {
  className: "",
  disallowSeasonId: ""
};

export default Filter;
