import React, { Fragment } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { Input } from 'reactstrap';

import useQueryInput from './useQueryInput';

const QueryInput = ({ value, setValue, isSearchStalled, refine, clear }) => {
  const { query, setQuery, gotFocus } = useQueryInput({ value, setValue, refine, clear });

  return (
    <Fragment>
      <Input
        type="search"
        value={query}
        onChange={e => setQuery(e.currentTarget.value)}
        onFocus={gotFocus}
      />
    </Fragment>
  )
}

export default connectSearchBox(QueryInput);