const selectRoot = state => state.currentSeason;
const selectData = state => selectRoot(state).data;
const selectMeta = state => selectRoot(state).meta;

export const getMeta = state => selectMeta(state);
export const getSeason = state => selectData(state);

export const getPenaltyClassOptions = state => {
    const { penaltySettings } = getSeason(state);
    return penaltySettings.penaltyClasses;
}

export const getPlayerPositionTranslator = (state) => {
    const { playerPositions } = getSeason(state);
    return (code) => {
        if (!playerPositions) return code;
        const position = playerPositions.find(p => p.key === code);
        return position ? position.value : code;
    }
}

export const getCoachPositionTranslator = (state) => {
    const { coachPositions } = getSeason(state);
    return (code) => {
        if (!coachPositions) return code;
        const position = coachPositions.find(p => p.key === code);
        return position ? position.value : code;
    }
}
