import { handleActions } from "redux-actions";

import { divisionListLoadingRoutine as loadingRoutine } from "../routines";
import actions from "../actions";

const initialState = {};

function updateSeasonDivisions(state, seasonId, data) {
  return {
    ...state,
    [seasonId]: {
      ...state[seasonId],
      ...data
    }
  };
}

function reduceSeasonSelected(state, { payload: seasonId }) {
  return {
    ...state,
    [seasonId]: {
      isLoaded: false,
      isLoading: false,
      ids: [],
      data: {},
      ...state[seasonId]
    }
  };
}

function reduceLoadingRequest(state, { payload }) {
  const { seasonId } = payload;

  return updateSeasonDivisions(state, seasonId, { isLoading: true });
}

function reduceLoadingSuccess(state, { payload }) {
  const { seasonId, ids, divisions } = payload;

  const data = divisions.reduce((result, { id, title }) => ({ ...result, [id]: { title } }), {});

  return updateSeasonDivisions(state, seasonId, {
    ids,
    data,
    isLoaded: true
  });
}

function reduceLoadingFailure(state, { payload }) {
  const { seasonId } = payload;

  return updateSeasonDivisions(state, seasonId, { isLoaded: false });
}

function reduceLoadingFulfill(state, { payload }) {
  const { seasonId } = payload;

  return updateSeasonDivisions(state, seasonId, { isLoading: false });
}

export default handleActions(
  {
    [actions.setSeasonId]: reduceSeasonSelected,
    [loadingRoutine.REQUEST]: reduceLoadingRequest,
    [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [loadingRoutine.FAILURE]: reduceLoadingFailure,
    [loadingRoutine.FULFILL]: reduceLoadingFulfill,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
