import React from "react";

import { useScheduledGamesCSVImportWizard } from "./hooks";

import Parser from "./Parser";
import Progress from "./Progress";
import Result from "./Result";

import "./Wizard.scss";

function Wizard() {
  const { status } = useScheduledGamesCSVImportWizard();

  switch (status) {
    case "pending":
    case "parsing":
      return <Parser />;
    case "importing":
      return <Progress />;
    case "fulfilled":
      return <Result />;
    default:
      return null;
  }
}

export default Wizard;
