import React from "react";
import PropTypes from "prop-types";

import { Row, Col, FormGroup } from "reactstrap";

import DashboardForm from "@/components/DashboardForm";

import DateInput from "./DateInput";
import DivisionInput from "./DivisionInput";
import QueryInput from "./QueryInput";
import ResetButton from "./ResetButton";

import "./ScheduledGamesFilter.scss";

function ScheduledGamesFilter({ disabled }) {
  if (disabled) {
    return null;
  }

  return (
    <DashboardForm className="scheduled-games-page__filter">
      <Row>
        <Col md="5" lg="3">
          <Row form>
            <FormGroup className="col-md-6">
              <DateInput label="Scheduled after" name="scheduledDateFrom" />
            </FormGroup>

            <FormGroup className="col-md-6">
              <DateInput label="Scheduled before" name="scheduledDateTo" />
            </FormGroup>
          </Row>
        </Col>

        <Col md="7" lg="3">
          <Row form>
            <FormGroup className="col-md-12">
              <DivisionInput />
            </FormGroup>
          </Row>
        </Col>

        <Col md="12" lg="6">
          <FormGroup className="scheduled-games-page__filter__search">
            <QueryInput />
          </FormGroup>
        </Col>
      </Row>

      <ResetButton />
    </DashboardForm>
  );
}

ScheduledGamesFilter.propTypes = {
  disabled: PropTypes.bool
};

ScheduledGamesFilter.defaultProps = {
  disabled: false
};

export default ScheduledGamesFilter;
