import { useSelector } from "react-redux";

import {
  getSelectedSeasonIds,
  getSelectedDivisionIds,
  getSelectedTeamIds,
  getSelectedPenalties
} from "@/redux/reportDataSourcesSelect/selectors";

function makeParam(key, val) {
  const searchParams = new URLSearchParams();
  searchParams.set(key, val);
  return searchParams.toString();
}

function makeUrl(url, paramName, ids) {
  return url + `?` + makeParam(paramName, ids);
}

export default function useSubmitButton({ url, extended, singleSelect, penalties }) {
  const seasonIds = useSelector(getSelectedSeasonIds);
  const divisionIds = useSelector(getSelectedDivisionIds);
  const teamIds = useSelector(getSelectedTeamIds);
  const selectedPenalties = useSelector(getSelectedPenalties);

  if (penalties) {
    if (selectedPenalties.length > 0) {
      const penaltyCodes = selectedPenalties.map(({ code }) => code);
      return {
        url: `${url}?${makeParam("season_id", seasonIds)}&${makeParam("penalty_codes", penaltyCodes)}`
      };
    }
  } else {
    if (teamIds.length > 0) {
      return {
        url: makeUrl(url, singleSelect ? "team_id" : "team_ids", teamIds)
      };
    } else if (divisionIds.length > 0) {
      return {
        url: makeUrl(url, singleSelect ? "division_id" : "division_ids", divisionIds)
      };
    } else if (seasonIds.length > 0) {
      return {
        url: makeUrl(url, singleSelect ? "season_id" : "season_ids", seasonIds)
      };
    }
  }

  return { disabled: true };
}
