import React from "react";
import { CustomInput } from "reactstrap";

import useGlobalUserCheckbox from "./hooks/useGlobalUserCheckbox";

function GlobalUserCheckbox() {
    const { roleIsGlobal, setRoleIsGlobal } = useGlobalUserCheckbox();

    return (
        <CustomInput
            id={`role-form__global-user`}
            type="checkbox"
            label={<span>Global User</span>}
            checked={roleIsGlobal}
            onChange={e => setRoleIsGlobal(e.target.checked)}
        />
    );
}

export default GlobalUserCheckbox;
