import React, { useEffect, useState } from "react";
import ProgressBar from "@/components-16.8/ProgressBar";
import { useRosterImport } from "@/components-16.8/RosterImportWizard/hooks/useRosterImport";

function ImportingProgress(){

  const { players, coaches, teamUpdatesAfterImport, playerImportProgress, coachImportProgress, teamUpdateProgress } = useRosterImport();
  const [requestedPlayers, setRequestedPlayers] = useState(0);
  const [requestedCoaches, setRequestedCoaches] = useState(0);
  const [requestedTeams, setRequestedTeams] = useState(0);

  useEffect(() => {
    const playersImporting = playerImportProgress.sent - (playerImportProgress.pending + playerImportProgress.resolved + playerImportProgress.rejected);
    const coachesImporting = coachImportProgress.sent - (coachImportProgress.pending + coachImportProgress.resolved + coachImportProgress.rejected);
    const teamsUpdating = teamUpdateProgress.sent - (teamUpdateProgress.pending + teamUpdateProgress.resolved + teamUpdateProgress.rejected);

    setRequestedPlayers( playersImporting );
    setRequestedCoaches( coachesImporting );
    setRequestedTeams( teamsUpdating );

  }, [ JSON.stringify(playerImportProgress), JSON.stringify(coachImportProgress), JSON.stringify(teamUpdateProgress) ])

  return (
    <>
      <ProgressBar
        title={`Players ( ${playerImportProgress.resolved} Imported of ${players.length} )`}
        total={players.length}
        pending={playerImportProgress.pending}
        requested={requestedPlayers}
        success={ playerImportProgress.resolved}
        failed={ playerImportProgress.rejected}
      />

      <ProgressBar
        title={`Coaches ( ${coachImportProgress.resolved} Imported of ${coaches.length} )`}
        total={coaches.length}
        pending={coachImportProgress.pending}
        requested={requestedCoaches}
        success={ coachImportProgress.resolved}
        failed={ coachImportProgress.rejected}
      />

      <ProgressBar
        title={`Team Rosters ( ${teamUpdateProgress.resolved} Updated of ${teamUpdatesAfterImport.length} )`}
        total={teamUpdatesAfterImport.length}
        pending={teamUpdateProgress.pending}
        requested={requestedTeams}
        success={ teamUpdateProgress.resolved}
        failed={ teamUpdateProgress.rejected}
      />
    </>

  )

}

export default ImportingProgress;
