import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";

import { Popover, PopoverBody } from "reactstrap";

function UnauthorizedResource({ resourceType }) {
  const [el, setEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const showPopover = useCallback(
    ({ currentTarget }) => {
      setEl(currentTarget);
      setIsOpen(true);
    },
    [setEl, setIsOpen]
  );

  const hidePopover = useCallback(() => setIsOpen(false), [setIsOpen]);

  return (
    <span>
      {resourceType}
      :&nbsp;
      <span
        onFocus={showPopover}
        onMouseOver={showPopover}
        onBlur={hidePopover}
        onMouseOut={hidePopover}
        className="invitation-form__roles-table__unauthorized-resource-title"
      >
        N/A
      </span>
      {isOpen && (
        <Popover placement="right" isOpen={isOpen} target={el}>
          <PopoverBody>You have insufficient privileges to access this {resourceType}</PopoverBody>
        </Popover>
      )}
    </span>
  );
}

UnauthorizedResource.propTypes = {
  resourceType: PropTypes.string.isRequired
};

export default UnauthorizedResource;
