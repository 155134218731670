import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { gamesActions } from "@/redux/games/actions";

export default function useCompletedGamesListClearing() {
  const dispatch = useDispatch();

  useEffect(
    () => {
      return () => dispatch(gamesActions.list.clear());
    },
    [dispatch]
  );
}
