import React, { useCallback } from "react";
import PropTypes from "prop-types";
import ConfirmSeasonUnarchivingModal from "@/components/ConfirmSeasonUnarchivingModal";
import { useSeasonUnarchiving } from "./hooks";
import { getStatsHref } from "@/lib/core/useOTP";
import { toast } from "react-toastify";


function Season({ id, title, isUnarchiving, otp }) {
  
  const unarchiveSeason = useSeasonUnarchiving(id);
  

  return (
    <tr key={id} className="dashboard-table-item">
      <td>{title}</td>
      <td>
        <div className="dashboard-table-item__actions">
          <ConfirmSeasonUnarchivingModal
            buttonClassname="dashboard-table-item__action dashboard-table-item__action--hoverable"
            seasonId={id}
            seasonTitle={title}
            isUnarchiving={isUnarchiving}
            onConfirm={unarchiveSeason}
          />

          <a
            className="btn btn-outline-primary btn-sm dashboard-table-item__action dashboard-table-item__action--align-right"
            // href={`/seasons/${id}/stats3/scores`}
            href={getStatsHref(`/seasons/${id}/scores`, otp)}
          >
            View Stats
          </a>
        </div>
      </td>
    </tr>
  );
}

Season.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isUnarchiving: PropTypes.bool.isRequired
};

export default Season;
