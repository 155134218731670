import _pick from "lodash/pick";

export default function reduceProperties(state, { payload: { game } }) {
  const { gameType } = game;
  const league = (game.season.league || {}).title;

  const { home, visitor, flags, coaches, players } = game;

  let fppEnabled = false;
  if (Object.keys(game.home.division.settings).length == 0) {
    // Division uses season settings
    if (game.home.season.settings.fairPlayPoints && game.home.season.settings.fairPlayPoints.isEnabled) {
      fppEnabled = true;
    }
  } else {
    // Division may override season settings
    if (game.home.division.settings.fairPlayPoints && game.home.division.settings.fairPlayPoints.isEnabled) {
      fppEnabled = true;
    }
  }

  return {
    ..._pick(game, [
      "number",
      "startTime",
      "endTime",
      "location",
      "category",
      "periods",
      "firstFlood",
      "secondFlood",
      "curfew",
      "scorekeeper"
    ]),
    gameType: { ...gameType, code: gameType.title },
    league,
    fppEnabled: fppEnabled,
    flags: (flags || []).map(({ event, code, member, team }) => {
      if (member !== undefined) {
        const { type: memberType, id: memberId } = member;

        switch (memberType) {
          case "coaches":
            member = coaches.find(({ id }) => id === memberId);
            break;
          case "players":
            member = players.find(({ id }) => id === memberId);
            break;
          default:
            break;
        }

        if (event == "suspension_lifted"){
          const memberTeam = member.teams.find(({ id, season }) => id == team.id && season);

          let _name = member ? [member.firstName, member.lastName].join(" ") : "N/A";
          _name = _name === " " ? (memberType === "players" ? "Player" : "Coach") : _name;

          member = member && memberTeam && memberTeam.season ? {name: _name, id: memberId || "", seasonId: memberTeam.season.id, type: memberType} : {name: "N/A", id: "", seasonId: "", type: ""};
        } else {
          member = member ? [member.firstName, member.lastName].join(" ") : "N/A";
          member = member === " " ? (memberType === "players" ? "Player" : "Coach") : member;
        }

      }

      if (team !== undefined) {
        const { id: teamId } = team;

        team = ([home, visitor].find(({ id }) => id === teamId) || { title: "N/A" }).title;
      }

      return {
        event,
        code,
        member,
        team
      };
    })
  };
}
