import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import InfiniteScroll from "@/components/InfiniteScroll";
import TotalCount from "@/components/TotalCount";

import RefereeReportRefereeListSearchBar from "./SearchBar";
import RefereeReportRefereeListTable from "./Table";

class List extends PureComponent {
  static propTypes = {
    referees: PropTypes.arrayOf(PropTypes.shape({})),
    totalCount: PropTypes.number,
    filteredCount: PropTypes.number,
    hasMore: PropTypes.bool,
    isLoaded: PropTypes.bool,
    isLoading: PropTypes.bool,
    isAppending: PropTypes.bool,
    loadMoreReferees: PropTypes.func
  };

  static defaultProps = {
    referees: [],
    totalCount: 0,
    filteredCount: 0,
    hasMore: false,
    isLoaded: false,
    isLoading: false,
    isAppending: false,
    loadMoreReferees() {}
  };

  render() {
    const {
      referees,
      totalCount,
      filteredCount,
      loadMoreReferees,
      hasMore,
      isLoaded,
      isLoading,
      isAppending
    } = this.props;

    return (
      <Fragment>
        <div className="mb-3">
          <RefereeReportRefereeListSearchBar />
        </div>

        <InfiniteScroll
          dataLength={referees.length}
          loadList={loadMoreReferees}
          hasMore={hasMore}
          isLoaded={isLoaded}
          isLoading={isLoading}
          isAppending={isAppending}
          emptyMessage="No referees found"
          endMessage="You’ve reached the end of the referees list."
        >
          <TotalCount plural="referees" singular="referee" totalCount={totalCount} filteredCount={filteredCount} />

          <RefereeReportRefereeListTable referees={referees} />
        </InfiniteScroll>
      </Fragment>
    );
  }
}

export default List;
