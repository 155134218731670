import { handleActions } from "redux-actions";

import { reportDataSourcesSelectLeagueListLoadingRoutine } from "../routines";
import actions from "../actions";

function updateAssociationLeagues(state, associationId, data) {
  return {
    ...state,
    data: {
      ...state.data,
      [associationId]: {
        ...state.data[associationId],
        ...data
      }
    }
  };
}

const initialState = {
  data: {},
  selectedIds: []
};

function reduceLoadingRequest(state, { payload: { associationId } }) {
  return updateAssociationLeagues(state, associationId, { isLoading: true });
}

function reduceLoadingSuccess(state, { payload: { associationId, leagues } }) {
  return updateAssociationLeagues(state, associationId, {
    leagues: leagues.map(({ id, title }) => ({ id, title })),
    isLoaded: true
  });
}

function reduceLoadingFailure(state, { payload: { associationId } }) {
  return updateAssociationLeagues(state, associationId, { isLoaded: false });
}

function reduceLoadingFulfill(state, { payload: { associationId } }) {
  return updateAssociationLeagues(state, associationId, { isLoading: false });
}

function reduceSelected(state, { payload: { id, singleSelect = false } }) {
  return {
    ...state,
    selectedIds: [...(singleSelect ? [] : state.selectedIds), id]
  };
}

function reduceDeselected(state, { payload: { id } }) {
  return {
    ...state,
    selectedIds: state.selectedIds.filter(selectedId => selectedId !== id)
  };
}

function reduceLeaguesClearing(state) {
  return { ...state, selectedIds: [] };
}

function reduceAssociationSelected(state, { payload: { id: associationId } }) {
  return {
    ...state,
    selectedIds: [],
    data: {
      ...state.data,
      [associationId]: {
        isLoaded: false,
        isLoading: false,
        leagues: [],
        ...state.data[associationId]
      }
    }
  };
}

function reduceAssociationDeselected(state, { payload: { associationId } }) {
  return { ...state, selectedIds: [] };
}

function reduceClearing(state) {
  return { ...initialState };
}

export default handleActions(
  {
    [reportDataSourcesSelectLeagueListLoadingRoutine.REQUEST]: reduceLoadingRequest,
    [reportDataSourcesSelectLeagueListLoadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [reportDataSourcesSelectLeagueListLoadingRoutine.FAILURE]: reduceLoadingFailure,
    [reportDataSourcesSelectLeagueListLoadingRoutine.FULFILL]: reduceLoadingFulfill,
    [actions.leagueList.select]: reduceSelected,
    [actions.leagueList.deselect]: reduceDeselected,
    [actions.leagueList.clear]: reduceLeaguesClearing,
    [actions.associationList.select]: reduceAssociationSelected,
    [actions.associationList.deselect]: reduceAssociationDeselected,
    [actions.clear]: reduceClearing
  },
  initialState
);
