import { useCallback } from "react";
import { useDispatch } from "react-redux";

import actions from "@/redux/gameForm/actions";

export default function useShootoutChange({ team, index }) {
  const dispatch = useDispatch();

  const onRemove = useCallback(
    () =>
      dispatch(
        actions.removeShootout({
          team,
          index
        })
      ),
    [dispatch, team, index]
  );

  return onRemove;
}
