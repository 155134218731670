import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

import Reset from "./pages/LegacyReset";
import NewPassword from "./pages/NewPassword";

const PasswordRoute = ({ match: { path } }) => (
  <Switch>
    <Route path={`${path}/reset`} component={Reset} />
    <Route path={`${path}/new`} component={NewPassword} />
  </Switch>
);

PasswordRoute.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired
  }).isRequired
};

export default PasswordRoute;
