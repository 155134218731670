import { createRoutine } from "redux-saga-routines";

export const associationListLoadingRoutine = createRoutine("USER_FILTER/ASSOCIATION_LIST/LOADING");

export const leagueListLoadingRoutine = createRoutine("USER_FILTER/LEAGUE_LIST/LOADING");

export const seasonListLoadingRoutine = createRoutine("USER_FILTER/SEASON_LIST/LOADING");

export const divisionListLoadingRoutine = createRoutine("USER_FILTER/DIVISION_LIST/LOADING");

export const teamListLoadingRoutine = createRoutine("USER_FILTER/TEAM_LIST/LOADING");
