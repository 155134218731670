import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { FormGroup, Label, Input, Button, FormFeedback } from "reactstrap";

import ContentSection from "@/components/ContentSection";
import DashboardForm from "@/components/DashboardForm";
import DashboardFormFooter from "@/components/DashboardFormFooter";
import FormItem from "@/components/FormItem";
import ConfirmDeletionModal from "@/components/ConfirmDeletionModal";
import AbilityButton from "@/components/AbilityButton";
import { Can } from "@/components/Ability";

import useAssociationForm from "./hooks/useAssociationForm";
import useAssociationFormSubmitting from "./hooks/useAssociationFormSubmitting";
import useAssociationFormDeleting from "./hooks/useAssociationFormDeleting";

import LogoPickerField from "./LogoPickerField";

function AssociationForm({ associationId, initialValues }) {
  const { isInvalid, isPristine, fields, values } = useAssociationForm(initialValues);

  const { isSubmitting, submit } = useAssociationFormSubmitting({
    associationId,
    values
  });

  const { isDeleting, deleteAssociation } = useAssociationFormDeleting(associationId);

  return (
    <DashboardForm className="association-form" onSubmit={submit} isOperating={isSubmitting}>
      <ContentSection>
        <FormItem>
          <FormGroup className="col-md-6 required">
            <Label for="associationTitle">Association Name</Label>
            <Input
              id="associationTitle"
              name="associationTitle"
              {...fields.title.input}
              placeholder="e.g. Newmarket Minor Ball Hockey"
            />
            {fields.title.input.invalid &&
              fields.title.errors.map(error => <FormFeedback key={error}>{error}</FormFeedback>)}
          </FormGroup>
        </FormItem>
      </ContentSection>

      <ContentSection title="Association Logo">
        <LogoPickerField {...fields.logo} />
        {fields.logo.isDirty &&
          fields.logo.errors.map(error => (
            <p key={error} className="text-danger">
              {error}
            </p>
          ))}
      </ContentSection>

      <DashboardFormFooter>
        {associationId ? (
          <Fragment>
            <Can I="delete" this={{ type: "associations", id: associationId }}>
              <div className="dashboard-form-footer__secondary">
                <ConfirmDeletionModal
                  resourceTitle={fields.title.initialValue}
                  resourceType="Association"
                  isOperating={isSubmitting}
                  isDeleting={isDeleting}
                  onDelete={deleteAssociation}
                />
              </div>
            </Can>

            <Can I="update" this={{ type: "associations", id: associationId }}>
              <div className="dashboard-form-footer__primary">
                <Button
                  type="submit"
                  color="success"
                  disabled={isInvalid || isPristine || isDeleting}
                  outline={isInvalid || isPristine || isDeleting}
                >
                  {isSubmitting ? "Saving Changes…" : "Save Changes"}
                </Button>
              </div>
            </Can>
          </Fragment>
        ) : (
          <div className="dashboard-form-footer__primary">
            <AbilityButton
              subject="associations"
              action="create"
              type="submit"
              color="success"
              disabled={isInvalid || isPristine}
              outline={isInvalid || isPristine}
            >
              {isSubmitting ? "Creating Association…" : "Create Association"}
            </AbilityButton>
          </div>
        )}
      </DashboardFormFooter>
    </DashboardForm>
  );
}

AssociationForm.propTypes = {
  associationId: PropTypes.string,
  initialValues: PropTypes.shape({})
};

export default AssociationForm;
