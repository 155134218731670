import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import Division from "./Division";

class Season extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    divisions: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })).isRequired
  };

  render() {
    const { title, divisions } = this.props;

    return (
      <Fragment>
        {divisions.map(({ id, ...rest }, index) => (
          <Division key={id} season={index === 0 ? title : null} {...rest} />
        ))}
      </Fragment>
    );
  }
}

export default Season;
