import { handleActions } from "redux-actions";

import { loadSeasonCoachesRosterRoutine } from "../../routines";
import { seasonRosterActions } from "../../actions";

const initialState = {};

function reduceCoach(coach) {
  const { id, firstName, lastName, externalId, teams, season, divisions } = coach;

  return {
    seasonId: season.id,
    teams: (teams || []).map(({ title }) => title),
    divisions: divisions.map(({ id, title }) => ({ id, title })),
    firstName,
    lastName,
    externalId,
    id
  };
}

function reduceLoadingSuccess(state, { payload: { coaches } }) {
  return coaches.reduce(
    (result, coach) => ({
      ...result,
      [coach.id]: reduceCoach(coach)
    }),
    { ...state }
  );
}

export default handleActions(
  {
    [loadSeasonCoachesRosterRoutine.SUCCESS]: reduceLoadingSuccess,
    [seasonRosterActions.clear]: () => ({ ...initialState })
  },
  initialState
);
