import React from "react";
import Pagination from "./Pagination";
import PageLoader from "@/components/PageLoader";

function PaginationContainer(props) {
  return (
    <React.Suspense fallback={<PageLoader isLoading />}>
      <Pagination {...props} />
    </React.Suspense>
  )
}

export default PaginationContainer;
