import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";
import { Switch, Redirect, Route } from "react-router-dom";
import { hot } from "react-hot-loader/root";

import { useLocation, withRouter } from "react-router";

import ErrorBoundaryMsg from "@/components/ErrorBoundaryMsg";
import LoggedInRoute from "@/containers/LoggedInRoute";

import NotFoundPage from "@/routes/NotFoundPage";

import AssociationsRoute from "@/routes/Associations";
import LeaguesRoute from "@/routes/Leagues";
import SeasonsRoute from "@/routes/Seasons";
import TeamRosterRoute from "@/routes/TeamRoster";
import SeasonRosterRoute from "@/routes/SeasonRoster";
import RefereesRoute from "@/routes/Referees";
import GamesRoute from "@/routes/Games";
import ReportsRoute from "@/routes/Reports";

import InvitationsRoute from "@/routes/Invitations";
import AccountRoute from "@/routes/Account";
import PasswordRoute from "@/routes/Password";

import UsersRoute from "@/routes/Users";
import UnsubscribeRoute from "@/routes/Unsubscribe";

import useWindowScrollReset from "./hooks/useWindowScrollReset";
import Layout from "@/layouts/Layout";
import { config } from "@/config";
import TitleBar from "@/components/TitleBar";
import Head from "@/components/Head";
import { PrivacyPolicy } from "@/routes/Legal/Privacy";
import CurrentDisplaySettings from "@/contexts/CurrentDisplaySettingsContext";
import { useLoginWithOtp } from "@/lib/core/useLoginWithOtp";
import { useFirebaseToken } from "@/hooks/useFirebaseToken";
import { TransferToTeamDashboard } from "@/routes/TeamDashboard/redirect";

function App({ location }) {
  
  useWindowScrollReset(location);
  const displaySettings = useContext(CurrentDisplaySettings)
  
  useFirebaseToken();
  useLoginWithOtp();

  useEffect(() => {
    
    if(window.parent){
      const msg = { source:"dashboard-v3", type: "location", location: location.pathname }
      window.parent.postMessage(msg, "*")
    }

  }, [location])

  return (
    <Sentry.ErrorBoundary fallback={<ErrorBoundaryMsg />}>
      {(config.MAINTENANCE_LOCK && (
        <Layout className="association-page">
          <Head title="GameSheet Maintenance Mode" />
          <TitleBar title="GameSheet Maintenance Mode" />

          <div>GameSheet is currently undergoing upgrades and will return momentarily.</div>
        </Layout>
      )) || (
        <CurrentDisplaySettings.Provider value={displaySettings}>
          <Switch>
            
            {/* Private Policy */}
            <Route path="/team-dashboard" component={TransferToTeamDashboard} />
            
            {/* Private Policy */}
            <Route path="/privacy" component={PrivacyPolicy} />
            
            {/* Associations */}
            <LoggedInRoute path="/associations" component={AssociationsRoute} />

            {/* Leagues */}
            <LoggedInRoute path="/leagues" component={LeaguesRoute} />

            {/* Games */}
            <LoggedInRoute path="/seasons/:seasonId/games" component={GamesRoute} />

            {/* Seasons */}
            <LoggedInRoute path="/seasons/:seasonId/teams/:teamId/roster" component={TeamRosterRoute} />

            <LoggedInRoute path="/seasons/:seasonId/roster" component={SeasonRosterRoute} />

            <LoggedInRoute path="/seasons/:seasonId/referees" component={RefereesRoute} />

            {/* Seasons */}
            <LoggedInRoute path="/seasons" component={SeasonsRoute} />

            {/* Teams */}
            <LoggedInRoute path="/teams/:teamId/roster" component={TeamRosterRoute} />

            {/* Account */}
            <Route path="/account" component={AccountRoute} />

            {/* Password */}
            <Route path="/password" component={PasswordRoute} />

            {/* Reports */}
            <LoggedInRoute path="/reports" component={ReportsRoute} />

            {/* Misc */}
            <LoggedInRoute path="/invitations" component={InvitationsRoute} />

            <LoggedInRoute path="/users" component={UsersRoute} />

            {/* Unsubscribe */}
            <Route path="/unsubscribe" component={UnsubscribeRoute} />

            <Redirect from="/" to="/associations" exact />

            <Route path="" component={NotFoundPage} />
          </Switch>
        </CurrentDisplaySettings.Provider>
      )}
    </Sentry.ErrorBoundary>
  );
}

App.propTypes = {
  location: PropTypes.shape({}).isRequired
};

export default hot(withRouter(App));
