import React from "react";
import PropTypes from "prop-types";
import { Table } from "reactstrap";
import EmptyListMessage from "../EmptyListMessage";

function AssessedPenalties({ assessedPenalties }) {
  return (
    <div className="penalty-type-report__section">
      <h3 className="penalty-type-report__section-title">Assessed Penalties by Type</h3>

      {assessedPenalties.length > 0 ? (
        <Table className="penalty-type-report__table" striped borderless>
          <thead>
            <tr>
              <th>Penalty Code</th>
              <th>Penalty Label</th>
              <th># Times Assessed</th>
            </tr>
          </thead>
          <tbody>
            {assessedPenalties.map(({ code, label, count }) => (
              <tr key={code}>
                <td className="penalty-type-report__assessed-penalties-table__code">{code}</td>
                <td>{label}</td>
                <td>{count}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyListMessage>There were no penalties assessed</EmptyListMessage>
      )}
    </div>
  );
}

AssessedPenalties.propTypes = {
  assessedPenalties: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      label: PropTypes.string,
      count: PropTypes.number
    })
  )
};

AssessedPenalties.defaultProps = {
  assessedPenalties: []
};

export default AssessedPenalties;
