import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import PageLoader from "@/components/PageLoader";
import PlayerTeamsList from "@/components/PlayerTeamsList";
import AbilityButton from "@/components/AbilityButton";

import useCurrentPlayer from "../hooks/useCurrentPlayer";
import useCurrentPlayerClearing from "../hooks/useCurrentPlayerClearing";

function PlayerPage({ seasonId, match: { params } }) {
  const { playerId } = params;

  const { seasonIsLoaded, seasonIsLoading, seasonIsForbidden, seasonBreadcrumbs } = useCurrentSeasonContext();

  const { isPlayerOperating, isPlayerReady, isPlayerForbidden, player, teams } = useCurrentPlayer({
    seasonId,
    playerId
  });

  useCurrentPlayerClearing();

  return (
    <ProtectedLayout condition={!seasonIsForbidden && !isPlayerForbidden}>
      {seasonIsLoaded ? (
        isPlayerReady ? (
          <Fragment>
            <Head title={`${player.firstName} ${player.lastName}`} />

            <Breadcrumbs
              items={[...seasonBreadcrumbs, { title: "Players", url: `/seasons/${seasonId}/roster/players` }]}
            />

            <TitleBar title={`${player.firstName} ${player.lastName}`} subtitle={`ID: ${player.externalId}`}>
              <AbilityButton
                subject={{ type: "penaltyReports", playerId: player.id }}
                action="read"
                outline
                color="primary"
                size="sm"
                tag={Link}
                to={`/seasons/${seasonId}/roster/players/${player.id}/penalty-report`}
              >
                Penalty Report
              </AbilityButton>

              <AbilityButton
                subject={{ type: "players", id: player.id }}
                action="update"
                outline
                color="primary"
                size="sm"
                tag={Link}
                to={`/seasons/${seasonId}/roster/players/${player.id}/edit`}
              >
                Edit Player
              </AbilityButton>
            </TitleBar>

            <PlayerTeamsList
              teams={teams}
              isLoading={isPlayerOperating}
              isLoaded={isPlayerReady}
              playerId={playerId}
              seasonId={seasonId}
            />
          </Fragment>
        ) : (
          <PageLoader isLoading={isPlayerOperating} />
        )
      ) : (
        <PageLoader isLoading={seasonIsLoading} />
      )}
    </ProtectedLayout>
  );
}

PlayerPage.propTypes = {
  seasonId: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      playerId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default PlayerPage;
