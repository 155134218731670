import { handleActions } from "redux-actions";

import { submittingRoutine, deletingRoutine } from "../routines";

import actions from "../actions";

const initialState = {
  isSubmitting: false,
  isDeleting: false,
  isArchiving: false
};

function reduceSubmittingRequest(state) {
  return { ...state, isSubmitting: true };
}

function reduceSubmittingFulfill(state) {
  return { ...state, isSubmitting: false };
}

function reduceDeletingRequest(state) {
  return { ...state, isDeleting: true };
}

function reduceDeletingFulfill(state) {
  return { ...state, isDeleting: false };
}

function reduceArchivingRequest(state) {
  return { ...state, isArchiving: true };
}

function reduceArchivingFulfill(state) {
  return { ...state, isArchiving: false };
}

export default handleActions(
  {
    [submittingRoutine.REQUEST]: reduceSubmittingRequest,
    [submittingRoutine.FULFILL]: reduceSubmittingFulfill,
    [deletingRoutine.REQUEST]: reduceDeletingRequest,
    [deletingRoutine.FULFILL]: reduceDeletingFulfill,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
