import { handleActions } from "redux-actions";

import { loadingRoutine } from "../routines";
import actions from "../actions";

const initialState = {};

const reduceGame = ({
  visitorDivision,
  visitorTeam,
  homeDivision,
  homeTeam,
  scheduledStartTime,
  number,
  gameType,
  location,
  scorekeeper,
  season
}) => ({
  visitor: {
    title: (visitorTeam && visitorTeam.title) || "",
    division: (visitorDivision && visitorDivision.title) || ""
  },
  home: {
    title: (homeTeam && homeTeam.title) || "",
    division: (homeDivision && homeDivision.title) || ""
  },
  seasonId: season.id,
  number,
  gameType,
  scheduledStartTime: scheduledStartTime,
  location,
  scorekeeper
});

function reduceLoadingSuccess(state, { payload: { scheduledGames: games } }) {
  return games.reduce((result, game) => ({ ...result, [game.id]: reduceGame(game) }), {});
}

export default handleActions(
  {
    [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
