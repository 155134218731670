export const formatPeriodName = period => {
    if (period.toUpperCase().startsWith("OT")) {
      const matches = period.match(/OT_?([0-9]+)/i)
      if (matches && matches.length >= 2) {
        return "OT" + matches[1];
      } else {
        return "OT"
      }
    } else {
      return period;
    }
  };