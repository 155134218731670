import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getRoleIsGlobal,
} from "@/redux/roleForm/selectors";

import actions from "@/redux/roleForm/actions";

export default function useGlobalUserCheckbox() {
  const roleIsGlobal = useSelector(getRoleIsGlobal);

  const dispatch = useDispatch();
  const setRoleIsGlobal = useCallback(
    (val) => {
      const fn = actions["setGlobal"];
      dispatch(fn({ isChecked: val }));
    },
    [dispatch]
  );

  return {
    roleIsGlobal,
    setRoleIsGlobal
  };
}
