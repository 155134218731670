import { useState, useCallback } from "react";

export default function useDropdown() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpened = useCallback(() => setIsOpen(!isOpen), [setIsOpen, isOpen]);

  const close = useCallback(() => setIsOpen(false), [setIsOpen]);

  return {
    isOpen,
    toggleOpened,
    close
  };
}
