import { createGetService, createPostService } from "./serviceMaker";

export const loadDashboardNotes = createGetService(({ seasonId, gameId }) => ({
  url: `/seasons/${seasonId}/games/${gameId}/notes`
}));

export const createDashboardNote = createPostService(({ seasonId, gameId, attributes }) => ({
  url: `/seasons/${seasonId}/games/${gameId}/notes`,
  attributes
}));
