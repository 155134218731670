import { useCallback } from "react";
import saveAs from "file-saver";

import { SCHEDULED_GAMES_CSV_TEMPLATE } from "../constants";

export default function useScheduledGamesCSVImportWizardTemplate() {
  return useCallback(() => {
    const blob = new Blob(SCHEDULED_GAMES_CSV_TEMPLATE, {
      type: "text/csv;charset=utf-8"
    });

    saveAs(blob, "scheduled-games-template.csv");
  }, []);
}
