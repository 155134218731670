import React from "react";
import PropTypes from "prop-types";

import { Table } from "reactstrap";

import PlayerRow from "./PlayerRow";

function PlayerTable(props) {
  const { teamType, players } = props;

  return (
    <Table striped borderless>
      <thead>
        <tr>
          <th scope="col">Full name</th>
          <th scope="col">External ID</th>
          <th scope="col">Teams</th>
          <th scope="col">
            <span className="visuallyhidden">Actions</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {players.map(({ id, ...rest }) => (
          <PlayerRow key={id} id={id} teamType={teamType} {...rest} />
        ))}
      </tbody>
    </Table>
  );
}

PlayerTable.propTypes = {
  teamType: PropTypes.string.isRequired,
  players: PropTypes.array.isRequired
};

export default PlayerTable;
