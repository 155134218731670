import { connect } from "react-redux";

import { getLogin } from "@/redux/login/selectors";
import { loginSetCredentialsAction, loginAction } from "@/redux/login/actions";

import Login from "./Login";

const mapStateToProps = state => ({
  ...getLogin(state)
});

const mapDispatchToProps = dispatch => ({
  onChange: credentials => dispatch(loginSetCredentialsAction(credentials)),
  onSubmit: credentials => dispatch(loginAction(credentials))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
