import React from 'react';
import { Button } from "reactstrap";

import DashboardFormFooter from "@/components/DashboardFormFooter";
import { useImport } from "@/components-16.8/RefereeImportWizard/hooks/useImport";

export function ParsingSuccess({ seasonId }) {

    const { handleResetImport, handleStartImport, refereeCount } = useImport({ seasonId });

    return (
        <>
            <div className="csv-import__confirmation__text">
                <p>
                    {refereeCount} {refereeCount === 1 ? "referee" : "referees"} will be imported.
                </p>
                <p>Would you like to continue?</p>
            </div>
            <DashboardFormFooter>
                <div className="dashboard-form-footer__secondary">
                    <Button type="button" color="secondary" outline onClick={handleResetImport}>Cancel</Button>
                </div>
                <div className="dashboard-form-footer__primary">
                    <Button type="button" color="success" onClick={handleStartImport}>Begin Import</Button>
                </div>
            </DashboardFormFooter>
        </>
    );
}