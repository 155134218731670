import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Popover, PopoverBody } from "reactstrap";

import GameFlagsList from "@/components/GameFlagsList";
import GameLink from "../GameLink";
import WrapIf from "../../hooks/useWrapIf";
import { makeFlagMessage } from "@/components/GameFlagsList/helpers";
import { Icon } from "@/components/GameFlagsList/Icon";

import "./GameFlag.scss";

class GameFlag extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    flags: PropTypes.arrayOf(
      PropTypes.shape({
        event: PropTypes.string.isRequired
      })
    ).isRequired,
    color: PropTypes.string.isRequired,
    seasonId: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }

  openPopover = () => {
    this.setState({
      open: true
    });
  };

  closePopover = () => {
    this.setState({
      open: false
    });
  };

  render() {
    const { open } = this.state;
    const { id, flags, color, seasonId } = this.props;

    const messages = flags.map(flag => makeFlagMessage({ flag, noLink: true }));

    const elementId = `gameFlag-${id}`;

    const touch = matchMedia("(hover: none)").matches;

    return (
      <div className="game-flag">
        <div
          id={elementId}
          onFocus={this.openPopover}
          onMouseOver={this.openPopover}
          onBlur={this.closePopover}
          onMouseOut={this.closePopover}
          className="game-flag__icon-box"
        >
          <Icon fill={color} />
        </div>

        <Popover className="game-flag__popover" placement="left" isOpen={open} target={elementId}>
          <PopoverBody>
            <WrapIf
              cond={touch}
              wrapper={GameLink}
              id={id}
              seasonId={seasonId}
              // PopoverBody is not a child of .game-flag__icon, so a click/touch on the former triggers
              // onMouseOut() on the latter, which closes PopoverBody before it gets a chance to handle
              // the click/touch. Luckily, on Safari, onMouseOver is handled after onMouseOut but before
              // a re-render occurs due to setState().
              onMouseOver={this.openPopover}
            >
              <GameFlagsList messages={messages} />
            </WrapIf>
          </PopoverBody>
        </Popover>
      </div>
    );
  }
}

export default GameFlag;
