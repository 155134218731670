import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";


function ConfirmDeleteSeasonModal(props) {
  const { isDeleting, resourceTitle, resourceType, onDelete, children, toggleDeleteModal, isDeleteModalOpen, setIsDeleteModalOpen, setIsDeleting } = props;

  const triggerDelete = useCallback(() => !isDeleting && onDelete() && setIsDeleting(true), [isDeleting, onDelete]);

  const btnTitle = isDeleting ? `Deleting ${resourceType}…` : `Delete ${resourceType}`;

  return (
    <div className="confirm-deletion-modal">
      <Modal className="confirm-deletion-modal" isOpen={isDeleteModalOpen} toggle={toggleDeleteModal} centered>
        <ModalHeader>Confirmation</ModalHeader>
        <ModalBody>
          {children === null ? (
            <p>
              Are you sure you want to delete <span className="text-lowercase">{resourceType}</span>{" "}
              <strong>{resourceTitle}</strong>?
            </p>
          ) : (
            children
          )}
        </ModalBody>

        <ModalFooter>
          <div className="d-flex w-100">
            <div className="mr-auto">
              <Button type="button" color="secondary" onClick={() => setIsDeleteModalOpen(false)} disabled={isDeleting}>
                Cancel
              </Button>
            </div>

            <div>
              <Button outline type="button" color="danger" onClick={triggerDelete} disabled={isDeleting}>
                {btnTitle}
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}

ConfirmDeleteSeasonModal.propTypes = {
  isDeleting: PropTypes.bool,
  resourceTitle: PropTypes.string.isRequired,
  resourceType: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  children: PropTypes.node
};

ConfirmDeleteSeasonModal.defaultProps = {
  isOperating: false,
  isDeleting: false,
  children: null,
  onDelete() {}
};

export default ConfirmDeleteSeasonModal;
