import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { ListGroupItem } from "reactstrap";

import SeasonIpadKeysManagerKeyActionBar from "./ActionBar";

import "./Key.scss";

class SeasonIpadKeysManagerKey extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    isIncludesSeason: PropTypes.bool.isRequired,
    isAvailable: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    toggleSelected: PropTypes.func
  };

  static defaultProps = {
    toggleSelected() {}
  };

  render() {
    const { value, description, isAvailable, isSelected, isIncludesSeason, toggleSelected } = this.props;

    return (
      <ListGroupItem className="ipad-key" action>
        <div className="d-flex align-items-center">
          <div className="flex-fill">
            <span className="d-block weight-bold">{description}</span>
            <code>{value}</code>
          </div>
          <div className="ml-auto">
            <SeasonIpadKeysManagerKeyActionBar
              isAvailable={isAvailable}
              isIncludesSeason={isIncludesSeason}
              isSelected={isSelected}
              toggleSelected={toggleSelected}
            />
          </div>
        </div>
      </ListGroupItem>
    );
  }
}

export default SeasonIpadKeysManagerKey;
