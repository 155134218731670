import React, { Fragment } from "react";
import PropTypes from "prop-types";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";

import RefereeReport from "@/components/RefereeReport";

import useRefereeReportPage from "./hooks/useRefereeReport";

function ReportPage({ match: { params } }) {
  const { externalId } = params;
  const { isLoaded, isForbidden, referee } = useRefereeReportPage(externalId);

  return (
    <ProtectedLayout condition={!isForbidden}>
      <Head title={isLoaded ? `${referee.firstName} ${referee.lastName} — Referee Report` : "Referee Report"} />

      {isLoaded && (
        <Fragment>
          <Breadcrumbs
            items={[
              { title: "Reports" },
              {
                title: "Referee Reports",
                url: "/reports/referee"
              }
            ]}
          />
        </Fragment>
      )}

      <RefereeReport externalId={externalId} />
    </ProtectedLayout>
  );
}

ReportPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      externalId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default ReportPage;
