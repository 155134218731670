import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Switch } from "react-router-dom";
import LoggedInRoute from "@/containers/LoggedInRoute";
import PlayerPenaltyReportPage from "./ReportPage";
import { PlayerPenaltyReportSearch } from "@/components-16.8/PlayerPenaltyReportSearch";
import { RecoilRoot } from "recoil";

class ReportsRoute extends PureComponent {
  static propTypes = {
    match: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired
  };

  render() {
    const { match } = this.props;

    return (<RecoilRoot>
        <Switch>
          <LoggedInRoute path={`${match.url}/:externalId`} component={PlayerPenaltyReportPage} />
          <LoggedInRoute path="" component={PlayerPenaltyReportSearch} />
        </Switch>
      </RecoilRoot>
    );
  }
}

export default ReportsRoute;
