import _orderBy from "lodash/orderBy";
import _trimEnd from "lodash/trimEnd";

const selectRoot = state => state.teamRoster;
const selectMeta = state => selectRoot(state).meta;
const selectTeam = state => selectRoot(state).team;
const selectMember = (state, { type, id }) => selectRoot(state)[type][id];
const selectPlayers = state => selectRoot(state).players;
const selectPlayerFields = (state, playerId) => selectPlayers(state)[playerId].fields;

const selectCoaches = state => selectRoot(state).coaches;
const selectCoachFields = (state, coachId) => selectCoaches(state)[coachId].fields;

export const getMeta = state => selectMeta(state);
export const getTeam = state => selectTeam(state);

export const getMemberField = (state, name, { type, id }) => {
  const { fields, autoFocus } = selectMember(state, { type, id });
  const { value, errors, isDirty } = fields[name];

  return {
    value,
    errors,
    isInvalid: errors.length > 0,
    autoFocus: autoFocus === name,
    isDirty
  };
};

export const getPlayers = state => {
  const players = Object.values(selectPlayers(state));

  return [
    ..._orderBy(players.filter(({ id }) => id !== ""), ["lastName"], ["asc"]),
    ...players.filter(({ id }) => id === "")
  ];
};

export const getPlayerField = (state, name, playerId) => {
  const { value, errors } = selectPlayerFields(state, playerId)[name];

  return { value, errors, isInvalid: errors.length > 0 };
};

export const getPlayerFormIsInvalid = (state, playerId) => {
  const fields = selectPlayerFields(state, playerId);

  return Object.values(fields).filter(({ errors }) => errors.length > 0).length > 0;
};

export const getPlayerFormIsNotFilled = (state, playerId) => {
  const fields = selectPlayerFields(state, playerId);

  return (
    Object.entries(fields).filter(
      ([name, { value }]) => ["firstName", "lastName"].includes(name) && _trimEnd(value) === ""
    ).length > 0
  );
};

export const getPlayerFormIsDirty = (state, playerId) => {
  const fields = selectPlayerFields(state, playerId);

  return Object.values(fields).filter(({ isDirty }) => isDirty).length > 0;
};

export const getPlayerFormIsPristine = (state, playerId) => getPlayerFormIsDirty(state, playerId) === false;

export const getAllPlayerAttributes = (state, playerId) => {
  const fields = selectPlayerFields(state, playerId);

  return Object.entries(fields).reduce((result, [field, { value }]) => ({ ...result, [field]: value }), {});
};

export const getSeasonPlayerAttributes = (state, playerId) => {
  const { firstName, lastName, externalId } = getAllPlayerAttributes(state, playerId);

  const {
    photoUrl,
    birthdate,
    weight,
    height,
    shotHand,
    country,
    province,
    hometown,
    draftedBy,
    committedTo,
    biography
  } = selectPlayers(state)[playerId];

  return {
    firstName,
    lastName,
    externalId,
    photoUrl,
    birthdate: birthdate && birthdate.split("T")[0], // @FIXME: API responds date format YYYY-MM-DDThh:mm:ssZ, but accepts only YYYY-MM-DD ¯\_(ツ)_/¯
    weight,
    height,
    shotHand,
    country,
    province,
    hometown,
    draftedBy,
    committedTo,
    biography
  };
};

export const getRosterPlayerAttributes = (state, playerId) => {
  const { number, affiliated, position, duty, status } = getAllPlayerAttributes(state, playerId);

  return { number, affiliated, position, duty, status };
};

export const getPlayerFormIsSaving = (state, playerId) => selectPlayers(state)[playerId].isSaving;

export const getPlayerFormIsDeleting = (state, playerId) => selectPlayers(state)[playerId].isDeleting;

export const getCoaches = state => {
  const coaches = Object.values(selectCoaches(state));

  return [
    ..._orderBy(coaches.filter(({ id }) => id !== ""), ["lastName"], ["asc"]),
    ...coaches.filter(({ id }) => id === "")
  ];
};

export const getCoachField = (state, name, coachId) => {
  const { value, errors } = selectCoachFields(state, coachId)[name];

  return { value, errors, isInvalid: errors.length > 0 };
};

export const getCoachFormIsInvalid = (state, coachId) => {
  const fields = selectCoachFields(state, coachId);

  return Object.values(fields).filter(({ errors }) => errors.length > 0).length > 0;
};

export const getCoachFormIsNotFilled = (state, coachId) => {
  const fields = selectCoachFields(state, coachId);

  return (
    Object.entries(fields).filter(
      ([name, { value }]) => ["firstName", "lastName"].includes(name) && _trimEnd(value) === ""
    ).length > 0
  );
};

export const getCoachFormIsDirty = (state, coachId) => {
  const fields = selectCoachFields(state, coachId);

  return Object.values(fields).filter(({ isDirty }) => isDirty).length > 0;
};

export const getCoachFormIsPristine = (state, coachId) => getCoachFormIsDirty(state, coachId) === false;

export const getAllCoachAttributes = (state, coachId) => {
  const fields = selectCoachFields(state, coachId);

  return Object.entries(fields).reduce((result, [field, { value }]) => ({ ...result, [field]: value }), {});
};

export const getSeasonCoachAttributes = (state, coachId) => {
  const { firstName, lastName, externalId } = getAllCoachAttributes(state, coachId);

  return { firstName, lastName, externalId };
};

export const getRosterCoachAttributes = (state, coachId) => {
  const { number, affiliated, position, signature, status } = getAllCoachAttributes(state, coachId);

  return { number, affiliated, position, signature, status };
};

export const getCoachFormIsSaving = (state, coachId) => selectCoaches(state)[coachId].isSaving;

export const getCoachFormIsDeleting = (state, coachId) => selectCoaches(state)[coachId].isDeleting;

export const getIsEditing = state =>
  [...getPlayers(state), ...getCoaches(state)].filter(({ isEditing }) => isEditing).length > 0;

export const getIsLocked = state => !!getTeam(state).data.rosterLocked;
