import React from "react";

import FilterSelect from "./FilterSelect";

import useDivisionSelect from "./hooks/useDivisionSelect";

function DivisionSelect() {
  const { options, selectedId, isLoaded, isLoading, setSelectedId } = useDivisionSelect();

  return (
    <FilterSelect
      placeholder="All divisions"
      options={options}
      value={selectedId}
      isLoaded={isLoaded}
      isLoading={isLoading}
      onChange={setSelectedId}
    />
  );
}

export default DivisionSelect;
