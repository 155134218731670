import { connect } from "react-redux";

import { getGameFormTeamTitle, getGameFormTeamPenalties } from "@/redux/gameForm/selectors";

import actions from "@/redux/gameForm/actions";

import PenaltiesFieldset from "./PenaltiesFieldset";

const mapStateToProps = (state, { type }) => ({
  teamTitle: getGameFormTeamTitle(state, type),
  penalties: getGameFormTeamPenalties(state, type)
});

const mapDispatchToProps = dispatch => ({
  changePenalty: payload => dispatch(actions.changePenalty(payload)),
  addPenalty: team => dispatch(actions.addPenalty({ team })),
  removePenalty: payload => dispatch(actions.removePenalty(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PenaltiesFieldset);
