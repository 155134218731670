import { useSelector } from "react-redux";

import {
  getIsLoaded,
  getIsLoading,
  getIsForbidden,
  getIsFailed,
  getArchivedList,
  getArchivedTotalCount,
  getActiveTotalCount
} from "@/redux/seasonsList";

export default function useSeasonsList() {
  const isListLoaded = useSelector(getIsLoaded);
  const isListLoading = useSelector(getIsLoading);
  const isListForbidden = useSelector(getIsForbidden);
  const isListFailed = useSelector(getIsFailed);
  const seasons = useSelector(getArchivedList);
  const totalCount = useSelector(getArchivedTotalCount);
  const activeTotalCount = useSelector(getActiveTotalCount);

  return {
    isListLoaded,
    isListLoading,
    isListForbidden,
    isListFailed,
    seasons,
    totalCount,
    activeTotalCount
  };
}
