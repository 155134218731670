import './style.scss';
import React from 'react';
import PropTypes from "prop-types";

import PaginationItem from './PaginationItem';

export function PaginationBar({ rows, pages, nav }) {
    return (
        <>
            <div className="pagination-footer">
                <p className="pagination-results"><strong>{rows.currentLow}</strong> to <strong>{rows.currentHigh}</strong> of <strong>{rows.total}</strong></p>

                <ul className="pagination">
                    <PaginationItem text={<span className="material-icons md-18">first_page</span>} onClick={nav.onFirst} />
                    <PaginationItem text={<span className="material-icons">navigate_before</span>} onClick={nav.onBack} />

                    <li><p> Page <strong>{pages.current}{" "}</strong> of <strong>{pages.total}</strong> </p></li>

                    <PaginationItem text={<span className="material-icons">navigate_next</span>} onClick={nav.onForward} />
                    <PaginationItem text={<span className="material-icons md-18">last_page</span>} onClick={nav.onLast} />
                </ul>
            </div>
            <hr className="pagination-hr" />
        </>
    )
}

PaginationBar.propTypes = {
    rows: PropTypes.shape({
        currentLow: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        currentHigh: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    }).isRequired,
    pages: PropTypes.shape({
        current: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    }).isRequired,
    nav: PropTypes.shape({
        onFirst: PropTypes.func.isRequired,
        onBack: PropTypes.func.isRequired,
        onForward: PropTypes.func.isRequired,
        onLast: PropTypes.func.isRequired,
    }).isRequired
};

PaginationBar.defaultProps = {
    rows: {
        currentLow: 0,
        currentHigh: 0,
        total: 0
    },
    pages: {
        current: 0,
        total: 0
    },
    nav: {
        onFirst: () => { },
        onBack: () => { },
        onForward: () => { },
        onLast: () => { }
    }
};