import { handleActions, combineActions } from "redux-actions";

import {
  ResourceLoadingRoutine as loadingRoutine,
  ResourceCreatingRoutine as creatingRoutine,
  ResourceUpdatingRoutine as updatingRoutine,
  ResourceReleasingRoutine as releasingRoutine
} from "./routines";

import { TeamLockingRoutine } from "@/redux/teams/routines";

import { mergeItemsToTree, removeItemFromTree } from "./utils";

const defaultState = {};

const mergingReducer = (state, { payload }) => mergeItemsToTree(state, payload.data);

const releasingReducer = (state, { payload }) => removeItemFromTree(state, payload.identity);

const reduceTeamLock = (state, { payload: { lock, teamId } }) => {
  if (!!state.teams && teamId in state.teams && !!state.teams[teamId].attributes) {
    return {
      ...state,
      teams: {
        ...state.teams,
        [teamId]: {
          ...state.teams[teamId],
          attributes: {
            ...state.teams[teamId].attributes,
            data: {
              ...state.teams[teamId].attributes.data,
              rosterLocked: lock
            }
          }
        }
      }
    };
  } else {
    return { ...state };
  }
};

export default handleActions(
  {
    [combineActions(loadingRoutine.SUCCESS, creatingRoutine.SUCCESS, updatingRoutine.SUCCESS)]: mergingReducer,
    [releasingRoutine.TRIGGER]: releasingReducer,
    [TeamLockingRoutine.SUCCESS]: reduceTeamLock
  },
  defaultState
);
