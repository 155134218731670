import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import _trimEnd from "lodash/trimEnd";

import {
  isCurrentPlayerReady,
  isCurrentPlayerOperating,
  getCurrentPlayer,
  isCurrentPlayerForbidden,
  getSeasonPlayerTeams,
  isCurrentPlayerDeleting
} from "@/redux/players/selectors";

import { CurrentPlayerLoadingRoutine } from "@/redux/players/routines";

import { clearCurrentPlayer } from "@/redux/players/actions";

export default function useCurrentPlayer({ seasonId, playerId }) {
  const dispatch = useDispatch();
  const isPlayerReady = useSelector(isCurrentPlayerReady);
  const isPlayerOperating = useSelector(isCurrentPlayerOperating);
  const isPlayerForbidden = useSelector(isCurrentPlayerForbidden);
  const isPlayerDeleting = useSelector(isCurrentPlayerDeleting);
  const player = useSelector(getCurrentPlayer);
  const teams = useSelector(getSeasonPlayerTeams);
  const externalId = useMemo(() => (player && _trimEnd(player.externalId)) || "", [player]);

  useEffect(
    () => {
      dispatch(CurrentPlayerLoadingRoutine({ seasonId, id: playerId }));

      return () => dispatch(clearCurrentPlayer());
    },
    [dispatch, seasonId, playerId]
  );

  return {
    isPlayerReady,
    isPlayerOperating,
    isPlayerForbidden,
    isPlayerDeleting,
    player,
    teams,
    externalId
  };
}
