import { useCallback } from "react";

import { LIMIT } from "../constants";

const VALUES = Array.from(Array(20), (x, index) => index + 1);

export default function useMaxGoalDifferential({ value, onChange }) {
  const changeValue = useCallback(({ target: { value } }) => onChange(parseInt(value, 10) || LIMIT), [onChange]);

  const options = [
    { value: LIMIT, text: "Disabled" },
    ...VALUES.map(value => ({
      value,
      text: value
    }))
  ];

  return {
    changeValue,
    options
  };
}
