import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getIsVisible as getRoleFormIsVisible } from "@/redux/roleForm/selectors";

import formActions from "@/redux/userForm/actions";
import roleFormActions from "@/redux/roleForm/actions";

export default function useRoleForm() {
  const dispatch = useDispatch();
  const roleFormIsVisible = useSelector(getRoleFormIsVisible);

  const toggleRole = useCallback(
    ({
      initRole,
      role,
      resourceTitle,
      selectedTeams = [],
      teamTitles = [],
      selectedDivisions = [],
      divisionTitles = [],
      selectedSeasons = [],
      seasonTitles = [],
      selectedLeagues = [],
      leagueTitles = [],
      selectedAssociations = [],
      associationTitles = [],
      additionalRoles = []
    }) => {
      // Process isGlobal role first in case association is selected
      if (role.level.id == "*") {
        dispatch(
          initRole
            ? formActions.changeRole({ initRole, role, resourceTitle: resourceTitle || "", additionalRoles })
            : formActions.addRole({ role, resourceTitle: resourceTitle || "", additionalRoles })
        );  
      }
      // Process teams if available
      else if (Array.isArray(selectedTeams) && selectedTeams.length > 0) {
        selectedTeams.forEach((team, index) => {
          const updatedRole = { ...role, team }; // Add team ID to the role
          const teamTitle = teamTitles[index]; // Get title for the team
          dispatch(
            initRole
              ? formActions.changeRole({ initRole, role: updatedRole, resourceTitle: teamTitle, additionalRoles })
              : formActions.addRole({ role: updatedRole, resourceTitle: teamTitle, additionalRoles })
          );
        });
      }
      // Process divisions if available
      else if (Array.isArray(selectedDivisions) && selectedDivisions.length > 0) {
        selectedDivisions.forEach((division, index) => {
          const updatedRole = { ...role, division }; // Add division ID to the role
          const divisionTitle = divisionTitles[index]; // Get title for the division
          dispatch(
            initRole
              ? formActions.changeRole({ initRole, role: updatedRole, resourceTitle: divisionTitle, additionalRoles })
              : formActions.addRole({ role: updatedRole, resourceTitle: divisionTitle, additionalRoles })
          );
        });
      }
      // Process seasons if available
      else if (Array.isArray(selectedSeasons) && selectedSeasons.length > 0) {
        selectedSeasons.forEach((season, index) => {
          const updatedRole = { ...role, season }; // Add season ID to the role
          const seasonTitle = seasonTitles[index]; // Get title for the season
          dispatch(
            initRole
              ? formActions.changeRole({ initRole, role: updatedRole, resourceTitle: seasonTitle, additionalRoles })
              : formActions.addRole({ role: updatedRole, resourceTitle: seasonTitle, additionalRoles })
          );
        });
      }
      // Process leagues if available
      else if (Array.isArray(selectedLeagues) && selectedLeagues.length > 0) {
        selectedLeagues.forEach((league, index) => {
          const updatedRole = { ...role, league }; // Add league ID to the role
          const leagueTitle = leagueTitles[index]; // Get title for the league
          dispatch(
            initRole
              ? formActions.changeRole({ initRole, role: updatedRole, resourceTitle: leagueTitle, additionalRoles })
              : formActions.addRole({ role: updatedRole, resourceTitle: leagueTitle, additionalRoles })
          );
        });
      }
      // Process associations if available
      else if (Array.isArray(selectedAssociations) && selectedAssociations.length > 0) {
        selectedAssociations.forEach((association, index) => {
          const updatedRole = { ...role, association }; // Add association ID to the role
          const associationTitle = associationTitles[index]; // Get title for the association
          dispatch(
            initRole
              ? formActions.changeRole({ initRole, role: updatedRole, resourceTitle: associationTitle, additionalRoles })
              : formActions.addRole({ role: updatedRole, resourceTitle: associationTitle, additionalRoles })
          );
        });
      }
      // If no resources are available
      else {
        dispatch(
          initRole
            ? formActions.changeRole({ initRole, role, resourceTitle: resourceTitle || "", additionalRoles })
            : formActions.addRole({ role, resourceTitle: resourceTitle || "", additionalRoles })
        );    
      }
    },
    [dispatch]
  );  
  
  const removeRole = useCallback(role => dispatch(formActions.removeRole(role)), [dispatch]);

  useEffect(
    () => {
      return () => dispatch(roleFormActions.reset());
    },
    [dispatch]
  );

  return {
    roleFormIsVisible,
    toggleRole,
    removeRole
  };
}
