import React from "react";
import { useSelector, useDispatch } from "react-redux"; 
import ResourceSelect from "./ResourceSelect";

import useAssociationSelect from "./hooks/useAssociationSelect";

function AssociationSelect() {
  const { isLoaded, isLoading, options, isEnabled, toggleSelected, resourceType, isOptionSelected, isAllOptionsSelected } = useAssociationSelect();

  return (
    <ResourceSelect
      label="Select association(s)"
      isLoaded={isLoaded}
      isLoading={isLoading}
      options={options}
      isEnabled={isEnabled}
      toggleSelected={toggleSelected}
      isOptionSelected={isOptionSelected} 
      isAllOptionsSelected={isAllOptionsSelected}
      resourceType="associationList"
    />
  );
}

export default AssociationSelect;
