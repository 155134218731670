import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Popover, PopoverBody } from "reactstrap";

class PenaltyLabel extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    scope: PropTypes.string.isRequired,
    code: PropTypes.string,
    label: PropTypes.string
  };

  static defaultProps = {
    code: "N/A",
    label: "N/A"
  };

  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }

  openPopover = () => {
    this.setState({
      open: true
    });
  };

  closePopover = () => {
    this.setState({
      open: false
    });
  };

  render() {
    const { id, scope, code, label } = this.props;

    const elementId = `penaltyCode-${scope}-${id}`;

    const { open } = this.state;

    return (
      <div
        id={elementId}
        onFocus={this.openPopover}
        onMouseOver={this.openPopover}
        onBlur={this.closePopover}
        onMouseOut={this.closePopover}
      >
        <Popover className="penalty-code__popover" placement="left" isOpen={open} target={elementId}>
          <PopoverBody>{label}</PopoverBody>
        </Popover>
        {code}
      </div>
    );
  }
}

export default PenaltyLabel;
