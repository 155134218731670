import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { useDispatch } from "react-redux";
import useActionsDropdown from "./hooks/useActionsDropdown";
import { isCurrentTeamDeleting } from "@/redux/teams/selectors";
import { CurrentTeamDeletingRoutine } from "@/redux/teams/routines";
import useConfirmDeleteModal from "@/components-16.8/ConfirmDeleteModal/index";
import { Can } from "@/components/Ability";
import { refereeFormDeletingRoutine } from "@/redux/referees/routines";
import { refereesActions } from "@/redux/referees/actions";

export default function ActionsDropdown(props) {
  const { seasonId, id, firstName, lastName, onDelete } = props;

  const dispatch = useDispatch();
  const deleteReferee = useCallback(
    () => {
      onDelete();
      dispatch(refereesActions.list.mergeTool.disable());
      return dispatch(refereeFormDeletingRoutine({ seasonId, id }));
    },
    [dispatch, seasonId, id]
  );

  const { toggle, DeleteModal } = useConfirmDeleteModal({ onDelete: deleteReferee });

  const { triggerEdit, isOpen, toggleIsOpened } = useActionsDropdown({ seasonId, id });

  return (
    <ButtonDropdown isOpen={isOpen} toggle={toggleIsOpened}>
      <DropdownToggle caret size="sm" color="secondary" outline>
        Actions
      </DropdownToggle>

      <DropdownMenu>
        <Can I="update" this={{ type: "referees", id }}>
          <DropdownItem
            onClick={triggerEdit}
            action="update"
            size="sm"
            color="primary"
            tag={Link}
            to={`/seasons/${seasonId}/referees/${id}/edit`}
          >
            Edit
          </DropdownItem>
        </Can>
        <Can I="delete" this={{ type: "referees", id }}>
          <DropdownItem divider />
          <DropdownItem className="text-danger" onClick={toggle}>
            Delete
          </DropdownItem>
          <DeleteModal resourceTitle={firstName + " " + lastName} resourceType="referee" />
        </Can>
      </DropdownMenu>
    </ButtonDropdown>
  );
}

ActionsDropdown.propTypes = {
  seasonId: PropTypes.string,
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string
};
