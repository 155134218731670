import axios from "axios";

import { config } from "@/config";

import { parseResponse, normalizeResponse, normalizeRequest, stringifyRequest, serializeParams } from "./utils";

const adapter = process.env.NODE_ENV === "test" ? require("axios/lib/adapters/http") : undefined;

const api = axios.create({
  baseURL: config.API_BASE_URL,
  headers: {
    "Content-Type": "application/vnd.api+json",
    Accept: "application/vnd.api+json"
  },
  transformResponse: [parseResponse, normalizeResponse],
  transformRequest: [normalizeRequest, stringifyRequest],
  paramsSerializer: serializeParams,
  adapter
});

api.interceptors.response.use(response => {
  const {
    headers,
    data: { data, errors, meta, _rawData: rawData },
    ...rest
  } = response;

  return {
    token: headers["x-token"],
    headers,
    data,
    errors,
    meta,
    rawData,
    ...rest
  };
});

export default api;
