import _orderBy from "lodash/orderBy";
import { useCallback, useState } from "react";
import _trimStart from "lodash/trimStart";

import { useDashboardFormNamespaceContext } from "@/components/DashboardForm";

export default function usePenaltyPicker(options) {
  const { value: penalties, onChange: changePenalties } = options;

  const existentPenalties = options.penaltyCodes.map(attrs => ({
    ...attrs,
    isMissing: false
  }));

  const missingPenalties = penalties
    .filter(code => !options.penaltyCodes.find(item => item.code === code))
    .map(code => ({ code, label: "", isMissing: true }));

  const togglePenaltyEnabled = useCallback(
    ({ target: { name, checked } }) => {
      const nextPenalties = checked ? [...penalties, name] : penalties.filter(code => code !== name);

      changePenalties(nextPenalties);
    },
    [changePenalties, penalties]
  );

  const clearSelectedPenalties = useCallback(() => changePenalties([]), [changePenalties]);

  const namespace = useDashboardFormNamespaceContext();

  const allPenalties = [...missingPenalties, ...existentPenalties].map(({ code, label, isMissing }, index) => {
    const id = `${namespace}__flagging-settings__penalty_picker__penalty_${index}`;
    const enabled = !!penalties.find(selectedCode => selectedCode === code);

    return {
      id,
      code,
      label,
      enabled,
      isMissing,
      input: {
        id,
        name: code,
        checked: enabled,
        onChange: togglePenaltyEnabled
      }
    };
  });

  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");

  const queryIsDirty = query.length > 0;

  const open = useCallback(() => setIsOpen(true), [setIsOpen]);
  const close = useCallback(
    () => {
      setIsOpen(false);
      setQuery("");
    },
    [setIsOpen]
  );

  const onQueryChange = useCallback(({ target: { value } }) => setQuery(_trimStart(value.toLowerCase())), [setQuery]);

  const resetQuery = useCallback(() => setQuery(""), [setQuery]);

  const filteredPenaltyCodes = queryIsDirty
    ? allPenalties.filter(({ code, label }) =>
        [code, label]
          .join(" ")
          .toLowerCase()
          .includes(query)
      )
    : allPenalties;

  const penaltyCodes = _orderBy(filteredPenaltyCodes, ["isMissing", "code"], ["desc", "asc"]);

  const totalCountSelected = penalties.length;

  const selectedCodes = penaltyCodes.filter(({ enabled }) => enabled).map(({ code }) => code);

  const notSelectedCodes = penaltyCodes.filter(({ enabled }) => !enabled).map(({ code }) => code);

  const countSelected = selectedCodes.length;
  const isAllSelected = countSelected === penaltyCodes.length;
  const isNoneSelected = countSelected === 0;
  const totalCountMissing = missingPenalties.length;
  const countMissing = penaltyCodes.filter(({ isMissing }) => isMissing).length;

  const toggleAllEnabled = useCallback(
    ({ target: { checked } }) => {
      const nextPenalties = checked
        ? [...allPenalties.filter(({ enabled }) => enabled).map(({ code }) => code), ...notSelectedCodes]
        : allPenalties.filter(({ enabled, code }) => enabled && !selectedCodes.includes(code)).map(({ code }) => code);

      changePenalties(nextPenalties);
    },
    [changePenalties, selectedCodes, allPenalties, notSelectedCodes]
  );

  return {
    isOpen,
    open,
    close,
    countSelected,
    isAllSelected,
    isNoneSelected,
    totalCountSelected,
    countMissing,
    totalCountMissing,
    penaltyCodes,
    toggleAllEnabled,
    clearSelectedPenalties,
    listIsNotBlank: allPenalties.length > 0,
    query: {
      input: {
        value: query,
        onChange: onQueryChange
      },
      isDirty: queryIsDirty,
      onReset: resetQuery
    }
  };
}
