import { takeLatest } from "redux-saga/effects";

import { GenericGenerator } from "./GenericGenerator";

import { createResource, loadResources, loadResource, updateResource, deleteResource } from "@/redux/resources/sagas";

export function beginSaga(routine) {
  class GenericSaga {
    constructor(routine) {
      this.routine = routine;
      this.succeedFn = () => {};
      this.failureFn = () => {};
      this.performFn = null;
      this.finallyPerform = null;
    }

    initiallyPut(fn) {
      this.initiallyPutFn = fn;

      return this;
    }

    perform(fn) {
      this.performFn = fn;

      return this;
    }

    withService(service) {
      this.serviceFn = service;

      return this;
    }

    setRequestSaga(saga, identityMaker) {
      this.requestSaga = saga;
      this.identityMaker = identityMaker;

      return this;
    }

    createResource(identityMaker) {
      return this.setRequestSaga(createResource, identityMaker);
    }

    loadResources(identityMaker) {
      return this.setRequestSaga(loadResources, identityMaker);
    }

    loadResource(identityMaker) {
      return this.setRequestSaga(loadResource, identityMaker);
    }

    updateResource(identityMaker) {
      return this.setRequestSaga(updateResource, identityMaker);
    }

    deleteResource(identityMaker) {
      return this.setRequestSaga(deleteResource, identityMaker);
    }

    andSucceedWith(fn) {
      this.succeedFn = fn;

      return this;
    }

    andFailWith(fn) {
      this.failureFn = fn;

      return this;
    }

    andRedirectTo(fn) {
      this.redirectFn = fn;

      return this;
    }

    andFinallyPerform(fn) {
      this.finalFn = fn;

      return this;
    }

    endSaga() {
      this.generator = GenericGenerator.bind(this);

      return this;
    }

    takeLatest() {
      if (!this.generator) {
        throw new Error("You didn't ended saga. Please add .endSaga() in order to get it ready.");
      }

      return takeLatest(this.routine.TRIGGER, this.generator);
    }
  }

  return new GenericSaga(routine);
}
