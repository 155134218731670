import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getRole } from "@/redux/userFilter/selectors";
import actions from "@/redux/userFilter/actions";

export default function useRoleSelect() {
  const value = useSelector(getRole);
  const dispatch = useDispatch();
  const setRole = useCallback(({ target: { value } }) => dispatch(actions.setRole(value)), [dispatch]);

  return {
    value,
    setRole
  };
}
