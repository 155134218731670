import React, { useState } from "react";
import Modal from "reactstrap/lib/Modal";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import Button from "reactstrap/lib/Button";

export function useDeleteProtection() {
  const [isTeamsDeleteProtectionDisplayed, setIsTeamsDeleteProtectionDisplayed] = useState(false);

  const toggle = () => setIsTeamsDeleteProtectionDisplayed(!isTeamsDeleteProtectionDisplayed);

  const DeleteProtectionModal = () => {
    return (
      <Modal className="teams-exist-modal" isOpen={isTeamsDeleteProtectionDisplayed} toggle={toggle} centered>
        <ModalHeader>Unable to Delete Division</ModalHeader>
        <ModalBody>
          <p>Divisions with teams cannot be deleted. Move or delete the teams within the division and try again.</p>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex w-100">
            <div className="ml-auto">
              <Button outline type="button" color="secondary" onClick={toggle}>
                OK
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    );
  };

  return {
    isTeamsDeleteProtectionDisplayed,
    toggle,
    DeleteProtectionModal
  };
}
