import React from "react";
import PropTypes from "prop-types";

import Division from "../List/Item";

import { useTeamGroup } from "./hooks";

function TeamGroup({ divisionTitle, teams }) {
  const { select, deselect } = useTeamGroup();

  const teamsList = teams.map(({ id, title, ...rest }) => (
    <Division key={id} id={id} title={title} {...rest} select={select} deselect={deselect} />
  ));

  return (
    <div className="report-data-sources-select-list__group">
      <p className="report-data-sources-select-list__group__title list-group-item list-group-item-light">
        {divisionTitle}
      </p>

      {teamsList}
    </div>
  );
}

TeamGroup.propTypes = {
  divisionTitle: PropTypes.string.isRequired,
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  )
};

export default TeamGroup;
