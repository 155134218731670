import { connect } from "react-redux";

import actions from "@/redux/reportDataSourcesSelect/actions";

import SeasonGroup from "./SeasonGroup";

const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch, { singleSelect, extended, penalties }) => {
  let select = id => dispatch(actions.seasonList.select({ id, singleSelect, loadDivisions: extended }));
  if (penalties) {
    select = id => dispatch(actions.seasonList.select({ id, singleSelect, loadPenalties: true }));
  }

  return {
    select,
    deselect: id => dispatch(actions.seasonList.deselect({ id, singleSelect }))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SeasonGroup);
