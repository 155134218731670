import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { resubscribingRoutine } from "@/redux/unsubscribeByToken";

export default function useUnsubscribeByTokenResubscribeAction({ token, teamId }) {
  const dispatch = useDispatch();

  return useCallback(() => dispatch(resubscribingRoutine({ token, teamId })), [dispatch, token, teamId]);
}
