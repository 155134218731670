import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMeta, deletingRoutine } from "@/redux/divisionForm";

import ConfirmDeleteModal from "@/components-16.8/ConfirmDeleteModal";

export default function useActionsDropdown({ seasonId, id }) {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpened = useCallback(() => setIsOpen(!isOpen), [setIsOpen, isOpen]);

  const { isSubmitting, isDeleting } = useSelector(getMeta);

  const deleteDivision = useCallback(() => dispatch(deletingRoutine({ seasonId: seasonId, divisionId: id })), [
    dispatch,
    seasonId,
    id
  ]);

  //  ======TRIGGERS======
  const triggerDelete = useCallback(
    () => {
      <ConfirmDeleteModal />;
    },
    [seasonId, id]
  );

  const triggerEdit = useCallback(() => {}, [seasonId, id]);

  return { isOpen, toggleIsOpened, triggerDelete, triggerEdit, deleteDivision, isSubmitting, isDeleting };
}
