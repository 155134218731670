import React from "react";
import PropTypes from "prop-types";

import { CustomInput } from "reactstrap";

import Spinner from "@/components/Spinner";

import useToggleViewedStatus from "./hooks/useToggleViewedStatus";

function ViewedStatus({ id, seasonId, isChanging, isViewed }) {
  const toggleViewedStatus = useToggleViewedStatus({ id, seasonId, isViewed });

  if (isChanging) {
    return <Spinner theme="scale" color={"yellow"} size="s" />;
  }

  return (
    <CustomInput
      type="checkbox"
      id={`viewed-status-check-${id}`}
      checked={isViewed === true}
      onChange={toggleViewedStatus}
    />
  );
}

ViewedStatus.propTypes = {
  id: PropTypes.string.isRequired,
  seasonId: PropTypes.string.isRequired,
  isViewed: PropTypes.bool.isRequired,
  isChanging: PropTypes.bool.isRequired
};

export default ViewedStatus;
