import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import PageLoader from "@/components/PageLoader";

class List extends PureComponent {
  static propTypes = {
    isLoaded: PropTypes.bool,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    sources: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
      })
    )
  };

  static defaultProps = {
    isError: false,
    isLoaded: false,
    isLoading: false,
    sources: []
  };

  render() {
    const { isError, isLoaded, isLoading, sources } = this.props;

    return (
      <div className="report-data-sources-list">
        <PageLoader isLoading={isLoading} isLoaded={isLoaded}>
          <h3 className="report-data-sources-list__title">Data Sources</h3>

          {isError ? (
            <p className="text-danger">Failed to load data sources list due to unexpected error.</p>
          ) : (
            <Fragment>
              <p>
                The data for this report was gathered from the following {sources.length}{" "}
                {sources.length === 1 ? "source" : "sources"}:
              </p>
              <ul>
                {sources.map(({ id, title, type }) => (
                  <li key={`${type}-${id}`}>{title}</li>
                ))}
              </ul>
            </Fragment>
          )}
        </PageLoader>
      </div>
    );
  }
}

export default List;
