import { useSelector } from "react-redux";

import { getTeam, getPlayers, getMeta, getIsEditing, getCoaches } from "@/redux/teamRoster";
import { getIgnoreTeamLock } from "@/redux/ability/selectors";

export default function useRoster() {
  const team = useSelector(getTeam);
  const ignoreLock = useSelector(getIgnoreTeamLock);
  const players = useSelector(getPlayers);
  const coaches = useSelector(getCoaches);
  const isEditing = useSelector(getIsEditing);

  const totalPlayersCount = players.filter(({ id }) => id !== "").length;
  const filteredPlayersCount = totalPlayersCount;

  const totalCoachesCount = coaches.filter(({ id }) => id !== "").length;
  const filteredCoachesCount = totalCoachesCount;

  const { isOperating } = useSelector(getMeta);

  return {
    team,
    players,
    coaches,
    totalPlayersCount,
    filteredPlayersCount,
    totalCoachesCount,
    filteredCoachesCount,
    ignoreLock,
    isEditing,
    isOperating
  };
}
