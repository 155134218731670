import React from "react";
import { Link } from "react-router-dom";

function seasonTitleRenderer({ value, data: { id } }) {
    return (<Link to={`/seasons/${id}`} >
        {value}
    </Link>);
}

export default seasonTitleRenderer;
