import { handleActions } from "redux-actions";

import { clearGamesheet } from "../../actions";
import { loadGamesheetRoutine } from "../../routines";

const initialState = {};

function reducePenaltyTypes(state, { payload: { game } }) {
  return (game.season.settings.penaltyCodes || []).reduce(
    (result, { code, label }) => ({ ...result, [code]: label }),
    {}
  );
}

export default handleActions(
  {
    [loadGamesheetRoutine.SUCCESS]: reducePenaltyTypes,
    [clearGamesheet]: () => ({ ...initialState })
  },
  initialState
);
