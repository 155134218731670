import { useEffect, useState, useMemo } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { CSVRefereeImportDisplayPage as DisplayPage } from "@/components-16.8/RefereeImportWizard/types/displayPage";

import { ImportStatus } from "@/components-16.8/RefereeImportWizard/types/importStatus";

import {
    csvRefereeImportDisplayPageState,
    importStatusState
  } from "@/components-16.8/RefereeImportWizard/state";

export function useWizard() {

    const [displayPage, setDisplayPage] = useRecoilState(csvRefereeImportDisplayPageState);
    const [importStatus, setImportStatus] = useRecoilState(importStatusState);

    useEffect(() => {
        switch(importStatus) {
            case ImportStatus.PENDING: setDisplayPage(DisplayPage.LANDING); return;
            case ImportStatus.PARSING: setDisplayPage(DisplayPage.LOADING); return;
            case ImportStatus.PARSE_ERROR: setDisplayPage(DisplayPage.PARSE_FAILURE); return;
            case ImportStatus.PARSE_SUCCESS: setDisplayPage(DisplayPage.PARSE_SUCCESS); return;
            case ImportStatus.IMPORTING: setDisplayPage(DisplayPage.IMPORT_PROGRESS); return;
            case ImportStatus.IMPORT_ERROR: setDisplayPage(DisplayPage.IMPORT_FAILURE); return;
            case ImportStatus.IMPORT_SUCCESS: setDisplayPage(DisplayPage.IMPORT_SUCCESS); return;
            default: setDisplayPage(DisplayPage.LOADING); return;
        }
    }, [importStatus]);

    return {
        displayPage
    }

}