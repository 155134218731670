import React from "react";

import "./NotFound.scss";

const NotFound = () => (
  <div className="not-found">
    <h1 className="not-found__title">404 Page Not Found</h1>
    <div className="not-found__text">
      <p>We are sorry but the page you are looking for does not exist.</p>
      <a className="not-found__link" href="/">
        ← Go back to homepage
      </a>
    </div>
  </div>
);

export default NotFound;
