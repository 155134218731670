export const FIELDS_MAPPING = {
  title: "Division name",
  externalId: "External ID"
};

export const DEFAULT_VALUES = {
  title: "",
  externalId: "",
  usingSeasonSettings: true
};

export const CONSTRAINTS = {
  title: {
    presence: { allowEmpty: false, message: "^Division name can’t be blank" }
  }
};
