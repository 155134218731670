import React, { Fragment } from "react";
import PropTypes from "prop-types";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";
import InvitationForm from "@/components/InvitationForm";
import RoleForm from "@/components/RoleForm";

import PageLoader from "@/components/PageLoader";

import useInvitationForm from "../hooks/useInvitationForm";
import useRoleForm from "../hooks/useRoleForm";

function InvitationsShow({ match: { params } }) {
  const { code } = params;

  const { isLoading, isLoaded } = useInvitationForm(code);
  const { roleFormIsVisible, toggleRole, removeRole } = useRoleForm();

  return (
    <ProtectedLayout className="invitations-page" subject={{ type: "invitations" }} action="read">
      <Head title="Invitation Details" />

      <PageLoader isLoading={isLoading} isLoaded={isLoaded}>
        {isLoaded && <Breadcrumbs items={[{ title: "Invitations", url: "/invitations" }, { title: code }]} />}

        {roleFormIsVisible ? (
          <RoleForm onSubmit={toggleRole} onRemove={removeRole} />
        ) : (
          <Fragment>
            <TitleBar title="Invitation Details" />
            <InvitationForm code={code} />
          </Fragment>
        )}
      </PageLoader>
    </ProtectedLayout>
  );
}

InvitationsShow.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      code: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default InvitationsShow;
