import { handleActions } from "redux-actions";

import { divisionListLoadingRoutine as loadingRoutine } from "../routines";
import actions from "../actions";

const initialState = {
  isLoaded: false,
  isLoading: false,
  ids: [],
  data: {},
  selectedIds: [] 
};

function updateSeasonDivisions(state, seasonId, data) {
  return {
    ...state,
    [seasonId]: {
      ...state[seasonId],
      ...data
    }
  };
}

function reduceSeasonSelected(state, { payload: { id: seasonId } }) {
  return {
    ...state,
    [seasonId]: {
      isLoaded: false,
      isLoading: false,
      ids: [],
      data: {},
      ...state[seasonId]
    }
  };
}

function reduceLoadingRequest(state, { payload }) {
  const { seasonId } = payload;

  return updateSeasonDivisions(state, seasonId, { isLoading: true });
}

function reduceLoadingSuccess(state, { payload }) {
  const { seasonId, ids, divisions } = payload;

  const data = divisions.reduce((result, { id, title }) => ({ ...result, [id]: { title } }), {});

  return updateSeasonDivisions(state, seasonId, {
    ids,
    data,
    isLoaded: true
  });
}

function reduceLoadingFailure(state, { payload }) {
  const { seasonId } = payload;

  return updateSeasonDivisions(state, seasonId, { isLoaded: false });
}

function reduceLoadingFulfill(state, { payload }) {
  const { seasonId } = payload;

  return updateSeasonDivisions(state, seasonId, { isLoading: false });
}

function reduceSelectDivision(state, { payload: { id } }) {
  return {
    ...state,
    selectedIds: [...state.selectedIds, id] // Add league ID to selectedIds array
  };
}

function reduceDeselectDivision(state, { payload: { id } }) {
  return {
    ...state,
    selectedIds: state.selectedIds.filter(divisionId => divisionId !== id) // Remove league ID from selectedIds array
  };
}

function reduceSelectAllDivisions(state) {
  const allDivisionIds = Object.keys(state.data).flatMap(
    seasonId => (state[seasonId] && state[seasonId].ids) || []
  );
  return {
    ...state,
    selectedIds: allDivisionIds // Set selectedIds to all league IDs
  };
}

function reduceClearDivisions(state) {
  return {
    ...state,
    selectedIds: [] // Clear selectedIds array
  };
}

export default handleActions(
  {
    [actions.seasonList.select]: reduceSeasonSelected,
    [loadingRoutine.REQUEST]: reduceLoadingRequest,
    [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [loadingRoutine.FAILURE]: reduceLoadingFailure,
    [loadingRoutine.FULFILL]: reduceLoadingFulfill,
    [actions.divisionList.select]: reduceSelectDivision, // Handle individual league selection
    [actions.divisionList.deselect]: reduceDeselectDivision, // Handle individual league deselection
    [actions.divisionList.selectAll]: reduceSelectAllDivisions, // Handle selecting all leagues
    [actions.divisionList.clear]: reduceClearDivisions // Handle clearing all selected leagues
  },
  initialState
);
