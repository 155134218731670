import React from "react";
import { Table, Button } from "reactstrap";

import { useGameReportEmailNotificationSettings } from "./hooks";

import SeasonTeamListSettings from "./SeasonTeamListSettings";

import "./GameReportEmailNotificationSettings.scss";

function GameReportEmailNotificationSettings() {
  const { settings, canDisableAll, canEnableAll, disableAll, enableAll } = useGameReportEmailNotificationSettings();

  return (
    <React.Fragment>
      <h3>Post Game Email</h3>
      <Table className="email-notification-settings__table" borderless>
        <thead>
          <tr>
            <th scope="col">Team</th>
            <th scope="col">Division</th>
            <th scope="col">
              {canDisableAll && (
                <Button
                  className="email-notification-settings__link"
                  color="link"
                  onClick={disableAll}
                  onKeyPress={disableAll}
                >
                  Disable all
                </Button>
              )}
              {canEnableAll && (
                <Button
                  className="email-notification-settings__link"
                  color="link"
                  onClick={enableAll}
                  onKeyPress={enableAll}
                >
                  Enable all
                </Button>
              )}
            </th>
          </tr>
        </thead>
        {settings.map(season => (
          <SeasonTeamListSettings key={season.id} {...season} />
        ))}
      </Table>
    </React.Fragment>
  );
}

export default GameReportEmailNotificationSettings;
