import _orderBy from "lodash/orderBy";

const selectRoot = state => state.billingReport;
const selectReport = state => selectRoot(state).report;

export const getBillingReportIsLoaded = state => selectReport(state).isLoaded;

export const getBillingReportIsLoading = state => selectReport(state).isLoading;

export const getBillingReportIsForbidden = state => selectReport(state).isForbidden;

export const getBillingReportTeamGamesStatsBySeasons = state => {
  const { seasons, divisions } = selectReport(state);
  const stats = selectReport(state).teamGamesStats;

  const groupedTeamGamesStats = Object.entries(seasons).reduce(
    (result, [id, { title }]) => [
      ...result,
      {
        season: { id, title },
        teams: _orderBy(stats.filter(({ seasonId }) => seasonId === id), ["teamTitle"], ["asc"]).map(
          ({ divisionId, ...rest }) => ({
            ...rest,
            divisionId,
            divisionTitle: divisions[divisionId].title
          })
        )
      }
    ],
    []
  );

  return _orderBy(groupedTeamGamesStats, ["season.title"], ["asc"]);
};
