import React from "react";
import PropTypes from "prop-types";

import { Container } from "reactstrap";

import TeamFilter from "@/components/TeamFilter";

import List from "./List";
import SelectedList from "./SelectedList";

function TeamSelector({ seasonId }) {
  return (
    <Container>
      <TeamFilter disallowSeasonId={seasonId} />
      <List />
      <SelectedList />
    </Container>
  );
}

TeamSelector.propTypes = {
  seasonId: PropTypes.string.isRequired
};

export default TeamSelector;
