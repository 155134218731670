import React, { Fragment, useEffect, useState } from "react";
import { Alert } from "reactstrap";
import moment from "moment";

import { config } from "@/config";

import "./WarningAlerts.scss";

const WarningAlerts = () => {
  const currentDate = new Date();

  const browserIsIE = /* @cc_on!@ */ false || !!document.documentMode;
  const browserIsEdge = !browserIsIE && !!window.StyleMedia;

  const [hide, setHide] = useState(false);

  return (
    <Fragment>
      {config.DISPLAY_STAGING_WARNING &&
        !hide && (
          <Alert className="warning-alert" color="secondary">
            Notice – You are using the {config.NODE_ENV || "legacy"} configuration.{" "}
            <a href="#" onClick={() => setHide(true)}>
              Hide This Message
            </a>
          </Alert>
        )}

      {currentDate < config.MAINTENANCE_FINISH_DATE && (
        <Alert className="warning-alert" color="success">
          GameSheet will be unavailable {moment(config.MAINTENANCE_START_DATE).format("dddd MMMM Do")} while we perform
          maintenance and upgrades.
        </Alert>
      )}

      {(browserIsIE || browserIsEdge) && (
        <Alert className="warning-alert" color="warning">
          Your browser is not supported. For the best experience please use one of these free alternatives:{" "}
          <a href="https://www.google.com/chrome/">Chorme</a>,{" "}
          <a href="https://www.mozilla.org/en-CA/firefox/new/">Firefox</a>, <a href="https://www.opera.com/">Opera</a>.
        </Alert>
      )}
    </Fragment>
  );
};

export default WarningAlerts;
