import _orderBy from "lodash/orderBy";

const selectRoot = state => state.reportDataSourcesSelect;

const selectFilterAssociations = state => selectRoot(state).associations;
const selectFilterLeagues = state => selectRoot(state).leagues;
const selectFilterLeaguesDataByAssociation = (state, associationId) => selectFilterLeagues(state).data[associationId];
const selectFilterSeasons = state => selectRoot(state).seasons;
const selectFilterPenalties = state => selectRoot(state).penalties;
const selectFilterDivisions = state => selectRoot(state).divisions;
const selectFilterTeams = state => selectRoot(state).teams;

const selectFilterSeasonsSelectedIds = state => {
  const { selectedIds, includeArchived } = selectFilterSeasons(state);

  const selectedLeagues = getReportDataSourcesSelectLeagueList(state).filter(({ isSelected }) => isSelected);

  if (selectedLeagues.length === 0) {
    return [];
  }

  return selectedLeagues.reduce(
    (result, { id: leagueId }) => [
      ...result,
      ...selectFilterSeasons(state)
        .data[leagueId].seasons.filter(
          ({ id, type }) => (includeArchived || type === "seasons" ? selectedIds.includes(id) : false)
        )
        .map(({ id }) => id)
    ],
    []
  );
};

const selectFilterSeasonFlaggedPenalties = state => {
  const seasons = getReportDataSourcesSelectSeasonGroupList(state)
    .reduce((result, { seasons }) => [...result, ...seasons], [])
    .filter(({ isSelected }) => isSelected);

  if (!seasons || seasons.length !== 1 || !seasons[0].flaggedPenalties) {
    return [];
  }

  return seasons[0].flaggedPenalties;
};

const selectFilterSeasonPenaltiesFlagSet = state => {
  const seasons = getReportDataSourcesSelectSeasonGroupList(state)
    .reduce((result, { seasons }) => [...result, ...seasons], [])
    .filter(({ isSelected }) => isSelected);

  if (!seasons || seasons.length !== 1) {
    return false;
  }

  const penaltySeasonInfo = selectFilterPenalties(state).seasons[seasons[0].id];
  return penaltySeasonInfo ? penaltySeasonInfo.flagged : true;
};

const selectFilterSeasonPenalties = state => {
  const seasons = getReportDataSourcesSelectSeasonGroupList(state)
    .reduce((result, { seasons }) => [...result, ...seasons], [])
    .filter(({ isSelected }) => isSelected);

  if (!seasons || seasons.length !== 1 || !seasons[0].penaltyCodes) {
    return [];
  }

  const penaltySeasonInfo = selectFilterPenalties(state).seasons[seasons[0].id];
  const selectedCodes = (penaltySeasonInfo && penaltySeasonInfo.selected) || [];

  const penalties = seasons[0].penaltyCodes.map(data => ({ ...data, isSelected: selectedCodes.includes(data.code) }));

  return penalties;
};

const selectFilterPenaltiesSelected = state => {
  return selectFilterSeasonPenalties(state).reduce((collector, elem) => {
    if (elem.isSelected) {
      return [...collector, elem];
    } else {
      return [...collector];
    }
  }, []);
};

const selectFilterPenaltiesNotSelected = state => {
  return selectFilterSeasonPenalties(state).reduce((collector, elem) => {
    if (elem.isSelected) {
      return [...collector];
    } else {
      return [...collector, elem];
    }
  }, []);
};

export const getPenaltyList = state => _orderBy(selectFilterSeasonPenalties(state), ["label"], ["asc"]);

export const getSelectedPenalties = state => selectFilterPenaltiesSelected(state);

export const getPenaltiesNotSelected = state => selectFilterPenaltiesNotSelected(state);

export const getPenaltyIsSelected = state => selectFilterPenaltiesSelected(state).length > 0;

export const getAllPenaltiesSelected = state => selectFilterPenaltiesNotSelected(state).length === 0;

export const getFlaggedPenalties = state => selectFilterSeasonFlaggedPenalties(state);

export const getPenaltySeasonIsFlagged = state => selectFilterSeasonPenaltiesFlagSet(state);

const selectFilterDivisionsNotSelectedIds = state => {
  const { selectedIds, data } = selectFilterDivisions(state);
  const selectedSeasonIds = selectFilterSeasonsSelectedIds(state);

  return selectedSeasonIds.reduce((result, seasonId) => {
    return [
      ...result,
      ...data[seasonId].divisions.reduce((ids, { id }) => (selectedIds.includes(id) ? ids : [...ids, id]), [])
    ];
  }, []);
};

const selectFilterDivisionsSelectedIds = state => {
  const { selectedIds, data } = selectFilterDivisions(state);
  const selectedSeasonIds = selectFilterSeasonsSelectedIds(state);

  return selectedSeasonIds.reduce((result, seasonId) => {
    return [
      ...result,
      ...data[seasonId].divisions.reduce((ids, { id }) => (selectedIds.includes(id) ? [...ids, id] : ids), [])
    ];
  }, []);
};

const selectFilterTeamsNotSelectedIds = state => {
  const { selectedIds, data } = selectFilterTeams(state);
  const selectedDivisionsIds = selectFilterDivisionsSelectedIds(state);

  return selectedDivisionsIds.reduce((result, divisionId) => {
    return [
      ...result,
      ...data[divisionId].teams.reduce((ids, { id }) => (selectedIds.includes(id) ? ids : [...ids, id]), [])
    ];
  }, []);
};

const selectFilterTeamsSelectedIds = state => {
  const { selectedIds, data } = selectFilterTeams(state);
  const selectedDivisionsIds = selectFilterDivisionsSelectedIds(state);

  return selectedDivisionsIds.reduce((result, divisionId) => {
    return [
      ...result,
      ...data[divisionId].teams.reduce((ids, { id }) => (selectedIds.includes(id) ? [...ids, id] : ids), [])
    ];
  }, []);
};

export const getReportDataSourcesSelectAssociationList = state => {
  const { selectedId, data } = selectFilterAssociations(state);

  const associations = data.map(({ id, title }) => ({
    id,
    title,
    isSelected: id === selectedId
  }));

  return _orderBy(associations, ["title"], ["asc"]);
};

export const getReportDataSourcesSelectAssociationListIsLoaded = state => selectFilterAssociations(state).isLoaded;

export const getReportDataSourcesSelectAssociationListIsLoading = state => selectFilterAssociations(state).isLoading;

export const getReportDataSourcesSelectSelectedAssociationId = state => selectFilterAssociations(state).selectedId;

export const getReportDataSourcesSelectAssociationIsSelected = state =>
  selectFilterAssociations(state).selectedId !== null;

export const getReportDataSourcesSelectLeagueList = state => {
  const selectedAssociationId = selectFilterAssociations(state).selectedId;

  if (!selectedAssociationId) {
    return [];
  }

  const { selectedIds } = selectFilterLeagues(state);
  const { leagues } = selectFilterLeaguesDataByAssociation(state, selectedAssociationId);

  return _orderBy(leagues, ["title"], ["asc"]).map(({ id, title }) => ({
    id,
    title,
    isSelected: selectedIds.includes(id)
  }));
};

export const getReportDataSourcesSelectLeagueIdsNotSelected = state => {
  const { selectedIds } = selectFilterLeagues(state);
  const selectedAssociationId = selectFilterAssociations(state).selectedId;

  const { leagues } = selectFilterLeaguesDataByAssociation(state, selectedAssociationId);

  return leagues.reduce((result, { id }) => (selectedIds.includes(id) ? result : [...result, id]), []);
};

export const getReportDataSourcesSelectHasAllLeaguesSelected = state => {
  const selectedAssociationId = selectFilterAssociations(state).selectedId;

  if (!selectedAssociationId) {
    return false;
  }

  const { leagues } = selectFilterLeaguesDataByAssociation(state, selectedAssociationId);

  return leagues.length === 0 || getReportDataSourcesSelectLeagueIdsNotSelected(state).length === 0;
};

export const getReportDataSourcesSelectLeagueListIsLoaded = state => {
  const selectedAssociationId = getReportDataSourcesSelectSelectedAssociationId(state);

  if (!selectedAssociationId) {
    return false;
  }

  const { isLoaded } = selectFilterLeaguesDataByAssociation(state, selectedAssociationId);

  return isLoaded;
};

export const getReportDataSourcesSelectLeagueListIsLoading = state => {
  const selectedAssociationId = selectFilterAssociations(state).selectedId;

  if (!selectedAssociationId) {
    return false;
  }

  const { isLoading } = selectFilterLeaguesDataByAssociation(state, selectedAssociationId);

  return isLoading;
};

export const getReportDataSourcesSelectLeagueListIsLoadedForAssociation = (state, associationId) =>
  selectFilterLeagues(state).data[associationId].isLoaded === true;

export const getReportDataSourcesSelectSeasonListIsLoadedForAssociation = (state, leagueId) =>
  selectFilterSeasons(state).data[leagueId].isLoaded === true;

export const getReportDataSourcesSelectLeagueIsSelected = state => selectFilterLeagues(state).selectedIds.length > 0;

export const getReportDataSourcesSelectSeasonGroupList = state => {
  const selectedLeagues = getReportDataSourcesSelectLeagueList(state).filter(({ isSelected }) => isSelected);

  if (selectedLeagues.length === 0) {
    return [];
  }

  const { selectedIds, includeArchived } = selectFilterSeasons(state);

  return _orderBy(selectedLeagues, ["title"], ["asc"]).map(({ id, title }) => ({
    leagueId: id,
    leagueTitle: title,
    seasons: _orderBy(selectFilterSeasons(state).data[id].seasons, ["title"], ["asc"])
      .filter(({ type }) => includeArchived || type === "seasons")
      .map(data => ({
        ...data,
        isSelected: selectedIds.includes(data.id)
      }))
  }));
};

export const getReportDataSourcesSelectSeasonIdsNotSelected = state => {
  const selectedLeagues = getReportDataSourcesSelectLeagueList(state).filter(({ isSelected }) => isSelected);

  if (selectedLeagues.length === 0) {
    return [];
  }

  const { includeArchived } = selectFilterSeasons(state);
  const selectedIds = selectFilterSeasonsSelectedIds(state);

  const allSeasonIds = selectedLeagues.reduce(
    (result, { id: leagueId }) => [
      ...result,
      ...selectFilterSeasons(state)
        .data[leagueId].seasons.filter(({ type }) => includeArchived || type === "seasons")
        .map(({ id }) => id)
    ],
    []
  );

  return allSeasonIds.filter(id => selectedIds.includes(id) === false);
};

export const getReportDataSourcesHasAllSeasonsSelected = state =>
  getReportDataSourcesSelectSeasonIdsNotSelected(state).length === 0;

export const getReportDataSourcesSelectSeasonListIsLoaded = state => {
  return (
    Object.values(selectFilterSeasons(state).data)
      .reduce((result, { isLoaded }) => [...result, isLoaded], [])
      .filter(isLoaded => isLoaded).length > 0
  );
};

export const getReportDataSourcesSelectSeasonListIsLoading = state => {
  return (
    Object.values(selectFilterSeasons(state).data)
      .reduce((result, { isLoading }) => [...result, isLoading], [])
      .filter(isLoading => isLoading).length > 0
  );
};

export const getReportDataSourcesSelectSelectedSeasonsCount = state => selectFilterSeasonsSelectedIds(state).length;

export const getReportDataSourcesSeasonIsSelected = state => selectFilterSeasonsSelectedIds(state).length > 0;

export const getReportDataSourcesSeasonIsArchivedIncluded = state => selectFilterSeasons(state).includeArchived;

export const getReportDataSourcesSeasonHasArchived = state => {
  const selectedLeagues = getReportDataSourcesSelectLeagueList(state).filter(({ isSelected }) => isSelected);

  const archivedSeasons = selectedLeagues.reduce(
    (result, { id: leagueId }) => [
      ...result,
      ...selectFilterSeasons(state).data[leagueId].seasons.filter(({ type }) => type === "archivedSeasons")
    ],
    []
  );

  return archivedSeasons.length > 0;
};

export const getDivisionListIsLoadedForSeason = (state, seasonId) =>
  selectFilterDivisions(state).data[seasonId].isLoaded === true;

export const getDivisionGroupList = state => {
  const selectedSeasons = getReportDataSourcesSelectSeasonGroupList(state)
    .reduce((result, { seasons }) => [...result, ...seasons], [])
    .filter(({ isSelected }) => isSelected);

  const { selectedIds } = selectFilterDivisions(state);

  return _orderBy(selectedSeasons, ["title"], ["asc"]).map(({ id, title }) => ({
    seasonId: id,
    seasonTitle: title,
    divisions: _orderBy(selectFilterDivisions(state).data[id].divisions, ["title"], ["asc"]).map(({ id, title }) => ({
      id,
      title,
      isSelected: selectedIds.includes(id)
    }))
  }));
};

export const getDivisionListIsLoaded = state =>
  Object.values(selectFilterDivisions(state).data)
    .reduce((result, { isLoaded }) => [...result, isLoaded], [])
    .filter(isLoaded => isLoaded).length > 0;

export const getDivisionListIsLoading = state =>
  Object.values(selectFilterDivisions(state).data)
    .reduce((result, { isLoading }) => [...result, isLoading], [])
    .filter(isLoading => isLoading).length > 0;

export const getDivisionIsSelected = state => selectFilterDivisionsSelectedIds(state).length > 0;

export const getDivisionIdsNotSelected = state => selectFilterDivisionsNotSelectedIds(state);

export const getAllDivisionsSelected = state => selectFilterDivisionsNotSelectedIds(state).length === 0;

export const getTeamListIsLoadedForDivision = (state, seasonId) =>
  selectFilterTeams(state).data[seasonId].isLoaded === true;

export const getTeamGroupList = state => {
  const selectedDivisions = getDivisionGroupList(state)
    .reduce((result, { divisions }) => [...result, ...divisions], [])
    .filter(({ isSelected }) => isSelected);

  const { selectedIds } = selectFilterTeams(state);

  return _orderBy(selectedDivisions, ["title"], ["asc"]).map(({ id, title }) => ({
    divisionId: id,
    divisionTitle: title,
    teams: _orderBy(selectFilterTeams(state).data[id].teams, ["title"], ["asc"]).map(({ id, title }) => ({
      id,
      title,
      isSelected: selectedIds.includes(id)
    }))
  }));
};

export const getTeamListIsLoaded = state =>
  Object.values(selectFilterTeams(state).data)
    .reduce((result, { isLoaded }) => [...result, isLoaded], [])
    .filter(isLoaded => isLoaded).length > 0;

export const getTeamListIsLoading = state =>
  Object.values(selectFilterTeams(state).data)
    .reduce((result, { isLoading }) => [...result, isLoading], [])
    .filter(isLoading => isLoading).length > 0;

export const getTeamIsSelected = state => selectFilterTeamsSelectedIds(state).length > 0;

export const getTeamIdsNotSelected = state => selectFilterTeamsNotSelectedIds(state);

export const getAllTeamsSelected = state => selectFilterTeamsNotSelectedIds(state).length === 0;

export const getSelectedSeasonIds = state => selectFilterSeasonsSelectedIds(state);

export const getSelectedDivisionIds = state => selectFilterDivisionsSelectedIds(state);

export const getSelectedTeamIds = state => selectFilterTeamsSelectedIds(state);
