import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import ArchivedSeasonContent from "@/layouts/ArchivedSeasonContent";
import ProtectedLayout from "@/layouts/ProtectedLayout";
import Head from "@/components/Head";
import PageLoader from "@/components/PageLoader";
import Breadcrumbs, { BreadcrumbsProps } from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";
import TeamRosterExtender from "@/components/TeamRosterExtender";

class TeamRosterPlayersAdd extends PureComponent {
  static propTypes = {
    seasonId: PropTypes.string,
    teamId: PropTypes.string,
    isTeamLoading: PropTypes.bool.isRequired,
    isTeamLoaded: PropTypes.bool.isRequired,
    isTeamForbidden: PropTypes.bool.isRequired,
    isTeamArchived: PropTypes.bool.isRequired,
    teamTitle: PropTypes.string.isRequired,
    breadcrumbs: BreadcrumbsProps,
    loadTeam: PropTypes.func,
    loadPlayers: PropTypes.func,
    clear: PropTypes.func,
    loadingAction: PropTypes.func
  };

  static defaultProps = {
    teamTitle: null,
    seasonPlayers: [],
    loadTeam() {},
    loadPlayers() {},
    clear() {},
    loadingAction() {}
  };

  componentDidMount() {
    const { loadTeam, loadPlayers } = this.props;

    loadTeam();
    loadPlayers();
  }

  componentWillUnmount() {
    this.props.clear();
  }

  render() {
    const {
      seasonId,
      teamId,
      isTeamLoading,
      isTeamLoaded,
      isTeamForbidden,
      isTeamArchived,
      teamTitle,
      breadcrumbs,
      loadingAction
    } = this.props;

    return (
      <ArchivedSeasonContent condition={isTeamArchived}>
        <ProtectedLayout
          className="add-player-page"
          subject={{ type: "roster", teamId }}
          action="addPlayer"
          skip={!isTeamLoaded && !isTeamForbidden}
        >
          <Head title={`Add Player`} />

          <PageLoader isLoading={isTeamLoading} isLoaded={isTeamLoaded}>
            <Breadcrumbs items={breadcrumbs} />

            {isTeamLoaded && <TitleBar title={`Add Player to ${teamTitle}`} />}

            <TeamRosterExtender
              seasonId={seasonId}
              teamId={teamId}
              searchPlaceholder="Search players…"
              emptyMessage="There are no players"
              endMessage="You’ve reached the end of the players list."
              loadingAction={loadingAction}
              countPlural="players"
              countSingular="player"
            />
          </PageLoader>
        </ProtectedLayout>
      </ArchivedSeasonContent>
    );
  }
}

export default TeamRosterPlayersAdd;
