import React from "react";
import PropTypes from "prop-types";

import { CustomInput } from "reactstrap";

import { useDashboardFormNamespaceContext } from "@/components/DashboardForm";

function PointSystemField({ title, label, description, checked, onChange }) {
  const namespace = useDashboardFormNamespaceContext();

  const id = `${namespace}__point-system__option-${title}`;

  return (
    <CustomInput
      className="point-system__option"
      type="radio"
      id={id}
      name={title}
      label={label}
      onChange={onChange}
      checked={checked}
    >
      <span className="point-system__option__description">{description}</span>
    </CustomInput>
  );
}

PointSystemField.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default PointSystemField;
