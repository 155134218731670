import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import GamesheetNote from "@/components/GamesheetNote";
import Referee from "./Referee";

class PenaltyNote extends PureComponent {
  static propTypes = {
    note: PropTypes.shape({}),
    referee: PropTypes.shape({})
  };

  static defaultProps = {
    note: {},
    referee: {}
  };

  render() {
    const { note, referee } = this.props;

    return (
      <tr>
        <td colSpan="6">&nbsp;</td>
        <td>
          <GamesheetNote {...note} />
        </td>
        <td>
          <Referee {...referee} />
        </td>
      </tr>
    );
  }
}

export default PenaltyNote;
