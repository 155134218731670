import { handleActions, combineActions } from "redux-actions";

import { recordParsingRoutine, parsingRoutine } from "../routines";
import actions from "../actions";

const initialState = [];

function reduceParsingFailure(state, { payload }) {
  const { record, lineNumber, validationErrors } = payload;

  return [...state, { ...record, lineNumber, validationErrors }];
}

export default handleActions(
  {
    [recordParsingRoutine.FAILURE]: reduceParsingFailure,
    [combineActions(actions.clear, parsingRoutine.TRIGGER)]: () => [...initialState]
  },
  []
);
