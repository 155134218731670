import { handleActions } from "redux-actions";
import camelCase from "lodash/camelCase";

import { account } from "../actions";

import {
  acceptInvitationValidationRoutine,
  acceptInvitationRoutine,
  loadUserRoleTeamsRoutine,
  loadMergedTeamPreviewRoutine,
} from "../routines";

export const screens = {
  ENTER_CODE: "enter-code",
  VERIFY_TEAM_INVITATION: "verify-team-invitation",
  HOW_TO_ADD: "how-to-add",
  MERGE_CONFIRMATION: "merge-confirmation",
};

const defaultState = {
  fields: {
    code: {
      value: "",
      error: null,
    },
    merge: {
      value: false,
    },
    prototeamId: {
      value: "",
    },
  },
  codeInfo: null,
  showScreen: screens.ENTER_CODE,
  userTeams: {
    isLoading: false,
    isLoaded: false,
    rawTeams: [],
    teams: [],
  },
  mergedTeam: null,
  mergedTeamIsLoading: false,
  codeIsLoading: false,
  acceptIsLoading: false,
  error: null,
};

const acceptInvitationFieldChangeActionHandler = (state, { payload }) => {
  const [[field, value]] = Object.entries(payload);

  const fields = Object.assign({}, state.fields, {
    [field]: {
      value,
      error: null,
    }
  });

  return Object.assign({}, state, { fields });
};

const acceptInvitationValidationRoutineFailureActionHandler = (state, { payload }) => {
  let { errors } = payload;
  let { fields } = state;

  for (let error of errors) {
    const [[field, title]] = Object.entries(error);
    const { value } = fields[field];
    fields = Object.assign({}, fields, { [field]: { value, error: title } });
  }

  return Object.assign({}, state, { fields });
};

const acceptInvitationRoutineTriggerActionHandler = state => {
  let { fields } = state;

  for (let [field, { value }] of Object.entries(fields)) {
    fields = Object.assign({}, fields, { [field]: { value, error: null } });
  }

  return Object.assign({}, state, { fields });
};

const setInvitationScreenHandler = (state, { payload }) => {
  // we only want to merge if the user has made it to the HOW_TO_ADD screen
  const mergeValue = (() => {
    switch (payload) {
      case screens.ENTER_CODE:
        return false;
      case screens.HOW_TO_ADD:
        return true;
      default: state.fields.merge.value;
    }
  })();

  return {
    ...state,
    fields: {
      ...state.fields,
      prototeamId: {
        value: "",
      },
      merge: {
        value: mergeValue,
      }
    },
    showScreen: payload,
  }
};

const invitationCodeInfoHandler = (state, { payload }) => {
  let codeInfo = null;
  let teams = [];

  if (!!payload.codeInfo) {
    codeInfo = {
      invitation: payload.codeInfo.invitation,
      team: payload.codeInfo.teams[0] || {},
    };

    teams = "season" in codeInfo.team && filterPrototeamOptions(state.userTeams.rawTeams, codeInfo.team.season.id) || [];
  }

  return {
    ...state,
    codeInfo,
    userTeams: {
      ...state.userTeams,
      teams,
    }
  };
};

const loadUserRoleTeamsRoutineRequestActionHandler = state => ({
  ...state,
  userTeams: {
    ...state.userTeams,
    isLoading: true,
  },
});

const loadUserRoleTeamsRoutineSuccessActionHandler = (state, { payload }) => {
  const inviteSeasonId = state.codeInfo.team.season.id;
  const rawTeams = payload && payload.teams || [];
  const teams = filterPrototeamOptions(rawTeams, inviteSeasonId);

  return {
    ...state,
    userTeams: {
      ...state.userTeams,
      isLoaded: true,
      rawTeams,
      teams,
    },
  }
};

const filterPrototeamOptions = (seasonTeams, inviteSeasonId) => {
  // take list of seasonteams
  // filter out any not in a prototeam
  // group by prototeam
  // remove prototeams with at least one seasonteam in the same season as the invite
  // sort the seasonteams in each prototeam by createdAt, take first
  // sort alphabetically

  const prototeams = seasonTeams
    .filter(team => !!team.prototeam.id)
    .reduce((acc, team) => {
      if (team.prototeam.id in acc) {
        acc[team.prototeam.id].push(team);
      } else {
        acc[team.prototeam.id] = [team];
      }
      return acc;
    }, {});

  const teams = Object.values(prototeams)
    .filter(teams => !teams.some(team => team.season.id == inviteSeasonId))
    .map(teams => teams.sort((a, b) => a.createdAt - b.createdAt)[0])
    .sort((a, b) => a.title.localeCompare(b.title));

  return teams;
};

const loadUserRoleTeamsRoutineFulfillActionHandler = state => ({
  ...state,
  userTeams: {
    ...state.userTeams,
    isLoading: false,
  },
});

const acceptInvitationRoutineFailureActionHandler = (state, { payload }) => {
  let { error = null, errors = [] } = payload;

  let { fields } = state;

  for (let {
    title,
    source: { pointer }
  } of errors) {
    const field = camelCase(pointer.match(/\/data\/attributes\/(\w+)/)[1]);
    const { value } = state.fields[field];

    fields = Object.assign({}, fields, { [field]: { value, error: title } });
  }

  return Object.assign({}, state, { error, fields });
};

const acceptInvitationClearActionHandler = () => ({ ...defaultState });

const acceptInvitationValidationRoutineRequestActionHandler = state => Object.assign({}, state, { codeIsLoading: true });

const acceptInvitationValidationRoutineFulfillActionHandler = state => Object.assign({}, state, { codeIsLoading: false });

const acceptInvitationRoutineRequestActionHandler = state => Object.assign({}, state, { acceptIsLoading: true });

const acceptInvitationRoutineFulfillActionHandler = state => Object.assign({}, state, { acceptIsLoading: false });

const loadMergedTeamPreviewRoutineSuccessActionHandler = (state, { payload: { mergedTeam } }) => {
  return {
    ...state,
    mergedTeam,
    showScreen: screens.MERGE_CONFIRMATION,
  }
}

export default handleActions(
  {
    [account.invitation.fieldChange]: acceptInvitationFieldChangeActionHandler,
    [acceptInvitationValidationRoutine.REQUEST]: acceptInvitationValidationRoutineRequestActionHandler,
    [acceptInvitationValidationRoutine.FAILURE]: acceptInvitationValidationRoutineFailureActionHandler,
    [acceptInvitationValidationRoutine.FULFILL]: acceptInvitationValidationRoutineFulfillActionHandler,
    [loadUserRoleTeamsRoutine.REQUEST]: loadUserRoleTeamsRoutineRequestActionHandler,
    [loadUserRoleTeamsRoutine.SUCCESS]: loadUserRoleTeamsRoutineSuccessActionHandler,
    [loadUserRoleTeamsRoutine.FULFILL]: loadUserRoleTeamsRoutineFulfillActionHandler,
    [acceptInvitationRoutine.TRIGGER]: acceptInvitationRoutineTriggerActionHandler,
    [acceptInvitationRoutine.REQUEST]: acceptInvitationRoutineRequestActionHandler,
    [acceptInvitationRoutine.FAILURE]: acceptInvitationRoutineFailureActionHandler,
    [acceptInvitationRoutine.FULFILL]: acceptInvitationRoutineFulfillActionHandler,
    [loadMergedTeamPreviewRoutine.REQUEST]: state => ({ ...state, mergedTeamIsLoading: true }),
    [loadMergedTeamPreviewRoutine.SUCCESS]: loadMergedTeamPreviewRoutineSuccessActionHandler,
    [loadMergedTeamPreviewRoutine.FULFILL]: state => ({ ...state, mergedTeamIsLoading: false }),
    [account.invitation.showScreen]: setInvitationScreenHandler,
    [account.invitation.setCodeInfo]: invitationCodeInfoHandler,
    [account.invitation.clear]: acceptInvitationClearActionHandler
  },
  defaultState
);
