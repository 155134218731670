import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";
import ProtectedLayout from "@/layouts/ProtectedLayout";
import PageLoader from "@/components/PageLoader";
import CoachTeamsList from "@/components/CoachTeamsList";
import AbilityButton from "@/components/AbilityButton";

import useCurrentCoach from "../hooks/useCurrentCoach";

function CoachPage({ seasonId, match: { params } }) {
  const { coachId } = params;

  const { seasonIsLoaded, seasonIsLoading, seasonBreadcrumbs } = useCurrentSeasonContext();

  const { isCoachOperating, isCoachReady, isCoachForbidden, coach, teams } = useCurrentCoach({ seasonId, coachId });

  return (
    <ProtectedLayout condition={!isCoachForbidden}>
      {seasonIsLoaded && isCoachReady ? (
        <Fragment>
          <Head title={`${coach.firstName} ${coach.lastName}`} />

          <Breadcrumbs
            items={[...seasonBreadcrumbs, { title: "Coaches", url: `/seasons/${seasonId}/roster/coaches` }]}
          />

          <TitleBar title={`${coach.firstName} ${coach.lastName}`} subtitle={`ID: ${coach.externalId}`}>
            <AbilityButton
              subject={{ type: "penaltyReports", coachId: coach.id }}
              action="read"
              outline
              color="primary"
              size="sm"
              tag={Link}
              to={`/seasons/${seasonId}/roster/coaches/${coach.id}/penalty-report`}
            >
              Penalty Report
            </AbilityButton>

            <AbilityButton
              subject={{ type: "coaches", id: coach.id }}
              action="update"
              outline
              color="primary"
              size="sm"
              tag={Link}
              to={`/seasons/${coach.season.id}/roster/coaches/${coach.id}/edit`}
            >
              Edit Coach
            </AbilityButton>
          </TitleBar>

          <CoachTeamsList
            teams={teams}
            seasonId={seasonId}
            coachId={coachId}
            isLoading={isCoachOperating}
            isLoaded={isCoachReady}
          />
        </Fragment>
      ) : (
        <PageLoader isLoading={seasonIsLoading || isCoachOperating} />
      )}
    </ProtectedLayout>
  );
}

CoachPage.propTypes = {
  seasonId: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      coachId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default CoachPage;
