import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import AbilityLink from "@/components/AbilityLink";
import AbilityButton from "@/components/AbilityButton";

class CoachTeam extends PureComponent {
  static propTypes = {
    coachId: PropTypes.string.isRequired,
    seasonId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    position: PropTypes.string
  };

  static defaultProps = {
    position: "",
    canEdit: false,
    canRead: false
  };

  render() {
    const { seasonId, id, title, position, coachId } = this.props;

    return (
      <tr>
        <td>
          <AbilityLink
            subject={{ type: "teams", id }}
            action="read"
            to={`/seasons/${seasonId}/teams/${id}/roster/coaches`}
            fallback={title}
          >
            {title}
          </AbilityLink>
        </td>
        <td>{position}</td>
        <td>
          <AbilityButton
            subject={{ type: "teams", id }}
            action="update"
            outline
            color="primary"
            size="sm"
            tag={Link}
            to={`/seasons/${seasonId}/teams/${id}/roster/coaches/${coachId}/edit`}
          >
            Edit
          </AbilityButton>
        </td>
      </tr>
    );
  }
}

export default CoachTeam;
