import { useSelector } from "react-redux";

import { getCopyTeamsSelectedList } from "@/redux/copyTeams/selectors";

function useSelectedTeamList() {
  const teams = useSelector(getCopyTeamsSelectedList);

  return {
    teams
  };
}

export default useSelectedTeamList;
