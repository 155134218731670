import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getSelectedDivisions,
  getTeamList,
  getTeamListIsLoaded,
  getTeamListIsLoading,
  getRoleIsGlobal,
  getSelectedTeams // New selector to get selected teams
} from "@/redux/roleForm/selectors";

import actions from "@/redux/roleForm/actions";

export default function useTeamSelect() {
  const selectedDivisions = useSelector(getSelectedDivisions);
  const options = useSelector(state => getTeamList(state, selectedDivisions));
  const selectedTeams = useSelector(getSelectedTeams); // Fetch selected teams
  const roleIsGlobal = useSelector(getRoleIsGlobal);
  const isLoaded = useSelector(state => getTeamListIsLoaded(state, selectedDivisions));
  const isLoading = useSelector(state => getTeamListIsLoading(state, selectedDivisions));
  const isOptionSelected = selectedTeams.length > 0; // Check if any option is selected
  const totalItemsCount = options.reduce((count, group) => count + group.items.length, 0); // Flatten options to get a count of all items across groups
  const isAllOptionsSelected = totalItemsCount > 0 && selectedTeams.length === totalItemsCount;
  const dispatch = useDispatch();

  const toggleSelected = useCallback(
    ({ id, ...rest }) => {
      const isSelected = selectedTeams.includes(id); // Check if team is selected
      const action = isSelected ? actions.teamList.deselect : actions.teamList.select;

      dispatch(action({ id, ...rest }));
    },
    [dispatch, selectedTeams] // Include selectedTeams in dependencies
  );

  return {
    options,
    isLoaded,
    isLoading,
    isEnabled: !roleIsGlobal,
    toggleSelected,
    selectedTeams, // Return selected teams for UI rendering if needed
    isOptionSelected, // Pass this value to indicate if any items are selected
    isAllOptionsSelected
  };
}
