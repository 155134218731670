import React, { useState, useCallback, useRef } from "react";
import PropTypes from "prop-types";

import { Button, ButtonGroup } from "reactstrap";
import ReactAvatarEditor from "react-avatar-editor";

function Editor({ file, onChange }) {
  const editor = useRef();
  const [scale, setScale] = useState(1.0);
  const fileName = file.name;
  const fileType = file.type;
  const onScaleChange = useCallback(({ target }) => setScale(parseFloat(target.value)), [setScale]);

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const onPositionChange = useCallback(position => setPosition(position), [setPosition]);

  const onImageChange = useCallback(
    () => {
      const canvas = editor.current.getImage();
      canvas.fileName = fileName;
      canvas.fileType = fileType;

      onChange(canvas);
    },
    [editor, fileName, fileType, onChange]
  );

  return (
    <div className="player-photo-field__editor">
      <ReactAvatarEditor
        ref={editor}
        scale={scale}
        width={300}
        height={300}
        position={position}
        onPositionChange={onPositionChange}
        rotate={0}
        border={0}
        borderRadius={0}
        image={file}
        onImageChange={onImageChange}
        onImageReady={onImageChange}
      />
      <ButtonGroup size="sm" className="player-photo-field__editor__scale-buttons">
        <Button color="secondary" value={scale - 0.1} disabled={scale <= 1} onClick={onScaleChange}>
          -
        </Button>
        <Button color="secondary" value={scale + 0.1} disabled={scale >= 2} onClick={onScaleChange}>
          +
        </Button>
      </ButtonGroup>
    </div>
  );
}

Editor.propTypes = {
  file: PropTypes.shape({}),
  onChange: PropTypes.func
};

export default Editor;
