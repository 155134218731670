import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { getMeta } from "@/redux/divisionForm";

export default function useActionsDropdown({ seasonId, id }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpened = useCallback(() => setIsOpen(!isOpen), [setIsOpen, isOpen]);

  //const { isDeleting } = useSelector(getMeta);

  //  ======TRIGGERS======

  const triggerEdit = useCallback(() => {}, [seasonId, id]);

  return { isOpen, toggleIsOpened, triggerEdit };
}
