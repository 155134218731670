import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import actions from "./actions";

import { roles, fields, meta } from "./reducers";
import { loadingRoutine } from "./routines";

const initRoles = handleActions(
  {
    [loadingRoutine.SUCCESS]: (
      state,
      {
        payload: {
          user: { roles }
        }
      }
    ) => [...(roles || [])],
    [actions.reset]: () => []
  },
  []
);

export default combineReducers({ roles, fields, initRoles, meta });
