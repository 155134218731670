import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import { getIsLoaded, getIsLoading, getDivisions, loadingRoutine } from "@/redux/divisionDropdown";

export default function useDivisionsList() {
  const dispatch = useDispatch();
  const { seasonId } = useCurrentSeasonContext();

  const isListLoaded = useSelector(getIsLoaded);
  const isListLoading = useSelector(getIsLoading);
  const divisions = useSelector(getDivisions);
  const ids = divisions.map(({ id }) => id);

  useEffect(
    () => {
      if (seasonId && !isListLoaded && !isListLoading) {
        dispatch(loadingRoutine({ seasonId }));
      }
    },
    [dispatch, seasonId, isListLoaded, isListLoading]
  );

  return {
    isListLoaded,
    isListLoading,
    divisions,
    ids
  };
}
