import { useSelector } from "react-redux";

import { getNumRecords, getImportedSuccess, getImportedFailure, CHUNK_SIZE } from "@/redux/scheduledGamesCSVImportWizard";

export default function useScheduledGamesCSVImportWizardProgress() {
  const total = useSelector(getNumRecords);
  const success = useSelector(getImportedSuccess);
  const fail = useSelector(getImportedFailure);

  return {
    total,
    success,
    fail,
    chunkSize: CHUNK_SIZE,
  };
}
