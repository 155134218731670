import actions from "../actions";

import {
  playerFieldValidatingRoutine,
  playerSubmittingRoutine,
  playerValidatingRoutine,
  playerSavingRoutine,
  playerRemovingRoutine
} from "../routines";

import createMembersReducer from "./createMembersReducer";

export default createMembersReducer({
  autoFocus: "number",
  fields: {
    firstName: "",
    lastName: "",
    externalId: "",
    number: "",
    duty: "",
    position: "",
    affiliated: false,
    status: "playing"
  },
  extraMemberAttrs: [
    "birthdate",
    "photoUrl",
    "weight",
    "height",
    "shotHand",
    "country",
    "province",
    "hometown",
    "draftedBy",
    "committedTo",
    "biography"
  ],
  actions: {
    appendMember: actions.appendEmptyPlayer,
    changeField: actions.playersForm.changeField,
    reset: actions.playersForm.reset,
    edit: actions.playersForm.edit
  },
  routines: {
    fieldValidating: playerFieldValidatingRoutine,
    validating: playerValidatingRoutine,
    submitting: playerSubmittingRoutine,
    saving: playerSavingRoutine,
    removing: playerRemovingRoutine
  },
  extract(team) {
    const players = team.players.reduce((result, player) => ({ ...result, [player.id]: { ...player } }), {});

    const roster = team.roster.players || [];

    return [players, roster];
  }
});
