import React, { Fragment } from "react";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import Breadcrumbs from "@/components/Breadcrumbs";
import Head from "@/components/Head";
import PageLoader from "@/components/PageLoader";

import RefereeImportWizard from "@/components-16.8/RefereeImportWizard";

function ImportReferees() {
  const { seasonIsLoading, seasonIsLoaded, seasonIsForbidden, seasonBreadcrumbs, seasonId } = useCurrentSeasonContext();

  return (
    <ProtectedLayout condition={!seasonIsForbidden}>
      {seasonIsLoaded ? (
        <Fragment>
          <Head title="Import Referees" />
          <Breadcrumbs
            items={
              seasonBreadcrumbs && [
                ...seasonBreadcrumbs,
                {
                  title: "Referees",
                  url: `/seasons/${seasonId}/referees`
                }
              ]
            }
          />

          <RefereeImportWizard seasonId={seasonId} />
        </Fragment>
      ) : (
        <PageLoader isLoading={seasonIsLoading} />
      )}
    </ProtectedLayout>
  );
}

export default ImportReferees;
