import React from "react";
import PropTypes from "prop-types";

import { Table } from "reactstrap";

import EmptyListMessage from "@/components/EmptyListMessage";

import TeamMemberPenaltyReportGameLink from "./GameLink";

function InfractionHistory({ history }) {
  history.sort((a, b) => b.game.start_time - a.game.start_time);
  return (
    <div className="team-member-penalty-report__section">
      <h3 className="team-member-penalty-report__section-title">Infraction History</h3>

      {history.length > 0 ? (
        <Table striped borderless>
          <thead>
            <tr>
              <th className="team-member-penalty-report__infraction-history-table__game">Game</th>
              <th className="team-member-penalty-report__infraction-history-table__team">Team</th>
              <th className="team-member-penalty-report__infraction-history-table__date">Date</th>
              <th className="team-member-penalty-report__infraction-history-table__penalty-minutes">PIM</th>
              <th className="team-member-penalty-report__infraction-history-table__code">Code</th>
            </tr>
          </thead>
          <tbody>
            {history.map(({ id, game, team, length, code }) => (
              <tr key={id}>
                <td>
                  <TeamMemberPenaltyReportGameLink {...game} />
                </td>
                <td>{team}</td>
                <td>{game.date}</td>
                <td>{length}</td>
                <td>{code}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyListMessage>No infractions.</EmptyListMessage>
      )}
    </div>
  );
}

InfractionHistory.propTypes = {
  history: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
      game: PropTypes.shape({ date: PropTypes.string.isRequired }),
      team: PropTypes.string.isRequired,
      length: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired
    })
  )
};

InfractionHistory.defaultProps = {
  history: []
};

export default InfractionHistory;
