import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import { Row, Col, Alert, Button } from "reactstrap";

import Layout from "@/layouts/Layout";
import Head from "@/components/Head";
import TitleBar from "@/components/TitleBar";

import ForgotPasswordForm from "@/components/ForgotPasswordForm";

import "./Reset.scss";

class Reset extends PureComponent {
  static propTypes = {
    email: PropTypes.string,
    error: PropTypes.string,
    isWorking: PropTypes.bool,
    isDirty: PropTypes.bool,
    isCompleted: PropTypes.bool,
    setEmail: PropTypes.func,
    resetPassword: PropTypes.func,
    goBack: PropTypes.func,
    clear: PropTypes.func
  };

  static defaultProps = {
    email: "",
    error: null,
    isWorking: false,
    isCompleted: false,
    isDirty: false,
    setEmail() {},
    resetPassword() {},
    goBack() {},
    clear() {}
  };

  componentWillUnmount() {
    this.props.clear();
  }

  render() {
    const { email, error, isWorking, isCompleted, isDirty, goBack, resetPassword, setEmail } = this.props;

    return (
      <Layout className="password-reset-page">
        <Head title="Password Reset" />

        <Row className="justify-content-center">
          <Col md="10" lg="6">
            <TitleBar title="Password Reset" />

            {isCompleted === false ? (
              <Fragment>
                <ForgotPasswordForm
                  email={email}
                  isDirty={isDirty}
                  error={error}
                  isWorking={isWorking}
                  setEmail={setEmail}
                  onSubmit={resetPassword}
                  goBack={goBack}
                />
              </Fragment>
            ) : (
              <Fragment>
                <Alert color="success">
                  <p className="text-left">A link to reset your password has been sent to {email}. Please check your email and follow the instructions to reset your password.</p>
                  <p className="text-left mb-0">If you do not receive the email within a few minutes, please check your spam or junk folder.</p>
                </Alert>
              </Fragment>
            )}
          </Col>
        </Row>
      </Layout>
    );
  }
}

export default Reset;
