import React, { useState } from "react";

import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";
import PageLoader from "@/components/PageLoader";
import SeasonForm, { SeasonSport, useSeasonFormClearing } from "@/components/SeasonForm";
import ProtectedLayout from "@/layouts/ProtectedLayout";
import useCurrentLeagueContext from "@/hooks/useCurrentLeagueContext";
import { useSportOptions } from "@/components/SeasonForm/hooks/useSportOptions";

function NewSeason() {
  const {
    leagueId,
    leagueAssociationId,
    leagueIsLoaded,
    leagueIsLoading,
    leagueIsForbidden,
    leagueBreadcrumbs
  } = useCurrentLeagueContext();

  const sportOptions = useSportOptions();
  const [page, setPage] = useState("sport"); // "sport" or "settings"
  const [sport, setSport] = useState("");
  const sportTitle = sport ? sportOptions.find(opt => opt.key == sport).title : "";

  useSeasonFormClearing();

  if (page == "sport") {
    return (
      <ProtectedLayout
        subject={{ type: "seasons", leagueId }}
        action="create"
        skip={!leagueIsLoaded && !leagueIsForbidden}
        className="seasons-page"
      >
        <Head title="Select a Sport" />
  
        <PageLoader isLoading={leagueIsLoading || sportOptions == []} isLoaded={leagueIsLoaded}>
          <Breadcrumbs items={leagueBreadcrumbs} />
  
          <TitleBar title="Select a Sport" />
  
          <SeasonSport sport={sport} setSport={setSport} sportOptions={sportOptions} goToSettings={() => setPage("settings")} leagueId={leagueId} />
        </PageLoader>
      </ProtectedLayout>
    );
  } else if (page == "settings") {
    return (
      <ProtectedLayout
        subject={{ type: "seasons", leagueId }}
        action="create"
        skip={!leagueIsLoaded && !leagueIsForbidden}
        className="seasons-page"
      >
        <Head title="New Season" />
  
        <PageLoader isLoading={leagueIsLoading} isLoaded={leagueIsLoaded}>
          <Breadcrumbs items={leagueBreadcrumbs} />
  
          <TitleBar title={`New ${sportTitle} Season`} />
  
          <SeasonForm associationId={leagueAssociationId} leagueId={leagueId} goToSports={() => setPage("sport")} sport={sport} />
        </PageLoader>
      </ProtectedLayout>
    );
  }
}

export default NewSeason;
