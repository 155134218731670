import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import useForm from "@/hooks/useForm";

import { getAccountEmail } from "@/redux/account";
import { submittingRoutine } from "@/redux/exportSeasonGames";

import useExportSeasonGamesMeta from "./useExportSeasonGamesMeta";
import { FORM_CONSTRAINTS } from "../constants";

export default function useExportSeasonGamesForm({ seasonId }) {
  const dispatch = useDispatch();
  const email = useSelector(getAccountEmail);
  const { isSubmitting } = useExportSeasonGamesMeta();

  const { values, ...form } = useForm({ email }, FORM_CONSTRAINTS);

  const submit = useCallback(
    () => {
      if (window.dataLayer) {
        window.dataLayer.push({ event: "export-season" });
      }

      dispatch(submittingRoutine({ values, seasonId }));
    },
    [dispatch, values, seasonId]
  );

  return { ...form, values, isSubmitting, submit };
}
