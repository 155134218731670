import React from "react";
import PropTypes from "prop-types";

const GameFlagsList = ({ messages }) => {
  return (
    <ul>
      {messages.map((message, i) => {
        return (
          <li key={message + i}>{message}</li>
        );
      })}
    </ul>
  );
};

GameFlagsList.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]).isRequired
  ).isRequired
};

export default GameFlagsList;
