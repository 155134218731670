import { createActions } from "redux-actions";

export const { seasonRoster: seasonRosterActions } = createActions({
  SEASON_ROSTER: {
    CLEAR: undefined,
    PLAYERS: {
      SEARCH: {
        SET_NEXT_QUERY: undefined,
        COMMIT: undefined,
        CLEAR: undefined
      },
      MERGE_TOOL: {
        ENABLE: undefined,
        DISABLE: undefined,
        SELECT_PLAYER: undefined,
        DESELECT_PLAYER: undefined,
        CHOOSE_WINNER: undefined,
        SET_STATUS: undefined
      }
    },
    COACHES: {
      SEARCH: {
        SET_NEXT_QUERY: undefined,
        COMMIT: undefined,
        CLEAR: undefined
      },
      MERGE_TOOL: {
        ENABLE: undefined,
        DISABLE: undefined,
        SELECT_COACH: undefined,
        DESELECT_COACH: undefined,
        CHOOSE_WINNER: undefined,
        SET_STATUS: undefined
      }
    }
  }
});
