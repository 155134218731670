import { handleActions, combineActions } from "redux-actions";

import { refereesActions } from "../../actions";

import { refereeFormSubmittingRoutine, refereeFormLoadingRoutine, refereeFormDeletingRoutine } from "../../routines";

const initialState = {
  isOperating: false,
  isLoading: false,
  isLoaded: false,
  isForbidden: false,
  isDeleting: false,
  refereeName: null
};

function reduceOperationRequest(state) {
  return { ...state, isOperating: true };
}

function reduceOperationFulfill(state) {
  return { ...state, isOperating: false };
}

function reduceLoadingRequest(state) {
  return { ...state, isLoading: true };
}

function reduceLoadingSuccess(state, { payload: { referee } }) {
  const { firstName, lastName } = referee;

  return { ...state, isLoaded: true, refereeName: `${firstName} ${lastName}` };
}

function reduceLoadingFailure(state, { payload: { responseStatus } }) {
  return { ...state, isLoaded: false, isForbidden: responseStatus === 401 };
}

function reduceLoadingFulfill(state) {
  return { ...state, isLoading: false };
}

function reduceDeletingRequest(state) {
  return { ...state, isDeleting: true };
}

function reduceDeletingFulfill(state) {
  return { ...state, isDeleting: false };
}

export default handleActions(
  {
    [combineActions(refereeFormSubmittingRoutine.REQUEST, refereeFormDeletingRoutine.REQUEST)]: reduceOperationRequest,
    [combineActions(refereeFormSubmittingRoutine.FULFILL, refereeFormDeletingRoutine.FULFILL)]: reduceOperationFulfill,
    [refereeFormLoadingRoutine.REQUEST]: reduceLoadingRequest,
    [refereeFormLoadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [refereeFormLoadingRoutine.FAILURE]: reduceLoadingFailure,
    [refereeFormLoadingRoutine.FULFILL]: reduceLoadingFulfill,
    [refereeFormDeletingRoutine.REQUEST]: reduceDeletingRequest,
    [refereeFormDeletingRoutine.FULFILL]: reduceDeletingFulfill,
    [refereesActions.form.clear]: () => ({ ...initialState })
  },
  initialState
);
