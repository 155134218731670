import React from "react";

function InvalidCodeMessage() {
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h1 className="display-5 text-danger mb-3">Invalid verification code</h1>

          <p>
            We’re sorry, but your verification code is invalid. In order to verify an account you need a valid
            verification code.
          </p>

          <p>
            For assistance please contact <a href="mailto:support@gamesheetinc.com">support@gamesheetinc.com</a>.
          </p>
        </div>
      </div>
    </div>
  );
}

export default InvalidCodeMessage;
