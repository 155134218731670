import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { FormGroup, Label, Input, Button } from "reactstrap";

import ContentSection from "@/components/ContentSection";
import DashboardForm from "@/components/DashboardForm";
import DashboardFormFooter from "@/components/DashboardFormFooter";
import FormItem from "@/components/FormItem";

class TeamPlayerForm extends React.PureComponent {
  static propTypes = {
    isOperating: PropTypes.bool,
    playerId: PropTypes.string.isRequired,
    playersRoster: PropTypes.arrayOf(PropTypes.shape({})),
    jersey: PropTypes.string,
    duty: PropTypes.string,
    position: PropTypes.string,
    affiliated: PropTypes.bool,
    onSubmit: PropTypes.func,
    onDelete: PropTypes.func,
    rosterLocked: PropTypes.bool,
    playerPositions: PropTypes.arrayOf(PropTypes.shape({})).isRequired
  };

  static defaultProps = {
    isOperating: false,
    jersey: "",
    duty: "",
    position: "",
    affiliated: false,
    playersRoster: [],
    playerPositions: [],
    onSubmit() {},
    onDelete() {},
    rosterLocked: false
  };

  state = {
    jersey: this.props.jersey,
    duty: this.props.duty,
    position: this.props.position,
    affiliated: this.props.affiliated
  };

  isPlayerInRoster = (this.props.playersRoster || []).findIndex(item => item.id === this.props.playerId) >= 0;

  handleInputChange = ({ target }) => {
    const value = target.name === "affiliated" ? target.value === "true" : target.value;

    this.setState({ [target.name]: value });
  };

  handleSubmit = () => {
    const { playersRoster, onSubmit, playerId: id } = this.props;
    const { jersey: number, duty, position, affiliated } = this.state;

    // @TODO: allow to change status
    const rosterPlayer = {
      id,
      number,
      duty,
      position,
      affiliated,
      status: "playing"
    };

    let nextPlayersRoster = [];

    if (this.isPlayerInRoster) {
      nextPlayersRoster = playersRoster.map(item => (item.id === id ? rosterPlayer : item));
    } else {
      nextPlayersRoster = [...playersRoster, ...[rosterPlayer]];
    }

    onSubmit(nextPlayersRoster);
  };

  handleDelete = () => {
    const { playersRoster, playerId, onDelete } = this.props;

    const nextPlayersRoster = playersRoster.filter(item => item.id !== playerId);

    onDelete(nextPlayersRoster);
  };

  render() {
    const { isOperating, rosterLocked, playerPositions } = this.props;
    const { jersey, duty, position, affiliated } = this.state;

    return (
      <DashboardForm className="player-form" isOperating={isOperating}>
        <ContentSection>
          <FormItem>
            <FormGroup className="col-md-3">
              <Label for="teamPlayerJersey">Jersey</Label>
              <Input id="teamPlayerJersey" name="jersey" value={jersey} onChange={this.handleInputChange} />
            </FormGroup>

            <FormGroup className="col-md-3">
              <Label for="teamPlayerDuty">Designation</Label>
              <Input
                className="custom-select"
                type="select"
                id="teamPlayerDuty"
                name="duty"
                value={duty}
                onChange={this.handleInputChange}
              >
                <option value="">None</option>
                <option value="captain">Captain</option>
                <option value="alternate_captain">Alternate Captain</option>
              </Input>
            </FormGroup>

            <FormGroup className="col-md-3">
              <Label for="teamPlayerPosition">Position</Label>
              <Input
                className="custom-select"
                type="select"
                id="teamPlayerPosition"
                name="position"
                value={position}
                onChange={this.handleInputChange}
              >
                <option value="">None</option>
                {playerPositions.map(position => (
                  <option key={position.key} value={position.key}>
                    {position.value}
                  </option>
                ))}
              </Input>
            </FormGroup>

            <FormGroup className="col-md-3">
              <Label for="teamPlayerStatus">Status</Label>
              <Input
                className="custom-select"
                type="select"
                id="teamPlayerStatus"
                name="affiliated"
                value={affiliated}
                onChange={this.handleInputChange}
              >
                <option value={false}>Regular</option>
                <option value={true}>Affiliated</option>
              </Input>
            </FormGroup>
          </FormItem>
        </ContentSection>

        <DashboardFormFooter>
          {this.isPlayerInRoster ? (
            <Fragment>
              {!rosterLocked && (
                <div className="dashboard-form-footer__secondary">
                  <Button type="button" color="danger" size="sm" outline onClick={this.handleDelete}>
                    Remove Player from Team
                  </Button>
                </div>
              )}

              <div className="dashboard-form-footer__primary">
                <Button type="button" color="success" onClick={this.handleSubmit}>
                  Save Changes
                </Button>
              </div>
            </Fragment>
          ) : (
            <div className="dashboard-form-footer__primary">
              <Button type="button" color="success" onClick={this.handleSubmit}>
                Add Player
              </Button>
            </div>
          )}
        </DashboardFormFooter>
      </DashboardForm>
    );
  }
}

export default TeamPlayerForm;
