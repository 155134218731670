import React from "react";
import PropTypes from "prop-types";

import "./TotalCount.scss";

export default function TotalCount({ plural, singular, totalCount, filteredCount, children }) {
  const resource = totalCount === 1 ? singular : plural;

  if (filteredCount !== null && totalCount > filteredCount) {
    return (
      <div className="total-count">
        <span className="total-count__text">
          Displaying <strong className="total-count__number">{filteredCount}</strong> of{" "}
          <strong className="total-count__number">{totalCount}</strong> {resource}
        </span>
        {children}
      </div>
    );
  }

  return (
    <div className="total-count">
      <span className="total-count__text">
        Total {plural}: <strong className="total-count__number">{totalCount}</strong>
      </span>
      {children}
    </div>
  );
}

TotalCount.propTypes = {
  plural: PropTypes.string,
  singular: PropTypes.string,
  totalCount: PropTypes.number,
  filteredCount: PropTypes.number,
  children: PropTypes.node
};

TotalCount.defaultProps = {
  plural: "records",
  singular: "record",
  totalCount: 0,
  filteredCount: null,
  children: null
};
