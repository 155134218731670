import React from "react";
import PropTypes from "prop-types";

import { Input, Button } from "reactstrap";

function NewPenaltyCodeForm(props) {
  const { code, label, isActive, onSubmit, onCancel } = props;

  if (!isActive) {
    return null;
  }

  return (
    <tr>
      <td>
        <Input type="text" name="label" bsSize="sm" {...label} />
      </td>
      <td>
        <Input type="text" name="code" bsSize="sm" {...code} />
      </td>
      <td>
        <Button className="save-button" size="sm" color="success" onClick={onSubmit}>
          Save
        </Button>
        <Button className="cancel-button ml-1" size="sm" color="secondary" onClick={onCancel} outline>
          Cancel
        </Button>
      </td>
    </tr>
  );
}

NewPenaltyCodeForm.propTypes = {
  code: PropTypes.shape({}).isRequired,
  label: PropTypes.shape({}).isRequired,
  isActive: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default NewPenaltyCodeForm;
