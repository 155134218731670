import { createActions } from "redux-actions";

const { penaltyAccumulationReport } = createActions({
  PENALTY_ACCUMULATION_REPORT: {
    REPORT: {
      CLEAR: undefined
    }
  }
});

export default penaltyAccumulationReport;
