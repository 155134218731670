import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getGameFormTeamTitle,
  getGameFormTeamTotalPenaltyMinutes,
  getGameFormTeamFairPlayEarned
} from "@/redux/gameForm/selectors";

import actions from "@/redux/gameForm/actions";

export default function useFairPlayPoints({ team }) {
  const dispatch = useDispatch();

  const teamTitle = useSelector(state => getGameFormTeamTitle(state, team));

  const totalPenaltyMinutes = useSelector(state => getGameFormTeamTotalPenaltyMinutes(state, team));

  const fairPlayEarned = useSelector(state => getGameFormTeamFairPlayEarned(state, team));

  const changeFairPlayEarned = useCallback(
    ({ target: { value } }) => {
      let earned = "true" === value;
      dispatch(actions.changeFairPlayEarned({ team, earned }));
    },
    [dispatch, team]
  );

  return {
    teamTitle,
    totalPenaltyMinutes,
    fairPlayEarned,
    changeFairPlayEarned
  };
}
