import React from "react";
import PropTypes from "prop-types";

import { Input, Label, FormGroup } from "reactstrap";

import { useDashboardFormNamespaceContext } from "@/components/DashboardForm";

function PointsField({ label, name, value, onChange }) {
  const namespace = useDashboardFormNamespaceContext();
  const id = `${namespace}__points__${name}`;

  return (
    <FormGroup className="col-md-6">
      <Label for={id}>{label}</Label>
      <Input
        name={name}
        id={id}
        type="number"
        min={0}
        onChange={onChange}
        value={value > 0 ? value : ""}
        placeholder={value === 0 ? "0" : undefined}
      />
    </FormGroup>
  );
}

PointsField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

export default PointsField;
