import { atom } from "recoil";

export const isDeletingState = atom({
  key: 'isDeleting',
  default: false
});

export const selectAllGamesState = atom({
  key: "selectAllGames",
  default: false
});