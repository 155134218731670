import React from "react";

const isString = value => toString.call(value) === "[object String]";

const nl2br = (str = "") => {
  if (isString(str) && str.length > 0) {
    return str.split("\n").map((item, key) => {
      return (
        <React.Fragment key={key}>
          {item}
          <br />
        </React.Fragment>
      );
    });
  }

  return "";
};

export default nl2br;
