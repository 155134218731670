import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getMeta, submittingRoutine } from "@/redux/playerForm";

export default function usePlayerFormSubmitting({ values, playerId, seasonId }) {
  const dispatch = useDispatch();

  const { isSubmitting } = useSelector(getMeta);

  const submit = useCallback(
    () => {
      dispatch(submittingRoutine({ playerId, seasonId, values }));
    },
    [dispatch, values, playerId, seasonId]
  );

  return {
    isSubmitting,
    submit
  };
}
