import React from "react";

import ResourceSelect from "./ResourceSelect";

import useDivisionSelect from "./hooks/useDivisionSelect";

function DivisionSelect() {
  const { isLoaded, isLoading, options, isEnabled, toggleSelected, resourceType, isOptionSelected, isAllOptionsSelected } = useDivisionSelect();

  return (
    <ResourceSelect
      label="Select division(s)"
      isLoaded={isLoaded}
      isLoading={isLoading}
      options={options}
      isEnabled={isEnabled}
      toggleSelected={toggleSelected}
      isOptionSelected={isOptionSelected} 
      isAllOptionsSelected={isAllOptionsSelected}
      resourceType="divisionList"
    />
  );
}

export default DivisionSelect;
