/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/aria-role */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./Column.scss";

class Column extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    sorting: PropTypes.shape({
      column: PropTypes.string.isRequired,
      order: PropTypes.string.isRequired
    }).isRequired,
    toggleSorting: PropTypes.func
  };

  handleClick = () => {
    const { name, toggleSorting } = this.props;

    toggleSorting(name);
  };

  render() {
    const {
      sorting: { column, order },
      title,
      name
    } = this.props;

    const isActive = name === column;

    const className = classNames({
      [`suspension-report__suspensions-table__sortable-column__${order}`]: isActive,
      "suspension-report__suspensions-table__sortable-column__active": isActive,
      "suspension-report__suspensions-table__sortable-column": true
    });

    return (
      <th>
        <span className={className} onClick={this.handleClick}>
          {title}
        </span>
      </th>
    );
  }
}

export default Column;
