import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { FormGroup, Label, Button } from "reactstrap";

import FormItem from "@/components/FormItem";
import ContentSection from "@/components/ContentSection";
import Goal from "@/components/GameForm/components/Goal";

class ScoringFieldset extends PureComponent {
  static propTypes = {
    team: PropTypes.oneOf(["visitor", "home"]).isRequired,
    teamTitle: PropTypes.string.isRequired,
    goals: PropTypes.arrayOf(PropTypes.shape({})),
    changeGoal: PropTypes.func,
    addGoal: PropTypes.func,
    removeGoal: PropTypes.func
  };

  static defaultProps = {
    goals: [],
    changeGoal() {},
    addGoal() {},
    removeGoal() {}
  };

  addGoal = () => {
    const { team, addGoal } = this.props;

    addGoal(team);
  };

  render() {
    const { team, teamTitle, goals, changeGoal, removeGoal } = this.props;

    return (
      <ContentSection title={`${team.toUpperCase()} – ${teamTitle}`}>
        {goals.length > 0 && (
          <FormItem>
            <FormGroup className="col-md-1">
              <Label>Period</Label>
            </FormGroup>

            <FormGroup className="col-md-1">
              <Label>Time</Label>
            </FormGroup>

            <FormGroup className="col-md-2">
              <Label>Player</Label>
            </FormGroup>

            <FormGroup className="col-md-2">
              <Label>Assist one</Label>
            </FormGroup>

            <FormGroup className="col-md-2">
              <Label>Assist two</Label>
            </FormGroup>

            <FormGroup className="col-md-3">
              <Label>Goalie</Label>
            </FormGroup>
          </FormItem>
        )}

        {goals.map(({ id, ...data }) => (
          <Goal key={id} team={team} id={id} {...data} onChange={changeGoal} onRemove={removeGoal} />
        ))}

        <FormItem>
          <Button block outline color="success" onClick={this.addGoal}>
            Add goal
          </Button>
        </FormItem>
      </ContentSection>
    );
  }
}

export default ScoringFieldset;
