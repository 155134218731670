import { handleActions } from "redux-actions";

import { otpRoutine } from "./routines";

const defaultState = {
  otp: "",
  isLoading: false,
  failed: false,
};

const otpRequestActionHandler = (state) => {
  return {
    ...state,
    otp: "",
    isLoading: true,
    failed: false,
  }
};

const otpSuccessActionHandler = (state, { payload: { otp } }) => {
  return {
    ...state,
    otp,
    isLoading: false,
    failed: false,
  }
};

const otpFailureActionHandler = (state) => ({
  ...state,
  otp: "",
  isLoading: false,
  failed: true,
});

export default handleActions(
  {
    [otpRoutine.request]: otpRequestActionHandler,
    [otpRoutine.success]: otpSuccessActionHandler,
    [otpRoutine.failure]: otpFailureActionHandler,
  },
  defaultState
);
