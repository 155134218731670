import React from "react";
import PropTypes from "prop-types";

import AbilityLink from "@/components/AbilityLink";
import Bubble from "@/components/Bubble";

function CountNotesBubble({ id, countNotes, seasonId }) {
  return (
    <AbilityLink
      action="read"
      subject={{ type: "gameNotes", seasonId, gameId: id }}
      to={`/seasons/${seasonId}/games/completed/${id}/notes`}
      fallback={null}
    >
      {countNotes > 0 ? <Bubble text={countNotes} /> : null}
    </AbilityLink>
  );
}

CountNotesBubble.propTypes = {
  id: PropTypes.string.isRequired,
  seasonId: PropTypes.string.isRequired,
  countNotes: PropTypes.number.isRequired
};

export default CountNotesBubble;
