import { useCallback } from "react";

export default function useGameFlaggingSettings(options) {
  const { value: flaggingSettings, onChange: changeFlaggingSettings } = options;
  const { criteria, penalties } = flaggingSettings;

  const changeCriteria = useCallback(criteria => changeFlaggingSettings({ ...flaggingSettings, criteria }), [
    changeFlaggingSettings,
    flaggingSettings
  ]);

  const changePenalties = useCallback(penalties => changeFlaggingSettings({ ...flaggingSettings, penalties }), [
    changeFlaggingSettings,
    flaggingSettings
  ]);

  return {
    criteria: {
      value: criteria,
      onChange: changeCriteria
    },
    penalties: {
      onChange: changePenalties,
      value: penalties
    }
  };
}
