import React from 'react';
import PropTypes from 'prop-types';
import EmptyListMessage from '../EmptyListMessage';
import SuspensionReportSeasonSuspensions from "./SeasonSuspensions";

const List = ({ report }) => {
  
  const suspensions = report.data
  const season = report.meta.season
  
  return (
    <div className="suspension-report__section">
      { !!suspensions.length ? (
        <SuspensionReportSeasonSuspensions key={season.id} season={season} suspensions={suspensions} />
      ) : (
        <EmptyListMessage>There were no incidents.</EmptyListMessage>
      )}
    </div>
  );
};

List.propTypes = {
  report: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    meta: PropTypes.shape({}).isRequired
  }).isRequired
};

export default List;
