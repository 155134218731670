import { createActions } from "redux-actions";

const { reportDataSourcesSelect } = createActions({
  REPORT_DATA_SOURCES_SELECT: {
    ASSOCIATION_LIST: {
      SELECT: undefined,
      DESELECT: undefined,
      CLEAR: undefined
    },
    LEAGUE_LIST: {
      SELECT: undefined,
      DESELECT: undefined,
      CLEAR: undefined,
      SELECT_ALL: undefined
    },
    SEASON_LIST: {
      SELECT: undefined,
      DESELECT: undefined,
      CLEAR: undefined,
      SELECT_ALL: undefined,
      TOGGLE_ARCHIVED_INCLUDED: undefined
    },
    PENALTY_LIST: {
      SELECT: undefined,
      DESELECT: undefined,
      CLEAR: undefined,
      SELECT_ALL: undefined,
      FLAGGED: undefined
    },
    DIVISION_LIST: {
      SELECT: undefined,
      DESELECT: undefined,
      CLEAR: undefined,
      SELECT_ALL: undefined
    },
    TEAM_LIST: {
      SELECT: undefined,
      DESELECT: undefined,
      CLEAR: undefined,
      SELECT_ALL: undefined
    },
    CLEAR: undefined
  }
});

export default reportDataSourcesSelect;
