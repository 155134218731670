import { getResources, getResource } from "@/redux/resources/selectors";
import { getCurrentLeague, getCurrentLeagueBreadcrumbs } from "@/redux/leagues/selectors";
import { isPlayersListLoaded, getPlayerList } from "@/redux/players/selectors";
import { isCoachesListLoaded, getCoachesList } from "@/redux/coaches/selectors";

const rootSelector = state => state.seasons;
const listSelector = state => rootSelector(state).list;
const newSelector = state => rootSelector(state).new;
const currentSelector = state => rootSelector(state).current;

export const isSeasonListLoaded = state => listSelector(state).isLoaded;
export const isSeasonListLoading = state => listSelector(state).isLoading;
export const isSeasonListForbidden = state => listSelector(state).isForbidden;
export const getSeasonList = state => {
  if (isSeasonListLoaded(state)) {
    return getResources(state, "seasons", listSelector(state).ids);
  }
};

export const isSeasonCreating = state => newSelector(state).isCreating;

export const isCurrentSeasonLoaded = state => currentSelector(state).isLoaded;

export const isCurrentSeasonOperating = state => {
  const operation = currentSelector(state).operation;

  return typeof operation === "string" && operation.length > 0;
};

export const isCurrentSeasonLoading = state => currentSelector(state).operation === "loading";

export const isCurrentSeasonReady = state => isCurrentSeasonLoaded(state) && !currentSelector(state).isDeleted;

export const isCurrentSeasonForbidden = state => currentSelector(state).isForbidden;

export const getCurrentSeason = state =>
  isCurrentSeasonReady(state) ? getResource(state, "seasons", currentSelector(state).id) : undefined;

export const getCurrentSeasonId = state => currentSelector(state).id;

export const getCurrentSeasonTitle = state => {
  const isLoaded = isCurrentSeasonLoaded(state);

  if (isLoaded) {
    return getCurrentSeason(state).title;
  }
};

export const getCurrentSeasonBreadcrumbs = state => {
  const league = getCurrentLeague(state);
  const season = getCurrentSeason(state);
  const leagueBreadcrumbs = getCurrentLeagueBreadcrumbs(state);

  if (league && season && leagueBreadcrumbs) {
    return [
      ...leagueBreadcrumbs,
      {
        url: `/seasons/${season.id}`,
        title: season.title
      }
    ];
  }
};

export const getCurrentSeasonRosterBreadcrumbs = state => {
  const season = getCurrentSeason(state);
  const seasonBreadcrumbs = getCurrentSeasonBreadcrumbs(state);

  if (season && seasonBreadcrumbs) {
    return [
      ...seasonBreadcrumbs,
      {
        url: `/seasons/${season.id}/roster`,
        title: "Roster"
      }
    ];
  }
};

export const getCurrentSeasonStats = state => currentSelector(state).stats;

export const formatSeasonRoster = data => {
  return data.map(({ id, firstName, lastName, externalId, season, teams = [] }) => {
    let teamsArr = [];
    let divisionsArr = [];

    if (Array.isArray(teams)) {
      teams.forEach(({ title, division }) => {
        teamsArr.push(title);
        divisionsArr.push(division && division.title);
      });
    }

    const toString = (arr = []) => arr.join(",\n");

    return {
      id,
      firstName,
      lastName,
      externalId,
      seasonId: season.id,
      teams: toString(teamsArr),
      divisions: toString(divisionsArr)
    };
  });
};

export const getSeasonPlayersRoster = state => {
  if (isPlayersListLoaded(state)) {
    const players = getPlayerList(state);

    return formatSeasonRoster(players);
  }

  return [];
};

export const getSeasonCoachesRoster = state => {
  if (isCoachesListLoaded(state)) {
    const coaches = getCoachesList(state);

    return formatSeasonRoster(coaches);
  }

  return [];
};

export const getSeasonListTotalCount = state => listSelector(state).totalCount;
