import React, { useCallback, Fragment } from "react";
import PropTypes from "prop-types";

import { Input, FormFeedback } from "reactstrap";

function TextInput(props) {
  const { id, name, value, setValue, isInvalid, isDirty, errors } = props;

  const onChange = useCallback(({ target: { value } }) => setValue(value), [setValue]);

  return (
    <Fragment>
      <Input invalid={isDirty && isInvalid} id={id} name={name} value={value} onChange={onChange} />

      {isDirty && errors.map(error => <FormFeedback key={error}>{error}</FormFeedback>)}
    </Fragment>
  );
}

TextInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  isInvalid: PropTypes.bool,
  isDirty: PropTypes.bool
};

TextInput.defaultProps = {
  isInvalid: false,
  isDirty: false,
  errors: []
};

export default TextInput;
