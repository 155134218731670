import './style.scss';
import React, { useEffect, useState } from 'react';
import usePagination from './usePagination';
import useFooter from '../Footer/useFooter';
import { useHistory, useLocation } from 'react-router-dom';
import { PaginationBar } from "@/components-16.8/PaginationBar";

export function Pagination(props) {
    const { seasonId } = props;
    const { meta, currentPage, totalPages, filteredGames, setFilteredGames, setTotalPages, setCurrentPage } = usePagination();
    const { clearSelections } = useFooter({ seasonId });
    const [currentHigh, setCurrentHigh] = useState();
    const [currentLow, setCurrentLow] = useState(1);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        clearSelections();
        setFilteredGames(meta.filteredCount);
        setTotalPages(meta.totalPagesMeta);
        if (currentPage * 20 < meta.filteredCount) {
            setCurrentHigh(currentPage * 20);
        } else {
            setCurrentHigh(meta.filteredCount);
        }
        if (meta.filteredCount === 0) {
            setCurrentLow(0);
            return;
        }
        setCurrentLow((currentPage * 20) - 19);
    }, [JSON.stringify(meta)]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        if (!params.get('page')) {
            params.set("page", 1)
            history.replace({
                pathname: location.pathname,
                search: params.toString()
            });
            return;
        } else {
            setCurrentPage(params.get('page'));
            return;
        }
    }, [location])

    const updateUrlPageParam = (page) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("page", page);
        history.push({
            pathname: location.pathname,
            search: searchParams.toString()
        });
    }

    const onClick = (direction) => {
        const params = new URLSearchParams(location.search);

        switch (direction) {
            case "first":
                updateUrlPageParam(1);
                break;
            case "last":
                updateUrlPageParam(meta.totalPagesMeta);
                break;
            case 1:
            case -1:
                const current = Number(params.get('page'));
                if (direction + current <= 0 || direction + current > meta.totalPagesMeta) {
                    return
                }
                updateUrlPageParam(direction + current);
                break;
            default: console.error(direction, " is not a valid page selector");
        }
    }

    return (
        <PaginationBar
            rows={{
                currentLow: currentLow || 0,
                currentHigh: currentHigh || 0,
                total: filteredGames || 0
            }}
            pages={{
                current: currentPage || 0,
                total: totalPages || 0
            }}
            nav={{
                onFirst: () => onClick("first"),
                onBack: () => onClick(-1),
                onForward: () => onClick(1),
                onLast: () => onClick("last")
            }}
        />
    )
}

export default Pagination;