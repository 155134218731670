import { useSelector } from "react-redux";

import { getIsLoaded, getIsLoading, getIsForbidden, getIsFailed, getList, getTotalCount } from "@/redux/leaguesList";

export default function useLeaguesList() {
  const isListLoaded = useSelector(getIsLoaded);
  const isListLoading = useSelector(getIsLoading);
  const isListForbidden = useSelector(getIsForbidden);
  const isListFailed = useSelector(getIsFailed);
  const leagues = useSelector(getList);
  const totalCount = useSelector(getTotalCount);

  return {
    isListLoaded,
    isListLoading,
    isListForbidden,
    isListFailed,
    leagues,
    totalCount
  };
}
