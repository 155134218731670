import React from 'react';
import { FilePicker } from "react-file-picker";
import { Button } from "reactstrap";
import { Table } from "reactstrap";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";

import ContentSection from "@/components/ContentSection";
import { useFileSelection } from '@/components-16.8/RefereeImportWizard/hooks/useFileSelection';
import DashboardFormFooter from "@/components/DashboardFormFooter";
import { useLanding } from "./useLanding";

export function Landing() {

    const { handleFileSelection } = useFileSelection();
    const { handleDownloadTemplate, readingError } = useLanding();

    return (
        <>
            {!!readingError && (<p className="text-danger">{readingError.message}</p>)}
            <ContentSection title="Expected CSV File Layout">
                <Table borderless>
                    <thead>
                        <tr>
                            <th>
                                First Name <span className="text-danger">*</span>
                            </th>
                            <th>
                                Last Name <span className="text-danger">*</span>
                            </th>
                            <th>Email Address</th>
                            <th>External ID</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Renee</td>
                            <td>Arroyo</td>
                            <td>rene@gmail.com</td>
                            <td>28465930</td>
                        </tr>
                        <tr>
                            <td>Farrah</td>
                            <td>Walters</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>

                <Row className="mt-5">
                    <Col lg={12}>
                        <h5>Requirements and limitations</h5>

                        <ul>
                            <li>
                                Fields marked with <span className="text-danger">*</span> are required. Others are optional.
                            </li>
                            <li>
                                Email addresses must be of the form <code>x@x.x</code>.
                            </li>
                        </ul>
                    </Col>
                </Row>
            </ContentSection>

            <DashboardFormFooter>
                <div className="dashboard-form-footer__secondary">
                    <Button type="button" color="secondary" outline onClick={handleDownloadTemplate}>
                        Download CSV Template
                    </Button>
                </div>
                <div className="dashboard-form-footer__primary">
                    <FilePicker onChange={handleFileSelection}>
                        <Button type="button" color="success">Select File</Button>
                    </FilePicker>
                </div>
            </DashboardFormFooter>
        </>
    );
}