import { connect } from "react-redux";

import {
  getGameFormTeamPlayers,
  getGameFormTeamCoaches,
  getGameFormTeamTitle,
  getGameFormTeamId
} from "@/redux/gameForm/selectors";

import PenaltyOffenderInput from "./PenaltyOffenderInput";

const mapStateToProps = (state, { team }) => ({
  players: getGameFormTeamPlayers(state, team),
  coaches: getGameFormTeamCoaches(state, team),
  team: {
    title: getGameFormTeamTitle(state, team),
    id: getGameFormTeamId(state, team)
  }
});

export default connect(mapStateToProps)(PenaltyOffenderInput);
