import { decode } from "jsonwebtoken";
import { getEncodedRefreshToken, getRefreshTokenIsExpired } from "../token/selectors";

export function getIsLoggedIn(state) {
  const token = getEncodedRefreshToken(state);
  const isTokenExpired = getRefreshTokenIsExpired(state);

  if (!token) {
    // redux may not be populated until a request goes out
    return manuallyGetIsLoggedIn();
  }

  // If they do have a token, we check to see if the token is fresh or stale, and returns true or false accordingly
  if (!isTokenExpired) {
    return true;
  }

  return false;
}

export function manuallyGetIsLoggedIn() {
  const refreshToken = localStorage.getItem("refreshToken");
  if (!refreshToken) {
    return false;
  }

  const decodedRefresh = decode(refreshToken, { complete: true }).payload;
  if (!'exp' in decodedRefresh) {
    return false;
  }

  const refreshExpTime = new Date(decodedRefresh.exp * 1000).getTime();
  const nowTime = new Date().getTime();
  return Math.round(refreshExpTime - nowTime) > 0;
}
