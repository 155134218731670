import _get from "lodash/get";
import { handleActions } from "redux-actions";

import { loadingRoutine, lockRostersRoutine } from "../routines";
import actions from "../actions";

const initialState = {
  isLoading: false,
  isLoaded: false,
  isFailed: false,
  isForbidden: false,
  isArchived: false,
  isRosterLocking: false
};

function reduceLoadingRequest(state) {
  return { ...state, isLoading: true, isRosterLocking: false };
}

function reduceLoadingSuccess(state) {
  return { ...state, isLoaded: true };
}

function reduceLoadingFailure(state, { payload: { response } }) {
  const errorObject = _get(response, "data.errors[0]", {
    source: { pointer: null },
    code: null
  });

  return {
    ...state,
    isFailed: true,
    isForbidden: response.status === 401,
    isArchived:
      response.status === 403 && _get(errorObject, "source.pointer") === "/" && errorObject.code === "archived"
  };
}

function reduceLoadingFulfill(state) {
  return { ...state, isLoading: false };
}

function reduceRosterLockRequest(state) {
  return { ...state, isRosterLocking: true };
}

function reduceRosterLockFulfill(state) {
  return { ...state, isRosterLocking: false };
}

export default handleActions(
  {
    [loadingRoutine.REQUEST]: reduceLoadingRequest,
    [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [loadingRoutine.FAILURE]: reduceLoadingFailure,
    [loadingRoutine.FULFILL]: reduceLoadingFulfill,
    [lockRostersRoutine.REQUEST]: reduceRosterLockRequest,
    [lockRostersRoutine.FULFILL]: reduceRosterLockFulfill,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
