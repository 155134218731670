import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import SuspensionReportSuspensionGameNumber from "./GameNumber";
import SuspensionReportSuspensionPerson from "./SuspendedPerson";
import SuspensionReportSuspensionTeam from "./Team";
import SuspensionReportSuspensionDivision from "./Division";
import SuspensionReportSuspensionCount from "./Count";
import * as moment from "moment";
import { useOTP } from "@/lib/core/useOTP";
import { config } from "@/config";

const Suspension = ({ id, date, disableLinks, game, team, division, person, infraction, codeLookup }) => {
  
  const personUrl = `/reports/player-penalty/${person.externalId}`
  const coachUrl = `/reports/bench-staff-penalty/${person.externalId}`

  const label = infraction.type === 'penalty'
    ? codeLookup[infraction.data.code]
      ? codeLookup[infraction.data.code].label + ` (${infraction.data.code})`
      : infraction.data.code
    : infraction.type.toUpperCase();
  
  return (
    <tr>
      <td>{moment(date).format("MMM D, YYYY")}</td>
      <td>
        <SuspensionReportSuspensionGameNumber noLink={disableLinks} game={game} />
      </td>
      <td className="suspension-report__table__person_type">{person.type === 'player' ? (person.jersey !== "" ? person.jersey : 'PLAYER') : person.type}</td>
      <td>
        <a href={ person.type == "player" ? personUrl : coachUrl} target="stats-widget">{person.firstName} {person.lastName}</a>
      </td>
      <td>
        <SuspensionReportSuspensionTeam noLink={disableLinks} {...team} />
      </td>
      <td>
        <SuspensionReportSuspensionDivision noLink={disableLinks} {...division} />
      </td>
      <td>
        {label}
      </td>
      <td>
        {infraction.type === 'penalty' ? "" : <SuspensionReportSuspensionCount number={infraction.data.number} length={infraction.data.length} />}
      </td>
    </tr>
  );
};

Suspension.propTypes = {
  id: PropTypes.number.isRequired,
  date: PropTypes.string,
  disableLinks: PropTypes.bool,
  game: PropTypes.shape({}).isRequired,
  team: PropTypes.shape({}).isRequired,
  division: PropTypes.shape({}).isRequired,
  person: PropTypes.shape({
    type: PropTypes.string.isRequired
  }),
  infraction: PropTypes.shape({
    type: PropTypes.string.isRequired,
    data: PropTypes.shape({}).isRequired
  }).isRequired
};

Suspension.defaultProps = {
  disableLinks: false
};

export default Suspension;
