import { all, put, select, takeLatest, actionChannel, take, race, call } from "redux-saga/effects";
import { push } from "connected-react-router";
import { buffers } from "redux-saga";

import generateInvitationExpirationDate from "@/lib/core/generateInvitationExpirationDate";

import { createInvitation, loadInvitation, deleteInvitation } from "@/lib/api/invitations";

import { loadAssociation } from "@/lib/api/associations";
import { loadLeague } from "@/lib/api/leagues";
import { loadSeason } from "@/lib/api/seasons";
import { loadDivision } from "@/lib/api/divisions";
import { loadTeam } from "@/lib/api/teams";

import { gamesheetAPIRequest, fetchOne } from "@/redux/api/sagas";

import {
  fieldValidatingRoutine,
  validatingRoutine,
  submittingRoutine,
  loadingRoutine,
  deletingRoutine,
  roleLevelLoadingRoutine
} from "./routines";

import { validate, validateCode, validateDescription } from "./validations";

import actions from "./actions";

import { getAttributes } from "./selectors";
import takeConcurrently from "../common/takeConcurrently";

const SERVICES_BY_RESOURCE_TYPE = {
  associations: loadAssociation,
  leagues: loadLeague,
  seasons: loadSeason,
  divisions: loadDivision,
  teams: loadTeam
};

function* fieldValidatingSaga({ payload: { name, value } }) {
  yield put(fieldValidatingRoutine.request({ name, value }));

  let errors;

  switch (name) {
    case "code":
      errors = validateCode(value);
      break;

    case "description":
      errors = validateDescription(value);
      break;

    default:
      throw new Error(`Unknown form field ${name}`);
  }

  if (errors.length === 0) {
    yield put(fieldValidatingRoutine.success({ name, value }));
  } else {
    yield put(fieldValidatingRoutine.failure({ name, value, errors }));
  }

  yield put(fieldValidatingRoutine.fulfill({ name, value }));
}

function* validatingSaga() {
  const attributes = yield select(getAttributes);

  yield put(validatingRoutine.request());

  const errors = validate(attributes);

  if (errors) {
    yield put(validatingRoutine.failure({ errors }));
  } else {
    yield put(validatingRoutine.success());
  }

  yield put(validatingRoutine.fulfill());
}

function* submittingSaga({ payload }) {
  yield put(submittingRoutine.request());

  try {
    const validationSuccess = yield actionChannel(validatingRoutine.SUCCESS, buffers.dropping(1));

    const validationFulfill = yield actionChannel(validatingRoutine.FULFILL, buffers.dropping(1));

    yield put(validatingRoutine());

    const { success } = yield race({
      success: take(validationSuccess),
      fulfill: take(validationFulfill)
    });

    if (!success) {
      yield put(submittingRoutine.failure());

      return;
    }

    const attributes = {
      ...(yield select(getAttributes)),
      expiresAt: generateInvitationExpirationDate().toISOString()
    };

    if (attributes.roles.length === 0) {
      yield put(submittingRoutine.failure());

      return;
    }

    yield call(gamesheetAPIRequest, createInvitation, { attributes });

    yield put(submittingRoutine.success());
    yield put(push("/invitations"));
  } catch (error) {
    if (error.response) {
      yield put(submittingRoutine.failure({ response: error.response }));
    } else {
      yield put(submittingRoutine.failure());
    }
  } finally {
    yield put(submittingRoutine.fulfill());
  }
}

function* loadingSaga({ payload: code }) {
  yield put(loadingRoutine.request());

  try {
    const [invitation] = yield fetchOne({ type: "invitations" }, loadInvitation, { code });

    yield put(loadingRoutine.success({ invitation }));

    yield loadRoleLevels(invitation.roles || []);
  } catch (error) {
    yield put(loadingRoutine.failure({ error }));
  } finally {
    yield put(loadingRoutine.fulfill());
  }
}

function* deletingSaga({ payload: invitationId }) {
  yield put(deletingRoutine.request());

  try {
    yield call(gamesheetAPIRequest, deleteInvitation, { id: invitationId });

    yield put(deletingRoutine.success());
    yield put(push(`/invitations`));
  } catch (e) {
    yield put(deletingRoutine.failure());
  } finally {
    yield put(deletingRoutine.fulfill());
  }
}

function* loadRoleLevels(roles) {
  yield all(roles.map(({ level }) => put(roleLevelLoadingRoutine(level))));
}

function* roleLevelLoadingSaga({ payload: level }) {
  const { type, id } = level;

  yield put(roleLevelLoadingRoutine.request(level));

  try {
    const service = SERVICES_BY_RESOURCE_TYPE[type];

    if (!service) {
      throw new Error(`Unable to load role level with unknown type ${type}`);
    }

    const [resource] = yield fetchOne({ type, id }, service);

    yield put(roleLevelLoadingRoutine.success({ ...level, title: resource.title }));
  } catch (error) {
    if (error.response) {
      const { status } = error.response;

      yield put(roleLevelLoadingRoutine.failure({ ...level, status }));
    } else {
      yield put(roleLevelLoadingRoutine.failure({ ...level, error }));
    }
  } finally {
    yield put(roleLevelLoadingRoutine.fulfill(level));
  }
}

export function* invitationFormFlow() {
  yield all([
    takeLatest(actions.changeField, fieldValidatingSaga),
    takeLatest(validatingRoutine.TRIGGER, validatingSaga),
    takeLatest(submittingRoutine.TRIGGER, submittingSaga),
    takeLatest(loadingRoutine.TRIGGER, loadingSaga),
    takeLatest(deletingRoutine.TRIGGER, deletingSaga),
    takeConcurrently(roleLevelLoadingRoutine, roleLevelLoadingSaga, 10)
  ]);
}
