import React, { Fragment } from "react";
import PropTypes from "prop-types";
import TimeInput from "@/components/TimeInput";

import { FormFeedback } from "reactstrap";

import useSetScheduledTimeValue from "./hooks/useSetScheduledTimeValue";

function ScheduledTimeInput(props) {
  const { id, value, name, setValue, isInvalid, isDirty, errors, ref } = props;

  const onChange = useSetScheduledTimeValue(setValue);

  return (
    <Fragment>
      <TimeInput name={name} id={id} value={value} isDirty={isDirty} isInvalid={isInvalid} onChange={onChange} ref={ref}/>

      {isDirty &&
        errors.map(error => (
          <FormFeedback style={{ display: "block" }} key={error}>
            {error}
          </FormFeedback>
        ))}
    </Fragment>
  );
}

ScheduledTimeInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  isDirty: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default ScheduledTimeInput;
