import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "reactstrap";

import ContentSection from "@/components/ContentSection";

import ShootoutTable from "./ShootoutTable";

function Shootout(props) {
  const { hasShootout, homeAttempts, shotFirst, visitorAttempts, visitorTitle, homeTitle } = props;

  if (!hasShootout) {
    return null;
  }

  return (
    <ContentSection title="Shootout" hSize="h3">
      <Row>
        <Col md="6">
          <ShootoutTable
            team={`Visitor - ${visitorTitle}`}
            attempts={visitorAttempts}
            shotFirst={shotFirst === "visitor"}
          />
        </Col>

        <Col md="6">
          <ShootoutTable team={`Home - ${homeTitle}`} attempts={homeAttempts} shotFirst={shotFirst === "home"} />
        </Col>
      </Row>
    </ContentSection>
  );
}

Shootout.propTypes = {
  hasShootout: PropTypes.bool,
  shotFirst: PropTypes.string,
  homeAttempts: PropTypes.arrayOf(PropTypes.shape({})),
  homeTitle: PropTypes.string.isRequired,
  visitorAttempts: PropTypes.arrayOf(PropTypes.shape({})),
  visitorTitle: PropTypes.string.isRequired
};

export default Shootout;
