import React, { useEffect } from "react";
import PropTypes from "prop-types";

import InfiniteScroll from "@/components/InfiniteScroll";
import TotalCount from "@/components/TotalCount";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import SearchBar from "./SearchBar";
import PlayerTable from "./PlayerTable";

import useAddPlayerModal from "./hooks/useAddPlayerModal";

function AddPlayerModal(props) {
  const { toggle, teamType } = props;

  const {
    seasonPlayers,
    countPlayers,
    hasMore,
    isLoaded,
    isLoading,
    isAppending,
    totalCount,
    load,
    loadMore,
    clearQuery
  } = useAddPlayerModal();

  useEffect(() => {
    load();
  }, []);

  return (
    <Modal isOpen={true} toggle={toggle} size="xl" onExit={clearQuery}>
      <ModalHeader toggle={toggle}>Add Player</ModalHeader>
      <ModalBody>
        <div className="mb-3">
          <SearchBar />
        </div>

        {isLoading ? (
          <p className="total-count">
            <span>&nbsp;</span>
          </p>
        ) : (
          <TotalCount plural="players" singular="player" totalCount={totalCount} filteredCount={seasonPlayers.length} />
        )}

        <InfiniteScroll
          dataLength={countPlayers}
          loadList={loadMore}
          hasMore={hasMore}
          isLoaded={isLoaded}
          isLoading={isLoading}
          isAppending={isAppending}
          emptyMessage="There are no players"
          endMessage="You’ve reached the end of the players list."
        >
          <PlayerTable teamType={teamType} players={seasonPlayers} />
        </InfiniteScroll>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color="secondary" onClick={loadMore} disabled={!hasMore || isLoading || isAppending}>
          Load More
        </Button>
        <Button color="secondary" onClick={toggle}>
          Done
        </Button>
      </ModalFooter>
    </Modal>
  );
}

AddPlayerModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  teamType: PropTypes.string.isRequired
};

export default AddPlayerModal;
