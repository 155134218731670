import React from "react";

import DropdownInput from "./DropdownInput";

function ViewedStatusInput() {
  return (
    <DropdownInput name="viewed" actionName="setViewedStatus" label="Viewed">
      <option value="">All</option>
      <option value="true">Viewed</option>
      <option value="false">Not Viewed</option>
    </DropdownInput>
  );
}

export default ViewedStatusInput;
