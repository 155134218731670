import { combineReducers } from "redux";

import associations from "./associations";
import leagues from "./leagues";
import seasons from "./seasons";
import divisions from "./divisions";
import teams from "./teams";
import players from "./players";
import coaches from "./coaches";
import referees from "./referees";
import games from "./games";
import apiKeys from "./apiKeys";
import invitations from "./invitations";
import users from "./users";
import notes from "./notes";
import reports from "./reports";

export default combineReducers({
  associations,
  leagues,
  seasons,
  divisions,
  teams,
  players,
  coaches,
  referees,
  games,
  apiKeys,
  invitations,
  users,
  notes,
  reports
});
