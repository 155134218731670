import React from 'react';
import { FilePicker } from "react-file-picker";
import { Button } from "reactstrap";
import classNames from "classnames";
import { Table } from "reactstrap";

import { useParsingFailure } from './useParsingFailure';
import { useFileSelection } from '@/components-16.8/RefereeImportWizard/hooks/useFileSelection';
import DashboardFormFooter from "@/components/DashboardFormFooter";
import { COLUMNS, makeHumanReadableCol } from "@/components-16.8/RefereeImportWizard/parser/read";

export function ParsingFailure() {

    const { badRecords } = useParsingFailure();
    const { handleFileSelection } = useFileSelection();

    return (
        <>
            <div className="csv-import__error__text">
                <p>{badRecords.length === 1 ? "1 record" : `${badRecords.length} records`} with validation errors {badRecords.length === 1 ? "was" : "were"} found in the imported .csv file. Please fix and try again.</p>
            </div>
            <Table borderless className="csv-import-table">
                {badRecords.map(badRecord => (
                    // every record get it's own tbody which provides the alternating color and thick black bar
                    <tbody key={badRecord.line} className="record">
                        <tr className="field-row">
                            <td className="field">{badRecord.line}</td>

                            {Object.values(COLUMNS).map(col => {

                                const humanCol = makeHumanReadableCol(col);
                                const error = badRecord.errors[col];

                                return (
                                    <td key={col} className={classNames({ field: true, error: !!error })}>
                                        <span className="label">{humanCol === "" ? "\u00A0" : humanCol}</span>
                                        <span className="value">{badRecord.record[col] === "" ? "\u00A0" : badRecord.record[col]}</span>
                                        {!!error && <span className="error">{error}</span>}
                                    </td>
                                );

                            })}
                        </tr>
                    </tbody>

                ))}
            </Table>
            <DashboardFormFooter>
                <div className="dashboard-form-footer__primary">
                    <FilePicker onChange={handleFileSelection}>
                        <Button type="button" color="success">Re-select File</Button>
                    </FilePicker>
                </div>
            </DashboardFormFooter>
        </>
    );
}