import { connect } from "react-redux";

import { getSeasonRosterCoachesMergeToolCoachesList } from "@/redux/seasonRoster/selectors";

import CoachesListMergeToolTable from "./Table";

const mapStateToProps = state => ({
  coaches: getSeasonRosterCoachesMergeToolCoachesList(state)
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoachesListMergeToolTable);
