import { createActions } from "redux-actions";

const { teamRoster } = createActions({
  TEAM_ROSTER: {
    APPEND_EMPTY_PLAYER: undefined,
    APPEND_EMPTY_COACH: undefined,
    PLAYERS_FORM: {
      EDIT: undefined,
      CHANGE_FIELD: undefined,
      RESET: undefined
    },
    COACHES_FORM: {
      EDIT: undefined,
      CHANGE_FIELD: undefined,
      RESET: undefined
    },
    CLEAR: undefined
  }
});

export default teamRoster;
