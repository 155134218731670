import { useCallback } from "react";

export default function useLiveScoringScopesInput({ value, setValue }) {
  const toggle = useCallback(
    ({ target: { checked, name } }) => {
      if (checked) {
        setValue([...value, name]);
      } else {
        setValue(value.filter(scope => scope !== name));
      }
    },
    [setValue, value]
  );

  return {
    read: { checked: value.includes("read"), onChange: toggle },
    write: { checked: value.includes("write"), onChange: toggle }
  };
}
