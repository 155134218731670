import { useSelector, useDispatch } from "react-redux";

import actions from "@/redux/gameForm/actions";

import { getTeamNormJerseysPlaying } from "@/redux/gameForm/selectors";

export default function useJerseyInput({ team }) {
  const normJerseysPlaying = useSelector(state => getTeamNormJerseysPlaying(state, team));

  const dispatch = useDispatch();
  const addError = ({ key, msg }) => dispatch(actions.addError({ key, msg }));
  const removeError = ({ key }) => dispatch(actions.removeError({ key }));

  return {
    normJerseysPlaying,
    addError,
    removeError
  };
}
