import { handleActions } from "redux-actions";

import penaltyIsMajor from "@/utils/penaltyIsMajor";

const initialState = {
  isLoading: false,
  isLoaded: false,
  isForbidden: false,
  member: {
    firstName: "",
    lastName: "",
    externalId: ""
  },
  careerStats: {
    avgPmGp: 0,
    mostFreqInfractionCode: "",
    mostFreqInfractionCount: 0,
    gamesSuspensionsCount: null
  },
  games: [],
  seasons: [],
  teams: [],
  penalties: []
};

function reduceMember(members) {
  const { firstName, lastName, externalId } = members[0];

  return { firstName, lastName, externalId };
}

function reduceGames(games) {
  return games.reduce(
    (result, { id, startTime, home, visitor, team, season }) => ({
      ...result,
      [id]: {
        startTime,
        homeId: home.id,
        visitorId: visitor.id,
        seasonId: season.id,
        teamId: team.id
      }
    }),
    {}
  );
}

function reduceSeasons(seasons) {
  return seasons.reduce((result, season) => {
    const { id, title, averagePenaltyMinutesPerGamePlayed, gamesPlayed, penaltiesNumber, penaltyMinutes } = season;

    return {
      ...result,
      [id]: {
        title,
        gamesPlayed,
        penalties: penaltiesNumber,
        penaltyMinutes,
        avgPmGp: averagePenaltyMinutesPerGamePlayed
      }
    };
  }, {});
}

function reduceTeams(teams) {
  return teams.reduce(
    (result, { id, title }) => ({
      ...result,
      [id]: {
        title
      }
    }),
    {}
  );
}

function reducePenalties(games) {
  return games
    .reduce(
      (result, { id, team, penalties }) => [
        ...result,
        ...(penalties.map(penalty => ({
          gameId: id,
          teamId: team.id,
          ...penalty
        })) || [])
      ],
      []
    )
    .map((penalty, index) => {
      const { code, gameId, length, offTime, period, teamId, penalized } = penalty;

      const id = [gameId, teamId, period, offTime, code, penalized.type, penalized.id, index].join("-");

      return {
        id,
        code,
        gameId,
        teamId,
        length,
        period,
        isMajor: penaltyIsMajor(penalty)
      };
    });
}

function reduceCareerStats(report) {
  const {
    averagePenaltyMinutesPerGamePlayed,
    mostFrequentPenaltiesNumber,
    mostFrequentPenaltyCode,
    gamesSatOutForSuspensions
  } = report;

  return {
    avgPmGp: averagePenaltyMinutesPerGamePlayed,
    mostFreqInfractionCode: mostFrequentPenaltyCode,
    mostFreqInfractionCount: mostFrequentPenaltiesNumber,
    gamesSuspensionsCount: gamesSatOutForSuspensions
  };
}

function reduceLoadingRequest(state) {
  return { ...state, isLoading: true };
}

function reduceLoadingSuccess(state, { payload: { report } }) {
  const member = reduceMember(report.seasons.map(({ person: member }) => member));
  const games = reduceGames(report.games);
  const seasons = reduceSeasons(report.seasons);
  const teams = reduceTeams([...report.games.map(({ home }) => home), ...report.games.map(({ visitor }) => visitor)]);
  const penalties = reducePenalties(report.games);
  const careerStats = reduceCareerStats(report);

  return {
    ...state,
    member,
    games,
    seasons,
    teams,
    penalties,
    careerStats,
    isLoaded: true
  };
}

function reduceLoadingFailure(state, { payload: { responseStatus } }) {
  return { ...state, isLoaded: false, isForbidden: responseStatus === 401 };
}

function reduceLoadingFulfill(state) {
  return { ...state, isLoading: false };
}

export default function createReportReducer({ actions, loadingRoutine }) {
  return handleActions(
    {
      [loadingRoutine.REQUEST]: reduceLoadingRequest,
      [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
      [loadingRoutine.FAILURE]: reduceLoadingFailure,
      [loadingRoutine.FULFILL]: reduceLoadingFulfill,
      [actions.report.clear]: () => ({ ...initialState })
    },
    initialState
  );
}
