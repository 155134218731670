import React from "react";

import { Table } from "reactstrap";

import InfiniteScroll from "@/components/InfiniteScroll";
import TotalCount from "@/components/TotalCount";

import useUserListSelector from "./hooks/useUserListSelector";
import useUserListLoader from "./hooks/useUserListLoader";

import User from "./User";

function List() {
  const { users, isLoading, isLoaded, isAppending, totalCount, filteredCount } = useUserListSelector();

  const { loadMoreUsers } = useUserListLoader();

  return (
    <InfiniteScroll
      dataLength={users.length}
      loadList={loadMoreUsers}
      hasMore={users.length < filteredCount}
      isLoaded={isLoaded}
      isLoading={isLoading}
      isAppending={isAppending}
      emptyMessage="No users found"
      endMessage="You’ve reached the end of the users list."
    >
      <TotalCount plural="users" singular="user" totalCount={totalCount} filteredCount={filteredCount} />

      <Table striped borderless>
        <thead>
          <tr>
            <th scope="col">Full Name</th>
            <th scope="col">
              <span className="visuallyhidden">Actions</span>
            </th>
            <th scope="col">Email</th>
            <th scope="col">Role</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {users.map(({ id, ...rest }) => (
            <User key={id} id={id} {...rest} />
          ))}
        </tbody>
      </Table>
    </InfiniteScroll>
  );
}

export default List;
