import { connect } from "react-redux";

import { getMeta } from "@/redux/currentDivision/selectors";

import { lockRostersRoutine } from "@/redux/currentDivision/routines";

import teamsActions from "@/redux/teams/actions";

import TeamsIndex from "./TeamsIndex";

const mapStateToProps = state => ({
  isRosterLocking: getMeta(state).isRosterLocking
});

const mapDispatchToProps = dispatch => ({
  lockRosters: payload =>
    dispatch(
      lockRostersRoutine.trigger({
        ...payload,
        successCb: () => {
          dispatch(teamsActions.list.clear());
        }
      })
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamsIndex);
