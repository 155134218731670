import { takeLatest, all, put, call, select, actionChannel, take, race } from "redux-saga/effects";

import { push } from "connected-react-router";
import build from "redux-object";

import { gamesheetAPIRequest } from "@/redux/api/sagas";

import {
  loadReferees,
  createReferee,
  updateReferee,
  deleteReferee,
  loadReferee,
  mergeReferees
} from "@/lib/api/referees";

import { refereesActions } from "./actions";

import { validateFirstName, validateLastName, validateEmailAddress, validateExternalId, validate } from "./validations";

import {
  loadRefereesListRoutine,
  refereeFormSubmittingRoutine,
  refereeFormValidationRoutine,
  refereeFormFieldValidationRoutine,
  refereeFormLoadingRoutine,
  refereeFormDeletingRoutine,
  refereeFormUpdatingRoutine,
  mergeRefereesRoutine,
  currentRefereeLoadingRoutine
} from "./routines";

import {
  getRefereesListLimit,
  getRefereesListOffset,
  getRefereesListSearchCurrentQuery,
  getRefereeFormAttributes,
  getRefereesMergeToolSeasonId,
  getRefereesMergeToolWinner,
  getRefereesMergeToolRefereeIds
} from "./selectors";

function* loadRefereesSaga({ payload: { seasonId, append = false } }) {
  const query = yield select(getRefereesListSearchCurrentQuery);

  const limit = yield select(getRefereesListLimit);
  const offset = append ? yield select(getRefereesListOffset) : 0;

  yield put(loadRefereesListRoutine.request({ query, append }));

  try {
    const { data, rawData, meta } = yield call(gamesheetAPIRequest, loadReferees, { seasonId, limit, offset, query });

    const referees = build(data, "referees") || [];

    yield put(
      loadRefereesListRoutine.success({
        ids: rawData.map(({ id }) => id),
        totalCount: meta ? meta["total-count"] || 0 : 0,
        filteredCount: meta ? meta["filtered-count"] || 0 : 0,
        referees,
        query,
        append
      })
    );
  } catch (error) {
    const responseStatus = error.response && error.response.status;

    yield put(loadRefereesListRoutine.failure({ error, responseStatus }));
  } finally {
    yield put(loadRefereesListRoutine.fulfill({ query, append }));
  }
}

function* commitRefereesSearchQuerySaga({ payload: { seasonId } }) {
  yield put(loadRefereesListRoutine({ seasonId }));
}

function* clearRefereesSearchQuerySaga({ payload: { seasonId } }) {
  yield put(loadRefereesListRoutine({ seasonId }));
}

function* validateFormFieldChangeSaga({ payload: { field, value } }) {
  yield put(refereeFormFieldValidationRoutine.request({ field, value }));

  let errors;

  switch (field) {
    case "firstName":
      errors = validateFirstName(value);
      break;

    case "lastName":
      errors = validateLastName(value);
      break;

    case "emailAddress":
      errors = validateEmailAddress(value);
      break;

    case "externalId":
      errors = validateExternalId(value);
      break;

    default:
      throw new Error(`Unknown form field ${field}`);
  }

  if (errors.length === 0) {
    yield put(refereeFormFieldValidationRoutine.success({ field, value }));
  } else {
    yield put(refereeFormFieldValidationRoutine.failure({ field, value, errors }));
  }

  yield put(refereeFormFieldValidationRoutine.fulfill({ field, value }));
}

function* validateFormSaga() {
  const attributes = yield select(getRefereeFormAttributes);

  yield put(refereeFormValidationRoutine.request());

  const errors = validate(attributes);

  if (errors) {
    yield put(refereeFormValidationRoutine.failure({ errors }));
  } else {
    yield put(refereeFormValidationRoutine.success());
  }

  yield put(refereeFormValidationRoutine.fulfill());
}

function* submitFormSaga({ payload }) {
  const { id, seasonId } = payload;

  yield put(refereeFormSubmittingRoutine.request());

  try {
    const validationSuccess = yield actionChannel(refereeFormValidationRoutine.SUCCESS);

    const validationFulfill = yield actionChannel(refereeFormValidationRoutine.FULFILL);

    yield put(refereeFormValidationRoutine());

    const { success } = yield race({
      success: take(validationSuccess),
      fulfill: take(validationFulfill)
    });

    if (!success) {
      yield put(refereeFormSubmittingRoutine.failure());

      return;
    }

    yield call(id ? updateRefereeSaga : createRefereeSaga, {
      id,
      seasonId
    });

    yield put(refereeFormSubmittingRoutine.success());
    yield put(push(`/seasons/${seasonId}/referees`));
  } catch (error) {
    if (error.response) {
      yield put(refereeFormSubmittingRoutine.failure({ response: error.response }));
    } else {
      yield put(refereeFormSubmittingRoutine.failure());
    }
  } finally {
    yield put(refereeFormSubmittingRoutine.fulfill());
  }
}

function* createRefereeSaga({ seasonId }) {
  const attributes = yield select(getRefereeFormAttributes);

  yield call(gamesheetAPIRequest, createReferee, { attributes, seasonId });
}

function* updateRefereeSaga({ id, seasonId }) {
  const attributes = yield select(getRefereeFormAttributes);

  yield call(gamesheetAPIRequest, updateReferee, {
    identity: { type: "referees", id },
    seasonId,
    attributes
  });
}

function* loadRefereeFormSaga({ payload: { id, seasonId } }) {
  yield put(refereeFormLoadingRoutine.request());

  try {
    const { data } = yield call(gamesheetAPIRequest, loadReferee, {
      identity: { type: "referees", id },
      seasonId
    });

    const referee = build(data, "referees", id);

    yield put(refereeFormLoadingRoutine.success({ referee }));
  } catch (error) {
    const responseStatus = error.response && error.response.status;

    yield put(refereeFormLoadingRoutine.failure({ error, responseStatus }));
  } finally {
    yield put(refereeFormLoadingRoutine.fulfill());
  }
}

function* deleteRefereeSaga({ payload: { id, seasonId } }) {
  yield put(refereeFormDeletingRoutine.request());

  try {
    yield call(gamesheetAPIRequest, deleteReferee, {
      identity: { type: "referees", id },
      seasonId
    });

    yield put(refereeFormDeletingRoutine.success());
    yield put(push(`/seasons/${seasonId}/referees`));
  } catch (e) {
    yield put(refereeFormDeletingRoutine.failure());
  } finally {
    yield put(refereeFormDeletingRoutine.fulfill());
  }
}

function* mergeRefereesSaga() {
  yield put(mergeRefereesRoutine.request());

  const seasonId = yield select(getRefereesMergeToolSeasonId);
  const { id: winnerId, firstName, lastName, externalId } = yield select(getRefereesMergeToolWinner);
  const refereeIds = yield select(getRefereesMergeToolRefereeIds);

  try {
    yield call(gamesheetAPIRequest, mergeReferees, {
      seasonId,
      winnerId,
      firstName,
      lastName,
      externalId,
      refereeIds
    });

    yield put(mergeRefereesRoutine.success());
    yield put(loadRefereesListRoutine({ seasonId }));
  } catch (e) {
    yield put(mergeRefereesRoutine.failure());
  } finally {
    yield put(mergeRefereesRoutine.fulfill());
  }
}

function* loadCurrentRefereeSaga({ payload: { id, seasonId } }) {
  yield put(currentRefereeLoadingRoutine.request());

  try {
    const { data } = yield call(gamesheetAPIRequest, loadReferee, {
      identity: { type: "referees", id },
      seasonId
    });

    const referee = build(data, "referees", id);

    yield put(currentRefereeLoadingRoutine.success({ referee }));
  } catch (error) {
    const responseStatus = error.response && error.response.status;

    yield put(currentRefereeLoadingRoutine.failure({ error, responseStatus }));
  } finally {
    yield put(currentRefereeLoadingRoutine.fulfill());
  }
}

export function* refereesFlow() {
  yield all([
    takeLatest(loadRefereesListRoutine.TRIGGER, loadRefereesSaga),
    takeLatest(refereesActions.list.search.commit, commitRefereesSearchQuerySaga),
    takeLatest(refereesActions.list.search.clear, clearRefereesSearchQuerySaga),
    takeLatest(refereesActions.form.changeField, validateFormFieldChangeSaga),
    takeLatest(refereeFormValidationRoutine.TRIGGER, validateFormSaga),
    takeLatest(refereeFormSubmittingRoutine.TRIGGER, submitFormSaga),
    takeLatest(refereeFormLoadingRoutine.TRIGGER, loadRefereeFormSaga),
    takeLatest(refereeFormUpdatingRoutine.TRIGGER, updateRefereeSaga),
    takeLatest(refereeFormDeletingRoutine.TRIGGER, deleteRefereeSaga),
    takeLatest(mergeRefereesRoutine.TRIGGER, mergeRefereesSaga),
    takeLatest(currentRefereeLoadingRoutine.TRIGGER, loadCurrentRefereeSaga)
  ]);
}
