import React, { Fragment } from "react";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";
import useCurrentDivisionContext from "@/hooks/useCurrentDivisionContext";
import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";
import TeamForm, { useTeamFormSubmitting } from "@/components/TeamForm";
import PageLoader from "@/components/PageLoader";

import ProtectedLayout from "@/layouts/ProtectedLayout";

function NewTeam() {
  const { seasonId } = useCurrentSeasonContext();

  const {
    divisionId,
    divisionIsLoaded,
    divisionIsLoading,
    divisionIsForbidden,
    divisionBreadcrumbs
  } = useCurrentDivisionContext();

  const { isCreating, onSubmit, errorsState, errorsDispatch } = useTeamFormSubmitting({
    seasonId,
    divisionId
  });

  return (
    <ProtectedLayout
      subject={{ type: "teams", divisionId }}
      action="create"
      skip={!divisionIsLoaded && !divisionIsForbidden}
    >
      <Head title="New Team" />
      {divisionIsLoaded ? (
        <Fragment>
          <Breadcrumbs items={divisionBreadcrumbs} />
          <TitleBar title="New Team" />
          <TeamForm
            onSubmit={onSubmit}
            isOperating={isCreating}
            seasonId={seasonId}
            divisionId={divisionId}
            submittingErrors={errorsState}
            updateSubmittingErrors={errorsDispatch}
          />
        </Fragment>
      ) : (
        <PageLoader isLoading={divisionIsLoading} />
      )}
    </ProtectedLayout>
  );
}

export default NewTeam;
