import React from "react";

import { Link } from "react-router-dom";

import { Table } from "reactstrap";

import PageLoader from "@/components/PageLoader";
import TotalCount from "@/components/TotalCount";
import AbilityButton from "@/components/AbilityButton";

import useAssociationsList from "./hooks/useAssociationsList";

function AssociationsList() {
  const { associations, totalCount, isLoading, isLoaded } = useAssociationsList();

  return (
    <PageLoader isLoading={isLoading} isLoaded={isLoaded}>
      <TotalCount plural="associations" singular="association" totalCount={totalCount} />

      <Table striped borderless>
        <thead>
          <tr>
            <th scope="col">Title</th>
            <th scope="col">
              <span className="visuallyhidden">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {associations.map(({ id, title }) => (
            <tr key={id} className="dashboard-table-item">
              <td>
                <Link to={`/associations/${id}/leagues`}>{title}</Link>
              </td>
              <td>
                <AbilityButton
                  subject={{ type: "associations", id }}
                  action="update"
                  size="sm"
                  color="primary"
                  outline
                  className="dashboard-table-item__action dashboard-table-item__action--hoverable"
                  tag={Link}
                  to={`/associations/${id}/edit`}
                >
                  Edit
                </AbilityButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </PageLoader>
  );
}

export default AssociationsList;
