import { combineReducers } from "redux";

import associations from "./reducers/associations";
import leagues from "./reducers/leagues";
import seasons from "./reducers/seasons";
import penalties from "./reducers/penalties";
import divisions from "./reducers/divisions";
import teams from "./reducers/teams";

export default combineReducers({
  associations,
  leagues,
  seasons,
  penalties,
  divisions,
  teams
});
