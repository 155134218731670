import { decode } from "jsonwebtoken";
import _camelCase from "lodash/camelCase";

export function decodeToken(jwt) {
  const decoded = decode(jwt, { complete: true });
  const tokenIsValid = !!decoded;

  const { teamTitle, teamId, subscriptionId } = Object.keys(tokenIsValid ? decoded.payload : {}).reduce(
    (result, key) => ({ ...result, [_camelCase(key)]: decoded.payload[key] }),
    {}
  );

  return {
    teamTitle,
    teamId,
    subscriptionId,
    tokenIsValid
  };
}
