import countries from "i18n-iso-countries";
const en = require("i18n-iso-countries/langs/en.json");
en.countries.US.push("United States");
countries.registerLocale(en);

export default function validateCountry({ country }) {
  console.log("validation.country", country, countries.getAlpha2Code(country, "en"), countries.isValid(country));

  if (country && !countries.isValid(country) && !countries.getAlpha2Code(country, "en")) {
    return [{ field: "country", error: "invalid" }];
  }

  return [];
}
