import React from "react";
import { Link } from "react-router-dom";
import AbilityButton from "@/components/AbilityButton";
import ProtectedLayout from "@/layouts/ProtectedLayout";
import TitleBar from "@/components/TitleBar";
import Head from "@/components/Head";
import AssociationsList from "@/components/AssociationsList";

function AssociationsIndex() {
  return (
    <ProtectedLayout subject="associations" action="read" className="associations-page">
      <Head title="Associations" />

      <TitleBar title="Associations">
        <AbilityButton
          subject="associations"
          action="create"
          color="success"
          size="sm"
          outline
          tag={Link}
          to="/associations/new"
        >
          + New Association
        </AbilityButton>
      </TitleBar>

      <AssociationsList />
    </ProtectedLayout>
  );
}
export default AssociationsIndex;
