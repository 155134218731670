import React, { Fragment } from 'react';
import { Row, Col, FormGroup } from "reactstrap";
import PropTypes from "prop-types";

import DateInput from "./components/DateInput";
import DivisionsInput from "./components/DivisionsInput";
import QueryInput from "./components/QueryInput";
import ResetButton from "./components/ResetButton";
import GameTypeInput from "./components/GameTypeInput"

import { useFilter } from './useFilter';

function Filter({ seasonId }) {
  const {
    startDate,
    endDate,
    divisions,
    queryString,
    gameType,
    setStartDate,
    setEndDate,
    setDivisions,
    setQueryString,
    setGameType,
    canReset,
    reset
  } = useFilter({ seasonId });

  return (
    <Fragment>
      <Row>
        <Col md="5" lg="3">
          <Row form>
            <Col>
              <DateInput inputId={"start-date-filter"} label={"Scheduled after"} value={startDate} setValue={setStartDate} />
            </Col>

            <Col>
              <DateInput inputId={"end-date-filter"} label={"Scheduled before"} value={endDate} setValue={setEndDate} />
            </Col>
          </Row>
        </Col>

        <Col md="7" lg="3">
          <Row>
            <Col>
              <DivisionsInput inputId={"divisions-filter"} value={divisions} setValue={setDivisions} seasonId={seasonId} />
            </Col>

            <Col>
              <GameTypeInput handleInputChange={setGameType} value={gameType}/>
            </Col>
          </Row>
        </Col>

        <Col md="12" lg="6">
          <QueryInput inputId={"query-filter"} value={queryString} setValue={setQueryString} />
        </Col>
      </Row>
      {canReset && (
        <Row>
          <ResetButton reset={reset} />
        </Row>
      )}
    </Fragment>
  );
}

Filter.propTypes = {
  seasonId: PropTypes.string.isRequired
};

export default Filter;