import { useCallback } from "react";

export default function useCountryFieldChange({ setValue, resetProvince }) {
  return useCallback(
    ({ target: { value } }) => {
      resetProvince();
      setValue(value);
    },
    [setValue, resetProvince]
  );
}
