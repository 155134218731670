import { handleActions } from "redux-actions";

import { loadGamesheetRoutine } from "../../routines";
import { clearGamesheet } from "../../actions";
import reduceShootout from "../../utils/reduceShootout";

const initialState = {
  hasShootout: false,
  homeAttempts: [],
  visitorAttempts: []
};

export default handleActions(
  {
    [loadGamesheetRoutine.SUCCESS]: reduceShootout,
    [clearGamesheet]: () => initialState
  },
  initialState
);
