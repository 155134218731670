import { createRoutine } from "redux-saga-routines";

export const PlayerListLoadingRoutine = createRoutine("LOAD_PLAYER_LIST");

export const PlayerCreatingRoutine = createRoutine("CREATE_PLAYER");

export const CurrentPlayerLoadingRoutine = createRoutine("LOAD_CURRENT_PLAYER");

export const CurrentPlayerUpdatingRoutine = createRoutine("UPDATE_CURRENT_PLAYER");

export const CurrentPlayerDeletingRoutine = createRoutine("DELETE_CURRENT_PLAYER");

export const AddPlayerToTeamRoutine = createRoutine("ADD_PLAYER_TO_TEAM");
