import { useSelector, useDispatch } from "react-redux";
import useDivisionLocking from "./useDivisionsLocking"
import useDivisionUnlocking from "./useDivisionsUnlocking"
import { useCallback } from "react";

import {
  getIsLoaded,
  getIsLoading,
  getIsForbidden,
  getIsFailed,
  getList,
  getTotalCount
} from "@/redux/divisionsList";

export default function useDivisionsList() {
  const dispatch = useDispatch();

  const isListLoaded = useSelector(getIsLoaded);
  const isListLoading = useSelector(getIsLoading);
  const isListForbidden = useSelector(getIsForbidden);
  const isListFailed = useSelector(getIsFailed);
  const divisions = useSelector(getList);
  const totalCount = useSelector(getTotalCount);

  return {
    isListLoaded,
    isListLoading,
    isListForbidden,
    isListFailed,
    divisions,
    totalCount
  };
}
