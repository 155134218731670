import { handleActions } from "redux-actions";

const triggerPageLoading = (state, { payload: { pageNumber = 1 } = {} }) => ({
  ...state,
  currentPage: pageNumber,
  error: null
});

const requestPage = state => ({
  ...state,
  isLoading: true
});

const succeedPage = (state, { payload: { totalPages, ids, totalCount, filteredCount } }) => ({
  ...state,
  totalPages,
  ids,
  totalCount,
  filteredCount,
  isLoaded: true
});

const failPage = (state, { payload: { error } }) => ({
  ...state,
  error
});

const fulfillPageLoading = state => ({
  ...state,
  isLoading: false
});

const createPaginationReducer = (loadingRoutine, clearAction, pageSize = 25) => {
  const initialState = {
    currentPage: 1,
    totalPages: 0,
    ids: [],
    error: null,
    isLoading: false,
    isLoaded: false,
    totalCount: 0,
    filteredCount: 0,
    pageSize
  };

  return handleActions(
    {
      [loadingRoutine.TRIGGER]: triggerPageLoading,
      [loadingRoutine.REQUEST]: requestPage,
      [loadingRoutine.SUCCESS]: succeedPage,
      [loadingRoutine.FAILURE]: failPage,
      [loadingRoutine.FULFILL]: fulfillPageLoading,
      [clearAction]: () => ({ ...initialState })
    },
    initialState
  );
};

export default createPaginationReducer;
