import { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import actions from "@/redux/reportDataSourcesSelect/actions";

import {
  getPenaltyList,
  getPenaltyIsSelected,
  getAllPenaltiesSelected,
  getReportDataSourcesSeasonIsSelected,
  getFlaggedPenalties,
  getSelectedSeasonIds,
  getPenaltySeasonIsFlagged
} from "@/redux/reportDataSourcesSelect/selectors";

export default function usePenaltyList() {
  const [userSelected, setUserSelected] = useState([]);
  const [flagged, setFlagged] = useState(true);
  const [oldSeason, setOldSeason] = useState("");

  const dispatch = useDispatch();

  const isLoaded = useSelector(getReportDataSourcesSeasonIsSelected);
  const penaltyList = useSelector(getPenaltyList);
  const isOptionSelected = useSelector(getPenaltyIsSelected);
  const isAllOptionsSelected = useSelector(getAllPenaltiesSelected);
  const flaggedPenalties = useSelector(getFlaggedPenalties);
  const penaltySeasonIsFlagged = useSelector(getPenaltySeasonIsFlagged);
  const selectedSeasons = useSelector(getSelectedSeasonIds);
  const sid = (selectedSeasons.length && selectedSeasons[0]) || "";

  useEffect(
    () => {
      if (selectedSeasons.length > 0 && selectedSeasons[0] !== oldSeason) {
        setOldSeason(selectedSeasons[0]);
        setFlagged(penaltySeasonIsFlagged);
        if (penaltySeasonIsFlagged) {
          dispatch(actions.penaltyList.select({ sid, codes: flaggedPenalties }));
        }
      }
    },
    [selectedSeasons, oldSeason, penaltySeasonIsFlagged]
  );

  const updateFlagged = () => {
    if (flagged) {
      // becoming unchecked
      const toDeselect = flaggedPenalties.filter(f => !userSelected.includes(f));
      dispatch(actions.penaltyList.deselect({ sid, codes: toDeselect }));
    } else {
      // becoming checked
      dispatch(actions.penaltyList.select({ sid, codes: flaggedPenalties }));
    }
    dispatch(actions.penaltyList.flagged({ sid, flagged: !flagged }));
    setFlagged(!flagged);
  };

  const select = code => {
    dispatch(actions.penaltyList.select({ sid, codes: [code] }));
    const newUserSelected = [...userSelected, code];
    setUserSelected(newUserSelected);

    if (newUserSelected.length === flaggedPenalties.length) {
      let same = true;
      for (let i = 0; i < newUserSelected.length; i++) {
        if (!flaggedPenalties.includes(newUserSelected[i])) {
          same = false;
          break;
        }
      }
      if (same) {
        setFlagged(true);
      }
    }
  };

  const deselect = code => {
    dispatch(actions.penaltyList.deselect({ sid, codes: [code] }));
    setUserSelected([...userSelected.filter(usCode => usCode !== code)]);
    if (flaggedPenalties.includes(code)) {
      setFlagged(false);
    }
  };

  const selectAll = () => {
    dispatch(actions.penaltyList.selectAll({ sid }));
    setUserSelected([]);
  };

  const clear = () => {
    dispatch(actions.penaltyList.clear({ sid }));
    setFlagged(false);
    setUserSelected([]);
  };

  return {
    isLoaded,
    penaltyList,
    isOptionSelected,
    isAllOptionsSelected,
    flagged,
    select,
    deselect,
    selectAll,
    clear,
    updateFlagged
  };
}
