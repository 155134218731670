import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./TitleBar.scss";

const TitleBar = ({ title, subtitle, children, titleTag: TitleTag, titleClassName, slim }) => {
  return (
    <div className={classNames("title-bar", { "title-bar--slim": slim })}>
      {title && (
        <div className="title-bar__title-wrapper">
          <TitleTag className={classNames("title-bar__title", titleClassName)}>{title}</TitleTag>
          {subtitle && <p className="title-bar__subtitle">{subtitle}</p>}
        </div>
      )}
      {children && <div className="title-bar__controls">{children}</div>}
    </div>
  );
};

TitleBar.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  titleTag: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  titleClassName: PropTypes.string,
  slim: PropTypes.bool
};

TitleBar.defaultProps = {
  titleTag: "h1",
  titleClassName: "",
  slim: false
};

export default TitleBar;
