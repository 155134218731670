import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { Label, InputGroup } from "reactstrap";

import { useBirthdateField } from "./hooks";

import MonthInput from "./MonthInput";
import DayInput from "./DayInput";
import YearInput from "./YearInput";

function BirthdateField({ value, setValue }) {
  const { month, day, year, date, clearDate } = useBirthdateField({
    value,
    setValue
  });

  return (
    <Fragment>
      <Label for="playerBirthdate">
        Birth Date
        {(month.value !== null || day.value !== null || year.value !== null) && (
          <small onClick={clearDate} className="text-secondary ml-1" onKeyUp={clearDate} role="button" tabIndex="-1">
            clear
          </small>
        )}
      </Label>

      <InputGroup>
        <MonthInput {...month} />
        <DayInput {...day} date={date} month={month.value} />
        <YearInput {...year} />
      </InputGroup>
    </Fragment>
  );
}

BirthdateField.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired
};

export default BirthdateField;
