import _camelCase from "lodash/camelCase";
import _trim from "lodash/trim";
import { handleActions } from "redux-actions";

import actions from "../actions";

import { fieldValidatingRoutine, validatingRoutine, passwordCreatingRoutine } from "../routines";

const FIELDS_MAPPING = {
  password: "Password",
  passwordConfirmation: "Password confirmation"
};

const initialState = {
  password: {
    initialValue: "",
    value: "",
    isDirty: false,
    errors: []
  },
  passwordConfirmation: {
    initialValue: "",
    value: "",
    isDirty: false,
    errors: []
  }
};

function reduceFieldChange(state, { payload: { name, value } }) {
  return {
    ...state,
    [name]: {
      ...state[name],
      isDirty: _trim(value) !== _trim(state[name].initialValue),
      value
    }
  };
}

function reduceFieldValidationSuccess(state, { payload: { name } }) {
  return { ...state, [name]: { ...state[name], errors: [] } };
}

function reduceFieldValidationFailure(state, { payload: { name, errors } }) {
  return { ...state, [name]: { ...state[name], errors } };
}

function reduceFormValidationSuccess(state) {
  return Object.keys(state).reduce((result, name) => {
    return { ...result, [name]: { ...state[name], errors: [] } };
  }, {});
}

function reduceFormValidationFailure(state, { payload: { errors } }) {
  return {
    ...state,
    ...Object.keys(errors).reduce(
      (result, name) => ({
        ...result,
        [name]: { ...state[name], errors: errors[name] }
      }),
      {}
    )
  };
}

function reduceSubmitFailure(state, { payload }) {
  if (payload && payload.response) {
    const {
      status,
      data: { errors }
    } = payload.response;

    if (status === 400 && errors) {
      return {
        ...state,
        ...errors.reduce((result, { title: error, source: { pointer } }) => {
          const name = _camelCase(pointer.match(/\/data\/attributes\/(\w+)/)[1]);

          if (!name) {
            return { ...result };
          }

          const title = FIELDS_MAPPING[name];

          return {
            ...result,
            [name]: {
              ...state[name],
              isDirty: false,
              errors: [...state[name].errors, `${title} ${error}`]
            }
          };
        }, {})
      };
    }
  }

  return { ...state };
}

export default handleActions(
  {
    [actions.changeField]: reduceFieldChange,
    [fieldValidatingRoutine.SUCCESS]: reduceFieldValidationSuccess,
    [fieldValidatingRoutine.FAILURE]: reduceFieldValidationFailure,
    [validatingRoutine.SUCCESS]: reduceFormValidationSuccess,
    [validatingRoutine.FAILURE]: reduceFormValidationFailure,
    [passwordCreatingRoutine.FAILURE]: reduceSubmitFailure,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
