import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import { getRefereesMergeToolStatus } from "@/redux/referees/selectors";
import { loadRefereesListRoutine } from "@/redux/referees/routines";
import { refereesActions } from "@/redux/referees/actions";

import RefereesIndex from "./RefereesIndex";

function RefereesIndexContainer({ seasonId }) {
  const dispatch = useDispatch();

  const status = useSelector(getRefereesMergeToolStatus);

  const { season, seasonIsLoaded, seasonIsLoading, seasonIsForbidden, seasonBreadcrumbs } = useCurrentSeasonContext();

  const loadRefereesList = useCallback(
    () =>
      dispatch(
        loadRefereesListRoutine({
          seasonId: seasonId
        })
      ),
    [dispatch, seasonId]
  );
  const clear = useCallback(() => dispatch(refereesActions.list.clear()), [dispatch]);

  const isMergingMode = ["choosing-winner", "merging", "failure"].includes(status);

  return (
    <RefereesIndex
      seasonId={seasonId}
      season={season}
      seasonIsLoaded={seasonIsLoaded}
      seasonIsLoading={seasonIsLoading}
      seasonIsForbidden={seasonIsForbidden}
      seasonBreadcrumbs={seasonBreadcrumbs}
      isMergingMode={isMergingMode}
      loadRefereesList={loadRefereesList}
      clear={clear}
    />
  );
}

RefereesIndexContainer.propTypes = {
  seasonId: PropTypes.string.isRequired
};

export default RefereesIndexContainer;
