import { connect } from "react-redux";

import RefereesListSearchBar from "./SearchBar";

import {
  getRefereesListSearchNextQuery,
  getRefereesListSearchCurrentQuery,
  getRefereesListSearchQueryIsDirty,
  getRefereesListIsLoading
} from "@/redux/referees/selectors";

import { refereesActions } from "@/redux/referees/actions";

const mapStateToProps = state => ({
  nextQuery: getRefereesListSearchNextQuery(state),
  currentQuery: getRefereesListSearchCurrentQuery(state),
  isDirty: getRefereesListSearchQueryIsDirty(state),
  isLoading: getRefereesListIsLoading(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setQuery: query => dispatch(refereesActions.list.search.setNextQuery(query)),
  commit: () => {
    dispatch(refereesActions.list.mergeTool.disable());
    dispatch(refereesActions.list.search.commit({ seasonId: ownProps.seasonId }));
  },
  clear: () => dispatch(refereesActions.list.search.clear({ seasonId: ownProps.seasonId }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RefereesListSearchBar);
