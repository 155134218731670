import React from "react";
import PropTypes from "prop-types";

import { Button, ListGroupItem } from "reactstrap";

function Role({ seasonId, title, isRemoved, remove, cancelRemoval, isLocked }) {
  return (
    <ListGroupItem color={isRemoved ? "light" : ""}>
      <div className="d-flex align-items-center">
        <div className="flex-fill">{title || <span className="text-secondary">N/A (seasonId: {seasonId})</span>}</div>
        {isLocked && (
          <div className="ml-auto mr-3">
            <span className="text-warning">
              Removing of this season is locked. At least one season must remain in the key.
            </span>
          </div>
        )}
        <div className="ml-auto">
          {isRemoved ? (
            <Button outline color="secondary" type="button" size="sm" onClick={cancelRemoval}>
              Cancel Removal
            </Button>
          ) : (
            <Button outline={isLocked} color="danger" type="button" size="sm" onClick={remove} disabled={isLocked}>
              Remove
            </Button>
          )}
        </div>
      </div>
    </ListGroupItem>
  );
}

Role.propTypes = {
  seasonId: PropTypes.string,
  title: PropTypes.string,
  isLocked: PropTypes.bool.isRequired,
  isRemoved: PropTypes.bool.isRequired,
  remove: PropTypes.func.isRequired,
  cancelRemoval: PropTypes.func.isRequired
};

Role.defaultProps = {
  title: null
};

export default Role;
