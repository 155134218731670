import { handleActions } from "redux-actions";

import { updatePasswordRoutine } from "../../routines";
import { passwordActions } from "../../actions";

const initialState = {
  isWorking: false,
  isCompleted: false,
  error: null
};

const beginRequest = state => ({ ...state, isWorking: true });
const succeedRequest = state => ({ ...state, isCompleted: true });
const failRequest = (state, { payload: { error } }) => ({ ...state, error });
const fulfillRequest = state => ({ ...state, isWorking: false });
const resetError = state => ({ ...state, error: null });
const clear = state => ({ ...initialState });

export default handleActions(
  {
    [updatePasswordRoutine.REQUEST]: beginRequest,
    [updatePasswordRoutine.SUCCESS]: succeedRequest,
    [updatePasswordRoutine.FAILURE]: failRequest,
    [updatePasswordRoutine.FULFILL]: fulfillRequest,
    [passwordActions.edit.changeField]: resetError,
    [passwordActions.edit.clear]: clear
  },
  initialState
);
