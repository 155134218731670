import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";

import { Nav, NavItem, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { Switch, Route, Link } from "react-router-dom";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";
import { Can } from "@/components/Ability";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";
import Head from "@/components/Head";
import PageLoader from "@/components/PageLoader";
import AbilityButton from "@/components/AbilityButton";
import ExportSeasonGamesModal, { useExportSeasonGamesModal } from "@/components/ExportSeasonGamesModal";

import TabLink from "./TabLink";
import CompletedGamesTab from "./tabs/CompletedGamesTab";
import ScheduledGamesList from "@/components-16.8/ScheduledGamesList";

function GamesIndex({ match: { params } }) {
  const { status } = params;
  const [exportScheduleCSV, setExportScheduleCSV] = useState(0);

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(prevState => !prevState);

  const triggerExportScheduleCSV = () => {
    setExportScheduleCSV(exportScheduleCSV + 1);
  };

  const {
    seasonIsLoading,
    seasonIsLoaded,
    seasonIsForbidden,
    season,
    seasonBreadcrumbs,
    seasonId
  } = useCurrentSeasonContext();

  const exportGamesModal = useExportSeasonGamesModal();

  return (
    <ProtectedLayout className="games-page" condition={!seasonIsForbidden}>
      <PageLoader isLoading={seasonIsLoading} isLoaded={seasonIsLoaded}>
        {seasonIsLoaded && (
          <Fragment>
            <Head title={`${season.title} Games`} />
            <Breadcrumbs items={seasonBreadcrumbs} />
            <TitleBar title={`${season.title} Games`} />
          </Fragment>
        )}
      </PageLoader>

      {/*
        PageLoader triggers DOM re-rendering and that leads to double mounting of the active tab on the initial GamesIndex rendering.
        Double rendering triggers double initial games list loading. That's why it is necessary to keep taps out of the PageLoader.
       */}
      {seasonIsLoaded && (
        <Fragment>
          <Nav tabs>
            <TabLink status="completed" active={status === "completed"}>
              Completed Games
            </TabLink>
            <TabLink status="scheduled" active={status === "scheduled"}>
              Scheduled Games
            </TabLink>

            {status === "completed" && (
              <NavItem className="ml-auto">
                <Button color="success" size="sm" onClick={exportGamesModal.open} outline>
                  Export Score Sheets
                </Button>
              </NavItem>
            )}

            {status === "scheduled" && (
              <Fragment>
                <NavItem className="ml-auto mr-2">
                  <Dropdown isOpen={isOpen} toggle={toggle}>
                    <DropdownToggle caret size="sm" color="secondary" outline={true}>
                      CSV Actions
                    </DropdownToggle>
                    <DropdownMenu>
                      <Can I="create" this={{ type: "scheduledGames", seasonId }}>
                        <DropdownItem
                          to={`/seasons/${seasonId}/games/scheduled/csv-import`}
                          tag={Link}
                          color="success"
                          size="sm"
                        >
                          Import from CSV
                        </DropdownItem>
                        <DropdownItem divider />
                      </Can>
                      <DropdownItem onClick={triggerExportScheduleCSV} color="primary">
                        Export to CSV
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </NavItem>
                <NavItem className="mr-0">
                  <AbilityButton
                    subject={{ type: "scheduledGames", seasonId }}
                    action="create"
                    to={`/seasons/${seasonId}/games/scheduled/new`}
                    tag={Link}
                    color="success"
                    outline
                    size="sm"
                  >
                    + New Scheduled Game
                  </AbilityButton>
                </NavItem>
              </Fragment>
            )}
          </Nav>

          <Switch>
            <Route path={`/seasons/:seasonId/games/completed`}>
              <CompletedGamesTab />
            </Route>
            <Route path={`/seasons/:seasonId/games/scheduled`}>
              <ScheduledGamesList season={season} exportTriggered={exportScheduleCSV} />
            </Route>
          </Switch>
        </Fragment>
      )}

      <ExportSeasonGamesModal {...exportGamesModal} />
    </ProtectedLayout>
  );
}

GamesIndex.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      status: PropTypes.oneOf(["completed", "scheduled"])
    }).isRequired
  }).isRequired
};

export default GamesIndex;
