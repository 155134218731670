import React from "react";
import PropTypes from "prop-types";

import LoggedInRoute from "@/containers/LoggedInRoute";
import { Switch } from "react-router-dom";

import InvitationsIndex from "./pages/InvitationsIndex";
import InvitationsNew from "./pages/InvitationsNew";
import InvitationsShow from "./pages/InvitationsShow";

import useInvitationFilterClearing from "./hooks/useInvitationFilterClearing";

function SeasonsRoute({ match }) {
  useInvitationFilterClearing();

  const { url } = match;

  return (
    <Switch>
      <LoggedInRoute path={`${match.url}/new`} component={InvitationsNew} />
      <LoggedInRoute path={`${url}/:code`} component={InvitationsShow} />
      <LoggedInRoute path="" component={InvitationsIndex} />
    </Switch>
  );
}

SeasonsRoute.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired
};

export default SeasonsRoute;
