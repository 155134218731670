import React from "react";

import { Link } from "react-router-dom";
import { Table } from "reactstrap";

import EmptyListMessage from "@/components/EmptyListMessage";
import TotalCount from "@/components/TotalCount";
import AbilityButton from "@/components/AbilityButton";

import useLeaguesList from "./hooks/useLeaguesList";

function LeaguesList() {
  const { leagues, totalCount } = useLeaguesList();

  if (totalCount < 1) {
    return (
      <EmptyListMessage>
        There are no leagues.
        <br />
        Create a new one.
      </EmptyListMessage>
    );
  }

  return (
    <div className="leagues-list">
      <TotalCount plural="leagues" singular="league" totalCount={totalCount} />

      <Table striped borderless>
        <thead>
          <tr>
            <th scope="col">Title</th>
            <th scope="col">
              <span className="visuallyhidden">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {leagues.map(({ id, title }) => (
            <tr key={id} className="dashboard-table-item">
              <td>
                <Link to={`/leagues/${id}/seasons`}>{title}</Link>
              </td>
              <td>
                <AbilityButton
                  subject={{ type: "leagues", id }}
                  action="update"
                  size="sm"
                  color="primary"
                  outline
                  className="dashboard-table-item__action dashboard-table-item__action--hoverable"
                  tag={Link}
                  to={`/leagues/${id}/edit`}
                >
                  Edit
                </AbilityButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default LeaguesList;
