import * as moment from "moment";

export class CoachPenaltyReportData {
    set externalId(externalId) {
        this._externalId = externalId;
    }

    set data(data) {
        this._data = data;

        this._season_coach = (() => {
            const season_coaches = data.season_coaches || [];
            if (season_coaches.length < 1) return null;
            return season_coaches[0];
        })();

        this._rostered_coach = (() => {
            const rostered_coaches = data.rostered_coaches || [];
            if (rostered_coaches.length < 1) return null;
            return rostered_coaches[0];
        })();
    }

    get externalId() {
        return this._externalId || "";
    }

    get ids() {
        if (!this._data) return [];
        return (this._data.season_coaches || []).map(coach => coach.id);
    }

    get firstName() {
        if (!this._season_coach) return "";
        return this._season_coach.first_name || "";
    }

    get lastName() {
        if (!this._season_coach) return "";
        return this._season_coach.last_name || "";
    }

    get isSuspended() {
        return (() => (this._data.rostered_coaches || []).some(team => {
            return team.status === 'suspended'
        }));
    }

    get careerStats() {
        if (!this._data) {
            return {
                avgPmGp: 0,
                mostFreqInfractionCode: "",
                mostFreqInfractionCount: 0,
                majorPenaltiesCount: 0,
            }
        };

        // calculate average penalty minutes per game
        let avgPmGp = 0;
        const gamesPlayed = (this._data.coach_games || []).length;
        const totalPims = (this._data.coach_penalties || []).reduce((acc, penalty) => {
            const times = (penalty.penalty_minutes || "00:00:00").split(":");
            const hours = parseInt(times[0]);
            const minutes = parseInt(times[1]);
            const seconds = parseInt(times[2]);

            return acc + (hours * 60) + minutes + (seconds / 60);
        }, 0);
        if (gamesPlayed > 0) {
            avgPmGp = totalPims / gamesPlayed;
        }

        // calculate most frequent infraction code, and the number of times it occurred
        const penalty_counts = (this._data.coach_penalties || []).reduce((acc, penalty) => {
            acc[penalty.code] = acc[penalty.code] || 0;
            acc[penalty.code] += 1;
            return acc;
        }, {});
        const mostFreqPenalty = Object.keys(penalty_counts).reduce((acc, code) => {
            if (penalty_counts[code] > acc.count) {
                acc.code = code;
                acc.count = penalty_counts[code];
            }
            return acc;
        }, { code: "", count: 0 });

        // calculate the number of major penalties (5 minutes or more)
        const majorPenaltiesCount = (this._data.coach_penalties || []).reduce((acc, penalty) => {
            const times = (penalty.penalty_minutes || "00:00:00").split(":");
            const hours = parseInt(times[0]);
            const minutes = parseInt(times[1]);

            if (hours >= 1 || minutes >= 5) {
                acc += 1;
            }
            return acc;
        }, 0);

        // calculate the number of games suspended
        const gamesSuspensionsCount = (this._data.coach_games || []).reduce((acc, game) => {
            let coach = (game.home.coaches || []).find(coach => this.ids.includes(coach.id));
            if (!coach) {
                coach = (game.visitor.coaches || []).find(coach => this.ids.includes(coach.id));
            }

            if (coach && coach.status === "suspended") {
                acc += 1;
            }

            return acc;
        }, 0);

        return {
            avgPmGp: avgPmGp.toFixed(2),
            mostFreqInfractionCode: mostFreqPenalty.code,
            mostFreqInfractionCount: mostFreqPenalty.count,
            majorPenaltiesCount,
            gamesSuspensionsCount,
        }
    }

    get statsBySeason() {   
        if (!this._data) return [];

        return (this._data.season_coaches || []).map(coach => {
            const gamesPlayed = (this._data.coach_games || []).filter(game => game.season_id === coach.season_id).length;

            const penaltyData = (this._data.coach_penalties || []).reduce((acc, penalty) => {
                const game = (this._data.coach_games || []).find(game => game.id === penalty.game_id);
                if (!game || game.season_id !== coach.season_id) return acc;
                const times = (penalty.penalty_minutes || "00:00:00").split(":");
                const hours = parseInt(times[0]);
                const minutes = parseInt(times[1]);
                const seconds = parseInt(times[2]);

                return {
                    penalties: acc.penalties + 1,
                    penaltyMinutes: acc.penaltyMinutes + (hours * 60) + minutes + (seconds / 60),
                };
            }, {
                penalties: 0,
                penaltyMinutes: 0,
            });

            let avgPmGp = 0;
            if (gamesPlayed > 0) {
                avgPmGp = penaltyData.penaltyMinutes / gamesPlayed;
            }

            return {
                season: {
                    id: coach.season_id,
                    title: coach.title
                },
                gamesPlayed,
                ...penaltyData,
                avgPmGp: avgPmGp.toFixed(2),
            }
        });
    }

    get majorPenalties() {
        if (!this._data) return [];

        const majorPenalties = (this._data.coach_penalties || []).filter(penalty => {
            const times = (penalty.penalty_minutes || "00:00:00").split(":");
            const hours = parseInt(times[0]);
            const minutes = parseInt(times[1]);

            return hours >= 1 || minutes >= 5;
        });

        const sorted = majorPenalties.map((penalty, i) => {
            const out = {
                id: i,
                game: {
                    seasonId: 0,
                    id: 0,
                    title: "unknown",
                    date: "N/A",
                    unixDate: 0,
                },
                code: penalty.code,
                count: 1,
            }

            const game = (this._data.coach_games || []).find(game => game.id === penalty.game_id);
            if (!game) return out;

            out.game = {
                seasonId: game.season_id,
                id: game.id,
                title: `${game.home.title} vs ${game.visitor.title}`,
                date: moment(game.start_time).format("MMM DD, YYYY"),
                unixDate: moment(game.start_time).unix(),
            }
            return out;
        }).sort((a, b) => a.game.unixDate - b.game.unixDate);

        const codes = {};
        sorted.forEach(penalty => {
            codes[penalty.code] = codes[penalty.code] || 0;
            codes[penalty.code] += 1;

            penalty.count = codes[penalty.code];
        });

        sorted.reverse();
        return sorted;
    }

    get infractionHistory() {
        if (!this._data) return [];

        return this._data.coach_penalties.map(penalty => {
            const times = (penalty.penalty_minutes || "00:00:00").split(":");
            const hours = parseInt(times[0]);
            const minutes = parseInt(times[1]);
            const seconds = parseInt(times[2]);
            const minutesTotal = (hours * 60) + minutes + (seconds / 60);

            const out = {
                id: penalty.id,
                game: {
                    seasonId: 0,
                    id: 0,
                    title: "unknown",
                    date: "N/A",
                    unixDate: 0,
                },
                team: "N/A",
                length: minutesTotal.toFixed(2),
                code: penalty.code,
            }

            const game = (this._data.coach_games || []).find(game => game.id === penalty.game_id);
            if (!game) return out;

            out.game = {
                seasonId: game.season_id,
                id: game.id,
                title: `${game.home.title} vs ${game.visitor.title}`,
                date: moment(game.start_time).format("MMM DD, YYYY"),
                unixDate: moment(game.start_time).unix(),
            }

            out.team = game.home.id == penalty.team_id ? game.home.title : game.visitor.title;

            return out;
        }).sort((a, b) => b.game.unixDate - a.game.unixDate);
    }
}