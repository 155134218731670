import { handleActions, combineActions } from "redux-actions";

import actions from "../actions";

const initialState = {
  seasons: {}
};

function reduceSelected(state, { payload: { sid, codes } }) {
  if (sid) {
    if (state.seasons[sid]) {
      return {
        ...state,
        seasons: {
          ...state.seasons,
          [sid]: {
            ...state.seasons[sid],
            selected: [...state.seasons[sid].selected, ...codes]
          }
        }
      };
    } else {
      return {
        ...state,
        seasons: {
          ...state.seasons,
          [sid]: {
            flagged: true,
            selected: [...codes]
          }
        }
      };
    }
  } else {
    return state;
  }
}

function reduceDeselected(state, { payload: { sid, codes } }) {
  return {
    ...state,
    seasons: {
      ...state.seasons,
      [sid]: {
        ...state.seasons[sid],
        selected: [...state.seasons[sid].selected.filter(selectedCode => !codes.includes(selectedCode))]
      }
    }
  };
}

function reducePenaltiesClearing(state, { payload: { sid } }) {
  if (sid) {
    return {
      ...state,
      seasons: {
        ...state.seasons,
        [sid]: {
          flagged: false,
          selected: []
        }
      }
    };
  } else {
    return state;
  }
}

function reducePenaltiesFlagged(state, { payload: { sid, flagged } }) {
  if (sid) {
    return {
      ...state,
      seasons: {
        ...state.seasons,
        [sid]: {
          ...state.seasons[sid],
          flagged
        }
      }
    };
  }
}

function reduceClearing(state) {
  return { ...initialState };
}

export default handleActions(
  {
    [actions.penaltyList.clear]: reducePenaltiesClearing,
    [actions.penaltyList.flagged]: reducePenaltiesFlagged,
    [actions.penaltyList.select]: reduceSelected,
    [actions.penaltyList.deselect]: reduceDeselected,
    [actions.clear]: reduceClearing
  },
  initialState
);
