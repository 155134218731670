import React from "react";
import PropTypes from "prop-types";
import NameWithNumber from "./NameWithNumber";

import { Table } from "reactstrap";

function ShootoutTable({ team, shotFirst, attempts }) {
  return (
    <Table striped borderless>
      <thead>
        <tr>
          <th scope="colgroup" colSpan="4">
            {team} {shotFirst && "*"}
          </th>
        </tr>
        <tr>
          <th scope="col">Player</th>
          <th scope="col">Goalie</th>
          <th scope="col">Result</th>
        </tr>
      </thead>
      <tbody>
        {attempts.map(({ number, shooter, goalie, result }) => (
          <tr key={number}>
            <td>
              <NameWithNumber {...shooter} />
            </td>
            <td>
              <NameWithNumber {...goalie} />
            </td>
            <td className="text-capitalize">{result}</td>
          </tr>
        ))}
      </tbody>
      {shotFirst && <caption className="text-secondary">* Shot First</caption>}
    </Table>
  );
}

ShootoutTable.propTypes = {
  team: PropTypes.string.isRequired,
  shotFirst: PropTypes.bool,
  attempts: PropTypes.arrayOf(PropTypes.shape({}))
};

export default ShootoutTable;
