const selectPassword = state => state.password;
const selectPasswordReset = state => selectPassword(state).reset;
const selectPasswordResetForm = state => selectPasswordReset(state).form;
const selectPasswordMeta = state => selectPasswordReset(state).meta;
const selectNewPassword = state => selectPassword(state).new;
const selectNewPasswordForm = state => selectNewPassword(state).form;
const selectNewPasswordMeta = state => selectNewPassword(state).meta;
const selectEditPassword = state => selectPassword(state).edit;
const selectEditPasswordForm = state => selectEditPassword(state).form;
const selectEditPasswordMeta = state => selectEditPassword(state).meta;

export const getPasswordResetFormEmail = state => selectPasswordResetForm(state).email;

export const getPasswordResetIsWorking = state => selectPasswordMeta(state).isWorking;

export const getPasswordResetIsCompleted = state => selectPasswordMeta(state).isCompleted;

export const getPasswordResetFormIsDirty = state => selectPasswordResetForm(state).isDirty;

export const getPasswordResetError = state => selectPasswordMeta(state).error;

export const getNewPasswordFormFields = state => selectNewPasswordForm(state);

export const getNewPasswordFormIsDirty = state =>
  Object.values(getNewPasswordFormFields(state))
    .map(({ isDirty }) => isDirty)
    .includes(true);

export const getNewPasswordIsWorking = state => selectNewPasswordMeta(state).isWorking;

export const getNewPasswordIsCompleted = state => selectNewPasswordMeta(state).isCompleted;

export const getNewPasswordError = state => selectNewPasswordMeta(state).error;

export const getEditPasswordFormFields = state => selectEditPasswordForm(state);

export const getEditPasswordFormIsDirty = state =>
  Object.values(getEditPasswordFormFields(state))
    .map(({ isDirty }) => isDirty)
    .includes(true);

export const getEditPasswordIsWorking = state => selectEditPasswordMeta(state).isWorking;

export const getEditPasswordIsCompleted = state => selectEditPasswordMeta(state).isCompleted;

export const getEditPasswordError = state => selectEditPasswordMeta(state).error;
