import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import { FormGroup, Label, Input } from "reactstrap";

import ContentSection from "@/components/ContentSection";
import DashboardForm from "@/components/DashboardForm";
import DashboardFormFooter from "@/components/DashboardFormFooter";
import FormItem from "@/components/FormItem";
import AbilityButton from "@/components/AbilityButton";
import SeasonRosterMemberDeleteConfirmation from "@/components/SeasonRosterMemberDeleteConfirmation";
import { Can } from "@/components/Ability";

class CoachForm extends PureComponent {
  static propTypes = {
    seasonId: PropTypes.string.isRequired,
    teamId: PropTypes.string,
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    externalId: PropTypes.string,
    isOperating: PropTypes.bool,
    isDeleting: PropTypes.bool,
    onSubmit: PropTypes.func,
    onDelete: PropTypes.func,
    teams: PropTypes.arrayOf(PropTypes.shape({}))
  };

  static defaultProps = {
    id: "",
    teamId: null,
    firstName: "",
    lastName: "",
    externalId: "",
    isOperating: false,
    isDeleting: false,
    teams: [],
    onSubmit() {},
    onDelete() {}
  };

  state = {
    firstName: {
      value: this.props.firstName,
      isValid: true
    },
    lastName: {
      value: this.props.lastName,
      isValid: true
    },
    externalId: {
      value: this.props.externalId,
      isValid: true
    },
    isPristine: true
  };

  isValid = (name, value) => {
    switch (name) {
      case "firstName":
      case "lastName":
        return !!value;
      default:
        return true;
    }
  };

  allValid = () => {
    return (
      this.isValid("firstName", this.state.firstName.value) &&
      this.isValid("lastName", this.state.lastName.value) &&
      this.isValid("externalId", this.state.externalId.value)
    );
  };

  handleChange = ({ target }) => {
    this.setState({
      [target.name]: {
        value: target.value,
        isValid: this.isValid(target.name, target.value)
      },
      isPristine: false
    });
  };

  handleSubmit = () => {
    const { id, onSubmit } = this.props;
    const { firstName, lastName, externalId } = this.state;

    this.setState({ isPristine: true });

    if (!this.allValid()) {
      return;
    }

    onSubmit({
      id,
      attributes: {
        firstName: firstName.value,
        lastName: lastName.value,
        externalId: externalId.value
      }
    });
  };

  handleDelete = () => {
    this.props.onDelete(this.props.id);
  };

  render() {
    const { isOperating, seasonId, id, teamId, isDeleting, teams } = this.props;
    const { firstName, lastName, externalId, isPristine } = this.state;

    const cannotSave = !this.allValid() || isPristine;

    return (
      <DashboardForm className="coach-form" isOperating={isOperating} onSubmit={this.handleSubmit}>
        <ContentSection>
          <FormItem>
            <FormGroup className="col-md-3">
              <Label for="coachFirstName">First Name</Label>
              <Input
                id="coachFirstName"
                name="firstName"
                onChange={this.handleChange}
                defaultValue={firstName.value}
                invalid={!firstName.isValid}
              />
            </FormGroup>

            <FormGroup className="col-md-3">
              <Label for="coachLastName">Last Name</Label>
              <Input
                id="coachLastName"
                name="lastName"
                onChange={this.handleChange}
                defaultValue={lastName.value}
                invalid={!lastName.isValid}
              />
            </FormGroup>

            <FormGroup className="col-md-6">
              <Label for="coachExternalD">External ID</Label>
              <Input
                id="coachExternalD"
                name="externalId"
                onChange={this.handleChange}
                defaultValue={externalId.value}
              />
            </FormGroup>
          </FormItem>
        </ContentSection>

        <DashboardFormFooter>
          {id ? (
            <Fragment>
              <Can I="delete" this={{ type: "coaches", id }}>
                <div className="dashboard-form-footer__secondary">
                  <SeasonRosterMemberDeleteConfirmation
                    member={{
                      id,
                      fullName: `${firstName.value} ${lastName.value}`,
                      type: "coach"
                    }}
                    teams={teams}
                    isOperating={isOperating}
                    isDeleting={isDeleting}
                    onDelete={this.handleDelete}
                  />
                </div>
              </Can>

              <div className="dashboard-form-footer__primary">
                <AbilityButton
                  subject={{ type: "coaches", id }}
                  action="update"
                  type="submit"
                  color="success"
                  outline={cannotSave}
                  disabled={cannotSave}
                >
                  Save Changes
                </AbilityButton>
              </div>
            </Fragment>
          ) : (
            <div className="dashboard-form-footer__primary">
              <AbilityButton
                subject={teamId ? { type: "roster", teamId } : { type: "coaches", seasonId }}
                action={teamId ? "createCoach" : "create"}
                type="submit"
                color="success"
                outline={cannotSave}
                disabled={cannotSave}
              >
                Create Coach
              </AbilityButton>
            </div>
          )}
        </DashboardFormFooter>
      </DashboardForm>
    );
  }
}

export default CoachForm;
