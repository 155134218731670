import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import { actions, getCreated, getUpdated, getImportedFailure } from "@/redux/scheduledGamesCSVImportWizard";

export default function useScheduledGamesCSVImportWizardResult() {
  const dispatch = useDispatch();

  const { seasonId } = useCurrentSeasonContext();

  const reset = useCallback(() => dispatch(actions.clear()), [dispatch]);

  const created = useSelector(getCreated);
  const updated = useSelector(getUpdated);
  const failed = useSelector(getImportedFailure);

  return {
    created,
    updated,
    failed,
    reset,
    seasonId
  };
}
