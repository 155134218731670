import { useEffect, useState } from "react";

export const useSportOptions = () => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    let mounted = true;

    (async () => {
      const sportOptions = await fetch("https://lookups.gamesheet.io/api/lookups/sports")
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch sports");
          }

          return response.json();
        })
        .catch(() => ([]));

      mounted && setOptions(sportOptions);
    })();

    return () => {
      mounted = false;
    };
  }, []);

  return options;
}