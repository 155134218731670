import { connect } from "react-redux";

import { loginAction } from "@/redux/login/actions";

import HandleFirebase from "./HandleFirebase";

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  login: payload => dispatch(loginAction(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HandleFirebase);
