import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import { APP_NAME } from "@/constants/app";

const Head = ({ title, description, keywords, children }) => (
  <Helmet>
    <title>{title ? `${title} :: ${APP_NAME}` : APP_NAME}</title>
    {description && <meta name="description" content={description} />}
    {keywords && <meta name="keywords" content={keywords} />}
    {children}
  </Helmet>
);

Head.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  description: PropTypes.string,
  keywords: PropTypes.string
};

Head.defaultProps = {
  title: ""
};

export default Head;
