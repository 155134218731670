import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { enableSubscriptionRoutine, disableSubscriptionRoutine } from "@/redux/gameReportEmailNotificationSettings";

export default function useToggleTeamSubscription() {
  const dispatch = useDispatch();

  return useCallback(
    ({ target: { checked, value: teamId } }) => {
      const routine = checked ? enableSubscriptionRoutine : disableSubscriptionRoutine;

      dispatch(routine({ teamId }));
    },
    [dispatch]
  );
}
