import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";

import PageLoader from "@/components/PageLoader";

import EmptyListMessage from "@/components/EmptyListMessage";

import InfiniteScrollSpinner from "./Spinner";
import InfiniteScrollEndMessage from "./EndMessage";

class InfiniteScrollWrapper extends PureComponent {
  static propTypes = {
    isLoaded: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    dataLength: PropTypes.number.isRequired,
    emptyMessage: PropTypes.string,
    endMessage: PropTypes.string,
    hasMore: PropTypes.bool.isRequired,
    isAppending: PropTypes.bool.isRequired,
    loadList: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired
  };

  static defaultProps = {
    emptyMessage: "No records found.",
    endMessage: "You’ve reached the end of the list."
  };

  render() {
    const {
      isLoaded,
      isLoading,
      dataLength,
      emptyMessage,
      endMessage,
      loadList,
      hasMore,
      isAppending,
      children
    } = this.props;

    if (!isLoaded || (isLoaded && isLoading && !isAppending)) {
      return <PageLoader isLoading={isLoading} />;
    } else if (dataLength === 0) {
      return <EmptyListMessage>{emptyMessage}</EmptyListMessage>;
    }

    return (
      <InfiniteScroll
        dataLength={dataLength}
        next={loadList}
        hasMore={hasMore}
        loader={<InfiniteScrollSpinner enabled={isLoading} />}
        endMessage={<InfiniteScrollEndMessage text={endMessage} />}
      >
        {children}
      </InfiniteScroll>
    );
  }
}

export default InfiniteScrollWrapper;
