import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Table } from "reactstrap";

import RefereesListMergeToolReferee from "../Referee";

class RefereesListMergeToolTable extends PureComponent {
  static propTypes = {
    referees: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired }))
  };

  static defaultProps = {
    referees: []
  };

  render() {
    const { referees } = this.props;

    return (
      <Table striped borderless className="referees-list__merge-tool__table">
        <thead>
          <tr>
            <th scope="col">Full name</th>
            <th scope="col">External ID</th>
            <th scope="col">
              <span className="visuallyhidden">Keep</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {referees.map(referee => (
            <RefereesListMergeToolReferee key={referee.id} {...referee} />
          ))}
        </tbody>
      </Table>
    );
  }
}

export default RefereesListMergeToolTable;
