import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

import FormItem from "@/components/FormItem";
import AddPlayerModal from "./AddPlayerModal";

function AddPlayerButton(props) {
  const { teamType } = props;
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <FormItem>
      <Button block outline color="success" onClick={() => setModal(true)} className="mt-2">
        Add player from season
      </Button>
      {modal && <AddPlayerModal toggle={toggle} teamType={teamType} />}
    </FormItem>
  );
}

AddPlayerButton.propTypes = {
  teamType: PropTypes.string.isRequired
};

export default AddPlayerButton;
