// assumes that eventTime starts at 0 and goes to 90+ minutes
// assumes eventTime is in format MM:SS (or MMM:SS for games longer than 99 minutes)
// assumes periods is an array of { name: string, length: number }
// assumes periods are sorted, and all periods before eventPeriod have been played
// always returns a string
export const formatSoccerTime = (eventTime, eventPeriod, periods) => {
    if (!eventTime) return "";
    if (!eventPeriod) return "";
    if (!periods || !Array.isArray(periods)) return "";
    if (periods.some(p => !p.name || isNaN(p.length) || p.length < 0)) return "";
    
    const periodElem = periods.find(p => p.name === eventPeriod);
    if (!periodElem) return "";
    const periodMinutes = periodElem.length;
    
    const timeComponents = eventTime.split(":");
    if (timeComponents.length !== 2) return "";
    const [minutes, seconds] = timeComponents.map(Number);
    if (isNaN(minutes) || isNaN(seconds)) return "";
  
    if (seconds > 59 || seconds < 0) return "";
    if (minutes < 0) return "";

    // sum all minutes in periods before this eventPeriod
    let minutesBefore = 0;
    const thisPeriodIndex = periods.findIndex(p => p.name === eventPeriod);
    for (let i = 0; i < thisPeriodIndex; i++) {
      minutesBefore += periods[i].length;
    }
  
    if (minutes > (periodMinutes + minutesBefore)) {
      return `${periodMinutes + minutesBefore}' + ${minutes - periodMinutes - minutesBefore}'`;
    } else {
      return `${minutes}'`;
    }
}

// example input -> output
// formatSoccerTime("02:01", "1", [{ name: "1", length: 45 }, { name: "2", length: 45 }]) -> 2'
// formatSoccerTime("49:40", "1", [{ name: "1", length: 45 }, { name: "2", length: 45 }]) -> 45' + 4'
// formatSoccerTime("49:40", "2", [{ name: "1", length: 45 }, { name: "2", length: 45 }]) -> 49'

export const formatSoccerMinutes = (eventTime) => {
  if (!eventTime) return "";
  
  const timeComponents = eventTime.split(":");
  if (timeComponents.length !== 2) return "";
  const [minutes, seconds] = timeComponents.map(Number);
  if (isNaN(minutes) || isNaN(seconds)) return "";

  if (seconds > 59 || seconds < 0) return "";
  if (minutes < 0) return "";

  return `${minutes}'`;
}