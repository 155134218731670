import { handleActions } from "redux-actions";

import actions from "./actions";

const initialState = {
  display: false,
  userEmail: "",
  title: ""
};

export default handleActions(
  {
    [actions.show]: (state, { payload: { userEmail, title } }) => ({
      ...state,
      userEmail,
      title,
      display: true
    }),
    [actions.hide]: () => ({ ...initialState })
  },
  initialState
);
