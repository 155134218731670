import { handleActions, combineActions } from "redux-actions";

import { submittingRoutine } from "../routines";
import actions from "../actions";

function reduceLoadingFailure(state, { payload: { validationErrors } }) {
  return validationErrors || {};
}

export default handleActions(
  {
    [submittingRoutine.FAILURE]: reduceLoadingFailure,
    [combineActions(submittingRoutine.SUCCESS, actions.clear)]: () => ({})
  },
  {}
);
