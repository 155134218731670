import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getMeta, getLeague, loadingRoutine } from "@/redux/currentLeague";

export default function useCurrentLeague(leagueId) {
  const dispatch = useDispatch();

  const league = useSelector(getLeague);
  const association = league.association;
  const associationId = association.id;
  const leagueFormFields = { title: league.title, logo: league.logo };

  const {
    isLoaded: leagueIsLoaded,
    isLoading: leagueIsLoading,
    isForbidden: leagueIsForbidden,
    isFailed: leagueIsFailed
  } = useSelector(getMeta);

  const leagueBreadcrumbs = leagueIsLoaded
    ? [
        { title: "Associations", url: "/associations" },
        {
          title: association.title,
          url: `/associations/${associationId}/leagues`
        },
        {
          title: league.title,
          url: `/leagues/${leagueId}/seasons`
        }
      ]
    : [];

  useEffect(
    () => {
      if (!leagueIsLoaded && !leagueIsLoading && !leagueIsFailed) {
        dispatch(loadingRoutine.trigger({ leagueId }));
      }
    },
    [dispatch, leagueIsLoaded, leagueIsLoading, leagueIsFailed, leagueId]
  );

  return {
    league,
    leagueId,
    leagueIsLoading,
    leagueIsLoaded,
    leagueIsForbidden,
    leagueIsFailed,
    leagueBreadcrumbs,
    leagueFormFields,
    leagueAssociationId: associationId
  };
}
