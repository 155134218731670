import { handleActions, combineActions } from "redux-actions";

import {
  teamLoadingRoutine,
  subscriptionsLoadingRoutine,
  enableSubscriptionRoutine,
  disableSubscriptionRoutine
} from "../routines";
import actions from "../actions";

const initialState = {};

function reduceSubscriptionsLoadingSuccess(state, { payload }) {
  const { postGameEmailSubscriptions } = payload;

  return postGameEmailSubscriptions.reduce(
    (result, subscription) => ({
      ...result,
      [subscription.team.id]: {
        isEnabled: true,
        isCommitted: true,
        subscriptionId: subscription.id
      }
    }),
    state
  );
}

function reduceTeamLoadingSuccess(state, { payload: { team } }) {
  return {
    ...state,
    [team.id]: state[team.id] || {
      isEnabled: false,
      isCommitted: true,
      subscriptionId: undefined
    }
  };
}

function reduceEnableRequest(state, { payload: { teamId } }) {
  return {
    ...state,
    [teamId]: {
      ...state[teamId],
      isEnabled: true,
      isCommitted: false
    }
  };
}

function reduceEnableSuccess(state, { payload }) {
  const { teamId, postGameEmailSubscription } = payload;

  return {
    ...state,
    [teamId]: {
      ...state[teamId],
      subscriptionId: postGameEmailSubscription.id
    }
  };
}

function reduceEnableFailure(state, { payload: { teamId } }) {
  return {
    ...state,
    [teamId]: {
      ...state[teamId],
      isEnabled: false
    }
  };
}

function reduceDisableRequest(state, { payload: { teamId } }) {
  return {
    ...state,
    [teamId]: {
      ...state[teamId],
      isEnabled: false,
      isCommitted: false
    }
  };
}

function reduceDisableSuccess(state, { payload: { teamId } }) {
  return {
    ...state,
    [teamId]: {
      ...state[teamId],
      subscriptionId: undefined
    }
  };
}

function reduceDisableFailure(state, { payload: { teamId } }) {
  return {
    ...state,
    [teamId]: {
      ...state[teamId],
      isEnabled: true
    }
  };
}

function reduceToggleFulfill(state, { payload: { teamId } }) {
  return {
    ...state,
    [teamId]: {
      ...state[teamId],
      isCommitted: true
    }
  };
}

export default handleActions(
  {
    [subscriptionsLoadingRoutine.SUCCESS]: reduceSubscriptionsLoadingSuccess,
    [teamLoadingRoutine.SUCCESS]: reduceTeamLoadingSuccess,
    [enableSubscriptionRoutine.REQUEST]: reduceEnableRequest,
    [enableSubscriptionRoutine.SUCCESS]: reduceEnableSuccess,
    [enableSubscriptionRoutine.FAILURE]: reduceEnableFailure,
    [disableSubscriptionRoutine.REQUEST]: reduceDisableRequest,
    [disableSubscriptionRoutine.SUCCESS]: reduceDisableSuccess,
    [disableSubscriptionRoutine.FAILURE]: reduceDisableFailure,
    [combineActions(enableSubscriptionRoutine.FULFILL, disableSubscriptionRoutine.FULFILL)]: reduceToggleFulfill,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
