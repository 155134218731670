import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { accountLoadingRoutine, passwordCreatingRoutine } from "@/redux/accountVerificationForm/routines";

import actions from "@/redux/accountVerificationForm/actions";

import {
  getMeta,
  getAccount,
  getIsNotFilled,
  getIsInvalid,
  getIsPristine
} from "@/redux/accountVerificationForm/selectors";

export default function useAccountVerification(code) {
  const codeIsInvalid = code.length === 0;
  const dispatch = useDispatch();

  const { accountIsLoading, accountIsLoaded, accountIsFailed, passwordIsCreating, passwordIsFailed } = useSelector(
    getMeta
  );

  const { userName, userEmail } = useSelector(getAccount);
  const isNotFilled = useSelector(getIsNotFilled);
  const isInvalid = useSelector(getIsInvalid);
  const isPristine = useSelector(getIsPristine);

  const triggerPasswordCreating = useCallback(
    () => dispatch(passwordCreatingRoutine({ verificationCode: code, userEmail })),
    [dispatch, code, userEmail]
  );

  useEffect(
    () => {
      if (!codeIsInvalid) {
        dispatch(accountLoadingRoutine(code));
      }

      return () => dispatch(actions.clear());
    },
    [dispatch, codeIsInvalid, code]
  );

  return {
    codeIsInvalid,
    accountIsLoading,
    accountIsLoaded,
    accountIsFailed,
    passwordIsCreating,
    passwordIsFailed,
    userName,
    userEmail,
    isNotFilled,
    isInvalid,
    isPristine,
    triggerPasswordCreating
  };
}
