export default function reduceShootouts(state, { payload: { game } }) {
  const homeAttempts = game.homeShootoutAttempts || [];
  const visitorAttempts = game.visitorShootoutAttempts || [];

  const hasShootout = homeAttempts.length > 0 || visitorAttempts.length > 0;

  return {
    hasShootout,
    homeAttempts,
    visitorAttempts
  };
}
