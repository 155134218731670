// react imports
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// type imports
import {
  CSVRosterImportStatusValues as ImportStatusValues,
  CSVRosterImportStatus as ImportStatus
} from "@/components-16.8/RosterImportWizard/types/importStatus";

// legacy component imports
import TitleBar from "@/components/TitleBar";
import PageLoader from "@/components/PageLoader";
import DashboardFormFooter from "@/components/DashboardFormFooter";
import DashboardForm from "@/components/DashboardForm";

// hooks
import { useRosterImport } from "@/components-16.8/RosterImportWizard/hooks/useRosterImport";
import { useParams } from 'react-router-dom';

// component imports
import { Link } from 'react-router-dom';
import Parser from "@/components-16.8/RosterImportWizard/steps/Parser";
import ValidationFailed from "@/components-16.8/RosterImportWizard/steps/ValidationFailed";
import ValidationSucceeded from "@/components-16.8/RosterImportWizard/steps/ValidationSucceeded";
import ImportingProgress from "@/components-16.8/RosterImportWizard/steps/ImportingProgress";
import ImportComplete from "@/components-16.8/RosterImportWizard/steps/ImportComplete";
import { Button } from "reactstrap";
import ShowIf from "@/components-16.8/ShowIf";
import { FilePicker } from "react-file-picker";

// component definition
function Wizard({ seasonId, teamId }) {

  // import the roster managed state
  const {
    status,
    error,
    isSeasonImport,
    isTeamImport,
    handleResetImport,
    handleStartImport,
    handleFileSelection,
    handleDownloadTemplate
  } = useRosterImport( seasonId, teamId );

  // render the wizard
  return (
    <>
      <DashboardForm>
        <TitleBar title={`${ isTeamImport ? 'Team':"Season"} Roster Import Wizard`} />

        <ShowIf cond={status} value={ImportStatus.PARSING} component={<PageLoader isLoading />} />
        <ShowIf cond={status} value={ImportStatus.PENDING} component={<Parser teamId={teamId} />} />
        <ShowIf cond={status} value={ImportStatus.PARSING_FAILED} component={<Parser error={error && error.message} teamId={teamId} />} />
        <ShowIf cond={status} value={ImportStatus.VALIDATION_FAILED} component={<ValidationFailed />} />
        <ShowIf cond={status} value={ImportStatus.PARSED} component={<ValidationSucceeded />} />
        <ShowIf cond={status} value={ImportStatus.IMPORTING} component={<ImportingProgress />} />
        <ShowIf cond={status} value={ImportStatus.COMPLETE} component={<ImportComplete />} />

        <DashboardFormFooter>
          <div className="dashboard-form-footer__secondary">

            <ShowIf cond={status} value={ImportStatus.PENDING}>
              <Button type="button" color="secondary" outline onClick={handleDownloadTemplate}>
                Download CSV Template
              </Button>
            </ShowIf>

            <ShowIf cond={status} value={ImportStatus.PARSED}>
              <Button type="button" color="secondary" outline onClick={handleResetImport}>
                Cancel
              </Button>
            </ShowIf>

          </div>

          <div className="dashboard-form-footer__primary">

            <ShowIf cond={status} value={ImportStatus.PARSED}>
              <Button type="button" color="success" onClick={handleStartImport}>Begin Import</Button>
            </ShowIf>

            <ShowIf cond={status} value={ImportStatus.PARSING_FAILED}>
              <FilePicker onChange={handleFileSelection}>
                <Button type="button" color="success">Re-select File</Button>
              </FilePicker>
            </ShowIf>

            <ShowIf cond={status} value={ImportStatus.VALIDATION_FAILED}>
              <FilePicker onChange={handleFileSelection}>
                <Button type="button" color="success">Re-select File</Button>
              </FilePicker>
            </ShowIf>

            <ShowIf cond={status} value={ImportStatus.PENDING}>
              <FilePicker onChange={handleFileSelection}>
                <Button type="button" color="success">Select File</Button>
              </FilePicker>
            </ShowIf>

            <ShowIf cond={status} value={ImportStatus.COMPLETE}>
              <ShowIf cond={isSeasonImport}>
                <Link to={`/seasons/${seasonId}/roster`}><Button type="button" color="primary">Go To Season Roster</Button></Link>
              </ShowIf>
              <ShowIf cond={isTeamImport}>
                <Link to={`/seasons/${seasonId}/teams/${teamId}/roster`}><Button type="button" color="primary">Go To Team Roster</Button></Link>
              </ShowIf>
            </ShowIf>

          </div>
        </DashboardFormFooter>
      </DashboardForm>
    </>
  );
}

Wizard.propTypes = {
  seasonId: PropTypes.string.isRequired,
  teamId: PropTypes.string
};

Wizard.defaultProps = {
  teamId: null
};

export default Wizard;
