import React, { Fragment } from "react";

import CompletedGamesFilter from "@/components/CompletedGamesFilter";

import CompletedGamesList, {
  useCompletedGamesListLoader,
  useCompletedGamesListPagination
} from "@/components/CompletedGamesList";

import Pagination from "@/components/Pagination";

function CompletedGamesTab() {
  useCompletedGamesListLoader();

  const { loadPage, currentPage, totalPages, totalCount } = useCompletedGamesListPagination();

  return (
    <Fragment>
      <CompletedGamesFilter disabled={totalCount === 0} />

      <CompletedGamesList />

      <Pagination totalCount={totalCount} currentPage={currentPage} totalPages={totalPages} changePage={loadPage} />
    </Fragment>
  );
}

export default CompletedGamesTab;
