import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import GamesheetNote from "@/components/GamesheetNote";

class Note extends PureComponent {
  static propTypes = {
    type: PropTypes.string,
    note: PropTypes.shape({}),
    data: PropTypes.string
  };

  static defaultProps = {
    type: "N/A",
    data: null,
    note: {}
  };

  render() {
    const { type, note, data } = this.props;

    return (
      <tr>
        <td>{type}</td>
        <td>
          {data && (
            <Fragment>
              {data}
              <br />
            </Fragment>
          )}
          <GamesheetNote {...note} />
        </td>
        <td>Scorekeeper</td>
      </tr>
    );
  }
}

export default Note;
