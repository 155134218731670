import { handleActions } from "redux-actions";

import { registrationSetFieldAction, setInvitationCodeInfoAction, showRegistrationScreenAction } from "./actions";
import { registrationValidationRoutine, registrationRoutine } from "./routines";

import camelCase from "lodash/camelCase";

export const screens = {
  ENTER_INFO: "enter-info",
  VERIFY_TEAM_INVITATION: "verify-team-invitation",
};

const defaultState = {
  fields: {
    code: {
      value: "",
      error: null
    },
    email: {
      value: "",
      error: null
    },
    firstName: {
      value: "",
      error: null
    },
    lastName: {
      value: "",
      error: null
    },
    password: {
      value: "",
      error: null
    },
    passwordConfirmation: {
      value: "",
      error: null
    }
  },
  codeInfo: null,
  showScreen: screens.ENTER_INFO,
  error: null,
  codeIsLoading: false,
  newUserIsLoading: false,
};

const registrationSetFieldActionHandler = (state, { payload }) => {
  const [[field, value]] = Object.entries(payload);

  const fields = Object.assign({}, state.fields, {
    [field]: {
      value,
      error: null
    }
  });

  return Object.assign({}, state, { fields });
};

const registrationValidationRoutineFailureActionHandler = (state, { payload }) => {
  let { errors } = payload;
  let { fields } = state;

  for (let error of errors) {
    const [[field, title]] = Object.entries(error);
    const { value } = fields[field];
    fields = Object.assign({}, fields, { [field]: { value, error: title } });
  }

  return Object.assign({}, state, { fields });
};

const registrationRoutineTriggerActionHandler = state => {
  let { fields } = state;

  for (let [field, { value }] of Object.entries(fields)) {
    fields = Object.assign({}, fields, { [field]: { value, error: null } });
  }

  return Object.assign({}, state, { fields, error: null, newUserIsLoading: false });
};

const registrationRoutineSuccessActionHandler = state => Object.assign({}, defaultState);

const registrationRoutineFailureActionHandler = (state, { payload }) => {
  let { error = null, errors = [] } = payload;

  let { fields } = state;

  for (let {
    title,
    source: { pointer }
  } of errors) {
    const field = camelCase(pointer.match(/\/data\/attributes\/(\w+)/)[1]);
    const { value } = state.fields[field];

    fields = Object.assign({}, fields, { [field]: { value, error: title } });
  }

  return Object.assign({}, state, { error, fields, showScreen: screens.ENTER_INFO });
};

const invitationCodeInfoHandler = (state, { payload }) => {
  if (!!payload.codeInfo) {
    return {
      ...state,
      codeInfo: {
        invitation: payload.codeInfo.invitation,
        team: payload.codeInfo.teams[0] || {},
      }
    };
  } else {
    return {
      ...state,
      codeInfo: null,
    };
  }
};

export default handleActions(
  {
    [registrationSetFieldAction]: registrationSetFieldActionHandler,
    [setInvitationCodeInfoAction]: invitationCodeInfoHandler,
    [showRegistrationScreenAction]: (state, { payload }) => Object.assign({}, state, { showScreen: payload }),
    [registrationValidationRoutine.REQUEST]: state => Object.assign({}, state, { codeIsLoading: true }),
    [registrationValidationRoutine.FAILURE]: registrationValidationRoutineFailureActionHandler,
    [registrationValidationRoutine.FULFILL]: state => Object.assign({}, state, { codeIsLoading: false }),
    [registrationRoutine.TRIGGER]: registrationRoutineTriggerActionHandler,
    [registrationRoutine.REQUEST]: state => Object.assign({}, state, { newUserIsLoading: true }),
    [registrationRoutine.SUCCESS]: registrationRoutineSuccessActionHandler,
    [registrationRoutine.FAILURE]: registrationRoutineFailureActionHandler,
    [registrationRoutine.FULFILL]: state => Object.assign({}, state, { newUserIsLoading: false }),
  },
  defaultState
);
