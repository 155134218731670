import { seasonsService } from "@/services/api/seasons";
import { config } from "@/config";

export const api = ( axiosConfig={} ) => {
  let path = (config.API_BASE_URL[config.API_BASE_URL.length -1] === "/") ? config.API_BASE_URL.substr(0, config.API_BASE_URL.length -1) : config.API_BASE_URL;

  const defaultConfig = {
    headers: {
      authorization: `Bearer ${window.localStorage.getItem('accessToken')}`
    }
  };

  axiosConfig = { ...defaultConfig, ...axiosConfig };

  return {
    seasons: seasonsService( path, axiosConfig )
  }

}
