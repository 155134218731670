import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import RefereeReportGameLink from "../GameLink";

class Penalty extends PureComponent {
  static propTypes = {
    game: PropTypes.shape({ date: PropTypes.string.isRequired }).isRequired,
    code: PropTypes.string
  };

  static defaultProps = {
    code: ""
  };

  render() {
    const { game, code } = this.props;

    return (
      <tr>
        <td>{game.date}</td>
        <td>
          <RefereeReportGameLink {...game} />
        </td>
        <td>{code}</td>
      </tr>
    );
  }
}

export default Penalty;
