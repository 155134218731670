import { call, put, takeEvery, delay } from "redux-saga/effects";

import { gamesheetAPIRequest } from "@/redux/api/sagas";
import { throwError } from "@/redux/common/utils";

import {
  ResourceLoadingRoutine as loadingRoutine,
  ResourceCreatingRoutine as creatingRoutine,
  ResourceUpdatingRoutine as updatingRoutine,
  ResourceDeletingRoutine as deletingRoutine,
  ResourceReleasingRoutine as releasingRoutine
} from "./routines";

export function* makeResourceRequest(routine, service, payload) {
  yield put(routine.request(payload));

  try {
    const response = yield call(gamesheetAPIRequest, service, payload);

    yield put(routine.success({ ...payload, data: response.data }));

    return response;
  } catch (error) {
    yield put(routine.failure({ ...payload, errors: [error.message] }));
    yield call(throwError, error);
  } finally {
    yield put(routine.fulfill(payload));
  }
}

export function* loadResources(service, payload) {
  return yield call(makeResourceRequest, loadingRoutine, service, payload);
}

export const loadResource = loadResources;

export function* createResource(service, payload) {
  return yield call(makeResourceRequest, creatingRoutine, service, payload);
}

export function* updateResource(service, payload) {
  return yield call(makeResourceRequest, updatingRoutine, service, payload);
}

export function* deleteResource(service, payload) {
  return yield call(makeResourceRequest, deletingRoutine, service, payload);
}

export function* resourceReleasingSaga({ payload }) {
  const { identity } = payload;

  yield delay(500);
  yield put(releasingRoutine.trigger({ identity }));
}

export function* resourcesFlow() {
  yield takeEvery(deletingRoutine.SUCCESS, resourceReleasingSaga);
}
