import React from "react";
import { FormGroup, Button, Row, Col, CustomInput } from "reactstrap";
import { useHistory } from "react-router-dom";

import TitleBar from "@/components/TitleBar";
import FormSection from "@/components/FormSection";

import ContentSection from "@/components/ContentSection";

import "./SeasonSport.scss";

export const SeasonSport = ({ sport, setSport, sportOptions, goToSettings, leagueId }) => {
  const history = useHistory();

  const sortedOptions = sportOptions
    .map(({ key, title }) => ({
      title,
      value: key,
    }))
    .sort((a, b) => {
      const sorted = ["Box Lacrosse", "Field Lacrosse", "Hockey", "Soccer"];
      return sorted.indexOf(a.title) - sorted.indexOf(b.title);
    });

  return (
    <ContentSection className="season-sport">
      <FormSection>
        <Row>
          <Col>
            <FormGroup>
              <TitleBar
                slim
                title="Please select a sport for this season"
                titleTag="h4"
              />
              <p className="season-sport__description">
                This cannot be changed after the season is created
              </p>

              {sortedOptions.map(({ title, value }) => (
                <CustomInput
                  className="point-system__option"
                  type="radio"
                  id={value}
                  key={value}
                  name={value}
                  label={title}
                  onChange={() => setSport(value)}
                  checked={sport === value}
                />
              ))}
            </FormGroup>
          </Col>
        </Row>
        <div className="dashboard-form-footer__primary">
          <Button
            color="secondary"
            size="sm"
            outline
            onClick={() => history.push(`/leagues/${leagueId}/seasons`)}
            disabled={false}
          >
            Cancel
          </Button>
          <Button
            color="success"
            size="sm"
            outline
            onClick={() => goToSettings()}
            disabled={sport == ""}
          >
            Next
          </Button>
        </div>
      </FormSection>
    </ContentSection>
  );
};
