import { useSelector, useDispatch } from "react-redux";

import { getGameSeasonId, getSearchCurrentQuery } from "@/redux/gameForm/selectors";

import { seasonPlayersLoadingRoutine } from "@/redux/gameForm/routines";

import actions from "@/redux/gameForm/actions";

export default function useSearchBar() {
  const value = useSelector(state => getSearchCurrentQuery(state));

  const dispatch = useDispatch();
  const seasonId = useSelector(state => getGameSeasonId(state));
  const onChange = e => {
    dispatch(actions.setSearchPlayer({ value: e.target.value }));
    dispatch(seasonPlayersLoadingRoutine.trigger({ seasonId, append: false }));
  };

  return {
    value,
    onChange
  };
}
