export const POINT_SYSTEMS = [
  {
    title: "2-point",
    label: "2-point games",
    description: "2 points for a win. 1 point for a tie. 0 points for a loss.",
    enabled: true
  },
  {
    title: "ot-point",
    label: "Point for OT/SO",
    description: "2 points for a win. 1 point for a loss in OT/SO. 0 points for a loss in regulation.",
    enabled: true
  },
  {
    title: "3-point",
    label: "3-point games",
    description:
      "3 points for a win in regulation. 2 points for a win in OT/SO. 1 point for a loss in OT/SO. 0 points for a loss in regulation.",
    enabled: true
  },
  {
    title: "period-points",
    label: "Point-Per-Period",
    description: "2 points for each period won. 1 point for each period tied. 4 points for winning the game.",
    enabled: true
  },
  {
    title: "custom",
    label: "Custom…",
    description: "Define your own point system by setting values for wins, losses, and ties by period and/or the game.",
    enabled: true
  }
];
