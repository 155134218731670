import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { registrationSetFieldAction } from "@/redux/registration/actions";

export const useUrlInvitationCode = () => {
    const dispatch = useDispatch();
    const urlParams = new URLSearchParams(window.location.search);
    const invitation = urlParams.get("invitation");

    useEffect(() => {
        if (!invitation) return;

        dispatch(registrationSetFieldAction({
            code: invitation,
        }));
    }, [invitation]);
};