import { useRecoilState, useSetRecoilState } from "recoil";
import { useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { useEffect } from "react";

import { actions } from "@/redux/scheduledGamesFilter";
import { startDateFilterState, endDateFilterState, divisionsFilterState, queryStringFilterState, gameTypeFilterState } from "./state";
import { selectedIdsState } from "../List/state";

export function useFilter({ seasonId }) {
  const dispatch = useDispatch();

  const [ startDate, rawSetStartDate ] = useRecoilState(startDateFilterState);
  const [ endDate, rawSetEndDate ] = useRecoilState(endDateFilterState);
  const [ divisions, rawSetDivisions ] = useRecoilState(divisionsFilterState);
  const [ queryString, rawSetQueryString ] = useRecoilState(queryStringFilterState);
  const [ gameType, rawSetGameType ] = useRecoilState(gameTypeFilterState)
  const setSelectedIds = useSetRecoilState(selectedIdsState);

  const history = useHistory();

  const clearThen = (fn) => (...args) => {
    setSelectedIds([]);
    fn(...args);
  }

  const updateUrl = (name, val) => {
    const searchParams = new URLSearchParams(location.search);
    if (!val || val.length <= 0) {
      searchParams.delete(name);
    } else {
      searchParams.set(name, val);
    }
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString()
    });
  }

  const setStartDate = (val) => {
    updateUrl('startDate', val);
    clearThen(rawSetStartDate(val));
  }
  const setEndDate = (val) => {
    updateUrl('endDate', val);
    clearThen(rawSetEndDate(val));
  }
  const setDivisions = (val) => {
    updateUrl('divisions', val);
    clearThen(rawSetDivisions(val));
  }
  const setQueryString = (val) => {
    updateUrl('queryString', val);
    clearThen(rawSetQueryString(val));
  }

  const setGameType = (val) => {
    updateUrl('gameType', val);
    clearThen(rawSetGameType(val));
  }

  const reset = () => {
    setStartDate('');
    setEndDate('');
    setDivisions([]);
    setQueryString('');
    setGameType('')
  };

  useEffect(() => {  
    const urlSearchParams = location.search;
    return () => {
      // save info to redux
      dispatch(actions.setUrlSearchParams({ urlSearchParams, seasonId }));
    }
  }, [dispatch, location.search, seasonId]);

  const canReset = 
    startDate != '' ||
    endDate != '' ||
    divisions.length ||
    queryString != '' || 
    gameType != '';

  return {
    startDate,
    endDate,
    divisions,
    queryString,
    gameType,
    setStartDate,
    setEndDate,
    setDivisions,
    setQueryString,
    setGameType,
    canReset,
    isFiltered: !!canReset,
    reset
  };
}
