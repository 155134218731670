import React from "react";
import PropTypes from "prop-types";

import { Button, FormGroup, Input } from "reactstrap";

import FormItem from "@/components/FormItem";

import TeamPlayerInput from "../TeamPlayerInput";
import TeamGoalieInput from "../TeamGoalieInput";

import { useShootoutChange, useShootoutRemove } from "./hooks";

function Shootout(props) {
  const { team, number, shooterId, goalieId, result, index } = props;

  const changeShootout = useShootoutChange({ team, index });
  const removeShootout = useShootoutRemove({ team, index });

  return (
    <FormItem data-number={number} data-shooter-id={shooterId}>
      <FormGroup className="col-md-2">
        <Input type="number" min="1" value={number} name="number" onChange={changeShootout} />
      </FormGroup>

      <FormGroup className="col-md-3">
        <TeamPlayerInput value={shooterId} team={team} name="shooterId" onChange={changeShootout} />
      </FormGroup>

      <FormGroup className="col-md-3">
        <TeamGoalieInput
          allowBlank={false}
          blankLabel=""
          value={goalieId}
          team={team === "visitor" ? "home" : "visitor"}
          name="goalieId"
          onChange={changeShootout}
        />
      </FormGroup>

      <FormGroup className="col-md-2">
        <Input type="select" team={team} name="result" value={result} onChange={changeShootout}>
          <option value="">{""}</option>
          <option value="miss">Miss</option>
          <option value="save">Save</option>
          <option value="goal">Goal</option>
        </Input>
      </FormGroup>

      <FormGroup className="col-md-2 removal-btn-group">
        <Button outline color="danger" onClick={removeShootout}>
          &times;
        </Button>
      </FormGroup>
    </FormItem>
  );
}

Shootout.propTypes = {
  team: PropTypes.oneOf(["home", "visitor"]).isRequired,
  index: PropTypes.string.isRequired,
  number: PropTypes.number,
  shooterId: PropTypes.string,
  goalieId: PropTypes.string,
  result: PropTypes.string
};

export default Shootout;
