import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import { refereesActions } from "@/redux/referees/actions";

import RefereesNew from "./RefereesNew";

function RefereesNewContainer({ seasonId }) {
  const dispatch = useDispatch();

  const { season, seasonIsLoaded, seasonIsLoading, seasonIsForbidden, seasonBreadcrumbs } = useCurrentSeasonContext();

  const clearForm = useCallback(() => dispatch(refereesActions.form.clear()), [dispatch]);

  return (
    <RefereesNew
      seasonId={seasonId}
      season={season}
      seasonIsLoaded={seasonIsLoaded}
      seasonIsLoading={seasonIsLoading}
      seasonIsForbidden={seasonIsForbidden}
      seasonBreadcrumbs={seasonBreadcrumbs}
      clearForm={clearForm}
    />
  );
}

RefereesNewContainer.propTypes = {
  seasonId: PropTypes.string.isRequired
};

export default RefereesNewContainer;
