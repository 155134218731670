import React from "react";
import PenaltyAccumulationReport from "./PenaltyAccumulationReport";
import { RecoilRoot } from "recoil";
import PageLoader from "@/components/PageLoader";

function PenaltyAccumulationReportContainer(props) {
  return (
    <RecoilRoot>
      <React.Suspense fallback={<PageLoader isLoading />}>
        <PenaltyAccumulationReport {...props} />
      </React.Suspense>
    </RecoilRoot>
  )
}

export default PenaltyAccumulationReportContainer;