import { all, put, takeLatest } from "redux-saga/effects";

import { loadDivisions } from "@/lib/api/divisions";
import { fetchList } from "@/redux/api/sagas";

import { loadingRoutine } from "./routines";

function* loadingSaga({ payload: { seasonId } }) {
  yield put(loadingRoutine.request());

  try {
    const { divisions } = yield fetchList("divisions", loadDivisions, {
      seasonId
    });

    yield put(loadingRoutine.success(divisions));
  } catch (error) {
    yield put(loadingRoutine.failure({ error }));
  } finally {
    yield put(loadingRoutine.fulfill());
  }
}

export function* divisionDropdownFlow() {
  yield all([takeLatest(loadingRoutine.TRIGGER, loadingSaga)]);
}
