import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Row, Col, Table } from "reactstrap";

import Note from "./components/Note";

class GameNotes extends PureComponent {
  static propTypes = {
    notes: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string }))
  };

  static defaultProps = {
    notes: []
  };

  render() {
    const { notes } = this.props;

    if (notes.length === 0) {
      return null;
    }

    return (
      <Row>
        <Col>
          <Table striped borderless size="sm" className="notes-table">
            <thead>
              <tr>
                <th scope="colgroup">Game Notes</th>
                <th scope="colgroup" colSpan="2" />
              </tr>
              <tr>
                <th scope="col">Type</th>
                <th scope="col">Note</th>
                <th scope="col">By</th>
              </tr>
            </thead>
            <tbody>
              {notes.map(({ id, ...note }) => (
                <Note key={id} {...note} />
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  }
}

export default GameNotes;
