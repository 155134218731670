import { createActions } from "redux-actions";

export const { referees: refereesActions } = createActions({
  REFEREES: {
    LIST: {
      CLEAR: undefined,
      REMOVE: undefined,
      SEARCH: {
        SET_NEXT_QUERY: undefined,
        COMMIT: undefined,
        CLEAR: undefined
      },
      MERGE_TOOL: {
        ENABLE: undefined,
        DISABLE: undefined,
        SELECT_REFEREES: undefined,
        CHOOSE_WINNER: undefined,
        SET_STATUS: undefined
      }
    },
    FORM: {
      CLEAR: undefined,
      CHANGE_FIELD: undefined
    },
    CURRENT: {
      CLEAR: undefined
    }
  }
});
