import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import Spinner from "@/components/Spinner";
import { useDispatch } from "react-redux";
import Option from "./Option";
import actions from "@/redux/roleForm/actions";
import "./List.scss";

function ResourceSelect(props) {
  const { isLoading, isLoaded, options, label, isEnabled = false, toggleSelected, resourceType, isOptionSelected, isAllOptionsSelected } = props;
  const dispatch = useDispatch();

  const selectAll = () => {
    dispatch(actions[resourceType].selectAll()); 
  };

  const clear = () => {
    dispatch(actions[resourceType].clear());
  
    // Cascading clears based on the resource type
    switch (resourceType) {
      case "associationList":
        dispatch(actions.leagueList.clear());
        dispatch(actions.seasonList.clear());
        dispatch(actions.divisionList.clear());
        dispatch(actions.teamList.clear());
        break;
  
      case "leagueList":
        dispatch(actions.seasonList.clear());
        dispatch(actions.divisionList.clear());
        dispatch(actions.teamList.clear());
        break;
  
      case "seasonList":
        dispatch(actions.divisionList.clear());
        dispatch(actions.teamList.clear());
        break;
  
      case "divisionList":
        dispatch(actions.teamList.clear());
        break;
  
      default:
        break;
    }
  };  

  const renderGroup = (group, index) => (
    <div
      key={`${resourceType}-${group.groupTitle}-${index}`}
      className={`role-form-resource-select-list__group ${
        resourceType === "associationList" ? "role-form-resource-select-list__group--no-title" : ""
      }`}
    >
      {resourceType !== "associationList" && (
        <p className="role-form-resource-select-list__group__title list-group-item list-group-item-light">
          {group.groupTitle}
        </p>
      )}
      {group.items.map(({ id, title, isSelected }) => (
        <Option
          key={id}
          id={id}
          title={title}
          isSelected={isSelected}
          toggleSelected={isEnabled ? toggleSelected : () => {}}
        />
      ))}
    </div>
  );  
  
  return (
    <div className={`role-form-resource-select-list ${isEnabled ? "" : "disabled"}`}>
      <p className="role-form-resource-select-list__label">{label}</p>

      <div className="role-form-resource-select-list__input list-group list-group-flush">
        {isLoading ? (
          <Spinner className="role-form-resource-select-list__spinner" theme="loader" color="yellow" size="xxl" />
        ) : isLoaded ? (
          options.map(renderGroup)
        ) : (
          <p className="role-form-resource-select-list__empty-list-message">{"No items selected"}</p>
        )}
      </div>

      <div className="role-form-resource-select-list__controls">
        {isOptionSelected && (
          <Button
            className="role-form-resource-select-list__controls__left-control"
            color="link"
            size="sm"
            onClick={clear}
          >
            Clear Selection
          </Button>
        )}
        {isLoaded && 
          options.some(group => group.items.length > 0) &&
          isAllOptionsSelected == false && (
            <Button
              className="role-form-resource-select-list__controls__right-control"
              color="link"
              size="sm"
              onClick={selectAll}
            >
              Select All
            </Button>
        )}
        </div>
    </div>
  );
}

ResourceSelect.propTypes = {
  isLoading: PropTypes.bool,
  isLoaded: PropTypes.bool,
  isOptionSelected: PropTypes.bool,
  isAllOptionsSelected: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      groupTitle: PropTypes.string.isRequired, // Title for each group
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          isSelected: PropTypes.bool.isRequired,
        })
      ).isRequired,
    })
  ),
  label: PropTypes.string,
  toggleSelected: PropTypes.func,
  resourceType: PropTypes.string.isRequired
};

ResourceSelect.defaultProps = {
  isLoading: false,
  isLoaded: false,
  isOptionSelected: false,
  isAllOptionsSelected: false,
  options: [],
  label: "Select options",
  toggleSelected() {}
};

export default ResourceSelect;
