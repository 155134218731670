import default_validations from "./validations";
import { makePositionValidator } from "./validations/validatePosition";

function validateRecord(record, validationOptions, positionValidator) {
  const validations = [ ...default_validations ];
  validations.push(positionValidator);

  return validations.reduce((allErrors, validation) => {
    const errors = validation(record, validationOptions);
    return errors.length > 0 ? [...allErrors, ...errors] : allErrors;
  }, []);
}

export default function validate(records, validationOptions, playerPositions, coachPositions) {
  const positionValidator = makePositionValidator(playerPositions, coachPositions);

  return records.map(record => ({
    ...record,
    validationErrors: validateRecord(record, validationOptions, positionValidator)
  }));
}
