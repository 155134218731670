import { put, call, select } from "redux-saga/effects";
import build from "redux-object";

import { gamesheetAPIRequest } from "@/redux/api/sagas";

import { getSearchCurrentQuery, getPaginationOffset, getPaginationLimit } from "../selectors";

export default function createMembersLoadingSaga(options) {
  const { routine, apiService, type } = options;

  return function*({ payload: { seasonId, append } }) {
    const query = yield select(getSearchCurrentQuery);

    const limit = yield select(getPaginationLimit);
    const offset = append ? yield select(getPaginationOffset) : 0;

    yield put(routine.request({ query, append }));

    const { data, rawData, meta } = yield call(gamesheetAPIRequest, apiService, {
      include: "teams",
      seasonId,
      limit,
      offset,
      query
    });

    const members = build(data, type, null, { includeType: true }) || [];

    yield put(
      routine.success({
        ids: rawData.map(({ id }) => id),
        totalCount: meta ? meta["total-count"] || 0 : 0,
        filteredCount: meta ? meta["filtered-count"] || 0 : 0,
        members,
        query,
        append
      })
    );

    yield put(routine.fulfill({ query, append }));
  };
}
