import React, { Fragment, useEffect } from "react";
import { Input, FormFeedback } from "reactstrap";

import {
  getUserTimeZone,
  getTimezoneOptions,
  formatOffset,
  getOffset,
} from "@/utils/timezones";

function TimeZoneInput(props) {
  const { id, value, setValue, errors, isDirty, date, time, setOffset } = props;

  const [options, setOptions] = React.useState([]);

  useEffect(() => {
    const options = getTimezoneOptions();
    setOptions(options.map(tz => ({
      label: tz,
      value: tz,
      offset: null,
    })));

    if (!value) {
      const userTimeZone = getUserTimeZone();
      setValue(userTimeZone);
    }
  }, []);

  useEffect(() => {
    if (!value) return;
    if (!date.value) return;
    if (!time.value) return;
    
    const offset = getOffset(value, date.value, time.value);

    if (offset === null) {
      if (date.isDirty || time.isDirty) {
        value !== "" && setValue("");
      } else {
        return;
      }
    }

    setOffset(offset);

    setOptions((prev) => {
      return prev.map((option) => {
        if (option.value === value) {
          if (option.offset !== offset) {
            return {
              ...option,
              label: `${value} (${formatOffset(offset)})`,
              offset: offset,
            };
          }
        }
        return option;
      });
    });
  }, [value, date.value, time.value]);

  return (
    <Fragment>
      <Input
        type="select"
        id={id}
        value={value || ""}
        onChange={(e) => setValue(e.target.value)}
        className={"form-control" + (isDirty && !value ? " is-invalid" : "")}
      >
        <option value="">Select a timezone</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Input>

      {isDirty && errors.map(error => <FormFeedback key={error}>{error}</FormFeedback>)}
    </Fragment>
  );
}

export default TimeZoneInput;
