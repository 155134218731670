import { useRecoilState } from 'recoil';

import { ImportStatus } from "@/components-16.8/RefereeImportWizard/types/importStatus";
import { readCsvFile } from "@/components-16.8/RefereeImportWizard/parser/read";
import { validateCSVRecords } from "@/components-16.8/RefereeImportWizard/parser/validate";

import {
    importStatusState,
    recordsState,
    readingErrorState,
    badRecordsState
} from "@/components-16.8/RefereeImportWizard/state";

export function useFileSelection() {

    const [importStatus, setImportStatus] = useRecoilState(importStatusState);
    const [readingError, setReadingError] = useRecoilState(readingErrorState);
    const [badRecords, setBadRecords] = useRecoilState(badRecordsState);
    const [records, setRecords] = useRecoilState(recordsState);

    async function handleFileSelection(file) {
        setReadingError(null);
        setBadRecords([]);
        setImportStatus(ImportStatus.PARSING);

        let records = [];

        try {
            records = await readCsvFile(file);
        } catch (e) {
            setReadingError(e);
            setImportStatus(ImportStatus.PENDING);
            return;
        }

        const badRecords = validateCSVRecords(records);
        if (badRecords.length) {
            setBadRecords(badRecords);
            setImportStatus(ImportStatus.PARSE_ERROR);
            return;
        }

        setRecords(records);
        setImportStatus(ImportStatus.PARSE_SUCCESS);
    }

    return {
        handleFileSelection
    }
}