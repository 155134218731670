import { handleActions } from "redux-actions";

import { submittingRoutine } from "../routines";

import actions from "../actions";

const initialState = {
  isSubmitting: false
};

function reduceSubmittingRequest(state) {
  return { ...state, isSubmitting: true };
}

function reduceSubmittingFulfill(state) {
  return { ...state, isSubmitting: false };
}

export default handleActions(
  {
    [submittingRoutine.REQUEST]: reduceSubmittingRequest,
    [submittingRoutine.FULFILL]: reduceSubmittingFulfill,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
