import { connect } from "react-redux";

import RefereeForm from "./Form";

import {
  getRefereeFormIsOperating,
  getRefereeFormIsDeleting,
  getRefereeFormFields,
  getRefereeFormIsInvalid,
  getRefereeFormIsPristine,
  getRefereeFormIsNotFilled,
  getRefereeFormRefereeName
} from "@/redux/referees/selectors";

import { refereesActions } from "@/redux/referees/actions";

import { refereeFormSubmittingRoutine, refereeFormDeletingRoutine } from "@/redux/referees/routines";

const mapStateToProps = state => ({
  isOperating: getRefereeFormIsOperating(state),
  isDeleting: getRefereeFormIsDeleting(state),
  isInvalid: getRefereeFormIsInvalid(state),
  isPristine: getRefereeFormIsPristine(state),
  isNotFilled: getRefereeFormIsNotFilled(state),
  fields: getRefereeFormFields(state),
  refereeName: getRefereeFormRefereeName(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeField: options => dispatch(refereesActions.form.changeField(options)),
  submitForm: ({ seasonId }) =>
    dispatch(
      refereeFormSubmittingRoutine({
        id: ownProps.id,
        seasonId
      })
    ),
  deleteReferee: () =>
    dispatch(
      refereeFormDeletingRoutine({
        id: ownProps.id,
        seasonId: ownProps.seasonId
      })
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RefereeForm);
