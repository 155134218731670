import React, { useCallback } from "react";
import PropTypes from "prop-types";

import PointsInput from "./PointsInput";

function PeriodValues({ value, onChange }) {
  const changePeriodValue = useCallback(
    ({ period, name, points }) =>
      onChange({
        ...value,
        [period]: {
          ...value[period],
          [name]: points
        }
      }),
    [onChange, value]
  );

  const changeFirstPeriod = useCallback(({ name, value: points }) => changePeriodValue({ period: "1", name, points }), [
    changePeriodValue
  ]);

  const changeSecondPeriod = useCallback(
    ({ name, value: points }) => changePeriodValue({ period: "2", name, points }),
    [changePeriodValue]
  );

  const changeThirdPeriod = useCallback(({ name, value: points }) => changePeriodValue({ period: "3", name, points }), [
    changePeriodValue
  ]);

  return (
    <table className="point-system__table">
      <thead>
        <tr>
          <th />
          <th>Win</th>
          <th>Tie</th>
          <th>Loss</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>1st period:</th>
          <td>
            <PointsInput name="win" value={value["1"].win} onChange={changeFirstPeriod} />
          </td>
          <td>
            <PointsInput name="tie" value={value["1"].tie} onChange={changeFirstPeriod} />
          </td>
          <td>
            <PointsInput name="loss" value={value["1"].loss} onChange={changeFirstPeriod} />
          </td>
        </tr>

        <tr>
          <th>2nd period:</th>
          <td>
            <PointsInput name="win" value={value["2"].win} onChange={changeSecondPeriod} />
          </td>
          <td>
            <PointsInput name="tie" value={value["2"].tie} onChange={changeSecondPeriod} />
          </td>
          <td>
            <PointsInput name="loss" value={value["2"].loss} onChange={changeSecondPeriod} />
          </td>
        </tr>

        <tr>
          <th>3rd period:</th>
          <td>
            <PointsInput name="win" value={value["3"].win} onChange={changeThirdPeriod} />
          </td>
          <td>
            <PointsInput name="tie" value={value["3"].tie} onChange={changeThirdPeriod} />
          </td>
          <td>
            <PointsInput name="loss" value={value["3"].loss} onChange={changeThirdPeriod} />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

PeriodValues.propTypes = {
  value: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired
};

export default PeriodValues;
