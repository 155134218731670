import React from "react";
import PropTypes from "prop-types";

import useSelectedResourcesCount from "./hooks/useSelectedResourcesCount";  

function SelectedResourcesCount({ singleSelect }) {
  const { text, count } = useSelectedResourcesCount();

  if (singleSelect || count === 0) {
    return null;
  }

  return <p className="dashboard-form-footer__text">{text} selected</p>;
}

SelectedResourcesCount.propTypes = {
  singleSelect: PropTypes.bool
};

SelectedResourcesCount.defaultProps = {
  singleSelect: false
};

export default SelectedResourcesCount;
