import React, { Fragment } from "react";
import PropTypes from "prop-types";

import TitleBar from "@/components/TitleBar";
import FormItem from "@/components/FormItem";

import PeriodLengthField from "./PeriodLengthField";

import { usePeriodLengthsFields } from "../hooks";

function PeriodLengths({ value, onChange }) {
  const fields = usePeriodLengthsFields({ value, onChange });

  return (
    <Fragment>
      <TitleBar slim title="Period Length" titleTag="h4" />

      <FormItem>
        <PeriodLengthField label="1st period (min)" {...fields.firstPeriod} />
        <div className="w-100" />
        <PeriodLengthField label="2nd period (min)" {...fields.secondPeriod} />
        <div className="w-100" />
        <PeriodLengthField label="3rd period (min)" {...fields.thirdPeriod} />
        <div className="w-100" />
        <PeriodLengthField label="4th period (min)" {...fields.fourthPeriod} />
        <div className="w-100" />
        <PeriodLengthField label="Overtime (min)" {...fields.overtimePeriod} />
        <div className="w-100" />
      </FormItem>
    </Fragment>
  );
}

PeriodLengths.propTypes = {
  value: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired
};

export default PeriodLengths;
