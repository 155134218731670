import { combineReducers } from "redux";

import formReducer from "./reducers/form";
import newReducer from "./reducers/new";
import listReducer from "./reducers/list";

export default combineReducers({
  form: formReducer,
  new: newReducer,
  list: listReducer
});
