import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getMeta, getDivision, loadingRoutine } from "@/redux/currentDivision";

export default function useCurrentDivision({ seasonId, divisionId }) {
  const dispatch = useDispatch();

  const {
    isLoaded: divisionIsLoaded,
    isLoading: divisionIsLoading,
    isForbidden: divisionIsForbidden,
    isFailed: divisionIsFailed
  } = useSelector(getMeta);

  const division = useSelector(getDivision);
  const association = division.association;
  const league = division.league;
  const season = division.season;
  const associationId = association.id;
  const leagueId = league.id;

  const { title, externalId, settings, usingSeasonSettings } = division;

  const divisionFormFields = {
    ...settings,
    title,
    externalId,
    usingSeasonSettings
  };

  const divisionBreadcrumbs = divisionIsLoaded
    ? [
        { title: "Associations", url: "/associations" },
        {
          title: association.title,
          url: `/associations/${associationId}/leagues`
        },
        {
          title: league.title,
          url: `/leagues/${leagueId}/seasons`
        },
        {
          title: season.title,
          url: `/seasons/${seasonId}`
        },
        {
          title: division.title,
          url: `/seasons/${seasonId}/divisions/${divisionId}/teams`
        }
      ]
    : [];

  useEffect(
    () => {
      if (!divisionIsFailed && !divisionIsLoaded && !divisionIsLoading) {
        dispatch(loadingRoutine({ seasonId, divisionId }));
      }
    },
    [dispatch, seasonId, divisionId, divisionIsFailed, divisionIsLoaded, divisionIsLoading]
  );

  return {
    division,
    divisionId,
    divisionIsLoading,
    divisionIsLoaded,
    divisionIsForbidden,
    divisionIsFailed,
    divisionBreadcrumbs,
    divisionFormFields,
    divisionAssociationId: associationId,
    divisionLeagueId: leagueId,
    divisionSeasonId: seasonId
  };
}
