import { handleActions } from "redux-actions";

import {
  openSeasonIpadKeysManager,
  closeSeasonIpadKeysManager,
  setSeasonIpadKeysManagerFilter,
  toggleSeasonIpadKeysManagerKeySelected
} from "./actions";

import { seasonIpadKeysManagerKeysLoadingRoutine, ipadKeysManagerAddingSeasonToKeysListRoutine } from "./routines";

const initialState = {
  isOpen: false,
  keys: {
    ids: [],
    data: {}
  },
  seasonId: null,
  seasonTitle: null,
  filter: "",
  isWorking: false,
  isLoading: false,
  isLoaded: false
};

function updateKey(state, id, attrs) {
  return {
    ...state,
    keys: {
      ...state.keys,
      data: {
        ...state.keys.data,
        [id]: { ...state.keys.data[id], ...attrs }
      }
    }
  };
}

function reduceOpen(state, { payload }) {
  const { associationId, leagueId, seasonId, seasonTitle } = payload;

  return {
    ...state,
    associationId,
    leagueId,
    seasonId,
    seasonTitle,
    isOpen: true
  };
}

function reduceClose() {
  return { ...initialState };
}

function reduceKeysLoadingRequest(state) {
  return { ...state, isLoading: true };
}

function reduceKeysLoadingSuccess(state, { payload: { keys } }) {
  const ids = keys.map(({ id }) => id);
  const data = keys.reduce(
    (result, { id, value, description, roles, liveScoringScopes }) => ({
      ...result,
      [id]: {
        isSelected: false,
        value,
        description,
        liveScoringScopes,
        roles: roles || [],
        seasonIds: (roles || [])
          .filter(({ title, level }) => title === "app" && level.type === "seasons")
          .map(({ level: { id } }) => id)
      }
    }),
    {}
  );

  return { ...state, isLoaded: true, keys: { ids, data } };
}

function reduceKeysLoadingFailure(state) {
  return { ...state, isLoaded: false };
}

function reduceKeysLoadingFulfill(state) {
  return { ...state, isLoading: false };
}

function reduceFiltering(state, { payload: filter }) {
  return { ...state, filter: filter };
}

function reduceSelectionToggling(state, { payload: { keyId } }) {
  return updateKey(state, keyId, {
    isSelected: !state.keys.data[keyId].isSelected
  });
}

function reduceAddingSeasonToKeysListRequest(state) {
  return { ...state, isWorking: true };
}

function reduceAddingSeasonToKeysListRequestFulfill(state) {
  return { ...state, isWorking: false };
}

export default handleActions(
  {
    [openSeasonIpadKeysManager]: reduceOpen,
    [closeSeasonIpadKeysManager]: reduceClose,
    [seasonIpadKeysManagerKeysLoadingRoutine.REQUEST]: reduceKeysLoadingRequest,
    [seasonIpadKeysManagerKeysLoadingRoutine.SUCCESS]: reduceKeysLoadingSuccess,
    [seasonIpadKeysManagerKeysLoadingRoutine.FAILURE]: reduceKeysLoadingFailure,
    [seasonIpadKeysManagerKeysLoadingRoutine.FULFILL]: reduceKeysLoadingFulfill,
    [setSeasonIpadKeysManagerFilter]: reduceFiltering,
    [toggleSeasonIpadKeysManagerKeySelected]: reduceSelectionToggling,
    [ipadKeysManagerAddingSeasonToKeysListRoutine.REQUEST]: reduceAddingSeasonToKeysListRequest,
    [ipadKeysManagerAddingSeasonToKeysListRoutine.SUCCESS]: reduceClose,
    [ipadKeysManagerAddingSeasonToKeysListRoutine.FULFILL]: reduceAddingSeasonToKeysListRequestFulfill
  },
  initialState
);
