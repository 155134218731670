import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  actions,
  coachSubmittingRoutine,
  getCoachFormIsInvalid,
  getCoachFormIsNotFilled,
  getCoachFormIsPristine,
  getCoachFormIsSaving,
  getCoachFormIsDeleting
} from "@/redux/teamRoster";

export default function useCoachForm(coachId) {
  const dispatch = useDispatch();

  const triggerReset = useCallback(() => dispatch(actions.coachesForm.reset({ memberId: coachId })), [
    dispatch,
    coachId
  ]);

  const triggerEdit = useCallback(() => dispatch(actions.coachesForm.edit({ memberId: coachId })), [dispatch, coachId]);

  const triggerSave = useCallback(() => dispatch(coachSubmittingRoutine.trigger({ memberId: coachId })), [
    dispatch,
    coachId
  ]);

  const isInvalid = useSelector(state => getCoachFormIsInvalid(state, coachId), [coachId]);

  const isNotFilled = useSelector(state => getCoachFormIsNotFilled(state, coachId), [coachId]);

  const isPristine = useSelector(state => getCoachFormIsPristine(state, coachId), [coachId]);

  const isSaving = useSelector(state => getCoachFormIsSaving(state, coachId), [coachId]);

  const isDeleting = useSelector(state => getCoachFormIsDeleting(state, coachId), [coachId]);

  return {
    triggerReset,
    triggerEdit,
    triggerSave,
    isInvalid,
    isNotFilled,
    isPristine,
    isSaving,
    isDeleting
  };
}
