import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { forceListUpdateState } from './components/List/state';
import { startDateFilterState, endDateFilterState, divisionsFilterState, queryStringFilterState, gameTypeFilterState } from "./components/Filter/state";

export function useScheduledGames({ season }) {
  const setForceListUpdate = useSetRecoilState(forceListUpdateState);

  const seasonId = season ? season.id : 0;
  const seasonTitle = season ? season.title : '';

  populateFilter();

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setForceListUpdate(Math.random());
    }
    
    return () => { mounted = false; }
  }, []);

  return {
    seasonId,
    seasonTitle
  };
}

const populateFilter = () => {
  const setStartDate = useSetRecoilState(startDateFilterState);
  const setEndDate = useSetRecoilState(endDateFilterState);
  const setDivisions = useSetRecoilState(divisionsFilterState);
  const setQueryString = useSetRecoilState(queryStringFilterState);
  const setGameType = useSetRecoilState(gameTypeFilterState)

  const params = new URLSearchParams(location.search);

  const startDate = params.get("startDate");
  const endDate = params.get("endDate");
  const divisions = params.get("divisions");
  const queryString = params.get("queryString");
  const gameType = params.get("gameType");

  if (startDate && startDate.length > 0) {
    setStartDate(startDate)
  }
  if (endDate && endDate.length > 0) {
    setEndDate(endDate);
  }
  if (divisions && divisions.length > 0) {
    setDivisions(divisions.split(","));
  }
  if (queryString && queryString.length > 0) {
    setQueryString(queryString);
  }
  if (gameType && gameType.length > 0) {
    setGameType(gameType);
  }
}
