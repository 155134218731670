const selectRoot = state => state.ability;
const selectResources = state => selectRoot(state).resources;

export const getAbilityRules = state => selectRoot(state).rules;

export const getTeamResources = state => selectResources(state).teams;

export const getAbilityResourcesListIsPrefetched = state => selectResources(state).isListPrefetched;

export const getAbilityResourcesListIsPrefetching = state => selectResources(state).isListPrefetching;

export const getIgnoreTeamLock = state => selectRoot(state).ignoreTeamLock;
