import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { playerRemovingRoutine, coachRemovingRoutine } from "@/redux/teamRoster";
import { getIgnoreTeamLock } from "@/redux/ability/selectors";
import useMemberEditTrigger from "./useMemberEditTrigger";

export default function useActionsDropdown({ type, id }) {
  const ignoreLock = useSelector(getIgnoreTeamLock);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpened = useCallback(() => setIsOpen(!isOpen), [setIsOpen, isOpen]);

  const triggerDelete = useCallback(
    () => {
      const routine = type === "players" ? playerRemovingRoutine : coachRemovingRoutine;

      dispatch(routine({ memberId: id }));
    },
    [dispatch, type, id]
  );

  const { triggerEdit } = useMemberEditTrigger({ member: { type, id } });

  return { isOpen, toggleIsOpened, triggerDelete, triggerEdit, ignoreLock };
}
