import React, { Component, Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import _trimEnd from "lodash/trimEnd";
import { Can, canCheck } from "@/components/Ability";
import { Nav, NavItem, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import moment from "moment";

import AbilityButton from "@/components/AbilityButton";
import RefereesListSearchBar from "./SearchBar";
import RefereesListMergeTool from "./MergeTool";
import RefereesListMergeToolFooter from "./MergeTool/Footer";
import TotalCount from "../TotalCount";
import ActionsDropdown from "./ActionDropdown";
import Spinner from "@/components/Spinner";

import "./RefereesList.scss";

const PAGE_SIZE = 25;

function RefereesList(props) {
  const [deletedReferees, setDeletedReferees] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const isMobile = window.innerWidth < 780 ? true : false;

  const now = new moment();

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(prevState => !prevState);

  const {
    filteredCount,
    seasonId,
    mergeToolStatus,
    enableMergeTool,
    isMergeToolEnabled,
    selectForMerge,
    disableMergeTool,
    removeReferee,
    totalCount,
    isLoading
  } = props;

  const referees = props.referees
    .filter(referee => !deletedReferees.includes(referee.id))
    .sort((a, b) => a.lastName.localeCompare(b.lastName));

  const onGridReady = params => {
    setGridApi(params.api);
  };

  const onDelete = id => {
    setDeletedReferees([...deletedReferees, id]);
    removeReferee(id);
  };

  const getSelectedRowData = () => {
    if (gridApi) {
      const selectedIds = gridApi.getSelectedNodes().map(node => node.data.id);
      selectForMerge(selectedIds);
      if (selectedIds.length !== 0) {
        enableMergeTool();
      } else {
        disableMergeTool();
      }
    }
  };

  const onRowDataChange = () => {
    if (gridApi) {
      gridApi.forEachNode(node => {
        if (node.data.isSelectedForMerge) {
          node.setSelected(true);
        }
      });
    }
  };

  const csvExportParams = {
    skipHeader: true,
    fileName: `season-${seasonId}-referees-${now.format("YYYY-MM-DD")}.csv`,
    customHeader: "First Name,Last Name,Email Address,External ID",
    columnKeys: ["firstName", "lastName", "emailAddress", "externalId"]
  };

  const triggerExportScheduleCSV = () => {
    if (gridApi) {
      gridApi.exportDataAsCsv(csvExportParams);
    }
  };

  const canDelete = canCheck({ I: "delete", this: "referes" });
  const canUpdate = canCheck({ I: "update", this: "referees" });

  return (
    <div className="referees-list">
      <RefereesListMergeTool />

      {(!isMergeToolEnabled || mergeToolStatus === "selecting-referees") && (
        <Fragment>
          <div className="d-flex mb-5 flex-row justify-content-between">
            <RefereesListSearchBar seasonId={seasonId} />
            <div className="d-flex flew-row">
              <Dropdown isOpen={isOpen} className="d-flex flew-row align-items-stretch" toggle={toggle}>
                <DropdownToggle caret size="sm" color="secondary" outline={true}>
                  CSV Actions
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem to={`/seasons/${seasonId}/referees/csv-import`} tag={Link} color="success" size="sm">
                    Import from CSV
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={triggerExportScheduleCSV} color="primary">
                    Export all to CSV
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <AbilityButton
                subject={{ type: "referees", seasonId }}
                action="create"
                outline
                className="ml-2"
                size="sm"
                tag={Link}
                color={isMergeToolEnabled ? "secondary" : "success"}
                to={`/seasons/${seasonId}/referees/new`}
                disabled={isMergeToolEnabled}
              >
                Create Referee
              </AbilityButton>
            </div>
          </div>

          {isLoading ? (
            <div className="d-flex flex-row justify-content-center">
              <Spinner className="loading-spinner" theme="loader" color="yellow" size="xxl" />
            </div>
          ) : (
            <Fragment>
              <TotalCount plural="referees" singular="referee" totalCount={totalCount} filteredCount={filteredCount} />

              <div className="ag-theme-alpine ag-gs-theme">
                <AgGridReact
                  defaultColDef={{
                    lockPosition: { isMobile },
                    resizable: true,
                    wrapText: true,
                    autoHeight: true,
                    headerCheckboxSelection: isFirstColumn,
                    checkboxSelection: isFirstColumn
                  }}
                  pagination={true}
                  paginationPageSize={PAGE_SIZE}
                  rowData={referees}
                  onGridReady={onGridReady}
                  onRowDataChanged={onRowDataChange}
                  domLayout={"autoHeight"}
                  suppressCellSelection={true}
                  rowHeight={44}
                  rowSelection={"multiple"}
                  enableCellTextSelection={true}
                  getRowNodeId={data => data.id}
                  suppressRowClickSelection={true}
                  onSelectionChanged={getSelectedRowData}
                >
                  <AgGridColumn field="externalId" hide />
                  <AgGridColumn
                    headerName="First Name"
                    field="firstName"
                    flex="2"
                    lockPosition={isMobile}
                    cellRendererFramework={params => {
                      const { id, seasonId } = params.data;
                      return (
                        <Link to={`/seasons/${seasonId}/referees/${id}/report`}>{params.data.firstName}</Link>
                      )
                    }}
                  />
                  <AgGridColumn
                    headerName="Last Name"
                    field="lastName"
                    flex="2"
                    lockPosition={isMobile}
                    cellRendererFramework={params => {
                      const { id, seasonId } = params.data;
                      return (
                        <Link to={`/seasons/${seasonId}/referees/${id}/report`}>{params.data.lastName}</Link>
                      )
                    }}
                  />
                  <AgGridColumn headerName="Email Address" field="emailAddress" flex="2" lockPosition={isMobile} />
                  <AgGridColumn
                    headerName=""
                    field=""
                    minWidth="120"
                    flex="1"
                    type="rightAligned"
                    lockPosition={isMobile}
                    hide={!canDelete && !canUpdate}
                    cellRendererFramework={params => {
                      const { id, seasonId, firstName, lastName } = params.data;
                      return (
                        <ActionsDropdown
                          seasonId={seasonId}
                          id={id}
                          firstName={firstName}
                          lastName={lastName}
                          onDelete={() => onDelete(id)}
                        />
                      );
                    }}
                  />
                </AgGridReact>
              </div>
            </Fragment>
          )}
        </Fragment>
      )}

      <Can I="delete" this={{ type: "referees", seasonId }}>
        <RefereesListMergeToolFooter />
      </Can>
    </div>
  );
}
function isFirstColumn(params) {
  const displayedColumns = params.columnApi.getAllDisplayedColumns();
  return displayedColumns[0] === params.column;
}

RefereesList.propTypes = {
  isLoading: PropTypes.bool,
  isLoaded: PropTypes.bool,
  referees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  ),
  totalCount: PropTypes.number,
  filteredCount: PropTypes.number,
  currentQuery: PropTypes.string,
  seasonId: PropTypes.string.isRequired,
  mergeToolStatus: PropTypes.string,
  hasMore: PropTypes.bool,
  isMergeToolEnabled: PropTypes.bool,
  isSelectedForMerge: PropTypes.bool,
  loadMoreReferees: PropTypes.func,
  selectForMerge: PropTypes.func,
  enableMergeTool: PropTypes.func,
  disableMergeTool: PropTypes.func,
  removeReferee: PropTypes.func
};

RefereesList.defaultProps = {
  isLoading: false,
  isLoaded: false,
  hasMore: false,
  referees: [],
  totalCount: 0,
  filteredCount: 0,
  currentQuery: "",
  mergeToolStatus: null,
  isMergeToolEnabled: false,
  isSelectedForMerge: false,
  selectForMerge() {},
  loadMoreReferees() {},
  enableMergeTool() {},
  disableMergeTool() {},
  removeReferee() {}
};

export default RefereesList;
