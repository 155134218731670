import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import "./Footer.scss";

class StickyFooter extends PureComponent {
  static propTypes = {
    children: PropTypes.node
  };

  static defaultProps = {
    children: null
  };

  render() {
    return <div className="sticky-footer">{this.props.children}</div>;
  }
}

export default StickyFooter;
