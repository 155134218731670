import React, { Fragment } from "react";

import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";
import PlayerForm from "@/components/PlayerForm";
import PageLoader from "@/components/PageLoader";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

function NewPlayer() {
  const { seasonId, seasonIsLoaded, seasonIsLoading, seasonIsForbidden, seasonBreadcrumbs } = useCurrentSeasonContext();

  return (
    <ProtectedLayout
      subject={{ type: "players", seasonId }}
      action="create"
      skip={!seasonIsLoaded && !seasonIsForbidden}
      className="roster-page"
    >
      {seasonIsLoaded ? (
        <Fragment>
          <Head title="New Player" />
          <Breadcrumbs
            items={seasonBreadcrumbs}
            lastItem={{
              title: "Players",
              url: `/seasons/${seasonId}/roster/players`
            }}
          />

          <TitleBar title="New Player" />

          <PlayerForm seasonId={seasonId} />
        </Fragment>
      ) : (
        <PageLoader isLoading={seasonIsLoading} />
      )}
    </ProtectedLayout>
  );
}

export default NewPlayer;
