import React, { Fragment, useCallback } from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";

import { Button } from "reactstrap";

function RuntimeErrorToast({ closeToast }) {
  const handleFeedbackButton = useCallback(
    () => {
      closeToast();
      Sentry.showReportDialog();
    },
    [closeToast]
  );

  return (
    <Fragment>
      <h4>Unexpected error</h4>

      <p>
        We’ve encountered an unexpected error. This error has been automatically reported to the issue tracker and our
        engineers are notified.
      </p>

      <p>Help us to make your user experience better and drop us a few lines about what happened.</p>

      <Button color="light" block onClick={handleFeedbackButton}>
        Leave a Feedback
      </Button>
    </Fragment>
  );
}

RuntimeErrorToast.propTypes = {
  closeToast: PropTypes.func
};

export default RuntimeErrorToast;
