import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { Link, Switch, Route } from "react-router-dom";
import { Button, CustomInput, FormGroup } from "reactstrap";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import Breadcrumbs, { BreadcrumbsProps } from "@/components/Breadcrumbs";
import NotFound from "@/components/NotFound";
import TitleBar from "@/components/TitleBar";
import Head from "@/components/Head";
import Spinner from "@/components/Spinner";
import PageLoader from "@/components/PageLoader";
import { Can } from "@/components/Ability";
import AbilityButton from "@/components/AbilityButton";
import Gamesheet from "@/components/Gamesheet";
import GamesheetHistoryModal from "@/components/Gamesheet/History";
import GameNotesModal from "@/components/GameNotes";

class GamesShow extends PureComponent {
  static propTypes = {
    path: PropTypes.string.isRequired,
    seasonId: PropTypes.string.isRequired,
    gameId: PropTypes.string.isRequired,
    seasonIsLoaded: PropTypes.bool,
    seasonIsLoading: PropTypes.bool,
    isLoaded: PropTypes.bool,
    isLoading: PropTypes.bool,
    isExporting: PropTypes.bool,
    isForbidden: PropTypes.bool,
    gamesheet: PropTypes.shape({}),
    viewedStatus: PropTypes.shape({
      isViewed: PropTypes.bool,
      isChanging: PropTypes.bool
    }),
    countDashboardNotes: PropTypes.number,
    breadcrumbs: BreadcrumbsProps,
    loadGamesheet: PropTypes.func,
    clearGamesheet: PropTypes.func,
    toggleGameViewed: PropTypes.func,
    showGamesheetHistory: PropTypes.func,
    exportGamesheetToPDF: PropTypes.func,
    sport: PropTypes.string
  };

  static defaultProps = {
    seasonIsLoaded: false,
    seasonIsLoading: false,
    gamesheet: {},
    isLoaded: false,
    isLoading: false,
    isExporting: false,
    isForbidden: false,
    viewedStatus: {
      isViewed: false,
      isChanging: false
    },
    countDashboardNotes: 0,
    breadcrumbs: null,
    loadGamesheet() {},
    clearGamesheet() {},
    toggleGameViewed() {},
    showGamesheetHistory() {},
    exportGamesheetToPDF() {}
  };

  componentDidMount() {
    const { loadGamesheet, seasonId, gameId } = this.props;

    loadGamesheet({ seasonId, id: gameId });
  }

  componentWillUnmount() {
    const { gameId } = this.props;

    this.props.clearGamesheet({ id: gameId });
  }

  changeGameViewedStatus = () => {
    const { viewedStatus, toggleGameViewed, gameId } = this.props;

    toggleGameViewed({ id: gameId, isViewed: !viewedStatus.isViewed });
  };

  print = () => {
    this.props.exportGamesheetToPDF(this.props.gameId);
  };

  render() {
    const {
      seasonIsLoading,
      seasonIsLoaded,
      isLoading,
      isLoaded,
      isForbidden,
      isExporting,
      gamesheet,
      viewedStatus,
      countDashboardNotes,
      breadcrumbs,
      showGamesheetHistory,
      path,
      seasonId,
      gameId,
      sport
    } = this.props;

    return (
      <ProtectedLayout className="game-details" condition={!isForbidden}>
        <PageLoader isLoading={seasonIsLoading} isLoaded={seasonIsLoaded}>
          {seasonIsLoaded ? (
            <Fragment>
              <Head title="Score Sheet" />
              {isLoaded && (
                <Breadcrumbs
                  items={breadcrumbs}
                  lastItem={{
                    title: "Completed Games",
                    url: `/seasons/${seasonId}/games/completed`
                  }}
                />
              )}

              <TitleBar title="Score Sheet">
                {isLoaded && (
                  <FormGroup check inline>
                    {viewedStatus.isChanging === true ? (
                      <Spinner theme="scale" color={"yellow"} size="s" />
                    ) : (
                      <CustomInput
                        type="checkbox"
                        id="viewed-status-check"
                        label="Viewed"
                        checked={viewedStatus.isViewed === true}
                        onChange={this.changeGameViewedStatus}
                      />
                    )}
                  </FormGroup>
                )}

                <Button outline color="primary" size="sm" onClick={this.print} disabled={isExporting}>
                  {isExporting ? "Please wait..." : "Print"}
                </Button>

                <AbilityButton
                  action="read"
                  subject={{ type: "gameNotes", gameId }}
                  outline
                  color="primary"
                  size="sm"
                  disabled={isLoading}
                  tag={Link}
                  to={`/seasons/${seasonId}/games/completed/${gameId}/notes`}
                >
                  Notes
                  {countDashboardNotes > 0 ? ` (${countDashboardNotes})` : null}
                </AbilityButton>

                <Button outline color="primary" size="sm" onClick={showGamesheetHistory}>
                  History
                </Button>

                <AbilityButton
                  subject={{ type: "games", id: gameId }}
                  action="update"
                  outline
                  color="primary"
                  size="sm"
                  tag={Link}
                  to={`/seasons/${seasonId}/games/completed/${gameId}/edit`}
                >
                  Edit Game
                </AbilityButton>
              </TitleBar>
            </Fragment>
          ) : (
            <NotFound />
          )}

          <PageLoader isLoaded={isLoaded} isLoading={isLoading}>
            <Gamesheet {...gamesheet} sport={sport} />
            <GamesheetHistoryModal seasonId={seasonId} gameId={gameId} />
          </PageLoader>
        </PageLoader>

        <Can I="read" this={{ type: "gameNotes", gameId }}>
          <Switch>
            <Route path={`${path}/notes`} component={GameNotesModal} seasonId={seasonId} gameId={gameId} />
          </Switch>
        </Can>
      </ProtectedLayout>
    );
  }
}

export default GamesShow;
