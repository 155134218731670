import { handleActions } from "redux-actions";

import { associationListLoadingRoutine as loadingRoutine } from "../routines";
import actions from "../actions";

const initialState = {
  isLoaded: false,
  isLoading: false,
  ids: [],
  data: {},
  selectedIds: [] // Track selected association IDs
};

function reduceLoadingRequest(state) {
  return { ...state, isLoading: true };
}

function reduceLoadingSuccess(state, { payload: { ids, associations } }) {
  const data = associations.reduce((result, { id, title }) => ({ ...result, [id]: { title } }), {});

  return {
    ...state,
    ids,
    data,
    isLoaded: true
  };
}

function reduceLoadingFailure(state) {
  return { ...state, isLoaded: false };
}

function reduceLoadingFulfill(state) {
  return { ...state, isLoading: false };
}

function reduceSelectAll(state) {
  return {
    ...state,
    selectedIds: [...state.ids] // Select all ids
  };
}

function reduceClearSelections(state) {
  return {
    ...state,
    selectedIds: [] // Clear all selections
  };
}

function reduceSelectAssociation(state, { payload: { id } }) {
  return {
    ...state,
    selectedIds: [...state.selectedIds, id] // Add selected ID
  };
}

function reduceDeselectAssociation(state, { payload: { id } }) {
  return {
    ...state,
    selectedIds: state.selectedIds.filter(selectedId => selectedId !== id) // Remove deselected ID
  };
}

export default handleActions(
  {
    [loadingRoutine.REQUEST]: reduceLoadingRequest,
    [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [loadingRoutine.FAILURE]: reduceLoadingFailure,
    [loadingRoutine.FULFILL]: reduceLoadingFulfill,
    [actions.associationList.selectAll]: reduceSelectAll,
    [actions.associationList.clear]: reduceClearSelections,
    [actions.associationList.select]: reduceSelectAssociation,
    [actions.associationList.deselect]: reduceDeselectAssociation
  },
  initialState
);
