import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Form as ReactStrapForm } from "reactstrap";

import "./Form.scss";

class Form extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    onSubmit: PropTypes.func,
    className: PropTypes.string,
    onChange: PropTypes.func
  };

  static defaultProps = {
    disabled: false,
    onSubmit() {},
    onChange() {}
  };

  handleSubmit = e => {
    e.preventDefault();

    this.props.onSubmit();
  };

  render() {
    const { children, disabled, className } = this.props;

    return (
      <ReactStrapForm
        className={classNames(["form", className])}
        onSubmit={this.handleSubmit}
        onChange={this.props.onChange}
      >
        <fieldset disabled={disabled}>{children}</fieldset>
      </ReactStrapForm>
    );
  }
}

export default Form;
