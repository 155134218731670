import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import PlayersListMergeToolTable from "./Table";
import PlayersListMergeToolProgress from "./Progress";
import PlayersListMergeToolFailure from "./Failure";

class PlayersListMergeTool extends PureComponent {
  static propTypes = {
    status: PropTypes.string,
    setStatus: PropTypes.func
  };

  static defaultProps = {
    status: null,
    setStatus() {}
  };

  returnToPlayerSelection = () => {
    this.props.setStatus("selecting-players");
  };

  render() {
    const { status } = this.props;

    switch (status) {
      case "choosing-winner":
        return (
          <Fragment>
            <Button color="link" onClick={this.returnToPlayerSelection}>
              ← Back to player selection
            </Button>

            <h1>Which player would you like to keep?</h1>
            <p className="text-secondary">
              Select the player you want to keep and we’ll merge the other players into this player.
            </p>

            <PlayersListMergeToolTable />
          </Fragment>
        );
      case "merging":
        return <PlayersListMergeToolProgress />;
      case "failure":
        return <PlayersListMergeToolFailure />;
      default:
        return null;
    }
  }
}

export default PlayersListMergeTool;
