function membersListArrayToObject(membersList, initialValue = {}) {
  return membersList.reduce(
    (result, { id, ...rest }) => ({
      ...result,
      [id]: { ...(result[id] || {}), ...rest }
    }),
    initialValue
  );
}

function membersListObjectToArray(membersList) {
  return Object.entries(membersList).reduce((result, [id, rest]) => [...result, { id, ...rest }], []);
}

export default function updateTeamRoster(currentRoster, { players = [], coaches = [] }) {
  const currentPlayers = membersListArrayToObject(currentRoster.players);
  const currentCoaches = membersListArrayToObject(currentRoster.coaches);

  const nextPlayers = membersListArrayToObject(players, currentPlayers);
  const nextCoaches = membersListArrayToObject(coaches, currentCoaches);

  return {
    players: membersListObjectToArray(nextPlayers),
    coaches: membersListObjectToArray(nextCoaches)
  };
}
