import { handleActions } from "redux-actions";

import { loadingRoutine } from "../routines";
import actions from "../actions";

function reduceLoadingSuccess(state, { payload: { teams } }) {
  return teams.reduce((result, { id, title, division }) => [...result, { id, title, divisionId: division.id }], []);
}

export default handleActions(
  {
    [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [actions.clear]: () => []
  },
  []
);
