export function sortPenalties({ period: periodA, timeOff: timeA }, { period: periodB, timeOff: timeB }) {
  if (periodA === periodB) {
    if (timeA === timeB) {
      return 0;
    }

    return timeA > timeB ? -1 : 1;
  }

  return periodA > periodB ? 1 : -1;
}

export function sortPenaltiesReverse({ period: periodA, timeOff: timeA }, { period: periodB, timeOff: timeB }) {
  if (periodA === periodB) {
    if (timeA === timeB) {
      return 0;
    }

    return timeA < timeB ? -1 : 1;
  }

  return periodA > periodB ? 1 : -1;
}
