import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import actions from "@/redux/reportDataSourcesSelect/actions";

import {
  getTeamGroupList,
  getTeamListIsLoaded,
  getTeamListIsLoading,
  getTeamIsSelected,
  getAllTeamsSelected
} from "@/redux/reportDataSourcesSelect/selectors";

export default function useTeamList() {
  const dispatch = useDispatch();

  const teamGroups = useSelector(getTeamGroupList);
  const isLoaded = useSelector(getTeamListIsLoaded);
  const isLoading = useSelector(getTeamListIsLoading);
  const isOptionSelected = useSelector(getTeamIsSelected);
  const isAllOptionsSelected = useSelector(getAllTeamsSelected);

  const clear = useCallback(() => dispatch(actions.teamList.clear()), [dispatch]);

  const selectAll = useCallback(() => dispatch(actions.teamList.selectAll()), [dispatch]);

  return {
    teamGroups,
    isLoaded,
    isLoading,
    isOptionSelected,
    isAllOptionsSelected,
    clear,
    selectAll
  };
}
