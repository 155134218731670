import React, { useState } from "react";
import { FormGroup } from "reactstrap";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import { useTieBreakers } from "./hooks";

import TitleBar from "@/components/TitleBar";
import TieBreaker from "./TieBreaker";

import "./TieBreakersFormField.scss";

const TieBreakersFormField = ({ value, onChange, sport }) => {
  const { tieBreakers, onDragEnd } = useTieBreakers({
    value,
    onChange,
    sport,
  });

  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <FormGroup className="tie-breakers">
      <TitleBar slim title="Ranking Criteria" titleTag="h4" />
      <p className="tie-breakers__description">
        Click and drag to arrange tie breakers in the order in which they should be applied.
      </p>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="tie-breakers__dnd__area" isDropDisabled={modalIsOpen}>
          {(provided) => (
            <div
              className="tie-breakers__dnd__area"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {tieBreakers.map((item, index) => (
                <TieBreaker
                  key={item.title}
                  {...item}
                  index={index}
                  modalIsOpen={modalIsOpen}
                  setModalIsOpen={setModalIsOpen}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </FormGroup>
  );
};

export default TieBreakersFormField;
