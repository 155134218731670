import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader } from "reactstrap";

import AbilityButton from "@/components/AbilityButton";

import GameNotesList from "../List";
import GameNotesForm from "../Form";

class GameNotesModal extends PureComponent {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        seasonId: PropTypes.string.isRequired,
        gameId: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    isWorking: PropTypes.bool,
    isFormEnabled: PropTypes.bool,
    close: PropTypes.func,
    clear: PropTypes.func,
    enableForm: PropTypes.func,
    loadNotesList: PropTypes.func
  };

  static defaultProps = {
    isWorking: false,
    isFormEnabled: false,
    canCreateNotes: false,
    close() {},
    clear() {},
    enableForm() {},
    loadNotesList() {}
  };

  handleClose = () => {
    const { isWorking, close } = this.props;

    if (isWorking) {
      return;
    }

    close();
  };

  componentWillUnmount() {
    this.props.clear();
  }

  render() {
    const {
      match: {
        params: { seasonId, gameId }
      },
      isFormEnabled,
      enableForm,
      loadNotesList,
      isWorking
    } = this.props;

    return (
      <Modal isOpen={true} toggle={this.handleClose} size="lg" onOpened={loadNotesList} centered>
        <ModalHeader toggle={this.handleClose}>
          {isFormEnabled ? "New note" : "Notes"}

          {!isFormEnabled && (
            <AbilityButton
              action="create"
              subject={{ type: "gameNotes", gameId }}
              outline
              size="sm"
              color="success"
              className="ml-4"
              onClick={enableForm}
              disabled={isWorking}
            >
              + New Note
            </AbilityButton>
          )}
        </ModalHeader>

        {!isFormEnabled ? <GameNotesList /> : <GameNotesForm seasonId={seasonId} gameId={gameId} />}
      </Modal>
    );
  }
}

export default GameNotesModal;
