import { config } from "@/config";
import { getFreshToken } from "@/lib/api/getFreshToken";
import { useEffect, useState } from "react";

export function useIncidentSuspensionReportData( seasonId ){

    const [ report, setReport ] = useState({ data:[], records: 0, meta: {
        loading:true,
        season: {
            id: seasonId
        }
    }})

    async function getIncidentSuspensionReport() {
        let token = await getFreshToken();
        return fetch(`${config.BFF_API}/reports/incident-suspension-report/${seasonId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => res.json())
            .then(response => {
                return response.data
            })
    }

    useEffect(() => {
        
        let mounted = true;
        getIncidentSuspensionReport().then(result => {
            console.log('result', result)
            mounted && setReport({ ...result, meta: {
                loading:false,
                ...result.meta,
            }})
        })

        return () => {
            mounted = false;
        }

    }, [])

    return report

}