import { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getAssociationId,
  getAssociationList,
  getAssociationListIsLoaded,
  getAssociationListIsLoading
} from "@/redux/invitationFilter/selectors";

import { associationListLoadingRoutine } from "@/redux/invitationFilter/routines";
import actions from "@/redux/invitationFilter/actions";

export default function useAssociationSelect() {
  const selectedId = useSelector(getAssociationId);
  const options = useSelector(getAssociationList);
  const isLoaded = useSelector(getAssociationListIsLoaded);
  const isLoading = useSelector(getAssociationListIsLoading);
  const dispatch = useDispatch();
  const setSelectedId = useCallback(id => dispatch(actions.setAssociationId(id)), [dispatch]);

  useEffect(
    () => {
      dispatch(associationListLoadingRoutine());
    },
    [dispatch]
  );

  return {
    selectedId,
    options,
    isLoaded,
    isLoading,
    setSelectedId
  };
}
