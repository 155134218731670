import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";

import Layout from "@/layouts/Layout";

import "./ArchivedSeasonContent.scss";

function ArchivedSeasonContent({ children, condition }) {
  if (condition) {
    return (
      <Layout>
        <div className="archived-season-content">
          <Link className="archived-season-content__link" to="/">
            ← Go back to homepage
          </Link>

          <Row>
            <Col lg="8">
              <h1 className="archived-season-content__title">Season Archived</h1>
              <p className="archived-season-content__text">
                The page you requested is part of a season which has been archived.
              </p>
              <p className="archived-season-content__text">
                If you require access, please email your league administrator and request that they unarchive this
                season.
              </p>
            </Col>
          </Row>
        </div>
      </Layout>
    );
  }

  return children;
}

ArchivedSeasonContent.propTypes = {
  children: PropTypes.node,
  condition: PropTypes.bool
};

ArchivedSeasonContent.defaultProps = {
  children: null,
  condition: false
};

export default ArchivedSeasonContent;
