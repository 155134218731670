import { put, call, all, takeLatest, select } from "redux-saga/effects";
import build from "redux-object";

import updateTeamRoster from "@/lib/core/updateTeamRoster";

import { loadTeam, updateTeam } from "@/lib/api/teams";
import { loadPlayers } from "@/lib/api/players";

import { gamesheetAPIRequest } from "@/redux/api/sagas";

import {
  teamLoadingRoutine,
  playersLoadingRoutine,
  coachesLoadingRoutine,
  addingMemberToTeamRoutine
} from "./routines";

import actions from "./actions";
import { getTeam } from "./selectors";

import createMembersLoadingSaga from "./helpers/createMembersLoadingSaga";
import { loadCoaches } from "@/lib/api/coaches";

const playersLoadingSaga = createMembersLoadingSaga({
  type: "players",
  routine: playersLoadingRoutine,
  apiService: loadPlayers
});

const coachesLoadingSaga = createMembersLoadingSaga({
  type: "coaches",
  routine: coachesLoadingRoutine,
  apiService: loadCoaches
});

function* teamLoadingSaga({ payload: { id, seasonId } }) {
  yield put(teamLoadingRoutine.request());

  try {
    const { data } = yield call(gamesheetAPIRequest, loadTeam, {
      identity: { id },
      include: "associations,leagues,seasons,divisions"
    });
    const team = build(data, "teams", id);

    yield put(teamLoadingRoutine.success({ ...team }));
  } catch (error) {
    yield put(teamLoadingRoutine.failure({ error }));
  } finally {
    yield put(teamLoadingRoutine.fulfill());
  }
}

function* searchQueryCommittingSaga({ payload: { action } }) {
  yield put(action());
}

function* addingMemberToRosterSaga({ payload }) {
  const { teamId, memberId, type, seasonId } = payload;

  yield put(addingMemberToTeamRoutine.request({ teamId, memberId }));

  try {
    const { title, externalId, divisionId, roster, data: teamData } = yield select(getTeam);

    const nextRoster = updateTeamRoster(roster, { [type]: [{ id: memberId }] });

    const { data } = yield call(gamesheetAPIRequest, updateTeam, {
      seasonId,
      divisionId,
      identity: { type: "teams", id: teamId },
      attributes: {
        title,
        externalId,
        roster: nextRoster,
        data: teamData
      }
    });

    const team = build(data, "teams", teamId);

    yield put(
      addingMemberToTeamRoutine.success({
        teamId,
        memberId,
        nextRoster: team.roster,
        team
      })
    );
  } catch (error) {
    yield put(addingMemberToTeamRoutine.failure({ teamId, memberId, error }));
  } finally {
    yield put(addingMemberToTeamRoutine.fulfill({ teamId, memberId }));
  }
}

export function* teamRosterExtenderFlow() {
  yield all([
    takeLatest(teamLoadingRoutine.TRIGGER, teamLoadingSaga),
    takeLatest(playersLoadingRoutine.TRIGGER, playersLoadingSaga),
    takeLatest(coachesLoadingRoutine.TRIGGER, coachesLoadingSaga),
    takeLatest(actions.search.commit, searchQueryCommittingSaga),
    takeLatest(addingMemberToTeamRoutine.TRIGGER, addingMemberToRosterSaga)
  ]);
}
