import React from "react";
import PropTypes from "prop-types";

import { Switch } from "react-router-dom";

import LoggedInRoute from "@/containers/LoggedInRoute";

import UsersIndex from "./pages/UsersIndex";
import UsersNew from "./pages/UsersNew";
import UsersEdit from "./pages/UsersEdit";

import useUserFilterClearing from "./hooks/useUserFilterClearing";

function UsersRoute({ match: { url } }) {
  useUserFilterClearing();

  return (
    <Switch>
      <LoggedInRoute path={`${url}/new`} component={UsersNew} />
      <LoggedInRoute path={`${url}/:userId/edit`} component={UsersEdit} />
      <LoggedInRoute path="" component={UsersIndex} />
    </Switch>
  );
}

UsersRoute.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired
};

export default UsersRoute;
