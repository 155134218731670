import { connect } from "react-redux";

import SeasonIpadKeysManagerKeysList from "./KeysList";

import {
  getSeasonIpadKeysManagerIsLoading,
  getSeasonIpadKeysManagerIsLoaded,
  getSeasonIpadKeysManagerKeys,
  getSeasonIpadKeysManagerTotalKeysAmount
} from "@/redux/seasonIpadKeysManager/selectors";

const mapStateToProps = state => ({
  isLoading: getSeasonIpadKeysManagerIsLoading(state),
  isLoaded: getSeasonIpadKeysManagerIsLoaded(state),
  keys: getSeasonIpadKeysManagerKeys(state),
  totalKeysAmount: getSeasonIpadKeysManagerTotalKeysAmount(state)
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SeasonIpadKeysManagerKeysList);
