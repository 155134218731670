import React from "react";
import PropTypes from "prop-types";

import { FilePicker } from "react-file-picker";
import useHandleFile from "./hooks/useHandleFile";

function ImagePicker({ onChange, onError, dims, children, ...rest }) {
  const handleFile = useHandleFile({ onChange, onError, dims });

  return (
    <FilePicker onChange={handleFile} onError={onError} {...rest}>
      {children}
    </FilePicker>
  );
}

ImagePicker.propTypes = {
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  maxSize: PropTypes.number.isRequired,
  dims: PropTypes.shape({
    minWidth: PropTypes.number.isRequired,
    maxWidth: PropTypes.number.isRequired,
    minHeight: PropTypes.number.isRequired,
    maxHeight: PropTypes.number.isRequired
  }).isRequired
};

export default ImagePicker;
