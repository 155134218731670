import { useCallback } from "react";

import { useDashboardFormNamespaceContext } from "@/components/DashboardForm";

export default function useGameCategoriesFields(options) {
  const { value: categoryValues, onChange: changeCategoryValues } = options;

  const namespace = useDashboardFormNamespaceContext();

  const appendNewCategory = useCallback(() => changeCategoryValues([...categoryValues, ""]), [
    changeCategoryValues,
    categoryValues
  ]);

  const changeCategory = useCallback(
    ({ index, value }) =>
      changeCategoryValues([...categoryValues.slice(0, index), value, ...categoryValues.slice(index + 1)]),
    [changeCategoryValues, categoryValues]
  );

  const removeCategory = useCallback(
    index => changeCategoryValues([...categoryValues.slice(0, index), ...categoryValues.slice(index + 1)]),
    [changeCategoryValues, categoryValues]
  );

  const removeAllCategories = useCallback(() => changeCategoryValues([]), [changeCategoryValues]);

  const categories = categoryValues.map((value, index) => {
    return {
      value,
      id: `${namespace}__game-categories__category-${index}`,
      onChange: ({ target: { value } }) => changeCategory({ index, value }),
      removeCategory: () => removeCategory(index)
    };
  });

  return {
    appendNewCategory,
    removeAllCategories,
    categories
  };
}
