import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

class RefereesListMergeToolReferee extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    seasonId: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    externalId: PropTypes.string,
    isWinner: PropTypes.bool,
    chooseAsWinner: PropTypes.func
  };

  static defaultProps = {
    externalId: "",
    isWinner: false,
    chooseAsWinner() {}
  };

  render() {
    const { firstName, lastName, externalId, isWinner, chooseAsWinner } = this.props;

    return (
      <tr>
        <td>
          <span className="full-name">
            {firstName} <span className="last-name">{lastName}</span>
          </span>
        </td>
        <td>{externalId}</td>
        <td>
          <Button outline={!isWinner} color="success" size="sm" onClick={chooseAsWinner}>
            Keep
          </Button>
        </td>
      </tr>
    );
  }
}

export default RefereesListMergeToolReferee;
