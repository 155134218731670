import { connect } from "react-redux";

import {
  getSuspensionReportIsLoading,
  getSuspensionReportIsLoaded,
  getSuspensionReportSuspensionsBySeasons
} from "@/redux/suspensionReport/selectors";

import Report from "./Report";

const mapStateToProps = state => ({
  isLoading: getSuspensionReportIsLoading(state),
  isLoaded: getSuspensionReportIsLoaded(state),
  suspensionsBySeasons: getSuspensionReportSuspensionsBySeasons(state)
});

export default connect(mapStateToProps)(Report);
