import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getSelectedSeasons,
  getDivisionList,
  getDivisionListIsLoaded,
  getDivisionListIsLoading,
  getRoleIsGlobal,
  getSelectedDivisions // New selector to get selected divisions
} from "@/redux/roleForm/selectors";

import actions from "@/redux/roleForm/actions";

export default function useDivisionSelect() {
  const selectedSeasons = useSelector(getSelectedSeasons);
  const options = useSelector(state => getDivisionList(state, selectedSeasons));
  const selectedDivisions = useSelector(getSelectedDivisions); // Fetch selected divisions
  const roleIsGlobal = useSelector(getRoleIsGlobal);
  const isLoaded = useSelector(state => getDivisionListIsLoaded(state, selectedSeasons));
  const isLoading = useSelector(state => getDivisionListIsLoading(state, selectedSeasons));
  const isOptionSelected = selectedDivisions.length > 0; // Check if any option is selected
  const totalItemsCount = options.reduce((count, group) => count + group.items.length, 0); // Flatten options to get a count of all items across groups
  const isAllOptionsSelected = totalItemsCount > 0 && selectedDivisions.length === totalItemsCount;
  const dispatch = useDispatch();

  const toggleSelected = useCallback(
    ({ id, ...rest }) => {
      const isSelected = selectedDivisions.includes(id); // Check if division is selected
      const action = isSelected ? actions.divisionList.deselect : actions.divisionList.select; // Use correct action path

      dispatch(action({ id, ...rest }));
    },
    [dispatch, selectedDivisions] // Include selectedDivisions in dependencies
  );

  return {
    options,
    isLoaded,
    isLoading,
    isEnabled: !roleIsGlobal,
    toggleSelected,
    selectedDivisions, // Return selected divisions for UI rendering if needed
    isOptionSelected, // Pass this value to indicate if any items are selected
    isAllOptionsSelected
  };
}
