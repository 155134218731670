import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { Input, FormFeedback } from "reactstrap";

function BroadcastProviderInput(props) {
  const { id, name, BROADCAST_PROVIDERS_LIST, input, isDirty, errors, link, selectedCode } = props;

  return (
    <Fragment>
      <Input type="select" id={id} name={name} {...input}>
        {/* {console.log("Input for Broadcast Provider: ",input)} */}
        {BROADCAST_PROVIDERS_LIST.map(({ code, title }) => (
          <option key={code} value={code}>
            {title}
          </option>
        ))}
      </Input>

      {isDirty && errors.map(error => <FormFeedback key={error}>{error}</FormFeedback>)}
    </Fragment>
  );
}

BroadcastProviderInput.propTypes = {
  id: PropTypes.string,
  input: PropTypes.shape({}),
  name: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  isDirty: PropTypes.bool
};

BroadcastProviderInput.defaultProps = {
  isDirty: false,
  errors: []
};

export default BroadcastProviderInput;
