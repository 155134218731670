import { all, takeEvery, takeLatest, put, select, actionChannel, take } from "redux-saga/effects";

import { buffers } from "redux-saga";

import { loadAssociations } from "@/lib/api/associations";
import { loadLeagues, loadLeague } from "@/lib/api/leagues";
import { loadSeasons, loadSeason } from "@/lib/api/seasons";
import { loadDivisions, loadDivision } from "@/lib/api/divisions";
import { loadTeams, loadTeam } from "@/lib/api/teams";

import { fetchList, fetchOne } from "@/redux/api/sagas";

import {
  associationListLoadingRoutine,
  leagueListLoadingRoutine,
  seasonListLoadingRoutine,
  divisionListLoadingRoutine,
  teamListLoadingRoutine,
  initRoutine
} from "./routines";

import actions from "./actions";

import {
  getLeagueListIsLoaded,
  getSeasonListIsLoaded,
  getDivisionListIsLoaded,
  getSelectedAssociations,
  getLeagueListIsLoading,
  getSeasonListIsLoading,
  getDivisionListIsLoading,
  getTeamListIsLoading,
  getTeamListIsLoaded,
  getAssociationIdsNotSelected,
  getLeagueIdsNotSelected,
  getSeasonIdsNotSelected,
  getDivisionIdsNotSelected,
  getTeamIdsNotSelected
} from "./selectors";

function* associationListLoadingSaga() {
  yield put(associationListLoadingRoutine.request());

  try {
    const { ids, associations } = yield fetchList("associations", loadAssociations);

    yield put(associationListLoadingRoutine.success({ ids, associations }));
  } catch (error) {
    yield put(associationListLoadingRoutine.failure({ error }));
  } finally {
    yield put(associationListLoadingRoutine.fulfill());
  }
}

function* leagueListLoadingSaga() {
  const selectedAssociations = yield select(getSelectedAssociations);

  for (const associationId of selectedAssociations) {
    const isLoaded = yield select(getLeagueListIsLoaded, associationId);
    const isLoading = yield select(getLeagueListIsLoading, associationId);

    if (isLoaded || isLoading || associationId === "") {
      yield put(leagueListLoadingRoutine.fulfill({ associationId }));
      continue;
    }

    yield put(leagueListLoadingRoutine.request({ associationId }));

    try {
      const { ids, leagues } = yield fetchList("leagues", loadLeagues, { associationId });
      yield put(leagueListLoadingRoutine.success({ associationId, ids, leagues }));
    } catch (error) {
      yield put(leagueListLoadingRoutine.failure({ error, associationId }));
    } finally {
      yield put(leagueListLoadingRoutine.fulfill({ associationId }));
    }
  }
}

function* seasonListLoadingSaga({ payload: { id: leagueId } }) {
  const selectedAssociations = yield select(getSelectedAssociations);

  for (const associationId of selectedAssociations) {
    const isLoaded = yield select(getSeasonListIsLoaded, leagueId);
    const isLoading = yield select(getSeasonListIsLoading, leagueId);

    if (isLoaded || isLoading || leagueId === "") {
      yield put(seasonListLoadingRoutine.fulfill({ leagueId }));
      continue;
    }

    yield put(seasonListLoadingRoutine.request({ leagueId }));

    try {
      const { ids, seasons } = yield fetchList("seasons", loadSeasons, { associationId, leagueId });
      yield put(seasonListLoadingRoutine.success({ leagueId, ids, seasons }));
    } catch (error) {
      yield put(seasonListLoadingRoutine.failure({ error, leagueId }));
    } finally {
      yield put(seasonListLoadingRoutine.fulfill({ leagueId }));
    }
  }
}

function* divisionListLoadingSaga({ payload: { id: seasonId } }) {
  const isLoaded = yield select(getDivisionListIsLoaded, seasonId);
  const isLoading = yield select(getDivisionListIsLoading, seasonId);

  if (isLoaded || isLoading || seasonId === "") {
    yield put(divisionListLoadingRoutine.fulfill({ seasonId }));
    return;
  }

  yield put(divisionListLoadingRoutine.request({ seasonId }));

  try {
    const { ids, divisions } = yield fetchList("divisions", loadDivisions, {
      seasonId
    });

    yield put(divisionListLoadingRoutine.success({ seasonId, ids, divisions }));
  } catch (error) {
    yield put(divisionListLoadingRoutine.failure({ error, seasonId }));
  } finally {
    yield put(divisionListLoadingRoutine.fulfill({ seasonId }));
  }
}

function* teamListLoadingSaga({ payload: { id: divisionId } }) {
  const isLoaded = yield select(getTeamListIsLoaded, divisionId);
  const isLoading = yield select(getTeamListIsLoading, divisionId);

  if (isLoaded || isLoading || divisionId === "") {
    yield put(teamListLoadingRoutine.fulfill({ divisionId }));
    return;
  }

  yield put(teamListLoadingRoutine.request({ divisionId }));

  try {
    const { ids, teams } = yield fetchList("teams", loadTeams, {
      divisionId
    });

    yield put(teamListLoadingRoutine.success({ divisionId, ids, teams }));
  } catch (error) {
    yield put(teamListLoadingRoutine.failure({ error, divisionId }));
  } finally {
    yield put(teamListLoadingRoutine.fulfill({ divisionId }));
  }
}

function* selectAllAssociationsSaga() {
  const ids = yield select(getAssociationIdsNotSelected);
  yield all(ids.map(id => put(actions.associationList.select({ id }))));
}

function* selectAllLeaguesSaga() {
  const ids = yield select(getLeagueIdsNotSelected);
  yield all(ids.map(id => put(actions.leagueList.select({ id }))));
}

function* selectAllSeasonsSaga() {
  const ids = yield select(getSeasonIdsNotSelected);
  yield all(ids.map(id => put(actions.seasonList.select({ id }))));
}

function* selectAllDivisionsSaga() {
  const ids = yield select(getDivisionIdsNotSelected);
  yield all(ids.map(id => put(actions.divisionList.select({ id }))));
}

function* selectAllTeamsSaga() {
  const ids = yield select(getTeamIdsNotSelected);
  yield all(ids.map(id => put(actions.teamList.select({ id }))));
}

export default function* rootSaga() {
  // Associations
  yield takeLatest(actions.associationList.selectAll().type, selectAllAssociationsSaga);
  yield takeLatest(associationListLoadingRoutine.TRIGGER, associationListLoadingSaga);

  // Leagues
  yield takeLatest(actions.leagueList.selectAll().type, selectAllLeaguesSaga);
  yield takeLatest(leagueListLoadingRoutine.TRIGGER, leagueListLoadingSaga);

  // Seasons
  yield takeLatest(actions.seasonList.selectAll().type, selectAllSeasonsSaga);
  yield takeLatest(seasonListLoadingRoutine.TRIGGER, seasonListLoadingSaga);

  // Divisions
  yield takeLatest(actions.divisionList.selectAll().type, selectAllDivisionsSaga);
  yield takeLatest(divisionListLoadingRoutine.TRIGGER, divisionListLoadingSaga);

  // Teams
  yield takeLatest(actions.teamList.selectAll().type, selectAllTeamsSaga);
  yield takeLatest(teamListLoadingRoutine.TRIGGER, teamListLoadingSaga);
}

function* initializeAssociationLevelRoleSaga() {
  const selectedAssociations = yield select(getSelectedAssociations);

  for (const associationId of selectedAssociations) {
    const associationListLoadingFulfillChannel = yield actionChannel(
      associationListLoadingRoutine.FULFILL,
      buffers.dropping(1)
    );

    const leagueListLoadingRoutineFulfillChannel = yield actionChannel(
      leagueListLoadingRoutine.FULFILL,
      buffers.dropping(1)
    );

    yield all([
      put(associationListLoadingRoutine()),
      put(actions.associationList.select({ id: associationId })),
      take(associationListLoadingFulfillChannel),
      take(leagueListLoadingRoutineFulfillChannel)
    ]);
  }
}

function* initializeLeagueLevelRoleSaga(leagueId) {
  const associationListLoadingFulfillChannel = yield actionChannel(
    associationListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const leagueListLoadingRoutineFulfillChannel = yield actionChannel(
    leagueListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const seasonListLoadingRoutineFulfillChannel = yield actionChannel(
    seasonListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const [league] = yield fetchOne({ type: "leagues", id: leagueId }, loadLeague);

  yield all([
    put(associationListLoadingRoutine()),
    put(actions.associationList.select({ id: league.association.id })),
    put(actions.leagueList.select({ id: leagueId })),
    take(associationListLoadingFulfillChannel),
    take(leagueListLoadingRoutineFulfillChannel),
    take(seasonListLoadingRoutineFulfillChannel)
  ]);
}

function* initializeSeasonLevelRoleSaga(seasonId) {
  const associationListLoadingFulfillChannel = yield actionChannel(
    associationListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const leagueListLoadingRoutineFulfillChannel = yield actionChannel(
    leagueListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const seasonListLoadingRoutineFulfillChannel = yield actionChannel(
    seasonListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const divisionListLoadingRoutineFulfillChannel = yield actionChannel(
    divisionListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const [season] = yield fetchOne({ type: "seasons", id: seasonId }, loadSeason);

  yield all([
    put(associationListLoadingRoutine()),
    put(actions.associationList.select({ id: season.association.id })),
    put(actions.leagueList.select({ id: season.league.id })),
    put(actions.seasonList.select({ id: seasonId })),
    take(associationListLoadingFulfillChannel),
    take(leagueListLoadingRoutineFulfillChannel),
    take(seasonListLoadingRoutineFulfillChannel),
    take(divisionListLoadingRoutineFulfillChannel)
  ]);
}

function* initializeDivisionLevelRoleSaga(divisionId) {
  const associationListLoadingFulfillChannel = yield actionChannel(
    associationListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const leagueListLoadingRoutineFulfillChannel = yield actionChannel(
    leagueListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const seasonListLoadingRoutineFulfillChannel = yield actionChannel(
    seasonListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const divisionListLoadingRoutineFulfillChannel = yield actionChannel(
    divisionListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const teamListLoadingRoutineFulfillChannel = yield actionChannel(teamListLoadingRoutine.FULFILL, buffers.dropping(1));

  const [division] = yield fetchOne({ type: "divisions", id: divisionId }, loadDivision);

  yield all([
    put(associationListLoadingRoutine()),
    put(actions.associationList.select({ id: division.association.id })),
    put(actions.leagueList.select({ id: division.league.id })),
    put(actions.seasonList.select({ id: division.season.id })),
    put(actions.divisionList.select({ id: divisionId })),
    take(associationListLoadingFulfillChannel),
    take(leagueListLoadingRoutineFulfillChannel),
    take(seasonListLoadingRoutineFulfillChannel),
    take(divisionListLoadingRoutineFulfillChannel),
    take(teamListLoadingRoutineFulfillChannel)
  ]);
}

function* initializeTeamLevelRoleSaga(teamId) {
  const associationListLoadingFulfillChannel = yield actionChannel(
    associationListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const leagueListLoadingRoutineFulfillChannel = yield actionChannel(
    leagueListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const seasonListLoadingRoutineFulfillChannel = yield actionChannel(
    seasonListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const divisionListLoadingRoutineFulfillChannel = yield actionChannel(
    divisionListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const teamListLoadingRoutineFulfillChannel = yield actionChannel(teamListLoadingRoutine.FULFILL, buffers.dropping(1));

  const [team] = yield fetchOne({ type: "teams", id: teamId }, loadTeam);

  yield all([
    put(associationListLoadingRoutine()),
    put(actions.associationList.select({ id: team.association.id })),
    put(actions.leagueList.select({ id: team.league.id })),
    put(actions.seasonList.select({ id: team.season.id })),
    put(actions.divisionList.select({ id: team.division.id })),
    put(actions.teamList.select({ id: teamId })),
    take(associationListLoadingFulfillChannel),
    take(leagueListLoadingRoutineFulfillChannel),
    take(seasonListLoadingRoutineFulfillChannel),
    take(divisionListLoadingRoutineFulfillChannel),
    take(teamListLoadingRoutineFulfillChannel)
  ]);
}

function* initializingSaga({ payload: initialRole = null }) {
  yield put(initRoutine.request());

  if (initialRole) {
    const { id, type } = initialRole.level;

    if (id === "*" && type === "") {
      // global, nothing to load
      yield put(initRoutine.success());
      yield put(initRoutine.fulfill());
      return;
    }

    switch (type) {
      case "associations":
        yield initializeAssociationLevelRoleSaga(id);
        break;
      case "leagues":
        yield initializeLeagueLevelRoleSaga(id);
        break;
      case "seasons":
        yield initializeSeasonLevelRoleSaga(id);
        break;
      case "divisions":
        yield initializeDivisionLevelRoleSaga(id);
        break;
      case "teams":
        yield initializeTeamLevelRoleSaga(id);
        break;
      default:
        throw new Error(`Unable to initialize role with level ${type}`);
    }
  }
  yield put(initRoutine.success());

  yield put(initRoutine.fulfill());
}

export function* roleFormFlow() {
  yield all([
    takeLatest(associationListLoadingRoutine, associationListLoadingSaga),
    takeEvery(actions.associationList.select, leagueListLoadingSaga),
    takeEvery(actions.leagueList.select, seasonListLoadingSaga),
    takeEvery(actions.seasonList.select, divisionListLoadingSaga),
    takeEvery(actions.divisionList.select, teamListLoadingSaga),
    takeLatest(actions.associationList.selectAll, selectAllAssociationsSaga),
    takeLatest(actions.leagueList.selectAll, selectAllLeaguesSaga),
    takeLatest(actions.seasonList.selectAll, selectAllSeasonsSaga),
    takeLatest(actions.divisionList.selectAll, selectAllDivisionsSaga),
    takeLatest(actions.teamList.selectAll, selectAllTeamsSaga),
    takeLatest(initRoutine, initializingSaga)
  ]);
}
