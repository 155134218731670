import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { ModalBody, ModalFooter, FormGroup, Input, Button, FormFeedback } from "reactstrap";

import DashboardForm from "@/components/DashboardForm";
import FormItem from "@/components/FormItem";

class GameNotesForm extends PureComponent {
  static propTypes = {
    seasonId: PropTypes.string.isRequired,
    gameId: PropTypes.string.isRequired,
    fields: PropTypes.shape({
      text: PropTypes.shape({
        value: PropTypes.string.isRequired,
        isInvalid: PropTypes.bool,
        errors: PropTypes.arrayOf(PropTypes.string)
      }).isRequired
    }),
    isOperating: PropTypes.bool,
    isPristine: PropTypes.bool,
    isInvalid: PropTypes.bool,
    isNotFilled: PropTypes.bool,
    changeField: PropTypes.func,
    submitForm: PropTypes.func,
    disableForm: PropTypes.func
  };

  static defaultProps = {
    fields: {
      text: {
        value: "",
        isInvalid: false,
        errors: []
      }
    },
    isOperating: false,
    isPristine: true,
    isInvalid: false,
    isNotFilled: true,
    changeField() {},
    submitForm() {},
    disableForm() {}
  };

  handleFieldChange = ({ target: { name, value } }) => {
    this.props.changeField({
      field: name,
      value
    });
  };

  render() {
    const {
      isOperating,
      fields: { text },
      isPristine,
      isInvalid,
      isNotFilled,
      submitForm,
      disableForm
    } = this.props;

    return (
      <DashboardForm isOperating={isOperating} onSubmit={submitForm}>
        <ModalBody>
          <FormItem>
            <FormGroup className="col-md-12">
              <Input
                type="textarea"
                id="gameNoteText"
                name="text"
                value={text.value}
                onChange={this.handleFieldChange}
                invalid={text.isInvalid}
              />

              {text.errors.map(error => (
                <FormFeedback key={error}>{error}</FormFeedback>
              ))}
            </FormGroup>
          </FormItem>
        </ModalBody>

        <ModalFooter className="d-flex">
          <div>
            <Button outline color="secondary" onClick={disableForm}>
              Cancel
            </Button>
          </div>

          <div className="ml-auto">
            <Button
              type="submit"
              color="success"
              disabled={isPristine || isInvalid || isNotFilled}
              outline={isPristine || isInvalid || isNotFilled}
            >
              {isOperating ? "Creating…" : "Create note"}
            </Button>
          </div>
        </ModalFooter>
      </DashboardForm>
    );
  }
}

export default GameNotesForm;
