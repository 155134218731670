import { createActions } from "redux-actions";

const { invitationFilter } = createActions({
  INVITATION_FILTER: {
    SET_ASSOCIATION_ID: undefined,
    SET_LEAGUE_ID: undefined,
    SET_SEASON_ID: undefined,
    SET_DIVISION_ID: undefined,
    SET_TEAM_ID: undefined,
    SET_ROLE: undefined,
    SET_STATUS: undefined,
    SET_QUERY: undefined,
    RESET: undefined,
    CLEAR: undefined
  }
});

export default invitationFilter;
