import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { GAME_TYPES } from "@/constants";

import { Table, FormGroup, Label, Input } from "reactstrap";

class GameType extends PureComponent {
  static propTypes = {
    code: PropTypes.string
  };

  render() {
    const { code: currentCode } = this.props;

    return (
      <Table striped borderless size="sm">
        <thead>
          <tr>
            <th scope="col">Game Type</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(GAME_TYPES).map(([code, title]) => (
            <tr key={code}>
              <td>
                <FormGroup check inline disabled>
                  <Label check>
                    <Input type="checkbox" disabled checked={code === currentCode} /> {title}
                  </Label>
                </FormGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}

export default GameType;
