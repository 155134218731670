import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { loadingRoutine, actions, getMeta } from "@/redux/gameReportEmailNotificationSettings";

export default function useGameReportEmailNotificationSettings() {
  const dispatch = useDispatch();
  const { isLoading, isLoaded } = useSelector(getMeta);

  useEffect(
    () => {
      dispatch(loadingRoutine());

      return () => dispatch(actions.clear());
    },
    [dispatch]
  );

  return {
    isLoading,
    isLoaded
  };
}
