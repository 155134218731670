import { handleActions } from "redux-actions";

import { gameNotesListLoadingRoutine } from "../../routines";
import { clearGameNotes } from "../../actions";

const initialState = {
  isLoading: false,
  isLoaded: false
};

function reduceLoadingRequest(state) {
  return { ...state, isLoading: true };
}

function reduceLoadingSuccess(state) {
  return { ...state, isLoaded: true };
}

function reduceLoadingFailure(state) {
  return { ...state, isLoaded: false };
}

function reduceLoadingFulfill(state) {
  return { ...state, isLoading: false };
}

export default handleActions(
  {
    [gameNotesListLoadingRoutine.REQUEST]: reduceLoadingRequest,
    [gameNotesListLoadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [gameNotesListLoadingRoutine.FAILURE]: reduceLoadingFailure,
    [gameNotesListLoadingRoutine.FULFILL]: reduceLoadingFulfill,
    [clearGameNotes]: () => ({ ...initialState })
  },
  initialState
);
