import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

import nl2br from "@/utils/nl2br";
import Spinner from "@/components/Spinner";

class PlayersListPlayer extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    seasonId: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    externalId: PropTypes.string,
    divisions: PropTypes.string,
    teams: PropTypes.string,
    gamesPlayed: PropTypes.number,
    isWinner: PropTypes.bool,
    statsIsLoading: PropTypes.bool,
    statsIsLoaded: PropTypes.bool,
    chooseAsWinner: PropTypes.func
  };

  static defaultProps = {
    externalId: "",
    divisions: "",
    teams: "",
    gamesPlayed: 0,
    statsIsLoading: false,
    statsIsLoaded: false,
    isWinner: false,
    chooseAsWinner() {}
  };

  renderGamesPlayer = () => {
    const { statsIsLoading, statsIsLoaded, gamesPlayed } = this.props;

    if (statsIsLoading) {
      return <Spinner color="yellow" theme="loader" size="xs" />;
    }

    if (statsIsLoaded) {
      return gamesPlayed;
    }

    return null;
  };

  render() {
    const { id, seasonId, firstName, lastName, externalId, divisions, teams, isWinner, chooseAsWinner } = this.props;

    return (
      <tr>
        <td>
          <Link className="full-name" to={`/seasons/${seasonId}/roster/players/${id}`}>
            {firstName} <span className="last-name">{lastName}</span>
          </Link>
        </td>
        <td>{externalId}</td>
        <td>{nl2br(divisions)}</td>
        <td>{nl2br(teams)}</td>
        <td>{this.renderGamesPlayer()}</td>
        <td>
          <Button outline={!isWinner} color="success" size="sm" onClick={chooseAsWinner}>
            Keep
          </Button>
        </td>
      </tr>
    );
  }
}

export default PlayersListPlayer;
