import { handleActions } from "redux-actions";

import { seasonPlayersLoadingRoutine } from "../routines";
import actions from "../actions";

const initialState = {
  players: [],
  loadingFailed: false,
  currentQuery: ""
};

const reduceQueryInput = (state, { payload: { value } }) => ({
  ...state,
  currentQuery: value
});

const clearQuery = state => ({
  ...state,
  currentQuery: ""
});

const reduceSeasonPlayers = (state, { payload: { members, append } }) => ({
  ...state,
  players: append ? [...state.players, ...members] : members,
  loadingFailed: false
});

const reduceFailure = state => ({
  ...state,
  loadingFailed: true
});

export default handleActions(
  {
    [actions.setSearchPlayer]: reduceQueryInput,
    [actions.clearSearchPlayer]: clearQuery,
    [seasonPlayersLoadingRoutine.SUCCESS]: reduceSeasonPlayers,
    [seasonPlayersLoadingRoutine.FAILURE]: reduceFailure,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
