import { all, takeLatest } from "redux-saga/effects";
import smartlookClient from "smartlook-client";

import { config } from "@/config";

import { loadAccountRoutine } from "@/redux/account/routines";
import { logoutAction } from "@/redux/logout/actions";
import { tokenResetRoutine } from "@/redux/token/routines";

function setGACookies({ userId, userRole }) {
  let expiryDate = new Date("2038-04-11T10:20:30Z"); // end of 32-bit Unix epoch
  if (userId) {
    document.cookie = "userId=" + userId + "; secure; path=/; expires=" + expiryDate.toGMTString();
  }
  if (userRole) {
    document.cookie = "userRole=" + userRole + "; secure; path=/; expires=" + expiryDate.toGMTString();
  }
}

function clearGACookies() {
  document.cookie = "userId=; secure; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC";
  document.cookie = "userRole=; secure; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC";
}

function identifyUserSaga({ payload }) {
  const [
    [
      id,
      {
        attributes: { email, firstName, lastName, roles }
      }
    ]
  ] = Object.entries(payload.users);

  const fullName = `${firstName} ${lastName}`;

  let userRole = "";
  const titles = (roles || []).reduce(
    (titles, { title }) => (!titles.includes(title) ? [...titles, title] : titles),
    []
  );
  if (titles.length == 1) {
    userRole = titles[0];
  } else if (titles.length > 1) {
    userRole = "multi";
  }

  setGACookies({ userId: id, userRole });

  if (!window.smartlook) {
    smartlookClient.init(config.SMARTLOOK_KEY);
  }

  smartlookClient.identify(id, { email, name: fullName, userRole });
  smartlookClient.record({ forms: true, emails: true, ips: true, numbers: true });
}

function anonymizeUserSaga() {
  smartlookClient.anonymize();
}

export function* trackingFlow() {
  if (config.NODE_ENV === "production") {
    yield all([
      takeLatest(loadAccountRoutine.SUCCESS, identifyUserSaga),
      takeLatest(tokenResetRoutine.SUCCESS, anonymizeUserSaga),
      takeLatest(logoutAction, clearGACookies)
    ]);
  }
}
