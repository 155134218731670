import _trimEnd from "lodash/trimEnd";

import { getCurrentSeason, getCurrentSeasonBreadcrumbs } from "@/redux/seasons/selectors";

const selectRoot = state => state.referees;

const selectList = state => selectRoot(state).list;
const selectListData = state => selectList(state).data;
const selectListSearch = state => selectList(state).search;
const selectForm = state => selectRoot(state).form;
const selectFormMeta = state => selectForm(state).meta;
const selectFormFields = state => selectForm(state).fields;
const selectMergeTool = state => selectList(state).mergeTool;
const selectCurrent = state => selectRoot(state).current;

export const getRefereesList = state => {
  const data = selectListData(state).referees;

  return Object.keys(data).map(id => {
    return {
      ...data[id]
    };
  });
};

export const getRefereesListIsLoading = state => selectListData(state).isLoading;

export const getRefereesListIsLoaded = state => selectListData(state).isLoaded;

export const getRefereesListIsForbidden = state => selectList(state).isForbidden;

export const getRefereesListTotalCount = state => selectListData(state).totalCount;

export const getRefereesListFilteredCount = state => Object.keys(selectListData(state).referees).length;

export const getRefereesListOffset = () => 0;

// TEMPORARY
// use server-side pagination in future, when we are paying for AG Grid
export const getRefereesListLimit = () => 5000;

export const getRefereesListHasMore = state => {
  const filteredCount = getRefereesListFilteredCount(state);
  const offset = getRefereesListOffset(state);

  return offset < filteredCount;
};

export const getRefereesListSearchCurrentQuery = state => selectListSearch(state).currentQuery;

export const getRefereesListSearchNextQuery = state => selectListSearch(state).nextQuery;

export const getRefereesListSearchQueryIsDirty = state => {
  const { nextQuery, currentQuery } = selectListSearch(state);

  return _trimEnd(nextQuery) !== _trimEnd(currentQuery);
};

export const getRefereesListBreadcrumbs = state => {
  const season = getCurrentSeason(state);
  const seasonBreadcrumbs = getCurrentSeasonBreadcrumbs(state);

  if (season && seasonBreadcrumbs) {
    return [
      ...seasonBreadcrumbs,
      {
        url: `/seasons/${season.id}/referees`,
        title: "Referees"
      }
    ];
  }
};

export const getCurrentRefereeIsLoaded = state => selectCurrent(state).isLoaded;
export const getCurrentRefereeIsForbidden = state => selectCurrent(state).isForbidden;

export const getCurrentRefereeIsLoading = state => selectCurrent(state).isLoading;

export const getCurrentReferee = state => {
  const { firstName, lastName, externalId } = selectCurrent(state);

  return { firstName, lastName, externalId };
};

export const getCurrentRefereeBreadcrumbs = state => {
  const season = getCurrentSeason(state);
  const referee = getCurrentReferee(state);
  const listBreadcrumbs = getRefereesListBreadcrumbs(state);

  if (season && referee && listBreadcrumbs) {
    const { firstName, lastName } = referee;

    return [
      ...listBreadcrumbs,
      {
        url: `/seasons/${season.id}/referees/${referee.id}`,
        title: `${firstName} ${lastName}`
      }
    ];
  }
};

export const getRefereeFormIsOperating = state => selectFormMeta(state).isOperating;

export const getRefereeFormIsDeleting = state => selectFormMeta(state).isDeleting;

export const getRefereeFormFields = state => {
  const fields = selectFormFields(state);

  return Object.keys(fields).reduce(
    (result, field) => ({
      ...result,
      [field]: {
        ...fields[field],
        isInvalid: fields[field].errors.length > 0
      }
    }),
    {}
  );
};

export const getRefereeFormAttributes = state => {
  const fields = selectFormFields(state);

  return Object.keys(fields).reduce(
    (result, field) => ({
      ...result,
      [field]: fields[field].value
    }),
    {}
  );
};

export const getRefereeFormIsValid = state => {
  const fields = getRefereeFormFields(state);

  return Object.values(fields).filter(({ isInvalid }) => isInvalid).length === 0;
};

export const getRefereeFormIsDirty = state => {
  const fields = selectFormFields(state);

  return Object.values(fields).filter(({ isDirty }) => isDirty).length > 0;
};

export const getRefereeFormIsNotFilled = state => {
  const fields = selectFormFields(state);

  return (
    Object.entries(fields).filter(([field, { value }]) => {
      return field !== "externalId" && field !== "emailAddress" && _trimEnd(value) === "";
    }).length > 0
  );
};

export const getRefereeFormIsInvalid = state => getRefereeFormIsValid(state) === false;

export const getRefereeFormIsPristine = state => getRefereeFormIsDirty(state) === false;

export const getRefereeFormIsLoading = state => selectFormMeta(state).isLoading;
export const getRefereeFormIsLoaded = state => selectFormMeta(state).isLoaded;

export const getRefereeFormIsForbidden = state => selectFormMeta(state).isForbidden;

export const getRefereeFormRefereeName = state => selectFormMeta(state).refereeName;

export const getRefereesMergeToolIsEnabled = state => selectMergeTool(state).status !== null;

export const getRefereesMergeToolStatus = state => selectMergeTool(state).status;

export const getRefereesMergeToolRefereesList = state => {
  const data = selectListData(state).referees;
  const { ids, winnerId } = selectMergeTool(state);

  return ids.reduce((result, id) => {
    const isWinner = winnerId === id;

    return [
      ...result,
      {
        ...data[id],
        id,
        isWinner
      }
    ];
  }, []);
};

export const getRefereesMergeToolWinnerId = state => selectMergeTool(state).winnerId;

export const getRefereesMergeToolWinner = state =>
  getRefereesMergeToolRefereesList(state).find(({ id }) => id === selectMergeTool(state).winnerId);

export const getRefereesMergeToolRefereeIds = state => selectMergeTool(state).ids;

export const getRefereesMergeToolCountRefereesSelected = state => getRefereesMergeToolRefereeIds(state).length;

export const getRefereesMergeToolSeasonId = state => selectMergeTool(state).seasonId;
