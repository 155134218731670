import { useCallback } from "react";
import { useSelector } from "react-redux";

import {
  getPenaltyTypeReportIsLoading,
  getPenaltyTypeReportIsLoaded,
  getPenaltyTypeReportSummaryStats,
  getPenaltyTypeReportAssessedPenalties
} from "@/redux/penaltyTypeReport/selectors";

export default function useReport() {
  const isLoading = useSelector(getPenaltyTypeReportIsLoading);
  const isLoaded = useSelector(getPenaltyTypeReportIsLoaded);
  const summaryStats = useSelector(getPenaltyTypeReportSummaryStats);
  const assessedPenalties = useSelector(getPenaltyTypeReportAssessedPenalties);
  const triggerPrint = useCallback(() => window.print(), []);

  return {
    isLoaded,
    isLoading,
    summaryStats,
    assessedPenalties,
    triggerPrint
  };
}
