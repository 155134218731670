import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { loadingRoutine } from "@/redux/invitationForm/routines";
import actions from "@/redux/invitationForm/actions";

import useForm from "@/components/InvitationForm/hooks/useForm";

export default function useInvitationForm(code) {
  const dispatch = useDispatch();

  const { isLoading, isLoaded } = useForm();

  useEffect(
    () => {
      if (code) {
        dispatch(loadingRoutine(code));
      }

      return () => dispatch(actions.reset());
    },
    [dispatch, code]
  );

  return {
    isLoaded,
    isLoading
  };
}
