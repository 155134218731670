import React from "react";
import PropTypes from "prop-types";

import ContentSection from "@/components/ContentSection";

import useFairPlayPoints from "./hooks/useFairPlayPoints";

import FairPlayPointInput from "./FairPlayPointInput";
import TotalPenaltyMinutesText from "./TotalPenaltyMinutesText";

function FairPlayPoints({ team }) {
  const { teamTitle, totalPenaltyMinutes, fairPlayEarned, changeFairPlayEarned } = useFairPlayPoints({ team });

  return (
    <ContentSection title={`${team.toUpperCase()} – ${teamTitle}`}>
      <TotalPenaltyMinutesText totalPenaltyMinutes={totalPenaltyMinutes} />
      <FairPlayPointInput
        id={`fair-play-input-${team}`}
        name={`${team}FppEarned`}
        value={fairPlayEarned}
        onChange={changeFairPlayEarned}
      />
    </ContentSection>
  );
}

FairPlayPoints.propTypes = {
  team: PropTypes.oneOf(["home", "visitor"]).isRequired
};

export default FairPlayPoints;
