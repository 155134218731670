import { handleActions } from "redux-actions";

import { penaltyAccumulationReportLoadingRoutine } from "../routines";
import actions from "../actions";

const initialState = {
  isLoaded: false,
  isLoading: false,
  isForbidden: false,
  data: {},
  season: {}
};

function reduceRows(rows) {
  const out = {};
  if (rows) {
    Object.keys(rows).forEach(key => {
      out[key] = { ...rows[key].attributes };
    });
  }
  return out;
}

function reduceLoadingRequest(state) {
  return { ...state, isLoading: true };
}

function reduceLoadingSuccess(state, { payload: { accumulatedPenaltyReports, season } }) {
  
  console.log("data1", accumulatedPenaltyReports);
    const data = reduceRows(accumulatedPenaltyReports);
    console.log("data2", data);

  return {
    ...state,
    data,
    season,
    isLoaded: true
  };
}

function reduceLoadingFailure(state, { payload: { responseStatus } }) {
  return { ...state, isLoaded: false, isForbidden: responseStatus === 401 };
}

function reduceLoadingFulfill(state) {
  return { ...state, isLoading: false };
}

function reduceClearing(state) {
  return { ...initialState };
}

export default handleActions(
  {
    [penaltyAccumulationReportLoadingRoutine.REQUEST]: reduceLoadingRequest,
    [penaltyAccumulationReportLoadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [penaltyAccumulationReportLoadingRoutine.FAILURE]: reduceLoadingFailure,
    [penaltyAccumulationReportLoadingRoutine.FULFILL]: reduceLoadingFulfill,
    [actions.report.clear]: reduceClearing
  },
  initialState
);
