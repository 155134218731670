import { all, takeLatest, put, call, select, race, take, actionChannel } from "redux-saga/effects";

import { buffers } from "redux-saga";

import build from "redux-object";

import { loadApiKeys, updateApiKey } from "@/lib/api/apiKeys";

import { gamesheetAPIRequest } from "@/redux/api/sagas";
import takeConcurrently from "@/redux/common/takeConcurrently";

import {
  seasonIpadKeysManagerKeysLoadingRoutine,
  ipadKeysManagerAddingSeasonToKeysListRoutine,
  ipadKeysManagerAddingSeasonToKeyRoutine
} from "./routines";

import { getSeasonIpadKeysManagerSeasonId, getSeasonIpadKeysManagerSelectedKeys } from "./selectors";
import buildIpadKeyRoles from "@/lib/core/buildIpadKeyRoles";

function* loadList({ payload: { associationId } }) {
  yield put(seasonIpadKeysManagerKeysLoadingRoutine.request());

  try {
    const { data } = yield call(gamesheetAPIRequest, loadApiKeys, {
      associationId
    });

    const keys = build(data, "apiKeys") || [];

    yield put(seasonIpadKeysManagerKeysLoadingRoutine.success({ keys }));
  } catch (e) {
    yield put(seasonIpadKeysManagerKeysLoadingRoutine.failure());
  } finally {
    yield put(seasonIpadKeysManagerKeysLoadingRoutine.fulfill());
  }
}

function* addSeasonToKey({ payload }) {
  const { key, seasonId } = payload;
  const keyId = key.id;
  const { value, description, seasonIds: currentSeasonIds, liveScoringScopes } = key;
  const nextSeasonIds = [...currentSeasonIds, seasonId];

  yield put(ipadKeysManagerAddingSeasonToKeyRoutine.request({ keyId }));

  try {
    const roles = buildIpadKeyRoles(nextSeasonIds);

    const attributes = { value, description, roles, liveScoringScopes };

    const { data } = yield call(gamesheetAPIRequest, updateApiKey, {
      identity: { id: keyId },
      attributes
    });

    const apiKey = build(data, "apiKeys", keyId);

    yield put(
      ipadKeysManagerAddingSeasonToKeyRoutine.success({
        keyId,
        apiKey
      })
    );
  } catch (e) {
    yield put(ipadKeysManagerAddingSeasonToKeyRoutine.failure({ keyId }));
  } finally {
    yield put(ipadKeysManagerAddingSeasonToKeyRoutine.fulfill({ keyId }));
  }
}

function* addSeasonToKeysList() {
  yield put(ipadKeysManagerAddingSeasonToKeysListRoutine.request());

  const successChannel = yield actionChannel(ipadKeysManagerAddingSeasonToKeyRoutine.SUCCESS, buffers.sliding(10));

  const fulfillChannel = yield actionChannel(ipadKeysManagerAddingSeasonToKeyRoutine.FULFILL, buffers.sliding(10));

  const seasonId = yield select(getSeasonIpadKeysManagerSeasonId);

  const keys = yield select(getSeasonIpadKeysManagerSelectedKeys);

  yield all(
    keys.map(key =>
      put(
        ipadKeysManagerAddingSeasonToKeyRoutine({
          key,
          seasonId
        })
      )
    )
  );

  const { success } = yield race({
    success: all(keys.map(() => take(successChannel))),
    fulfill: all(keys.map(() => take(fulfillChannel)))
  });

  if (success) {
    yield put(ipadKeysManagerAddingSeasonToKeysListRoutine.success());
  } else {
    yield put(ipadKeysManagerAddingSeasonToKeysListRoutine.failure());
  }

  yield put(ipadKeysManagerAddingSeasonToKeysListRoutine.fulfill());
}

export function* seasonIpadKeysManagerFlow() {
  yield all([
    takeLatest(seasonIpadKeysManagerKeysLoadingRoutine.TRIGGER, loadList),
    takeConcurrently(ipadKeysManagerAddingSeasonToKeyRoutine.TRIGGER, addSeasonToKey),
    takeLatest(ipadKeysManagerAddingSeasonToKeysListRoutine.TRIGGER, addSeasonToKeysList)
  ]);
}
