import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Switch } from "react-router-dom";

import LoggedInRoute from "@/containers/LoggedInRoute";

import RefereeReportSearchPage from "./SearchPage";
import RefereeReportPage from "./ReportPage";

class ReportsRoute extends PureComponent {
  static propTypes = {
    match: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired
  };

  render() {
    const { match } = this.props;

    return (
      <Switch>
        <LoggedInRoute path={`${match.url}/:externalId`} component={RefereeReportPage} />

        <LoggedInRoute path="" component={RefereeReportSearchPage} />
      </Switch>
    );
  }
}

export default ReportsRoute;
