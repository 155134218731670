import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getTokenRoles } from "@/redux/token/selectors";
import { getTeamResources } from "@/redux/ability/selectors";

export function useTryTeamDashboard() {

  const roles = useSelector(getTokenRoles);
  const teams = useSelector(getTeamResources);

  const teamManagerIds = roles
    .filter(role => role.title === "manager" && role.level.type === "teams")
    .map(role => role.level.id);
  const isTeamManager = teamManagerIds
    .some(id => id in teams && teams[id].isPrefetched); // team is not archived or deleted

  const isIframe = window.self !== window.top;
  const shouldShowBeacon = !isIframe && isTeamManager

  useEffect(() => {
    shouldShowBeacon && window.Beacon && window.Beacon('show-message', 'ab300854-fe0a-4f7c-a7ea-eb5e9af6af96')
  }, [shouldShowBeacon])

  useEffect(() => {
    
    // only show on teams pages
    if (!/^https?:\/\/.*\/seasons\/\d+\/teams\/\d+/gm.test(window.location.href)) {
      return
    }
    shouldShowBeacon && window.Beacon && window.Beacon('show-message', 'be95c177-22db-46c4-8fc9-41833e9aea87')

  }, [shouldShowBeacon, window.location.href])

  return { isTeamManager };
}