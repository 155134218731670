import { useSelector } from "react-redux";

import createAbility from "@/createAbility";
import { getAbilityRules } from "@/redux/ability/selectors";

export default function useAbility() {
  const rules = useSelector(getAbilityRules);
  const ability = createAbility(rules);

  return ability;
}
