import { connect } from "react-redux";

import SeasonIpadKeysManagerKey from "./Key";
import { toggleSeasonIpadKeysManagerKeySelected } from "@/redux/seasonIpadKeysManager/actions";

const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleSelected: () => dispatch(toggleSeasonIpadKeysManagerKeySelected({ keyId: ownProps.id }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SeasonIpadKeysManagerKey);
