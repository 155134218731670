import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getMeta, getIpadKey, loadingRoutine } from "@/redux/currentIpadKey";
import useCurrentSeasonContext from "./useCurrentSeasonContext";

export default function useCurrentIpadKey({ seasonId, keyId }) {
  const dispatch = useDispatch();

  const {
    isLoaded: ipadKeyIsLoaded,
    isLoading: ipadKeyIsLoading,
    isForbidden: ipadKeyIsForbidden,
    isFailed: ipadKeyIsFailed
  } = useSelector(getMeta);

  const ipadKey = useSelector(getIpadKey);
  const { season, seasonIsLoaded } = useCurrentSeasonContext();
  const association = season.association;
  const league = season.league;
  const associationId = association.id;
  const leagueId = league.id;

  const { value, description, roles, liveScoringScopes, seasons } = ipadKey;

  const ipadKeyFormFields = {
    value,
    description,
    roles,
    liveScoringScopes
  };

  const ipadKeyBreadcrumbs =
    ipadKeyIsLoaded && seasonIsLoaded
      ? [
          { title: "Associations", url: "/associations" },
          {
            title: association.title,
            url: `/associations/${associationId}/leagues`
          },
          {
            title: league.title,
            url: `/leagues/${leagueId}/seasons`
          },
          {
            title: season.title,
            url: `/seasons/${seasonId}`
          },
          {
            title: "Scoring Access Keys",
            url: `/seasons/${seasonId}/ipad-keys`
          },
          {
            title: value
          }
        ]
      : [];

  useEffect(
    () => {
      if (!ipadKeyIsFailed && !ipadKeyIsLoaded && !ipadKeyIsLoading) {
        dispatch(loadingRoutine({ keyId }));
      }
    },
    [dispatch, keyId, ipadKeyIsFailed, ipadKeyIsLoaded, ipadKeyIsLoading]
  );

  return {
    ipadKey,
    keyId,
    ipadKeyValue: value,
    ipadKeySeasons: seasons,
    ipadKeyIsLoading,
    ipadKeyIsLoaded,
    ipadKeyIsForbidden,
    ipadKeyIsFailed,
    ipadKeyBreadcrumbs,
    ipadKeyFormFields
  };
}
