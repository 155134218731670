import { all, takeLatest, put, actionChannel, take, select, takeEvery } from "redux-saga/effects";
import { buffers } from "redux-saga";
import build from "redux-object";

import { loadTeam } from "@/lib/api/teams";
import { loadSubscriptions, deleteSubscription, createSubscription } from "@/lib/api/subscriptions";

import { fetchOne, fetchList, takeConcurrently, gamesheetAPIRequest } from "@/redux";

import {
  loadingRoutine,
  teamsLoadingRoutine,
  subscriptionsLoadingRoutine,
  teamLoadingRoutine,
  enableSubscriptionRoutine,
  disableSubscriptionRoutine
} from "./routines";

import actions from "./actions";

import { getUserTeamIds, getSubscriptionIdByTeamId, getDisabledTeamIds, getEnabledTeamIds } from "./selectors";

function* subscriptionsLoadingSaga() {
  yield put(subscriptionsLoadingRoutine.request());

  try {
    const { postGameEmailSubscriptions } = yield fetchList("postGameEmailSubscriptions", loadSubscriptions);

    yield put(subscriptionsLoadingRoutine.success({ postGameEmailSubscriptions }));
  } catch (error) {
    yield put(subscriptionsLoadingRoutine.failure());
  } finally {
    yield put(subscriptionsLoadingRoutine.fulfill());
  }
}

function* teamLoadingSaga({ payload: { teamId } }) {
  yield put(teamLoadingRoutine.request({ teamId }));

  try {
    const [team] = yield fetchOne({ type: "teams", id: teamId }, loadTeam, {
      include: "division,season",
      skipAbilitiesUpdate: true
    });

    yield put(teamLoadingRoutine.success({ team }));
  } catch (error) {
    yield put(teamLoadingRoutine.failure({ teamId, error }));
  } finally {
    yield put(teamLoadingRoutine.fulfill({ teamId }));
  }
}

function* teamsLoadingSaga() {
  yield put(teamsLoadingRoutine.request());

  const teamIds = yield select(getUserTeamIds);

  const fulfillChanel = yield actionChannel(teamLoadingRoutine.FULFILL, buffers.dropping(teamIds.length));

  yield all(teamIds.map(teamId => put(teamLoadingRoutine({ teamId }))));
  yield all(teamIds.map(() => take(fulfillChanel)));

  yield put(teamsLoadingRoutine.success());
  yield put(teamsLoadingRoutine.fulfill());
}

function* loadingSaga() {
  yield put(loadingRoutine.request());

  const subscriptionsLoadingFulfill = yield actionChannel(subscriptionsLoadingRoutine.fulfill);

  const teamsLoadingFulfill = yield actionChannel(teamsLoadingRoutine.fulfill);

  yield put(subscriptionsLoadingRoutine());
  yield put(teamsLoadingRoutine());

  yield all([take(teamsLoadingFulfill), take(subscriptionsLoadingFulfill)]);

  yield put(loadingRoutine.success());
  yield put(loadingRoutine.fulfill());
}

function* enableSubscriptionSaga({ payload: { teamId } }) {
  yield put(enableSubscriptionRoutine.request({ teamId }));

  try {
    const { data } = yield gamesheetAPIRequest(createSubscription, { teamId }, true);

    const postGameEmailSubscription = build(data, "postGameEmailSubscriptions")[0];

    yield put(enableSubscriptionRoutine.success({ teamId, postGameEmailSubscription }));
  } catch (error) {
    yield put(enableSubscriptionRoutine.failure({ teamId }));

    throw new Error("Failed to enable team subscription due to unknown error");
  } finally {
    yield put(enableSubscriptionRoutine.fulfill({ teamId }));
  }
}

function* disableSubscriptionSaga({ payload: { teamId } }) {
  yield put(disableSubscriptionRoutine.request({ teamId }));

  try {
    const subscriptionId = yield select(getSubscriptionIdByTeamId, teamId);

    yield gamesheetAPIRequest(deleteSubscription, { subscriptionId }, true);

    yield put(disableSubscriptionRoutine.success({ teamId }));
  } catch (error) {
    yield put(disableSubscriptionRoutine.failure({ teamId, error }));

    throw new Error("Failed to disable team subscription due to unknown error");
  } finally {
    yield put(disableSubscriptionRoutine.fulfill({ teamId }));
  }
}

function* disableAllSaga() {
  const teamIds = yield select(getEnabledTeamIds);

  yield all(teamIds.map(teamId => put(disableSubscriptionRoutine({ teamId }))));
}

function* enableAllSaga() {
  const teamIds = yield select(getDisabledTeamIds);

  yield all(teamIds.map(teamId => put(enableSubscriptionRoutine({ teamId }))));
}

export function* gameReportEmailNotificationSettingsFlow() {
  yield all([
    takeLatest(subscriptionsLoadingRoutine.TRIGGER, subscriptionsLoadingSaga),
    takeConcurrently(teamLoadingRoutine.TRIGGER, teamLoadingSaga, 10),
    takeLatest(teamsLoadingRoutine.TRIGGER, teamsLoadingSaga),
    takeLatest(loadingRoutine.TRIGGER, loadingSaga),
    takeEvery(enableSubscriptionRoutine.TRIGGER, enableSubscriptionSaga),
    takeEvery(disableSubscriptionRoutine.TRIGGER, disableSubscriptionSaga),
    takeLatest(actions.disableAll, disableAllSaga),
    takeLatest(actions.enableAll, enableAllSaga)
  ]);
}
