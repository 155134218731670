import { createActions } from "redux-actions";

const { trainerVisitsReport } = createActions({
  TRAINER_VISITS_REPORT: {
    REPORT: {
      CLEAR: undefined
    }
  }
});

export default trainerVisitsReport;
