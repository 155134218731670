import _camelCase from "lodash/camelCase";
import _trim from "lodash/trim";
import { handleActions } from "redux-actions";

import { refereesActions } from "../../actions";

import {
  refereeFormFieldValidationRoutine,
  refereeFormValidationRoutine,
  refereeFormSubmittingRoutine,
  refereeFormLoadingRoutine
} from "../../routines";

const FIELDS_MAPPING = {
  firstName: "First name",
  lastName: "Last name",
  emailAddress: "Email Address",
  externalId: "External ID"
};

const initialState = {
  firstName: {
    initialValue: "",
    value: "",
    isDirty: false,
    errors: []
  },
  lastName: {
    initialValue: "",
    value: "",
    isDirty: false,
    errors: []
  },
  emailAddress: {
    initialValue: "",
    value: "",
    isDirty: false,
    errors: []
  },
  externalId: {
    initialValue: "",
    value: "",
    isDirty: false,
    errors: []
  }
};

function reduceFieldChange(state, { payload: { field, value } }) {
  return {
    ...state,
    [field]: {
      ...state[field],
      isDirty: _trim(value) !== _trim(state[field].initialValue),
      value
    }
  };
}

function reduceFieldValidationSuccess(state, { payload: { field } }) {
  return { ...state, [field]: { ...state[field], errors: [] } };
}

function reduceFieldValidationFailure(state, { payload: { field, errors } }) {
  return { ...state, [field]: { ...state[field], errors } };
}

function reduceFormValidationSuccess(state) {
  return Object.keys(state).reduce((result, field) => {
    return { ...result, [field]: { ...state[field], errors: [] } };
  }, {});
}

function reduceFormValidationFailure(state, { payload: { errors } }) {
  return {
    ...state,
    ...Object.keys(errors).reduce(
      (result, field) => ({
        ...result,
        [field]: { ...state[field], errors: errors[field] }
      }),
      {}
    )
  };
}

function reduceSubmitFailure(state, { payload }) {
  if (payload && payload.response) {
    const {
      status,
      data: { errors }
    } = payload.response;

    if (status === 400 && errors) {
      return {
        ...state,
        ...errors.reduce((result, { title, source: { pointer } }) => {
          const field = _camelCase(pointer.match(/\/data\/attributes\/(\w+)/)[1]);

          if (!field) {
            return { ...result };
          }

          const fieldName = FIELDS_MAPPING[field];

          return {
            ...result,
            [field]: {
              ...state[field],
              isDirty: false,
              errors: [...state[field].errors, `${fieldName} ${title}`]
            }
          };
        }, {})
      };
    }
  }

  return { ...state };
}

function reduceLoadingSuccess(state, { payload }) {
  const {
    referee: { firstName, lastName, emailAddress, externalId }
  } = payload;

  return {
    ...state,
    firstName: {
      ...state.firstName,
      initialValue: firstName,
      value: firstName
    },
    lastName: {
      ...state.lastName,
      initialValue: lastName,
      value: lastName
    },
    emailAddress: {
      ...state.emailAddress,
      initialValue: emailAddress,
      value: emailAddress
    },
    externalId: {
      ...state.externalId,
      initialValue: externalId,
      value: externalId
    }
  };
}

export default handleActions(
  {
    [refereesActions.form.changeField]: reduceFieldChange,
    [refereeFormFieldValidationRoutine.SUCCESS]: reduceFieldValidationSuccess,
    [refereeFormFieldValidationRoutine.FAILURE]: reduceFieldValidationFailure,
    [refereeFormValidationRoutine.SUCCESS]: reduceFormValidationSuccess,
    [refereeFormValidationRoutine.FAILURE]: reduceFormValidationFailure,
    [refereeFormSubmittingRoutine.FAILURE]: reduceSubmitFailure,
    [refereeFormLoadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [refereesActions.form.clear]: () => ({ ...initialState })
  },
  initialState
);
