import { useCallback } from "react";

import { TIE_BREAKERS, SOCCER_TIE_BREAKERS } from "../constants";

export default function useTieBreakers({ value, onChange, sport }) {
  const _TIE_BREAKERS = sport === "soccer" ? SOCCER_TIE_BREAKERS : TIE_BREAKERS;

  const reorderValue = useCallback(
    ({ source, destination }) => {
      if (
        destination &&
        destination.index !== source.index &&
        _TIE_BREAKERS[destination.index].enabled
      ) {
        const dragged = mashupTieBreakerKeys(value, _TIE_BREAKERS)[source.index];

        let nextValue = mashupTieBreakerKeys(value, _TIE_BREAKERS).slice();

        nextValue.splice(source.index, 1);
        nextValue.splice(destination.index, 0, dragged);

        onChange(nextValue);
      }
    },
    [onChange, value]
  );

  function mashupTieBreakerKeys(fromSettings, fromApp) {
    const keys = fromApp.map((item) => item.title);

    const legacyRemovedSettings = removeLegacyHeadToHeadAndReplaceWithNewHeadToHead(fromSettings)

    const newKeys = keys.filter((key) => !legacyRemovedSettings.includes(key));

    return [...legacyRemovedSettings, ...newKeys];
  }

  function removeLegacyHeadToHeadAndReplaceWithNewHeadToHead(array){
    const headToHeadIndex = array.findIndex(tieBreaker => tieBreaker === "head_to_head")
    array[headToHeadIndex] = "h2h_points"
    return array
  }

  return {
    tieBreakers: mashupTieBreakerKeys(value, _TIE_BREAKERS).map(
      (element) =>
        _TIE_BREAKERS.find(({ title }) => element === title) || {
          title: element,
          label: "Unknown",
        }
    ),
    onDragEnd: reorderValue,
  };
}
