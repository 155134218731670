import { all, put, takeLatest } from "redux-saga/effects";

import { createPlayerOfTheGame } from "@/lib/api/games";
import { responseErrorsFullMessages } from "@/lib/api/utils";
import { gamesheetAPIRequest } from "@/redux/api/sagas";

import { FIELDS_MAPPING } from "@/components/PlayerOfTheGameForm";

import { submittingRoutine } from "./routines";

function* submittingSaga({ payload }) {
  const { seasonId, gameId, values } = payload;

  yield put(submittingRoutine.request());

  try {
    yield gamesheetAPIRequest(
      createPlayerOfTheGame,
      {
        attributes: values,
        seasonId,
        gameId
      },
      true
    );

    yield put(submittingRoutine.success());
  } catch (error) {
    const { response } = error;
    const validationErrors = response ? responseErrorsFullMessages(response, FIELDS_MAPPING) : {};

    yield put(submittingRoutine.failure({ error, validationErrors }));
  } finally {
    yield put(submittingRoutine.fulfill());
  }
}

export function* playerOfTheGameFormFlow() {
  yield all([takeLatest(submittingRoutine, submittingSaga)]);
}
