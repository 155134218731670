import { useRecoilState } from 'recoil';

import Service from "@/services";
import { ImportStatus } from "@/components-16.8/RefereeImportWizard/types/importStatus";

import {
    importStatusState,
    recordsState,
    importingErrorState,
    importProgressState
} from "@/components-16.8/RefereeImportWizard/state";

const defaultImportProgress = {
    pending: 0,
    requested: 0,
    success: 0,
    failed: 0
}

export function useImport({ seasonId }) {

    const [importStatus, setImportStatus] = useRecoilState(importStatusState);
    const [error, setError] = useRecoilState(importingErrorState);
    const [records, setRecords] = useRecoilState(recordsState);
    const [importProgress, setImportProgress] = useRecoilState(importProgressState);

    const handleResetImport = () => {
        setError(null);
        setRecords([]);
        setImportProgress(defaultImportProgress);
        setImportStatus(ImportStatus.PENDING);
    };

    const formatRecord = (record) => {
        return {
            email_address: record.emailAddress,
            first_name: record.firstName,
            last_name: record.lastName,
            external_id: record.externalId
        }
    }

    const handleStartImport = async () => {
        setError(null);
        setImportStatus(ImportStatus.IMPORTING);

        const imports = records.map(record => () => Service.api().seasons.season(seasonId).referees.import.post({ data: { attributes: { ...formatRecord(record) } } }));

        setImportProgress({ ...defaultImportProgress, pending: imports.length });

        const updateProgress = (queue) => {
            setImportProgress((old) => ({
                ...old,
                pending: queue.pending.length,
                requested: queue.sent.length - (queue.resolved.length + queue.rejected.length),
                success: queue.resolved.length,
                failed: queue.rejected.length
            }));
        }

        await new Promise((resolve) => {
            Service.asyncCommandQueue({
                MAX_CONCURRENT: 6,
                requests: [...imports],
                onResolve: () => { },
                onProgress: updateProgress,
                onDone: (queue) => {
                    resolve(queue);
                    setImportStatus(ImportStatus.IMPORT_SUCCESS);
                }
            })
        }).catch(e => {
            setImportStatus(ImportStatus.IMPORT_ERROR);
        });
    };

    return {
        handleResetImport,
        handleStartImport,
        refereeCount: records.length,
        progress: importProgress
    };

}
