import { createRoutine } from "redux-saga-routines";

export const rosterParsingRoutine = createRoutine("CSV_ROSTER/PARSING");
export const rosterImportingRoutine = createRoutine("CSV_ROSTER/IMPORTING");
export const teamLoadingRoutine = createRoutine("CSV_ROSTER/TEAM/LOADING");

export const playersListImportingRoutine = createRoutine("CSV_ROSTER/PLAYERS_LIST/IMPORTING");

export const coachesListImportingRoutine = createRoutine("CSV_ROSTER/COACHES_LIST/IMPORTING");

export const playerImportingRoutine = createRoutine("CSV_ROSTER/PLAYER/IMPORTING");

export const coachImportingRoutine = createRoutine("CSV_ROSTER/COACH/IMPORTING");

export const teamsListUpdatingRoutine = createRoutine("CSV_ROSTER/TEAMS_LIST/UPDATING");

export const teamUpdatingRoutine = createRoutine("CSV_ROSTER/TEAM/UPDATING");
