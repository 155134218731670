import { createActions } from "redux-actions";

const { gameForm: gameFormActions } = createActions({
  GAME_FORM: {
    CHANGE_FIELD: undefined,
    CHANGE_PENALTY: undefined,
    CHANGE_FAIR_PLAY_EARNED: undefined,
    ADD_PENALTY: undefined,
    REMOVE_PENALTY: undefined,
    CHANGE_GOAL: undefined,
    ADD_GOAL: undefined,
    REMOVE_GOAL: undefined,
    CHANGE_GOALIE_SHIFT: undefined,
    ADD_GOALIE_SHIFT: undefined,
    REMOVE_GOALIE_SHIFT: undefined,
    SET_SHOTS: undefined,
    CHANGE_SHOOTOUT: undefined,
    ADD_SHOOTOUT: undefined,
    REMOVE_SHOOTOUT: undefined,
    UPDATE_PLAYER: undefined,
    ADD_PLAYER: undefined,
    REMOVE_PLAYER: undefined,
    SET_SEARCH_PLAYER: undefined,
    CLEAR_SEARCH_PLAYER: undefined,
    ADD_ERROR: undefined,
    REMOVE_ERROR: undefined,
    CLEAR: undefined
  }
});

export default gameFormActions;
