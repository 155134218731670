import React, { Fragment } from 'react';
import PageLoader from "@/components/PageLoader";
import Filter from './components/Filter';
import List from './components/List';
import Footer from './components/Footer';
import { useScheduledGames } from './useScheduledGames';
import Pagination from './components/Pagination'

function ScheduledGames({ season, exportTriggered }) {
  const { seasonId, seasonTitle } = useScheduledGames({ season });

  return (
    <div>
      {seasonId === null
        ? (<PageLoader isLoading />)
        : (
        <Fragment>
          <Filter seasonId={seasonId} />
          <List seasonId={seasonId} seasonTitle={seasonTitle} exportTriggered={exportTriggered} />
          <Pagination seasonId={seasonId} />
          <Footer seasonId={seasonId} />
        </Fragment>
      )}
    </div>
  );
}

export default ScheduledGames;