import { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";

import { listLoadingRoutine } from "@/redux/userList/routines";
// import actions from "@/redux/userList/actions";

export default function useAssociationList() {
  const dispatch = useDispatch();

  const loadMoreUsers = useCallback(() => dispatch(listLoadingRoutine({ append: true })), [dispatch]);

  const loadUsers = useCallback(() => dispatch(listLoadingRoutine()), [dispatch]);

  useEffect(
    () => {
      loadUsers();
    },
    [loadUsers]
  );

  return { loadMoreUsers };
}
