import React from "react";
import PropTypes from "prop-types";

import { defaultTeamLogo } from "@/constants/defaultImageUrls";
import DefaultPlayerIcon from "@/constants/DefaultPlayerIcon.js";
import "../invitation.scss";

export const TeamReview = (props) => {
    const {
        teamInfo,
    } = props;

    const setPlayerImg = (url) => {
        if (url.includes("google")) {
            return url;
        } else {
            return `${url}/96`;
        }
    }

    const formatPlayerDuty = (duty) => {
        if (!duty) {
            return "-";
        } else {
            return duty
                .split("_")
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ");
        }
    }

    const sortPlayers = (players) => {
        return players ? players.sort((a, b) => parseInt(a.number) - parseInt(b.number)) : [];
    }

    return (
        <div>
            <div className="team-container">
                <div className="team-picture-container">
                    <img src={`${teamInfo.logo ? `${teamInfo.logo}/128` : `${defaultTeamLogo}/96`}`} />
                </div>
                <div className="team-content-container">
                    <div className="team-name">{teamInfo.title}</div>
                    <div className="team-content">Season: {teamInfo.season.title}</div>
                    <div className="team-content">Division: {teamInfo.division.title}</div>
                </div>
            </div>
            <div className="roster-container">
                {sortPlayers(teamInfo.players).map((player, i) => (
                    <div className="player-container" key={player.id || i}>
                        <div className="player-picture-container">
                            {player.photo ? <img src={`${setPlayerImg(player.photo)}`} /> : <DefaultPlayerIcon width={48} height={48} />}
                        </div>
                        <div className="player-details">
                            <div className="player-name">{player.firstName} <span className="bold">{player.lastName}</span></div>
                            {!!player.duty && <div className="player-duty">{formatPlayerDuty(player.duty)}</div>}
                        </div>
                        <div className="player-number">{player.number ? `#${player.number}` : ""}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

TeamReview.propTypes = {
    teamInfo: PropTypes.shape({
        logo: PropTypes.string,
        title: PropTypes.string,
        players: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            photo: PropTypes.string,
            number: PropTypes.string,
            duty: PropTypes.string,
        })),
        season: PropTypes.shape({
            title: PropTypes.string,
        }),
        division: PropTypes.shape({
            title: PropTypes.string,
        }),
    }),
};