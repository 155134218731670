import { connect } from "react-redux";

import PenaltyLengthInput from "./PenaltyLengthInput";
import { getGameFormPenaltyLengths } from "@/redux/gameForm/selectors";

const mapStateToProps = state => ({
  lengths: getGameFormPenaltyLengths(state)
});

export default connect(mapStateToProps)(PenaltyLengthInput);
