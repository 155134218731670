import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { ListGroup } from "reactstrap";

import SeasonIpadKeysManagerSelectedKey from "./Key";

class SeasonIpadKeysManagerSelectedKeysList extends PureComponent {
  static propTypes = {
    keys: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })),
    toggleSelected: PropTypes.func
  };

  static defaultProps = {
    keys: [],
    toggleSelected() {}
  };

  render() {
    const { keys, toggleSelected } = this.props;

    const countKeys = keys.length;

    if (countKeys < 1) {
      return null;
    }

    return (
      <div className="mt-4">
        <h4>Selected ({countKeys})</h4>

        <ListGroup flush>
          {keys.map(({ id, ...rest }) => (
            <SeasonIpadKeysManagerSelectedKey key={id} id={id} toggleSelected={toggleSelected} {...rest} />
          ))}
        </ListGroup>
      </div>
    );
  }
}

export default SeasonIpadKeysManagerSelectedKeysList;
