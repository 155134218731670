import React, { Component } from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";

import { Button } from "reactstrap";

import "./ErrorBoundaryMsg.scss";

class ErrorBoundaryMsg extends Component {
  reloadPage = () => window.location.reload(true);

  handleFeedbackButton = () => Sentry.showReportDialog();

  render() {
    return (
      <main className="root-error-boundary">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1 className="display-4 text-danger mb-5">Oops! Something went wrong.</h1>

              <h3>Why you’re seeing this?</h3>
              <p>
                The Dashboard has encountered an unexpected error. This error has been automatically reported to the
                issue tracker and our engineers are notified. <br />
                This is an emergency screen that gets displayed instead of normal UI in order to prevent inconsistent
                state and data corruption.
              </p>

              <h3 className="mt-5">What can you do?</h3>
              <p>
                You may want to{" "}
                <Button color="primary" outline size="sm" onClick={this.reloadPage}>
                  reload
                </Button>{" "}
                the current page or go back to <a href="/">the homepage</a>.
              </p>

              <h3 className="mt-5">Your feedback is important for us</h3>

              <p>Help us to make your user experience better and drop us a few lines about what happened.</p>

              <Button color="primary" onClick={this.handleFeedbackButton}>
                Leave Feedback
              </Button>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default ErrorBoundaryMsg;
