import { handleActions } from "redux-actions";

import { parsingRoutine, importingRoutine } from "../routines";
import actions from "../actions";

const initialState = {
  status: "pending",
  isParsingInProgress: false,
  isParsingFailed: false,
  isParsingSuccess: false,
  parsingError: "",
  isImportingInProgress: false,
};

function reduceParsingTrigger(state) {
  return {
    ...state,
    status: "parsing",
    parsingError: "",
    isParsingSuccess: false,
    isParsingFailed: false
  };
}

function reduceParsingRequest(state) {
  return { ...state, isParsingInProgress: true };
}

function reduceParsingSuccess(state) {
  return { ...state, isParsingSuccess: true };
}

function reduceParsingFailure(state, { payload: { error } }) {
  return { ...state, isParsingFailed: true, parsingError: error };
}

function reduceParsingFulfill(state) {
  return { ...state, isParsingInProgress: false };
}

function reduceImportingTrigger(state) {
  return { ...state, status: "importing" };
}

function reduceImportingRequest(state) {
  return { ...state, isImportingInProgress: true };
}

function reduceImportingFulfill(state) {
  return { ...state, status: "fulfilled", isImportingInProgress: false };
}

export default handleActions(
  {
    [parsingRoutine.TRIGGER]: reduceParsingTrigger,
    [parsingRoutine.REQUEST]: reduceParsingRequest,
    [parsingRoutine.SUCCESS]: reduceParsingSuccess,
    [parsingRoutine.FAILURE]: reduceParsingFailure,
    [parsingRoutine.FULFILL]: reduceParsingFulfill,
    [importingRoutine.TRIGGER]: reduceImportingTrigger,
    [importingRoutine.REQUEST]: reduceImportingRequest,
    [importingRoutine.FULFILL]: reduceImportingFulfill,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
