import React from "react";
import PropTypes from "prop-types";

import useAppendingEmptyCoach from "./hooks/useAppendingEmptyCoach";

import CreateMemberButton from "./CreateMemberButton";

function CreateCoachButton({ children, team, ...rest }) {
  const triggerEmptyCoachAdding = useAppendingEmptyCoach();

  return (
    <CreateMemberButton action="createCoach" onClick={triggerEmptyCoachAdding} teamId={team.id} {...rest}>
      {children}
    </CreateMemberButton>
  );
}

CreateCoachButton.propTypes = {
  children: PropTypes.node.isRequired,
  team: PropTypes.shape({
    id: PropTypes.string.isRequired,
    season: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default CreateCoachButton;
