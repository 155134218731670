import { put, takeLatest, all, select, takeEvery } from "redux-saga/effects";
import _snakeCase from "lodash/snakeCase";

import { loadAssociations } from "@/lib/api/associations";
import { loadLeagues } from "@/lib/api/leagues";
import { loadSeasons } from "@/lib/api/seasons";
import { loadDivisions } from "@/lib/api/divisions";

import { fetchList } from "@/redux/api/sagas";

import {
  associationListLoadingRoutine,
  leagueListLoadingRoutine,
  seasonListLoadingRoutine,
  divisionListLoadingRoutine
} from "./routines";

import actions from "./actions";

import {
  getLeagueListIsLoaded,
  getSeasonListIsLoaded,
  getDivisionListIsLoaded,
  getAssociationId,
  getLeagueListIsLoading,
  getSeasonListIsLoading,
  getDivisionListIsLoading,
  getYear,
  getSeasonFilterParams
} from "./selectors";

function* associationListLoadingSaga() {
  yield put(associationListLoadingRoutine.request());

  try {
    const { ids, associations } = yield fetchList("associations", loadAssociations);

    yield put(associationListLoadingRoutine.success({ ids, associations }));
  } catch (error) {
    yield put(associationListLoadingRoutine.failure({ error }));
  } finally {
    yield put(associationListLoadingRoutine.fulfill());
  }
}

function* leagueListLoadingSaga({ payload: associationId }) {
  const isLoaded = yield select(getLeagueListIsLoaded, associationId);
  const isLoading = yield select(getLeagueListIsLoading, associationId);

  if (isLoaded || isLoading || associationId === "") {
    return;
  }

  yield put(leagueListLoadingRoutine.request({ associationId }));

  try {
    const { ids, leagues } = yield fetchList("leagues", loadLeagues, {
      associationId
    });

    yield put(leagueListLoadingRoutine.success({ associationId, ids, leagues }));
  } catch (error) {
    yield put(leagueListLoadingRoutine.failure({ error, associationId }));
  } finally {
    yield put(leagueListLoadingRoutine.fulfill({ associationId }));
  }
}

function* seasonListLoadingSaga({ payload: leagueId }) {
  const associationId = yield select(getAssociationId);
  const year = yield select(getYear);
  const isLoaded = yield select(getSeasonListIsLoaded, leagueId);
  const isLoading = yield select(getSeasonListIsLoading, leagueId);

  if (isLoaded || isLoading || leagueId === "") {
    return;
  }

  yield put(seasonListLoadingRoutine.request({ leagueId }));

  const filterParams = yield select(getSeasonFilterParams);
  const filter = Object.entries(filterParams)
    .map(([key, value]) => [_snakeCase(key), value])
    .reduce((result, [key, value]) => ({ ...result, [key]: value }), {});

  try {
    const { ids, seasons } = yield fetchList("seasons", loadSeasons, {
      associationId,
      leagueId,
      filter
    });

    yield put(seasonListLoadingRoutine.success({ leagueId, ids, seasons }));
  } catch (error) {
    yield put(seasonListLoadingRoutine.failure({ error, leagueId }));
  } finally {
    yield put(seasonListLoadingRoutine.fulfill({ leagueId }));
  }
}

function* divisionListLoadingSaga({ payload: seasonId }) {
  const isLoaded = yield select(getDivisionListIsLoaded, seasonId);
  const isLoading = yield select(getDivisionListIsLoading, seasonId);

  if (isLoaded || isLoading || seasonId === "") {
    return;
  }

  yield put(divisionListLoadingRoutine.request({ seasonId }));

  try {
    const { ids, divisions } = yield fetchList("divisions", loadDivisions, {
      seasonId
    });

    yield put(divisionListLoadingRoutine.success({ seasonId, ids, divisions }));
  } catch (error) {
    yield put(divisionListLoadingRoutine.failure({ error, seasonId }));
  } finally {
    yield put(divisionListLoadingRoutine.fulfill({ seasonId }));
  }
}

export function* teamFilterFlow() {
  yield all([
    takeLatest(associationListLoadingRoutine, associationListLoadingSaga),
    takeEvery(actions.setAssociationId, leagueListLoadingSaga),
    takeEvery(actions.setLeagueId, seasonListLoadingSaga),
    takeEvery(actions.setSeasonId, divisionListLoadingSaga)
  ]);
}
