import React, { Fragment } from "react";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";
import useCurrentIpadKeyContext from "@/hooks/useCurrentIpadKeyContext";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import TitleBar from "@/components/TitleBar";
import Breadcrumbs from "@/components/Breadcrumbs";
import Head from "@/components/Head";
import PageLoader from "@/components/PageLoader";

import IpadKeyForm, { useIpadKeyFormClearing } from "@/components/IpadKeyForm";

function EditIpadKey() {
  const {
    keyId,
    ipadKeyValue,
    ipadKeyFormFields,
    ipadKeyIsForbidden,
    ipadKeyIsLoading,
    ipadKeyIsLoaded,
    ipadKeyBreadcrumbs
  } = useCurrentIpadKeyContext();

  const { seasonId, seasonIsLoaded, seasonIsLoading } = useCurrentSeasonContext();

  useIpadKeyFormClearing();

  return (
    <ProtectedLayout
      subject={{ type: "ipadKeys", id: keyId }}
      action="update"
      skip={!ipadKeyIsLoaded && !ipadKeyIsForbidden}
    >
      {seasonIsLoaded ? (
        ipadKeyIsLoaded ? (
          <Fragment>
            <Head title="Edit iPad Key" />
            <Breadcrumbs items={ipadKeyBreadcrumbs} />
            <TitleBar title={`Edit ${ipadKeyValue} Key`} />

            <IpadKeyForm initialValues={ipadKeyFormFields} seasonId={seasonId} keyId={keyId} />
          </Fragment>
        ) : (
          <PageLoader isLoading={ipadKeyIsLoading} />
        )
      ) : (
        <PageLoader isLoading={seasonIsLoading} />
      )}
    </ProtectedLayout>
  );
}

export default EditIpadKey;
