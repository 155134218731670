import React from "react";
import PropTypes from "prop-types";
import { Input, FormGroup } from "reactstrap";

import TitleBar from "@/components/TitleBar";

function PlayerStatsDisplay({ value, onChange }) {
  return (
    <FormGroup>
      <TitleBar slim title="Player Stats Display" titleTag="h4" />
      <Input type="select" onChange={onChange} value={value}>
        <option value="full_stats">Public</option>
        <option value="no_stats">Private (requires login)</option>
      </Input>
    </FormGroup>
  );
}

PlayerStatsDisplay.propTypes = {
  value: PropTypes.oneOf(["full_stats", "no_stats"]).isRequired,
  onChange: PropTypes.func.isRequired
};

export default PlayerStatsDisplay;
