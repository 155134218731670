import createOffsetPaginationReducer from "@/redux/common/pagination/createOffsetPaginationReducer";

import { listLoadingRoutine } from "../routines";
import actions from "../actions";

import users from "./users";

const pagination = createOffsetPaginationReducer(listLoadingRoutine, actions.clear);

export { users, pagination };
