import React from "react";

import useExpirationDate from "./hooks/useExpirationDate";

const labelByStatus = {
  active: "Expires",
  expired: "Expired"
};

function ExpirationDate() {
  const { status, permanent, dateTime } = useExpirationDate();

  const label = labelByStatus[status];

  return (
    <span>
      {label}
      :&nbsp;
      {permanent ? "never" : dateTime}
    </span>
  );
}

export default ExpirationDate;
