import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Table } from "reactstrap";

import CoachesListMergeToolCoach from "../Coach";

class CoachesListMergeToolTable extends PureComponent {
  static propTypes = {
    coaches: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired }))
  };

  static defaultProps = {
    coaches: []
  };

  render() {
    const { coaches } = this.props;

    return (
      <Table striped borderless className="coaches-list__merge-tool__table">
        <thead>
          <tr>
            <th scope="col">Full name</th>
            <th scope="col">External ID</th>
            <th scope="col">Team</th>
            <th scope="col">Division</th>
            <th scope="col">
              <span className="visuallyhidden">Keep</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {coaches.map(coach => (
            <CoachesListMergeToolCoach key={coach.id} {...coach} />
          ))}
        </tbody>
      </Table>
    );
  }
}

export default CoachesListMergeToolTable;
