import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Input } from "reactstrap";

class TeamGoalieInput extends PureComponent {
  static propTypes = {
    team: PropTypes.oneOf(["home", "visitor"]).isRequired,
    goalies: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        firstName: PropTypes.string,
        lastName: PropTypes.string
      })
    ),
    value: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    allowBlank: PropTypes.bool,
    blankLabel: PropTypes.string
  };

  static defaultProps = {
    goalies: [],
    value: "",
    allowBlank: true,
    blankLabel: "Empty net",
    onChange() {}
  };

  render() {
    const { id, name, value, goalies, onChange, allowBlank, blankLabel } = this.props;

    return (
      <Input type="select" id={id} name={name} value={value} onChange={onChange}>
        <option value="" disabled={!allowBlank}>
          {blankLabel}
        </option>
        {goalies.map(({ id, firstName, lastName, number }) => (
          <option key={id} value={id}>{`#${number} – ${firstName} ${lastName}`}</option>
        ))}
      </Input>
    );
  }
}

export default TeamGoalieInput;
