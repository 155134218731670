import React from "react";
import PropTypes from "prop-types";

import useAppendingEmptyPlayer from "./hooks/useAppendingEmptyPlayer";

import CreateMemberButton from "./CreateMemberButton";

function CreatePlayerButton({ children, team, ...rest }) {
  const triggerEmptyPlayerAdding = useAppendingEmptyPlayer();

  return (
    <CreateMemberButton action="createPlayer" onClick={triggerEmptyPlayerAdding} teamId={team.id} {...rest}>
      {children}
    </CreateMemberButton>
  );
}

CreatePlayerButton.propTypes = {
  children: PropTypes.node.isRequired,
  team: PropTypes.shape({
    id: PropTypes.string.isRequired,
    season: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default CreatePlayerButton;
