import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";

import { FormGroup, Label, Input, CustomInput, FormFeedback } from "reactstrap";
import ContentSection from "@/components/ContentSection";
import DashboardForm from "@/components/DashboardForm";
import DashboardFormFooter from "@/components/DashboardFormFooter";
import FormItem from "@/components/FormItem";
import useConfirmDeleteModal from "@/components-16.8/ConfirmDeleteModal/index";
import AbilityButton from "@/components/AbilityButton";
import { useDeleteProtection } from "../DivisionsList/hooks/useDeleteProtection";

import { useDivisionForm } from "./hooks";

import GameGeneralSettingsFieldset from "@/components/GameGeneralSettingsFieldset";
import GameStatsSettingsFieldset from "@/components/GameStatsSettingsFieldset";
import { DivisionPenaltySettingsFieldset } from "@/components/GamePenaltySettingsFieldsets/DivisionPenaltySettings";
import { useDivisionsList } from "../DivisionsList";


function DivisionForm(props) {
  const { initialValues, seasonGameSettings, seasonId, divisionId, sport } = props;

  const {
    fields,
    submit,
    deleteDivision,
    isPristine,
    isInvalid,
    isSubmitting,
    isDeleting,
    toggleUsingSeasonSettings,
    reset
  } = useDivisionForm({
    seasonGameSettings,
    initialValues,
    seasonId,
    divisionId
  });

  const { toggle, DeleteModal } = useConfirmDeleteModal({ onDelete: deleteDivision });
  const { toggle: toggleDeleteProtection, DeleteProtectionModal } = useDeleteProtection();

  const { divisions } = useDivisionsList();

  const handleDelete = () => {

    const division = divisions.find(division => division.id === divisionId);

    const numberOfTeamsInDivision = division ? division.numTeams : 0;

    if (numberOfTeamsInDivision > 0) {
      toggleDeleteProtection(); 
    } else {
      toggle(); 
    }
  };

  const handleSubmit = ({goBack = true})=> {
    submit({goBack})
    if(!goBack){
      reset()
    }
  }
  
  return (
    <DashboardForm
      className="division-form"
      namespace="division"
      isOperating={isSubmitting || isDeleting}
      onSubmit={submit}
    >
      <ContentSection>
        <FormItem>
          <FormGroup className="col-md-6 required">
            <Label for="divisionTitle">Division Name</Label>
            <Input id="divisionTitle" name="title" placeholder="e.g. Atom East" {...fields.title.input} />

            {fields.title.input.invalid && 
              fields.title.errors.map(error => <FormFeedback key={error}>{error}</FormFeedback>)}
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label for="divisionExternalId">External ID</Label>
            <Input id="divisionExternalId" name="externalId" placeholder="Optional" {...fields.externalId.input} />

            {fields.externalId.input.invalid &&
              fields.externalId.errors.map(error => <FormFeedback key={error}>{error}</FormFeedback>)}
          </FormGroup>
        </FormItem>

        <FormGroup className="col-md-3 mt-3 pl-0" check>
          <CustomInput
            id="division-form__using-season-settings-checkbox"
            type="checkbox"
            label="Use Season settings"
            onChange={toggleUsingSeasonSettings}
            checked={fields.usingSeasonSettings.value}
          />
        </FormGroup>
      </ContentSection>

      {fields.usingSeasonSettings.value ? <></> : (
        <Fragment>
          <GameGeneralSettingsFieldset
            value={fields.generalSettings.value}
            onChange={fields.generalSettings.setValue}
          />

          <GameStatsSettingsFieldset
            value={fields.statsSettings.value}
            onChange={fields.statsSettings.setValue}
            errors={fields.statsSettings.errors}
            sport={sport}
          />

          <DivisionPenaltySettingsFieldset
            value={fields.penaltySettings.value}
            onChange={fields.penaltySettings.setValue}
            hidePenaltyCodes
            sport={sport}
          />
        </Fragment>
      )}

      <DashboardFormFooter>
        {divisionId ? (
          <Fragment>
            <div className="dashboard-form-footer__secondary">
              <AbilityButton
                subject="divisions"
                action="delete"
                color="danger"
                outline
                onClick={handleDelete}
              >
                {isSubmitting ? "Deleting…" : "Delete"}
              </AbilityButton>
              <DeleteModal resourceTitle={fields.title.initialValue} resourceType="division" />
              <DeleteProtectionModal />
            </div>

            <div className="dashboard-form-footer__primary">
              <AbilityButton
                subject={{ type: "divisions", id: divisionId }}
                action="update"
                onClick={handleSubmit}
                color="success"
                outline={isPristine || isInvalid}
                disabled={isPristine || isInvalid}
              >
                {isSubmitting ? "Saving Changes…" : "Save Changes"}
              </AbilityButton>
            </div>
          </Fragment>
        ) : (
          <div className="dashboard-form-footer__primary">
            <AbilityButton
              subject={{ type: "divisions", seasonId }}
              action="create"
              onClick={() => handleSubmit({goBack:false})}
              color="success"
              outline={isPristine || isInvalid}
              disabled={isPristine || isInvalid}
            >
              {isSubmitting ? "Creating Division…" : "Create And Add Another"}
            </AbilityButton>
            <AbilityButton
              subject={{ type: "divisions", seasonId }}
              action="create"
              onClick={handleSubmit}
              color="success"
              outline={isPristine || isInvalid}
              disabled={isPristine || isInvalid}
            >
              {isSubmitting ? "Creating Division…" : "Create Division"}
            </AbilityButton>
          </div>
        )}
      </DashboardFormFooter>
    </DashboardForm>
  );
}

DivisionForm.propTypes = {
  seasonId: PropTypes.string.isRequired,
  divisionId: PropTypes.string,
  initialValues: PropTypes.shape({}),
  seasonGameSettings: PropTypes.shape({})
};

export default DivisionForm;
