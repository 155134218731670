import { connect } from "react-redux";

import { getSeasonRosterPlayersMergeToolPlayersList } from "@/redux/seasonRoster/selectors";

import PlayersListMergeToolTable from "./Table";

const mapStateToProps = state => ({
  players: getSeasonRosterPlayersMergeToolPlayersList(state)
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayersListMergeToolTable);
