import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function Option(props) {
  const { title="", isSelected, toggleSelected } = props;
  const callback = useCallback(() => toggleSelected(props), [toggleSelected, props]);

  return (
    <button
      type="button"
      className={classNames(
        ["role-form-resource-select-list__input__option", "list-group-item", "list-group-item-action"],
        { active: isSelected }
      )}
      onClick={callback}
    >
      {title}
    </button>
  );
}

Option.propTypes = {
  title: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,
  toggleSelected: PropTypes.func
};

Option.defaultProps = {
  toggleSelected() {}
};

export default Option;
