import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function Field({ error, value }) {
  // Check if the error contains the specific message and render the link
  const renderErrorWithLink = (error) => {
    if (error.includes("Supported Time Zones")) {
      const linkText = "``Supported Time Zones``";
      const linkUrl = "https://help.gamesheet.app/article/119-time-zone";
      // Split the error text to extract the parts around the link
      const [beforeLink, afterLink] = error.split(linkText);

      return (
        <>
          {beforeLink}
          <a href={linkUrl} target="_blank" rel="noopener noreferrer">
            {linkText}
          </a>
          {afterLink}
        </>
      );
    }

    // Return error as plain text if it doesn't contain the expected link text
    return error;
  };

  return (
    <td
      className={classNames({
        "scheduled-games-csv-import__error__table__error": !!error
      })}
    >
      {value !== "" && (
        <span
          className={classNames({
            "scheduled-games-csv-import__error__table__error__value": !!error
          })}
        >
          {value}
        </span>
      )}
      {error && (
        <span className="scheduled-games-csv-import__error__table__error__text">
          {renderErrorWithLink(error)}
        </span>
      )}
    </td>
  );
}

Field.propTypes = {
  error: PropTypes.string,
  value: PropTypes.string
};

Field.defaultProps = {
  error: null,
  value: ""
};

export default Field;
