import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import StickyFooter from "@/components/StickyFooter";

class PlayersListMergeToolFooter extends PureComponent {
  static propTypes = {
    countPlayersSelected: PropTypes.number,
    isEnabled: PropTypes.bool,
    allStatsLoaded: PropTypes.bool,
    status: PropTypes.string,
    winnerId: PropTypes.string,
    disable: PropTypes.func,
    setStatus: PropTypes.func,
    mergePlayers: PropTypes.func
  };

  static defaultProps = {
    players: [],
    isEnabled: false,
    allStatsLoaded: false,
    status: null,
    winnerId: null,
    disable() {},
    setStatus() {},
    mergePlayers() {}
  };

  renderSelectedPlayersCount = () => {
    const { countPlayersSelected } = this.props;

    if (countPlayersSelected === 0) {
      return "No players selected to merge";
    }

    switch (countPlayersSelected) {
      case 0:
        return "No players selected to merge.";
      case 1:
        return "Only one player selected to merge. Select at least one more.";
      default:
        return `${countPlayersSelected} players selected to merge.`;
    }
  };

  proceedToChoosingWinner = () => {
    this.props.setStatus("choosing-winner");
  };

  renderPrimaryButton = () => {
    const { status, countPlayersSelected, winnerId, mergePlayers, allStatsLoaded } = this.props;

    switch (status) {
      case "selecting-players":
        return (
          <Button
            outline={countPlayersSelected < 2}
            type="button"
            color="success"
            disabled={countPlayersSelected < 2}
            onClick={this.proceedToChoosingWinner}
          >
            Continue
          </Button>
        );
      case "choosing-winner":
        return (
          <Button
            outline={winnerId === null || allStatsLoaded === false}
            type="button"
            color="success"
            disabled={winnerId === null || allStatsLoaded === false}
            onClick={mergePlayers}
          >
            Merge Players
          </Button>
        );
      default:
        return null;
    }
  };

  render() {
    const { isEnabled, status, disable } = this.props;

    if (!isEnabled || !["selecting-players", "choosing-winner"].includes(status)) {
      return null;
    }

    return (
      <StickyFooter>
        <div className="sticky-footer__secondary">
          <Button outline type="button" color="secondary" onClick={disable}>
            Cancel merge
          </Button>
        </div>

        {status === "selecting-players" && <p className="sticky-footer__text">{this.renderSelectedPlayersCount()}</p>}

        <div className="sticky-footer__primary">{this.renderPrimaryButton()}</div>
      </StickyFooter>
    );
  }
}

export default PlayersListMergeToolFooter;
