import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { useImagePicker } from '@/components/TeamForm/ImagePicker/useImagePicker'
import { FilePicker } from "react-file-picker";
import { useTeamFormSubmitting } from '@/components/TeamForm';
import { useTrashIcon } from './useTrashIcon';
import { canCheck } from "@/components/Ability";

export function EditableTeamRosterHeader({ team }){

  // defaults
  const placeholder = "https://imagedelivery.net/ErrQpIaCOWR-Tz51PhN1zA/e4d8b129-b545-4ed1-c2a5-2f7787e5c300/96"
  
  // state
  const [ isEditable, setIsEditable ] = useState(false);
  const [ localTeam, setLocalTeam ] = useState({...team});
  const setTitle = (title) => setLocalTeam({ ...localTeam, title });
  const setLogo = (logoUrl) => setLocalTeam({ ...localTeam, logoUrl });
  const ignoreLock = canCheck({ I: "ignore", this: { type: "teamLocks" } });
  
  // hooks
  const { onSubmit: saveTeam, isUpdating, errorsState, errorsDispatch } = useTeamFormSubmitting({ seasonId: team.season.id });
  const { imgSrc, imgFile: imagefile, hasImg, error, setError, updateImg, reset: resetImage, destroy } = useImagePicker({ 
    initialLogo: { url: team.logoUrl && `${team.logoUrl}/96` }, 
    updateParent: (upload) => {}
  });

  // controls
  const toggleEditState = () => setIsEditable(!isEditable);

  // trash icon
  const { Icon: TrashIcon, setColor: setTrashColor } = useTrashIcon();
  
  const trashImage = async () => {

    console.log("trash");
    setLogo("");
    destroy();

  }
  
  const cancel = async () => {

    setLocalTeam({ ...team });
    resetImage();
    toggleEditState();

  }

  const save = async () => {

    toggleEditState();
    
    const logo = 
      imagefile && { file: imagefile }
      || localTeam.logoUrl && { url: localTeam.logoUrl }
      || { url: '' };

    console.log(logo, imagefile, team);

    saveTeam({ 
      attributes:{
        title: localTeam.title.trim(),
        externalId: team.externalId.trim(),
        roster: team.roster,
        data: team.data
      }, 
      divisionId: team.division.id, 
      id: team.id, 
      logo,
      goBack: false 
    });

  }

  return <div style={{ maxWidth: 510, padding: '2px', marginBottom:15, borderRadius: 4, backgroundColor:isEditable ? '#F5F5F5' : '#fff' }}>
    <div style={{ display: 'flex', alignItems: 'flex-start', borderRadius: 4, backgroundColor:'#FFF', padding: 15 }}>
      <div style={{ padding: '0 20px 0 0px' }}>
        <img src={imgSrc || placeholder} width="96" height="96" style={{ objectFit:'contain' }} />
        
        {isEditable && <FilePicker
          onChange={updateImg}
          onError={setError}
          extensions={["jpg", "jpeg", "png", "webp", "svg"]}
          maxSize={10}
          dims={{
            minWidth: 256,
            minHeight: 256,
            maxWidth: 1024,
            maxHeight: 1024
          }}
        >
          <Button id="btnSelectImage" type="button" size="sm" color="primary" outline style={{display:'none',margin:0}}>
            {hasImg ? "Update" : "Select Image"}
          </Button>
        </FilePicker>}

        {!!error && <p className="text-danger">{error}</p>}
        
      </div>
      <div>
        <span className="import-from-text">Team Roster { isUpdating && <>Updating...</> }</span>
        
        
        {/* When isEditable, the title gets put into the input */}
        {isEditable 
          && <textarea value={localTeam.title} onChange={(e)=>setTitle(e.target.value)} style={{
              display: 'block',
              fontSize: '1.9rem',
              width: '100%',
              lineHeight: '2.1rem'
          }}/> || <h1 style={{ margin: 0 }}>{localTeam.title}</h1>
        }

        { !isEditable && (!team.data.rosterLocked || ignoreLock) && <>
          <a href="#" onClick={toggleEditState}>Edit Name / Logo</a>
        </>}

      </div>
    </div>
    { isEditable && <>
      <div style={{ padding: 10 }}>
        <div style={{ marginTop: 10, color:'#666' }}>Image must be  PNG, JPEG, WebP, or SVG format. Supported formats are. The maximum upload size is 10MB.</div>
        <div style={{ display:'flex', flexWrap:'wrap', justifyContent:'space-between' }}>
          <div style={{ marginTop: 10 }}>
            {hasImg && imgSrc !== placeholder &&
            <Button size="sm" color="warning" outline 
              style={{ marginRight: 10 }}
              onClick={trashImage} 
              onMouseOver={()=>setTrashColor('#000')} 
              onMouseOut={()=>setTrashColor()}
            >
              <TrashIcon />
            </Button>}
            <Button size="sm" style={{ marginRight: 10 }} color="primary" outline onClick={() => { document.querySelector('#btnSelectImage').click() }}>+ Upload Logo</Button>
          </div>
          <div style={{ marginTop: 11 }}>  
            <Button size="sm" style={{ marginRight: 10 }} color="secondary" outline onClick={cancel}>Cancel</Button>
            <Button size="sm" color="success" outline onClick={save}>Save Changes</Button>
          </div>
        </div>
      </div>
    </>}
  </div>
  // show a title with the team name
  
  // on edit button, show edit form and 
  // get temporary url for image upload from our api

  // on submit
    // if image changed:
      // convert logo to loading icon
      // upload image
      // show image
      // change submit button to confirm
    // else
      // update team data in background
  // on confirm
  // update team data in background
  // finally
  // return to title state

  // show a title with the team name

}