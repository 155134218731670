import { useCallback, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getMemberField, actions } from "@/redux/teamRoster";

export default function useField(name, member) {
  const dispatch = useDispatch();
  const ref = useRef(null);

  const triggerReset = useCallback(() => dispatch(actions[`${member.type}Form`].reset({ memberId: member.id })), [
    dispatch,
    member
  ]);

  const { value, isInvalid, errors, autoFocus } = useSelector(state => getMemberField(state, name, member), [
    name,
    member
  ]);

  const setValue = useCallback(
    value =>
      dispatch(
        actions[`${member.type}Form`].changeField({
          name,
          value,
          memberId: member.id
        })
      ),
    [dispatch, name, member]
  );

  useEffect(
    () => {
      if (autoFocus) {
        ref.current.focus();
      }
    },
    [ref, autoFocus]
  );

  return {
    value,
    setValue,
    isInvalid,
    errors,
    ref,
    triggerReset
  };
}
