import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import _trim from "lodash/trim";

import useFormWithRemoteValidationErrors from "@/hooks/useFormWithRemoteValidationErrors";

import { getRemoteValidationErrors, getMeta, submittingRoutine, deletingRoutine } from "@/redux/divisionForm";

import { DEFAULT_VALUES, CONSTRAINTS } from "../constants";
import { useHistory } from "react-router";

export default function useDivisionForm(options) {
  const { seasonGameSettings } = options;
  const { initialValues = { ...seasonGameSettings, ...DEFAULT_VALUES }, seasonId, divisionId } = options;

  const dispatch = useDispatch();
  const history = useHistory()

  const { values, fields, reset, ...form } = useFormWithRemoteValidationErrors({
    initialValues: {
      ...seasonGameSettings,
      ...initialValues
    },
    constraints: CONSTRAINTS,
    errorsSelector: getRemoteValidationErrors
  });

  const resetDivisionForm = () => {
    reset()
  }

  const { isSubmitting, isDeleting } = useSelector(getMeta);

  const submit = useCallback(
    ({goBack = true}) =>
      dispatch(
        submittingRoutine({
          values: {
            ...values,
            title: _trim(values.title),
            externalId: _trim(values.externalId)
          },
          seasonId,
          divisionId,
          goBack,
          history
        })
      ),
    [dispatch, values, seasonId, divisionId]
  );

  const deleteDivision = useCallback(() => dispatch(deletingRoutine({ seasonId, divisionId })), [
    dispatch,
    seasonId,
    divisionId
  ]);

  const toggleUsingSeasonSettings = useCallback(
    ({ target: { checked } }) => fields.usingSeasonSettings.setValue(checked),
    [fields]
  );

  return {
    ...form,
    fields,
    values,
    isSubmitting,
    isDeleting,
    submit,
    deleteDivision,
    toggleUsingSeasonSettings,
    reset: resetDivisionForm
  };
}
