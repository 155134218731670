import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "reactstrap";

import FormSection from "@/components/FormSection";
import TitleBar from "@/components/TitleBar";

import PointSystemFormFields from "./components/PointSystemFormFields";
import TieBreakersFormField from "./components/TieBreakersFormField";
import MaxGoalDifferentialFormField from "./components/MaxGoalDifferentialFormField";
import FairPlayFormFields from "./components/FairPlayFormFields";

import { useGameStatsSettingsFields } from "./hooks";

import PlayerStatsDisplayField from "./PlayerStatsDisplayField";
import PointsField from "./PointsField";

function GameStatsSettingsFieldset(props) {
  const { value, onChange, sport } = props;

  const {
    playerStatsDisplay,
    goalValue,
    assistValue,
    pointSystem,
    tieBreakers,
    fairPlayPoints,
    maxGoalDifferential,
  } = useGameStatsSettingsFields({
    value,
    onChange,
  });

  tieBreakers.sport = sport;
  fairPlayPoints.sport = sport;

  return (
    <FormSection title="Standings and Stats">
      <Row>
        <Col md="6">
          <PlayerStatsDisplayField {...playerStatsDisplay} />

          <TitleBar slim title="Points" titleTag="h4" />
          <Row>
            <PointsField name="goalValue" label="Goal Value" {...goalValue} />
            <PointsField name="assistValue" label="Assist Value" {...assistValue} />
          </Row>

          <PointSystemFormFields {...pointSystem} />
          <FairPlayFormFields {...fairPlayPoints} />
        </Col>
        <Col md="6">
          <TieBreakersFormField {...tieBreakers} />
          <MaxGoalDifferentialFormField {...maxGoalDifferential} />
        </Col>
      </Row>
    </FormSection>
  );
}

GameStatsSettingsFieldset.propTypes = {
  value: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func,
};

GameStatsSettingsFieldset.defaultProps = {
  onChange() {},
};

export default GameStatsSettingsFieldset;
