import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class Member extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    number: PropTypes.number,
    length: PropTypes.number
  };

  static defaultProps = {
    name: "N/A"
  };

  render() {
    const { name, number, length } = this.props;

    return (
      <tr>
        <td className="text-uppercase">{name}</td>
        <td>
          {number} of {length}
        </td>
      </tr>
    );
  }
}

export default Member;
