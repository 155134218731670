import { useSelector } from "react-redux";

import {
  getSelectedSeasonIds,
  getSelectedDivisionIds,
  getSelectedTeamIds
} from "@/redux/reportDataSourcesSelect/selectors";

function countResources(singular, plural, count) {
  if (count === 1) {
    return { text: `${count} ${singular}`, count };
  }

  return { text: `${count} ${plural}`, count };
}

export default function useSelectedResourcesCount() {
  const seasonIds = useSelector(getSelectedSeasonIds);
  const divisionIds = useSelector(getSelectedDivisionIds);
  const teamIds = useSelector(getSelectedTeamIds);

  const countSeasons = seasonIds.length;
  const countDivisions = divisionIds.length;
  const countTeams = teamIds.length;

  if (countTeams > 0) {
    return countResources("team", "teams", countTeams);
  } else if (countDivisions > 0) {
    return countResources("division", "divisions", countDivisions);
  } else if (countSeasons > 0) {
    return countResources("season", "seasons", countSeasons);
  }

  return { count: 0 };
}
