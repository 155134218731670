import { handleActions } from "redux-actions";
import _uniq from "lodash/uniq";

import { listLoadingRoutine } from "../routines";

const initialState = {};

function reduceLoadingSuccess(state, { payload: { users } }) {
  return users.reduce((result, user) => {
    const { id, firstName, lastName, email, roles, status } = user;

    return {
      ...result,
      [id]: {
        firstName,
        lastName,
        email,
        roles: _uniq((roles || []).map(({ title }) => title)),
        status
      }
    };
  }, state);
}

export default handleActions(
  {
    [listLoadingRoutine.SUCCESS]: reduceLoadingSuccess
  },
  initialState
);
