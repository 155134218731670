import axios from 'axios'
import jsonwebtoken from 'jsonwebtoken'
import { config } from "../../config";

export const getFreshToken = async () => {
  const accessToken = localStorage.getItem('accessToken');

  if (!tokenIsExpired(accessToken)) {
    return accessToken;
  }

  const refreshToken = localStorage.getItem('refreshToken');

  if (!refreshToken) {
    return "";
  }

  if (tokenIsExpired(refreshToken)) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('rolesToken');
    return "";
  }

  const url = `${config.AUTH_GATEWAY}/auth/v4/refresh`;
  try {
    const response = await axios.post(
      url,
      {},
      {
        headers: { Authorization: `Bearer ${refreshToken}` },
      },
    )

    if (response.status !== 200) {
      throw response
    }

    const tokens = response.data;
    localStorage.setItem('accessToken', tokens.access);
    localStorage.setItem('refreshToken', tokens.refresh);
    localStorage.setItem('rolesToken', tokens.roles);
    return tokens.access;
  } catch (error) {
    console.error(error);
  }

  return "";
}

// ask for one time password (OTP)
export const getOTP = async () => {
  let accessToken = localStorage.getItem('accessToken');
  if (!accessToken) {
    return "";
  }

  if (tokenIsExpired(accessToken)) {
    accessToken = await getFreshToken();
    if (!accessToken) {
      return "";
    }
  }

  try {
    const endpoint = `${config.AUTH_GATEWAY}/auth/v4/otp`;
    const response = await axios.get(
      endpoint,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );

    if (response.status !== 200) {
      return "";
    }

    return response.data.otp;
  } catch (error) {
    return "";
  }
}

export const tokenIsExpired = (token) => {
  if (!token) {
    return true;
  }

  const jwt = jsonwebtoken.decode(token, { complete: true })

  if (!!jwt) {
    const exp = new Date(jwt.payload.exp * 1000).getTime()
    const now = new Date().getTime()

    return (Math.round(exp - now) < 500); // 500ms buffer
  }

  return true;
}