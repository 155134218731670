import React from "react";
import PropTypes from "prop-types";

import TeamSettings from "./TeamSettings";

function SeasonTeamListSettings({ id, title, teams }) {
  return (
    <tbody>
      <tr>
        <th colSpan="3">{title}</th>
      </tr>
      {teams.map(team => (
        <TeamSettings key={team.id} {...team} />
      ))}
    </tbody>
  );
}

SeasonTeamListSettings.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  teams: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired }))
};

export default SeasonTeamListSettings;
