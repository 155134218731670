import React, { Fragment } from "react";
import PropTypes from "prop-types";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";
import useCurrentDivisionContext from "@/hooks/useCurrentDivisionContext";
import useCurrentTeamLoading from "@/hooks/useCurrentTeamLoading";
import useCurrentTeamClearing from "@/hooks/useCurrentTeamClearing";

import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";
import TeamForm, { useTeamFormSubmitting, useTeamFormDeleting } from "@/components/TeamForm";
import PageLoader from "@/components/PageLoader";

import ProtectedLayout from "@/layouts/ProtectedLayout";

function EditTeam({ match: { params } }) {
  const { teamId } = params;

  const { seasonId } = useCurrentSeasonContext();

  const {
    divisionId,
    divisionIsLoaded,
    divisionIsLoading,
    divisionIsForbidden,
    divisionBreadcrumbs
  } = useCurrentDivisionContext();

  const { teamIsLoaded, teamIsLoading, teamIsForbidden, team } = useCurrentTeamLoading({ seasonId, teamId });

  const { onSubmit, isUpdating, errorsState, errorsDispatch } = useTeamFormSubmitting({
    divisionId,
    seasonId
  });

  const { onDelete, isDeleting } = useTeamFormDeleting({
    seasonId,
    divisionId,
    teamId
  });

  useCurrentTeamClearing();

  return (
    <ProtectedLayout condition={!divisionIsForbidden && !teamIsForbidden}>
      {divisionIsLoaded ? (
        teamIsLoaded ? (
          <Fragment>
            <Head title="Edit Team" />
            <Breadcrumbs
              items={divisionBreadcrumbs}
              lastItem={{
                title: team.title,
                url: `/seasons/${seasonId}/teams/${teamId}/roster`
              }}
            />
            <TitleBar title="Edit Team" />
            <TeamForm
              {...team}
              divisionId={divisionId}
              seasonId={seasonId}
              isOperating={isUpdating}
              isDeleting={isDeleting}
              onSubmit={onSubmit}
              onDelete={onDelete}
              allowChangeDivision
              submittingErrors={errorsState}
              updateSubmittingErrors={errorsDispatch}
            />
          </Fragment>
        ) : (
          <PageLoader isLoading={teamIsLoading} />
        )
      ) : (
        <PageLoader isLoading={divisionIsLoading} />
      )}
    </ProtectedLayout>
  );
}

EditTeam.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      teamId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default EditTeam;
