import { handleActions } from "redux-actions";

import { loadGameFormRoutine } from "../routines";
import actions from "../actions";

function reduceLoadingSuccess(
  state,
  {
    payload: {
      game: { season }
    }
  }
) {
  return season.settings.penaltyCodes || [];
}

export default handleActions(
  {
    [loadGameFormRoutine.SUCCESS]: reduceLoadingSuccess,
    [actions.clear]: () => []
  },
  []
);
