import React from "react";
import PropTypes from "prop-types";

import AddMemberButton from "./AddMemberButton";

function AddPlayerButton({ children, team, ...rest }) {
  return (
    <AddMemberButton
      action="addPlayer"
      url={`/seasons/${team.season.id}/teams/${team.id}/roster/players/add`}
      teamId={team.id}
      {...rest}
    >
      {children}
    </AddMemberButton>
  );
}

AddPlayerButton.propTypes = {
  children: PropTypes.node.isRequired,
  team: PropTypes.shape({
    id: PropTypes.string.isRequired,
    season: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default AddPlayerButton;
