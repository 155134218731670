import React, { Fragment } from "react";
import PropTypes from "prop-types";

import CurrentPlayerContext from "@/contexts/CurrentPlayerContext";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";
import useCurrentPlayer from "@/hooks/useCurrentPlayer";
import useCurrentPlayerClearing from "@/hooks/useCurrentPlayerClearing";

import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";
import PlayerForm from "@/components/PlayerForm";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import PageLoader from "@/components/PageLoader";

function EditPlayer({ seasonId, match: { params } }) {
  const { playerId } = params;

  const { seasonIsLoaded, seasonIsLoading, seasonIsForbidden } = useCurrentSeasonContext(seasonId);

  const currentPlayer = useCurrentPlayer({ seasonId, playerId });
  const { playerIsLoading, playerIsLoaded, playerIsForbidden, playerBreadcrumbs, playerFormFields } = currentPlayer;

  useCurrentPlayerClearing();

  return (
    <CurrentPlayerContext.Provider value={{ ...currentPlayer, playerId }}>
      <ProtectedLayout condition={!seasonIsForbidden && !playerIsForbidden}>
        <Head title="Edit Player" />

        {seasonIsLoaded && playerIsLoaded ? (
          <Fragment>
            <Breadcrumbs items={playerBreadcrumbs} />

            <TitleBar title="Edit Player" />

            <PlayerForm initialValues={playerFormFields} seasonId={seasonId} playerId={playerId} />
          </Fragment>
        ) : (
          <PageLoader isLoading={playerIsLoading || seasonIsLoading} />
        )}
      </ProtectedLayout>
    </CurrentPlayerContext.Provider>
  );
}

EditPlayer.propTypes = {
  seasonId: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      playerId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default EditPlayer;
