import { connect } from "react-redux";

import { getRefereesMergeToolRefereesList } from "@/redux/referees/selectors";

import RefereesListMergeToolTable from "./Table";

const mapStateToProps = state => ({
  referees: getRefereesMergeToolRefereesList(state)
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RefereesListMergeToolTable);
