import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import TitleBar from "@/components/TitleBar";
import PageLoader from "@/components/PageLoader";

import SuspensionReportList from "./List";

import "./Report.scss";
import { useIncidentSuspensionReportData } from "./useIncidentSuspensionReportData";

const Report = ({ isLoaded, isLoading }) => {
  
  const url = new URL(window.location);
  const seasonId = url.searchParams.get('season_id');
  const report = useIncidentSuspensionReportData(seasonId)

  const print = useCallback(() => {
    window.print();
  }, []);

  return (
    <PageLoader isLoading={isLoading || report.isLoading} isLoaded={!report.isLoading}>
      <TitleBar title="Incident &amp; Suspension Report">
        <Button outline color="primary" size="sm" onClick={print}>
          Print
        </Button>
      </TitleBar>

      <SuspensionReportList report={report} />
    </PageLoader>
  );
};

Report.propTypes = {
  isLoaded: PropTypes.bool,
  isLoading: PropTypes.bool,
  suspensionsBySeasons: PropTypes.arrayOf(PropTypes.shape({}))
};

Report.defaultProps = {
  isLoaded: false,
  isLoading: false,
  suspensionsBySeasons: []
};

export default Report;