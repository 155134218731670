import { connect } from "react-redux";

import {
  getSeasonRosterPlayersMergeToolIsEnabled,
  getSeasonRosterPlayersMergeToolStatus,
  getSeasonRosterPlayersMergeToolCountPlayersSelected,
  getSeasonRosterPlayersMergeToolWinnerId,
  getSeasonRosterPlayersMergeToolAllPlayersStatsIsLoaded
} from "@/redux/seasonRoster/selectors";

import { seasonRosterActions } from "@/redux/seasonRoster/actions";

import PlayersListMergeToolFooter from "./Footer";
import { mergeSeasonPlayersRoutine } from "@/redux/seasonRoster/routines";

const mapStateToProps = state => ({
  isEnabled: getSeasonRosterPlayersMergeToolIsEnabled(state),
  status: getSeasonRosterPlayersMergeToolStatus(state),
  countPlayersSelected: getSeasonRosterPlayersMergeToolCountPlayersSelected(state),
  winnerId: getSeasonRosterPlayersMergeToolWinnerId(state),
  allStatsLoaded: getSeasonRosterPlayersMergeToolAllPlayersStatsIsLoaded(state)
});

const mapDispatchToProps = dispatch => ({
  disable: () => dispatch(seasonRosterActions.players.mergeTool.disable()),
  setStatus: status => dispatch(seasonRosterActions.players.mergeTool.setStatus(status)),
  mergePlayers: () => dispatch(mergeSeasonPlayersRoutine())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayersListMergeToolFooter);
