import { all, select, put, call, takeLatest } from "redux-saga/effects";

import { loadDivision } from "@/lib/api/divisions";
import { fetchOne, gamesheetAPIRequest } from "@/redux/api/sagas";

import { loadingRoutine, lockRostersRoutine } from "./routines";

import { updateDivisionRosterLock } from "@/lib/api/divisions";

import { getMeta, getDivision } from "./selectors";

function* loadingSaga({ payload: { divisionId } }) {
  yield put(loadingRoutine.request());

  try {
    const [division] = yield fetchOne({ type: "divisions", id: divisionId }, loadDivision, {
      include: "associations,leagues,seasons"
    });

    yield put(loadingRoutine.success({ division }));
  } catch (error) {
    const { response } = error;

    yield put(
      loadingRoutine.failure({
        error,
        responseStatus: response && response.responseStatus
      })
    );
  } finally {
    yield put(loadingRoutine.fulfill());
  }
}

function* lockRostersSaga({ payload: { lock, successCb } }) {
  yield put(lockRostersRoutine.request());

  const meta = yield select(getMeta);
  if (!meta.isLoaded) {
    yield put(lockRostersRoutine.fulfill());
    return;
  }

  const division = yield select(getDivision);

  try {
    yield call(
      gamesheetAPIRequest,
      updateDivisionRosterLock,
      {
        divisionId: division.id,
        attributes: { lock }
      },
      true
    );

    yield put(lockRostersRoutine.success());

    if (successCb && typeof successCb === "function") {
      successCb();
    }
  } catch (error) {
    const { response } = error;

    yield put(
      lockRostersRoutine.failure({
        error,
        response
      })
    );
  } finally {
    yield put(lockRostersRoutine.fulfill());
  }
}

export function* currentDivisionFlow() {
  yield all([takeLatest(loadingRoutine.TRIGGER, loadingSaga), takeLatest(lockRostersRoutine.TRIGGER, lockRostersSaga)]);
}
