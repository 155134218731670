import _isEmpty from "lodash/isEmpty";
import axios from 'axios';
import { all, put, call, takeLatest, take, select } from "redux-saga/effects";

import readCSV from "@/lib/core/readScheduledGamesCSV";

import takeConcurrently from "@/redux/common/takeConcurrently";
import { config } from "../../config";

import { parsingRoutine, recordParsingRoutine, importingRoutine, chunkImportingRoutine } from "./routines";

import {
  parseID,
  parseStartDate,
  parseStartTime,
  parseEndTime,
  parseTimeZone,
  findVisitorDivisionId,
  findVisitorTeamId,
  findHomeDivisionId,
  findHomeTeamId,
  parseGameNumber,
  parseGameType,
  buildLookups,
  parseBroadcastProvider
} from "./utils/parsers";
import { getDuplicateIds } from "./utils/getDuplicateIds";
import { countResponseGames } from "./utils/countResponseGames";

import { getFormattedGames } from "./selectors";
import { getFreshToken } from "@/lib/api/getFreshToken";
import { fetchScheduledGameBroadcasters } from "@/components/ScheduledGameForm/hooks/useScheduledGameBroadcasters";
import { getTimezoneOptions } from "@/utils/timezones";

export const CHUNK_SIZE = 200;

function* parsingSaga({ payload: { file, seasonId } }) {
  yield put(parsingRoutine.request());

  try {
    const records = yield readCSV(file);

    const { idList, teamMap, divisionMap } = yield buildLookups({ seasonId });

    const duplicateIds = getDuplicateIds(records)

    const { BROADCAST_PROVIDER_VALUES } = yield call(fetchScheduledGameBroadcasters)
    const timeZoneOptions = getTimezoneOptions();

    yield all(
      records.map((record, index) => {
        const { good, data } = recordParsingFn({ idList, duplicateIds, teamMap, divisionMap, seasonId, record, lineNumber: index + 2, BROADCAST_PROVIDER_VALUES, timeZoneOptions });

        if (good) {
          return put(recordParsingRoutine.success(data));
        } else {
          return put(recordParsingRoutine.failure(data));
        }
      })
    )

    yield put(parsingRoutine.success());
  } catch (error) {
    if (error.name === "ReadingError") {
      yield put(parsingRoutine.failure({ error: error.message }));
    } else {
      yield put(parsingRoutine.failure({ error: "Unexpected error" }));

      throw error;
    }
  } finally {
    yield put(parsingRoutine.fulfill());
  }
}

function recordParsingFn({ idList, duplicateIds, teamMap, divisionMap, record, lineNumber, BROADCAST_PROVIDER_VALUES, timeZoneOptions }) {
  const {
    id,
    date,
    time,
    endTime,
    timeZone,
    visitorDivision,
    visitorTeam,
    homeDivision,
    homeTeam,
    location,
    gameNumber,
    gameType,
    scorekeeperName,
    scorekeeperPhone,
    broadcastProvider
  } = record;

  const validationErrors = {};

  const [parsedID, idError] = parseID(id, idList, duplicateIds);

  if (idError) {
    validationErrors.id = idError;
  }

  const [parsedDate, dateError] = parseStartDate(date);

  if (dateError) {
    validationErrors.date = dateError;
  }

  const [parsedStartTime, startTimeError] = parseStartTime(time);

  if (startTimeError) {
    validationErrors.time = startTimeError;
  }

  const [parsedEndTime, endTimeError] = parseEndTime(parsedStartTime, endTime);
  
  if (endTimeError) {
    validationErrors.endTime = endTimeError;
  }

  const [parsedTimeZone, timeZoneError] = parseTimeZone(timeZone, timeZoneOptions);

  if (timeZoneError) {
    validationErrors.timeZone = timeZoneError;
  }

  const [visitorDivisionId, visitorDivisionError] = findVisitorDivisionId({
    divisionMap,
    visitorDivision,
    visitorTeam
  });

  if (visitorDivisionError) {
    validationErrors.visitorDivision = visitorDivisionError;
  }

  const [visitorTeamId, visitorTeamError] = findVisitorTeamId({
    teamMap,
    visitorTeam,
    visitorDivision,
    visitorDivisionId
  });

  if (visitorTeamError) {
    validationErrors.visitorTeam = visitorTeamError;
  }

  const [homeDivisionId, homeDivisionError] = findHomeDivisionId({ divisionMap, homeDivision });

  if (homeDivisionError) {
    validationErrors.homeDivision = homeDivisionError;
  }

  const [homeTeamId, homeTeamError] = findHomeTeamId({ teamMap, homeTeam, homeDivision, homeDivisionId });

  if (homeTeamError) {
    validationErrors.homeTeam = homeTeamError;
  }

  const [parsedGameNumber, gameNumberError] = parseGameNumber(gameNumber);

  if (gameNumberError) {
    validationErrors.gameNumber = gameNumberError;
  }

  const [parsedGameType, gameTypeError] = parseGameType(gameType);

  if (gameTypeError) {
    validationErrors.gameType = gameTypeError;
  }

  const [parsedBroadcastProvider, broadcastProviderError] = parseBroadcastProvider(broadcastProvider, BROADCAST_PROVIDER_VALUES);

  if (broadcastProviderError) {
    validationErrors.broadcastProvider = broadcastProviderError;
  }

  if (_isEmpty(validationErrors)) {
    return {
      good: true,
      data: {
        lineNumber,
        record: {
          id: parsedID,
          date: parsedDate,
          time: parsedStartTime,
          endTime: parsedEndTime,
          timeZone: parsedTimeZone,
          visitor: {
            division: visitorDivisionId,
            team: visitorTeamId
          },
          home: {
            division: homeDivisionId,
            team: homeTeamId
          },
          gameNumber: parsedGameNumber,
          gameType: parsedGameType,
          scorekeeper: {
            name: scorekeeperName,
            phone: scorekeeperPhone
          },
          location,
          broadcastProvider: parsedBroadcastProvider,
          status: "scheduled"
        }
      }
    }
  } else {
    return {
      good: false,
      data: {
        record,
        lineNumber,
        validationErrors
      }
    }
  }
}

function* importingSaga({ payload: { seasonId } }) {
  yield put(importingRoutine.request());

  const games = yield select(getFormattedGames);

  // upload one chunk at a time
  for (let i = 0; i < games.length; i += CHUNK_SIZE) {
    const chunk = games.slice(i, i + CHUNK_SIZE);
    yield put(chunkImportingRoutine({ seasonId, games: chunk }));
    yield take(chunkImportingRoutine.FULFILL);
  }

  yield put(importingRoutine.success());
    
  yield put(importingRoutine.fulfill());
}

function* chunkImportingSaga({ payload: { seasonId, games } }) {
  yield put(chunkImportingRoutine.request({ numRecords: games.length }));

  const event = {
    event: "set",
    attributes: {
      schema: "bulk-schedule"
    },
    data: {
      seasonId: Number(seasonId),
      games
    },
  }

  const token = yield call(getFreshToken);

  try {
    if (!token) {
      throw "No token";
    }

    if (!seasonId) {
      throw "No seasonId";
    }

    const url = `${config.BFF_API}/bulk-games`;
    const response = yield call(
      () => axios.post(
        url,
        event,
        {
          headers: {
            "Authorization": `Bearer ${token}`,
          },
        }
      )
    );

    if (response.status === 200 && response.data.status === "success") {
      const counts = countResponseGames(response);
      yield put(chunkImportingRoutine.success(counts));
    } else {
      throw response
    }
  } catch (error) {
    // TODO:
    // if 401 -> no records imported
    // if ? -> no records imported
    // if 500 -> unknown records imported
    
    console.error(error);
    yield put(chunkImportingRoutine.failure({ error, numRecords: games.length }));
  } finally {
    yield put(chunkImportingRoutine.fulfill());
  }
}

export function* scheduledGamesCSVImportWizardFlow() {
  yield all([
    takeLatest(parsingRoutine.TRIGGER, parsingSaga),
    takeLatest(importingRoutine.TRIGGER, importingSaga),
    takeConcurrently(chunkImportingRoutine, chunkImportingSaga, 5),
  ]);
}
