import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import "./Failure.scss";

class RefereesListMergeToolFailure extends PureComponent {
  static propTypes = {
    seasonId: PropTypes.string.isRequired,
    exitMergeMode: PropTypes.func
  };

  static defaultProps = {
    exitMergeMode() {}
  };

  handleExitMergeMode = () => {
    const { seasonId, exitMergeMode } = this.props;

    exitMergeMode(seasonId);
  };

  render() {
    return (
      <div className="referees-list__merge-tool__failure">
        <h1 className="text-danger">Merge Failed</h1>
        <p className="mb-4">Referees were not merged due to an unexpected error. Please try again.</p>
        <div>
          <Button outline color="primary" onClick={this.handleExitMergeMode}>
            Return to referees list
          </Button>
        </div>
      </div>
    );
  }
}

export default RefereesListMergeToolFailure;
