import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { actions } from "@/redux/divisionsList";

export default function useDivisionsListClearing() {
  const dispatch = useDispatch();

  useEffect(() => () => dispatch(actions.clear()), [dispatch]);
}
