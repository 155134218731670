import { useContext, useMemo } from "react";

import CurrentPlayerContext from "@/contexts/CurrentPlayerContext";

export default function useCurrentPlayerContext() {
  const currentPlayer = useContext(CurrentPlayerContext);
  const currentPlayerMemo = useMemo(() => ({ ...currentPlayer }), [currentPlayer]);

  return currentPlayerMemo;
}
