import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormGroup, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import usePositionInput from "./usePositionInput";

function PositionInput(props) {
  const { team, player, onChange } = props;
  const { goaliesHadShift } = usePositionInput({ team });
  const { season } = useCurrentSeasonContext();
  const mustBeGoalie = player.position === "goalie" && goaliesHadShift.includes(player.id);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const handleChange = e => {
    const val = e.target.value;
    if (mustBeGoalie && val !== "goalie") {
      setModal(true);
    } else {
      onChange(player.id, e);
    }
  };

  return (
    <FormGroup className="col-md-2">
      <Input type="select" name="position" value={player.position} onChange={handleChange}>
        <option value={""} className={mustBeGoalie ? "disabled" : ""}>
          None
        </option>
        {season.playerPositions.map(position => (
          <option key={position.key} value={position.key} className={mustBeGoalie ? "disabled" : ""}>
            {position.value}
          </option>
        ))}
      </Input>
      <Modal isOpen={modal} toggle={toggle} centered={true} backdrop="static">
        <ModalHeader toggle={toggle}>Invalid Position</ModalHeader>
        <ModalBody>
          {player.firstName} {player.lastName} played a goalie shift; their position must be Goalie. If you wish to
          assign a different position, please modify their goalie shift.
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </FormGroup>
  );
}

PositionInput.propTypes = {
  team: PropTypes.string.isRequired,
  player: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired
};

export default PositionInput;
