import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import AbilityLink from "@/components/AbilityLink";

class Team extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    seasonId: PropTypes.string.isRequired,
    divisionTitle: PropTypes.string.isRequired,
    teamTitle: PropTypes.string.isRequired,
    regularSeasonGamesCount: PropTypes.number.isRequired,
    playoffGamesCount: PropTypes.number.isRequired,
    exhibitionGamesCount: PropTypes.number.isRequired,
    tournamentGamesCount: PropTypes.number.isRequired,
    totalGamesCount: PropTypes.number.isRequired
  };

  render() {
    const {
      id,
      seasonId,
      divisionTitle,
      teamTitle,
      regularSeasonGamesCount,
      playoffGamesCount,
      exhibitionGamesCount,
      tournamentGamesCount,
      totalGamesCount
    } = this.props;

    return (
      <tr>
        <td>
          <AbilityLink
            subject={{ type: "teams", id }}
            action="read"
            fallback={teamTitle}
            to={`/seasons/${seasonId}/teams/${id}/roster`}
          >
            {teamTitle}
          </AbilityLink>
        </td>
        <td>{divisionTitle}</td>
        <td>{regularSeasonGamesCount}</td>
        <td>{playoffGamesCount}</td>
        <td>{exhibitionGamesCount}</td>
        <td>{tournamentGamesCount}</td>
        <td>{totalGamesCount}</td>
      </tr>
    );
  }
}

export default Team;
