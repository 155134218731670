import { useCallback, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { TeamCreatingRoutine, CurrentTeamUpdatingRoutine } from "@/redux/teams/routines";

import { isTeamCreating } from "@/redux/teams/selectors";

const initialErrors = {};

function errorsReducer(state, action) {
  return { ...state, [action.name]: action.value };
}

export default function useTeamFormSubmitting({ seasonId, divisionId }) {
  const dispatch = useDispatch();
  const isCreating = useSelector(isTeamCreating);
  const [errorsState, errorsDispatch] = useReducer(errorsReducer, initialErrors);

  const history = useHistory();

  const onSubmit = useCallback(
    ({ attributes, divisionId: nextDivisionId, id: teamId, logo, goBack = true }) => {
      const routine = teamId ? CurrentTeamUpdatingRoutine : TeamCreatingRoutine;

      dispatch(
        routine({
          redirect: () => {
            if (goBack) {
              if (history.length > 1) {
                history.goBack();
              } else {
                // fallback to season teams list
                const seasonTeams = `/seasons/${seasonId}/teams`;
                history.push(seasonTeams);
              }
            }
          },
          ...(teamId ? { id: teamId } : {}),
          seasonId,
          divisionId: nextDivisionId || divisionId,
          attributes,
          logo,
          failureCb: err => {
            const responseData = (err && err.response && err.response.data) || null;
            const apiErrors =
              (responseData && responseData.errors && Array.isArray(responseData.errors) && responseData.errors) || [];
            apiErrors.forEach(apiErr => {
              const pointer = (apiErr && apiErr.source && apiErr.source.pointer) || "";
              const title = (apiErr && apiErr.title) || "";
              if (pointer.includes("external_id") && title.includes("not unique")) {
                errorsDispatch({ name: "externalId", value: "Not unique within this season" });
              }
            });
          }
        })
      );
    },
    [dispatch, seasonId, divisionId]
  );

  return {
    isCreating,
    onSubmit,
    errorsState,
    errorsDispatch
  };
}
