import React from "react";
import * as Scroll from "react-scroll";

export default function useScrollTo(destination) {
  function Element({ ...props }) {
    return (
      <Scroll.Element name={destination} {...props}>
        {props.children}
      </Scroll.Element>
    );
  }

  function scrollTo() {
    Scroll.scroller.scrollTo(destination, { duration: 500, smooth: true });
  }

  return {
    Element,
    scrollTo
  };
}
