import { getTokenRoles } from "@/redux/token/selectors";

const selectRoot = state => state.gameReportEmailNotificationSettings;
const selectTeams = state => selectRoot(state).teams;
const selectLoadedTeams = state =>
  Object.entries(selectTeams(state))
    .filter(([id, { isLoaded }]) => isLoaded)
    .reduce((result, [id, data]) => ({ ...result, [id]: data }), {});

const selectCommittedSettings = state => {
  const teamIds = Object.keys(selectLoadedTeams(state));
  const allSettings = selectRoot(state).settings;

  return teamIds.reduce((result, teamId) => {
    const settings = allSettings[teamId];

    return settings.isCommitted ? [...result, { ...settings, teamId }] : result;
  }, []);
};

export const getUserTeamIds = state =>
  getTokenRoles(state)
    .filter(role => role.title === "manager" && role.level.type === "teams")
    .map(({ level }) => level.id);

export const getMeta = state => {
  const { isLoaded, isLoading } = selectRoot(state);

  return { isLoading, isLoaded };
};

export const getTeamSettingsGroupedBySeason = state => {
  const seasons = Object.entries(selectRoot(state).seasons).map(([id, data]) => ({
    id,
    ...data
  }));

  const { divisions, settings } = selectRoot(state);

  const teams = Object.entries(selectLoadedTeams(state)).map(([id, { title, divisionId, seasonId }]) => ({
    id,
    seasonId,
    divisionId,
    divisionTitle: divisions[divisionId].title,
    title,
    ...settings[id]
  }));

  return seasons.map(season => ({
    ...season,
    teams: teams.filter(({ seasonId }) => seasonId === season.id)
  }));
};

export const getSubscriptionIdByTeamId = (state, teamId) => selectRoot(state).settings[teamId].subscriptionId;

export const getDisabledTeamIds = state =>
  selectCommittedSettings(state)
    .filter(({ isEnabled }) => !isEnabled)
    .map(({ teamId }) => teamId);

export const getEnabledTeamIds = state =>
  selectCommittedSettings(state)
    .filter(({ isEnabled }) => isEnabled)
    .map(({ teamId }) => teamId);
