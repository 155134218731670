import React from "react";
import PropTypes from "prop-types";

import { Input, InputGroup, FormFeedback } from "reactstrap";

import useScheduledGameFormScorekeeperInput from "./hooks/useScheduledGameFormScorekeeperInput";

function ScorekeeperInput(props) {
  const {
    value,
    setValue,
    setVisited,
    input: { invalid },
    errors
  } = props;

  const { name, phone } = useScheduledGameFormScorekeeperInput({
    value,
    setValue,
    setVisited
  });

  return (
    <InputGroup>
      <Input invalid={invalid} id="scheduledGameFormScorekeeperName" placeholder="Name" {...name} />

      <Input invalid={invalid} id="scheduledGameFormScorekeeperPhone" placeholder="Phone" {...phone} />

      {invalid && errors.map(error => <FormFeedback key={error}>{error}</FormFeedback>)}
    </InputGroup>
  );
}

ScorekeeperInput.propTypes = {
  value: PropTypes.shape({}).isRequired,
  setValue: PropTypes.func.isRequired,
  setVisited: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  input: PropTypes.shape({ invalid: PropTypes.bool.isRequired }).isRequired
};

export default ScorekeeperInput;
