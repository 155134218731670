import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getIsDirty } from "@/redux/teamFilter/selectors";
import actions from "@/redux/teamFilter/actions";

export default function useFilterReset() {
  const dispatch = useDispatch();
  const isDirty = useSelector(getIsDirty);
  const reset = useCallback(() => dispatch(actions.reset()), [dispatch]);

  return {
    isFilterDirty: isDirty,
    reset
  };
}
