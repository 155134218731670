import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Table } from "reactstrap";

import EmptyListMessage from "@/components/EmptyListMessage";

import RefereeReportMajorPenaltiesPenalty from "./Penalty";

import "./MajorPenalties.scss";

class MajorPenalties extends PureComponent {
  static propTypes = {
    penalties: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired }))
  };

  static defaultProps = {
    penalties: []
  };

  render() {
    const { penalties } = this.props;

    return (
      <div className="referee-report__section">
        <h3 className="referee-report__section-title">Major &amp; Misconduct Penalties Assessed</h3>

        {penalties.length > 0 ? (
          <Table striped borderless className="referee-report__major-penalties-table">
            <thead>
              <tr>
                <th className="referee-report__major-penalties-table__game-date">Game Date</th>
                <th className="referee-report__major-penalties-table__game-title">Game</th>
                <th className="referee-report__major-penalties-table__penalty-code">Infraction</th>
              </tr>
            </thead>
            <tbody>
              {penalties.map(({ id, ...rest }) => (
                <RefereeReportMajorPenaltiesPenalty key={id} {...rest} />
              ))}
            </tbody>
          </Table>
        ) : (
          <EmptyListMessage>No major or misconduct penalties.</EmptyListMessage>
        )}
      </div>
    );
  }
}

export default MajorPenalties;
