import { useState, useCallback } from "react";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

export function usePenaltyCodesImporterModal() {
  const [isOpen, setIsOpen] = useState(false);
  const open = useCallback(() => setIsOpen(true), [setIsOpen]);
  const close = useCallback(() => setIsOpen(false), [setIsOpen]);

  const { season } = useCurrentSeasonContext();

  const seasonTitle = (season && season.title) || "";

  return {
    isOpen,
    open,
    close,
    seasonTitle
  };
}
