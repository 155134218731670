import { all, put, takeLatest } from "redux-saga/effects";

import { loadPlayer } from "@/lib/api/players";
import { fetchOne } from "@/redux/api/sagas";

import { loadingRoutine } from "./routines";

function* loadingSaga({ payload: { seasonId, playerId } }) {
  yield put(loadingRoutine.request());

  try {
    const [player] = yield fetchOne({ type: "players", id: playerId }, loadPlayer, {
      seasonId,
      include: "season,league,association,teams"
    });

    yield put(loadingRoutine.success({ player }));
  } catch (error) {
    const { response } = error;

    yield put(
      loadingRoutine.failure({
        error,
        responseStatus: response && response.responseStatus
      })
    );
  } finally {
    yield put(loadingRoutine.fulfill());
  }
}

export function* currentPlayerFlow() {
  yield all([takeLatest(loadingRoutine.TRIGGER, loadingSaga)]);
}
