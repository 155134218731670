import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getAssociationId,
  getLeagueId,
  getLeagueList,
  getLeagueListIsLoaded,
  getLeagueListIsLoading
} from "@/redux/invitationFilter/selectors";

import actions from "@/redux/invitationFilter/actions";

export default function useLeagueSelect() {
  const associationId = useSelector(getAssociationId);
  const selectedId = useSelector(getLeagueId);
  const options = useSelector(state => getLeagueList(state, associationId));

  const isLoaded = useSelector(state => getLeagueListIsLoaded(state, associationId));

  const isLoading = useSelector(state => getLeagueListIsLoading(state, associationId));

  const dispatch = useDispatch();

  const setSelectedId = useCallback(id => dispatch(actions.setLeagueId(id)), [dispatch]);

  return {
    selectedId,
    options,
    isLoaded,
    isLoading,
    setSelectedId
  };
}
