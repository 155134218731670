import React from "react";
import PropTypes from "prop-types";

import { Input } from "reactstrap";

import { useMonthInputOptions } from "./hooks";

function MonthInput({ onChange, value }) {
  const options = useMonthInputOptions();

  return (
    <Input type="select" value={value === null ? "" : value} onChange={onChange} className="custom-select">
      {value === null && (
        <option disabled value="">
          Month
        </option>
      )}
      {options.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </Input>
  );
}

MonthInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func
};

export default MonthInput;
