import { useSelector } from "react-redux";

import { getMeta } from "@/redux/unsubscribeByToken";

export default function useUnsubscribeByTokenMeta() {
  const { isUnsubscribed, isResubscribed, isOperating, isNotFound } = useSelector(getMeta);

  const isPending = !isNotFound && ((!isUnsubscribed && !isResubscribed) || isOperating);

  return {
    isPending,
    isUnsubscribed,
    isResubscribed,
    isNotFound
  };
}
