import { useSelector, useDispatch } from "react-redux";

import { getGameFormTeamLineup } from "@/redux/gameForm/selectors";

import actions from "@/redux/gameForm/actions";

export default function usePlayerRow({ team, playerId }) {
  const lineup = useSelector(state => getGameFormTeamLineup(state, team)).map(({ id }) => id);
  const alreadyOnLineup = lineup.includes(playerId);

  const dispatch = useDispatch();
  const addToLineup = player => dispatch(actions.addPlayer({ team, player }));

  return {
    alreadyOnLineup,
    addToLineup
  };
}
