import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { deletingRoutine, getMeta } from "@/redux/leagueForm";

export default function useLeagueFormDeleting({ leagueId, associationId }) {
  const dispatch = useDispatch();
  const { isDeleting } = useSelector(getMeta);

  const deleteLeague = useCallback(
    () => {
      if (leagueId) {
        dispatch(deletingRoutine({ associationId, leagueId }));
      }
    },
    [dispatch, associationId, leagueId]
  );

  return {
    isDeleting,
    deleteLeague
  };
}
