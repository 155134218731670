import React, { useCallback } from "react";

import { Input } from "reactstrap";

import useTitleSelect from "./hooks/useTitleSelect";

function AssociationSelect() {
  const { title, setTitle } = useTitleSelect();
  const onChange = useCallback(({ target: { value } }) => setTitle(value), [setTitle]);

  return (
    <Input type="select" value={title} onChange={onChange}>
      <option value="" disabled>
        Select a role
      </option>
      <option value="administrator">Administrator</option>
      <option value="director">Director</option>
      <option value="convenor">Convenor</option>
      <option value="manager">Manager</option>
      <option value="scheduler">Scheduler</option>
      <option value="observer">Observer</option>
      <option value="guest">Guest</option>
    </Input>
  );
}

export default AssociationSelect;
