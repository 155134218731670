import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  isTeamListLoaded,
  isTeamListLoading,
  isTeamListForbidden,
  getDivisionTeamList,
  getTeamsLocking
} from "@/redux/teams/selectors";

import { TeamLockingRoutine } from "@/redux/teams/routines";

export default function useDivisionTeamsList() {
  const dispatch = useDispatch();

  const isLoaded = useSelector(isTeamListLoaded);
  const isLoading = useSelector(isTeamListLoading);
  const isForbidden = useSelector(isTeamListForbidden);
  const teams = useSelector(getDivisionTeamList);
  const lockingTeams = useSelector(getTeamsLocking);

  const triggerLockTeam = useCallback(({ teamId, lock }) => dispatch(TeamLockingRoutine({ teamId, lock })), [dispatch]);

  return {
    isLoaded,
    isLoading,
    isForbidden,
    teams,
    lockingTeams,
    triggerLockTeam
  };
}
