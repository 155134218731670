import { createRoutine } from "redux-saga-routines";

export const associationListLoadingRoutine = createRoutine("INVITATION_FILTER/ASSOCIATION_LIST/LOADING");

export const leagueListLoadingRoutine = createRoutine("INVITATION_FILTER/LEAGUE_LIST/LOADING");

export const seasonListLoadingRoutine = createRoutine("INVITATION_FILTER/SEASON_LIST/LOADING");

export const divisionListLoadingRoutine = createRoutine("INVITATION_FILTER/DIVISION_LIST/LOADING");

export const teamListLoadingRoutine = createRoutine("INVITATION_FILTER/TEAM_LIST/LOADING");
