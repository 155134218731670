import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap";
import { TieBreakerTable } from "./TieBreakerTable";
import Spinner from "@/components/Spinner";
import { useDivision } from "./hooks";
import axios from "axios";
import { config } from "../../../../config";
import "./ManualTieBreakerModal.scss";
import { getFreshToken } from "@/lib/api/getFreshToken";

export function ManualTieBreaker({ modalIsOpen, setModalIsOpen, isFirstCriteria }) {
  const location = useLocation();
  const division = useDivision();

  const toggleManualTeamRanksModal = (e) => {
    e.preventDefault();
    setModalIsOpen(!modalIsOpen);
  };

  const closeManualTeamRanksModal = (e) => {
    e.preventDefault();
    setModalIsOpen(!modalIsOpen);
    division.setGameTypeFilter("overall");
    if (!location.pathname.includes("division")) {
      division.setSelectedDivision(null);
    }
  };

  const selectDivisionToEdit = (e) => {
    e.preventDefault();
    const filteredDivision = division.divisions.filter(
      (division) => division.id === e.target.value
    );
    division.setSelectedDivision(filteredDivision[0]);
  };

  const selectGameType = (e) => {
    e.preventDefault();
    if (e.target.value !== 1) {
      division.setGameTypeFilter(e.target.value);
    } else {
      division.setGameTypeFilter(null);
    }
  };

  const saveButton = async () => {
    let token = await getFreshToken();

    const data = division.dataToSubmit.map(d => ({ ...d, id: 0 }));

    if (division.selectedDivision.id) {
      await axios
        .post(
          `${config.BFF_API}/standings`,
          {
            event: "rerank-standings",
            attributes: {
              schema: "standings-event"
            },
            data
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          let currentDivision = division.selectedDivision;
          division.setSelectedDivision(null);
          division.setGameTypeFilter("overall");
          if (location.pathname.includes("division")) {
            division.setSelectedDivision(currentDivision);
          }
          setModalIsOpen(!modalIsOpen);
        });
    }
  };

  useEffect(() => {
    if (location.pathname.includes("division") && division.divisions.length > 0) {
      let regex = /([0-9])\w+/g;
      let divisionIdFromURL = location.pathname.match(regex)[1];
      let filteredDivision = division.divisions.filter(
        (division) => division.id === divisionIdFromURL
      );
      division.setSelectedDivision(filteredDivision[0]);
    }
  }, [location, division.divisions]);

  return (
    <div>
      <div className="manual__options__container">
        <Button color="primary" outline={true} onClick={toggleManualTeamRanksModal} size="sm">
          Rank Teams
        </Button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        toggle={toggleManualTeamRanksModal}
        centered
        size="xl"
        backdrop="static"
        fade={false}
      >
        <ModalHeader>
          <div className="modal__header__container">
            {!location.pathname.includes("division") ? (
              <div className="modal__header__selector">
                <label className="modal__select__label">Division</label>
                <Input
                  type="select"
                  onChange={selectDivisionToEdit}
                  bsSize="sm"
                  className="modal__select__input"
                >
                  <option value={null}>Choose A Division...</option>
                  {division.divisions &&
                    division.divisions.map((division) => {
                      return (
                        <option
                          value={division.id}
                          key={division.id}
                          disabled={division.hasOwnSettings}
                        >
                          {division.name}
                        </option>
                      );
                    })}
                </Input>
                <label className="modal__select__label">Game Type</label>
                <Input
                  type="select"
                  onChange={selectGameType}
                  bsSize="sm"
                  className="modal__select__input"
                >
                  {division.gameTypeList.map((gameType) => {
                    return (
                      <option value={gameType.searchTerm} key={gameType.searchTerm}>
                        {gameType.label}
                      </option>
                    );
                  })}
                </Input>
              </div>
            ) : (
              <div className="modal__header__selector">
                <div className="modal__select__division">
                  {division.selectedDivision && division.selectedDivision.name}
                </div>
                <label className="modal__select__label">Game Type</label>
                <Input
                  type="select"
                  onChange={selectGameType}
                  bsSize="sm"
                  className="modal__select__input"
                >
                  {division.gameTypeList.map((gameType) => {
                    return (
                      <option value={gameType.searchTerm} key={gameType.searchTerm}>
                        {gameType.label}
                      </option>
                    );
                  })}
                </Input>
              </div>
            )}
          </div>
        </ModalHeader>

        <ModalBody className="modal_body">
          {!division.selectedDivision && <p>Select a division to edit standings. For divisions that do not use season settings, go to the Divisions page, click Actions {">"} Edit, and use manual sorting.</p>}
          {division.selectedDivision && (
            <p>Click and drag the tied teams into the desired order.</p>
          )}

          {division.isLoading && (
            <div className="modal__spinner">
              <Spinner theme="loader" color="yellow" size="xxl" />
            </div>
          )}

          {division.selectedDivision && division.divisionStandings && (
            <TieBreakerTable
              allowSortAllRows={isFirstCriteria}
              data={division.divisionStandings}
              dataToSubmit={division.dataToSubmit}
              setDataToSubmit={division.setDataToSubmit}
            />
          )}
        </ModalBody>

        <ModalFooter>
          <div className="d-flex w-100">
            <div className="mr-auto">
              <Button outline type="button" color="secondary" onClick={closeManualTeamRanksModal}>
                Cancel
              </Button>
            </div>

            <div>
              {division.selectedDivision && division.selectedDivision.id ? (
                <Button type="button" color="success" onClick={saveButton}>
                  Save
                </Button>
              ) : (
                <Button outline disabled type="button" color="success">
                  Save
                </Button>
              )}
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}
