import React, { PureComponent } from "react";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";

import ReportDataSourcesSelect from "@/components/ReportDataSourcesSelect";

class DataSourcesPage extends PureComponent {
  render() {
    return (
      <ProtectedLayout subject="reports" action="read">
        <Head title="Suspension Report" />

        <Breadcrumbs
          items={[
            { title: "Reports" },
            {
              url: "/reports/suspension/sources",
              title: "Suspension Report"
            }
          ]}
        />

        <TitleBar title="Suspension Report" />

        <ReportDataSourcesSelect url="/reports/suspension" singleSelect />
      </ProtectedLayout>
    );
  }
}

export default DataSourcesPage;
