import React from "react";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";

import RuntimeErrorToast from "@/components/RuntimeErrorToast";

export default function handleRuntimeError(error) {
  if (error.isAxiosError) {
    return;
  }

  Sentry.captureException(error);

  console.error(error);

  toast.error(<RuntimeErrorToast />, {
    autoClose: false
  });
}
