import React from "react";

import Layout from "@/layouts/Layout";
import NotFound from "@/components/NotFound";

const NotFoundPage = () => (
  <Layout>
    <NotFound />
  </Layout>
);

export default NotFoundPage;
