import _isEmpty from "lodash/isEmpty";

export function validateText(value) {
  const errors = [];

  if (_isEmpty(value)) {
    errors.push("Note can not be empty");
  }

  return errors;
}

export function validate({ text }) {
  let errors = null;

  const textErrors = validateText(text);

  if (textErrors.length > 0) {
    errors = { ...errors, text: textErrors };
  }

  return errors;
}
