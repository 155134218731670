import { handleActions } from "redux-actions";

import { invitationFormActions } from "../../actions";

const defaultState = {
  isOpen: false,
  association: null,
  league: null,
  seasons: []
};

const open = () => Object.assign({}, defaultState, { isOpen: true });
const close = () => Object.assign({}, defaultState, { isOpen: false });

const toggleOpened = state => Object.assign({}, defaultState, { isOpen: !state.isOpen });

const setAssociation = (state, { payload }) =>
  Object.assign({}, state, {
    association: payload,
    league: null
  });

const setLeague = (state, { payload }) =>
  Object.assign({}, state, {
    league: payload
  });

const selectSeason = (state, { payload }) =>
  Object.assign({}, state, {
    seasons: [...state.seasons, payload]
  });

const deselectSeason = (state, { payload }) =>
  Object.assign({}, state, {
    seasons: state.seasons.filter(id => id !== payload)
  });

export default handleActions(
  {
    [invitationFormActions.seasonsList.open]: open,
    [invitationFormActions.seasonsList.close]: close,
    [invitationFormActions.seasonsList.toggleOpened]: toggleOpened,
    [invitationFormActions.seasonsList.selectAssociation]: setAssociation,
    [invitationFormActions.seasonsList.selectLeague]: setLeague,
    [invitationFormActions.seasonsList.selectSeason]: selectSeason,
    [invitationFormActions.seasonsList.deselectSeason]: deselectSeason
  },
  defaultState
);
