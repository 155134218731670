import React from "react";
import { Table } from "reactstrap";

import TotalCount from "@/components/TotalCount";
import EmptyListMessage from "@/components/EmptyListMessage";
import ListControls from "@/components/ListControls";
import DashboardForm from "@/components/DashboardForm";

import AddPlayerButton from "./AddPlayerButton";
import CreatePlayerButton from "./CreatePlayerButton";
import Player from "./Player";
import { PlayerForm } from "./Form";

import useRoster from "./hooks/useRoster";

import "./PlayersRoster.scss";

function PlayersRoster({ otp }) {
  const {
    team,
    players,
    totalPlayersCount,
    filteredPlayersCount,
    isEditing: isRosterEditing,
    ignoreLock,
    isOperating
  } = useRoster();

  if (players.length < 1) {
    return (
      <EmptyListMessage>
        <p>There are no players</p>
        {(!team.data.rosterLocked || ignoreLock) && (
          <p>
            <AddPlayerButton team={team}>+ Add Player from Season</AddPlayerButton>{" "}
            <CreatePlayerButton team={team}>Create New Player</CreatePlayerButton>
          </p>
        )}
      </EmptyListMessage>
    );
  }

  return (
    <DashboardForm disabled={isOperating}>
      <div className="players-roster">
        {(!team.data.rosterLocked || ignoreLock) && (
          <ListControls>
            <AddPlayerButton team={team} outline disabled={isRosterEditing || isOperating}>
              Add Player from Season
            </AddPlayerButton>
            <CreatePlayerButton team={team} disabled={isRosterEditing}>
              + New Player
            </CreatePlayerButton>
          </ListControls>
        )}

        <TotalCount
          plural="players"
          singular="player"
          totalCount={totalPlayersCount}
          filteredCount={filteredPlayersCount}
        />

        <Table striped borderless>
          <thead>
            <tr>
              <th scope="col" className="players-roster__number">
                Jersey
              </th>
              <th scope="col" className="players-roster__first-name">
                First name
              </th>
              <th scope="col" className="players-roster__last-name">
                Last name
              </th>
              <th scope="col" className="players-roster__external-id">
                External Id
              </th>
              <th scope="col" className="players-roster__affiliated">
                Status
              </th>
              <th scope="col" className="players-roster__position">
                Position
              </th>
              <th scope="col" className="players-roster__duty">
                Designation
              </th>
              <th scope="col" className="players-roster__actions">
                &nbsp;
              </th>
            </tr>
          </thead>
          <tbody>
            {players.map(
              ({ id, isEditing, ...rest }) =>
                isEditing ? (
                  <PlayerForm key={id} playerId={id} />
                ) : (
                  <Player muted={isRosterEditing} key={id} id={id} team={team} otp={otp} {...rest} />
                )
            )}
          </tbody>
        </Table>
      </div>
    </DashboardForm>
  );
}

export default PlayersRoster;
