import _snakeCase from "lodash/snakeCase";

import { createGetService, createPostService, createPatchService, createDeleteService } from "./serviceMaker";

const makeSeasonTeamUrl = ({ seasonId, identity }) => ({
  url: `/seasons/${seasonId}/teams/${identity.id}`
});

export const loadTeams = createGetService(
  ({ seasonId, divisionId, include, title, ids, pageSize, pageNumber, sort }) => ({
    url: divisionId ? `/divisions/${divisionId}/teams` : seasonId ? `/seasons/${seasonId}/teams` : "/teams",
    params: {
      include: include || (divisionId ? undefined : "divisions"),
      filter: { title, id: ids && ids.join(",") },
      ...(!!pageNumber && !!pageSize ? { page: { number: pageNumber, size: pageSize } } : {}),
      ...(!!sort ? { sort } : {})
    }
  })
);

export const createTeam = createPostService(({ seasonId, attributes, divisionId }) => ({
  url: `/seasons/${seasonId}/teams`,
  attributes,
  relationships: {
    season: {
      data: { id: seasonId, type: "seasons" }
    },
    division: {
      data: { id: divisionId, type: "divisions" }
    }
  }
}));

export const loadTeam = createGetService(({ identity, seasonId, include = "players,coaches" }) => ({
  url: seasonId ? `/seasons/${seasonId}/teams/${identity.id}` : `/teams/${identity.id}`,
  params: { include: include || undefined }
}));

export const updateTeam = createPatchService(({ seasonId, divisionId, identity, attributes }) => ({
  url: `/seasons/${seasonId}/teams-v2/${identity.id}`,
  relationships: {
    division: {
      data: { id: divisionId, type: "divisions" }
    }
  },
  attributes
}));

export const deleteLogo = createDeleteService(({ seasonId, teamId }) => ({
  url: `/seasons/${seasonId}/teams-v2/${teamId}/logo`
}));

export const lockTeam = createPatchService(({ seasonId, teamId, attributes }) => ({
  url: `/seasons/${seasonId}/teams/${teamId}/roster-lock`,
  attributes
}));

export const deleteTeam = createDeleteService(makeSeasonTeamUrl);

export const importTeam = createPostService(({ seasonId, divisionId, externalId, title, roster }) => ({
  url: `/seasons/${seasonId}/teams/import`,
  attributes: { externalId, title, roster },
  relationships: {
    season: {
      data: { id: seasonId, type: "seasons" }
    },
    division: {
      data: { id: divisionId, type: "divisions" }
    }
  }
}));

export const searchTeams = createGetService(({ filter, size, page, include }) => ({
  url: `/teams`,
  params: {
    filter: Object.entries(filter).reduce((result, [key, value]) => ({ ...result, [_snakeCase(key)]: value }), {}),
    page: { number: page, size },
    include
  }
}));
