import { combineReducers } from "redux";

import createListReducer from "./teamMemberPenaltyReportReducers/createListReducer";
import createReportReducer from "./teamMemberPenaltyReportReducers/createReportReducer";

export default function createTeamMemberPenaltyReportReducer(options) {
  const { actions, listLoadingRoutine, loadingRoutine, memberType } = options;

  return combineReducers({
    list: createListReducer({
      actions,
      loadingRoutine: listLoadingRoutine,
      memberType
    }),
    report: createReportReducer({
      actions,
      loadingRoutine
    })
  });
}
