import { takeLatest, takeEvery, all, put, call, select, debounce, delay, race, take } from "redux-saga/effects";

import { gamesheetAPIRequest, fetchList } from "@/redux/api/sagas";
import { loadGames, toggleGameViewed } from "@/lib/api/games";

import { actions as filterActions, getFilter } from "@/redux/completedGamesFilter";

import { clearGamesheet } from "@/redux/gamesheet/actions";

import { loadGamesRoutine, toggleGameViewedRoutine } from "./routines";

import { getGamesListPageSize } from "./selectors";

function* loadGamesSaga({ payload }) {
  const { pageNumber = 1, seasonId } = payload;
  const filter = yield select(getFilter);
  const pageSize = yield select(getGamesListPageSize);

  yield put(loadGamesRoutine.request({ pageNumber }));

  const response = yield fetchList("games", loadGames, {
    pageNumber,
    pageSize,
    seasonId,
    filter,
    include: "teams,suspensions"
  });

  yield put(loadGamesRoutine.success({ ...response, pageNumber }));

  yield put(loadGamesRoutine.fulfill({ pageNumber }));
}

function* toggleGameViewedSaga({ payload: { isViewed, id, delayed = false } }) {
  if (delayed) {
    const { abort } = yield race({
      abort: take(({ type, payload }) => type === clearGamesheet.toString() && payload && payload.id === id),
      delay: delay(3000)
    });

    if (abort) {
      return;
    }
  }

  yield put(toggleGameViewedRoutine.request({ id }));

  try {
    yield call(gamesheetAPIRequest, toggleGameViewed, { id, isViewed });

    yield put(toggleGameViewedRoutine.success({ id, isViewed }));
  } catch (error) {
    yield put(toggleGameViewedRoutine.failure({ id, error }));
  } finally {
    yield put(toggleGameViewedRoutine.fulfill({ id }));
  }
}

export function* gamesFlow() {
  yield all([
    takeLatest(loadGamesRoutine.TRIGGER, loadGamesSaga),
    takeLatest(
      ({ type }) =>
        [
          filterActions.setDateFrom,
          filterActions.setDateTo,
          filterActions.setViewedStatus,
          filterActions.setFlaggedStatus,
          filterActions.setDivisionIds,
          filterActions.reset,
          filterActions.setGametype
        ]
          .map(a => a.toString())
          .includes(type),
      loadGamesSaga
    ),
    debounce(300, filterActions.setQuery, loadGamesSaga),
    takeEvery(toggleGameViewedRoutine.TRIGGER, toggleGameViewedSaga)
  ]);
}
