import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import SuspensionReportSuspensionGameNumber from "./GameNumber";
import SuspensionReportSuspensionPerson from "./SuspendedPerson";
import SuspensionReportSuspensionTeam from "./Team";
import SuspensionReportSuspensionDivision from "./Division";
import SuspensionReportSuspensionCount from "./Count";

class Suspension extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    disableLinks: PropTypes.bool,
    game: PropTypes.shape({
      date: PropTypes.string
    }).isRequired,
    team: PropTypes.shape({}).isRequired,
    division: PropTypes.shape({}).isRequired,
    suspendedPerson: PropTypes.shape({
      type: PropTypes.string.isRequired
    }),
    number: PropTypes.number.isRequired,
    length: PropTypes.number.isRequired
  };

  static defaultProps = {
    disableLinks: false
  };

  render() {
    const { disableLinks, team, division, game, suspendedPerson, number, length } = this.props;

    return (
      <tr>
        <td>{game.date}</td>
        <td>
          <SuspensionReportSuspensionGameNumber noLink={disableLinks} game={game} />
        </td>
        <td className="suspension-report__table__person_type">{suspendedPerson.type}</td>
        <td>
          <SuspensionReportSuspensionPerson noLink={disableLinks} {...suspendedPerson} />
        </td>
        <td>
          <SuspensionReportSuspensionTeam noLink={disableLinks} {...team} />
        </td>
        <td>
          <SuspensionReportSuspensionDivision noLink={disableLinks} {...division} />
        </td>
        <td>
          <SuspensionReportSuspensionCount number={number} length={length} />
        </td>
      </tr>
    );
  }
}

export default Suspension;
