import _trim from "lodash/trim";

export default (baseClassName, key = "theme") => theme => {
  const mapThemeClasses = themes => themes.map(t => `${baseClassName}_${key}_${t}`);

  const strToArr = str =>
    _trim(str)
      .split(" ")
      .filter(t => t !== "");

  const themes = Array.isArray(theme) ? theme : strToArr(theme);

  return mapThemeClasses(themes);
};
