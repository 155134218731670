import _trimEnd from "lodash/trimEnd";

const selectRoot = state => state.accountVerificationForm;
const selectFields = state => selectRoot(state).fields;
const selectFieldValue = (state, name) => selectFields(state)[name].value;

export const getAccount = state => selectRoot(state).account;
export const getMeta = state => selectRoot(state).meta;

export const getField = (state, name) => {
  const { value, errors } = selectFields(state)[name];

  return { value, errors, isInvalid: errors.length > 0 };
};

export const getIsValid = state => {
  const fields = selectFields(state);

  return Object.values(fields).filter(({ errors }) => errors.length > 0).length === 0;
};

export const getIsDirty = state => {
  const fields = selectFields(state);

  return Object.values(fields).filter(({ isDirty }) => isDirty).length > 0;
};

export const getIsNotFilled = state => {
  const fields = selectFields(state);

  return Object.entries(fields).filter(([name, { value }]) => _trimEnd(value) === "").length > 0;
};

export const getIsInvalid = state => getIsValid(state) === false;
export const getIsPristine = state => getIsDirty(state) === false;

export const getAttributes = state => {
  const password = selectFieldValue(state, "password");
  const passwordConfirmation = selectFieldValue(state, "passwordConfirmation");

  return { password, passwordConfirmation };
};
