import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import List from "../List";

class AssociationList extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    isLoaded: PropTypes.bool,
    isOptionSelected: PropTypes.bool,
    singleSelect: PropTypes.bool,
    associations: PropTypes.arrayOf(PropTypes.shape({})),
    loadList: PropTypes.func,
    select: PropTypes.func,
    deselect: PropTypes.func,
    clear: PropTypes.func
  };

  static defaultProps = {
    isLoading: false,
    isLoaded: false,
    isOptionSelected: false,
    singleSelect: false,
    associations: [],
    loadList() {},
    select() {},
    deselect() {},
    clear() {}
  };

  componentDidMount() {
    const { isLoading, isLoaded, loadList } = this.props;

    if (!isLoading && !isLoaded) {
      loadList();
    }
  }

  componentDidUpdate(prevProps) {
    const { isLoaded, isLoading, loadList } = this.props;

    if (prevProps.isLoaded && isLoaded === false && isLoading === false) {
      loadList();
    }
  }

  render() {
    const {
      isLoading,
      isLoaded,
      isOptionSelected,
      singleSelect,
      associations,
      loadList,
      select,
      deselect,
      clear
    } = this.props;

    return (
      <List
        isLoading={isLoading}
        isLoaded={isLoaded}
        isOptionSelected={isOptionSelected}
        singleSelect={singleSelect}
        options={associations}
        loadList={loadList}
        select={select}
        deselect={deselect}
        clear={clear}
        label="Select association"
      />
    );
  }
}

export default AssociationList;
