import React from "react";
import PropTypes from "prop-types";

import "./SignatureImage.scss";

const SignatureImage = ({ src, name }) => {
  return src && src !== "" ? (
    <img className="signature-image" src={src} alt={name ? `${name} Signature` : "Signature"} />
  ) : null;
};

SignatureImage.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string
};

export default SignatureImage;
