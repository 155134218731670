import { getResources, getResource } from "@/redux/resources/selectors";
import { getCurrentAssociation, getCurrentAssociationBreadcrumbs } from "@/redux/associations/selectors";

const rootSelector = state => state.leagues;
const listSelector = state => rootSelector(state).list;
const newSelector = state => rootSelector(state).new;
const currentSelector = state => rootSelector(state).current;

export const isLeagueListLoading = state => listSelector(state).isLoading;
export const isLeagueListLoaded = state => listSelector(state).isLoaded;
export const isLeagueListForbidden = state => listSelector(state).isForbidden;

export const getLeagueList = state => {
  if (!isLeagueListLoaded(state)) {
    return [];
  }

  return getResources(state, "leagues", listSelector(state).ids);
};

export const isLeagueCreating = state => newSelector(state).isCreating;

export const isCurrentLeagueLoaded = state => currentSelector(state).isLoaded;

export const isCurrentLeagueOperating = state => {
  const { operation } = currentSelector(state);

  return typeof operation === "string" && operation.length > 0;
};

export const isCurrentLeagueDeleted = state => currentSelector(state).isDeleted;

export const isCurrentLeagueReady = state => isCurrentLeagueLoaded(state) && !isCurrentLeagueDeleted(state);

export const isCurrentLeagueForbidden = state => currentSelector(state).isForbidden;

export const getCurrentLeague = state => {
  const { id, isLoaded, isDeleted } = currentSelector(state);

  return isLoaded && !isDeleted ? getResource(state, "leagues", id) : undefined;
};

export const getCurrentLeagueId = state => currentSelector(state).id;

export const getCurrentLeagueBreadcrumbs = state => {
  const association = getCurrentAssociation(state);
  const league = getCurrentLeague(state);
  const associationBreadcrumbs = getCurrentAssociationBreadcrumbs(state);

  if (association && league && associationBreadcrumbs) {
    return [
      ...associationBreadcrumbs,
      {
        url: `/associations/${association.id}/leagues/${league.id}/seasons`,
        title: league.title
      }
    ];
  }
};

export const getLeagueListTotalCount = state => listSelector(state).totalCount;
