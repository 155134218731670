import { atom } from "recoil";
import { CSVRefereeImportDisplayPage as DisplayPage } from "@/components-16.8/RefereeImportWizard/types/displayPage";
import { ImportStatus } from "@/components-16.8/RefereeImportWizard/types/importStatus";

export const csvRefereeImportDisplayPageState = atom({
  key: 'csvRefereeImportDisplayPageState',
  default: DisplayPage.LANDING
});

export const importStatusState = atom({
  key: 'ImportStatusState',
  default: ImportStatus.PENDING
});

export const recordsState = atom({
  key: 'recordsState',
  default: []
});

export const readingErrorState = atom({
  key: 'readingErrorState',
  default: null
});

export const badRecordsState = atom({
  key: 'badRecordsState',
  default: []
});

export const importingErrorState = atom({
  key: 'importingErrorState',
  default: null
});

export const importProgressState = atom({
  key: 'importProgressState',
  default: {}
});