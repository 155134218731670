export default teamType => (state, { payload: { game } }) => {
  const team = game[teamType];
  const goals = game[`${teamType}Goals`] || [];
  const penalties = game[`${teamType}Penalties`] || [];
  const { players, coaches } = game[`${teamType}Roster`];
  const suspensions = game[`${teamType}Suspensions`];
  const fairPlayEarned = game[`${teamType}FppEarned`];

  return {
    id: team.id,
    title: team.title,
    playersIds: (players || []).map(({ id }) => id),
    coachesIds: (coaches || []).map(({ id }) => id),
    division: (team.division || {}).title,
    goals,
    penalties,
    suspensions,
    fairPlayEarned: fairPlayEarned
  };
};
