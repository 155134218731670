import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getTitle } from "@/redux/roleForm/selectors";
import actions from "@/redux/roleForm/actions";

export default function useTitleSelect() {
  const dispatch = useDispatch();
  const title = useSelector(getTitle);

  const setTitle = useCallback(newTitle => dispatch(actions.setTitle(newTitle)), [dispatch]);

  return {
    title,
    setTitle
  };
}
