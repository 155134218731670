import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getIsDeleting, deletingRoutine } from "@/redux/scheduledGameForm";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

export default function useScheduledGameFormDeleting(gameId) {
  const dispatch = useDispatch();
  const { seasonId } = useCurrentSeasonContext();

  const isDeleting = useSelector(getIsDeleting);

  const deleteGame = useCallback(
    () => {
      dispatch(deletingRoutine({ gameId, seasonId }));
    },
    [dispatch, gameId, seasonId]
  );

  return {
    isDeleting,
    deleteGame
  };
}
