import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";

import _trimEnd from "lodash/trimEnd";

import InfiniteScroll from "@/components/InfiniteScroll";
import TotalCount from "@/components/TotalCount";
import AbilityButton from "@/components/AbilityButton";

import CoachesListSearchBar from "./SearchBar";
import CoachesListCoach from "./Coach";
import CoachesListMergeTool from "./MergeTool";
import CoachesListMergeToolFooter from "./MergeTool/Footer";

class CoachesList extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    isLoaded: PropTypes.bool,
    coaches: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired
      })
    ),
    totalCount: PropTypes.number,
    filteredCount: PropTypes.number,
    currentQuery: PropTypes.string,
    seasonId: PropTypes.string.isRequired,
    mergeToolStatus: PropTypes.string,
    isAppending: PropTypes.bool,
    hasMore: PropTypes.bool,
    isMergeToolEnabled: PropTypes.bool,
    loadMoreCoaches: PropTypes.func,
    enableMergeTool: PropTypes.func
  };

  static defaultProps = {
    isLoading: false,
    isLoaded: false,
    hasMore: false,
    coaches: [],
    totalCount: 0,
    filteredCount: 0,
    currentQuery: "",
    mergeToolStatus: null,
    isAppending: false,
    isMergeToolEnabled: false,
    loadMoreCoaches() {},
    enableMergeTool() {}
  };

  render() {
    const {
      coaches,
      seasonId,
      currentQuery,
      mergeToolStatus,
      loadMoreCoaches,
      enableMergeTool,
      hasMore,
      isLoaded,
      isLoading,
      isAppending,
      isMergeToolEnabled,
      totalCount,
      filteredCount
    } = this.props;

    return (
      <Fragment>
        <CoachesListMergeTool />

        {(!isMergeToolEnabled || mergeToolStatus === "selecting-coaches") && (
          <Fragment>
            <div className="d-flex mb-5">
              <CoachesListSearchBar seasonId={seasonId} />

              <div className="ml-auto">
                <AbilityButton
                  subject={{ type: "coaches", seasonId }}
                  action="merge"
                  outline
                  color={isMergeToolEnabled ? "secondary" : "primary"}
                  size="sm"
                  onClick={enableMergeTool}
                  disabled={isMergeToolEnabled}
                >
                  Merge Coaches
                </AbilityButton>

                <AbilityButton
                  subject={{ type: "coaches", seasonId }}
                  action="create"
                  outline
                  className="ml-2"
                  size="sm"
                  tag={Link}
                  color={isMergeToolEnabled ? "secondary" : "success"}
                  to={`/seasons/${seasonId}/roster/coaches/new`}
                  disabled={isMergeToolEnabled}
                >
                  Create Coach
                </AbilityButton>
              </div>
            </div>

            <InfiniteScroll
              dataLength={coaches.length}
              loadList={loadMoreCoaches}
              hasMore={hasMore}
              isLoaded={isLoaded}
              isLoading={isLoading}
              isAppending={isAppending}
              emptyMessage={_trimEnd(currentQuery) === "" ? "There are no coaches" : "No coaches found"}
              endMessage="You’ve reached the end of the coaches list."
            >
              <TotalCount plural="coaches" singular="coach" totalCount={totalCount} filteredCount={filteredCount} />

              <div className="dashboard-table">
                <Table striped borderless className="coaches-list__table">
                  <thead>
                    <tr>
                      <th scope="col">Full name</th>
                      {!isMergeToolEnabled && (
                        <th scope="col">
                          <span className="visuallyhidden">Actions</span>
                        </th>
                      )}
                      <th scope="col">External ID</th>
                      <th scope="col">Team</th>
                      <th scope="col">Division</th>
                      {isMergeToolEnabled && (
                        <th scope="col">
                          <span className="visuallyhidden">Merge</span>
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {coaches.map(({ id, ...rest }) => (
                      <CoachesListCoach key={id} id={id} {...rest} />
                    ))}
                  </tbody>
                </Table>
              </div>
            </InfiniteScroll>
          </Fragment>
        )}

        <CoachesListMergeToolFooter />
      </Fragment>
    );
  }
}

export default CoachesList;
