import React, { Fragment } from "react";
import PropTypes from "prop-types";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import PageLoader from "@/components/PageLoader";

import RefereeReport from "@/components/RefereeReport";

import useRefereeReport from "../hooks/useRefereeReport";

function Report({ seasonId, match: { params } }) {
  const { refereeId } = params;

  const { seasonIsLoaded, seasonIsLoading, seasonBreadcrumbs } = useCurrentSeasonContext();

  const { isRefereeLoaded, isRefereeLoading, isRefereeForbidden, referee, externalId } = useRefereeReport({
    seasonId,
    refereeId
  });

  return (
    <ProtectedLayout
      subject={{ type: "refereeReports", refereeId }}
      action="read"
      skip={!isRefereeLoaded && !isRefereeForbidden}
    >
      {isRefereeLoaded && seasonIsLoaded ? (
        <Fragment>
          <Head title={`${referee.firstName} ${referee.lastName} Referee Report`} />

          <Breadcrumbs
            items={seasonBreadcrumbs}
            lastItem={{
              title: "Referees",
              url: `/seasons/${seasonId}/referees`
            }}
          />

          {externalId !== "" && <RefereeReport externalId={externalId} />}
        </Fragment>
      ) : (
        <PageLoader isLoading={isRefereeLoading || seasonIsLoading} />
      )}
    </ProtectedLayout>
  );
}

Report.propTypes = {
  seasonId: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      refereeId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default Report;
