import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

import { useSubmitButton } from "./hooks";

function SubmitButton({ url, extended, singleSelect, penalties }) {
  const { url: reportUrl, disabled } = useSubmitButton({
    url,
    extended,
    singleSelect,
    penalties
  });

  return (
    <Button outline={disabled} color="success" disabled={disabled} tag={Link} to={reportUrl || ""}>
      Generate Report
    </Button>
  );
}

SubmitButton.propTypes = {
  url: PropTypes.string.isRequired,
  extended: PropTypes.bool,
  singleSelect: PropTypes.bool
};

SubmitButton.defaultProps = {
  extended: false,
  singleSelect: false
};

export default SubmitButton;
