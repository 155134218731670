import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

import GamesheetHistoryRecordAuthor from "./Author";

class Record extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    viewPDF: PropTypes.func.isRequired,
    downloadPDF: PropTypes.func.isRequired,
    author: PropTypes.shape({})
  };

  static defaultProps = {
    author: null,
    disabled: false
  };

  handleViewPDF = () => {
    const { id, viewPDF } = this.props;

    viewPDF(id);
  };

  handleDownloadPDF = () => {
    const { id, downloadPDF } = this.props;

    downloadPDF(id);
  };

  render() {
    const { date, author, disabled } = this.props;

    return (
      <tr>
        <td>{date}</td>
        <td>
          <GamesheetHistoryRecordAuthor data={author} />
        </td>
        <td>
          <Button outline color="primary" size="sm" onClick={this.handleViewPDF} disabled={disabled}>
            View
          </Button>

          <Button outline color="primary" size="sm" onClick={this.handleDownloadPDF} disabled={disabled}>
            Download
          </Button>
        </td>
      </tr>
    );
  }
}

export default Record;
