import { handleActions } from "redux-actions";

import { submittingRoutine } from "../routines";
import actions from "../actions";

export default handleActions(
  {
    [submittingRoutine.FAILURE]: (state, { payload: { validationErrors } }) => validationErrors,
    [actions.clear]: () => ({})
  },
  {}
);
