import { useCallback } from "react";

export function usePenaltyDurationsList(options) {
  const { value: penaltyDurations, onChange: changePenaltyDurations } = options;

  const appendNewPenaltyDuration = useCallback(() => changePenaltyDurations([...penaltyDurations, {
    length: "",
    penaltyClass: "",
  }]), [
    changePenaltyDurations,
    penaltyDurations
  ]);

  const deleteAll = useCallback(() => changePenaltyDurations([]), [changePenaltyDurations]);

  const changePenaltyDuration = useCallback(
    ({ index, value }) => {
      changePenaltyDurations([...penaltyDurations.slice(0, index), value, ...penaltyDurations.slice(index + 1)]);
    },
    [changePenaltyDurations, penaltyDurations]
  );

  const deletePenaltyDuration = useCallback(
    index => changePenaltyDurations(penaltyDurations.filter((_item, itemIndex) => itemIndex !== index)),
    [changePenaltyDurations, penaltyDurations]
  );

  const items = penaltyDurations.map((duration, index) => ({
    ...duration,
    id: index,
    onChangeLength: ({ target: { value } }) => {
      if (value.includes(";")) return;
      return changePenaltyDuration({ index, value: {
        length: value,
        penaltyClass: duration.penaltyClass,
      }});
    },
    onChangeClass: ({ target: { value } }) => {
      return changePenaltyDuration({ index, value: {
        length: duration.length,
        penaltyClass: value,
      }});
    },
    deletePenaltyDuration: () => deletePenaltyDuration(index)
  }));

  return {
    appendNewPenaltyDuration,
    deleteAll,
    penaltyDurations: items,
    isNotBlank: items.length > 0
  };
}
