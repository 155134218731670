import { all, put } from "redux-saga/effects";

import { beginSaga } from "@/redux/common/GenericSaga";

import { createPlayer, loadPlayer, loadPlayers, updatePlayer, deletePlayer } from "@/lib/api/players";

import {
  PlayerListLoadingRoutine,
  PlayerCreatingRoutine,
  CurrentPlayerLoadingRoutine,
  CurrentPlayerUpdatingRoutine,
  CurrentPlayerDeletingRoutine,
  AddPlayerToTeamRoutine
} from "./routines";
import { updateTeam } from "@/lib/api/teams";

const makePlayerIdentity = ({ id }) => ({ type: "players", id });

export const loadPlayerListSaga = beginSaga(PlayerListLoadingRoutine)
  .withService(loadPlayers)
  .loadResources({ type: "players" })
  .andSucceedWith(({ players }) => ({
    ids: players ? Object.keys(players).map(id => id) : []
  }))
  .endSaga();

export const createPlayerSaga = beginSaga(PlayerCreatingRoutine)
  .withService(createPlayer)
  .createResource({ type: "players" })
  .andRedirectTo(({ seasonId, teamId }, { players }) => {
    const playerId = Object.keys(players)[0];

    return teamId
      ? `/seasons/${seasonId}/teams/${teamId}/roster/players/${playerId}/edit`
      : `/seasons/${seasonId}/roster/players/${playerId}`;
  })
  .endSaga();

export const loadCurrentPlayerSaga = beginSaga(CurrentPlayerLoadingRoutine)
  .withService(loadPlayer)
  .loadResource(makePlayerIdentity)
  .endSaga();

export const updateCurrentPlayerSaga = beginSaga(CurrentPlayerUpdatingRoutine)
  .withService(updatePlayer)
  .updateResource(makePlayerIdentity)
  .andRedirectTo(({ seasonId, id }) => `/seasons/${seasonId}/roster/players/${id}`)
  .endSaga();

export const deleteCurrentPlayerSaga = beginSaga(CurrentPlayerDeletingRoutine)
  .withService(deletePlayer)
  .deleteResource(makePlayerIdentity)
  .andRedirectTo(({ seasonId }) => `/seasons/${seasonId}/roster/players`)
  .endSaga();

export const addPlayerToTeamSaga = beginSaga(AddPlayerToTeamRoutine)
  .withService(updateTeam)
  .updateResource(({ teamId }) => ({ type: "teams", id: teamId }))
  .andFinallyPerform(function*({ playerId, seasonId }) {
    yield put(CurrentPlayerLoadingRoutine.trigger({ seasonId, id: playerId }));
  })
  .endSaga();

export function* playersFlow() {
  yield all([
    createPlayerSaga.takeLatest(),
    loadPlayerListSaga.takeLatest(),
    loadCurrentPlayerSaga.takeLatest(),
    updateCurrentPlayerSaga.takeLatest(),
    deleteCurrentPlayerSaga.takeLatest(),
    addPlayerToTeamSaga.takeLatest()
  ]);
}
