import { useSelector } from "react-redux";

import { getIsLoggedIn } from "@/redux/app/selectors";
import { getTokenRoles } from "@/redux/token/selectors";
import { useFirebaseUser } from "@/hooks/useFirebaseUser";

// useFirebaseUser needs to be called in order for Redux to know about the firebase user
// I tried moving it to the top level App, but it stopped working on password recovery, and I don't know why
// So now it's back here (even though we don't use 'user' anywhere just yet)

export default function useLoggedIn() {
  
  const isLoggedIn = useSelector(getIsLoggedIn);
  const roles = useSelector(getTokenRoles);
  const user = useFirebaseUser();

  const shouldRedirectToNewDashboard = roles.length > 0 <= 8 && roles.every(role => role.title === "manager" && role.level.type === "teams");

  return {
    roles,
    isLoggedIn,
    user,
    redirectToNewDashboard: false
  };
}
