import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import "./ConfirmDeletionModal.scss";

function ConfirmDeletionModal(props) {
  const { isDeleting, isOperating, resourceTitle, resourceType, onDelete, children } = props;

  const [isModalOpen, setModalIsOpen] = useState(false);

  const toggleModal = useCallback(() => !isDeleting && setModalIsOpen(!isModalOpen), [
    setModalIsOpen,
    isModalOpen,
    isDeleting
  ]);

  const triggerDelete = useCallback(() => !isDeleting && onDelete(), [isDeleting, onDelete]);

  const btnTitle = isDeleting ? `Deleting ${resourceType}…` : `Delete ${resourceType}`;

  return (
    <div className="confirm-deletion-modal">
      <Button type="button" color="danger" size="sm" outline onClick={toggleModal} disabled={isOperating || isDeleting}>
        {btnTitle}
      </Button>

      <Modal className="confirm-deletion-modal" isOpen={isModalOpen} toggle={toggleModal} centered>
        <ModalHeader>Confirmation</ModalHeader>
        <ModalBody>
          {children === null ? (
            <p>
              Are you sure you want to delete <span className="text-lowercase">{resourceType}</span>{" "}
              <strong>{resourceTitle}</strong>?
            </p>
          ) : (
            children
          )}
        </ModalBody>

        <ModalFooter>
          <div className="d-flex w-100">
            <div className="mr-auto">
              <Button type="button" color="secondary" onClick={toggleModal} disabled={isOperating || isDeleting}>
                Cancel
              </Button>
            </div>

            <div>
              <Button outline type="button" color="danger" onClick={triggerDelete} disabled={isOperating || isDeleting}>
                {btnTitle}
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}

ConfirmDeletionModal.propTypes = {
  isOperating: PropTypes.bool,
  isDeleting: PropTypes.bool,
  resourceTitle: PropTypes.string.isRequired,
  resourceType: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  children: PropTypes.node
};

ConfirmDeletionModal.defaultProps = {
  isOperating: false,
  isDeleting: false,
  children: null,
  onDelete() {}
};

export default ConfirmDeletionModal;
