import React, { Fragment, useState, useCallback, useContext } from "react";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";

import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu
} from "reactstrap";

import { getIsLoggedIn } from "@/redux/app/selectors";
import getIsIPad from "@/lib/core/getIsIPad";

import { Can } from "@/components/Ability";
import AccountNav from "@/containers/AccountNav";

import logo from "./assets/logo.svg";

import "./Header.scss";
import CurrentDisplaySettings from "@/contexts/CurrentDisplaySettingsContext/Context";

function Header() {
  const isIpad = getIsIPad(navigator);

  const isLoggedIn = useSelector(getIsLoggedIn);
  const { nav } = useContext(CurrentDisplaySettings);

  const [isReportsDropdownExpanded, setIsReportsDropdownExpanded] = useState(false);

  const toggleReportsDropdown = useCallback(() => setIsReportsDropdownExpanded(!isReportsDropdownExpanded), [
    isReportsDropdownExpanded,
    setIsReportsDropdownExpanded
  ]);

  if(!nav){
    return null;
  }

  return (<>
    <header className="header">
      <Navbar dark expand="sm">
        <div className="container">
          <NavbarBrand tag={Link} to="/">
            <img className="logo" src={logo} alt="GameSheet Inc" />
          </NavbarBrand>

          {isLoggedIn && (
            <Fragment>
              <Nav className="mr-auto" navbar>
                <Can I="read" an="associations">
                  <NavItem>
                    <NavLink tag={Link} to="/associations">
                      Associations
                    </NavLink>
                  </NavItem>
                </Can>

                <Can I="read" a="reports">
                  <Dropdown nav isOpen={isReportsDropdownExpanded} toggle={toggleReportsDropdown}>
                    <DropdownToggle nav caret>
                      Reports
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem tag={Link} to="/reports/player-penalty">
                        Player Penalty Reports
                      </DropdownItem>
                      <DropdownItem tag={Link} to="/reports/bench-staff-penalty">
                        Bench Staff Penalty Reports
                      </DropdownItem>
                      <DropdownItem tag={Link} to="/reports/penalty-accumulation/sources">
                        Penalty Accumulation Reports
                      </DropdownItem>
                      <DropdownItem tag={Link} to="/reports/penalty-type/sources">
                        Penalty Type Reports
                      </DropdownItem>
                      <DropdownItem tag={Link} to="/reports/referee">
                        Referee Reports
                      </DropdownItem>
                      <DropdownItem tag={Link} to="/reports/trainer-visits/sources">
                        Trainer Visits Reports
                      </DropdownItem>
                      <DropdownItem tag={Link} to="/reports/billing/sources">
                        Billing Reports
                      </DropdownItem>
                      <DropdownItem tag={Link} to="/reports/suspension/sources">
                        Suspension Reports
                      </DropdownItem>
                      <DropdownItem tag={Link} to="/reports/incidents/sources">
                        Incidents &amp; Suspension Reports
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </Can>

                <Can I="create" an="invitations">
                  <NavItem>
                    <NavLink tag={Link} to="/invitations">
                      Invitations
                    </NavLink>
                  </NavItem>
                </Can>

                <Can I="read" an="users">
                  <NavItem>
                    <NavLink tag={Link} to="/users">
                      Users
                    </NavLink>
                  </NavItem>
                </Can>
              </Nav>

              <Nav navbar>
                {isIpad && (
                  <NavItem>
                    <NavLink href="itms-apps://apps.apple.com/ca/app/gamesheet/id1633700093?mt=8&ls=1">
                      Download iPad App
                    </NavLink>
                  </NavItem>
                )}

                <AccountNav />
              </Nav>
            </Fragment>
          )}
        </div>
      </Navbar>
    </header>
    <div className="fancybar" />
  </>);
}

export default Header;
