import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { divisionOptionsState, divisionOptionsLoadedState } from './state';

export function useDivisionsInput({ seasonId }) {
  const [isLoaded, setIsLoaded] = useRecoilState(divisionOptionsLoadedState);
  const options = useRecoilValue(divisionOptionsState({ seasonId }));

  useEffect(() => {
    setIsLoaded(true);
  }, [divisionOptionsState]);

  return {
    options,
    isLoaded
  };
}