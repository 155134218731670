import { useEffect } from "react";

export default function useListLoading({ disabled, loadList }) {
  useEffect(
    () => {
      if (!disabled) {
        loadList();
      }
    },
    [disabled, loadList]
  );
}
