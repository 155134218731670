import moment from "moment";

export default function makeSeasonMemberAttributes(member) {
  const { firstName, lastName, externalId, birthdate } = member;

  return {
    firstName,
    lastName,
    externalId,
    birthdate:
      birthdate && birthdate !== ""
        ? moment(
            birthdate,
            ["MM-DD-YYYY", "MM/DD/YYYY", "YYYY/MM/DD", "DD-MM-YYYY", "DD/MM/YYYY", moment.ISO_8601],
            true
          ).format("YYYY-MM-DD")
        : undefined
  };
}
