export const getAccount = state => state.account.account;

export const getAccountFields = state => getAccount(state).fields;

export const getAccountAttributes = state => {
  const fields = getAccountFields(state);

  let attributes = {};

  for (let [field, { value }] of Object.entries(fields)) {
    attributes[field] = value;
  }

  return attributes;
};

export const getFirebaseUser = state => getAccount(state).fUser;

export const getAccountIsLoaded = state => getAccount(state).isLoaded;
export const getAccountIsLoading = state => getAccount(state).isLoading;

export const getAccountUser = state => getAccount(state).user;

export const getAccountUserFullName = state => {
  const { firstName, lastName } = getAccountUser(state);

  return `${firstName} ${lastName}`;
};

export const getAcceptInvitation = state => state.account.invitation;
export const getAcceptInvitationFields = state => getAcceptInvitation(state).fields;

export const getAcceptInvitationAttributes = state => {
  const fields = getAcceptInvitationFields(state);

  let attributes = {};

  for (let [field, { value }] of Object.entries(fields)) {
    attributes[field] = value;
  }

  return attributes;
};

export const getCodeInfo = state => getAcceptInvitation(state).codeInfo;

export const getPreviewRequestInfo = state => {
  const attributes = getAcceptInvitationAttributes(state);
  const codeInfo = getCodeInfo(state);

  return {
    prototeamId: attributes.prototeamId,
    seasonTeam: codeInfo.team,
  };
}

export const getPrototeamToLink = state => {
  const userTeams = getUserTeamAttributes(state);
  if (!userTeams.isLoaded) {
    return {};
  }

  const attributes = getAcceptInvitationAttributes(state);
  if (!attributes.prototeamId) {
    return {};
  }

  const team = userTeams.teams.find(team => team.prototeam.id === attributes.prototeamId);
  if (!team) {
    return {};
  }

  return team.prototeam;
}

export const getUserTeamAttributes = state => getAcceptInvitation(state).userTeams;

export const getAccountEmail = state => getAccount(state).user.email;
