import actions from "../actions";

import {
  coachFieldValidatingRoutine,
  coachSubmittingRoutine,
  coachValidatingRoutine,
  coachSavingRoutine,
  coachRemovingRoutine
} from "../routines";

import createMembersReducer from "./createMembersReducer";

export default createMembersReducer({
  fields: {
    firstName: "",
    lastName: "",
    externalId: "",
    position: "",
    signature: "",
    status: "coaching"
  },
  actions: {
    appendMember: actions.appendEmptyCoach,
    changeField: actions.coachesForm.changeField,
    reset: actions.coachesForm.reset,
    edit: actions.coachesForm.edit
  },
  routines: {
    fieldValidating: coachFieldValidatingRoutine,
    validating: coachValidatingRoutine,
    submitting: coachSubmittingRoutine,
    saving: coachSavingRoutine,
    removing: coachRemovingRoutine
  },
  extract(team) {
    const coaches = team.coaches.reduce((result, coach) => ({ ...result, [coach.id]: { ...coach } }), {});

    const roster = team.roster.coaches || [];

    return [coaches, roster];
  }
});
