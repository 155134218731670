import { handleActions } from "redux-actions";

const initialState = {
  currentQuery: "",
  nextQuery: ""
};

const reduceQueryInput = (state, { payload: query }) => ({
  ...state,
  nextQuery: query
});

const reduceQueryCommit = state => ({
  ...state,
  currentQuery: state.nextQuery
});

export default function createSearchReducer(actions) {
  return handleActions(
    {
      [actions.list.search.setNextQuery]: reduceQueryInput,
      [actions.list.search.commit]: reduceQueryCommit,
      [actions.list.search.clear]: () => ({ ...initialState }),
      [actions.list.clear]: () => ({ ...initialState })
    },
    initialState
  );
}
