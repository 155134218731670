import { handleActions } from "redux-actions";

import { loadGamesheetRoutine } from "../../routines";
import { clearGamesheet } from "../../actions";
import reduceScorekeeperNotes from "../../utils/reduceScorekeeperNotes";

const initialState = {
  generalNotes: [],
  injuryNotes: [],
  timeoutNotes: []
};

export default handleActions(
  {
    [loadGamesheetRoutine.SUCCESS]: reduceScorekeeperNotes,
    [clearGamesheet]: () => initialState
  },
  initialState
);
