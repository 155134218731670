import React from "react";
import PropTypes from "prop-types";

import { usePreview } from "./hooks";

function Preview({ value }) {
  const { name, url } = usePreview({ value });

  return (
    <div className="file-field__preview">
      {name ? (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {name}
        </a>
      ) : (
        "No file uploaded"
      )}
    </div>
  );
}

Preview.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)])
};

export default Preview;
