import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { locationQueryState, locationInputFocusState } from './state';

export default function useQueryInput({ value, setValue, refine, clear }) {
  const [query, setQuery] = useRecoilState(locationQueryState);
  const setFocus = useSetRecoilState(locationInputFocusState);

  useEffect(() => {
    setQuery(value);
  }, []);

  useEffect(() => {
    if (clear > 0) {
      setQuery("");
    }
  }, [clear])

  useEffect(() => {
    refine(query);
    setValue(query);
  }, [query]);

  return {
    query,
    setQuery,
    gotFocus: () => setFocus(true)
  };
}