import { connect } from "react-redux";

import { CurrentTeamLoadingRoutine } from "@/redux/teams/routines";
import {
  isCurrentTeamReady,
  isCurrentTeamOperating,
  getCurrentTeamBreadcrumbs,
  isCurrentTeamForbidden,
  isCurrentTeamArchived
} from "@/redux/teams/selectors";
import actions from "@/redux/csvImport/actions";
import { rosterParsingRoutine } from "@/redux/csvImport/routines";

import ImportPage from "./ImportPage";

const mapStateToProps = state => ({
  isTeamOperating: isCurrentTeamOperating(state),
  isTeamReady: isCurrentTeamReady(state),
  isTeamForbidden: isCurrentTeamForbidden(state),
  isTeamArchived: isCurrentTeamArchived(state),
  breadcrumbs: getCurrentTeamBreadcrumbs(state)
});

const mapDispatchToProps = (dispatch, { seasonId, teamId }) => ({
  loadTeam: () => dispatch(CurrentTeamLoadingRoutine.trigger({ id: teamId })),
  parse: () => dispatch(rosterParsingRoutine.trigger({ seasonId, teamId })),
  reset: () => dispatch(actions.reset({ seasonId }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportPage);
