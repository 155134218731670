import { all, takeLatest, put, call } from "redux-saga/effects";
import build from "redux-object";

import { loadSeasonApiKeys } from "@/lib/api/apiKeys";

import { gamesheetAPIRequest } from "../api/sagas";

import { ipadKeysListLoadingRoutine } from "./routines";

function* loadList({ payload: { seasonId } }) {
  yield put(ipadKeysListLoadingRoutine.request());

  try {
    const { data } = yield call(gamesheetAPIRequest, loadSeasonApiKeys, {
      seasonId
    });

    const apiKeys = build(data, "apiKeys") || [];

    yield put(ipadKeysListLoadingRoutine.success({ apiKeys }));
  } catch (e) {
    const responseStatus = e.response && e.response.status;

    yield put(ipadKeysListLoadingRoutine.failure({ responseStatus, error: e }));
  } finally {
    yield put(ipadKeysListLoadingRoutine.fulfill());
  }
}

export function* ipadKeysFlow() {
  yield all([takeLatest(ipadKeysListLoadingRoutine.TRIGGER, loadList)]);
}
