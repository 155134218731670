import { createPostService, createPatchService } from "./serviceMaker";

export const resetPassword = createPostService(({ email }) => ({
  url: "/password/reset",
  attributes: { email }
}));

export const createPassword = createPostService(({ password, token }) => ({
  url: "/password",
  attributes: { password, token }
}));

export const updatePassword = createPatchService(({ currentPassword, password, verificationCode }) => ({
  url: "/password",
  identity: { type: "passwords" },
  headers: {
    "x-verification-code": verificationCode
  },
  attributes: verificationCode ? { password } : { currentPassword, password }
}));
