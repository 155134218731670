export function makePositionTranslator( playerPositions, coachPositions ) {
    const playerLookup = playerPositions.reduce((acc, pos) => ({
        ...acc,
        [pos.abbr]: pos.key,
        [pos.value.toLowerCase()]: pos.key,
    }), {});

    const coachLookup = coachPositions.reduce((acc, pos) => ({
        ...acc,
        [pos.abbr]: pos.key,
        [pos.value.toLowerCase()]: pos.key,
    }), {});

    return function ( value ) {
        
        value = value.toLowerCase();

        if( value in playerLookup ){
            return playerLookup[value];
        } else if ( value in coachLookup ){
            return coachLookup[value];
        }

        return position( value );
    }
}

export function position( value ){

    value = value.toLowerCase();

    switch( value ){
        // players
        case "d": 
        case "defense": return "defence";
        case "f": return "forward";
        case "g": return "goalie";
        case "c": return "centre";
        case "rw": return "right_wing";
        case "lw": return "left_wing";
        case "t": return "transition";
        case "m": return "midfield";
        case "a": return "attack";
        // coaches
        case "hc": return "head_coach";
        case "ac": return "assistant_coach";
        case "atr": return "assistant_trainer";
        case "tr": return "trainer";
        case "hcl": return "head_coach_at_large";
        case "acl": return "assistant_coach_at_large";
        case "trl": return "trainer_at_large";
        case "mgr": return "manager";
    }

    return value;

}