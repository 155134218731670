import PropTypes from "prop-types";

import useAbility from "./useAbility";

const alias = (names, validate) => (props, ...args) => {
  if (!names.split(" ").some(name => props[name])) {
    return validate(props, ...args);
  }
};

export function canCheck(props) {
  const ability = useAbility();

  const action = props.I || props.do;
  const subject = props.a || props.an || props.this || props.on;

  const fn = props.not ? "cannot" : "can";
  return ability[fn](action, subject);
}

function Can(props) {
  const { fallback } = props;
  return canCheck(props) ? props.children : fallback;
}

Can.propTypes = {
  I: alias("do", PropTypes.string.isRequired),
  a: alias("an this on", PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired),
  not: PropTypes.bool,
  children: PropTypes.node,
  fallback: PropTypes.node
};

Can.defaultProps = {
  children: null,
  fallback: null,
  not: false
};

export default Can;
