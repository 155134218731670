import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { FormGroup, Button } from "reactstrap";

import FormItem from "@/components/FormItem";
import GamePeriodTimeInput from "@/components/GamePeriodTimeInput";

import PeriodInput from "../PeriodInput";
import TeamGoalieInput from "../TeamGoalieInput";

class GoalieShift extends PureComponent {
  static propTypes = {
    team: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    period: PropTypes.string,
    time: PropTypes.string,
    goalieId: PropTypes.string,
    onChange: PropTypes.func,
    onRemove: PropTypes.func
  };

  static defaultProps = {
    onChange() {},
    onRemove() {}
  };

  handleChange = ({ field, value }) => {
    const { id, team, onChange } = this.props;

    onChange({ id, team, field, value });
  };

  handleFieldChange = ({ target: { name, value } }) => {
    this.handleChange({ field: name, value });
  };

  handleRemove = () => {
    const { team, id, onRemove } = this.props;

    onRemove({ team, id });
  };

  render() {
    const { team, period, time, goalieId } = this.props;

    return (
      <FormItem data-period={period} data-time={time}>
        <FormGroup className="col-md-1">
          <PeriodInput value={period} name="period" onChange={this.handleFieldChange} />
        </FormGroup>

        <FormGroup className="col-md-9">
          <TeamGoalieInput value={goalieId} team={team} name="goalieId" onChange={this.handleFieldChange} />
        </FormGroup>

        <FormGroup className="col-md-1">
          <GamePeriodTimeInput name="time" value={time} onChange={this.handleFieldChange} />
        </FormGroup>

        <FormGroup className="col-md-1 removal-btn-group">
          <Button outline color="danger" onClick={this.handleRemove}>
            &times;
          </Button>
        </FormGroup>
      </FormItem>
    );
  }
}

export default GoalieShift;
