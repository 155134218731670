import { handleActions, combineActions } from "redux-actions";

const reduceLoadingTrigger = state => ({
  ...state,
  error: null
});

const reduceLoadingRequest = (state, { payload: { append } }) => ({
  ...state,
  isLoading: true,
  append: append === true
});

const reduceLoadingSuccess = (state, { payload: { totalCount, filteredCount, ids, append } }) => ({
  ...state,
  totalCount,
  filteredCount,
  ids: append ? Array.from(new Set([...state.ids, ...ids])) : [...ids],
  isLoaded: true
});

const reduceLoadingFailure = (state, { payload: { error } }) => ({
  ...state,
  error
});

const reduceLoadingFulfill = state => ({
  ...state,
  isLoading: false,
  append: false
});

export default (loadingRoutines, clearAction, limit = 50) => {
  const initialState = {
    limit,
    totalCount: 0,
    filteredCount: 0,
    query: null,
    ids: [],
    error: null,
    isLoading: false,
    isLoaded: false,
    append: false
  };

  const routines = Array.isArray(loadingRoutines) ? loadingRoutines : [loadingRoutines];

  return handleActions(
    {
      [combineActions(...routines.map(routine => routine.TRIGGER))]: reduceLoadingTrigger,
      [combineActions(...routines.map(routine => routine.REQUEST))]: reduceLoadingRequest,
      [combineActions(...routines.map(routine => routine.SUCCESS))]: reduceLoadingSuccess,
      [combineActions(...routines.map(routine => routine.FAILURE))]: reduceLoadingFailure,
      [combineActions(...routines.map(routine => routine.FULFILL))]: reduceLoadingFulfill,
      [clearAction]: () => ({ ...initialState, limit })
    },
    { ...initialState, limit }
  );
};
