import { handleActions } from "redux-actions";

import { invitationFormActions } from "../../../actions";

const defaultState = {
  actions: {
    create: false,
    read: false,
    update: false,
    delete: false
  },
  global: true,
  ids: []
};

const changeActions = (state, { payload }) => Object.assign({}, state, { actions: { ...state.actions, ...payload } });

const changeGlobalMode = (state, { payload }) => Object.assign({}, state, { global: payload });

const addAssociations = (state, { payload }) => Object.assign({}, state, { ids: [...state.ids, ...payload] });

const removeAssociation = (state, { payload }) =>
  Object.assign({}, state, { ids: state.ids.filter(id => id !== payload) });

export default handleActions(
  {
    [invitationFormActions.scopes.associations.changeActions]: changeActions,
    [invitationFormActions.scopes.associations.changeGlobalMode]: changeGlobalMode,
    [invitationFormActions.scopes.associations.addAssociations]: addAssociations,
    [invitationFormActions.scopes.associations.removeAssociation]: removeAssociation
  },
  defaultState
);
