import React from "react";
import PropTypes from "prop-types";

import PlayersList from "@/components/PlayersList";

function PlayersTab({ match: { params } }) {
  const { seasonId } = params;

  return <PlayersList seasonId={seasonId} />;
}

PlayersTab.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      seasonId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default PlayersTab;
