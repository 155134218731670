import { createRoutine } from "redux-saga-routines";

const namespace = "GAME_REPORT_EMAIL_NOTIFICATION_SETTINGS";

export const loadingRoutine = createRoutine(`${namespace}/LOADING`);
export const teamsLoadingRoutine = createRoutine(`${namespace}/TEAMS/LOADING`);
export const teamLoadingRoutine = createRoutine(`${namespace}/TEAM/LOADING`);

export const subscriptionsLoadingRoutine = createRoutine(`${namespace}/SUBSCRIPTIONS/LOADING`);

export const enableSubscriptionRoutine = createRoutine(`${namespace}/SUBSCRIPTION/ENABLE`);

export const disableSubscriptionRoutine = createRoutine(`${namespace}/SUBSCRIPTION/DISABLE`);
