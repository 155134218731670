import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import TitleBar from "@/components/TitleBar";
import PageLoader from "@/components/PageLoader";
import TrainerVisitsReportDataSources from "@/components/ReportDataSourcesList";

import TrainerVisitsReportSummaryStats from "./SummaryStats";
import TrainerVisitsReportList from "./List";

import "./Report.scss";

class Report extends PureComponent {
  static propTypes = {
    summaryStats: PropTypes.shape({ visits: PropTypes.number }),
    visits: PropTypes.arrayOf(PropTypes.shape({})),
    isLoaded: PropTypes.bool,
    isLoading: PropTypes.bool
  };

  static defaultProps = {
    summaryStats: {
      visits: 0
    },
    visits: [],
    dataSources: [],
    isLoaded: false,
    isLoading: false
  };

  print = () => {
    window.print();
  };

  render() {
    const { summaryStats, visits, isLoading, isLoaded } = this.props;

    return (
      <PageLoader isLoading={isLoading} isLoaded={isLoaded}>
        <TitleBar title="Trainer Visits Report">
          <Button outline color="primary" size="sm" onClick={this.print}>
            Print
          </Button>
        </TitleBar>

        <TrainerVisitsReportSummaryStats {...summaryStats} />

        <TrainerVisitsReportList visits={visits} visitsCount={summaryStats.visits} />

        <TrainerVisitsReportDataSources />
      </PageLoader>
    );
  }
}

export default Report;
