import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { Row, FormGroup, Label, Button } from "reactstrap";

import DashboardForm from "@/components/DashboardForm";
import DashboardFormFooter from "@/components/DashboardFormFooter";
import ContentSection from "@/components/ContentSection";
import ConfirmDeletionModal from "@/components/ConfirmDeletionModal";
import UserStatus from "@/components/UserStatus";

import RolesTable from "./RolesTable";
import Field from "./Field";
import ResendVerificationEmailButton from "./ResendVerificationEmailButton";

import useForm from "./hooks/useForm";

function UserForm({ userId }) {
  const { submit, isInvalid, isPristine, isNotFilled, isOperating, userName, userEmail, userStatus, remove } = useForm(
    userId
  );

  return (
    <DashboardForm onSubmit={submit} isOperating={isOperating}>
      <Row form className="mb-3">
        <FormGroup className="col-md-3">
          <Label for="userFormFirstName">First Name</Label>

          <Field id="userFormFirstName" name="firstName" />
        </FormGroup>

        <FormGroup className="col-md-3">
          <Label for="userFormLastName">Last Name</Label>

          <Field id="userFormLastName" name="lastName" />
        </FormGroup>

        <FormGroup className="col-md-6">
          <Label for="userFormEmail">Email</Label>

          <Field id="userFormEmail" name="email" />
        </FormGroup>
      </Row>

      <Row form>
        <FormGroup className="col-md-12">
          <ContentSection title="Permissions">
            <RolesTable />
          </ContentSection>
        </FormGroup>
      </Row>

      {userId && (
        <Row form className="mt-3">
          <FormGroup className="col-md-12">
            Status:&nbsp;
            <UserStatus status={userStatus} />
          </FormGroup>
        </Row>
      )}

      <DashboardFormFooter>
        {userId ? (
          <Fragment>
            <div className="dashboard-form-footer__secondary">
              <ConfirmDeletionModal
                resourceTitle={`${userName} (${userEmail})`}
                resourceType="User"
                isOperating={isOperating}
                onDelete={remove}
              />
            </div>

            <div className="dashboard-form-footer__primary">
              <ResendVerificationEmailButton userId={userId} />

              <Button
                type="submit"
                color="success"
                disabled={isPristine || isInvalid || isNotFilled}
                outline={isPristine || isInvalid || isNotFilled}
              >
                {isOperating ? "Saving Changes…" : "Save Changes"}
              </Button>
            </div>
          </Fragment>
        ) : (
          <div className="dashboard-form-footer__primary">
            <Button
              type="submit"
              color="success"
              disabled={isPristine || isInvalid || isNotFilled}
              outline={isPristine || isInvalid || isNotFilled}
            >
              {isOperating ? "Creating User…" : "Create User"}
            </Button>
          </div>
        )}
      </DashboardFormFooter>
    </DashboardForm>
  );
}

UserForm.propTypes = {
  userId: PropTypes.string
};

export default UserForm;
