import { handleActions } from "redux-actions";

import { invitationFormActions } from "../../../actions";

const defaultState = {
  actions: {
    "player-penalty-report": false,
    "coach-penalty-report": false,
    "referee-penalty-report": false,
    "penalty-type-report": false,
    "trainer-visits-report": false,
    "billing-report": false,
    "suspension-report": false
  },
  global: true
};

const changeActions = (state, { payload }) => Object.assign({}, state, { actions: { ...state.actions, ...payload } });

export default handleActions(
  {
    [invitationFormActions.scopes.reports.changeActions]: changeActions
  },
  defaultState
);
