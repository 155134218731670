import React from "react";
import PropTypes from "prop-types";

import { InputGroup, FormFeedback } from "reactstrap";

import DivisionInput from "./DivisionInput";
import TeamInput from "./TeamInput";

function TeamPickerField(props) {
  const { id, name, value, onChange, disallowTeamValue, invalid, errors } = props;

  return (
    <InputGroup>
      <DivisionInput invalid={invalid} value={value} onChange={onChange} />

      <TeamInput
        value={value}
        id={id}
        name={name}
        onChange={onChange}
        disallowValue={disallowTeamValue}
        invalid={invalid}
      />

      {invalid && errors.map(error => <FormFeedback key={error}>{error}</FormFeedback>)}
    </InputGroup>
  );
}

TeamPickerField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.shape({
    team: PropTypes.string,
    division: PropTypes.string
  }),
  onChange: PropTypes.func,
  disallowTeamValue: PropTypes.string,
  invalid: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string)
};

TeamPickerField.defaultProps = {
  id: null,
  name: null,
  value: {
    team: "",
    division: ""
  },
  disallowTeamValue: "",
  invalid: false,
  errors: [],
  onChange() {}
};

export default TeamPickerField;
