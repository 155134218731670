import _uniq from "lodash/uniq";
import _trim from "lodash/trim";

import { getCurrentSeason, getCurrentSeasonBreadcrumbs } from "@/redux/seasons/selectors";

const selectRoot = state => state.seasonRoster;

const selectPlayers = state => selectRoot(state).players;
const selectPlayersPagination = state => selectPlayers(state).pagination;
const selectPlayersData = state => selectPlayers(state).data;
const selectPlayersSearch = state => selectPlayers(state).search;
const selectPlayersMergeTool = state => selectPlayers(state).mergeTool;

const selectCoaches = state => selectRoot(state).coaches;
const selectCoachesPagination = state => selectCoaches(state).pagination;
const selectCoachesData = state => selectCoaches(state).data;
const selectCoachesSearch = state => selectCoaches(state).search;
const selectCoachesMergeTool = state => selectCoaches(state).mergeTool;

const makeSeasonRosterMembersList = (state, ids, data, idsForMerge = []) =>
  ids.map(id => {
    const { firstName, lastName, externalId, teams, seasonId, divisions } = data[id];

    return {
      id,
      firstName,
      lastName,
      externalId,
      seasonId,
      teams: _uniq(teams).join(",\n"),
      divisions: divisions.map(({ title }) => title).join(",\n"),
      isSelectedForMerge: idsForMerge.includes(id)
    };
  });

export const getSeasonRosterPlayersList = state => {
  const ids = selectPlayersPagination(state).ids;
  const data = selectPlayersData(state);
  const idsForMerge = selectPlayersMergeTool(state).ids;

  return makeSeasonRosterMembersList(state, ids, data, idsForMerge);
};

export const getSeasonRosterPlayersListIsLoading = state => selectPlayersPagination(state).isLoading;

export const getSeasonRosterPlayersListIsLoaded = state => selectPlayersPagination(state).isLoaded;

export const getSeasonRosterPlayersListTotalCount = state => selectPlayersPagination(state).totalCount;

export const getSeasonRosterPlayersListFilteredCount = state => selectPlayersPagination(state).filteredCount;

export const getSeasonRosterPlayersListOffset = state => selectPlayersPagination(state).ids.length;

export const getSeasonRosterPlayersListLimit = state => selectPlayersPagination(state).limit;

export const getSeasonRosterPlayersListHasMore = state => {
  const filtered = getSeasonRosterPlayersListFilteredCount(state);
  const offset = getSeasonRosterPlayersListOffset(state);

  return offset < filtered;
};

export const getSeasonRosterPlayersListSearchCurrentQuery = state => selectPlayersSearch(state).currentQuery;

export const getSeasonRosterPlayersListSearchNextQuery = state => selectPlayersSearch(state).nextQuery;

export const getSeasonRosterPlayersListSearchQueryIsDirty = state => {
  const { nextQuery, currentQuery } = selectPlayersSearch(state);

  return _trim(nextQuery) !== _trim(currentQuery);
};

export const getSeasonRosterPlayersListIsAppending = state => selectPlayersPagination(state).append;

export const getSeasonRosterPlayersMergeToolIsEnabled = state => selectPlayersMergeTool(state).status !== null;

export const getSeasonRosterPlayersMergeToolStatus = state => selectPlayersMergeTool(state).status;

export const getSeasonRosterPlayersMergeToolPlayersList = state => {
  const ids = selectPlayersMergeTool(state).ids;
  const data = selectPlayersData(state);

  return makeSeasonRosterMembersList(state, ids, data, ids).reduce((result, { id, ...rest }) => {
    const statsIsLoading =
      getSeasonRosterPlayersMergeToolPlayerStatsIsLoading(state, id) ||
      getSeasonRosterPlayersMergeToolGoalieStatsIsLoading(state, id);

    const statsIsLoaded =
      getSeasonRosterPlayersMergeToolPlayerStatsIsLoaded(state, id) &&
      getSeasonRosterPlayersMergeToolGoalieStatsIsLoaded(state, id);

    const gamesPlayed =
      ((selectPlayersMergeTool(state).playerStats[id] || {}).gamesPlayed || 0) +
      ((selectPlayersMergeTool(state).goalieStats[id] || {}).gamesPlayed || 0);

    const isWinner = selectPlayersMergeTool(state).winnerId === id;

    return [
      ...result,
      {
        ...rest,
        id,
        statsIsLoading,
        statsIsLoaded,
        gamesPlayed,
        isWinner
      }
    ];
  }, []);
};

export const getSeasonRosterPlayersMergeToolAllPlayersStatsIsLoaded = state => {
  const allPlayers = getSeasonRosterPlayersMergeToolPlayersList(state);
  const loadedStatsPlayers = allPlayers.filter(({ statsIsLoaded }) => statsIsLoaded);

  return allPlayers.length === loadedStatsPlayers.length;
};

export const getSeasonRosterPlayersMergeToolWinnerId = state => selectPlayersMergeTool(state).winnerId;

export const getSeasonRosterPlayersMergeToolWinner = state =>
  getSeasonRosterPlayersMergeToolPlayersList(state).find(({ id }) => id === selectPlayersMergeTool(state).winnerId);

export const getSeasonRosterPlayersMergeToolPlayerIds = state => selectPlayersMergeTool(state).ids;

export const getSeasonRosterPlayersMergeToolCountPlayersSelected = state => selectPlayersMergeTool(state).ids.length;

export const getSeasonRosterPlayersMergeToolSeasonId = state => selectPlayersMergeTool(state).seasonId;

export const getSeasonRosterPlayersMergeToolPlayerStatsIsLoading = (state, playerId) =>
  (selectPlayersMergeTool(state).playerStats[playerId] || {}).isLoading || false;

export const getSeasonRosterPlayersMergeToolPlayerStatsIsLoaded = (state, playerId) =>
  (selectPlayersMergeTool(state).playerStats[playerId] || {}).isLoaded || false;

export const getSeasonRosterPlayersMergeToolGoalieStatsIsLoading = (state, goalieId) =>
  (selectPlayersMergeTool(state).goalieStats[goalieId] || {}).isLoading || false;

export const getSeasonRosterPlayersMergeToolGoalieStatsIsLoaded = (state, goalieId) =>
  (selectPlayersMergeTool(state).goalieStats[goalieId] || {}).isLoaded || false;

export const getSeasonRosterCoachesList = state => {
  const ids = selectCoachesPagination(state).ids;
  const data = selectCoachesData(state);
  const idsForMerge = selectCoachesMergeTool(state).ids;

  return makeSeasonRosterMembersList(state, ids, data, idsForMerge);
};

export const getSeasonRosterCoachesListIsLoading = state => selectCoachesPagination(state).isLoading;

export const getSeasonRosterCoachesListIsLoaded = state => selectCoachesPagination(state).isLoaded;

export const getSeasonRosterCoachesListTotalCount = state => selectCoachesPagination(state).totalCount;

export const getSeasonRosterCoachesListFilteredCount = state => selectCoachesPagination(state).filteredCount;

export const getSeasonRosterCoachesListOffset = state => selectCoachesPagination(state).ids.length;

export const getSeasonRosterCoachesListLimit = state => selectCoachesPagination(state).limit;

export const getSeasonRosterCoachesListHasMore = state => {
  const filteredCount = getSeasonRosterCoachesListFilteredCount(state);
  const offset = getSeasonRosterCoachesListOffset(state);

  return offset < filteredCount;
};

export const getSeasonRosterCoachesListSearchCurrentQuery = state => selectCoachesSearch(state).currentQuery;

export const getSeasonRosterCoachesListSearchNextQuery = state => selectCoachesSearch(state).nextQuery;

export const getSeasonRosterCoachesListSearchQueryIsDirty = state => {
  const { nextQuery, currentQuery } = selectCoachesSearch(state);

  return _trim(nextQuery) !== _trim(currentQuery);
};

export const getSeasonRosterCoachesListIsAppending = state => selectCoachesPagination(state).append;

export const getSeasonRosterCoachesMergeToolIsEnabled = state => selectCoachesMergeTool(state).status !== null;

export const getSeasonRosterCoachesMergeToolStatus = state => selectCoachesMergeTool(state).status;

export const getSeasonRosterCoachesMergeToolCoachesList = state => {
  const data = selectCoachesData(state);
  const { ids, winnerId } = selectCoachesMergeTool(state);

  return makeSeasonRosterMembersList(state, ids, data, ids).reduce((result, { id, ...rest }) => {
    const isWinner = winnerId === id;

    return [
      ...result,
      {
        ...rest,
        id,
        isWinner
      }
    ];
  }, []);
};

export const getSeasonRosterCoachesMergeToolWinnerId = state => selectCoachesMergeTool(state).winnerId;

export const getSeasonRosterCoachesMergeToolWinner = state =>
  getSeasonRosterCoachesMergeToolCoachesList(state).find(({ id }) => id === selectCoachesMergeTool(state).winnerId);

export const getSeasonRosterCoachesMergeToolCoachIds = state => selectCoachesMergeTool(state).ids;

export const getSeasonRosterCoachesMergeToolCountCoachesSelected = state =>
  getSeasonRosterCoachesMergeToolCoachIds(state).length;

export const getSeasonRosterCoachesMergeToolSeasonId = state => selectCoachesMergeTool(state).seasonId;

export const getSeasonRosterBreadcrumbs = (state, tabId) => {
  const tabTitle = { players: "Players", coaches: "Coaches" }[tabId];
  const season = getCurrentSeason(state);
  const seasonBreadcrumbs = getCurrentSeasonBreadcrumbs(state);

  if (season && seasonBreadcrumbs) {
    return [
      ...seasonBreadcrumbs,
      {
        url: `/seasons/${season.id}/roster/${tabId}`,
        title: tabTitle
      }
    ];
  }
};
