import createTeamMemberPenaltyReportReducers from "@/redux/common/reports/createTeamMemberPenaltyReportReducer";

import actions from "./actions";

import { playerPenaltyReportListLoadingRoutine, playerPenaltyReportLoadingRoutine } from "./routines";

export default createTeamMemberPenaltyReportReducers({
  actions,
  listLoadingRoutine: playerPenaltyReportListLoadingRoutine,
  loadingRoutine: playerPenaltyReportLoadingRoutine,
  memberType: "players"
});
