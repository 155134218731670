import _isEqual from "lodash/isEqual";

import { handleActions } from "redux-actions";

import { logoutAction } from "@/redux/logout/actions";

import { addAbilityRules } from "./actions";

import { resourcePrefetchingRoutine, resourcesListPrefetchingRoutine } from "./routines";

const initialState = {
  rules: [],
  ignoreTeamLock: false,
  resources: {
    isListPrefetching: false,
    isListPrefetched: false,
    associations: {},
    leagues: {},
    seasons: {},
    divisions: {},
    teams: {}
  }
};

function ruleHash(rule) {
  return (
    (rule.subject || "") +
    (rule.actions || []).sort().join() +
    Object.keys(rule.conditions || {})
      .sort()
      .join() +
    Object.values(rule.conditions || {})
      .sort()
      .join()
  );
}

function uniquelify(arr, hashFunc) {
  const set = new Set();
  const result = [];

  arr.forEach(elem => {
    const key = hashFunc(elem);
    if (!set.has(key)) {
      set.add(key);
      result.push(elem);
    }
  });

  return result;
}

function reduceAddingRules(state, { payload: rules = [] }) {
  const nextRules = uniquelify([...state.rules, ...rules], ruleHash); // way faster than _uniqWith( ..., _isEqual)

  const ignoreTeamLock = nextRules.some(({ subject, actions }) => subject === "teamLocks" && actions.includes("ignore"));

  return { ...state, rules: nextRules, ignoreTeamLock };
}

function reduceResourcePrefetchingTrigger(state, { payload: { type, id } }) {
  return {
    ...state,
    resources: {
      ...state.resources,
      [type]: {
        ...state.resources[type],
        [id]: { isPrefetching: false, isPrefetched: false }
      }
    }
  };
}

function reduceResourcePrefetchingRequest(state, { payload: { type, id } }) {
  return {
    ...state,
    resources: {
      ...state.resources,
      [type]: {
        ...state.resources[type],
        [id]: { ...state.resources[type][id], isPrefetching: true }
      }
    }
  };
}

function reduceResourcePrefetchingSuccess(state, { payload: { type, id } }) {
  return {
    ...state,
    resources: {
      ...state.resources,
      [type]: {
        ...state.resources[type],
        [id]: { ...state.resources[type][id], isPrefetched: true }
      }
    }
  };
}

function reduceResourcePrefetchingFailure(state, { payload: { type, id, error } }) {
  return {
    ...state,
    resources: {
      ...state.resources,
      [type]: {
        ...state.resources[type],
        [id]: { ...state.resources[type][id], error }
      }
    }
  };
}

function reduceResourcePrefetchingFulfill(state, { payload: { type, id } }) {
  return {
    ...state,
    resources: {
      ...state.resources,
      [type]: {
        ...state.resources[type],
        [id]: { ...state.resources[type][id], isPrefetching: false }
      }
    }
  };
}

function reduceListPrefetchingRequest(state) {
  return {
    ...state,
    resources: { ...state.resources, isListPrefetching: true }
  };
}

function reduceListPrefetchingSuccess(state) {
  return {
    ...state,
    resources: { ...state.resources, isListPrefetched: true }
  };
}

function reduceListPrefetchingFulfill(state) {
  return {
    ...state,
    resources: { ...state.resources, isListPrefetching: false }
  };
}

export default handleActions(
  {
    [addAbilityRules]: reduceAddingRules,
    [resourcePrefetchingRoutine.TRIGGER]: reduceResourcePrefetchingTrigger,
    [resourcePrefetchingRoutine.REQUEST]: reduceResourcePrefetchingRequest,
    [resourcePrefetchingRoutine.SUCCESS]: reduceResourcePrefetchingSuccess,
    [resourcePrefetchingRoutine.FAILURE]: reduceResourcePrefetchingFailure,
    [resourcePrefetchingRoutine.FULFILL]: reduceResourcePrefetchingFulfill,
    [resourcesListPrefetchingRoutine.REQUEST]: reduceListPrefetchingRequest,
    [resourcesListPrefetchingRoutine.SUCCESS]: reduceListPrefetchingSuccess,
    [resourcesListPrefetchingRoutine.FULFILL]: reduceListPrefetchingFulfill,
    [logoutAction]: () => ({ ...initialState })
  },
  initialState
);
