import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import EmptyListMessage from "@/components/EmptyListMessage";

import BillingReportTeamGamesStatsSeason from "./Season";

class TeamGamesStats extends PureComponent {
  static propTypes = {
    teamGamesStats: PropTypes.arrayOf(
      PropTypes.shape({
        season: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired
      })
    )
  };

  static defaultProps = {
    teamGamesStats: []
  };

  render() {
    const { teamGamesStats } = this.props;

    return (
      <div className="billing-report__section">
        {teamGamesStats.length > 0 ? (
          teamGamesStats.map(({ season, teams }) => (
            <BillingReportTeamGamesStatsSeason key={season.id} seasonTitle={season.title} teams={teams} />
          ))
        ) : (
          <EmptyListMessage>No games were played.</EmptyListMessage>
        )}
      </div>
    );
  }
}

export default TeamGamesStats;
