import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Table } from "reactstrap";

import Member from "./components/Member";

class SuspendedMembersList extends PureComponent {
  static propTypes = {
    caption: PropTypes.string,
    members: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired
      })
    )
  };

  static defaultProps = {
    caption: "Suspended Players/Coaches",
    members: []
  };

  render() {
    const { caption, members } = this.props;

    return (
      <Table striped borderless size="sm">
        <thead>
          <tr>
            <th scope="colgroup" colSpan="2">
              {caption}
            </th>
          </tr>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">No. of Games</th>
          </tr>
        </thead>
        <tbody>
          {members.map(({ type, id, ...rest }) => (
            <Member key={`${type}-${id}`} {...rest} />
          ))}
        </tbody>
      </Table>
    );
  }
}

export default SuspendedMembersList;
