import { connect } from "react-redux";

import {
  getSeasonRosterCoachesMergeToolIsEnabled,
  getSeasonRosterCoachesMergeToolStatus,
  getSeasonRosterCoachesMergeToolCountCoachesSelected,
  getSeasonRosterCoachesMergeToolWinnerId
} from "@/redux/seasonRoster/selectors";

import { seasonRosterActions } from "@/redux/seasonRoster/actions";

import CoachesListMergeToolFooter from "./Footer";
import { mergeSeasonCoachesRoutine } from "@/redux/seasonRoster/routines";

const mapStateToProps = state => ({
  isEnabled: getSeasonRosterCoachesMergeToolIsEnabled(state),
  status: getSeasonRosterCoachesMergeToolStatus(state),
  countCoachesSelected: getSeasonRosterCoachesMergeToolCountCoachesSelected(state),
  winnerId: getSeasonRosterCoachesMergeToolWinnerId(state)
});

const mapDispatchToProps = dispatch => ({
  disable: () => dispatch(seasonRosterActions.coaches.mergeTool.disable()),
  setStatus: status => dispatch(seasonRosterActions.coaches.mergeTool.setStatus(status)),
  mergeCoaches: () => dispatch(mergeSeasonCoachesRoutine())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoachesListMergeToolFooter);
