import React from "react";
import List from "./List";
import PageLoader from "@/components/PageLoader";

function ListContainer(props) {
  return (
    <React.Suspense fallback={<PageLoader isLoading />}>
      <List {...props} />
    </React.Suspense>
  )
}

export default ListContainer;
