import { getTeams, getTeam } from "@/redux/teamList/selectors";

const selectRoot = state => state.copyTeams;

export const getCopyTeamsIsModalOpen = state => selectRoot(state).isModalOpen;

export const getCopyTeamsSeasonId = state => selectRoot(state).seasonId;

export const getCopyTeamsDivisionId = state => selectRoot(state).divisionId;

export const getCopyTeamsStage = state => selectRoot(state).stage;

export const getCopyTeamsList = state => {
  const { selectedTeams } = selectRoot(state);
  const currentSeasonId = getCopyTeamsSeasonId(state);

  return getTeams(state).map(({ id, ...rest }) => ({
    ...rest,
    id,
    isSelected: selectedTeams.includes(id),
    isAvailable: currentSeasonId !== rest.season.id
  }));
};

export const getCopyTeamsSelectedList = state => {
  const { selectedTeams } = selectRoot(state);

  return selectedTeams.map(id => ({
    ...getTeam(state, id),
    isSelected: true,
    isAvailable: true
  }));
};

export const getCopyTeamsSelectedIds = state => selectRoot(state).selectedTeams;

export const getCopyTeamsSelectedCount = state => getCopyTeamsSelectedIds(state).length;
