const selectRoot = state => state.userList;
const selectPagination = state => selectRoot(state).pagination;

export const getUsers = state => {
  const { users } = selectRoot(state);

  return selectPagination(state).ids.map(id => ({ ...users[id], id }));
};

export const getIsLoaded = state => selectPagination(state).isLoaded;
export const getIsLoading = state => selectPagination(state).isLoading;
export const getIsAppending = state => selectPagination(state).append;
export const getTotalCount = state => selectPagination(state).totalCount;
export const getFilteredCount = state => selectPagination(state).filteredCount;
export const getQuery = state => selectPagination(state).query;
export const getLimit = state => selectPagination(state).limit;
export const getOffset = state => selectPagination(state).ids.length;
