import React from "react";
import { Button } from "reactstrap";

import Spinner from "@/components/Spinner";

import { useTeamList } from "./hooks";

import TeamGroup from "./TeamGroup";

function TeamList() {
  const { isLoading, isLoaded, isOptionSelected, isAllOptionsSelected, teamGroups, clear, selectAll } = useTeamList();

  return (
    <div className="report-data-sources-select-list">
      <div className="report-data-sources-select-list__label">
        <span className="report-data-sources-select-list__label__text">Select team(s)</span>
      </div>

      <div className="report-data-sources-select-list__input list-group list-group-flush">
        {isLoading ? (
          <Spinner className="report-data-sources-select-list__spinner" theme="loader" color="yellow" size="xxl" />
        ) : isLoaded ? (
          teamGroups.map(({ divisionId, ...rest }) => <TeamGroup key={divisionId} {...rest} />)
        ) : (
          <p className="report-data-sources-select-list__empty-list-message">No divisions selected</p>
        )}
      </div>

      <div className="report-data-sources-select-list__controls">
        {isOptionSelected && (
          <Button
            className="report-data-sources-select-list__controls__left-control"
            color="link"
            size="sm"
            onClick={clear}
          >
            Clear selection
          </Button>
        )}

        {isLoaded &&
          isAllOptionsSelected === false && (
            <Button
              className="report-data-sources-select-list__controls__right-control"
              color="link"
              size="sm"
              onClick={selectAll}
            >
              Select All
            </Button>
          )}
      </div>
    </div>
  );
}

export default TeamList;
