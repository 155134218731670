import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Button, ModalBody, ModalFooter, Table } from "reactstrap";
import { FilePicker } from "react-file-picker";
import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

function CSVFilePicker({ onSelect, parseError, importableClasses }) {
  const { season } = useCurrentSeasonContext();
  const sport = season && season.sport ? season.sport : "hockey";
  const csvTemplate = (() => {
    switch (sport) {
      case "hockey":
        return hockeyCsv();
      case "soccer":
        return soccerCsv();
      case "boxla":
        return boxlaCsv();
      case "field_lacrosse":
        return fieldlaxCsv();
      default:
        return hockeyCsv();
    }
  })();

  const formattedClasses = (() => {
    if (importableClasses.length == 1) {
      return importableClasses[0]
    }

    let out = ""
    importableClasses.forEach((c, i) => {
      if (i == importableClasses.length - 2) {
        out += c + ", or "
      } else if (i == importableClasses.length - 1) {
        out += c
      } else {
        out += c + ", "
      }
    });

    return out;
  })();

  return (
    <Fragment>
      <ModalBody>
        {parseError && <p className="alert alert-danger">{parseError}</p>}
        {csvTemplate}
        <p>Acceptable classes include {formattedClasses}. If your league does not have timed {sport === 'soccer' ? "infractions" : "penalties"}, use a duration of zero.</p>
      </ModalBody>
      <ModalFooter className="justify-content-center">
        <FilePicker onChange={onSelect}>
          <Button className="btn btn-success" type="button">
            Select File
          </Button>
        </FilePicker>
      </ModalFooter>
    </Fragment>
  );
}

function hockeyCsv() {
  return (
    <Table borderless size="sm" className="csv-template">
      <caption>Expected Layout of CSV</caption>
      <thead>
        <tr>
          <th>Penalty Label<span className="text-danger">*</span></th>
          <th>Code<span className="text-danger">*</span></th>
          <th>Class<span className="text-danger">*</span></th>
          <th>Duration<span className="text-danger">*</span></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Holding</td>
          <td>HO</td>
          <td>Minor</td>
          <td>1;1.5;2</td>
        </tr>
        <tr>
          <td>Body Checking</td>
          <td>BC</td>
          <td>Minor</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Illegal Equipment</td>
          <td>IE</td>
          <td>Minor</td>
          <td>2;3</td>
        </tr>
        <tr>
          <td>Slashing</td>
          <td>SL</td>
          <td>Major</td>
          <td>5</td>
        </tr>
      </tbody>
    </Table>
  );
}

function soccerCsv() {
  return (
    <Table borderless size="sm" className="csv-template">
      <caption>Expected Layout of CSV</caption>
      <thead>
        <tr>
          <th>Infraction Label<span className="text-danger">*</span></th>
          <th>Code<span className="text-danger">*</span></th>
          <th>Class<span className="text-danger">*</span></th>
          <th>Duration<span className="text-danger">*</span></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Tripping</td>
          <td>TRP</td>
          <td>Foul</td>
          <td>0</td>
        </tr>
        <tr>
          <td>Delay of Game</td>
          <td>DG</td>
          <td>Yellow Card</td>
          <td>0</td>
        </tr>
        <tr>
          <td>Violence</td>
          <td>VIO</td>
          <td>Red Card</td>
          <td>0</td>
        </tr>
      </tbody>
    </Table>
  );
}

function boxlaCsv() {
  return (
    <Table borderless size="sm" className="csv-template">
      <caption>Expected Layout of CSV</caption>
      <thead>
        <tr>
          <th>Penalty Label<span className="text-danger">*</span></th>
          <th>Code<span className="text-danger">*</span></th>
          <th>Class<span className="text-danger">*</span></th>
          <th>Duration<span className="text-danger">*</span></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Illegal Equipment</td>
          <td>IE</td>
          <td>Misconduct</td>
          <td>2;3</td>
        </tr>
        <tr>
          <td>High Stick</td>
          <td>HS</td>
          <td>Minor</td>
          <td>1;1.5;2</td>
        </tr>
        <tr>
          <td>Unsportsmanlike Conduct</td>
          <td>UC</td>
          <td>Major</td>
          <td>5</td>
        </tr>
        <tr>
          <td>Violent Cross Check</td>
          <td>VCC</td>
          <td>Match</td>
          <td>0</td>
        </tr>
      </tbody>
    </Table>
  );
}

function fieldlaxCsv() {
  return (
    <Table borderless size="sm" className="csv-template">
      <caption>Expected Layout of CSV</caption>
      <thead>
        <tr>
          <th>Penalty Label<span className="text-danger">*</span></th>
          <th>Code<span className="text-danger">*</span></th>
          <th>Class<span className="text-danger">*</span></th>
          <th>Duration<span className="text-danger">*</span></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Holding</td>
          <td>HO</td>
          <td>Technical</td>
          <td>1;1.5;2</td>
        </tr>
        <tr>
          <td>Slashing</td>
          <td>SLA</td>
          <td>Personal</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Unnecessary Roughness</td>
          <td>RO</td>
          <td>Expulsion</td>
          <td>0</td>
        </tr>
      </tbody>
    </Table>
  );
}

CSVFilePicker.propTypes = {
  parseError: PropTypes.string,
  onSelect: PropTypes.func.isRequired
};

export default CSVFilePicker;
