export function type( value ){

    value = value.toLowerCase();

    switch(value){
        case "p": return "player";
        case "c": return "coach";
    }

    return value;

}