import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import { Row, Col, FormGroup, Label, Input, Button, Alert, FormFeedback } from "reactstrap";

import Layout from "@/layouts/Layout";
import Head from "@/components/Head";
import TitleBar from "@/components/TitleBar";
import DashboardForm from "@/components/DashboardForm";
import DashboardFormFooter from "@/components/DashboardFormFooter";

class Password extends PureComponent {
  static propTypes = {
    fields: PropTypes.shape({
      currentPassword: PropTypes.shape({
        value: PropTypes.string,
        error: PropTypes.string
      }),
      password: PropTypes.shape({
        value: PropTypes.string,
        error: PropTypes.string
      }),
      passwordConfirmation: PropTypes.shape({
        value: PropTypes.string,
        error: PropTypes.string
      })
    }),
    isFormDirty: PropTypes.bool,
    isWorking: PropTypes.bool,
    isCompleted: PropTypes.bool,
    error: PropTypes.string,
    changeField: PropTypes.func,
    updatePassword: PropTypes.func,
    clear: PropTypes.func
  };

  static defaultProps = {
    isFormDirty: true,
    isWorking: false,
    isCompleted: false,
    fields: {
      currentPassword: { value: "", error: null },
      password: { value: "", error: null },
      passwordConfirmation: { value: "", error: null }
    },
    changeField() {},
    updatePassword() {},
    clear() {}
  };

  componentWillUnmount() {
    this.props.clear();
  }

  handleChange = ({ target }) => {
    const { name: field, value } = target;

    this.props.changeField({ field, value });
  };

  handleSubmit = () => {
    const {
      fields: { currentPassword, password, passwordConfirmation }
    } = this.props;

    this.props.updatePassword({
      currentPassword: currentPassword.value,
      password: password.value,
      passwordConfirmation: passwordConfirmation.value
    });
  };

  render() {
    const {
      fields: { currentPassword, password, passwordConfirmation },
      error,
      isCompleted,
      isWorking,
      isFormDirty
    } = this.props;

    return (
      <Layout className="page-align-center">
        <Head title="Enter New Password" />

        <Row className="justify-content-center">
          <Col md="10" lg="6">
            <TitleBar title="Enter New Password" />

            {error ? <Alert color="danger">{error}</Alert> : null}

            {isCompleted ? (
              <Fragment>
                <Alert color="success">Password has been successfully updated. </Alert>
                <Button color="primary" tag={Link} to="/account/edit" outline>
                  Back to Profile
                </Button>
              </Fragment>
            ) : (
              <DashboardForm isOperating={isWorking}>
                <FormGroup>
                  <Label for="password">Current Password</Label>
                  <Input
                    type="password"
                    id="currentPassword"
                    name="currentPassword"
                    defaultValue={currentPassword.value}
                    onChange={this.handleChange}
                    invalid={currentPassword.error !== null}
                  />
                  <FormFeedback>{currentPassword.error}</FormFeedback>
                </FormGroup>

                <FormGroup>
                  <Label for="password">New Password</Label>
                  <Input
                    type="password"
                    id="password"
                    name="password"
                    defaultValue={password.value}
                    onChange={this.handleChange}
                    invalid={password.error !== null}
                  />
                  <FormFeedback>{password.error}</FormFeedback>
                </FormGroup>

                <FormGroup>
                  <Label for="passwordConfirmation">Confirm Password</Label>
                  <Input
                    type="password"
                    id="passwordConfirmation"
                    name="passwordConfirmation"
                    defaultValue={passwordConfirmation.value}
                    onChange={this.handleChange}
                    invalid={passwordConfirmation.error !== null}
                  />
                  <FormFeedback>{passwordConfirmation.error}</FormFeedback>
                </FormGroup>

                <DashboardFormFooter>
                  <div className="dashboard-form-footer__secondary">
                    <Button color="secondary" tag={Link} to="/account/edit" outline>
                      Cancel
                    </Button>
                  </div>

                  <div className="dashboard-form-footer__primary">
                    <Button
                      type="button"
                      color="success"
                      outline={isFormDirty === false}
                      disabled={isFormDirty === false}
                      onClick={this.handleSubmit}
                    >
                      {isWorking ? "Processing…" : "Update Password"}
                    </Button>
                  </div>
                </DashboardFormFooter>
              </DashboardForm>
            )}
          </Col>
        </Row>
      </Layout>
    );
  }
}

export default Password;
