import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { clearCurrentPlayer } from "@/redux/players/actions";

export default function useCurrentPlayerClearing() {
  const dispatch = useDispatch();

  return useEffect(() => () => dispatch(clearCurrentPlayer()), [dispatch]);
}
