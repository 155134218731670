import { getResources, getResource } from "@/redux/resources/selectors";
import { getCurrentSeason, getCurrentSeasonBreadcrumbs } from "@/redux/seasons/selectors";

const rootSelector = state => state.divisions;
const listSelector = state => rootSelector(state).list;
const newSelector = state => rootSelector(state).new;
const currentSelector = state => rootSelector(state).current;

export const isDivisionListLoaded = state => listSelector(state).isLoaded;
export const isDivisionListLoading = state => listSelector(state).isLoading;
export const isDivisionListForbidden = state => listSelector(state).isForbidden;
export const getDivisionList = state => {
  if (isDivisionListLoaded(state)) {
    return getResources(state, "divisions", listSelector(state).ids);
  }
};

export const isDivisionCreating = state => newSelector(state).isCreating;

export const isCurrentDivisionLoaded = state => currentSelector(state).isLoaded;

export const isCurrentDivisionOperating = state => {
  const operation = currentSelector(state).operation;

  return typeof operation === "string" && operation.length > 0;
};

export const isCurrentDivisionReady = state => isCurrentDivisionLoaded(state) && !currentSelector(state).isDeleted;

export const isCurrentDivisionForbidden = state => currentSelector(state).isForbidden;

export const getCurrentDivision = state =>
  isCurrentDivisionReady(state) ? getResource(state, "divisions", currentSelector(state).id) : undefined;

export const getDivisionsListBreadcrumbs = state => {
  const season = getCurrentSeason(state);
  const seasonBreadcrumbs = getCurrentSeasonBreadcrumbs(state);

  if (season && seasonBreadcrumbs) {
    return [
      ...seasonBreadcrumbs,
      {
        url: `/seasons/${season.id}/divisions`,
        title: "Divisions"
      }
    ];
  }
};

export const getCurrentDivisionBreadcrumbs = state => {
  const season = getCurrentSeason(state);
  const division = getCurrentDivision(state);
  const seasonBreadcrumbs = getCurrentSeasonBreadcrumbs(state);

  if (season && division && seasonBreadcrumbs) {
    return [
      ...seasonBreadcrumbs,
      {
        url: `/seasons/${season.id}/divisions/${division.id}/teams`,
        title: division.title
      }
    ];
  }
};

export const getCurrentSeasonStats = state => currentSelector(state).stats;

export const getDivisionListTotalCount = state => listSelector(state).totalCount;
