import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import SignatureImage from "@/components/SignatureImage";

class Referee extends PureComponent {
  static propTypes = {
    position: PropTypes.string,
    name: PropTypes.string,
    externalId: PropTypes.string,
    signature: PropTypes.string
  };

  static defaultProps = {
    position: "N/A",
    name: "N/A",
    externalId: "N/A"
  };

  render() {
    const { position, name, externalId, signature } = this.props;

    var scoringAppSignature = false;
    var svgString = ""

    function extractSVGPath(svgString) {
      const match = svgString.match(/<path[^>]*d="M([^"]*)"/);
      return match ? match[1] : '';
    }

    if (signature.startsWith("<svg width")) {
      scoringAppSignature = true;
      svgString = "M" + extractSVGPath(signature);
    }

    return (
      <tr>
        <td>{position}</td>
        <td className="text-uppercase">{name}</td>
        <td>{externalId}</td>
        <td>
          { scoringAppSignature && <svg height="30" viewBox="0 0 500 300" xmlns="http://www.w3.org/2000/svg">
            <path d={svgString} stroke="black" fill="none" width="3" />
          </svg>}
          {/* {  <SignatureImage src={signature} />} */}
          { !scoringAppSignature && <SignatureImage src={signature} />}
        </td>
      </tr>
    );
  }
}

export default Referee;
