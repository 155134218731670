import { handleActions } from "redux-actions";

import { submittingRoutine } from "./routines";
import actions from "./actions";
import { combineReducers } from "redux";

const initialMeta = {
  isSubmitting: false,
  isSubmitted: false,
  isFailed: false
};

const meta = handleActions(
  {
    [submittingRoutine.REQUEST]: state => ({ ...state, isSubmitting: true }),
    [submittingRoutine.SUCCESS]: state => ({ ...state, isSubmitted: true }),
    [submittingRoutine.FAILURE]: state => ({ ...state, isFailed: true }),
    [submittingRoutine.FULFILL]: state => ({ ...state, isSubmitting: false }),
    [actions.clear]: () => ({ ...initialMeta })
  },
  initialMeta
);

const remoteValidationErrors = handleActions(
  {
    [submittingRoutine.FAILURE]: (state, { payload: { validationErrors } }) => validationErrors,
    [actions.clear]: () => ({})
  },
  {}
);

export default combineReducers({ meta, remoteValidationErrors });
