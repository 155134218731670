import _isEqual from "lodash/isEqual";
import { useState, useCallback, useEffect } from "react";

export default function useSelection({ preselected, onChange, close }) {
  const [selected, setSelected] = useState(preselected);

  useEffect(
    () => {
      setSelected(preselected);
    },
    [setSelected, preselected]
  );

  const toggleSelected = useCallback(
    ({ target: { value } }) => {
      const nextSelected = selected.includes(value) ? selected.filter(v => v !== value) : [...selected, value];

      setSelected(nextSelected);
    },
    [selected, setSelected]
  );

  const apply = useCallback(
    () => {
      onChange(selected);
      close();
    },
    [onChange, selected, close]
  );

  const clear = useCallback(
    () => {
      setSelected([]);

      if (preselected.length > 0) {
        onChange([]);
      }

      close();
    },
    [setSelected, preselected, onChange, close]
  );

  const isDirty = !_isEqual(selected, preselected);

  return {
    selected,
    toggleSelected,
    apply,
    clear,
    isDirty
  };
}
