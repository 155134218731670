import { useSelector } from "react-redux";

import { getMeta } from "@/redux/scheduledGamesCSVImportWizard";

export default function useScheduledGamesCSVImportWizard() {
  const { status } = useSelector(getMeta);

  return {
    status
  };
}
