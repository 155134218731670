import React from "react";
import { Link } from "react-router-dom";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import Head from "@/components/Head";
import TitleBar from "@/components/TitleBar";
import Breadcrumbs from "@/components/Breadcrumbs";
import PageLoader from "@/components/PageLoader";

import SeasonsList, { useSeasonsList, useSeasonsListLoading } from "@/components/SeasonsList";

import AbilityButton from "@/components/AbilityButton";
import useCurrentLeagueContext from "@/hooks/useCurrentLeagueContext";

function SeasonsIndex() {
  useSeasonsListLoading();

  const { leagueIsLoaded, leagueIsLoading, leagueBreadcrumbs, league, leagueId } = useCurrentLeagueContext();

  const { isListLoaded, isListLoading, isListForbidden } = useSeasonsList();

  return (
    <ProtectedLayout condition={!isListForbidden} className="seasons-page">
      <PageLoader isLoaded={leagueIsLoaded} isLoading={leagueIsLoading}>
        {league && <Head title={`${league.title} Seasons`} />}

        <Breadcrumbs items={leagueBreadcrumbs} lastItemNoUrl />

        <TitleBar title="Seasons">
          <AbilityButton
            subject={{ type: "seasons", leagueId }}
            action="create"
            outline
            color="success"
            size="sm"
            tag={Link}
            to={`/leagues/${leagueId}/seasons/new`}
          >
            + New Season
          </AbilityButton>
        </TitleBar>

        {isListLoaded ? <SeasonsList /> : <PageLoader isLoading={isListLoading} />}
      </PageLoader>
    </ProtectedLayout>
  );
}

export default SeasonsIndex;
