import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import {
  getGamesheetIsLoading,
  getGamesheetIsLoaded,
  getGamesheet,
  getGamesheetError,
  getGamesheetIsExporting,
  getGamesheetCountDashboardNotes,
  getGamesheetIsForbidden
} from "@/redux/gamesheet/selectors";

import { getGameViewedStatus } from "@/redux/games/selectors";

import { clearGamesheet, showGamesheetHistory } from "@/redux/gamesheet/actions";

import { loadGamesheetRoutine, exportGamesheetToPDF } from "@/redux/gamesheet/routines";

import { toggleGameViewedRoutine } from "@/redux/games/routines";

import GamesShow from "./GamesShow";

function GamesShowContainer({ match: { params, path } }) {
  const { seasonId, gameId } = params;

  const dispatch = useDispatch();

  const { season, seasonIsLoaded, seasonIsLoading, seasonBreadcrumbs } = useCurrentSeasonContext();

  const loadGamesheet = useCallback(() => dispatch(loadGamesheetRoutine({ seasonId, id: gameId })), [
    dispatch,
    seasonId,
    gameId
  ]);
  const clear = useCallback(payload => dispatch(clearGamesheet(payload)), [dispatch]);
  const toggleGameViewed = useCallback(payload => dispatch(toggleGameViewedRoutine.trigger(payload)), [dispatch]);
  const showHistory = useCallback(() => dispatch(showGamesheetHistory()), [dispatch]);
  const exportToPDF = useCallback((gameId) => dispatch(exportGamesheetToPDF({gameId})), [dispatch]);
  const isLoading = useSelector(getGamesheetIsLoading);
  const isLoaded = useSelector(getGamesheetIsLoaded);
  const isForbidden = useSelector(getGamesheetIsForbidden);
  const isExporting = useSelector(getGamesheetIsExporting);
  const gamesheet = useSelector(getGamesheet);
  const error = useSelector(getGamesheetError);
  const viewedStatus = useSelector(state => getGameViewedStatus(state, gameId));
  const countDashboardNotes = useSelector(getGamesheetCountDashboardNotes);

  return (
    <GamesShow
      gameId={gameId}
      seasonId={seasonId}
      seasonIsLoaded={seasonIsLoaded}
      seasonIsLoading={seasonIsLoading}
      breadcrumbs={seasonBreadcrumbs}
      loadGamesheet={loadGamesheet}
      clearGamesheet={clear}
      toggleGameViewed={toggleGameViewed}
      showGamesheetHistory={showHistory}
      exportGamesheetToPDF={exportToPDF}
      isLoading={isLoading}
      isLoaded={isLoaded}
      isForbidden={isForbidden}
      isExporting={isExporting}
      gamesheet={gamesheet}
      error={error}
      viewedStatus={viewedStatus}
      countDashboardNotes={countDashboardNotes}
      path={path}
      sport={season.sport || ""}
    />
  );
}

GamesShowContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      seasonId: PropTypes.string.isRequired,
      gameId: PropTypes.string.isRequired
    }).isRequired,
    path: PropTypes.string.isRequired
  }).isRequired
};

export default GamesShowContainer;
