import { combineReducers } from "redux";

import { handleActions, combineActions } from "redux-actions";

import { gamesCountLoadingRoutine, submittingRoutine } from "./routines";
import actions from "./actions";

const count = handleActions(
  {
    [gamesCountLoadingRoutine.SUCCESS]: (state, { payload: { count } }) => count,
    [actions.clear]: () => null
  },
  null
);

const isLoading = handleActions(
  {
    [gamesCountLoadingRoutine.REQUEST]: () => true,
    [combineActions(gamesCountLoadingRoutine.FULFILL, actions.clear)]: () => false
  },
  false
);

const isLoaded = handleActions(
  {
    [gamesCountLoadingRoutine.SUCCESS]: () => true,
    [actions.clear]: () => false
  },
  false
);

const isSubmitting = handleActions(
  {
    [submittingRoutine.REQUEST]: () => true,
    [combineActions(submittingRoutine.FULFILL, actions.clear)]: () => false
  },
  false
);

const isSubmitted = handleActions(
  {
    [submittingRoutine.SUCCESS]: () => true,
    [actions.clear]: () => false
  },
  false
);

export default combineReducers({
  count,
  isLoading,
  isLoaded,
  isSubmitting,
  isSubmitted
});
