import { connect } from "react-redux";
import withQueryParams from "react-router-query-params";

import {
  getNewPasswordFormFields,
  getNewPasswordFormIsDirty,
  getNewPasswordIsWorking,
  getNewPasswordIsCompleted,
  getNewPasswordError
} from "@/redux/password/selectors";
import { passwordActions } from "@/redux/password/actions";
import { validateNewPasswordRoutine } from "@/redux/password/routines";

import NewPassword from "./NewPassword";

const mapStateToProps = (state, ownProps) => ({
  fields: getNewPasswordFormFields(state),
  isFormDirty: getNewPasswordFormIsDirty(state),
  isWorking: getNewPasswordIsWorking(state),
  isCompleted: getNewPasswordIsCompleted(state),
  error: getNewPasswordError(state)
});

const mapDispatchToProps = dispatch => {
  return {
    changeField: payload => dispatch(passwordActions.new.changeField(payload)),
    initField: payload => dispatch(passwordActions.new.initField(payload)),
    createPassword: attributes => dispatch(validateNewPasswordRoutine(attributes)),
    clear: () => dispatch(passwordActions.new.clear())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withQueryParams({
    keys: {
      token: { default: null, validate: value => value && value.length > 0 }
    }
  })(NewPassword)
);
