import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

import PageLoader from "@/components/PageLoader";

import TeamSelector from "./TeamSelector";

class CopyTeamsModal extends PureComponent {
  static propTypes = {
    seasonId: PropTypes.string,
    isOpen: PropTypes.bool,
    stage: PropTypes.string,
    countSelectedTeams: PropTypes.number,
    close: PropTypes.func,
    beginCopyTeams: PropTypes.func,
    clearTeamFilter: PropTypes.func,
    clearTeamList: PropTypes.func,
    clearCopyTeams: PropTypes.func
  };

  static defaultProps = {
    isOpen: false,
    stage: "selectTeams",
    countSelectedTeams: 0,
    close() {},
    beginCopyTeams() {},
    clearTeamFilter() {},
    clearTeamList() {},
    clearCopyTeams() {}
  };

  handleClosed = () => {
    const { clearTeamFilter, clearTeamList, clearCopyTeams } = this.props;

    clearTeamFilter();
    clearTeamList();
    clearCopyTeams();
  };

  close = () => {
    const { close, stage } = this.props;

    if (stage === "selectTeams" || stage === "completed" || stage === "failed") {
      close();
    }
  };

  renderBody() {
    const { stage, seasonId } = this.props;

    switch (stage) {
      case "selectTeams":
        return <TeamSelector seasonId={seasonId} />;
      case "copying":
        return <PageLoader isLoading={true} />;
      case "failed":
        return (
          <h2 className="text-center text-danger">Failed to copy teams due to unexpected error. Please try again.</h2>
        );
      case "completed":
        return <h2 className="text-center">Teams successfully copied!</h2>;
      default:
        return null;
    }
  }

  render() {
    const { isOpen, beginCopyTeams, countSelectedTeams, stage } = this.props;

    return (
      <Modal size="xlg" isOpen={isOpen} toggle={this.close} onClosed={this.handleClosed} backdrop="static">
        <ModalHeader toggle={this.close}>Copy teams</ModalHeader>

        <ModalBody>{this.renderBody()}</ModalBody>

        {stage !== "copying" && (
          <ModalFooter className="d-flex">
            <div>
              {stage === "selectTeams" && (
                  <Button type="button" color="secondary" outline onClick={this.close}>
                    Cancel
                  </Button>
                )  || stage === "failed" && (
                  <Button type="button" color="secondary" outline onClick={this.close}>
                    Cancel
                  </Button>
                )}
            </div>

            <div className="ml-auto">
              {stage === "selectTeams" && (
                <Button type="button" color="success" disabled={countSelectedTeams === 0} onClick={beginCopyTeams}>
                  Copy selected teams
                </Button>
              )}

              {stage === "failed" && (
                <Button type="button" color="success" onClick={beginCopyTeams}>
                  Try again
                </Button>
              )}

              {stage === "completed" && (
                <Button type="button" color="primary" onClick={this.close}>
                  Dismiss
                </Button>
              )}
            </div>
          </ModalFooter>
        )}
      </Modal>
    );
  }
}

export default CopyTeamsModal;
