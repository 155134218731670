import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import ReactAudioPlayer from "react-audio-player";

class GamesheetNote extends PureComponent {
  static propTypes = {
    type: PropTypes.oneOf(["text", "audio"]),
    content: PropTypes.string
  };

  static defaultProps = {
    type: "text"
  };

  render() {
    const { type, content } = this.props;

    switch (type) {
      case "text":
        return <span>{content}</span>;
      case "audio":
        return <ReactAudioPlayer src={content} controls />;
      default:
        return null;
    }
  }
}

export default GamesheetNote;
