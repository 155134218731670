import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getSeasonId,
  getDivisionId,
  getDivisionList,
  getDivisionListIsLoaded,
  getDivisionListIsLoading
} from "@/redux/userFilter/selectors";

import actions from "@/redux/userFilter/actions";

export default function useLeagueSelect() {
  const seasonId = useSelector(getSeasonId);
  const selectedId = useSelector(getDivisionId);
  const options = useSelector(state => getDivisionList(state, seasonId));

  const isLoaded = useSelector(state => getDivisionListIsLoaded(state, seasonId));

  const isLoading = useSelector(state => getDivisionListIsLoading(state, seasonId));

  const dispatch = useDispatch();

  const setSelectedId = useCallback(id => dispatch(actions.setDivisionId(id)), [dispatch]);

  return {
    selectedId,
    options,
    isLoaded,
    isLoading,
    setSelectedId
  };
}
