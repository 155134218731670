import { createActions } from "redux-actions";

const { userForm: actions } = createActions({
  USER_FORM: {
    ADD_ROLE: undefined,
    CHANGE_ROLE: undefined,
    REMOVE_ROLE: undefined,
    CHANGE_FIELD: undefined,
    CLEAR: undefined,
    RESET: undefined
  }
});

export default actions;
