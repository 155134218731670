import { handleActions, combineActions } from "redux-actions";
import { combineReducers } from "redux";

import { unsubscribingRoutine, resubscribingRoutine } from "./routines";
import actions from "./actions";

export default combineReducers({
  isOperating: handleActions(
    {
      [combineActions(unsubscribingRoutine.REQUEST, resubscribingRoutine.REQUEST)]: () => true,
      [combineActions(unsubscribingRoutine.FULFILL, resubscribingRoutine.FULFILL)]: () => false,
      [actions.clear]: () => false
    },
    false
  ),
  isUnsubscribed: handleActions(
    {
      [unsubscribingRoutine.SUCCESS]: () => true,
      [actions.clear]: () => false
    },
    false
  ),
  isResubscribed: handleActions(
    {
      [resubscribingRoutine.SUCCESS]: () => true,
      [actions.clear]: () => false
    },
    false
  ),
  isNotFound: handleActions(
    {
      [unsubscribingRoutine.FAILURE]: (state, { payload: { responseStatus } }) => responseStatus === 404,
      [actions.clear]: () => false
    },
    false
  )
});
