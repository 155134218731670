import { all, call, race, take } from "redux-saga/effects";

import handleRuntimeError from "@/utils/handleRuntimeError";

import { restart as restartAction } from "./app/actions";

import { appFlow } from "./app/sagas";
import { tokenFlow } from "./token/sagas";
import { loginFlow } from "./login/sagas";
import { otpFlow } from "./otp/sagas";
import { logoutFlow } from "./logout/sagas";
import { abilityFlow } from "./ability/sagas";
import { registrationFlow } from "./registration/sagas";
import { resourcesFlow } from "./resources/sagas";
import { accountFlow } from "./account/sagas";
import { associationsListFlow } from "./associationsList";
import { currentAssociationFlow } from "./currentAssociation";
import { associationFormFlow } from "./associationForm";
import { leaguesListFlow } from "./leaguesList";
import { leagueFormFlow } from "./leagueForm";
import { currentLeagueFlow } from "./currentLeague";
import { seasonsListFlow } from "./seasonsList";
import { currentSeasonFlow } from "./currentSeason";
import { seasonFormFlow } from "./seasonForm";
import { cloneSeasonFormFlow } from "./cloneSeasonForm";
import { divisionsFlow } from "./divisions/sagas";
import { divisionsListFlow } from "./divisionsList";
import { currentDivisionFlow } from "./currentDivision";
import { divisionFormFlow } from "./divisionForm";
import { teamsFlow } from "./teams/sagas";
import { playersFlow } from "./players/sagas";
import { coachesFlow } from "./coaches/sagas";
import { invitationsFlow } from "./invitations/sagas";
import { gamesFlow } from "./games/sagas";
import { gamesheetFlow } from "./gamesheet/sagas";
import { passwordFlow } from "./password/sagas";
import { seasonRosterFlow } from "./seasonRoster/sagas";
import { gameFormFlow } from "./gameForm/sagas";
import { copyTeamsFlow } from "./copyTeams/sagas";
import { ipadKeyFormFlow } from "./ipadKeyForm/sagas";
import { ipadKeysFlow } from "./ipadKeys/sagas";
import { seasonIpadKeysManagerFlow } from "./seasonIpadKeysManager/sagas";
import { refereesFlow } from "./referees/sagas";
import { gameNotesFlow } from "./gameNotes/sagas";
import { playerPenaltyReportFlow } from "./playerPenaltyReport/sagas";
import { coachPenaltyReportFlow } from "./coachPenaltyReport/sagas";
import { penaltyAccumulationReportFlow } from "./penaltyAccumulationReport/sagas";
import { penaltyTypeReportFlow } from "./penaltyTypeReport/sagas";
import { refereeReportFlow } from "./refereeReport/sagas";
import { trainerVisitsReportFlow } from "./trainerVisitsReport/sagas";
import { billingReportFlow } from "./billingReport/sagas";
import { suspensionReportFlow } from "./suspensionReport/sagas";
import { reportDataSourcesSelectFlow } from "./reportDataSourcesSelect/sagas";
import { csvImportFlow } from "./csvImport/sagas";
import { teamRosterExtenderFlow } from "./teamRosterExtender/sagas";
import { reportDataSourcesListFlow } from "./reportDataSourcesList/sagas";
import { userListFlow } from "./userList/sagas";
import { userFilterFlow } from "./userFilter/sagas";
import { roleFormFlow } from "./roleForm/sagas";
import { userFormFlow } from "./userForm/sagas";
import { accountVerificationFlow } from "./accountVerificationForm/sagas";
import { invitationFilterFlow } from "./invitationFilter/sagas";
import { invitationListFlow } from "./invitationList/sagas";
import { invitationFormFlow } from "./invitationForm/sagas";
import { teamRosterFlow } from "./teamRoster";
import { teamFilterFlow } from "./teamFilter/sagas";
import { teamListFlow } from "./teamList/sagas";
import { divisionDropdownFlow } from "./divisionDropdown";
import { scheduledGamesListFlow } from "./scheduledGamesList";
import { teamPickerFieldFlow } from "./teamPickerField";
import { scheduledGameFormFlow } from "./scheduledGameForm";
import { scheduledGamesCSVImportWizardFlow } from "./scheduledGamesCSVImportWizard";
import { currentPlayerFlow } from "./currentPlayer";
import { playerFormFlow } from "./playerForm";
import { gameReportEmailNotificationSettingsFlow } from "./gameReportEmailNotificationSettings";
import { unsubscribeByTokenFlow } from "./unsubscribeByToken";
import { currentGameFlow } from "./currentGame";
import { playerOfTheGameFormFlow } from "./playerOfTheGameForm";
import { trackingFlow } from "./tracking";
import { exportSeasonGamesFlow } from "./exportSeasonGames";
import { currentIpadKeyFlow } from "./currentIpadKey";
import { smartlookFlow } from "./smartlook";

function* run() {
  yield all([
    call(appFlow),
    call(tokenFlow),
    call(loginFlow),
    call(otpFlow),
    call(logoutFlow),
    call(abilityFlow),
    call(registrationFlow),
    call(resourcesFlow),
    call(accountFlow),
    call(associationsListFlow),
    call(currentAssociationFlow),
    call(associationFormFlow),
    call(leaguesListFlow),
    call(currentLeagueFlow),
    call(leagueFormFlow),
    call(seasonsListFlow),
    call(currentSeasonFlow),
    call(seasonFormFlow),
    call(cloneSeasonFormFlow),
    call(divisionsFlow),
    call(divisionsListFlow),
    call(currentDivisionFlow),
    call(divisionFormFlow),
    call(teamsFlow),
    call(playersFlow),
    call(coachesFlow),
    call(invitationsFlow),
    call(gamesFlow),
    call(gamesheetFlow),
    call(passwordFlow),
    call(seasonRosterFlow),
    call(gameFormFlow),
    call(copyTeamsFlow),
    call(ipadKeyFormFlow),
    call(ipadKeysFlow),
    call(seasonIpadKeysManagerFlow),
    call(refereesFlow),
    call(gameNotesFlow),
    call(playerPenaltyReportFlow),
    call(coachPenaltyReportFlow),
    call(penaltyAccumulationReportFlow),
    call(penaltyTypeReportFlow),
    call(refereeReportFlow),
    call(trainerVisitsReportFlow),
    call(billingReportFlow),
    call(reportDataSourcesSelectFlow),
    call(suspensionReportFlow),
    call(csvImportFlow),
    call(teamRosterExtenderFlow),
    call(reportDataSourcesListFlow),
    call(userListFlow),
    call(userFilterFlow),
    call(roleFormFlow),
    call(userFormFlow),
    call(accountVerificationFlow),
    call(invitationFilterFlow),
    call(invitationListFlow),
    call(invitationFormFlow),
    call(teamRosterFlow),
    call(teamFilterFlow),
    call(teamListFlow),
    call(divisionDropdownFlow),
    call(scheduledGamesListFlow),
    call(teamPickerFieldFlow),
    call(scheduledGameFormFlow),
    call(scheduledGamesCSVImportWizardFlow),
    call(currentPlayerFlow),
    call(playerFormFlow),
    call(gameReportEmailNotificationSettingsFlow),
    call(unsubscribeByTokenFlow),
    call(currentGameFlow),
    call(playerOfTheGameFormFlow),
    call(smartlookFlow),
    call(currentIpadKeyFlow),
    call(trackingFlow),
    call(exportSeasonGamesFlow)
  ]);
}

export default function* rootSaga() {
  while (true) {
    try {
      yield race({
        restart: take(restartAction),
        all: call(run)
      });
    } catch (error) {
      handleRuntimeError(error);
    }
  }
}
