import { createRoutine } from "redux-saga-routines";

export const fieldValidatingRoutine = createRoutine("USER_FORM/FIELD_VALIDATING");

export const validatingRoutine = createRoutine("USER_FORM/VALIDATING");
export const submittingRoutine = createRoutine("USER_FORM/SUBMITTING");
export const loadingRoutine = createRoutine("USER_FORM/LOADING");
export const deletingRoutine = createRoutine("USER_FORM/DELETING");
export const roleLevelLoadingRoutine = createRoutine("USER_FORM/ROLE_LEVEL_LOADING");

export const verificationEmailResendingRoutine = createRoutine("USER_FORM/VERIFICATION_EMAIL_RESENDING");
