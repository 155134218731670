import React, { useEffect, useMemo } from "react";

import SplashScreen from "@/components/SplashScreen/SplashScreen";
import { useOTP } from "@/lib/core/useOTP";
import { config } from "@/config";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export function TransferToTeamDashboard(){

    const history = useHistory()
    const { otp } = useOTP()
    const [isNavigating, setIsNavigating] = React.useState(false)
    
    const destination = useMemo(() => {
        const destination = new URL("/", config.TEAM_DASHBOARD)
        destination.searchParams.append("otp", otp)
        return destination
    }, [otp])

    useEffect(() => {
        if(otp && destination.href.includes(otp)){
            setIsNavigating(true)
            window.location.href = destination.href
        }
    }, [ otp, destination ])

    useEffect(() => {
        setTimeout(() => {
            !isNavigating && history.push("/")
        }, 2000)
    }, [isNavigating])
    
    return <SplashScreen />

}