import React from "react";
import PropTypes from "prop-types";

import { Input, Label, FormGroup } from "reactstrap";

function PeriodLengthField({ value, onChange, label, id }) {
  return (
    <FormGroup className="col-md-6">
      <Label for={id}>{label}</Label>
      <Input
        id={id}
        type="number"
        min={0}
        onChange={onChange}
        value={value > 0 ? value : ""}
        placeholder={value === 0 ? "0" : undefined}
      />
    </FormGroup>
  );
}

PeriodLengthField.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
};

export default PeriodLengthField;
