import React from "react";

import Spinner from "@/components/Spinner";

import "./SplashScreen.scss";

function SplashScreen() {
  return (
    <div className="splash-screen">
      <Spinner className="splash-screen__spinner" theme="loader" color="yellow" size="xxl" />
    </div>
  );
}

export default SplashScreen;
