import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import { FormGroup, FormFeedback, Label, Input } from "reactstrap";

import DashboardForm from "@/components/DashboardForm";
import DashboardFormFooter from "@/components/DashboardFormFooter";
import ContentSection from "@/components/ContentSection";
import FormItem from "@/components/FormItem";
import ConfirmDeletionModal from "@/components/ConfirmDeletionModal";
import { Can } from "@/components/Ability";
import AbilityButton from "@/components/AbilityButton";

class RefereeForm extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    refereeName: PropTypes.string,
    seasonId: PropTypes.string,
    isOperating: PropTypes.bool,
    isDeleting: PropTypes.bool,
    isInvalid: PropTypes.bool,
    isPristine: PropTypes.bool,
    isNotFilled: PropTypes.bool,
    fields: PropTypes.shape({
      firstName: PropTypes.shape({
        value: PropTypes.string.isRequired,
        error: PropTypes.string,
        isInvalid: PropTypes.bool.isRequired
      }).isRequired,
      lastName: PropTypes.shape({
        value: PropTypes.string.isRequired,
        error: PropTypes.string,
        isInvalid: PropTypes.bool.isRequired
      }).isRequired,
      emailAddress: PropTypes.shape({
        value: PropTypes.string.isRequired,
        error: PropTypes.string,
        isInvalid: PropTypes.bool.isRequired
      }).isRequired,
      externalId: PropTypes.shape({
        value: PropTypes.string.isRequired,
        error: PropTypes.string,
        isInvalid: PropTypes.bool.isRequired
      }).isRequired
    }).isRequired,
    changeField: PropTypes.func,
    submitForm: PropTypes.func,
    clearForm: PropTypes.func,
    deleteReferee: PropTypes.func
  };

  static defaultProps = {
    id: null,
    refereeName: null,
    seasonId: null,
    isOperating: false,
    isDeleting: false,
    isInvalid: false,
    isDirty: false,
    isNotFilled: false,
    changeField() {},
    submitForm() {},
    clearForm() {},
    deleteReferee() {}
  };

  handleChange = event => {
    this.props.changeField({
      field: event.target.name,
      value: event.target.value
    });
  };

  handleSubmit = () => {
    const { seasonId, submitForm } = this.props;

    submitForm({ seasonId });
  };

  render() {
    const {
      id,
      refereeName,
      isOperating,
      isDeleting,
      isInvalid,
      isPristine,
      isNotFilled,
      fields,
      deleteReferee,
      seasonId
    } = this.props;
    const { firstName, lastName, emailAddress, externalId } = fields;

    return (
      <DashboardForm isOperating={isOperating} onSubmit={this.handleSubmit}>
        <ContentSection>
          <FormItem>
            <FormGroup className="col-md-3 required">
              <Label for="refereeFirstName">First name</Label>
              <Input
                id="refereeFirstName"
                name="firstName"
                value={firstName.value}
                onChange={this.handleChange}
                invalid={firstName.isInvalid}
              />

              {firstName.errors.map(error => (
                <FormFeedback key={error}>{error}</FormFeedback>
              ))}
            </FormGroup>

            <FormGroup className="col-md-3 required">
              <Label for="refereeLastName">Last name</Label>
              <Input
                id="refereeLastName"
                name="lastName"
                value={lastName.value}
                onChange={this.handleChange}
                invalid={lastName.isInvalid}
              />

              {lastName.errors.map(error => (
                <FormFeedback key={error}>{error}</FormFeedback>
              ))}
            </FormGroup>

            <FormGroup className="col-md-6">
              <Label for="refereeEmailAddress">Email Address</Label>
              <Input
                id="refereeEmailAddress"
                name="emailAddress"
                value={emailAddress.value}
                onChange={this.handleChange}
                invalid={emailAddress.isInvalid}
              />

              {emailAddress.errors.map(error => (
                <FormFeedback key={error}>{error}</FormFeedback>
              ))}
            </FormGroup>

            <FormGroup className="col-md-6">
              <Label for="refereeExternalId">External ID</Label>
              <Input
                id="refereeExternalId"
                name="externalId"
                value={externalId.value}
                onChange={this.handleChange}
                invalid={externalId.isInvalid}
              />

              {externalId.errors.map(error => (
                <FormFeedback key={error}>{error}</FormFeedback>
              ))}
            </FormGroup>
          </FormItem>
        </ContentSection>

        <DashboardFormFooter>
          {id ? (
            <Fragment>
              <div className="dashboard-form-footer__secondary">
                <Can I="delete" this={{ type: "referees", seasonId }}>
                  <ConfirmDeletionModal
                    resourceTitle={refereeName}
                    resourceType="Referee"
                    isOperating={isOperating}
                    isDeleting={isDeleting}
                    onDelete={deleteReferee}
                  />
                </Can>
              </div>

              <div className="dashboard-form-footer__primary">
                <AbilityButton
                  subject={{ type: "referees", seasonId }}
                  action="update"
                  type="submit"
                  color="success"
                  disabled={isPristine || isInvalid || isNotFilled}
                  outline={isPristine || isInvalid || isNotFilled}
                >
                  {isOperating ? "Processing…" : "Save changes"}
                </AbilityButton>
              </div>
            </Fragment>
          ) : (
            <div className="dashboard-form-footer__primary">
              <AbilityButton
                subject={{ type: "referees", seasonId }}
                action="create"
                type="submit"
                color="success"
                disabled={isPristine || isInvalid || isNotFilled}
                outline={isPristine || isInvalid || isNotFilled}
              >
                {isOperating ? "Creating Referee…" : "Create Referee"}
              </AbilityButton>
            </div>
          )}
        </DashboardFormFooter>
      </DashboardForm>
    );
  }
}

export default RefereeForm;
