
import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Col, Row, FormGroup, Label, Input, FormFeedback, Button } from "reactstrap";

import Layout from "@/layouts/Layout";
import Head from "@/components/Head";
import TitleBar from "@/components/TitleBar";
import DashboardForm from "@/components/DashboardForm";
import DashboardFormFooter from "@/components/DashboardFormFooter";
import FormItem from "@/components/FormItem";
import { firebase } from "@/firebase";

export class HandlePasswordReset extends PureComponent {
    static propTypes = {
        actionCode: PropTypes.string,
        login: PropTypes.func.isRequired,
    };

    static defaultProps = {
        actionCode: "",
    };

    state = {
        newPassword: {
            value: "",
            error: "",
        },
        confirmNewPassword: {
            value: "",
            error: "",
        },
        canSubmit: false,
        submitting: false,
        submitError: "",
    };

    handleSubmit = async () => {
        if (!this.state.canSubmit) {
            return;
        }

        this.setState(prevState => ({
            ...prevState,
            canSubmit: false,
            submitting: true,
        }));

        let email = "";
        const password = this.state.newPassword.value;
        try {
            email = await firebase.auth().verifyPasswordResetCode(this.props.actionCode);
            await firebase.auth().confirmPasswordReset(this.props.actionCode, password);
        } catch (error) {
            if ('code' in error) {
                switch (error.code) {
                    case "auth/expired-action-code":
                    case "auth/invalid-action-code":
                    case "auth/user-disabled":
                    case "auth/user-not-found":
                    case "auth/weak-password":
                    default:
                }
            } else {

            }

            return;
        }

        this.props.login({ email, password, thenUpdatePassword: true });
    };

    handleChangeNewPassword = ({ target }) => {
        let error = "";
        if (target.value.length < 8) {
            error = "Password should be at least 8 characters long";
        }

        let confirmError = "";
        if (error === "" && this.state.confirmNewPassword.value !== target.value) {
            confirmError = "Passwords don't match";
        }

        this.setState(prevState => ({
            ...prevState,
            newPassword: {
                value: target.value,
                error,
            },
            confirmNewPassword: {
                value: prevState.confirmNewPassword.value,
                error: confirmError,
            },
            canSubmit: error === "" && confirmError === "",
        }));
    };

    handleChangeConfirmNewPassword = ({ target }) => {
        let error = "";
        if (target.value !== this.state.newPassword.value) {
            error = "Passwords don't match";
        }

        this.setState(prevState => ({
            ...prevState,
            confirmNewPassword: {
                value: target.value,
                error,
            },
            canSubmit: prevState.newPassword.error === "" && error === "",
        }));
    };

    render() {
        return (
            <Layout>
                <Head title="Create New Password" />

                <Row className="justify-content-center">
                    <Col md="10" lg="6">
                        <TitleBar title="Create New Password" />

                        <DashboardForm className="create-new-password-form" onSubmit={this.handleSubmit}>

                            <FormItem>
                                <FormGroup className="col">
                                    <Label for="newPassword">New Password</Label>
                                    <Input
                                        id="newPassword"
                                        name="newPassword"
                                        type="password"
                                        defaultValue={this.state.newPassword.value}
                                        onChange={this.handleChangeNewPassword}
                                        invalid={this.state.newPassword.error !== ""}
                                    />
                                    <FormFeedback>{this.state.newPassword.error}</FormFeedback>
                                </FormGroup>
                            </FormItem>

                            <FormItem>
                                <FormGroup className="col">
                                    <Label for="confirmNewPassword">Confirm New Password</Label>
                                    <Input
                                        id="confirmNewPassword"
                                        name="confirmNewPassword"
                                        type="password"
                                        defaultValue={this.state.confirmNewPassword.value}
                                        onChange={this.handleChangeConfirmNewPassword}
                                        invalid={this.state.confirmNewPassword.error !== ""}
                                    />
                                    <FormFeedback>{this.state.confirmNewPassword.error}</FormFeedback>
                                </FormGroup>
                            </FormItem>

                            <DashboardFormFooter className="small-margin">
                                <div className="dashboard-form-footer__primary">
                                    <Button type="submit" color="success" disabled={!this.state.canSubmit}>
                                        Confirm New Password
                                    </Button>
                                </div>
                            </DashboardFormFooter>

                            {/* {error ? <Alert color="danger">{error}</Alert> : null} */}
                        </DashboardForm>
                    </Col>
                </Row>
            </Layout>
        );
    }
}