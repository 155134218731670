import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";

import { playerAffiliated, playerDuty } from "@/utils/codeToTitle";
import { getPlayerPositionTranslator } from "@/redux/currentSeason/selectors";

import FieldValue from "./FieldValue";
import ActionsDropdown from "./ActionsDropdown";

function Player(props) {
  const { id, muted, firstName, lastName, externalId, number, affiliated, position, duty, team, isDeleting, otp } = props;
  const playerPosition = useSelector(getPlayerPositionTranslator);

  return (
    <tr className={classNames({ "players-roster__player--muted": muted })}>
      <td>
        <FieldValue teamId={team.id} value={number} name="number" muted={muted} member={{ type: "players", id }} />
      </td>
      <td>
        <FieldValue
          teamId={team.id}
          value={firstName}
          name="firstName"
          muted={muted}
          member={{ type: "players", id }}
        />
      </td>
      <td>
        <FieldValue teamId={team.id} value={lastName} name="lastName" muted={muted} member={{ type: "players", id }}>
          {lastName => <span className="players-roster__player__last-name">{lastName}</span>}
        </FieldValue>
      </td>
      <td>
        <FieldValue
          teamId={team.id}
          value={externalId}
          name="externalId"
          muted={muted}
          member={{ type: "players", id }}
        />
      </td>
      <td>
        <FieldValue
          teamId={team.id}
          value={affiliated}
          name="affiliated"
          muted={muted}
          member={{ type: "players", id }}
          transform={playerAffiliated}
        />
      </td>
      <td>
        <FieldValue
          teamId={team.id}
          value={position}
          name="position"
          muted={muted}
          member={{ type: "players", id }}
          transform={playerPosition}
        />
      </td>
      <td>
        <FieldValue
          teamId={team.id}
          value={duty}
          name="duty"
          muted={muted}
          member={{ type: "players", id }}
          transform={playerDuty}
        />
      </td>
      <td className="text-right">
        <ActionsDropdown
          seasonId={team.season.id}
          teamId={team.id}
          member={{ type: "players", id, title: "Player" }}
          isDeleting={isDeleting}
          disabled={muted}
          rosterLocked={team.data.rosterLocked}
          otp={otp}
        />
      </td>
    </tr>
  );
}

Player.propTypes = {
  muted: PropTypes.bool,
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  externalId: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  affiliated: PropTypes.bool.isRequired,
  position: PropTypes.string.isRequired,
  duty: PropTypes.string.isRequired,
  team: PropTypes.shape({
    season: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
    id: PropTypes.string.isRequired
  }).isRequired,
  isDeleting: PropTypes.bool.isRequired
};

Player.defaultProps = {
  muted: false
};

export default Player;
