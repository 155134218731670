import React, { Fragment, useCallback } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import SelectedResourcesCount from "./SelectedResourcesCount";
import { 
  getSelectedTeams, getTeamTitle,
  getSelectedDivisions, getDivisionTitle,
  getSelectedSeasons, getSeasonTitle,
  getSelectedLeagues, getLeagueTitle,
  getSelectedAssociations, getAssociationTitle } from "@/redux/roleForm/selectors"; 

import { Row, FormGroup, Button } from "reactstrap";

import { Can } from "@/components/Ability";

import {
  getFullRoleName,
  getIsValid,
  getIsInvalid,
  getIsInitializing,
  getRole,
  getInitRole,
  getResourceTitle,
  getIsPristine
} from "@/redux/roleForm/selectors";

import actions from "@/redux/roleForm/actions";

import TitleBar from "@/components/TitleBar";
import PageLoader from "@/components/PageLoader";
import DashboardForm from "@/components/DashboardForm";
import DashboardFormFooter from "@/components/DashboardFormFooter";
import ConfirmDeletionModal from "@/components/ConfirmDeletionModal";

import TitleSelect from "./TitleSelect";
import AssociationSelect from "./AssociationSelect";
import LeagueSelect from "./LeagueSelect";
import SeasonSelect from "./SeasonSelect";
import DivisionSelect from "./DivisionSelect";
import TeamSelect from "./TeamSelect";
import GlobalUserCheckbox from "./GlobalUserCheckbox";

function RoleForm({ onSubmit, onRemove }) {
  const dispatch = useDispatch();
  const isValid = useSelector(getIsValid);
  const isInvalid = useSelector(getIsInvalid);
  const isInitializing = useSelector(getIsInitializing);
  const role = useSelector(getRole);
  const initRole = useSelector(getInitRole);
  const resourceTitle = useSelector(getResourceTitle);
  const isPristine = useSelector(getIsPristine);
  const reset = useCallback(() => dispatch(actions.reset()), [dispatch]);
  const fullRoleName = useSelector(state => getFullRoleName(state));
  const selectedTeams = useSelector(getSelectedTeams); // Added line to retrieve selected teams
  const selectedDivisions = useSelector(getSelectedDivisions); // Retrieves selected divisions
  const selectedSeasons = useSelector(getSelectedSeasons); 
  const selectedLeagues = useSelector(getSelectedLeagues); 
  const selectedAssociations = useSelector(getSelectedAssociations); 
  const state = useSelector(state => state); // Get the Redux state
  
  const submit = useCallback(
    () => {
      // Process isGlobal role first in case association is selected
      if (role.level.id == "*") {
        onSubmit({ initRole, role, resourceTitle });
      }

      else if (selectedTeams && Array.isArray(selectedTeams) && selectedTeams.length > 0) {
        const teamTitles = selectedTeams.map((teamId) => getTeamTitle(state, teamId)); // Generate titles for selected teams
        const additionalRoles = selectedTeams.map((teamId, index) => ({
          ...role,
          level: { type: "teams", id: teamId },
          resourceTitle: teamTitles[index]
        }));
        selectedTeams.forEach((teamId, index) => {
          const teamTitle = teamTitles[index];
          onSubmit({
            initRole,
            role: { ...role, level: { type: "teams", id: teamId } }, // Update level.type and level.id for teams
            selectedTeams: [teamId],
            teamTitles: [teamTitle],
            additionalRoles,
          });
        });
      } 
      
      else if (selectedDivisions && Array.isArray(selectedDivisions) && selectedDivisions.length > 0) {
        const divisionTitles = selectedDivisions.map((divisionId) => getDivisionTitle(state, divisionId)); // Generate titles for selected divisions
        const additionalRoles = selectedDivisions.map((divisionId, index) => ({
          ...role,
          level: { type: "divisions", id: divisionId },
          resourceTitle: divisionTitles[index]
        }));
        selectedDivisions.forEach((divisionId, index) => {
          const divisionTitle = divisionTitles[index];
          onSubmit({
            initRole,
            role: { ...role, level: { type: "divisions", id: divisionId } }, // Update level.type and level.id for divisions
            selectedDivisions: [divisionId],
            divisionTitles: [divisionTitle],
            additionalRoles,
          });
        });
      } 
      
      else if (selectedSeasons && Array.isArray(selectedSeasons) && selectedSeasons.length > 0) {
        const seasonTitles = selectedSeasons.map((seasonId) => getSeasonTitle(state, seasonId)); // Generate titles for selected seasons
        const additionalRoles = selectedSeasons.map((seasonId, index) => ({
          ...role,
          level: { type: "seasons", id: seasonId },
          resourceTitle: seasonTitles[index]
        }));
        selectedSeasons.forEach((seasonId, index) => {
          const seasonTitle = seasonTitles[index];
          onSubmit({
            initRole,
            role: { ...role, level: { type: "seasons", id: seasonId } }, // Update level.type and level.id for seasons
            selectedSeasons: [seasonId],
            seasonTitles: [seasonTitle],
            additionalRoles,
          });
        });
      } 
    
      else if (selectedLeagues && Array.isArray(selectedLeagues) && selectedLeagues.length > 0) {
        const leagueTitles = selectedLeagues.map((leagueId) => getLeagueTitle(state, leagueId)); // Generate titles for selected leagues
        const additionalRoles = selectedLeagues.map((leagueId, index) => ({
          ...role,
          level: { type: "leagues", id: leagueId },
          resourceTitle: leagueTitles[index]
        }));
        selectedLeagues.forEach((leagueId, index) => {
          const leagueTitle = leagueTitles[index];
          onSubmit({
            initRole,
            role: { ...role, level: { type: "leagues", id: leagueId } }, // Update level.type and level.id for leagues
            selectedLeagues: [leagueId],
            leagueTitles: [leagueTitle],
            additionalRoles,
          });
        });
      } 
    
      else if (selectedAssociations && Array.isArray(selectedAssociations) && selectedAssociations.length > 0) {
        const associationTitles = selectedAssociations.map((associationId) => getAssociationTitle(state, associationId)); // Generate titles for selected associations
        const additionalRoles = selectedAssociations.map((associationId, index) => ({
          ...role,
          level: { type: "associations", id: associationId },
          resourceTitle: associationTitles[index]
        }));
        selectedAssociations.forEach((associationId, index) => {
          const associationTitle = associationTitles[index];
          onSubmit({
            initRole,
            role: { ...role, level: { type: "associations", id: associationId } }, // Update level.type and level.id for associations
            selectedAssociations: [associationId],
            associationTitles: [associationTitle],
            additionalRoles,
          });
        });
      } 
    
      else {
        onSubmit({ initRole, role, resourceTitle });
      }
      
      reset(); // Reset after all roles have been submitted
    },
    [onSubmit, initRole, role, reset, selectedTeams, selectedDivisions, selectedSeasons, selectedLeagues, selectedAssociations, state]
  );
  
  const remove = useCallback(
    () => {
      onRemove(initRole);
      reset();
    },
    [onRemove, initRole, reset]
  );

  if (isInitializing) {
    return <PageLoader isLoading />;
  }

  return (
    <Fragment>
      <TitleBar title={initRole ? "Edit Role" : "Add Role"} />

      <DashboardForm onSubmit={submit}>
        <Row form>
          <FormGroup className="col-md-3">
            <TitleSelect />
          </FormGroup>

          <Can I="update" this={{ type: "all" }}>
            <FormGroup className="col-md-3 d-flex align-items-center pl-4">
              <GlobalUserCheckbox />
            </FormGroup>
          </Can>
        </Row>

        <Row form>
          <FormGroup className="col-md-2">
            <AssociationSelect />
          </FormGroup>

          <FormGroup className="col-md-2">
            <LeagueSelect />
          </FormGroup>

          <FormGroup className="col-md-2">
            <SeasonSelect />
          </FormGroup>

          <FormGroup className="col-md-3">
            <DivisionSelect />
          </FormGroup>

          <FormGroup className="col-md-3">
            <TeamSelect />
          </FormGroup>
        </Row>

        <DashboardFormFooter>
          <div className="dashboard-form-footer__secondary">
            <div className="dashboard-form-footer__count">
              <SelectedResourcesCount singleSelect={false} />
            </div>
            <Button outline size="sm" color="secondary" onClick={reset}>
              Cancel
            </Button>

            {initRole && <ConfirmDeletionModal resourceType="Role" resourceTitle={fullRoleName} onDelete={remove} />}
          </div>

          <div className="dashboard-form-footer__primary">
            <Button
              type="submit"
              outline={isInvalid || isPristine}
              color={isInvalid || isPristine ? "secondary" : "success"}
              disabled={isInvalid || isPristine}
            >
              {isValid
                ? initRole
                  ? `Change Role to ${fullRoleName}`
                  : `Add ${fullRoleName} Role`
                : initRole
                  ? `Change Role`
                  : `Add Role`}
            </Button>
          </div>
        </DashboardFormFooter>
      </DashboardForm>
    </Fragment>
  );
}

RoleForm.propTypes = {
  onSubmit: PropTypes.func,
  onRemove: PropTypes.func
};

RoleForm.defaultProps = {
  onSubmit() { },
  onRemove() { }
};

export default RoleForm;
