import { handleActions } from "redux-actions";

import { invitationFormActions } from "../../actions";

const defaultState = {
  isOpen: false,
  association: null,
  leagues: []
};

const open = () => Object.assign({}, defaultState, { isOpen: true });
const close = () => Object.assign({}, defaultState, { isOpen: false });

const toggleOpened = state => Object.assign({}, defaultState, { isOpen: !state.isOpen });

const setAssociation = (state, { payload }) =>
  Object.assign({}, state, {
    association: payload
  });

const selectLeague = (state, { payload }) =>
  Object.assign({}, state, {
    leagues: [...state.leagues, payload]
  });

const deselectLeague = (state, { payload }) =>
  Object.assign({}, state, {
    leagues: state.leagues.filter(id => id !== payload)
  });

export default handleActions(
  {
    [invitationFormActions.leaguesList.open]: open,
    [invitationFormActions.leaguesList.close]: close,
    [invitationFormActions.leaguesList.toggleOpened]: toggleOpened,
    [invitationFormActions.leaguesList.selectAssociation]: setAssociation,
    [invitationFormActions.leaguesList.selectLeague]: selectLeague,
    [invitationFormActions.leaguesList.deselectLeague]: deselectLeague
  },
  defaultState
);
