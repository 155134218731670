import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import actions from "@/redux/csvImport/actions";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import ImportPage from "./ImportPage";

function ImportPageContainer({ seasonId }) {
  const dispatch = useDispatch();

  const { seasonBreadcrumbs, seasonIsLoaded, seasonIsLoading, seasonIsForbidden } = useCurrentSeasonContext();

  const reset = useCallback(() => dispatch(actions.reset({ seasonId })), [dispatch, seasonId]);

  return (
    <ImportPage
      seasonId={seasonId}
      seasonIsLoaded={seasonIsLoaded}
      seasonIsLoading={seasonIsLoading}
      seasonIsForbidden={seasonIsForbidden}
      breadcrumbs={seasonBreadcrumbs}
      reset={reset}
    />
  );
}

ImportPageContainer.propTypes = {
  seasonId: PropTypes.string.isRequired
};

export default ImportPageContainer;
